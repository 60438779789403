import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  roots: {
    display: "flex",
    borderRadius: "30px",
    width: "100%",
  },

  classTogglad: {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    borderRadius: "30px",
    color: "#6C757D",
    // border: "1px solid",
    // borderColor: "#C9C9C9",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    width: "100%",
    alignSelf: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {},

  switchBase: {
    color: theme.palette.primary.main,

    "&$checked": {
      color: theme.palette.primary.main,

      "& + $track": {
        opacity: 1,
        border: "none",
      },
    },
    "&$checked + $track": {
      backgroundColor: "#D9D9D9",
    },
  },
  thumb: {},
  track: {
    backgroundColor: "#D9D9D9",
    color: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      inputProps={{ "aria-label": "controlled" }}
      // checked={groupView}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function TableSwitch(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.classTogglad}>
        <IOSSwitch
          onChange={props.ToggleSwitch}
          name="checkedB"
          checked={props.value}
        />
        <Typography variant="body2" color="inherit">
          {props.switchToggled ? "Card View" : "Table View"}
        </Typography>
      </div>
    </>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  Button,
  createStyles,
  Divider,
  Drawer,
  Hidden,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { TReduxStore } from "../../..";
import applicants from "../../../assets/common/applicants.svg";
import company_icon from "../../../assets/common/company.png";
import connection from "../../../assets/common/connection.svg";
import Home_icon from "../../../assets/common/home_icon.png";
import job from "../../../assets/common/job.svg";
import arrow1 from "../../../assets/common/Layer_arrow.svg";
import report from "../../../assets/common/report.svg";
import upload from "../../../assets/common/upload.svg";
import Logo from "../../../assets/images/sidepanel/logo.png";
import Logo_tr from "../../../assets/logo-transparent.png";
import ActivateCreditButton from "../../../components/common/ActivateCredit/ActivateCreditButton";
import ActivateCreditModal from "../../../components/common/ActivateCredit/ActivateCreditModal";
import CreditBoard from "../../../GlobalComponents/CreditBoard/CreditBoard";

interface Props {
  window?: any;
  handleDrawerToggle?: () => void;
  mobileOpen?: boolean;
}

const CollegeSidebar = (props: Props) => {
  const { window, handleDrawerToggle, mobileOpen } = props;
  const [creditModal, showCreditModal] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const classes2 = useStyles1();
  const userDetails = useSelector<TReduxStore, any>((state) => {
    //@ts-ignore
    return state.UserDetails?.userDetails;
  });
  const [logoUrl, setLogoUrl] = useState("");
  const theme = useTheme();

  useEffect(() => {
    setLogoUrl(userDetails.college_logo);
  }, [userDetails]);

  const [expanded, setExpanded] = useState("");

  const container = window !== undefined ? () => window().document.body : undefined;

  const switchIcon = (menu: string, defaultIcon: string, flag: boolean) => {
    if (flag && menu === "Home") {
      return Home_icon;
    } else if (flag && menu === "Invite Companies") {
      return company_icon;
    } else if (flag && menu === "Jobs") {
      return job;
    } else if (flag && menu === "Applicants Status") {
      return applicants;
    } else if (flag && menu === "Connections") {
      return connection;
    } else if (flag && menu === "Upload Student Data") {
      return upload;
    } else if (flag && menu === "Reports") {
      return report;
    } else return defaultIcon;
  };

  const switchIconDark = (menu: string, defaultIcon: string, flag: boolean) => {
    if (flag && menu === "Home") {
      return Home_icon;
    } else if (flag && menu === "Invite Companies") {
      return company_icon;
    } else if (flag && menu === "Jobs") {
      return job;
    } else if (flag && menu === "Applicants Status") {
      return applicants;
    } else if (flag && menu === "Connections") {
      return connection;
    } else if (flag && menu === "Upload Student Data") {
      return upload;
    } else if (flag && menu === "Reports") {
      return report;
    } else return defaultIcon;
  };

  const sideMenus = [
    {
      menu: "Home",
      link: "/college/dashboard",
      icon: Home_icon,
      subMenu: [],
      subLinks: [],
    },
    // {
    //   menu: "Invite Companies",
    //   link: "/college/invite",
    //   icon: company_icon,
    //   subMenu: [],
    //   subLinks: [],
    // },
    {
      menu: "Jobs",
      icon: job,
      link: "",
      subMenu: [
        { name: "GetWork Listing", isNew: true },
        { name: "Drafted Jobs", isNew: false },

        { name: "Inbound Jobs", isNew: false },
        { name: "Open Jobs", isNew: false },
        { name: "Closed Jobs", isNew: false },
      ],
      subLinks: ["/college/recommended-job", "/college/drafted-job", "/college/new-job", "/college/open-job", "/college/close-job"],
    },

    {
      menu: "Applicants Status",
      icon: applicants,
      link: "",
      subMenu: [
        { name: "Track", isNew: false },
        { name: "Interviews", isNew: false },
      ],
      subLinks: ["/college/track", "/college/interviews"],
    },
    {
      menu: "Connections",
      icon: connection,
      link: "",
      subMenu: [
        // { name: "Companies", isNew: false },
        { name: "Students", isNew: false },
      ],
      subLinks: [
        // "/college/company-connection",
        "/college/students_connections",
      ],
    },

    {
      menu: "Upload Student Data",
      link: "/college/upload_student_data",
      icon: upload,
      subMenu: [],
      subLinks: [],
    },
    {
      menu: "Reports",
      link: "/college/reports",
      icon: report,
      subMenu: [],
      subLinks: [],
    },
  ];

  const sideMenusDark = [
    {
      menu: "Home",
      link: "/college/dashboard",
      icon: Home_icon,
      subMenu: [],
      subLinks: [],
    },
    // {
    //   menu: "Invite Companies",
    //   link: "/college/invite",
    //   icon: company_icon,
    //   subMenu: [],
    //   subLinks: [],
    // },

    {
      menu: "Jobs",
      icon: job,
      link: "",
      isNew: true,
      subMenu: [
        { name: "GetWork Listing", isNew: true },
        { name: "Inbound Jobs", isNew: false },
        { name: "Open Jobs", isNew: false },
        { name: "Closed Jobs", isNew: false },
      ],
      subLinks: ["/college/recommended-job", "/college/new-job", "/college/open-job", "/college/close-job"],
    },

    {
      menu: "Applicants Status",
      icon: applicants,
      link: "",
      subMenu: [
        { name: "Track", isNew: false },
        { name: "Interviews", isNew: false },
      ],
      subLinks: ["/college/track", "/college/interviews"],
    },
    {
      menu: "Connections",
      icon: connection,
      link: "",
      subMenu: [
        // { name: "Companies", isNew: false },
        { name: "Students", isNew: false },
      ],
      subLinks: [
        // "/college/company-connection",
        "/college/students_connections",
      ],
    },

    {
      menu: "Upload Student Data",
      link: "/college/upload_student_data",
      icon: upload,
      subMenu: [],
      subLinks: [],
    },
    {
      menu: "Reports",
      link: "/college/reports",
      icon: report,
      subMenu: [],
      subLinks: [],
    },
  ];

  const checkAccordionExpansion = () => {
    if (
      location.pathname === "/college/new-job" ||
      location.pathname === "/college/open-job" ||
      location.pathname === "/college/new-job" ||
      location.pathname === "/college/close-job"
    ) {
      setExpanded("Jobs");
    } else if (location.pathname === "/college/company-connection" || location.pathname === "/college/students_connections") {
      setExpanded("Connections");
    } else if (location.pathname === "/student/invited" || location.pathname === "/student/scheduled") {
      setExpanded("Notices");
    } else if (location.pathname === "/college/track" || location.pathname === "/college/interviews") {
      setExpanded("Applicants Status");
    } else setExpanded("");
  };

  useEffect(() => {
    checkAccordionExpansion();
  }, [location.pathname]);

  const handleExpansionChange = (panel: any) => (event: object, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box>
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
            container={container}
            variant="temporary"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <div />
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="space-around">
              <a href="/college/dashboard">
                <img src={Logo} className={classes.logo} alt="logo" />
              </a>
              <Avatar src={logoUrl} />
            </Box>

            <br />
            <br />
            <br />
            <Box>
              <CreditBoard iscollege={true}/>
            </Box>
            <div
              onClick={() => {
                showCreditModal(true);
              }}
            >
              <ActivateCreditButton />
            </div>
            <Box display="flex" justifyContent="center">
              <Link to="/college/post-job">
                <Button className={classes.createJobButton}>
                  <WorkOutlineIcon style={{ margin: "0px 10px 0px 0px" }} />
                  Create Job
                </Button>
              </Link>
            </Box>
            <>
              {sideMenus.map((item, index) =>
                item.subMenu.length ? (
                  <Accordion className={classes.accordion} key={`${item.menu}-${index}`} classes={{ root: classes.accordionRoot }}>
                    <AccordionSummary
                      classes={{
                        expandIcon: classes.expandIcon,
                        expanded: classes.expanded,
                      }}
                      expandIcon={<img src={arrow1} height="20px" alt="arrow-right" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <img
                          src={switchIcon(item.menu, item.icon, item.link === location.pathname || item.subLinks.includes(location.pathname))}
                          className={classes.expandIcon}
                          alt="expand-icon"
                        />
                        <Typography variant="body1" className={classes.sidePanelMenuText}>
                          {item.menu}
                        </Typography>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Box display="flex" flexDirection="column" alignItems="end" paddingLeft="33%">
                        {item.subMenu.map((item2, index2) => (
                          <NavLink to={`${item.subLinks[index2]}`} key={index2} activeClassName={classes.activeLink}>
                            {item2.isNew === true ? (
                              <>
                                <Badge
                                  color="secondary"
                                  badgeContent={"New"}
                                  classes={{
                                    badge: classes2.badge,
                                    colorSecondary: classes2.colorSecondary,
                                  }}
                                >
                                  <Typography variant="body1" className={classes.submenuText}>
                                    {item2.name}
                                  </Typography>
                                </Badge>
                                <Divider />
                              </>
                            ) : (
                              <>
                                <Typography variant="body1" className={classes.submenuText}>
                                  {item2.name}
                                </Typography>
                                <Divider />
                              </>
                            )}
                          </NavLink>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <NavLink to={item.link} activeClassName={classes.activeLink}>
                    <Box className={classes.normalLinkBox} key={index}>
                      <img
                        src={switchIcon(item.menu, item.icon, item.link === location.pathname || item.subLinks.includes(location.pathname))}
                        className={classes.expandIcon}
                        alt="expand-icon"
                      />
                      <Typography variant="body1" className={classes.sidePanelMenuText}>
                        {item.menu}
                      </Typography>
                    </Box>
                  </NavLink>
                )
              )}

              {/* <FooterNew /> */}
            </>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <div />
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="space-around">
              <a href="/college/dashboard">
                <img src={theme.palette.type === "dark" ? Logo_tr : Logo} className={classes.logo} alt="logo" />
              </a>
              <Avatar src={logoUrl} />
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <CreditBoard iscollege={true}/>
            </Box>
            <div onClick={() => showCreditModal(true)}>
              <ActivateCreditButton />
            </div>
            <Box display="flex" justifyContent="center">
              <Link to="/college/post-job">
                <Button className={classes.createJobButton}>
                  <WorkOutlineIcon style={{ margin: "0px 10px 0px 0px" }} />
                  Create Job
                </Button>
              </Link>
            </Box>
            {theme.palette.type === "dark"
              ? sideMenusDark.map((item, index) =>
                  item.subMenu.length ? (
                    <Accordion
                      className={classes.accordion}
                      key={`${item.menu}-${index}`}
                      classes={{ root: classes.accordionRoot }}
                      expanded={expanded === item.menu}
                      onChange={handleExpansionChange(item.menu)}
                    >
                      <AccordionSummary
                        classes={{
                          expandIcon: classes.expandIcon,
                          expanded: classes.expanded,
                        }}
                        expandIcon={<img src={arrow1} height="20px" alt="arrow-right" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          <img
                            src={switchIconDark(item.menu, item.icon, item.link === location.pathname || item.subLinks.includes(location.pathname))}
                            className={classes.expandIcon}
                            alt="expand-icon"
                          />
                          <Typography variant="body1" className={classes.sidePanelMenuText}>
                            {item.menu}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex" flexDirection="column" alignItems="end" paddingLeft="23%">
                          {item.subMenu.map((item2, index2) => (
                            <NavLink to={`${item.subLinks[index2]}`} key={index2} activeClassName={classes.activeLink}>
                              {item2.isNew === true ? (
                                <>
                                  <Badge
                                    color="secondary"
                                    badgeContent={"New"}
                                    classes={{
                                      badge: classes2.badge,
                                      colorSecondary: classes2.colorSecondary,
                                    }}
                                  >
                                    <Typography variant="subtitle1" className={classes.submenuText}>
                                      {item2.name}
                                    </Typography>
                                  </Badge>
                                  <Divider />
                                </>
                              ) : (
                                <>
                                  <Typography variant="subtitle1" className={classes.submenuText}>
                                    {item2.name}
                                  </Typography>
                                  <Divider />
                                </>
                              )}
                            </NavLink>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <NavLink to={item.link} activeClassName={classes.activeLink}>
                      <Box className={classes.normalLinkBox} key={index}>
                        <img
                          src={switchIconDark(item.menu, item.icon, item.link === location.pathname || item.subLinks.includes(location.pathname))}
                          className={classes.expandIcon}
                          alt="expand-icon"
                        />
                        <Typography variant="body1" className={classes.sidePanelMenuText}>
                          {item.menu}
                        </Typography>
                      </Box>
                    </NavLink>
                  )
                )
              : sideMenus.map((item, index) =>
                  item.subMenu.length ? (
                    <Accordion
                      className={classes.accordion}
                      key={`${item.menu}-${index}`}
                      classes={{ root: classes.accordionRoot }}
                      expanded={expanded === item.menu}
                      onChange={handleExpansionChange(item.menu)}
                    >
                      <AccordionSummary
                        classes={{
                          expandIcon: classes.expandIcon,
                          expanded: classes.expanded,
                        }}
                        expandIcon={<img src={arrow1} height="20px" alt="arrow-right" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          <img
                            src={switchIcon(item.menu, item.icon, item.link === location.pathname || item.subLinks.includes(location.pathname))}
                            className={classes.expandIcon}
                            alt="expand-icon"
                          />
                          <Typography variant="body1" className={classes.sidePanelMenuText}>
                            {item.menu}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex" flexDirection="column" alignItems="end" paddingLeft="23%">
                          {item.subMenu.map((item2, index2) => (
                            <NavLink to={`${item.subLinks[index2]}`} key={index2} activeClassName={classes.activeLink}>
                              <Typography variant="subtitle1" className={classes.submenuText}>
                                {item2.isNew === true ? (
                                  <>
                                    <Badge
                                      color="secondary"
                                      badgeContent={"New"}
                                      classes={{
                                        badge: classes2.badge,
                                        colorSecondary: classes2.colorSecondary,
                                      }}
                                    >
                                      <Typography variant="subtitle1" className={classes.submenuText}>
                                        {item2.name}
                                      </Typography>
                                    </Badge>
                                    <Divider />
                                  </>
                                ) : (
                                  <>
                                    <Typography variant="subtitle1" className={classes.submenuText}>
                                      {item2.name}
                                    </Typography>
                                    <Divider />
                                  </>
                                )}
                              </Typography>
                            </NavLink>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <NavLink to={item.link} activeClassName={classes.activeLink}>
                      <Box className={classes.normalLinkBox} key={index}>
                        <img
                          src={switchIcon(item.menu, item.icon, item.link === location.pathname || item.subLinks.includes(location.pathname))}
                          className={classes.expandIcon}
                          alt="expand-icon"
                        />
                        <Typography variant="body1" className={classes.sidePanelMenuText}>
                          {item.menu}
                        </Typography>
                      </Box>
                    </NavLink>
                  )
                )}
            {/* <FooterNew /> */}
          </Drawer>
        </Hidden>
      </Box>

      {creditModal && (
        // @ts-ignore
        <ActivateCreditModal
          isFromSideBar={true}
          openModal={creditModal}
          // @ts-ignore
          setOpenModal={showCreditModal}
        />
      )}
    </>
  );
};

const useStyles1 = makeStyles({
  badge: {
    position: "relative",
    right: 15,
    top: 9,
  },
  colorSecondary: {
    background: "linear-gradient(90deg, #f58915 0%, #fd1d1d 51%, #1d3557 100%)",
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: `calc(100% - 220px)`,
      marginLeft: "220px",
      background: theme.palette.common.white,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    drawer: {
      width: "220px",
    },
    drawerPaper: {
      width: "220px",
      backgroundColor: theme.palette.background.default,
      borderRadius: "30px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 0 3px 0 #ced4da",
      border: "solid 1px #f1f1f1",
      scrollbarWidth: "none",
      // backgroundColor: "#ffffff",
    },
    logo: {
      width: "60px",
      height: "auto",
      marginTop: 10,
    },
    colorSelectorBox: {
      background: theme.palette.common.black,
      borderRadius: 4,
      width: "47px",
      height: "47px",
    },
    createJobButton: {
      width: "130px",
      height: "46px",
      //@ts-ignore
      backgroundColor: theme.palette.domain.main,
      marginBottom: "30px",
      // borderRadius: 4,
      fontFamily: "Roboto",
      fontWeight: "bold",
      textTransform: "capitalize",
      color: "#fff",

      marginTop: "30px",
      borderRadius: "40px",
      // boxShadow: "-6px -6px 30px 0 #ffffff",
      // backgroundImage: "linear-gradient(282deg, #00bcff 124%, #e5e6ec -76%)",
      "&:hover": {
        //@ts-ignore
        backgroundColor: theme.palette.domain.main,
        boxShadow: theme.palette.type !== "dark" && "2px 2px 1px rgba(0, 0, 10, 0.25)",
      },
      // "&:focus": {
      //   outline: "none",
      // },
    },
    listIcon: {
      minWidth: "36px",
    },
    listIcon2: {
      minWidth: "16px",
    },
    accordion: {
      boxShadow: "none",
      backgroundColor: theme.palette.background.default,
    },
    expandIcon: {
      "&$expanded": {
        transform: "rotate(90deg)",
      },
      width: "auto",
      height: "24px",
    },
    expanded: {},
    sidePanelMenuText: {
      marginLeft: "5%",
    },
    submenuText: {
      fontFamily: "ProximaNova",
    },
    normalLinkBox: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "11px 16px",
      cursor: "pointer",
      backgroundColor: theme.palette.background.default,
    },
    accordionRoot: {
      "&:before": {
        height: "0px",
      },
    },
    activeLink: {
      fontFamily: "ProximaNova",
      color: theme.palette.primary.main,
    },
  })
);

export default CollegeSidebar;

import { Button, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "8px",
    display: "flex",
    marginRight: "80px",
  },
})(props => (
  <Menu
    MenuListProps={{
      style: {
        padding: "0px",
      },
    }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function View({ id }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        View
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <Link to={`/company/manage-job/?job_id=${btoa(id)}`} style={{ textDecoration: "none" }}>
            <ListItemText primary="Track Job" />
          </Link>
        </StyledMenuItem>
        {/* <StyledMenuItem>
          <Link
            to={{ pathname: `/assignment-details/`, search: `?job_id=${id}` }}
            style={{ textDecoration: "none" }}
          >
            <ListItemText primary="View Assignment" />
          </Link>
        </StyledMenuItem>

        <StyledMenuItem>
          <Link
            to={{ pathname: `/interview/`, search: `?${id}` }}
            style={{ textDecoration: "none" }}
          >
            <ListItemText primary="View Interviews" />
          </Link>
        </StyledMenuItem> */}
      </StyledMenu>
    </div>
  );
}

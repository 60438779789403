/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import oj from "../../../assets/images/openjob.png";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import NewJobCard from "../../../components/college/JobCard/NewJobCard";
import TrackPane from "../../../components/college/TrackPane/TrackPane";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import { BackendBaseApi } from "../../../constants/constants";
import { AllCompanyProps, AllJobProfilesProps, JobListProps, JobTypeProps } from "../NewJob/@types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsCont: {
      marginTop: "70px",
      overflowY: "scroll",
      scrollbarWidth: "none",
    },
  })
);

export default function OpenJob() {
  const alert = useAlert();
  const classes = useStyles();
  const [jobData, setJobData] = useState<JobListProps[]>([]);

  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searching, setSearching] = useState("");
  const [buttonClickSearch, setButtonClickSearch] = useState("");
  const [allJobProfiles, setAllJobProfiles] = useState<AllJobProfilesProps[]>([]);
  const [jobProfile, setJobProfile] = useState("All");

  const [jobType, setJobType] = useState<JobTypeProps[]>([]);
  const [selectedJobType, setSelectedJobType] = useState("All");

  const [allCompanies, setAllCompanies] = useState<AllCompanyProps[]>([]);
  const [selectedCompany, setSelectedCompany] = useState("All");

  const [queryParamsObj, setQueryParamsObj] = useState({});

  const [filteredJobData, setFilteredJobData] = useState([]);
  const [jobProfileObject, setJobProfileObject] = useState({});
  const [mainData, setMainData] = useState<JobListProps[]>([]);
  const firstCall = useRef(0);
  let search = useLocation().search;
  let params = (new URLSearchParams(search)).get('query');

  const generateQueryParamsObj = (arr: JobListProps[]) => {
    const newArr = arr.map((item) => ({
      id: item.id,
      is_approved: item.is_approved,
      is_rejected: !item.is_approved,
      college_id: parseInt(localStorage.getItem("college_id")!),
      user_id: parseInt(localStorage.getItem("user_id")!),
    }));
    setQueryParamsObj(newArr);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    let e = event.nativeEvent;
    if (
      //@ts-ignore
      e.target.scrollTop + 10 >=
      //@ts-ignore
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        newData(newURL, newEndPoint);
      }
    }
  };

  const getJobTypes = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch((err) => {
      throw err;
    });
    let obj = { id: "All", job_type: "All" };
    setJobType([obj, ...res.data.data]);
  };

  const getCompanies = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + `job/college/get_companies/?college_id=${btoa(localStorage.getItem("college_id")!)}`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    }).catch((err) => {
      throw err;
    });
    let obj = { company_id: "All", company_name: "All" };
    setAllCompanies([obj, ...res.data.data]);
  };

  useEffect(() => {
    getJobTypes();
    getCompanies();
  }, []);

  useEffect(() => {
    if(searching!=='' && firstCall.current === 0){
      firstCall.current = 1;
      return;
    }
    if (searching !== "") getAllJobs();
  }, [buttonClickSearch]);

  const getAllJobs = () => {
    const checkJobProfile = () => {
      if (jobProfile === "All") {
        return "";
      } else {
        return `&job_id=${btoa(jobProfile)}`;
      }
    };

    const checkJobType = () => {
      if (selectedJobType === "All") {
        return "";
      } else {
        return `&job_type_id=${btoa(selectedJobType)}`;
      }
    };

    const checkCompany = () => {
      if (selectedCompany === "All") {
        return "";
      } else {
        return `&company_id=${btoa(selectedCompany)}`;
      }
    };

    const checkSearch = () => {
      if (buttonClickSearch !== "") { 
        firstCall.current = 1;
        return `&search=${buttonClickSearch}`;
      }
      else if(firstCall.current === 0 && params) {
        setSearching(params);
        setButtonClickSearch(params);
        return `&search=${params}`;
      }
      else {
        firstCall.current = 1;
        return ``;
      }
    };

    setLoading(true);
    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/invite/?college_id=${btoa(localStorage.getItem("college_id")!)}${checkJobType()}${checkJobProfile()}${checkCompany()}&job_status=T1BFTg==${checkSearch()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.next === null) {
            setEnd(true);
          } else {
            setEnd(false);
            setNewURL(res.data.data.next.slice(0, 20));
            setNewEndPoint(res.data.data.next.slice(20));
          }

          let withFilter = [];

          setJobData(res.data.data.results);
          generateQueryParamsObj(res.data.data.results);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Some Error Occured");
        throw err;
      });
      
  };

  useEffect(() => {
    
    getAllJobs();
  }, [selectedJobType, selectedCompany, jobProfile]);

  useEffect(() => {
    filteredJobData.length !== 0 ? setMainData(filteredJobData) : setMainData(jobData);
  }, [filteredJobData, jobData]);

  const newData = (baseURL: string, endpoint: string) => {
    setLoading(true);
    setLoadMore(true);
    Axios.get(baseURL + endpoint, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.data.next === null) {
          setEnd(true);
        } else {
          setNewURL(res.data.data.next.slice(0, 20));
          setNewEndPoint(res.data.data.next.slice(20));
        }

        let data = [...jobData, ...res.data.data.results];
        setJobData(data);
        setLoading(false);
        setLoadMore(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoadMore(false);
        throw err;
      });
  };

  return (
    <Box style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <TrackPane
          profiles={allJobProfiles}
          jobProfile={jobProfile}
          jobTypes={jobType}
          selectedJobType={selectedJobType}
          setSelectedJobType={setSelectedJobType}
          setJobProfile={setJobProfile}
          allCompanies={allCompanies}
          setSelectedCompany={setSelectedCompany}
          setJobProfileObject={setJobProfileObject}
          JobProfileObject={jobProfileObject}
          searching={searching}
          setSearching={setSearching}
          buttonClickSearch={buttonClickSearch}
          setButtonClickSearch={setButtonClickSearch}
          setJobData={setJobData}
          setEnd={setEnd}
          setNewURL={setNewURL}
          setNewEndPoint={setNewEndPoint}
          path="OPEN"
        />
      </div>
      <div className={classes.cardsCont} onScroll={handleScroll} style={{ height: filteredJobData.length > 9 ? "100vh" : "auto" }}>
        {buttonClickSearch !== "" && (
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                marginLeft: 50,
                marginTop: 10,
              }}
            >
              You searched for "{buttonClickSearch}"...
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                // setSearching("");
                setButtonClickSearch("");
                setJobData([]);
                setEnd(false);
                setNewEndPoint("");
                setNewURL("");
              }}
              style={{ borderRadius: 30 }}
            >
              Clear your Search
            </Button>
          </Box>
        )}
        {mainData.length > 0 ? (
          <NewJobCard data={filteredJobData.length !== 0 ? filteredJobData : jobData} interview={false} reject={false} setJobData={setJobData} isEditable={true} />
        ) : loading ? (
          <Box padding="2% 2%">
            <JobCardSkeleton />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <img src={oj} alt="" width="50%" height="auto" />

            <Typography variant="h4">No Jobs available</Typography>
          </Box>
        )}

        {end !== true && !loading ? (
          <Box display="flex" justifyContent="center" margin="auto" marginTop="-20px" marginBottom="30px">
            <CommonButton variant="outlined" onClick={() => newData(newURL, newEndPoint)}>
              Load More Opportunities
            </CommonButton>
          </Box>
        ) : (
          !end && loadMore && (
            <Box padding="2% 2%">
              <JobCardSkeleton />
            </Box>
          )
        )}
      </div>
    </Box>
  );
}

import {
  AppBar,
  Box,
  Chip,
  Tab,
  Tabs,
  Tooltip,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import pSBC from "shade-blend-color";
import { Link, useHistory } from "react-router-dom";
import DummyTableOnly from "../Table/DummyTableOnly";
import {
  columnsOpenJobs,
  columnScheduledJobs,
  columnsClosedJobs,
  columnsScheduledJobs,
  columnsDraftedJobs,
} from "./openJobColumn";
import ManageJobActionBar from "../ActionBar/ManageJobActionBar";
import DeleteJobDialog from "../../Dialog/DeleteJobDialog/index";
import AddTeamMember from "../../Dialog/AddTeamMember/index";
import ChangeJobStatus from "../../Dialog/ChangeJobStatus";
import CommonTable from "../Table/CommonTable";
import { useAlert } from "react-alert";
import fetchApi from "../../../apiCall/fetchApi";
import { BackendBaseApi } from "../../../../constants/constants";

let cgreen = "#0B8A00";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tabpanel-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
  rate: {
    backgroundColor: pSBC(0.8, cgreen),
    color: cgreen,
    borderColor: cgreen,
    border: "1px solid",
  },
  btn: {
    borderRadius: "30px",
    marginRight: "10px",
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    color: theme.palette.primary.main,
    fontSize: "14px",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  scrollButtons: {
    "&.Mui-disabled": {
      opacity: 0.6,
    },
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "14px",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function JobManageTabs({
  navbarData,
  value,
  setValue,
  activeStatusObject,
  setActiveStatusObject,
  jobData,
  totalJobList,
  setTotalJobList,
  loading,
  errorJobData,
  pageData,
  setPageData,
  paginationResponse,
  selectedRow,
  setSelectedRow,
  filters,
  setFilters,
  clickSearchButton,
  setClickSearchButton,
  setApplyClickedCTC,
  setApplyClickedStipend,
  callBackMainApis,
}) {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const [rowData, setRowData] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRowData([]);
    var selectedData = null;
    Object.keys(navbarData).forEach((item, index) => {
      if (index === newValue) selectedData = navbarData[item];
    });
    setActiveStatusObject(selectedData);
  };
  useEffect(() => {
    setRowData([]);
  }, [pageData]);
  const [open, setOpen] = React.useState(false);

  const [maxWidth, setMaxWidth] = React.useState("xs");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => setShowResults(true);

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });

  const [loadingDraft, setLoadingDraft] = useState(false);
  const handleSubmitDraftToOpen = () => {
    var data = {
      company_id: JSON.parse(localStorage.getItem("company")).company,
      job_ids: selectedRow?.selectedIds,
      status_choices: "OPEN",
    };

    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/job_status_change/`,
      method: "post",
      alert: alert,
      body: JSON.stringify(data),
      setLoading: setLoadingDraft,
      callBackFn: callBackMainApis,
    });
  };

  const checkJobColumns = (type) => {
    switch (type) {
      case "Open Jobs":
        return columnsOpenJobs;
      case "Closed Jobs":
        return columnsClosedJobs;
      case "Scheduled Jobs":
        return columnsScheduledJobs;
      case "Drafted Jobs":
        return columnsDraftedJobs;
      default:
        return columnsOpenJobs;
    }
  };

  useEffect(() => {
    if (jobData?.data?.results) {
      setRowData(jobData?.data?.results);
    }
  }, [jobData?.data?.results]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <NewTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="ant example"
        >
          {navbarData &&
            Object.keys(navbarData)?.map((item, index) => (
              <NewTab
                label={
                  <Tooltip title={navbarData[item]?.tooltip} placement="right">
                    <span>{`${navbarData[item].heading} (${navbarData[item].count})`}</span>
                  </Tooltip>
                }
                {...a11yProps(index)}
              />
            ))}
        </NewTabs>
      </AppBar>

      <br />
      <ManageJobActionBar
        filters={filters}
        setFilters={setFilters}
        clickSearchButton={clickSearchButton}
        setClickSearchButton={setClickSearchButton}
        setApplyClickedCTC={setApplyClickedCTC}
        setApplyClickedStipend={setApplyClickedStipend}
      />

      {navbarData &&
        Object.keys(navbarData)?.map((item, idx) => (
          <TabPanel value={value} index={idx} key={item}>
            {selectedRow?.selectedIds?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  marginTop: "15px",
                  //marginBottom: "15px",
                }}
              >
                {navbarData[item].heading !== "Closed Jobs" &&
                  selectedRow?.selectedIds?.length === 1 && (
                    <Button
                      color="primary"
                      className={classes.btn}
                      variant="contained"
                      onClick={() => {
                        if (selectedRow?.details[0]?.is_editable)
                          history.push({
                            pathname:
                              "/company/post-job/" +
                              selectedRow?.selectedIds[0],
                            flag: 1976,
                          });
                        else
                          alert.error(
                            "Applicant has already applied to this job. Can't edit this job"
                          );
                      }}
                    >
                      Edit
                    </Button>
                  )}
                {(navbarData[item].heading === "Open Jobs" ||
                  navbarData[item].haeding === "Scheduled Jobs") &&
                  selectedRow?.selectedIds?.length === 1 && (
                    <Button
                      color="primary"
                      className={classes.btn}
                      variant="contained"
                      onClick={() => {
                        history.push({
                          pathname:
                            "/company/post-job/" + selectedRow?.selectedIds[0],
                          flag: 1979,
                        });
                      }}
                    >
                      Clone Job
                    </Button>
                  )}

                <DeleteJobDialog
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  callBackMainApis={callBackMainApis}
                />
                {/* {navbarData[item].heading !== "Closed Jobs" && <AddTeamMember />} */}

                {(navbarData[item].heading === "Open Jobs" ||
                  navbarData[item].heading === "Closed Jobs") && (
                  <ChangeJobStatus
                    type={
                      navbarData[item].heading === "Open Jobs"
                        ? "open"
                        : "closed"
                    }
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    callBackMainApis={callBackMainApis}
                  />
                )}
                {navbarData[item].heading === "Drafted Jobs" && (
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    className={classes.btn}
                    onClick={() => {
                      if (!loadingDraft) handleSubmitDraftToOpen();
                    }}
                  >
                    {loadingDraft ? (
                      <CircularProgress size={16} style={{ color: "#fff" }} />
                    ) : (
                      "Publish"
                    )}{" "}
                  </Button>
                )}
              </div>
            )}

            <br />
            <CommonTable
              columns={checkJobColumns(navbarData[item].heading)}
              rowData={rowData}
              loading={loading}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              applicantDataError={errorJobData}
              pageData={pageData}
              setPageData={setPageData}
              totalDataCount={paginationResponse?.totalDataCount}
              fromManageJobs={true}
            />
          </TabPanel>
        ))}
    </div>
  );
}

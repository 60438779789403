import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import EducationForm from "./Forms/EducationForm";
import ProjectForm from "./Forms/ProjectsForm";
import SkillsForm from "./Forms/SkillsForm";
import WorkExperienceForm from "./Forms/WorkExperienceForm";

const FormModal = ({
  appendToParentState,
  formState,
  detailType,
  formDetails,
  showForm,
  setShowForm,
}) => {
  const disableSubmitButton = useRef();
  const toggle = () => setShowForm(!showForm);
  const handleAdd = () => {
    appendToParentState();
    toggle();
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={showForm}
        onClose={toggle}
        maxWidth="md"
        classes={{ paperScrollPaper: classes.dialogPaper }}
      >
        <DialogTitle toggle={toggle}>{formDetails.Title}</DialogTitle>
        <DialogContent>{formDetails.FormComponent}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleAdd}>
            {formDetails.ButtonText}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      width: "100%",
      height: "50%",
    },
  })
);

const AddDetails = ({
  appendToParentState,
  formState,
  setFormState,
  detailType,
  showForm,
  setShowForm,
}) => {
  const [formDetails, setFormDetails] = useState({
    Title: "",
    Type: detailType,
    FormComponent: null,
    ButtonText: "",
  });
  useEffect(() => {
    switch (detailType) {
      case "ADD_WORKEX":
        setFormDetails({
          ...formDetails,
          Title: "Add New Work Experience",
          FormComponent: (
            <WorkExperienceForm
              type={detailType}
              newWorkEx={formState}
              setNewWorkEx={setFormState}
            />
          ),
          ButtonText: "Add Experience",
        });

        break;
      case "EDIT_WORKEX":
        setFormDetails({
          ...formDetails,
          Title: "Edit Work Experience",
          FormComponent: (
            <WorkExperienceForm
              type={detailType}
              newWorkEx={formState}
              setNewWorkEx={setFormState}
            />
          ),
          ButtonText: "Save Changes",
        });

        break;

      case "ADD_TECHNICAL_SKILL":
        setFormDetails({
          ...formDetails,
          Title: "Add New Technical Skill",
          FormComponent: (
            <SkillsForm
              type={"Technical Skills"}
              newSkill={formState}
              setNewSkill={setFormState}
            />
          ),
          ButtonText: "Add Skill",
        });

        break;
      case "ADD_INTERPERSONAL_SKILL":
        setFormDetails({
          ...formDetails,
          Title: "Add New Interpersonal Skill",
          FormComponent: (
            <SkillsForm
              type={"Interpersonal Skills"}
              newSkill={formState}
              setNewSkill={setFormState}
            />
          ),
          ButtonText: "Add Skill",
        });

        break;
      case "ADD_PROJECT":
        setFormDetails({
          ...formDetails,
          Title: "Add New Gig/Project",
          FormComponent: (
            <ProjectForm
              type={detailType}
              newProject={formState}
              setNewProject={setFormState}
            />
          ),
          ButtonText: "Add Project",
        });

        break;
      case "EDIT_PROJECT":
        setFormDetails({
          ...formDetails,
          Title: "Edit Project",
          FormComponent: (
            <ProjectForm
              type={detailType}
              newProject={formState}
              setNewProject={setFormState}
            />
          ),
          ButtonText: "Save Changes",
        });

        break;
      case "ADD_EDUCATION":
        setFormDetails({
          ...formDetails,
          Title: "Add Education Details",
          FormComponent: <EducationForm />,
          ButtonText: "Add Education",
        });

        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <FormModal
        appendToParentState={appendToParentState}
        formState={formState}
        type={detailType}
        formDetails={formDetails}
        showForm={showForm}
        setShowForm={setShowForm}
      />
    </>
  );
};

export default AddDetails;

import React, { useEffect, useState } from "react";
import user from "../../../../assets/common/subuser.jpg";
import { Typography, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: "50%",
    marginBottom: "20px",
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
  },
  userinfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  fontStyling: {
    fontFamily: "gilRoy",
    fontStyle: "normal",
    fontVariant: "normal",
    textTransform: "none",
  },
}));

function UserInfo({ data }) {
  const classes = useStyles();
  const [filterDetails, setFilterDetails] = useState();
  useEffect(() => {
    var filters = data?.student_user_details?.education.filter(
      (item) => item.is_current === true
    );
    setFilterDetails(filters);
  }, [data]);

  return (
    <>
      <div className={classes.userinfo}>
        <img
          src={data?.profile_picture ? data?.profile_picture : user}
          height="150px"
          width="150px"
          className={classes.img}
          alt="demo"
        />

        <Typography variant="h4" className={classes.fontStyling}>
          {data?.first_name + " " + data?.last_name}
        </Typography>

        {filterDetails?.length > 0 && filterDetails[0].board && (
          <Typography
            variant="h6"
            className={classes.fontStyling}
            style={{ textAlign: "center" }}
          >
            {filterDetails[0]?.board_name}
          </Typography>
        )}
        {filterDetails?.length > 0 && filterDetails[0].degree && (
          <Typography
            variant="h6"
            className={classes.fontStyling}
            style={{ textAlign: "center" }}
          >
            {filterDetails[0]?.degree}{" "}
            {" in " + filterDetails[0]?.specialization}
          </Typography>
        )}
        {filterDetails?.length > 0 && filterDetails[0].college_name && (
          <Typography variant="h6" className={classes.fontStyling}>
            {filterDetails[0].degree && "at"} {filterDetails[0].college_name}
          </Typography>
        )}
      </div>
    </>
  );
}

export default UserInfo;

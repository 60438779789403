import { Button, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
/* import AllPlanDialog from "../../Dialog/AllPlanDialog"; */

const TempPlan = ({ fromOverview = false }) => {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const profileUnlockInfo = useSelector(
    (state) => state?.CreditInfo?.profileUnlockInfo
  );

  /*   var today = new Date();
  var date =
    today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  var currentDate = moment(date, "DD/MM/YYYY");
  const nextBillingDate = moment(
    profileUnlockInfo?.next_billing_date,
    "DD/MM/YYYY"
  );
  const daysRemaining = nextBillingDate.diff(currentDate, "days"); */

  return (
    <>
      {
        show && fromOverview ? (
          <Paper
            style={{
              backgroundColor:
                profileUnlockInfo?.available_credits === 0 ||
                profileUnlockInfo?.days_remaining === 0
                  ? "#FFC0C2"
                  : "#FFEFD0",
              borderRadius: "15px",
              height: "130px",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "20px",
              margin: "20px 0",
              position: "relative",
              width: "100%",
            }}
          >
            {fromOverview && (
              <Button
                style={{
                  position: "absolute",
                  backgroundColor: "inherit",
                  top: "5%",
                  right: "2px",
                }}
                onClick={() => {
                  sessionStorage.setItem("show", "false");
                  setShow(false);
                }}
              >
                X
              </Button>
            )}

            <Typography
              variant="h5"
              style={{
                color:
                  profileUnlockInfo?.available_credits === 0 ||
                  profileUnlockInfo?.days_remaining === 0
                    ? "#C4161C"
                    : "#C58D00",
                marginBottom: "10px",
              }}
            >
              {/*  ? `Your Plan expires in ${daysRemaining} days`
                :   ? "Your Plan has been Expired!" daysRemaining <= 140
                ? `Your Plan expires in ${daysRemaining} days`
                : profileUnlockInfo?.available_credits <= 10
                ? `You have ${profileUnlockInfo?.available_credits} profile Unlocks Available`
                : "Your Plan is Expiring Soon!"}  */}

              {profileUnlockInfo?.available_credits === 0 ||
              profileUnlockInfo?.days_remaining === 0
                ? "Your Plan Has Been Expired"
                : profileUnlockInfo?.days_remaining <= 10 ||
                  profileUnlockInfo?.available_credits <= 10
                ? "Your Plan Will Expire Soon"
                : ""}
            </Typography>

            <Typography variant="body2" style={{ fontWeight: "400" }}>
              {profileUnlockInfo?.days_remaining === 0
                ? `${profileUnlockInfo?.expired_message}. Contact to Team GetWork to keep enjoying the benefits of GetWork ATS & Profile Unlocks services.`
                : profileUnlockInfo?.available_credits === 0
                ? "You have burned all your profile unlocks”. Contact to Team GetWork to keep enjoying the benefits of GetWork ATS & Profile Unlocks services."
                : profileUnlockInfo?.available_credits <= 10 ||
                  profileUnlockInfo?.days_remaining <= 10
                ? `Your Available profile unlocks ${profileUnlockInfo?.available_credits} will expire in ${profileUnlockInfo?.days_remaining} days. Contact to Team GetWork to keep enjoying the benefits of GetWork ATS & Profile Unlocks services.`
                : ""}
            </Typography>
            {/*  <div style={{ display: "flex", marginTop: "40px" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#EBB241",
              borderRadius: "30px",
              boxShadow: "none",
              height: "40px",
              display: "flex",
              color: "#fff",
            }}
          >
            Renew Plan
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#EBB241",
              borderRadius: "30px",
              boxShadow: "none",
              height: "40px",
              display: "flex",
              color: "#fff",
              marginLeft: "20px",
            }}
            onClick={handleClickOpen}
          >
            Change Plan
          </Button>
        </div> */}
          </Paper>
        ) : (
          show && (
            <Paper
              style={{
                backgroundColor:
                  profileUnlockInfo?.available_credits === 0 ||
                  profileUnlockInfo?.days_remaining === 0
                    ? "#FFC0C2"
                    : "#FFEFD0",
                borderRadius: "15px",
                height: "130px",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "20px",
                margin: "20px 0",
                position: "relative",
                width: "100%",
              }}
            >
              {fromOverview && (
                <Button
                  style={{
                    position: "absolute",
                    backgroundColor: "inherit",
                    top: "5%",
                    right: "2px",
                  }}
                  onClick={() => {
                    sessionStorage.setItem("show", "false");
                    setShow(false);
                  }}
                >
                  X
                </Button>
              )}

              <Typography
                variant="h5"
                style={{
                  color:
                    profileUnlockInfo?.available_credits === 0 ||
                    profileUnlockInfo?.days_remaining === 0
                      ? "#C4161C"
                      : "#C58D00",
                  marginBottom: "10px",
                }}
              >
                {/*  ? `Your Plan expires in ${daysRemaining} days`
            :   ? "Your Plan has been Expired!" daysRemaining <= 140
            ? `Your Plan expires in ${daysRemaining} days`
            : profileUnlockInfo?.available_credits <= 10
            ? `You have ${profileUnlockInfo?.available_credits} profile Unlocks Available`
            : "Your Plan is Expiring Soon!"}  */}

                {profileUnlockInfo?.available_credits === 0 ||
                profileUnlockInfo?.days_remaining === 0
                  ? "Your Plan have expired"
                  : profileUnlockInfo?.days_remaining <= 10 ||
                    profileUnlockInfo?.available_credits <= 10
                  ? "Your Plan expires soon"
                  : ""}
              </Typography>

              <Typography variant="body2" style={{ fontWeight: "400" }}>
                {profileUnlockInfo?.days_remaining === 0
                  ? `${profileUnlockInfo?.expired_message}. Contact to Team GetWork to keep enjoying the benefits of GetWork ATS & Profile Unlocks services.`
                  : profileUnlockInfo?.available_credits === 0
                  ? "You have burned all your profile unlocks. Contact to Team GetWork to keep enjoying the benefits of GetWork ATS & Profile Unlocks services."
                  : profileUnlockInfo?.available_credits <= 10 ||
                    profileUnlockInfo?.days_remaining <= 10
                  ? `Your Available profile unlocks ${profileUnlockInfo?.available_credits} will expire in ${profileUnlockInfo?.days_remaining} days. Contact to Team GetWork to keep enjoying the benefits of GetWork ATS & Profile Unlocks services.`
                  : ""}
              </Typography>
              {/*  <div style={{ display: "flex", marginTop: "40px" }}>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#EBB241",
          borderRadius: "30px",
          boxShadow: "none",
          height: "40px",
          display: "flex",
          color: "#fff",
        }}
      >
        Renew Plan
      </Button>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#EBB241",
          borderRadius: "30px",
          boxShadow: "none",
          height: "40px",
          display: "flex",
          color: "#fff",
          marginLeft: "20px",
        }}
        onClick={handleClickOpen}
      >
        Change Plan
      </Button>
    </div> */}
            </Paper>
          )
        )
        /*   <AllPlanDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      /> */
      }
    </>
  );
};

export default TempPlan;

import { Button, Chip, CircularProgress, createStyles, Dialog, makeStyles, Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useLocation } from "react-router-dom";
import upload from "../../../assets/images/assignmentUpload.png";
import upload1 from "../../../assets/images/upload_icon.png";
import CompanyLogo from "../../../components/common/CompanyLogo/CompanyLogo";
import JobSharerButton from "../../../components/common/JobSharer/JobSharerButton";
import { BackendBaseApi } from "../../../constants/constants";
import kFormater, { LFormatter } from "../../../utils/ZeroToK";
import ViewJob2 from "../viewJob/viewJob2";
import "./newjobcard.scss";
import { useSelector } from "react-redux";
import { GetMentoredButton } from "../../../components/student/Mentorship/GetMentoredButtton";

const useStyles = makeStyles((theme) =>
  createStyles({
    fileBox: {
      padding: "5%",
      backgroundColor: "#FFF",
      borderRadius: "4px",
      border: "1px solid black",
      width: "100%",
    },
    modal1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    paper1: {
      width: "50%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    greyBox: {
      width: "50%",
      backgroundColor: "#E0E0E0",
      padding: "20px",
      borderRadius: "4px",
      textAlign: "left",
    },
    textLeft: {
      textAlign: "left",
    },
    outerDottedBox: {
      border: "2px dotted black",
      padding: "20%",
      width: "100%",
      borderRadius: "6px",
      backgroundColor: "#F2F2F2",
      textAlign: "center",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      padding: "10px 20px 10px 20px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    hiringTypeChip: {
      fontWeight: "bolder",
      // border: "1px solid #3282C4",
      // color: "#3282C4",
      fontSize: "12px",
      marginRight: "14px",
      
    },
    cardTitleBox: {
      width: "96%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    actionButton: {
      color: "#ffffff",
      fontWeight: "700",
      borderRadius:"30px"
    },
    rejectButton: {
      border: "1px solid #3282C4",
      color: "#3282C4",
      marginRight: "10px",
    },
    disableButton: {
      backgroundColor: "#CDEDF6",
      color: "#000000 !important",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
    },
    circularLoaderParent:{
      position: "fixed",
      top: "0",
      left:"0",
      zIndex: "3",
      backgroundColor: "rgba(0,0,0,0.1)",
      width: "100vw",
      height: "100vh",
    },
    circularLoader: {
      position: "relative",
      top: "50%",
      left: "100%",
      transform: "translate(-50%, -50%)",
    },
    jobSharerButton: {
      display: "flex",
    },
    jobDetailApply: {},
    "@media screen and (max-width: 768px)": {
      cardTitleBox: {
        flexDirection: "column",
        alignItems: "baseline",
      },
      hiringTypeChip: {
        fontSize: "10px",
        height: "20px",
      },
      actionButton: {
        height: "28px",
        width: "40px",
        fontSize: "12px",
        float: "right",
      },
      rejectButton: {
        height: "28px",
        width: "40px",
        fontSize: "12px",
        marginBottom: "6px",
        float: "right",
        marginRight: "0px",
      },
      buttonBox: {
        flexDirection: "column",
      },
      jobDetailApply: {
        flexDirection: "column",
        display: "flex",
        borderRadius:"15px"
      },
    },
  })
);

const NewJobCard = (props) => {
  const { data, applied, interview, reject, fullView, all, hideStatus, saveJob, invite, GetData, setJobData } = props;

  const [appliedList, setAppliedList] = useState([false, false, false]);
  const [inviteList, setInviteList] = useState([]);
  const [rejectList, setRejectList] = useState([]);

  const [currentIndex, setCurrentIndex] = useState();
  const classes = useStyles();

  const [open, handleClose] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [isReject, setIsReject] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [singleJobData, setSingleJobData] = useState(null);
  const [loaderSingleJob, setLoaderSingleJob] = useState(false);
  const [jobId, setJobId] = useState(null);
  const alert = useAlert();
  const token = localStorage.getItem("gw_token");
  const loc = useLocation().pathname;
  let show = 0;
  if (loc == "/student/dashboard") {
    if (data.length > 3) {
      data = data.slice(0, 3);
      show = 1;
    }
  }
  const openJob = (e, item, index, id) => {
    e.stopPropagation();
    setSelectedCard(index);
    setJobDetails(item);
    setCurrentIndex(index);
    setJobId(id);
    handleClose(true);
  };
  const callback = (value) => {
    handleClose(value);
    // do something with value in parent component, like save to state
  };

  useEffect(() => {
    if (jobId) {
      setLoaderSingleJob(true);
      let jobUrl = BackendBaseApi.NIYUKTI + `job/?job_id=${btoa(jobId?.toString())}`;
      Axios.get(jobUrl, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      })
        .then((res) => {
          if (res.data.success) {
            setSingleJobData(res.data.data);
            setLoaderSingleJob(false);
          } else {
          }
          setLoaderSingleJob(false);
        })
        .catch((err) => {
          alert.error("Error");
          setLoaderSingleJob(false);
        });
    }

  },[jobId])

  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      {loaderSingleJob ?
        <div className={classes.circularLoaderParent} >
          <div className={classes.circularLoader}> <CircularProgress size={60} /> </div>
        </div>
        : null
      }
      {/* <div className={classes.circularLoader} >
        {loaderSingleJob ? <CircularProgress size={80} /> : null}
      </div> */}
      {
        data.length ?
          data.map((item, index) => (
            <div className="new-job-card" key={item.job_id}>
              <div
                className="container"
                style={{
                  borderLeft: "5px solid",
                  borderRight:"0px",
                  borderBottom:"0px",
                  borderTop:"0px",
                  borderColor: domain?.domain ? domain?.color_code : "#007bff",
                  marginBottom: index === data.length - 1 ? "0px" : "1rem",
                  cursor: "pointer",
                  boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)",
                }}
                onClick={(e) => openJob(e, item, index, item.job_id)}
              >
                <div className="container__header">
                  <CompanyLogo companyWebsite={data?.company?.company_website} />
                  <div className="container__header__names">
                    <div className="container__header__role position-relative">
                      <Box className={classes.cardTitleBox}>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginBottom: "0px",
                          }}
                        >
                          {" "}
                          {item.job_title}
                        </p>
                        {/* {applied && (
                          <div
                            className="absolute top-0 fs-14 fw-700 text-green"
                            // style={{ right: "23px" }}
                          >
                            {item.applicant_status_name}
                          </div>
                        )} */}
                        <Box className={classes.onCampusBox} display="flex" alignItems="center">
                          <Chip
                            className={classes.hiringTypeChip}
                            label={item.is_posted_by_college === true || item.hiring_type === "ON CAMPUS" ? "ON CAMPUS HIRING" : "OFF CAMPUS HIRING"}
                            color="primary"
                            variant="outlined"
                            style={{color: domain?.domain ? domain?.color_code : "#007bff", borderColor: domain?.domain ? domain?.color_code : "#007bff"}}
                          />
                          {applied && (
                            <div
                              className="top-0 fs-14 fw-700 text-green"
                              // style={{ right: "23px" }}
                            >
                              {item.applicant_status === 7 ? item.applicant_status_name + " " + "(" + item?.round?.round_name + ")" : item.applicant_status_name}
                            </div>
                          )}
                        </Box>
                      </Box>

                      <ChevronRightIcon />
                    </div>
                    <div className="container__header__company">
                      <span className="container__header__cname"> {item.company ? item.company.company_name : item.company_details}</span>
                      <br />
                      <span className="container__header__location">
                        {item.job_location.length > 0 ? (
                          item.job_location.map((item1, i) => <>{i !== item.job_location.length - 1 ? <>{item1.city}, </> : <>{item1.city}</>}</>)
                        ) : (
                          <>Work from Home</>
                        )}
                        &nbsp;&middot;&nbsp;
                        {item.company ? item.company.company_size + " employees" : null}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={`container__body ${classes.jobDetailApply}`} style={{ alignItems: "center" }}>
                  <div className="container__body__info" style={{ width: "100%" }}>
                    <span className="fs-14">{item.job_type_name}</span>
                    {item.ctc_min === 0 && item.ctc_max === 0 ? null : (
                      <>
                        &nbsp;&middot;&nbsp;
                        <span className="orange fs-14">
                          {" "}
                          {item.ctc_min > 99999 ? LFormatter(item.ctc_min) : kFormater(item.ctc_min)} - {item.ctc_max > 99999 ? LFormatter(item.ctc_max) : kFormater(item.ctc_max)}{" "}
                          {item.salary_payment_type.toLowerCase()}
                        </span>
                      </>
                    )}
                    &nbsp;&middot;&nbsp;
                    <span className="fs-14"> {item.equity_min === 0 && item.equity_max === 0 ? "No equity" : `${item.equity_min} % - ${item.equity_max} %`}</span>
                  </div>
                  <div className={`container__body__info ${classes.jobSharerButton}`}>
                    {/* <JobSharerButton
                      detail={{
                        job_id: item.job_id,
                        company_name: item.company ? item.company.company_name : item.company_details,
                        job_title: item.job_title,
                        job_type: item.job_type_name,
                        job_role: item.job_role_name,
                      }}
                      page="RECOMMENDED"
                    /> */}
                   <GetMentoredButton jobRole={item?.job_role_name}/>
                    {interview && appliedList && (
                      <>
                        {" "}
                        <ApplyButton
                          isAccepted={item.is_accepted}
                          jobId={item.job_id}
                          interviewId={item.id}
                          reject={reject}
                          index={index}
                          appliedList={appliedList}
                          setAppliedList={setAppliedList}
                          invite={invite}
                          collegeId={JSON.parse(localStorage.getItem("user_details")).student_user_details.education.find((item) => item.is_current === true)?.college}
                        />
                      </>
                    )}

                    {invite ? (
                      item.is_accepted === false ? (
                        <>
                          {item.interview ? (
                            <RejectAccept
                              interviewId={item.interview.interview_id}
                              jobId={item.job_id}
                              reject={reject}
                              inviteList={inviteList}
                              setInviteList={setInviteList}
                              index={index}
                              rejectList={rejectList}
                              setRejectList={setRejectList}
                              isAccepted={false}
                            />
                          ) : (
                            <>
                              <div className="fs-16 text-black-50">invited for interview, details will be posted soon</div>
                              {/* <RejectAccept
                                interviewId={item.interview[0]?.interview_id}
                                jobId={item.job_id}
                                reject={reject}
                              /> */}
                            </>
                          )}{" "}
                        </>
                      ) : null
                    ) : null}

                    {loc == "/student/OpenAssignment" ? <AssignmentButton /> : null}
                  </div>
                </div>
                <div className="container__footer flex justify-content-between">
                  <div className="w-70">
                    {loc !== "/student/OpenAssignment" && item?.interview ? (
                      <div className="container__body__info">
                        {item.interview?.location !== null && (
                          <>
                            {" "}
                            <span className="fs-12">{item.interview?.location?.city}</span>
                          </>
                        )}

                        <>
                          {item.interview?.hangout_link !== null && (
                            <>
                              {" "}
                              <p
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "8px",
                                  fontWeight: "bold",
                                }}
                              >
                                Interview Details
                              </p>
                              <a href={item.interview?.hangout_link} target="_blank" style={{ color: "#3282C4" }}>
                                <span style={{ fontSize: "15px" }}>{item.interview?.hangout_link}</span>
                              </a>
                              <br />{" "}
                            </>
                          )}
                          {item.interview?.address !== null && (
                            <>
                              <span className="fs-12">{item.interview?.address}</span>
                              <br />{" "}
                            </>
                          )}

                          {
                            <span className="orange fs-12">
                              {" "}
                              {moment(item.interview?.start_date).format("DD MMM, YYYY, h:mm a")} - {moment(item.interview?.end_date).format("DD MMM, YYYY, h:mm a")}{" "}
                            </span>
                          }
                        </>
                      </div>
                    ) : null}
                  </div>

                  <div className="w-30">
                    {all && (
                      <div className="flex flex-row justify-content-end" style={{ marginTop: "13px" }}>
                        {<SaveIcon jobId={item.job_id} isSave={item.is_saved} GetData={GetData} setJobData={setJobData} />}
                        {<HiddenIcon jobId={item.job_id} hideStatus={hideStatus}  />}
                      </div>
                    )}

                    {saveJob && (
                      <div className="flex flex-row justify-content-end">{<SaveIcon jobId={item.job_id} isSave={item.is_saved} GetData={GetData} setJobData={setJobData} />}</div>
                    )}
                  </div>
                </div>

                <div className="w-70">
                  {loc == "/student/OpenAssignment" ? (
                    <Box className={classes.greyBox}>
                      <Typography className={classes.textLeft} align="left" variant="body2">
                        <b>Title:</b>
                        <span style={{ color: "grey" }}>title</span>{" "}
                      </Typography>
                      <Typography align="left" variant="body2">
                        <b>Submission Date:</b> <span style={{ color: "grey" }}>23/11/2020</span>{" "}
                      </Typography>
                      <Typography align="left" variant="body2">
                        <b>Attachment:</b> <span style={{ color: "grey" }}>helloo</span>
                        <a href={null} download="GFG" target="blank">
                          &nbsp; <img src={upload} />
                        </a>
                      </Typography>
                    </Box>
                  ) : null}
                </div>
              </div>
              {show ? (
                <Link to="/student/open" className="connection_link">
                  View All Open Jobs <i class="fa fa-arrow-right"></i>
                </Link>
              ) : (
                ""
              )}
              {loaderSingleJob ? null :
                selectedCard === index && (
                <Dialog disableBackdropClick open={open} onClose={() => handleClose(false)} maxWidth="md" style={{scrollbarWidth:"none"}}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {singleJobData ?
                      <ViewJob2
                        data={singleJobData}
                        apply={interview}
                        open={fullView}
                        appliedList={appliedList}
                        setAppliedList={setAppliedList}
                        inviteList={inviteList}
                        setInviteList={setInviteList}
                        index={currentIndex}
                        handleClosey={callback}
                        invite={invite}
                        reject={reject}
                        rejectList={rejectList}
                        setRejectList={setRejectList}
                        fromApplied={true}
                      /> : <div className="text-gray fs-20 fw-700 mg-top-20">No Jobs</div>
                    }
                  </div>
                </Dialog>
              )}
            </div>
          ))
          : null
      }
    </>
    
  )
};

export default NewJobCard;

const ApplyButton = ({ jobId, reject, collegeId, index, appliedList, setAppliedList, invite, isAccepted }) => {
  const classes = useStyles();
  const [done, setDone] = useState(false);
  const alert = useAlert();
  const handelActive = (jobId) => {
    const data = JSON.parse(localStorage.getItem("user_details"));

    fetch(BackendBaseApi.NIYUKTI + "job/student/apply/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&round=1&status=1&feedback=1&college_id=${collegeId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert.success(data.data.message);
          changeIndex(index);
          setDone(true);
        } else {
          alert.error(data.error);
        }
      });
  };

  const changeIndex = (index2) => {
    let list = [...appliedList];
    list[index2] = true;
    setAppliedList(list);
  };
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <Button
      variant="contained"
      style={appliedList[index] === false ? { backgroundColor: domain?.domain ? domain?.color_code : "#007bff", } : { backgroundColor: "#717374" }}
      classes={{ disabled: classes.disableButton }}
      className={classes.actionButton}
      disabled={appliedList[index]}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
    >
      {reject ? (done || isAccepted ? "Accepted" : "Accept") : appliedList[index] ? "Applied" : "Apply"}
    </Button>
  );
};

const AssignmentButton = () => {
  const [done, setDone] = useState(false);
  const alert = useAlert();
  const classes = useStyles();
  const [fileDetails, setFileDetails] = useState();

  const [open2, setOpen2] = React.useState(false);
  const handleOpen1 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen2(false);
  };
  const getFileDetails = (e) => {
    setFileDetails(e.target.files[0]);
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal1}
          open={open2}
          onClose={handleClose1}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open2}>
            <div className={classes.paper1}>
              <Box className={classes.outerDottedBox}>
                <input
                  onChange={(e) => {
                    getFileDetails(e);
                  }}
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Box>
                    {fileDetails ? (
                      <>
                        <Box className={classes.fileBox}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Typography
                                align="center"
                                style={{
                                  justifyContent: "center",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                                variant="body1"
                              >
                                <b> {fileDetails.name}</b>
                                &nbsp;
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                onClick={() => {
                                  setFileDetails();
                                }}
                                aria-label="delete"
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                        <br />
                        <Button className={classes.postButton} variant="contained" component="span">
                          Upload File
                        </Button>
                      </>
                    ) : (
                      <>
                        <img
                          src={upload1}
                          style={{
                            alignItems: "center",
                            contentAlign: "center",
                            float: "center",
                            display: "flex",
                            margin: "auto",
                          }}
                        />
                        <br />
                        <Typography align="center" variant="h4">
                          <b>Click here to Select your File to Upload</b>
                        </Typography>
                      </>
                    )}
                  </Box>
                  &nbsp;
                </label>
                <br />
                <br />
              </Box>
            </div>
          </Fade>
        </Modal>
      </div>

      <Button variant="contained" style={{ backgroundColor: "#3282C4" }} className="text-white fw-700" onClick={handleOpen1}>
        Send Invite Link
      </Button>
    </>
  );
};

const RejectButton = ({ jobId, isReject, interviewId, index, rejectList, setRejectList }) => {
  const classes = useStyles();
  const alert = useAlert();
  const data = JSON.parse(localStorage.getItem("user_details"));

  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    rejectList[index] = true;
    setRejectList(rejectList);
    fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&status=false&interview_id=${interviewId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert.success("Invite Rejected");

        setDone(true);
      });
  };
  return (
    !isReject && (
      <Button
        variant="outlined"
        color="primary"
        className={classes.rejectButton}
        disabled={done}
        onClick={(event) => {
          event.stopPropagation();
          handelActive(jobId);
        }}
      >
        {rejectList[index] ? "Rejected" : "Reject"}
      </Button>
    )
  );
};

const AcceptButton = ({ jobId, reject, setIsReject, interviewId, inviteList, setInviteList, index, rejectList, setRejectList, isAccepted }) => {
  const classes = useStyles();
  const alert = useAlert();
  const data = JSON.parse(localStorage.getItem("user_details"));

  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    // inviteList[index] = true;
    // setInviteList(inviteList);

    fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&status=true&interview_id=${interviewId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert.success("Invite Accepted");

        setDone(true);
        setIsReject(true);
        changeIndex(index);
      });
  };

  const changeIndex = (index) => {
    let list = [...inviteList];
    list[index] = true;
    setInviteList(list);
  };

  return (
    <Button
      variant="contained"
      style={{ backgroundColor: "#3282C4" }}
      className={classes.actionButton}
      disabled={inviteList[index]}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
    >
      {done || isAccepted ? "Accepted" : "Accept"}
    </Button>
  );
};

const SaveIcon = ({ jobId, updateData, isSave, GetData, setJobData }) => {
  const alert = useAlert();
  const data = JSON.parse(localStorage.getItem("user_details"));
  const [done, setDone] = useState(isSave);
  const token = localStorage.getItem("gw_token");
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  const handelActive = (jobId) => {
    if (done) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=unsave`,
      }).then((res) => {
        res.json().then((data) => {
          alert.success(data.data.message);
          setDone(!done);
          // updateData(jobId)
        });
        // GetData(
        //   baseUrl.niyukti.BASE_URL,
        //   `job/student/job_details/?user_id=${window.btoa(
        //     data.id
        //   )}&status=c2F2ZWQ=`,
        //   { headers: token },
        //   setJobData,
        //   true
        // );
      });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=saved`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert.success(data.data.message);
          setDone(!done);
        });
    }
  };
  return (
    <>
      {done ? (
        <>
          {" "}
          <div className="flex align-item-center">
            {" "}
            <BookmarkIcon
            style={{color: domain?.domain ? domain?.color_code : "#007bff",}} 
              className="pointer text-blue mg-right-5"
              onClick={(event) => {
                event.stopPropagation();
                handelActive(jobId);
              }}
            />
            <span style={{color: domain?.domain ? domain?.color_code : "#007bff"}}  className="fs-12 text-blue mr-2">Saved</span>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="flex align-item-center">
            {" "}
            <BookmarkBorderIcon
            style={{color: domain?.domain ? domain?.color_code : "#007bff",}} 
              className="pointer text-blue mg-right-5"
              onClick={(event) => {
                event.stopPropagation();
                handelActive(jobId);
              }}
            />
            <span className="fs-12 text-blue mr-2" style={{color: domain?.domain ? domain?.color_code : "#007bff",}}  >Save</span>
          </div>
        </>
      )}
    </>
  );
};
const HiddenIcon = ({ jobId, hideStatus }) => {
  const alert = useAlert();
  const [done, setDone] = useState(hideStatus);
  const handelActive = (jobId) => {
    const data = JSON.parse(localStorage.getItem("user_details"));

    if (done) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=unhide`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert.success(data.data.message);
          setDone(false);
        });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=hidden`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert.success(data.data.message);
          setDone(true);
        });
    }
  };

  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  return (
    <>
      {done ? (
        <>
          <div className="flex align-item-center">
            <VisibilityOffIcon
            style={{color: domain?.domain ? domain?.color_code : "#007bff",}}
              className="pointer text-blue mg-right-5"
              onClick={(event) => {
                event.stopPropagation();
                handelActive(jobId);
              }}
            />
            <span className="fs-12 text-blue mr-2">Hidden</span>
          </div>{" "}
        </>
      ) : (
        <>
          <div className="flex align-item-center">
            <VisibilityIcon
             style={{color: domain?.domain ? domain?.color_code : "#007bff",}}
              className="pointer text-blue mg-right-5"
              onClick={(event) => {
                event.stopPropagation();
                handelActive(jobId);
              }}
            />
            <span className="fs-12 text-blue mr-2"     style={{color: domain?.domain ? domain?.color_code : "#007bff",}} > Hide</span>
          </div>
        </>
      )}
    </>
  );
};

const RejectAccept = ({ jobId, reject, interviewId, inviteList, setInviteList, index, rejectList, setRejectList, isAccepted }) => {
  const alert = useAlert();
  const classes = useStyles();
  const [isReject, setIsReject] = useState(false);
  return (
    <div className={classes.buttonBox}>
      {!isReject && <RejectButton jobId={jobId} reject={reject} interviewId={interviewId} rejectList={rejectList} setRejectList={setRejectList} index={index} />}
      <AcceptButton
        setIsReject={setIsReject}
        interviewId={interviewId}
        reject={reject}
        jobId={jobId}
        inviteList={inviteList}
        setInviteList={setInviteList}
        index={index}
        rejectList={rejectList}
        setRejectList={setRejectList}
        isAccepted={isAccepted}
      />
    </div>
  );
};

import { Grid, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/common/httpRequest";
import ConnectionCard from "./ConnectionCard";
import Layout from "../Layout/Layout";
import Loader from "../../common/UI/Loader";


export default function Connection() {
  const [connectionData, setConnectionData] = useState([]);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   const token = localStorage.getItem("gw_token");
  //   let user_type_id = localStorage.getItem("user_type_id");

  //   const data = JSON.parse(localStorage.getItem("user_details"));

  //   GetData(
  //     BackendBaseApi.PRAVESH,
  //     "api/shared/user_connections/?user_type=" + user_type_id,
  //     { headers: token },
  //     setConnectionData
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setLoading(true);

    let res = await httpRequest(baseUrl, endPoint, body);
    updateState(res.data.results);
    setLoading(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <Layout>
        {!loading ? (
          <Grid container style={{ marginTop: "54px" }}>
            <Grid xs={12} style={{ marginTop: "0px" }}>
              <div className="flex flex-col align-item-baseline">
                <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading-underline">
                  Connections
                </h2>
              </div>
              <ConnectionCard data={connectionData} />
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Loader />
          </Box>
        )}
      </Layout>
    </div>
  );
}

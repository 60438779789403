import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Divider,
  MuiThemeProvider,
  Chip,
  InputAdornment,
  IconButton,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import Filter_icon from "../../../assets/common/Filter2.png";
import SelectSearch from "react-select-search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CssTextField from "../../common/JobPostNew/FormComponents/CssTextField";
import theme from "../../common/JobPostNew/FormComponents/MuiOutlined";
import FilterGif from "../../../assets/images/filter_load.gif";
import no_filter from "../../../assets/common/no_filter.gif";

import generateCsv, { generateCsvWithoutSpacing } from "../../../common/generateCsv/generateCsv";
import CommonChip from "../../CommonChip/CommonChip";
import SearchIcon from "@material-ui/icons/Search";
import StyledRadio from "../../common/JobPostNew/FormComponents/CustomizedRadio";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
    backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
    width: 310,
  },
  filter_s: {
    marginBottom: "30px",
  },
  comboOptions: {
    fontSize: "13px",
    padding: "6.5px 11px",
  },

  autoComplete: {
    width: "100%",
    marginBottom: 10,
    borderRadius: theme.palette.type === "dark" && 30,
  },
  root1: {
    fontWeight: "bolder",
    border: "2px solid #082c5a",
    color: "#082c5a",
    backgroundColor: "#bde3ff",
    fontSize: 13,
  },
  deleteIcon: {
    color: "rgba(0, 0, 0, 0.26)",
  },
  "@media only screen and (max-width: 1500px)": {
    paper: {
      width: 280,
    },
  },
  "@media only screen and (max-width: 1400px)": {
    paper: {
      width: 230,
    },
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: 190,
    },
  },
}));

function Filter2(props) {
  const {
    data,
    setData,
    filterObject,
    setFilterObject,
    loader,
    setLoader,
    filterArray,
    setFilterArray,
    setSearchString,
    filterData,
    trackLoader,
    applicants,
    setEnd,
    setNextUrl,
    setTrackData,
    publicTrack,
    searchButton,
    setSearchButton,
    setSearchByName,
    searchByName,
  } = props;
  const classes = useStyles();
  const [currentSortValue, setCurrentSortValue] = useState(2);

  const handleDelete = (key, array, head) => {
    let sampleArr = [...array];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      var new_arr = [...filterArray];
      var arr = filterObject[head];

      var temp = filterObject;

      arr.splice(index, 1);
      new_arr.push(key);
      setFilterArray(new_arr);

      temp[head] = arr;
      setFilterObject(temp);
    }
  };

  useEffect(() => {
    let str = "";
    var count = 0;

    Object.keys(filterObject).forEach((head, index) =>
      Object.keys(filterObject).indexOf(head) !== Object.keys(filterObject).length - 1
        ? (str += filterObject[head].length > 0 ? head + "=" + generateCsvWithoutSpacing(filterObject[head], "id") + "&" : "")
        : (str += filterObject[head].length > 0 ? head + "=" + generateCsvWithoutSpacing(filterObject[head], "id") : "")
    );

    // Object.keys(filterObject).forEach((head, index) => {
    //   if (filterObject[head].length > 0) count = count + 1;
    // });
    // setAppliedFilterCount(count);
    if (applicants) {
      setEnd(false);
      setNextUrl(null);
      setTrackData([]);
    }
    setSearchString(str);
  }, [filterArray.length]);

  useEffect(() => {
    setCurrentSortValue(2);
  }, [loader]);

  return (
    <>
      <div
        style={
          applicants
            ? {
                position: "fixed",
                zIndex: "1",
                height: "90.1%",
                overflowY: "auto",
                top: "17.5%",
              }
            : publicTrack
            ? {
                position: "fixed",
                zIndex: "1",
                height: "93.5%",
                overflowY: "auto",
                width: "16%",
              }
            : {
                position: "fixed",
                zIndex: "1",
                height: "93.5%",
                overflowY: "auto",
              }
        }
      >
        <Paper
          className={classes.paper}
          style={
            applicants
              ? {
                  borderRadius: "30px",
                  justifyContent: "center",
                  padding: "30px",
                  height: "90.1%",
                  overflowY: "auto",
                }
              : publicTrack
              ? {
                  borderRadius: "30px",
                  justifyContent: "center",
                  padding: "30px",
                  height: "93.5%",
                  overflowY: "auto",
                  width: "99%",
                }
              : {
                  borderRadius: "30px",
                  justifyContent: "center",
                  padding: "30px",
                  height: "93.5%",
                  overflowY: "auto",
                }
          }
        >
          {loader ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 240,
              }}
            >
              <img src={FilterGif} alt="" height="120px" />
            </Box>
          ) : filterData ? (
            <>
              <div className={classes.filter_s}>
                <Typography variant="h5" gutterBottom style={{ fontSize: "14px" }}>
                  Applicant Filters
                </Typography>
                <Divider variant="middle" />
              </div>

              {Object.keys(data).map((item) => (
                <div className={classes.filter_s}>
                  {item === "sort_by" ? (
                    <>
                      <Typography variant="h5" gutterBottom style={{ fontSize: "12px" }}>
                        {data[item].heading}
                      </Typography>
                      <FormControl component="fieldset" row>
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          style={{ paddingLeft: 40 }}
                          row
                          value={currentSortValue}
                          onChange={(e) => {
                            setCurrentSortValue(Number(e.target.value));
                            var obj = { ...filterObject };
                            obj["sort_by"] = [{ id: Number(e.target.value), name: "xyz" }];
                            console.log(obj);
                            setFilterObject(obj);
                            var new_arr = [...filterArray];
                            new_arr.push(1);
                            setFilterArray(new_arr);
                          }}
                        >
                          {data[item].data?.map((item_in) => (
                            <FormControlLabel value={Number(item_in.id)} control={<StyledRadio />} label={<span style={{ fontSize: 12 }}>{item_in.name}</span>} />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </>
                  ) : item === "search" ? (
                    <>
                      <div>
                        <CssTextField
                          label={<span style={{ fontSize: 13 }}>Search by Name</span>}
                          id="standard-start-adornment"
                          variant="outlined"
                          // className={clsx(classes.margin, classes.textField)}
                          value={searchByName}
                          onChange={(e) => {
                            setSearchByName(e.target.value);
                          }}
                          onKeyPress={(data) => {
                            if (data.charCode === 13) {
                              setSearchButton(true);
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start" style={{ margin: 0 }}>
                                <IconButton
                                  style={{ padding: 0 }}
                                  onClick={() => {
                                    setSearchButton(true);
                                  }}
                                >
                                  <SearchIcon style={{ color: "grey" }} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      {searchByName && (
                        <>
                          <span
                            onClick={() => {
                              setSearchByName("");
                              setSearchButton(true);
                            }}
                            style={{ fontSize: 13, cursor: "pointer", color: "#007bff" }}
                          >
                            Clear Search
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {/* <Typography variant="h5" gutterBottom style={{ fontSize: "12px" }}>
                        {data[item].heading}
                      </Typography> */}
                      <Autocomplete
                        // value={currentJobSegment}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            var new_arr = [...filterArray];
                            var arr = filterObject[data[item].param_name];
                            var temp = filterObject;
                            var n_arr = [];
                            if (!data[item].is_single_select) {
                              arr.push(newValue);
                              new_arr.push(newValue.id);
                              setFilterArray(new_arr);
                              temp[data[item].param_name] = arr;
                              setFilterObject(temp);
                            } else {
                              n_arr.push(newValue);
                              new_arr.push(newValue.id);
                              setFilterArray(new_arr);
                              temp[data[item].param_name] = n_arr;
                              setFilterObject(temp);
                            }
                          }
                        }}
                        id="role"
                        options={data[item].data}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => {
                          return <span style={{ fontSize: 12 }}>{`${option.name}`}</span>;
                        }}
                        // style={{ width: "100%", marginBottom: 10 }}
                        className={classes.autoComplete}
                        renderInput={(params) => {
                          params.inputProps.className = classes.comboOptions;
                          return <CssTextField {...params} label={<span style={{ fontSize: 12 }}>{data[item].heading}</span>} variant="outlined" />;
                        }}
                      />
                      {filterObject &&
                        filterObject[data[item].param_name] &&
                        filterObject[data[item].param_name].length > 0 &&
                        filterObject[data[item].param_name].map((ind) => {
                          return (
                            <CommonChip
                              label={<span style={{ fontSize: 12 }}>{ind.name}</span>}
                              onDelete={() => {
                                handleDelete(ind, filterObject[data[item].param_name], data[item].param_name);
                              }}
                              // className={classes.root1}
                              // classes={{ deleteIcon: classes.deleteIcon }}
                              style={{
                                marginRight: "1%",
                                marginBottom: "1%",
                                maxWidth: "90%",
                              }}
                            />
                          );
                        })}
                    </>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div style={{ marginTop: 240 }}>
              <Box display="flex" justifyContent="center">
                <img src={no_filter} alt="" height="50px" />
              </Box>
              <div>
                <p align="center" style={{ fontSize: 12, color: "#6a6a6a" }}>
                  No Filter Present
                </p>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}

export default Filter2;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Form from "../Card/Form";
import { useParams } from "react-router-dom";
import RegisterMethods from "../Card/Form/RegisterMethods";
import RegisterForm from "../Card/Form/RegisterForm";
import RegisterFormCollege from "../Card/Form/RegisterFormCollege";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "16%",
    // width: 500,
  },
  tabs: {
    color: "#007bff",
    backgroundColor: "#fff",
    active_tab: {
      color: "red",
    },
  },
}));

export default function MobileTabRegister(props) {
  const {
    type,
    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    handleSubmit,
    responseGoogle,
    responseFacebook,
    registerForm,
    loading,

    userDetailsCollege,
    setUserDetailsCollege,
    errorMessagesCollege,
    setErrorMessagesCollege,
    handleSubmitCollege,
  } = props;

  const [currentStep, setCurrentStep] = React.useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const params = useParams();

  useEffect(() => {
    if (params && params?.token) {
      if (params?.token === "student") setValue(0);
      if (params?.token === "college") return setValue(1);

      if (params?.token === "employer" || params?.token === "company") return setValue(2);
    } else if (!params.token && params.token === undefined) setValue(0);
    else setValue(0);
  }, [params]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          //  textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label="Students"
            {...a11yProps(0)}
            onClick={() => {
              // window.history.pushState({}, null, `/signup/student`);
              window.history.pushState({}, null, `/candidate/signup`);
              setCurrentStep(0);
            }}
          />

          <Tab
            label="College"
            {...a11yProps(1)}
            onClick={() => {
              window.history.pushState({}, null, `/signup/college`);
              setCurrentStep(1);
            }}
          />

          <Tab
            label="Employer"
            {...a11yProps(2)}
            onClick={() => {
              window.history.pushState({}, null, `/signup/employer`);
              setCurrentStep(2);
            }}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          {currentStep === 0 ? (
            <RegisterMethods currentStep={currentStep} setCurrentStep={setCurrentStep} type={"Student"} responseGoogle={responseGoogle} responseFacebook={responseFacebook} />
          ) : (
            <RegisterForm
              type={"Student"}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
              handleSubmit={handleSubmit}
              loading={loading}
            />
          )}
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <RegisterFormCollege
            type={"College"}
            handleSubmit={handleSubmitCollege}
            loading={loading}
            userDetails={userDetailsCollege}
            setUserDetails={setUserDetailsCollege}
            errorMessages={errorMessagesCollege}
            setErrorMessages={setErrorMessagesCollege}
          />{" "}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {currentStep === 0 ? (
            <RegisterMethods currentStep={currentStep} setCurrentStep={setCurrentStep} type={"Company"} responseGoogle={responseGoogle} responseFacebook={responseFacebook} />
          ) : (
            <RegisterForm
              type={"Company"}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
              handleSubmit={handleSubmit}
              loading={loading}
            />
          )}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

import { AppBar, Avatar, Box, createStyles, Divider, IconButton, makeStyles, Menu, MenuItem, Tab, Tabs, Toolbar, Typography, withStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi } from "../../../constants/constants";

const NavBar2 = ({ handleDrawerToggle, setLoading }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const profilePic = localStorage.getItem("profile_pic");
  const alert = useAlert();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    location.pathname === "/college/track"
      ? setPageTitle("Track")
      : location.pathname === "/college/reports"
      ? setPageTitle("Reports")
      : location.pathname === "/college/open-job"
      ? setPageTitle("Open Jobs")
      : location.pathname === "/company/post-job"
      ? setPageTitle("Create Job")
      : location.pathname === "/college/invite"
      ? setPageTitle("Invite Companies")
      : location.pathname === "/college/new-job"
      ? setPageTitle("Available Jobs")
      : location.pathname === "/college/close-job"
      ? setPageTitle("Closed Jobs")
      : location.pathname === "/college/company-connection"
      ? setPageTitle("Companies")
      : location.pathname === "/student/scheduled"
      ? setPageTitle("Students")
      : location.pathname === "/college/dashboard"
      ? setPageTitle("Dashboard")
      : location.pathname === "/college/students_connections"
      ? setPageTitle("Student Data")
      : location.pathname === "/college/upload_student_data"
      ? setPageTitle("Upload Student Data")
      : location.pathname === "/college/post-job"
      ? setPageTitle("Create Job")
      : location.pathname === "/college/profile"
      ? setPageTitle("Profile")
      : location.pathname === "/college/profile_update"
      ? setPageTitle("Update Profile")
      : location.pathname === "/public/college/profile"
      ? setPageTitle("View Profile")
      : location.pathname === "/college/reports/leads-by-industry"
      ? setPageTitle("Reports")
      : location.pathname === "/college/reports/yearly-visit-report"
      ? setPageTitle("Reports")
      : location.pathname === "/college/reports/ctc-report"
      ? setPageTitle("Reports")
      : location.pathname === "/college/reports/placement-report"
      ? setPageTitle("Reports")
      : location.pathname === "/college/reports/skills-report"
      ? setPageTitle("Reports")
      : location.pathname === "/college/reports/openings-report"
      ? setPageTitle("Reports")
      : location.pathname === "/college/user-settings"
      ? setPageTitle("User Settings")
      : location.pathname === "/college/college-settings"
      ? setPageTitle("College Settings")
      : setPageTitle("");
  }, [location.pathname]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    Logout()
  };

  const Logout = () => {
    setLoading(true);
    Axios.post(BackendBaseApi.PRAVESH + "api/logout/", null, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
      withCredentials: true
    })
      .then((res) => {
        if (res.data.success) {
          localStorage.clear();
          history.push("/login");
          setLoading(false);
          alert.success("You have been successfuly logged out");
        }
      })
      .catch((err) => {
        setLoading(false);
        localStorage.clear();
        history.push("/login");
        throw err;
      });
  };

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.headerTitle}>
            {pageTitle}
          </Typography>

          {/* <Box>
            <CreditBoard />
          </Box> */}
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                color: "black",
                marginRight: "10px",
              }}
            >
              {localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).first_name : ""}{" "}
              {localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).last_name : ""}
            </Typography>
            <Box>
              <Avatar aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.avatar} src={profilePic} />

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {/* <MenuItem onClick={handleClose} className={classes.menuText}>
                  <Link to="/college/profile">College Profile</Link>
                </MenuItem> */}
                <MenuItem onClick={handleClose} className={classes.menuText}>
                  <Link to="/public/college/profile" target="_blank">
                    College Public Profile
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose} className={classes.menuText}>
                  <Link to="/college/user-settings">User Settings</Link>
                </MenuItem>

                <MenuItem onClick={handleClose} className={classes.menuText}>
                  <Link to="/college/college-settings">College Settings</Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} className={classes.logoutText}>
                  Sign Out
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    // backgroundColor: "transparent",
    borderBottom: "4px solid #3282C4",

    "& > span": {
      width: "100%",
      backgroundColor: "#3282C4",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "black",
    "&:hover": {
      borderBottom: "4px solid #3282C4",
      color: "#3282C4",
    },

    // "&:focus": {
    //   opacity: 1,
    // },
  },
  wrapper: {
    display: "block",
    color: "#000000",
    flexDirection: "column",
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      width: `100%`,
      marginLeft: "220px",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.up(1000)]: {
        width: "calc(100% - 220px)",
        marginLeft: "220px",
      },
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
    },
    headerTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000000",
      fontFamily: "ProximaNova",
    },
    headerTitle2: {
      fontSize: "18px",
      color: "#000000",
      fontFamily: "ProximaNova",
    },
    dropdown: {
      width: "220px",
      height: "36px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    searchBox: {
      width: "219px",
      height: "36px",
      background: "#F2F2F2",
      boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 4,
      padding: "0% 2%",
    },
    selectedTabText: {
      color: "#000",
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "ProximaNova",
    },
    normalTabText: {
      color: "#000",
      fontSize: "18px",

      fontFamily: "ProximaNova",
    },
    selectedTab: {
      height: "40px",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      backgroundColor: "#E5E5E5",
      position: "absolute",
      top: "43%",
      borderRadius: "4px 4px 0px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
    },
    normalTab: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
      height: "40px",
    },
    mainContentBox: {
      width: `calc(100% - 220px)`,
      marginLeft: "220px",
      background: "#E5E5E5",
      padding: "1% 2%",
      boxSizing: "border-box",
      display: "flex",
    },
    filterPanel: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      width: "240px",
    },
    menuText: {
      fontSize: "18px",
      fontFamily: "ProximaNova",
      color: "#424242",
    },
    logoutText: {
      fontSize: "18px",
      fontFamily: "ProximaNova",
      color: "#D64045",
    },
    avatar: {
      cursor: "pointer",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: "#000",
      [theme.breakpoints.up(1000)]: {
        display: "none",
      },
    },
  })
);

export default NavBar2;

import { Tooltip } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi, emailRegex, FACEBOOK_APP_ID, FACEBOOK_APP_SECRET, GOOGLE_CLIENT_ID } from "../../../constants/constants";
import "../../../icons.css";
import AdminApprove from "../UI/AdminApprove";
import AuthHeader from "../UI/AuthHeader";
import Loader from "../UI/Loader";
import SideImage from "../UI/SideImage";
import "./Login.css";
import { getCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import { useDispatch } from "react-redux";

const Login = () => {
  //axios.defaults.withCredentials=true;
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  //if(localStorage.getItem('gw_token')) history.push('/home')

  //prevent first render calls
  const firstRender_1 = useRef(true);
  const firstRender_2 = useRef(true);
  const firstRender_3 = useRef(true);
  //state variables
  const [loading, setLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let passwordFieldType = showPassword ? "text" : "password";
  let tooltipText = showPassword ? "Hide Password" : "Show Password";
  const [showApprovalPage, setShowApprovalPage] = useState({
    show: false,
    company: null,
  });
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [fbAccessToken, setfbAccessToken] = useState("");
  const [ldAccessToken, setldAccessToken] = useState("");
  const [googleAccessToken, setgoogleAccessToken] = useState("");
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({
    emailError: "",
    passwordError: "",
    disabledClass: "disabled",
    isError: true,
  });
  const [linkedInPopper, setLinkedinPopper] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("gw_token") && localStorage.getItem("user_type") && localStorage.getItem("user_type") !== "Admin") {
      checkUser(localStorage.getItem("gw_token"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (state, val) => {
    switch (state) {
      case "Email":
        if (!val) {
          setErrors({
            ...errors,
            emailError: "Email cannot be left blank",
            isError: true,
          });
        } else if (val && !emailRegex.test(val)) {
          setErrors({
            ...errors,
            emailError: "Please enter a valid email",
            isError: true,
          });
        } else {
          setErrors({ ...errors, emailError: "" });
        }
        break;
      case "Password":
        if (!val) {
          setErrors({
            ...errors,
            passwordError: "Password cannot be left blank",
            isError: true,
          });
        } else if (val && val.length < 6) {
          setErrors({
            ...errors,
            passwordError: "Password must be at least 6 characters long",
            isError: true,
          });
        } else {
          setErrors({ ...errors, passwordError: "" });
        }
        break;
    }
  };
  useEffect(() => {
    let accessToken = "";
    if (firstRender_1.current) {
      firstRender_1.current = false;
      return;
    }

    //Facebook Auth API calls
    const fbGraphAPICalls = async () => {
      axios
        .get(
          `https://graph.facebook.com/v6.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${FACEBOOK_APP_ID}&client_secret=${FACEBOOK_APP_SECRET}&fb_exchange_token=${fbAccessToken}`
        )
        .then((res) => {
          accessToken = res.data.access_token;
        })
        .then(() => {
          axios
            .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
              headers: {
                "Content-Type": "application/json",
                Connection: "keep-alive",
              },
              withCredentials: true,
              provider: "facebook",
              access_token: accessToken,
            },
            )
            .then((res) => {
              if (res.data.success) {
                let token = "";
                token = res.data.data.token;
                localStorage.setItem("gw_token", token);
                alert.success(res.data.data.message);
                setLoading(true);
                checkUser(token);
              } else {
                alert.error("Error occured!");
              }
            })
            .catch((err) => {
              alert.error("Error occured!");
            });
        })
        .catch((err) => {});
    };

    fbGraphAPICalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbAccessToken]);

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let token = "";
    if (firstRender_2.current) {
      firstRender_2.current = false;
      return;
    }
    if (googleAccessToken) {
      axios
        .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
          },
          withCredentials: true,
          provider: "google-oauth2",
          access_token: googleAccessToken,
        },
        )
        .then((res) => {
          if (res.data.success) {
            token = res.data.data.token;
            localStorage.setItem("gw_token", token);
            alert.success(res.data.data.message);
            setLoading(true);
            checkUser(token);
          } else {
            alert.error("Error");
          }
        })
        .catch((err) => {
          alert.error("Error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAccessToken]);

  useEffect(() => {
    let accessToken = "";
    if (firstRender_3.current) {
      firstRender_3.current = false;
      return;
    }
    //LinkedIn Auth API calls
    const ldAPICalls = async () => {
      axios
        .get(
          `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=AQTyMMhg1GZcvDxFVEOktKkfFm3ebdjwa5usy-wccScrDK-ntUIullT85-J1qRY4FyWo18J8fBgW0veXVHRU1gcQAzvyZszZCBv_Vn2E8zAd6nvuN4CqfjEXE0qnU8E7OCRn3TviSmzlT7vpZup9tOacc18SrZHdBmhEVV0lUSparZWGyCxlg-dCsSi7tA
                    &redirect_uri=http://localhost:3000/
                    &client_id=78ujcbt4n29tyo
                    &client_secret=1Yzcb8WLzOjEJEkJ`
        )
        .then((res) => {
          accessToken = res.data.access_token;
        })
        .then(() => {
          axios
            .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
              headers: {
                "Content-Type": "application/json",
                Connection: "keep-alive",
              },
              withCredentials: true,
              provider: "linkedin-oauth2",
              access_token: accessToken,
            },
            )
            .then((res) => {
              if (res.data.success) {
                let token = "";
                token = res.data.data.token;

                localStorage.setItem("gw_token", token);
                alert.success(res.data.data.message);
                setLoading(true);
                checkUser(token);
              } else {
                alert.error("Error occured!");
              }
            })
            .catch((err) => {
              alert.error("Error occured!");
            });
        })
        .catch((err) => {});
    };

    ldAPICalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldAccessToken]);

  //LinkedIN
  let state = {
    code: "",
    errorMessage: "",
  };

  const handleLinkedInSuccess = (data) => {
    this.setState({
      code: data.code,
      errorMessage: "",
    });
  };

  const handleLinkedInFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };

  //access short life token from facebook popup response
  const responseFacebook = (response) => {
    setfbAccessToken(response.accessToken);
  };

  //access short life token from google popup response
  const responseGoogle = (response) => {
    setgoogleAccessToken(response.accessToken);
  };

  const handleChange = (e) => {
    e.preventDefault();

    let field = e.target.id;
    if (field === "Email") setEmail(e.target.value);
    else setPassword(e.target.value);

    validate(e.target.id, e.target.value, errors, setErrors);
  };

  const checkUser = (token) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          alert.success("User Already Create!! Please Complete Your Profile");
          if (!res.data.email_verified) {
            //move to signup options component
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              email: res.data.email,
              token,
              isemailVerified: false,
            });
          } else {
            //move to signup options component
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              token,
              email: res.data.email,
              isemailVerified: true,
            });
          }
        } else {
          if (res.data.user_type[0].type === 10) {
            localStorage.clear();
            alert.error("You can not Login with Admin account here");
          } else {
            alert.success("Login Successful");

            // alert.success("Login Successful");
            if (res.data.user_type[0].type !== 10) {
              localStorage.setItem("user_type", res.data.user_type[0].main_user);
            }

            if (res.data.email_verified) {
              localStorage.setItem("gw_token", token);
              localStorage.setItem("user_type_id", res.data.user_type[0].type);
              localStorage.setItem("user_id", res.data.user_type[0].user_id);

              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                //student with verified email, go to home
                if (location.flag == "102") {
                  history.push("/student/open");
                } else {
                  history.push("/student/dashboard");
                }
              } else if (res.data.user_type[0].type === 7) {
                dispatch(getCreditInfo(setLoader));

                if (location.flag === 28071998) history.push("/college/post-job");
                else history.push("/college/dashboard");
              } else if (res.data.user_type[0].type === 8) {
                dispatch(getCreditInfo(setLoader));
                if (location.flag === 28071998) history.push("/college/post-job");
                else history.push("/college/dashboard");
              } else {
                //company user with verified email, check if admin approved
                if (!res.data.company_details.admin_approved) {
                  history.push({
                    pathname: "/company/admin-approve",
                    company: res.data.company_details.company_name,
                    admin_email: res.data.company_details.admin_email,
                    admin_name: res.data.company_details.admin_name,
                    is_admin: res.data.is_company_admin,
                  });
                } else {
                  localStorage.setItem("company", JSON.stringify(res.data.company_details));

                  dispatch(getCreditInfo(setLoader));
                  if (location.flag === 28071998) history.push("/company/post-job");
                  else history.push("/company/dashboard");
                }
              }
            } else {
              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem("gw_token", token);
                localStorage.setItem("user_type_id", res.data.user_type[0].type);

                //student without verified email, go to home

                history.push("/student/dashboard");
              } else {
                //company user without verified email
                history.push({
                  pathname: "/confirm-email",
                  email: res.data.data.email,
                });
              }
            }
          }
        }

        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        
        throw err;
      });
  };
  const loginUser = () => {
    setLoading(true);
    let token = "";
    axios
      .post(BackendBaseApi.PRAVESH + "api/login/", {
        email: Email,
        password: Password,
      })
      .then((res) => {
        // if (res.data.success)
        //     alert.success(res.data.data.message);
        if (!res.data.success) {
          alert.error(res.data.error);
          setLoading(false);
          return;
        }
        token = res.data.data.token;
        setAuthToken(token);
        localStorage.setItem("gw_token", token);
        checkUser(token);
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser();
  };

  const linkedInHandler = () => {
    setLinkedinPopper(true);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div style={{ background: "#ffff" }} className="col-md-8  col-lg-8">
            <div className="row">
              <AuthHeader />
            </div>
            <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-5 mx-auto">
                    {showApprovalPage.show ? (
                      <>
                        {" "}
                        <AdminApprove company={showApprovalPage.company} />{" "}
                      </>
                    ) : (
                      <>
                        <h1 className="mb-4 login-heading text-left" style={{ color: "#000" }}>
                          Sign In
                        </h1>
                        <form onChange={handleChange} onSubmit={handleSubmit} className="my-md-0 my-2">
                          <div className="form-group text-left">
                            <label htmlFor="Email" className="fw-500" style={{ color: "#424242" }}>
                              Enter your Email address
                            </label>
                            <input type="email" className="form-control mt-2 mb-1 shadow_1-lightest" id="Email" aria-describedby="emailHelp" placeholder="" required autoFocus />
                          </div>
                          <div className="form-group text-left pt-md-2">
                            <label htmlFor="Password" className="fw-500" style={{ color: "#424242" }}>
                              Enter your Password
                            </label>
                            <div className="row no-gutters">
                              <div className="col">
                                <input type={passwordFieldType} className="form-control mt-2 mb-1 shadow_1-lightest" id="Password" placeholder="" required />
                              </div>
                              <div className="col-auto">
                                <Tooltip title={tooltipText} placement="right" arrow>
                                  {/* <i>
                                  { showPassword ? 
                                      <VisibilityIcon onClick={() => {
                                        setShowPassword(!showPassword);
                                      }}/> :
                                      <VisibilityOffIcon onClick={() => {
                                        setShowPassword(!showPassword);
                                      }}/>

                                  }
                                  </i> */}
                                  <i
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                    id="toggleShowPassword"
                                    className={!showPassword ? "fas fa-eye show-password-icon" : "fa fa-eye-slash show-password-icon"}
                                  ></i>
                                </Tooltip>
                              </div>
                              {/* <Tooltip
                                className="fs-13"
                                placement="right"
                                isOpen={tooltipOpen}
                                target="toggleShowPassword"
                                toggle={toggle}
                              >
                                {tooltipText}
                              </Tooltip> */}
                            </div>
                          </div>

                          <div className="row my-1 pt-md-2 pt-3">
                            <div className="col-6 text-left">
                              <button className={" shadow_1 btn btn-lg btn-main btn-login text-uppercase font-weight-bold mb-2"} type="submit">
                                Sign in
                              </button>
                            </div>
                            <div className="col-6 text-center my-1">
                              <Link className="fs-14 link-text_2" to="/forgot-password">
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                          <div className="row justify-content-center mt-md-3 mt-5 pt-2">
                            <p className="text-muted" style={{ color: "#424242 !important" }}>
                              or connect with
                            </p>
                          </div>
                          <div className="row mt-md-0 mt-4 justify-content-center" style={{ marginLeft: "20px" }}>
                            <div className="col-4 text-right-small">
                              <FacebookLogin
                                appId={FACEBOOK_APP_ID}
                                callback={responseFacebook}
                                render={(renderProps) => (
                                  <a onClick={renderProps.onClick} className="btn shadow_1 azm-social azm-size-48 azm-circle azm-long-shadow azm-facebook text-light">
                                    <i className="fa fa-facebook"></i>
                                  </a>
                                )}
                                disableMobileRedirect={true}
                              />
                            </div>
                            <div className="col-4 justify-content-center">
                              <GoogleLogin
                              clientId={GOOGLE_CLIENT_ID}
                                // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
                                render={(renderProps) => (
                                  <a href="" onClick={renderProps.onClick} className="btn shadow_1 azm-social azm-size-35 azm-circle azm-long-shadow">
                                    <i>
                                      <img src="https://img.icons8.com/fluent/48/000000/google-logo.png"></img>
                                    </i>
                                  </a>
                                )}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                              />
                            </div>
                            {/* <div className="col-4 text-left-small">
                                                            <a
                                                                href=""
                                                                className="disabled btn shadow_1 azm-social azm-size-48 azm-circle azm-long-shadow azm-linkedin"
                                                            >
                                                                <i className="fa fa-linkedin"></i>
                                                            </a>{" "}
                                                            
                                                        </div> */}
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
                {/* <Footer /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

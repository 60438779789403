import {
  createMuiTheme,
  createStyles,
  makeStyles,
  MuiThemeProvider,
  TextField,
  withStyles,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import uniqueArray from "../../../../utils/UniqueArray";
import {
  UPDATE_JOB_SEGMENT,
  changeState,
  UPDATE_LOCATIONS,
} from "../../../company/jobs/PostJob/Forms/action";
import CssTextField from "./CssTextField";
import theme2 from "./MuiOutlined";

export const JobSegmentSingle = ({
  state,
  setState,
  segmentList,
  width,
  responsive,
  currentJobSegment,
  setCurrentJobSegment,
  MenuSize,
  setApplyButtonClicked,
}) => {
  const dispatch = useDispatch();
  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };
  const classes = useStyles();

  return (
    <Autocomplete
      value={currentJobSegment}
      onChange={(event, newValue) => {
        if (newValue) {
          setState({
            ...state,
            Basics: {
              ...state.Basics,
              job_segment: newValue.job_segment_id,
            },
          });
          setCurrentJobSegment(newValue);
          handleFilterChange(UPDATE_JOB_SEGMENT, [newValue]);
          setApplyButtonClicked(true);
        } else {
          setState({
            ...state,
            Basics: { ...state.Basics, job_segment: null },
          });
          setCurrentJobSegment({});
          handleFilterChange(UPDATE_JOB_SEGMENT, []);
          setApplyButtonClicked(false);
        }
      }}
      id="role"
      options={segmentList?.length > 0 ? segmentList : []}
      getOptionLabel={(option) =>
        option?.job_segment_name ? option?.job_segment_name : ""
      }
      renderOption={(option) => {
        return (
          <span className={MenuSize}>{`${
            option?.job_segment_name ? option?.job_segment_name : ""
          }`}</span>
        );
      }}
      className={width}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return (
          <CssTextField
            {...params}
            label={<span className={responsive}>Job Segment</span>}
            variant="outlined"
          />
        );
      }}
    />
  );
};

export const Companies = ({
  state,
  setState,
  companyList,
  width,
  responsive,
  currentCompany,
  setCurrentCompany,
  MenuSize,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      value={currentCompany}
      disabled={companyList?.length > 0 ? false : true}
      onChange={(event, newValue) => {
        if (newValue) {
          setState({
            ...state,
            Basics: {
              ...state.Basics,
              company_id: newValue.company_id,
              company_details: newValue.company_name,
            },
          });
          setCurrentCompany(newValue);
        } else {
          setState({
            ...state,
            Basics: {
              ...state.Basics,
              company_id: 0,
              company_details: null,
            },
          });
          setCurrentCompany({});
        }
      }}
      id="role"
      options={companyList?.length > 0 ? companyList : []}
      getOptionLabel={(option) =>
        option?.company_name ? option?.company_name : ""
      }
      renderOption={(option) => {
        return (
          <span className={MenuSize}>{`${
            option?.company_name ? option?.company_name : ""
          }`}</span>
        );
      }}
      className={width}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return (
          <CssTextField
            {...params}
            label={<span className={responsive}>Companies</span>}
            variant="outlined"
          />
        );
      }}
    />
  );
};

export const JobRoles = ({
  state,
  setState,
  jobRoleList,
  width,
  responsive,
  currentJobRole,
  setCurrentJobRole,
  MenuSize,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });
  const classes = useStyles();
  return (
    <Autocomplete
      value={currentJobRole}
      filterOptions={filterOptions}
      onChange={(event, newValue) => {
        if (newValue) {
          setState({
            ...state,
            Details: { ...state.Details, job_role: newValue.id },
          });
          setCurrentJobRole(newValue);
        } else {
          setState({
            ...state,
            Details: { ...state.Details, job_role: null },
          });
          setCurrentJobRole();
        }
      }}
      id="rolenew"
      options={jobRoleList?.length > 0 ? jobRoleList : []}
      getOptionLabel={(option) =>
        option?.job_role_name ? option?.job_role_name : ""
      }
      className={width}
      renderOption={(option) => {
        return (
          <span className={MenuSize}>{`${
            option?.job_role_name ? option?.job_role_name : ""
          }`}</span>
        );
      }}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return (
          <CssTextField
            {...params}
            label={<span className={responsive}>Job Roles</span>}
            variant="outlined"
          />
        );
      }}
    />
  );
};

export const JobRolesDescription = ({
  jobRoleList,
  width,
  responsive,
  currentJobRole,
  setCurrentJobRole,
  MenuSize,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      value={currentJobRole}
      onChange={(event, newValue) => {
        if (newValue) {
          setCurrentJobRole(newValue);
        } else {
          setCurrentJobRole();
        }
      }}
      id="rolenew"
      options={jobRoleList?.length > 0 ? jobRoleList : []}
      getOptionLabel={(option) =>
        option?.job_role_name ? option.job_role_name : ""
      }
      className={width}
      renderOption={(option) => {
        return (
          <span className={MenuSize}>{`${
            option?.job_role_name ? option.job_role_name : ""
          }`}</span>
        );
      }}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return (
          <CssTextField
            {...params}
            label={<span className={responsive}>Job Roles</span>}
            variant="outlined"
          />
        );
      }}
    />
  );
};

export const EmploymentType = ({
  state,
  setState,
  empTypeList,
  width,
  responsive,
  currentEmpType,
  setCurrentEmpType,
  MenuSize,
}) => {
  const classes = useStyles();
  return (
    // <MuiThemeProvider theme={theme2}>
    <Autocomplete
      value={currentEmpType}
      onChange={(event, newValue) => {
        if (newValue) {
          setState({
            ...state,
            Basics: { ...state.Basics, employment_type: newValue.id },
            extra1: { ...state.extra1, emp_type_name: newValue.job_type },
          });
          setCurrentEmpType(newValue);
        } else {
          setState({
            ...state,
            Basics: { ...state.Basics, employment_type: null },
            extra1: { ...state.extra1, emp_type_name: "" },
          });
          setCurrentEmpType();
        }
      }}
      id="emptype"
      options={empTypeList?.length > 0 ? empTypeList : []}
      getOptionLabel={(option) => (option?.job_type ? option?.job_type : "")}
      renderOption={(option) => {
        return (
          <span className={MenuSize}>{`${
            option?.job_type ? option?.job_type : ""
          }`}</span>
        );
      }}
      className={width}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return (
          <CssTextField
            {...params}
            label={<span className={responsive}>Employment Type</span>}
            variant="outlined"
          />
        );
      }}
    />
  );
  // </MuiThemeProvider>
};

export const Salaries = ({
  state,
  setState,
  SalaryTypes,
  width,
  labelSizeSalary,
  currentSalaryType,
  setCurrentSalaryType,
  MenuSize,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      value={currentSalaryType}
      autoComplete={true}
      onChange={(event, newValue) => {
        if (newValue) {
          setState({
            ...state,
            Details: {
              ...state.Details,
              salary_payment_type: newValue.value,
            },
          });
          setCurrentSalaryType(newValue);
        } else {
          setState({
            ...state,
            Details: {
              ...state.Details,
              salary_payment_type: "",
            },
          });
          setCurrentSalaryType();
        }
      }}
      id="exp"
      options={SalaryTypes?.length > 0 ? SalaryTypes : []}
      getOptionLabel={(option) => (option?.name ? option?.name : "")}
      getOptionSelected={(option, value) => option.value === value.value}
      renderOption={(option) => {
        return (
          <span className={MenuSize}>{`${
            option?.name ? option?.name : ""
          }`}</span>
        );
      }}
      style={{ width: "100%" }}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return (
          <CssTextField
            {...params}
            label={<span className={labelSizeSalary}>Compensation</span>}
            variant="outlined"
          />
        );
      }}
    />
  );
};

export const Experiences = ({
  state,
  setState,
  work_experiences,
  width,
  responsive,
  currentWorkExperience,
  setCurrentWorkExperience,
  MenuSize,
  name,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      value={currentWorkExperience}
      autoComplete={true}
      onChange={(event, newValue) => {
        // if (newValue) {
        //   setState({
        //     ...state,
        //     Preferences: {
        //       ...state.Preferences,
        //       eligibility_criteria: {
        //         ...state.Preferences.eligibility_criteria,
        //         experience: [newValue.id],
        //       },
        //     },
        //   });
        setCurrentWorkExperience(newValue);
        // } else {
        //   setState({
        //     ...state,
        //     Preferences: {
        //       ...state.Preferences,
        //       eligibility_criteria: {
        //         ...state.Preferences.eligibility_criteria,
        //         experience: [],
        //       },
        //     },
        //   });
        //   setCurrentWorkExperience({});
        // }
      }}
      id="exp2"
      options={work_experiences?.length > 0 ? work_experiences : []}
      getOptionLabel={(option) => (option?.name ? option.name : "")}
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={(option) => {
        return (
          <span className={MenuSize}>{`${
            option?.name ? option.name : ""
          }`}</span>
        );
      }}
      className={width}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return (
          <CssTextField
            {...params}
            label={<span className={responsive}> {name} Experience </span>}
            variant="outlined"
          />
        );
      }}
    />
  );
};

export const JobTypes = ({
  state,
  setState,
  jobTypeList,
  width,
  responsive,
  currentJobType,
  setCurrentJobType,
  MenuSize,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      value={currentJobType}
      onChange={(event, newValue) => {
        if (newValue) {
          setState({
            ...state,
            Basics: { ...state.Basics, job_type: newValue.id },
            extra1: { ...state.extra1, job_type_name: newValue.job_type },
          });
          setCurrentJobType(newValue);
        } else {
          setState({
            ...state,
            Basics: { ...state.Basics, job_type: null },
            extra1: { ...state.extra1, job_type_name: "" },
          });
          setCurrentJobType();
        }
      }}
      id="type"
      options={jobTypeList?.length > 0 ? jobTypeList : []}
      getOptionLabel={(option) => (option?.job_type ? option?.job_type : "")}
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={(option) => {
        return <span className={MenuSize}>{`${option.job_type}`}</span>;
      }}
      className={width}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return (
          <CssTextField
            {...params}
            label={<span className={responsive}>Job Type</span>}
            variant="outlined"
          />
        );
      }}
    />
  );
};

const JobSegment = ({ state, setState, segmentList, width, responsive }) =>
  state.Basics.job_segment.length > 0 ? (
    <MuiThemeProvider theme={theme}>
      <Autocomplete
        multiple
        filterSelectedOptions
        id="tags-outlined3"
        options={segmentList?.length > 0 ? segmentList : []}
        getOptionLabel={(option) =>
          option?.job_segment_name ? option?.job_segment_name : ""
        }
        className={width}
        onChange={(e, i) => {
          var arr = uniqueArray(i);
          setState({
            ...state,
            Basics: { ...state.Basics, job_segment: arr },
          });
        }}
        value={state.Basics.job_segment}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="outlined"
            label={<span className={responsive}>Job Segment</span>}
          />
        )}
      />
    </MuiThemeProvider>
  ) : (
    <Autocomplete
      multiple
      filterSelectedOptions
      id="tags-outlined4"
      options={segmentList}
      getOptionLabel={(option) => option.job_segment_name}
      className={width}
      onChange={(e, i) => {
        var arr = uniqueArray(i);
        setState({ ...state, Basics: { ...state.Basics, job_segment: arr } });
      }}
      value={state.Basics.job_segment}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          label={<span className={responsive}>Job Segment</span>}
        />
      )}
    />
  );

export const JobSegmentFilter = ({
  state,
  setState,
  segmentList,
  width,
  responsive,
}) =>
  state.job_segment.length > 0 ? (
    <MuiThemeProvider theme={theme}>
      <Autocomplete
        multiple
        filterSelectedOptions
        id="tags-outlined5"
        options={segmentList}
        getOptionLabel={(option) => option.job_segment_name}
        renderOption={(option) => {
          return (
            <span style={{ fontSize: 13 }}>{`${option.job_segment_name}`}</span>
          );
        }}
        className={width}
        onChange={(e, i) => {
          var arr = uniqueArray(i);
          setState({ ...state, job_segment: arr });
        }}
        value={state.job_segment}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="outlined"
            label={<span className={responsive}>Job Segment</span>}
          />
        )}
      />
    </MuiThemeProvider>
  ) : (
    <Autocomplete
      multiple
      filterSelectedOptions
      id="tags-outlined6"
      options={segmentList?.length > 0 ? segmentList : []}
      getOptionLabel={(option) =>
        option?.job_segment_name ? option?.job_segment_name : ""
      }
      renderOption={(option) => {
        return (
          <span style={{ fontSize: 13 }}>{`${
            option?.job_segment_name ? option?.job_segment_name : ""
          }`}</span>
        );
      }}
      className={width}
      onChange={(e, i) => {
        var arr = uniqueArray(i);
        setState({ ...state, job_segment: arr });
      }}
      value={state.job_segment}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          label={<span className={responsive}>Job Segment</span>}
        />
      )}
    />
  );

export const Skills = ({ state, setState, skillList, width, responsive }) =>
  state.length > 0 ? (
    <Autocomplete
      multiple
      filterSelectedOptions
      id="tags-outlined7"
      options={skillList?.length > 0 ? skillList : []}
      getOptionLabel={(option) =>
        option?.skill_name ? option?.skill_name : ""
      }
      renderOption={(option) => {
        return (
          <span style={{ fontSize: 13 }}>{`${
            option?.skill_name ? option?.skill_name : ""
          }`}</span>
        );
      }}
      className={width}
      onChange={(e, i) => {
        var arr = uniqueArray(i);

        setState(arr);
      }}
      value={state}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          multiline
          label={<span className={responsive}>Skills</span>}
        />
      )}
    />
  ) : (
    <Autocomplete
      multiple
      filterSelectedOptions
      id="tags-outlined8"
      options={skillList}
      getOptionLabel={(option) => option.skill_name}
      renderOption={(option) => {
        return <span style={{ fontSize: 13 }}>{`${option.skill_name}`}</span>;
      }}
      className={width}
      onChange={(e, i) => {
        var arr = uniqueArray(i);

        setState(arr);
      }}
      value={state}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          label={<span className={responsive}>Skills</span>}
        />
      )}
    />
  );

export const Years = ({
  state,
  setState,
  MainState,
  yearsList,
  width,
  responsive,
}) =>
  MainState.Preferences.eligibility_criteria.graduation_years.length > 0 ? (
    <Autocomplete
      multiple
      filterSelectedOptions
      id="tags-outlined7"
      options={yearsList?.length > 0 ? yearsList : []}
      getOptionLabel={(option) => (option?.name ? option?.name : "")}
      renderOption={(option) => {
        return (
          <span style={{ fontSize: 13 }}>{`${
            option?.name ? option?.name : ""
          }`}</span>
        );
      }}
      className={width}
      onChange={(e, i) => {
        var arr = uniqueArray(i);

        setState(arr);
      }}
      value={state}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          multiline
          label={<span className={responsive}>Graduation Years</span>}
        />
      )}
    />
  ) : (
    <Autocomplete
      multiple
      filterSelectedOptions
      id="tags-outlined7"
      options={yearsList}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => {
        return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
      }}
      className={width}
      onChange={(e, i) => {
        var arr = uniqueArray(i);

        setState(arr);
      }}
      value={state}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          label={<span className={responsive}>Graduation Years</span>}
        />
      )}
    />
  );

export const Locations = ({
  state,
  setState,
  cityList,
  width,
  responsive,
  setApplyButtonClicked,
}) => {
  const dispatch = useDispatch();
  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };
  return (
    <>
      {state.Details.job_location.length > 0 ? (
        <Autocomplete
          multiple
          filterSelectedOptions
          disabled={state.Details.allow_remote}
          id="tags-outlined9"
          options={cityList?.length > 0 ? cityList : []}
          getOptionLabel={(option) => (option.city ? option.city : "")}
          renderOption={(option) => {
            return (
              <span style={{ fontSize: 13 }}>{`${
                option.city ? option.city : ""
              }`}</span>
            );
          }}
          className={width}
          onChange={(e, i) => {
            var arr = uniqueArray(i);

            setState({
              ...state,
              Details: {
                ...state.Details,
                job_location: arr,
              },
            });
            handleFilterChange(UPDATE_LOCATIONS, arr);
            setApplyButtonClicked(true);
          }}
          value={state.Details.job_location}
          renderInput={(params) => (
            <CssTextField
              {...params}
              variant="outlined"
              multiline
              label={<span className={responsive}>Location</span>}
            />
          )}
        />
      ) : (
        <Autocomplete
          multiple
          filterSelectedOptions
          disabled={state.Details.allow_remote}
          id="tags-outlined10"
          options={cityList}
          getOptionLabel={(option) => option.city}
          renderOption={(option) => {
            return <span style={{ fontSize: 13 }}>{`${option.city}`}</span>;
          }}
          className={width}
          onChange={(e, i) => {
            var arr = uniqueArray(i);

            setState({
              ...state,
              Details: {
                ...state.Details,
                job_location: arr,
              },
            });
            handleFilterChange(UPDATE_LOCATIONS, arr);
            setApplyButtonClicked(true);
          }}
          value={state.Details.job_location}
          renderInput={(params) => (
            <CssTextField
              {...params}
              variant="outlined"
              label={<span className={responsive}>Location</span>}
            />
          )}
        />
      )}
    </>
  );
};

export const LocationsFilter = ({
  state,
  setState,
  cityList,
  width,
  responsive,
}) =>
  state.location.length > 0 ? (
    <MuiThemeProvider theme={theme}>
      <Autocomplete
        multiple
        filterSelectedOptions
        id="tags-outlined1"
        options={cityList?.length > 0 ? cityList : []}
        getOptionLabel={(option) => (option.city ? option.city : "")}
        renderOption={(option) => {
          return (
            <span style={{ fontSize: 13 }}>{`${
              option.city ? option.city : ""
            }`}</span>
          );
        }}
        className={width}
        onChange={(e, i) => {
          var arr = uniqueArray(i);

          setState({ ...state, location: arr });
        }}
        value={state.location}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="outlined"
            label={<span className={responsive}>Location</span>}
          />
        )}
      />
    </MuiThemeProvider>
  ) : (
    <Autocomplete
      multiple
      filterSelectedOptions
      id="tags-outlined2"
      options={cityList}
      getOptionLabel={(option) => option.city}
      renderOption={(option) => {
        return <span style={{ fontSize: 13 }}>{`${option.city}`}</span>;
      }}
      className={width}
      onChange={(e, i) => {
        var arr = uniqueArray(i);

        setState({ ...state, location: arr });
      }}
      value={state.location}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          label={<span className={responsive}>Location</span>}
        />
      )}
    />
  );

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        height: "auto",
        borderRadius: 30,
        // borderColor: "#dedede",
      },
    },
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
  })
);

export default JobSegment;

import {
  AppBar,
  Box,
  Button,
  Container,
  createStyles,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import Axios from "axios";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../../assets/images/gw_sidebar_icon.png";
import { BackendBaseApi } from "../../../constants/constants";

const PublicNavbar = () => {
  const classes = useStyles();
  let history = useHistory();

  const logout = () => {
    Axios.post(BackendBaseApi.PRAVESH + "api/logout/", null, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
      withCredentials: true
    })
      .then((res) => {
        if (res.data.success) {
          localStorage.clear();
          history.push("/login");
        }
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/login");
      });
  };

  return (
    <Box>
      <AppBar position="fixed" color="transparent">
        <Toolbar style={{ padding: "0px", backgroundColor: "#ffffff" }}>
          <Container>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link to="/student/dashboard">
                <img src={Logo} className={classes.logo} alt="" />
              </Link>
              {localStorage.getItem("gw_token") === null ? (
                <Link to="/signup">
                  <Button variant="outlined" className={classes.logButton}>
                    Signup
                  </Button>
                </Link>
              ) : (
                <Button
                  variant="outlined"
                  className={classes.logButton}
                  onClick={logout}
                >
                  Logout
                </Button>
              )}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appbar: {
      background: "#ffffff",
      minHeight: 40,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    logo: {
      width: "113px",
    },
    logButton: {
      border: "1px solid #3282C4",
      color: "#3282C4",
      height: "35px",
      "&:hover": {
        backgroundColor: "CDEDF6",
      },
    },
  })
);

export default PublicNavbar;

import { Search } from "@mui/icons-material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  alpha,
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  styled,
  Typography,
  useTheme,
  Autocomplete
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../constants/constants";
import user from "../../../Assets/Images/png/aisha.png";
import { CustomTextField } from "../../Common/Input/InputField";

const CustomListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function StrongSkills({
  setStep,
  step,
  finalSkills,
  setFinalSkills,
  signupComplete,
  getContinueButtonId,
  getSkipButtonId
}) {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const gwToken = localStorage.getItem("gw_token");

  const theme = useTheme();

  const handleDelete = (chipToDelete) => () => {
    setSelectedSkills((chips) =>
      chips.filter((chip) => chip.skill_id !== chipToDelete.skill_id)
    );
  };

  const [allSkills, setAllSkills] = useState([]);

  const [filteredSkills, setFilteredSkills] = useState([]);

  const [searchedSkill, setSearchedSkill] = useState();
  const [selectedSkill, setSelectedSkill] = useState({});
  const [skillLoading, setSkillLoading] = useState(false);


  const [selectedSkills, setSelectedSkills] = useState(finalSkills);
  

  const [searchTerm, setSearchTerm] = useState();

  const [skillLoader, setSkillLoader] = useState();

  // useEffect(() => {
  //   setSkillLoader(true);
  //   Axios.get(`${BackendBaseApi.PRAVESH}api/education/skills?result_count=50`)
  //     .then((res) => {
  //       if (res?.data?.success) {
  //         setAllSkills(res?.data?.data?.skills?.slice(0, 50));
  //         setFilteredSkills(res?.data?.data?.skills?.slice(0, 10));
  //       }
  //       setSkillLoader(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setSkillLoader(true);
  //     });
  // }, []);

  useEffect(() => {
    setSkillLoading(true);
    const skillSearch = searchedSkill ? `&skill_name=${searchedSkill}` : "";
    Axios.get(`${BackendBaseApi.PRAVESH}api/education/skills?result_count=50${skillSearch}`)
      .then((res) => {
        if (res?.data?.success) {
          setAllSkills(res?.data?.data?.skills?.slice(0, 50));
          if(res?.data?.data?.skills?.length > 0){
              setFilteredSkills(res?.data?.data?.skills?.slice(0, 10));
          }
        }
        setSkillLoading(false);
      })
      .catch((err) => {
        setSkillLoading(true);
      });
  }, [searchedSkill]);

  useEffect(() => {
    let filterSkill = allSkills?.filter((item) =>
      item?.skill_name?.toLowerCase()?.includes(searchTerm ? searchTerm?.toLowerCase() : "")
    );
    setFilteredSkills(filterSkill);
  }, [searchTerm]);

  const addSkills = (skill) => {
    const ind = selectedSkills?.findIndex(
      (x) => x.skill_id === skill?.skill_id
    );
    if (ind === -1) {
      setSelectedSkills([...selectedSkills, skill]);
      const filterInd = filteredSkills?.findIndex(
        (x) => x.skill_id === skill?.skill_id
      );
      if(filterInd !== -1){
        let filterAllSkill = [...filteredSkills];
        filterAllSkill.splice(filterInd, 1);
        setFilteredSkills(filterAllSkill);
      }
    }
  };

  const updateSkills = () => {
    if (!validateSkills()) {
      return;
    }
    setLoading(true);
    Axios.post(
      `${BackendBaseApi.PRAVESH}api/education/user_skill`,
      {
        skill: selectedSkills,
      },
      {
        headers: {
          Authorization: "Token " + gwToken,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        setLoading(false);
        setFinalSkills(selectedSkills);
        signupComplete();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const validateSkills = () => {
    if (!selectedSkills || selectedSkills.length < 5) {
      alert.error("Please select atleast 5 skills!!");
      return false;
    }
    return true;
  };

  const debounce = (func) => {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChangeSearch = (e) => {
    setAllSkills([]);
    setSearchedSkill(e.target.value);
    
  };

  const optimisedHandleChange = React.useCallback(debounce(handleChangeSearch), []);

  return (
    <>
      <div>
        {" "}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar alt="user-img" src={user} sx={{ width: 66, height: 66 }} />
          <Typography
            variant="h4"
            style={{ fontWeight: "500", lineHeight: 1.4, marginTop: 5 }}
          >
            Cool, Share what you are good at to attract the top companies
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={12}>
            <Typography
              variant="body2"
              color="initial"
              style={{ color: "#6C757D" }}
            >
              Add 5-10 of your strongest skills
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12} style={{ display: "grid" }}>
            {/* <CustomTextField
              size="small"
              label="Search Skills"
              type="text"
              onWheel={() => document.activeElement.blur()}
              InputProps={{
                startAdornment: <Search />,
              }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            /> */}

        <Autocomplete
              id="select-skill"
              options={allSkills}
              value={selectedSkills}
              // defaultValue=""
              loading={skillLoading}
              getOptionLabel={(option) =>
                option?.skill_name
                  ? option?.skill_name
                  : ""
              }
              renderOption={(props, option) => {
                let { key, ...propsNew } = props;
                return (
                  <Typography
                    style={{
                      fontSize: "14px",
                      padding: "5px 20px",
                      cursor: "pointer",
                    }}
                    {...propsNew}
                  >
                    {option?.skill_name}
                  </Typography>
                );
              }}
              style={{
                background: "#fff",
                borderRadius: 30,
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  label="Skills"
                  size="small"
                  required
                  // InputProps={{
                  //   startAdornment: <Search />,
                  // }}
                  onChange={(ev) => {
                    setSkillLoading(true);
                    // dont fire API if the user delete or not entered anything
                      optimisedHandleChange(ev);
                  }}
                  
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedSkills({});
                  addSkills(newValue);
                }
              }}
             
            />

          </Grid>
          <Grid xs={12} sm={12} md={12} sx={{ display: "grid", pt: 0, pb: 0 }}>
            <Box sx={{ marginLeft: "-7px" }}>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  padding: "1px",
                  m: 0,
                  boxShadow: "none",
                }}
                component="ul"
              >
                {selectedSkills?.map((data) => {
                  return (
                    <CustomListItem key={data.skill_id}>
                      <Chip
                        size="small"
                        color="primary"
                        variant="filled"
                        deleteIcon={<CloseRoundedIcon sx={{ color: "#fff" }} />}
                        label={data.skill_name}
                        onDelete={handleDelete(data)}
                      />
                    </CustomListItem>
                  );
                })}
              </Paper>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={12} sx={{ pb: 0 }}>
            <Typography
              variant="body2"
              color="initial"
              style={{ color: "#6C757D" }}
            >
              Add from suggested skills
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            sx={{
              pt: 0
            }}
          >
            <Box
              sx={{
                marginLeft: "-7px",
                maxHeight: "50vh",
                overflowY: "scroll",
                [theme.breakpoints.only("xs")]: {
                  maxHeight: "55vh",
                  overflowY: "scroll",
                  // marginBottom: "40px"
                },
              }}
            >
              <Paper
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                  boxShadow: "none",
                }}
                component="ul"
              >
                {skillLoader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                filteredSkills?.map((data) => {
                    return (
                      <CustomListItem
                        key={`${data?.skill_id}-${data?.skill_name}`}
                      >
                        <Chip
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.1
                            ),
                            cursor: "pointer",
                          }}
                          label={data?.skill_name}
                          onClick={() => {
                            addSkills(data);
                          }}
                        />
                      </CustomListItem>
                    );
                  })
                )
                }
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </div>
      <AppBar
        color="inherit"
        sx={{
          boxShadow: "none",
          position: "relative",
          marginTop: "10px",
          [theme.breakpoints.only("sm")]: {
            top: "auto",
            bottom: 0,
            boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
            position: "fixed",
          },
          [theme.breakpoints.only("xs")]: {
            top: "auto",
            bottom: 0,
            boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
            position: "fixed",
          },
        }}
      >
        <Box
          sx={{
            pl: 0,
            pr: 0,
            [theme.breakpoints.only("sm")]: { pl: 1, pr: 1 },
            [theme.breakpoints.only("xs")]: { pl: 1, pr: 1 },
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
              <Button
                color="primary"
                sx={{
                  height: "40px",
                  width: "100%",
                  boxShadow: "none",
                  borderRadius: "30px",
                  display: "flex",
                  fontWeight: "400",
                  fontSize: "15px",
                }}
                variant="outlined"
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
              <Button
                color="primary"
                endIcon={<ArrowForwardRoundedIcon />}
                sx={{
                  height: "40px",
                  width: "100%",
                  boxShadow: "none",
                  borderRadius: "30px",
                  display: "flex",
                  fontWeight: "400",
                  fontSize: "15px",
                }}
                variant="contained"
                onClick={() => {
                  updateSkills();
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  <span id={getContinueButtonId()}> Finish</span>
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    </>
  );
}

import { Box } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import SelectSearch from "react-select-search";
import Logo from "../../../../assets/img/getwork-logo.png";
import {
  BackendBaseApi,
  Genders,
  veteranStatuses,
} from "../../../../constants/constants";
import Loader from "../../../common/components/UI/Loader";

const StudentIdentification = ({
  step,
  setStep,
  studentDetails,
  setStudentDetails,
}) => {
  const disableButton = useRef();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  let history = useHistory();

  const gotoLogin = () => {
    history.push("/login");
  };

  const getEthnicityList = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/education/ethnicity/")
      .then((res) => {
        let temp = [];
        res.data.forEach((ethnicity) => {
          let tempObj = {
            name: ethnicity.name,
            value: ethnicity.name,
            id: ethnicity.id,
          };

          temp.push(tempObj);
          hashEthnicity[ethnicity.name] = ethnicity.id;
        });
        setEthnicityList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEthnicityList();
    disableButton.current.classList.add("disabled");
  }, []);

  const [ethnicityList, setEthnicityList] = useState();
  const [hashEthnicity, setHashEthnicity] = useState({});
  const [gender, setGender] = useState("");
  const [veteranStatus, setVeteranStatus] = useState("");
  const [ethnicity, setEthnicity] = useState("");

  useEffect(() => {
    if (gender && veteranStatus && ethnicity)
      disableButton.current.classList.remove("disabled");
    else disableButton.current.classList.add("disabled");
  }, [gender, veteranStatus, ethnicity]);

  const LoginStudent = () => {
    localStorage.setItem("gw_token", studentDetails.Token);
    history.push("/student/dashboard");
  };

  const sendEducationalDetails = () => {
    let data = {
      type_id: studentDetails.Type,
      user_id: studentDetails.StudentID,
      education_level: studentDetails.QualificationID,
      college_country: 1,
      gender: gender,
      ethnicity: hashEthnicity[ethnicity],
      veteran: veteranStatus,
      current_year: Number(studentDetails.Year),
      referral_code: studentDetails.RefferalCode
        ? studentDetails.RefferalCode
        : null,
    };

    switch (studentDetails.Case) {
      case 1:
        data = {
          ...data,
          college_id: studentDetails.CollegeID,
          degree_id: studentDetails.CourseID,
          specialization_id: studentDetails.BranchID
            ? studentDetails.BranchID
            : null,
          college_name: null,
          college_city: null,
          college_state: null,
          degree_name: null,
          specialization_name: null,
        };
        break;
      case 2:
        data = {
          ...data,
          college_id: studentDetails.CollegeID,
          degree_id: studentDetails.CourseID,
          specialization_id: studentDetails.BranchID
            ? studentDetails.BranchID
            : null,
          college_name: null,
          college_city: null,
          college_state: null,
          degree_name: null,
          specialization_name: null,
        };
        break;
      case 3:
        data = {
          ...data,
          college_id: studentDetails.CollegeID,
          degree_id: studentDetails.CourseID,
          specialization_name: studentDetails.Branch
            ? studentDetails.Branch
            : null,
          college_name: null,
          college_city: null,
          college_state: null,
          degree_name: null,
          specialization_id: null,
        };
        break;
      case 4:
        data = {
          ...data,
          college_id: studentDetails.CollegeID,
          degree_name: studentDetails.Course,
          specialization_name: studentDetails.Branch
            ? studentDetails.Branch
            : null,
          college_name: null,
          college_city: null,
          college_state: null,
          degree_id: null,
          specialization_id: null,
        };
        break;
      case 5:
        data = {
          ...data,
          college_name: studentDetails.CollegeName,
          degree_id: studentDetails.CourseID,
          specialization_id: studentDetails.BranchID
            ? studentDetails.BranchID
            : null,
          college_id: null,
          college_city: studentDetails.CollegeCityID,
          college_state: studentDetails.CollegeStateID,
          degree_name: null,
          specialization_name: null,
        };
        break;
      case 6:
        data = {
          ...data,
          college_name: studentDetails.CollegeName,
          degree_id: studentDetails.CourseID,
          specialization_name: studentDetails.Branch
            ? studentDetails.Branch
            : null,
          college_id: null,
          college_city: studentDetails.CollegeCityID,
          college_state: studentDetails.CollegeStateID,
          degree_name: null,
          specialization_id: null,
        };
        break;
      case 7:
        data = {
          ...data,
          college_name: studentDetails.CollegeName,
          degree_name: studentDetails.Course,
          specialization_name: studentDetails.Branch
            ? studentDetails.Branch
            : null,
          college_city: studentDetails.CollegeCityID,
          college_state: studentDetails.CollegeStateID,
          degree_id: null,
          college_id: null,
          specialization_id: null,
        };
        break;

      default:
        break;
    }

    axios
      .post(BackendBaseApi.PRAVESH + "api/education/student_user", { ...data })
      .then((res) => {
        setLoading(false);

        if (res.data.success) {
          alert.success(res.data.data.message);
        } else {
          alert.error(res.data.error);
          return;
        }
        alert.success("User signup successfully completed!");
        localStorage.setItem("user_type_id", 1);

        LoginStudent();
      })
      .catch((err) => {
        alert.error("There was some error");
      });
  };

  const completeSignup = () => {
    if(!(gender && ethnicity && veteranStatus)){
      alert.info("Please select all fields");
    }
    else{
    setLoading(true);
    sendEducationalDetails();
    }
  };

  const GenderDropdown = () => (
    <SelectSearch
      options={Genders}
      onChange={setGender}
      value={gender}
      placeholder="Select"
    />
  );
  const EthincityDropdown = () => (
    <SelectSearch
      options={ethnicityList}
      onChange={setEthnicity}
      value={ethnicity}
      placeholder="Select"
    />
  );
  const VeteranStatusDropdown = () => (
    <SelectSearch
      options={veteranStatuses}
      onChange={setVeteranStatus}
      value={veteranStatus}
      placeholder="Select"
    />
  );

  return loading ? (
    <Loader />
  ) : (
    <Box bgcolor="#ffffff">
      <div className="container fluid-container" style={{ marginTop: "0px" }}>
        <div className="row my-3">
          <div className="col-6 text-left">
            <img src={Logo} alt="" className="logo-lg" />
          </div>
          <div className="col-6 text-right pt-3">
            <button
              onClick={() => {
                gotoLogin();
              }}
              className=" shadow_1 btn btn-lg btn-main btn-login btn-next text-uppercase font-weight-bold mb-2"
            >
              <span>Logout</span>
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <p style={{ color: "#000", fontSize: "36px", fontWeight: "bold" }}>
            Heads up!
          </p>
          <div className="mx-md-5 px-md-0 mx-0 px-0">
            <p className="px-md-0 px-0 my-3" style={{ color: "#424242" }}>
              Due to government regulations, we have to show you some legal text
              and ask you a few short questions to help companies hire better.
            </p>
          </div>

          <div className="mx-md-5 px-md-5 mx-0 px-0">
            <p
              className="px-md-5 px-0 my-3"
              style={{ textAlign: "center", color: "#424242" }}
            >
              The questions are simple and are about your ethnicity, gender, and
              veteran status. <br /> Don’t be scared off by all the text, we
              promise it’s easy and will only take a minute.
            </p>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-6 text-left">
            <div className="form-group required text-left">
              <label
                htmlFor="Gender"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Gender
              </label>
              <GenderDropdown
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="Gender"
              />
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="form-group required text-left">
              <label
                htmlFor="Ethnicity"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Ethnicity
              </label>
              <EthincityDropdown
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="Ethnicity"
              />
            </div>
          </div>
        </div>
        <div className="row text-left my-1 mx-1">
          <h5 style={{ color: "#000" }}>Veteran Status:</h5>
          <p style={{ color: "#424242", textAlign: "justify" }}>
            1. Many of the employers that GetWork works with are Government
            contractors which requires them to take affirmative action to employ
            and advance in employment: (1) disabled veterans; (2) recently
            separated veterans; (3) active duty wartime or campaign badge
            veterans; and (4) Armed Forces service medal veterans.
          </p>
          <p style={{ color: "#424242", textAlign: "justify" }}>
            2. If you believe you belong to any of the categories of protected
            veterans, please indicate by checking the appropriate box below. As
            Government contractors, the companies request this information in
            order to measure the effectiveness of the outreach and positive
            recruitment efforts they undertake. If you are a disabled veteran it
            would assist us if you tell us whether there are accommodations we
            could make that would enable you to perform the essential functions
            of the job, including special equipment, changes in the physical
            layout of the job, changes in the way the job is customarily
            performed, provision of personal assistance services or other
            accommodations. This information will assist us in making reasonable
            accommodations for your disability.
          </p>
          <p style={{ color: "#424242", textAlign: "justify" }}>
            3. Submission of this information is voluntary and refusal to
            provide it will not subject you to any adverse treatment. The
            information provided will be used only in ways that are not
            inconsistent.
          </p>
          <p style={{ color: "#424242", textAlign: "justify" }}>
            4. The information you submit will be kept confidential, except that
            (i) supervisors and managers may be informed regarding restrictions
            on the work or duties of disabled veterans, and regarding necessary
            accommodations; (ii) first aid and safety personnel may be informed,
            when and to the extent appropriate, if you have a condition that
            might require emergency treatment; and (iii) Government officials
            engaged in enforcing laws administered by the Government of India.
          </p>

          <div className="form-group text-left required w-100 my-1">
            <label
              htmlFor="Veteran"
              className="fw-500 control-label"
              style={{ color: "#424242" }}
            >
              Veteran Status
            </label>
            <VeteranStatusDropdown
              className="form-control mt-2 mb-1 shadow_1-lightest"
              id="Veteran"
            />
          </div>
        </div>
        <div className="row my-3 pt-md-2 pt-3">
          <div className="col-6 text-left"></div>
          <div className="col-6 text-right">
            <button
              ref={disableButton}
              onClick={completeSignup}
              className=" shadow_1 btn btn-lg btn-main btn-login btn-next text-uppercase font-weight-bold mb-2"
            >
              <span>
                Find Jobs <i class="fas fa-angle-double-right" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default StudentIdentification;

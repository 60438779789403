import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Pagination_Track(props) {
  const { page, handleChangePage, dataCount } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Pagination
        size="small"
        count={Math.ceil(dataCount / 10)}
        page={page}
        onChange={handleChangePage}
      />
    </div>
  );
}

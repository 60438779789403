import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Grid, Hidden, IconButton, makeStyles, Paper, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Link, useLocation } from "react-router-dom";

import college from "../../../../assets/svg/login/college.svg";
import office from "../../../../assets/svg/login/office-building.svg";
import graduated from "../../../../assets/svg/graduated.svg";
import college_white from "../../../../assets/svg/college_white.svg";
import office_white from "../../../../assets/svg/office-building_white.svg";
import graduated_white from "../../../../assets/svg/graduated_white.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    color: "grey",
    fontSize: 13,
  },
});

const useStyles = makeStyles((theme) => ({
  newDialog: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "90%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  paper: {
    padding: 8,
    textAlign: "center",
    color: theme.palette.common.black,
    borderRadius: "15px",
    backgroundColor: theme.palette.common.white,
    border: "solid 1px #007bff",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
  },
  paperBlue: {
    padding: 8,
    textAlign: "center",
    color: theme.palette.common.black,
    borderRadius: "15px",
    backgroundColor: "#007bff",
    border: "solid 1px #007bff",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
  },

  img: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{ fontSize: 15 }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function LoginDialog({ open, handleClickOpen, handleClose, forMobile }) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const data_company = [
    {
      title: "Student",
      logo:
        location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" || location.pathname === "/login/student" || location.pathname === ""
          ? graduated_white
          : graduated,
      link: "/login/student",
      paper:
        location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" || location.pathname === "/login/student" || location.pathname === ""
          ? classes.paperBlue
          : classes.paper,
      selected:
        location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" || location.pathname === "/login/student" || location.pathname === ""
          ? true
          : false,
      details: "Find Exciting Jobs",
    },

    {
      title: "Employer",
      logo: location.pathname === "/signup/employer" || location.pathname === "/login/company" ? office_white : office,
      link: "/signup/employer",
      paper: location.pathname === "/signup/employer" || location.pathname === "/login/company" ? classes.paperBlue : classes.paper,
      selected: location.pathname === "/signup/employer" || location.pathname === "/login/company" ? true : false,
      details: "Hire Best Candidates",
    },
    {
      title: "College / Institute",
      logo: location.pathname === "/login/college" ? college_white : college,
      link: "/login/college",
      paper: location.pathname === "/login/college" ? classes.paperBlue : classes.paper,
      selected: location.pathname === "/login/college" ? true : false,
      details: "Manage Placements",
    },
  ];
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        classes={{
          paper: classes.newDialog,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: forMobile ? 45 : 10,
            // bottom: forMobile && 0,
            // margin: forMobile && 0,
            // height: 800,
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
        <DialogContent style={{ paddingLeft: "40px", paddingRight: "40px" }}>
          <Typography
            variant="body1"
            style={{
              fontFamily: " Nunito",
              fontWeight: "1900",
              marginBottom: 10,
            }}
          >
            <b> Hey, Are you a:</b>
          </Typography>
          {data_company.map((items) => (
            <Grid item xs={12} sm={12} style={{ marginBottom: 20 }} onClick={() => handleClose()}>
              <Link
                to={{
                  pathname: items.link,
                  search: location.search ? location.search : "",
                }}
              >
                <Paper className={items.paper}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} className={classes.img}>
                      <img src={items.logo} height="70px" width="70px" alt="icons" />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color: items.selected ? "#fff" : "#007bff",
                          fontFamily: " Nunito",
                          fontWeight: "900",
                          marginBottom: 10,
                          textAlign: "left",
                        }}
                      >
                        <b>{items.title}</b>
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: items.selected ? "#fff" : "#007bff",
                          fontFamily: " Nunito",
                          fontWeight: "900",
                          textAlign: "left",
                        }}
                      >
                        {items.details}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}

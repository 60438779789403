import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../bundles/common/components/UI/Loader";
import { BackendBaseApi } from "../../../../constants/constants";
import {
  changeState,
  FILTER_COUNT,
  FILTER_COUNT_COLLEGE, getAllLocations,
  getIndustries, getJobSegment,
  UPDATE_CTC_EXPLORE,
  UPDATE_INDUSTRY,
  UPDATE_JOB_SEGMENT,
  UPDATE_LOCATION_EXPLORE,
  UPDATE_LOCATION_EXPLORE_COLLEGE, UPDATE_SIZE
} from "./action";
import ExploreFeature from "./ExploreFeature";

export default function Explore() {
  const collegeID = localStorage.getItem("college_id");
  const [jobRoles, setJobRoles] = React.useState({});
  const [jobRole, setJobRole] = React.useState();
  const [jobTypes, setJobTypes] = useState({});
  const [city, setCity] = useState();
  // const [cityList, setCityList] = React.useState({});
  const [jobType, setJobType] = useState();
  const [data, setData] = React.useState({});
  const [blog, setBlog] = React.useState({});
  const alert = useAlert();
  const [loader, setLoader] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };
  const getJobRoles = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/segment/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((job_type) => {
            temp[job_type.job_segment] = job_type.id;
          });
          setJobRoles({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured 5");
      });
  };
  const stateMemory = useSelector((state) => state.ExploreFilter);

  const cityList = useSelector((state) => state.CityList.cityList);
  const jobSegments = useSelector(
    (state) => state.JobSegmentList.JobSegmentList
  );
  const industries = useSelector((state) => state.IndustryList.IndustryList);

  const getJobTypes = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/type/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((job_type) => {
            temp[job_type.job_type] = job_type.id;
          });
          setJobTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Some Error Occured");
      });
  };

  // const getAllLocations = () => {
  //   Axios.get(BackendBaseApi.PRAVESH + "api/location/city/")
  //     .then((res) => {
  //       let temp = {};

  //       for (let i = 0; i < res.data.length; i++) {
  //         temp[res.data[i].city] = res.data[i].city_id;
  //       }
  //       setCityList({ ...temp });
  //     })
  //     .catch((err) => {
  //       alert.error("Some Error Occured");
  //     });
  // };

  const getExploreData = () => {
    const checkValue = () => {
      if (localStorage.getItem("user_type") === "College") return "college";
      else return "company";
    };
    setLoader(true);
    Axios.get(`${BackendBaseApi.PRAVESH}api/${checkValue()}/explore/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        setData(res.data.data);

        setLoader(false);
      })
      .catch((err) => {
        console.log("Some Error Occured");
        setLoader(false);
      });
  };

  const getBlogs = () => {
    setLoading(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/blog/all/")
      .then((res) => {
        setBlog(res.data.data);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Some Error Occured");
      });
  };

  useEffect(() => {
    if (!collegeID) {
      getJobRoles();
      getJobTypes();
      dispatch(getAllLocations());
      dispatch(getJobSegment());

      getExploreData();
      getBlogs();
      handleFilterChange(UPDATE_CTC_EXPLORE, 0);
      handleFilterChange(UPDATE_LOCATION_EXPLORE, []);
      handleFilterChange(UPDATE_JOB_SEGMENT, []);
      handleFilterChange(FILTER_COUNT, 0);
    } else {
      dispatch(getIndustries());
      dispatch(getAllLocations());
      getExploreData();
      getBlogs();
      handleFilterChange(UPDATE_SIZE, null);
      handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, []);
      handleFilterChange(UPDATE_INDUSTRY, []);
      handleFilterChange(FILTER_COUNT_COLLEGE, 0);
    }
  }, []);
  return (
    <>
      {collegeID ? (
        loader === true && loading === true ? (
          <Loader />
        ) : (
          <ExploreFeature
            jobRole={jobRole}
            jobRoles={industries}
            setJobRole={setJobRole}
            jobType={jobType}
            setJobType={setJobType}
            jobTypes={jobTypes}
            city={city}
            setCity={setCity}
            cityList={cityList}
            data={data}
            blog={blog}
            stateMemory={stateMemory}
            handleFilterChange={handleFilterChange}
          />
        )
      ) : loader && loading ? (
        <Loader />
      ) : (
        <ExploreFeature
          jobRole={jobRole}
          jobRoles={jobSegments}
          setJobRole={setJobRole}
          jobType={jobType}
          setJobType={setJobType}
          jobTypes={jobTypes}
          city={city}
          setCity={setCity}
          cityList={cityList}
          data={data}
          blog={blog}
          stateMemory={stateMemory}
          handleFilterChange={handleFilterChange}
        />
      )}
    </>
  );
}

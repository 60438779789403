import React, { useEffect, useState } from "react";
import Companies from "../UI/Companies";
import JobTypes from "../UI/JobTypes";
import Skills from "../UI/Skills";

const WorkExperienceForm = ({ type, newWorkEx, setNewWorkEx }) => {
  const [workexSkills, setWorkexSkills] = useState(
    type === "ADD_WORKEX" ? [] : [...newWorkEx.skills]
  );

  const [newWorkexSkill, setNewWorkexSkill] = useState({
    skill_name: "",
    skill_id: null,
  });

  const [workEx, setWorkEx] = useState(
    type === "ADD_WORKEX"
      ? {
          id: null,
          company_id: null,
          company_name: "",
          company_website: "",
          job_type_id: null,
          job_type: "",
          start_date: null,
          end_date: null,
          job_designation: "",
          job_description: "",
          skills: [],
        }
      : newWorkEx
  );

  const [currentlyWork, setCurrentlyWork] = useState(false);

  const [company, setCompany] = useState({
    company_name: "",
    company_id: null,
  });

  const [job, setJob] = useState({
    job_type_id: null,
    job_type: "",
  });

  useEffect(() => {
    if (
      !newWorkexSkill ||
      !Object.values(newWorkexSkill)[0] ||
      Object.values(newWorkexSkill)[0].length === 0
    )
      return;

    let temp = [...workexSkills];
    temp.push(newWorkexSkill);
    setWorkexSkills(temp);
    setWorkEx({ ...workEx, skills: [...temp] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newWorkexSkill]);

  const removeSkill = (id) => {
    let temp = workexSkills.filter((skill) => {
      return id !== skill.skill_id;
    });
    setWorkexSkills(temp);
    setWorkEx({ ...workEx, skills: [...temp] });
  };
  const handleChange = (e) => {
    setWorkEx({
      ...workEx,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (currentlyWork) {
      setWorkEx({ ...workEx, end_date: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyWork]);

  useEffect(() => {
    setNewWorkEx(workEx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workEx]);

  useEffect(() => {
    if (job) {
      setWorkEx({
        ...workEx,
        job_type: job.job_type,
        job_type_name: job.job_type,
        job_type_id: job.job_type_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  useEffect(() => {
    if (company.company_name) {
      if (company.company_id)
        setWorkEx({
          ...workEx,
          company_id: company.company_id,
          company_name: company.company_name,
        });
      else setWorkEx({ ...workEx, company_name: company.company_name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <>
      <div className="mx-5 px-2">
        <div className="row my-2">
          <div className="form-group text-left w-100">
            <label htmlFor="company_name" className="fs-14 fw-500 mb-1">
              Company Name
            </label>
            <Companies
              type={type}
              defaultID={type === "ADD_WORKEX" ? null : newWorkEx.company_id}
              defaultName={type === "ADD_WORKEX" ? null : newWorkEx.company_name}
              state={company}
              setState={setCompany}
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="form-group  text-left w-100">
            <label htmlFor="company_website" className="fs-14 fw-500 mb-1">
              Website
            </label>
            <input
              onChange={handleChange}
              type="text"
              class="form-control shadow_1-lightest profile-field "
              id="company_website"
              aria-describedby="emailHelp"
              value={workEx.company_website}
              placeholder=""
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="form-group text-left w-100">
            <label htmlFor="job_type" className="fs-14 fw-500 mb-1">
              Job Type
            </label>
            <JobTypes
              defaultValue={type === "ADD_WORKEX" ? null : workEx.job_type_name}
              state={job}
              setState={setJob}
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="form-group  text-left w-100">
            <label htmlFor="job_designation" className="fs-14 fw-500 mb-1">
              Job Profile
            </label>
            <input
              onChange={handleChange}
              type="text"
              class="form-control shadow_1-lightest profile-field "
              id="job_designation"
              aria-describedby="emailHelp"
              value={workEx.job_designation}
              placeholder=""
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="form-group  text-left w-100">
            <label htmlFor="job_description" className="fs-14 fw-500 mb-1">
              Job Description
            </label>
            <textarea
              onChange={handleChange}
              class="form-control shadow_1-lightest profile-field "
              id="job_description"
              aria-describedby="emailHelp"
              value={workEx.job_description}
              placeholder=""
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-5 pl-0">
            <div className="form-group text-left w-100">
              <label htmlFor="start_date" className="fs-14 fw-500 mb-1 ">
                Start Date
              </label>
              <input
                onChange={handleChange}
                type="date"
                class="form-control shadow_1-lightest profile-field "
                id="start_date"
                aria-describedby="emailHelp"
                value={workEx.start_date}
                placeholder=""
              />
            </div>
          </div>
          {!currentlyWork && (
            <div className="col-5 pr-0">
              <div className="form-group  text-left w-100">
                <label htmlFor="end_date" className="fs-14 fw-500 mb-1 ">
                  End Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  class="form-control shadow_1-lightest profile-field "
                  id="end_date"
                  aria-describedby="emailHelp"
                  value={workEx.end_date}
                  placeholder=""
                  min={workEx.start_date ? workEx.start_date : null}
                />
              </div>
            </div>
          )}

          <div
            className="col-2 pr-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="form-check">
              <input
                type="checkbox"
                checked={currentlyWork}
                onChange={(e) => setCurrentlyWork(e.target.checked)}
                className="form-check-input my-2"
                id="company-checkbox"
              />
              <label className="form-check-label" htmlFor="company-checkbox">
                <small id="emailHelp" className="form-text my-1 fw-500">
                  Present
                </small>
              </label>
            </div>
          </div>
        </div>

        <div className="row my-2">
          <div className="form-group text-left w-100">
            <label htmlFor="skills" className="fs-14 fw-500 mb-1 t">
              Skills
            </label>
            <Skills
              type={"all"}
              newSkill={newWorkexSkill}
              setNewSkill={setNewWorkexSkill}
            />
            <div className="selected-skill">
              {workexSkills &&
                workexSkills.map((skill) => {
                  return (
                    <span
                      key={skill.skill_id}
                      className={
                        "gradient-5  m-2 px-3 py-3 badge badge-primary skill-badge fs-13"
                      }
                    >
                      {skill.skill_name}
                      <i
                        onClick={() => {
                          removeSkill(skill.skill_id);
                        }}
                        className="fas fa-times-circle pl-1 skill-remove"
                      ></i>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkExperienceForm;

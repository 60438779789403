import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
    backgroundColor: "white",
  },
  chip: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  skill: {
    color: "#007bff",
    backgroundColor: "#e8f3ff",
    borderColor: "#007bff",
  },
}));

export default function Skill({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h6">Skills</Typography>
      </div>
      <div className={classes.chip}>
        {data?.student_user_details?.skill.map((item) => (
          <Chip label={item?.skill_name} variant="outlined" className={classes.skill} />
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    // border: "2px solid #000",
    flexDirection: "row",
  },
  leftPaper: {
    backgroundColor: "#fff",
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    width: "260px",
    // border: "2px solid #000",
    background: "#ffffff",
    paddingTop: "100px",
    position: "fixed",
  },
  leftMenuBox: {
    width: "200px",
    height: "38px",
    marginTop: "15px",
    borderRadius: 5,
    backgroundColor: "#fff",
    color: "black",
  },
  leftMenuBoxSelected: {
    width: "200px",
    height: "38px",
    marginTop: "15px",
    borderRadius: 5,
    backgroundColor: "#CDEDF6",
    fontWeight: "bold",

    color: "black",
  },
  paperStyle: {
    //marginLeft: "280px",
    width: "100%",
    marginTop: "2%",
    // paddingTop: "30px",
    // paddingLeft: "20px",
    // paddingBottom: "15px",
    marginBottom: "35px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    borderRadius: "25px",
  },
  avatarBox: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
  },

  fieldDiv: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    alignItems: "flex-start",
    marginBottom: "30px",
    // border: "2px solid #000",
  },
  heading1: {
    width: "170px",
    // border: "2px solid #000",
    height: "30px",
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
    paddingTop: "8%",
    paddingRight: "30px",
  },
  inputBox: {
    marginLeft: "35px",
    width: "250px",
    paddingLeft: "5px",
    height: "35px",
    // borderRadius: "5px",
  },
  inputBoxE: {
    marginLeft: "35px",
    paddingLeft: "5px",
    height: "35px",
    marginRight: "30px",
    display: "flex",
    alignItems: "center",
    // borderRadius: "5px",
  },
  heading: {
    width: "100px",
    // border: "2px solid #000",
    height: "30px",
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  firstBox: {
    display: "flex",
    flexDirection: "row",
    // border: "1px solid #000",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "100px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "30px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  button: {
    backgroundColor: "#3282C4",
    color: "#fff",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  inputBox2: {
    marginLeft: "35px",
    width: "650px",
    height: "150px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  radioStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

export default styles;

import React from "react";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import { Button, Typography } from "@material-ui/core";

function Approval({email}) {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <SupervisorAccountOutlinedIcon
          style={{ color: "#F1511B", fontSize: "45px" }}
        />

        <Typography
          variant="body1"
          style={{
            fontWeight: "500",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          Your request for approval has been sent to the admin at:
          <span
            style={{
              color: "#007BFF",
            }}
          >
            {" "}
            {email}{" "}
          </span>
        </Typography>
        <Button
          color="primary"
          variant="contained"
          style={{
            boxShadow: "none",
            height: "40px",
            display: "flex",
            borderRadius: "30px",
            fontWeight: "400",
            width: "100%",
          }}
          onClick={() => {
            window.open('https://getwork.org/employer', '_blank')
          }}
        >
          Explore GetWork
        </Button>
      </div>
    </>
  );
}

export default Approval;

import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Divider, Hidden, IconButton, CircularProgress } from "@material-ui/core";
import loginimg from "../../../assets/svg/login/Resume-rafiki.svg";
import { ReactComponent as LoginImg } from "../../../assets/svg/login/Resume-rafiki.svg";

import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { useAlert } from "react-alert";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link, useLocation } from "react-router-dom";
import { BackendBaseApi, NEXT_APP_ENDPOINT } from "../../../constants/constants";
import Footer from "../../../components/public/FooterNew/Footer";
import Navbar from "../../../components/public/Navbar/Navbar";
import Axios from "axios";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
  },
  grid: {
    textAlign: "center",
  },
  heading: {
    fontSize: "40px",
  },
  btn: {
    borderRadius: "30px",
    backgroundColor: theme.palette.domain.main,
    color: "#ebf4f8",
    boxShadow: "none",
    width: "350px",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  divider: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    marginTop: 15,
  },
  upload: {
    borderRadius: "30px",
    // backgroundColor: "#007bff",
    color: "#007bff",
    borderColor: "#007bff",
    boxShadow: "none",
    width: "350px",
  },
  upload1: {
    borderRadius: "30px",
    // backgroundColor: "#007bff",
    color: "#007bff",
    borderColor: "#007bff",
    boxShadow: "none",
  },
}));

export default function ThankYouForApplying() {
  const classes = useStyles();
  const alert = useAlert();

  const location = useLocation();

  const [jobDetails, setJobDetails] = useState([]);
  const getJobDetails = () => {
    const params = new URLSearchParams(location.search); 
    const source = params.get("source");
    const jobIdText = source.split("-")[0];
    const jobId = jobIdText.split("/").slice(-1)[0];
    Axios.get(BackendBaseApi.NIYUKTI + `job/signup_job_detail?job_id=${jobId}`)
      .then((res) => {
        if (res.data.success) setJobDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (location.search.split("job/")[1].split("-")[0]) getJobDetails();
  }, []);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      <Navbar />
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <div>
              {/* <img src={loginimg} height="400px" width="400px" alt="profile" /> */}
              <LoginImg height="400px" width="400px" fill={domain?.domain ? domain?.color_code : "#007bff"} />

              <br />
              <Hidden smDown>
                <Typography className={classes.heading}>Thank You for Applying</Typography>
              </Hidden>
              <Hidden smUp>
                <Typography variant="h5">Thank You for Applying</Typography>
              </Hidden>

              <br />

              <>
                <Typography variant="body2" color="initial">
                  for Job Position{" "}
                  <Link to={`/public/job/${jobDetails?.hiring_type === "ON CAMPUS" ? "oncampus" : "offcampus"}/${location.search.split("job/")[1].split("-")[0]}`}>
                    <span style={domain?.domain ? { color: domain?.color_code } : { color: "#007bff" }}> ({jobDetails?.job_title})</span>
                  </Link>
                  {(jobDetails?.company?.length > 0 || jobDetails.company_details) && (
                    <>
                      {" "}
                      at{" "}
                      <span style={domain?.domain ? { color: domain?.color_code } : { color: "#007bff" }}>
                        {" "}
                        {jobDetails?.company && jobDetails?.company?.length > 0 ? (
                          // <a style={{ marginTop: "10px", color: "#007bff" }} href={`/public/company/profile/${jobDetails?.company[0].company_id}`} target="blank">

                          <b> {jobDetails?.company[0].company_name}</b>
                        ) : (
                          // </a>
                          <b> {jobDetails?.company_details}</b>
                        )}
                      </span>{" "}
                    </>
                  )}
                </Typography>
                <br />
              </>

              <a href={`${NEXT_APP_ENDPOINT}/public/jobs`} target="blank">
                <Button variant="contained" className={classes.btn} style={{ marginBottom: 20 }}>
                  Explore More Jobs
                </Button>
              </a>
              <br />
              <Link to={`/student/complete-profile`}>
                <Button variant="contained" className={classes.btn} style={{ marginBottom: 20 }}>
                  Complete Your Profile
                </Button>
              </Link>
              <br />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}

var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
const checkDomain = (dom) => {
  if (dom.toLowerCase().includes("app")) return "http://appreacttest.getwork.org";
  else return "http://mentorkarttest.getwork.org";
};

const checkDomainmain = (dom) => {
  if (dom.toLowerCase().includes("app")) return "https://app.getwork.org";
  else return "https://mentorkart.getwork.org";
};

const dev = {
  apis: {
    pravesh: "http://praveshtest1.getwork.org/",
    // pravesh: "http://127.0.0.1:8080/",
    niyukti: "http://niyuktitest.getwork.org/",
    sujhav: "http://sujhavtest.getwork.org/",
    cms: "http://cmstest.getwork.org:1337/"
  },
  payment: "https://securegw-stage.paytm.in/order/process",
  app: checkDomain(domain),
  next_app: "http://test.getwork.org",
  mentorkart: "https://mentorkart.org",
  // facebookAppId: "125784129104913",
  facebookAppId: "459405676073963",
  // facebookClientSecret: "0f1138a98b24c282c580dff5d808243d",
  facebookClientSecret: "695a7f383fc7ef80b3536d6cd2dd19c7",
  googleClientId: "1030834411741-bhh7rnbq4qnq6t918soifunk4rbqee9k.apps.googleusercontent.com",
  maintainance: false,
  recruiter: "http://niyojantest.getwork.org:3000"
};

const prod = {
  apis: {
    pravesh: "https://pravesh.getwork.org/",
    niyukti: "https://niyukti.getwork.org/",
    sujhav: "https://sujhav.getwork.org/",
    cms: "https://cms.getwork.org/",
  },
  payment: "https://securegw.paytm.in/order/process",
  app: checkDomainmain(domain),
  next_app: "https://getwork.org",

  mentorkart: "https://app.mentorkart.com",
  // facebookAppId: "3527199753979643",
  facebookAppId: "2112746128897178",
  // facebookClientSecret: "985184d9659e8d8d4e829cd6cbca1b6a",
  facebookClientSecret: "f5c1050a94f92c7512b0db3449033ff1",
  googleClientId: "468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com",
  maintainance: false,
  recruiter: "https://recruiter.getwork.org"
};

const staging = {
  apis: {
    pravesh: "http://praveshstaging.getwork.org/",
    // pravesh: "http://127.0.0.1:8000/",
    niyukti: "http://niyuktistaging.getwork.org/",
    sujhav: "http://sujhavstaging.getwork.org/",
    cms: "http://cmstest.getwork.org:1337/",
  },
  payment: "https://securegw-stage.paytm.in/order/process",
  app: "http://appreactstaging.getwork.org",
  next_app: "http://test.getwork.org",
  mentorkart: "https://mentorkart.org",
  // facebookAppId: "125784129104913",
  facebookAppId: "459405676073963",
  // facebookClientSecret: "0f1138a98b24c282c580dff5d808243d",
  facebookClientSecret: "695a7f383fc7ef80b3536d6cd2dd19c7",
  googleClientId: "1030834411741-bhh7rnbq4qnq6t918soifunk4rbqee9k.apps.googleusercontent.com",
  maintainance: false,
  recruiter: "http://recruiter1.getwork.org"
};

const config = process.env.REACT_APP_ENVIRONMENT === "production" ? prod : process.env.REACT_APP_ENVIRONMENT === "production" ? prod : prod;
export default {
  // Add common config values here
  ...config,
};

import {
  Box,
  Chip,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DuoIcon from "@material-ui/icons/Duo";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScheduleIcon from "@material-ui/icons/Schedule";
import TimerIcon from "@material-ui/icons/Timer";
import React, { Fragment, useEffect, useState } from "react";
import map from "../../../../assets/images/map.png";
import { Istatus } from "../../../../utils/interviewStatus";
import ViewDetails from "./Modal";

const useStyles = makeStyles((theme) =>
  createStyles({
    BoxCard: {
      width: "95.9%",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      backgroundColor: "#fff",
      //   padding: "20px",
      borderRadius: "30px",
      marginLeft: "25px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      backgroundColor: "#ffffff",
      // cursor: "pointer",
      //   "&:hover": {
      //     background: "#cdedf6",
      //   },
    },
    root: {
      fontWeight: "bolder",
      fontSize: "14px",
      border: "2px solid #007BFF",
      color: "#007BFF",
      backgroundColor: "#e3f0ff",
    },
    root1: {
      fontWeight: "bolder",
      fontSize: "14px",
      border: "2px solid #007BFF",
      color: "#007BFF",
      backgroundColor: "#e3f0ff",
      borderRadius: "30px",
    },
    greyBox: {
      width: "100%",
      backgroundColor: "#BDBDBD",
      borderRadius: "0px 0px 4px 4px",
      padding: "5px",
      display: "flex",
      justifyContent: "center",
    },
    InsideGreyBox: {
      width: "95%",
      backgroundColor: "#F2F2F2",
      borderRadius: "30px",
      padding: "20px 0 20px 1px",
      //   boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    },
  })
);

const InterviewCard = (props) => {
  const { data, index, onlyClosed, jobData, setJobData } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [check, setCheck] = useState(false);
  const [tempData, setTempData] = useState();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (tempData !== undefined) {
      var list = [...jobData];

      list = list.filter((item) => item?.interview_id !== tempData?.interview_id);
      setJobData(list);
    }
  }, [tempData]);

  return (
    <div>
      <ViewDetails
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        setOpen={setOpen}
        interviewID={data}
        index={index}
        tempData={tempData}
        setTempData={setTempData}
        profile={data?.job_title}
      />
      <Box className={classes.BoxCard}>
        <Box style={{ padding: "20px" }}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography
                align="left"
                variant="h3"
                style={{ marginBottom: "10px", fontFamily: "Roboto" }}
              >
                <b>
                  {data?.job_title} - {data?.job_type}
                </b>
              </Typography>

              <Typography
                align="left"
                variant="h6"
                style={{ fontFamily: "Roboto" }}
              >
                <b>
                  {data?.interview_type === "ONLINE" ? (
                    "Online Interview"
                  ) : (
                    <>
                      <img src={map} height="18px" alt="map" />
                      &nbsp; {data?.address !== null ? data?.address : null}{" "}
                      -&nbsp;
                      {data?.location?.length > 0 &&
                        data?.location?.map((item, i) => (
                          <Fragment key={i}>
                            {i !== data.location.length - 1 ? (
                              <> {item?.city}, </>
                            ) : (
                              <>{item?.city}</>
                            )}
                          </Fragment>
                        ))}
                    </>
                  )}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Chip
                style={{ fontFamily: "Roboto" }}
                className={classes.root}
                label={data.hiring_type + " JOB"}
                color="primary"
                variant="outlined"
              />
              {/* {onlyClosed === false && (
                <IconButton aria-label="delete" onClick={() => handleOpen()}>
                  <DeleteIcon />
                  <img
                  width="45px"
                  src={delete_icon}
                  />
                </IconButton>
              )} */}
            </Grid>
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              {onlyClosed === false && (
                <IconButton
                  aria-label="delete"
                  onClick={() => handleOpen()}
                  style={{ backgroundColor: "#F8D7DA", color: "#FF0000" }}
                >
                  {/* <DeleteIcon /> */}
                  {/* <img
                    width="45px"
                    src={delete_icon}
                    style={{ marginTop: "-15px" }}
                  /> */}
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "Roboto",
                      color: "#6C757D",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <img src={calendar_icon} alt="calender" height="30px" />{" "} */}
                    <EventAvailableIcon />
                    &nbsp; {data?.start_date?.slice(8, 10)}
                    {"."}
                    {data?.start_date?.slice(5, 7)}
                    {"."}
                    {data?.start_date?.slice(0, 4)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "Roboto",
                      color: "#6C757D",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {/* <img src={clock_icon} alt="calender" height="30px" /> */}
                    <ScheduleIcon />
                    &nbsp;
                    {data?.start_date?.slice(11, 16)}
                    {" - "}
                    {data?.end_date?.slice(11, 16)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    style={{
                      fontFamily: "Roboto",
                      color: "#6C757D",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <img src={stopwatch_icon} alt="calender" height="30px" />{" "} */}
                    <TimerIcon />
                    &nbsp; {data?.time_slot} Mins Each
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          {data?.interview_type === "ONLINE" && data?.hangout_link !== null && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <a
                  href={data?.hangout_link}
                  target="blank"
                  style={
                    onlyClosed === true
                      ? {
                          pointerEvents: "none",
                        }
                      : null
                  }
                >
                  <Typography
                    variant="body2"
                    style={
                      data?.address !== null
                        ? {
                            color: "#007BFF",
                            paddingBottom: "10px",
                            display: "flex",
                            alignItems: "center",
                          }
                        : {
                            color: "#007BFF",
                            display: "flex",
                            alignItems: "center",
                          }
                    }
                  >
                    {" "}
                    {/* <img src={hangout_icon} alt="calender" height="30px" />{" "} */}
                    <DuoIcon />
                    &nbsp;
                    {data?.hangout_link}
                  </Typography>
                </a>
              </Grid>
            </Grid>
          )}
          <br />

          {data?.interviewers?.length > 0 && (
            <>
              {" "}
              <Typography
                align="left"
                variant="body1"
                style={{ fontFamily: "Roboto" }}
              >
                <b>{`${data?.interviewers?.length} Interviewers`}</b>
              </Typography>
              <br />
            </>
          )}

          {data?.interviewers?.length > 0 &&
            data?.interviewers?.map((item, index) => (
              <>
                {" "}
                <Fragment key={index}>
                  <Chip
                    className={classes.root1}
                    label={item}
                    color="primary"
                    variant="outlined"
                  />{" "}
                  &nbsp; &nbsp; &nbsp;
                </Fragment>
                <br />
                <br />
              </>
            ))}

          <Divider />
          <br />
          <Typography
            align="left"
            variant="body1"
            style={{ fontFamily: "Roboto" }}
          >
            <b> {data?.total_slots} Candidates Selected</b>
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={11} style={{ marginTop: "10px" }}>
              {data?.student_data.slice(0, 20).map((item, index) => (
                <Fragment key={index}>
                  <Chip
                    className={classes.root1}
                    label={item.user.first_name + " " + item.user.last_name}
                    color="primary"
                    variant="outlined"
                  />{" "}
                  &nbsp; &nbsp; &nbsp;
                </Fragment>
              ))}
            </Grid>
            <Grid
              item
              xs={1}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {check === false ? (
                <IconButton aria-label="delete" onClick={() => setCheck(true)}>
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="delete" onClick={() => setCheck(false)}>
                  <ExpandLessIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {check === true ? (
            <>
              <br />
              <Typography align="left" variant="body1">
                <b>Interview Timing Details</b>
              </Typography>
              <br />
              <Box className={classes.InsideGreyBox}>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <b>Timings</b>{" "}
                      </Grid>
                      <Grid item xs={3}>
                        <b>Full Name </b>{" "}
                      </Grid>
                      <Grid item xs={6}>
                        <b>Status </b>{" "}
                      </Grid>
                    </Grid>
                  </li>
                  {data?.student_data.map((item, index) => (
                    <li key={index}>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <b>
                            {item?.start_date?.slice(11, 16)} -{" "}
                            {item?.end_date?.slice(11, 16)}
                          </b>{" "}
                        </Grid>
                        <Grid item xs={3}>
                          {item?.user?.first_name} {item?.user?.last_name}
                        </Grid>
                        <Grid item xs={3}>
                          {Istatus(item?.status)}
                        </Grid>
                      </Grid>
                    </li>
                  ))}
                </ul>
              </Box>
              {/* <br />
              <Typography align="left" variant="body1">
                <b>Submitted Assignments</b>
              </Typography>
              <br />
              <Box className={classes.InsideGreyBox}>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <Typography align="left" variant="body2">
                      <b>Vipin Sharma</b> - Vipin Sharma
                    </Typography>
                  </li>
                  <li>
                    <Typography align="left" variant="body2">
                      <b>02:00 - 02:30 pm</b> - Vipin Sharma
                    </Typography>
                  </li>
                </ul>
              </Box> */}
            </>
          ) : null}
        </Box>
      </Box>
    </div>
  );
};

export default InterviewCard;

import {
  Box,
  createStyles, makeStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TReduxStore } from "../../../..";
import NoReport from "../../../../assets/images/reports/no-records.png";
import Loader from "../../../../bundles/common/components/UI/Loader";
import { getCtcByCompany } from "../action";
import ReportsControlPanel from "../ReportsControlPanel";
import ReportsTable from "../ReportsTable";

const CompaniesVisited = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ctcData = useSelector<TReduxStore, any>(
    (state) =>
      //@ts-ignore
      state.Reports.ctcByCompany
  );
  const [loading, setLoading] = useState(false);
  const [tableDownloadData, setTableDownloadData] = useState([]);

  useEffect(() => {
    if (!ctcData.length) {
      dispatch(getCtcByCompany(setLoading));
    }
  }, []);

  const createTableDownloadData = () => {
    let arr = ctcData.map((item: any) => ({
      "Company Name": item.company_name,
      "Contact Person": `${item.company_user.first_name} ${item.company_user.last_name}`,
      Hired: item.hired,
      "Min CTC*": item.ctc_min.toFixed(2),
      "Max CTC*": item.ctc_max.toFixed(2),
      "Avg. CTC*": item.ctc_avg.toFixed(2),
      Contact: `${item.company_user.phone}`,
    }));

    setTableDownloadData(arr);
  };

  useEffect(() => {
    if (ctcData.length) {
      createTableDownloadData();
    }
  }, [ctcData]);

  return loading ? (
    <Loader />
  ) : ctcData.length ? (
    <Box
      padding="2%"
      width="100%"
      style={{ maxHeight: "100%", overflowY: "scroll" }}
    >
      <ReportsControlPanel
        // buttons={["Show Chart", "Export", "Send Email"]}
        buttons={["Export"]}
        title="Companies Visited"
        tableData={tableDownloadData}
      />
      <ReportsTable
        columns={[
          "Company name",
          "Contact Person",
          "Hired",
          "Min CTC*",
          "Max CTC*",
          "Avg. CTC*",
          "Contact",
        ]}
        data={ctcData}
      />
    </Box>
  ) : (
    <Box className={classes.mainBox}>
      <img src={NoReport} alt="no-report" />
      <p>No Reports</p>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    progress: {
      color: "#3282C4",
    },
    mainBox: {},
  })
);

export default CompaniesVisited;

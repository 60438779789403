import {
  AppBar,
  Box,
  Button,
  Container,
  createStyles,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";

import Logo from "../../../assets/images/sidepanel/logo.png";
import CompanyNavbar_C from "../../../bundles/common/components/UI/CompanyNavbar_c";

const CancellationPolicy = () => {
  const classes = useStyles();
  const data = [
    {
      title: "Refund & Cancelation Policies on Pay-Per Hire Payment Plan",

      detail: [
        {
          text: `GetWork shall not refund any amount to the Employers when they opt for pay-per-hire basis. Employers are entitled to pay after hiring the candidate through the platform.  `,
        },
        {
          text: `No refunds will be offered to Employers if they are unhappy with their new recruit after they have successfully made this hiring on the GetWork platform. Assessing the quality of the hire is done by the Employer through the GetWork Service. GetWork only provides assessment tools, training modules, and AI based matching to connect you to the most suitable candidate. How you make the final decision on quality is the sole responsibility of our Employer Partner.`,
        },
        {
          text: `If upon successfully hiring a candidate decides to leave the organization without notice or without a reasonable cause, GetWork will offer no refunds to the Employer Partner. On a case-by-case basis we may give the Employer Partner the option to replace the candidate at a discounted cost. This decision is made by the Dedicated Account Manager assigned to the respective Employer Partner.`,
        },
        {
          text: `If an employer uses the GetWork Service for training and assessment modules only, and are unsatisfied with the reports generated, GetWork will not offer any refunds.`,
        },
        {
          text: `If an Employer upon successful hiring of a student on GetWork decides to revoke an offer letter, due to any reason, will still be charged in full for the hiring completed through the GetWork service.`,
        },
        {
          text: `The Employer Partner can choose to submit an official refund request, which will be reviewed by the GetWork Team, and must include:`,
          subText: [
            "Job Opening Name",
            "Date Posted",
            "Company Name",
            "Contact Name",
            "Reason for Refund",
          ],
        },
      ],
    },
    {
      title: "‍Refund & Cancellation Policies on Subscription Plan",
      detail: [
        {
          text: `If after your company has made a full payment for the annual subscription plan, and you choose to cancel your subscription, you will only be entitled for a refund on the remaining total months available in your plan. (I.e. if you leave the plan halfway through the month, you will receive no refund for that month)`,
        },
        {
          text: `In the case that GetWork Services has not fulfilled our deliverables as per the Contract Agreement signed between GetWork & Employer Partner, the Partner will be entitled for a Refund.`,
        },
        {
          text: `In the case that the Employer Partner fails to uphold the agreements dictated in the Contract Agreement and Terms & Conditions policies, the Partner will not be entitled to any refunds.`,
        },
        {
          text: `The Employer Partner can choose to submit an official cancellation request, which will be reviewed by the GetWork Team, and must include:`,
          subText: [
            "GetWork Onboarding Date",
            "Company Name",
            "Contact Name",
            "Reason for Camcellation",
          ],
        },
      ],
    },
    {
      title:
        "‍Refund & Cancellation Policies for College Partner on Subscription Plan",
      detail: [
        {
          text: `If after your College has made a full payment for the annual subscription plan, and you choose to cancel your subscription, you will only be entitled for a refund on the remaining total months available in your plan. (I.e. if you leave the plan halfway through the month, you will receive no refund for that month)  `,
        },
        {
          text: `In the case that GetWork Services has not fulfilled our deliverables as per the Contract Agreement signed between GetWork & College Partner, the Partner will be entitled for a Refund.`,
        },
        {
          text: `In the case that the College Partner fails to uphold the agreements dictated in the Contract Agreement and Terms & Conditions policies, the Partner will not be entitled to any refunds.`,
        },
        {
          text: `In the case that students enrolled in the College Partner institute is not receiving job opportunities as agreed upon at the time of onboarding, GetWork will:`,
          subText: [
            "Provide a partial refund to College Partner",
            "Provide a discounted subscription offer to College Partners",
          ],
        },
        {
          text: `The College Partner can choose to submit an official cancellation request, which will be reviewed by the GetWork Team, and must include: `,
          subText: [
            "GetWork Onboarding Date",
            "Institution Name",
            "Contact Name",
            "Reason for Refund",
          ],
        },
      ],
    },
    {
      title: "Refund & Cancellations for Events",
      detail: [
        {
          text: `If a College Partner or Employer Partner holds an event via the GetWork Services, the Partner will not receive a refund for cancellation of said event, as per the following conditions:`,
          subText: [
            "Cancellation request was submitted less than 3 days before event date",
            "The Partner was unprepared to host and/or execute Event as per agreed upon standards and deliverables",
            "GetWork has open the registrations for the event on the platform",
          ],
        },
        {
          text: `In the case that a College Partner or Employer Partner charges registration fee from Student user on GetWork, after time of cancellation, Students will be entitled to a full Refund from the respective Partner.`,
        },
        {
          text: `All event cancellation requests must be received via writing (e-mails, official documents) and include:`,
          subText: [
            "Event Title",
            "Contact Name",
            "Invoice #",
            "Reason for Cancellation",
          ],
        },
        {
          text: `In the case that GetWork finds that the general, public or weather conditions are deemed unsafe for an event, GetWork will cancel the event and Partners will be compensated on case-by-case basis.`,
        },
        {
          text: `n the case that at event proceeds as planned and a Student that has registered is unable to attend the event, regardless of cause, that student will not be entitled to a refund.`,
        },
      ],
    },
    {
      title: "Cancellation & Refund Policies for Third Party Vendors",
      detail: [
        {
          text: `
          Any third party vendors or Partners of GetWork will enter a contract prior to collaboration that will dictate the cancellation and refund policies as per terms of that agreement.`,
        },
      ],
    },
  ];

  // useEffect(() => {
  //   console.log("cancel");
  // }, []);

  return (
    <Fragment>
      {!localStorage.getItem("gw_token") ? (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <a href="/login">
              <img src={Logo} width="80px" height="auto" alt="logo" />
            </a>
            <Box className={classes.buttonBox}>
              <a href="/login">
                <Button variant="outlined" className={classes.outlinedButton}>
                  Login
                </Button>
              </a>
              <a href="/signup">
                <Button className={classes.button}>Sign up</Button>
              </a>
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <>
          <CompanyNavbar_C />
        </>
      )}

      <Box bgcolor="#ffffff" padding="10% 12%">
        <Container style={{ width: "80%" }}>
          <h1 style={{ textAlign: "center" }} className={classes.title}>
            Cancellation & Refund Policies
          </h1>

          {data.map((item, index) => (
            <Box paddingTop="2%" key={index}>
              <h1 className={classes.title3}>{item.title}</h1>
              <ol>
                {item.detail.map((item2, index2) => (
                  <Fragment key={index2}>
                    {item2.subtitle && (
                      <p className={classes.subtitle}>{item2.subtitle}</p>
                    )}{" "}
                    <li>
                      <div
                        key={index2}
                        className={classes.details}
                        dangerouslySetInnerHTML={{
                          __html: item2.text,
                        }}
                      />
                      {item2?.subText?.map((item3) => (
                        <ul style={{ listStyleType: "disc" }}>
                          <li>
                            <div
                              key={index2}
                              className={classes.details2}
                              dangerouslySetInnerHTML={{
                                __html: item3,
                              }}
                            />
                          </li>
                        </ul>
                      ))}
                    </li>
                  </Fragment>
                ))}
              </ol>
            </Box>
          ))}

          <p className={classes.EndPara}>
            In general it is important to note that GetWork has a zero tolerance
            policy for any misconduct from any of their users and vendors.
            Should any College Partners, Employer Partners, Students or Third
            Party Vendors be involved in any acts that directly go against our
            Terms & Conditions and Privacy Policies, GetWork has the full right
            to discontinue partnership, dissolve contracts and revoke
            refund/cancellation requests.
          </p>
        </Container>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    EndPara: {
      paddingTop: 60,
      color: "#000",
      fontSize: 18,
      fontFamily: "Mark simonson proxima nova ,sans-serif",
    },
    title: {
      fontSize: "36px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
    },
    helpText: {
      fontSize: "16px",
      fontStyle: "italic",
    },
    warningText: {
      fontSize: "16px",
      fontFamily: "ProximaNona",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    title2: {
      fontSize: "40px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
    },
    title3: {
      fontSize: "22px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      marginTop: "3%",
    },
    details: {
      fontSize: "16px",
      marginBottom: "8px",
      fontFamily: "ProximaNova",
      textAlign: "justify",
    },
    details2: {
      fontSize: "16px",
      fontFamily: "ProximaNova",
      textAlign: "justify",
    },
    subtitle: {
      fontSize: "22px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      marginTop: "3%",
    },
    appBar: {
      width: `100%`,
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      height: "36px",
      borderRadius: 4,
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
      backgroundColor: "#3282c4",
      color: "#ffffff",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      "&:hover": {
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor: "#3282c4",
      },
    },
    outlinedButton: {
      height: "36px",
      borderRadius: 4,
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
      border: "2px solid #3282c4",
      color: "#3282c4",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      "&:hover": {
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor: "#ffffff",
      },
    },
    buttonBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "12%",
    },
    "@media only screen and (max-width: 768px)": {
      buttonBox: {
        width: "48%",
      },
      details: {
        textAlign: "left",
      },
    },
  })
);

export default CancellationPolicy;

import React, { Fragment, useState, useEffect } from "react";

import { Avatar, Box, createStyles, Divider, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { Link, useHistory } from "react-router-dom";
import { checkLinkingHomePage } from "../../../utils/utility";
import PaymentLoading from "../../../assets/company/gif/circles-menu.gif";
import { useDispatch } from "react-redux";

const AvatarMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  var userDetails;
  if (localStorage.getItem("user_type") === "Company") userDetails = JSON.parse(localStorage.getItem("user"));
  else userDetails = JSON.parse(localStorage.getItem("user_details"));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Logout = (user_type) => {
    axios
      .post(BackendBaseApi.PRAVESH + "api/logout/", null, {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
        withCredentials: true
      })
      .then((res) => {
        localStorage.clear();
        if (user_type === "Student") history.push("/login");
        else if (user_type === "Company") history.push("/signup/employer");
        else if (user_type === "College") history.push("/login/college");
        else history.push("/login");
        // dispatch({ type: "USER_LOGOUT" });
      })
      .catch((err) => {
        localStorage.clear();
        if (user_type === "Student") history.push("/login");
        else if (user_type === "Company") history.push("/signup/employer");
        else if (user_type === "College") history.push("/login/college");
        else history.push("/login");
      });
  };
  return (
    <Box display="flex" alignItems="center" marginLeft="30px">
      <Typography className={classes.userName}>
        {" "}
        {userDetails?.first_name ? (
          userDetails?.first_name + " " + userDetails?.last_name
        ) : (
          <img src={PaymentLoading} width="40px" height="auto" style={{ marginLeft: "5px" }} />
        )}{" "}
      </Typography>
      <Avatar
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.avatar}
        src={userDetails?.profile_picture}
        //src={dp}
      />

      <Menu
        style={{ marginTop: "10px", marginLeft: "40px" }}
        id=""
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {localStorage.getItem("user_type") !== "Company" ? (
          <Link to={"/student/dashboard"}>
            <MenuItem onClick={handleClose} className={classes.menuText}>
              {/* <img src={company} fontSize="inherit" alt="" height="20px" style={{ margin: "0px 15px 0px 0px" }} /> */}
              Home
            </MenuItem>
          </Link>
        ) : (
          !localStorage.getItem("show_home_menu") && (
            <Link to={localStorage.getItem("is_pref_filled") === "true" ? "/company/dashboard" : "/company/complete-profile"}>
              <MenuItem onClick={handleClose} className={classes.menuText}>
                {/* <img src={company} fontSize="inherit" alt="" height="20px" style={{ margin: "0px 15px 0px 0px" }} /> */}
                Home
              </MenuItem>
            </Link>
          )
        )}

        <Divider />
        <MenuItem onClick={() => {handleClose();
        Logout(localStorage.getItem("user_type"))
        }} className={classes.logoutText}>
          {/* <img src={logout} fontSize="inherit" alt="" height="20px" style={{ margin: "0px 12px 0px 4px" }} /> */}
          Sign Out
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AvatarMenu;

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      cursor: "pointer",
      border: "solid 1px #f1f1f1;",
    },
    userName: {
      fontSize: "16px",
      color: "#000",

      fontFamily: "Roboto",
      marginRight: "10px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: "#000",
      [theme.breakpoints.up(1000)]: {
        display: "none",
      },
    },
    menuText: {
      fontSize: "15px",
      fontFamily: "Roboto",
      color: "#424242",
    },
    logoutText: {
      fontSize: "15px",
      fontFamily: "Roboto",
      color: "#D64045",
    },
  })
);

// import NewJobCard from '../../Components/NewJobCard/NewJobCard'
import { Box, createStyles, Grid, Hidden, makeStyles } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";
import oj from "../../../assets/images/openjob.png";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import { BackendBaseApi } from "../../../constants/constants";
import { getCookie } from "../../../utils/common/Cookies";
import filterData from "../../../utils/common/filterJob";
import { httpRequest } from "../../../utils/common/httpRequest";
import BlogCard from "../Blog/BlogCard";
import ConnectionCard from "../Connections/ConnectionCard";
import NewJobCard from "../JobsCard/NewJobCard";
import Layout from "../Layout/Layout";
import TopDescriptionSection from "../TopDescriptionSection/TopDescriptionSection";
import AppDownloadDialog from "./AppDownloadDialog";
import "./studentDash.css";
import {instituteIdSelectorFn} from '../../../selector';
import {useSelector} from 'react-redux';

export default function Index({studentData}) {
  const classes = useStyles();

  const [jobsData, setJobsData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [connectionData, setConnectionData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [schJob, setSchJob] = useState([]);
  const [allInterviewJob, setAllInterviewJob] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isJobDataComing, setIsJobDataComing] = useState(false);
  const [isInterviewDataComing, setIsInterviewDataComing] = useState(false);
  const [isScheduledInterviews, setIsScheduledInterviews] = useState(false);
  const [isBlogComing, setIsBlogComing] = useState(false);
  const [isConnectionDataComing, setIsConnectionDataComing] = useState(false);
  const [bannerImage, setBannerImage] = useState();
  const [bannerCta, setBannerCta] = useState();
  const [bannerList, setBannerList] = useState([]);
  let user_type_id = localStorage.getItem("user_type_id");
  const instituteId = useSelector(instituteIdSelectorFn);


  // const [isJobDataComing, setIsJobDataComing] = useState(false);

  useEffect(() => {
    setLoader(true);
    const token = localStorage.getItem("gw_token");
    const data = localStorage.getItem("user_details") ? JSON.parse(localStorage.getItem("user_details")) : studentData;
    setBannerImage(data?.student_user_details?.banner_image && data?.student_user_details?.banner_image[0]?.banner_path);
    setBannerCta(data?.student_user_details?.banner_image && data?.student_user_details?.banner_image[0]?.banner_url);
    setBannerList(data?.student_user_details?.banner_image);
    let filterDetails = [];
    if (data?.student_user_details?.education) {
      filterDetails = data?.student_user_details?.education.filter((item) => item?.is_current === true);
    }
    const checkSpecialization = () => {
      if (filterDetails[0]?.specialization_id === null) return "";
      else return `&specialization_id=${btoa(filterDetails[0]?.specialization_id)}`;
    };

    GetData(
      BackendBaseApi.NIYUKTI,
      `job/student/job/home/?degree_id=${window.btoa(filterDetails[0]?.degree_id)}${checkSpecialization()}`,
      {headers: token},
      setJobsData,
      setIsJobDataComing
    );
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/student/interview/?user_id=${window.btoa(data?.id)}&college_id=${window.btoa(data?.student_user_details?.education[0]?.college)}&status=${btoa(0)}`,
      null,
      setAllInterviewJob,
      setIsInterviewDataComing
    );
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/student/interview/?user_id=${window.btoa(data.id)}&college_id=${window.btoa(data.student_user_details.education[0]?.college)}&status=${btoa(1)}`,
      null,
      setSchJob,
      setIsScheduledInterviews
    );
    GetDataBlog(setBlogData, setIsBlogComing);
    // GetData(BackendBaseApi.PRAVESH, "api/shared/user_connections/?user_type=" + user_type_id, { headers: token }, setConnectionData, setIsConnectionDataComing);
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetDataBlog = async (updateState, dataComing) => {
    fetch(`${BackendBaseApi.CMS}blogs/and?category.id=${1}&_sort=published_at:DESC`)
      .then(response => response.json())
      .then(result => {
        var arr=[];
        result &&
          result.map(item => arr.push(item))
        updateState(arr);
        dataComing(true);
      })
      .catch(error => console.log('error', error));
  };

  const GetData = async (baseUrl, endPoint, body, updateState, dataComing) => {
    let res = await httpRequest(baseUrl, endPoint, body);
    res.data?.results && updateState(res.data.results);
    dataComing(true);
  };

  useEffect(() => {
    if (allData.length) {
      let tempData = filterData(allData, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = React.useState(getCookie("open-modal-app") ? false : true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateData = (jobId) => {};
  return (
    <>
      <BreakpointProvider>
        <Breakpoint large up>
          <Grid container justify="center" style={{ backgroundColor: "#f2f2f2" }}>
            <Layout>
              {!loader ? (
                <>
                  <TopDescriptionSection banner_image={bannerImage} ctaUrl={bannerCta} banner_list={bannerList}/>
                  <div className="student-dash mg-left-10">
                    <Grid container className="relative">
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "0px",
                        }}
                      >
                        <h2 className="fs-20 fw-700 float-left text-green heading heading-underline mg-top-10">Jobs/Internships</h2>

                        {jobsData.length > 0 ? (
                          <NewJobCard data={jobsData.slice(0, 3)} interview={true} apply={true} UpdateData={UpdateData} hideStatus={false} all={true} />
                        ) : isJobDataComing === false ? (
                          <Box>
                            <JobCardSkeleton />
                          </Box>
                        ) : (
                          <>
                            {" "}
                            <img
                              src={oj}
                              align="center"
                              style={{
                                margin: "auto",
                                height: "250px",
                                // paddingLeft: "30%",
                              }}
                            />
                            <p align="center" style={{ color: "black" }}>
                              No Jobs For Now
                            </p>
                            {/* <p align="center">
                              <span style={{ fontSize: "12px" }}>
                                Create Jobs to view open jobs here
                              </span>
                            </p> */}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {jobsData.length > 3 && (
                      <Grid container justify="flex-start">
                        <Grid xs={12} style={{ marginTop: "10px" }}>
                          <Link
                            to="/student/open"
                            className="float-left fs-16 mg-top-10 flex align-item-center"
                            style={{
                              fontSize: "18px",
                              color: "#3282C4",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            View All open jobs{" "}
                            <ArrowRightAltIcon
                              style={{
                                fontSize: "35px",
                                color: "#3282C4",
                              }}
                            />
                          </Link>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container>
                      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                        <div className="flex flex-col align-item-baseline">
                          <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading heading-underline">Interviews</h2>
                          {allInterviewJob.length > 0 ? <h2 className="fs-16 float-left">Invited you</h2> : <h2 className="fs-16 float-left">No interview scheduled yet..</h2>}
                        </div>
                        {jobsData.length > 0 ? (
                          <NewJobCard data={allInterviewJob.slice(0, 3)} setIsReject={setIsReject} isReject={isReject} invite={true} reject={true} all={false} />
                        ) : isInterviewDataComing === false ? (
                          <Box>
                            <JobCardSkeleton />
                          </Box>
                        ) : (
                          <>
                            {" "}
                            <img
                              src={oj}
                              align="center"
                              style={{
                                margin: "auto",
                                height: "250px",
                                // paddingLeft: "30%",
                              }}
                            />
                            <p align="center" style={{ color: "black" }}>
                              No interviews for now.
                            </p>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {allInterviewJob.length > 3 && (
                      <Grid container justify="flex-start">
                        <Grid xs={12} style={{ marginTop: "10px" }}>
                          <Link
                            to="/student/invited"
                            className="connection-link"
                            style={{
                              fontSize: "18px",
                              color: "#3282C4",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            View All Interview Invitations{" "}
                            <ArrowRightAltIcon
                              style={{
                                fontSize: "35px",
                                color: "#3282C4",
                              }}
                            />
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                    {schJob.length > 3 && (
                      <Grid container>
                        <Grid xs={12} style={{ marginTop: "0px" }}>
                          <div className="flex flex-col align-item-baseline">
                            <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading-underline"></h2>
                            {schJob.length > 0 ? <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading heading-underline">Scheduled Interviews</h2> : null}
                          </div>
                          {jobsData.length > 0 ? (
                            <NewJobCard data={schJob.slice(0, 3)} interview={false} reject={false} all={false} />
                          ) : isScheduledInterviews === false ? (
                            <Box>
                              <JobCardSkeleton />
                            </Box>
                          ) : (
                            <>
                              {" "}
                              <img
                                src={oj}
                                align="center"
                                style={{
                                  margin: "auto",
                                  height: "250px",
                                  // paddingLeft: "30%",
                                }}
                              />
                              <p align="center" style={{ color: "black" }}>
                                No interviews scheduled for now.
                              </p>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    )}

                    {schJob.length > 3 && (
                      <Grid container justify="flex-start">
                        <Grid xs={12} style={{ marginTop: "10px" }}>
                          <Link
                            to="/student/scheduled"
                            className="connection-link"
                            style={{
                              fontSize: "24px",
                              color: "#3282C4",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            All scheduled interviews{" "}
                            <ArrowRightAltIcon
                              style={{
                                fontSize: "35px",
                                color: "#3282C4",
                              }}
                            />
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="flex flex-col align-item-baseline">
                          <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading heading-underline">Blog</h2>
                        </div>
                        <BlogCard data={blogData} isBlogComing={isBlogComing} setIsBlogComing={setIsBlogComing} />
                      </Grid>
                    </Grid>
                    {/* <Grid container>
                      <Grid item xs={12}>
                        <div className="flex flex-col align-item-baseline">
                          <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading heading-underline">Connections</h2>
                        </div>
                        <ConnectionCard data={connectionData} />
                      </Grid>
                    </Grid> */}
                  </div>
                </>
              ) : (
                <div>Loading</div>
              )}
            </Layout>
          </Grid>
        </Breakpoint>
        <Breakpoint medium down>
          <Grid container justify="center" style={{ width: "100%", marginTop: 40 }}>
            <TopDescriptionSection banner_image={bannerImage} ctaUrl={bannerCta} banner_list={bannerList}/>
            <Layout>
              <Grid container className="relative">
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                  <h2 className="fs-24 fw-700 float-left text-green heading heading-underline">Jobs/Internships</h2>

                  {jobsData.length > 0 ? (
                    <NewJobCard data={jobsData.slice(0, 3)} interview={true} apply={true} UpdateData={UpdateData} hideStatus={false} all={true} />
                  ) : isJobDataComing === false ? (
                    <Box>
                      <JobCardSkeleton />
                    </Box>
                  ) : (
                    <>
                      {" "}
                      <img
                        src={oj}
                        align="center"
                        style={{
                          margin: "auto",
                          height: "250px",
                          // paddingLeft: "30%",
                        }}
                      />
                      <p align="center" style={{ color: "black" }}>
                        No Jobs for now
                      </p>
                    </>
                  )}
                </Grid>
              </Grid>

              {jobsData.length > 3 && (
                <Grid container justify="flex-start">
                  <Grid xs={12} style={{ marginTop: "10px" }}>
                    <Link
                      to="/student/open"
                      className="connection-link"
                      style={{
                        fontSize: "24px",
                        color: "#3282C4",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      All open jobs{" "}
                      <ArrowRightAltIcon
                        style={{
                          fontSize: "35px",
                          color: "#3282C4",
                        }}
                      />
                    </Link>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                  <div className="flex flex-col align-item-baseline">
                    <h2 className="fs-24 fw-700 float-left text-green mg-top-10 heading heading-underline">Interview</h2>

                    <h2 className="fs-16 float-left">Invited you</h2>
                  </div>

                  {jobsData.length > 0 ? (
                    <NewJobCard data={allInterviewJob.slice(0, 3)} setIsReject={setIsReject} isReject={isReject} invite={true} reject={true} all={false} />
                  ) : isInterviewDataComing === false ? (
                    <Box>
                      <JobCardSkeleton />
                    </Box>
                  ) : (
                    <>
                      {" "}
                      <img
                        src={oj}
                        align="center"
                        style={{
                          margin: "auto",
                          height: "250px",
                          // paddingLeft: "30%",
                        }}
                      />
                      <p align="center" style={{ color: "black" }}>
                        No interviews for now.
                      </p>
                    </>
                  )}
                </Grid>
              </Grid>
              {allInterviewJob.length > 3 && (
                <Grid container justify="flex-start">
                  <Grid xs={12}>
                    <Link
                      to="/student/invited"
                      className="connection-link"
                      style={{
                        fontSize: "24px",
                        color: "#3282C4",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      All Invited interviews <ArrowRightAltIcon />
                    </Link>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                  <div className="flex flex-col align-item-baseline">
                    <h2 className="fs-24 fw-700 float-left text-green mg-top-10 heading heading-underline">Scheduled interviews</h2>
                  </div>
                  {jobsData.length > 0 ? (
                    <NewJobCard data={schJob.slice(0, 3)} interview={false} reject={false} all={false} />
                  ) : isScheduledInterviews === false ? (
                    <Box>
                      <JobCardSkeleton />
                    </Box>
                  ) : (
                    <>
                      {" "}
                      <img
                        src={oj}
                        align="center"
                        style={{
                          margin: "auto",
                          height: "250px",
                          // paddingLeft: "30%",
                        }}
                      />
                      <p align="center" style={{ color: "black" }}>
                        No interviews scheduled for now.
                      </p>
                    </>
                  )}
                </Grid>
              </Grid>
              {schJob.length > 2 && (
                <Grid container justify="flex-start">
                  <Grid item xs={12}>
                    <Link
                      to="/student/scheduled"
                      className="connection-link"
                      style={{
                        fontSize: "24px",
                        color: "#3282C4",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      All scheduled interviews <ArrowRightAltIcon />
                    </Link>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12}>
                  <div className="flex flex-col align-item-baseline">
                    <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading-underline">Blog</h2>
                  </div>
                  <BlogCard data={blogData} isBlogComing={isBlogComing} setIsBlogComing={setIsBlogComing} />
                  <Link to="/student/blog" className="connection_link">
                    View All Blogs <i class="fa fa-arrow-right"></i>
                  </Link>
                </Grid>
              </Grid>
              {/* <Grid container>
                <Grid item xs={12}>
                  <div className="flex flex-col align-item-baseline">
                    <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading heading-underline">Connections</h2>
                  </div>
                  <ConnectionCard data={connectionData} />
                </Grid>
              </Grid> */}
            </Layout>
          </Grid>
        </Breakpoint>
      </BreakpointProvider>
      <Hidden smDown>
        {" "}
        {!instituteId && 
        <AppDownloadDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
        }
        </Hidden>
    </>
  );
}

const useStyles = makeStyles((theme) => createStyles({}));

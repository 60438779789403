import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import Axios from "axios";
import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { TReduxStore } from "../../..";
import EmployeIcon from "../../../assets/icon/newicon.svg";
import DefaultLogo from "../../../assets/images/logo.png";
import VerifiedIcon from "../../../assets/svg/ic_baseline-verified.svg";
import { BackendBaseApi } from "../../../constants/constants";
import ConfirmationDialog from "../../../GlobalComponents/ConfirmationDialog/ConfirmationDialog";
import { updateCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import { CreditInfoProps } from "../../../GlobalComponents/CreditBoard/reducer";
import { JobDataProps } from "../../../pages/college/InviteJob/Invite";
import CommonButton from "../../common/CommonButton/CommonButton";

interface CredentialDataProps {
  first_name: string;
  last_name: string;
  email: string;
  phone: string | number;
}

const CompanyConnectDetail = ({ detail }: { detail: JobDataProps }) => {
  return (
    <span
      style={{
        border: "2px solid #3282c4",
        padding: "2px 8px",
        borderRadius: "12px",
        marginRight: "10px",
      }}
      // onClick={() => handleReject(item)}
    >
      <b
        style={{
          color: "#3282c4",
        }}
      >
        Email: {detail?.company_user?.email}
        <br />
        Phone: {detail?.company_user?.phone}
      </b>
    </span>
  );
};

const CompanyCard = ({ data, invitation, apiCall }: { data: JobDataProps[]; invitation?: boolean; apiCall?: string }) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [inviteClick, setInviteClick] = React.useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const creditInfo = useSelector<TReduxStore, CreditInfoProps>(
    (state) =>
      //@ts-ignore
      state.CreditInfo.creditInfo
  );
  const [showCredentials, setShowCredentials] = useState(false);
  //@ts-ignore
  const [credentialData, setCredentialData] = useState<CredentialDataProps>({});
  const done = useRef([]);

  const handleConnect = async (id: number) => {
    const res: any = await Axios({
      method: "POST",
      url: `${BackendBaseApi.PRAVESH}api/college/company_connect/?company_id=${id}`,
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      data: {
        user_id: localStorage.getItem("user_id"),
        user_type_id: localStorage.getItem("user_type_id")!,
        service_type: "UNLOCK_CREDENTIAL",
        amount: creditInfo?.user_service?.find((item) => item.service_type === "UNLOCK_CREDENTIAL")?.credits,
        transaction_type: "DEBIT",
      },
    }).catch((err) => alert.error(err));
    if (res.data.success) {
      setCredentialData(res.data.data.user_data);
      alert.success("transaction successful");
      setConfirmationDialog(false);
      setShowCredentials(true);
      dispatch(updateCreditInfo(res.data.data.transaction_data));
    } else alert.error(res.data.error);
  };

  const handleDialog = (index: number) => {
    setConfirmationDialog(true);
  };

  return (
    <Box style={{ marginRight: "4%", marginLeft: "4%" }}>
      <Grid container spacing={3} style={{ width: "unset", margin: "unset" }}>
        {data.map((item, index) => (
          <>
            <Grid item xs={12} sm={12}>
              <Box
                className={classes.card}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!confirmationDialog && !showCredentials) {
                    window.open("/public/company/profile/" + item.id);
                  }
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={2} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "center" }}>
                    <img src={item.logo === null ? DefaultLogo : item.logo} width="100%" height="auto" />
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="h3">
                          <b>{item.company_name}</b>
                        </Typography>
                        <Box display="flex" alignItems="center" margin="1% 0%">
                          <img src={EmployeIcon} />
                          &nbsp;
                          <Typography variant="subtitle1" style={{ color: theme.palette.grey[100] }}>
                            {item.size}
                          </Typography>
                          &nbsp;
                          {" • "} &nbsp;
                          <Typography variant="body1">
                            <b>{item.industry}</b>
                          </Typography>
                          {item.is_onboarded && (
                            <>
                              &nbsp;
                              {" • "} &nbsp;
                              <Box display="flex" alignItems="center">
                                <img src={VerifiedIcon} />
                                &nbsp;
                                <Typography color="error">
                                  <b>Verified</b>
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                      {invitation && (
                        <>
                          <Box display="flex" alignItems="center">
                            {item.show_company_detail ? (
                              <CompanyConnectDetail detail={item} />
                            ) : (
                              <CommonButton
                                style={{
                                  marginLeft: "10px",
                                  position: "unset",
                                  marginRight: "10px",
                                  borderRadius: "30px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedCard(index);
                                  handleDialog(index);
                                }}
                              >
                                Connect
                              </CommonButton>
                            )}
                            {/* {item.company_user !== null && (
                              <CommonButton
                                style={{ borderRadius: "30px", position: "unset" }}
                                variant={done.current[index] ? "outlined" : "contained"}
                                disabled={done.current[index]}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedCard(index);
                                  setInviteModal(true);
                                }}
                              >
                                {done.current[index] ? "Invited" : "Invite"}
                              </CommonButton>
                            )} */}
                          </Box>
                          {selectedCard === index && (
                            <ConfirmationDialog
                              open={confirmationDialog}
                              setOpen={setConfirmationDialog}
                              poster={handleConnect}
                              posterArg={item.id}
                              creditsRequired={creditInfo?.user_service?.find((item) => item.service_type === "UNLOCK_CREDENTIAL")?.credits}
                              serviceType={creditInfo?.user_service?.find((item) => item.service_type === "UNLOCK_CREDENTIAL")?.service_type}
                            />
                          )}
                          {selectedCard === index && (
                            <Dialog
                              disableBackdropClick={true}
                              open={showCredentials}
                              onClose={(e) => {
                                setShowCredentials(false);
                              }}
                            >
                              <DialogContent>
                                <Box>
                                  <p style={{ fontSize: "24px" }}>Company HR details:-</p>
                                  <p style={{ fontSize: "18px" }}>Name: {credentialData.first_name + " " + credentialData.last_name}</p>
                                  <p style={{ fontSize: "18px" }}>Emai: {credentialData.email}</p>
                                  <p style={{ fontSize: "18px" }}>Contact: {credentialData.phone}</p>
                                  <p style={{ fontSize: "16px" }}>Please Save these details for your future reference.</p>
                                </Box>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowCredentials(false);
                                  }}
                                >
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                        </>
                      )}
                      {apiCall === "pending" ? (
                        <>
                          {item.show_company_detail ? <CompanyConnectDetail detail={item} /> : null}
                          <span
                            style={{
                              border: "2px solid #3282c4",
                              padding: "2px 8px",
                              borderRadius: "30px",
                            }}
                            // onClick={() => handleReject(item)}
                          >
                            <b
                              style={{
                                color: "#3282c4",
                              }}
                            >
                              Company Action Awaited
                            </b>
                          </span>
                        </>
                      ) : null}
                      {apiCall === "connected" ? (
                        <>
                          {item.show_company_detail ? <CompanyConnectDetail detail={item} /> : null}
                          <span
                            style={{
                              border: "2px solid #3282c4",
                              padding: "2px 8px",
                              borderRadius: "6px",
                            }}
                            // onClick={() => handleReject(item)}
                          >
                            <b
                              style={{
                                color: "#3282c4",
                              }}
                            >
                              Great! Now You are Connected.
                            </b>
                          </span>
                        </>
                      ) : null}
                      {apiCall === "rejected" ? (
                        <>
                          {item.show_company_detail ? <CompanyConnectDetail detail={item} /> : null}
                          <span
                            style={{
                              border: "2px solid #3282c4",
                              padding: "2px 8px",
                              borderRadius: "6px",
                            }}
                            // onClick={() => handleReject(item)}
                          >
                            <b
                              style={{
                                color: "#3282c4",
                              }}
                            >
                              Sorry, your request has been rejeted.
                            </b>
                          </span>
                        </>
                      ) : null}
                    </Box>
                    <Divider />
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={6}>
                          <Box className={classes.detailsBox}>
                            <Typography variant="body1">
                              <b>Details</b>
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <Box className={classes.subDetailsBox}>
                                <Typography>
                                  <b>{item.city}</b>
                                </Typography>
                                <Typography>HQ</Typography>
                              </Box>
                              <Divider orientation="vertical" className={classes.verticalDivider} />
                              {item.year_founded !== null && (
                                <>
                                  <Box className={classes.subDetailsBox}>
                                    <Typography>
                                      <b>{item.year_founded}</b>
                                    </Typography>
                                    <Typography>Founded</Typography>
                                  </Box>
                                  <Divider orientation="vertical" className={classes.verticalDivider} />
                                </>
                              )}

                              <Box className={classes.subDetailsBox}>
                                <Typography>
                                  <b>{item.formatted_time}</b>
                                </Typography>
                                <Typography>on GetWork</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          <Box className={classes.detailsBox}>
                            <Typography variant="body1">
                              <b>Hiring Details</b>
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <Box className={classes.subDetailsBox}>
                                <Typography>
                                  <b>{item.company_user !== null && item.company_user.first_name}</b>
                                </Typography>
                                <Typography>Contact</Typography>
                              </Box>
                              <Divider orientation="vertical" className={classes.verticalDivider} />
                              <Box className={classes.subDetailsBox}>
                                <Typography>
                                  <b>{item.company_user !== null && item.company_user.hiring_total}</b>
                                </Typography>
                                <Typography>Past Hiring</Typography>
                              </Box>
                              <Divider orientation="vertical" className={classes.verticalDivider} />
                              <Box className={classes.subDetailsBox}>
                                <Typography>
                                  <b>{item.company_user !== null && item.company_user.hiring_now}</b>
                                </Typography>
                                <Typography>Hiring Now</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {index === selectedCard && (
              <InviteCompanyModal
                companyId={item.company_user.company_id}
                setInviteClick={setInviteClick}
                inviteClick={inviteClick}
                setDone={done}
                index={index}
                companyName={item.company_name}
                hrName={item.company_user.first_name}
                hrEmail={null}
                inviteModal={inviteModal}
                setInviteModal={setInviteModal}
              />
            )}
          </>
        ))}
      </Grid>
    </Box>
  );
};

interface InviteModalProps {
  companyId: number;
  hrName: string;
  companyName: string;
  hrEmail: string | null;
  inviteClick: any;
  setInviteClick: any;
  setDone: any;
  index: number;
  note?: boolean;
  inviteModal: boolean;
  setInviteModal: any;
}

const InviteCompanyModal = (props: InviteModalProps) => {
  const { companyId, hrName, companyName, hrEmail, inviteClick, setInviteClick, setDone, index, inviteModal, setInviteModal } = props;
  const [openNote, setOpenNote] = useState<boolean>(false);
  const [note, setNote] = useState<any>(null);
  return (
    <Dialog open={inviteModal} onClose={() => setInviteModal(false)}>
      <DialogTitle>You can customize the invitation</DialogTitle>
      <DialogContent>
        <Divider />
        <Box padding="3% 0%">
          <Typography variant="body1">
            Your invitation to <b>{companyName}</b> is on its way. You can add a note to personalize your invitation.
          </Typography>
          {openNote && <textarea className="form-control" onChange={(e) => setNote(e.target.value)} aria-label="With textarea"></textarea>}
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpenNote(!openNote)}>
          {openNote ? "Close Note" : "Add a Note"}
        </Button>
        <Button variant="contained" onClick={() => setInviteModal(false)}>
          Close
        </Button>
        <InviteButton
          companyId={companyId}
          setInviteClick={setInviteClick}
          inviteClick={inviteClick}
          setDone={setDone}
          index={index}
          hrName={hrName}
          companyName={companyName}
          hrEmail={hrEmail}
          note={note}
          inviteModal={inviteModal}
          setInviteModal={setInviteModal}
        />
      </DialogActions>
    </Dialog>
  );
};

const InviteButton = ({ companyId, hrName, companyName, hrEmail, note, setDone, inviteClick, setInviteClick, index, setInviteModal }: InviteModalProps) => {
  const [doneText, setDoneText] = useState(false);
  const alert = useAlert();

  const handelInvite = (event: any) => {
    event.stopPropagation();

    fetch(BackendBaseApi.PRAVESH + "api/college/invite/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      body: JSON.stringify({
        company_id: companyId,
        company_name: companyName,
        hr_name: hrName,
        hr_email: hrEmail,
        note: note,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        alert.success(data.data.message);
        setDone.current[index] = true;
        setInviteClick(!inviteClick);
        setDoneText(true);
        setInviteModal(false);
      })
      .catch((err) => {
        alert.error("Some Error Occured");
        setInviteModal(false);
      });
  };
  return (
    <Button
      className="inviteButton"
      variant="contained"
      style={{ backgroundColor: "#3282C4" }}
      data-toggle="modal"
      data-target={`#${companyId}`}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handelInvite(event);
      }}
    >
      Invite
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[2],
      padding: "1% 2%",
      borderRadius: "30px",
      backgroundColor: theme.palette.background.paper,
      border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none",
    },

    detailsBox: {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.background.default : theme.palette.grey[50],
      marginTop: "4%",
      padding: "4%",
      borderRadius: 25,
    },
    subDetailsBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "4%",
    },
    verticalDivider: {
      height: 50,
      margin: "0% 3%",
    },
  })
);

export default CompanyCard;

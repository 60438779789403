import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import delete1 from "../../../assets/images/delete1.png";
import { BackendBaseApi } from "../../../constants/constants";
import "./styles.css";

export default function UploadStudentData() {
  const classes = useStyles();
  const fileInputRef = useRef();
  const modalImageRef = useRef();
  const modalRef = useRef();
  const progressRef = useRef();
  const uploadRef = useRef();
  const uploadModalRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const alert = useAlert();

  const isGradeSystem = JSON.parse(localStorage.getItem("user_details"))?.is_grade_system;

  const headers = [
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },

    { label: "Batch", key: "batch" },
    { label: "Course", key: "course" },
    { label: "Branch", key: "branch" },
    {
      label: isGradeSystem ? "CGPA" : "Percentage",
      key: isGradeSystem ? "cgpa" : "percentage"
    },
    { label: "10th", key: "tenth" },
    { label: "12th", key: "twelfth" },
    { label: "Status", key: "status" },
    { label: "Contact", key: "contact" },
  ];

  const data = [
    {
      firstname: "",
      lastname: "",
      email: "",
      batch: "",
      course: "",
      branch: "",
      cgpa: "",
      tenth: "",
      twelfth: "",
      status: "",
      contact: "",
    },
  ];

  useEffect(() => {
    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);

      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);
  }, [selectedFiles]);

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;

    if (files.length == 1) {
      handleFiles(files);
    } else {
      alert.error("Drag only one File");
    }
  };

  const filesSelected = (e) => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        files[i]["invalid"] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        setErrorMessage("File type not permitted");
        setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const fileSize = (size) => {
    if (size === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const fileType = (fileName) => {
    return fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;
  };

  const removeFile = (name) => {
    const index = validFiles.findIndex((e) => e.name === name);
    const index2 = selectedFiles.findIndex((e) => e.name === name);
    const index3 = unsupportedFiles.findIndex((e) => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  };

  const openImageModal = (file) => {
    const reader = new FileReader();
    modalRef.current.style.display = "block";
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    };
  };

  const closeModal = () => {
    modalRef.current.style.display = "none";
    modalImageRef.current.style.backgroundImage = "none";
  };

  const uploadFiles = async () => {
    uploadModalRef.current.style.display = "block";
    uploadRef.current.innerHTML = "File Uploaded";
    const fd = new FormData();

    var imagefile = document.querySelector("#file");
    fd.append("file", imagefile.files[0]);

    // formData.append('key', '');

    axios
      .post(
        BackendBaseApi.PRAVESH + "api/college/send/",
        fd,

        {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
            progressRef.current.innerHTML = `${uploadPercentage}%`;
            progressRef.current.style.width = `${uploadPercentage}%`;

            if (uploadPercentage === 100) {
              uploadRef.current.innerHTML =
                "<br><span class='hello123456'>Your Upload is successfull and it will reflect in your data within 3 hours</span> <br><br><a href='/college/students_connections/'> <button class='button1 type='button' >Go to Connections - Students</button></a>";

              validFiles.length = 0;
              setValidFiles([...validFiles]);
              setSelectedFiles([...validFiles]);
              setUnsupportedFiles([...validFiles]);
            } else {
              uploadRef.current.innerHTML = "File Uploading...";
            }
          },
        }
      )
      .catch(() => {
        uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s)</span>`;
        progressRef.current.style.backgroundColor = "red";
      });
  };

  const closeUploadModal = () => {
    uploadModalRef.current.style.display = "none";
  };

  const theme = useTheme();

  return (
    <>
      <>
        <br />
        <div>
          {/* {unsupportedFiles.length === 0 && validFiles.length ? <button className="file-upload-btn" onClick={() => uploadFiles()}>Upload Files</button> : ''} <br/><br/>
                {unsupportedFiles.length ? <p>Unsupported file</p> : ''} */}
          <div className={classes.dropContainer} onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={fileDrop} onClick={fileInputClicked}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="upload-icon"></div>
              <p className={classes.dropText}>
                {" "}
                Drag & Drop your
                <br />
                excel(xlsx, csv) file here
              </p>
              <br />
              <p
                style={{
                  fontSize: "12px",
                  color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
                }}
              >
                or <span style={{ color: "#3282C4" }}>Browse</span> to upload file
              </p>
            </div>
            {validFiles == 0 ? (
              <input ref={fileInputRef} className="file-input" type="file" id="file" onChange={filesSelected} />
            ) : (
              <input
                disabled
                ref={fileInputRef}
                className="file-input"
                type="file"
                id="file"
                // onChange={filesSelected}
              />
            )}
          </div>
          <div className="file-display-container">
            {validFiles.map((data, i) => (
              <div className="file-status-bar" key={i}>
                <div onClick={!data.invalid ? () => openImageModal(data) : () => removeFile(data.name)}>
                  <div className="file-type-logo"></div>
                  <div className="file-type">{fileType(data.name)}</div>
                  <span className={`file-name ${data.invalid ? "file-error" : ""}`}>{data.name}</span>
                  <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className="file-error-message">({errorMessage})</span>}
                </div>
                <div className="file-remove" onClick={() => removeFile(data.name)}>
                  <img src={delete1} height="20px" />
                </div>
              </div>
            ))}
          </div>
          {unsupportedFiles.length === 0 && validFiles.length ? (
            <button className="file-upload-btn" onClick={() => uploadFiles()}>
              Submit File
            </button>
          ) : (
            ""
          )}{" "}
          <br />
          <br />
          {unsupportedFiles.length ? <p>Unsupported file</p> : ""}
        </div>
        <div className="modal" ref={modalRef}>
          <div className="overlay"></div>
          <span className="close1" onClick={() => closeModal()}>
            X
          </span>
          <div className="modal-image" ref={modalImageRef}></div>
        </div>

        <div className="upload-modal" ref={uploadModalRef}>
          <div className="overlay"></div>
          <div className="close1" onClick={() => closeUploadModal()}>
            X
          </div>
          <div className="progress-container">
            <span ref={uploadRef}></span>
            <div className="progress">
              <div className="progress-bar" ref={progressRef}></div>
            </div>
          </div>
        </div>
      </>
      <br />
      <br />

      <div
        style={{
          width: "60%",
          margin: "auto",
          color: theme.palette.type === "dark" ? "#ffffff" : "#212529",
        }}
      >
        <h2
          style={{
            color: theme.palette.type === "dark" ? "#ffffff" : "#212529",
          }}
        >
          Instructions
        </h2>
        1. Student data will be reflected in the next 3 hours.
        <br />
        <br />
        2. All student will be notified through email which contains login credentials of each students account.
        <br />
        <br />
        3. Students need to complete their profile after they receive welcome email from GetWork.
        <br />
        <br />
        4. Excel sheet must include column names:{" "}
        <b>1. First name, 2.Last Name, 3.Email 4. Batch, 5. Course, 6. Branch, 7. {isGradeSystem ? "CGPA" : "Percentage"} , 8. 10th Percentage 9. 12th Percentage 10. Status and 11. Contact</b>
        .<br />
        <br />
        5. Please make sure all the student's details are correct. repetitive or incomplete data will be rejected.
        <br />
        <br />
        <CSVLink data={data} headers={headers} filename={"sample.csv"} style={{ color: "#3282C4" }}>
          6. Click here to download sample file.
        </CSVLink>
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dropContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
      width: 400,
      height: 300,
      border: theme.palette.type === "dark" ? "4px dashed #ffffff" : "4px dashed #2d2d2e",
      borderRadius: 6,
    },
    dropText: {
      fontSize: "32px",
      color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
    },
  })
);

import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import graduated from "../../../../../assets/svg/graduated.svg";
import { ReactComponent as Graduated } from "../../../../../assets/svg/graduated_changable.svg";
import college from "../../../../../assets/svg/login/college.svg";
import { ReactComponent as College } from "../../../../../assets/svg/login/college_changable.svg";
import office from "../../../../../assets/svg/login/office-building.svg";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 8,
    textAlign: "center",
    color: theme.palette.common.black,
    borderRadius: "15px",
    backgroundColor: theme.palette.common.white,
    border: `solid 1px ${theme.palette.domain.main}`,
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
  },
  // paperBlue: {
  //   padding: 8,
  //   textAlign: "center",
  //   color: theme.palette.common.black,
  //   borderRadius: "15px",
  //   backgroundColor: theme.palette.domain.main,
  //   border: `solid 1px ${theme.palette.domain.main}`,
  //   boxShadow: "none",
  // },
  paperBlue: {
    padding: 8,
    textAlign: "center",
    color: theme.palette.common.black,
    borderRadius: "15px",
    backgroundColor: theme.palette.domain.main,
    border: `solid 1px ${theme.palette.domain.main}`,
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
  },
  img: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default function LoginGridDomain({ type }) {
  const classes = useStyles();
  const [selectData, setSelectData] = useState([]);
  const location = useLocation();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  const data_student = [
    {
      title: "College / Institute",
      logo: college,
      link: "/login/college",
    },
    {
      title: "Employer",
      logo: office,
      link: "/signup/employer",
    },
  ];

  const data_college = [
    {
      title: "Student",
      logo: graduated,
      link: "/login/student",
    },
    {
      title: "Employer",
      logo: office,
      link: "/signup/employer",
    },
  ];

  const data_company = [
    {
      title: "Student",
      logo: (
        <Graduated
          height="70px"
          width="70px"
          fill={
            location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" || location.pathname === "/login/student" || location.pathname === ""
              ? "#fff"
              : domain?.color_code
          }
        />
      ),
      link: "/login/student",
      paper:
        location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" || location.pathname === "/login/student" || location.pathname === ""
          ? classes.paperBlue
          : classes.paper,
      selected:
        location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" || location.pathname === "/login/student" || location.pathname === ""
          ? true
          : false,
      details: "Find Exciting Jobs",
    },
    {
      title: domain?.login_page?.grid_name_2,
      logo: <College height="70px" width="70px" fill={location.pathname === "/login/college" ? "#fff" : domain?.color_code} />,
      link: "/login/college",
      paper: location.pathname === "/login/college" ? classes.paperBlue : classes.paper,
      selected: location.pathname === "/login/college" ? true : false,
      details: "Manage Placements",
    },
  ];

  const dataSelect = () => {
    switch ("Company") {
      case "Student":
        setSelectData(data_student);
        break;
      case "College":
        setSelectData(data_college);
        break;
      case "Company":
        setSelectData(data_company);
        break;
      default:
      // code block
    }
  };

  useEffect(() => {
    dataSelect();
  }, [type]);
  const history = useHistory();
  return (
    <>
      <Grid container spacing={3}>
        {selectData?.length > 0 &&
          selectData?.map((items) => (
            <Grid
              item
              xs={6}
              sm={6}
              onClick={() => {
                history.push({
                  pathname: items.link,
                  search: location.search ? location.search : "",
                });
              }}
            >
              {/* <Link to={{ pathname: items.link, search: location.search ? location.search : "" }}> */}
              <Paper className={items.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={4} className={classes.img}>
                    {/* <img src={items.logo} height="70px" width="70px" alt="icons" style={!items.selected ? { color: domain?.color_code } : {}} /> */}
                    {items.logo}{" "}
                  </Grid>
                  <Grid item xs={8} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Typography
                      variant="body1"
                      style={{
                        color: items.selected ? "#fff" : domain?.color_code,
                        fontFamily: " Nunito",
                        fontWeight: "900",
                        marginBottom: 10,
                      }}
                    >
                      <b>{items.title}</b>
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: items.selected ? "#fff" : domain?.color_code,
                        fontFamily: " Nunito",
                        fontWeight: "900",
                      }}
                    >
                      Login
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              {/* </Link> */}
            </Grid>
          ))}
      </Grid>
    </>
  );
}

import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  createStyles,
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Button,
  Menu,
  Divider,
  Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi } from "../../../../constants/constants";
import Axios from "axios";
import Logo from "../../../../assets/images/sidepanel/logo.png";

const CompanyNavbar_C = ({ name, dp }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const userType = localStorage.getItem("user_type");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    //Logout()
  };

  const Logout = () => {
    localStorage.clear();
    Axios.post(BackendBaseApi.PRAVESH + "api/logout/", null, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
      withCredentials: true
    })
      .then((res) => {
        localStorage.clear();
        history.push("/login");
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/login");
      });
  };

  return (
    <Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <a
            href={
              localStorage.getItem("gw_token")
                ? userType === "Company"
                  ? "/company/dashboard"
                  : "/college/dashboard"
                : "/login"
            }
          >
            <img src={Logo} width="80px" height="auto" />
          </a>
          {localStorage.getItem("gw_token") ? (
            <Box display="flex" alignItems="center">
              <Typography className={classes.userName}>{name}</Typography>
              <Avatar
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.avatar}
                src={dp}
              />

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {userType === "Company" && (
                  <>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.menuText}
                    >
                      <Link to="/company/dashboard">Home</Link>
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} className={classes.menuText}>
                  <Link to="/company/profile">Company Profile</Link>
                </MenuItem> */}
                    <MenuItem
                      onClick={handleClose}
                      className={classes.menuText}
                    >
                      <Link to="/company/user-settings">User Settings</Link>
                    </MenuItem>

                    <MenuItem
                      onClick={handleClose}
                      className={classes.menuText}
                    >
                      <Link to="/company/company-settings">
                        Company Settings
                      </Link>
                    </MenuItem>
                  </>
                )}
                {userType === "College" && (
                  <>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.menuText}
                    >
                      <Link to="/college/dashboard">Home</Link>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.menuText}
                    >
                      <Link to="/college/profile">Colege Profile</Link>
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      className={classes.menuText}
                    >
                      <Link to="/college/user-settings">User Settings</Link>
                    </MenuItem>

                    <MenuItem
                      onClick={handleClose}
                      className={classes.menuText}
                    >
                      <Link to="/college/college-settings">
                        College Settings
                      </Link>
                    </MenuItem>
                  </>
                )}

                <Divider />
                <MenuItem
                  onClick={() => {
                    Logout();
                  }}
                  className={classes.logoutText}
                >
                  Sign Out
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Link to="/login">
              <Button variant="contained" className={classes.postButton}>
                LogIn
              </Button>
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      width: `100%`,

      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.up(1000)]: {
        width: "100%",
      },
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
    },
    headerTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000000",
      fontFamily: "ProximaNova",
    },
    headerTitle2: {
      fontSize: "18px",
      color: "#000000",
      fontFamily: "ProximaNova",
    },
    dropdown: {
      width: "220px",
      height: "36px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    searchBox: {
      width: "219px",
      height: "36px",
      background: "#F2F2F2",
      boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 4,
      padding: "0% 2%",
    },
    selectedTabText: {
      color: "#000",
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "ProximaNova",
    },
    normalTabText: {
      color: "#000",
      fontSize: "18px",

      fontFamily: "ProximaNova",
    },
    selectedTab: {
      height: "40px",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      backgroundColor: "#E5E5E5",
      position: "absolute",
      top: "43%",
      borderRadius: "4px 4px 0px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
    },
    normalTab: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
      height: "40px",
    },
    mainContentBox: {
      width: `calc(100% - 220px)`,
      marginLeft: "220px",
      background: "#E5E5E5",
      padding: "1% 2%",
      boxSizing: "border-box",
      display: "flex",
    },
    filterPanel: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      width: "240px",
    },
    menuText: {
      fontSize: "18px",
      fontFamily: "ProximaNova",
      color: "#424242",
    },
    logoutText: {
      fontSize: "18px",
      fontFamily: "ProximaNova",
      color: "#D64045",
    },
    avatar: {
      cursor: "pointer",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: "#000",
      [theme.breakpoints.up(1000)]: {
        display: "none",
      },
    },
    userName: {
      fontSize: "16px",
      color: "rgba(0,0,0,.5)",
      fontFamily: "ProximaNova",
      marginRight: "10px",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "left",
      width: "60%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  })
);

export default CompanyNavbar_C;

import { Box, Chip, Dialog, DialogContent, Divider, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link } from "react-router-dom";
import add from "../../../assets/images/addition.png";
import edit from "../../../assets/images/edit.png";
import { BackendBaseApi } from "../../../constants/constants";
import { Fragment } from "react";
import Editor from "../../common/Editor";

const useStyles = makeStyles((theme) =>
  createStyles({
    deleteButton: {
      background: "#3282C4",
      color: "#fff",
      borderRadius: "30px",
      marginBottom: 15,
      border: "2px solid #3282C4",

      // width: "60%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    deleteButtondisabled: {
      background: "#828282",
      color: "white",
      borderRadius: "30px",
      marginBottom: 15,

      // width: "60%",
    },
    cancelButton: {
      color: "black",
      backgroundColor: "white",
      border: "2px solid black",
      borderRadius: "30px",
      marginBottom: 15,

      "&:hover": {
        backgroundColor: "#F2F2F2",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      fontSize: 13,
      marginRight: "1%",
      marginBottom: "1%",
    },

    reactCalender: {
      border: "0px",
      boxShadow: "0px 9px 20px 6px rgba(0, 0, 0, 0.25)",
      justifyContent: "center",
      borderRadius: 30,
    },
    InnerBox: {
      width: "100%",
      backgroundColor: "#F2F2F2",
      padding: "10px 0 10px 10px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #efefef",
      borderRadius: 30,
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
  })
);

export default function ViewDetails(props) {
  const { handleClose, handleOpen, open, currentStateObject, majorData, setMajorData } = props;
  const alert = useAlert();

  const classes = useStyles();
  const [value, onChange] = useState(new Date());
  const [currentData, setCurrentData] = useState([]);
  const [openCalender, setOpenCalender] = useState(false);
  const [fullDate, setFullDate] = useState(true);
  const [editCalender, setEditCalender] = useState(false);
  const [interviewButton, setInterviewButton] = useState(false);

  const [timeSlot, setTimeSlot] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [perEndTime, setPerEndTime] = useState();

  const [editTimeSlot, setEditTimeSlot] = useState(false);
  const [editTimeStart, setEditTimeStart] = useState(false);
  const [interviewScheduled, SetInterviewScheduled] = useState(false);
  const [interviewType, setInterviewType] = useState("");
  const [cityList, setCityList] = useState({});
  const [city, setCity] = useState();
  const [address, setAddress] = useState("");
  const [editInterviewType, setEditInterviewType] = useState(false);
  const [interviewDescription, setInterviewDescription] = useState();
  const [htmlDesc, setHtmlDesc] = useState("hello");
  const getAllLocations = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/location/city/")
      .then((res) => {
        let temp = {};

        for (let i = 0; i < res.data.length; i++) {
          temp[res.data[i].city] = res.data[i].city_id;
        }
        setCityList({ ...temp });
      })
      .catch((err) => {
        alert.error("Some Error Occured");
      });
  };

  useEffect(() => {
    if (Object.keys(cityList).length === 0 && interviewType === "OFFLINE") getAllLocations();
  }, [interviewType]);

  useEffect(() => {
    if (city) {
      if (!majorData.location.includes(city)) {
        const currentLocation = {
          city: city,
          city_id: cityList[city],
        };
        setMajorData({
          ...majorData,

          location: [...majorData.location, currentLocation],
        });
      }
    }
  }, [city]);

  const removeLocation = (location) => {
    if (majorData.location.length > 0 && majorData.location.includes(location)) {
      let temp = majorData.location.filter((loc) => loc.city_id !== location.city_id);
      setMajorData({
        ...majorData,

        location: temp,
      });
    }
  };

  let newList = [];
  useEffect(() => {
    if (majorData?.student_detail !== undefined) setCurrentData(majorData.student_detail);
  }, [majorData]);

  useEffect(() => {
    calculateEndTime();
  }, [startTime, timeSlot]);

  useEffect(() => {
    while (newList.length > 0) newList.pop();
    perObjectTime();

    majorData.timeSlot = timeSlot;
    majorData.start_date = String(value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate() + " " + startTime);
    majorData.end_date = String(value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate() + " " + endTime);
  }, [startTime, endTime]);
  const calculateEndTime = () => {
    var len = currentData.length;
    var min_fixed;

    var min = len * timeSlot;

    var start_time = String(startTime + ":00");
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }

    var piece = start_time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +min;

    setEndTime(D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60));
  };

  const calculateTime = (start, min, i) => {
    min = min * i;
    var start_time = String(start + ":00");
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }

    var piece = start_time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +min;
    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  };

  const scheduleInterview = () => {
    const data = {
      job_id: majorData.job_id,
      company_id: majorData.company_id,
      user_id: majorData.user_id,
      type: "ONLINE",
      address: null,
      location: null,
      total_slots: majorData.total_slots,

      student_detail: majorData.student_detail,
    };

    var description = currentData.map(
      (item, ind) => `<p><b>${item.start_date.slice(11 - 17)}-${item.end_date.slice(11 - 17)}</b>&nbsp;&nbsp;${item.first_name}&nbsp;${item.last_name}</p>`
    );

    Axios.post(
      BackendBaseApi.NIYUKTI + "job/company/interview/",
      {
        job_id: majorData.job_id,
        company_id: majorData.company_id,
        user_id: majorData.user_id,
        type: interviewType,
        address: interviewType === "OFFLINE" ? address : null,
        location: interviewType === "OFFLINE" ? majorData.location : [],
        total_slots: majorData.total_slots,
        student_detail: majorData.student_detail,
        start_date: majorData.start_date,
        end_date: majorData.end_date,
        time_slot: majorData.timeSlot,
        description: interviewDescription ? interviewDescription : "",
        scheduled_at: {
          status_id: 7,
          status_name: "Interviewed",
          round_id: currentStateObject?.round_id,
          round_name: currentStateObject?.tooltip,
        },
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("Interview Scheduled Successfully");
          SetInterviewScheduled(true);
        } else alert.error("Interview was not scheduled. Some Error occurred.");
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    if (currentData?.length > 0 && majorData.start_date && interviewButton) {
      var description = currentData.map(
        (item, ind) => `<p><b>${item.start_date.slice(11 - 17)}-${item.end_date.slice(11 - 17)}</b>&nbsp;&nbsp;${item.first_name}&nbsp;${item.last_name}</p>`
      );
      setHtmlDesc(String(description));
    }
  }, [majorData.start_date, interviewButton]);

  const perObjectTime = () => {
    let endTime = calculateTime(startTime, timeSlot, 1);
    currentData.forEach((item, index) => {
      item.start_date = String(value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate() + " " + calculateTime(startTime, timeSlot, index));
      item.end_date = String(value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate() + " " + calculateTime(endTime, timeSlot, index));
      item.slot_number = index + 1;
    });
  };
  const cities = Object.keys(cityList).map((City) => {
    return { name: City, value: City };
  });

  const CityList = () => (
    <Autocomplete
      value={city}
      onChange={(event, newValue) => {
        setCity(newValue);
      }}
      id="controllable-states-demo"
      options={cities.map((option) => option.name)}
      style={{ width: "70%" }}
      renderInput={(params) => <TextField {...params} label="Choose Cities" variant="outlined" />}
    />
  );
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,
            // background:
            //   "linear-gradient(15deg, #d0d5dd, #d5d9e1, #d9dee5, #dee2e9, #e2e7ed, #e7ebf2, #ebf0f6, #f0f4fa)",
            // height: 800,
          },
        }}
        onClose={() => {
          handleClose();

          setOpenCalender(false);
          setFullDate(true);
          setEditCalender(false);
          onChange(new Date());
          setInterviewButton(false);
          setTimeSlot();
          setStartTime();
          setEndTime();
          setEditTimeSlot(false);
          setEditTimeStart(false);
          SetInterviewScheduled(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={11}></Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleClose();
                  setOpenCalender(false);
                  setFullDate(true);
                  setEditCalender(false);
                  onChange(new Date());
                  setInterviewButton(false);
                  setTimeSlot();
                  setStartTime();
                  setEndTime();
                  setEditTimeSlot(false);
                  setEditTimeStart(false);
                  SetInterviewScheduled(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {interviewScheduled === false ? (
            <>
              <Typography align="left" variant="body1">
                <b>Schedule an Interview</b>
              </Typography>
              <Typography align="left" variant="body2" style={{ color: "#424242" }}>
                Schedule an Interview with these selected applicants
              </Typography>
              <br />
              {currentData.map((item, ind) => (
                <Chip key={ind} className={classes.root1} label={item.first_name + " " + item.last_name} color="primary" variant="outlined" />
              ))}
              <br /> <br />
              <Divider />
              <br />
              {fullDate === true ? (
                <>
                  <Typography align="left" variant="body1" style={{ color: "#3282C4", cursor: "pointer" }} onClick={() => setOpenCalender(true)}>
                    <img src={add} alt="add" />
                    &nbsp; &nbsp; &nbsp; Choose Date
                  </Typography>
                  <br />
                  {openCalender === true && (
                    <>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Calendar onChange={onChange} value={value} minDate={new Date()} className={classes.reactCalender} />
                      </div>

                      <br />
                      <br />
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          float: "right",
                        }}
                      >
                        <Button className={classes.deleteButton} onClick={() => setFullDate(false)} style={{ marginBottom: 10 }}>
                          Confirm Date{" "}
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Typography align="left" variant="body1" style={{ color: "#3282C4" }}>
                    Date: {value.getDate() + "." + (value.getMonth() + 1) + "." + value.getFullYear()} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditCalender(true);
                      }}
                    >
                      <img src={edit} alt="edit" />
                    </span>{" "}
                  </Typography>
                  <br />
                  {editCalender === true && (
                    <>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <Calendar onChange={onChange} value={value} minDate={new Date()} className={classes.reactCalender} />
                      </div>

                      <br />
                      <br />
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          float: "right",
                        }}
                      >
                        <Button className={classes.deleteButton} onClick={() => setEditCalender(false)}>
                          Confirm Date{" "}
                        </Button>
                      </Box>
                      <br />
                    </>
                  )}
                  {interviewButton === false ? (
                    <>
                      {/* <Divider /> */}
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Typography align="left" variant="body1" style={{ color: "#424242" }}>
                            Interview Type
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <>
                            {/* <FormLabel component="legend">PPO</FormLabel> */}
                            <RadioGroup
                              row
                              aria-label="gender"
                              name="gender1"
                              // value={state.Basics.ppo}
                              onChange={(e) => {
                                setInterviewType(e.target.value);
                              }}
                              style={{ marginTop: -7 }}
                            >
                              <FormControlLabel
                                value="ONLINE"
                                control={
                                  <Radio
                                    classes={{
                                      root: classes.radio,
                                      checked: classes.checked,
                                    }}
                                  />
                                }
                                label="Online"
                              />
                              <FormControlLabel
                                value="OFFLINE"
                                control={
                                  <Radio
                                    classes={{
                                      root: classes.radio,
                                      checked: classes.checked,
                                    }}
                                  />
                                }
                                label="Offline"
                              />
                            </RadioGroup>
                          </>
                        </Grid>
                      </Grid>
                      {interviewType === "OFFLINE" && (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={3}>
                              Interview Location
                            </Grid>
                            <Grid item xs={9}>
                              <CityList />
                              <br />

                              {majorData.location.length > 0 &&
                                majorData.location.map((location, ind) => (
                                  <Chip key={ind} onDelete={() => removeLocation(location)} className={classes.root1} label={location.city} color="primary" variant="outlined" />
                                ))}
                            </Grid>
                          </Grid>
                          <br />
                          <Grid container spacing={3}>
                            <Grid item xs={3}>
                              Address
                            </Grid>
                            <Grid item xs={9}>
                              <TextField
                                id="outlined-name"
                                label="Address"
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                }}
                                type="text"
                                variant="outlined"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                          <br />
                        </>
                      )}

                      {/* <Divider /> */}
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Typography align="left" variant="body1" style={{ color: "#424242", paddingTop: "7%" }}>
                            Time Slot
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-name"
                            label="Time Slot"
                            value={timeSlot}
                            onChange={(e) => {
                              setTimeSlot(e.target.value);
                            }}
                            type="number"
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Typography align="left" variant="body1" style={{ color: "#424242", paddingTop: "7%" }}>
                            mins
                          </Typography>{" "}
                        </Grid>
                      </Grid>
                      <br />
                      {/* <Divider /> */}
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Typography align="left" variant="body1" style={{ color: "#424242", paddingTop: "7%" }}>
                            Start Time (24-hours Format)
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="outlined-name"
                            label="Start Time"
                            value={startTime}
                            onChange={(e) => {
                              setStartTime(e.target.value);
                            }}
                            type="time"
                            variant="outlined"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                      <br />
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          float: "right",
                        }}
                      >
                        {timeSlot && startTime && interviewType ? (
                          <Button className={classes.deleteButton} disabled={false} onClick={() => setInterviewButton(true)}>
                            Next{" "}
                          </Button>
                        ) : (
                          <Button
                            className={classes.deleteButtondisabled}
                            disabled={true}
                            // onClick={() => setEditCalender(false)}
                          >
                            Next{" "}
                          </Button>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* <Divider /> */}
                      <br />
                      {editInterviewType === false ? (
                        <Typography align="left" variant="body1" style={{ color: "#3282C4" }}>
                          Interview Type: {interviewType} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setEditInterviewType(true);
                            }}
                          >
                            <img src={edit} alt="edit" />
                          </span>{" "}
                          <br />
                          {interviewType === "OFFLINE" && (
                            <>
                              Location:{" "}
                              {majorData.location.length > 0 ? (
                                majorData.location.map((item, i) => <Fragment key={i}>{i != majorData.location.length - 1 ? <>{item.city}, </> : <>{item.city}</>}</Fragment>)
                              ) : (
                                <>Online interview</>
                              )}{" "}
                              <br />
                              Address: {address}
                            </>
                          )}
                        </Typography>
                      ) : (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={3}>
                              <Typography align="left" variant="body1" style={{ color: "#424242" }}>
                                Interview Type
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <>
                                {/* <FormLabel component="legend">PPO</FormLabel> */}
                                <RadioGroup
                                  row
                                  aria-label="gender"
                                  name="gender1"
                                  value={interviewType}
                                  onChange={(e) => {
                                    setInterviewType(e.target.value);
                                  }}
                                >
                                  <FormControlLabel
                                    value="ONLINE"
                                    control={
                                      <Radio
                                        classes={{
                                          root: classes.radio,
                                          checked: classes.checked,
                                        }}
                                      />
                                    }
                                    label="Online"
                                  />
                                  <FormControlLabel
                                    value="OFFLINE"
                                    control={
                                      <Radio
                                        classes={{
                                          root: classes.radio,
                                          checked: classes.checked,
                                        }}
                                      />
                                    }
                                    label="Offline"
                                  />
                                </RadioGroup>
                              </>
                            </Grid>
                          </Grid>
                          {interviewType === "OFFLINE" && (
                            <>
                              <Grid container spacing={3}>
                                <Grid item xs={3}>
                                  Interview Location
                                </Grid>
                                <Grid item xs={9}>
                                  <CityList />
                                  <br />
                                  <div className="mt-1 fs-18">
                                    <div className="selected-locations text-left my-2">
                                      {majorData.location.length > 0 &&
                                        majorData.location.map((location, ind) => {
                                          return (
                                            <span key={ind} className="px-3 py-3 badge badge-dark" style={{ marginRight: "2px" }}>
                                              {location.city}
                                              <i
                                                onClick={() => {
                                                  removeLocation(location);
                                                }}
                                                className="ml-3 cp fas fa-times"
                                              ></i>
                                            </span>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <br />
                              <Grid container spacing={3}>
                                <Grid item xs={3}>
                                  Address
                                </Grid>
                                <Grid item xs={9}>
                                  <TextField
                                    id="outlined-name"
                                    label="Address"
                                    value={address}
                                    onChange={(e) => {
                                      setAddress(e.target.value);
                                    }}
                                    type="text"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                  />
                                </Grid>
                              </Grid>
                              <br />
                            </>
                          )}
                          <br />
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              float: "right",
                            }}
                          >
                            <Button className={classes.deleteButton} onClick={() => setEditInterviewType(false)}>
                              Save{" "}
                            </Button>
                          </Box>
                        </>
                      )}
                      <br />

                      {/* <Divider /> */}
                      <br />
                      {editTimeSlot === false ? (
                        <Typography align="left" variant="body1" style={{ color: "#3282C4" }}>
                          Time Slot: {timeSlot} mins &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setEditTimeSlot(true);
                            }}
                          >
                            <img src={edit} alt="edit" />
                          </span>{" "}
                        </Typography>
                      ) : (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={3}>
                              <Typography
                                align="left"
                                variant="body1"
                                style={{
                                  color: "#424242",
                                  paddingTop: "7%",
                                }}
                              >
                                Time Slot
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-name"
                                label="Time Slot"
                                value={timeSlot}
                                onChange={(e) => {
                                  setTimeSlot(e.target.value);
                                }}
                                type="number"
                                variant="outlined"
                                style={{ width: "100%" }}
                                InputProps={{ inputProps: { min: 0 } }}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                align="left"
                                variant="body1"
                                style={{
                                  color: "#424242",
                                  paddingTop: "7%",
                                }}
                              >
                                mins
                              </Typography>{" "}
                            </Grid>
                          </Grid>
                          <br />
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              float: "right",
                            }}
                          >
                            <Button className={classes.deleteButton} onClick={() => setEditTimeSlot(false)}>
                              Save{" "}
                            </Button>
                          </Box>
                        </>
                      )}
                      <br />
                      {/* <Divider /> */}
                      <br />
                      {editTimeStart === false ? (
                        <>
                          <Typography align="left" variant="body1" style={{ color: "#3282C4" }}>
                            Start Time: {startTime} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setEditTimeStart(true);
                              }}
                            >
                              <img src={edit} alt="edit" />
                            </span>{" "}
                          </Typography>
                          <Typography align="left" variant="body1" style={{ color: "#3282C4" }}>
                            End Time: {endTime}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={3}>
                              <Typography
                                align="left"
                                variant="body1"
                                style={{
                                  color: "#424242",
                                  paddingTop: "7%",
                                }}
                              >
                                Start Time (24-hours Format)
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-name"
                                label="Start Time"
                                value={startTime}
                                onChange={(e) => {
                                  setStartTime(e.target.value);
                                }}
                                type="time"
                                variant="outlined"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid item xs={3}></Grid>
                          </Grid>
                          <br />
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              float: "right",
                            }}
                          >
                            <Button className={classes.deleteButton} onClick={() => setEditTimeStart(false)}>
                              Save{" "}
                            </Button>
                          </Box>
                        </>
                      )}
                      <br />
                      <Typography align="left" variant="body2" style={{ color: "black" }}>
                        <b>Scheduled Interviews </b>
                      </Typography>

                      <Typography align="left" variant="body2" style={{ color: "#424242" }}>
                        This Description will be shared among the students{" "}
                      </Typography>
                      <br />
                      <Box>
                        <Editor state={interviewDescription} setState={setInterviewDescription} desc={htmlDesc} />
                      </Box>
                      <br />
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          float: "right",
                        }}
                      >
                        <Button className={classes.deleteButton} onClick={scheduleInterview}>
                          Schedule Interview{" "}
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Typography align="center" variant="body1" style={{ color: "black" }}>
                <b>Success Your Interviews has been scheduled </b>
              </Typography>
              <br />
              <br />
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button className={classes.cancelButton} onClick={() => handleClose()}>
                  Close
                </Button>
                &nbsp; &nbsp;
                <Link to={localStorage.getItem("user_type") === "College" ? "/college/interviews" : "/company/interview"}>
                  <Button className={classes.deleteButton}>Go to Scheduled Interview </Button>
                </Link>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";

import Slide from "@material-ui/core/Slide";
import { createStyles } from "@material-ui/styles";
import { Box, makeStyles } from "@material-ui/core";
import warning from "../../assets/warning1.gif";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LessCredit(props) {
  const { amount, walletAmount } = props;
  const [value, setValue] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (amount > walletAmount) setValue(true);
  }, [amount, walletAmount]);

  return (
    <>
      {value && (
        <Box className={classes.FormBox}>
          <p align="center" className={classes.formLabel}>
            <img src={warning} height="20px" alt="" />
            Your wallet balance is very low. Please Recharge it
          </p>
          <Box
            style={{
              display: "flex",
              float: "center",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              className={classes.preferenceButton}
              variant="contained"
              color="primary"
              component="span"
              onClick={() => {}}
            >
              <span className={classes.labelSize}>Reacharge Now!</span>
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    FormBox: {
      borderRadius: 30,
      //   borderLeft: "4px solid #3282c4",
      background: "white",
      WebkitBackdropFilter: "blur(30px)",
      backdropFilter: "blur(30px)",
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[25],
      padding: 30,
      width: "90%",

      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "solid 2px #F1F1F1",
      marginBottom: 20,
    },
    preferenceButton: {
      borderRadius: 30,
      background: "#3282C4",
      color: "white",
      "&:hover": {
        border: "2px solid #3282C4",
        background: "white",
        color: "#3282C4",
      },
    },
    labelSize: {
      fontSize: "15px",
    },
    formLabel: {
      fontSize: "15px",
      fontWeight: "bold",
    },

    "@media only screen and (max-width: 768px)": {
      FormBox: {
        width: "100%",
      },
      labelSize: {
        fontSize: "12px",
      },
      labelSize: {
        fontSize: 12,
      },
    },
  })
);

import { Tooltip } from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import { BackendBaseApi } from "../../../constants/constants";
import ProfileLinks from "./UI/ProfilLinks";

const StudentProfileLinks = () => {
  const hideSaveButton = useRef();
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [userLinks, setUserLinks] = useState([]);
  const [tempLinks, setTempLinks] = useState([]);
  const [currentLink, setCurrentLink] = useState({
    name: "",
    value: "",
  });
  let user_type_id = localStorage.getItem("user_type_id");

  useEffect(() => {
    setLoading(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/shared/user/?user_type=" + user_type_id, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        setLoading(false);

        if (res.data.success) {
          if (res.data.data.profile_url)
            setUserLinks(res.data.data.profile_url);
        }
      })
      .catch((err) => {
        throw err;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      if (userLinks.length == 0) {
        hideSaveButton.current.classList.add("d-none");
      } else {
        hideSaveButton.current.classList.remove("d-none");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLinks]);

  const showLinkForm = () => {
    setShowForm(true);
  };

  const handleChange = (e) => {
    setCurrentLink({ ...currentLink, value: e.target.value, id: uuidv4() });
  };

  const AddLink = () => {
    let temp = [...userLinks];
    let isValid = true;

    const keys = Object.keys(currentLink);
    for (const key of keys) {
      if (key && !currentLink[key]) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      alert.error("Please fill all the details first");
      return;
    }

    let found = false;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].name == currentLink.name) {
        found = true;
        alert.show(
          "You already have this handle! Delete it first and then add it again"
        );
        return;
      }
    }
    temp.push(currentLink);
    setUserLinks(temp);
    setCurrentLink({ name: "", value: "" });
  };

  const SaveLinks = () => {
    let data = {
      profile_url: userLinks,
    };
    Axios.patch(BackendBaseApi.PRAVESH + "api/shared/user/?user_type=" + user_type_id, data, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          //setUserLinks([...userLinks],currentLink)
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        throw err;
      });
  };

  const EditLink = (id) => {
    let temp = {};
    temp[id] = true;
    setShow({ ...show, ...temp });
  };

  const RemoveLink = (id) => {
    let temp = [...userLinks];
    temp = temp.filter((link) => link.id != id);
    setUserLinks(temp);
  };

  const UndoLink = (id) => {
    setShow({ ...show, [id]: false });
  };

  const updateLink = (e) => {
    setTempLinks({ ...tempLinks, [e.target.id]: e.target.value });
  };

  const ChangeLink = (linkname) => {
    let temp = [...userLinks];
    let id = null;
    temp.forEach((link) => {
      if (link.name == linkname) {
        link.value = tempLinks[linkname];
        id = link.id;
      }
    });
    setUserLinks(temp);
    setShow({ ...show, [id]: false });
  };

  const checkClearBitDomain = (name) => {
    if (name === "LinkedIn") {
      return ".cn";
    } else return ".com";
  };

  return (
    <>
      <div className="container my-4">
        <div className="row my-3 justify-content-center">
          <Tooltip title="Add Link" placement="left">
            <button onClick={showLinkForm} className="add ripple">
              <span>+</span>
            </button>
          </Tooltip>
        </div>

        {showForm && (
          <div className="row my-2 ml-md-5 ml-0">
            <div className="col-md-4 col-12 text-left">
              <ProfileLinks
                state={currentLink}
                setState={setCurrentLink}
                value={currentLink.name}
              />
            </div>
            <div className="col-md-8 col-12 text-left">
              <div className="form-group text-left w-100">
                <div className="row no-gutters">
                  <div className="col-md-8">
                    <input
                      onChange={handleChange}
                      type="text"
                      class="form-control shadow_1-lightest profile-field mt-2"
                      id="linkedin"
                      aria-describedby="emailHelp"
                      placeholder="Enter URL"
                      value={currentLink.Value || currentLink.value}
                    />
                  </div>
                  <div className="col-md-2 ml-2 my-2 py-1">
                    <button
                      onClick={AddLink}
                      className="btn-info btn-sm shadow-main cp"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="card job-card-main">
            <div
              className="card-body"
              style={{ boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)" }}
            >
              {userLinks.length > 0 ? (
                userLinks.map((link, ind) => {
                  return (
                    <Fragment key={ind}>
                      <div className="row my-3" key={link.id}>
                        <div className="col-md-4 col-12">
                          <div className="row">
                            <div className="col-6 text-md-center text-left">
                              <img
                                src={
                                  "//logo.clearbit.com/" +
                                  link.name +
                                  checkClearBitDomain(link.name)
                                }
                                style={{ height: "40px", width: "auto" }}
                              />
                            </div>
                            <div className="col-6 text-left py-2">
                              <span className="fs-14 fw-500 text-uppercase">
                                {link.name || (
                                  <Skeleton width={100} duration={2} />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-12 text-left py-2">
                          <div className="row no-gutters">
                            <div className="col-md-10 col-12">
                              {show[link.id] && show[link.id] == true ? (
                                <>
                                  <div className="row">
                                    <div className="col-md-9 col-12">
                                      <input
                                        type="text"
                                        onChange={updateLink}
                                        class="form-control shadow_1-lightest profile-field"
                                        id={link.name}
                                        aria-describedby="emailHelp"
                                        placeholder={link.Value || link.value}
                                      />
                                    </div>
                                    <div className="col-md-3 col-12">
                                      <button
                                        onClick={() => {
                                          ChangeLink(link.name);
                                        }}
                                        className="mt-1 btn btn-main btn-sm shadow-main"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <span className="fs-14">
                                  {link.Value || link.value || (
                                    <Skeleton width={100} duration={2} />
                                  )}
                                </span>
                              )}
                            </div>
                            <div className="col-md-2 col-12 fs-13">
                              {show[link.id] && show[link.id] == true ? (
                                <i
                                  onClick={() => UndoLink(link.id)}
                                  className="fas fa-redo-alt edit-icon "
                                ></i>
                              ) : (
                                <i
                                  onClick={() => {
                                    EditLink(link.id);
                                  }}
                                  className="fas fa-edit edit-icon "
                                ></i>
                              )}
                              <i
                                onClick={() => RemoveLink(link.id)}
                                className="fas fa-trash edit-icon mx-2"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })
              ) : (
                <div>
                  Let recruiters know more about you. Add your social handles
                  now!
                </div>
              )}
              <div className="row justify-content-center my-4">
                <button
                  ref={hideSaveButton}
                  onClick={SaveLinks}
                  className="btn btn-main btn-sm shadow-main update-btn"
                >
                  Save Links
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4 pt-2 justify-content-center">
          {/* <button onClick={SaveLinks} className="btn btn-main btn-sm shadow-main update-btn">Save Links</button> */}
          <a
            href="https://clearbit.com"
            className="fs-12 text-muted attribution-text"
          >
            Logos provided by Clearbit
          </a>
        </div>
      </div>
    </>
  );
};

export default StudentProfileLinks;

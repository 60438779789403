import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "./ViewJob.css";
import Navbar from "../../../components/public/Navbar/Navbar";
import JobOverview from "../../../components/common/viewJob/JobOverview";
import JobApplication from "../../../components/common/viewJob/JobApplication";
import JobSuccessPage from "../../../components/common/viewJob/JobSuccessPage";
import { makeStyles } from "@material-ui/core/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";

import JobSharerButton from "../../../components/common/JobSharer/JobSharerButton";
import { jobSharerDetail } from "../../../utils/utility";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import Axios from "axios";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { BackendBaseApi } from "../../../constants/constants";
import Loader from "../UI/Loader";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  root2: {
    fontWeight: "bolder",
    border: "2px solid red",
    color: "red",
  },
  root1: {
    fontWeight: "bolder",
    border: "2px solid #26a95b",
    color: "#26a95b",
  },
  root: {
    fontWeight: "bolder",
    border: "2px solid #3282C4",
    color: "#3282C4",
  },
  "@media only screen and (max-width: 768px)": {},
});

// Axios.defaults.withCredentials = window.location.hostname === 'localhost' ? false : true;
// Axios.defaults.withCredentials = true;

function ViewJob() {
  const { job_id, action } = useParams();

  const location = useLocation();
  const [jobData, setJobData] = useState();
  const [applySuccessful, setApplySuccessful] = useState(false);
  const [isUserApplied, setIsUserApplied] = useState(false);
  const [collegeJobUrl, setCollegeJobUrl] = useState("");

  const alert = useAlert();

  const [navValue, setNavValue] = React.useState("overview");
  const [applicationFirst, setApplicationFirst] = React.useState(false);
  const [successFirst, setSuccessFirst] = React.useState(false);
  const history = useHistory();
  useEffect(() => {
    if (jobData) if (applicationFirst && !localStorage.getItem("gw_token") && jobData.job_status === "OPEN") setNavValue("application");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationFirst]);

  useEffect(() => {
    if (jobData) if (successFirst && jobData.job_status === "OPEN") setNavValue("success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successFirst]);

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
  };

  useEffect(() => {
    const urlParams = location.pathname.split("/");
    const jobID = parseInt(job_id.split("-")[0]);

    if (jobID) {
      let jobUrl = BackendBaseApi.NIYUKTI + `job/?job_id=${btoa(jobID.toString())}`;

      Axios.get(jobUrl, {
        headers: {
          ...(localStorage.getItem("gw_token") && { Authorization: `Token ${localStorage.getItem("gw_token")}` }),
        },
      })
        .then((res) => {
          if (res.data.success) {
            let detail = res.data.data;
            let obj = {
              job_id: detail.id,
              company: detail.company,
              company_id: detail.company?.company_id || "",
              company_name: detail.company?.company_name || "Company Name",
              company_location: detail.company?.company_location || "Company Location",
              company_website: detail.company?.company_website || "",
              company_description: detail.company?.company_description || "",
              company_logo: detail.company?.company_logo || "",
              company_size: detail.company?.company_size || "",
              industry_name: detail.company?.industry_name || "",
              job_title: detail.job_title || "",
              job_type_name: detail.job_type_name || "",
              colleges: detail.college || [],
              job_location: detail.job_location || [],
              job_rounds: detail.job_rounds || [],
              documents: detail.documents || [],
              job_description: detail.job_description || "",
              apply_end_date: detail.apply_end_date || "",
              apply_start_date: detail.apply_start_date || "",
              ctc_max: detail.ctc_max || "",
              ctc_min: detail.ctc_min || "",
              employment_type_name: detail.employment_type_name || "",
              equity_max: detail.equity_max || "",
              equity_min: detail.equity_min || "",
              hiring_type: detail.hiring_type || "",
              job_duration_end: detail.job_duration_end || "",
              job_duration_start: detail.job_duration_start || "",
              job_role_name: detail.job_role_name || "",
              job_status: detail.job_status || "",
              salary_payment_type: detail.salary_payment_type || "",
              salary_type: detail.salary_type || "",
              stipend: detail.stipend || "",
              service_bond: detail.service_bond || "",
              vacancy: detail.vacancy || "",
              ppo: detail.ppo ? true : false,
              work_from_home: detail.work_from_home ? true : false,
              skills: detail.eligibility_criteria?.skills || "",
              percentage: detail.eligibility_criteria?.percentage || "",
              experience: detail.eligibility_criteria?.experience || [],
              graduation_years: detail.eligibility_criteria?.graduation_years || [],
              education_levels: detail.eligibility_criteria?.education_levels || [],
              courses: detail.eligibility_criteria?.courses || [],
              courses_rank: detail.eligibility_criteria?.courses_rank || [],
              degrees: detail.eligibility_criteria?.degrees || [],
              degrees_rank: detail.eligibility_criteria?.degrees_rank || [],
              up_coming_state: detail?.up_coming_state || {},
            };
            let qualifications = {};
            obj.courses.forEach((item) => {
              if (qualifications[item.degree_name] === undefined) {
                qualifications[item.degree_name] = [{ ...item }];
              } else {
                qualifications[item.degree_name].push({ ...item });
              }
            });
            obj["qualifications"] = qualifications;
            setJobData(obj);
            setIsUserApplied(detail.is_user_applied);
            setCollegeJobUrl(detail.college_job_url);
            action === "apply" ? setApplicationFirst(true) : setApplicationFirst(false);
            if (location?.state && location.state.is_success) {
              setApplicationFirst(false);
              setSuccessFirst(true);
            }
          } else {
            alert.error(res.data.error);
            if (res.data.error === "No Job Found") history.push("/public/not-found");
          }
        })
        .catch((err) => {
          alert.error("Error");
        });
    } else {
      alert.error("Job ID not provided !!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="view__job__page">
      {jobData ? (
        <div id="job__view" className="overflow-x-hidden">
          <Navbar />
          <div id="company_job_details">
            <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {jobData.company_logo && <img src={jobData.company_logo} id="public_job_page_company_logo" alt="logo" />}
                <span
                  id="view_job_comp_name"
                  onClick={(e) => {
                    jobData.company_id && window.open(`/public/company/profile/${jobData.company_id}`);
                  }}
                  style={jobData.company_id ? { cursor: "pointer" } : {}}
                >
                  {jobData.company_name}
                </span>
              </div>
              <span id="view_job_job_title">{jobData.job_title}</span>
              <span id="view_job_comp_loc">
                {jobData.job_location.length > 0 ? jobData.job_location.map((e) => e.city).join(", ") : "PAN India"}
                <span style={{ margin: "0 3px" }}>&bull;</span>
                {jobData.employment_type_name && jobData.employment_type_name}
              </span>
            </div>
            <div style={{ margin: "0.5rem auto" }}>
              <JobSharerButton detail={jobSharerDetail(jobData)} page="PUBLIC" />
            </div>
          </div>
          <div id="shared-tabs-styles">
            <BottomNavigation value={navValue} showLabels onChange={handleNavChange} id="mui_job_nav">
              <BottomNavigationAction label="Overview" value="overview" icon={<FindInPageIcon />} />
              {!localStorage.getItem("gw_token") && !applySuccessful && jobData.job_status === "OPEN" && jobData.up_coming_state?.code === 0 && (
                <BottomNavigationAction label="Application" value="application" icon={<CardTravelIcon />} />
              )}
              {applySuccessful || (successFirst && <BottomNavigationAction label="Success" value="success" icon={<CheckCircleOutlineIcon />} />)}
            </BottomNavigation>
          </div>
          {navValue === "overview" && (
            <div id="job__details__overview">
              <JobOverview jobData={jobData} 
              setApplySuccessful={setApplySuccessful} 
              setNavValue={setNavValue} 
              isUserApplied={isUserApplied} 
              setIsUserApplied={setIsUserApplied}
              collegeJobUrl={collegeJobUrl} />
            </div>
          )}
          {navValue === "application" && (
            <div id="job__details__apply">
              {!localStorage.getItem("gw_token") && (
                <JobApplication
                  jobID={jobData.job_id}
                  setApplySuccessful={setApplySuccessful}
                  setNavValue={setNavValue}
                  isUserApplied={isUserApplied}
                  setIsUserApplied={setIsUserApplied}
                />
              )}
            </div>
          )}
          {navValue === "success" && (
            <div id="job__success">
              <JobSuccessPage alert={alert} jobData={jobData} />
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ViewJob;

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  alpha,
  AppBar,
  Avatar,
  Button,
  CircularProgress,
  Typography,
  useTheme
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../constants/constants";
import user from "../../../Assets/Images/png/aisha.png";
import { CustomTextField } from "../../Common/Input/InputField";

export default function FirstCheck({
  step,
  setStep,
  finalNoticePeriod,
  setFinalNoticePeriod,
  finalClickedIndex,
  setFinalClickedIndex,
  getContinueButtonId,
  name
}) {
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const theme = useTheme();

  const [noticePeriodError, setNoticePeriodError] = useState();
  const [noticePeriod, setNoticePeriod] = useState(finalNoticePeriod);
  const [clickedIndex, setClickedIndex] = useState(finalClickedIndex);
  // const [isLoading, setIsLoading] = useState(false);

  const gwToken = localStorage.getItem("gw_token");
  const signupName = localStorage.getItem("signupName");

  const updateNoticePeriod = () => {
    
    if (clickedIndex === 3 && !noticePeriod && noticePeriod !== 0) {
      setNoticePeriodError("Please tell us when you will join");
      return;
    } else {
      setLoading(true);
      Axios.post(
        `${BackendBaseApi.PRAVESH}api/student/signup/notice-period/`,
        {
          notice_period: noticePeriod || noticePeriod === 0 ? noticePeriod : null,
        },
        {
          headers: {
            Authorization: "Token " + gwToken,
          },
          withCredentials: true,
        }
      )
        .then((res) => {
          setLoading(false);
          setFinalClickedIndex(clickedIndex);
          setFinalNoticePeriod(noticePeriod);
          setStep(step + 1);
        })
        .catch((err) => {
          setLoading(false);
          alert.error();
        });
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar alt="user-img" src={user} sx={{ width: 66, height: 66 }} />
          <Typography
          // variant="h4"
          // style={{ fontWeight: "600", lineHeight: 1.8, marginTop: 5 }}
          >
            Hi <span style={{ fontWeight: "500"}} > {" "}{name}!{" "} </span> This is Aisha, your GetWorks Online Assistant
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: "500", lineHeight: 1.4, marginTop: 5 }}
          >
            Before we start, what <br />
            could be the best of you today?
          </Typography>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} md={12} style={{ display: "grid" }}>
            <Button
              onClick={() => 
                {
                  setNoticePeriod(null);
                  setClickedIndex(clickedIndex === 1 ? 0 : 1)
                }
              }
              // endIcon={<ArrowForwardRoundedIcon />}
              sx={{
                height: "40px",
                position: "relative",
                boxShadow: "none",
                borderRadius: "30px",
                display: "flex",
                fontWeight: "400",
                fontSize: "15px",
                backgroundColor: `${
                  clickedIndex !== 1
                    ? "#fff"
                    : alpha(theme.palette.primary.main, 0.1)
                }`,
                color: `${
                  clickedIndex !== 1 ? "#6C757D" : theme.palette.primary.main
                }`,
                borderWidth: "2px",
                borderColor: `${
                  clickedIndex !== 1 ? "#6C757D" : theme.palette.primary.main
                }`,
              }}
              variant="outlined"
            >
              {clickedIndex !== 1 ? (
                ""
              ) : (
                <CheckCircleRoundedIcon
                  color="primary"
                  style={{
                    fontSize: "20px",
                    position: "absolute",
                    left: "15px",
                  }}
                />
              )}
              Just Browsing
            </Button>
          </Grid>
          <Grid xs={12} md={12} style={{ display: "grid" }}>
            <Button
              onClick={() => {
                setNoticePeriod(null);
                setClickedIndex(clickedIndex === 2 ? 0 : 2)
              }}
              // endIcon={<ArrowForwardRoundedIcon />}
              sx={{
                height: "40px",
                boxShadow: "none",
                borderRadius: "30px",
                display: "flex",
                position: "relative",
                fontWeight: "400",
                fontSize: "15px",
                backgroundColor: `${
                  clickedIndex !== 2
                    ? "#fff"
                    : alpha(theme.palette.primary.main, 0.1)
                }`,
                color: `${
                  clickedIndex !== 2 ? "#6C757D" : theme.palette.primary.main
                }`,
                borderWidth: "2px",
                borderColor: `${
                  clickedIndex !== 2 ? "#6C757D" : theme.palette.primary.main
                }`,
              }}
              variant="outlined"
            >
              {clickedIndex !== 2 ? (
                ""
              ) : (
                <CheckCircleRoundedIcon
                  color="primary"
                  style={{
                    marginRight: "4px",
                    fontSize: "20px",
                    position: "absolute",
                    left: "15px",
                  }}
                />
              )}
              I’m employed but open to new job
            </Button>
          </Grid>{" "}
          <Grid xs={12} md={12} style={{ display: "grid" }}>
            <Button
              onClick={() => setClickedIndex(clickedIndex === 3 ? 0 : 3)}
              // endIcon={<ArrowForwardRoundedIcon />}
              sx={{
                height: "40px",
                boxShadow: "none",
                borderRadius: "30px",
                position: "relative",
                display: "flex",
                fontWeight: "400",
                fontSize: "15px",
                backgroundColor: `${
                  clickedIndex !== 3
                    ? "#fff"
                    : alpha(theme.palette.primary.main, 0.1)
                }`,
                color: `${
                  clickedIndex !== 3 ? "#6C757D" : theme.palette.primary.main
                }`,
                borderWidth: "2px",
                borderColor: `${
                  clickedIndex !== 3 ? "#6C757D" : theme.palette.primary.main
                }`,
              }}
              variant="outlined"
            >
              {clickedIndex !== 3 ? (
                ""
              ) : (
                <CheckCircleRoundedIcon
                  color="primary"
                  style={{
                    marginRight: "4px",
                    fontSize: "20px",
                    position: "absolute",
                    left: "15px",
                  }}
                />
              )}
              I’m actively looking for job
            </Button>
          </Grid>
          {clickedIndex !== 3 ? (
            ""
          ) : (
            <>
              {/* <Grid xs={12} md={12} >
                <Typography
                  variant="body2"
                  color="initial"
                  style={{ color: "#6C757D" }}
                >
                  Tell us your notice period
                </Typography>
              </Grid> */}
              <Grid xs={12} md={12} sx={{ display: "grid", paddingTop: "5px" }}>
                <Typography
                  variant="body2"
                  color="initial"
                  style={{ color: "#6C757D", marginBottom: "7px" }}
                >
                  Tell us your notice period
                </Typography>

                <CustomTextField
                  variant="outlined"
                  size="small"
                  label="Notice Period (in days)"
                  type="number"
                  required
                  value={noticePeriod}
                  onChange={(e) => {
                    setNoticePeriod(e?.target?.value);
                    setNoticePeriodError("");
                  }}
                  error={noticePeriodError ? true : false}
                  helperText={noticePeriodError ? noticePeriodError : ""}
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <AppBar
        color="inherit"
        sx={{
          boxShadow: "none",
          position: "relative",
          marginTop: "10px",
          [theme.breakpoints.only("sm")]: {
            top: "auto",
            bottom: 0,
            boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
            position: "fixed",
          },
          [theme.breakpoints.only("xs")]: {
            top: "auto",
            bottom: 0,
            boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
            position: "fixed",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} sm={12} md={12}>
            <Button
              color="primary"
              endIcon={<ArrowForwardRoundedIcon />}
              sx={{
                height: "40px",
                width: "100%",
                boxShadow: "none",
                borderRadius: "30px",
                display: "flex",
                fontWeight: "400",
                fontSize: "15px",
              }}
              variant="contained"
              onClick={() => {
                updateNoticePeriod();
              }}
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                <span id={getContinueButtonId()}> Continue</span>
              )}
            </Button>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi } from "../../../../constants/constants";
import Loader from "../UI/Loader";
import { useDispatch } from "react-redux";

const StudentHome = () => {
  const location = useLocation();
  const history = useHistory();
  let token = "";
  const [userDetails, setUserDetails] = useState({});
  const [educationDetails, setEducationDetails] = useState({});

  useEffect(() => {
    if (location.data) {
      token = location.data.token;
      setUserDetails({
        first_name: location.data.first_name,
        last_name: location.data.last_name,
        email: location.data.email,
        education_level: location.data.educational_details[0].education_level,
        degree: location.data.educational_details[0].degree,
        specialization: location.data.educational_details[0].specialization,
      });
      return;
    }
    token = localStorage.getItem("gw_token");
    getUserInfo(token);
  }, []);

  const getUserInfo = (token) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        setUserDetails(res.data.data[0]);
        setEducationDetails(res.data.data[0].educational_data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear();
    axios
      .get(BackendBaseApi.PRAVESH + "api/logout/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true
      })
      .then((res) => {
        localStorage.clear();
        dispatch({ type: "USER_LOGOUT" });
      })
      .catch((err) => {
        localStorage.clear();
        console.log(err);
      });
    history.push("/login");
  };
  if (!userDetails)
    return (
      <>
        <Loader />
      </>
    );
  else
    return (
      <>
        <h1>This is dummy home component</h1>
        <h3>
          Hello {userDetails.first_name} {userDetails.last_name}
        </h3>
        {educationDetails && (
          <>
            <h5>{educationDetails.college}</h5>
            <h5>{educationDetails.degree}</h5>
            <h5>{educationDetails.education_level}</h5>
            <h5>{educationDetails.specialization}</h5>
          </>
        )}

        <h5>{userDetails.email}</h5>
        <button onClick={logout} className=" shadow_1 btn btn-lg btn-main btn-login text-uppercase font-weight-bold mb-2">
          Logout
        </button>
      </>
    );
};

export default StudentHome;

import { Box, styled, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi } from "../../constants/constants";
import PublicLayout from "../Components/Layout/PublicLayout";
import CurrentEducation from "../Components/Section/LoginRightSection/CurrentEducation";
import FirstCheck from "../Components/Section/LoginRightSection/FirstCheck";
import InterestedJobArea from "../Components/Section/LoginRightSection/InterestedJobArea";
import JobPreferences from "../Components/Section/LoginRightSection/JobPreferences";
import RegisterBasicInfo from "../Components/Section/LoginRightSection/RegisterBasicInfo";
import StrongSkills from "../Components/Section/LoginRightSection/StrongSkills";
import {useParams} from "react-router-dom";
import {useAlert} from "react-alert";
import { CircularProgress } from "@material-ui/core";

function LoginFlow() {
  const [step, setStep] = useState(2);
  const history = useHistory();
  const alert = useAlert();

  //name
  const [username, setUsername] = useState();

  // First Check States
  const [finalNoticePeriod, setFinalNoticePeriod] = useState();
  const [finalClickedIndex, setFinalClickedIndex] = useState(0);

  // Job Role States
  const [selectedJobSegments, setSelectedJobSegments] = useState([{}]);
  const [selectedJobRoles, setSelectedJobRoles] = useState([[]]);
  const [allRoleSegment, setAllRoleSegment] = useState([[]]);

  // Job Preferences States
  const [remote, setRemote] = useState(false);
  const [annualSalary, setAnnualSalary] = useState();
  const [chipData, setChipData] = useState([]);
  const [jobType, setJobType] = useState();

  //Work Experience States
  const [finalExpclicked, setFinalExpClicked] = useState(0);
  const [resume, setResume] = useState(null);
  const [uploadedResume, setUploadedResume] = useState(null);
  const [values, setValues] = React.useState({
    yearExp: null,
    currentCtc: null,
    monthExp: null
  });

  //Current Education States
  const [selectedCollege, setSelectedCollege] = useState({});
  const [selectedDegree, setSelectedDegree] = useState({});
  const [selectedSpecialization, setSelectedSpecilization] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isCurrent, setIsCurrent] = useState();

  //Skills States
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [loader, setLoader] = useState(true);

  

  //Signup complete function
  const {token} = useParams();

  useEffect(() => {
    if(token){
      localStorage.setItem('gw_token', token);
      profileDetailCall();
    }
  }, [token])
  const gwToken = localStorage.getItem('gw_token') || token;


  const profileDetailCall = () => {
    Axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + gwToken,
        },
        withCredentials: true,
      }).then((res) => {
        if(res?.data?.success){
          localStorage.setItem("user_type", res.data.user_type[0].main_user);
          localStorage.setItem("signupName", `${res.data?.first_name} ${res.data?.last_name}`);
          localStorage.setItem("user_type_id", res.data.user_type[0].type);
          localStorage.setItem("user_id", res.data.user_type[0].user_id);
          localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
          if (res.data.is_pref_filled) history.push("/student/dashboard");
        }else{
          history.push("/login/student");
          alert.error("Something went wrong. Login Again!!");
        }
      }).catch(err => {
        alert.error("Something went wrong. Login Again!!");
        history.push("/login/student");
        })
  }

  useEffect(() => {
    setLoader(true);
    Axios
      .get(BackendBaseApi.PRAVESH + "api/student/signup/all-details/", {
        headers: {
          Authorization: "Token " + gwToken,
        },
        withCredentials: true,
      }).then((res) => {
        if(res?.data?.success){
          const details = res?.data?.data?.data;
          setFinalNoticePeriod(details?.notice_period);
          if(details?.notice_period){
            setFinalClickedIndex(3);
          }
          setValues({
            yearExp: details?.total_exp_year,
            currentCtc: details?.cur_ctc,
            monthExp: details?.total_exp_month
          })
          setUploadedResume(details?.resume);
          // setSelectedJobRoles(details?.job_roles ? details?.job_roles : []);
          setRemote(details?.wfh);
          setAnnualSalary(details?.min_salary);
          setJobType(details?.job_type);
          setChipData(details?.job_location ? details?.job_location  : []);
          setFinalExpClicked(details?.fresher ? 1 : details?.total_exp_year || details?.total_exp_month ? 2 : 0);
          setSelectedSkills(details?.skills ? details?.skills : []);
          setSelectedCollege(details?.college);
          setSelectedDegree(details?.degree);
          setSelectedSpecilization(details?.specialization);
          setStartDate(details?.start_date);
          setEndDate(details?.end_date);
          setUsername(details?.name);
          setSelectedJobSegments(details?.job_segment ? details?.job_segment : []);
          setSelectedJobRoles(details?.job_role_group ? details?.job_role_group : []);
        }
        setLoader(false);
      }).catch(err => {
        setLoader(false);
      })
  },[])


  const location = useLocation();

  const params = new URLSearchParams(location.search);

  

  const signupComplete = () => {
    if(params.get('url')){
    history.push(`/${params.get('url')}`);
    }else{
      history.push("/student/dashboard");
    }
  }

  function getContinueButtonId(){
    switch (step){
      case 2:
        return `notice_period_continue`;
      case 3:
        return 'job_role_continue';
      case 4:
        return 'job_preference_continue';
      case 5:
        return 'exp_resume_continue';
      case 6:
        return 'current_education_continue';
      case 7:
        return 'skill_continue';
      default:
        return 'default_continue';
    }
  }

  function getSkipButtonId(){
    switch (step){
      case 2:
        return `notice_period_skip`;
      case 3:
        return 'job_role_skip';
      case 4:
        return 'job_preference_skip';
      case 5:
        return 'exp_resume_skip';
      case 6:
        return 'current_education_skip';
      case 7:
        return 'skill_skip';
      default:
        return 'default_skip';
    }
  }

  function getComponent() {
    switch (step) {
      case 2:
        return <FirstCheck setStep={setStep} step={step}
        finalNoticePeriod={finalNoticePeriod}
        setFinalNoticePeriod={setFinalNoticePeriod}
        finalClickedIndex={finalClickedIndex}
        setFinalClickedIndex={setFinalClickedIndex}
        getContinueButtonId={getContinueButtonId}
        getSkipButtonId={getSkipButtonId}
        name={username}
          />;
      case 3:
        return <InterestedJobArea
        setStep={setStep} step={step}
        finalSelectedJobSegments={selectedJobSegments}
        setFinalSelectedJobSegments={setSelectedJobSegments}
        finalSelectedJobRoles={selectedJobRoles}
        setFinalSelectedJobRoles={setSelectedJobRoles}
        finalAllRoleSegment={allRoleSegment}
        setFinalAllRoleSegment={setAllRoleSegment}
        getContinueButtonId={getContinueButtonId}
        getSkipButtonId={getSkipButtonId}
        name={username}
        />;
      case 4:
        return <JobPreferences 
        setStep={setStep}
        step={step}
        finalRemote={remote}
        setFinalRemote={setRemote}
        finalAnnualSalary={annualSalary}
        setFinalAnnualSalary={setAnnualSalary}
        workLocations={chipData}
        setWorkLocations={setChipData}
        finalJobType={jobType}
        setFinalJobType={setJobType}
        getContinueButtonId={getContinueButtonId}
        getSkipButtonId={getSkipButtonId}
        name={username}
        />;
      case 5:
        return <RegisterBasicInfo setStep={setStep} step={step}
        finalResume={resume}
        setFinalResume={setResume}
        finalValues={values}
        setFinalValues={setValues}
        finalExpclicked={finalExpclicked}
        setFinalExpClicked={setFinalExpClicked}
        getContinueButtonId={getContinueButtonId}
        getSkipButtonId={getSkipButtonId}
        uploadedResume={uploadedResume}
        name={username}
        />;
      case 6:
        return <CurrentEducation setStep={setStep} step={step}
          finalSelectedCollege={selectedCollege}
          setFinalSelectedCollege={setSelectedCollege}
          finalSelectedDegree={selectedDegree}
          setFinalSelectedDegree={setSelectedDegree}
          finalSelectedSpecialization={selectedSpecialization}
          setFinalSelectedSpecilization={setSelectedSpecilization}
          finalStartDate={startDate}
          finalEndDate={endDate}
          setFinalStartDate={setStartDate}
          setFinalEndDate={setEndDate}
          isCurrent={isCurrent}
          setIsCurrent={setIsCurrent}
          getContinueButtonId={getContinueButtonId}
        getSkipButtonId={getSkipButtonId}
        name={username}
        />;
      case 7:
        return <StrongSkills setStep={setStep} step={step}
        finalSkills={selectedSkills}
        setFinalSkills={setSelectedSkills}
        signupComplete={signupComplete}
        getContinueButtonId={getContinueButtonId}
        getSkipButtonId={getSkipButtonId}
        name={username}
        />;
      default:
        return <FirstCheck 
        setStep={setStep} step={step}
        getContinueButtonId={getContinueButtonId}
        getSkipButtonId={getSkipButtonId}
        name={username}
        />;
    }
  }

  const RootPaper = styled(Box)(({ theme }) => ({
    width: `${step >= 2 ? "50%" : "80%"}`,
    boxShadow: "0px 0px 10px 2px #0000001A",
    padding: "20px",
    height: "fit-content",
    borderRadius: "20px",
    backgroundColor: "#fff",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      padding: "5px",
      paddingTop: "35px",
      boxShadow: "none",
      marginBottom: "50px"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "5px",
      paddingTop: "35px",
      boxShadow: "none",
      marginBottom: "50px"
    },
  }));

  return (
    <>
      <PublicLayout step={step} setStep={setStep} signupComplete={signupComplete} getSkipButtonId={getSkipButtonId}>
        <Box sx={{ pt: 1 }}>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              sm={12}
              // md={6}
              md={ 12}
              sx={{
                display: "flex",
                justifyContent: `${"center"}`,
              }}
            >
              { loader ?
              <CircularProgress />
              :
              <RootPaper>{getComponent()}</RootPaper>
}
            </Grid>
          </Grid>
        </Box>
      </PublicLayout>
    </>
  );
}

export default LoginFlow;

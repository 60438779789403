/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../../common/UI/Loader";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/common/httpRequest";
import AssignmentJobCard from "../JobsCard/AssignmentJobCard";
import Layout from "../Layout/Layout";

export default function Index() {
  const [jobData, setJobData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filterValue, setFilterValue] = useState("All");
  const [loader, setLoader] = useState(false);

  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const data = JSON.parse(localStorage.getItem("user_details"));

  useEffect(() => {
    const token = localStorage.getItem("gw_token");
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/student/assignment/?user_id=${window.btoa(data.id)}&state=Q0xPU0U=`,
      null,
      setJobData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setLoader(true);

    let res = await httpRequest(baseUrl, endPoint, body);

    if (res.data.next === null) {
      setEnd(true);
    } else {
      setNewURL(res.data.next.slice(0, 20));
      setNewEndPoint(res.data.next.slice(20));
    }

    updateState(jobData.concat([...res.data.results]));
    setAllData(allData.concat([...res.data.results]));
    setLoader(false);
  };
  const handleChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleScroll = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        // const token = localStorage.getItem("gw_token");
        GetData(newURL, newEndPoint, null, setJobData);
      }
    }
  };

  return (
    <>
      <Layout>
        {!loader ? (
          <div className="container__jobs" style={{ marginTop: "80px" }}>
            {/* {data.map((card, index) => <NewJobCard key={index} {...card} />)} */}
            <div
              id="myid"
              className="scrollY1"
              style={{ marginTop: "10px" }}
              onScroll={handleScroll}
            >
              {jobData.length ? (
                <AssignmentJobCard
                  data={jobData}
                  fullView={false}
                  invite={false}
                  reject={true}
                />
              ) : (
                <div className="text-gray fs-20 fw-700 mg-top-20">
                  No Assignments
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </Layout>
    </>
  );
}

{
  /* <Grid container justify='center'>

<Layout>

 

 
<div className='applied mg-left-10'>
    
   
   
   
   
    <Grid container> 
        <Grid xs={12} className='big-card-res'>
        
 {       jobData.length ?  <JobsCard data={jobData} reject={true}/> :   <div className='text-gray fs-20 fw-700 mg-top-20'>No Jobs</div>}
        </Grid>
    </Grid>
    
</div>  
 </Layout>
 </Grid> */
}

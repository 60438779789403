import {
  Box,
  Button,
  Chip,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  useTheme,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import CoursesCollegeDialog from "./CoursesCollegeDialog";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BackendBaseApi } from "../../../constants/constants";
import { Years } from "./FormComponents/JobSegment";
import NumberTextField, { NumberTextFieldPercentage } from "./FormComponents/NumberTextField";
import generateCsv from "../../../common/generateCsv/generateCsv";
import { BootstrapTooltipNew } from "../../../common/Tooltip/Tooltip";
import CommonButton from "../CommonButton/CommonButton";
import CommonChip from "../../CommonChip/CommonChip";
function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const Qualifications = (props) => {
  let { job_id } = useParams();

  const { state, setState, qualificationsRef, jobData, courseArray, setCourseArray } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [percentage, setPercentage] = useState();
  const [currentYear, setCurrentYear] = useState([]);

  useEffect(() => {
    setState({
      ...state,
      Preferences: {
        ...state.Preferences,
        eligibility_criteria: {
          ...state.Preferences.eligibility_criteria,
          percentage: parseFloat(percentage),
        },
      },
    });
  }, [percentage]);

  const graduation_years = [
    { name: "2015", value: "2015" },
    { name: "2016", value: "2016" },
    { name: "2017", value: "2017" },
    { name: "2018", value: "2018" },
    { name: "2019", value: "2019" },
    { name: "2020", value: "2020" },
    { name: "2021", value: "2021" },
    { name: "2022", value: "2022" },
    { name: "2023", value: "2023" },
    { name: "2024", value: "2024" },
    { name: "2025", value: "2025" },
  ];

  useEffect(() => {
    var arr = [];
    if (job_id && jobData && jobData !== undefined) {
      var arr = [];
      setPercentage(jobData?.eligibility_criteria?.percentage);
      jobData &&
        jobData.eligibility_criteria &&
        jobData.eligibility_criteria.graduation_years.length > 0 &&
        jobData.eligibility_criteria.graduation_years.map((item) => {
          arr.push({ name: item, value: item });
        });
      setCurrentYear(arr);
      if (jobData?.college?.length > 0) setCourseArray(jobData?.college[0].preferences);
    }
  }, [jobData]);

  useEffect(() => {
    var arr = [];
    currentYear &&
      currentYear.map((i) => {
        arr.push(i.value);
      });

    setState({
      ...state,
      Preferences: {
        ...state.Preferences,
        eligibility_criteria: {
          ...state.Preferences.eligibility_criteria,
          graduation_years: arr,
        },
      },
    });
  }, [currentYear]);
  return (
    <div style={{ marginTop: "50px" }} ref={qualificationsRef}>
      <h5 className={classes.heading}>Qualifications</h5>
      <Divider />

      <Grid container style={{ marginTop: "30px" }}>
        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>Minimum Percentage</p>
        </Grid>
        <Grid item xs={9} className={classes.section}>
          <NumberTextFieldPercentage state={percentage} setState={setPercentage} labelName={"Percentage"} width={"70%"} />
        </Grid>

        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>Eligible Graduation Years</p>
        </Grid>
        <Grid item xs={9} className={classes.section} id="box_value">
          <Years state={currentYear} setState={setCurrentYear} MainState={state} yearsList={graduation_years} width={classes.InputWidth} responsive={classes.labelSize} />
        </Grid>
      </Grid>
      {localStorage.getItem("user_type") === "College" && (
        <>
          <br />

          <p align="center" className={classes.formLabel}>
            Click on the below button to choose Courses. <span className={classes.RedStar}>*</span>
          </p>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              float: "center",
              marginBottom: "20px",
            }}
          >
            <CommonButton variant="contained" onClick={handleClickOpen}>
              Select Courses
            </CommonButton>
          </Box>
        </>
      )}

      {localStorage.getItem("user_type") === "College" && (state.Colleges.college.length > 0 || state.Preferences.eligibility_criteria.courses.length > 0) && (
        <Box className={classes.selectedPreferences}>
          <Grid container>
            <Grid item xs={3}>
              <p className={classes.formLabel}>
                Selected Courses
                <Button
                  autofocus
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  Edit
                </Button>
              </p>
            </Grid>
          </Grid>

          <>
            {courseArray.map((item) => (
              <>
                <BootstrapTooltipNew title={generateCsv(item.specialization, "specialization_name")} className="">
                  <CommonChip label={<span style={{ fontSize: 12 }}>{item.degree_name}</span>} style={{ marginRight: "1%", marginBottom: "1%" }} />
                </BootstrapTooltipNew>
              </>
            ))}
          </>
        </Box>
      )}
      {localStorage.getItem("user_type") === "College" && (
        <CoursesCollegeDialog
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          state={state}
          setState={setState}
          courseArray={courseArray}
          setCourseArray={setCourseArray}
          jobData={jobData}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formLabel: {
      fontSize: "13px",
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    RedStar: {
      color: "red",
      fontWeight: "bold",
      marginLeft: "2px",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "bolder",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    section: {
      marginBottom: "20px",
    },
    subTitle: {
      fontSize: "11px",
      color: "grey",
      width: "70%",
      margin: "6px 0 0 10px",
    },
    editor: {
      backgroundColor: theme.palette.type === "dark" ? "#282C34" : "#F2F2F2",
      color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
      maxHeight: 350,
      minHeight: 200,
      overflowY: "scroll",
      borderRadius: 30,
      width: "80%",
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
    },
    selectedPreferences: {
      padding: "20px",
      borderRadius: 30,
      WebkitBackdropFilter: "blur(30px)",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "1px solid rgba(152, 152, 152, 0.27)",
      marginBottom: 30,
    },
    mainEditor: {
      border: "0px hidden",
      backgroundColor: theme.palette.type === "dark" ? "#C1D9ED" : "#F2F2F2",
      borderRadius: 30,
      width: "80%",
    },
    editorToolbar: {
      backgroundColor: theme.palette.type === "dark" ? "#C1D9ED" : "#F2F2F2",
      borderRadius: 30,
    },
    InputWidth: {
      width: "70%",
    },
    labelSize: {
      fontSize: 12,
    },
    preferenceButton: {
      borderRadius: 30,
      fontSize: 12,
      "&:hover": {
        border: "2px solid #3282C4",
        background: "white",
        color: "#3282C4",
      },
    },
    "@media only screen and (max-width: 900px)": {
      InputWidth: {
        width: "100%",
      },
      formLabel: {
        fontSize: "11px",
        fontWeight: "bold",
        paddingTop: "10px",
      },
    },
    "@media only screen and (max-width: 1100px)": {
      labelSize: {
        fontSize: 11,
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "auto",

        // border: "1px solid #ced4da",
        // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
      },
    },
  })
);
export default Qualifications;

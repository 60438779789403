import { makeStyles, createStyles } from "@material-ui/core";

const useStyles_usersSettings = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: "-40px",
      // marginLeft: "-40px",
      display: "flex",
      // border: "2px solid #000",
      flexDirection: "row",
    },

    leftPaper: {
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      height: "auto",
      width: "260px",
      paddingBottom: '50px', 
      marginTop: '39px',
      borderRadius: '25px',
      //marginLeft: '18px',
      // border: "2px solid #000",
      background: "#ffffff",
      paddingTop: "50px",
      position: "fixed",
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      borderRight:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
    },
    leftMenuBox: {
      width: "200px",
      height: "38px",
      marginTop: "15px",
      borderRadius: 5,
      backgroundColor: theme.palette.background.default,
      border:
        theme.palette.type === "dark"
          ? "2px solid rgba(255, 255, 255, 0.12)"
          : "2px solid #cdedf6",
      color: theme.palette.type === "dark" ? "white" : "black",
    },
    leftMenuBox1: {
      width: "200px",
      height: "38px",
      marginTop: "15px",
      borderRadius: 5,
      backgroundColor: theme.palette.background.default,
      border:
        theme.palette.type === "dark"
          ? "2px solid rgba(255, 255, 255, 0.12)"
          : "2px solid #cdedf6",
      color: theme.palette.type === "dark" ? "white" : "black",
    },
    leftMenuBoxSelected: {
      width: "200px",
      height: "38px",
      marginTop: "15px",
      borderRadius: 5,
      backgroundColor: "#CDEDF6",
      fontWeight: "bold",
      color: "#000000",
      // "&:focus": {
      //   outline: "none",
      // },
    },
    leftMenuBoxSelected1: {
      width: "200px",
      height: "38px",
      marginTop: "15px",
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      fontWeight: "bold",
      color: "#fff",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      // "&:focus": {
      //   outline: "none",
      // },
    },
    paperStyle: {
      marginLeft: "290px",
      backgroundColor: theme.palette.background.paper,
      marginTop: "39px",
      paddingTop: "30px",
      paddingLeft: "20px",
      paddingBottom: "15px",
      marginBottom: "35px",
      borderRadius:"30px",
      paddingRight: "20px",
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
    },
    avatarBox: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "center",
      marginBottom: "30px",
    },

    fieldDiv: {
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
      alignItems: "center",
      marginBottom: "30px",
      // border: "2px solid #000",
    },
    inputBox: {
      marginLeft: "15px",
      width: "250px",
      paddingLeft: "5px",
      height: "35px",
      // borderRadius: "5px",
    },
    inputBoxE: {
      marginLeft: "35px",
      paddingLeft: "5px",
      height: "35px",
      marginRight: "30px",
      display: "flex",
      alignItems: "center",
      // borderRadius: "5px",
    },
    heading: {
      width: "100px",
      // border: "2px solid #000",
      height: "30px",
      paddingLeft: "5px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    heading1: {
      width: "170px",
      // border: "2px solid #000",
      height: "30px",
      paddingLeft: "5px",
      display: "flex",
      flexDirection: "row",
      paddingTop: "8%",
      paddingRight: "30px",
    },
    firstBox: {
      display: "flex",
      flexDirection: "row",
      // border: "1px solid #000",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: "100px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "30px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    buttonContainer2: {
      display: "flex",
      justifyContent: "center",
      paddingRight: "30px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    button: {
      borderRadius:"30px",
      backgroundColor: "#3282C4",
      color: "#fff",
      paddingRight: "20px",
      paddingLeft: "20px",
      width: "210px",
      height: "36px",
    },
    inputBox2: {
      marginLeft: "35px",
      width: "650px",
      height: "150px",
      paddingLeft: "5px",
      paddingRight: "5px",
      justifyContent:'center',
    },
    radioStyle: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  })
);

const styles = {
  container: {
    marginTop: "-40px",
    // marginLeft: "-40px",
    display: "flex",
    // border: "2px solid #000",
    flexDirection: "row",
  },

  leftPaper: {
    backgroundColor: "#fff",
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    width: "260px",
    // border: "2px solid #000",
    background: "#ffffff",
    paddingTop: "100px",
    position: "fixed",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.25)",
  },
  leftMenuBox: {
    width: "200px",
    height: "38px",
    marginTop: "15px",
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "2px solid #cdedf6",
    color: "black",
  },
  leftMenuBoxSelected: {
    width: "200px",
    height: "38px",
    marginTop: "15px",
    borderRadius: 5,
    backgroundColor: "#CDEDF6",
    fontWeight: "bold",
    color: "#000000",
  },
  paperStyle: {
    marginLeft: "290px",
    backgroundColor: "#fff",
    width: "65vw",
    marginTop: "39px",
    paddingTop: "30px",
    borderRadius:"30px",
    paddingLeft: "20px",
    paddingBottom: "15px",
    paddingRight: "20px",
    marginBottom: "35px",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.25)",
  },
  avatarBox: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
  },

  fieldDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "20px",
    alignItems: "flex-start",
    marginBottom: "30px",
    // border: "2px solid #000",
  },
  inputBox: {
    marginLeft: "35px",
    width: "250px",
    paddingLeft: "5px",
    height: "35px",
    // borderRadius: "5px",
  },
  inputBoxE: {
    marginLeft: "35px",
    paddingLeft: "5px",
    height: "35px",
    marginRight: "30px",
    display: "flex",
    alignItems: "center",
    // borderRadius: "5px",
  },
  heading: {
    width: "100px",
    // border: "2px solid #000",
    height: "30px",
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  heading1: {
    width: "170px",
    // border: "2px solid #000",
    height: "30px",
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
    paddingTop: "8%",
    paddingRight: "30px",
  },
  firstBox: {
    display: "flex",
    flexDirection: "row",
    // border: "1px solid #000",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "100px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "30px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  button: {
    borderRadius:"30px",
    backgroundColor: "#3282C4",
    color: "#fff",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  inputBox2: {
    marginLeft: "35px",
    width: "650px",
    height: "150px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  radioStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

export default useStyles_usersSettings;

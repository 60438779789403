import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import { red } from '@material-ui/core/colors';
import demo from "../../../../assets/demo.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: "10px",
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    cursor: "pointer"
   
  },
  fixedHeight: {
    height: "auto",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    flexDirection: 'column',
    backgroundColor: "#ffffff",
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    borderRadius:"25px",
    // 16:9
  },
  avatar: {
     // marginLeft:"-20px",
    backgroundColor: red[500],
  },
  mainGrid: {
    fontSize: "14px",
    fontFamily:"Roboto",
  },
  marginLeft:{
    marginLeft: "5px"
  }
}));


export default function Cards({data}) {
  const classes = useStyles();
 

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
   <>
   <Grid item xs={12} sm={6} md={4}  >
    <Grid container spacing={3} className={classes.mainGrid} >
     <Container style={{display: "flex", flexDirection: "row", paddingTop: "10px"}}>
     <Card className={fixedHeightPaper}  style={{borderRadius:"25px",width:"100%"}} onClick={() => { window.open('/public/college/new-profile/' + data.college_name + '$' + data.college_id)}}>


     <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={3} sm={3} md={3} lg={3} style={{height: "100px", width: "100px"}}>
               <img src={data.logo ? data.logo : demo}
                height="100%"
                width="100%"
               />
          </Grid>
          <Grid item xs={9} sm={9} md={9}lg={9} style={{paddingLeft: "0px"}}>
            <Typography  style={{
                marginBottom: "0px",
                fontSize: "14px",
                fontWeight: "bold",
                }}>{data?.college_name}</Typography>
            <Typography  style={{
                marginBottom: "0px",
                fontSize: "14px",
                fontWeight: "bold",
                }}>{data?.city}, {data?.state}</Typography>
          </Grid>
        </Grid>
        {data.banner && (
      <CardMedia
        className={classes.media}
        image={data.banner}
        title="Paella dish"
      />
        )
        }
      <CardActions disableSpacing>
        <Box component="fieldset" mb={3} borderColor="transparent" style={{display:"contents"}}>
        <Rating
          name="read-only"
          // value={parseFloat(item.avg_rating) / 2}
          defaultValue={
            parseFloat(data.avg_rating) / 2
          }
          precision={0.25}
          readOnly
        />
        &nbsp;{" "}
        <span style={{ color: "grey" }}>
          {parseFloat(data.avg_rating) / 2}
          </span>
        
      </Box>
      </CardActions>
     
    </Card>
    </Container>
      </Grid>
     </Grid>
  </>
  );
}
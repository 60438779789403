import React from "react";
import Axios from "axios";
import "./mentor_css.css";
import "./swiper.css";
import {
  BackendBaseApi, MENTORKART_ENDPOINT
} from "../../../constants/constants";

import whitelogo from "../../../assets/mentor/assets/img/whitelogo.png";
import whitegetwork from "../../../assets/mentor/assets/img/whitegetwork.png";
import goalanimate from "../../../assets/mentor/assets/img/goal-animate.svg";
import c1 from "../../../assets/mentor/assets/img/c1.svg";
import au from "../../../assets/mentor/assets/img/au.png";
import c2 from "../../../assets/mentor/assets/img/c2.svg";
import c3 from "../../../assets/mentor/assets/img/c3.svg";
import c4 from "../../../assets/mentor/assets/img/c4.svg";
import c5 from "../../../assets/mentor/assets/img/c5.svg";
import c6 from "../../../assets/mentor/assets/img/c6.svg";
import m0 from "../../../assets/mentor/assets/img/mentors/m0.jpg";
import m9 from "../../../assets/mentor/assets/img/mentors/m9.jpg";
import m5 from "../../../assets/mentor/assets/img/mentors/m5.jpg";
import m7 from "../../../assets/mentor/assets/img/mentors/m7.jpg";
import m1 from "../../../assets/mentor/assets/img/mentors/m1.jpg";
import m2 from "../../../assets/mentor/assets/img/mentors/m2.jpg";
import m3 from "../../../assets/mentor/assets/img/mentors/m3.jpg";

import m8 from "../../../assets/mentor/assets/img/mentors/m8.png";
import m4 from "../../../assets/mentor/assets/img/mentors/m4.jpg";
import m6 from "../../../assets/mentor/assets/img/mentors/m6.jpg";
import m10 from "../../../assets/mentor/assets/img/mentors/m10.jpg";
import c_c1 from "../../../assets/mentor/assets/img/clients/img/c1.png";
import c_c4 from "../../../assets/mentor/assets/img/clients/img/c4.png";
import c_c5 from "../../../assets/mentor/assets/img/clients/img/c5.png";
import c_c6 from "../../../assets/mentor/assets/img/clients/img/c6.png";
import c_c7 from "../../../assets/mentor/assets/img/clients/img/c7.png";
import c_c8 from "../../../assets/mentor/assets/img/clients/img/c8.png";
import c_c9 from "../../../assets/mentor/assets/img/clients/img/c9.png";
import c_c10 from "../../../assets/mentor/assets/img/clients/img/c10.png";
import c_c11 from "../../../assets/mentor/assets/img/clients/img/c11.png";
import c_c12 from "../../../assets/mentor/assets/img/clients/img/c12.png";
import c_c3 from "../../../assets/mentor/assets/img/clients/img/c3.png";

import c_c14 from "../../../assets/mentor/assets/img/clients/img/c14.png";
import c_c15 from "../../../assets/mentor/assets/img/clients/img/c15.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import "swiper/swiper.scss";

export default function () {
  const [value, setValue] = React.useState("quaterly");
  SwiperCore.use([Autoplay]);

  const checkforSSO = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/sso/mentorkart/token", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res?.data?.success){
          window.open(MENTORKART_ENDPOINT + "/auth?SSO_Mtoken=" + res.data.data.token + "&utm_source=Getwork_Portal%20&utm_medium=web&utm_campaign=SMP&utm_content=download");
        }
      })
      .catch((err) => {
      });
  };

  return (
    <>
      <main id="main">
        <section id="hero" onClick = {() => checkforSSO()}>
          <div className="container">
            <div className="py-2 row">
              <div className="col-12  d-flex justify-content-between ">
                <a >
                  <img
                    src={whitelogo}
                    alt=""
                    style={{ maxHeight: 120 }}
                    className="img-fluid"
                    onClick = {() => checkforSSO()}
                  />
                </a>
                <a href="/student/dashboard">
                  <img
                    src={whitegetwork}
                    alt=""
                    style={{ maxHeight: 120 }}
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div
                className="col-lg-6 py-5 py-lg-0 order-1 order-lg-1"
                data-aos="fade-right"
              >
                <h1>
                  STUDENT MENTORSHIP
                  <br />
                  PROGRAM
                  <br />
                  (SMP)
                </h1>

                <h2>Specially Designed For College Students</h2>
                <a href='javascript:;' onclick='checkforSSO();' className="btn-get-started scrollto">
                  Know More
                </a>
              </div>
              <div
                className="col-lg-6 py-4 order-2 order-lg-2 hero-img"
                data-aos="fade-left"
              >
                <img src={goalanimate} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about section-bg">
          <div className="container">
            <div className="row">
              <div className="image col-xl-5 d-flex justify-content-center">
                <a
                  href="https://youtu.be/OEvJf8ahGgo"
                  className="venobox play-btn"
                  data-vbtype="video"
                  data-autoplay="true"
                ></a>
              </div>

              <div className="col-xl-7 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
                <div className="content d-flex flex-column justify-content-center">
                  <h3 data-aos="fade-in" data-aos-delay="100">
                    Student Mentorship Program (SMP)
                  </h3>
                  <p data-aos="fade-in">
                    Student Mentorship Program (SMP) is a tailored made program
                    for college/university students. This program is presented
                    to you after years of research and continuous evaluation of
                    college/university students on various parameters. This
                    program will exactly target those nodes of mind which needs
                    to be activated for achieving the pinnacle of success.
                  </p>
                  <div className="row">
                    <div className="col-md-6 icon-box" data-aos="fade-up">
                      <i className="fa fa-receipt"></i>
                      <h4>
                        <a href='javascript:;' onclick='checkforSSO();' className="btn-get-started scrollto">Connect with Industry Experts</a>
                      </h4>
                      <p>
                        Chat with Industry Experts, Corporate Buddy/ Passion
                        Buddy / Connects
                      </p>
                    </div>{" "}
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <i className="fa fa-shield"></i>
                      <h4>
                        <a href='javascript:;' onclick='checkforSSO();' className="btn-get-started scrollto">New month - New Content</a>
                      </h4>
                      <p>New Premium content every month</p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <i className="fa fa-cube"></i>
                      <h4>
                        <a href='javascript:;' onclick='checkforSSO();' className="btn-get-started scrollto">Free Call</a>
                      </h4>
                      <p>15 min free call with selected mentors</p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <i className="fa fa-images"></i>
                      <h4>
                        <a href='javascript:;' onclick='checkforSSO();' className="btn-get-started scrollto">Unlock Acheivement</a>
                      </h4>
                      <p>Assessment and Certicate for each chapter</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="features section-bg">
          <div className="container">
            <div className="section-title">
              <h2 data-aos="fade-in">SMP Curriculum</h2>
            </div>

            <div className="row content">
              <div className="col-md-5" data-aos="fade-right">
                <img src={c1} className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-4" data-aos="fade-left">
                <h3>CHAPTER 1: What inspires me - My GOAL (MISSION)</h3>
                <p>Contains: 4 Modules and 10 Capsules</p>
                <ul>
                  <li>
                    <i className="icofont-check"></i> M1: Searching for my
                    passion
                  </li>
                  <li>
                    <i className="icofont-check"></i> M2: My abilities & Self
                    Assessment
                      <img
                        src={au}
                        className="img-fluid"
                        style={{ width: "15%" }}
                        alt=""
                      />
                  </li>
                  <li>
                    <i className="icofont-check"></i> M3: Career suitability
                  </li>
                  <li>
                    <i className="icofont-check"></i> M4: Goal setting and
                    measurement{" "}
                    
                      <img
                        src={au}
                        className="img-fluid"
                        style={{ width: "15%" }}
                        alt=""
                      />
                    
                  </li>

                  <a
                    href='javascript:;' onclick='checkforSSO();'
                    target="_blank"
                    className="btn btn-warning"
                    style={{ borderRadius: "20px" }}
                  >
                    Get Started
                  </a>
                </ul>
              </div>
            </div>

            <div className="row content">
              <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
                <img src={c2} className="img-fluid" alt="" />
              </div>
              <div
                className="col-md-7 pt-5 order-2 order-md-1"
                data-aos="fade-right"
              >
                <h3>CHAPTER 2: Leading my Self ( MINDFULNESS)</h3>
                <p>Contains: 4 Modules and 9 Capsules</p>
                <ul>
                  <li>
                    <i className="icofont-check"></i> M1: How do I learn
                  </li>
                  <li>
                    <i className="icofont-check"></i> M2: Walking the Talk{" "}
                      <img
                        src={au}
                        className="img-fluid"
                        style={{ width: "15%" }}
                        alt=""
                      />
                  </li>
                  <li>
                    <i className="icofont-check"></i> M3: Being genuine
                  </li>
                  <li>
                    <i className="icofont-check"></i> M4: Taking Responsibility
                  </li>

                  <a
                    href='javascript:;' onclick='checkforSSO();'
                    target="_blank"
                    className="btn btn-warning"
                    style={{ borderRadius: 20 }}
                  >
                    Get Started
                  </a>
                </ul>
              </div>
            </div>

            <div className="row content">
              <div className="col-md-5" data-aos="fade-right">
                <img src={c3} className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-5" data-aos="fade-left">
                <h3>CHAPTER 3: Leading others ( MOJO)</h3>
                <p>Contains: 4 Modules and 11 Capsules</p>
                <ul>
                  <li>
                    <i className="icofont-check"></i>M1: Developing a leadership
                    style
                  </li>
                  <li>
                    <i className="icofont-check"></i>M2: Inspiring others{" "}
                    
                      <img
                        src={au}
                        className="img-fluid"
                        style={{ width: "15%" }}
                        alt=""
                      />
                    
                  </li>
                  <li>
                    <i className="icofont-check"></i>M3: Creating positive team
                    dynamics
                  </li>
                  <li>
                    <i className="icofont-check"></i>M4: Monitoring the team
                    progress
                  </li>
                  <a
                    href='javascript:;' onclick='checkforSSO();'
                    target="_blank"
                    className="btn btn-warning"
                    style={{ borderRadius: "20px" }}
                  >
                    Get Started
                  </a>
                </ul>
              </div>
            </div>

            <div className="row content">
              <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
                <img src={c4} className="img-fluid" alt="" />
              </div>
              <div
                className="col-md-7 pt-5 order-2 order-md-1"
                data-aos="fade-right"
              >
                <h3>CHAPTER 4: Building your financial stability (MONEY)</h3>
                <p>Contains: 4 Modules and 7 Capsules</p>
                <ul>
                  <li>
                    <i className="icofont-check"></i>M1: Money as a requirement
                  </li>
                  <li>
                    <i className="icofont-check"></i>M2: Earn while you learn(EWL)
                  </li>
                  <li>
                    <i className="icofont-check"></i>M3: Budgeting
                  </li>
                  <li>
                    <i className="icofont-check"></i>M4: Investing
                    progress{" "}
                    
                      <img
                        src={au}
                        className="img-fluid"
                        style={{ width: "15%" }}
                        alt=""
                      />
                    
                  </li>
                  <a
                    href='javascript:;' onclick='checkforSSO();'
                    target="_blank"
                    className="btn btn-warning"
                    style={{ borderRadius: "20px" }}
                  >
                    Get Started
                  </a>
                </ul>
              </div>
            </div>
            <div className="row content">
              <div className="col-md-5" data-aos="fade-right">
                <img src={c5} className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-5" data-aos="fade-left">
                <h3>CHAPTER 5: Letting your abilities speak (MARKETING)</h3>
                <p>Contains: 5 Modules and 18 Capsules</p>
                <ul>
                  <li>
                    <i className="icofont-check"></i>M1: Personality development
                  </li>
                  <li>
                    <i className="icofont-check"></i>M2: Communication skills
                  </li>
                  <li>
                    <i className="icofont-check"></i>M3: Personal Networking
                    skills{" "}
                   
                      <img
                        src={au}
                        className="img-fluid"
                        style={{ width: "15%" }}
                        alt=""
                      />
                    
                  </li>
                  <li>
                    <i className="icofont-check"></i>M4: Professional Networking
                    skills
                  </li>
                  <li>
                    <i className="icofont-check"></i>M5: Succeeding in a job
                    entry
                  </li>
                  <a
                    href='javascript:;' onclick='checkforSSO();'
                    target="_blank"
                    className="btn btn-warning"
                    style={{ borderRadius: "20px" }}
                  >
                    Get Started
                  </a>
                </ul>
              </div>
            </div>

            <div className="row content">
              <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
                <img src={c6} className="img-fluid" alt="" />
              </div>
              <div
                className="col-md-7 pt-5 order-2 order-md-1"
                data-aos="fade-right"
              >
                <h3>CHAPTER 6: Improving all the while (METAMORPHOSIS)</h3>
                <p>Contains: 1 Module and 6 Capsules</p>
                <ul>
                  <li>
                    <i className="icofont-check"></i>M1: Overcoming failures{" "}
                    
                      <img
                        src={au}
                        className="img-fluid"
                        style={{ width: "15%" }}
                        alt=""
                      />
                  
                  </li>
                  <li>
                    <i className="icofont-check"></i>M2: Developing the mental
                    muscle
                  </li>
                  <li>
                    <i className="icofont-check"></i>M3: Innovation
                  </li>
                  <li>
                    <i className="icofont-check"></i>M4: Standing for something
                    bigger than just yourself
                  </li>
                  <a
                    href='javascript:;' onclick='checkforSSO();'
                    target="_blank"
                    className="btn btn-warning"
                    style={{ borderRadius: "20px" }}
                  >
                    Get Started
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg">
          <div className="container outcomes-swiper">
            <div className="section-title">
              <h2 data-aos="fade-in">Learning Outcomes</h2>
            </div>
            <div className="swiper-wrapper">
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{ delay: 3000 }}
              >
                <SwiperSlide>
                  <div className="swiper-slide" data-aos="fade-right">
                    {/* <div className="card"> */}
                    <div
                      className="card-body"
                      style={{ marginTop: 0, backgroundColor: "#ff7f2a" }}
                    >
                      <h5 className="card-title">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-white"
                        >
                          Learning integrity and self awareness
                        </a>
                      </h5>
                      <p className="card-text text-center text-white">
                        Know your Net-Worth in life
                      </p>
                      <div className="read-more text-center">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          className="text-white"
                          target="_blank"
                        >
                          <i className="icofont-arrow-right"></i> Get Started
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide" data-aos="fade-right">
                    {/* <div className="card"> */}
                    <div
                      className="card-body"
                      style={{ marginTop: 0, backgroundColor: "#ffde2a" }}
                    >
                      <h5 className="card-title">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-dark"
                        >
                          Build your financial stability
                        </a>
                      </h5>
                      <p className="card-text text-center text-dark">
                        Its better to break-up with your Girlfriend/Boyfriend
                        not with your money
                      </p>
                      <div className="read-more text-center">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          className="text-dark"
                          target="_blank"
                        >
                          <i className="icofont-arrow-right"></i> Get Started
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide" data-aos="fade-right">
                    {/* <div className="card"> */}
                    <div
                      className="card-body"
                      style={{ marginTop: 0, backgroundColor: "#ff7f2a" }}
                    >
                      <h5 className="card-title">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-white"
                        >
                          Learn about self branding
                        </a>
                      </h5>
                      <p className="card-text text-center text-white">
                        Make yourself a brand
                      </p>
                      <div className="read-more text-center">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          className="text-white"
                          target="_blank"
                        >
                          <i className="icofont-arrow-right"></i> Get Started
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide" data-aos="fade-right">
                    {/* <div className="card"> */}
                    <div
                      className="card-body"
                      style={{ marginTop: 0, backgroundColor: "#ffde2a" }}
                    >
                      <h5 className="card-title">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-dark"
                        >
                          Increase your creativity and creative thinking
                        </a>
                      </h5>
                      <p className="card-text text-center text-dark">
                        Take things in a different way/ Develop new style of
                        thinking
                      </p>
                      <div className="read-more text-center">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          className="text-dark"
                          target="_blank"
                        >
                          <i className="icofont-arrow-right"></i> Get Started
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide" data-aos="fade-right">
                    {/* <div className="card"> */}
                    <div
                      className="card-body"
                      style={{ marginTop: 0, backgroundColor: "#ff7f2a" }}
                    >
                      <h5 className="card-title">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-white"
                        >
                          {" "}
                          Increase your employbility by multifold
                        </a>
                      </h5>
                      <p className="card-text text-center text-white">
                        Getting hired is a left hand's game for you
                      </p>
                      <div className="read-more text-center">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          className="text-white"
                          target="_blank"
                        >
                          <i className="icofont-arrow-right"></i> Get Started
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide" data-aos="fade-right">
                    {/* <div className="card"> */}
                    <div
                      className="card-body"
                      style={{ marginTop: 0, backgroundColor: "#ffde2a" }}
                    >
                      <h5 className="card-title">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-dark"
                        >
                          {" "}
                          Get mentored by experienced mentors
                        </a>
                      </h5>
                      <p className="card-text text-center text-dark">
                        Educate from the mentors you actually need
                      </p>
                      <div className="read-more text-center">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          className="text-dark"
                          target="_blank"
                        >
                          <i className="icofont-arrow-right"></i> Get Started
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide" data-aos="fade-right">
                    {/* <div className="card"> */}
                    <div
                      className="card-body"
                      style={{ marginTop: 0, backgroundColor: "#ff7f2a" }}
                    >
                      <h5 className="card-title">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-white"
                        >
                          Industry ready program
                        </a>
                      </h5>
                      <p className="card-text text-center text-white">
                        The professional program which helps you become
                        employable and self-reliant
                      </p>
                      <div className="read-more text-center">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          className="text-white"
                          target="_blank"
                        >
                          <i className="icofont-arrow-right"></i> Get Started
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide" data-aos="fade-right">
                    {/* <div className="card"> */}
                    <div
                      className="card-body"
                      style={{ marginTop: 0, backgroundColor: "#ffde2a" }}
                    >
                      <h5 className="card-title">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-dark"
                        >
                          Gain skill which are necessary for getting a job
                        </a>
                      </h5>
                      <p className="card-text text-center text-dark">
                        Get the right skill for the right job you want
                      </p>
                      <div className="read-more text-center">
                        <a
                          href='javascript:;' onclick='checkforSSO();'
                          target="_blank"
                          className="text-dark"
                        >
                          <i className="icofont-arrow-right"></i> Get Started
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </SwiperSlide>
                ...
              </Swiper>
            </div>
          </div>
        </section>

        <section id="team" className="team section-bg">
          <div className="container mentor-swiper">
            <div className="section-title">
              <h2 data-aos="fade-in">Our Mentors</h2>
            </div>

            <div className="swiper-wrapper">
              <Swiper
                spaceBetween={20}
                slidesPerView={5}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{ delay: 3000 }}
              >
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="800"
                    >
                      <div className="pic">
                        <img src={m0} alt="" />
                      </div>
                      <h4>Ashish Khare</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="800"
                    >
                      <div className="pic">
                        <img src={m9} alt="" />
                      </div>
                      <h4>Swaaruup Gandewar</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <div className="pic">
                        <img src={m5} alt="" />
                      </div>
                      <h4>Geet K Vijan</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="600"
                    >
                      <div className="pic">
                        <img src={m7} alt="" />
                      </div>
                      <h4>Karuna Ahuja</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="pic">
                        <img src={m3} alt="" />
                      </div>
                      <h4>Aparna Apte</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="pic">
                        <img src={m10} alt="" />
                      </div>
                      <h4>Krishna Priya Harsh</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="member" data-aos="fade-up">
                      <div className="pic">
                        <img src={m1} alt="" />
                      </div>
                      <h4>Amit Khare</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div className="pic">
                        <img src={m2} alt="" />
                      </div>
                      <h4>Aparajita Prasad</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <div className="pic">
                        <img src={m4} alt="" />
                      </div>
                      <h4>Gaurav Sharma</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <div className="pic">
                        <img src={m6} alt="" />
                      </div>
                      <h4>Kamilini Roy</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div
                      className="member"
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      <div className="pic">
                        <img src={m8} alt="" />
                      </div>
                      <h4>Mangala Sr</h4>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>

        <section id="pricing" className="pricing section-bg">
          <div className="container">
            <div className="section-title">
              <h2 data-aos="fade-in">Invest in yourself</h2>
            </div>

            <div className="row no-gutters">
              <div className="col-lg-4 offset-lg-1 box" data-aos="zoom-in">
                <h5>Lifetime Free on SignUp</h5>
                <h4>
                  ₹0<span>per month</span>
                </h4>
                <ul>
                  <li>
                    <i className="fa fa-check"></i> Live Session with mentors
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Ask a mentor(Dtawk) Mentor
                    to <br /> &nbsp;&nbsp; &nbsp; &nbsp;you and vice versa
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Free Event Passes
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Free Games(Puzzles,
                    Strategic and <br /> &nbsp;&nbsp; &nbsp; &nbsp;Logics)
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Free videos from mentors
                  </li>
                </ul>
                <a
                  href='javascript:;' onclick='checkforSSO();'
                  target="_blank"
                  className="get-started-btn"
                >
                  Get Started
                </a>
              </div>

              <div
                className="col-lg-4 offset-lg-2 box order-2 order-lg-1"
                data-aos="zoom-in"
              >
                <h5>
                  Student Mentorship Program
                  <br />
                  <small>(SMP)</small>
                </h5>
                <label
                  className="toggler toggler--is-active"
                  id="filt-monthly"
                  style={
                    value === "quaterly"
                      ? { color: "#fdc33a" }
                      : { color: "#ddd" }
                  }
                >
                  Quaterly
                </label>
                <div className="toggle">
                  <input
                    type="checkbox"
                    id="switcher"
                    className="check"
                    onChange={() => {
                      value === "yearly"
                        ? setValue("quaterly")
                        : setValue("yearly");
                    }}
                  />
                  <b className="b switch"></b>
                </div>
                <label
                  className="toggler"
                  id="filt-hourly"
                  style={value !== "quaterly" ? { color: "#fdc33a" } : {}}
                >
                  Yearly
                </label>
                {value !== "quaterly" ? (
                  <h4 id="yearly">
                    ₹1799<span>per year</span>
                  </h4>
                ) : (
                  <h4 id="quaterly">
                    ₹599<span>per quarter</span>
                  </h4>
                )}
                <ul>
                  <li>
                    <i className="fa fa-check"></i> Free+
                  </li>
                  <li>
                    <i className="fa fa-check"></i> SMP of 10hrs (6 Chapters, 22{" "}
                    <br /> &nbsp;&nbsp; &nbsp; &nbsp;Modules and 60 Capsules)
                    and <br /> &nbsp;&nbsp; &nbsp; &nbsp;premium content in
                    every month
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Assessment and Certificate
                    for <br /> &nbsp;&nbsp; &nbsp; &nbsp;each all chapters
                  </li>
                  <li>
                    <i className="fa fa-check"></i> SMP Special Live classNamees
                    by <br /> &nbsp;&nbsp; &nbsp; &nbsp;Mentors
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Live Chat with Corporate
                    Buddy/ <br /> &nbsp;&nbsp; &nbsp; &nbsp;Passion Buddy
                    /Connects
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Live Chat by Industry
                    Experts
                  </li>
                  <li>
                    <i className="fa fa-check"></i> 1:1 personalized chat with
                    selected <br /> &nbsp;&nbsp; &nbsp; &nbsp;mentors
                  </li>
                  <li>
                    <i className="fa fa-check"></i> 15mins free call with
                    selected <br /> &nbsp;&nbsp; &nbsp; &nbsp;mentors
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Flat 50% off on all paid
                    events
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Flat 10% off on Premium
                    services
                  </li>
                </ul>
                <a
                  href='javascript:;' onclick='checkforSSO();'
                  target="_blank"
                  className="get-started-btn"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div className="container partners-swiper">
            <div className="section-title" style={{ paddingBottom: 0 }}>
              <h2 data-aos="fade-in">Mentorkart Partners</h2>
            </div>
            <div className="no-gutters swiper-wrapper clients-wrap clearfix wow fadeInUp">
              <Swiper
                spaceBetween={20}
                slidesPerView={5}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{ delay: 3000 }}
              >
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c1}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c3}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c14}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c5}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c6}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c7}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c8}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c9}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c10}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c11}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c12}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c4}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="client-logo">
                      <img
                        src={c_c15}
                        className="img-fluid"
                        alt=""
                        data-aos="flip-right"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>

        {/* <footer id="footer">
          <div className="footer-top">
            <div className="container" data-aos="fade-up">
              <div className="row  justify-content-center">
                <div className="col-lg-6">
                  <img
                    src={whitelogo}
                    style={{ maxHeight: 120 }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div className="row footer-newsletter justify-content-center">
                <div className="col-lg-6">
                  <a
                    href='javascript:;' onclick='checkforSSO();'
                    target="_blank"
                    className="btn btn-warning"
                    style={{ borderRadius: 20 }}
                  >
                    LOGIN / SIGNUP <i className="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
              <div className="social-links">
                <a
                  href="https://twitter.com/mentor_kart"
                  target="_blank"
                  className="twitter"
                >
                  <i className="fa fa-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com/mentorkart/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/mentorkart/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="fa fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/mentorkart/"
                  target="_blank"
                  className="linkedin"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="container footer-bottom clearfix">
            <div className="copyright" style={{ color: "white" }}>
              &copy; Copyright{" "}
              <strong>
                <span>Mentorkart</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits" style={{ color: "white" }}>
              Designed by{" "}
              <a
                target="_blank"
                href="https://www.leafcreations.in/"
                style={{ color: "#ffc107" }}
              >
                Leaf Creations
              </a>
            </div>
          </div>
        </footer> */}
      </main> 

      <a href="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </a>
    </>
  );
}

import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import p1 from "../../assets/svg/plan/p1.svg";
import p2 from "../../assets/svg/plan/p2.svg";
import p3 from "../../assets/svg/plan/p3.svg";
import p4 from "../../assets/svg/plan/p4.svg";
import CircleIcon from "@mui/icons-material/Circle";
const TempCurrentPlan = ({
  profileUnlocks,
  nextBillingDate,
  jobPosting,
  keyAccountManager,
  creditStatus,
}) => {
  const data = [
    {
      icon: p1,
      title: "Profile Unlocks",
      res: profileUnlocks,
    },
    {
      icon: p2,
      title: "Job Posting",
      res: jobPosting,
    },
    {
      icon: p3,
      title: "Key Account Manager",
      res: keyAccountManager ? "Yes" : "No",
    },
    {
      icon: p4,
      title: "Next Billing Date",
      res: nextBillingDate,
    },
  ];

  return (
    <>
      <Paper
        style={{
          padding: "20px",
          borderRadius: "20px",
          //   boxShadow: "0px, 0px rgba(0, 0, 0, 0.09)",
          // boxShadow: "none",
          // border: ".5px solid #6C757D",
          boxShadow: "0px 0px 5px 2px #00000017",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h5" component="h3" style={{ fontWeight: "500" }}>
            Current Plan Details
          </Typography>
          {
            <Typography
              variant="h5"
              variant="h5"
              style={{
                color: creditStatus === "ACTIVE" ? "#12CB02" : "red",
                fontWeight: 500,
                display: "flex",

                alignItems: "center",
              }}
            >
              <CircleIcon
                sx={{
                  fontSize: 14,
                  color: creditStatus === "ACTIVE" ? "#12CB02" : "red",
                  marginRight: "5px",
                }}
              />
              {creditStatus === "ACTIVE" ? "Active" : "InActive"}
            </Typography>
            /*   <div style={{ color: "red" }}>In Active</div> */
          }

          {/*     <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: "30px", boxShadow: "none" }}
            onClick={handleClickOpen}
          >
            Change Plan
          </Button> */}
        </div>
        {data.map((item) => (
          <Grid container spacing={2}>
            <Grid item md={1}>
              <img src={item.icon} height="15px" />
            </Grid>
            <Grid item md={5}>
              <Typography variant="body2">{item.title}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="body2">{item.res}</Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
      {/*   <AllPlanDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      /> */}
    </>
  );
};

export default TempCurrentPlan;

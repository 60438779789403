import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, InputBase, Divider, Slide, IconButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import CssTextField from "../../../common/JobPostNew/FormComponents/CssTextField";
import search_icon from "../../../../assets/search.svg";
import { NEXT_APP_ENDPOINT } from "../../../../constants/constants";
const useStyles = makeStyles((theme) => ({
  search_btn: {
    display: "flex",
    padding: "20px",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSearch({ jobRoleList }) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [currentJobRole, setCurrentJobRole] = useState();

  useEffect(() => {
    if (currentJobRole) {
      // history.push({
      //   pathname: `/public/jobs`,
      //   search: `?job_role_id=${currentJobRole.id}`,
      // });
      window.open(`${NEXT_APP_ENDPOINT}/public/jobs?job_role_id=${currentJobRole.id}`, "_self");

      handleClose();
    }
  }, [currentJobRole]);

  return (
    <div>
      <Button color="inherit" onClick={handleClickOpen} style={{ padding: "0px" }}>
        <img src={search_icon} alt="GetWork" width="20px" height="20px" />
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div className={classes.search_btn}>
          {/* <IconButton type="submit" className={classes.iconButton} aria-label="search">
           <SearchIcon />
         </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Search Here"
        inputProps={{ 'aria-label': 'Search Here' }}
      />
      <IconButton type="submit" onClick={handleClose}  className={classes.iconButton} aria-label="search">
        <CloseIcon/>
      </IconButton> */}
          <Autocomplete
            id="multiple-limit-tags"
            size="small"
            style={{ width: "100%" }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={currentJobRole}
            onChange={(event, newValue) => {
              if (newValue) {
                setCurrentJobRole(newValue);
              }
            }}
            options={jobRoleList}
            getOptionLabel={(option) => option.job_role_name}
            renderOption={(option) => {
              return <span style={{ fontSize: 14 }}>{`${option.job_role_name}`}</span>;
            }}
            renderInput={(params) => (
              <CssTextField
                {...params}
                variant="outlined"
                placeholder="Search"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.searchIcon}>
                        <SearchIcon className={classes.icon} />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>

        <Divider />
      </Dialog>
    </div>
  );
}

import { Box, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Doughnut, HorizontalBar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getPlacementByDegree, getSkills, setRef } from "../action";
import ReportsControlPanel from "../ReportsControlPanel";
import ReportsTable from "../ReportsTable";

const options2 = {
  legend: {
    position: "right",
    align: "start",
  },
};

const PlacementReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef();

  const skills = useSelector((state) => state.Reports.skills);
  const placementByDegree = useSelector(
    (state) => state.Reports.placementByDegree
  );

  const [barChartData, setBarChartData] = useState([]);

  function hashCode(str) {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  useEffect(() => {
    if (!skills) {
      dispatch(getSkills());
    }
    if (Object.keys(placementByDegree).length === 0) {
      dispatch(getPlacementByDegree());
    }

    dispatch(setRef(ref));
  }, []);

  const generateBarChartData = () => {
    if (skills && skills.results) {
      setBarChartData(
        Object.entries(placementByDegree.results).forEach(
          ([key, value]) => value.placed / value.count / 100
        )
      );
    }
  };

  useEffect(() => {
    if (placementByDegree.result) {
      let arr = [];

      Object.entries(placementByDegree.result).forEach(([key, value]) =>
        arr.push({
          name: key,
          percentage: value.placed / value.count / 100,
        })
      );

      setBarChartData(arr);
    }

    generateBarChartData();
  }, [placementByDegree]);

  const barData = {
    labels: barChartData.map((item) => item.name),
    datasets: [
      {
        data: barChartData.map((item) => item.percentage.toFixed(4)),
        backgroundColor: barChartData.map(
          (item) => `#${intToRGB(hashCode(item.name))}`
        ),

        borderColor: barChartData.map(
          (item) => `#${intToRGB(hashCode(item.name))}`
        ),
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const data2 = {
    datasets: [
      {
        data: barChartData.map((item) => item.percentage),
        backgroundColor: barChartData.map(
          (item) => `#${intToRGB(hashCode(item.name))}`
        ),
      },
    ],
    labels: barChartData.map((item) => item.name),
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <Box
      padding="2%"
      width="100%"
      style={{ maxHeight: "100%", overflowY: "scroll" }}
    >
      <ReportsControlPanel
        // buttons={["Show Chart", "Export", "Send Email"]}
        buttons={["Export"]}
        title="Placement Report"
        tableData={[]}
      />
      <p>Summary Report</p>
      <ReportsTable
        columns={[
          "Total Students",
          "Students placed",
          "% Placed",
          "Avg. Skills per Student",
        ]}
        data={[placementByDegree]}
      />
      <Box className={classes.chartBox} ref={ref}>
        <Box
          // width="400px"
          // height="400px"
          //display="flex"
          //alignItems="center"
          padding="3%"
        >
          <HorizontalBar data={barData} options={options} />
        </Box>
        <Box
          //width="400px"
          //height="400px"
          //display="flex"
          //alignItems="center"
          padding="3%"
        >
          <Doughnut data={data2} options={options2} />
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    chartBox: {
      background: theme.palette.background.paper,
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      borderRadius: "25px",
      padding: "2%",
      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
      //display: "flex",
    },
  })
);

export default PlacementReport;

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  alpha,
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  List,
  Paper,
  styled,
  Typography,
  useTheme
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../constants/constants";
import user from "../../../Assets/Images/png/aisha.png";
import {
  CustomTextField
} from "../../Common/Input/InputField";

const CustomListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function JobPreferences({
  setStep,
  step,
  finalRemote,
  setFinalRemote,
  finalAnnualSalary,
  setFinalAnnualSalary,
  workLocations,
  setWorkLocations,
  finalJobType,
  setFinalJobType,
  getContinueButtonId,
  getSkipButtonId
}) {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([0]);
  const [remote, setRemote] = useState(finalRemote);
  const [annualSalary, setAnnualSalary] = useState(finalAnnualSalary);
  const [chipData, setChipData] = useState(workLocations);
  const [jobType, setJobType] = useState(finalJobType);
  const [fulltime, setFulltime] = useState(
    finalJobType === 0 || finalJobType === 7 ? true : false
  );
  const [internship, setInternship] = useState(
    finalJobType === 0 || finalJobType === 1 ? true : false
  );

  const theme = useTheme();

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips?.filter((chip) => chip?.city_id !== chipToDelete?.city_id)
    );
  };

  const [cityData, setCityData] = useState([]);
  const [searchedCity, setSearchedCity] = useState();
  const [selectedCity, setSelectedCity] = useState({});
  const [cityLoading, setCityLoading] = useState(false);

  const getCityState = () => {
    setCityLoading(true);
    const citySearch = searchedCity ? `?city=${searchedCity}` : "";
    Axios.get(`${BackendBaseApi.PRAVESH}api/location/search/city/${citySearch}`)
      .then((res) => {
        if (res?.data?.success) {
          setCityData(res?.data?.data);
        }
        setCityLoading(false);
      })
      .catch((err) => {
        setCityLoading(false);
        alert.error("Something went wrong. Please try again!!");
      });
  };

  useEffect(() => {
    getCityState();
  }, [searchedCity]);

  const getSalaryValue = () => {
    let data = {};
    const sal = salary?.find((x) => x.value === annualSalary || (annualSalary >= x?.min && annualSalary <= x?.max));
    if (sal) {
      data = sal;
    }
    return data;
  };

  const updateChipData = (value) => {
    const ind = chipData?.findIndex((x) => x.city_id === value?.city_id);
    if (ind === -1) {
      setChipData([...chipData, value]);
    }
  };

  const gwToken = localStorage.getItem("gw_token");

  const updateJobPreference = () => {
    if (!validateJobPreference()) {
      return;
    }

    setLoading(true);
    Axios.post(
      `${BackendBaseApi.PRAVESH}api/student/signup/job-preferences/`,
      {
        job_type: fulltime && internship ? 0 : fulltime ? 7 : 1,
        salary_expectation: annualSalary,
        preferred_locations: chipData,
        wfh: remote,
      },
      {
        headers: {
          Authorization: "Token " + gwToken,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        if (res?.data?.success) {
          setLoading(false);
          setFinalAnnualSalary(annualSalary);
          setFinalRemote(remote);
          setFinalJobType(fulltime && internship ? 0 : fulltime ? 7 : 1);
          setWorkLocations(chipData);
          setStep(step + 1);
        } else {
          alert.error("Something went wrong. Please try again!!");
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Something went wrong. Please try again!!");
      });
  };

  const validateJobPreference = () => {
    if (!annualSalary) {
      alert.error("Please enter annual salary!!");
      return false;
    }
    if (!chipData || chipData.length === 0) {
      alert.error("Please enter atleast one preferred work location!!");
      return false;
    }
    return true;
  };

  const debounce = (func) => {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChangeSearch = (e) => {
    setCityData([]);
    setSearchedCity(e.target.value);
    
  };

  const optimisedHandleChange = React.useCallback(debounce(handleChangeSearch), []);

  return (
    <>
      <div>
        {" "}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar alt="user-img" src={user} sx={{ width: 66, height: 66 }} />
          <Typography
            variant="h4"
            style={{ fontWeight: "500", lineHeight: 1.4, marginTop: 5 }}
          >
            Okay, let me know your job preferences
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={12} sx={{ paddingBottom: 0 }}>
            <Typography
              variant="body2"
              color="initial"
              style={{
                color: "#6C757D",
                marginTop: "5px",
                marginBottom: "-3px",
              }}
            >
              Type of opportunities*
            </Typography>
            <div>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={fulltime}
                      onChange={() => {
                        setFulltime(!fulltime);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      color="initial"
                      // style={{ color: "#6C757D" }}
                    >
                      Full-Time Jobs
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={internship}
                      onChange={() => {
                        setInternship(!internship);
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      color="initial"
                      // style={{ color: "#6C757D" }}
                    >
                      Internship
                    </Typography>
                  }
                />
              </List>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={12} style={{ display: "grid" }}>
            <Typography
              variant="body2"
              color="initial"
              style={{ color: "#6C757D", marginBottom: "7px" }}
            >
              Annual salary expectation*
            </Typography>

            <Autocomplete
              options={salary}
              value={getSalaryValue()}
              disableClearable
              openOnFocus={true}
              getOptionLabel={(option) => (option?.title ? option?.title : "")}
              renderOption={(props, option) => {
                let { key, ...propsNew } = props;
                return (
                  <Typography
                    style={{
                      fontSize: "14px",
                      padding: "5px 20px",
                      cursor: "pointer",
                    }}
                    {...propsNew}
                  >
                    {option?.title}
                  </Typography>
                );
              }}
              style={{
                background: "#fff",
                borderRadius: 30,
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  required
                  label="Expected Salary"
                  size="small"
                  onKeyPress={(e) => {
                    e.preventDefault();
                  }}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(event, value) => {
                if (value) {
                  setAnnualSalary(value?.value);
                } else {
                  setAnnualSalary("");
                }
              }}
            />
          </Grid>
          {/* <Grid xs={12} sm={12} md={12} style={{ display: "grid" }}>
            <Typography
              variant="body2"
              color="initial"
              style={{ color: "#6C757D" }}
            >
              Work location preferences*
            </Typography>
          </Grid> */}
          <Grid
            xs={12}
            sm={12}
            md={12}
            style={{ display: "grid", paddingBottom: 0 }}
          >
            <Typography
              variant="body2"
              color="initial"
              style={{ color: "#6C757D", marginBottom: "7px" }}
            >
              Work location preferences*
            </Typography>
            <Autocomplete
              id="select-city"
              options={cityData}
              value={selectedCity}
              // defaultValue=""
              loading={cityLoading}
              getOptionLabel={(option) =>
                option?.city && option?.state
                  ? `${option?.city}, ${option?.state}`
                  : ""
              }
              renderOption={(props, option) => {
                let { key, ...propsNew } = props;
                return (
                  <Typography
                    style={{
                      fontSize: "14px",
                      padding: "5px 20px",
                      cursor: "pointer",
                    }}
                    {...propsNew}
                  >
                    {option?.city}, {option?.state}
                  </Typography>
                );
              }}
              style={{
                background: "#fff",
                borderRadius: 30,
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  label="Work Location"
                  size="small"
                  required
                  onChange={(ev) => {
                    setCityLoading(true);
                    // dont fire API if the user delete or not entered anything
                      optimisedHandleChange(ev);
                  }}
                  // value=""
                  // error={errorValues?.city ? true : false}
                  // helperText={errorValues?.city ? errorValues?.city : ""}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedCity({});
                  updateChipData(newValue);
                }
              }}
             
            />
            <Box sx={{ marginTop: "7px", marginLeft: "-7px" }}>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                  boxShadow: "none",
                }}
                component="ul"
              >
                {chipData?.map((data) => {
                  return (
                    <CustomListItem key={data.city_id}>
                      <Chip
                        size="small"
                        color="primary"
                        variant="outlined"
                        deleteIcon={<CloseRoundedIcon />}
                        sx={{
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1
                          ),
                        }}
                        label={data?.city}
                        onDelete={handleDelete(data)}
                      />
                    </CustomListItem>
                  );
                })}
              </Paper>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={12} sx={{ pt: 0 }}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={remote}
                    onChange={() => {
                      setRemote(!remote);
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="initial"
                    style={{ color: "#6C757D" }}
                  >
                    Willing to work remotely
                  </Typography>
                }
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <AppBar
        color="inherit"
        sx={{
          boxShadow: "none",
          position: "relative",
          marginTop: "10px",
          [theme.breakpoints.only("sm")]: {
            top: "auto",
            bottom: 0,
            boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
            position: "fixed",
          },
          [theme.breakpoints.only("xs")]: {
            top: "auto",
            bottom: 0,
            boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
            position: "fixed",
          },
        }}
      >
        <Box
          sx={{
            pl: 0,
            pr: 0,
            [theme.breakpoints.only("sm")]: { pl: 1, pr: 1 },
            [theme.breakpoints.only("xs")]: { pl: 1, pr: 1 },
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
              <Button
                color="primary"
                sx={{
                  height: "40px",
                  width: "100%",
                  boxShadow: "none",
                  borderRadius: "30px",
                  display: "flex",
                  fontWeight: "400",
                  fontSize: "15px",
                }}
                variant="outlined"
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
              <Button
                color="primary"
                endIcon={<ArrowForwardRoundedIcon />}
                sx={{
                  height: "40px",
                  width: "100%",
                  boxShadow: "none",
                  borderRadius: "30px",
                  display: "flex",
                  fontWeight: "400",
                  fontSize: "15px",
                }}
                variant="contained"
                onClick={() => {
                  updateJobPreference();
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  <span id={getContinueButtonId()}> Continue</span>
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    </>
  );
}

const salary = [
  { title: "0-3 Lakhs", value: 100000, min: 0, max: 299999 },
  { title: "3-5 Lakhs", value: 300000, min: 300000, max: 499999 },
  { title: "5-7 Lakhs", value: 500000, min: 500000, max: 699999 },
  { title: "7-10 Lakhs", value: 700000, min: 700000, max: 999999 },
  { title: "10-12 Lakhs", value: 1000000, min: 1000000, max: 1199999 },
  { title: "12-15 Lakhs", value: 1200000, min: 1200000, max: 1499999 },
  { title: "15-18 Lakhs", value: 1500000, min: 1500000, max: 1799999 },
  { title: "18-20 Lakhs", value: 1800000, min: 1800000, max: 1999999 },
  { title: "20+ Lakhs", value: 2000000, min: 2000000, max: 20000000 },
];

import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  paper: {
    // minHeight: "600px",
    borderRadius: "20px",
    // textAlign: "center",
    padding: 12,
    width: "100%",
    marginBottom: 10,
  },
}));

function ProjectDetailsCards(props) {
  const { data, index, editBtn, handleDelete } = props;

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="body1" style={{ textAlign: "left", fontSize: 15 }}>
              {data?.type}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <EditIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                editBtn(data?.id);
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <DeleteIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                handleDelete(data?.id);
              }}
            />
          </Grid>
        </Grid>

        <Typography variant="body2" style={{ color: "#6c757d", textAlign: "left", fontSize: 13 }}>
          {data?.title}
        </Typography>
        <Typography variant="body2" style={{ color: "#6c757d", textAlign: "left", fontSize: 12 }}>
          {data?.description}
        </Typography>

        <Typography variant="body2" style={{ textAlign: "left", fontSize: 13, color: "#007bff" }}>
          {/* {data?.formatted_start_date ? data?.formatted_start_date + " - " : ""} {data?.formatted_end_date} */}
          {
            data.links &&
            <a style={{ textAlign: "left", fontSize: 13, color: "#007bff" }} href={data.links} target="blank">
              <b>View Live</b>
            </a>
          }
        </Typography>
      </Paper>
    </>
  );
}

export default ProjectDetailsCards;

import React from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },
  img: {
    paddingLeft: "0px",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    // marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    //  paddingRight: "10px",
    //paddingLeft: "10px",
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  playstore: {
    borderRadius: "10px",
  },
  social: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "12px",
    color: "white",
  },
  linktext: {
    color: "white",
  },
  social: {
    color: "#fff",
    display: "flex",
    width: "170px",
    justifyContent: "space-between",
  },
}));

export default function Social() {
  const classes = useStyles();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      <div className={classes.social} id="footerBtn">
        <Link href={domain?.factor?.facebook_link} className={classes.social} target="_blank">
          <FacebookIcon fontSize="12px" id="footerBtn" />
        </Link>
        <Link href={domain?.factor?.instagram_link} className={classes.social} target="_blank">
          <InstagramIcon id="footerBtn" />
        </Link>
        <Link href={domain?.factor?.twitter_link} className={classes.social} target="_blank">
          <TwitterIcon id="footerBtn" />
        </Link>
        <Link href={domain?.factor?.linkedin_link} className={classes.social} target="_blank">
          <LinkedInIcon id="footerBtn" />
        </Link>
        <Link href={domain?.factor?.youtube_link} className={classes.social} target="_blank">
          <YouTubeIcon id="footerBtn" />
        </Link>
      </div>
    </>
  );
}

import {
  Button, createStyles, makeStyles
} from "@material-ui/core";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Refresh from "../../assets/common/refresh.svg";
import PaymentLoading from "../../assets/payment-loading.gif";
import { getCreditInfo } from "./action";
import {useHistory} from "react-router-dom";

const CreditBoard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("user_type_id") !== null &&
      localStorage.getItem("user_id") !== null &&
      Object.keys(creditInfo).length === 0
    ) {
      dispatch(getCreditInfo(setLoading));
    }
  }, [localStorage.getItem("user_type_id"), localStorage.getItem("user_id")]);

  return (
    <Button
      startIcon={<AccountBalanceWalletOutlinedIcon />}
      variant="text"
      color="primary"
      // endIcon={
      //   <img
      //     src={Refresh}
      //     alt="loading"
      //     width="25px"
      //     height="auto"
      //     onClick={() => dispatch(getCreditInfo(setLoading))}
      //   />
      // }
      style={{
        height: "40px",
        borderRadius: "30px",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "flex",
        boxShadow: "none",
        backgroundColor: "#fff",
        border: "1px solid #C4C4C4",
        marginRight: props?.iscollege ? "0px" : "30px",

      }}
      onClick={() => {
        const userType = localStorage.getItem("user_type");
        if(userType === 'College')
          history.push("/college/college-settings/?credits");
        else
          history.push("/company/company-settings/?credits");
    }}
    >
      {loading ? (
        <img src={PaymentLoading} alt="loading" width="20px" height="auto" style={{ marginLeft: "5px" }} />
      ) : (
        <span>Credits: {creditInfo ? creditInfo.available_credits : "0"}</span>
      )}
    </Button>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    amount: {
      marginLeft: 7,
    },
    iconButton: {
      "&:focus": {
        outline: "none",
      },
    },
    mainBox: {
      display: "flex",
      alignItems: "center",
      padding: "5px 12px",

      borderRadius: "5px",
      justifyContent: "space-between",

      borderRadius: "10px",

      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
    },
  })
);

export default CreditBoard;

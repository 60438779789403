import React from "react";
import { useHistory } from "react-router-dom";
import { positions, useAlert } from "react-alert";
import "./JobOverview.css";
import { Backdrop } from "@material-ui/core";
import CommonButton from "../CommonButton/CommonButton";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";

import JobOverviewContent from "./JobOverviewContent";
import { makeStyles } from "@material-ui/core/styles";
import loaderGIF from "../../../assets/images/loopingcircles-3.gif";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function JobOverview({ jobData, setNavValue, setApplySuccessful, isUserApplied, setIsUserApplied, collegeJobUrl = "" }) {
  const alert = useAlert();
  let history = useHistory();
  const classes = useStyles();
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const jobApply = () => {
    if (window.document.getElementById("submit_application_btn__overview").disabled) {
      alert.error("Wait!! You previous request is under progress...");
      return;
    }
    window.document.getElementById("submit_application_btn__overview").disabled = true;

    setSubmitLoading(true);
    Axios.post(
      BackendBaseApi.PRAVESH + "api/public_signup/",
      {
        job_id: jobData.job_id,
        user_id: (localStorage.getItem("user_details") && JSON.parse(localStorage.getItem("user_details")).id) || parseInt(localStorage.getItem("user_id")),
      },
      { withCredentials: true }
    )
      .then((res) => {
        window.document.getElementById("submit_application_btn__overview").disabled = false;
        if (!res.data.success) {
          alert.error(res.data.data.message, { position: positions.TOP_RIGHT });
        } else {
          alert.success(res.data.data.message, { position: positions.TOP_RIGHT });
          if (Object.keys(res.data.data.verbose_data).length > 0) {
            localStorage.setItem("gw_token", res.data.data.verbose_data.token);
            localStorage.setItem("user_type_id", res.data.data.verbose_data.user_type_id);
            localStorage.setItem("user_type", res.data.data.verbose_data.user_type);
            localStorage.setItem("user_id", res.data.data.verbose_data.user_id);
            localStorage.setItem("email_verified", res.data.data.verbose_data.email_verified);
          }
          setIsUserApplied(true);
          setApplySuccessful(true);
          setNavValue("success");
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const checkForApply = (e) => {
    e.preventDefault();
    Axios.get(BackendBaseApi.PRAVESH + "api/profile/details/", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
      withCredentials: true,
    }).then((res) => {
      if (res.data.user_type[0]?.main_user === "Student" || res.data.user_type[0]?.type === 1) {
        if (res.data.is_pref_filled) jobApply();
        else {
          // set user id in local storage to be used while applyinh for job
          localStorage.setItem("user_id", res.data.id);

          alert.show("Complete your profile before applying for job!");
          setTimeout(() => {
            // window.open("/student/complete-profile",'_self')
            history.push({
              pathname: "/student/complete-profile",
              jobData: jobData,
            });
          }, 2000);
        }
      } else {
        alert.error("Not Logged In as Student User !");
      }
    });
  };

  return (
    <div>
      {submitLoading && (
        <Backdrop className={classes.backdrop} open={submitLoading}>
          <img src={loaderGIF} alt="Loading..." width="160px" height="90px" />
        </Backdrop>
      )}
      <JobOverviewContent jobData={jobData} />
      {((localStorage.getItem("gw_token") &&
        localStorage.getItem("user_type_id") === "1" &&
        (localStorage.getItem("user_id") || JSON.parse(localStorage.getItem("user_details")).id)) ||
        !localStorage.getItem("gw_token")) &&
        jobData.job_status === "OPEN" &&
        jobData.up_coming_state?.code === 0 && (
          <>
            <br />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {isUserApplied ? (
                <CommonButton variant="contained" style={{ outline: "none" }}>
                  Applied
                </CommonButton>
              ) : (
                <CommonButton
                  variant="contained"
                  style={{ outline: "none" }}
                  onClick={(e) => (localStorage.getItem("gw_token") ? checkForApply(e) : setNavValue("application"))}
                  id="submit_application_btn__overview"
                >
                  SUBMIT APPLICATION
                </CommonButton>
              )}
            </div>
            <br />
            <br />
          </>
        )}
      {localStorage.getItem("gw_token") && localStorage.getItem("user_type") === "College" && collegeJobUrl && (
        <>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <CommonButton
              variant="contained"
              style={{ outline: "none" }}
              onClick={() => {
                window.open(collegeJobUrl, "_blank");
              }}
            >
              View Job
            </CommonButton>
          </div>
          <br />
          <br />
        </>
      )}
    </div>
  );
}

export default JobOverview;

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import demo from "../../../assets/demo.svg";
import Typography from "@material-ui/core/Typography";
import { Button, Chip, Avatar } from "@material-ui/core";
import job_icon from "../../../assets/svg/icon/job_b.svg";
import rupee_icon from "../../../assets/svg/icon/rupee.svg";
import location_icon from "../../../assets/svg/icon/location.svg";
import experience from "../../../assets/svg/icon/experiences.svg";
import calendar from "../../../assets/svg/icon/calendar.svg";
import skill from "../../../assets/svg/icon/skill_card.svg";
import cgp from "../../../assets/svg/icon/cgp.svg";
import kFormatter, { LFormatter } from "../../../utils/ZeroToK";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "auto",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  mainGrid: {
    fontSize: "14px",
    fontFamily: "Roboto",
    cursor: "pointer",
  },
  marginLeft: {
    marginLeft: "5px",
  },
  avatar: { height: "24px !important", width: "24px !important" },
  infoFlex: {
    display: "flex",
    justifyContent: "right",
  },
  "@media only screen and (max-width: 800px)": {
    infoFlex: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
}));

export default function JobCard({ data }) {
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Grid item xs={12} sm={6} md={6}>
        <Paper className={fixedHeightPaper} style={{ borderRadius: "25px" }}>
          <Grid
            container
            spacing={3}
            className={classes.mainGrid}
            onClick={() => {
              window.open(`/public/job/${data?.hiring_type === "ON CAMPUS" ? "oncampus" : "offcampus"}/${data.id}`);
            }}
          >
            <Container style={{ display: "flex", flexDirection: "row", paddingTop: "10px", paddingLeft: "12px" }}>
              <Grid item xs={6} sm={4} md={4} lg={3} style={{ height: "100px", width: "100px" }}>
                <img src={data?.company[0] && data?.company[0].company_logo ? data?.company[0].company_logo : demo} height="100%" width="100%" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classes.marginLeft}>
                <Typography variant="h4" gutterBottom style={{ fontFamily: "Roboto", fontSize: "18px" }}>
                  <a>{data.job_title} </a>
                </Typography>

                <Typography variant="body1" gutterBottom style={{ fontSize: "14px", fontFamily: "Roboto" }}>
                  {data?.company[0] ? data?.company[0].company_name : data.job_role_name}
                </Typography>
                <Button size="small" variant="outlined" color="primary" style={{ borderRadius: "30px", color: "green", marginBottom: "10px", borderColor: "green" }}>
                  View Details
                </Button>
              </Grid>

              {/* <Grid item xs={12} sm={2} md={2} lg={3}>
           <div style={{display: 'flex',
           justifyContent: 'space-evenly',
           alignItems: 'center',
           flexWrap: 'wrap',}} >
            
         <Button size= "small" variant="outlined" color="primary" style={{borderRadius:"30px", color:"green",marginBottom:"10px", borderColor:"green"}} 
            > 
          View Details 
         </Button>
      
         {/* <Button size= "small" variant="outlined" color="primary" style={{borderRadius:"30px", color:"	#FF0000",marginBottom:"10px", borderColor:"#FF0000" }}> 
         Reject
        </Button> 
        </div>
        </Grid> */}
            </Container>
            <Grid item xs={4} sm={4} className={classes.infoFlex}>
              <div>
                <img src={experience} height="20px" />
              </div>{" "}
              <div>
                {"   "}&nbsp; {data?.eligibility_criteria?.experience && data?.eligibility_criteria?.experience.length > 0 ? data?.eligibility_criteria?.experience[0] : ""}
              </div>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.infoFlex}>
              <div>
                <img src={rupee_icon} height="20px" />
              </div>{" "}
              <div>
                {"   "}&nbsp;{" "}
                {data.salary_type === "UNPAID"
                  ? "UNPAID"
                  : (data.ctc_min > 99999 ? LFormatter(data.ctc_min) : kFormatter(data.ctc_min)) +
                    "-" +
                    (data.ctc_max > 99999 ? LFormatter(data.ctc_max) : kFormatter(data.ctc_max)) +
                    " " +
                    data.salary_payment_type.toLowerCase()}
              </div>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.infoFlex}>
              <div>
                <img src={location_icon} height="20px" />
              </div>
              <div>
                {"   "}&nbsp; {data?.job_location.length > 0 ? data.job_location.map((e) => e.city).join(", ") : "PAN India"}
              </div>
            </Grid>

            <Grid item xs={4} sm={4} className={classes.infoFlex}>
              <div>
                <img src={job_icon} height="25px" />
              </div>{" "}
              <div>
                {"   "}&nbsp; {data.job_type_name}
              </div>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.infoFlex}>
              <div>
                <img src={calendar} height="20px" />
              </div>{" "}
              <div>
                {"   "}&nbsp; {data?.apply_end_date}
              </div>
            </Grid>
            <Grid item xs={4} sm={4} className={classes.infoFlex}>
              <div>
                <img src={cgp} height="20px" />
              </div>{" "}
              <div>
                {"   "}&nbsp; {data?.eligibility_criteria?.percentage ? data?.eligibility_criteria?.percentage + "%" : "Passed Only"}
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <img src={skill} height="25px" />
              {"   "}&nbsp;{" "}
              {data?.eligibility_criteria?.skills &&
                data?.eligibility_criteria?.skills.map((skill) => {
                  return (
                    <Chip
                      variant="outlined"
                      size="medium"
                      avatar={<Avatar variant="circular">{skill.skill_name ? skill.skill_name.substring(0, 1) : "S"}</Avatar>}
                      label={<b style={{ fontSize: "14px" }}>{skill.skill_name}</b>}
                      color="primary"
                      style={{ margin: "0 1px 5px 2px" }}
                    />
                  );
                })}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

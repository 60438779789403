import { Tooltip } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import Skeleton from "react-loading-skeleton";
import { BackendBaseApi } from "../../../constants/constants";
import AddDetails from "./AddDetails";

const StudentProfileSkills = () => {
  const disableUpdateButton = useRef();
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  //state variables
  const [skillType, setSkillType] = useState("");
  const [showSkillsForm, setShowSkillsForm] = useState(false);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [interpersonalSkills, setInterpersonalSkills] = useState([]);

  const [newTechnicalSkill, setNewTechnicalSkill] = useState({
    skill_name: "",
    skill_id: null,
    skill_rating: "",
    skill_rating_value: null,
    type: 2,
  });

  const [newInterpersonalSkill, setNewInterpersonalSkill] = useState({
    skill_name: "",
    skill_id: null,
    skill_rating: "",
    skill_rating_value: null,
    type: 1,
  });

  const updateUserSkills = () => {
    //make a post call to send new skills

    let data = [...technicalSkills, ...interpersonalSkills];

    Axios.post(
      BackendBaseApi.PRAVESH + "api/education/user_skill",
      { skill: data },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        alert.success("Skills updated successfully!");
      })
      .catch((err) => {
        alert.error("There was error updating your skills");
      });
  };

  const addNewSkills = () => {
    let temp = [];
    if (skillType == "ADD_TECHNICAL_SKILL") {
      let isValid = true;
      Object.values(newTechnicalSkill).forEach((val, ind) => {
        if (!val) {
          isValid = false;
          return;
        }
      });
      if (!isValid) {
        alert.error("Please fill all the details first");
        return;
      }
      temp = [...technicalSkills];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].skill_name == newTechnicalSkill.skill_name) {
          alert.show(
            "You already have this skill! Remove it first and add it again"
          );
          return;
        }
      }
      temp.push(newTechnicalSkill);
      setTechnicalSkills(temp);
    } else {
      temp = [...interpersonalSkills];
      let isValid = true;
      Object.values(newInterpersonalSkill).forEach((val, ind) => {
        if (!val) {
          isValid = false;
          return;
        }
      });
      if (!isValid) {
        alert.error("Please fill all the details first");
        return;
      }
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].skill_name == newInterpersonalSkill.skill_name) {
          alert.show(
            "You already have this skill! Remove it first and add it again"
          );
          return;
        }
      }
      temp.push(newInterpersonalSkill);
      setInterpersonalSkills(temp);
    }
  };

  const removeSkill = (type, id) => {
    let temp = [];
    type == "technical"
      ? (temp = [...technicalSkills])
      : (temp = [...interpersonalSkills]);
    temp = temp.filter((t) => {
      return t.skill_id != id;
    });
    type == "technical"
      ? setTechnicalSkills(temp)
      : setInterpersonalSkills(temp);
  };

  useEffect(() => {
    //get all skills for the user
    setLoading(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/education/user_skill", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        setLoading(false);

        if (res.data.data.user_skill.length > 0) {
          let technicalskills = [],
            softskills = [];
          res.data.data.user_skill.forEach((skill) => {
            if (skill.type === 2) technicalskills.push(skill);
            else softskills.push(skill);
          });

          setTechnicalSkills(technicalskills);
          setInterpersonalSkills(softskills);
          //alert.success('Retreived skills successfully!');
        } else {
          disableUpdateButton.current.classList.add("d-none");
          alert.show("Looks like you dont have any skills yet!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (technicalSkills.length > 0 || interpersonalSkills.length > 0)
      disableUpdateButton.current.classList.remove("d-none");
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technicalSkills, interpersonalSkills]);

  //set the type of skill and open skill form
  const openSkillsForm = (type) => {
    if (type == "technical") setSkillType("ADD_TECHNICAL_SKILL");
    else setSkillType("ADD_INTERPERSONAL_SKILL");

    setShowSkillsForm(true);
  };

  return (
    <>
      {showSkillsForm && (
        <AddDetails
          appendToParentState={addNewSkills}
          formState={
            skillType == "ADD_TECHNICAL_SKILL"
              ? newTechnicalSkill
              : newInterpersonalSkill
          }
          setFormState={
            skillType == "ADD_TECHNICAL_SKILL"
              ? setNewTechnicalSkill
              : setNewInterpersonalSkill
          }
          detailType={skillType}
          showForm={showSkillsForm}
          setShowForm={setShowSkillsForm}
        />
      )}

      <div className="container">
        <div className="row mt-4 mb-1">
          <div className="col-6 text-left">
            <span
              className="fs-12 fw-500"
              style={{ color: "#000000", fontWeight: "bold" }}
            >
              Technical Skills
            </span>
          </div>
          <div className="col-6 text-right">
            <Tooltip title="Add Technical skill" placement="left">
              <button
                onClick={() => {
                  openSkillsForm("technical");
                }}
                className="add ripple"
              >
                <span>+</span>
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="row mb-5 mx-auto">
          {loading ? (
            <div>Loading...</div>
          ) : technicalSkills.length > 0 ? (
            technicalSkills.map((skill) => {
              return (
                <span
                  key={skill.skill_id}
                  style={{ opacity: 0.6 + 0.1 * skill.rating_value }}
                  className={
                    "gradient-4  m-2 px-3 py-3 badge badge-primary skill-badge fs-13"
                  }
                >
                  {skill.skill_name || <Skeleton width={100} duration={2} />}
                  <i
                    onClick={() => {
                      removeSkill("technical", skill.skill_id);
                    }}
                    className="fas fa-times-circle pl-1 skill-remove"
                  ></i>
                </span>
              );
            })
          ) : (
            <div>
              Technical skills give you an edge over other candidates when
              applying for jobs. Add them now!
            </div>
          )}
        </div>
        <div className="row mt-2 mb-1 ">
          <div className="col-6 text-left">
            <span
              className="fs-12 fw-500 "
              style={{ color: "#000000", fontWeight: "bold" }}
            >
              Interpersonal Skills
            </span>
          </div>
          <div className="col-6 text-right">
            <Tooltip title="Add Interpersonal skill" placement="left">
              <button
                onClick={() => {
                  openSkillsForm("interpersonal");
                }}
                className="add ripple"
              >
                <span>+</span>
              </button>
            </Tooltip>
          </div>
        </div>

        <div className="row mb-3 mx-auto">
          {loading ? (
            <div>Loading...</div>
          ) : interpersonalSkills.length > 0 ? (
            interpersonalSkills.map((skill) => {
              return (
                <span
                  key={skill.skill_id}
                  style={{ opacity: 0.6 + 0.1 * skill.rating_value }}
                  className={
                    "gradient-1  m-2 px-3 py-3 badge badge-primary skill-badge fs-13"
                  }
                >
                  {skill.skill_name}
                  <i
                    onClick={() => {
                      removeSkill("soft", skill.skill_id);
                    }}
                    className="fas fa-times-circle pl-1 skill-remove"
                  ></i>
                </span>
              );
            })
          ) : (
            <div>
              Your soft skills will get you through your HR rounds! Don't forget
              to add them :)
            </div>
          )}
        </div>
        <div className="row mt-4 pt-2 justify-content-center">
          <button
            ref={disableUpdateButton}
            onClick={updateUserSkills}
            className="btn btn-main btn-sm shadow-main"
          >
            Update Skills
          </button>
        </div>
      </div>
    </>
  );
};

export default StudentProfileSkills;

import axios from "axios";
import { loadCSS } from "fg-loadcss";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Loader from "../../../bundles/common/components/UI/Loader";
import Layout from "../../../components/college/Layout/Layout2v";
import { BackendBaseApi } from "../../../constants/constants";
import "./custom.css";
import styles from "./ProfileCSS";

export default function ViewProfile() {
  const location1 = useLocation();

  const urlParams = location1.pathname.split("/");
  const comID = urlParams[urlParams.length - 1];

  const [publicUrl, setPublicUrl] = useState(BackendBaseApi.PRAVESH + "api/college/profile/?college_id=" + comID);

  const [isAbout, setIsAbout] = useState();
  const [isDescription, setIsDescription] = useState();
  const [isName, setIsName] = useState();
  const [isMessage, setIsMessage] = useState();
  const [location, setIsLocation] = useState();
  const [logo, setIsLogo] = useState();
  const [banner, setIsBanner] = useState();
  const [faqs, setIsFaq] = useState();
  const [specs, setIsSpecs] = useState([]);
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [fb, setFb] = useState();
  const [linked, setLinkedIn] = useState();
  const [twitter, setTwitter] = useState();
  const [socialLink, setSocialLink] = useState([]);

  const checkClearBitDomain = (name) => {
    if (name === "LinkedIn") {
      return ".in";
    } else return ".com";
  };

  React.useEffect(() => {
    const node = loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const fetchData = () => {
      setLoader(true);

      axios
        .get(publicUrl, {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            setIsName(res.data.data.name);
            setIsAbout(res.data.data.about);
            setIsDescription(res.data.data.description);
            setIsMessage(res.data.data.message_for_recruiters);
            setIsLocation(res.data.data.location);
            setIsLogo(res.data.data.logo);
            setIsBanner(res.data.data.banner);
            setIsFaq(res.data.data.faqs);
            setIsSpecs(res.data.data.degree_specialization);
            setCity(res.data.data.city_name);
            setState(res.data.data.state_name);
            setFb(res.data.data.social_links[1].value);
            setLinkedIn(res.data.data.social_links[0].value);
            setTwitter(res.data.data.social_links[2].value);
            setSocialLink(res.data.data.social_links);

            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);

          console.log(error);
        });
    };

    fetchData();
  }, []);

  return (
    <div className="collegeprofile">
      <Layout>
        {loader ? (
          <Loader />
        ) : (
          <Container className="mt--7" fluid>
            <Row>
              <Col xl="3">
                <Card
                  className=""
                  style={{
                    position: "fixed",
                    padding: "0 50px 0 50px",
                    width: "22%",
                    overflowY: "scroll",
                    height: "650px",
                  }}
                >
                  <CardBody>
                    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>

                      <img
                        src={logo}
                        alt="logo"
                        height="150px"
                        width="160px"
                        style={{
                          border: "6px solid rgb(182, 205, 226)",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          margin: "auto",
                        }}
                      />
                      <br />
                      <h5 style={{
                        textAlign: "center", minWidth: '15rem',
                      }}>
                        {isName}
                        <br />
                        {location},
                        India
                      </h5>

                    </div>




                    {/* <p style={{ textAlign: "center" }}> {isAbout} </p>
                  <br /> */}
                    {/* <Button className="bbbt" style={{backgroundColor:'rgb(76, 135, 190)', borderColor:'rgb(76, 135, 190)' }}>Edit profile</Button> */}
                    <hr />
                    <br />
                    {socialLink &&
                      socialLink !== null &&
                      socialLink.length > 0 &&
                      socialLink.map((item) => {
                        return (
                          <div style={{ marginBottom: 10 }}>
                            <img
                              onClick={() => window.open(`${item.value}`)}
                              src={"//logo.clearbit.com/" + item.name + checkClearBitDomain(item.name)}
                              style={{
                                height: "30px",
                                width: "auto",
                                cursor: "pointer",
                              }}
                            />
                            &nbsp; {item.name}
                          </div>
                        );
                      })}
                    {/* {fb ? (
                    <p>
                      <a
                        href={fb}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <img className="imgmod" src={fb1} height="18px" />
                        &nbsp; Facebook
                      </a>
                    </p>
                  ) : null}
                  {linked ? (
                    <p>
                      <a
                        href={linked}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <img className="imgmod" src={li} height="18px" />
                        &nbsp; LinkedIn
                      </a>
                    </p>
                  ) : null}
                  {twitter ? (
                    <p>
                      <img
                            onClick={() => window.open(`//${twitter}`)}
                            src={
                              "//logo.clearbit.com/" +
                              "twitter" +
                              checkClearBitDomain("twitter")
                            }
                            style={{
                              height: "30px",
                              width: "auto",
                              cursor: "pointer",
                            }}
                          />
                      &nbsp; Twitter
                    </p>
                  ) : null} */}
                  </CardBody>
                </Card>
              </Col>
              <Col xl="9">
                <Card className="shadow" style={{ padding: "40px" }}>
                  <CardBody>
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundImage: `url(${banner})`,
                        boxShadow: "2px 3px 18px #9E9E9E",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></div>

                    <br />
                    <br />

                    <h3 style={{ textAlign: "center" }}>
                      {isName} - {location},&nbsp; India
                    </h3>

                    {/* <p>9.2/10 Based on 57 Reviews</p> */}

                    <hr />
                    <h4>About</h4>
                    <p>{isAbout}</p>
                    <br />
                    <h4>Description</h4>
                    <p>{isDescription}</p>
                    <br />
                    <h4>Message for recruiters</h4>
                    <p>{isMessage}</p>

                    <br />
                    <h4>Specializations and Course</h4>
                    <br />
                    <div style={styles.tableContainer}>
                      <div style={styles.tab}>
                        <div style={styles.tableHeader22}>Graduation</div>
                        <div style={styles.tableHeader22}>Degree</div>
                        <div style={styles.tableHeader22}>Specialization</div>
                      </div>
                      {specs.map((data, index) => {
                        return (
                          <div style={styles.tab1}>
                            {index % 2 == 0 ? (
                              <div style={styles.tab}>
                                <div style={styles.tableHeaderCollegeProfileEven}>{data.education_type_name}</div>
                                <div style={styles.tableHeaderCollegeProfileEven}>{data.degree_name}</div>
                                <div style={styles.tableHeaderCollegeProfileEven}>{data.specialization_name}</div>
                                {/* <div style={styles.tableHeader3}>{data.job_title}</div> */}
                              </div>
                            ) : (
                              <div style={styles.tab}>
                                <div style={styles.tableHeaderCollegeProfile}>{data.education_type_name}</div>
                                <div style={styles.tableHeaderCollegeProfile}>{data.degree_name}</div>
                                <div style={styles.tableHeaderCollegeProfile}>{data.specialization_name}</div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    <br />
                    {/* <h4>IIM - Ahmedabad Rankings</h4><br/>
             <Row style={{paddingLeft:'40px'}}>
             <Col x1='4'>
                    <Row>
                        <Col xl='3'>
                        <h1>9th</h1>
                        </Col>
                        <Col xl='7'>
                            <p >Business India Magazine 2017 Top B School</p>
                        </Col>
                        <Col xl='2'>
                        </Col>
                        </Row> 
                 </Col>
                 <Col x1='4'>
                    <Row>
                        <Col xl='3'>
                        <h1>9th</h1>
                        </Col>
                        <Col xl='7'>
                            <p >Business India Magazine 2017 Top B School</p>
                        </Col>
                        <Col xl='2'>
                        </Col>
                        </Row> 
                 </Col>

                 <Col x1='4'>
                    <Row>
                        <Col xl='3'>
                        <h1>9th</h1>
                        </Col>
                        <Col xl='7'>
                            <p >Business India Magazine 2017 Top B School</p>
                        </Col>
                        <Col xl='2'>
                        </Col>
                        </Row> 
                 </Col>
             </Row>

             <br/>
             <h4>Our students Come from</h4> */}
                    {faqs && (
                      <>
                        <h4>FAQ's</h4>
                        <p>{ReactHtmlParser(faqs)}</p>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </Layout>
    </div>
  );
}

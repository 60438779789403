/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/common/httpRequest";
import JobsCard from "../JobsCard/JobsCard";
import Layout from "../Layout/Layout";

export default function Index() {
  const [jobData, setJobData] = useState([]);
  const data = JSON.parse(localStorage.getItem("user_details"));

  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);

  useEffect(() => {
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/student/interview/?user_id=${window.btoa(data.id)}`,
      null,
      setJobData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetData = async (baseUrl, endPoint, body, updateState) => {
    let res = await httpRequest(baseUrl, endPoint, body);
    if (res.data.next === null) {
      setEnd(true);
    } else {
      setNewURL(res.data.next.slice(0, 20));
      setNewEndPoint(res.data.next.slice(20));
    }

    updateState(jobData.concat([...res.data.results]));
  };

  const handleScroll = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        const token = localStorage.getItem("gw_token");
        GetData(newURL, newEndPoint, { headers: token }, setJobData);
      }
    }
  };
  return (
    <>
      <Grid container justify="center">
        <Layout>
          <div className="applied mg-left-10">
            <Grid container>
              <Grid xs={12} className="big-card-res">
                <div
                  id="myid"
                  className="scrollY1"
                  style={{ marginTop: "10px" }}
                  onScroll={handleScroll}
                >
                  {jobData.length ? <JobsCard data={jobData} /> : ""}
                </div>
              </Grid>
            </Grid>
            {/* <Grid container style={{width:771}}>
        <Grid xs={12}>
        <div className='flex flex-col align-item-baseline'>

        <h2 className='fs-20 fw-700 float-left text-green mg-top-10 heading-underline'>
        Blog
     </h2>  

     
        </div>
     <BlogCard data={blogData} /> 
        </Grid>
    </Grid>
    <Grid container style={{width:771}}>
        <Grid xs={12}>
        <div className='flex flex-col align-item-baseline'>

        <h2 className='fs-20 fw-700 float-left text-green mg-top-10 heading-underline'>
        Connections
     </h2>  

     
        </div>
     <ConnectionCard data={connectionData} /> 
        </Grid>
    </Grid> */}
          </div>
        </Layout>
      </Grid>
    </>
  );
}

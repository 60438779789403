import React from "react";
import { Breakpoint, BreakpointProvider } from "react-socks";
import { Grid } from "@material-ui/core";

export default function Layout({ children }) {
	return (
		<>
			<BreakpointProvider>
				<Breakpoint large up>
					<Grid container>
						<Grid xs={10}>
							<div
								className="comapnyconc"
								style={{marginTop: 20, paddingRight: 10 }}
							>
								{children}
							</div>
						</Grid>
					</Grid>
				</Breakpoint>
				<Breakpoint medium down>
					<div className="mt-5">{children}</div>
				</Breakpoint>
			</BreakpointProvider>
		</>
	);
}

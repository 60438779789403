import {
  Box,
  Button,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../../constants/constants";
import Axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import JobTypeList from "../../../../student/components/Main/JobTypeList";
import { JobSegmentList } from "../Jobs/PostJob/reducer";
const useStyles = makeStyles((theme) =>
  createStyles({
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "20px 10px 0 20px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
      minHeight: "700px",
      maxHeight: "700px",
      padding: "7px",
      position: "fixed",
      overflowY: "scroll",
      width: "17%",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
    listItem: {
      display: "flex",
      flexDirection: "column",
    },
    heading: {
      fontWeight: "bolder",
      fontSize: "18px",
      color: theme.palette.type === "dark" ? "#ffffff" : "#444343",
    },
    deleteIcon: {
      color: "#ffffff",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      padding: "10px 20px 10px 20px",
      fontWeight: "bold",
      // marginLeft: "42%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  })
);

export default function CollegeList(props) {
  const { data, setPreferencesData } = props;
  const classes = useStyles();
  const alert = useAlert();
  const [segmentTypes, setSegmentTypes] = useState({});
  const [currentSegment, setCurrentSegment] = useState("");
  const [jobSegmentList, setJobSegmentList] = useState(data?.job_segment || []);
  const [jobSegmentEdit, setJobSegmentEdit] = useState(false);
  const [locationEdit, setLocationEdit] = useState(false);
  const [city, setCity] = useState();
  const [cityList, setCityList] = useState({});
  const [selectedCities, setSelectedCities] = useState(data?.location || []);
  const [jobTypes, setJobTypes] = useState({});
  const [selectedJobType, setSelectedJobType] = useState(data?.job_type || []);
  const [jobTypeEdit, setJobTypeEdit] = useState(false);
  const [workExpEdit, setWorkExpEdit] = useState(false);
  const [workExperiences, setWorkExperiences] = useState({});
  const [workExp, setWorkExp] = useState(data?.work_experience || []);

  const work_experiences = [
    {
      name:
        localStorage.getItem("user_type") === "College" ? "Fresher" : "Fresher",
      value: 1,
    },
    {
      name:
        localStorage.getItem("user_type") === "College"
          ? "1 Year Passouts"
          : "1 year",
      value: 2,
    },
    {
      name:
        localStorage.getItem("user_type") === "College"
          ? "2 years Passouts"
          : "2 years",
      value: 3,
    },
    {
      name:
        localStorage.getItem("user_type") === "College"
          ? "More than 3 or more years passouts"
          : "3 and more years",
      value: 4,
    },
  ];

  useEffect(() => {
    getJobSegment();
    getAllLocations();
    getJobTypes();
    let temp = {};

    work_experiences.forEach((j) => {
      temp[j.name] = { ...j, isChecked: checkIsTrueWE(j) };
    });
    setWorkExperiences({ ...temp });
  }, []);

  const checkIsTrueWE = (obj) => {
    var boole = false;
    workExp.map((u, iin) => {
      if (u.id === obj.value) {
        boole = true;
      }
    });
    return boole;
  };
  const getJobSegment = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/segment/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((job_type) => {
            temp[job_type.job_segment] = {
              ...job_type,
              isChecked: checkIsTrueJS(job_type),
            };
          });
          setSegmentTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured 3");
      });
  };
  const getAllLocations = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/location/city/")
      .then((res) => {
        let temp = {};

        for (let i = 0; i < res.data.length; i++) {
          temp[res.data[i].city] = res.data[i].city_id;
        }
        setCityList({ ...temp });
      })
      .catch((err) => {
        alert.error("Some Error Occured 2");
      });
  };

  const getJobTypes = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/type/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((job_type) => {
            temp[job_type.job_type] = {
              ...job_type,
              isChecked: checkIsTrue(job_type),
            };
          });

          setJobTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Some Error Occured 1");
      });
  };

  const checkIsTrue = (obj) => {
    var boole = false;
    selectedJobType.map((u, iin) => {
      if (u.id === obj.id) {
        boole = true;
      }
    });
    return boole;
  };

  const checkIsTrueJS = (obj) => {
    var boole = false;
    jobSegmentList.map((u, iin) => {
      if (u.id === obj.id) {
        boole = true;
      }
    });
    return boole;
  };

  const segments = Object.keys(segmentTypes).map((d) => {
    return { name: d, value: d };
  });
  const cities = Object.keys(cityList).map((City) => {
    return { name: City, value: City };
  });

  const JobSegment = () => (
    <Autocomplete
      value={currentSegment}
      onChange={(event, newValue) => {
        setCurrentSegment(newValue);
      }}
      id="controllable-states-demo"
      options={segments.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Job Segments" variant="outlined" />
      )}
    />
  );
  const CityList = () => (
    <Autocomplete
      value={city}
      onChange={(event, newValue) => {
        setCity(newValue);
      }}
      id="controllable-states-demo"
      options={cities.map((option) => option.name)}
      style={{ width: "70%" }}
      renderInput={(params) => (
        <TextField {...params} label="Choose Cities" variant="outlined" />
      )}
    />
  );

  useEffect(() => {
    if (currentSegment) {
      let list = [...jobSegmentList];
      const listName = list.map((d) => d.name);
      if (listName.includes(currentSegment)) return;
      list.push({ id: segmentTypes[currentSegment], name: currentSegment });
      setJobSegmentList(list);
    }
  }, [currentSegment]);

  useEffect(() => {
    if (city) {
      let list = [...selectedCities];
      const listName = list.map((d) => d.name);
      if (listName.includes(city)) return;
      list.push({ city_id: cityList[city], city: city });
      setSelectedCities(list);
    }
  }, [city]);

  const handleDelete = (key, arr, setArr) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);
    }
  };
  const handleDeleteJobType = (key, arr, setArr, name) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);

      jobTypes[name].isChecked = false;
    }
  };

  const handleDeleteJS = (key, arr, setArr, name) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);

      segmentTypes[name].isChecked = false;
    }
  };
  const handleDeleteWE = (key, arr, setArr) => {
    var name;
    if (key.id === 1) name = "Fresher";
    if (key.id === 2) name = "1 year";
    if (key.id === 3) name = "2 years";
    if (key.id === 4) name = "3 and more years";

    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);
      workExperiences[name].isChecked = false;
    }
  };
  let token = localStorage.getItem("gw_token");

  const handlePreferences = () => {
    var preferencesObject = {
      location: selectedCities,
      job_segment: jobSegmentList,
      job_type: selectedJobType,
      work_experience: workExp,
    };
    var body = {
      preferences: preferencesObject,
    };

    Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/", body, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setPreferencesData(preferencesObject);
          alert.success("Updated Successfully");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };

  const handlePreferencesCollege = () => {
    var arrayObj = [...jobSegmentList];
    arrayObj = arrayObj.map((item) => {
      return {
        job_segment_id: item.id,
        job_segment_name: item.name,
      };
    });

    var preferencesObject = {
      location: selectedCities,
      job_type: selectedJobType,
      work_experience: workExp,
    };
    var body = {
      preferences: preferencesObject,
      job_segments: arrayObj,
    };

    Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", body, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setPreferencesData(preferencesObject);
          alert.success("Updated Successfully");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };

  return (
    <>
      <>
        <div>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h4" className={classes.heading}>
                Job Segment
              </Typography>
            </Grid>
            {jobSegmentList.length > 0 && (
              <Grid item xs={2}>
                <Button
                  onClick={() => setJobSegmentEdit(true)}
                  variant="outlined"
                  color="primary"
                  startIcon={<CreateIcon />}
                >
                  Edit Job Segment
                </Button>
              </Grid>
            )}
          </Grid>
          <br />
          <Divider />
          <br />

          {jobSegmentList.length > 0
            ? jobSegmentList.map((item) =>
                jobSegmentEdit ? (
                  <Chip
                    label={item.name}
                    onDelete={() => {
                      handleDeleteJS(
                        item,
                        jobSegmentList,
                        setJobSegmentList,
                        item.name
                      );
                    }}
                    classes={{ deleteIcon: classes.deleteIcon }}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#424242",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                ) : (
                  <Chip
                    label={item.name}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#424242",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                )
              )
            : !jobSegmentEdit && (
                <>
                  <p
                    align="center"
                    style={{ fontSize: "17px", fontWeight: "bold" }}
                  >
                    No Job Segment selected yet. Please click on the below
                    button to add new job segments.
                  </p>

                  <Box
                    style={{
                      display: "flex",
                      float: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      onClick={() => {
                        setJobSegmentEdit(true);
                      }}
                      style={{ fontWeight: "bold", fontSize: "17px" }}
                    >
                      Add Job Segments{" "}
                    </Button>
                  </Box>
                </>
              )}
          <br />
          <br />
          {jobSegmentEdit && (
            <>
              <Grid container>
                <Grid item xs={3}>
                  <Typography variant="body1">Select Job Segment</Typography>
                </Grid>
                <Grid item xs={5}>
                  {/* <JobSegment /> */}
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    {Object.keys(segmentTypes).map((item) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={segmentTypes[item].isChecked}
                            onChange={(e) => {
                              if (segmentTypes[item].isChecked === false) {
                                var arr = [...jobSegmentList];

                                arr.push({
                                  id: segmentTypes[item].id,
                                  name: segmentTypes[item].job_segment,
                                });
                                setJobSegmentList(arr);
                              } else {
                                var arr = [...jobSegmentList];
                                var indexx;
                                arr.map((u, iin) => {
                                  if (u.id === segmentTypes[item].id) {
                                    indexx = iin;
                                  }
                                });
                                arr.splice(indexx, 1);
                                setJobSegmentList(arr);
                              }

                              segmentTypes[item].isChecked = e.target.checked;
                            }}
                            name="gilad"
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={segmentTypes[item].job_segment}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    type="submit"
                    className={classes.postButton}
                    onClick={() => {
                      setJobSegmentEdit(false);
                      if (localStorage.getItem("user_type") === "Company")
                        handlePreferences();
                      else handlePreferencesCollege();
                    }}
                    style={{ marginLeft: "40px" }}
                  >
                    Update Job Segment
                  </Button>
                </Grid>
              </Grid>

              <br />
              <br />
            </>
          )}
        </div>
        <br />
        <br />
      </>

      <>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.heading}>
              Location
            </Typography>
          </Grid>
          {selectedCities.length > 0 && (
            <Grid item xs={2}>
              <Button
                onClick={() => setLocationEdit(true)}
                variant="outlined"
                color="primary"
                startIcon={<CreateIcon />}
              >
                Edit Location
              </Button>
            </Grid>
          )}
        </Grid>
        <br />
        <Divider />
        <br />

        {selectedCities.length > 0
          ? selectedCities.map((item) =>
              locationEdit ? (
                <>
                  <Chip
                    label={item.city}
                    onDelete={() => {
                      handleDelete(item, selectedCities, setSelectedCities);
                    }}
                    classes={{
                      deleteIcon: classes.deleteIcon,
                      chip: classes.chip,
                    }}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#424242",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </>
              ) : (
                <>
                  <Chip
                    label={item.city}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#424242",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </>
              )
            )
          : !locationEdit && (
              <>
                <p
                  align="center"
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                >
                  No Locations selected yet. Please click on the below button to
                  add new locations
                </p>

                <Box
                  style={{
                    display: "flex",
                    float: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    onClick={() => {
                      setLocationEdit(true);
                    }}
                    style={{ fontWeight: "bold", fontSize: "17px" }}
                  >
                    Add Locations{" "}
                  </Button>
                </Box>
              </>
            )}
        <br />
        <br />
        {locationEdit && (
          <>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="body1">Select Location</Typography>
              </Grid>
              <Grid item xs={5}>
                <CityList />
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  className={classes.postButton}
                  onClick={() => {
                    setLocationEdit(false);
                    if (localStorage.getItem("user_type") === "Company")
                      handlePreferences();
                    else handlePreferencesCollege();
                  }}
                  style={{ marginLeft: "40px" }}
                >
                  Update Location
                </Button>
              </Grid>
            </Grid>

            <br />
            <br />
          </>
        )}
      </>

      <br />
      <br />
      <>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.heading}>
              Job Type
            </Typography>
          </Grid>
          {selectedJobType.length > 0 && (
            <Grid item xs={2}>
              <Button
                onClick={() => setJobTypeEdit(true)}
                variant="outlined"
                color="primary"
                startIcon={<CreateIcon />}
              >
                Edit Job Type
              </Button>
            </Grid>
          )}
        </Grid>
        <br />
        <Divider />
        <br />

        {selectedJobType.length > 0
          ? selectedJobType.map((item) =>
              jobTypeEdit ? (
                <>
                  <Chip
                    label={item.name}
                    onDelete={() => {
                      handleDeleteJobType(
                        item,
                        selectedJobType,
                        setSelectedJobType,
                        item.name
                      );
                    }}
                    classes={{
                      deleteIcon: classes.deleteIcon,
                      chip: classes.chip,
                    }}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#424242",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </>
              ) : (
                <>
                  <Chip
                    label={item.name}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#424242",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </>
              )
            )
          : !jobTypeEdit && (
              <>
                <p
                  align="center"
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                >
                  No Job Types selected yet. Please click on the below button to
                  add new Job Types.
                </p>

                <Box
                  style={{
                    display: "flex",
                    float: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    onClick={() => {
                      setJobTypeEdit(true);
                    }}
                    style={{ fontWeight: "bold", fontSize: "17px" }}
                  >
                    Add Job Type{" "}
                  </Button>
                </Box>
              </>
            )}
        <br />
        <br />
        {jobTypeEdit && (
          <>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="body1">Select Job Types</Typography>
              </Grid>
              <Grid item xs={8}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {Object.keys(jobTypes).map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={jobTypes[item].isChecked}
                          onChange={(e) => {
                            if (jobTypes[item].isChecked === false) {
                              var arr = [...selectedJobType];

                              arr.push({
                                id: jobTypes[item].id,
                                name: jobTypes[item].job_type,
                              });
                              setSelectedJobType(arr);
                            } else {
                              var arr = [...selectedJobType];
                              var indexx;
                              arr.map((u, iin) => {
                                if (u.id === jobTypes[item].id) {
                                  indexx = iin;
                                }
                              });
                              arr.splice(indexx, 1);
                              setSelectedJobType(arr);
                            }

                            jobTypes[item].isChecked = e.target.checked;
                          }}
                          name="gilad"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={jobTypes[item].job_type}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Box
              style={{
                display: "flex",
                float: "right",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                className={classes.postButton}
                onClick={() => {
                  setJobTypeEdit(false);
                  if (localStorage.getItem("user_type") === "Company")
                    handlePreferences();
                  else handlePreferencesCollege();
                }}
                style={{ marginRight: "40px" }}
              >
                Update Job Type
              </Button>
            </Box>
            <br />
            <br />
          </>
        )}
      </>

      <br />
      <br />
      <>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.heading}>
              {localStorage.getItem("user_type") === "College"
                ? "Placement Batch"
                : "Work Experience"}
            </Typography>
          </Grid>
          {workExp.length > 0 && (
            <Grid item xs={2}>
              <Button
                onClick={() => setWorkExpEdit(true)}
                variant="outlined"
                color="primary"
                startIcon={<CreateIcon />}
              >
                {localStorage.getItem("user_type") === "College"
                  ? "Edit Placement Batch"
                  : "Edit Work Experience"}
              </Button>
            </Grid>
          )}
        </Grid>
        <br />
        <Divider />
        <br />

        {workExp.length > 0
          ? workExp.map((item) =>
              workExpEdit ? (
                <>
                  <Chip
                    label={
                      item.id === 1
                        ? "Fresher"
                        : localStorage.getItem("user_type") === "College"
                        ? item.id - 1 + " year passout"
                        : item.id - 1 + " year"
                    }
                    onDelete={() => {
                      handleDeleteWE(item, workExp, setWorkExp);
                    }}
                    classes={{
                      deleteIcon: classes.deleteIcon,
                      chip: classes.chip,
                    }}
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#424242",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </>
              ) : (
                <>
                  <Chip
                    label={
                      item.id === 1
                        ? "Fresher"
                        : localStorage.getItem("user_type") === "College"
                        ? item.id - 1 + " year passout"
                        : item.id - 1 + " year"
                    }
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#424242",
                      color: "#ffffff",
                      fontWeight: "bold",
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                </>
              )
            )
          : !workExpEdit && (
              <>
                <p
                  align="center"
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                >
                  No{" "}
                  {localStorage.getItem("user_type") === "College"
                    ? "Placement Batch"
                    : "Work Experience"}{" "}
                  selected yet. Please click on the below button to add new Work
                  Experiences.
                </p>

                <Box
                  style={{
                    display: "flex",
                    float: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    onClick={() => {
                      setWorkExpEdit(true);
                    }}
                    style={{ fontWeight: "bold", fontSize: "17px" }}
                  >
                    {localStorage.getItem("user_type") === "College"
                      ? "Add Placement Batches"
                      : "Add Work Experience"}
                  </Button>
                </Box>
              </>
            )}
        <br />
        <br />
        {workExpEdit && (
          <>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="body1">
                  {localStorage.getItem("user_type") === "College"
                    ? "Select Placement Batches"
                    : "Select Work Experience"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {Object.keys(workExperiences).map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={workExperiences[item].isChecked}
                          onChange={(e) => {
                            if (workExperiences[item].isChecked === false) {
                              var arr = [...workExp];
                              arr.push({
                                id: workExperiences[item].value,
                                name: workExperiences[item].name,
                              });
                              setWorkExp(arr);
                            } else {
                              var arr = [...workExp];
                              var indexx;
                              arr.map((u, iin) => {
                                if (u.id === workExperiences[item].value) {
                                  indexx = iin;
                                }
                              });
                              arr.splice(indexx, 1);
                              setWorkExp(arr);
                            }

                            workExperiences[item].isChecked = e.target.checked;
                          }}
                          name="gilad"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={workExperiences[item].name}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Box
              style={{
                display: "flex",
                float: "right",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                className={classes.postButton}
                onClick={() => {
                  setWorkExpEdit(false);
                  if (localStorage.getItem("user_type") === "College")
                    handlePreferencesCollege();
                  else handlePreferences();
                }}
                style={{ marginRight: "40px" }}
              >
                {localStorage.getItem("user_type") === "College"
                  ? "Update Placement Batches"
                  : "Update Work Experience"}
              </Button>
            </Box>
          </>
        )}
      </>
    </>
  );
}

import { Typography, Grid, Button } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddCommentDialog from "../Dialog/AddCommentDialog";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  icon: {
    color: "#007bff",
  },
  font: {
    fontFamily: "",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function AllComment({ data, currentApplicant, isDetail=false }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Typography
          variant="h6"
          style={{ fontWeight: "600", marginBottom: "15px" }}
        >
          Comments {data && data?.length > 0 && `(${data?.length})`}
        </Typography>
        {!isDetail && 
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Applicant Name: {currentApplicant?.applicantName}
          </Typography>
      }

        {data?.length > 0 ? (
          data?.map((item) => (
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  /*  marginBottom: "10px", */
                }}
              >
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {item?.user_name}: {item?.status_name}
                </Typography>
                <Typography style={{ color: "#B0B6BA", fontSize: "12px" }}>
                  {item.create_time
                    ? moment(item.create_time).format("DD/MM/YYYY")
                    : ""}
                </Typography>
              </div>
              <Typography variant="body2" style={{ color: "#6C757D" }}>
                {item?.comment ? item?.comment : item?.feedbcak_name ? item?.feedbcak_name : item?.feedback_name}
              </Typography>
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <NoData
                height="180px"
                width="180px"
                alt="404 Error"
                fill={"#007BFF"}
              />
              <p align="center">{"No Comments Available"}</p>
            </div>{" "}
          </div>
        )}
      </div>
    </>
  );
}

export default AllComment;

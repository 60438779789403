import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Carousel, { consts } from "react-elastic-carousel";
import Item from "./Item";
import "./styles_main.css";
import eg from "../../assets/images/banner-bg.jpg";
import eg2 from "../../assets/images/def.png";
import { Box, Button, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import kFormatter, { LFormatter } from "../../utils/ZeroToK";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 5 },
];

const useStyles = makeStyles((theme) => ({
  CarouselTitle: {
    paddingLeft: "8%",
    fontSize: "14px",
    marginTop: "-4%",
    color: theme.palette.type === "dark" ? "#ffff" : "black",
  },
  CarouselSubTitle: {
    fontSize: "12px",
    color: theme.palette.type === "dark" ? "#a4a4a4" : "#424242",
  },
}));

export default function AppCarousel(props) {
  const { data } = props;
  const collegeID = localStorage.getItem("college_id");
  const classes = useStyles();

  const [collegeList, setCollegeList] = React.useState([]);
  useEffect(() => {
    setCollegeList(data.company_college_list);
  }, [data]);

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <DeleteIcon /> : "👉";
    return (
      <IconButton onClick={onClick} disabled={isEdge}>
        {pointer}
      </IconButton>
    );
  };
  return (
    <div className="AppCarousel">
      <Carousel
        breakPoints={breakPoints}
        focusOnSelect={true}
        enableAutoPlay
        autoPlaySpeed={4500}
        // renderArrow={myArrow}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <>
              {pages.map((page) => {
                const isActivePage = activePage === page;
                return <></>;
              })}
            </>
          );
        }}
      >
        {collegeList &&
          collegeList.map((item) => (
            <>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <a href={!collegeID ? "/public/college/profile/" + item.id : "/public/company/profile/" + item.id} target="_blank">
                  <Item>
                    <img src={item.logo ? item.logo : eg2} alt="" height="160px" width="160px" />
                  </Item>
                </a>

                <p className={classes.CarouselTitle}>
                  <b>{item.name}</b>
                  <br />
                  <span className={classes.CarouselSubTitle}>
                    {!collegeID ? (
                      <>Min CTC&nbsp; {item.size_min_ctc ? (item.size_min_ctc > 99999 ? LFormatter(item.size_min_ctc) + "PA" : kFormatter(item.size_min_ctc) + "PA") : ""}</>
                    ) : (
                      <>
                        Company Size&nbsp;{" - "}
                        {item.size_min_ctc && item.size_min_ctc}
                      </>
                    )}
                  </span>
                </p>
              </Box>
            </>
          ))}
      </Carousel>
    </div>
  );
}

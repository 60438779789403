import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { fade, ThemeProvider, withStyles, makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Button,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  InputLabel,
  Typography,
  OutlinedInput,
  Paper,
  Grid,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { display } from "@material-ui/system";
import Logo from "../../../../../assets/images/sidepanel/logo.png";
import Facebook from "../../../../../assets/facebook.png";
import Google from "../../../../../assets/google-icon.png";
import { emailRegex, FACEBOOK_APP_ID, NEXT_APP_ENDPOINT, GOOGLE_CLIENT_ID } from "../../../../../constants/constants";
import { Link, useHistory, useLocation } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  btnN: {
    padding: "14px",
    // paddingLeft: "32%",
    // paddingRight: "32%",
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
    border: `1px solid ${theme.palette.domain.main}`,
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
      border: `1px solid ${theme.palette.domain.main}`,
    },
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);
export default function RegisterMethods(props) {
  const {
    type,

    responseGoogle,
    responseFacebook,

    setCurrentStep,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const location = useLocation();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.grid}>
            {/* <img src={Logo} height="70px" width="80px" />
            <br /> */}
            <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
              <b>
                {" "}
                {location.pathname === "/signup/student" ? (
                  <>
                    Register to Get Personalized Job
                    <br /> Recommendations
                  </>
                ) : (
                  <>
                    Register to Hire high-quality candidates <br />
                    with GetWork
                  </>
                )}
              </b>
            </Typography>
            <br />
          </Grid>

          <Grid item md={12} xs={12}>
            <div className={classes.btndiv}>
              <Button
                className={classes.btnN}
                variant="contained"
                onClick={() => {
                  setCurrentStep(1);
                }}
                style={{ height: 45 }}
              >
                <Typography variant="body2" style={{ color: "#fff", fontFamily: " Nunito" }}>
                  {" "}
                  {/* Sign In{" "} */}
                  <b> Register with Email</b>
                </Typography>
              </Button>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Divider style={{ width: "45%" }} />
              <Typography variant="body2" color="initial" style={{ fontFamily: " Nunito" }}>
                OR
              </Typography>
              <Divider style={{ width: "45%" }} />
            </div>

            <br />
            <Grid item xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <GoogleLogin
                  // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
                  clientId={GOOGLE_CLIENT_ID}
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      className={classes.btnsocial}
                      variant="outlined"
                      color="primary"
                      style={{ height: 45 }}

                      // startIcon={<FaceBook />}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={Google} height="28px" width="28px" />
                        <Typography
                          variant="body1"
                          style={{
                            color: domain?.domain ? domain?.color_code : "#007bff",
                            marginLeft: "12px",
                            fontFamily: " Nunito",
                          }}
                        >
                          <b> Register with Google</b>
                        </Typography>
                      </div>
                    </Button>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                />
              </div>
              <br />
              {type === "Student" && (
                <Grid item xs={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <FacebookLogin
                      appId={FACEBOOK_APP_ID}
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          className={classes.btnsocial}
                          variant="outlined"
                          color="primary"
                          style={{ height: 45 }}

                          // startIcon={<FaceBook />}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={Facebook} height="28px" width="28px" />
                            <Typography
                              variant="body1"
                              style={{
                                color: domain?.domain ? domain?.color_code : "#007bff",
                                marginLeft: "12px",
                                fontFamily: " Nunito",
                              }}
                            >
                              <b>Register with Facebook</b>
                            </Typography>
                          </div>
                        </Button>
                      )}
                      disableMobileRedirect={true}
                    />
                  </div>
                </Grid>
              )}
              <br />
              <div className={classes.create}>
                <Typography variant="body2" style={{ fontFamily: " Nunito", textAlign: "center" }}>
                  By signing up you indicate that you have read and agree to the
                  <span
                    variant="body2"
                    className={classes.text}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(`${NEXT_APP_ENDPOINT}/terms-and-conditions`);
                    }}
                  >
                    {" "}
                    Terms and Conditions
                  </span>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  Button,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Typography,
  OutlinedInput,
  Paper,
  Grid,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { display } from "@material-ui/system";
import Logo from "../../../../../assets/images/sidepanel/logo.png";
import Facebook from "../../../../../assets/facebook.png";
import Google from "../../../../../assets/google-icon.png";
import {
  emailRegex,
  FACEBOOK_APP_ID,
  passwordRegex,
} from "../../../../../constants/constants";
import { Link, useLocation } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    backgroundColor: "#007bff",
    borderRadius: "30px",
    boxShadow: "none",
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
  floatingLabelFocusStyle: {
    color: "#6c757d",
    padding: "6px",
    fontSize: "14px",
    paddingLeft: "10px",
    fontFamily: "Nunito",
    fontWeight: "600",
  },

  input: {
    height: 48,
    fontFamily: "Nunito",
  },
}));

const BlueCheckbox = withStyles({
  root: {
    color: "#6c757d",
    "&$checked": {
      color: "#007bff",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);
export default function RegisterFormCollege(props) {
  const {
    type,

    errorMessages,
    setErrorMessages,

    handleSubmit,
    userDetails,
    setUserDetails,
    loading,
  } = props;
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const location = useLocation();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [buttonClicked, setButtonClicked] = useState(false);
  const [validate, setValidate] = useState(false);

  const validateData = () => {
    var validate_ = false;
    var name_error = "";
    var email_error = "";
    var phone_error = "";
    var college_name_error = "";
    var college_location_error = "";

    if (userDetails.name === "") {
      name_error = "Name cannot be left blank";
      validate_ = true;
    }

    if (userDetails.college_name === "") {
      college_name_error = "College Name cannot be left blank";
      validate_ = true;
    }
    if (userDetails.college_location === "") {
      college_location_error = "College Location cannot be left blank";
      validate_ = true;
    }
    if (userDetails.email === "") {
      email_error = "Email cannot be left blank";
      validate_ = true;
    }

    if (!emailRegex.test(userDetails.email)) {
      email_error = "Please enter a valid email address";
      validate_ = true;
    }
    if (userDetails.phone === null) {
      phone_error = "Phone Number cannot be left blank";
      validate_ = true;
    }
    if (userDetails.phone && userDetails.phone.length !== 10) {
      phone_error = "Enter a valid 10 digit phone number";
      validate_ = true;
    }

    setErrorMessages({
      ...errorMessages,
      name: name_error,
      email: email_error,
      phone: phone_error,
      college_name: college_name_error,
      college_location: college_location_error,
    });

    setButtonClicked(true);
    setValidate(validate_);
  };

  useEffect(() => {
    if (!validate && buttonClicked) {
      handleSubmit();
    }
  }, [buttonClicked]);

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangecheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.grid}>
            {/* <img src={Logo} height="70px" width="80px" />
            <br /> */}
            <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
              <b>
                {location.pathname === "/signup/student" ? (
                  <>
                    Register to Get Personalized Job
                    <br /> Recommendations
                  </>
                ) : location.pathname === "/signup/college" ? (
                  <>
                    Please Fill in these details and we'll get back to you
                    shortly.
                  </>
                ) : (
                  <>
                    Register to Hire high-quality candidates <br />
                    with GetWork
                  </>
                )}
              </b>
            </Typography>
          </Grid>
          <br />
          <Grid item xs={12} md={12} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="Name *"
              variant="outlined"
              id="custom-css-outlined-input"
              type="text"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={errorMessages.name !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages.name !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.name}
                </span>
              }
              value={userDetails.name}
              onChange={(e) => {
                setUserDetails({ ...userDetails, name: e.target.value });
                setErrorMessages({ ...errorMessages, name: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="College Name *"
              variant="outlined"
              id="custom-css-outlined-input"
              type="text"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={errorMessages.college_name !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages.college_name !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.college_name}
                </span>
              }
              value={userDetails.college_name}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  college_name: e.target.value,
                });
                setErrorMessages({ ...errorMessages, college_name: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="College Location *"
              variant="outlined"
              id="custom-css-outlined-input"
              type="text"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={errorMessages.college_location !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages.college_location !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.college_location}
                </span>
              }
              value={userDetails.college_location}
              onChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  college_location: e.target.value,
                });
                setErrorMessages({ ...errorMessages, college_location: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="Email *"
              variant="outlined"
              id="custom-css-outlined-input"
              type="email"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={errorMessages.email !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages.email !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.email}
                </span>
              }
              value={userDetails.email}
              onChange={(e) => {
                setUserDetails({ ...userDetails, email: e.target.value });
                setErrorMessages({ ...errorMessages, email: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="Phone No. *"
              variant="outlined"
              type="number"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={errorMessages.phone !== "" ? true : false}
              id="custom-css-outlined-input"
              helperText={
                <span
                  style={
                    errorMessages.phone !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.phone}
                </span>
              }
              value={userDetails.phone}
              onChange={(e) => {
                setUserDetails({ ...userDetails, phone: e.target.value });
                setErrorMessages({ ...errorMessages, phone: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={
                <BlueCheckbox
                  size="small"
                  checked={state.checkedA}
                  onChange={handleChangecheck}
                  name="checkedA"
                />
              }
              label={
                <Typography
                  style={{
                    fontSize: "12px",
                    fontFamily: "Nunito",
                    marginTop: "10px",
                    color: "#000",
                  }}
                >
                  I want to receive GetWork newsletter to receive Product
                  updates and useful tips.
                </Typography>
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <div className={classes.btndiv}>
              <Button
                className={classes.btn}
                variant="contained"
                onClick={() => {
                  validateData();
                }}
                style={{ height: 45 }}
              >
                <Typography
                  variant="body2"
                  style={{ color: "#fff", fontFamily: " Nunito" }}
                >
                  {" "}
                  {/* Sign In{" "} */}
                  {loading ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    <b>Schedule Demo</b>
                  )}
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: "#007bff",
  },
}));

export default function UserDetail({ detailState, setDetailState }) {
  const classes = useStyles();

  const handleChange = (event) => {
    var arr = [...detailState];

    if (detailState.includes(event.target.name)) {
      var arr = [...detailState];

      const index = arr.indexOf(event.target.name);
      if (index > -1) {
        arr.splice(index, 1);
        setDetailState(arr);
      }
    } else {
      var arr = [...detailState];
      arr.push(event.target.name);
      setDetailState(arr);
    }
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup className={classes.formControl} row>
          <FormControlLabel control={<Checkbox style={{ color: "#007bff" }} checked={detailState.includes("mail")} onChange={handleChange} name="mail" />} label="Email" />
          <FormControlLabel
            control={<Checkbox style={{ color: "#007bff" }} checked={detailState.includes("phone")} onChange={handleChange} name="phone" />}
            label="Mobile Number"
          />
          <FormControlLabel control={<Checkbox style={{ color: "#007bff" }} checked={detailState.includes("resume")} onChange={handleChange} name="resume" />} label="Resume" />
        </FormGroup>
      </FormControl>
    </>
  );
}

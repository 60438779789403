import React from "react";

import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import check from "../../../assets/checked-new.png";
import app_download from "../../../assets/new/qr-code.png";
import app_store from "../../../assets/apple-min.png";
import play_store from "../../../assets/goolge-min.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    marginTop: "50px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    marginTop: "50px",
    justifyContent: "center",
  },
  playstore: {
    borderRadius: "10px",
  },
}));
export default function Oncampus() {
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  const classes = useStyles();
  const [loaderNav, setLoaderNav] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setLoaderNav(false);
    }, 1000);
  }, []);

  return (
    <>
      {loaderNav ? <></> : domain?.domain ? <DomainNavbar /> : <Navbar />}

      <div className="text-gray fs-20 fw-700 " style={{ margin: "145px 10px 0px 10px" }}>
        {/* No Jobs
        <Box>
          <img src={NoJobs} width="100%" height="auto" />
        </Box> */}

        <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <img
            src={"https://s3.ap-south-1.amazonaws.com/getwork-store/prod/common/APP"}
            height="350px"
            width="180px"
            alt="banner"
            style={{ display: "inline-block", marginRight: 20 }}
          />
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="subtitle2" style={{ fontFamily: " Nunito", color: "#6c757d", fontSize: 17, margin: "20px 0px 10px 0px" }}>
              <b> Download the App to Access On Campus Placement Jobs </b>
            </Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                {" "}
                <img src={check} height="20px" width="20px" />
                <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                  Get New Job Alerts
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                {" "}
                <img src={check} height="20px" width="20px" />
                <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                  Create your Resume & Share{" "}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <img src={check} height="20px" width="20px" />
                <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                  Manage your Job Applications{" "}
                </Typography>
              </div>
            </div>
            <br />
            <Grid container spacing={3} style={{ width: "80%" }}>
              <Grid item xs={5}>
                <img id="footerBtn" src={app_download} alt="playstore" height="120px" />
              </Grid>
              <Grid item xs={7} style={{ display: "flex", alignItems: "center" }}>
                <Hidden smDown>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a href="https://play.google.com/store/apps/details?id=com.getwork_app" target="_blank" style={{ marginBottom: 10 }}>
                      <img id="footerBtn" src={play_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                    </a>

                    <a href="https://apps.apple.com/in/app/getwork/id1579830388" target="_blank">
                      <img id="footerBtn" src={app_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                    </a>
                  </div>
                </Hidden>
                <Hidden smUp>
                  <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                    <a href="https://play.google.com/store/apps/details?id=com.getwork_app" target="_blank" style={{ marginBottom: 10 }}>
                      <img id="footerBtn" src={play_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                    </a>

                    <a href="https://apps.apple.com/in/app/getwork/id1579830388" target="_blank">
                      <img id="footerBtn" src={app_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                    </a>
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" padding="8%">
          <Link to="/">
            <Button
              style={{
                width: 238,
                height: 44,
                backgroundColor: domain?.domain ? domain?.color_code : "#007bff",
                textTransform: "capitalize",
                borderRadius: 20,
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#3282C4",
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </div>
    </>
  );
}

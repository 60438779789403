import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Button,
  useTheme,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Warning from "../../assets/transfer-money.gif";
import Warning_dark from "../../assets/transfer-money-dark.gif";
import { useHistory } from "react-router-dom";
import Theme from "../../resources/Theme/Theme";

const ConfirmationDialog = ({
  open,
  setOpen,
  poster,
  posterArg,
  creditsRequired,
  serviceType,
}) => {
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const theme = useTheme();
  const history = useHistory();
  return (
    <Dialog
      //open
      open={open}
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
      aria-labelledby="customized-dialog-title"
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <p>
            {creditInfo &&
              creditInfo.user_service &&
              creditInfo?.user_service?.find(
                (item) => item.service_type === serviceType
              )?.service_type}
          </p>
          <p>
            {creditInfo &&
              creditInfo.user_service &&
              creditInfo?.user_service?.find(
                (item) => item.service_type === serviceType
              )?.service_description}
          </p>
          <img
            src={theme.palette.type === "dark" ? Warning_dark : Warning}
            width="60%"
            height="auto"
          />
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            You will be charged {creditsRequired} Credits for this action
          </p>
          {creditsRequired > creditInfo.available_credits ? (
            <>
              <p style={{ fontSize: "18px" }}>
                seems like You don't have enough credits to perform this action.
              </p>
              <Button
                onClick={() =>
                  localStorage.getItem("user_type") === "College"
                    ? history.push("/college/college-settings")
                    : history.push("/company/company-settings")
                }
                style={{ backgroundColor: "#3282c4", color: "#ffffff" }}
              >
                Recharge now !
              </Button>
            </>
          ) : (
            creditInfo.credit_status === "INACTIVE" && (
              <p style={{ fontSize: "18px" }}>
                Your Account is deactivated. Please activate it first.
              </p>
            )
          )
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ backgroundColor: "#D64045", color: "#ffffff" }}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        {creditInfo.credit_status === "ACTIVE"  && creditsRequired <= creditInfo.available_credits && (
          <Button
            style={{ backgroundColor: "#3282c4", color: "#ffffff" }}
            onClick={(e) => {
              e.stopPropagation();

              poster(posterArg);
              setOpen(false);
            }}
          >
            Proceed
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import {
  Box,




  Button,








  CircularProgress, createStyles,



  Divider, makeStyles,






  Table,



  TableBody, TableCell, TableContainer,

  TableHead,
  TableRow, Typography,









  useTheme
} from "@material-ui/core";
import axios from "axios";
import { loadCSS } from "fg-loadcss";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import ReactHtmlParser from "react-html-parser";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import pic from "../../../assets/common/edit3.svg";
import edit from "../../../assets/common/pen.svg";
import { getUserDetails } from "../Dashboard/action";
import { BackendBaseApi } from "../../../constants/constants";
import "./custom.css";
import styles from "./ProfileCSS";


const useStyles = makeStyles((theme) =>
  createStyles({
    mainCard: {
      position: "relative",
    },
    uploadBox: {
      position: "absolute",
      left: "42%",
      bottom: "-105px",
      border: "7px solid rgb(231, 230, 230)",
      borderRadius: "50%",
      width: 150,
      height: 150,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      backgroundSize: "contain !important",
      flexDirection: "column",
    },
    outerBox: {
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.background.paper,
      borderRadius:"30px "
    },
  })
);

export default function CollegeProfile(props) {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const [urll, setUrl] = useState(
    BackendBaseApi.PRAVESH + "api/college/edit_profile/"
  );
  const [isError, setIsError] = useState(false);
  const [isAbout, setIsAbout] = useState();
  const [isDescription, setIsDescription] = useState();
  const [isName, setIsName] = useState();
  const [isMessage, setIsMessage] = useState();
  const [location, setIsLocation] = useState();
  const [logo, setIsLogo] = useState("");
  const [banner, setIsBanner] = useState("");
  const [faqs, setIsFaq] = useState();
  const [post, setPost] = useState({ data: {} });
  const [file, setFile] = useState({ data: {} });
  const [image, setImage] = useState({ preview: "" });
  const [imagelogo, setImagelogo] = useState({ previewlogo: "" });
  const [specs, setIsSpecs] = useState([]);
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const alert = useAlert();
  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(urll, {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            setFile(res.data.data);

            setPost(res.data.data);
            setIsName(res.data.data.name);
            setIsAbout(res.data.data.about);
            setIsDescription(res.data.data.description);
            setIsMessage(res.data.data.message_for_recruiters);
            setIsLocation(res.data.data.location);
            setIsLogo(res.data.data.logo);
            setIsBanner(res.data.data.banner);
            setIsFaq(res.data.data.faqs);
            setIsSpecs(res.data.data.degree_specialization);
            setCity(res.data.data.city_name);
            setState(res.data.data.state_name);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, []);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
  };

  const { buttonLabel, className } = props;

  const [modall, setModal1] = useState(false);

  const toggle1 = () => setModal1(!modall);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage(img);
    }
  };

  const blobToImage = async (blob) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;

      // setImgtemp(blob);

      const fd = new FormData();
      fd.append("logo", blob);
      setImageLoading(true);
      axios
        .patch(urll, fd, {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success) {
            alert.success("Logo uploaded Successfully");
            setIsLogo(res.data.data.logo[0]);
          } else {
            alert.error(res.data.error);
          }
          setImageLoading(false);
          dispatch(
            getUserDetails(history, alert, localStorage.getItem("user_type_id"))
          );
        })
        .catch((err) => {
          alert.error("Error occured!");
          setImageLoading(false);
        });
    });
  };

  const onImageChange1 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage1(img);
    }
  };

  const blobToImage1 = (blob) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;
      setIsBanner(img.src);
      // setImgtemp(blob);

      const fd = new FormData();
      fd.append("banner", blob);
      axios
        .patch(urll, fd, {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success) {
            alert.success("Banner uploaded Successfully");
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          alert.error("Error occured!");
        });
    });
  };

  const theme = useTheme();

  return (
    <Box padding="4%">
      <Box className={classes.outerBox}>
        <Box className={classes.mainCard}>
          {banner ? <img src={banner} width="100%" height="300px"  style={{borderRadius:"30px"}}/> : null}
          <Box
            className={classes.uploadBox}
            style={{
              background:
                imageLoading || logo === ""
                  ? "rgba(0, 0, 0, 0.5)"
                  : `url(${logo})`,
            }}
          >
            {imageLoading === true || logo === "" ? (
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                marginBottom="20px"
              >
                <CircularProgress size={25} style={{ color: "#ffffff" }} />
              </Box>
            ) : null}

            <input
              accept="image/*"
              style={{ display: "none" }}
              id="icon-button-file"
              type="file"
              onChange={onImageChange}
            />
            <label
              htmlFor="icon-button-file"
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                //border: "2px solid rgb(231, 230, 230)",
                opacity: 0.8,
                marginRight: "19px",
              }}
            >
           
                              <img
                onClick={toggle}
                src={pic}
                fontSize="inherit"
                alt=""
                height="30px"
                style={{ float: "right", cursor: "pointer" }}
              />
               </label>
          </Box>
        </Box>
        <Box marginTop="10%" padding="2%" >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            
          >
            <Typography variant="h4">
              {isName} - {city},&nbsp;{state}
            </Typography>
            <Link to="/college/Profile_update">
              <Button   style={{borderRadius:"30px"}}>
              <img src={edit} height="20px" />
                Edit Profile
              </Button>
            </Link>
          </Box>
          <Divider style={{ margin: "2% 0%" }} />
          <Typography variant="h4">About</Typography>
          <Typography variant="body1">{isAbout}</Typography>
          <br />
          <Typography variant="h4">Description</Typography>
          <Typography variant="body1">{isDescription}</Typography>
          <br />
          <Typography variant="h4">Message for recruiters</Typography>
          <Typography variant="body1">{isMessage}</Typography>

          <br />
          <Typography variant="h4">Specializations and Course</Typography>

          <br />
          <div style={styles.tableContainer}>
            <TableContainer
              style={{
                maxHeight: "400px",
                border:
                  theme.palette.type === "dark"
                    ? "1px solid rgba(255, 255, 255, 0.12)"
                    : "1px solid #ced4da",
                borderRadius: "30px",
                boxShadow:
                  theme.palette.type === "dark"
                    ? theme.shadows[0]
                    : theme.shadows[4],
              }}
            >
              <Table stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>Graduation</TableCell>
                    <TableCell>Degree</TableCell>
                    <TableCell>Specialization</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specs.map((data, index) => (
                    <TableRow>
                      <TableCell>{data.education_type_name}</TableCell>
                      <TableCell>{data.degree_name}</TableCell>
                      <TableCell>{data.specialization_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <div style={styles.tab}>
              <div style={styles.tableHeader22}>Graduation</div>
              <div style={styles.tableHeader22}>Degree</div>
              <div style={styles.tableHeader22}>Specialization</div>
            </div>
            {specs.map((data, index) => {
              return (
                <div style={styles.tab1}>
                  {index % 2 == 0 ? (
                    <div style={styles.tab}>
                      <div style={styles.tableHeader32}>
                        {data.education_type_name}
                      </div>
                      <div style={styles.tableHeader32}>{data.degree_name}</div>
                      <div style={styles.tableHeader32}>
                        {data.specialization_name}
                      </div>
                    </div>
                  ) : (
                    <div style={styles.tab}>
                      <div style={styles.tableHeader0}>
                        {data.education_type_name}
                      </div>
                      <div style={styles.tableHeader0}>{data.degree_name}</div>
                      <div style={styles.tableHeader0}>
                        {data.specialization_name}
                      </div>
                    </div>
                  )}
                </div>
              );
            })} */}
          </div>
          <br />

          <br />

          <br />
          {faqs !== null && (
            <>
              <Typography variant="h4">FAQ's</Typography>
              <div
                style={{
                  color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
                }}
              >
                {ReactHtmlParser(faqs)}
              </div>
            </>
          )}
        </Box>
      </Box>
    </Box>
    // <div className="collegeprofile">
    //   <br />
    //   <Container className="mt--7" fluid>
    //     <Card className="shadow" style={{ padding: "40px" }}>
    //       <CardBody>
    //         <Desktop>
    //           {banner ? (
    //             <>
    //               <div style={styles.images12}>
    //                 <div
    //                   className="hello img_edit1"
    //                   style={{
    //                     border: "2px solid #e7e6e6",
    //                     borderRadius: "3px",
    //                   }}
    //                 >
    //                   <input
    //                     type="file"
    //                     id="image1"
    //                     style={{ display: "none" }}
    //                     onChange={onImageChange1}
    //                   ></input>
    //                   <label
    //                     for="image1"
    //                     style={{
    //                       display: "flex",
    //                       justifyContent: "center",
    //                     }}
    //                   >
    //                     <img
    //                       src={banner}
    //                       width="1200"
    //                       height="200"
    //                       style={{
    //                         objectFit: "cover",
    //                       }}
    //                     />
    //                   </label>
    //                 </div>
    //               </div>
    //               <Link to="/public/college/profile" target="_blank">
    //                 <p
    //                   style={{
    //                     textAlign: "right",
    //                     color: "rgb(76, 135, 190)",
    //                   }}
    //                 >
    //                   <img src={eye} height="16px" />
    //                   &nbsp; View your public profile
    //                 </p>
    //               </Link>
    //             </>
    //           ) : (
    //             <>
    //               <div style={styles.emptyBanner12}>
    //                 <input
    //                   type="file"
    //                   id="image1"
    //                   style={{ display: "none" }}
    //                   onChange={onImageChange1}
    //                 ></input>
    //                 <label for="image1">Add an image</label>
    //               </div>
    //               <Link to="/public/college/profile" target="_blank">
    //                 <p
    //                   style={{
    //                     textAlign: "right",
    //                     color: "rgb(76, 135, 190)",
    //                   }}
    //                 >
    //                   <img src={eye} height="16px" />
    //                   &nbsp; View your public profile
    //                 </p>
    //               </Link>
    //             </>
    //           )}

    //           {logo ? (
    //             <div style={styles.avatarBox12}>
    //               <Avatar
    //                 style={{
    //                   width: "150px",
    //                   height: "150px",
    //                   borderRadius: "75px",
    //                   border: "7px solid #e7e6e6",
    //                   margin: "auto",
    //                   top: -100,
    //                 }}
    //                 alt="Remy Sharp"
    //                 src={logo}
    //               />
    //               <div>
    //                 <input
    //                   type="file"
    //                   id="image"
    //                   style={{ display: "none" }}
    //                   onChange={onImageChange}
    //                 ></input>
    //                 <label
    //                   for="image"
    //                   className="img_edit"
    //                   style={{
    //                     position: "relative",
    //                     top: -50,
    //                     left: -520,
    //                   }}
    //                 >
    //                   <div
    //                     style={{
    //                       backgroundColor: "white",
    //                       borderRadius: "75px",
    //                       border: "2px solid #e7e6e6",
    //                       padding: "5px 9px 5px 9px",
    //                       opacity: 0.8,
    //                     }}
    //                   >
    //                     <Icon
    //                       className="fa fa-pencil"
    //                       style={{
    //                         fontSize: "20px",
    //                       }}
    //                     />
    //                   </div>
    //                 </label>
    //               </div>
    //             </div>
    //           ) : (
    //             <div style={styles.avatarBox12}>
    //               <Avatar
    //                 style={{
    //                   width: "150px",
    //                   height: "150px",
    //                   borderRadius: "75px",
    //                   border: "7px solid #e7e6e6",
    //                   margin: "auto",
    //                   top: -100,
    //                 }}
    //                 alt="Remy Sharp"
    //               />

    //               <div>
    //                 <input
    //                   type="file"
    //                   id="image"
    //                   style={{ display: "none" }}
    //                   onChange={onImageChange}
    //                 ></input>
    //                 <label
    //                   for="image"
    //                   className="img_edit"
    //                   style={{
    //                     position: "relative",
    //                     top: -50,
    //                     left: -520,
    //                   }}
    //                 >
    //                   <div
    //                     style={{
    //                       backgroundColor: "white",
    //                       borderRadius: "75px",
    //                       border: "2px solid #e7e6e6",
    //                       padding: "5px 9px 5px 9px",
    //                       opacity: 0.8,
    //                     }}
    //                   >
    //                     <Icon
    //                       className="fa fa-pencil"
    //                       style={{
    //                         fontSize: "20px",
    //                       }}
    //                     />
    //                   </div>
    //                 </label>
    //               </div>
    //             </div>
    //           )}
    //         </Desktop>

    //         <Mobile>
    //           {banner ? (
    //             <div style={styles.images12}>
    //               <div
    //                 className="hello img_edit1"
    //                 style={{
    //                   border: "2px solid #e7e6e6",
    //                   borderRadius: "3px",
    //                 }}
    //               >
    //                 <input
    //                   type="file"
    //                   id="image1"
    //                   style={{ display: "none" }}
    //                   onChange={onImageChange1}
    //                 ></input>
    //                 <label
    //                   for="image1"
    //                   style={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                   }}
    //                 >
    //                   <img src={banner} width="100%" height="200" />
    //                 </label>
    //               </div>
    //             </div>
    //           ) : (
    //             <div style={styles.emptyBanner12}>
    //               <input
    //                 type="file"
    //                 id="image1"
    //                 style={{ display: "none" }}
    //                 onChange={onImageChange1}
    //               ></input>
    //               <label for="image1">Add an image</label>
    //             </div>
    //           )}

    //           {logo ? (
    //             <>
    //               <div style={styles.avatarBox98}>
    //                 <Avatar
    //                   style={{
    //                     width: "150px",
    //                     height: "150px",
    //                     borderRadius: "75px",
    //                     border: "7px solid #e7e6e6",
    //                     margin: "auto",
    //                     top: -100,
    //                   }}
    //                   alt="Remy Sharp"
    //                   src={logo}
    //                 />
    //                 <div>
    //                   <input
    //                     type="file"
    //                     id="image"
    //                     style={{ display: "none" }}
    //                     onChange={onImageChange}
    //                   ></input>
    //                   <label
    //                     for="image"
    //                     className="img_edit"
    //                     style={{
    //                       position: "relative",
    //                       top: -40,
    //                       left: -120,
    //                     }}
    //                   >
    //                     <div
    //                       style={{
    //                         backgroundColor: "white",
    //                         borderRadius: "75px",
    //                         border: "2px solid #e7e6e6",
    //                         padding: "5px 9px 5px 9px",
    //                         opacity: 0.8,
    //                       }}
    //                     >
    //                       <Icon
    //                         className="fa fa-pencil"
    //                         style={{
    //                           fontSize: "20px",
    //                         }}
    //                       />
    //                     </div>
    //                   </label>
    //                 </div>
    //               </div>
    //               <Link to="/public/college/profile" target="_blank">
    //                 <p
    //                   style={{
    //                     textAlign: "center",
    //                     color: "rgb(76, 135, 190)",
    //                   }}
    //                 >
    //                   <img src={eye} height="16px" />
    //                   &nbsp; View your public profile
    //                 </p>
    //               </Link>
    //             </>
    //           ) : (
    //             <>
    //               <div style={styles.avatarBox98}>
    //                 <Avatar
    //                   style={{
    //                     width: "150px",
    //                     height: "150px",
    //                     borderRadius: "75px",
    //                     border: "7px solid #e7e6e6",
    //                     margin: "auto",
    //                     top: -50,
    //                   }}
    //                   alt="Remy Sharp"
    //                 />

    //                 <div>
    //                   <input
    //                     type="file"
    //                     id="image"
    //                     style={{ display: "none" }}
    //                     onChange={onImageChange}
    //                   ></input>
    //                   <label
    //                     for="image"
    //                     className="img_edit"
    //                     style={{
    //                       position: "relative",
    //                       top: -40,
    //                       left: -120,
    //                     }}
    //                   >
    //                     <div
    //                       style={{
    //                         backgroundColor: "white",
    //                         borderRadius: "75px",
    //                         border: "2px solid #e7e6e6",
    //                         padding: "5px 9px 5px 9px",
    //                         opacity: 0.8,
    //                       }}
    //                     >
    //                       <Icon
    //                         className="fa fa-pencil"
    //                         style={{
    //                           fontSize: "20px",
    //                         }}
    //                       />
    //                     </div>
    //                   </label>
    //                 </div>
    //               </div>{" "}
    //               <Link to="/public/college/profile" target="_blank">
    //                 <p
    //                   style={{
    //                     textAlign: "center",
    //                     color: "rgb(76, 135, 190)",
    //                   }}
    //                 >
    //                   <img src={eye} height="16px" />
    //                   &nbsp; View your public profile
    //                 </p>
    //               </Link>
    //             </>
    //           )}
    //           <br />
    //           <br />
    //         </Mobile>

    //         <Row>
    //           <Col xl="10">
    //             <h3>
    //               {isName} - {city},&nbsp;{state}
    //             </h3>

    //           </Col>
    //           <Col xl="2">
    //             <Link to="/college/Profile_update">
    //               <Button className="bbtn">
    //                 {" "}
    //                 <Icon
    //                   className="fa fa-pencil"
    //                   style={{ fontSize: "17px" }}
    //                 />
    //                 &nbsp;<b>Edit Profile</b>
    //               </Button>
    //             </Link>
    //           </Col>
    //         </Row>
    //         <hr />
    //         <h4>About</h4>
    //         <p>{isAbout}</p>
    //         <br />
    //         <h4>Description</h4>
    //         <p>{isDescription}</p>
    //         <br />
    //         <h4>Message for recruiters</h4>
    //         <p>{isMessage}</p>

    //         <br />
    //         <h4>Specializations and Course</h4>

    //         <br />
    //         <div style={styles.tableContainer}>
    //           <div style={styles.tab}>
    //             <div style={styles.tableHeader22}>Graduation</div>
    //             <div style={styles.tableHeader22}>Degree</div>
    //             <div style={styles.tableHeader22}>Specialization</div>
    //           </div>
    //           {specs.map((data, index) => {
    //             return (
    //               <div style={styles.tab1}>
    //                 {index % 2 == 0 ? (
    //                   <div style={styles.tab}>
    //                     <div style={styles.tableHeader32}>
    //                       {data.education_type_name}
    //                     </div>
    //                     <div style={styles.tableHeader32}>
    //                       {data.degree_name}
    //                     </div>
    //                     <div style={styles.tableHeader32}>
    //                       {data.specialization_name}
    //                     </div>

    //                   </div>
    //                 ) : (
    //                   <div style={styles.tab}>
    //                     <div style={styles.tableHeader0}>
    //                       {data.education_type_name}
    //                     </div>
    //                     <div style={styles.tableHeader0}>
    //                       {data.degree_name}
    //                     </div>
    //                     <div style={styles.tableHeader0}>
    //                       {data.specialization_name}
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             );
    //           })}
    //         </div>

    //         <br />

    //         <br />

    //         <br />

    //         <h4>FAQ's</h4>
    //         <div>{ReactHtmlParser(faqs)}</div>
    //       </CardBody>
    //     </Card>
    //   </Container>
    // </div>
  );
}

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
// import { CustomButton } from "../../InputComponent/Button/CustomButton";
import { Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import CommonButton from "../../common/CommonButton/CommonButton";
import { useSelector } from "react-redux";

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "10px",
  },
})((props) => (
  <Menu
    style={{ top: "14px" }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function LogInBtnDomain() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      <Link to={{ pathname: `/login`, search: location.search ? location.search : "" }}>
        <CommonButton
          type="button"
          buttonStyle="btn--primary--outline"
          buttonSize="btn--medium"
          id="navBtn"
          variant="outlined"
          style={{ border: domain?.domain ? `1px solid ${domain?.color_code}` : `1px solid #007bff`, color: domain?.domain ? `${domain?.color_code}` : `#007bff` }}

          // aria-controls="customized-menu"
          // aria-haspopup="true"
          // onClick={handleClick}
        >
          <Typography variant="body2" color="inherit">
            Login{" "}
          </Typography>
        </CommonButton>
      </Link>
      {/* <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        
        <StyledMenuItem>
          <ListItemText primary="Student" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText primary="College" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText primary="Company" />
        </StyledMenuItem>
      </StyledMenu> */}
    </>
  );
}

import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  TextareaAutosize,
  TextField,
  withStyles,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import circular_loader from "../../../../../assets/LOAD_CIRCLE.gif";
import { BackendBaseApi, emailRegex } from "../../../../../constants/constants";
import ImageCropperDialog from "./ImageCropperDialog";
export default function BasicInfo(props) {
  const {
    basicDetailsFilled,
    setBasicDetailsFilled,
    setPhoneUpdated,
    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    buttonClicked,
    setButtonClicked,
    currentGender,
    setCurrentGender,

    currentCity,
    currentHomeCity,
    setCurrentCity,
    setCurrentHomeCity,
    allCities,
    handleBack,
    handleNext,
    activeStep,
    previewImage,
    setPreviewImage,
    setProfilePicChanged,
    profilePicChanged,
    fetchPrecentage,
    getUserDetails,
  } = props;
  const classes = useStyles();
  const alert = useAlert();
  const fileInputRef = useRef();
  const fileInputRefNew = useRef();

  const [saveLoader, setSaveLoader] = useState(false);
  const [saveLoaderResume, setSaveLoaderResume] = useState(false);
  const [saveLoaderImage, setSaveLoaderImage] = useState(false);

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const sources = [
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" },
    { name: "Transgender", value: "Transgender" },
  ];
  const [emailError, setEmailError] = React.useState(false);
  const [validate, setValidate] = useState(false);

  const validateData = () => {
    var first_name_error = "";
    var last_name_error = "";
    var email_error = "";
    var phone_error = "";
    // var dob_error = "";
    var gender_error = "";
    // var current_city_error = "";
    // var home_city_error = "";
    setValidate(false);
    if (!userDetails?.FirstName) {
      first_name_error = "Please Enter First Name";
      setValidate(true);
    }
    if (!userDetails?.PhoneNo) {
      phone_error = "Phone Number cannot be left blank";
      setValidate(true);
    }
    if (userDetails.PhoneNo && String(userDetails.PhoneNo).length !== 10) {
      phone_error = "Enter a valid phone number";
      setValidate(true);
    }
    if (!userDetails?.Email) {
      email_error = "Email cannot be left blank";
      setValidate(true);
    }
    if (!emailRegex.test(userDetails?.Email)) {
      email_error = "Please enter a valid email address";
      setValidate(true);
    }
    // if (!userDetails?.dob) {
    //   dob_error = "Please Enter DOB";
    //   setValidate(true);
    // }
    if (!userDetails?.gender?.value) {
      gender_error = "Please Enter gender";
      setValidate(true);
    }
    // if (!userDetails?.currentCity?.city_id) {
    //   current_city_error = "Please Enter Current City ";
    //   setValidate(true);
    // }
    // if (!userDetails?.currentHomeCity?.city_id) {
    //   home_city_error = "Please Enter CHome City ";
    //   setValidate(true);
    // }
    setErrorMessages({
      ...errorMessages,
      PhoneNo: phone_error,
      Email: email_error,
      FirstName: first_name_error,
      // dob: dob_error,
      // currentCity: current_city_error,
      // currentHomeCity: home_city_error,
      gender: gender_error,
    });
    setButtonClicked(true);
  };

  useEffect(() => {
    if (!validate && buttonClicked) {
      addDetails();
    }
    setButtonClicked(false);
  }, [buttonClicked]);

  const fileInputClicked = (e) => {
    fileInputRef.current.click();
  };

  const fileInputClickedNew = (e) => {
    fileInputRefNew.current.click();
  };

  const addDetails = () => {
    setSaveLoader(true);
    const fd = new FormData();

    fd.append("first_name", userDetails.FirstName);
    fd.append("last_name", userDetails.LastName);
    fd.append("phone", userDetails.PhoneNo);
    fd.append("email", userDetails.Email);
    if (userDetails?.currentCity) {
      fd.append(
        "current_city",
        userDetails?.currentCity ? userDetails?.currentCity?.city_id : null
      );
    }
    if (userDetails?.currentHomeCity) {
      fd.append(
        "hometown_city",
        userDetails?.currentHomeCity
          ? userDetails?.currentHomeCity?.city_id
          : null
      );
    }
    if (userDetails.dob) {
      fd.append("dob", userDetails.dob || null);
    }

    fd.append("gender", userDetails.gender ? userDetails.gender.value : null);
    if (userDetails.about) {
      fd.append("about", userDetails.about);
    }

    Axios.patch(BackendBaseApi.PRAVESH + "api/shared/user/", fd, {
      headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Personal Details Updated!");
          setProfilePicChanged(false);
          setSaveLoader(false);
          setButtonClicked(false);
          fetchPrecentage();
          setPhoneUpdated(true);
          setBasicDetailsFilled(true);
        } else {
          alert.error(
            "There was some error while uploading your personal details. Please try again later!"
          );
        }
      })
      .catch((err) => {
        setSaveLoader(false);
        alert.error(err.message);
        console.log(err);
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setProfilePic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      handleClickOpen();
    }
  };
  const [profilePic, setProfilePic] = useState();

  const addDetailsResume = (resume) => {
    const fd = new FormData();

    fd.append("resume", resume);
    setSaveLoaderResume(true);
    Axios.post(BackendBaseApi.PRAVESH + "api/education/v2/resume_upload/", fd, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          setSaveLoaderResume(false);
          alert.success("Resume Parsed Successfully!!!");
          localStorage.setItem("is_student_resume_uploaded", true);
          getUserDetails();
        } else {
          alert.error("Unable to parse resume!");
          setSaveLoaderResume(false);
        }
      })
      .catch((err) => {
        setSaveLoaderResume(false);

        console.log(err);
      });
  };

  const addDetailsImage = (prevImg) => {
    setSaveLoaderImage(true);
    const fd = new FormData();

    fd.append("profile_picture", prevImg ? prevImg : null);

    Axios.patch(
      BackendBaseApi.PRAVESH + "api/shared/user/",

      fd,
      {
        headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("Profile Pic Uploaded Successfully!");

          setSaveLoaderImage(false);
          setButtonClicked(false);
        } else {
          alert.error(
            "There was some error while uploading your personal details. Please try again later!"
          );
        }
      })
      .catch((err) => {
        alert.error(err.message);
        console.log(err);
      });
  };

  const updateResume = (resume) => {
    setSaveLoaderResume(true);
    const fd = new FormData();

    fd.append("resume", resume);

    Axios.patch(
      BackendBaseApi.PRAVESH + "api/shared/user/",

      fd,
      {
        headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setSaveLoaderResume(false);
          alert.success("Resume Updated Successfully!!!");
          localStorage.setItem("is_student_resume_uploaded", true);

          getUserDetails();
        } else {
          alert.error(
            "There was some error while uploading resume details. Please try again later!"
          );
          setSaveLoaderResume(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSaveLoaderResume(false);
      });
  };
  const domain = useSelector(
    (state) => state.SubDomainDetails.subDomainDetails
  );

  return (
    <div>
      <ImageCropperDialog
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        setPreviewImage={setPreviewImage}
        setProfilePicChanged={setProfilePicChanged}
        addDetailsImage={addDetailsImage}
      />
      <div>
        <input
          ref={fileInputRefNew}
          className="file-input"
          type="file"
          id="file"
          onChange={(e) => {
            onSelectFile(e);
            // if (!e.target.files[0]) return;
            // const ext = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".") + 1);
            // if (ext !== "jpg" && ext !== "jpeg" && ext !== "png") {
            //   alert.error("Only jpg, jpeg, png Format Allowed!");
            //   return;
            // }
            // if (e.target.files[0].size < 2097152) {
            //   setProfilePic(e.target.files[0]);
            //   handleClickOpen();
            // } else alert.error("File Size Limit: 2MB");
          }}
        />
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ marginBottom: 40 }}
          badgeContent={
            <IconButton
              style={{
                background: "#fff",
                padding: 6,
                boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                border: "solid 1px #f1f1f1",
              }}
              onClick={(e) => fileInputClickedNew(e)}
            >
              <PhotoCameraIcon style={{ fontSize: 20, color: "#007bff" }} />
            </IconButton>
          }
        >
          <Avatar
            alt="Travis Howard"
            style={{ height: "100px", width: "100px" }}
            src={saveLoaderImage ? circular_loader : previewImage}
          />
        </Badge>
      </div>
      <div>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              error={errorMessages.FirstName !== "" ? true : false}
              id="standard-error-helper-text"
              variant="outlined"
              label={
                <span className={classes.labelField}>
                  First Name&nbsp;<span className={classes.RedStar}>*</span>
                </span>
              }
              helperText={
                <span
                  style={
                    errorMessages.FirstName !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.FirstName}
                </span>
              }
              value={userDetails.FirstName}
              onChange={(e) => {
                setUserDetails({ ...userDetails, FirstName: e.target.value });
                setErrorMessages({ ...errorMessages, FirstName: "" });
                setButtonClicked(false);
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={{
                style: { backgroundColor: "#fff" },
              }}
              className={classes.fieldStyling}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={errorMessages.LastName !== "" ? true : false}
              id="standard-error-helper-text"
              variant="outlined"
              label={<span className={classes.labelField}>Last Name</span>}
              helperText={
                <span
                  style={
                    errorMessages.LastName !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.LastName}
                </span>
              }
              value={userDetails.LastName}
              onChange={(e) => {
                setUserDetails({ ...userDetails, LastName: e.target.value });
                setErrorMessages({ ...errorMessages, LastName: "" });
                setButtonClicked(false);
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={{
                style: { backgroundColor: "#fff" },
              }}
              className={classes.fieldStyling1}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              error={errorMessages.Email !== "" ? true : false}
              key="Email"
              id="standard-error-helper-text"
              variant="outlined"
              label={
                <span className={classes.labelField}>
                  Email&nbsp;<span className={classes.RedStar}>*</span>
                </span>
              }
              helperText={
                <span
                  style={
                    errorMessages.Email !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.Email}
                </span>
              }
              value={userDetails.Email}
              onChange={(e) => {
                setUserDetails({ ...userDetails, Email: e.target.value });
                setErrorMessages({ ...errorMessages, Email: "" });
                setButtonClicked(false);
                setEmailError(false);
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={{
                style: { backgroundColor: "#fff" },
              }}
              className={classes.fieldStyling1}
              disabled
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={errorMessages.PhoneNo !== "" ? true : false}
              key="Email"
              id="standard-error-helper-text"
              variant="outlined"
              type="number"
              label={
                <span className={classes.labelField}>
                  Phone No.&nbsp;<span className={classes.RedStar}>*</span>
                </span>
              }
              helperText={
                <span
                  style={
                    errorMessages.PhoneNo !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.PhoneNo}
                </span>
              }
              value={userDetails.PhoneNo ? userDetails.PhoneNo : <></>}
              onChange={(e) => {
                setUserDetails({ ...userDetails, PhoneNo: e.target.value });
                setErrorMessages({ ...errorMessages, PhoneNo: "" });
                setButtonClicked(false);
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              // InputLabelProps={{
              //   style: { backgroundColor: "#fff" },
              // }}
              InputLabelProps={{ shrink: true }}
              className={classes.fieldStyling}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              error={errorMessages.dob !== "" ? true : false}
              key="Email"
              id="standard-error-helper-text"
              variant="outlined"
              type="date"
              label={
                <span className={classes.labelField}>Date of Birth&nbsp;</span>
              }
              helperText={
                <span
                  style={
                    errorMessages.dob !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.dob}
                </span>
              }
              value={userDetails.dob}
              onChange={(e) => {
                setUserDetails({ ...userDetails, dob: e.target.value });
                setErrorMessages({ ...errorMessages, dob: "" });
                setButtonClicked(false);
                setEmailError(false);
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={{ shrink: true }}
              className={classes.fieldStyling1}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              value={userDetails.gender}
              openOnFocus
              onChange={(event, newValue) => {
                if (newValue) {
                  setCurrentGender(newValue);
                  setUserDetails({ ...userDetails, gender: newValue });
                  setErrorMessages({ ...errorMessages, gender: "" });

                  setButtonClicked(false);
                }
              }}
              id="role345"
              options={sources}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={
                      <span style={{ fontSize: 13 }}>
                        Gender <span className={classes.RedStar}>*</span>
                      </span>
                    }
                    variant="outlined"
                    error={errorMessages.gender !== "" ? true : false}
                    helperText={
                      <span
                        style={
                          errorMessages.gender !== ""
                            ? { color: "#D64045", fontSize: 13 }
                            : { color: "grey", fontSize: 13 }
                        }
                      >
                        {errorMessages.gender}
                      </span>
                    }
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Autocomplete
              value={userDetails.currentCity}
              openOnFocus
              onChange={(event, newValue) => {
                if (newValue) {
                  setUserDetails({ ...userDetails, currentCity: newValue });
                  setErrorMessages({ ...errorMessages, currentCity: "" });

                  setButtonClicked(false);
                }
              }}
              id="role345"
              options={allCities}
              getOptionLabel={(option) => option.city}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.city}`}</span>;
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={<span style={{ fontSize: 13 }}>Current City</span>}
                    variant="outlined"
                    error={errorMessages.currentCity !== "" ? true : false}
                    helperText={
                      <span
                        style={
                          errorMessages.currentCity !== ""
                            ? { color: "#D64045", fontSize: 13 }
                            : { color: "grey", fontSize: 13 }
                        }
                      >
                        {errorMessages.currentCity}
                      </span>
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              value={userDetails.currentHomeCity}
              openOnFocus
              onChange={(event, newValue) => {
                if (newValue) {
                  setUserDetails({ ...userDetails, currentHomeCity: newValue });
                  setErrorMessages({ ...errorMessages, currentHomeCity: "" });

                  setButtonClicked(false);
                }
              }}
              id="role345"
              options={allCities}
              getOptionLabel={(option) => option.city}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.city}`}</span>;
              }}
              className={classes.fieldStyling1}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={<span style={{ fontSize: 13 }}>Hometown</span>}
                    variant="outlined"
                    error={errorMessages.currentHomeCity !== "" ? true : false}
                    helperText={
                      <span
                        style={
                          errorMessages.currentHomeCity !== ""
                            ? { color: "#D64045", fontSize: 13 }
                            : { color: "grey", fontSize: 13 }
                        }
                      >
                        {errorMessages.currentHomeCity}
                      </span>
                    }
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <TextareaAutosize
          rowsMin={3}
          rowsMax={5}
          style={{
            width: "100%",
            borderRadius: "30px",
            borderColor: "#d2cdcd",
            padding: 12,
            outline: "none",
            marginTop: 10,
          }}
          aria-label="maximum height"
          placeholder="About you"
          onChange={(e) => {
            setButtonClicked(false);
            setUserDetails({ ...userDetails, about: e.target.value });
          }}
          value={userDetails.about}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "left" }}>
        {userDetails.resume && (
          <>
            <Button style={{ color: "#007bff" }}>
              <a
                href={userDetails.resume}
                download
                target="blank"
                style={{ color: "#007bff" }}
              >
                Download Resume
              </a>
            </Button>
            &nbsp; &nbsp;&nbsp;&nbsp;
            <Hidden smDown>
              <div onClick={(e) => fileInputClicked(e)}>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  id="file"
                  onChange={(e) => {
                    if (!e.target.files[0]) return;
                    const ext = e.target.files[0].name.substring(
                      e.target.files[0].name.lastIndexOf(".") + 1
                    );
                    if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
                      alert.error("Only PDF & DOC Format Allowed!");
                      return;
                    }
                    if (e.target.files[0].size < 2097152) {
                      updateResume(e.target.files[0]);
                      // setUserDetails({ ...userDetails, resume: e.target.files[0] });
                    } else alert.error("File Size Limit: 2MB");
                  }}
                  accept=".docx,.pdf"
                />
                <Button style={{ color: "#007bff" }}>
                  {saveLoaderResume ? (
                    <CircularProgress style={{ color: "#007bff" }} size={15} />
                  ) : (
                    "Update Resume"
                  )}
                </Button>
              </div>
            </Hidden>
          </>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "left" }}>
        <Button
          onClick={() => {
            validateData();
          }}
          style={{ color: "#007bff" }}
        >
          {saveLoader ? (
            <CircularProgress style={{ color: "#007bff" }} size={15} />
          ) : (
            "Save Details"
          )}
        </Button>
      </div>

      <Hidden smUp>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div>
          <div onClick={(e) => fileInputClicked(e)}>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              id="file"
              onChange={(e) => {
                if (!e.target.files[0]) return;
                const ext = e.target.files[0].name.substring(
                  e.target.files[0].name.lastIndexOf(".") + 1
                );
                if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
                  alert.error("Only PDF & DOC Format Allowed!");
                  return;
                }
                if (e.target.files[0].size < 2097152) {
                  if (!userDetails.resume) addDetailsResume(e.target.files[0]);
                  else updateResume(e.target.files[0]);
                  // setUserDetails({ ...userDetails, resume: e.target.files[0] });
                } else alert.error("File Size Limit: 2MB");
              }}
              accept=".docx,.pdf"
            />
            <Button
              variant="contained"
              className={classes.btn}
              style={{ width: "100%" }}
            >
              {saveLoaderResume ? (
                <CircularProgress style={{ color: "#fff" }} size={20} />
              ) : userDetails.resume ? (
                "Update Resume"
              ) : (
                "Upload Resume to auto update your details"
              )}
            </Button>
          </div>{" "}
        </div>
      </Hidden>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    labelField: {
      fontSize: 13,
    },
    RedStar: {
      color: "red",
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      // marginBottom: 20,
    },
    fieldStyling1: {
      width: "100%",
      marginBottom: 10,
    },
    btn: {
      backgroundColor: theme.palette.domain.main,
      borderRadius: "30px",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: theme.palette.domain.hover,
      },
    },
  })
);

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

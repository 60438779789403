import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Chip, Grid, CircularProgress, Divider } from "@material-ui/core";
import pSBC from "shade-blend-color";

let red = "#C4161C";
let cgreen = "#0B8A00";

const useStyles = makeStyles((theme) => ({
  rate: {
    backgroundColor: pSBC(0.8, cgreen),
    color: cgreen,
    borderColor: cgreen,
    border: "1px solid",
  },

  greenchip: {
    marginLeft: "20px",
    backgroundColor: pSBC(0.8, cgreen),
    color: cgreen,
    borderColor: cgreen,
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  redchip: {
    marginLeft: "20px",
    backgroundColor: pSBC(0.8, red),
    color: red,
    borderColor: red,
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  item: {
    display: "flex",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const ScoreTooltip = withStyles((theme) => ({
  tooltip: {
    borderRadius: "20px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#fff",
    // maxWidth: 320,
    // height: 320,
    display: "flex",
    justifyContent: "center",
    padding: "30px",
    alignItems: "center",
    alignContent: "center",
  },
}))(Tooltip);

export default function ScoreDialog(props) {
  const classes = useStyles();
  return (
    <div>
      <ScoreTooltip
        placement="right"
        title={
          <>
            <div className={classes.root1}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Typography variant="body1" style={{ fontWeight: "600" }}>
                    Relevance Score Based On
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">Key Skills</Typography>
                </Grid>
                <Grid item sm={6}>
                  {" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress
                      variant="determinate"
                      size={15}
                      style={{ color: "#12CB02" }}
                      value={
                        props?.scoreData?.key_skills
                          ? props?.scoreData?.key_skills
                          : 1
                      }
                    />
                    <Typography variant="body2" style={{ marginLeft: "10px" }}>
                      {props?.scoreData?.key_skills ?? 0}% Match
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="body2">Comm Skills</Typography>
                </Grid>
                <Grid item sm={6}>
                  {" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress
                      variant="determinate"
                      style={{ color: "#EBB241" }}
                      size={15}
                      value={
                        props?.scoreData?.communication_skills
                          ? props?.scoreData?.communication_skills
                          : 1
                      }
                    />
                    <Typography variant="body2" style={{ marginLeft: "10px" }}>
                      {props?.scoreData?.communication_skills ?? 0}% Match
                    </Typography>
                  </div>
                </Grid>
                <Grid item sm={12}>
                  <Divider />
                </Grid>
                <Grid item sm={4}>
                  <Typography variant="body2">Location</Typography>
                </Grid>
                <Grid item sm={8}>
                  <Chip
                    size="small"
                    label={
                      <Typography
                        color="inherit"
                        style={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        {props?.scoreData?.location ? "Match" : "Not Match"}
                      </Typography>
                    }
                    className={
                      props?.scoreData?.location
                        ? classes.greenchip
                        : classes.redchip
                    }
                  />
                </Grid>
                <Grid item sm={4}>
                  <Typography variant="body2">Salary</Typography>
                </Grid>
                <Grid item sm={8}>
                  <Chip
                    size="small"
                    label={
                      <Typography
                        color="inherit"
                        style={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        {props?.scoreData?.salary ? "Match" : "Not Match"}
                      </Typography>
                    }
                    className={
                      props?.scoreData?.salary
                        ? classes.greenchip
                        : classes.redchip
                    }
                  />
                </Grid>{" "}
                <Grid item sm={4}>
                  <Typography variant="body2">Work Ex</Typography>
                </Grid>
                <Grid item sm={8}>
                  <Chip
                    size="small"
                    label={
                      <Typography
                        color="inherit"
                        style={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        {props?.scoreData?.work_exp ? "Match" : "Not Match"}
                      </Typography>
                    }
                    className={
                      props?.scoreData?.work_exp
                        ? classes.greenchip
                        : classes.redchip
                    }
                  />
                </Grid>{" "}
                <Grid item sm={4}>
                  <Typography variant="body2">Education</Typography>
                </Grid>
                <Grid item sm={8}>
                  <Chip
                    size="small"
                    label={
                      <Typography
                        color="inherit"
                        style={{ fontWeight: "400", fontSize: "12px" }}
                      >
                        {props?.scoreData?.education ? "Match" : "Not Match"}
                      </Typography>
                    }
                    className={
                      props?.scoreData?.education
                        ? classes.greenchip
                        : classes.redchip
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </>
        }
      >
        <Chip
          size="small"
          label={
            <Typography
              color="inherit"
              style={{ fontWeight: "400", fontSize: "14px", cursor: "pointer" }}
            >
              {props.score}
            </Typography>
          }
          className={classes.rate}
        />
      </ScoreTooltip>
    </div>
  );
}

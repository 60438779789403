import React, { useState, useEffect } from "react";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";

export default function Dummy() {
  const [url, setUrl] = useState(
    BackendBaseApi.PRAVESH + "api/college/edit_profile/"
  );
  const [degree, setDegree] = useState([]);
  const [specs, setSpecialization] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(url, {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            const { data } = res.data;
            setDegree(data.degree_specialization);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, []);

  const handleDropdown = (e) => {
    if (e.target.value == "others") {
    } else {
      var temp = degree.filter((item) => {
        return item.degree_name === e.target.value;
      });

      setSpecialization(temp);
    }
  };

  return (
    <>
      <select onChange={handleDropdown} className="form-control">
        <>
          {degree &&
            degree.map((e) => {
              return (
                <option key={e.degree_id} value={e.degree_name}>
                  {" "}
                  {e.degree_name}{" "}
                </option>
              );
            })}
        </>
        <option value="others">Other</option>
      </select>

      <select className="form-control">
        <>
          {specs &&
            specs.map((e) => {
              return (
                <option value={e.specialization_name}>
                  {" "}
                  {e.specialization_name}{" "}
                </option>
              );
            })}
        </>
        <option value="others">Other</option>
      </select>

      <select className="form-control">
        <>
          {specs &&
            specs.map((e) => {
              return (
                <option value={e.education_type}> {e.education_type} </option>
              );
            })}
        </>
        <option value="others">Other</option>
      </select>
    </>
  );
}

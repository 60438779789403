import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import CustomizedCheckboxes from "./FormComponents/CustomizedCheckboxes";

import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { BackendBaseApi } from "../../../constants/constants";
import Axios from "axios";
import {
  getDegrees,
  UPDATE_EDUCATION_LEVELS,
  UPDATE_DEGREES,
  UPDATE_COURSES,
  getCourses,
  getAllDegrees,
  getAllLocations,
  UPDATE_LOCATIONS,
  FILTER_COUNT,
  UPDATE_MIN_CTC,
  UPDATE_JOB_SEGMENT,
  getJobSegment,
  UPDATE_COLLEGE_FILTER,
} from "../../company/jobs/PostJob/Forms/action";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckSharp,
  CompareArrowsOutlined,
  MaximizeSharp,
} from "@material-ui/icons";
import {
  JobSegmentFilter,
  Locations,
  LocationsFilter,
} from "./FormComponents/JobSegment";
import CurrencyComponent from "./FormComponents/CurrencyComponent";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  grid1: {
    marginTop: "25px",
    fontSize: 13,
    fontWeight: "bolder",
    paddingLeft: "20%",
    paddingTop: "4%",
  },
  whiteBox: {
    padding: "20px",
    borderRadius: 30,
    WebkitBackdropFilter: "blur(30px)",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "3px solid rgba(152, 152, 152, 0.27)",
    // minHeight: "700px",
    // maxHeight: "700px",
    // overflowY: "scroll",
    // scrollBehavior: "smooth",
  },
  grid2: {
    marginTop: "25px",
  },
  grid3: {
    fontWeight: "bolder",
    paddingLeft: "20%",
    paddingTop: "4%",
    fontSize: 13,
  },
  grid4: {
    marginTop: "10px",
  },
  redstar: {
    color: "red",
    fontWeight: "bolder",
  },
  head1: {
    alignItems: "left",
    textAlign: "left",
    margin: "20px 10px 20px 20px",
  },
  postButton: {
    background: "#3282C4",
    color: "#fff",
    alignItems: "left",
    width: "60%",

    "&:hover": {
      background: "#3282C4",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  backButton: {
    background: "#eb5757",
    color: "#fff",
    alignItems: "right",
    width: "90%",
    marginLeft: "40%",

    "&:hover": {
      background: "#c82333",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  description_para: {
    fontSize: 13,
  },
  radio: {
    "&$checked": {
      color: "#3282C4",
    },
    "&:hover": {
      backgroundColor: "rgba(50, 130, 196, 0.04)",
    },
  },
  checked: {},
  preferenceButton: {
    borderRadius: 30,
    background: "#3282C4",
    color: "white",
    "&:hover": {
      border: "2px solid #3282C4",
      background: "white",
      color: "#3282C4",
    },
  },
  labelSize: {
    fontSize: "12px",
  },
  InputWidth: {
    width: "100%",
  },

  "@media only screen and (max-width: 1100px)": {
    labelSize: {
      fontSize: "11px",
    },
    grid1: {
      marginTop: "17px",
      fontSize: 12,

      fontWeight: "bolder",
      paddingLeft: "20%",
      paddingTop: "2%",
    },
    grid3: {
      fontWeight: "bolder",
      paddingLeft: "20%",
      paddingTop: "2%",
      fontSize: 12,
    },
  },
});

export default function CustomizedDialogs(props) {
  const {
    state,
    newStateMemory,
    handleFilterChange,
    setCollegeList,
    setNextUrl,
    setEnd,
    checkAll,
    checkJobLake,
    setState,
    setLoader,
    applyButtonClicked,
    setApplyButtonClicked,
  } = props;

  const dispatch = useDispatch();
  const wholeDegreeList = useSelector((state) => state.AllDegreesList.list);
  const courses = useSelector((state) => state.CoursesList.courseList);
  const degrees = useSelector((state) => state.DegreesList.degreeList);
  // const cityList = useSelector((state) => state.CityList.cityList);
  const cityList = useSelector((state) => state.CityArrayNew.cityArray);

  const [degree, setDegree] = useState();
  const [course, setCourse] = useState();
  const [city, setCity] = useState();
  const [currentCTC, setCurrentCTC] = useState(newStateMemory.ctc);
  const [currentJobSegment, setCurrentJobSegment] = useState();
  const [apiCall_n, setApiCall_n] = React.useState(false);
  const segmentList = useSelector(
    (state) => state.JobSegmentListNew.JobSegmentList
  );
  // const stateMemoryNew = useSelector((state) => state.CollegeFilterState);

  const [stateMemory, setStateMemory] = useState({
    education_levels: newStateMemory.education_levels,
    degrees: newStateMemory.degrees,
    courses: newStateMemory.courses,

    location: newStateMemory.location,
    ctc: parseInt(newStateMemory.ctc),
    job_segment: newStateMemory.job_segment,
    num: newStateMemory.num,
  });

  const citySelected = useSelector(
    (state) => state.CollegeFilterState.location
  );

  const segmentSelected = useSelector(
    (state) => state.CollegeFilterState.job_segment
  );

  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const min_ctc = [
    { name: "Greater than 1 LPA", value: 100000 },
    { name: "Greater than 6 LPA", value: 600000 },
    { name: "Greater than 10 LPA", value: 1000000 },
    { name: "Greater than 30 LPA", value: 3000000 },
    { name: "Greater than 60 LPA", value: 6000000 },
  ];
  let CompanyID = JSON.parse(localStorage.getItem("company")).company;

  // const [degree, setDegree] = React.useState();
  // const [course, setCourse] = React.useState();
  // const [city, setCity] = React.useState();

  const education_levels = {
    Graduation: 3,
    "Post Graduation": 4,
    "Ph.D": 5,
  };
  const [filterIsChanged, setFilterIsChanged] = useState(false);

  useEffect(() => {
    setFilterIsChanged(true);
  }, [
    stateMemory.degrees.length,
    stateMemory.courses.length,
    stateMemory.location.length,
    stateMemory.ctc,
    stateMemory.job_segment.length,
    stateMemory.education_levels.length,
  ]);

  useEffect(() => {
    if (Object.keys(wholeDegreeList).length === 0) dispatch(getAllDegrees());
    // if (Object.keys(cityList).length === 0) dispatch(getAllLocations());
    // if (Object.keys(segmentList).length === 0) dispatch(getJobSegment());
  }, []);

  const handleEducationLevelChange = (e) => {
    const educationlevel = e.target.value;
    let educationLevels = stateMemory.education_levels;
    if (educationLevels.includes(educationlevel)) {
      ///remove it
      educationLevels = educationLevels.filter(
        (edu_lv) => edu_lv !== educationlevel
      );
    } else {
      //add it
      educationLevels.push(educationlevel);
    }

    // handleFilterChange(UPDATE_EDUCATION_LEVELS, educationLevels);
    setStateMemory({ ...stateMemory, education_levels: educationLevels });
  };

  const removeDegree = (deg) => {
    let temp = stateMemory.degrees;
    if (temp.map((d) => d.name).includes(deg.name)) {
      temp = temp.filter((d) => d.id !== deg.id);

      setStateMemory({ ...stateMemory, degrees: temp });

      if (temp.length === 0) setDegree();
    }
  };

  const removeCourse = (Course) => {
    let selectedCourses = stateMemory.courses;
    if (
      !selectedCourses
        .map((selected_course) => selected_course.specialization_name)
        .includes(Course.specialization_name)
    )
      return;
    selectedCourses = selectedCourses.filter(
      (c) => c.specialization_id !== Course.specialization_id
    );

    setStateMemory({ ...stateMemory, courses: selectedCourses });
    if (selectedCourses.length === 0) setCourse();
  };

  useEffect(() => {
    if (degree) {
      let selectedDegrees = stateMemory.degrees;
      const selectedDegreeNames = selectedDegrees.map((deg) => deg.name);
      if (selectedDegreeNames.includes(degree)) return;
      //add this degree to the list
      selectedDegrees.push(degrees[degree]);
      setStateMemory({ ...stateMemory, degrees: selectedDegrees });
    }
  }, [degree]);
  useEffect(() => {
    setStateMemory({ ...stateMemory, ctc: parseInt(currentCTC) });
  }, [currentCTC]);

  useEffect(() => {
    if (course) {
      let selectedCourses = stateMemory.courses;
      if (
        selectedCourses
          .map((selected_course) => selected_course.specialization_name)
          .includes(course)
      )
        return;

      selectedCourses.push(courses[course]);
      setStateMemory({ ...stateMemory, courses: selectedCourses });
    }
  }, [course]);

  const setDegreesURL = (edu_lvs) => {
    let degreeURL = "/education/get_degree?education_level=";
    for (let i = 0; i < edu_lvs.length; i++) {
      degreeURL += JSON.stringify(education_levels[edu_lvs[i]]);
      if (i < edu_lvs.length - 1) degreeURL += ",";
    }
    dispatch(getDegrees(degreeURL));
  };

  useEffect(() => {
    let edu_lvs = stateMemory.education_levels;
    if (edu_lvs.length > 0) setDegreesURL(edu_lvs);
  }, [stateMemory.education_levels.length]);

  useEffect(() => {
    let selectedDegrees = stateMemory.degrees;
    if (selectedDegrees.length > 0) setCourseURL(selectedDegrees);
  }, [stateMemory.degrees.length]);

  const setCourseURL = (selectedDegrees) => {
    let courseURL = "/education/get_specialization?degree_id=";

    for (let i = 0; i < selectedDegrees.length; i++) {
      courseURL += JSON.stringify(wholeDegreeList[selectedDegrees[i].name].id);
      if (i < selectedDegrees.length - 1) courseURL += ",";
    }
    dispatch(getCourses(courseURL));
  };

  useEffect(() => {
    if (city) {
      let selectedCourses = stateMemory.location;
      if (
        selectedCourses
          .map((selected_course) => selected_course.city)
          .includes(city)
      )
        return;
      const currentLocation = {
        city: city,
        city_id: cityList[city],
      };
      selectedCourses.push(currentLocation);

      setStateMemory({ ...stateMemory, location: selectedCourses });
    }

    // if (city) {
    //   if (!stateMemory.location.includes(city)) {
    //     const currentLocation = {
    //       city: city,
    //       city_id: cityList[city],
    //     };
    //     let job_location = [...stateMemory.location];
    //     job_location.push(currentLocation);

    //     setStateMemory({ ...stateMemory, location: job_location });
    //   }
    // }
  }, [city]);

  const removeLocation = (location) => {
    if (
      stateMemory.location.length > 0 &&
      stateMemory.location.includes(location)
    ) {
      let temp = stateMemory.location.filter(
        (loc) => loc.city_id !== location.city_id
      );

      setStateMemory({ ...stateMemory, location: temp });
    }
  };

  useEffect(() => {
    if (currentJobSegment) {
      let selectedCourses = stateMemory.job_segment;
      if (
        selectedCourses
          .map((selected_course) => selected_course.job_segment_name)
          .includes(currentJobSegment)
      )
        return;

      selectedCourses.push(segmentList[currentJobSegment]);

      setStateMemory({ ...stateMemory, job_segment: selectedCourses });
    }
  }, [currentJobSegment]);

  const removeSegment = (item) => {
    if (
      stateMemory.job_segment.length > 0 &&
      stateMemory.job_segment.includes(item)
    ) {
      let temp = stateMemory.job_segment.filter(
        (loc) => loc.job_segment_id !== item.job_segment_id
      );

      setStateMemory({ ...stateMemory, job_segment: temp });
    }
  };

  const degree_s = Object.keys(degrees).map((C) => {
    return { name: C, value: C };
  });

  const cousre_s = Object.keys(courses).map((C) => {
    return { name: C, value: C };
  });
  const Degrees = () => (
    <Autocomplete
      disabled={checkAll || !stateMemory.education_levels.length > 0}
      value={degree}
      onChange={(event, newValue) => {
        setDegree(newValue);
      }}
      id="controllable-states-demo"
      options={degree_s.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<span className={classes.labelSize}>Choose Degrees</span>}
          variant="outlined"
        />
      )}
    />
  );

  const cities = Object.keys(cityList).map((City) => {
    return { name: City, value: City };
  });
  const job_segments = Object.keys(segmentList).map((C) => {
    return { name: C, value: C };
  });
  const JobSegmentList = () => (
    <Autocomplete
      disabled={checkAll || checkJobLake}
      value={currentJobSegment}
      onChange={(event, newValue) => {
        setCurrentJobSegment(newValue);
      }}
      id="controllable-states-demo"
      options={job_segments.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<span className={classes.labelSize}>Job Segment</span>}
          variant="outlined"
        />
      )}
    />
  );

  const CityList = () => (
    <Autocomplete
      disabled={checkAll || checkJobLake}
      value={city}
      onChange={(event, newValue) => {
        setCity(newValue);
      }}
      id="controllable-states-demo"
      options={cities.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<span className={classes.labelSize}>Choose Cities</span>}
          variant="outlined"
        />
      )}
    />
  );

  const Courses = () => (
    <Autocomplete
      disabled={checkAll || checkJobLake || !stateMemory.degrees.length > 0}
      value={course}
      onChange={(event, newValue) => {
        setCourse(newValue);
      }}
      id="controllable-states-demo"
      options={cousre_s.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<span className={classes.labelSize}>Choose Courses</span>}
          variant="outlined"
        />
      )}
    />
  );
  return (
    <div>
      <Box className={classes.whiteBox}>
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>Filters</p>
        <Divider />
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={4}>
            <Typography
              className={classes.grid3}
              // variant="body1"
             // noWrap
              style={
                checkAll
                  ? { color: "grey", fontWeight: "bold", paddingRight: 20 }
                  : { color: "black", fontWeight: "bold", paddingRight: 20 }
              }
            >
              Qualifications
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormGroup>
              {Object.keys(education_levels).map((edu_lv) => {
                return (
                  <FormControlLabel
                    value={edu_lv}
                    control={
                      <CustomizedCheckboxes
                        checked={stateMemory.education_levels.includes(edu_lv)}
                        disabled={checkAll || checkJobLake}
                        onChange={handleEducationLevelChange}
                        name={edu_lv}
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label={<span className={classes.labelSize}>{edu_lv}</span>}
                  />
                );
              })}
            </FormGroup>
          </Grid>
        </Grid>
        <br />

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography
              className={classes.grid3}
              // variant="body1"
              style={
                checkAll || stateMemory.education_levels.length === 0
                  ? { color: "grey", fontWeight: "bold", paddingTop: "15%" }
                  : { color: "black", fontWeight: "bold", paddingTop: "15%" }
              }
            >
              Degree
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.grid4}>
            <Degrees />
            <div className="selected text-left my-2" style={{display: 'flex',flexWrap: 'wrap',justifyContent: 'flex-start',}} >
              {stateMemory.education_levels.length > 0 &&
                stateMemory.degrees.length > 0 &&
                stateMemory.degrees.map((deg) => {
                  return (
                    <Chip
                     // size="small"
                      disabled={checkAll}
                      label={deg.name}
                      onDelete={() => {
                        if (checkAll === false && !checkJobLake)
                          removeDegree(deg);
                        else console.log();
                      }}
                      style={{ marginRight: "1%", marginBottom: "1%", maxWidth: "calc(100% - 6px)" }}
                    />
                  );
                })}
            </div>
          </Grid>
        </Grid>

        <br />

        <>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography
                className={classes.grid3}
                // variant="body1"
                style={
                  checkAll || stateMemory.degrees.length === 0
                    ? { color: "grey", fontWeight: "bold", paddingTop: "15%" }
                    : { color: "black", fontWeight: "bold", paddingTop: "15%" }
                }
              >
                Courses
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.grid4}>
              <Courses />
              <div className="selected text-left my-2" style={{display: 'flex',flexWrap: 'wrap',justifyContent: 'flex-start',}} >
                {stateMemory.courses.length > 0 &&
                  stateMemory.courses.map((spec) => {
                    return (
                      <Chip
                        //size="small"
                        disabled={checkAll}
                        label={spec.specialization_name}
                        onDelete={() => {
                          if (checkAll === false && !checkJobLake)
                            removeCourse(spec);
                          else console.log();
                        }}
                        style={{ marginRight: "1%", marginBottom: "1%",maxWidth: "calc(100% - 6px)" }}
                      />
                    );
                  })}
              </div>
            </Grid>
          </Grid>

          <br />
        </>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography
              className={classes.grid1}
              // variant="body1"
              style={
                checkAll
                  ? { color: "grey", fontWeight: "bold" }
                  : { color: "black", fontWeight: "bold" }
              }
            >
              College Location&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.grid2}>
            <LocationsFilter
              state={stateMemory}
              setState={setStateMemory}
              cityList={cityList}
              width={classes.InputWidth}
              responsive={classes.labelSize}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography
              className={classes.grid1}
              // variant="body1"
              style={
                checkAll
                  ? { color: "grey", fontWeight: "bold" }
                  : { color: "black", fontWeight: "bold" }
              }
            >
              Max CTC&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.grid2}>
            {/* <TextField
              disabled={checkAll || checkJobLake}
              id="outlined-name-2"
              label={<span className={classes.labelSize}>Max CTC</span>}
              type="number"
              value={stateMemory.ctc}
              onChange={(e) => {
                setCurrentCTC(parseInt(e.target.value));
              }}
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{ inputProps: { min: 0 } }}
            /> */}
            <CurrencyComponent
              state={currentCTC}
              setState={setCurrentCTC}
              labelName={"Max CTC"}
              width={"100%"}
              responsive={classes.labelSize}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography
              className={classes.grid1}
              // variant="body1"
              style={
                checkAll
                  ? { color: "grey", fontWeight: "bold" }
                  : { color: "black", fontWeight: "bold" }
              }
            >
              Job Segment &nbsp;
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.grid2}>
            <JobSegmentFilter
              state={stateMemory}
              setState={setStateMemory}
              segmentList={segmentList}
              width={classes.InputWidth}
              responsive={classes.labelSize}
            />
          </Grid>
        </Grid>
        <br />
        <Box
          style={{
            display: "flex",
            float: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            // autoFocus
            variant="outlined"
            style={
              checkAll || checkJobLake
                ? {
                    cursor: "not-allowed",
                    borderRadius: 30,
                    backgroundColor: "grey",
                  }
                : { cursor: "pointer", borderRadius: 30 }
            }
            className={classes.preferenceButton}
            onClick={() => {
              if (checkAll === false || checkJobLake === false) {
                setStateMemory({
                  education_levels: [],
                  degrees: [],
                  courses: [],
                  location: [],
                  ctc: 0,
                  job_segment: [],
                  num: 0,
                });
                setCurrentCTC(0);
                if (
                  (newStateMemory.degrees.length > 0 ||
                    newStateMemory.courses.length > 0 ||
                    newStateMemory.location.length > 0 ||
                    newStateMemory.ctc !== 0 ||
                    newStateMemory.job_segment.length > 0 ||
                    newStateMemory.education_levels.length > 0) &&
                  applyButtonClicked
                ) {
                  setCollegeList({});
                  setNextUrl(null);
                  setEnd(false);
                  handleFilterChange(UPDATE_DEGREES, []);
                  handleFilterChange(UPDATE_COURSES, []);
                  handleFilterChange(UPDATE_LOCATIONS, []);
                  handleFilterChange(UPDATE_EDUCATION_LEVELS, []);
                  handleFilterChange(FILTER_COUNT, 0);
                  handleFilterChange(UPDATE_MIN_CTC, 0);
                  handleFilterChange(UPDATE_JOB_SEGMENT, []);
                  setApplyButtonClicked(false);
                }
              }
            }}
            color="primary"
          >
            <span className={classes.labelSize}>Clear All Filters</span>
          </Button>
          <Button
            variant="outlined"
            // autoFocus
            className={classes.preferenceButton}
            style={
              checkAll || checkJobLake
                ? {
                    cursor: "not-allowed",
                    borderRadius: 30,
                    backgroundColor: "grey",
                  }
                : { cursor: "pointer", borderRadius: 30 }
            }
            onClick={() => {
              if (checkAll === false || checkJobLake === false) {
                if (filterIsChanged) {
                  setCollegeList({});
                  setNextUrl(null);
                  setEnd(false);
                  setLoader(true);
                  handleFilterChange(UPDATE_COLLEGE_FILTER, stateMemory);
                  handleFilterChange(UPDATE_DEGREES, stateMemory.degrees);
                  handleFilterChange(UPDATE_COURSES, stateMemory.courses);
                  handleFilterChange(UPDATE_LOCATIONS, stateMemory.location);
                  handleFilterChange(
                    UPDATE_EDUCATION_LEVELS,
                    stateMemory.education_levels
                  );
                  handleFilterChange(UPDATE_MIN_CTC, stateMemory.ctc);
                  handleFilterChange(
                    UPDATE_JOB_SEGMENT,
                    stateMemory.job_segment
                  );
                  setFilterIsChanged(false);
                  setApplyButtonClicked(true);
                }
              }
            }}
            color="primary"
          >
            <span className={classes.labelSize}>Apply</span>
          </Button>
        </Box>
      </Box>
    </div>
  );
}

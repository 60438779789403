import React, { useState, useEffect } from "react";
import "./FormComponents.css";
import moment from "moment";
import { Tooltip, TextField, InputLabelProps } from "@material-ui/core";
import { Chip, Avatar, Button, Divider } from "@material-ui/core";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import Axios from "axios";
import { BackendBaseApi } from "../../../../constants/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { uniqueArray } from "../../../../utils/common/filterJob";
import dateDifference from "../../../../utils/common/dateDifference";
import CommonButton from "../../CommonButton/CommonButton";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

function WorkExperience({ experiences, setExperiences, allSkills, alert, experienceBoxOpen, positions }) {
  const [allCompanies, setAllCompanies] = useState([]);
  const [viewBtn, setViewBtn] = useState(false);

  const [company, setCompany] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [isCompanyListed, setIsCompanyListed] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [jobDesc, setJobDesc] = useState("");
  const [jobDesig, setJobDesig] = useState("");
  const [jobStart, setJobStart] = useState("");
  const [jobEnd, setJobEnd] = useState("");
  const [jobType, setJobType] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [count, setCount] = useState(0);

  const classes = useStyles();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    setCount(experiences.length);
  }, [experiences]);

  // All Companies in DB
  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/company_list/")
      .then((res) => {
        if (res.data.success) {
          setAllCompanies(res.data.data.all_companies);
        } else {
          alert.error("Unable to fetch Companies from Server!", { position: positions.TOP_RIGHT });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (experienceBoxOpen.success) {
      editBtn(experienceBoxOpen.id);
    }
  }, [experienceBoxOpen]);

  const clearStates = () => {
    setCompany(null);
    setCompanyName("");
    setCompanyWebsite("");
    setIsCompanyListed(false);
    setJobDesc("");
    setJobDesig("");
    setJobStart("");
    setJobEnd("");
    setJobType(null);
    setSelectedSkills([]);
  };

  const addExperience = (e) => {
    e.preventDefault();
    if (!isCompanyListed && !company) {
      alert.error("Select Company from list !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!company && !companyName) {
      alert.error("Enter Company Name to proceed !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!jobDesig) {
      alert.error("Job Designation required !", { position: positions.TOP_RIGHT });
      return;
    }
    // if (!jobType) {
    //   alert.error("Job Type required !", { position: positions.TOP_RIGHT });
    //   return;
    // }
    // if (!jobDesc) {
    //   alert.error("Job Description required !", { position: positions.TOP_RIGHT });
    //   return;
    // }
    if (!jobStart) {
      alert.error("Job Start Date Mandatory !", { position: positions.TOP_RIGHT });
      return;
    }

    let arr = [...experiences];
    let temp = {
      id: count,
      company_id: company ? company.id : null,
      company_name: company ? company.company : companyName,
      company_website: companyWebsite,
      is_current_working: jobEnd ? false : true,
      job_type_id: jobType?.job_type_id ? jobType.job_type_id : null,
      job_type: jobType?.job_type_name ? jobType.job_type_name : null,
      job_designation: jobDesig,
      job_description: jobDesc ? jobDesc : null,
      start_date: jobStart || null,
      end_date: jobEnd || null,
      skills: selectedSkills,
      is_valid: true,
    };
    arr.push(temp);
    setExperiences(uniqueArray(arr));
    setCount(count + 1);
    clearStates();
    window.document.getElementById("work-experience-enter").className = "collapse";
  };

  const jobTypes = [
    { job_type_id: 1, job_type_name: "Internship" },
    { job_type_id: 7, job_type_name: "Full-Time" },
  ];

  const handleDelete = (boxId) => {
    var arr = experiences.filter((exp) => exp.id !== boxId);
    setExperiences(arr);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (!isCompanyListed && !company) {
      alert.error("Select Company from list !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!company && !companyName) {
      alert.error("Enter Company Name to proceed !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!jobDesig) {
      alert.error("Job Designation required !", { position: positions.TOP_RIGHT });
      return;
    }
    // if (!jobType?.job_type_name) {
    //   alert.error("Job Type required !", { position: positions.TOP_RIGHT });
    //   return;
    // }
    // if (!jobDesc) {
    //   alert.error("Job Description required !", { position: positions.TOP_RIGHT });
    //   return;
    // }
    if (!jobStart) {
      alert.error("Job Start Date Mandatory !", { position: positions.TOP_RIGHT });
      return;
    }

    let arr = [...experiences];
    let temp = {
      id: count,
      company_id: company ? company.id : null,
      company_name: company ? company.company : companyName,
      company_website: companyWebsite,
      is_current_working: jobEnd ? false : true,
      job_type_id: jobType?.job_type_id ? jobType.job_type_id : null,
      job_type: jobType?.job_type_name ? jobType.job_type_name : null,
      job_designation: jobDesig,
      job_description: jobDesc ? jobDesc : null,
      start_date: jobStart || null,
      end_date: jobEnd || null,
      skills: selectedSkills,
      is_valid: true,
    };
    let a = arr.map((obj) => (obj.id === editId ? temp : obj));
    setExperiences(uniqueArray(a));
    cancelEdit();
  };

  const editBtn = (id) => {
    let arr = experiences.filter((edu) => edu.id === id);
    let temp = arr[0];
    setIsEditOpen(true);
    setEditId(id);
    setCompany(
      temp.company_id
        ? {
            id: temp.company_id,
            company: temp.company_name,
          }
        : null
    );
    setCompanyName(temp.company_name);
    setCompanyWebsite(temp.company_website);
    setIsCompanyListed(temp.company_id ? false : true);
    setJobDesc(temp.job_description);
    setJobDesig(temp.job_designation);
    setJobStart(temp.start_date || "");
    setJobEnd(temp.end_date || "");
    setJobType({
      job_type_id: temp.job_type_id,
      job_type_name: temp.job_type,
    });
    setSelectedSkills(temp.skills || []);
    window.document.getElementById("work-experience-enter").className = "collapse show";
    window.document.getElementById("experience_section").scrollIntoView(true);
  };

  const cancelEdit = () => {
    window.document.getElementById("work-experience-enter").className = "collapse";
    setIsEditOpen(false);
    setEditId(null);
    clearStates();
  };

  return (
    <div id="experiences-form">
      <div className="collapse" id="work-experience-enter">
        <div>
          <div id="add_new_div">
            {!isCompanyListed ? (
              <Autocomplete
                id="tags-outlined"
                options={allCompanies}
                required
                className="type_search_autocomplete_half"
                getOptionLabel={(option) => option.company}
                onChange={(e, i) => {
                  i ? setCompany(i) : setCompany(null);
                }}
                value={company}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Select Company *" />}
              />
            ) : (
              <TextField
                type="text"
                required
                variant="outlined"
                label="Company Name"
                value={companyName}
                style={{ marginBottom: "2rem" }}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            )}
            <TextField type="text" variant="outlined" label="Company Website" value={companyWebsite} onChange={(e) => setCompanyWebsite(e.target.value)} />
          </div>
          <div id="add_checkbox">
            <FormControlLabel
              style={{ color: "#3a3838", marginLeft: "4px" }}
              control={<Checkbox checked={isCompanyListed} onChange={(e) => setIsCompanyListed(!isCompanyListed)} name="checkedB" color="primary" />}
              label="Company Not Listed ?"
            />
          </div>
          <div id="add_new_div">
            <TextField type="text" required variant="outlined" label="Job Designation" value={jobDesig} onChange={(e) => setJobDesig(e.target.value)} />
            <Autocomplete
              id="tags-outlined"
              options={jobTypes}
              required
              className="type_search_autocomplete_half"
              getOptionLabel={(option) => option.job_type_name}
              onChange={(e, i) => {
                i ? setJobType(i) : setJobType(null);
              }}
              value={jobType}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Select Job Type" />}
            />
          </div>
          <div id="add_new_full_length_div">
            <TextField type="text" variant="outlined" label="Job Description" multiline rows={3} value={jobDesc} onChange={(e) => setJobDesc(e.target.value)} />
          </div>
          <div id="add_new_div">
            <TextField
              type="date"
              required
              variant="outlined"
              label="Job Start Date"
              InputLabelProps={{ shrink: true }}
              value={jobStart}
              onChange={(e) => setJobStart(e.target.value)}
            />
            <TextField type="date" variant="outlined" label="Job End Date" InputLabelProps={{ shrink: true }} value={jobEnd} onChange={(e) => setJobEnd(e.target.value)} />
          </div>
          <div id="add_new_autocomplete_div">
            <Autocomplete
              multiple
              filterSelectedOptions
              options={allSkills}
              getOptionLabel={(option) => option.skill_name}
              className="type_search_autocomplete_one"
              onChange={(e, i) => {
                i ? setSelectedSkills(i) : setSelectedSkills(null);
              }}
              value={selectedSkills}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Skills Implemented" />}
            />
          </div>
          <div style={{ marginLeft: "4px", display: "flex", alignItems: "center", color: "#1ea0c7" }}>
            <AnnouncementIcon style={{ marginRight: "4px" }} />
            <span>
              <b>NOTE: </b>
              Leave the Job End Date Field vacant, in case you are currently working here !
            </span>
          </div>
        </div>
        {isEditOpen ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CommonButton variant="contained" style={{ display: "block", margin: "1rem 8px", outline: "none", backgroundColor: "#e0e0e0" }} onClick={() => cancelEdit()}>
              Cancel
            </CommonButton>
            <CommonButton variant="contained" style={{ display: "block", margin: "1rem 8px", outline: "none" }} onClick={(e) => handleEdit(e)}>
              Update
            </CommonButton>
          </div>
        ) : (
          <CommonButton variant="contained" style={{ display: "block", margin: "1rem auto", outline: "none" }} onClick={(e) => addExperience(e)}>
            Add Experience
          </CommonButton>
        )}
      </div>
      <div>
        {experiences.length > 0 ? (
          <span id="box_value" style={{ marginLeft: "1%" }}>
            {experiences.map((exp, key) => {
              return (
                <div id="card_outer_div" style={!exp.is_valid ? { border: "1px solid #dc3545", backgroundColor: "#fff6ff" } : {}}>
                  {/* Heading & Delete */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginBottom: "0.6rem" }}>
                    <div>
                      <span>
                        <b>Experience {key + 1}</b>
                      </span>
                    </div>
                    <div style={{ marginRight: "0.5rem", cursor: "pointer" }}>
                      <Tooltip title="Edit Experience" placement="right">
                        <span
                          style={{ marginRight: "1rem" }}
                          onClick={(e) => {
                            editBtn(exp.id);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete Experience" placement="right">
                        <span
                          onClick={(e) => {
                            handleDelete(exp.id);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  {/* Job Designation & Job Type */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <div>
                      <span>
                        <b> {exp.job_designation} </b>
                      </span>
                    </div>
                    <div>
                      <span>
                        <b> {exp.job_type} </b>
                      </span>
                    </div>
                  </div>
                  {/* Company Name with Link */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <div>
                      <b
                        style={exp.company_website ? { color: "#3282c4", cursor: "pointer" } : {}}
                        onClick={(e) => {
                          exp.company_website && window.open(exp.company_website);
                        }}
                      >
                        {" "}
                        {exp.company_name}{" "}
                      </b>
                    </div>
                  </div>
                  {/* Job Start & End Date, Duration if both dates present */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    {exp.start_date && (
                      <div>
                        <span>
                          {moment(exp.start_date).format("DD MMM'YY")}
                          {exp.end_date ? ` - ${moment(exp.end_date).format("DD MMM'YY")}` : " - Present"}
                        </span>
                      </div>
                    )}
                    {exp.start_date && exp.end_date && moment(exp.start_date).isValid() && moment(exp.end_date).isValid() && (
                      <div>
                        <span>
                          <i class="fas fa-calendar-week" style={{ color: "#9a9595" }}></i> {dateDifference(exp.start_date, exp.end_date)}
                          {/* {moment(exp.end_date).diff(moment(exp.start_date), "years", true)} */}
                          {/* {' '}days */}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* Job Description */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <div>
                      <span> {exp.job_description} </span>
                    </div>
                  </div>
                  {/* Job Skills */}
                  {uniqueArray(exp.skills).length > 0 && (
                    <div style={{ marginTop: 10 }}>
                      <Divider />
                      <p style={{ marginTop: 5, marginBottom: -2, fontWeight: "bold" }}>Skills:</p>
                      <span id="box_value">
                        {uniqueArray(exp.skills).length <= 5 ? (
                          uniqueArray(exp.skills).map((skill, key) => {
                            return (
                              <Chip
                                variant="outlined"
                                size="medium"
                                label={<b style={{ fontSize: "14px" }}>{skill.skill_name}</b>}
                                color="primary"
                                style={{ margin: "4px 8px 8px 0px", maxHeight: "25px" }}
                              />
                            );
                          })
                        ) : (
                          <>
                            {uniqueArray(exp.skills).map(
                              (skill, skill_key) =>
                                (skill_key < 5 || viewBtn) && (
                                  <Chip
                                    variant="outlined"
                                    size="medium"
                                    label={<b style={{ fontSize: "14px" }}>{skill.skill_name}</b>}
                                    color="primary"
                                    style={{ margin: "4px 8px 8px 0px", maxHeight: "25px" }}
                                  />
                                )
                            )}
                            <Button onClick={() => setViewBtn(!viewBtn)} style={{ marginLeft: "8px" }}>
                              {viewBtn ? "...View Less" : "...View More"}
                            </Button>
                          </>
                        )}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </span>
        ) : (
          <div id="no_content">
            <span>Add your previous Work Experiences !!</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default WorkExperience;

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Chip,
  createStyles,
  makeStyles,
  MuiThemeProvider,
  TextareaAutosize,
} from "@material-ui/core";
import next from "../../../../assets/next.png";
import cancel from "../../../../assets/cancel.png";
import skip from "../../../../assets/common/skip.png";

import Autocomplete from "@material-ui/lab/Autocomplete";
import theme from "../../../common/JobPostNew/FormComponents/MuiOutlined";
import CssTextField from "../../../common/JobPostNew/FormComponents/CssTextField";

export default function FormDialog(props) {
  const {
    open,
    handleClose,
    handleClickOpen,
    moveNextArray,
    feedbackList,
    currentFeedback,
    setCurrentFeedback,
    setFeedbackSentState,
    feedbackSentState,
    feedbackTyped,
    setFeedbackTyped,
    currentData,
    selectedApplicants,
    setSelectedApplicants,
    setMoveButtonClicked,
    showWholeApplicantList,
  } = props;
  const [currentSelectedNextStep, setCurrentSelectedNextStep] = useState({});
  const classes = useStyles();
  const [feedbackSelectedCheck, setFeedbackSelectedCheck] = useState(false);
  const [showSkip, setShowSkip] = useState(true);

  useEffect(() => {
    if (feedbackSelectedCheck) {
      if (!currentFeedback) setShowSkip(true);
      else if (currentFeedback?.feedback_id === "others" && feedbackTyped)
        setShowSkip(false);
      else if (currentFeedback?.feedback_id !== "others") setShowSkip(false);
      else setShowSkip(true);
    }
  }, [currentFeedback, feedbackSelectedCheck, feedbackTyped]);
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,
            // background:
            //   "linear-gradient(15deg, #d0d5dd, #d5d9e1, #d9dee5, #dee2e9, #e2e7ed, #e7ebf2, #ebf0f6, #f0f4fa)",
          },
        }}
        open={open}
        onClose={() => {
          handleClose();
          setCurrentSelectedNextStep();
          setFeedbackSelectedCheck(false);
          setFeedbackSentState();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Move to selected step</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            Select the state where you want to send the applicant(s).
          </DialogContentText>
          {showWholeApplicantList &&
            selectedApplicants &&
            selectedApplicants.length > 0 &&
            selectedApplicants.map((item) => (
              <Chip
                label={<span style={{ fontSize: 12 }}>{item.name}</span>}
                className={classes.root1}
                style={{ marginRight: "1%", marginBottom: "1%" }}
              />
            ))}
          <MuiThemeProvider theme={theme}>
            <Autocomplete
              value={currentSelectedNextStep}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCurrentSelectedNextStep(newValue);
                  setFeedbackSentState(newValue);
                } else {
                  setCurrentSelectedNextStep();
                  setFeedbackSentState();
                }
              }}
              id="rolenew"
              options={moveNextArray}
              getOptionLabel={(option) => option.name}
              style={{
                width: "100%",
                background: "white",
                borderRadius: 30,
                marginTop: 13,
              }}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
              }}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label={<span style={{ fontSize: 13 }}>Select Next Step</span>}
                  variant="outlined"
                />
              )}
            />
          </MuiThemeProvider>
          {feedbackSentState !== null && feedbackSentState !== undefined && (
            <>
              <p
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  marginTop: 30,
                  textAlign: "center",
                }}
              >
                Do You want to Enter the feedback for Selected Applicant(s)?{" "}
              </p>
              {!feedbackSelectedCheck ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  style={{ marginTop: 20 }}
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    className={classes.mainButton}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      if (!showWholeApplicantList)
                        setSelectedApplicants([
                          {
                            id: currentData.applicant_id,
                            name:
                              currentData.user_details.first_name +
                              " " +
                              currentData.user_details.last_name,
                            user_id: currentData.user_details.user_id,
                            first_name: currentData.user_details.first_name,
                            last_name: currentData.user_details.last_name,
                            job_id: currentData.job_id,
                          },
                        ]);
                      setMoveButtonClicked(true);
                      setFeedbackSelectedCheck(false);

                      handleClose();
                    }}
                  >
                    <span style={{ fontSize: 13 }}>
                      No & Move to Selected State
                    </span>
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.mainButton}
                    onClick={() => setFeedbackSelectedCheck(true)}
                  >
                    <span style={{ fontSize: 13 }}>Yes</span>
                  </Button>
                </Box>
              ) : (
                <>
                  <MuiThemeProvider theme={theme} style={{ marginTop: 20 }}>
                    <Autocomplete
                      value={currentFeedback}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setCurrentFeedback(newValue);
                        } else {
                          setCurrentFeedback();
                        }
                      }}
                      id="rolenew"
                      options={feedbackList}
                      getOptionLabel={(option) => option.feedback_name}
                      style={{
                        width: "100%",
                        background: "white",
                        borderRadius: 30,
                      }}
                      renderOption={(option) => {
                        return (
                          <span
                            style={{ fontSize: 13 }}
                          >{`${option.feedback_name}`}</span>
                        );
                      }}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          label={
                            <span style={{ fontSize: 13 }}>Select Reason</span>
                          }
                          variant="outlined"
                        />
                      )}
                    />
                  </MuiThemeProvider>

                  <br />
                  {currentFeedback?.feedback_id === "others" && (
                    <TextareaAutosize
                      rowsMin={3}
                      rowsMax={5}
                      style={{
                        width: "100%",
                        borderRadius: "30px",
                        borderColor: "#d2cdcd",
                        padding: 12,
                        outline: "none",
                      }}
                      aria-label="maximum height"
                      placeholder="Enter your Feedback"
                      onChange={(e) => {
                        setFeedbackTyped(e.target.value);
                      }}
                      value={feedbackTyped}
                    />
                  )}
                </>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          {currentFeedback &&
            currentFeedback?.feedback_id === "others" &&
            feedbackTyped && (
              <Button
                variant="contained"
                className={classes.nextButton}
                startIcon={<img src={next} alt="" height="20px" />}
                onClick={() => {
                  if (!showWholeApplicantList)
                    setSelectedApplicants([
                      {
                        id: currentData.applicant_id,
                        name:
                          currentData.user_details.first_name +
                          " " +
                          currentData.user_details.last_name,
                        user_id: currentData.user_details.user_id,
                        first_name: currentData.user_details.first_name,
                        last_name: currentData.user_details.last_name,
                        job_id: currentData.job_id,
                      },
                    ]);
                  setMoveButtonClicked(true);
                  handleClose();
                  setFeedbackSelectedCheck(false);
                }}
              >
                <span style={{ fontSize: 13 }}>Move to Selected Step</span>
              </Button>
            )}

          {currentFeedback && currentFeedback?.feedback_id !== "others" && (
            <Button
              variant="contained"
              className={classes.nextButton}
              startIcon={<img src={next} alt="" height="20px" />}
              onClick={() => {
                if (!showWholeApplicantList)
                  setSelectedApplicants([
                    {
                      id: currentData.applicant_id,
                      name:
                        currentData.user_details.first_name +
                        " " +
                        currentData.user_details.last_name,
                      user_id: currentData.user_details.user_id,
                      first_name: currentData.user_details.first_name,
                      last_name: currentData.user_details.last_name,
                      job_id: currentData.job_id,
                    },
                  ]);
                setMoveButtonClicked(true);
                setFeedbackSelectedCheck(false);

                handleClose();
              }}
            >
              <span style={{ fontSize: 13 }}>Move to Selected Step</span>
            </Button>
          )}

          {showSkip && feedbackSelectedCheck && (
            <Button
              variant="contained"
              className={classes.skipButton}
              startIcon={<img src={skip} alt="" height="20px" />}
              onClick={() => {
                setFeedbackSelectedCheck(false);
                setCurrentFeedback();
                setFeedbackTyped();

                // handleClose();
              }}
            >
              <span style={{ fontSize: 13 }}>Skip</span>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    mainButton: {
      // height: "36px",
      borderRadius: 30,

      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      "&:hover": {
        backgroundColor: "#84cbff",
      },
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      fontSize: 13,
    },

    nextButton: {
      backgroundColor: "#76e5ac",
      border: "1px solid #145f38",
      height: "36px",
      borderRadius: 30,
      color: "#145f38",
      "&:hover": {
        backgroundColor: "#5dd698",
      },
    },

    skipButton: {
      backgroundColor: "#bde3ff",
      border: "1px solid #082c5a",
      height: "36px",
      borderRadius: 30,
      color: "#082c5a",
      "&:hover": {
        backgroundColor: "#84cbff",
      },
    },
  })
);

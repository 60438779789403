import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Backdrop, CircularProgress, Hidden, Paper, Typography } from "@material-ui/core";
import Header from "../../../components/public/JobDetails/Header";
import Description from "../../../components/public/JobDetails/Description";
import MobileView from "../../../components/public/JobDetails/MobileView";
import { useAlert } from "react-alert";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Navbar from "../../../components/public/Navbar/Navbar";
import PassCode from "./Passcode";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import { checkLinkingHomePage } from "../../../utils/utility";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import { useSelector } from "react-redux";
import Oncampus from "./oncampus";
const useStyles = makeStyles((theme) => ({
  jobGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  job: {
    height: "100%",
    borderRadius: "20px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default function Album() {
  const classes = useStyles();
  const alert = useAlert();
  const [jobData, setJobData] = useState();
  const history = useHistory();
  const { job_id, action } = useParams();
  const location = useLocation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isUserApplied, setIsUserApplied] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [passCode, setPassCode] = useState();
  const [jobId, setJobId] = useState();
  useEffect(() => {
    getJobData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [notAuthorized, setNotAuthorized] = useState(false);

  const getJobData = () => {
    const jobID = parseInt(job_id.split("-")[0]);
    setJobId(jobID);
    if (jobID) {
      let jobUrl = BackendBaseApi.NIYUKTI + `job/?job_id=${btoa(jobID.toString())}${checkPassCode()}`;
      setLoader(true);
      Axios.get(jobUrl, {
        headers: {
          // ...(localStorage.getItem("gw_token") && {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
          // }),
        },
      })
        .then((res) => {
          if (res.data.success) {
            let detail = res.data.data;
            setLoader(false);
            if (!detail.hidden_data) {
              var object = {};
              if (detail.hiring_type === "OFF CAMPUS") {
                detail.eligibility_criteria.degrees.map((item) => {
                  var arr = [];

                  detail.eligibility_criteria.courses.map((obj) => {
                    if (obj.degree_id === item.id) arr.push(obj);
                  });

                  if (item.all_degree_selected && item.all_spec_selected) object["Open to All"] = {};
                  else {
                    if (item.all_spec_selected) object[item.name] = [{ name: "All Courses" }];
                    else object[item.name] = arr;
                  }
                });
              } else if (detail.hiring_type === "ON CAMPUS" && (localStorage.getItem("user_type") === "College" || localStorage.getItem("user_type") === "Student")) {
                if (detail.college.length > 0) {
                  var checkSelectAllArray = detail.college.map((item) => item.preferences.every((e) => e.degree_selected || e.select_all));
                  var checkSelectAll = checkSelectAllArray.every((e) => e === true);
                  if (checkSelectAll) object["Open to All"] = {};
                  else {
                    detail.college.map((item) =>
                      item.preferences.map((item2) => {
                        object[item2.degree_name] = checkValue(item2);
                      })
                    );
                  }
                }
              } else if (detail.hiring_type === "ON CAMPUS" && localStorage.getItem("user_type") === "Company") {
                detail.college.map((item) => {
                  object[item.name + ", " + item.location] = checkIfAllSelected(item);
                });
              }
              detail.eligibility_criteria["qualifications"] = object;
            }
            setJobData(detail);

            setIsUserApplied(detail.is_user_applied);

            if (passCode) handleClose();
            // setCollegeJobUrl(detail.college_job_url);
          } else {
            setLoader(false);

            alert.error(res.data.error);
            if (res.data.error === "No Job Found") history.push("/public/not-found");
            if (res.data.error === "Not Authorized") setNotAuthorized(true);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          alert.error("Error");
        });
    } else {
      alert.error("Job ID not provided !!");
    }
  };

  const checkValue = (item) => {
    var boole = item.specialization.every((e) => e.selected || e.course_selected);
    var obj;
    if (boole) {
      obj = [{ name: "All Courses" }];
    } else {
      obj = item.specialization;
    }
    return obj;
  };

  const checkIfAllSelected = (item) => {
    var boole = item.preferences.every((e) => e.select_all || e.degree_selected);
    item.preferences.forEach((item2) => {
      item2["name"] = item2.degree_name;
    });
    var obj;
    if (boole) {
      obj = [{ name: "Open to All Degrees" }];
    } else {
      obj = item.preferences;
    }
    return obj;
  };

  useEffect(() => {
    if (localStorage.getItem("job-detail-state")) {
      setSubmitLoading(true);

      setApplyClicked(true);
    }
  }, []);

  useEffect(() => {
    if (applyClicked) {
      applyJob();
    }
  }, [applyClicked]);

  const applyJob = () => {
    //make a post call to send new skills
    setSubmitLoading(true);

    Axios.post(
      BackendBaseApi.NIYUKTI + "job/student/apply/",
      {
        job_id: parseInt(job_id.split("-")[0]),
        user_id: localStorage.getItem("user_id"),
        round: 1,
        status: 1,
        feedback: 1,
        college_id: 0,
        profile_complete_percentage: localStorage.getItem("profile_complete"),
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("Job Applied successfully!");
          setSubmitLoading(false);
          setIsUserApplied(true);
          setApplyClicked(false);
        } else {
          alert.error(res.data.error);
          setSubmitLoading(false);
          setApplyClicked(false);
        }
      })
      .catch((err) => {
        alert.error("Some Error");
        setSubmitLoading(false);
        setApplyClicked(false);
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (jobData?.hiring_type === "ON CAMPUS" && jobData?.hidden_data && (!localStorage.getItem("user_type") || localStorage.getItem("user_type") === "Student")) setOpen(true);
  }, [jobData]);

  const checkPassCode = () => {
    if (passCode) return `&passcode=${passCode}`;
    else return ``;
  };
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  const [loaderNav, setLoaderNav] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setLoaderNav(false);
    }, 1000);
  }, []);

  return (
    <>
      <main>
        {submitLoading && (
          <Backdrop className={classes.backdrop} open={submitLoading}>
            <CircularProgress size={30} style={{ color: "#fff" }} />
          </Backdrop>
        )}
        {localStorage.getItem("gw_token") && localStorage.getItem("user_type") !== "Student" && (
          <PassCode
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            getJobData={getJobData}
            passCode={passCode}
            setPassCode={setPassCode}
            jobId={jobId}
            loader={loader}
            jobData={jobData}
          />
        )}
        <>
          <Hidden smDown>
            {loaderNav ? <></> : domain?.domain ? <DomainNavbar /> : <Navbar />}

            {loader ? (
              <Container
                className={classes.jobGrid}
                maxWidth="md"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 170,
                }}
              >
                <CircularProgress size={50} style={{ color: "#007bff", alignItems: "center", justifyContent: "center", display: "flex" }} />
              </Container>
            ) : (
              <>
                <>
                  {!notAuthorized ? (
                    <Container className={classes.jobGrid} maxWidth="md">
                      {/* {(passCode || (jobData?.hiring_type !== "ON CAMPUS" && !localStorage.getItem("gw_token"))) && ( */}
                      {jobData?.hidden_data ? (
                        <Oncampus />
                      ) : (
                        <Paper className={classes.job}>
                          <Header data={jobData} />
                          <Description data={jobData} isUserApplied={isUserApplied} setIsUserApplied={setIsUserApplied} setApplyClicked={setApplyClicked} />
                        </Paper>
                      )}
                    </Container>
                  ) : (
                    <>
                      {localStorage?.getItem("gw_token") ? (
                        <Container className={classes.jobGrid} maxWidth="md">
                          <Paper className={classes.job}>
                            <Typography variant="body1" style={{ textAlign: "center" }}>
                              {" "}
                              You are not authorized to view this job
                            </Typography>
                            <br />

                            <CommonButton
                              style={{ width: "20%", margin: "auto" }}
                              onClick={() => {
                                var link = checkLinkingHomePage(localStorage.getItem("user_type"));
                                history.push(link);
                              }}
                            >
                              Home
                            </CommonButton>
                          </Paper>
                        </Container>
                      ) : (
                        <Oncampus />
                      )}
                    </>
                  )}
                </>
              </>
            )}
          </Hidden>
          <Hidden smUp>
            <MobileView
              data={jobData}
              isUserApplied={isUserApplied}
              setIsUserApplied={setIsUserApplied}
              setApplyClicked={setApplyClicked}
              notAuthorized={notAuthorized}
              loader={loader}
            />
          </Hidden>
        </>
      </main>
    </>
  );
}

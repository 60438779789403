import React from "react";
import Layout from "../components/Layout/Layout";
import TrackEmployee from "./TrackEmployee";

function ManageJobApplicants() {
  return (
    <>
      <div>
        {!localStorage.getItem("gw_token") ? (
          <Layout>
            <TrackEmployee />
          </Layout>
        ) : (
          <div style={{ margin: 0 }}>
            <TrackEmployee />
          </div>
        )}
      </div>
    </>
  );
}

export default ManageJobApplicants;

import { Box, Container, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Footer from "../../../components/public/FooterNew/Footer";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";

function SignupLayout({ children, step }) {
  const domain = useSelector(
    (state) => state?.SubDomainDetails?.subDomainDetails
  );
  const theme = useTheme();
  return (
    <>
      {domain?.domain ? <DomainNavbar /> : <Navbar />}
      <Box
        sx={{
          backgroundColor: `${step === 1 ? "#EFF8FF" : "#fff"}`,
          // paddingTop: "40px",
          // marginTop: "4.5%",
          marginTop: "4%",
          paddingTop: "40px",
          paddingBottom: "20px",
          [theme.breakpoints.down("sm")]: {
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
            backgroundColor: "#fff !important",
          },
        }}
      >
        <Container maxWidth="lg">{children}</Container>
      </Box>
      <Footer />
    </>
  );
}

export default SignupLayout;

import React from "react";
import Layout from "../components/Layout/Layout";
import EmployeeDetails from "./EmployeeDetails";
import JobTrack from "./JobTrack";

function JobTrackIndex() {
  return (
    <>
      <div>
        {!localStorage.getItem("gw_token") ? (
          <Layout>
            <JobTrack />
          </Layout>
        ) : (
          <div style={{}}>
            <JobTrack />
          </div>
        )}
      </div>
    </>
  );
}

export default JobTrackIndex;

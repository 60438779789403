async function  httpRequest(baseUrl,endPoint,body){

    let result=null;

    if(body===null){
     

       result=await  fetch(`${baseUrl}${endPoint}`).then((res)=>res.json())
        
       return result
       
    }
    else{

        if(body.headers){

            result=await  fetch(`${baseUrl}${endPoint}`,{
                headers: {
                    'Authorization': `Token ${body.headers}`
                  }
            }).then((res)=>res.json())
        }
        
       return result
    }



        

}

async function  httpRequestGet(baseUrl,endPoint,headers){

    let result=null;

  
     

       result=await  fetch(`${baseUrl}${endPoint}`,{
        headers: {
            'Authorization': `Token ${headers.headers}`
          }
       }).then((res)=>res.json())
        
       return result
       
    
  



        

}

async function httpRequestPost(baseUrl,endPoint,headers,body){
   
    let result=null;

  
     

       result=await  fetch(`${baseUrl}${endPoint}`,{
        headers: {
            'Authorization': `Token ${headers.headers}`
          }
       }).then((res)=>res.json())
        
       return result
}

export  {httpRequest,httpRequestGet,httpRequestPost};
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import Skeleton from "react-loading-skeleton";
import { BackendBaseApi } from "../../../constants/constants";
import EducationForm from "./Forms/EducationForm";

const StudentProfileEducation = () => {
  const disableCurrentEducationButton = useRef();
  const alert = useAlert();
  const [showEducationForm, setShowEducationForm] = useState(false);
  const [currentEducationDetails, setCurrentEducationDetails] = useState({});
  const [newEducationDetails, setNewEducationDetails] = useState({});
  const [allEducationDetails, setAllEducationDetails] = useState([]);
  const [currentCollege, setCurrentCollege] = useState();
  const [colleges, setColleges] = useState({});
  const [currentDegree, setCurrentDegree] = useState();
  const [degrees, setDegrees] = useState({});
  const [currentSpecialization, setCurrentSpecialization] = useState();
  const [specializations, setSpecializations] = useState([]);
  const years = [
    { name: "1st Year", value: 1 },
    { name: "2nd Year", value: 2 },
    { name: "3rd Year", value: 3 },
    { name: "4th Year", value: 4 },
    { name: "5th Year", value: 5 },
    { name: "6th Year", value: 6 },
  ];

  const graduation = [
    { name: "Graduation (UG)", value: 3 },
    { name: "Post Graduation (PG)", value: 4 },
    { name: "PhD", value: 5 },
    { name: "Diploma", value: 6 },
  ];

  const [showField, setShowField] = useState({
    Percentage: false,
    Start_Date: false,
    End_Date: false,
    currentYear: false,
    collegeName: false,
    highestQualification: false,
    Degree: false,
    Specialization: false,
  });
  const addEducation = "ADD_EDUCATION";
  const openEducationForm = () => {
    setShowEducationForm(true);
  };

  const updateCurrentEducation = () => {
    if (
      !currentEducationDetails.percentage &&
      !currentEducationDetails.start_date &&
      !currentEducationDetails.end_date &&
      !currentEducationDetails.current_year
    ) {
      alert.error("Please fill any detail first");
      return;
    }

    if (currentEducationDetails.percentage <= 100) {
      Axios.patch(
        BackendBaseApi.PRAVESH +
          `api/education/user_education/${currentEducationDetails.education_id}/`,
        {
          percentage: currentEducationDetails.percentage
            ? currentEducationDetails.percentage
            : null,
          start_date: currentEducationDetails.start_date
            ? currentEducationDetails.start_date
            : null,
          end_date: currentEducationDetails.end_date
            ? currentEducationDetails.end_date
            : null,
          current_year: currentEducationDetails.current_year
            ? currentEducationDetails.current_year
            : null,
          college_name: currentCollege ? currentCollege : null,
          college: currentCollege ? colleges[currentCollege] : null,
          education: currentEducationDetails.education
            ? currentEducationDetails.education
            : null,
          highest_qualification: currentEducationDetails.highest_qualification
            ? currentEducationDetails.highest_qualification
            : null,
          degree: currentEducationDetails.degree_id
            ? currentEducationDetails.degree_id
            : null,
          specialization: currentEducationDetails.specialization_id
            ? currentEducationDetails.specialization_id
            : null,
        },
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("gw_token"),
          },
        }
      )
        .then((res) => {
          if (res.data.success) {
            alert.success(res.data.data.message);
            setShowField({
              Percentage: currentEducationDetails.percentage ? false : true,
              Start_Date: currentEducationDetails.start_date ? false : true,
              End_Date: currentEducationDetails.end_date ? false : true,
              currentYear: currentEducationDetails.current_year ? false : true,
              highestQualification:
                currentEducationDetails.highest_qualification ? false : true,
              Degree: currentEducationDetails.degree ? false : true,
              Specialization: currentEducationDetails.specialization
                ? false
                : true,
            });
          } else alert.error(res.data.error);
        })
        .catch((err) => {
          alert.error(
            "There was some error updating your details. Try again later"
          );
        });
    } else {
      alert.error("Enter Valid Percentage");
    }
  };

  const handleCurrentYearChange = (e) => {
    setCurrentEducationDetails({
      ...currentEducationDetails,
      current_year: e.target.value,
    });
  };

  const handleCurrentQualificationChange = (e) => {
    var qs;
    if (e.target.value === 3) qs = "Graduation (UG)";
    else if (e.target.value === 4) qs = "Post Graduation (PG)";
    else if (e.target.value === 5) qs = "PhD";
    else qs = "Diploma";
    setCurrentEducationDetails({
      ...currentEducationDetails,
      highest_qualification: qs,
      education: e.target.value,
    });
  };
  const handleCurrentCollegeChange = (e) => {
    setCurrentEducationDetails({
      ...currentEducationDetails,
      college_name: e,
      college: colleges[e],
    });
  };

  const handleCurrentDegreeChange = (e) => {
    setCurrentEducationDetails({
      ...currentEducationDetails,
      degree: e,
      degree_id: degrees[e],
    });
  };

  const handleCurrentSpecializationChange = (e) => {
    setCurrentEducationDetails({
      ...currentEducationDetails,
      specialization: e.specialization_name,
      specialization_id: e.specialization_id,
    });
  };

  const handleCurrentEducationChange = (e) => {
    setCurrentEducationDetails({
      ...currentEducationDetails,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (currentEducationDetails.percentage > 100) {
      let sampleEd = { ...currentEducationDetails };
      setCurrentEducationDetails({
        ...sampleEd,
        percentage: 100,
      });
      alert.error("Percentage can not be more than 100");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEducationDetails.percentage]);

  const SaveNewEducation = async () => {
    let result = await Axios.post(
      BackendBaseApi.PRAVESH + `api/education/user_education`,
      {
        college:
          typeof newEducationDetails.college == "string"
            ? null
            : newEducationDetails.college,
        college_name:
          typeof newEducationDetails.college == "number"
            ? null
            : newEducationDetails.college,
        education: newEducationDetails.education,
        percentage: newEducationDetails.percentage,
        board: newEducationDetails.board,
        end_date: newEducationDetails.end_date,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          //setNewEducationDetails({...newEducationDetails,education_id:res.data.data.education_id})
          alert.success(res.data.data.message);
          setAllEducationDetails([...allEducationDetails, res.data.data.data]);
          setNewEducationDetails({});
          setShowEducationForm(false);
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error(
          "There was some error updating your details. Try again later"
        );
      });
  };

  const AddNewEducationDetails = () => {
    let temp = [...allEducationDetails];

    temp.push(newEducationDetails);

    let result = SaveNewEducation();
    // result.then(()=>{
    //       setAllEducationDetails([...allEducationDetails,newEducationDetails])
    // })

    //setAllEducationDetails(temp);
  };

  const appendToParentState = () => {};

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/user_education", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          let temp = [];
          res.data.data.forEach((data, ind) => {
            if (ind == 0) {
              setCurrentEducationDetails(data);
              setCurrentCollege(data.college_name);
              setShowField({
                ...showField,
                Percentage: data.percentage ? false : true,
                Start_Date: data.start_date ? false : true,
                End_Date: data.end_date ? false : true,
                collegeName: data.college_name ? false : true,
              });
            } else {
              temp.push(data);
            }
          });
          setAllEducationDetails(temp);
        } else alert.error();
      })
      .catch((err) => {
        console.log(err);
      });

    getColleges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentEducationDetails.education !== undefined) getDegrees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEducationDetails.education]);

  useEffect(() => {
    if (currentEducationDetails.degree_id !== undefined) getSpecialization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEducationDetails]);

  const getColleges = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/college/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        let temp = {};
        res.data.forEach((c) => {
          temp[c.name] = c.id;
        });
        setColleges({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDegrees = () => {
    Axios.get(
      `${BackendBaseApi.PRAVESH}api/education/degree/?search=${currentEducationDetails.education}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        let temp = {};
        res.data.forEach((c) => {
          temp[c.name] = c.id;
        });
        setDegrees({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpecialization = () => {
    Axios.get(
      `${BackendBaseApi.PRAVESH}api/education/get_specialization?degree_id=${currentEducationDetails.degree_id}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if(res.data.success)
          setSpecializations(res.data.data);
        else
          setSpecializations([])
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let isValid = false;
    if (
      currentEducationDetails.percentage ||
      currentEducationDetails.start_date ||
      currentEducationDetails.end_date ||
      currentEducationDetails.current_year
    )
      isValid = true;
    if (isValid)
      disableCurrentEducationButton.current.classList.remove("disabled");
    else disableCurrentEducationButton.current.classList.add("disabled");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEducationDetails]);

  useEffect(() => {
    if (!showField.Percentage && !showField.Start_Date && !showField.End_Date)
      disableCurrentEducationButton.current.classList.add("disabled");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showField]);

  const college_list = Object.keys(colleges).map((C) => {
    return { name: C, value: C };
  });
  const CollegeList = () => (
    <Autocomplete
      value={currentCollege}
      onChange={(event, newValue) => {
        setCurrentCollege(newValue);
        handleCurrentCollegeChange(newValue);
      }}
      id="controllable-states-demo"
      options={college_list.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Choose College" variant="outlined" />
      )}
    />
  );

  const degree_list = Object.keys(degrees).map((C) => {
    return { name: C, value: C };
  });
  const DegreeList = () => (
    <Autocomplete
      value={currentDegree}
      onChange={(event, newValue) => {
        setCurrentDegree(newValue);
        handleCurrentDegreeChange(newValue);
      }}
      id="controllable-states-demo"
      options={degree_list.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Choose Degree" variant="outlined" />
      )}
    />
  );

  const specialization_list = Object.keys(specializations).map((C) => {
    return { name: C, value: C };
  });
  const SpecializationList = () => (
    <Autocomplete
      value={currentSpecialization}
      onChange={(event, newValue) => {
        setCurrentSpecialization(newValue);
        handleCurrentSpecializationChange(newValue);
      }}
      id="controllable-states-demo"
      options={specializations}
      getOptionLabel={(option) => option.specialization_name}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose Specialization"
          variant="outlined"
        />
      )}
    />
  );
  return (
    <>
      <div className="row my-3 justify-content-center">
        <Tooltip title="Add Past Education" placement="left">
          <button onClick={openEducationForm} className="add ripple">
            <span>+</span>
          </button>
        </Tooltip>
      </div>

      <div className="my-4">
        {showEducationForm && (
          <div className="card job-card-main">
            <div
              className="card-body"
              style={{ boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)" }}
            >
              <EducationForm
                state={newEducationDetails}
                setState={setNewEducationDetails}
                appendToParentState={AddNewEducationDetails}
              />
            </div>
          </div>
        )}

        <div className="card job-card-main">
          <div
            className="card-body"
            style={{ boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)" }}
          >
            <div className="row my-2">
              <div className="col-md-6 col-sm-12 text-left">
                <p
                  className="fs-12"
                  style={{ color: "#000000", fontWeight: "bold" }}
                >
                  Current Education Details
                </p>
              </div>
              {/* <div className="col-md-6 col-sm-12 text-right">
                <ifa-info-circle
                  className="fas  mt-1 pt-1 text-muted "
                  style={{ marginLeft: "-25px", fontSize: "12px" }}
                />
              </div> */}
            </div>

            <div className="row my-3">
              <div className="col-md-4 col-sm-12 text-left">
                <label htmlFor="college" className="fs-12 fw-500 mb-1 my-auto">
                  College
                </label>
              </div>
              {showField.collegeName ? (
                <div className="col-md-8 col-sm-12 text-left my-auto">
                  <CollegeList />
                </div>
              ) : (
                <div className="col-md-8 col-sm-12 text-left">
                  <div className="row">
                    <div className="col-10">
                      <span className="fs-13">
                        {currentEducationDetails.college_name || (
                          <Skeleton width={100} />
                        )}
                      </span>
                    </div>
                    <div className="col-2">
                      <i
                        onClick={() => {
                          setShowField({ ...showField, collegeName: true });
                        }}
                        className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row my-3">
              <div className="col-md-4 col-sm-12 text-left">
                <label
                  htmlFor="qualification"
                  className="fs-12 fw-500 mb-1 my-auto"
                >
                  Highest Qualification
                </label>
              </div>

              {showField.highestQualification ? (
                <div className="col-md-8 col-sm-12 text-left my-auto">
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Highest Qualification
                    </InputLabel>
                    <Select
                      onChange={(e) => handleCurrentQualificationChange(e)}
                      label="Year"
                      id="current_year"
                      aria-describedby="emailHelp"
                      style={{ width: "200%" }}
                    >
                      {graduation.map((data, ind) => {
                        return (
                          <MenuItem key={ind} value={data.value}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <div className="col-md-8 col-sm-12 text-left">
                  <div className="row">
                    <div className="col-10">
                      <span className="fs-13">
                        {currentEducationDetails.highest_qualification || (
                          <Skeleton width={100} />
                        )}
                      </span>
                    </div>
                    <div className="col-2">
                      <i
                        onClick={() => {
                          setShowField({
                            ...showField,
                            highestQualification: true,
                          });
                        }}
                        className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row my-3">
              <div className="col-md-4 col-sm-12 text-left">
                <label htmlFor="degree" className="fs-12 fw-500 mb-1 my-auto">
                  Recent/On-going Degree
                </label>
              </div>
              {/* <div className="col-md-8 col-sm-12 text-left">
                <span className="fs-13">
                  {currentEducationDetails.degree || <Skeleton width={100} />}
                </span>
              </div> */}
              {showField.Degree ? (
                <div className="col-md-8 col-sm-12 text-left my-auto">
                  <DegreeList />
                </div>
              ) : (
                <div className="col-md-8 col-sm-12 text-left">
                  <div className="row">
                    <div className="col-10">
                      <span className="fs-13">
                        {currentEducationDetails.degree || (
                          <Skeleton width={100} />
                        )}
                      </span>
                    </div>

                    <div className="col-2">
                      <i
                        onClick={() => {
                          setShowField({ ...showField, Degree: true });
                        }}
                        className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {currentEducationDetails.specialization && (
              <div className="row my-3">
                <div className="col-md-4 col-sm-12 text-left">
                  <label htmlFor="branch" className="fs-12 fw-500 mb-1 my-auto">
                    Branch
                  </label>
                </div>
                {/* <div className="col-md-8 col-sm-12 text-left">
                  <div className="row">
                    <div className="col">
                      <span className="fs-13">
                        {currentEducationDetails.specialization || (
                          <Skeleton width={100} />
                        )}
                      </span>
                    </div>
                  </div>
                </div> */}

                {showField.Specialization ? (
                  <div className="col-md-8 col-sm-12 text-left my-auto">
                    <SpecializationList />
                  </div>
                ) : (
                  <div className="col-md-8 col-sm-12 text-left">
                    <div className="row">
                      <div className="col-10">
                        <span className="fs-13">
                          {currentEducationDetails.specialization || (
                            <Skeleton width={100} />
                          )}
                        </span>
                      </div>

                      <div className="col-2">
                        <i
                          onClick={() => {
                            setShowField({
                              ...showField,
                              Specialization: true,
                            });
                          }}
                          className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="row my-3">
              <div className="col-md-4 col-sm-12 text-left">
                <label htmlFor="year" className="fs-12 fw-500 mb-1 my-auto">
                  Year
                </label>
              </div>
              {/* <div className="col-md-8 col-sm-12 text-left">
                <span className="fs-13">
                  {currentEducationDetails.current_year || (
                    <Skeleton width={100} />
                  )}
                </span>
              </div> */}

              {showField.currentYear ? (
                <div className="col-md-8 col-sm-12 text-left my-auto">
                  {/* <input
                    onChange={handleCurrentYearChange}
                    type="number"
                    class="form-control shadow_1-lightest profile-field w-80"
                    id="current_year"
                    aria-describedby="emailHelp"
                    placeholder=""
                    min={1}
                    max={6}
                  /> */}

                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Year
                    </InputLabel>
                    <Select
                      onChange={(e) => handleCurrentYearChange(e)}
                      label="Year"
                      id="current_year"
                      aria-describedby="emailHelp"
                      style={{ width: "200%" }}
                    >
                      {years.map((data, ind) => {
                        return (
                          <MenuItem key={ind} value={data.value}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <div className="col-md-8 col-sm-12 text-left my-auto">
                  <div className="row">
                    <div className="col-10">
                      <span className="fs-13">
                        {currentEducationDetails.current_year || (
                          <Skeleton width={100} />
                        )}
                      </span>
                    </div>
                    <div className="col-2">
                      <i
                        onClick={() => {
                          setShowField({ ...showField, currentYear: true });
                        }}
                        className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row my-3">
              <div className="col-md-4 col-sm-12 text-left">
                <label
                  htmlFor="percentage"
                  className="fs-12 fw-500 mb-1 my-auto"
                >
                  Current Percentage
                </label>
              </div>

              {showField.Percentage ? (
                <div className="col-md-8 col-sm-12 text-left my-auto">
                  <input
                    onChange={handleCurrentEducationChange}
                    value={currentEducationDetails.percentage}
                    type="number"
                    class="form-control shadow_1-lightest profile-field w-80"
                    id="percentage"
                    aria-describedby="emailHelp"
                    placeholder=""
                    max="100"
                    min="0"
                  />
                </div>
              ) : (
                <div className="col-md-8 col-sm-12 text-left my-auto">
                  <div className="row">
                    <div className="col-10">
                      <span className="fs-13">
                        {currentEducationDetails.percentage || (
                          <Skeleton width={100} />
                        )}
                      </span>
                    </div>
                    <div className="col-2">
                      <i
                        onClick={() => {
                          setShowField({ ...showField, Percentage: true });
                        }}
                        className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row my-3">
              <div className="col-md-4 col-sm-12 text-left">
                <label
                  htmlFor="start_date"
                  className="fs-12 fw-500 mb-1 my-auto"
                >
                  Course Start Date
                </label>
              </div>
              <div className="col-md-8 col-sm-12 text-left">
                <div className="form-group row text-left w-100">
                  <div className="row no-gutters w-80 ml-3">
                    {!showField.Start_Date ? (
                      <span className="fs-13">
                        {currentEducationDetails.start_date ? (
                          moment(currentEducationDetails.start_date).format(
                            "LL"
                          )
                        ) : (
                          <Skeleton width={100} />
                        )}
                      </span>
                    ) : (
                      <div className="col">
                        <input
                          type="date"
                          onChange={handleCurrentEducationChange}
                          class="form-control shadow_1-lightest profile-field "
                          id="start_date"
                          aria-describedby="emailHelp"
                          placeholder=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md-4 col-sm-12 text-left">
                <label htmlFor="end_date" className="fs-12 fw-500 mb-1 my-auto">
                  Course End Date
                </label>
              </div>
              <div className="col-md-8 col-sm-12 text-left">
                <div className="form-group row text-left w-100">
                  <div className="row no-gutters w-80 ml-3">
                    {!showField.End_Date ? (
                      <span className="fs-13">
                        {currentEducationDetails.end_date ? (
                          moment(currentEducationDetails.end_date).format("LL")
                        ) : (
                          <Skeleton width={100} />
                        )}
                      </span>
                    ) : (
                      <div className="col">
                        <input
                          type="date"
                          onChange={handleCurrentEducationChange}
                          class="form-control shadow_1-lightest profile-field "
                          id="end_date"
                          aria-describedby="emailHelp"
                          placeholder=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 pt-2 justify-content-center">
              <button
                ref={disableCurrentEducationButton}
                onClick={updateCurrentEducation}
                className="btn btn-main btn-sm shadow-main"
              >
                Update Details
              </button>
            </div>
          </div>
        </div>

        {allEducationDetails &&
          allEducationDetails.map((data, ind) => {
            return (
              <div className="card job-card-main" key={ind}>
                <div
                  className="card-body"
                  style={{ boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)" }}
                >
                  <div className="row my-2">
                    <div className="col-md-6 col-sm-12 text-left">
                      <p
                        className="fs-12 "
                        style={{ color: "#000000", fontWeight: "bold" }}
                      >
                        Past Education Details
                      </p>
                    </div>
                    {/* <div className="col-md-6 col-sm-12 text-right">
                      <i
                        className="fas fa-info-circle mt-1 pt-1 text-muted "
                        style={{ marginLeft: "-25px", fontSize: "12px" }}
                      />
                    </div> */}
                  </div>

                  <div className="row my-3">
                    <div className="col-md-4 col-sm-12 text-left">
                      <label
                        htmlFor="college"
                        className="fs-12 fw-500 mb-1 my-auto"
                      >
                        Qualification
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-12 text-left">
                      <span className="fs-13">
                        {data.highest_qualification || <Skeleton width={100} />}
                      </span>
                    </div>
                  </div>
                  {data.education <= 2 ? (
                    <>
                      <div className="row my-3">
                        <div className="col-md-4 col-sm-12 text-left">
                          <label
                            htmlFor="college"
                            className="fs-12 fw-500 mb-1 my-auto"
                          >
                            Board
                          </label>
                        </div>
                        <div className="col-md-8 col-sm-12 text-left">
                          <span className="fs-13">
                            {data.board_name || <Skeleton width={100} />}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row my-3">
                      <div className="col-md-4 col-sm-12 text-left">
                        <label
                          htmlFor="college"
                          className="fs-12 fw-500 mb-1 my-auto"
                        >
                          College
                        </label>
                      </div>
                      <div className="col-md-8 col-sm-12 text-left">
                        <span className="fs-13">
                          {data.college_name || <Skeleton width={100} />}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="row my-3">
                    <div className="col-md-4 col-sm-12 text-left">
                      <label
                        htmlFor="college"
                        className="fs-12 fw-500 mb-1 my-auto"
                      >
                        Percentage
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-12 text-left">
                      <span className="fs-13">
                        {data.percentage || <Skeleton width={100} />}
                      </span>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col-md-4 col-sm-12 text-left">
                      <label
                        htmlFor="college"
                        className="fs-12 fw-500 mb-1 my-auto"
                      >
                        Year of Passing
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-12 text-left">
                      <span className="fs-13">
                        {data.end_date || <Skeleton width={100} />}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default StudentProfileEducation;

import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// import * from "../../assets/co"
import popup2 from "../../../assets/company_new_view.svg";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import { NEW_RECRUITER_ENDPOINT } from "../../../constants/constants";
import "./drop.css";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "20px",
    minHeight: "500px",
    maxWidth: "500px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "30px",
    margin: theme.spacing(2),
    boxShadow: "none",
    fontSize: "16px",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
}));

const CompanyNewViewPopUp = ({
  fromOverview = false,
  setShowTempPlanPopUp = () => {},
  tempPlanPopUp = false,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    
  };

  const company_details = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : null;
  
  return (
    <>
      {show && (
        <div>
          <Dialog
            PaperProps={{ classes: { root: classes.root1 } }}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <div
              style={{
                padding: "40px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 auto",
                width: "450px",
                textAlign: "center",
              }}
            >
              <img
                src={popup2}
                height="300px"
                style={{ marginBottom: "5px" }}
              />
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
              We have made some changes to bring an<br /> Enhanced Hiring Experience
              </Typography>
              {/* <Typography variant="body2" style={{ color: "#6C757D" }}>
              We have been working endlessly to bring the best to you.
              </Typography> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <CommonButton onClick={(e) =>{
                    window.open(company_details ? company_details?.company_domain : NEW_RECRUITER_ENDPOINT, "_self")
                }}>
                    Switch to New Experience!!
                </CommonButton>
            </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default CompanyNewViewPopUp;

import { Laptop, VerifiedUserSharp } from "@material-ui/icons";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";

import {
  default as Description,
  default as DescriptionIcon,
} from "@material-ui/icons/Description";
import ForumIcon from "@material-ui/icons/Forum";
import GroupIcon from "@material-ui/icons/Group";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PieChartIcon from "@material-ui/icons/PieChart";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import SettingsIcon from "@material-ui/icons/Settings";
import WorkIcon from "@material-ui/icons/Work";
import SearchIcon from "@material-ui/icons/Search";
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
// import Subscription from "../../assets/svg/subscription.svg";
import React from "react";

export const IconSelector = (icon_key) => {
  switch (icon_key) {
    case "overview":
      return <DashboardIcon />;
    case "search_college":
      return <SearchIcon />;
    case "jobs":
      return <WorkIcon />;
    case "interviews":
      return <RecordVoiceOverIcon />;
    case "assignments":
      return <AssignmentOutlinedIcon />;
    case "college-connection":
      return <BusinessIcon />;
    case "settings":
      return <SettingsIcon />;

    case "manage_students":
      return <GroupIcon />;

    case "manage_links":
      return <DescriptionIcon />;
    case "chat_room":
      return <ForumIcon />;
    case "report":
      return <PieChartIcon />;

    case "Settings":
      return <VerifiedUserSharp color="primary" style={{ fontSize: "80px" }} />;

    case "dropdown_closed":
      return <KeyboardArrowDownIcon />;
    case "dropdown_open":
      return <KeyboardArrowUpIcon />;
    case "mysubscription":
        return <ReceiptLongOutlinedIcon />;

    default:
      return <KeyboardArrowUpIcon />;
  }
};

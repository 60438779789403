import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { fade, ThemeProvider, withStyles, makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Button,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  InputLabel,
  Typography,
  OutlinedInput,
  Paper,
  Grid,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { display } from "@material-ui/system";
import Logo from "../../../../../assets/images/sidepanel/logo.png";
import Facebook from "../../../../../assets/facebook.png";
import Google from "../../../../../assets/google-icon.png";
import { emailRegex, FACEBOOK_APP_ID, NEXT_APP_ENDPOINT } from "../../../../../constants/constants";
import { Link, useHistory } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  disabledButton: {
    backgroundColor: theme.palette.domain.main,
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
    "&:disabled": {
      backgroundColor: theme.palette.domain.light,
    },
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "auto",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);
export default function ForgotPasswordForm(props) {
  const { loading, resetEmail, setResetEmail, handleSubmit, passwordResetStep, setPasswordResetStep, buttonClicked, setButtonClicked } = props;
  const classes = useStyles();

  const history = useHistory();

  const [emailError, setEmailError] = useState("");

  const [validate, setValidate] = useState(false);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  const validateData = () => {
    var pass_error = "";

    setValidate(false);
    if (resetEmail === "") {
      pass_error = "Please Enter Email";
      setValidate(true);
    }

    if (!emailRegex.test(resetEmail)) {
      pass_error = "Enter correct email address";

      setValidate(true);
    }

    setEmailError(pass_error);
    setButtonClicked(true);
  };

  useEffect(() => {
    if (!validate && buttonClicked && resetEmail !== "") {
      handleSubmit();
    }
  }, [buttonClicked]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.grid}>
            {!domain?.domain && <img src={Logo} height="70px" width="80px" />}
            <br />
            {passwordResetStep === 0 ? (
              <>
                <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
                  Enter your associated Email Address and we'll send you a link to <br /> reset your password
                </Typography>
                <br />
                <CssTextField
                  className={classes.margin}
                  label="Email"
                  variant="outlined"
                  id="custom-css-outlined-input"
                  error={emailError !== "" ? true : false}
                  helperText={<span className={emailError !== "" ? classes.errorPassword : classes.noErrorPassword}>{emailError}</span>}
                  value={resetEmail}
                  onChange={(e) => {
                    setResetEmail(e.target.value);
                  }}
                />
              </>
            ) : (
              <>
                <Typography variant="body1" style={{ fontFamily: " Nunito" }}>
                  <b>Password Reset Link sent! </b>{" "}
                </Typography>
                <br />
                <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
                  We've sent you a link to reset your password on <b>{resetEmail}</b>. Please follow the steps further to reset your password.{" "}
                </Typography>
              </>
            )}
          </Grid>

          {passwordResetStep !== 0 ? (
            <div className={classes.create}>
              <Typography variant="body2" style={{ fontFamily: " Nunito", textAlign: "center" }}>
                Didn't receive an email?{" "}
                <span
                  variant="body2"
                  className={classes.text}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPasswordResetStep(0);
                  }}
                >
                  {" "}
                  Click here
                </span>{" "}
                to send the link again.
              </Typography>
            </div>
          ) : (
            <Grid item md={12} xs={12}>
              <div className={classes.btndiv}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  disabled={!resetEmail}
                  onClick={() => {
                    validateData();
                  }}
                  classes={{ disabled: classes.disabledButton }}
                >
                  <Typography variant="body2" style={{ color: "#fff", fontFamily: " Nunito" }}>
                    {" "}
                    {/* Sign In{" "} */}
                    {loading ? <CircularProgress style={{ color: "#fff" }} size={18} /> : <b>Send</b>}
                  </Typography>
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
}

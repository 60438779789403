import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "reactstrap";
import axios from "axios";
import {
  EndPointPrefix,
  emailRegex,
  passwordRegex,
  BackendBaseApi,
} from "../../../../constants/constants";

const CompanyUserDetails = ({
  companyUserDetails,
  setCompanyUserDetails,
  step,
  setStep,
}) => {
  const disableButton = useRef();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [showPassword, setShowPassword] = useState(false);
  let passwordFieldType = showPassword ? "text" : "password";
  let tooltipText = showPassword ? "Hide Password" : "Show Password";

  const handleChange = (e) => {
    setCompanyUserDetails({
      ...companyUserDetails,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    // let {FirstName,LastName,Email,PhoneNo,Password}=companyUserDetails;
    // if(!FirstName || !LastName || !Email || !PhoneNo || !Password || !passwordRegex.test(Password) || PhoneNo.length!=10)
    // disableButton.current.classList.add('disabled');
    // else
    // disableButton.current.classList.remove('disabled');
  }, []);

  const createCompanyUserProfile = () => {
    axios
      .post(BackendBaseApi.PRAVESH + "api/profile/create/", {
        flag: true,
        email: companyUserDetails.Email,
        first_name: companyUserDetails.FirstName,
        last_name: companyUserDetails.LastName,
        password: companyUserDetails.Password,
      })
      .then((res) => {
        setCompanyUserDetails({
          ...companyUserDetails,
          CompanyUserID: res.data.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createCompanyUserProfile();
  };

  return (
    <>
      <div className="row pl-3">
        <h1 className="mb-4 login-heading text-left">
          Enter your Personal Details
        </h1>
      </div>

      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="my-md-0 my-2"
      >
        <div className="row">
          <div className="col-6">
            <div className="form-group text-left">
              <label htmlFor="FirstName" className="fw-500">
                First Name
              </label>
              <input
                type="text"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="FirstName"
                aria-describedby="emailHelp"
                placeholder=""
                required
                autoFocus
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group text-left">
              <label htmlFor="LastName" className="fw-500">
                Last Name
              </label>
              <input
                type="text"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="LastName"
                aria-describedby="emailHelp"
                placeholder=""
                required
                autoFocus
              />
            </div>
          </div>
        </div>
        <div className="form-group text-left">
          <label htmlFor="PhoneNo" className="fw-500">
            Phone No
          </label>
          <input
            type="number"
            className="form-control mt-2 mb-1 shadow_1-lightest"
            id="PhoneNo"
            aria-describedby="emailHelp"
            placeholder=""
            required
            autoFocus
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="Email" className="fw-500">
            Enter your Email address
          </label>
          <input
            type="email"
            className="form-control mt-2 mb-1 shadow_1-lightest"
            id="Email"
            aria-describedby="emailHelp"
            placeholder=""
            required
            autoFocus
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="Password" className="fw-500">
            Create your Password
          </label>
          <small id="emailHelp" className="form-text text-muted">
            Passworld must be atleast 8 characters long and contain at least one
            uppercase, one lowercase and one digit
          </small>
          <div className="row no-gutters">
            <div className="col">
              <input
                type={passwordFieldType}
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="Password"
                placeholder=""
                required
                autoFocus
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              />
            </div>
            <div className="col-auto">
              <i
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                id="toggleShowPassword"
                className="fas fa-eye show-password-icon"
              ></i>
            </div>
            <Tooltip
              className="fs-13"
              placement="right"
              isOpen={tooltipOpen}
              target="toggleShowPassword"
              toggle={toggle}
            >
              {tooltipText}
            </Tooltip>
          </div>
        </div>

        <div className="row my-1 pt-3">
          <div className="col-6 text-left">
            <button
              className=" shadow_1 btn btn-lg btn-main btn-login btn-next text-uppercase font-weight-bold mb-2"
              type="submit"
            >
              <span>
                Next <i class="fas fa-angle-double-right" />
              </span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CompanyUserDetails;

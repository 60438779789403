import { Button, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { ReactComponent as Error } from "../../../assets/svg/Error.svg";
import { ReactComponent as NewError } from "../../../assets/svg/NewError.svg";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  lay: {
    width: "100%",
    "@media only screen and (min-width: 2000px)": {
      height: "600px",
    },
    "@media only screen and (max-width: 2000px)": {
      height: "500px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "410px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "330px",
    },
  },

  root: {
    display: "flex",
    justifyContent: "center",
  },
  rowColor: {
    backgroundColor: "#e4f1ff",
    color: "black",
  },
  postJobBtn: {
    background: theme.palette.primary,
  },
}));

export default function CommonTable({
  columns,
  rowData,
  loading,
  selectedRow,
  setSelectedRow,
  applicantDataError,
  pageData,
  setPageData,
  totalDataCount,
  fromTrack,
  checkboxSelection = true,
  fromManageJobs = false,
}) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.lay}>
      <DataGrid
        style={{
          borderRadius: "20px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
        }}
        getRowClassName={(params) => {
          return params?.row?.newFlag ? classes.rowColor : "";
        }}
        pinnedColumns={{ left: ["email"] }}
        columns={columns}
        rows={applicantDataError ? [] : rowData}
        rowCount={totalDataCount}
        selectionModel={selectedRow.selectedIds}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = rowData.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedRow({
            ...selectedRow,
            details: selectedRowData?.map((item) => {
              if (fromTrack) {
                return {
                  id: item?.id,
                  name: item?.name,
                  user_id: item?.user_id,
                  job_id: item?.job_id,
                  is_locked: item?.is_locked,
                };
              } else {
                return item;
              }
            }),
            selectedIds: ids,
          });
        }}
        disableColumnSelector
        disableColumnMenu
        //pageSize={2}
        pageSize={pageData?.pageSize}
        page={pageData?.currentPage}
        onPageChange={(page) => setPageData({ ...pageData, currentPage: page })}
        onPageSizeChange={(newPageSize) =>
          setPageData({ ...pageData, pageSize: newPageSize, currentPage: 0 })
        }
        rowsPerPageOptions={[10]}
        pagination
        paginationMode="server"
        // {...list}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        loading={loading}
        componentsProps={{
          pagination: {
            style: {
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            },
          },
        }}
        components={{
          // Pagination: <Pagination style={{ display: "flex", justifyContent: "center", margin: "auto" }}/>,
          NoRowsOverlay: () => (
            <>
              {!loading && (
                <>
                  <br /> <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      paddingTop: 30,
                    }}
                  >
                    {applicantDataError ? (
                      <NewError
                        height="180px"
                        width="180px"
                        alt="404 Error"
                        fill={"#007BFF"}
                      />
                    ) : (
                      <NoData
                        height="180px"
                        width="180px"
                        alt="404 Error"
                        fill={"#007BFF"}
                      />
                    )}

                    <span
                      align="center"
                      style={{ fontSize: 14, color: "#6c757d" }}
                    >
                      <b>
                        {applicantDataError ? (
                          applicantDataError
                        ) : fromManageJobs ? (
                          <div>
                            <div>No Jobs Available</div>{" "}
                            <div>
                              <b>Post A Job Now! </b>
                            </div>
                          </div>
                        ) : (
                          "No Applicants"
                        )}
                      </b>
                    </span>
                  </div>
                </>
              )}
            </>
          ),
        }}
      />
    </div>
  );
}

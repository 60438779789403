import { Button, Container, Grid, Hidden, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import logo from "../../../assets/company/png/de.png";
import Footer from "../../../components/public/FooterNew/Footer";
import FooterDomain from "../../../components/public/FooterNew/Footer/FooterDomain";
import Form from "../../../components/public/LogIn/Card/Form";
import RegisterForm from "../../../components/public/LogIn/Card/Form/RegisterForm";
import MobileTabApply from "../../../components/public/LogIn/Header/MobileTabApply";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";
import { BackendBaseApi, FACEBOOK_APP_ID, FACEBOOK_APP_SECRET } from "../../../constants/constants";
import { getCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import { getCookie } from "../../../utils/common/Cookies";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  open: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  header: {
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    marginTop: "100px",
    marginBottom: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  image: {
    display: "flex",
    width: 150,
    height: 80,
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  img: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    //  borderRadius: "50%",
  },
  company: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textpaper: {
    padding: "20px",
    boxShadow: "none",
    border: ".5px solid #007bff",
    width: "73%",
    borderRadius: "10px",
  },
  divider: {
    width: "20px",
    height: "35%",
    borderLeft: `3px dashed ${theme.palette.domain.main}`,
  },
  primediv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

function StudentLogin() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [emailUser, setEmailUser] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [fbAccessToken, setfbAccessToken] = useState("");
  const [googleAccessToken, setgoogleAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);

  const [loader, setLoader] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const firstRender_1 = useRef(true);
  const firstRender_2 = useRef(true);
  const alert = useAlert();
  const params = useParams();
  const dispatch = useDispatch();
  const [buttonClickedLogin, setButtonClickedLogin] = useState(false);

  const [userDetails, setUserDetails] = useState({
    Flag: false,
    UserID: null,
    FirstName: "",
    LastName: "",
    PhoneNo: null,
    Email: "",
    Password: "",
    CurrentCtc: null,
    ExpectedCtc: null,
    NoticePeriod: null,
    Token: null,
    isemailVerified: false,
    temp_resume: null,
    college: null,
    temp_college_name: null,
  });

  const [errorMessages, setErrorMessages] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    temp_resume: "",
    college: "",
  });

  const responseFacebook = (response) => {
    setfbAccessToken(response.accessToken);
  };

  // useEffect(() => {
  //   if (localStorage.getItem("user_type")) {
  //     console.log("inside");
  //     var link = checkLinkingHomePage(localStorage.getItem("user_type"));
  //     console.log(link);

  //     history.push("/company/dashboard");
  //   }
  // }, []);

  useEffect(() => {
    axios.get(
        BackendBaseApi.PRAVESH + "api/shared/user/?user_type=1",
        {
          headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
          withCredentials: true
        }
    ).then((res)=>{

      if(res?.data?.success){
        if(res?.data?.data?.email){
          localStorage.setItem("email", res?.data?.data?.email);
          localStorage.setItem("email_verified", res?.data?.data?.email_verified);
          localStorage.setItem("first_name", res?.data?.data?.first_name);
          localStorage.setItem('full_name', res?.data?.data?.full_name);
          localStorage.setItem("gw_token", res?.data?.data?.gw_token);
          localStorage.setItem("user_id", res?.data?.data?.id);
          localStorage.setItem("user_type_id", "1");
          localStorage.setItem('user_type', 'Student');
        }
      }
    }).catch((err)=>{
      console.log(err);
    })

    if (localStorage.getItem("gw_token") && localStorage.getItem("user_type") && localStorage.getItem("user_type") !== "Admin") {
      checkUser(localStorage.getItem("gw_token"));
    }
  }, []);

  const checkUserType = () => {
    if (params && params?.token) {
      if (params?.token === "student") return "Student";
      if (params?.token === "college") return "College";
      if (params?.token === "employer" || params?.token === "company") return "Company";
    } else if (!params.token && params.token === undefined) return "Student";
    else return "Student";
  };

  const [open, setOpen] = React.useState(location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" ? true : false);


  //access short life token from google popup response
  const responseGoogle = (response) => {
    setgoogleAccessToken(response.accessToken);
  };

  const loginUser = (user_type) => {
    setLoading(true);
    let token = "";
    Axios.post(
      BackendBaseApi.PRAVESH + "api/login_new/",
      {
        email: emailUser,
        password: passwordUser,
        user_type: user_type,
      },
      { withCredentials: true }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          token = res.data.data.token;

          setAuthToken(token);
          checkUser(token);
        } else {
          alert.error(res.data.error);
          setEmailErrorMessage("Enter a valid Email Address");
          setPasswordErrorMessage("Enter a valid Password");

          setLoading(false);
          // return;
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 400) {
          alert.error(error.response.data.error);
          setEmailErrorMessage("Enter a valid Email Address");
          setPasswordErrorMessage("Enter a valid Password");
        } else {
          setEmailErrorMessage("");
          setPasswordErrorMessage("");
        }
        setButtonClickedLogin(false);
      });
  };

  const checkUser = (token) => {
    Axios.get(BackendBaseApi.PRAVESH + "api/profile/details/", {
      headers: {
        Authorization: "Token " + token,
      },
      withCredentials: true,
    })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          alert.success("User Already Create!! Please Complete Your Profile");
          if (!res.data.email_verified) {
            history.push({
              pathname: "/confirm-email",
              email: res.data.email,
              user_id: res.data.user_type[0].user_id,
            });
          }
        } else {
          if (res.data.user_type[0].type === 10) {
            localStorage.clear();
            alert.error("You can not Login with Admin account here");
          } else {
            if (res.data.email_verified) {
              if (res.data.user_type[0].type !== 10) {
                localStorage.setItem("user_type", res.data.user_type[0].main_user);
              }
              localStorage.setItem("gw_token", token);
              localStorage.setItem("user_type_id", res.data.user_type[0].type);
              localStorage.setItem("user_id", res.data.user_type[0].user_id);
              localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
              localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);

              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem("user_type", res.data.user_type[0].main_user);

                //student with verified email, go to home
                localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
                localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);

                localStorage.setItem("user_id", res.data.id);

                if (location.flag == "102") {
                  history.push("/student/open");
                } else {
                  if (res.data.is_pref_filled) {
                    if (location.search && location.search.includes("?source=")) history.push(`${location.search.replace("?source=", "")}`);
                    else history.push("/student/dashboard");
                  } else {
                    if (location.search && location.search.includes("?source=")) {
                      if (res.data.is_student_resume_uploaded) history.push(`${location.search.replace("?source=", "")}`);
                      else history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                    } else {
                      history.push({
                        pathname: "/student/complete-profile",
                        flag: 12367,
                      });
                    }
                  }
                }
              } else if (res.data.user_type[0].type === 7) {
                dispatch(getCreditInfo(setLoader));

                if (location.flag === 28071998) history.push("/college/post-job");
                else {
                  if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                  else history.push("/college/dashboard");
                }
              } else if (res.data.user_type[0].type === 8) {
                dispatch(getCreditInfo(setLoader));
                if (location.flag === 28071998) history.push("/college/post-job");
                else {
                  if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                  else history.push("/college/dashboard");
                }
              } else {
                //company user with verified email, check if admin approved

                if (res.data.is_pref_filled) {
                  if (!res.data.company_details.admin_approved) {
                    localStorage.setItem(
                      "user",
                      JSON.stringify({
                        email: res.data.email,
                        first_name: res.data.first_name,
                        last_name: res.data.last_name,
                        id: res.data.id,
                        profile_picture: null,
                      })
                    );

                    history.push({
                      pathname: "/company/admin-approve",
                      flag: 19087,

                      company: res.data.company_details.company_name,
                      admin_email: res.data.company_details.admin_email,
                      admin_name: res.data.company_details.admin_name,
                      is_admin: res.data.is_company_admin,
                    });
                  } else {
                    if (res?.data?.company_details !== null) localStorage.setItem("company", JSON.stringify(res.data.company_details));

                    dispatch(getCreditInfo(setLoader));
                    if (location.flag === 28071998) history.push("/company/post-job");
                    else {
                      if (res.data.user_type[0].type === 5) {
                        localStorage.setItem("comp_pref_filled", res.data.comp_pref_filled);
                        if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                        else history.push({ pathname: "/company/dashboard", comp_pref_filled: res.data.comp_pref_filled });
                      } else {
                        if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                        else history.push({ pathname: "/company/dashboard" });
                      }
                    }
                  }
                } else {
                  localStorage.setItem("mobile", res.data.mobile);
                  if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                  else history.push("/company/complete-profile");
                }
              }
            } else {
              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem("gw_token", token);
                localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
                localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);
                localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);

                localStorage.setItem("user_type", res.data.user_type[0].main_user);
                localStorage.setItem("user_id", res.data.id);

                localStorage.setItem("user_type_id", res.data.user_type[0].type);

                //student without verified email, go to home

                if (res.data.is_pref_filled) {
                  if (location.search && location.search.includes("?source=")) history.push(`${location.search.replace("?source=", "")}`);
                  else history.push("/student/dashboard");
                } else {
                  if (location.search && location.search.includes("?source=")) {
                    if (res.data.is_student_resume_uploaded) history.push(`${location.search.replace("?source=", "")}`);
                    else history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                  } else {
                    history.push({
                      pathname: "/student/complete-profile",
                      flag: 12367,
                    });
                  }
                }
              } else {
                //company user without verified email
                history.push({
                  pathname: "/confirm-email",
                  email: res.data.email,
                  user_id: res.data.user_type[0].user_id,
                });
              }
            }
          }
        }

        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        throw err;
      });
  };

  useEffect(() => {
    let accessToken = "";
    if (firstRender_1.current) {
      firstRender_1.current = false;
      return;
    }

    //Facebook Auth API calls
    const fbGraphAPICalls = async () => {
      Axios.get(
        `https://graph.facebook.com/v6.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${FACEBOOK_APP_ID}&client_secret=${FACEBOOK_APP_SECRET}&fb_exchange_token=${fbAccessToken}`
      )
        .then((res) => {
          accessToken = res.data.access_token;
        })
        .then(() => {
          Axios.post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
            headers: {
              "Content-Type": "application/json",
              Connection: "keep-alive",
            },
            withCredentials: true,
            provider: "facebook",
            access_token: accessToken,
            user_type: checkUserType(),
          },
          )
            .then((res) => {
              if (res.data.success) {
                let token = "";
                token = res.data.data.token;
                localStorage.setItem("gw_token", token);
                alert.success(res.data.data.message);
                setLoading(true);
                checkUser(token);
              } else {
                alert.error(res.data.error);
              }
            })
            .catch((err) => {
              alert.error("Error occured!");
            });
        })
        .catch((err) => {});
    };

    fbGraphAPICalls();
  }, [fbAccessToken]);

  useEffect(() => {
    let token = "";
    if (firstRender_2.current) {
      firstRender_2.current = false;
      return;
    }
    if (googleAccessToken) {
      Axios.post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
        },
        withCredentials: true,
        provider: "google-oauth2",
        access_token: googleAccessToken,
        user_type: checkUserType(),
      },
      )
        .then((res) => {
          if (res.data.success) {
            token = res.data.data.token;
            localStorage.setItem("gw_token", token);
            alert.success(res.data.data.message);
            setLoading(true);
            checkUser(token);
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          alert.error("Error");
        });
    }
  }, [googleAccessToken]);

  const createUserProfileStudent = () => {
    var flag = 0;

    // if (digits_count(userDetails.PhoneNo) !== 10) flag = 1;
    // if (emailRegex.test(userDetails.Email) === false) {
    //   flag = 2;
    // }

    if (flag === 0) {
      const fd = new FormData();

      fd.append("first_name", userDetails.FirstName);
      fd.append("last_name", userDetails.LastName);
      fd.append("phone", userDetails.PhoneNo);
      fd.append("email", userDetails.Email);
      fd.append("password", userDetails.Password);
      userDetails.CurrentCtc !== null && fd.append("cur_ctc", userDetails.CurrentCtc);
      userDetails.ExpectedCtc !== null && fd.append("expected_ctc", userDetails.ExpectedCtc);
      userDetails.NoticePeriod !== null && fd.append("notice_period", userDetails.NoticePeriod);
      fd.append("resume", userDetails.temp_resume);
      fd.append("job_id", location.search.split("/")[location.search.split("/").length - 1].split("-")[0]);
      if (userDetails?.college?.id) {
        fd.append("college_id", userDetails?.college?.id);
        fd.append("college_name", userDetails?.college?.name);
      } else {
        fd.append("temp_college_name", userDetails?.temp_college_name);
      }

      setLoadingRegister(true);
      Axios.post(BackendBaseApi.PRAVESH + "api/public_signup/apply", fd, { withCredentials: true })
        .then((res) => {
          setLoadingRegister(false);

          if (res.data.success) {
            alert.success(res.data.data.message);
            alert.success("You are successfully registered!!");
          } else {
            alert.error(res.data.error);
            setErrorMessages({
              ...errorMessages,
              email: "Email Address already exists",
            });
            return;
          }

          let data = res.data.data;

          setUserDetails({
            ...userDetails,
            UserID: data.id,
            Token: data.token,
          });
          getDetailsRegister(data.token);

          // setStep(step + 1);
        })
        .catch((err) => {
          localStorage.clear();
          setLoadingRegister(false);
          alert.error("Invalid Session!! Please try again");
          throw err;
        });
    } else {
      if (flag === 1) alert.error("Enter a valid  10 digit Phone No.");
      if (flag === 2) alert.error("Enter valid Email Address");
    }
  };

  const getDetailsRegister = (token) => {
    Axios.get(BackendBaseApi.PRAVESH + "api/profile/details/", {
      headers: {
        Authorization: "Token " + token,
      },
      withCredentials: true,
    }).then((res) => {
      if (res.data.user_type[0].type === 1) {
        localStorage.setItem("gw_token", token);
        localStorage.setItem("user_type_id", res.data.user_type[0].type);
        localStorage.setItem("user_type", "Student");
        localStorage.setItem("user_id", res.data.id);
        localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
        localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);
        localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);
        if (location.search.includes("utm_source") || getCookie("utm_source")) {
          history.push({ pathname: `/public/referral/thank-you-for-applying`, search: location.search ? location.search : "" });
        } else {
          history.push({ pathname: `/public/thank-you-for-applying`, search: location.search ? location.search : "" });
        }

        //student without verified email, go to home

        // if (res.data.is_pref_filled) {
        //   if (location.search.includes("?source=") && !location.search.includes("utm_source") && !getCookie("utm_source")) {
        //     history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
        //   } else if (location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
        //     history.push({ pathname: `/student/referral/update-profile-and-apply`, search: location.search ? location.search : "" });
        //   } else if (!location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
        //     history.push({ pathname: `/student/referral/update-profile-and-apply` });
        //   } else history.push("/student/dashboard");
        // } else {
        //   if (location.search.includes("?source=") && !location.search.includes("utm_source") && !getCookie("utm_source")) {
        //     history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
        //   } else if (location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
        //     history.push({ pathname: `/student/referral/update-profile-and-apply`, search: location.search ? location.search : "" });
        //   } else if (!location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
        //     history.push({ pathname: `/student/referral/update-profile-and-apply` });
        //   } else {
        //     history.push({
        //       pathname: "/student/complete-profile",
        //       flag: 12367,
        //     });
        //   }
        // }
      }
    });
  };

  const [jobDetails, setJobDetails] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search); 
    const source = params.get("source")
    if (source){
    const jobIdText = source.split("-")[0];
    const jobId = jobIdText.split("/").slice(-1)[0];
    Axios.get(BackendBaseApi.NIYUKTI + `job/signup_job_detail?job_id=${jobId}`)
      .then((res) => {
        if (res.data.success) setJobDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }
  , []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      {domain?.domain ? <DomainNavbar /> : <Navbar />}
      <Hidden smDown>
        <Container maxWidth="lg" className={classes.header}>
          <Grid container spacing={3} style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={12} md={5}>
              <div>
                <Grid container spacing={2} style={{ marginLeft: "60px" }}>
                  <Grid item>
                    <div className={classes.image}>
                      <img src={jobDetails?.company && jobDetails?.company[0]?.company_logo ? jobDetails?.company[0]?.company_logo : logo} className={classes.img} />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} sm container>
                    <Grid item xs container direction="column" spacing={2} style={{ display: "contents" }}>
                      <div className={classes.title}>
                        <Typography variant="h6">{jobDetails.job_title}</Typography>
                        <div className={classes.company}>
                          <Typography variant="body2">
                            {jobDetails?.company && jobDetails?.company?.length > 0 ? (
                              <a style={{ marginTop: "10px", color: "#6c757d" }} href={`/public/company/profile/${jobDetails?.company[0].company_id}`} target="blank">
                                {" "}
                                <b> {jobDetails?.company[0].company_name}</b>
                              </a>
                            ) : (
                              <b> {jobDetails?.company_details}</b>
                            )}
                          </Typography>{" "}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <br />
              <Form
                type={"Student"}
                email={emailUser}
                setEmail={setEmailUser}
                setPassword={setPasswordUser}
                password={passwordUser}
                loginUser={loginUser}
                emailErrorMessage={emailErrorMessage}
                passwordErrorMessage={passwordErrorMessage}
                setEmailErrorMessage={setEmailErrorMessage}
                setPasswordErrorMessage={setPasswordErrorMessage}
                responseGoogle={responseGoogle}
                responseFacebook={responseFacebook}
                loading={loading}
                buttonClicked={buttonClickedLogin}
                setButtonClicked={setButtonClickedLogin}
                fromApplyPage={true}
                checkUser={checkUser}
              />
            </Grid>

            <div className={classes.primediv}>
              <div className={classes.divider} />
              <Button endIcon={<ArrowForwardIcon style={domain?.domain ? { color: domain?.color_code } : { color: "#007bff" }} />} style={{ maxHeight: "50px" }}>
                <Typography variant="h6" style={domain?.domain ? { color: domain?.color_code } : { color: "#007bff" }}>
                  {" "}
                  New to GetWork <br /> Signup Now
                </Typography>
              </Button>
              <div className={classes.divider} />
            </div>
            <Grid item xs={12} md={5} style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Hidden smDown>
                  <Paper className={classes.textpaper}>
                    <Typography variant="h6">
                      Job Application Closes in: <span style={domain?.domain ? { color: domain?.color_code } : { color: "#007bff" }}>{jobDetails?.job_deadline}</span>{" "}
                    </Typography>
                  </Paper>

                  <br />
                </Hidden>
                {/* <CreateAccount /> */}

                <RegisterForm
                  type={"Student"}
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                  handleSubmit={createUserProfileStudent}
                  loading={loadingRegister}
                  fromApplyPage={true}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Hidden>

      <Hidden smUp>
        {" "}
        <MobileTabApply
          type={"Student"}
          email={emailUser}
          setEmail={setEmailUser}
          setPassword={setPasswordUser}
          password={passwordUser}
          loginUser={loginUser}
          emailErrorMessage={emailErrorMessage}
          passwordErrorMessage={passwordErrorMessage}
          setEmailErrorMessage={setEmailErrorMessage}
          setPasswordErrorMessage={setPasswordErrorMessage}
          responseGoogle={responseGoogle}
          responseFacebook={responseFacebook}
          loadingLogin={loading}
          buttonClicked={buttonClickedLogin}
          setButtonClicked={setButtonClickedLogin}
          fromApplyPage={true}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
          handleSubmit={createUserProfileStudent}
          loading={loadingRegister}
        />
      </Hidden>
      {!domain?.domain ? <Footer /> : <FooterDomain />}
    </>
  );
}

export default StudentLogin;

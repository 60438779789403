import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { BackendBaseApi } from "../../../../constants/constants";

const Cities = ({ cityID, Id, setId }) => {
  const [cityList, setCityList] = useState({});
  const [city, setCity] = useState();
  const [defaultCity, setDefaultCity] = useState(cityID);
  const cities = Object.keys(cityList).map((city) => {
    return { name: city, value: city };
  });
  const TypeAndSearchCity = () => (
    <SelectSearch
      options={cities}
      value={city}
      onChange={setCity}
      search
      placeholder={defaultCity ? defaultCity : "Search City"}
    />
  );

  const getAllCities = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/location/city/")
      .then((res) => {
        let temp = {};
        res.data.forEach((city) => {
          temp[city.city] = city.city_id;
        });
        setCityList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
    setCity(cityList[Id]);
  };

  useEffect(() => {
    getAllCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setId(cityList[city]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  return (
    <>
      <TypeAndSearchCity />
    </>
  );
};

export default Cities;

import { Tooltip } from "@material-ui/core";
import { default as axios, default as Axios } from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Skeleton from "react-loading-skeleton";
import defaultCompLogo from "../../../assets/images/com.png";
import { BackendBaseApi } from "../../../constants/constants";
import AddDetails from "./AddDetails";

const StudentProfileWorkExperience = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const [showWorkExperienceForm, setShowWorkExperienceForm] = useState(false);
  const [workEx, setWorkEx] = useState([]);
  const [type, setType] = useState("");
  const [selectedWorkEx, setSelectedWorkEx] = useState({});

  const [newWorkEx, setNewWorkEx] = useState({
    id: null,
    company_id: null,
    company_name: "",
    company_website: "",
    job_type_id: null,
    job_type: "",
    start_date: null,
    end_date: null,
    job_designation: "",
    job_description: "",
    skills: [],
  });

  function validURL(str) {
    var pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return !!pattern.test(str);
  }
  const AddWorkEx = () => {
    let data = newWorkEx;
    let isValid = true;
    let isWebsiteInvalid = false;
    if (data?.company_website && !validURL(data.company_website)) {
      isWebsiteInvalid = true;
      alert.error("Please add Company Website with http:// or https://");
      return;
    }
    Object.values(newWorkEx).forEach((val, ind) => {
      if (ind > 2 && !val && ind !== 7) {
        isValid = false;
        return;
      }
    });
    if (!isValid) {
      alert.error("Please fill all the details first");
      return;
    }

    Axios.post(BackendBaseApi.PRAVESH + "api/company/work_experience/", data, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setNewWorkEx({ ...newWorkEx, id: res.data.data.work_id });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        throw err;
      });
  };

  const DeleteWorkEx = (id) => {
    Axios.delete(
      BackendBaseApi.PRAVESH + `api/company/work_experience/${id}/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          let temp = workEx.filter((workex) => {
            return workex.id != id;
          });
          setWorkEx(temp);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    if (newWorkEx && newWorkEx.id) {
      setWorkEx([...workEx, newWorkEx]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newWorkEx]);

  const ChangeWorkEx = () => {
    //send patch request to update details
    let isWebsiteInvalid = false;
    let temp = [...workEx];
    temp.forEach((val, ind) => {
      if (val.id === selectedWorkEx.id) {
        if (
          selectedWorkEx?.company_website &&
          !validURL(selectedWorkEx.company_website) &&
          !isWebsiteInvalid
        ) {
          isWebsiteInvalid = true;
          return;
        }
      }
    });
    if (isWebsiteInvalid) {
      alert.error(
        "Please check Company Website. It should start with http:// or https://"
      );
      return;
    }
    temp.forEach((workex, ind) => {
      if (workex.id == selectedWorkEx.id) {
        let res = updateWorkEx(selectedWorkEx);
        res.then((response) => {
          temp[ind] = selectedWorkEx;
          setWorkEx(temp);
        });
      }
    });
  };

  const updateWorkEx = async (workex) => {
    let result = Axios.patch(
      BackendBaseApi.PRAVESH + `api/company/work_experience/${workex.id}/`,
      workex,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) alert.success(res.data.data.message);
        else alert.error(res.data.error);
      })
      .catch((err) => {
        throw err;
      });
    return result;
  };

  const EditWorkEx = (workex) => {
    let temp = workex;

    setSelectedWorkEx(temp);
    openWorkExperienceForm("EDIT_WORKEX");
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/work_experience/", {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
      .then((res) => {
        setLoading(false);

        if (res.data.success) {
          //user already has some work experience, populate state
          let temp = [];
          res.data.data.forEach((workex) => {
            temp.push(workex);
          });
          setWorkEx(temp);
        }
      })
      .catch((err) => {
        throw err;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openWorkExperienceForm = (workex_type) => {
    setType(workex_type);
    setShowWorkExperienceForm(true);
  };
  return (
    <>
      {showWorkExperienceForm && (
        <AddDetails
          appendToParentState={type == "ADD_WORKEX" ? AddWorkEx : ChangeWorkEx}
          formState={type == "ADD_WORKEX" ? newWorkEx : selectedWorkEx}
          setFormState={type == "ADD_WORKEX" ? setNewWorkEx : setSelectedWorkEx}
          detailType={type}
          showForm={showWorkExperienceForm}
          setShowForm={setShowWorkExperienceForm}
        />
      )}

      <div className="row my-3 justify-content-center">
        <Tooltip title="Add Work Experience" arrow placement="left">
          <button
            onClick={() => {
              openWorkExperienceForm("ADD_WORKEX");
            }}
            className="add ripple"
          >
            <span>+</span>
          </button>
        </Tooltip>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : workEx.length > 0 ? (
        workEx.map((workex, ind) => {
          return (
            <div className="card job-card-main" key={workex.job_type_id}>
              <div
                className="card-body"
                style={{ boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)" }}
              >
                <div className="row">
                  <div className="col-md-8 col-7 text-left">
                    <p
                      className="fs-12"
                      style={{ color: "#000000", fontWeight: "bold" }}
                    >
                      Work Experience {ind + 1}
                    </p>
                  </div>
                  <div className="col-md-4 col-5 text-right">
                    <Tooltip title="Edit" arrow placement="top">
                      <i
                        onClick={() => {
                          EditWorkEx(workex);
                        }}
                        className="fas fa-edit edit-icon mx-2"
                      ></i>
                    </Tooltip>
                    <Tooltip title="Delete" arrow placement="top">
                      <i
                        onClick={() => {
                          DeleteWorkEx(workex.id);
                        }}
                        className="fas fa-trash edit-icon mx-2"
                      ></i>
                    </Tooltip>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-md-1 col-sm-4 text-center">
                    <img
                      src={workex.logo === null ? defaultCompLogo : workex.logo}
                      alt=""
                      style={{
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </div>
                  <div className="col-md-7 col-sm-8 text-left">
                    <p className="fs-14">
                      <b className="fs-16">
                        {" "}
                        {workex.job_designation || (
                          <Skeleton width={100} duration={2} />
                        )}
                      </b>{" "}
                      @{" "}
                      <span className="fw-500 link-text">
                        {workex.company_name || (
                          <Skeleton width={100} duration={2} />
                        )}
                      </span>
                      <br />
                      <span className="fw-500 link-text">
                        <a
                          href={
                            validURL(workex.company_website)
                              ? workex.company_website
                              : "//" + workex.company_website
                          }
                          target="blank"
                        >
                          {workex.company_website || (
                            <Skeleton width={100} duration={2} />
                          )}
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-12 text-md-right text-left">
                    <span className="fs-12 text-muted">
                      <i class="far fa-calendar"></i>{" "}
                      {workex.end_date !== null
                        ? moment(workex.end_date).diff(
                            moment(workex.start_date),
                            "months"
                          )
                        : moment().diff(
                            moment(workex.start_date),
                            "months"
                          )}{" "}
                      months
                    </span>
                    <br />
                    <span className="fs-13 text-muted">
                      {" "}
                      {moment(workex.start_date).format("MMM DD")} -{" "}
                      {workex.end_date !== null
                        ? moment(workex.end_date).format("MMM DD")
                        : "Present"}{" "}
                    </span>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-md-8 col-sm-12 text-left">
                    <p className="fs-12">
                      {workex.job_description || (
                        <Skeleton width={100} duration={2} />
                      )}
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-12 text-left pl-md-5 pl-2">
                    {workex.skills &&
                      workex.skills.map((skill) => {
                        return (
                          <span
                            key={skill.skill_id}
                            className="badge badge-light bg-blue-light p-1 mx-2 my-1"
                          >
                            {skill.skill_name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div>Add some past work experiences to enhance your GetWork score!</div>
      )}
    </>
  );
};

export default StudentProfileWorkExperience;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  overview: {
    marginBottom: "15px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function SaveEducation({data}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
          <Typography
              variant="body1"
              style={{
                  fontWeight: "bold",
                  marginBottom: "4px",
              }}
          >
              {data?.highest_qualification}
          </Typography>
        <Typography
          variant="body1"
          style={{
            fontWeight: "semi-bold",
            marginBottom: "4px",
          }}
        >
         {data?.college_name}
        </Typography>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
            fontWeight: "semi-bold",
          }}
        >
          {data?.degreeSpec} {data?.grade && `${data?.degreeSpec && `, `} ${data?.grade}`}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontWeight: 400,
            color: "#6C757D",
          }}
        >
          {data?.timePeriod}
        </Typography>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Breakpoint, BreakpointProvider } from "react-socks";

import { Grid } from "@material-ui/core";

import CompanyNavbar_C from "../../../bundles/common/components/UI/CompanyNavbar_c";
import StudentNavbar from "../../../pages/student/Navbar/StudentNavbar";
export default function Layout({ children }) {
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    let details = JSON.parse(
      localStorage.getItem("user") !== null
        ? localStorage.getItem("user")
        : localStorage.getItem("all_details")
    );
    setUserDetails(details);
  }, [localStorage.getItem("user_details")]);
  return (
    <>
      <BreakpointProvider>
        <Breakpoint large up>
          <Grid container>
            <Grid xs={12}>
              {parseInt(localStorage.getItem("user_type_id")) !== 1 ? (
                <CompanyNavbar_C
                  name={userDetails?.first_name + " " + userDetails?.last_name}
                  dp={userDetails?.profile_picture}
                />
              ) : (
                <StudentNavbar />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12}>
              <div
                className="mg-top-20"
                // style={{ marginTop: 0, paddingRight: 40, paddingLeft: 40 }}
                style={{ marginTop: "-5px" }}
              >
                {children}
              </div>
            </Grid>
          </Grid>
        </Breakpoint>
        <Breakpoint medium down>
          {/* <Navbar /> */}
          <CompanyNavbar_C
            name={userDetails?.first_name + " " + userDetails?.last_name}
            dp={userDetails?.profile_picture}
          />
          <div style={{ marginTop: "16%" }}>{children}</div>
        </Breakpoint>
      </BreakpointProvider>
      {/* <Sidebar userType='COMPANY'/> */}
    </>
  );
}

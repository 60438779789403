export const printNumber = (val) => {
    if(val>=10000000)
        return `${(val/10000000).toFixed(2).replace(/\.0$/, '')} cr`
    else if(val>=100000)
        return `${(val/100000).toFixed(2).replace(/\.0$/, '')} L` 
    else if(val>=1000)
        return `${(val/1000).toFixed(2).replace(/\.0$/, '')} K` 
    else
        return val       
}


export const printDate = (date) => {
    return date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4)
}

// @ts-nocheck

import {Box, createStyles, makeStyles, Theme} from "@material-ui/core";
import React, {FC, Fragment, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import CompanyHomeComponent from "../../../components/company/Home/CompanyHome";
import StudentHomeComponent from "../../../bundles/student/components/Home/StudentHome";
//import SideBar2 from "../components/SideBar/CollegeSidebar";
//import IndexRoutes from "../../routes/IndexRoutes";
import IndexRoutes from "../../../routes/IndexRoutes";
import CollegeNavbar from "../NavBar/CollegeNavbar";
import SideBar2 from "../SideBar/CollegeSidebar";
import {CircularProgress, createMuiTheme, fade, ThemeProvider} from "@material-ui/core";
import axios from "axios";
import {BackendBaseApi} from "../../../constants/constants";
import Axios from "axios";
import {useAlert} from "react-alert";

interface LayoutProps {
    setDarkState: any;
    darkState: boolean;
}

const NavigationLayout: FC<LayoutProps> = (Props) => {
    const classes = useStyles();
    const {setDarkState, darkState} = Props;
    const location = useLocation();
    const history = useHistory();
    const alert = useAlert();
    const [userType, setUserType] = React.useState(localStorage.getItem('user_type'));
    const [isPublic, setIsPublic] = React.useState(location.pathname.split("/")[1] === "public")

    const checkUser = (token) => {
        Axios.get(BackendBaseApi.PRAVESH + "api/profile/details/", {
            headers: {
                Authorization: "Token " + token,
            },
            withCredentials: true,
        })
            .then((res) => {
                //CASE 1: user type does not exist => profile is also not completed
                if (res.data.user_type.length === 0) {
                    alert.success("User Already Create!! Please Complete Your Profile");
                    if (!res.data.email_verified) {
                        //move to signup options component
                        history.push({
                            pathname: "/signup",
                            step: 2,
                            userid: res.data.id,
                            email: res.data.email,
                            token,
                            isemailVerified: false,
                        });
                    } else {
                        //move to signup options component
                        history.push({
                            pathname: "/signup",
                            step: 2,
                            userid: res.data.id,
                            token,
                            email: res.data.email,
                            isemailVerified: true,
                        });
                    }
                } else {
                    setUserType(res?.data?.user_type[0]?.main_user)
                    localStorage.setItem("user_type", res.data.user_type[0].main_user);
                    localStorage.setItem("user_type_id", res.data.user_type[0].type);
                    if (res.data.email_verified) {
                        localStorage.setItem("gw_token", token);
                        //check if its student or company
                        if (res.data.user_type[0].type === 1) {
                            //student with verified email, go to home
                            // if (location.flag == "102") {
                            //     history.push("/student/open");
                            // } else {
                            //     history.push("/student/dashboard");
                            // }
                            setUserType(res?.data?.user_type[0]?.main_user)
                        } else if (res.data.user_type[0].type === 7) {
                            // history.push("/college/dashboard");
                            // setUserType(res?.data?.user_type[0]?.main_user)
                        } else if (res.data.user_type[0].type === 8) {
                            // history.push("/college/dashboard");
                            // setUserType(res?.data?.user_type[0]?.main_user)
                        } else {
                            //company user with verified email, check if admin approved
                            if (!res.data.company_details.admin_approved) {
                                // history.push({
                                //     pathname: "/company/admin-approve",
                                //     flag: 19087,
                                //
                                //     company: res.data.company_details.company_name,
                                //     admin_email: res.data.company_details.admin_email,
                                //     admin_name: res.data.company_details.admin_name,
                                //     is_admin: res.data.is_company_admin,
                                // });
                            } else {
                                localStorage.setItem("company", JSON.stringify(res.data.company_details));

                                // history.push("/company/dashboard");
                            }
                        }
                    } else {
                        //check if its student or company
                        if (res.data.user_type[0].type === 1) {
                            localStorage.setItem("gw_token", token);
                            //student without verified email, go to home
                            if (res.data.is_pref_filled) {
                                // history.push("/student/dashboard");
                            } else {
                                history.push({
                                    pathname: "/student/complete-profile",
                                    // flag: 12367,
                                });
                            }

                        } else {
                            //company user without verified email
                            history.push({
                                pathname: "/confirm-email",
                                email: res.data.data.email,
                            });
                        }
                    }
                }

                //CASE 2:
            })
            .catch((err) => {
                // setLoading(false);
                alert.error("Oop! There is some error, try again later.");
                localStorage.clear();
                history.push('/login')
            });
    };


    useEffect(() => {
        if (!userType) {
            axios.get(
                BackendBaseApi.PRAVESH + "api/shared/user/",
                {
                    headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
                    withCredentials: true
                }
            ).then((res) => {

                if (res?.data?.success) {
                    localStorage.setItem('user_details', JSON.stringify(res?.data?.data))
                    checkUser(res?.data?.data?.gw_token);
                } else {
                    if (!isPublic) {
                        history.push('/login')
                    }
                }
            }).catch((err) => {
                if (!isPublic) {
                    history.push('/login')
                }
                console.log(err);
            })
        }

    }, []);

    return (

        <Box>
            {/* {localStorage.getItem("user_type_id") !== null && */}
            {/*{localStorage.getItem("user_type") === "College" ? (*/}
            {isPublic ? (<>
                <Box>
                    <IndexRoutes/>
                </Box>
            </>) : userType === "College" ? (
                <Fragment>
                    {location.pathname.split("/")[1] === "public" ? (
                        <>
                            <Box>
                                <IndexRoutes/>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box>
                                <CollegeNavbar setDarkState={setDarkState as any} darkState={darkState}/>
                            </Box>
                            <SideBar2/>

                            <Box className={classes.mainBox}>
                                <IndexRoutes/>
                            </Box>
                        </>
                    )}
                </Fragment>
                // ) : localStorage.getItem("user_type") === "Student" || localStorage.getItem("user_type_id") == "1" ? (
            ) : userType === "Student" || localStorage.getItem("user_type_id") == "1" ? (
                <Fragment>
                    {location.pathname.split("/")[1] === "public" ? (
                        <>
                            <Box>
                                <IndexRoutes/>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box>
                                <StudentHomeComponent/>
                            </Box>
                        </>
                    )}
                </Fragment>
            ) : userType === "Company" ? (
                <Fragment>
                    <>
                        <Box>
                            <CompanyHomeComponent/>
                        </Box>
                    </>

                </Fragment>
            ) : (<CircularProgress size={60} style={{margin: "auto", marginTop: 100, display: "flex"}}/>)}
        </Box>


    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainBox: {
            marginLeft: "220px",
            marginTop: "64px",
            backgroundColor: theme.palette.type === "dark" ? "#222222" : "#ffff",
        },
    })
);

export default NavigationLayout;

import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import pass from "../../assets/png/passcode.png";
import { TextField, makeStyles, Button } from "@material-ui/core";
import fetchApi from "../../apiCall/fetchApi";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";
import Axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "20px",
  },

  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    transform: "translate(15px, 14px) scale(1)",
    //fontSize: "14px",
  },
}));

export default function PassCodeDialog({
  open,
  handleClickOpen,
  handleClose,
  loadingValidation,
  setLoadingValidation,
  setCookie,
  setSharedTrackData,
  setSelectedJob,
  setLinkValid
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const alert = useAlert();
  const classes = useStyles();
  const [passCode, setPassCode] = useState();
  const [loading, setLoading] = useState(false);

  const ValidatePassCode = () => {
    if(!passCode){
      alert.error("Please enter valid passcode!");
      return;
    }
    setLoading(true);
    Axios.post(
      BackendBaseApi.NIYUKTI + "api/track_sharer/company/link_validation/",
      {
        link: window.location.href,
        passcode: passCode,
      },
      { withCredentials: true }
    )
      .then((res) => {
        if(res?.data?.success === false){
          alert.error(res?.data?.error);
      } else{
        handleClose();
        setSharedTrackData(res.data);
        setSelectedJob({
          job_id: Number(res.data?.job_id),
          job_title: res.data?.job_title,
        });
        alert.success("Passcode validation sucessful");
        
        setLoadingValidation(true);
        setCookie(res.data.token);
        setLinkValid(true);
      }
      setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 422)
            alert.error("Some Error occurred. Please try again");
          else alert.error("Error!");
        }
      });
  };

  return (
    <div>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullScreen={fullScreen}
        open={open}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <img src={pass} height="400px" />
        </div>
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <TextField
            style={{ width: "70%" }}
            variant="outlined"
            color="primary"
            label="Please Enter Passcode"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            placeholder="0-0-0-0-0-0"
            value={passCode}
            onChange={(e) => setPassCode(e.target.value)}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: "40px",
            borderRadius: "30px",
            paddingLeft: "20px",
            paddingRight: "20px",
            display: "flex",
            boxShadow: "none",
            margin: "20px 90px",
          }}
          onClick={() => {
            if (!loading) {
              ValidatePassCode();
            }
          }}
        >
          {loading ? (
            <CircularProgress style={{ color: "#fff" }} size={18} />
          ) : (
            "Submit"
          )}
        </Button>
      </Dialog>
    </div>
  );
}

import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FilterList from './FilterList';
import Box from "@material-ui/core/Box";
import filter from "../../../assets/svg/icon/filter.svg";
import loader_gif from "../../../assets/images/filter_load.gif";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


function MobileFilter({filterData, setFilters, isFilterDataPresent, appliedFilters}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
         <Box
         display="flex"
         justifyContent="center"
         alignItems="center"
         padding="1% 0%"
         style={{marginTop:"-20px",marginBottom:"10px"}}
         >  
        <Button variant="outlined" style={{borderRadius:"30px", color:"#000000",borderColor:"#61DE56"}} color="primary" onClick={handleClickOpen}>
         <img src={filter} height="15px" style={{marginRight:"5px"}}/> Filter
        </Button>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}    fullScreen={fullScreen}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Filter
          </DialogTitle>
          <DialogContent dividers>
          {isFilterDataPresent ? 
          <FilterList filterData={filterData} setFilters={setFilters} appliedFilters={appliedFilters} /> :
          <img src={loader_gif} height="150px" />
          }
          </DialogContent>
          <DialogActions style={{justifyContent:"right"}}>
          <Button size= "small" variant="outlined" color="primary" style={{borderRadius:"30px",marginBottom:"10px"}} onClick={handleClose}> 
          Apply 
         </Button>
        </DialogActions>
        </Dialog>
        </Box>
      </div>
    );
  }
export default MobileFilter

import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      marginTop: "20px",
      marginBottom: "10px",
      width: "500px",
    },
    deleteButton: {
      background: "#3282C4",
      color: "#fff",
      borderRadius: "4px",

      // width: "60%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    cancelButton: {
      color: "black",
      backgroundColor: "white",
      border: "2px solid black",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#F2F2F2",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  })
);

export default function ViewDetails(props) {
  const {
    handleClose,
    handleOpen,
    open,
    setOpen,
    profiles,
    jobProfile,
    jobTypes,
    selectedJobType,
    setSelectedJobType,
    setJobProfile,
    jobProfileName,
    setJobProfileName,
    jobTypeName,
    setJobTypeName,
    allCompanies,
    setSelectedCompany,
    campusType,
    setCampusType,
    invited,
    interviewType,
    setInterviewType,

    // interviewID,
    // index,
    // tempData,
    // setTempData,
  } = props;
  const classes = useStyles();
  const alert = useAlert();
  const [currentSelectedJobType, setCurrentSelectedJobType] = useState("All");
  const [currentSelectedJobTypeName, setCurrentSelectedJobTypeName] = useState(
    "All"
  );
  const [currentJobProfile, setCurrentJobProfile] = useState("All");
  const [currentJobProfileName, setCurrentJobProfileName] = useState("All");
  const [currentInterviewType, setCurrentInterviewType] = useState("All");
  const [currentHiringType, setCurrentHiringType] = useState("All");

  const profile_s = Object.keys(profiles).map((item) => {
    return { name: item, value: item };
  });

  const list_s = Object.keys(jobTypes).map((item) => {
    return { name: item, value: item };
  });

  const JobTypeList = () => (
    <Autocomplete
      value={currentJobProfileName}
      onChange={(event, newValue) => {
        setCurrentJobProfile(profiles[newValue]);
        setCurrentJobProfileName(newValue);
      }}
      closeIcon=""
      id="controllable-states-demo"
      options={profile_s.map((option) => option.name)}
      style={{ width: "70%" }}
      renderInput={(params) => (
        <TextField {...params} label="Choose Hiring Type" variant="outlined" />
      )}
    />
  );

  const InterviewTypeForm = () => (
    <Autocomplete
      value={currentInterviewType}
      onChange={(event, newValue) => {
        setCurrentInterviewType(newValue);
      }}
      closeIcon=""
      id="controllable-states-demo"
      options={["All", "ONLINE", "OFFLINE"]}
      style={{ width: "70%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose Interview Type"
          variant="outlined"
        />
      )}
    />
  );

  const HiringTypeForm = () => (
    <Autocomplete
      value={currentHiringType}
      onChange={(event, newValue) => {
        setCurrentHiringType(newValue);
      }}
      closeIcon=""
      id="controllable-states-demo"
      options={["All", "ON CAMPUS", "OFF CAMPUS"]}
      style={{ width: "70%" }}
      renderInput={(params) => (
        <TextField {...params} label="Choose Job Profile" variant="outlined" />
      )}
    />
  );

  const JobType = () => (
    <Autocomplete
      value={currentSelectedJobTypeName}
      onChange={(event, newValue) => {
        // setSelectedJobType(jobTypes[newValue]);
        // setJobTypeName(newValue);
        setCurrentSelectedJobType(jobTypes[newValue]);
        setCurrentSelectedJobTypeName(newValue);
      }}
      //   options={
      //     profiles &&
      //     profiles.length > 0 &&
      //     profiles.map((item) => ({
      //       name: item.job_title,
      //       value: item.job_id,
      //     }))
      //   }
      closeIcon=""
      id="controllable-states-demo"
      options={list_s.map((option) => option.name)}
      style={{ width: "70%" }}
      renderInput={(params) => (
        <TextField {...params} label="Choose Job Type" variant="outlined" />
      )}
    />
  );

  const filterData = () => {
    setJobProfile(currentJobProfile);
    setJobProfileName(currentJobProfileName);
    setSelectedJobType(currentSelectedJobType);
    setJobTypeName(currentSelectedJobTypeName);
    setInterviewType(currentInterviewType);
    setCampusType(currentHiringType);
    handleClose();
  };

  const clear = () => {
    setJobProfile("All");
    setJobProfileName("All");
    setSelectedJobType("All");
    setJobTypeName("All");
    setInterviewType("All");
    setCampusType("All");
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={11}></Grid>
              <Grid item xs={1}>
                <IconButton aria-label="delete" onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography
                  align="center"
                  variant="body2"
                  style={{ paddingTop: "15%" }}
                >
                  Job Type
                </Typography>{" "}
              </Grid>
              <Grid item xs={8}>
                <JobType />
              </Grid>
            </Grid>
            {invited === false && (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography
                    align="center"
                    variant="body2"
                    style={{ paddingTop: "15%" }}
                  >
                    Job Profile
                  </Typography>{" "}
                </Grid>
                <Grid item xs={8}>
                  <JobTypeList />
                </Grid>
              </Grid>
            )}
            {invited === true && (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography
                    align="center"
                    variant="body2"
                    style={{ paddingTop: "15%" }}
                  >
                    Interview Type
                  </Typography>{" "}
                </Grid>
                <Grid item xs={8}>
                  <InterviewTypeForm />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography
                  align="center"
                  variant="body2"
                  style={{ paddingTop: "15%" }}
                >
                  Hiring Type
                </Typography>{" "}
              </Grid>
              <Grid item xs={8}>
                <HiringTypeForm />
              </Grid>
            </Grid>
            <br />
            <br />
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button className={classes.cancelButton} onClick={() => clear()}>
                Clear
              </Button>
              &nbsp; &nbsp;
              <Button
                className={classes.deleteButton}
                onClick={() => {
                  filterData();
                }}
              >
                Filter{" "}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

import {
  AppBar,
  Box,
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import CardBox from "./CardBox";
import OverviewBox from "./OverviewBox";
import JobSegmentBox from "./JobSegmentBox";
import DegreeBox from "./DegreeBox";
import DegreeAdmissionBox from "./DegreeAdmissionBox";
import EligibilityCriteria from "./EligibilityCriteria";
import PlacementBox from "./PlacementBox";
import CutoffBox from "./CutoffBox";
import RankingBox from "./RankingBox";
import GalleryBox from "./GalleryBox";

import React, { Fragment, useEffect, useState } from "react";
const useStyles = makeStyles((theme) =>
  createStyles({
    BlueBox: {
      backgroundColor: "#defcff",
      padding: "5%",
    },
    // fixedBar: {
    //   position: "fixed",
    //   top: 0,
    //   width: "100%",
    // },
  })
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function TabBar(props) {
  const { data } = props;
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.fixedBar}>
        <Tabs
          style={{
            minHeight: "80px",
            paddingTop: "20px",
            backgroundColor: "#fff",
          }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Courses" {...a11yProps(1)} />

          <Tab label="Cutoff" {...a11yProps(2)} />
          <Tab label="Gallery" {...a11yProps(3)} />
          <Tab label="Rankings" {...a11yProps(4)} />
          {data?.placement && Object.keys(data?.placement).length > 0 && (
            <Tab label="Placements" {...a11yProps(5)} />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <CardBox data={data} />
        <OverviewBox data={data} />
        {/* <JobSegmentBox /> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DegreeBox data={data} />
        {data?.courses?.compare_courses.length > 0 && (
          <EligibilityCriteria data={data} />
        )}
        <DegreeAdmissionBox data={data} />
      </TabPanel>
      {/* {data?.cutoff && Object.keys(data?.cutoff).length > 0 && ( */}
      <TabPanel value={value} index={2}>
        <CutoffBox data={data} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <RankingBox data={data} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GalleryBox data={data} />
      </TabPanel>
      {/* )} */}
      {data?.placement && Object.keys(data?.placement).length > 0 && (
        <TabPanel value={value} index={5}>
          <PlacementBox data={data} />
        </TabPanel>
      )}
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../../bundles/common/components/UI/Loader";
import CompanyRoutes from "../../../routes/CompanyRoutes";
import { getCompanyUserDetails } from "./action";

const CompanyHomeComponent = () => {
  const alert = useAlert();
  const firstRender = useRef();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (location.pathname !== "/company/complete-profile")
      dispatch(getCompanyUserDetails(history, alert));
  }, []);

  const [userDetails, setUserDetails] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
  );
  const [companyDetails, setCompanyDetails] = useState(
    localStorage.getItem("company")
      ? JSON.parse(localStorage.getItem("company"))
      : {}
  );

  const companyUserDetails = useSelector(
    (state) => state?.CompanyUserDetails?.companyUserDetails
  );

  const [openPreferences, setOpenPreferences] = React.useState(false);

  useEffect(() => {
    if (location.comp_pref_filled)
      setOpenPreferences(location.comp_pref_filled === true ? false : true);
    else if (localStorage.getItem("comp_pref_filled"))
      setOpenPreferences(
        localStorage.getItem("comp_pref_filled") === "true" ? false : true
      );
    else setOpenPreferences(false);
  }, []);

  const handleClickOpenPreferences = () => {
    setOpenPreferences(true);
  };

  const handleClosePreferences = () => {
    setOpenPreferences(false);
  };

  return (
    <>
      {/* <div className="row company-page"> */}
      {/* {location.pathname !== "/company/complete-profile" && (
          <div className="col-2 d-none d-md-block">
            <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
          </div>
        )} */}
      {/* <div className={location.pathname !== "/company/complete-profile" ? "col-10" : "col-12"} style={{ paddingLeft: "0px" }}> */}
      {/* {location.pathname !== "/company/complete-profile" && (
            <div style={{ height: "64px" }}>
              <Navbar handleDrawerToggle={handleDrawerToggle} name={userDetails.first_name + " " + userDetails.last_name} dp={userDetails.profile_picture} />
            </div>
          )} */}
      {/* {location.pathname !== "/company/complete-profile" && (
        <MuiCustomTheme>
          <Layout>
            {companyUserDetails === undefined &&
            location.pathname !== "/company/complete-profile" ? (
              <>
                <Loader />
              </>
            ) : (
              <CompanyRoutes data={companyDetails} />
            )}
          </Layout>
        </MuiCustomTheme>
      )} */}

      {companyUserDetails === undefined &&
      location.pathname !== "/company/complete-profile" ? (
        <>
          <Loader />
        </>
      ) : (
        <CompanyRoutes data={companyDetails} />
      )}

      {/* </div> */}
      {/* </div> */}
      {/*    {location.pathname !== "/company/complete-profile" &&
        companyUserDetails !== undefined &&
        localStorage.getItem("comp_pref_filled") === "false" && (
          <FillPreferences
            open={openPreferences}
            handleClickOpen={handleClickOpenPreferences}
            handleClose={handleClosePreferences}
          />
        )} */}
    </>
  );
};

export default CompanyHomeComponent;

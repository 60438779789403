import { Box, Chip, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import Calender from "../../../assets/images/CalendarFigma.png";
import Time from "../../../assets/images/TimeCircle.png";
import Video from "../../../assets/images/Video.png";
import map from "../../../assets/images/map.png";

import ViewDetails from "./Modal";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Istatus } from "../../../utils/interviewStatus";
import EventIcon from "@material-ui/icons/Event";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import { LocationOnRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    BoxCard: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "25px",
      cursor: "pointer",
      border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none",
      boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[2],
    },
    root: {
      fontWeight: "bolder",
      color: theme.palette.type === "dark" ? "#ffffff" : "#424242",
      border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid #424242",
      backgroundColor: theme.palette.type === "dark" ? theme.palette.background.paper : "#F0FCFF",
    },
    root1: {
      fontWeight: "bolder",
      color: theme.palette.type === "dark" ? "#ffffff" : "#424242",
      border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "2px solid black",
      borderRadius: "30px",
    },
    greyBox: {
      width: "100%",
      backgroundColor: "#BDBDBD",
      borderRadius: "0px 0px 4px 4px",
      padding: "5px",
      display: "flex",
      justifyContent: "center",
    },
    InsideGreyBox: {
      width: "90%",
      backgroundColor: "#F2F2F2",
      borderRadius: "25px",
      padding: "20px 0 20px 1px",
      //   boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    },
  })
);

const InterviewCard = (props) => {
  const { data, onlyClosed } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [check, setCheck] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ViewDetails handleOpen={handleOpen} handleClose={handleClose} open={open} setOpen={setOpen} profile={data?.job_title} />
      <Box className={classes.BoxCard}>
        <Box style={{ padding: "20px" }}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Typography align="left" variant="h3">
                <b>
                  {data.job_title} - {data.job_type}
                </b>
              </Typography>
              <Typography align="left" variant="h6" style={{ display: "flex", alignItems: "center" }}>
                <b style={{ display: "flex", alignItems: "center" }}>
                  {data.interview_type === "ONLINE" ? (
                    "Online Interview"
                  ) : (
                    <>
                      <LocationOnRounded />
                      &nbsp; &nbsp; {data.address !== null ? data.address : null} -&nbsp;
                      {data.location.length > 0 && data.location.map((item, i) => <>{i !== data.location.length - 1 ? <>{item.city}, </> : <>{item.city}</>}</>)}
                    </>
                  )}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Chip className={classes.root} label="On Campus Job" color="primary" variant="outlined" />
              {/* {onlyClosed === false && (
                <IconButton aria-label="delete" onClick={() => handleOpen()}>
                  <DeleteIcon />
                </IconButton>
              )} */}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                    <EventIcon /> &nbsp; {data.start_date.slice(8, 10)}
                    {"."}
                    {data.start_date.slice(5, 7)}
                    {"."}
                    {data.start_date.slice(0, 4)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <AccessTimeIcon />
                    {data.start_date.slice(11, 16)}
                    {" - "}
                    {data.end_date.slice(11, 16)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                    <DirectionsBusIcon />
                    {data.time_slot} mins each
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          {data.interview_type === "ONLINE" && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <a
                  href={data.hangout_link}
                  target="_blank"
                  style={
                    onlyClosed === true
                      ? {
                          pointerEvents: "none",
                        }
                      : null
                  }
                >
                  <Typography variant="body2" style={{ color: "#3282C4" }}>
                    {" "}
                    <img src={Video} alt="calender" height="20px" /> &nbsp;
                    {data.hangout_link}
                  </Typography>
                </a>
              </Grid>
            </Grid>
          )}
          <br />
          <Divider />
          <br />
          <Typography align="left" variant="body1">
            <b> {data.total_slots} Candidates Selected</b>
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={11} style={{ marginTop: "10px" }}>
              {data.student_data.slice(0, 20).map((item, index) => (
                <>
                  <Chip className={classes.root1} label={item.user.first_name + " " + item.user.last_name} variant="outlined" /> &nbsp; &nbsp; &nbsp;
                </>
              ))}
            </Grid>
            <Grid item xs={1}>
              {check === false ? (
                <IconButton aria-label="delete" onClick={() => setCheck(true)}>
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="delete" onClick={() => setCheck(false)}>
                  <ExpandLessIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {check === true ? (
            <>
              <br />
              <Typography align="left" variant="body1">
                <b>Interview Timing Details</b>
              </Typography>
              <br />
              <Box className={classes.InsideGreyBox}>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <b>Timings</b>{" "}
                      </Grid>
                      <Grid item xs={3}>
                        <b>Full Name </b>{" "}
                      </Grid>
                      <Grid item xs={6}>
                        <b>Status </b>{" "}
                      </Grid>
                    </Grid>
                  </li>
                  {data.student_data.map((item, index) => (
                    <li>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <b>
                            {item.start_date.slice(11, 16)} - {item.end_date.slice(11, 16)}
                          </b>{" "}
                        </Grid>
                        <Grid item xs={3}>
                          {item.user.first_name} {item.user.last_name}
                        </Grid>
                        <Grid item xs={3}>
                          {Istatus(item.status)}
                        </Grid>
                      </Grid>
                    </li>
                  ))}
                </ul>
              </Box>
              {/* <br />
                <Typography align="left" variant="body1">
                  <b>Submitted Assignments</b>
                </Typography>
                <br />
                <Box className={classes.InsideGreyBox}>
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <Typography align="left" variant="body2">
                        <b>Vipin Sharma</b> - Vipin Sharma
                      </Typography>
                    </li>
                    <li>
                      <Typography align="left" variant="body2">
                        <b>02:00 - 02:30 pm</b> - Vipin Sharma
                      </Typography>
                    </li>
                  </ul>
                </Box> */}
            </>
          ) : null}
        </Box>
      </Box>
    </div>
  );
};

export default InterviewCard;

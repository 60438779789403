import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import FilterDrawer from "../FilterDrawer/FilterDrawer";
import ToogleSwitch from "./ToogleSwitch";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Icon } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       //  width: 250,
//     },
//   },
// };

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const PurpleSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "30px",
    border: "none",
  },
  button: {
    borderRadius: "30px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "30px",
    height: "40px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    // fontSize: "14px",
    transform: "translate(15px, 14px) scale(1)",
    color: "#c5c4ba",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "30px",
    display: "flex",
    height: "40px",
    paddingLeft: "30px",
    marginRight: "10px",
    paddingRight: "30px",
  },
  sortBtnIcon: {
    boxShadow: "none",
    /*  borderRadius: "0%", */
    /*  padding: "10px 5px", */
    height: "40px",
    "&:hover": {
      backgroundColor: "none",
      cursor: "pointer",
    },
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "30px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
  clearIndicator: {
    backgroundColor: "gray",
    "& span": {
      "& svg": {
        backgroundColor: "red",
      },
    },
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')",
        },
      },
    },
  },
}));

const variants = [
  {
    id: 3,
    name: "Voucher",
    slug: "voucher",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:27:23.000Z",
    updated_at: "2021-11-15T08:27:23.000Z",
    cover: null,
  },
  {
    id: 1,
    name: "Top Up",
    slug: "top-up",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:26:44.000Z",
    updated_at: "2021-11-15T08:26:44.000Z",
    cover: null,
  },
  {
    id: 2,
    name: "Game Key",
    slug: "game-key",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:27:03.000Z",
    updated_at: "2021-11-15T08:27:03.000Z",
    cover: null,
  },
  {
    id: 12,
    name: "Other",
    slug: "other",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:50.000Z",
    updated_at: "2021-11-15T08:30:50.000Z",
    cover: null,
  },
  {
    id: 11,
    name: "Nintendo",
    slug: "nintendo",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:22.000Z",
    updated_at: "2021-11-15T08:30:22.000Z",
    cover: null,
  },
  {
    id: 10,
    name: "Xbox",
    slug: "xbox",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:08.000Z",
    updated_at: "2021-11-15T08:30:08.000Z",
    cover: null,
  },
];

function ActionBar({
  ToggleSwitch,
  switchToggled,
  filterData,
  filterLoading,
  setSelectedFilters,
  selectedFilters,
  setClickFilterApplyButton,
  tableColumns,
  setTableColumns,
  searchByName,
  setSearchByName,
  filterDataError,
  setSort,
  sort,
  order,
  setOrder,
  isPublic,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });
  const [clearFilter, setClearFilter] = useState(false);

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const [variantName, setVariantName] = React.useState([
    // {
    //   id: 11,
    //   name: "Nintendo",
    //   slug: "nintendo",
    //   type: "SubMain",
    //   locale: "en",
    //   created_at: "2021-11-15T08:30:22.000Z",
    //   updated_at: "2021-11-15T08:30:22.000Z",
    //   cover: null,
    // },
    // {
    //   id: 10,
    //   name: "Xbox",
    //   slug: "xbox",
    //   type: "SubMain",
    //   locale: "en",
    //   created_at: "2021-11-15T08:30:08.000Z",
    //   updated_at: "2021-11-15T08:30:08.000Z",
    //   cover: null,
    // },
  ]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const preventDuplicate = value.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    setVariantName(
      // On autofill we get a the stringified value.
      typeof preventDuplicate === "string"
        ? preventDuplicate.split(",")
        : preventDuplicate
    );
  };

  const sortDataList = [
    { id: 6, name: "Relevance Score", value: "student_score" },
    { id: 9, name: "Applied On", value: "create_time" },
    { id: 5, name: "Notice Period", value: "notice_period" },
    { id: 3, name: "Expected Salary", value: "expected_ctc" },
    { id: 2, name: "Current Salary", value: "cur_ctc" },
    { id: 4, name: "Experience", value: "total_exp" },
    {
      id: 7,
      name: "Key Skill",
      value: "student_job_matching_data__key_skills",
    },
    {
      id: 8,
      name: "Comm Skilll Score",
      value: "student_job_matching_data__communication_skills",
    },
    { id: 1, name: "Candidate Name", value: "first_name,last_name" },
  ];

  return (
    <>
      <div>
        <Grid container spacing={4}>
          <Grid item sm={2}>
            <Paper className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search by Name"
                inputProps={{
                  style: { fontSize: "14px", padding: "9px 0 7px" },
                }}
                value={searchByName.searchString}
                onChange={(e) => {
                  setSearchByName({
                    ...searchByName,
                    searchString: e.target.value,
                  });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setSearchByName({ ...searchByName, searchNow: "search" });
                  }
                }}
              />
              {searchByName?.searchString && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setSearchByName({
                      ...searchByName,
                      searchString: "",
                      searchNow: "cancel",
                    });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setSearchByName({ ...searchByName, searchNow: "search" });
                }}
              >
                {/* {!clickSearchButton &&  ? <SearchIcon /> : <ClearIcon />} */}
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          {/* <Grid item xs={2}>
            <Autocomplete
              id="country-select-demo"
              options={data}
              getOptionLabel={(option) => option.title}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.title}
                </Typography>
              )}
              style={{ background: "#fff", borderRadius: 30 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Experiences"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              id="country-select-demo"
              options={data}
              getOptionLabel={(option) => option.title}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.title}
                </Typography>
              )}
              style={{ background: "#fff", borderRadius: 30 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Location"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={2} style={{ display: "grid" }}>
            <FilterDrawer
              filterData={filterData}
              filterLoading={filterLoading}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
              setClickFilterApplyButton={setClickFilterApplyButton}
              filterDataError={filterDataError}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />
          </Grid>
          {selectedFilters && Object.keys(selectedFilters)?.length && (
            <Grid item xs={2} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                style={{
                  /// color: "#6C757D",
                  borderRadius: "30px",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  backgroundColor: "#fff",
                  fontWeight: "normal",
                  border: "none",
                }}
                onClick={() => {
                  setSelectedFilters(null);
                  setClickFilterApplyButton(true);
                  setClearFilter(true);
                }}
              >
                Clear Filter
              </Button>
            </Grid>
          )}
          {/*   <Grid item xs={2} style={{ display: "grid" }}>
            <Button
              variant="outlined"
              style={{
                /// color: "#6C757D",
                borderRadius: "30px",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                backgroundColor: "#fff",
                fontWeight: "normal",
                border: "none",
              }}
              onClick={() => {
                setSelectedFilters(null);
                setClickFilterApplyButton(true);
                setClearFilter(true);
              }}
            >
              Clear Filter
            </Button>
          </Grid> */}
          <Grid item xs={2} style={{ display: "grid" }}>
            <ToogleSwitch
              ToggleSwitch={ToggleSwitch}
              switchToggled={switchToggled}
            />
          </Grid>
          {/* <Grid item xs={3}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FilterDrawer />
              <ToogleSwitch
                ToggleSwitch={ToggleSwitch}
                switchToggled={switchToggled}
              />
            </div>
          </Grid> */}
          {!switchToggled && !isPublic && (
            <Grid item xs={2} style={{ display: "grid" }}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                // style={{
                //   boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                //   borderColor: "none",
                // }}
              >
                <InputLabel
                  className={classes.floatingLabelFocusStyle}
                  id="demo-multiple-checkbox-label"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    right: "52px",
                    bottom: "0px",
                  }}
                >
                  Customize Table Field
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  inputProps={{
                    style: {
                      padding: "16px",
                      borderRadius: "30px",
                    },
                  }}
                  variant="outlined"
                  value={variantName}
                  label="Customize Table Field"
                  onChange={handleChange}
                  // input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) =>
                    selected.map((x) => x.name).join(", ")
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        maxWidth: "200px",
                        borderRadius: "15px",
                      },
                    },
                  }}
                >
                  {tableColumns.map((variant) => (
                    <MenuItem key={variant.field} value={variant}>
                      {/*  <Checkbox
                        color="primary"
                        checked={!variant?.hide}
                        onChange={(e) => {
                          let tempArray = [...tableColumns];
                          var itemIndex = tempArray.findIndex(
                            (x) => x.field === variant.field
                          );
                          var item = tempArray[itemIndex];
                          item.hide = !e.target.checked;
                          tempArray[itemIndex] = item;
                          setTableColumns(tempArray);
                        }}
                      /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={!variant?.hide}
                            onChange={(e) => {
                              let tempArray = [...tableColumns];
                              var itemIndex = tempArray.findIndex(
                                (x) => x.field === variant.field
                              );
                              var item = tempArray[itemIndex];
                              item.hide = !e.target.checked;
                              tempArray[itemIndex] = item;
                              setTableColumns(tempArray);
                            }}
                          />
                        }
                        label={variant.displayName}
                      />
                      {/* <ListItemText primary={variant.displayName} /> */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              id="country-select-demo"
              options={sortDataList}
              value={sort}
              disableClearable
              getOptionLabel={(option) => option.name}
              onChange={(e, val) => {
                if (val) {
                  setSort(val);
                }
              }}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.name}
                </Typography>
              )}
              style={{
                width: "95%",
                /*  background: "#fff",
                borderRadius: 30, */
                marginRight: "5px",
              }}
              renderInput={(params) => (
                <>
                  {" "}
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Sort"
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                  />
                </>
              )}
            />
            {order === "asc" ? (
              <Icon
                className={classes.sortBtnIcon}
                onClick={() => {
                  setOrder("dsc");
                }}
              >
                <ArrowUpwardIcon />
              </Icon>
            ) : (
              <Icon
                className={classes.sortBtnIcon}
                onClick={() => {
                  setOrder("asc");
                }}
              >
                <ArrowDownwardIcon />
              </Icon>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ActionBar;

import { Button, Chip, TextField, withStyles } from "@material-ui/core";

const CommonChip = withStyles({
  root: {
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: "solid 0.5px #007bff",
    backgroundColor: "#e8f3ff",
    color: "#007bff",
    fontWeight: "normal",
    marginRight: "8px",
    marginBottom: "8px",
    "&:hover": {
      backgroundColor: "#e8f3ff",
    },
    "&:active": {
      backgroundColor: "#e8f3ff",
    },
    "& .MuiChip-deleteIcon": {
      color: "#007bff",
      "&:hover": {
        color: "#0069d9",
      },
    },
  },
})(Chip);

export default CommonChip;

import React from 'react';

const SideImage=()=>{
    const imgStyles={
        backgroundImage: 'url("https://images.unsplash.com/photo-1564069114553-7215e1ff1890?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=889&q=80")',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }
    return(
        <>
        <div className="d-none d-md-flex col-md-4 col-lg-4" style={imgStyles} />
        </>
    )
}

export default SideImage;
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import gift from "../../../assets/gif/users.gif";
import refer from "../../../assets/common/refer.jpg";
import { Typography, InputBase, Divider, Paper, IconButton, Grid, DialogActions, CircularProgress, Hidden } from "@material-ui/core";
import CropFreeIcon from "@material-ui/icons/CropFree";
import logo from "../../../assets/img/getwork-logo.png";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";
import setNewCookie, { eraseCookie } from "../../../utils/common/Cookies";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: "30px",
  },
  paper: {
    padding: "8px 8px",
    display: "flex",
    alignItems: "center",
    border: "solid 0.5px rgba(0 0 0 0.12)",
    borderRadius: "12px",
    boxShadow: "rgba(0 0 0 0.25) 0px 54px 55px rgba(0 0 0 0.12) 0px -12px 30px rgba(0 0 0 0.12) 0px 4px 6px rgba(0 0 0 0.17) 0px 12px 13px rgba(0 0 0 0.09) 0px -3px 5px",
  },
  input: {
    marginLeft: theme.spacing(1),
    textAlign: "center",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  msg: {
    display: "flex",
    height: "200px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  btn: {
    borderRadius: "30px",
    backgroundColor: theme.palette.domain.main,
    paddingRight: "50px",
    paddingLeft: "50px",
    color: "#fff",

    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
    "&:disabled": {
      backgroundColor: theme.palette.domain.light,
      color: "#fff",
    },
  },
  img: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dialogcontent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
// Axios.defaults.withCredentials = true;

export default function PassCode(props) {
  const { open, handleClickOpen, handleClose, getJobData, passCode, setPassCode, jobId, loader, jobData } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const alert = useAlert();
  const history = useHistory();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <div>
      {/* <Button variant="contained" style={{backgroundColor:"#007bff", borderRadius:"30px", paddingLeft:"60px", paddingRight:"60px"}} onClick={handleClickOpen}>
       passcode 
      </Button> */}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,
            border: "solid 0.5px #e2e6ea",
            backgroundColor: "white",
            boxShadow: "rgba(149 157 165 0.2) 0px 8px 24px",
          },
        }}
        maxWidth="md"
        open={open}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {" "}
        <div className={classes.img} style={{}}>
          <img src={logo} height="50px" width="50px" style={{ padding: "4px" }} />
        </div>
        <Divider />
        <DialogContent className={classes.dialogcontent}>
          <Hidden smDown>
            {" "}
            <img src={refer} height="240px" width="470px" style={{ borderRadius: "30px" }} />
          </Hidden>
          <Hidden smUp>
            {" "}
            <img src={refer} height="170px" width="300px" style={{ borderRadius: "30px" }} />
          </Hidden>
          <div className={classes.msg}>
            <Typography variant="h6"> Fill the below passcode to Open Job Details</Typography>
            {/* <img src={refer} width="400px" height="200px" /> */}
            <div>
              {/* <Typography variant="h6"> Fill PassCode </Typography> */}
              <Paper className={classes.paper}>
                <InputBase className={classes.input} placeholder="0-0-0-0-0-0" value={passCode} onChange={(e) => setPassCode(e.target.value)} />
              </Paper>
            </div>
            <Button className={classes.btn} disabled={passCode ? false : true} onClick={() => getJobData()}>
              {loader ? <CircularProgress size={25} style={{ color: "#fff" }} /> : "Submit"}
            </Button>
          </div>
        </DialogContent>
        <Hidden smDown>
          <DialogActions style={{ display: "flex", justifyContent: "center", padding: 20 }}>
            If you are college or an employer. &nbsp;
            <span
              style={{ cursor: "pointer", color: "#007bff" }}
              onClick={() => {
                if (domain?.domain) history.push({ pathname: "/login/college", search: `?redirect=/public/job/${jobData?.hiring_type}/${jobId}` });
                else history.push({ pathname: "/signup/employer", search: `?redirect=/public/job/${jobData?.hiring_type}/${jobId}` });
              }}
            >
              Please login here to view the job.
            </span>
          </DialogActions>
        </Hidden>
        <Hidden smUp>
          <DialogActions style={{ display: "flex", justifyContent: "center", padding: 20, flexDirection: "column" }}>
            If you are college or an employer. &nbsp;
            <span
              style={{ cursor: "pointer", color: "#007bff" }}
              onClick={() => {
                if (domain?.domain) history.push({ pathname: "/login/college", search: `?redirect=/public/job/${jobData?.hiring_type}/${jobId}` });
                else history.push({ pathname: "/signup/employer", search: `?redirect=/public/job/${jobData?.hiring_type}/${jobId}` });
              }}
            >
              Please login here to view the job.
            </span>
          </DialogActions>
        </Hidden>
      </Dialog>
    </div>
  );
}

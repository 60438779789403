import {
  Box,
  createStyles,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import Basics from "../../../assets/Basics.png";
import Details from "../../../assets/Details.png";
import Qualifications from "../../../assets/Qualifications.png";
import Selection from "../../../assets/Selection.png";
import Hiring from "../../../assets/Hiring.png";
import tick from "../../../assets/tick.png";
import tick_yellow from "../../../assets/tick_yellow.png";

import red_dot from "../../../assets/red_dot.png";

const ColorlibConnector = withStyles({
  //   alternativeLabel: {
  //     top: 22,
  //   },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  vertical: {},
  line: {
    height: 3,
    // border: 2,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    display: "inline",
    marginLeft: "5%",
  },
  lineVertical: {
    minHeight: 24,
    borderLeftStyle: "solid",
    borderLeftWidth: 3,
  },
})(StepConnector);

const useColorlibStepIconStyles1 = makeStyles({
  root: {
    backgroundColor: "#c6ddff",
    zIndex: 1,
    color: "#4d95ff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      transform: "scale(1.1)",
      cursor: "pointer",
    },
  },
});
const useColorlibStepIconStyles2 = makeStyles({
  root: {
    backgroundColor: "#ffdae5    ",
    zIndex: 1,
    color: "#ff3d79",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      transform: "scale(1.1)",
      cursor: "pointer",
    },
  },
});

const useColorlibStepIconStyles3 = makeStyles({
  root: {
    backgroundColor: "#d4f4e8   ",
    zIndex: 1,
    color: "#50d1a1",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      transform: "scale(1.1)",
      cursor: "pointer",
    },
  },
});
const useColorlibStepIconStyles4 = makeStyles({
  root: {
    backgroundColor: "#ffb9b9  ",
    zIndex: 1,
    color: "#ff4242",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      transform: "scale(1.1)",
      cursor: "pointer",
    },
  },
});
const useColorlibStepIconStyles5 = makeStyles({
  root: {
    backgroundColor: "#dbc6ff ",
    zIndex: 1,
    color: "#a13fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      transform: "scale(1.1)",
      cursor: "pointer",
    },
  },
});
function ColorlibStepIcon1(props) {
  const classes = useColorlibStepIconStyles1();

  const icons = {
    1: <img src={Basics} alt="" height="30px" />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div className={clsx(classes.root, {})}>{icons[String(props.icon)]}</div>
  );
}
function ColorlibStepIcon2(props) {
  const classes = useColorlibStepIconStyles2();

  const icons = {
    1: <img src={Basics} alt="" />,
    2: <img src={Details} alt="" height="30px" />,
    3: <VideoLabelIcon />,
  };

  return (
    <div className={clsx(classes.root, {})}>{icons[String(props.icon)]}</div>
  );
}
function ColorlibStepIcon3(props) {
  const classes = useColorlibStepIconStyles3();

  const icons = {
    1: <img src={Basics} alt="" />,
    2: <img src={Details} alt="" height="30px" />,
    3: <img src={Qualifications} alt="" height="30px" />,
  };

  return (
    <div className={clsx(classes.root, {})}>{icons[String(props.icon)]}</div>
  );
}

function ColorlibStepIcon4(props) {
  const classes = useColorlibStepIconStyles4();

  const icons = {
    1: <img src={Basics} alt="" />,
    2: <img src={Details} alt="" height="30px" />,
    3: <VideoLabelIcon />,
    4: <img src={Selection} alt="" height="30px" />,
  };

  return (
    <div className={clsx(classes.root, {})}>{icons[String(props.icon)]}</div>
  );
}

function ColorlibStepIcon5(props) {
  const classes = useColorlibStepIconStyles5();

  const icons = {
    1: <img src={Basics} alt="" />,
    2: <img src={Details} alt="" height="30px" />,
    3: <VideoLabelIcon />,
    4: <VideoLabelIcon />,
    5: <img src={Hiring} alt="" height="30px" />,
  };

  return (
    <div className={clsx(classes.root, {})}>{icons[String(props.icon)]}</div>
  );
}

function getSteps() {
  return ["Basics", "Details", "Preferences"];
}

const StepBar = (props) => {
  const {
    detailsRef,
    executeScroll,
    basicsRef,
    basicsCheck,
    detailsCheck,
    preferencesCheck,
    selectionCheck,
    hiringCheck,
    preferencesYellowCheck,
  } = props;
  const classes = useStyles();

  const steps = getSteps();

  return (
    <>
      <Box className={classes.stepBarBox}>
        <Stepper
          connector={<ColorlibConnector />}
          orientation="vertical"
          style={{ borderRadius: 30 }}
        >
          <Step
            key={0}
            onClick={() => {
              executeScroll(0);
            }}
            className={classes.stepsPointer}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon1}>
              {/* {label} */}
              <span className={classes.stepName}>
                Basics {basicsCheck && <img src={tick} alt="" height="20px" />}
              </span>
            </StepLabel>
          </Step>
          <Step
            key={1}
            onClick={() => {
              executeScroll(1);
            }}
            className={classes.stepsPointer}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon2}>
              <span className={classes.stepName}>
                Details{detailsCheck && <img src={tick} alt="" height="20px" />}
              </span>
            </StepLabel>
          </Step>
          <Step
            key={2}
            onClick={() => {
              executeScroll(2);
            }}
            className={classes.stepsPointer}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon3}>
              <span className={classes.stepName}>
                Qualifications
                {preferencesCheck ? (
                  <img src={tick} alt="" height="20px" />
                ) : (
                  preferencesYellowCheck && (
                    <img src={tick_yellow} alt="" height="20px" />
                  )
                )}
              </span>
            </StepLabel>
          </Step>
          <Step
            key={3}
            onClick={() => {
              executeScroll(3);
            }}
            className={classes.stepsPointer}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon4}>
              <span className={classes.stepName}>
                Selection Procedure{" "}
                {selectionCheck && <img src={tick} alt="" height="20px" />}
              </span>
            </StepLabel>
          </Step>
          {localStorage.getItem("user_type") === "Company" && (
            <Step
              key={4}
              onClick={() => {
                executeScroll(4);
              }}
              className={classes.stepsPointer}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon5}>
                <span className={classes.stepName}>
                  Hiring Process{" "}
                  {hiringCheck && <img src={tick} alt="" height="20px" />}
                </span>
              </StepLabel>
            </Step>
          )}
        </Stepper>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    stepBarBox: {
      position: "fixed",
      top: "30%",
      //left: "20%",
    },
    stepName: {
      fontSize: "13px",
      color: "#828282",
      "&:hover": {
        transform: "scale(1.1)",
        cursor: "pointer",
      },
    },
    stepsPointer: {
      cursor: "pointer",
      transition: "all 0.3s ease",
      "&:hover": {
        transform: "scale(1.1)",
        cursor: "pointer",
      },
    },
    "@media only screen and (max-width: 1200px)": {
      stepBarBox: {
        //  left: "17%",
      },
      stepName: {
        fontSize: 12,
      },
    },
    "@media only screen and (max-width: 1400px)": {
      stepBarBox: {
        // left: "20%",
        top: "25%",
      },
      stepName: {
        fontSize: 12,
      },
    },
    "@media only screen and (max-width: 900px)": {
      stepBarBox: {
        //left: "7%",
      },
      stepName: {
        fontSize: 12,
      },
    },
    "@media only screen and (max-width: 750px)": {
      stepBarBox: {
        ///  left: "4%",
      },
      stepName: {
        fontSize: 12,
      },
    },
    "@media only screen and (max-width: 500px)": {
      stepBarBox: {
        //  left: "1%",
      },
      stepName: {
        fontSize: 12,
      },
    },
  })
);

export default StepBar;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Hidden,
  SvgIcon,
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import Social from "./Social";
import HireByCategories from "./HireByCategories";
import AboutGetWork from "./AboutGetWork";
import InternshipsByLocations from "./InternshipsByLocations";
import JobsByLocations from "./JobsByLocations";
import PostJobsInCity from "./PostJobsInCity";
import { NEXT_APP_ENDPOINT } from "../../../../constants/constants";
import play_store from "../../../../assets/png/Playstore-white.png";
import app_store from "../../../../assets/app_store.png";

import { Link } from "react-router-dom";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      id="footerBtn"
      style={{ fontFamily: "Nunito" }}
    >
      {"Copyright © "}
      <a color="inherit" href={`${NEXT_APP_ENDPOINT}`}>
        <span id="footerBtn" style={{ color: "#fff" }}>
          GetWork
        </span>
      </a>{" "}
      {new Date().getFullYear()}
      {". All rights reserved."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },
  img: {
    paddingLeft: "0px",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    // marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    //  paddingRight: "10px",
    //paddingLeft: "10px",
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  playstore: {
    borderRadius: "10px",
  },
  social: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "12px",
    color: "white",
  },
  linktext: {
    color: "white",
    paddingBottom: "10px",
  },
  text: {
    marginBottom: "7px",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <>
      {/* Footer */}
      {/* <Hidden smDown> */}
      <div style={{ backgroundColor: "#23272b" }}>
        <Container maxWidth="lg" component="footer" className={classes.footer}>
          <Grid container spacing={4} justify="space-evenly">
            <Grid item xs={12} sm={3} md={2}>
              <InternshipsByLocations />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <JobsByLocations />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <PostJobsInCity />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <HireByCategories />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <AboutGetWork />
            </Grid>
          </Grid>
          <br />
          <Divider style={{ backgroundColor: "#e5e9ec" }} />
          <Box mt={2}>
            <Grid container spacing={4} justify="space-evenly">
              <Grid
                item
                xs={12}
                sm={3}
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {" "}
                <Hidden smDown>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.getwork_app"
                    target="_blank"
                  >
                    <img
                      id="footerBtn"
                      src={play_store}
                      height="50px"
                      width="160px"
                      alt="playstore"
                      className={classes.playstore}
                    />
                  </a>
                  &nbsp;
                  <a
                    href="https://apps.apple.com/in/app/getwork/id1579830388"
                    target="_blank"
                  >
                    <img
                      id="footerBtn"
                      src={app_store}
                      height="50px"
                      width="160px"
                      alt="playstore"
                      className={classes.playstore}
                    />
                  </a>
                </Hidden>
                <Hidden smUp>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.getwork_app"
                    target="_blank"
                  >
                    <img
                      id="footerBtn"
                      src={play_store}
                      height="30px"
                      width="100px"
                      alt="playstore"
                      className={classes.playstore}
                    />
                  </a>
                  &nbsp;
                  <a
                    href="https://apps.apple.com/in/app/getwork/id1579830388"
                    target="_blank"
                  >
                    <img
                      id="footerBtn"
                      src={app_store}
                      height="30px"
                      width="100px"
                      alt="playstore"
                      className={classes.playstore}
                    />
                  </a>
                </Hidden>
                <div style={{ marginLeft: "20px" }}>
                  <Social />
                </div>
              </Grid>
              <Hidden smDown>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={6}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Copyright />
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={6}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Copyright />
                </Grid>
              </Hidden>
            </Grid>
          </Box>
        </Container>
      </div>
      {/* </Hidden> */}
      {/* End footer */}
    </>
  );
}

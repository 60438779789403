import {
  Box,
  createStyles,

  makeStyles,
  Theme
} from "@material-ui/core";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import NoReport from "../../../assets/images/reports/no-records.png";
import CompaniesVisited from "./CompaniesVisited/CompaniesVisited";
import CtcReport from "./CtcReport/CtcReport";
import LeadByIndustry from "./LeadByIndustry/LeadByIndustry";
import OpeningsReport from "./OpeningsReport/OpeningsReport";
import PlacementReport from "./PlacementReport/PlacementReport";
import SkillsReport from "./SkillsReport/SkillsReport";

const ReportsPanel = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Box>
      <Box className={classes.main}> 
        <Box className={classes.sidebar}>
          <NavLink
            to="/college/reports/leads-by-industry"
            activeClassName={classes.activeLink}
            className={classes.sidebarLinks}
          >
            Leads by Industry
          </NavLink>

          {/* <Divider className={classes.divider} /> */}

          <NavLink
            to="/college/reports/yearly-visit-report"
            activeClassName={classes.activeLink}
            className={classes.sidebarLinks}
          >
            Companies yearly Visit report
          </NavLink>

          {/* <Divider className={classes.divider} /> */}

          <NavLink
            to="/college/reports/ctc-report"
            activeClassName={classes.activeLink}
            className={classes.sidebarLinks}
          >
            CTC Yearly Report
          </NavLink>

          {/* <Divider className={classes.divider} /> */}

          <NavLink
            to="/college/reports/placement-report"
            activeClassName={classes.activeLink}
            className={classes.sidebarLinks}
          >
            Yearly Placement report
          </NavLink>

          {/* <Divider className={classes.divider} /> */}

          <NavLink
            to="/college/reports/skills-report"
            activeClassName={classes.activeLink}
            className={classes.sidebarLinks}
          >
            Skills report
          </NavLink>

          {/* <Divider className={classes.divider} /> */}

          <NavLink
            to="/college/reports/openings-report"
            activeClassName={classes.activeLink}
            className={classes.sidebarLinks}
          >
            Job Openings report
          </NavLink>
        </Box>

        <Box width="100%" marginLeft="255px" height="calc(100vh - 64px)">
          {location.pathname === "/college/reports" ? (
            <Box className={classes.mainBox}>
              <img src={NoReport} alt="no-report" />
              <p className={classes.noReportText}>No Reports</p>
              <p className={classes.selectReportText}>
                Select a report from left to view
              </p>
            </Box>
          ) : location.pathname === "/college/reports/leads-by-industry" ? (
            <LeadByIndustry />
          ) : location.pathname === "/college/reports/ctc-report" ? (
            <CtcReport />
          ) : location.pathname === "/college/reports/yearly-visit-report" ? (
            <CompaniesVisited />
          ) : location.pathname === "/college/reports/skills-report" ? (
            <SkillsReport />
          ) : location.pathname === "/college/reports/placement-report" ? (
            <PlacementReport />
          ) : (
            <OpeningsReport />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      background: theme.palette.primary.dark,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    createButton: {
      width: "220px",
      height: "44px",
      background: "#3282C4",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      fontSize: "18px",
      color: "#fff",
      fontFamily: "ProximaNova",
      textTransform: "capitalize",
      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    sidebar: {
      //background: theme.palette.background.default,
      //boxShadow:  theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      padding: "20px 2px",
      height: "auto",
      width: "250px",
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      borderRight:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
    },
    sidebarLinks: {
      backgroundColor:"#F8F9FA",
      padding:"10px",
      borderRadius:"30px",
      fontSize: "16px",
      fontFamily: "ProximaNova",
      textTransform: "capitalize",
      margin: "3px 0px 3px 9px",
      color: "black",
    },
    mainBox: {
      height: "calc(100vh - 64px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      background: theme.palette.background.default,
    },
    noReportText: {
      fontSize: "20px",
      color: "#4A4A4A",
      fontFamily: "ProximaNova",
    },
    selectReportText: {
      fontSize: "18px",
      fontFamily: "Proximanova",
      color: "#6B6B6B",
    },
    activeLink: {
      color: "#3282C4",
      fontWeight: "bold",
      marginLeft: "9PX",
    },
    divider: {
      height: "2px",
      margin: "4px 0px",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#F2F2F2",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
      "&:hover": {
        backgroundColor: "#F2F2F2",
      },
      marginLeft: "82%",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        //   marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#424242",
    },
    inputRoot: {
      color: "#424242",
    },
    main:{
    display:"flex",
    paddingLeft:"4%",
    paddingRight:"4%"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

export default ReportsPanel;

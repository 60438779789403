import { Hidden } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Footer from "../../../components/public/FooterNew/Footer";
import EmailVerify from "../../../components/public/LogIn/Card/Verifications/emailVerify";
import MobileTabRegister from "../../../components/public/LogIn/Header/MobileTabRegister";
import StudentHeader from "../../../components/public/LogIn/Header/StudentHeader";
import Navbar from "../../../components/public/Navbar/Navbar";
import { BackendBaseApi } from "../../../constants/constants";

function EmailConfirmation() {
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [timerComplete, setTimerComplete] = React.useState(false);
  const sendAgain = () => {
    Axios.post(BackendBaseApi.PRAVESH + "api/signup/send_verification_mail/", {
      user_id: location.user_id,
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setTimerComplete(false);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Some Error Occurred!");
      });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Navbar />

      <Hidden smDown>
        {" "}
        <StudentHeader showGrid={false} verifyEmail={true} type={"verify-email"} timerComplete={timerComplete} setTimerComplete={setTimerComplete} handleSubmit={sendAgain} />
      </Hidden>

      <Hidden smUp>
        {" "}
        <div style={{ marginTop: "20%" }}>
          <EmailVerify timerComplete={timerComplete} setTimerComplete={setTimerComplete} handleSubmit={sendAgain} />
        </div>
      </Hidden>
      <Footer />
    </>
  );
}

export default EmailConfirmation;

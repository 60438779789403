import React, { useState, useEffect } from 'react';
import { useAlert } from "react-alert";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Button, CircularProgress } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

import viewResume from "../../../assets/images/details.png";
import uploadPDF from "../../../assets/images/pdf.png";

import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  hr: {
    margin:'0px 4px',
  }
}));

function UploadResume({ handleClose, openResumeModal }) {

  const classes = useStyles();
  const alert = useAlert();

  const [resumeFile, setResumeFile] = useState(null);
  const [resumeUploading, setResumeUploading] = useState(false);

  let user_details = JSON.parse(localStorage.getItem('user_details'));

  const closeModal = () => { handleClose() };

  const TestFileType = (fileName, fileTypes) => {
    if (!fileName) return;

    let dots = fileName.split(".");
    //get the part AFTER the LAST period.
    let fileType = dots[dots.length - 1];

    if (fileTypes.includes(fileType)) {
      alert.success("Resume file format is ok !");
      return true;
    } 
    else {
      alert.error("Please only upload files with .pdf, .jpeg, .png, .docx format!"+ " Please select a new pdf file and try again.");
      return false;
    }
  };

  const updateResume = (e) => {
    e.preventDefault();
    if(!resumeFile) { alert.error("Upload Resume to Continue !!"); return; }
    if(!TestFileType(resumeFile.name, ["pdf","jpeg","png","docx"])) { return; }
    setResumeUploading(true);
    let resumeData = new FormData();
    resumeData.append("resume", resumeFile);
    axios.patch(
        BackendBaseApi.PRAVESH + `api/education/student_details`,
        resumeData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "Token " + localStorage.getItem("gw_token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let student = JSON.parse(localStorage.getItem('user_details'));
          student['student_user_details']['resume'] = res.data.data.data.resume;
          localStorage.setItem('user_details', JSON.stringify(student));
          alert.success('Resume Uploaded Successfully !!');
          closeModal();
        } 
        else alert.error(res.data.error);
        setResumeUploading(false);
      })
      .catch((err) => {
        alert.error("There was some error updating your details. Please try again later");
        throw err;
      });
  }

  return (
    <Dialog
        open={openResumeModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle id="form-dialog-title" style={{paddingBottom:'4px'}}>
          <span style={{fontSize:'22px'}}>Upload Resume</span>
        </DialogTitle>
        <hr className={classes.hr} />
        <DialogContent component={'span'}>
          <DialogContentText component={'span'}>
            <b style={{fontSize:'18px', color:'black'}}>Upload your most up-to-date Resume or CV</b>
            <br />
            <span style={{fontSize:'14px'}}>File types allowed : PDF, DOCX, JPEG, PNG </span>            
          </DialogContentText>
          <Grid item xs={12}  style={{marginTop:'1rem'}}>
            <Paper className={classes.paper}>
              {
                !resumeUploading
                ?
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  {
                    user_details?.student_user_details && user_details.student_user_details.resume
                    ?
                    <div style={{display:'flex', flexDirection:'row', marginTop:'0.5rem', marginBottom:'0.5rem'}}>
                      <img 
                        src={viewResume} 
                        onClick={() => window.open(user_details.student_user_details.resume)}
                        alt="resume" 
                        style={{cursor:'pointer', marginRight: '0.5rem'}}
                      />
                      <span>View Your Current Resume</span>
                    </div>
                    :
                    null
                  }
                  {/* <img 
                      src={uploadPDF} 
                      onClick={ e=> window.document.getElementById('my-resume-upload').click() }
                      alt="pdf"
                      style={{ height:'60px', width:'50px', cursor:'pointer' }}
                  /> */}
                  <input
                    type="file"
                    id='my-resume-upload'
                    // style={{visibility:'hidden', width:0, height:0}} 
                    onChange={e => setResumeFile(e.target.files[0])}
                    accept=".docx,.pdf,.png,.jpeg"
                  />
                  {/* <span 
                      style={{fontSize:'13px', color:'#3282C4', cursor:'pointer'}}
                      onClick={ e=> window.document.getElementById('my-resume-upload').click() }
                  >
                      Upload Your Resume {" "}
                      {resumeFile && ` (${resumeFile.name})`}
                  </span> */}
                </div>               
                :
                <CircularProgress
                  style={{display:'block', margin:'auto', marginTop:'1rem'}}
                />
              }              
            </Paper>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            <span style={{fontSize:'16px'}}>Cancel</span>
          </Button>
          <Button onClick={e=>updateResume(e)} color="primary">
            <span style={{fontSize:'16px'}}>Upload</span>
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default UploadResume;

import React from "react";
import { Box, makeStyles, createStyles } from "@material-ui/core";

const Profile = ({ data }) => {
    const classes = useStyles();
    return (
        <Box>
            <p>profile card</p>
        </Box>
    );
};

const useStyles = makeStyles((theme) => createStyles({}));

export default Profile;

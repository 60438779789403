import { Typography, TextField, Grid, Hidden, AppBar, Toolbar, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Logo from "../../../assets/png/logo-long.png";
import EnterOtp from "./EnterOtp";
import { BackendBaseApi, emailRegex } from "../../../constants/constants";
import Axios from "axios";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {/* <Typography variant="h6">{children}</Typography> */}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
    },
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    height: "48px",
    fontSize: "16px",
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "#007bff",
  },

  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
  },

  appBar: {
    position: "relative",
    backgroundColor: "#fff",
  },
  title: {
    flex: 1,
    textAlign: "center",
    marginRight: "30px",
    fontFamily: "Nunito",
  },
  card: {
    paddingBottom: "20px",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
      fontFamily: "Nunito",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);

export default function Otp({ handleClose, open, type, openOtp, setOpenOtp, otpDetails, setOtpDetails, otpDetailsError, setOtpDetailsError }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const alert = useAlert();
  // const [fullWidth, setFullWidth] = React.useState(true);
  // const [maxWidth, setMaxWidth] = React.useState("xs");

  const [query, setQuery] = useState(null);
  const [mainQuery, setMainQuery] = useState(null);

  const handleCloseotp = () => {
    setOpenOtp(false);
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleClickOpenotp = () => {
    if (!otpDetails?.email) {
      setOtpDetailsError({ ...otpDetailsError, email: "Please Enter Email" });
      return;
    }
    if (otpDetails?.email && !emailRegex.test(otpDetails?.email)) {
      setOtpDetailsError({ ...otpDetailsError, email: "Please Enter Correct Email" });
      return;
    }
    setLoadingSubmit(true);
    Axios.post(`${BackendBaseApi.PRAVESH}api/generate_otp/`, { email: otpDetails?.email, user_type: type.toLowerCase() })
      .then((res) => {
        if (res.data.success) {
          setOpenOtp(true);
          handleClose();
        } else {
          if (res.data.data.code === 2 || res.data.data.code === 1) {
            // alert.error(res.data.data.code_text);
            setOtpDetailsError({ ...otpDetailsError, email: res.data.data.code_text, user_type: true });
          } else alert.error("Some Error Occurred");
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  return (
    <div>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        // fullWidth={fullWidth}
        // maxWidth={maxWidth}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Hidden smUp>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <ArrowBackIcon style={{ color: "#000" }} />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Enter Email ID to Login
              </Typography>
            </Toolbar>
          </AppBar>
        </Hidden>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
        <div style={{ padding: "20px" }}>
          <Hidden smDown>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                fontFamily: "Nunito",
                fontWeight: "bold",
                marginBottom: "40px",
              }}
            >
              Enter Email ID to Login
            </Typography>
          </Hidden>

          <Hidden smUp>
            <div style={{ padding: "20px", textAlign: "center" }}>
              <img src={Logo} height="40px" alt="getwork-logo" style={{ marginBottom: "50px" }} />
            </div>
          </Hidden>

          <div className={classes.card}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} style={{ display: "grid" }}>
                <CssTextField
                  className={classes.margin}
                  label="Email"
                  variant="outlined"
                  id="custom-css-outlined-input"
                  error={otpDetailsError?.email ? true : false}
                  helperText={
                    <span className={!otpDetailsError?.email ? classes.errorPassword : classes.noErrorPassword}>
                      {otpDetailsError?.user_type ? (
                        <>
                          <Typography
                            style={{
                              fontFamily: "Nunito",
                              fontSize: "12px",
                              fontWeight: "bold",
                              padding: 12,
                            }}
                          >
                            <span style={{ color: "#ed1111" }}> {otpDetailsError?.email}</span>{" "}
                            <Link to={type === 'student' ? `/candidate/signup` : `/signup/employer`}>
                              <span style={{ color: "#007bff", cursor: "pointer" }}> Create Account </span>
                            </Link>
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          style={{
                            fontFamily: "Nunito",
                            fontSize: "12px",
                            fontWeight: "bold",
                            padding: 12,
                          }}
                        >
                          <span style={{ color: "#ed1111" }}> {otpDetailsError?.email}</span>{" "}
                        </Typography>
                      )}
                    </span>
                  }
                  value={otpDetails?.email}
                  onChange={(e) => {
                    setOtpDetails({ ...otpDetails, email: e.target.value });
                    setOtpDetailsError({ ...otpDetailsError, email: "", user_type: false });
                  }}
                  onKeyPress={(data) => {
                    if (data.charCode === 13) {
                      handleCloseotp();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ display: "grid" }}>
                {" "}
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleClickOpenotp}
                >
                  {loadingSubmit ? <CircularProgress style={{ color: "#fff" }} size={18} /> : "Get OTP"}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

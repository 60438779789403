import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Form from "../Card/Form";
import { useParams } from "react-router-dom";
import { ButtonBase, Grid, Button } from "@material-ui/core";
import { AndroidView, IOSView } from "react-device-detect";
import google from "../../../../assets/new/google.svg";
import apple from "../../../../assets/new/apple.svg";
import RegisterForm from "../Card/Form/RegisterForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "16%",
    // width: 500,
  },
  blackBox: {
    backgroundColor: "black",
    padding: 10,
    color: "#fff",
    marginTop: -5,
  },
  tabs: {
    color: theme.palette.domain.main,
    backgroundColor: "#fff",
    active_tab: {
      color: "red",
    },
  },
}));

export default function MobileTabApply(props) {
  const {
    type,
    email,
    setEmail,
    setPassword,
    password,
    loginUser,
    emailErrorMessage,
    passwordErrorMessage,
    setEmailErrorMessage,
    setPasswordErrorMessage,
    responseGoogle,
    responseFacebook,
    loadingLogin,
    buttonClicked,
    setButtonClicked,
    fromApplyPage,

    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    handleSubmit,
    loading,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const params = useParams();
  const [value, setValue] = React.useState(1);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          //  textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label="Login to Apply"
            {...a11yProps(0)}
            onClick={() => {
              setValue(0);
            }}
          />
          <Tab
            label="Register to Apply"
            {...a11yProps(1)}
            onClick={() => {
              setValue(1);
            }}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Form
            type={"Student"}
            email={email}
            setEmail={setEmail}
            setPassword={setPassword}
            password={password}
            loginUser={loginUser}
            emailErrorMessage={emailErrorMessage}
            passwordErrorMessage={passwordErrorMessage}
            setEmailErrorMessage={setEmailErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            responseGoogle={responseGoogle}
            responseFacebook={responseFacebook}
            loading={loadingLogin}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
            fromApplyPage={true}
            setValue={setValue}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <RegisterForm
            type={"Student"}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            handleSubmit={handleSubmit}
            loading={loading}
            fromApplyPage={true}
            setValue={setValue}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthHeader from "../../../common/components/UI/AuthHeader";
import SideImage from "../../../common/components/UI/SideImage";
import Countdown from "react-countdown";
import { makeStyles } from "@material-ui/core";
import Axios from "axios";
import { BackendBaseApi } from "../../../../constants/constants";

const EmailConfirmation = () => {
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [timerComplete, setTimerComplete] = React.useState(false);
  const classes = useStyles();
  const sendAgain = () => {
    Axios.post(BackendBaseApi.PRAVESH + "api/signup/send_verification_mail/", {
      user_id: location.user_id,
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setTimerComplete(false);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Some Error Occurred!");
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div className="col-md-8 col-lg-8 y-scroll full-page-view" style={{ backgroundColor: "#ffffff" }}>
            <div className="row">
              <AuthHeader />
            </div>
            <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-6 mx-auto">
                    <div className="row justify-content-center mx-auto my-2">
                      <i class="fas fa-envelope-square fa-5x"></i>
                    </div>
                    <div className="row justify-content-center mx-auto my-2">
                      <p className="fs-18 fw-500">Great! You've successfully signed up for GetWork.</p>
                      <p className="fs-14">
                        We've sent you a link to confirm your email address. Please use that link to confirm your email and then{" "}
                        <Link to="/login">
                          <b>login </b>
                        </Link>
                        again.
                      </p>
                      <p className="fs-14" style={{ textAlign: "center" }}>
                        <b> Didn’t receive the email?</b>
                        <br />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <span
                            className={timerComplete ? classes.link : classes.linkDisabled}
                            onClick={() => {
                              if (timerComplete) {
                                if (location.user_id) sendAgain();
                                else alert.error("Login Again and then try again");
                              }
                            }}
                          >
                            {" "}
                            Click here &nbsp;
                          </span>
                          to send the mail again in&nbsp;{" "}
                          {!timerComplete ? (
                            <Countdown
                              date={Date.now() + 30000}
                              onComplete={() => setTimerComplete(true)}
                              renderer={(props) => (
                                <div>
                                  <b>{props.seconds + "s"}</b>
                                </div>
                              )}
                            />
                          ) : (
                            <div>
                              <b>{0 + "s"}</b>
                            </div>
                          )}
                          &nbsp; again.
                        </div>
                      </p>
                    </div>
                    <div className="row mt-4 fs-12 text-left helper-box">
                      <p>
                        <b>Still Didn’t receive the email?</b>
                      </p>
                      <br></br>
                      <ul>
                        <li>
                          <p>
                            Is {location.email} your correct email without typos? If not, you can
                            <Link to="signup"> restart the sign up process</Link>
                          </p>
                        </li>
                        <li>
                          <p>Check your spam folder</p>
                        </li>
                        <li>
                          <p>
                            Add{" "}
                            <span>
                              <a href="mailto:getwork@m.joingetwork.com" target="_blank">
                                getwork@m.joingetwork.com
                              </a>
                            </span>{" "}
                            to your contacts
                          </p>
                        </li>
                      </ul>
                      <p>
                        <b>Still having trouble? </b>
                      </p>
                      <br /> &nbsp;
                      <a href="mailto:getwork@m.joingetwork.com" target="_blank">
                        <p>Contact us</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row justify-content-center mx-auto my-2">
                <i class="fas fa-envelope-square fa-5x"></i>
            </div>
            <div className="row justify-content-center mx-auto my-2">
                <p className="fs-18 fw-500">
                Great! You've successfully signed up for GetWork.
                </p>
                <p className="fs-14">
                We've sent you a link to confirm your email address. Please check your inbox. It could take up to 10 minutes to show up in your inbox.

                </p>
            </div>
            <div className="row mt-4 fs-12 text-left helper-box">

                    <p><b>Didn’t receive the email?</b></p><br></br>
                    <ul>
                        <li><p>Is {location.email} your correct email without typos? If not, you can 
                        <a href=""> restart the sign up process</a></p></li>
                        <li><p>Check your spam folder</p></li>
                        <li><p>Add getwork@m.joingetwork.com to your contacts</p></li>
                        <li><p>Click here to resend the email</p></li>
                    </ul>

                <p><b>Still having trouble? </b></p><br/> &nbsp;
                <p><Link to="/company/register"> Contact us</Link></p>
                
              
            

            </div> */}
    </>
  );
};

export default EmailConfirmation;
const useStyles = makeStyles({
  link: {
    cursor: "pointer",
    color: "black",
    "&:hover": {
      color: "rgb(50, 130, 196)",
    },
  },
  linkDisabled: {
    cursor: "no-allowed",
    color: "grey",
  },
});

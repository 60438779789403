export const tableColumns = [
  {
    id: 1,
    field: "name",
    displayName: "Candidate Name",
  },
  {
    id: 2,
    field: "email",
    displayName: "Email",
  },
  {
    id: 3,
    field: "phone",
    displayName: "Phone",
  },
  {
    id: 4,
    field: "current_salary",
    displayName: "Current Salary",
  },
  {
    id: 5,
    field: "expected_salary",
    displayName: "Expected Salary",
  },
  {
    id: 6,
    field: "location",
    displayName: "Current Location",
  },
  {
    id: 7,
    field: "experience",
    displayName: "Experience",
  },
  {
    id: 8,
    field: "current_title",
    displayName: "Current Designation",
  },
  {
    id: 9,
    field: "notice_period",
    displayName: "Notice Period",
  },
  {
    id: 10,
    field: "skills",
    displayName: "Key Skills",
  },
  {
    id: 11,
    field: "score",
    displayName: "Relevance Score",
  },
  {
    id: 12,
    field: "key_skill_score",
    displayName: "Key Skill Score",
  },
  {
    id: 13,
    field: "communication_score",
    displayName: "Communication Skill Score",
  },
  {
    field: "College Name",
    displayName: "College Name",
    d: 17,
  },
  {
    id: 14,
    field: "education",
    displayName: "Highest Education",
  },


  {
    id: 15,
    field: "preferred_location",
    displayName: "Prefered Location",
  },
  // {
  //   id: 13,
  //   field: "industry",
  //   displayName: "Industry",
  // },
  {
    field: "applied_on",
    displayName: "Applied On",
    id: 18,
  },
  {
    field: "resume",
    displayName: "Resume",
    id: 19,
  },
  {
    field: "assignment_list",
    displayName: "Assignment",
    id: 20,
  },
  {
    id: 16,
    field: "Comments",
    displayName: "Comments",
  },
  
];

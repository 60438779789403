import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import Countdown from "react-countdown";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

export default function EnterOtp({
  setLoginStep,
  email,
  setEmail,
  checkUser,
  otpEditStep,
  setPrefillData,
  setPrefillCompany,
  redirectTo,
  sendText,
}) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [timerComplete, setTimerComplete] = React.useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const handleChange = (code) => {
    setCode(code);
    setCodeError(null);
  };

  const helper2 = (data) => {
    if (data.success) {
      setTimerComplete(false);
    } else {
      if (data?.data?.code === 2) {
        alert.error(data?.data?.code_text);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            fontWeight: "500",
            padding: 12,
          }}
        >
          <span style={{ color: "#707070" }}>Sent to</span>{" "}
          <span style={{ color: "#007bff" }}> {email}</span>
        </Typography>
        <EditIcon
          style={{
            color: "#007bff",
            fontSize: "18px",
            cursor: "pointer",
            marginLeft: "-7px",
          }}
          onClick={() => {
            setLoginStep(1);
          }}
        />
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "400",
                  padding: 6,
                }}
              >
                Enter OTP
              </Typography>
              <OtpInput
                value={code}
                onChange={(e) => handleChange(e)}
                numInputs={4}
                separator={<span style={{ width: "20px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: ".5px solid #a5a5a5",
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "20px",
                  fontFamily: "Nunito",
                  // color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: 12,
                }}
              >
                <span style={{ color: "#ed1111" }}> </span>{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  padding: 12,
                }}
              >
                <span
                  style={
                    timerComplete
                      ? { color: "#007bff", cursor: "pointer" }
                      : { color: "#707070" }
                  }
                >
                  Resend OTP
                </span>
                {!timerComplete && (
                  <span style={{ color: "#707070" }}>{""}&nbsp; in </span>
                )}
                <span style={{ color: "#007bff" }}>
                  {!timerComplete && (
                    <Countdown
                      date={Date.now() + 30000}
                      onComplete={() => setTimerComplete(true)}
                      renderer={(props) => (
                        <div>
                          <b>{props.seconds + " sec"}</b>
                        </div>
                      )}
                    />
                  )}
                </span>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} style={{ display: "grid" }}>
            {" "}
            <Button
              variant="contained"
              sx={{
                display: "flex",
                borderRadius: "30px",
                boxShadow: "none",
                height: "40px",
                fontWeight: "400",
                fontSize: "15px",
              }}
              color="primary"
            >
              {loadingSubmit ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                " Verify"
              )}
            </Button>
            <Typography
              variant="body2"
              style={{
                color: "#A3A3A3",
                textAlign: "center",
                marginTop: "20px",
                // marginBottom: "20px",
                // fontWeight: "600",
                fontWeight: "500",
                padding: 12,
              }}
            >
              Check spam folders if email is not in the inbox.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

import { Box, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getSkills, setRef } from "../action";
import ReportsControlPanel from "../ReportsControlPanel";
import ReportsTable from "../ReportsTable";

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const SkillsReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef();

  const skills = useSelector((state) => state.Reports.skills);

  useEffect(() => {
    if (!skills) {
      dispatch(getSkills());
    }
  }, []);

  useEffect(() => {
    dispatch(setRef(ref));
  }, []);

  function hashCode(str) {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  const data = {
    labels: skills ? skills.skills.map((item) => item.skill_name) : [],
    datasets: [
      {
        label: "# of Votes",
        data: skills ? skills.skills.map((item) => item.count) : [],
        backgroundColor: skills
          ? skills.skills.map(
              (item) => `#${intToRGB(hashCode(item.skill_name))}`
            )
          : [],
        borderColor: skills
          ? skills.skills.map(
              (item) => `#${intToRGB(hashCode(item.skill_name))}`
            )
          : [],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box
      padding="2%"
      width="100%"
      style={{ maxHeight: "100%", overflowY: "scroll" }}
    >
      <ReportsControlPanel
        // buttons={["Show Chart", "Export", "Send Email"]}
        buttons={["Export"]}
        title="Skills Report"
      />
      {/* <Pdf targetRef={ref} filename="code-example.pdf">
                {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
            </Pdf> */}
      <p>Summary Report</p>
      <ReportsTable
        columns={[
          "Total Students",
          "Total Skills",
          "Most Popular Skill",
          "Avg. Skills per Student",
        ]}
        data={[skills]}
      />
      <Box className={classes.chartBox} ref={ref}>
        <HorizontalBar data={data} options={options} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    chartBox: {
      background: theme.palette.background.paper,
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
      borderRadius: "25px",
      padding: "2%",
    },
  })
);

export default SkillsReport;

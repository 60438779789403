import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { NEXT_APP_ENDPOINT } from "../../../../../constants/constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },

  linktext: {
    color: "white",
    paddingBottom: "10px",
    "&:hover": {
      textDecoration: "underline",
      color: "white",
    },
  },
  text: {
    marginBottom: "7px",
    fontSize: 14,
    fontFamily: "Nunito",
  },
  title: {
    color: "#fff",
    fontSize: 20,
    fontFamily: "Nunito",
  },
}));

export default function HireByCategories() {
  const classes = useStyles();

  const profiles = [
    {
      name: "Sales and Business Development",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/sales-and-business-development-profiles`,

      title: "Sales & Business Development Profiles",
      content:
        "Hire pre-verified quality sales & business development professionals candidates for your organization.",
      student: true,
      employer: true,
    },

    {
      name: "Digital Marketing",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/digital-marketing-profiles`,
      title: "Digital Marketing Profiles",
      content:
        "Hire pre-verified quality digital marketing professionals for your organization.",
      student: true,
      employer: true,
    },
    {
      name: "Product Development",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/product-development-profiles`,
      title: "Product Development Profiles",
      content:
        "Hire pre-verified quality product development professionals for your organization.",
      student: false,
      employer: true,
    },
    {
      name: "Human Resources",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/human-resources-profiles`,
      title: "Human Resources Profiles",
      content:
        "Hire pre-verified quality HR professionals for your organization.",
      student: true,
      employer: true,
    },

    {
      name: "Software Development",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/software-development-profiles`,
      title: "Software Development Profiles",
      content:
        "Hire pre-verified quality software development professionals for your organization. ",
      student: true,
      employer: true,
    },

    {
      name: "Operations",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/operations-profiles`,
      title: "Operations Profiles",
      content:
        " Hire pre-verified quality operations professionals for  your organization.",
      student: true,
      employer: true,
    },

    {
      name: "Customer Support",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/customer-support-profiles`,
      title: "Customer Support Profiles",
      content:
        " Hire pre-verified quality customer support professionals for your organization.",
      student: false,
      employer: true,
    },
    {
      name: "Core Engineering",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/core-engineering-profiles`,
      title: "Core Engineering Profiles",
      content: "Hire pre-verified quality engineers for your organization.",
      student: false,
      employer: true,
    },
    {
      name: "Graphic Design",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/graphic-design-profiles`,
      title: "Graphic Design Profiles",
      // content: "Hire pre-verified quality engineers for your organization.",
      student: false,
      employer: false,
    },
    {
      name: "Accountancy and Finance",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/accountancy-and-finance-profiles`,
      title: "Accountancy & Finance Profiles",
      // content: "Hire pre-verified quality engineers for your organization.",
      student: false,
      employer: false,
    },
    {
      name: "Data Science",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/data-science-profiles`,
      title: "Data Science Profiles",
      // content: "Hire pre-verified quality engineers for your organization.",
      student: false,
      employer: false,
    },
    {
      name: "Content Writing",
      link: `${NEXT_APP_ENDPOINT}/hire-candidates/content-writing-profiles`,
      title: "Content Writing Profiles",
      // content: "Hire pre-verified quality engineers for your organization.",
      student: false,
      employer: false,
    },
  ];

  return (
    <>
      <Typography variant="h4" className={classes.title} gutterBottom>
        Hire by Categories
      </Typography>
      <br />
      {profiles.map((item) => (
        <a href={item.link} className={classes.linktext}>
          <Typography
            variant="body2"
            color="inherit"
            className={classes.text}
            id="footerBtn"
          >
            {item.title}
          </Typography>{" "}
        </a>
      ))}
    </>
  );
}

import { Avatar, Box, Button, Container, createStyles, Divider, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import GoogleLogin from "react-google-login";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DefaultComp from "../../../assets/images/com.png";
import { BackendBaseApi, GOOGLE_CLIENT_ID } from "../../../constants/constants";
import PublicNavbar from "./PublicNavbar";
import "./publicprofile.css";

export default function Index({ handleClosey, userId, id }) {
  const params = useParams();
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const [project, setProjectData] = useState([]);
  const [profile, setProfileData] = useState(null);
  const [education, setEducationData] = useState([]);
  const [allData, setAllData] = useState(null);
  const [exp, setExpData] = useState([]);
  const [skill, setSkillData] = useState([]);
  const [currentEducation, setCurrentEducation] = useState({});
  const [showMore, setShowmore] = useState(false);
  const [googleAccessToken, setgoogleAccessToken] = useState("");
  const [loading, setLoading] = useState(false);

  const checkRoute = () => {
    if (location.pathname !== "/studen/dashboard" || location.pathname !== "/studen/connection") {
      if (params.id.includes("-")) {
        var split = params.id.split("-");
        var new_id = split[split.length - 1];
        return new_id;
      } else return params.id;
    } else return id;
  };

  const getPublicData = async () => {
    const res = await Axios.get(`${BackendBaseApi.PRAVESH}api/shared/public_profile/?user_id=${checkRoute()}`).catch((err) => {
      throw err;
    });
    setAllData(res.data.data);
  };

  useEffect(() => {
    getPublicData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allData !== null) {
      setEducationData(allData.student_user_details.education);
      setProjectData(allData.student_user_details.project);
      setExpData(allData.student_user_details.work_ex);
      setSkillData(allData.student_user_details.skill);
      setCurrentEducation(allData.student_user_details.education.find((item) => item.is_current === true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  const ellipsify = (str) => {
    if (str && str.length > 150 && !showMore) {
      return str.substring(0, 150);
    } else {
      return str;
    }
  };

  const checkClearBitDomain = (name) => {
    if (name === "LinkedIn") {
      return ".cn";
    } else return ".com";
  };

  const responseGoogle = (response) => {
    setgoogleAccessToken(response.accessToken);
  };
  function validURL(str) {
    var pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return !!pattern.test(str);
  }

  useEffect(() => {
    let token = "";

    if (googleAccessToken) {
      Axios.post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
        },
        withCredentials: true,
        provider: "google-oauth2",
        access_token: googleAccessToken,
      },
      )
        .then((res) => {
          if (res.data.success) {
            token = res.data.token;

            localStorage.setItem("gw_token", token);
            setLoading(true);
            checkUser(token);
          } else {
            alert.error("Error");
          }
        })
        .catch((err) => {
          alert.error("Error");
          throw err;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAccessToken]);

  const checkUser = (token) => {
    Axios.get(BackendBaseApi.PRAVESH + "api/profile/details/", {
      headers: {
        Authorization: "Token " + token,
      },
      withCredentials: true,
    })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          alert.success("User Already Create!! Please Complete Your Profile");
          if (!res.data.email_verified) {
            //move to signup options component
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              email: res.data.email,
              token,
              isemailVerified: false,
            });
          } else {
            //move to signup options component
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              token,
              email: res.data.email,
              isemailVerified: true,
            });
          }
        } else {
          alert.success("Login Successful");
          localStorage.setItem("user_type", res.data.user_type[0].main_user);
          if (res.data.email_verified) {
            localStorage.setItem("gw_token", token);
            //check if its student or company
            if (res.data.user_type[0].type === 1) {
              //student with verified email, go to home
              if (location.flag == "102") {
                history.push("/student/open");
              } else {
                history.push("/student/dashboard");
              }
            } else if (res.data.user_type[0].type === 7) {
              history.push("/college/dashboard");
            } else if (res.data.user_type[0].type === 8) {
              history.push("/college/dashboard");
            } else {
              //company user with verified email, check if admin approved
              if (!res.data.company_details.admin_approved) {
                history.push({
                  pathname: "/company/admin-approve",
                  flag: 19087,

                  company: res.data.company_details.company_name,
                  admin_email: res.data.company_details.admin_email,
                  admin_name: res.data.company_details.admin_name,
                  is_admin: res.data.is_company_admin,
                });
              } else {
                localStorage.setItem("company", JSON.stringify(res.data.company_details));

                history.push("/company/dashboard");
              }
            }
          } else {
            //check if its student or company
            if (res.data.user_type[0].type === 1) {
              localStorage.setItem("gw_token", token);
              //student without verified email, go to home

              history.push("/student/dashboard");
            } else {
              //company user without verified email
              history.push({
                pathname: "/confirm-email",
                email: res.data.data.email,
              });
            }
          }
        }

        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
      });
  };

  return (
    <Box>
      <PublicNavbar />
      <Container style={{ marginTop: "8%" }}>
        <Grid container spacing={4}>
          <Grid item sm={3} xs={12} className={classes.topCont}>
            <Box className={classes.sidebar}>
              <Avatar src={allData?.profile_picture} className={classes.profilePic} />
              <p className={classes.name}>
                {allData?.first_name} {allData?.last_name}
              </p>
              <Typography className={classes.eduDetails}>{currentEducation?.degree}</Typography>

              <Typography className={classes.collegeDetails}>{currentEducation?.college_name}</Typography>

              <Hidden smDown>
                <Divider style={{ width: "100%", margin: "12% 0%" }} />
                {localStorage.getItem("gw_token") === null && (
                  <>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        textAlign: "center",
                        margin: "7% 0%",
                      }}
                    >
                      Sign up to connect with <br />
                      {allData?.first_name} {allData?.last_name}
                    </Typography>
                    <GoogleLogin
                      // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
                      clientId={GOOGLE_CLIENT_ID}
                      render={(renderProps) => (
                        <a href="" onClick={renderProps.onClick} className="btn shadow_1 azm-social azm-size-48 azm-circle azm-long-shadow azm-google-plus">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      )}
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                    />
                    {/* <Button
                    variant="outlined"
                    startIcon={<img src={googleIcon} />}
                    className={classes.socialLoginButton}
                  >
                    Sign in with Google
                  </Button> */}
                    <Box display="flex" alignItems="center" width="100%" justifyContent="space-between" margin="5% 0%">
                      <Divider style={{ width: "42%", backgroundColor: "#424242" }} />
                      or
                      <Divider style={{ width: "42%", backgroundColor: "#424242" }} />
                    </Box>
                    <Link to="/signup">
                      <Button variant="outlined" className={classes.socialLoginButton} startIcon={<MailOutline style={{ fontSize: "36px" }} />}>
                        Sign up with email
                      </Button>
                    </Link>
                  </>
                )}
                <Box width="100%" marginTop="15%">
                  {allData?.profile_url?.length > 0 &&
                    allData.profile_url.map((item, index) => (
                      <Box key={index} display="flex" alignItems="center" marginBottom="10px">
                        <Grid container alignItems="center">
                          <Grid item sm={4} align="center">
                            <img
                              onClick={() => window.open(`//${item.Value || item.value}`)}
                              src={"//logo.clearbit.com/" + item.name + checkClearBitDomain(item.name)}
                              style={{
                                height: "30px",
                                width: "auto",
                                cursor: "pointer",
                              }}
                            />
                          </Grid>
                          <Grid item sm={8}>
                            <a href={`//${item.Value || item.value}`} target="_blank">
                              <Typography style={{ cursor: "pointer" }}>{item.name}</Typography>
                            </a>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                </Box>
              </Hidden>
            </Box>
          </Grid>
          <Grid item sm={9} className={classes.sideCont}>
            <Box>
              {allData?.student_user_details?.work_ex.length > 0 && (
                <Box className={classes.card}>
                  <Typography className={classes.cardTitle}>Experience</Typography>
                  {allData.student_user_details.work_ex.map((item, index) => (
                    <Box key={index}>
                      <Grid container spacing={3}>
                        <Hidden smDown>
                          <Grid item sm={1}>
                            <img width="100%" height="auto" src={item.company_logo !== null ? item.company_logo : DefaultComp} />
                          </Grid>
                        </Hidden>
                        <Grid item sm={11}>
                          <Typography className={classes.detailText}>
                            <b>{item.company_name}</b>, {item.job_designation}
                          </Typography>
                          <Typography className={classes.detailText}>
                            {moment(item.start_date).format("MMM, YYYY")} - {item.end_date !== null ? moment(item.end_date).format("MMM, YYYY") : "Present"}{" "}
                            <b>{moment(item.start_date).diff(item.end_date !== null ? item.end_date : moment(), "months")} months</b>
                          </Typography>
                          <Typography className={classes.detailText} style={{ marginTop: "8px" }}>
                            {item.job_description}
                          </Typography>
                          <a target="blank" href={validURL(item.company_website) ? item.company_website : "//" + item.company_website}>
                            <Typography
                              className={classes.detailText}
                              onClick={() => (validURL(item.company_website) ? window.open(item.company_website) : window.open("//" + item.company_website))}
                              style={{ cursor: "pointer", color: "#3282c4" }}
                            >
                              {item.company_website}
                            </Typography>
                          </a>
                          <Divider style={{ width: "100%", margin: "4% 0%" }} />
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              )}

              {allData?.student_user_details?.education.length > 0 && (
                <Box className={classes.card}>
                  <Typography className={classes.cardTitle}>Education</Typography>
                  {allData.student_user_details.education.map((item, index) => (
                    <Box key={index}>
                      <Grid container spacing={3}>
                        <Hidden smDown>
                          <Grid item sm={1}>
                            <img width="100%" height="auto" src={item.college_logo !== null ? item.college_logo : DefaultComp} />
                          </Grid>
                        </Hidden>
                        <Grid item sm={11}>
                          <Typography className={classes.detailText}>
                            <b>
                              {[1, 2].includes(item.education)
                                ? item.highest_qualification
                                : item.degree
                                ? item.degree + (item.specialization ? ", " + item.specialization : "")
                                : item.highest_qualification}
                            </b>
                          </Typography>
                          <Typography className={classes.detailText}>
                            <b>{item.college_name}</b>
                          </Typography>
                          <Typography className={classes.detailText}>
                            {item.is_current === true
                              ? item.start_date
                                ? moment(item.start_date).format("YYYY") + " - " + (item.end_date ? moment(item.end_date).format("YYYY") : "Till Date")
                                : "NA"
                              : item.end_date
                              ? moment(item.end_date).format("YYYY")
                              : ""}
                          </Typography>
                          <Divider style={{ width: "100%", margin: "4% 0%" }} />
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              )}

              {allData?.student_user_details?.skill.length > 0 && (
                <Box className={classes.card}>
                  <Typography className={classes.cardTitle}>Skills</Typography>
                  {allData.student_user_details.skill.map((item, index) => (
                    <Box key={index} padding="8px 0px">
                      <Typography className={classes.detailText}>
                        <b>{item.skill_name}</b> - {item.rating_name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}

              {allData?.student_user_details?.project.length > 0 && (
                <Box className={classes.card}>
                  <Typography className={classes.cardTitle}>Projects</Typography>
                  {allData.student_user_details.project.map((item, index) => (
                    <Box key={index} padding="8px 0px">
                      <Typography className={classes.detailText}>
                        <b>{item.title}</b>
                      </Typography>
                      <Typography className={classes.detailText}>{item.description}</Typography>
                      <a target="blank" href={validURL(item.links) ? item.links : "//" + item.links}>
                        <Typography className={classes.detailText} style={{ cursor: "pointer", color: "#3282c4" }}>
                          {item.links}
                        </Typography>
                      </a>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sidebar: {
      boxShadow: "0 10px 25px 0 rgb(172,185,196)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "10%",
    },
    profilePic: {
      width: "34%",
      height: "auto",
    },
    name: {
      fontSize: 18,
      marginTop: 10,
      fontWeight: "bold",
      marginBottom: "0px",
    },
    eduDetails: {
      fontSize: "16px",
      color: "#424242",
    },
    collegeDetails: {
      fontSize: "14px",
      color: "#424242",
    },
    about: {
      fontSize: "12px",
      color: "#424242",
      margin: "10% 0%",
    },
    showMoreButton: {
      color: "#3282c4",
      cursor: "pointer",
    },
    socialLoginButton: {
      border: "2px solid #3282c4",
      color: "#3282c4",
    },
    cardTitle: {
      fontSize: 18,
      fontWeight: "bold",
      textDecoration: "underline",
      marginBottom: "3%",
    },
    card: {
      boxShadow: "0 10px 25px 0 rgb(172,185,196)",
      padding: "3%",
      marginBottom: "5%",
    },
    detailText: {
      color: "#424242",
      fontSize: "14px",
    },
    topCont: {
      height: "87vh",
      overflowY: "scroll",
      scrollbarWidth: "none",
    },
    sideCont: {
      height: "87vh",
      overflowY: "scroll",
    },
    "@media only screen and (max-width: 768px)": {
      topCont: {
        marginTop: "54px",
        height: "auto",
        overflowY: "auto",
      },
      sideCont: {
        height: "auto",
        overflowY: "auto",
      },
    },
  })
);

import React from "react";
import { Container, makeStyles } from "@material-ui/core";
import Navbar from "../../components/public/Navbar/Navbar";
import MuiCustomTheme from "../components/Theme/theme";
import JobNavbar from "../components/Layout/JobNavbar";
import ContinueEmployer from "./ContinueEmployer";
import Footer from "../../components/public/FooterNew/Footer";

const useStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
});

export default function NewSignUp() {
  const classes = useStyles();
  return (
    <>
      <JobNavbar />
      <Navbar toggled />
      <Container
        maxWidth="lg"
        component="main"
        className={classes.header}
        disableGutters
      >
        <MuiCustomTheme>
          <div style={{ marginTop: "5px", width: "100%" }}>
            <ContinueEmployer />
          </div>
        </MuiCustomTheme>
       
      </Container>
      <Footer />
    </>
  );
}

import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import { DataGrid, useGridSlotComponentProps } from "@material-ui/data-grid";
import React from "react";

const useStyles = makeStyles((theme) => ({
  lay: {
    width: "100%",
    "@media only screen and (min-width: 2000px)": {
      height: "600px",
    },
    "@media only screen and (max-width: 2000px)": {
      height: "500px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "410px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "330px",
    },
  },

  root: {
    display: "flex",
    justifyContent: "center",
  },
}));

const rows = [
  {
    id: 1,
    name: "Rohan Singh Prakash",
    asalary: "10 LPA",
    esalary: "16 LPA",
    location: "Gurgaon",
    experience: "3+ Year",
    noticeperiod: "45 Days",
    skills: "React.js, Node.js, Web development",
    score: "8.5 High Match",
    education: "B Tech. in Computer Science",
    plocation: "Gurgaon, Noida, Delhi, Bangalore",
    industry: "IT",
    email: "rohansingh@gmail.com",
  },
  {
    id: 2,
    name: "Rohan Singh Prakash",
    asalary: "10 LPA",
    esalary: "16 LPA",
    location: "Gurgaon",
    experience: "3+ Year",
    noticeperiod: "45 Days",
    skills: "React.js, Node.js, Web development",
    score: "8.5 High Match",
    education: "B Tech. in Computer Science",
    plocation: "Gurgaon, Noida, Delhi, Bangalore",
    industry: "IT",
    email: "rohansingh@gmail.com",
  },
  {
    id: 3,
    name: "Rohan Singh Prakash",
    asalary: "10 LPA",
    esalary: "16 LPA",
    location: "Gurgaon",
    experience: "3+ Year",
    noticeperiod: "45 Days",
    skills: "React.js, Node.js, Web development",
    score: "8.5 High Match",
    education: "B Tech. in Computer Science",
    plocation: "Gurgaon, Noida, Delhi, Bangalore",
    industry: "IT",
    email: "rohansingh@gmail.com",
  },
  {
    id: 4,
    name: "Rohan Singh Prakash",
    asalary: "10 LPA",
    esalary: "16 LPA",
    location: "Gurgaon",
    experience: "3+ Year",
    noticeperiod: "45 Days",
    skills: "React.js, Node.js, Web development",
    score: "8.5 High Match",
    education: "B Tech. in Computer Science",
    plocation: "Gurgaon, Noida, Delhi, Bangalore",
    industry: "IT",
    email: "rohansingh@gmail.com",
  },
  {
    id: 5,
    name: "Rohan Singh Prakash",
    asalary: "10 LPA",
    esalary: "16 LPA",
    location: "Gurgaon",
    experience: "3+ Year",
    noticeperiod: "45 Days",
    skills: "React.js, Node.js, Web development",
    score: "8.5 High Match",
    education: "B Tech. in Computer Science",
    plocation: "Gurgaon, Noida, Delhi, Bangalore",
    industry: "IT",
    email: "rohansingh@gmail.com",
  },
];

export default function DummyTableOnly({ columns }) {
  const classes = useStyles();
  const [pageSize, setPageSize] = React.useState(5);
  return (
    <div className={classes.lay}>
      <DataGrid
        style={{
          borderRadius: "20px",
          backgroundColor: "#fff",
          border: "none",
          textAlign: "center",
          //display: "flex",
          // flexDirection: "column",
          //alignItems: "center",
        }}
        columns={columns}
        rows={rows}
        disableColumnSelector
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20]}
        pagination
        checkboxSelection
        disableSelectionOnClick
        componentsProps={{
          pagination: {
            style: {
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            },
          },
        }}
      />
    </div>
  );
}

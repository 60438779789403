import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import React from "react";

const useStyles = makeStyles((theme) => ({
  include: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#c5c4ba",
    //fontSize: "14px",
    transform: "translate(15px, 14px) scale(1)",
  },
}));

export default function Calendar(props) {
  const { title, selectedDate, setSelectedDate } = props;
  const classes = useStyles();
  // The first commit of Material-UI

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* <Grid container justifyContent="flex-start"> */}
      <div
        style={{
          borderRadius: "30px",
          border: "1px soild black",
          // backgroundColor: "#fff",
        }}
      >
        <KeyboardDatePicker
          InputLabelProps={{
            className: classes.floatingLabelFocusStyle,
          }}
          //margin="normal"
          //   id="Tentative Date"
          label={title}
          inputVariant="outlined"
          InputProps={{
            classes: { root: classes.inputRoot },
          }}
          inputProps={{
            style: {
              padding: "16px",
              // backgroundColor: "#fff",
              fontSize: "14px",
              color: "#6c757d",
              borderRadius: "30px",
            },
            readOnly: true,
          }}
          format="MM/dd/yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          clearable
        />
      </div>
      {/* </Grid> */}
    </MuiPickersUtilsProvider>
  );
}

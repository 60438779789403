import { Avatar, Box, Button, Card, CardContent, Chip, createStyles, Divider, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AccountBalanceWallet, BeenhereOutlined, ClassOutlined, CreditCardOutlined, FiberManualRecord, LockOutlined, QueryBuilderOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import RoomIcon from "@material-ui/icons/Room";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import companyLogo from "../../../assets/company/png/de.png";
import { BackendBaseApi } from "../../../constants/constants";
import kFormatter, { LFormatter } from "../../../utils/common/ZeroToK";
import { BootstrapTooltipNew } from "../../common/Tooltip/Tooltip";
import Loading from "../customHooks/Loading";
import "./ViewJob.css";
import {GetMentoredButton} from "../../../components/student/Mentorship/GetMentoredButtton";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#3e3d3d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const JobViewStyles = {
  Page: {
    height: "auto",
    background: "#E5E5E5",
  },
  Logo: {
    height: "100px",
    width: "100px",
  },
};

const useStyles = makeStyles((theme) =>
  createStyles({
    mainCont: {
      marginTop: "0px",
      height: "82vh !important",
      boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)",
    },
    table: {
      minWidth: 500,
    },
    chip: {
      color: "#007bff",
      backgroundColor: "#e8f3ff",
      borderColor: "#007bff",
    },
    chipsection: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      actionButton: {
        color: "#ffffff",
        fontWeight: "700",
        borderRadius:"30px"
      },
    },
    actionButton: {
      color: "#ffffff",
      fontWeight: "700",
      borderRadius:"30px"
    },
  })
);

const ViewJob = ({
  data,
  handleClosey,
  open,
  apply,
  hide,
  closeIcon,
  index,
  appliedList,
  setAppliedList,
  invite,
  reject,
  setInviteList,
  inviteList,
  rejectList,
  setRejectList,
  fromApplied,
  isLoading
}) => {
  const classes = useStyles();
  const [isApply, setIsApply] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [qualificationData, setQualificationData] = useState({});
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const temp = {};
    if (data?.hiring_type === "OFF CAMPUS" && data.eligibility_criteria?.courses) {
      data.eligibility_criteria.courses.forEach((item) => {
        if (temp[item.degree_name] === undefined) {
          temp[item.degree_name] = [{ ...item }];
        } else {
          temp[item.degree_name].push({ ...item });
        }
      });
      setQualificationData(temp);
    }
  }, [data]);

  const generateCsv = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += (item.specialization_name ? item.specialization_name : item.name) + " | ")
        : (str += item.specialization_name ? item.specialization_name : item.name)
    );
    return str;
  };

  return (
    <>
      {data !== null ? (
        <Grid
          container
          justify="center"
          style={{ height: "100%" }}
        >
          <Grid xs={12} style={{ marginTop: "0" }}>
            <Card style={{ height: "100vh", overflowY: "scroll", scrollbarWidth: "none" }}>
              <CardContent style={{ paddingLeft: "2%" }}>
                {!closeIcon && (
                  <span
                    className="float-right pointer"
                    onClick={(e) => {
                      // e.stopPropagation();
                      handleClosey(false);
                    }}
                  >
                    <CloseIcon />
                  </span>
                )}

                <Grid container>
                  <Grid xs={12} style={{ marginTop: "0px" }}>
                    <Grid container>
                      <Grid xs={12} sm={10}>
                        <h4
                          style={{
                            fontSize: "20px",
                            fontFamily: "ProximaNova",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          <b>{data.job_title}</b>
                        </h4>
                      </Grid>
                      <Grid xs={12} sm={10} style={{ marginTop: "0px" }}>
                        <Box display="flex" alignItems="center" marginBottom="10px">
                          <Box>
                            <Avatar width="40px" height="auto"
                              src={data?.company?.company_website ? `//logo.clearbit.com/${data?.company?.company_website}` : companyLogo}
                              onError={(e) => {
                                e.target.onerror = null
                                e.target.src = companyLogo
                              }}
                            />
                          </Box>
                          <Box marginLeft="10px">
                            <Typography
                              style={{
                                color: "rgb(66,66,66)",
                                fontSize: "14px",
                              }}
                            >
                              {data.company ? data.company.company_name : data.company_details}
                            </Typography>
                            <Typography
                              style={{
                                color: "rgb(66,66,66)",
                                fontSize: "14px",
                              }}
                            >
                              {data.company?.company_location}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid container className="mg-top-10">
                        {data.ctc_min !== 0 && data.ctc_max1 === 0 ? (
                          <Grid xs={12} sm={4} className="mg-top-10">
                            <b className="flex align-item-center">
                              <CreditCardOutlined style={{ fontSize: "20px", marginRight: "4px" }} />
                              <h6
                                className="viewjob_heading flex align-item-center"
                                style={{
                                  fontSize: "16px",
                                  marginBottom: "0px",
                                }}
                              >
                                {" "}
                                Compensation
                              </h6>
                            </b>
                            <div className="flex align-item-center viewjob_data">
                              <p
                                style={{
                                  fontFamily: "ProximaNova",
                                  fontSize: "14px",
                                  color: "#424242",
                                }}
                              >
                                ₹ {data.ctc_min > 99999 ? LFormatter(data.ctc_min) : kFormatter(data.ctc_min)} -{" "}
                                {data.ctc_max > 99999 ? LFormatter(data.ctc_max) : kFormatter(data.ctc_max)} {data.salary_payment_type}
                              </p>
                            </div>
                          </Grid>
                        ) : null}
                        <Grid xs={12} sm={4} className="mg-top-10">
                          <b className="flex align-item-center">
                            <div className="flex align-item-center">
                              <WorkOutlineIcon style={{ fontSize: "20px", marginRight: "4px" }} />
                              <p className="mg-0" style={{ fontSize: "16px" }}>
                                <b>Job Type</b>
                              </p>{" "}
                            </div>
                          </b>
                          <div className="mg-left-10 viewjob_data" style={{ fontSize: "14px", marginLeft: "0px" }}>
                            {data.job_type_name}
                          </div>
                        </Grid>

                        <Grid xs={12} sm={4} className="mg-top-10">
                          <b className="flex align-item-center">
                            <div className="flex align-item-center">
                              <ClassOutlined style={{ fontSize: "20px", marginRight: "4px" }} />
                              <p className="mg-0" style={{ fontSize: "16px" }}>
                                <b>Employment type</b>
                              </p>
                            </div>
                          </b>
                          <div className="mg-left-10 viewjob_data" style={{ fontSize: "14px", marginLeft: "0px" }}>
                            {data.employment_type_name}
                          </div>
                        </Grid>

                        <Grid xs={12} sm={4} className="mg-top-10" style={{ marginLeft: "0px" }}>
                          <b>
                            {" "}
                            <div className="flex align-item-center">
                              <RoomIcon style={{ fontSize: "20px", marginRight: "4px" }} />
                              <p className="mg-0" style={{ fontSize: "16px" }}>
                                <b>Office Location</b>
                              </p>
                            </div>
                          </b>
                          <div className="viewjob_data" style={{ fontSize: "14px", marginLeft: "0px" }}>
                            {data?.job_location?.length > 0
                              ? data?.job_location?.map((item) => {
                                    return item.city;
                                  })
                                  .join(", ")
                              : "PAN India"}
                          </div>
                        </Grid>

                        {data.apply_start_date && data.apply_end_date && (
                          <Grid xs={12} sm={4} className="mg-top-10">
                            <b>
                              <div className="flex align-item-center">
                                <QueryBuilderOutlined
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "4px",
                                  }}
                                />
                                <p className="mg-0" style={{ fontSize: "16px" }}>
                                  <b>Job Deadline</b>
                                </p>
                              </div>
                            </b>
                            <div className="mg-left-10 viewjob_data" style={{ fontSize: "14px", marginLeft: "0px" }}>
                              {/* {data.apply_start_date.slice(8, 10)}
                              {"/"}
                              {data.apply_start_date.slice(5, 7)}
                              {"/"}
                              {data.apply_start_date.slice(0, 4)}{" "} */}
                              {data.apply_end_date && data.college && data.college.length > 0 ? (
                                <>
                                  {data.college[0].apply_end_date.slice(8, 10)}
                                  {"/"}
                                  {data.college[0].apply_end_date.slice(5, 7)}
                                  {"/"}
                                  {data.college[0].apply_end_date.slice(0, 4)}
                                </>
                              ) : (
                                <>
                                  {data.apply_end_date.slice(8, 10)}
                                  {"/"}
                                  {data.apply_end_date.slice(5, 7)}
                                  {"/"}
                                  {data.apply_end_date.slice(0, 4)}
                                </>
                              )}
                            </div>
                          </Grid>
                        )}

                        {data.job_type_name === "Internship" && (
                          <Grid xs={12} sm={4} className="mg-top-10">
                            <b>
                              <div className="flex align-item-center">
                                <BeenhereOutlined
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "4px",
                                  }}
                                />
                                <p className="mg-0" style={{ fontSize: "16px" }}>
                                  <b>PPO</b>
                                </p>
                              </div>
                            </b>
                            <div className="mg-left-10 viewjob_data" style={{ fontSize: "14px", marginLeft: "0px" }}>
                              {data.ppo === true ? "YES" : "NO"}
                            </div>
                          </Grid>
                        )}

                        <Grid xs={6} sm={4} className="mg-top-10" style={{ marginLeft: "0px", marginTop: "16px" }}>
                          <b className="flex align-item-center">
                            {/* <AccountBalanceWallet
                              style={{ fontSize: "20px", marginRight: "4px" }}
                            /> */}
                            <h6
                              className="viewjob_heading"
                              style={{
                                fontSize: "16px",
                                marginBottom: "0px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AccountBalanceWallet style={{ fontSize: "20px", marginRight: "4px" }} />
                              Equity Range
                            </h6>
                          </b>
                          <div className="viewjob_data">
                            <p
                              style={{
                                fontFamily: "ProximaNova",
                                fontSize: "14px",
                                color: "#424242",
                              }}
                            >
                              {data.equity_min === 0 && data.equity_max === 0 ? "No equity" : `${data.equity_min} % - ${data.equity_max} %`}
                            </p>
                          </div>
                        </Grid>
                        {data.service_bond !== 0 && (
                          <Grid xs={12} sm={4} style={{ marginTop: "16px" }}>
                            <b>
                              <div className="flex align-item-center">
                                <LockOutlined
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "4px",
                                  }}
                                />
                                <p className="mg-0" style={{ fontSize: "16px" }}>
                                  <b>Service Bond</b>
                                </p>
                              </div>
                            </b>
                            <div className="mg-left-10 viewjob_data" style={{ fontSize: "14px", marginLeft: "0px" }}>
                              {data.service_bond} months
                            </div>
                          </Grid>
                        )}

                        {data.job_type_name === "Internship" && (
                          <Grid xs={12} sm={4} className="mg-top-10">
                            <b>
                              <div className="flex align-item-center">
                                <QueryBuilderOutlined
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "4px",
                                  }}
                                />
                                <p className="mg-0" style={{ fontSize: "16px" }}>
                                  <b>Internship Duration</b>
                                </p>
                              </div>
                            </b>
                            <div className="mg-left-10 viewjob_data" style={{ fontSize: "14px", marginLeft: "0px" }}>
                              {data.job_duration_start && moment(data.job_duration_start).format("DD MMM'YY")}
                              {data.job_duration_start && data.job_duration_end && "  to  "}
                              {data.job_duration_end && moment(data.job_duration_end).format("DD MMM'YY")}
                              {/* {data.job_duration_end ? (
                                <>
                                  {data.job_duration_start.slice(8, 10)}
                                  {"/"}
                                  {data.job_duration_start.slice(5, 7)}
                                  {"/"}
                                  {data.job_duration_start.slice(0, 4)}
                                </>
                              ) : (
                                <>
                                  {data.job_duration_end.slice(8, 10)}
                                  {"/"}
                                  {data.job_duration_end.slice(5, 7)}
                                  {"/"}
                                  {data.job_duration_end.slice(0, 4)}
                                </>
                              )} */}
                            </div>
                          </Grid>
                        )}
                      </Grid>

                      <br />
                      <Grid container>
                        <Grid
                          xs={12}
                          className="flex"
                          justify="flex-end"
                          style={{
                            borderBottom: "1px solid #DEDEDE",
                            paddingBottom: 10,
                            marginTop: "16px",
                          }}
                        >
                          {hide && (
                            <>
                              {" "}
                              <HiddenBtn isHide={isHide} setIsHide={setIsHide} jobId={data.id} reject={false} />{" "}
                            </>
                          )}
                          {apply && (
                            <>
                            <GetMentoredButton jobRole={data?.job_role_name}/>
                              {" "}
                              <ApplyButton
                                isHide={isHide}
                                setIsHide={setIsHide}
                                jobId={data.id}
                                reject={false}
                                setIsApply={setIsApply}
                                appliedList={appliedList}
                                appliedListFun={setAppliedList}
                                index={index}
                                collegeId={JSON.parse(localStorage.getItem("user_details")).student_user_details.education.find((item) => item.is_current === true)?.college}
                              />{" "}
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <hr />
                      <Divider />
                      <br />

                      <Grid container>
                        <Grid x={12} className="mg-top-10">
                          <b>
                            <h6 className="viewjob_heading" style={{ fontSize: "16px" }}>
                              Job Description
                            </h6>
                          </b>
                          <div
                            style={{ fontSize: "14px", textAlign: "justify" }}
                            className="viewjob_data"
                            dangerouslySetInnerHTML={{
                              __html: data.job_description,
                            }}
                          />
                        </Grid>
                      </Grid>

                      {data.hiring_type === "ON CAMPUS" && (
                        <div className="row my-5 text-left pl-2">
                          <h6>
                            <b>On Campus Hiring - College Information</b>
                          </h6>
                          <br />

                          {/* <div className="col-md-12">
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="customized table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>College</StyledTableCell>
                                    <StyledTableCell align="left">
                                      Degrees
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      Specializations
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                    Status
                                  </StyledTableCell>
                                    <StyledTableCell align="left">
                                    Apply Start Date
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    Apply End Date
                                  </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data.college.map((colgObj, ind) => (
                                    <StyledTableRow key={ind}>
                                      <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.name}
                                        </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {colgObj.preferences.map(
                                          (prefObj, ind) => (
                                            <Fragment key={ind}>
                                              <ul>
                                                <li> {prefObj.degree_name}</li>
                                              </ul>
                                            </Fragment>
                                          )
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        {colgObj.preferences.map((prefObj) =>
                                          prefObj.specialization.map(
                                            (spzl, ind) => (
                                              <Fragment key={ind}>
                                                <ul>
                                                  <li>
                                                    {" "}
                                                    {spzl.specialization_name
                                                      ? spzl.specialization_name
                                                      : spzl.name}
                                                  </li>
                                                </ul>
                                              </Fragment>
                                            )
                                          )
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colgObj.is_rejected === true &&
                                            colgObj.is_approved === false && (
                                              <>Rejected</>
                                            )}
                                          {colgObj.is_rejected === false &&
                                            colgObj.is_approved === true && (
                                              <>Accepted</>
                                            )}
                                          {colgObj.is_rejected === false &&
                                            colgObj.is_approved === false && (
                                              <>Pending</>
                                            )}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div> */}
                          <div className="col-md-12">
                            <TableContainer component={Paper}>
                              <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell align="left">Degrees and Specializations</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {data?.college?.map((colgObj, ind) => (
                                    <StyledTableRow key={ind}>
                                      <StyledTableCell align="left">
                                        {colgObj.preferences.map((prefObj, ind) => (
                                          <Fragment key={ind}>
                                            {colgObj.preferences.indexOf(prefObj) !== colgObj.preferences.length - 1 ? (
                                              <>
                                                <BootstrapTooltipNew title={generateCsv(prefObj.specialization)} placement="top-start">
                                                  <span
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                      cursor: "pointer",
                                                      margin: "0 4px",
                                                      color: "#3282c4",
                                                    }}
                                                  >
                                                    {prefObj.degree_name}
                                                  </span>
                                                </BootstrapTooltipNew>
                                                {" | "}
                                              </>
                                            ) : (
                                              <>
                                                <BootstrapTooltipNew title={generateCsv(prefObj.specialization)} placement="top-start">
                                                  <span
                                                    style={{
                                                      fontSize: "16px",
                                                      fontWeight: "bold",
                                                      cursor: "pointer",
                                                      margin: "0 4px",
                                                      color: "#3282c4",
                                                    }}
                                                  >
                                                    {prefObj.degree_name}
                                                  </span>
                                                </BootstrapTooltipNew>{" "}
                                              </>
                                            )}
                                          </Fragment>
                                        ))}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                      )}

                      <div className="text-left my-3">
                        <h6 style={{ fontSize: "16px" }}>
                          <b>Preferences</b>
                        </h6>

                        <ul className="fs-16 gray-2 m-0 py-2 px-4">
                          <li style={{ fontSize: "14px" }}>
                            <b>Work Experience:</b> {data?.eligibility_criteria?.experience?.length > 0 && data?.eligibility_criteria?.experience[0]}
                          </li>
                          <li style={{ fontSize: "14px" }}>
                            <b>Eligible Graduation Years:</b>{" "}
                            {data?.eligibility_criteria?.graduation_years?.map((item, i) => (
                              <> {i != data?.eligibility_criteria?.graduation_years.length - 1 ? <span className="mr-2">{item},</span> : <span className="mr-2">{item}</span>}</>
                            ))}
                          </li>
                          {data.hiring_type !== "ON CAMPUS" && (
                            <li style={{ fontSize: "14px" }}>
                              <b>Qualifications Required:</b>{" "}
                              <span className="mr-2">
                                {Object.keys(qualificationData).map((item) => (
                                  <>
                                    {/* <ul>
                                    <li>
                                      {" "}
                                      <b>{item}</b> (
                                      {qualificationData[item].map(
                                        (item1, i) => (
                                          <>
                                            {i !=
                                            qualificationData[item].length -
                                              1 ? (
                                              <>
                                                {item1[specializationKey]}
                                                {" , "}
                                              </>
                                            ) : (
                                              <>{item1[specializationKey]}</>
                                            )}
                                          </>
                                        )
                                      )}
                                      )
                                    </li>
                                  </ul> */}
                                    {Object.keys(qualificationData).indexOf(item) !== Object.keys(qualificationData).length - 1 ? (
                                      <>
                                        <BootstrapTooltipNew title={generateCsv(qualificationData[item])} placement="top-start">
                                          <span
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              margin: "0 4px",
                                              color: "#3282c4",
                                            }}
                                          >
                                            {item}
                                          </span>
                                        </BootstrapTooltipNew>
                                        {" | "}
                                      </>
                                    ) : (
                                      <>
                                        <BootstrapTooltipNew title={generateCsv(qualificationData[item])} placement="top-start">
                                          <span
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              margin: "0 4px",
                                              color: "#3282c4",
                                            }}
                                          >
                                            {item}
                                          </span>
                                        </BootstrapTooltipNew>
                                      </>
                                    )}
                                  </>
                                ))}
                              </span>
                            </li>
                          )}
                          {data?.eligibility_criteria?.percentage != null && (
                            <li style={{ fontSize: "14px" }}>
                              <b>Percentage:</b> <span style={{ fontSize: "14px" }}>{data?.eligibility_criteria?.percentage}</span>
                            </li>
                          )}
                        </ul>
                      </div>

                      {/* {data?.eligibility_criteria?.qualifications && Object.keys(data?.eligibility_criteria?.qualifications)?.length > 0 && localStorage.getItem("user_type") !== "Company" && (
                        <>
                          <br />
                          <Typography variant="h6">Eligible Degrees</Typography>
                          <div className={classes.chipsection}>
                            <>
                              {Object.keys(data?.eligibility_criteria?.qualifications)?.map((item, index) => (
                                <>
                                  <>{item === "Open to All" && <Chip variant="outlined" size="small" label={"Open to All Courses"} className={classes.chip} />}</>
                                </>
                              ))}
                            </>
                            <>
                              <>
                                {!showMore &&
                                  Object.keys(data?.eligibility_criteria?.qualifications)
                                    ?.slice(0, 1)
                                    .map((item, index) => (
                                      <>
                                        {item !== "Open to All" &&
                                          !showMore &&
                                          data?.eligibility_criteria?.qualifications[item]
                                            .slice(0, 5)
                                            .map((item2) => (
                                              <>
                                                {
                                                  <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                                    className={classes.chip}
                                                  />
                                                }
                                              </>
                                            ))}
                                        {!showMore && data?.eligibility_criteria?.qualifications[Object.keys(data?.eligibility_criteria?.qualifications)[0]].length > 5 && (
                                          <span onClick={() => setShowMore(true)} style={{ color: "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 3 }}>
                                            Show More...
                                          </span>
                                        )}
                                      </>
                                    ))}
                              </>
                              <>
                                {showMore &&
                                  Object.keys(data?.eligibility_criteria?.qualifications)?.map((item, index) => (
                                    <>
                                      {item !== "Open to All" &&
                                        showMore &&
                                        data?.eligibility_criteria?.qualifications[item].map((item2) => (
                                          <>
                                            <Hidden smUp>
                                              <Chip
                                                variant="outlined"
                                                size="small"
                                                label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                                className={classes.chip}
                                                style={{ maxWidth: "100%" }}
                                              />
                                            </Hidden>
                                            <Hidden smDown>
                                              <Chip
                                                variant="outlined"
                                                size="small"
                                                label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                                className={classes.chip}
                                              />
                                            </Hidden>
                                          </>
                                        ))}
                                    </>
                                  ))}
                                {showMore && (
                                  <span onClick={() => setShowMore(false)} style={{ color: "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 3 }}>
                                    Show less...
                                  </span>
                                )}
                              </>
                            </>
                          </div>
                        </>
                      )} */}

                      <Grid container>
                        <Grid xs={12} sm={10} className="mg-bottom-10">
                          <h6 className="viewjob_heading" style={{ fontSize: "16px" }}>
                            Skills
                          </h6>
                          <div className="flex flex-wrap">
                            {data?.eligibility_criteria?.skills &&
                              data?.eligibility_criteria?.skills.map((item) => (
                                <span
                                  className="viewjob_tag"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    marginLeft: "0px",
                                    marginRight: "15px",
                                  }}
                                >
                                  {item.skill_name}
                                </span>
                              ))
                            }
                          </div>
                        </Grid>
                      </Grid>

                      {data?.documents?.length > 0 && (
                        <Grid container>
                          <Grid xs={12} className="mg-top-10">
                            <div>
                              <h6 className="viewjob_heading" style={{ fontSize: "16px" }}>
                                Documents Required
                              </h6>

                              <div className="viewjob_data">
                                {data.documents &&
                                  data.documents.map((item, index) => (
                                    <Box display="flex" alignItems="center">
                                      <FiberManualRecord
                                        style={{
                                          fontSize: "10px",
                                        }}
                                      />
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          fontFamily: "ProximaNova",
                                          marginLeft: "10px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item}
                                      </p>
                                    </Box>
                                  ))}
                              </div>
                            </div>
                          </Grid>
                          <br />
                          <br />
                        </Grid>
                      )}

                      <Grid container>
                        <Grid className="">
                          {data.job_rounds && data.job_rounds.length > 0 && (
                            <div>
                              <h6 className="viewjob_heading">Selection Procedure</h6>

                              <ul className="fs-14 gray-2 m-0 py-2 px-4">
                                {data.job_rounds &&
                                  data.job_rounds.map((doc, index) => {
                                    return (
                                      <li>
                                        <span>
                                          Round&nbsp; {index + 1}&nbsp;:&nbsp; {doc.round_name}
                                        </span>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          )}
                        </Grid>
                      </Grid>

                      {data?.company && (
                        <Grid container>
                          <Grid xs={12} style={{ marginTop: "0px" }}>
                            <b>
                              <h6 className="viewjob_heading" style={{ fontSize: "16px" }}>
                                About {data.company.company_name}
                              </h6>
                            </b>

                            {data.company.industry_name ?
                              <div
                                className="flex flex-wrap"
                                style={{
                                  marginLeft: "0px",
                                }}
                              >
                                <span
                                  className="viewjob_tag"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    marginLeft: "0px",
                                    marginRight: "15px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {data.company.industry_name}
                                </span>
                              </div> : ""
                            }
                            <div className="mg-top-10 viewjob_data " style={{ fontSize: "14px", textAlign: "justify" }}>
                              {data.company.company_description}
                            </div>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container>
                        <Grid xs={12} className="mg-top-10">
                          {data?.company && (
                            <>
                              <div>
                                <span className="fw-700 gray-2" style={{ fontSize: "14px" }}>
                                  Company Size:{" "}
                                </span>
                                <span style={{ fontSize: "14px" }}>{data.company.company_size}</span>
                              </div>
                              <div>
                                <span className="fw-700 gray-2" style={{ fontSize: "14px" }}>
                                  Check Us Out At:{" "}
                                </span>
                                <span
                                  className="viewjob_data"
                                  style={{
                                    color: "#3282c4",
                                    fontSize: "14px",
                                  }}
                                >
                                  <a
                                    style={{
                                      color: "#3282c4",
                                      fontSize: "14px",
                                    }}
                                    target="_blank"
                                    href={data.company.company_website}
                                  >
                                    {data.company.company_website}
                                  </a>
                                </span>
                              </div>
                            </>
                          )}{" "}
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid xs={12} className="flex" justify="flex-end">
                          {hide && (
                            <>
                              {" "}
                              <HiddenBtn isHide={isHide} setIsHide={setIsHide} jobId={data.id} reject={false} />{" "}
                            </>
                          )}
                          {apply && (
                            <>
                              {" "}
                              <ApplyButton
                                isApply={isApply}
                                setIsApply={setIsApply}
                                jobId={data.id}
                                reject={false}
                                index={index}
                                appliedList={appliedList}
                                appliedListFun={setAppliedList}
                                collegeId={JSON.parse(localStorage.getItem("user_details")).student_user_details.education.find((item) => item.is_current === true)?.college}
                              />
                            </>
                          )}

                          {invite ? (
                            data.is_accepted === false ? (
                              <>
                                {data.interview ? (
                                  <RejectAccept
                                    interviewId={data.interview.interview_id}
                                    jobId={data.id}
                                    reject={reject}
                                    inviteList={inviteList}
                                    setInviteList={setInviteList}
                                    index={index}
                                    rejectList={rejectList}
                                    setRejectList={setRejectList}
                                  />
                                ) : (
                                  <>
                                    <div className="fs-16 text-black-50">invited for interview, details will be posted soon</div>
                                  </>
                                )}{" "}
                              </>
                            ) : null
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default ViewJob;

const ApplyButton = ({ jobId, reject, isApply, setIsApply, collegeId, index, appliedList, appliedListFun }) => {
  const alert = useAlert();
  const classes = useStyles();
  const loading = Loading();
  const [done, setDone] = useState(false);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  const data = JSON.parse(localStorage.getItem("user_details"));

  const handelActive = (jobId) => {
    // setIsApply(true);
    // appliedList[index] = true;
    // appliedListFun(appliedList);

    loading.changeLoading(true);
    fetch(BackendBaseApi.NIYUKTI + "job/student/apply/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&round=1&status=1&feedback=1&college_id=${collegeId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert.success(data.data.message);
          loading.changeLoading(false);
          setDone(true);
          setIsApply(true);
          changeIndex(index);
        } else {
          alert.error(data.error);
        }
      });
  };

  const changeIndex = (index) => {
    let list = [...appliedList];
    list[index] = true;
    appliedListFun(list);
  };

  return (
    <Button
      variant="contained"
      style={appliedList && appliedList[index] ? { backgroundColor: "#717374" } :{ backgroundColor: (domain?.domain ? domain?.color_code : "#007bff") } }
      classes={{ disabled: classes.disableButton }}
      className={classes.actionButton}
      //disabled={appliedList[index]}
      onClick={() => handelActive(jobId)}
    >
      {reject ? (isApply ? "Accepted" : "Accept") : appliedList && appliedList[index] ? "Applied" : "Apply"}
      {/* {
            done ? 'Applied' : 'Apply'
        } */}
    </Button>
  );
};

const HiddenBtn = ({ jobId, hideStatus, isHide, setIsHide }) => {
  const [done, setDone] = useState(hideStatus);
  const handelActive = (jobId) => {
    const data = JSON.parse(localStorage.getItem("user_details"));

    if (hideStatus) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=unhide`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          setIsHide(false);
        });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=hidden`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          setIsHide(true);
        });
    }
  };

  return (
    <>
      {isHide ? (
        <>
          {" "}
          <Button className="mg-right-15" style={{ marginRight: 15 }} disabled={true} variant="outlined">
            Hidden
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            style={{ marginRight: 15 }}
            className="pointer text-blue mg-right-15"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          >
            <span className="fs-12 text-blue">hide</span>
          </Button>
        </>
      )}
    </>
  );
};

const RejectAccept = ({ jobId, reject, interviewId, inviteList, setInviteList, index, rejectList, setRejectList }) => {
  const alert = useAlert();
  const [isReject, setIsReject] = useState(false);
  return (
    <>
      {!isReject && <RejectButton jobId={jobId} reject={reject} interviewId={interviewId} rejectList={rejectList} setRejectList={setRejectList} index={index} />}
      <AcceptButton
        setIsReject={setIsReject}
        interviewId={interviewId}
        reject={reject}
        jobId={jobId}
        inviteList={inviteList}
        setInviteList={setInviteList}
        index={index}
        rejectList={rejectList}
        setRejectList={setRejectList}
      />
    </>
  );
};

const RejectButton = ({ jobId, isReject, interviewId, index, rejectList, setRejectList }) => {
  const alert = useAlert();
  const data = JSON.parse(localStorage.getItem("user_details"));

  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    rejectList[index] = true;
    setRejectList(rejectList);
    fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&status=false&interview_id=${interviewId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert.success("Invite Rejected");

        setDone(true);
      });
  };
  return (
    !isReject && (
      <Button
        variant="outlined"
        color="primary"
        className="fw-700 mr-1"
        disabled={done}
        onClick={(event) => {
          event.stopPropagation();
          handelActive(jobId);
        }}
      >
        {rejectList[index] ? "Rejected" : "Reject"}
      </Button>
    )
  );
};

const AcceptButton = ({ jobId, reject, setIsReject, interviewId, inviteList, setInviteList, index, rejectList, setRejectList }) => {
  const alert = useAlert();
  const data = JSON.parse(localStorage.getItem("user_details"));

  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    inviteList[index] = true;
    setInviteList(inviteList);

    fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&status=true&interview_id=${interviewId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert.success("Invite Accepted");

        setDone(true);
        setIsReject(true);
      });
  };
  return (
    <Button
      variant="contained"
      style={{ backgroundColor: "#3282C4" }}
      className="text-white fw-700"
      disabled={inviteList[index]}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
    >
      {reject ? (inviteList[index] ? "Accepted" : "Accept") : done ? "Applied" : "Apply"}
    </Button>
  );
};

import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import React, { useCallback } from "react";
import { useAlert } from "react-alert";

import "./drop.css";
import done from "../../assets/png/Done.png";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "20px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "30px",
    margin: theme.spacing(2),
    boxShadow: "none",
    fontSize: "16px",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
}));

export default function UploadDialog() {
  const classes = useStyles();
  const alert = useAlert();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        className={classes.button}
        onClick={handleClickOpen}
      >
      Done
      </Button> */}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div
          style={{
            padding: "40px",
          }}
        >
          <Typography variant="h6">Write your query</Typography>
          <br />
          <TextField
            variant="outlined"
            rows={6}
            multiline
            style={{ width: "100%" }}
          />
          <Typography variant="h6">
            Upload Screenshot (Maximum 3 Images)
          </Typography>
        </div>
      </Dialog>
    </div>
  );
}

import {
  GET_LEADS,
  CTC_BY_COMPANY,
  DETAILED_CTC,
  GET_SKILLS,
  GET_PLACEMENT_BY_DEGREE,
  GET_PLACEMENT_BY_SPECIALIZATION,
  SET_REF,
  TAction,
} from "./action";
import { LeadsByIndustryProps } from "./LeadByIndustry/LeadByIndustry";
import { Reducer } from "react";

interface IStateProps {
  leadsByIndustry: LeadsByIndustryProps;
  ctcByCompany: any;
  detailedCtc: any;
  placementByDegree: any;
  placementBySpecialization: any;
}

const initialState: IStateProps = {
  leadsByIndustry: {},
  ctcByCompany: [],
  detailedCtc: {},
  placementByDegree: {},
  placementBySpecialization: {},
};

const Reports: Reducer<IStateProps, TAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_LEADS:
      return { ...state, leadsByIndustry: action.payload };
    case CTC_BY_COMPANY:
      return { ...state, ctcByCompany: action.payload };
    case DETAILED_CTC:
      return { ...state, detailedCtc: action.payload };
    case GET_SKILLS:
      return { ...state, skills: action.payload };
    case GET_PLACEMENT_BY_DEGREE:
      return { ...state, placementByDegree: action.payload };
    case GET_PLACEMENT_BY_SPECIALIZATION:
      return { ...state, placementBySpecialization: action.payload };
    case SET_REF:
      return { ...state, ref: action.payload };
    default:
      return state;
  }
};

export default Reports;

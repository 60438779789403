import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
  LinearProgress,
  TextField,
  Chip,
  Paper,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import pSBC from "shade-blend-color";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Autocomplete } from "@material-ui/lab";
import { useAlert } from "react-alert";
import fetchApi from "../../apiCall/fetchApi";
import { BackendBaseApi } from "../../../constants/constants";
import { useParams } from "react-router-dom";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "20px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "30px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "30px",
    // margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    // paddingLeft: "50px",
    /// paddingRight: "50px",
    display: "flex",
    fontWeight: "500",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    // border:"1px solid #007bff"
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    transform: "translate(15px, 14px) scale(1)",
    // fontSize: "14px",
  },
  move: {
    boxShadow: "none",
    borderRadius: "30px",
    height: "40px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

export default function Rejected(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  //   const handleChange = (event) => {
  //     const name = event.target.name;
  //     setMoveToNext({
  //       ...moveToNext,
  //       round_id: event.target.value,
  //     });
  //     setStepSelectError("");
  //   };
  const [moveToNext, setMoveToNext] = useState({
    round_id: null,
    round_name: null,
    feedback: null,
    status_id: null,
  });
  const [showMore, setShowMore] = useState(false);
  const [typeFeedbackError, setTypeFeedbackError] = useState(false);
  const [open, setOpen] = React.useState(false);
  let { token } = useParams();

  const theme = useTheme();
  const alert = useAlert();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [stepSelectError, setStepSelectError] = useState(null);
  const handleDelete = (chipToDelete) => () => {
    var temp_item_arr = [...props.selectedRow?.details];
    var temp_id_arr = [...props.selectedRow?.selectedIds];
    temp_id_arr = temp_id_arr.filter((id) => id !== chipToDelete?.id);
    temp_item_arr = temp_item_arr.filter((obj) => obj?.id !== chipToDelete?.id);
    props.setSelectedRow({
      ...props.selectedRow,
      details: temp_item_arr,
      selectedIds: temp_id_arr,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const feedbackList = [
    {
      name: "Candidate Not Match Criteria",
      value: "Candidate Not Match Criteria",
    },
    { name: "Other", value: "Other" },
  ];

  const validateData = () => {
    var is_valid = true;
    var step_error = null;
    var type_error = null;
    if (
      moveToNext?.feedback &&
      moveToNext?.feedback?.name?.toLowerCase() === "other" &&
      !moveToNext?.feedback_typed
    ) {
      type_error = "Please Type your feedback";
      is_valid = false;
    }

    setStepSelectError(step_error);
    setTypeFeedbackError(type_error);
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      if (moveToNext?.feedback || moveToNext?.feedback_typed) {
        var studentData = props?.selectedRow?.details?.map((item) => {
          return {
            id: item?.id,
            round: null,
            status: 14,
            user_id: item?.user_id,
            job_id: item?.job_id,
            ...(!props.isPublic && {
              company_id: JSON.parse(localStorage.getItem("company")).company,
            }),
          };
        });
        fetchApi({
          url: props?.isPublic
            ? `${BackendBaseApi.NIYUKTI}api/track_sharer/company/move_applicant/?action=${token}`
            : `${BackendBaseApi.NIYUKTI}api/manage_jobs/common/move_applicant/`,
          method: "post",
          headers: props?.isPublic
            ? JSON.stringify({ withCredentials: true })
            : JSON.stringify({
                headers: {
                  Authorization: `Token ${localStorage.getItem("gw_token")}`,
                },
              }),
          alert: alert,
          body: JSON.stringify({
            student_data: studentData,
            applicant_feedback:
              moveToNext?.feedback_typed || moveToNext?.feedback?.value
                ? {
                    user_id: localStorage.getItem("user_id"),
                    status_id: 14,
                    status_name: "Rejected",
                    feedback_id: null,
                    create_time: new Date(),
                    feedback_name: moveToNext?.feedback_typed
                      ? moveToNext?.feedback_typed
                      : moveToNext?.feedback?.value,
                    user_name: props?.isPublic
                      ? "Track Sharer"
                      : JSON.parse(localStorage.getItem("user")).first_name +
                        " " +
                        JSON.parse(localStorage.getItem("user")).last_name,
                  }
                : null,
          }),
          setLoading: setLoading,
          callBackFn: callBackFn,
        });
      } else {
        alert.error("Please enter Feedback");
      }
    }
  };

  const callBackFn = () => {
    props.handleClose();
    props.callBackMainApplicantApi();
    !props.isPublic && props.getNavBarData();
    let filteredData = props.totalApplicantList.filter((obj) => {
      return !props.selectedRow?.selectedIds.includes(obj.id);
    });
    props.setTotalApplicantList(filteredData);
  };

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Rohan Singh" },
  ]);
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              // paddingBlockEnd: "40px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h5">Reject Students</Typography>
          </div>

          <div component="ul" className={classes.root}>
            <div style={{ width: "100%", display: "flex" }}>
              {!showMore
                ? props.selectedRow?.details?.slice(0, 5)?.map((data) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          label={data.name}
                          size="small"
                          onDelete={
                            props.selectedRow?.details?.length > 1
                              ? handleDelete(data)
                              : null
                          }
                          deleteIcon={
                            props.selectedRow?.details?.length > 1 ? (
                              <CloseIcon color="primary" />
                            ) : null
                          }
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : props.selectedRow?.details?.map((data) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          label={data.name}
                          size="small"
                          onDelete={
                            props.selectedRow?.details?.length > 1
                              ? handleDelete(data)
                              : null
                          }
                          deleteIcon={
                            props.selectedRow?.details?.length > 1 ? (
                              <CloseIcon color="primary" />
                            ) : null
                          }
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
            </div>
            <br />
            {props.selectedRow?.details?.length > 5 && (
              <Button
                className={classes.button}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                style={{
                  padding: "5px",
                  marginLeft: "7px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                color="primary"
              >
                {showMore ? "Hide" : "View All"}
              </Button>
            )}
            <Autocomplete
              id="country-select-demo"
              options={feedbackList}
              getOptionLabel={(option) => option.value}
              style={{
                background: "#fff",
                borderRadius: 30,
                width: "100%",
                marginBottom: 20,
                marginTop: 20,
              }}
              value={moveToNext.feedback}
              onChange={(e, newValue) => {
                setMoveToNext({ ...moveToNext, feedback: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Feedbacks"}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
            />

            {moveToNext?.feedback &&
              moveToNext?.feedback?.name?.toLowerCase() === "other" && (
                <TextField
                  id="outlined-multiline-static"
                  label="Enter Your Feedback"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  multiline
                  style={{ width: "100%" }}
                  rows={6}
                  variant="outlined"
                  value={moveToNext?.feedback_typed}
                  onChange={(e) => {
                    setMoveToNext({
                      ...moveToNext,
                      feedback_typed: e.target.value,
                    });
                    setTypeFeedbackError(null);
                  }}
                  error={typeFeedbackError ? true : false}
                  helperText={typeFeedbackError}
                />
              )}
          </div>
          <br />
          <Grid
            container
            spacing={5}
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          >
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={props.handleClose}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => {
                  if (!loading) handleSubmit();
                }}
              >
                {loading ? (
                  <CircularProgress size={18} style={{ color: "#fff" }} />
                ) : (
                  "Reject"
                )}
              </Button>
            </Grid>
          </Grid>
          {/* <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
            >
              Move Applicants
            </Button>
          </div> */}
        </div>
        {/* <DialogActions> */}

        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}

const data = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
];

import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../bundles/common/components/UI/Loader";
import line from "../../../assets/images/lineOr.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Box, createStyles, FormControlLabel, FormLabel, Grid, IconButton, makeStyles } from "@material-ui/core";
import CustomizedCheckboxes from "./FormComponents/CustomizedCheckboxes";
import { Accordion, AccordionDetails, AccordionSummary } from "./FormComponents/AccordianCustom";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useDispatch } from "react-redux";

export default function FormDialog(props) {
  const { open, handleClickOpen, handleClose, state, setState, offCampusLoader, setOffCampusLoader } = props;
  const dispatch = useDispatch();
  const [degree, setDegree] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const classes = useStyles();
  const [loader, setLoader] = useState(false);

  const handleSelectAllSpecialization1 = (e, item, index) => {
    // var arr = allData;
    var arr = state.OffCampus.allData;

    state.OffCampus.allData[item].forEach((it) => {
      it.isActive = e.target.checked;
      //   setAllData({ ...allData });
      setState({
        ...state,
        OffCampus: {
          ...state.OffCampus,
          allData: { ...state.OffCampus.allData },
        },
      });

      if (it.isActive === true) {
        var temp2 = [];

        state.OffCampus.degree.map((a, o) => {
          temp2.push(a.id);
        });

        if (temp2.includes(it.degree.id) === false) {
          state.OffCampus.degree.push(it.degree);
        }
      } else {
        state.OffCampus.degree = state.OffCampus.degree.filter((item) => item.id !== it.degree.id);

        // state.OffCampus.degree.splice(index, 1);
      }

      if (it.isActive === true) {
        state.OffCampus.specialization.push(it.specialization);
      } else {
        state.OffCampus.specialization = state.OffCampus.specialization.filter((item) => item.degree_id !== it.degree.id);
      }

      setDegree(state.OffCampus.degree);
      setSpecialization(state.OffCampus.specialization);

      arr[item][0].isChecked = e.target.checked;

      // setAllData({ ...arr });
      setState({
        ...state,
        OffCampus: {
          ...state.OffCampus,
          allData: { ...arr },
        },
      });
    });
  };

  useEffect(() => {
    if (degree.length > 0 && specialization.length > 0) {
      var booleArrayAllDegree = [];
      var booleArrayAllSpecialization = [];

      booleArrayAllDegree =
        state.Colleges.hiring_type == "OFF CAMPUS" && Object.keys(state?.OffCampus?.allData).map((item) => state.OffCampus.allData[item].every((e) => e.isActive === true));
      var allDegreeCheck = state.Colleges.hiring_type == "OFF CAMPUS" && booleArrayAllDegree?.every((e) => e === true);
      console.log(allDegreeCheck);

      booleArrayAllSpecialization =
        state.Colleges.hiring_type == "OFF CAMPUS" &&
        degree.map((item) =>
          state.OffCampus.allData[item.name].forEach((e) => {
            if (e.isActive === true) item["all_spec_selected"] = true;
            else item["all_spec_selected"] = false;
          })
        );

      degree.forEach((item) => {
        item["all_degree_selected"] = allDegreeCheck;
      });

      setState({
        ...state,
        Preferences: {
          ...state.Preferences,
          eligibility_criteria: {
            ...state.Preferences.eligibility_criteria,
            degrees: degree,
            courses: specialization,
          },
        },
      });
    }
  }, [degree.length, specialization.length]);

  const handleSelectSingleSpecialization1 = (e, item, index) => {
    // var arr = allData;
    var arr = state.OffCampus.allData;
    arr[item][index].isActive = e.target.checked;

    // setAllData({ ...arr });
    setState({
      ...state,
      OffCampus: {
        ...state.OffCampus,
        allData: { ...arr },
      },
    });
    var array1 = [];
    state.OffCampus.specialization.map((item) => {
      array1.push(item.degree_id);
    });

    if (arr[item][index].isActive === true) {
      var temp2 = [];

      state.OffCampus.degree.map((a, o) => {
        temp2.push(a.id);
      });

      if (temp2.includes(arr[item][index].degree.id) === false) {
        state.OffCampus.degree.push(arr[item][index].degree);
      }
    } else {
      if (!checkForDuplicates(array1)) {
        var indexing;
        state.OffCampus.degree.map((u, iin) => {
          if (u.id === arr[item][index].degree.id) {
            indexing = iin;
          }
        });
        state.OffCampus.degree.splice(indexing, 1);
      }
    }
    var indexx;
    if (arr[item][index].isActive === true) state.OffCampus.specialization.push(arr[item][index].specialization);
    else {
      state.OffCampus.specialization.map((u, iin) => {
        if (u.id === arr[item][index].specialization.id) {
          indexx = iin;
        }
      });
      state.OffCampus.specialization.splice(indexx, 1);
    }

    // setState({
    //   ...state,
    //   Preferences: {
    //     ...state.Preferences,
    //     eligibility_criteria: {
    //       ...state.Preferences.eligibility_criteria,
    //       degrees: state.OffCampus.degree,
    //       courses: state.OffCampus.specialization,
    //     },
    //   },
    // });

    setDegree(state.OffCampus.degree);
    setSpecialization(state.OffCampus.specialization);

    if (state.OffCampus.specialization.length) arr[item][0].isValue = true;
    else arr[item][0].isValue = false;
  };

  function checkForDuplicates(array) {
    let valuesAlreadySeen = [];

    for (let i = 0; i < array.length; i++) {
      let value = array[i];
      if (valuesAlreadySeen.indexOf(value) !== -1) {
        return true;
      }
      valuesAlreadySeen.push(value);
    }
    return false;
  }
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Select Branches and Courses</DialogTitle>
        <DialogContent>
          {offCampusLoader === false ? (
            state.Colleges.hiring_type == "OFF CAMPUS" &&
            state.OffCampus?.allData && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <FormLabel className={classes.grid1} component="legend" style={{ fontSize: 13 }}>
                      Courses
                    </FormLabel>
                  </Grid>
                  <Grid item xs={9} className={classes.grid2}>
                    <FormControlLabel
                      control={
                        <CustomizedCheckboxes
                          onChange={(e) => {
                            //   var len = Object.keys(allData);
                            var len = Object.keys(state.OffCampus.allData);

                            len.forEach((item) => {
                              state.OffCampus.allData[item][0].isValue = e.target.checked;

                              state.OffCampus.allData[item].forEach((n) => {
                                n.isActive = e.target.checked;

                                setState({
                                  ...state,
                                  OffCampus: {
                                    ...state.OffCampus,
                                    allData: { ...state.OffCampus.allData },
                                  },
                                });

                                if (n.isActive === true) {
                                  var temp2 = [];

                                  state.OffCampus.degree.map((a, o) => {
                                    temp2.push(a.id);
                                  });

                                  if (temp2.includes(n.degree.id) === false) {
                                    state.OffCampus.degree.push(n.degree);
                                  }
                                } else
                                  while (state.OffCampus.degree.length > 0) {
                                    state.OffCampus.degree.pop();
                                  }

                                if (n.isActive === true) state.OffCampus.specialization.push(n.specialization);
                                else
                                  while (state.OffCampus.specialization.length > 0) {
                                    state.OffCampus.specialization.pop();
                                  }

                                // setState({
                                //   ...state,
                                //   Preferences: {
                                //     ...state.Preferences,
                                //     eligibility_criteria: {
                                //       ...state.Preferences.eligibility_criteria,
                                //       degrees: state.OffCampus.degree,
                                //       courses: state.OffCampus.specialization,
                                //     },
                                //   },
                                // });

                                setDegree(state.OffCampus.degree);
                                setSpecialization(state.OffCampus.specialization);
                              });
                            });
                          }}
                          // checked={state.offCampus.value === true ? true : false}
                          name="gilad"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={<span className={classes.labelSize}>Select All Courses</span>}
                    />

                    <img src={line} width="90%" />
                    <br />
                    <br />

                    {Object.keys(state.OffCampus.allData)?.map((item, index) => (
                      <Fragment key={index}>
                        <Accordion square>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                              control={
                                <CustomizedCheckboxes
                                  onChange={(e) => {
                                    handleSelectAllSpecialization1(e, item, index);
                                    state.OffCampus.allData[item][0].isValue = e.target.checked;
                                  }}
                                  checked={state.OffCampus.allData[item][0].isValue === true ? true : false}
                                  name="gilad"
                                  classes={{
                                    root: classes.radio,
                                    checked: classes.checked,
                                  }}
                                />
                              }
                              label={<span className={classes.labelSize}>{item}</span>}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box className={classes.innerBox}>
                                {state.OffCampus.allData[item].map((i, index) => (
                                  <Fragment key={index}>
                                    {i.specialization ? (
                                      <FormControlLabel
                                        control={
                                          <CustomizedCheckboxes
                                            checked={i.isActive}
                                            onChange={(e) => {
                                              handleSelectSingleSpecialization1(e, item, index);
                                            }}
                                            name="gilad"
                                            classes={{
                                              root: classes.radio,
                                              checked: classes.checked,
                                            }}
                                          />
                                        }
                                        label={<span className={classes.labelSize}>{i.specialization.name}</span>}
                                      />
                                    ) : null}
                                    <br />
                                  </Fragment>
                                ))}
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </Fragment>
                    ))}
                  </Grid>
                </Grid>
              </>
            )
          ) : (
            <Loader />
          )}
        </DialogContent>
        {!offCampusLoader && (
          <DialogActions>
            <Button onClick={handleClose} className={classes.preferenceButton} variant="contained">
              Done
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formLabel: {
      fontSize: "13px",
      fontWeight: "bold",
    },
    RedStar: {
      color: "red",
      fontWeight: "bold",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "bolder",
    },
    section: {
      marginBottom: "20px",
    },
    subTitle: {
      fontSize: "11px",
      color: "grey",
      width: "70%",
      margin: "6px 0 0 10px",
    },
    preferenceButton: {
      borderRadius: 30,
      "&:hover": {
        border: "2px solid #3282C4",
        background: "white",
        color: "#3282C4",
      },
    },
    labelSize: {
      fontSize: 12,
    },

    "@media only screen and (max-width: 1100px)": {
      labelSize: {
        fontSize: 11,
      },
      formLabel: {
        fontSize: "11px",
        fontWeight: "bold",
      },
    },

    MuiOutlinedInput: {
      root: {
        height: "auto",

        // border: "1px solid #ced4da",
        // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
      },
    },
  })
);

import React, { useState } from "react";
import {
  Dialog,
  Box,
  Tooltip,
  TextField,
  Button,
  makeStyles,
  createStyles,
  useTheme,
} from "@material-ui/core";
import Axios from "axios";
import { BackendBaseApi } from "../../constants/constants";
import UPI from "../../assets/icon/upi-bar-code.jpg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Success from "../../assets/success.gif";
import Success_dark from "../../assets/success-dark.gif";
import { useAlert } from "react-alert";

const RechargeDialog = ({ open, success, setOpen, setSuccess }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [paymentId, setPaymentId] = useState("");
  const [amount, setAmount] = useState(0);
  const alert = useAlert();

  const handlePaymentSubmit = async () => {
    const res = await Axios({
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      url: `${BackendBaseApi.PRAVESH}api/common/manual_payment/`,
      data: {
        user_id: localStorage.getItem("user_id"),
        user_type: localStorage.getItem("user_type_id"),
        reference_id: paymentId,
        amount: amount,
      },
    }).catch((err) => {
      throw err;
    });
    if (res.data.success) {
      setSuccess(true);
    } else alert.error("some error occured, please try again");
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setSuccess(false);
        setOpen(false);
      }}
    >
      <Box
        padding="3%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!success ? (
          <>
            <p style={{ fontSize: "20px" }}>Scan to Pay:</p>
            <img src={UPI} width="50%" height="auto" />
            <CopyToClipboard text="getwork@icici">
              <Tooltip placement="right" arrow title="Click to copy UPI Id">
                <p style={{ fontSize: "22px" }}>
                  UPI Id:{" "}
                  <span style={{ fontWeight: "bold" }}>getwork@icici</span>
                </p>
              </Tooltip>
            </CopyToClipboard>
            <p>Enter transaction Id to get money credited in your wallet</p>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              padding="5%"
              width="100%"
            >
              <TextField
                placeholder="Transaction ID"
                variant="outlined"
                onChange={(e) => setPaymentId(e.target.value)}
              ></TextField>
              <TextField
                placeholder="Amount"
                variant="outlined"
                type="number"
                onChange={(e) => setAmount(e.target.value)}
              ></TextField>
            </Box>
            <Button
              disabled={paymentId === "" || amount === 0 ? true : false}
              onClick={() => handlePaymentSubmit()}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </>
        ) : (
          <>
            <img
              src={theme.palette.type === "dark" ? Success_dark : Success}
              width="50%"
              height="auto"
            />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Request recieved Successfully ! Amount will get credit into your
              wallet after verification. Please check in 15 minutes
            </p>
          </>
        )}
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    submitButton: {
      backgroundColor: "#219653",
      color: "#ffffff",
      marginTop: "10px",
    },
  })
);

export default RechargeDialog;

import Axios from "axios";
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { BackendBaseApi } from "../../../../constants/constants";

const ProfileLinks = ({ state, setState, value }) => {
  const [list, setList] = useState({});
  const [selectedHandle, setSelectedHandle] = useState("");
  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/shared/social_links/", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        let temp = {};
        res.data.data.forEach((link) => {
          temp[link.name] = link.id;
        });
        setList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedHandle) {
      setState({ ...state, Name: selectedHandle });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHandle]);

  const TypeAndSearchSocialHandles = () => (
    <SelectSearch
      onChange={setSelectedHandle}
      value={value}
      options={Object.keys(list).map((l) => {
        return { name: l, value: l };
      })}
      search
      placeholder="Search a handle"
    />
  );
  return (
    <>
      <TypeAndSearchSocialHandles />
    </>
  );
};

export default ProfileLinks;

import {
  Dialog,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import React from "react";
import { useAlert } from "react-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import JobSharerButton from "../../../../components/common/JobSharer/JobSharerButton";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "20px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  root: {
    padding: "20px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  button: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    height: "40px",
    width: "40%",
    marginBottom: "10px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  circle: {
    width: "100px",
    height: "100px",
    backgroundColor: "#28a745",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
  },
  link: {
    boxShadow: "none",
    border: "1px solid #007BFF",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  input: {
    width: "100%",
    fontSize: "14px",
    color: "#6C757D",
  },
}));

export default function TrackShareSuccessfully({
  data,
  handleClickOpen,
  handleClose,
  open,
  jobTitle,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const alert = useAlert();

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.root}>
          <div
            style={{
              padding: "10px",
              textAlign: "center",
            }}
          >
            <div className={classes.circle}>
              <CheckCircleIcon style={{ color: "#fff", fontSize: "70px" }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <CheckCircleIcon
                  style={{
                    color: "#28a745",
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                />
              </div>
              <div>
                <Typography variant="body2 ">
                  Track Sharer Link has been Generated Successfully. Use the
                  below Pascode and Link
                </Typography>
              </div>
            </div>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item className={classes.grid} xs={8} sm={8} md={8}>
                <div className={classes.paper}>
                  <Paper className={classes.link}>
                    <InputBase
                      className={classes.input}
                      value={data?.link}
                      inputProps={{
                        className: classes.labellink,
                        "aria-label": "https://getwork-ejd-mba.in",
                      }}
                      disabled
                    />
                    <CopyToClipboard
                      text={data?.link}
                      onCopy={() => alert.success("Link Copied!")}
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-label="copy"
                      >
                        <FileCopyOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  </Paper>
                </div>
              </Grid>

              <Grid item xs={4} sm={4} md={4} className={classes.grid}>
                {/* <TextField
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  label="Passcode"
                  inputProps={{
                    style: {
                      padding: "16px",
                      borderRadius: "30px",
                      backgroundColor: "#fff",
                    },
                  }}
                  disabled
                  value={data?.passcode}
                /> */}
                <div className={classes.paper}>
                  <Paper className={classes.link}>
                    <InputBase
                      className={classes.input}
                      value={data?.passcode}
                      inputProps={{
                        className: classes.labellink,
                        // "aria-label": "https://getwork-ejd-mba.in",
                      }}
                      disabled
                    />
                    <CopyToClipboard
                      text={data?.passcode}
                      onCopy={() => alert.success("Passcode Copied!")}
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-label="copy"
                      >
                        <FileCopyOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <JobSharerButton
            detail={{
              url: data?.link,
              user: JSON.parse(localStorage.getItem("company")).company_name,
              passcode: data?.passcode,
              jobProfile: jobTitle,
            }}
            page="SHARE_TRACK"
          />
        </div>
        <br />
        <br />
        {/* <div className={classes.btngrid}>
          <Button autoFocus className={classes.button} variant="outlined" onClick={handleClose} color="primary">
            Back
          </Button>
        </div> */}
      </Dialog>
    </div>
  );
}

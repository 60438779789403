import { Button } from "@material-ui/core";
import React from "react";
import Axios from "axios";
import {
    BackendBaseApi, MENTORKART_ENDPOINT
  } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
createStyles({
  
  actionButton: {
    color: "#ffffff",
    fontWeight: "700",
    borderRadius:"30px"
  },
  "@media screen and (max-width: 768px)": {
    actionButton: {
      // height: "28px",
      // width: "40px",
      fontSize: "12px",
      // float: "right",
    }
  },
})
);

export const GetMentoredButton = ({jobRole=null}) => {


  
    const classes = useStyles();
    const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);


    const checkforSSO = (jobRole) => {
        var jobRoleText = ""
        if (jobRole)
            jobRoleText += '?job_role=' + jobRole
        Axios.get(BackendBaseApi.PRAVESH + "api/sso/mentorkart/getmentored/token" +  jobRoleText, {
          headers: {
            Authorization: "Token " + localStorage.getItem("gw_token"),
          },
        })
          .then((res) => {
            if (res?.data?.success){
              window.open(MENTORKART_ENDPOINT + "/partners?SSO_Mtoken=" + res.data.data.token);
            }
          })
          .catch((err) => {
          });
      };
  
    return (
        <Button
        variant="contained"
        style={ {backgroundColor: (domain?.domain ? domain?.color_code : "#007bff") , marginRight: "10px"}}
        className= {classes.actionButton}
        onClick={(event) => {
            event.stopPropagation();
            checkforSSO(jobRole);
        }}
      >
        GetMentored
    </Button>
    );
  };
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  createStyles,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  TextareaAutosize,
  withStyles,
  show,
} from "@material-ui/core";
import React, { useEffect } from "react";
import mail from "../../../assets/mail.png";
import phone from "../../../assets/phone.png";
import Iron from "../../../assets/common/ironman.png";
import download1 from "../../../assets/download1.png";
import bullet from "../../../assets/bullets.png";
import next from "../../../assets/next.png";
import cancel from "../../../assets/cancel.png";
import subuser_icon from "../../../assets/common/subuser.jpg";
import no_feedback from "../../../assets/common/no_feedback.gif";
import { useAlert } from "react-alert";

export default function ApplicantsProfile(props) {
  const classes = useStyles();
  const {
    currentData,
    moveNextArray,
    setMoveButtonClicked,
    setSelectedApplicants,
    setFeedbackSentState,
    handleMainModalClose,
    feedbackTyped,
    setFeedbackTyped,
    currentState,
    show,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const alert = useAlert();
  const [feedBackArray, setFeedbackArray] = React.useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const user_type_id = parseInt(localStorage.getItem("user_type_id"));

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    var filtered =
      currentData?.feedback?.length &&
      currentData?.feedback.filter((el) => el!=null && el.feedback_name != null);
    setFeedbackArray(filtered);
  }, [currentData]);

  return (
    <>
      <Box className={classes.profileBox}>
        {show && (
          <>
            <p className={classes.newTitle}>Feedback</p>
            <p className={classes.subTitle} style={{ marginBottom: 17 }}>
              Please Write Your Comments regarding the student and then perform
              the appropriate action. (Feedback is a must if you reject any
              applicant)
            </p>
            <TextareaAutosize
              rowsMin={3}
              rowsMax={5}
              style={{
                width: "100%",
                borderRadius: "30px",
                borderColor: "#d2cdcd",
                padding: 12,
                outline: "none",
              }}
              aria-label="maximum height"
              placeholder="Maximum 100 words.."
              onChange={(e) => {
                setFeedbackTyped(e.target.value);
              }}
              value={feedbackTyped}
            />
            <Box
              display="flex"
              flexDirection="row-reverse"
              style={{ marginTop: 20 }}
            >
              <Button
                variant="contained"
                className={classes.nextButton}
                startIcon={<img src={next} alt="" height="20px" />}
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <span style={{ fontSize: 13 }}>Move to Another Step</span>
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {moveNextArray?.map((item) => (
                  <StyledMenuItem>
                    <ListItemText
                      primary={item.name}
                      onClick={() => {
                        setFeedbackSentState(item);

                        setSelectedApplicants([
                          {
                            id: currentData.applicant_id,
                            name:
                              currentData.user_details.first_name +
                              " " +
                              currentData.user_details.last_name,
                            user_id: currentData.user_details.user_id,
                            first_name: currentData.user_details.first_name,
                            last_name: currentData.user_details.last_name,
                            job_id: currentData.job_id,
                          },
                        ]);
                        setMoveButtonClicked(true);
                        handleMainModalClose();
                        handleClose();
                      }}
                    />
                  </StyledMenuItem>
                ))}
              </StyledMenu>
              <Button
                variant="contained"
                className={classes.rejectButton}
                style={{ marginRight: 10 }}
                startIcon={<img src={cancel} alt="" height="20px" />}
                onClick={() => {
                  if (feedbackTyped) {
                    setFeedbackSentState({
                      status_id: 14,
                      round_id: null,
                      name: "Rejected",
                    });

                    setSelectedApplicants([
                      {
                        id: currentData.applicant_id,
                        name:
                          currentData.user_details.first_name +
                          " " +
                          currentData.user_details.last_name,
                        user_id: currentData.user_details.user_id,
                        first_name: currentData.user_details.first_name,
                        last_name: currentData.user_details.last_name,
                        job_id: currentData.job_id,
                      },
                    ]);
                    setMoveButtonClicked(true);
                    handleMainModalClose();
                    handleClose();
                  } else
                    alert.error("First Enter Feedback to reject the candidate");
                }}
              >
                <span style={{ fontSize: 13 }}>Reject</span>
              </Button>
            </Box>
          </>
        )}
        {feedBackArray && feedBackArray?.length > 0 ? (
          <>
            <p className={classes.newTitle} style={{ marginTop: 30 }}>
              Feedback Details
            </p>
            {feedBackArray.length
              ? feedBackArray.map((item, index) =>
                  index % 2 === 0 ? (
                    <Box className={classes.commentBox}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <img
                            className={classes.img}
                            alt="complex"
                            src={
                              item.profile_picture
                                ? item.profile_picture
                                : subuser_icon
                            }
                          />{" "}
                        </Grid>
                        <Grid item xs={3}>
                          <p className={classes.listTitle}>
                            {item.first_name?item.first_name + " " + item.last_name:'Anonymous'}
                          </p>
                        </Grid>
                        <Grid item xs={3}>
                          <p className={classes.listTitle}>
                            {item.feedback_name ? (
                              item.feedback_name
                            ) : (
                              <span style={{ color: "#c56868" }}>
                                No Feedback
                              </span>
                            )}
                          </p>
                        </Grid>
                        <Grid item xs={3}>
                          <p className={classes.listTitle}>
                            {item.status_name}
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Box className={classes.commentBox}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <p className={classes.listTitle}>
                          {item.first_name?item.first_name + " " + item.last_name:'Anonymous'}
                          </p>
                        </Grid>
                        <Grid item xs={3}>
                          <p className={classes.listTitle}>
                            {item.feedback_name ? (
                              item.feedback_name
                            ) : (
                              <span style={{ color: "#c56868" }}>
                                No Feedback
                              </span>
                            )}
                          </p>
                        </Grid>
                        <Grid item xs={3}>
                          <p className={classes.listTitle}>
                            {item.status_name}
                          </p>
                        </Grid>
                        <Grid item xs={3}>
                          <img
                            className={classes.img}
                            alt="complex"
                            src={
                              item.profile_picture
                                ? item.profile_picture
                                : subuser_icon
                            }
                          />{" "}
                        </Grid>
                      </Grid>
                    </Box>
                  )
                )
              : null}
          </>
        ) : (
          <>
            <p className={classes.newTitle} style={{ marginTop: 30 }}>
              Feedback Details
            </p>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              flexDirection="column"
            >
              <img src={no_feedback} alt="" height="50%" />
              <p style={{ fontSize: 13, color: "grey", textAlign: "center" }}>
                No Feedback present for this applicant
              </p>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    profileBox: {
      padding: 20,
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: "30px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border:
        theme.palette.type === "dark"
          ? "solid 1px #504e4e"
          : "solid 1px #f1f1f1",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      marginBottom: 20,
      marginTop: 10,
      // height: "96%",
    },
    commentBox: {
      paddingRight: 10,
      paddingLeft: 10,
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: "30px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border:
        theme.palette.type === "dark"
          ? "solid 1px #504e4e"
          : "solid 1px #f1f1f1",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      marginBottom: 10,
    },
    nextButton: {
      backgroundColor: "#76e5ac",
      border: "1px solid #145f38",
      height: "36px",
      borderRadius: 30,
      color: "#145f38",
      "&:hover": {
        backgroundColor: "#5dd698",
      },
    },
    rejectButton: {
      backgroundColor: "rgb(249 188 202)",
      border: "1px solid rgb(188 12 30 / 1)",
      height: "36px",
      borderRadius: 30,
      color: "rgb(188 12 30 / 1)",
      "&:hover": {
        backgroundColor: "#ff86a2",
      },
    },
    img: {
      margin: "auto",
      display: "block",

      borderRadius: "20px",
      height: 70,
      width: 70,
      padding: 8,
    },
    title: {
      fontSize: 19,
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    newTitle: {
      fontSize: 16,
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    educationTitle: {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    subTitle: {
      fontSize: 12,
      marginTop: -15,
    },
    listTitle: {
      fontSize: 12,
      marginTop: 20,
      textAlign: "center",
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      fontSize: 12,
    },
    listIcon: {
      marginBottom: 2,
      marginRight: 8,
      height: "20px",
    },
  })
);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    top: 406,
    left: 1340,
    width: 200,
    fontSize: 12,
    borderRadius: 30,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      fontSize: 12,
    },
    "&:focus": {
      backgroundColor: "#3282C4",

      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontSize: 12,
      },
    },
  },
}))(MenuItem);

import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DegreeCourses from "../../../common/DegreeSpecializationCollege/Index";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { open, handleClickOpen, handleClose, state, setState, courseArray, setCourseArray, jobData } = props;
  const [degreeCoursesComing, setDegreeCoursesComing] = useState(false);
  const [allData, setAllData] = useState([]);
  const [loaderDegree, setLoaderDegree] = useState();
  const [specializationNew, setSpecializationNew] = useState({});
  const alert = useAlert();
  const getDegreeAndCourses = (flag) => {
    // if (collegeList[clg_name].education_details.length === 0) {
    setLoaderDegree(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/company/new_college_rank/education/?college_id=" + localStorage.getItem("college_id"), {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data?.success && res?.data.data) {
          res.data.data.forEach((item) => {
            item.select_all = checkBooleanStatusDegree(item.degree_id, flag);
            item.specialization.forEach((spec) => {
              spec.selected = checkBooleanStatus(spec.specialization_id, flag);
            });
            item.select_all = false;
          });
          setAllData(res.data.data);
        } else {
          alert.error("Error Occured");
        }
        setLoaderDegree(false);
      })
      .catch((err) => {
        setLoaderDegree(false);
        alert.error("Error");
      });
    // }
  };

  const checkBooleanStatus = (id, flag) => {
    var boole = false;
    if (jobData?.college?.length > 0 && flag === 1) {
      jobData.college[0].preferences.map((item) => {
        item.specialization.map((item_in) => {
          if (item_in.specialization_id === id) boole = true;
        });
      });
    }
    return boole;
  };

  const checkBooleanStatusDegree = (id, flag) => {
    var boole = false;
    if (jobData?.college?.length > 0 && flag === 1) {
      jobData.college[0].preferences.map((item) => {
        if (item.degree_id === id) boole = true;
      });
    }
    return boole;
  };
  let { job_id } = useParams();

  useEffect(() => {
    if (localStorage.getItem("user_type") === "College" && !job_id) getDegreeAndCourses(0);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user_type") === "College" && job_id && jobData && jobData !== undefined) getDegreeAndCourses(1);
  }, [jobData]);

  useEffect(() => {
    if (courseArray.length > 0) {
      var arr = [...courseArray];
      var degree_array = [];
      var course_array = [];
      arr.map((item) => {
        item.specialization.forEach((i) => {
          i.degree_id = item.degree_id;
          i.degree_name = item.degree_name;
          course_array.push(i);
        });
        var obj = {
          id: item.degree_id,
          name: item.degree_name,
        };
        degree_array.push(obj);
      });

      setCourseArray(arr);
      setState({
        ...state,
        Preferences: {
          ...state.Preferences,
          eligibility_criteria: {
            ...state.Preferences.eligibility_criteria,
            courses: course_array,
            degrees: degree_array,
          },
        },
      });
    }
  }, [courseArray.length]);

  useEffect(() => {
    var college_array = [
      {
        college_id: localStorage.getItem("college_id"),
        college_name: JSON.parse(localStorage.getItem("user_details")).college_name,
        apply_start_date: state.Colleges.apply_start_date,
        apply_end_date: state.Colleges.apply_end_date,
        preferences: courseArray,
      },
    ];
    console.log("fgf", college_array);

    setState({
      ...state,
      Colleges: {
        ...state.Colleges,
        college: college_array,
      },
    });
  }, [state.Preferences.eligibility_criteria.courses.length, state.Colleges.apply_start_date, state.Colleges.apply_end_date]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Select Courses
        </DialogTitle>
        <DialogContent dividers>
          <DegreeCourses
            data={courseArray}
            setData={setCourseArray}
            width={"90%"}
            loading={loaderDegree}
            setLoading={setLoaderDegree}
            degreeCoursesComing={degreeCoursesComing}
            setDegreeCoursesComing={setDegreeCoursesComing}
            allData={allData}
            setAllData={setAllData}
            specializationNew={specializationNew}
            jobData={jobData}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

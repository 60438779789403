import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import CommonChip from "../../../../CommonChip/CommonChip";
const useStyles = makeStyles((theme) => ({
  paper: {
    // minHeight: "600px",
    borderRadius: "12px",
    // textAlign: "center",
    padding: 12,
    width: "100%",
    marginBottom: 10,
  },
}));

function AchievementCards(props) {
  const { data, index, handleDelete } = props;

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="body1" style={{ textAlign: "left", fontSize: 15 }}>
              {data}
            </Typography>
          </Grid>
          {/* <Grid item xs={1}>
            <EditIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                editBtn(data?.id);
              }}
            />
          </Grid> */}
          <Grid item xs={1}>
            <DeleteIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                handleDelete(index);
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default AchievementCards;

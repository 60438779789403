import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  makeStyles,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../bundles/common/components/UI/Loader";
import { BackendBaseApi } from "../../../constants/constants";
import { updateCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import CreditDialog from "../CreditDialog/CreditDialog";
import Basics from "./Basics";
import Details from "./Details";
import salaryRange from "./FormComponents/SalaryRange";
import HiringProcess from "./HiringProcess";
import PreviewDialog from "./PreviewDialog";
import Qualifications from "./Qualifications";
import SelectionProcedure from "./SelectionProcedure";
import JobDescriptionTempelate from "./JobDescriptionTempelate";
import LessCredit from "../../../GlobalComponents/LessCredits/LessCredits";
import CommonButton from "../CommonButton/CommonButton";
const MainForm = (props) => {
  const {
    state,
    setState,
    detailsRef,
    basicsRef,
    qualificationsRef,
    selectionRef,
    hiringRef,
    loading,
    setLoading,
    basicsCheck,
    detailsCheck,
    preferencesCheck,
    selectionCheck,
    hiringCheck,
    jobData,
    locationUrl,
  } = props;
  const [applyButtonClicked, setApplyButtonClicked] = useState(false);
  const [courseArray, setCourseArray] = useState([]);

  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  let { job_id } = useParams();

  useEffect(() => {
    if (
      state.OffCampus.allData === null &&
      state.Colleges.hiring_type === "OFF CAMPUS"
    )
      getDegreeCourse();
  }, [state.Colleges.hiring_type]);

  const getDegreeCourse = () => {
    setLoader(true);
    setLoading(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/education/job/get_specialization")
      .then((res) => {
        if (res.data?.success && res?.data.data) {
          //   setAllData(res.data.data)
          const temp = {};
          res.data.data.forEach((item) => {
            var tempSpzl = {
              ...item,
              isChecked: false,
              isActive: false,
              isValue: false,
            };
            if (temp[item.degree.name] === undefined) {
              if (item.specialization === null)
                temp[item.degree.name] = [
                  {
                    degree: item.degree,
                    specialization: {},
                    isChecked: false,
                    isValue: false,
                  },
                ];
              else {
                temp[item.degree.name] = [tempSpzl];
              }
            } else {
              if (item.specialization === null)
                temp[item.degree.name].push({
                  degree: item.degree,
                  specialization: {},
                  isChecked: false,
                  isValue: false,
                });
              else temp[item.degree.name].push(tempSpzl);
            }
          });
          if (job_id) {
            Object.values(temp).forEach((item) => {
              item.forEach((inner, index) => {
                jobData.eligibility_criteria.courses.map((item2) => {
                  let specialization_id = item2.specialization_id
                    ? item2.specialization_id
                    : item2.id;
                  if (inner.specialization.id === specialization_id) {
                    inner.isActive = true;
                    inner.isValue = true;
                    inner.isChecked = true;
                  }
                  if (index === 0) {
                    if (inner.degree.id === item2.degree_id) {
                      inner.isValue = true;
                      inner.isChecked = true;
                    }
                  }
                });
              });
            });
          }

          //   setAllData(temp);
          setState({
            ...state,
            OffCampus: { ...state.OffCampus, allData: temp },
          });
        } else {
          alert.error("Error Occured");
        }
        setLoader(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkSingleSpecialization = (spec_id, deg_id) => {
    var spec_arr = jobData?.eligibility_criteria?.courses;

    var boole = false;
    if (
      spec_arr.map((item) => item.id).includes(spec_id) &&
      spec_arr.map((item) => item.degree_id).includes(deg_id)
    )
      boole = true;
    return boole;
  };

  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [previewDialog, setPreviewDialog] = useState(false);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const [postJobLoader, setPostJobLoader] = useState(false);
  const [draftJobLoader, setDraftJobLoader] = useState(false);

  const generateCsv = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1 ? (str += item + ",") : (str += item)
    );
    return str;
  };

  const CollegeID = () => {
    if (state.college_id_list.list.length > 0) {
      return `&is_select_all=true`;
    } else return "";
  };
  const checkJobID = () => {
    if (job_id) return `job_id=${btoa(job_id.toString())}`;
    else return ``;
  };

  const PublishJob = () => {
    Axios.post(
      BackendBaseApi.NIYUKTI + "job/company/job_status/",
      {
        job_id: job_id,
        status: "OPEN",
        user_type_id: localStorage.getItem("user_type_id"),
        user_id: localStorage.getItem("user_id"),
        service_type: "POST_JOB",
        amount: creditInfo?.user_service?.find(
          (item) => item.service_type === "POST_JOB"
        )?.credits,
        transaction_type: "DEBIT",
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    ).then((res) => {
      if (res.data.success) {
        alert.success("Job Opened Successfully!");
        dispatch(updateCreditInfo(res.data.data.transaction_data));
      }
    });
  };
  const getPostFunction = (data, status) => {
    setPostJobLoader(true);
    Axios.post(
      `${BackendBaseApi.NIYUKTI}job/company/job_post/?${CollegeID()}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          if (status === "OPEN")
            dispatch(updateCreditInfo(res.data.data.transaction_data));
          setProgress(false);
          if (localStorage.getItem("user_type") === "Company")
            history.push("/company/all-jobs" /* "/company/dashboard" */);
          else {
            if (status === "DRAFT") history.push("/college/drafted-job");
            else history.push("/college/open-job");
          }
        } else {
          setProgress(false);
          alert.error(res.data.data.message);
        }
        setPostJobLoader(false);
      })
      .catch((err) => {
        alert.error("err");
        setProgress(false);
        setPostJobLoader(false);
      });
  };

  const getPatchFunction = (data, status) => {
    setPostJobLoader(true);
    Axios.patch(
      `${
        BackendBaseApi.NIYUKTI
      }job/company/job_post/?${checkJobID()}${CollegeID()}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) alert.success(res.data.data.message);
        else alert.success(res.data.error);
        // if (status === "OPEN")
        //   dispatch(updateCreditInfo(res.data.data.transaction_data));
        setProgress(false);
        if (localStorage.getItem("user_type") === "Company")
          history.push("/company/dashboard");
        else {
          if (status === "DRAFT") history.push("/college/drafted-job");
          else history.push("/college/open-job");
        }
        setPostJobLoader(false);
        setDraftJobLoader(false);
      })
      .catch((err) => {
        alert.error("Fill all the details");
        setProgress(false);
        setPostJobLoader(false);
        setDraftJobLoader(false);
      });
  };
  const CreateJob = (status) => {
    setProgress(true);

    const data = {
      ...state.Basics,
      ...state.Details,
      ...state.Preferences,
      ...state.Colleges,
      job_status: status,
      college_list: state.college_id_list.list,
      user_type_id: localStorage.getItem("user_type_id"),
      user_id: localStorage.getItem("user_id"),
      service_type: "POST_JOB",
      amount: creditInfo?.user_service?.find(
        (item) => item?.service_type === "POST_JOB"
      )?.credits,
      transaction_type: "DEBIT",
    };

    const data2 = {
      ...state.Basics,
      ...state.Details,
      ...state.Preferences,
      ...state.Colleges,
      job_status: status,
      college_list: state.college_id_list.list,
      user_type_id: localStorage.getItem("user_type_id"),
      user_id: localStorage.getItem("user_id"),
      service_type: "POST_JOB",
      amount: creditInfo?.user_service?.find(
        (item) => item?.service_type === "POST_JOB"
      )?.credits,
      transaction_type: "DEBIT",
    };
    if (job_id) {
      var arr = [];
      data2.rounds.map((item) => {
        arr.push({
          round: item.round_id,
          r_name: item.r_name,
          round_no: item.round_no,
          is_interviewed: item.is_interviewed ? item.is_interviewed : false,
        });
      });
      data2.rounds = arr;
      if (locationUrl.flag === 1976) {
        getPatchFunction(data2, status);
      } else if (locationUrl.flag === 1979) {
        getPostFunction(data, status);
      } else {
        if (status === "OPEN") {
          getPatchFunction(data2, status);
          PublishJob();
        }
        if (status === "DRAFT") {
          getPatchFunction(data2, status);
        }
      }
    } else {
      getPostFunction(data, status);
    }
  };
  const validateData = () => {
    let draft = false;
    /* #################### basic################################# */
    if (!state?.Basics?.job_title) {
      alert.error("Job Title Required");
      return false;
    }
    if (!state?.Basics?.job_segment) {
      alert.error("Job Segment Required");
      return false;
    }

    if (state?.Basics?.job_segment && !state?.Details?.job_role) {
      alert.error("Job Role Required");
      return false;
    }
    if (!state?.Basics?.job_type) {
      alert.error("Job Type Required");
      return false;
    }
    if (state?.Basics?.job_type && !state?.Basics?.job_duration_start) {
      alert.error("Joining Date Required");
      return false;
    }
    if (state?.Basics?.job_type === 1 && !state?.Basics?.job_duration_end) {
      alert.error("Internship Duration Required");
      return false;
    }
    if (!state?.Basics?.employment_type) {
      alert.error("Employment Type Required");
      return false;
    }
    if (!state?.Preferences?.eligibility_criteria?.skills?.length > 0) {
      alert.error("Atleast One Skill Required");
      return false;
    }
    /* #################### details ################################# */
    if (!state?.Details?.job_description) {
      alert.error("Job Description Required");
      return false;
    }

    if (!state?.Preferences?.eligibility_criteria?.experience?.length > 0) {
      alert.error("Min and Max Work Experience Required");
      return false;
    }
    if (
      state?.Basics?.job_type === 1 &&
      state?.Basics?.internship_salary_type === "PAID" &&
      state?.Basics?.stipend === 0
    ) {
      alert.error("Please Enter Stipend Amount");
      return false;
    }

    if (
      state?.Basics?.job_type === 7 &&
      state?.Details?.salary_type === "PAID" &&
      (state?.Details?.ctc_min === 0 || state?.Details?.ctc_max === 0)
    ) {
      alert.error("Salary Range Required");
      return false;
    }
    if (
      state?.Basics?.job_type === 1 &&
      state?.Basics?.ppo &&
      state?.Details?.salary_type === "PAID" &&
      (state?.Details?.ctc_min === 0 || state?.Details?.ctc_max === 0)
    ) {
      alert.error("Salary Range Required");
      return false;
    }

    if (
      !state?.Details?.work_from_home &&
      !state?.Details?.allow_remote &&
      !state?.Details?.job_location?.length > 0
    ) {
      alert.error("Atleast One Job Location Required");
      return false;
    }
    if (!state?.Details?.vacancy) {
      alert.error("Please Enter No. Of Vacancies");
      return false;
    }
    if (state?.Details?.is_service_bond && state?.Details?.service_bond === 0) {
      alert.error("Please Enter Service Bond Duration");
      return false;
    }

    /* #################### Qualification ################################# */
    if (!state?.Preferences?.eligibility_criteria?.percentage) {
      alert.error("Minimum Percentage Required");
      return false;
    }
    if (
      !state?.Preferences?.eligibility_criteria?.graduation_years?.length > 0
    ) {
      alert.error("Graduation Years Required");
      return false;
    }

    /* #################### selection Procedure ################################# */
    if (!state?.Basics?.rounds?.length > 0) {
      alert.error("Selection Of Atleast One Round Required");
      return false;
    }
    if (!state?.Details?.documents?.length > 0) {
      alert.error("Please Select The Documents Required");
      return false;
    }

    /* #################### Hiring Process ################################# */
    if (!state?.Colleges?.hiring_type) {
      alert.error("Please Select Hiring Type");
      return false;
    }
    if (!state?.Colleges?.apply_start_date) {
      alert.error("Please Select Global Date: Apply Start Date");
      return false;
    }
    if (!state?.Colleges?.apply_end_date) {
      alert.error("Please Select Global Date: Apply End Date");
      return false;
    }
    if (
      state?.Colleges?.hiring_type === "OFF CAMPUS" &&
      !state?.OffCampus?.degree?.length > 0
    ) {
      alert.error("Please Select Courses");
      return false;
    }
    if (
      !state.college_id_list.checkAll &&
      state?.Colleges?.hiring_type &&
      state?.Colleges?.hiring_type === "ON CAMPUS" &&
      !state.Basics.posted_on_job_lake &&
      !state?.Colleges?.college?.length > 0
    ) {
      alert.error("Please Select College");
      return;
    }
    return true;
  };

  /*  const validateDraft = () => {
    let drafted = false;
    if (
      state?.Basics?.job_title ||
      state?.Basics?.job_segment ||
      state?.Basics?.job_type ||
      state?.Basics?.employment_type ||
      state?.Preferences?.eligibility_criteria?.skills?.length > 0 ||
      state?.Details?.job_description ||
      state?.Preferences?.eligibility_criteria?.experience?.length > 0 ||
      state?.Details?.job_location?.length > 0 ||
      state?.Details?.vacancy ||
      state?.Details?.is_service_bond ||
      state?.Preferences?.eligibility_criteria?.percentage ||
      state?.Preferences?.eligibility_criteria?.graduation_years?.length > 0 ||
      state?.Basics?.rounds?.length > 0 ||
      state?.Details?.documents?.length > 0 ||
      state?.Colleges?.hiring_type ||
      state?.Colleges?.apply_start_date ||
      state?.Colleges?.apply_end_date
    ) {
      drafted = true;
    } else {
      alert.error("Fill Form Before Drafting");
    }

    return drafted;
  }; */

  // useEffect(() => {
  //   console.log("SSSSSSS", state);
  // }, [state]);
  return (
    <div style={{ marginTop: 30, marginRight: "22px" }}>
      <LessCredit
        Amount={
          creditInfo?.user_service?.find(
            (item) => item.service_type === "POST_JOB"
          )?.credits
        }
        walletAmount={creditInfo?.available_credits}
      />
      <Box className={classes.FormBox}>
        <Basics
          state={state}
          setState={setState}
          basicsRef={basicsRef}
          jobData={jobData}
          setApplyButtonClicked={setApplyButtonClicked}
        />
        <Details
          state={state}
          setState={setState}
          detailsRef={detailsRef}
          jobData={jobData}
          setApplyButtonClicked={setApplyButtonClicked}
        />
        <Qualifications
          state={state}
          setState={setState}
          qualificationsRef={qualificationsRef}
          jobData={jobData}
          courseArray={courseArray}
          setCourseArray={setCourseArray}
        />
        <SelectionProcedure
          state={state}
          setState={setState}
          selectionRef={selectionRef}
          jobData={jobData}
        />
        {localStorage.getItem("user_type") === "Company" && (
          <HiringProcess
            state={state}
            setState={setState}
            hiringRef={hiringRef}
            offCampusLoader={loader}
            setOffCampusLoader={setLoader}
            jobData={jobData}
            setApplyButtonClicked={setApplyButtonClicked}
            applyButtonClicked={applyButtonClicked}
          />
        )}
        <Divider />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          {(localStorage.getItem("user_type") === "College" ||
            localStorage.getItem("user_type") === "Company") && (
            <CommonButton
              variant="contained"
              style={{ minWidth: "120px" }}
              onClick={() => {
                if (validateData()) {
                  if (salaryRange(state) === true)
                    alert.error("Please Enter the right CTC Package");
                  else {
                    setDraftJobLoader(true);
                    CreateJob("DRAFT");
                  }
                }
              }}
              /*   disabled={
                basicsCheck &&
                detailsCheck &&
                // preferencesCheck &&
                selectionCheck &&
                hiringCheck &&
                locationUrl.flag !== 1976
                  ? false
                  : true
              } */
              disabled={postJobLoader ? true : false}
            >
              <span className={classes.labelSize}>
                {draftJobLoader ? (
                  <CircularProgress color="white" size={17} />
                ) : (
                  "Save as Draft"
                )}
              </span>
            </CommonButton>
          )}
          <CommonButton
            variant="contained"
            onClick={() => {
              if (salaryRange(state) === true)
                alert.error("Please Enter the right CTC Package");
              else setPreviewDialog(true);
            }}
            /*     disabled={
              basicsCheck && detailsCheck && selectionCheck && hiringCheck
                ? false
                : true
            } */
            disabled={postJobLoader ? true : false}
          >
            <span className={classes.labelSize}>Preview</span>
          </CommonButton>
          <CommonButton
            variant="contained"
            style={{ minWidth: "120px" }}
            onClick={() => {
              if (validateData()) {
                if (salaryRange(state) === true) {
                  alert.error("Please Enter the right CTC Package");
                  return;
                }
                // console.log(creditInfo?.user_service?.find((item) => item.service_type === "POST_JOB")?.credits)
                /*   if (
                  locationUrl.flag === 1976 ||
                  creditInfo?.user_service?.find(
                    (item) => item.service_type === "POST_JOB"
                  )?.credits === 0
                ) */
                CreateJob("OPEN");
              }

              /*   else setConfirmationDialog(true); */
              // else
              //   CreateJob(
              //     /*  localStorage.getItem("comp_profile_completed") === "true" ||
              //       localStorage.getItem("user_type") === "College"
              //       ?  */ "OPEN"
              //     /*  : "PENDING" */
              //   );
            }}
            /*    disabled={
              basicsCheck &&
              detailsCheck &&
              // preferencesCheck &&
              selectionCheck &&
              hiringCheck
                ? false
                : true
            } */
          >
            <span className={classes.labelSize}>
              {postJobLoader ? (
                <CircularProgress color="white" size={17} />
              ) : locationUrl.flag === 1976 ? (
                "Edit & Post Job"
              ) : (
                "Post Job"
              )}
            </span>
          </CommonButton>
        </Box>
        {/* <JobDescriptionTempelate /> */}
      </Box>
      <PreviewDialog
        state={state}
        setState={setState}
        open={previewDialog}
        setOpen={setPreviewDialog}
        CreateJob={CreateJob}
        confirmationDialog={confirmationDialog}
        setConfirmationDialog={setConfirmationDialog}
        creditInfo={creditInfo}
        progress={progress}
        qualificationsRef={qualificationsRef}
        basicsCheck={basicsCheck}
        detailsCheck={detailsCheck}
        preferencesCheck={preferencesCheck}
        selectionCheck={selectionCheck}
        hiringCheck={hiringCheck}
        courseArray={courseArray}
        setCourseArray={setCourseArray}
        locationUrl={locationUrl}
        validateData={validateData}
        salaryRange={salaryRange}
        draftJobLoader={draftJobLoader}
        postJobLoader={postJobLoader}
      />

      <CreditDialog
        open={confirmationDialog}
        setOpen={setConfirmationDialog}
        poster={CreateJob}
        posterArg={
          localStorage.getItem("user_type") === "Company" ||
          localStorage.getItem("user_type") === "College"
            ? "OPEN"
            : "PENDING"
        }
        creditsRequired={
          creditInfo?.user_service?.find(
            (item) => item.service_type === "POST_JOB"
          )?.credits
        }
        serviceType={
          creditInfo?.user_service?.find(
            (item) => item.service_type === "POST_JOB"
          )?.service_type
        }
        progress={progress}
      />
      <br />
      <br />
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    FormBox: {
      borderRadius: 30,
      //   borderLeft: "4px solid #3282c4",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      WebkitBackdropFilter: "blur(30px)",
      backdropFilter: "blur(30px)",
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[25],
      padding: 30,
      // width: "90%",
      width: "100%",

      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "solid 2px #F1F1F1",
    },
    preferenceButton: {
      borderRadius: 30,
      background: "#3282C4",
      color: "white",
      "&:hover": {
        border: "2px solid #3282C4",
        background: "white",
        color: "#3282C4",
      },
    },
    labelSize: {
      fontSize: "13px",
    },
    "@media only screen and (max-width: 768px)": {
      FormBox: {
        width: "100%",
      },
      labelSize: {
        fontSize: "11px",
      },
    },
  })
);
export default MainForm;

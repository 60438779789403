import React, { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import Logo from "../../../assets/img/getwork-logo.png";
import "./AuthHeader.css";
import SignupOptions from "./SignupOptions";

const AuthHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const [renderModal, setRenderModal] = useState(false);
  let address = location.pathname;
  let element = [];
  const checkAddress = () => {
    switch (address) {
      case "/":
        element.push(
          <span key="1" className="fs-16">
            New to GetWork?{" "}
            <strong
              className="link-text"
              style={{ color: "#3282C4" }}
              onClick={() => {
                history.push("/signup");
              }}
            >
              Signup Now
            </strong>{" "}
          </span>
        );
        break;
      case "/login":
        element.push(
          <span key="1" className="fs-16">
            New to GetWork?{" "}
            <strong
              className="link-text"
              style={{ color: "#3282C4" }}
              onClick={() => {
                history.push("/signup");
              }}
            >
              Signup Now
            </strong>{" "}
          </span>
        );
        break;
      case "/company/join":
        element = <div></div>;
        break;
      case "/company/create":
        element = (
          <span key="2" className="fs-16">
            Got a company?{" "}
            <strong
              onClick={() => {
                history.push("/company/join");
              }}
              className="link-text"
            >
              Join here
            </strong>{" "}
          </span>
        );
        break;
      default:
        element = (
          <span key="3" className="fs-16">
            Already on GetWork?{" "}
            <strong
              className="link-text"
              style={{ color: "#3282C4" }}
              onClick={() => {
                localStorage.removeItem("gw_token");
                localStorage.removeItem("user_type");
                history.push("/login");
              }}
            >
              Login here
            </strong>{" "}
          </span>
        );
        break;
    }
  };
  checkAddress();

  return (
    <>
      <nav className="auth-navbar sticky-top navbar-expand-lg navbar-light bg-light w-100 ">
        <a className="navbar-brand" href="/login">
          <img src={Logo} className="logo" alt="" />
        </a>

        <form className="form-inline my-2 my-lg-0">
          <div className="mx-auto">{element}</div>
        </form>
      </nav>
      {renderModal && (
        <SignupOptions
          renderModal={renderModal}
          setRenderModal={setRenderModal}
        />
      )}
    </>
  );
};

export default AuthHeader;

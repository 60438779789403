import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import CommonButton from "../../common/CommonButton/CommonButton";
import refer_img from "../../../assets/form_fill.gif";
import { createStyles, makeStyles, withStyles } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useAlert } from "react-alert";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
export default function CompanyVerificationDialog(props) {
  const { open, handleClickOpen, handleClose } = props;
  const classes = useStyles();
  const alert = useAlert();

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>

        <DialogContent>
          <img src={refer_img} style={{ width: "300px", margin: "auto", display: "flex " }} alt="" />
          <br />
          <div style={{ width: "70%", margin: "auto" }}>
            <p style={{ fontSize: 15, textAlign: "center" }}>
              Please Enter all the Company Details of <b>Details</b> section to verify your Company.
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    headingNew: {
      textAlign: "left",
      fontSize: 14,
      // fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      marginBottom: 20,
      marginTop: "20%",
    },
  })
);

import { Box, Chip, Divider, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DoneIcon from '@mui/icons-material/Done';
import Stack from '@mui/material/Stack';
import Axios from "axios";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import GetMoney from "../../assets/icon/get-money.png";
import logo from "../../assets/img/getwork-logo.png";
import redirect from "../../assets/redirecting.gif";
import AddMoney from "../../assets/svg/add_money.svg";
import OrderSummary from "../../assets/svg/order_summary.svg";
import ScanCode from "../../assets/svg/scancode.svg";
import { BackendBaseApi, Payment } from "../../constants/constants";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  "& .MuiTextField-root": {
    margin: theme.spacing(1),
    width: "25ch",
  },

  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    height: "35px",
    marginLeft: "35px",
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "center",
    height: "320px",
    color: theme.palette.text.secondary,
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
  },
  paper2: {
    padding: theme.spacing(1),
    textAlign: "center",
    height: "320px",
    color: theme.palette.text.secondary,
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
  },
  recharge: {
    borderRadius: "30px",
    marginBottom: "12px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#007BFF",
    marginTop: "10px"
  },
  paper3: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
}));

export default function New_gate(props) {
  const {  setOpenCheck } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [amount, setAmount] = React.useState(0);
  const [totalTax, setTotalTax] = React.useState(0);
  const [CGST, setCGST] = React.useState(0);
  const [SGST, setSGST] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const [paramDict, setParamDict] = React.useState();
  const [redirection, setRedirection] = React.useState(false);
  const [qrCode, setQRCode] = React.useState(false);

  const [chipData, setChipData] = React.useState([{
      amount: 299,
      isSelected: false
    }, {
      amount: 500,
      isSelected: false
    },
    {
      amount: 3000,
      isSelected: false
    },{
      amount: 4999,
      isSelected: false
    },
    {
      amount: 5000,
      isSelected: false
    },
    {
      amount: 8000,
      isSelected: false
    },
    {
      amount: 10000,
      isSelected: false
    },
    ])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCheck(false);
  };

  

  useEffect(() => {
    if (paramDict !== undefined) {
      let keyArr = Object.keys(paramDict);
      let valArr = Object.values(paramDict);
      document.getElementById("paymentFrm").style.display = "none";

      let heading1 = document.createElement("h1");
      // heading1.innerText =
      //   "Redirecting you to the paytm thoda sa wait kro ....";
      // let heading2 = document.createElement("h1");
      // heading2.innerText = "Refresh kroge to paise gayab ho jaayenge....";
      let frm = document.createElement("form");
      // frm.setAttribute("target", "_blank");

      frm.action = Payment.pay;
      frm.method = "post";
      frm.name = "paytmForm";
      keyArr.map((k, i) => {
        let inp = document.createElement("input");
        inp.key = i;
        inp.type = "hidden";
        inp.name = k;
        inp.value = valArr[i];
        frm.appendChild(inp);
      });
      // document.body.appendChild(heading1);
      // document.body.appendChild(heading2);
      document.body.appendChild(frm);
      frm.submit();
    }
  }, [paramDict]);

  useEffect(() => {
    if (amount && amount > 0) {
      var cgst = 0.09 * amount;
      var sgst = 0.09 * amount;
      var total_tax = sgst + cgst;
      setTotalTax(parseFloat(total_tax).toFixed(2));
      setCGST(cgst.toFixed(2));
      setSGST(sgst.toFixed(2));
      setTotalAmount((parseFloat(amount) + parseFloat(total_tax)).toFixed(2));
    }
    if (!amount || amount === 0) {
      setTotalTax(0);
      setCGST(0);
      setSGST(0);
      setTotalAmount(0);
    }
  }, [amount]);

  function handleClick(event, index){
    let tempData = [...chipData];
    tempData.forEach((data, i) => {
      if(i === index){
        if(tempData[index]?.isSelected){
          setAmount(0);
        }
        else{
          setAmount(tempData[index]?.amount)
        }
        tempData[index].isSelected = !tempData[index].isSelected;
      }else{
      tempData[i].isSelected = false;
      }
    })
    setChipData(tempData);
  }

  const startPayment = () => {
    let bodyData = new FormData();
    bodyData.append("user", parseInt(localStorage.getItem("user_id")));
    bodyData.append(
      "user_type",
      parseInt(localStorage.getItem("user_type_id"))
    );
    bodyData.append("order_amount", parseFloat(amount));
    bodyData.append("order_status", "TXN_INITIATED");

    bodyData.append("total_tax", parseFloat(totalTax));
    bodyData.append("CGST", parseFloat(CGST));
    bodyData.append("SGST", parseFloat(SGST));
    bodyData.append("total_amount", parseFloat(totalAmount));

    Axios.post(
      `${BackendBaseApi.PRAVESH}api/paytm_payment/pay/`,

      bodyData,

      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res) {
          setParamDict(res.data.param_dict);
          setRedirection(true);
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Some Error Occured");
      });
  };

  const products = [
    {
      name: "Your Amount",
      desc: "",
      price: `₹ ${amount && amount > 0 ? amount : 0}`,
    },
    { name: "SGST (9%)", desc: "", price: `₹ ${SGST}` },
    { name: "SGST (9%)", desc: "", price: `₹ ${CGST}` },
  ];

  return (
    <>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Button
        onClick={handleClickOpen}
        style={{
          backgroundColor: "#219653",
          color: "#ffffff",
          width: "50%",
          height: "50px",
          borderRadius: 30,
          display: "flex",
          "&:focus": {
            outline: "none",
          },
        }}
        startIcon={<img src={GetMoney} width="30px" height="auto" />}
      >
        Recharge Now
      </Button>
      <Dialog
        //fullScreen={fullScreen}
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          padding="3%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ overflowX: "hidden" }}
        >
          <div className={classes.root}>
            {!redirection && (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <img
                    src={logo}
                    width="40%"
                    height="auto"
                    style={{ marginTop: "-10px" }}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={2}></Grid>

                <Grid item xs={4}>
                  <div className={clsx(classes.column, classes.helper)}>
                    <p style={{ float: "right", marginTop: "-12px" }}>
                      Total Balance
                    </p>
                    <br />
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ float: "right", marginTop: "-20px" }}
                    >
                      {creditInfo && creditInfo.available_credits + " /-"}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "-35px" }}>
                  <Divider />
                </Grid>

                <Grid item xs={6}>
                  <Paper className={classes.paper1}>
                    <img
                      src={OrderSummary}
                      width="17%"
                      height="auto"
                      style={{ marginTop: "-50px" }}
                    />
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: "-15px" }}
                    >
                      Order summary
                    </Typography>
                    <Divider />

                    <List disablePadding>
                      {products.map((product) => (
                        <ListItem
                          className={classes.listItem}
                          key={product.name}
                        >
                          <ListItemText
                            primary={product.name}
                            secondary={product.desc}
                          />
                          <Typography variant="body2">
                            {product.price}
                          </Typography>
                        </ListItem>
                      ))}
                      <Divider />

                      <ListItem className={classes.listItem}>
                        <ListItemText primary="Total (Inc. GST)" />
                        <Typography
                          variant="subtitle1"
                          className={classes.total}
                        >
                          ₹ {totalAmount}
                        </Typography>
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper className={classes.paper2}>
                    <img
                      src={AddMoney}
                      width="17%"
                      height="auto"
                      style={{ marginTop: "-50px" }}
                    />
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: "-15px" }}
                    >
                      Add Money To Wallet
                    </Typography>
                    <Divider />
                  {!qrCode && 
                  <>
                    <List disablePadding>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                      >
                        <div style={{marginTop: "10px"}}>
                          <TextField
                            type="number"
                            id="standard-textarea"
                            label="Enter Amount"
                            placeholder="Amount"
                            value={amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                          
                        </div>
                      </form>
                      <div style={{marginTop: "10px"}}>
                      <Typography color="primary" variant="body2">
                              Quick Recharge
                      </Typography>
                      </div>
                      <Grid spacing={1}>
                      {
                        chipData?.map((dta, index) => {
                          return(
                            <Chip
                              label={dta?.amount}
                              onClick={(e) => {
                                handleClick(e, index)
                              }}
                              variant="outlined"
                              color="primary"
                              style={{"background": dta?.isSelected ? "#E5F2FF" : "white", margin: "2px", cursor: "pointer"}}
                            />
                          )
                          })
                      
                      }
                      </Grid>
                    </List>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.recharge}
                      onClick={() => startPayment()}
                      disabled={amount && amount > 0 ? false : true}
                    >
                      Pay Now
                    </Button>
                    <br />
                    <Typography variant="body2">
                      OR
                    </Typography>
                    <Typography color="primary" style={{cursor: "pointer"}} onClick={() => {
                      setQRCode(true);
                    }}>
                      Scan QR code
                    </Typography>
                    </>
            }
            {
              qrCode && 
              <>
              <List disablePadding>
                      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                      <Typography color="primary" variant="body1">
                             Scan Now
                      </Typography>
                      <img
                      src={ScanCode}
                      width="50%"
                      height="160px"
                    />
                    </div>
                      
                    </List>
                    
                    <br />
                    <Typography variant="body2">
                      OR
                    </Typography>
                    <Typography color="primary" style={{cursor: "pointer"}} onClick={() => {
                      setQRCode(false);
                    }}>
                      Pay from another method
                    </Typography>
              </>
            }
                  </Paper>
                </Grid>
              </Grid>
            )}
            

            {redirection && (
              <img src={redirect} alt="" />
            )}
          </div>

          <div id="paymentFrm"></div>
        </Box>
      </Dialog>
    </>
  );
}

import {
  Box,
  createStyles,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,

  OutlinedInput,
  TextField
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import success from "../../../assets/common/smile_success.gif";
import line from "../../../assets/images/lineOr.png";
import { NEXT_APP_ENDPOINT, passwordRegex, websiteRegex } from "../../../constants/constants";
import CommonButton from "../../common/CommonButton/CommonButton";
import ReferralCode from "../Dialogs/ReferralCode";
import CreateNewCompany from "./CreateNewCompany";
import DetailsComplete from "./DetailsComplete";

const FormBox = (props) => {
  const {
    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    companyList,
    jobRolesList,
    stateList,
    cityList,
    companyCreation,
    setCompanyCreation,
    currentState,
    setCurrentState,
    handleSubmit,
    finalReferralCode,
    setFinalReferralCode,
  } = props;
  
  const [buttonClicked, setButtonClicked] = useState(false);
  const classes = useStyles();
  const [currentCompany, setCurrentCompany] = useState();
  const [currentJobRole, setCurrentJobRole] = useState();
  const [currentCity, setCurrentCity] = useState();
  const history = useHistory();
  const alert = useAlert();
  const [referralMessage, setRefferalMessage] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [values, setValues] = React.useState({
    password: "",

    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [validate, setValidate] = useState(false);

  const validateData = () => {
    setValidate(false);

    var company_error = "";
    var job_error = "";
    var phone_error = "";
    var password_error = "";
    var designation_error = "";

    if (!userDetails.CompanyName) {
      company_error = "Company Name cannot be left blank";
      setValidate(true);
    }
    if (userDetails.JobTitle === "") {
      designation_error = "Designation cannot be left blank";
      setValidate(true);
    }
    if (localStorage.getItem("mobile") === "null") {
      if (userDetails.PhoneNo === null) {
        phone_error = "Phone Number cannot be left blank";
        setValidate(true);
      }
      if (userDetails.PhoneNo && userDetails.PhoneNo.length !== 10) {
        phone_error = "Enter a valid 10 digit phone number";
        setValidate(true);
      }
      if (userDetails.Password === "") {
        password_error = "Password cannot be left blank";
        setValidate(true);
      }
      if (!passwordRegex.test(userDetails.Password)) {
        password_error = "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit";
        setValidate(true);
      }
    }
    if (userDetails.JobRole === null) {
      job_error = "Job Role cannot be left blank";
      setValidate(true);
    }

    console.log(company_error);
    setErrorMessages({ ...errorMessages, password: password_error, jobRole: job_error, phone: phone_error, companyName: company_error, jobTitle: designation_error });

    setButtonClicked(true);
  };

  const validateNewCompany = () => {
    setValidate(false);

    var company_error = "";
    var website_error = "";
    var state_error = "";
    var city_error = "";
    var password_error = "";
    var phone_error = "";
    var designation_error = "";

    if (userDetails.CompanyTypedName === "") {
      company_error = "Company Name cannot be left blank";
      setValidate(true);
    }
    if (userDetails.JobTitle === "") {
      designation_error = "Designation cannot be left blank";
      setValidate(true);
    }
    if (localStorage.getItem("mobile") === "null") {
      if (userDetails.PhoneNo === null) {
        phone_error = "Phone Number cannot be left blank";
        setValidate(true);
      }
      if (userDetails.PhoneNo && userDetails.PhoneNo.length !== 10) {
        phone_error = "Enter a valid 10 digit phone number";
        setValidate(true);
      }
      if (userDetails.Password === "") {
        password_error = "Password cannot be left blank";
        setValidate(true);
      }
      if (!passwordRegex.test(userDetails.Password)) {
        password_error = "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit";
        setValidate(true);
      }
    }
    if (userDetails.CompanyWebsite === "") {
      website_error = "Website cannot be left blank";
      setValidate(true);
    }
    if (userDetails.CompanyWebsite && !websiteRegex.test(userDetails.CompanyWebsite)) {
      website_error = "Enter a valid website link";
      setValidate(true);
    }

    if (userDetails.State === null) {
      state_error = "State cannot be left blank";
      setValidate(true);
    }

    if (userDetails.City === "") {
      city_error = "City be left blank";
      setValidate(true);
    }

    setErrorMessages({
      ...errorMessages,
      companyNameTyped: company_error,
      password: password_error,
      state: state_error,
      city: city_error,
      companyWebsite: website_error,
      phone: phone_error,
      jobTitle: designation_error,
    });

    setButtonClicked(true);
  };

  useEffect(() => {
    if (!validate && buttonClicked) {
      handleSubmit();
      console.log(errorMessages);
    }
    console.log(errorMessages);
  }, [buttonClicked]);

  useEffect(() => {
    setCurrentCity();
    setCurrentState();
    setCurrentJobRole();
    setCurrentCompany();
  }, [companyCreation]);

  return (
    <Box className={classes.formBox}>
      {localStorage.getItem("is_pref_filled") === "true" ? (
        <DetailsComplete />
      ) : (
        <>
          <div style={{ width: "70%", margin: "auto" }}>
            {localStorage.getItem("mobile") === "null" && (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p className={classes.heading}>User Details</p>
                  <span className={classes.subTitle}>Enter the below mentioned User Details</span>
                </div>

                <TextField
                  error={errorMessages.phone !== "" ? true : false}
                  key="jhvjh"
                  id="standard-error-helper-text-1"
                  variant="outlined"
                  type="number"
                  label={<span>Phone No.</span>}
                  helperText={<span style={errorMessages.phone !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.phone}</span>}
                  value={userDetails.PhoneNo}
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, PhoneNo: e.target.value });
                    setErrorMessages({ ...errorMessages, phone: "" });
                    setButtonClicked(false);
                  }}
                  InputProps={{
                    style: { fontSize: 13 },
                  }}
                  InputLabelProps={{
                    style: { backgroundColor: "#fff" },
                  }}
                  className={classes.fieldStyling}
                />
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  error={errorMessages.password !== "" ? true : false}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="standard-adornment-password-1"
                    //   style={errorMessages.password !== "" ? { color: "#D64045" } : { color: "grey" }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    key="df"
                    id="standard-adornment-password-1"
                    type={values.showPassword ? "text" : "password"}
                    value={userDetails.Password}
                    labelWidth={80}
                    onChange={(e) => {
                      setUserDetails({ ...userDetails, Password: e.target.value });
                      setErrorMessages({ ...errorMessages, password: "" });
                      setButtonClicked(false);
                    }}
                    style={{ fontSize: 13 }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <FormHelperText id="standard-weight-helper-text" style={errorMessages.password !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                    {errorMessages.password !== ""
                      ? errorMessages.password
                      : "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit"}
                  </FormHelperText>
                </FormControl>
              </>
            )}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className={classes.heading}>{companyCreation ? "Create New Company" : "Company Details"}</p>
              <span className={classes.subTitle}>Enter the below mentioned Company Details</span>
            </div>
            {!companyCreation ? (
              <>
                <Autocomplete
                  value={currentCompany}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setUserDetails({ ...userDetails, CompanyName: newValue.id });
                      setCurrentCompany(newValue);
                    } else {
                      setUserDetails({ ...userDetails, CompanyName: null });

                      setCurrentCompany();
                    }
                    setErrorMessages({ ...errorMessages, companyName: "" });

                    setButtonClicked(false);
                  }}
                  id="role890"
                  options={companyList}
                  getOptionLabel={(option) => option.company}
                  renderOption={(option) => {
                    return <span style={{ fontSize: 13 }}>{`${option.company}`}</span>;
                  }}
                  className={classes.fieldStyling}
                  renderInput={(params) => {
                    params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={<span>Companies</span>}
                        variant="outlined"
                        error={errorMessages.companyName !== "" ? true : false}
                        helperText={
                          <span style={errorMessages.companyName !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.companyName}</span>
                        }
                      />
                    );
                  }}
                />
                <Autocomplete
                  value={currentJobRole}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setUserDetails({ ...userDetails, JobRole: newValue.id });
                      setCurrentJobRole(newValue);
                    } else {
                      setUserDetails({ ...userDetails, JobRole: "" });

                      setCurrentJobRole();
                    }
                    setErrorMessages({ ...errorMessages, jobRole: "" });

                    setButtonClicked(false);
                  }}
                  id="role345"
                  options={jobRolesList}
                  getOptionLabel={(option) => option.sub_user}
                  renderOption={(option) => {
                    return <span style={{ fontSize: 13 }}>{`${option.sub_user}`}</span>;
                  }}
                  className={classes.fieldStyling}
                  renderInput={(params) => {
                    params.inputProps.className = classes.comboOptions;
                    return (
                      <TextField
                        {...params}
                        label={<span>Select Job Role</span>}
                        variant="outlined"
                        error={errorMessages.jobRole !== "" ? true : false}
                        helperText={
                          <span style={errorMessages.jobRole !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.jobRole}</span>
                        }
                      />
                    );
                  }}
                />
              </>
            ) : (
              <CreateNewCompany
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
                buttonClicked={buttonClicked}
                setButtonClicked={setButtonClicked}
                currentCity={currentCity}
                setCurrentCity={setCurrentCity}
                currentState={currentState}
                setCurrentState={setCurrentState}
                cityList={cityList}
                stateList={stateList}
                companyCreation={companyCreation}
                setCompanyCreation={setCompanyCreation}
              />
            )}
            <TextField
              error={errorMessages.jobTitle !== "" ? true : false}
              key="jhvjhs"
              id="standard-error-helper-text-1"
              variant="outlined"
              type="text"
              label={<span>Your Designation</span>}
              helperText={<span style={errorMessages.jobTitle !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.jobTitle}</span>}
              value={userDetails.JobTitle}
              onChange={(e) => {
                setUserDetails({ ...userDetails, JobTitle: e.target.value });
                setErrorMessages({ ...errorMessages, jobTitle: "" });
                setButtonClicked(false);
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={{
                style: { backgroundColor: "#fff" },
              }}
              className={classes.fieldStyling}
            />

            <img src={line} alt="" width="100%" style={{ marginBottom: 20, marginTop: 20 }} />
            <Box display="flex" justifyContent="center" margin="auto" marginBottom="20px">
              <CommonButton
                onClick={() => {
                  setCompanyCreation(!companyCreation);
                }}
                variant="outlined"
              >
                {companyCreation ? "Company Already Exists?" : "Create New Company"}
              </CommonButton>
            </Box>
            <Divider />

            {finalReferralCode !== "" && (
              <p className={classes.headingNew}>
                {" "}
                <img src={success} height="40px" alt="" />
                {referralMessage}{" "}
              </p>
            )}

            <div style={{ display: "flex", marginTop: 14, justifyContent: "center" }}>
              <CommonButton
                onClick={() => {
                  if (finalReferralCode !== "") {
                    setUserDetails({ ...userDetails, Referral: "" });
                    setFinalReferralCode("");
                  } else handleClickOpen();
                }}
                style={{ height: 30 }}
                variant="outlined"
              >
                {finalReferralCode === "" ? " Click here to enter your Referral Code" : "Remove Referral Code"}
              </CommonButton>
            </div>
          </div>

          <Box display="flex" justifyContent="space-between" margin="auto" marginBottom="20px" marginTop="20px">
            <CommonButton
              onClick={() => {
                // history.push("/public/open-jobs");
                window.open(`${NEXT_APP_ENDPOINT}/public/jobs`, "_self");
              }}
              variant="contained"
            >
              Explore GetWork
            </CommonButton>
            <CommonButton
              onClick={() => {
                if (!companyCreation) validateData();
                else validateNewCompany();
              }}
              variant="contained"
            >
              Submit
            </CommonButton>
          </Box>
        </>
      )}

      <ReferralCode
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        errorMessages={errorMessages}
        setErrorMessages={setErrorMessages}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        setButtonClicked={setButtonClicked}
        finalReferralCode={finalReferralCode}
        setFinalReferralCode={setFinalReferralCode}
        setRefferalMessage={setRefferalMessage}
      />
    </Box>
  );
};

export default FormBox;

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    headingNew: {
      textAlign: "center",
      fontSize: 14,
      // fontWeight: "bold",
      marginBottom: "0rem",
      marginTop: 20,
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      marginBottom: 20,
    },
  })
);

import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";

const ChangePasswordDialog = (props) => {
  const classes = useStyles();
  const {
    open1,
    handleClose,
    setOldPass,
    setNewPass,
    oldpass,
    newpass,
    setConfPass,
    passwordChange,
    resetFields,
    confpass,
    updatingPassword,
  } = props;

  return (
    <div>
      <Dialog
        open={open1}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
      >
        <DialogContent>
          <div style={{ width: "480px", paddingBottom: "10px" }}>
            <Typography align="center" className={classes.grid3} variant="h6">
              <b>Change Your Password</b>&nbsp;
            </Typography>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography className={classes.grid3} variant="body1">
                  <b>Old Password</b>&nbsp;
                  <span className={classes.redstar}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={7} className={classes.grid4}>
                <TextField
                  id="date"
                  label="Old Password"
                  type="password"
                  autoComplete="off"
                  onChange={(e) => {
                    setOldPass(e.target.value);
                  }}
                  value={oldpass}
                  variant="outlined"
                  inputProps={{
                    autocomplete: "new-password",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography className={classes.grid3} variant="body1">
                  <b>New Password</b>&nbsp;
                  <span className={classes.redstar}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={7} className={classes.grid4}>
                <TextField
                  id="date"
                  label="New Password"
                  type="password"
                  onChange={(e) => {
                    setNewPass(e.target.value);
                  }}
                  value={newpass}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography className={classes.grid3} variant="body1">
                  <b>Confirm Password</b>&nbsp;
                  <span className={classes.redstar}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={7} className={classes.grid4}>
                <TextField
                  id="date"
                  label="Confirm Password"
                  type="password"
                  onChange={(e) => {
                    setConfPass(e.target.value);
                  }}
                  value={confpass}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={4}></Grid>
              <Grid
                item
                xs={4}
                className={classes.grid4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  disabled={updatingPassword}
                  style={{
                    width: "100px",
                    height: "40px",
                    borderRadius: "30px",
                    backgroundColor: "#007BFF",
                    color: "#fff",
                    display: "flex",
                    boxShadow: "none",
                  }}
                  className={classes.postButton}
                  onClick={() => {
                    passwordChange();
                    resetFields();
                  }}
                >
                  {updatingPassword ? (
                    <CircularProgress size={25} style={{ color: "#ffffff" }} />
                  ) : (
                    "Update"
                  )}
                </Button>{" "}
              </Grid>
              <Grid item xs={4} className={classes.grid4}></Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChangePasswordDialog;

const useStyles = makeStyles((theme) => createStyles({}));

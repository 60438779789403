import { Hidden } from "@material-ui/core";
import Axios from "axios";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Footer from "../../../components/public/FooterNew/Footer";
import FooterDomain from "../../../components/public/FooterNew/Footer/FooterDomain";
import ResetPasswordForm from "../../../components/public/LogIn/Card/Form/ResetPasswordForm";
import MobileTabRegister from "../../../components/public/LogIn/Header/MobileTabRegister";
import StudentHeader from "../../../components/public/LogIn/Header/StudentHeader";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";
import { BackendBaseApi } from "../../../constants/constants";

function ResetPassword() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const params = useParams();
  const [value, setValue] = useState(0);
  const [step, setStep] = useState(0);
  const [Password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [uid, setUid] = useState("");
  const [token, setToken] = useState("");
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  React.useEffect(() => {
    const temp = location.pathname.split("/");
    setUid(temp[2]);
    setToken(temp[3]);
  }, []);

  const handleSubmit = (e) => {
    if (Password !== confirmPassword) return;
    setLoading(true);
    Axios.post(BackendBaseApi.PRAVESH + "api/email/reset", {
      uid: uid,
      token: token,
      password: Password,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          alert.success(res.data.data.message);
          setStep(step + 1);
        } else {
          alert.error(res.data.error);
          if (res.data.error === "Invalid token") setStep(3);
        }
      })
      .catch((err) => {
        setLoading(false);

        localStorage.clear();
        alert.error("Invalid Session!! Please try again");
      });
  };

  return (
    <>
      {domain?.domain ? <DomainNavbar /> : <Navbar />}

      <Hidden smDown>
        {" "}
        <StudentHeader
          showGrid={false}
          resetPasswordForm={true}
          type={"reset-password"}
          handleSubmit={handleSubmit}
          loading={loading}
          resPassword={Password}
          setResPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          resetSteps={step}
          setResetSteps={setStep}
        />
      </Hidden>
      <Hidden smUp>
        {" "}
        <div style={{ marginTop: "20%" }}>
          <ResetPasswordForm
            loading={loading}
            handleSubmit={handleSubmit}
            resPassword={Password}
            setResPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            resetSteps={step}
            setResetSteps={setStep}
          />
        </div>
      </Hidden>
      {!domain?.domain ? <Footer /> : <FooterDomain />}
    </>
  );
}

export default ResetPassword;

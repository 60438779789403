import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Carousel, { consts } from "react-elastic-carousel";
import Item from "./Item";
import "./styles_main.css";
import eg from "../../assets/images/banner-bg.jpg";
import eg2 from "../../assets/images/company-7.jpg";
import { Box, Button, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeState, UPDATE_INDUSTRY, UPDATE_JOB_SEGMENT } from "./action";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 5 },
];

const useStyles = makeStyles((theme) => ({
  CarouselTitle: {
    paddingLeft: "8%",
    fontSize: "14px",
    marginTop: "-4%",
    color: theme.palette.type === "dark" ? "#ffff" : "black",
  },
  CarouselSubTitle: {
    fontSize: "12px",
    color: theme.palette.type === "dark" ? "#a4a4a4" : "#424242",
  },
}));

export default function AppCarousel(props) {
  const { data } = props;
  const classes = useStyles();
  const array = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  const dispatch = useDispatch();
  const history = useHistory();
  const collegeID = localStorage.getItem("college_id");

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };
  const [segmentList, setSegmentList] = React.useState([]);
  useEffect(() => {
    setSegmentList(data.job_segment_industry);
  }, [data]);
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <DeleteIcon /> : "👉";
    return (
      <IconButton onClick={onClick} disabled={isEdge}>
        {pointer}
      </IconButton>
    );
  };
  return (
    <div className="AppCarousel">
      <Carousel
        breakPoints={breakPoints}
        focusOnSelect={true}
        enableAutoPlay
        autoPlaySpeed={4500}
        // renderArrow={myArrow}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <>
              {pages.map((page) => {
                const isActivePage = activePage === page;
                return <></>;
              })}
            </>
          );
        }}
      >
        {segmentList &&
          segmentList.map((item) => (
            <>
              <Box
                style={{ display: "flex", flexDirection: "column" }}
                onClick={() => {
                  if (!collegeID) {
                    handleFilterChange(UPDATE_JOB_SEGMENT, [
                      {
                        job_segment_id: item.id,
                        job_segment_name: item.name,
                      },
                    ]);
                    history.push({
                      pathname: "/company/invite",
                      flag: 1424,
                    });
                  } else {
                    handleFilterChange(UPDATE_INDUSTRY, [
                      {
                        industry_id: item.id,
                        industry_name: item.name,
                      },
                    ]);
                    history.push({
                      pathname: "/college/invite",
                      flag: 1424,
                    });
                  }
                }}
              >
                <Item>
                  <img src={item.image ? item.image : eg2} alt="" height="200px" width="200px" style={{ borderRadius: "30px" }} />
                </Item>

                <p className={classes.CarouselTitle}>
                  <b>{item.name}</b>
                  <br />
                  <span className={classes.CarouselSubTitle}>
                    {item.count} {!collegeID ? "Colleges" : "Companies"}
                  </span>
                </p>
              </Box>
            </>
          ))}
      </Carousel>
    </div>
  );
}

import { Grid, Typography } from "@material-ui/core";
import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
//import User from "../../../../assets/svg/user-emp.svg
import User from "../../assets/svg/TeamInvite.svg";
import CompanyIdeal from "../Common/HiringIdeal/CompanyIdeal"; //../../HiringIdeal/CompanyIdeal
export default function TeamInviteLeftSideSection() {
  return (
    <>
      <div
        style={{
          marginTop: "30px",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          style={{ fontWeight: "600", fontSize: "40px" }}
        >
          Your Team Is Waiting For You !
        </Typography>
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {Data.map((item) => (
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingLeft: "1px" }}
                >
                  <div style={{ display: "flex", marginBottom: "15px" }}>
                    <CheckCircleIcon
                      color="primary"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    <Typography variant="h6" style={{ fontWeight: "400" }}>
                      {item.info}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item md={6}>
            <img src={User} height="400px" />
          </Grid>
        </Grid>
        <CompanyIdeal />
      </div>
    </>
  );
}
const Data = [
  {
    info: "Save 10x More Money And Time",
  },
  {
    info: "Hire At Scale With Free ATS",
  },
  {
    info: "Access 700K+ Candidate Profiles",
  },
  {
    info: "Dedicated Key Account Manager",
  },
  {
    info: "AI Based Recomendations",
  },
  {
    info: "Create A Seamless Hiring Experience",
  },
];

import React from 'react';
import maintainence from '../../../../assets/images/maintainence.png';

const maintainenceStyles={
    Container:{
        height:'100vh',
        width:'100vw'
    },
    Content:{
        paddingLeft:'17%'
    },
    Image:{
        height:'inherit',
        width:'inherit'
    }
}
const Maintainence=()=>{
    return(
        <>
            <div className="row" style={maintainenceStyles.Container}>
                <div className="col-md-5 col-lg-5 col-12 my-auto text-right" style={maintainenceStyles.Content}>
                        <div className="row my-2 text-left"> <h1>Maintenance</h1></div>
                        <div className="row my-2 text-left">
                            <p className="gray-2">
                                We are currently working hard on fixing this page. <br/>Please come back again later.
                            </p>
                        </div>
                        <div className="row my-2 text-left"> 
                            <button className="btn btn-main btn-login btn-rounded">Contact Us</button>
                        </div>
                        
                </div>
                <div className="col-md-7 col-lg-7 col-12">
                    <img className="my-5" style={maintainenceStyles.Image} src={maintainence} alt="maintainence"/>
                </div>
            </div>
        </>
    )
}

export default Maintainence;
import React from "react";
import Layout from "../components/Layout/Layout";
import EmployeeDetails from "./EmployeeDetails";
import Subscriptions from "./Subscriptions";

function SubscriptionIndex() {
  return (
    <>
      <div>
        {!localStorage.getItem("gw_token") ? (
          <Layout>
            <Subscriptions />
          </Layout>
        ) : (
          <div style={{}}>
            <Subscriptions />
          </div>
        )}
      </div>
    </>
  );
}

export default SubscriptionIndex;

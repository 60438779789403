import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "3px",
  },
});

const SliderBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const NewSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: theme.palette.primary.main,
    boxShadow: SliderBoxShadow,
    marginTop: -7,
    marginLeft: -7,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: SliderBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-60% + 1px)",
    //    top: -22,

    "& *": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#C9C9C9",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 4,
    width: 4,
    borderRadius: "50%",
    marginTop: -1,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
}))(Slider);

function calculateValue(value) {
  switch (value) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    case 6:
      return 6;
    case 7:
      return 7;
    case 8:
      return 8;
    case 9:
      return 9;
    case 10:
      return 10;
    case 11:
      return 11;
    case 12:
      return 12;
    case 13:
      return 13;
    case 14:
      return 14;
    default:
      return 0;
  }
}

const marks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(
  (value) => ({
    value,
    label: calculateValue(value),
  })
);

export default function SalarySlider() {
  const classes = useStyles();
  const [value, setValue] = React.useState([3, 10]);

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-restrict" gutterBottom variant="body2">
        Salary (in CTC)
      </Typography>
      <NewSlider
        step={null}
        value={value}
        onChange={(_, newValue) => {
          if (newValue !== value) {
            console.log(calculateValue(newValue));
            setValue(newValue);
          }
        }}
        valueLabelDisplay="auto"
        marks={marks}
        scale={calculateValue}
        min={1} //2
        max={14} //16
      />
    </div>
  );
}

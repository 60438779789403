// import '../../../pages/student/EditProfile/Forms/EditProfileForms.css';
import { Backdrop, Button, MenuItem, TextField, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { positions, useAlert } from "react-alert";
import { v4 as uuidv4 } from "uuid";
import loaderGIF from "../../../assets/images/loopingcircles-3.gif";
import college from "../../../assets/job/college.svg";
import company from "../../../assets/job/company.png";
import ctc_img from "../../../assets/job/money.svg";
import project_img from "../../../assets/job/project.png";
import skills_img from "../../../assets/job/skills.svg";
import user_img from "../../../assets/job/user.png";
import { BackendBaseApi, emailRegex, Genders } from "../../../constants/constants";
import { uniqueArray } from "../../../utils/common/filterJob";
import CommonButton from "../CommonButton/CommonButton";
import EducationDetails from "./FormComponents/EducationDetails";
import Projects from "./FormComponents/Projects";
import ResumeUpload from "./FormComponents/ResumeUpload";
// import ResumeUpload from './FormComponents/ResumeUpload';
import Skills from "./FormComponents/Skills";
import WorkExperience from "./FormComponents/WorkExperience";
import "./RegisterFormJobApply.css";
import ResumeAutofill from "./ResumeAutofill";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  sugNoticePeriod: {
    display: "inline-block",
    color: "#a1a1a1",
    fontSize: "13px",
    margin: "-18px 0px 15px 20px"
  }
}));

function RegisterFormJobApply({
  jobID,
  initEmail,
  setInitEmail,
  initFirstName,
  setInitFirstName,
  setNavValue,
  setApplySuccessful,
  initLastName,
  setInitLastName,
  initPhone,
  setInitPhone,
  isUserApplied,
  setIsUserApplied,
  executeScroll,
  setToggleExpand,
}) {
  const alert = useAlert();
  const classes = useStyles();

  const [allCities, setAllCities] = useState([]);
  const [gender, setGender] = useState(null);
  const [allSkills, setAllSkills] = useState([]);
  const [allSkillRatings, setAllSkillRatings] = useState([]);

  const [dob, setDob] = useState("");
  const [currentCity, setCurrentCity] = useState(null);
  const [homeCity, setHomeCity] = useState(null);
  const [linkedIn, setLinkedIn] = useState("");
  const [github, setGithub] = useState("");
  const [resume, setResume] = useState(null);
  const [resumeParsedData, setResumeParsedData] = useState({});
  const [parseSuccess, setParseSuccess] = React.useState(false);

  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const [projects, setProjects] = useState([]);

  const [studentUserExist, setStudentUserExist] = useState(false);
  const [emailExistAlert, setEmailExistAlert] = useState("");

  const [educationBoxOpen, setEducationBoxOpen] = useState({});
  const [experienceBoxOpen, setExperienceBoxOpen] = useState({});
  const [projectBoxOpen, setProjectBoxOpen] = useState({});

  const [clearForm, setClearForm] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [remarks, setRemarks] = useState(null);
  const [currentCtc, setCurrentCtc] = useState(null);
  const [expectedCtc, setExpectedCtc] = useState(null);
  const [noticePeriod, setNoticePeriod] = useState(null);

  useEffect(() => {
    clearStates();
  }, [clearForm]);

  // Skills in DB
  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/skills")
      .then((res) => {
        if (res.data.success) {
          setAllSkills(res.data.data.skills);
        } else {
          alert.error("Unable to fetch Skills from Server!", { position: positions.TOP_RIGHT });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Skill Ratings in DB
  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/skill_rating")
      .then((res) => {
        if (res.data.success) {
          setAllSkillRatings(res.data.data.skill_rating);
        } else {
          alert.error("Unable to fetch Skill Ratings from Server!", { position: positions.TOP_RIGHT });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cities in DB
  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/location/city/")
      .then((res) => {
        setAllCities(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearStates = () => {
    setInitEmail("");
    setInitFirstName("");
    setInitLastName("");
    setInitPhone("");
    setDob("");
    setLinkedIn("");
    setGithub("");
    setGender(null);
    setCurrentCity(null);
    setHomeCity(null);
    // setResume(null);
    setEducations([]);
    setExperiences([]);
    setSkills([]);
    setProjects([]);
    setCurrentCity(null);
    setExpectedCtc(null);
    setRemarks(null);
    setNoticePeriod(null);
  };

  useEffect(() => {
    if (emailRegex.test(initEmail)) {
      Axios.post(BackendBaseApi.PRAVESH + "api/check_user/", {
        email: initEmail,
      })
        .then((res) => {
          setStudentUserExist(res.data.data.is_student_user);
          if (res.data.data.is_student_user) {
            setEmailExistAlert(`Email '${initEmail}' already exists!!`);
            return;
          }
        })
        .catch((err) => {
          alert.error("Error occured!", { position: positions.TOP_RIGHT });
        });
    }
    setEmailExistAlert("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initEmail]);

  useEffect(() => {
    if (Object.keys(resumeParsedData).length === 0) return;
    setInitFirstName(resumeParsedData.personal_details?.first_name || "");
    setInitLastName(resumeParsedData.personal_details?.last_name || "");
    setInitEmail(resumeParsedData.personal_details?.email || "");
    if (resumeParsedData.personal_details?.phone) {
      let phone = resumeParsedData.personal_details?.phone;
      phone = phone.slice(-10);
      setInitPhone(phone);
    }
    setDob(resumeParsedData.personal_details?.date_of_birth || "");
    if (resumeParsedData.personal_details?.current_city) {
      setCurrentCity({
        city_id: resumeParsedData.personal_details.current_city,
        city: resumeParsedData.personal_details.current_city_name,
      });
      setHomeCity({
        city_id: resumeParsedData.personal_details.current_city,
        city: resumeParsedData.personal_details.current_city_name,
      });
    }
    if (resumeParsedData.personal_details?.social_links) {
      setLinkedIn(resumeParsedData.personal_details.social_links?.linkedIn || "");
      setGithub(resumeParsedData.personal_details.social_links?.github || "");
    }
    let edus = resumeParsedData.user_education;
    edus.forEach((t) => {
      if (t.education_type && t.percentage && t.year_of_passing) {
        t.is_valid = true;
      } else {
        t.is_valid = false;
      }
    });
    let work = [...resumeParsedData.work_experience];
    work.forEach((t) => {
      if (t.company_name && t.start_date && t.job_designation) {
        t.is_valid = true;
      } else {
        t.is_valid = false;
      }
    });
    let proj = [...resumeParsedData.user_projects];
    proj.forEach((t) => {
      if (t.title && t.description) {
        t.is_valid = true;
      } else {
        t.is_valid = false;
      }
    });
    setEducations(uniqueArray(edus));
    setExperiences(uniqueArray(work));
    setProjects(uniqueArray(proj));
    setSkills(uniqueArray(resumeParsedData.user_skills));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeParsedData]);

  const signupAndApply = (e) => {
    e.preventDefault();
    if (window.document.getElementById("submit_application_btn").disabled) {
      alert.error("Wait!! You previous request is under progress...", { position: positions.TOP_RIGHT });
      return;
    }
    if (studentUserExist) {
      alert.error("Already a Student User. Sign in to Apply for this job!", { position: positions.TOP_RIGHT });
      return;
    }
    if (!initFirstName) {
      alert.error("First Name Required!", { position: positions.TOP_RIGHT });
      return;
    }
    if (!initLastName) {
      alert.error("Last Name Required!", { position: positions.TOP_RIGHT });
      return;
    }
    if (!initEmail) {
      alert.error("Email Required!", { position: positions.TOP_RIGHT });
      return;
    }
    if (!initPhone) {
      alert.error("Phone Required!", { position: positions.TOP_RIGHT });
      return;
    }
    if (!emailRegex.test(initEmail)) {
      alert.error("Invalid Email!", { position: positions.TOP_RIGHT });
      return;
    }
    if (initPhone.length !== 10) {
      alert.error("Invalid Contact No.\n 10 digits only!", { position: positions.TOP_RIGHT });
      return;
    }
    if (isNaN(initPhone)) {
      alert.error("Invalid Contact Number!", { position: positions.TOP_RIGHT });
      return;
    }
    // if(!dob) { alert.error('Date of Birth Required!'); return; }
    if (!gender) {
      alert.error("Gender Required!", { position: positions.TOP_RIGHT });
      return;
    }
    if (!resume) {
      alert.error("Resume required!", { position: positions.TOP_RIGHT });
      return;
    }
    if (educations.length === 0) {
      alert.error("Education Details Cannot be left empty!", { position: positions.TOP_RIGHT });
      return;
    }
    if (educations.length > 0) {
      let invalid_education = false;
      let invalid_education_id = null;
      educations.every((edu) => {
        if (edu.is_valid === false) {
          invalid_education_id = edu.id;
          invalid_education = true;
          return false;
        }
        return true;
      });
      if (invalid_education) {
        // alert.error('Edit your highlighted education!');
        setEducationBoxOpen({ success: true, id: invalid_education_id });
        return;
      }

      let curr_count = 0;
      educations.forEach((edu) => {
        if (edu.is_current === true) {
          curr_count += 1;
        }
      });
      if (curr_count === 0) {
        alert.error("Please select atleast one of the following educations (UG, PG, PhD) as your current education!", { position: positions.TOP_RIGHT });
        return;
      }
    }
    if (experiences.length > 0) {
      let invalid_experience = false;
      let invalid_experience_id = null;
      experiences.every((edu) => {
        if (edu.is_valid === false) {
          invalid_experience = true;
          invalid_experience_id = edu.id;
          return false;
        }
        return true;
      });
      if (invalid_experience) {
        setExperienceBoxOpen({ success: true, id: invalid_experience_id });
        // alert.error('Edit your highlighted Work Experience!');
        return;
      }
    }
    if (projects.length > 0) {
      let invalid_proj = false;
      let invalid_proj_id = null;
      projects.every((edu) => {
        if (edu.is_valid === false) {
          invalid_proj = true;
          invalid_proj_id = edu.id;
          return false;
        }
        return true;
      });
      if (invalid_proj) {
        setProjectBoxOpen({ success: true, id: invalid_proj_id });
        // alert.error('Edit your highlighted Project!');
        return;
      }
    }

    window.document.getElementById("submit_application_btn").disabled = true;

    let links = [];
    links.push({ id: uuidv4(), name: "LinkedIn", value: linkedIn });
    links.push({ id: uuidv4(), name: "Github", value: github });
    links.push({ id: uuidv4(), name: "Facebook", value: "" });

    var personalObj = {
      first_name: initFirstName,
      last_name: initLastName,
      email: initEmail,
      phone: initPhone,
      current_city: currentCity ? currentCity.city_id : null,
      hometown_city: homeCity ? homeCity.city_id : null,
      date_of_birth: dob || null,
      gender: gender.value,
      ethnicity: 1,
      veteran: "I'm not a protected veteran",
      social_links: links,
    };
    let studentData = new FormData();
    studentData.append("job_id", jobID);
    studentData.append("resume", resume);
    studentData.append("personal_details", JSON.stringify(personalObj));
    studentData.append("user_skills", JSON.stringify(skills));
    studentData.append("work_experience", JSON.stringify(experiences));
    studentData.append("user_education", JSON.stringify(educations));
    studentData.append("user_projects", JSON.stringify(projects));
    noticePeriod !==null && studentData.append("notice_period", noticePeriod);
    currentCtc && studentData.append("cur_ctc", currentCtc);
    expectedCtc && studentData.append("expected_ctc", expectedCtc);
    remarks && studentData.append("remark", JSON.stringify(remarks));

    setSubmitLoading(true);
    Axios.post(BackendBaseApi.PRAVESH + "api/public_signup/", studentData, { withCredentials: true, headers: { "content-type": "multipart/form-data" } })
      .then((res) => {
        window.document.getElementById("submit_application_btn").disabled = false;
        if (!res.data.success) {
          alert.error(res.data.data.message, { position: positions.TOP_RIGHT });
        } else {
          alert.success(res.data.data.message, { position: positions.TOP_RIGHT });
          if (Object.keys(res.data.data.verbose_data).length > 0) {
            localStorage.setItem("gw_token", res.data.data.verbose_data.token);
            localStorage.setItem("user_type_id", res.data.data.verbose_data.user_type_id);
            localStorage.setItem("user_type", res.data.data.verbose_data.user_type);
            localStorage.setItem("user_id", res.data.data.verbose_data.user_id);
            localStorage.setItem("email_verified", res.data.data.verbose_data.email_verified);
            localStorage.setItem('user_details', JSON.stringify(res.data.data.verbose_data.user_details));
          }
          if (res.data.data?.verbose_data?.applied_status) {
            setIsUserApplied(true);
            setApplySuccessful(true);
            setNavValue("success");
            clearStates();
          }
        }
      })
      .catch((err) => {
        window.document.getElementById("submit_application_btn").disabled = false;
        alert.error("Error occured!", { position: positions.TOP_RIGHT });
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <div id="register_form_job_apply">
      {submitLoading && (
        <Backdrop className={classes.backdrop} open={submitLoading}>
          <img src={loaderGIF} alt="Loading..." width="160px" height="90px" />
        </Backdrop>
      )}
      <ResumeAutofill
        alert={alert}
        clearForm={clearForm}
        setClearForm={setClearForm}
        setResume={setResume}
        setResumeParsedData={setResumeParsedData}
        parseSuccess={parseSuccess}
        setParseSuccess={setParseSuccess}
        positions={positions}
      />
      <div id="required_field">
        <div>
          <span id="required_sign">* &nbsp;</span>
          <span>Required fields</span>
        </div>
        {/* <div style={{cursor:'pointer'}} onClick={() => clearStates()}>
                    <i class="fa fa-trash" aria-hidden="true"></i>
                    <span>{'  '} Clear Form</span>
                </div> */}
      </div>
      <form>
        <div>
          {/* Personal Information */}
          <div>
            <div id="form_section_heading">
              <div>
                <img id="heading_img_style" src={user_img} alt="skills" />
                <span>Personal Information</span>
              </div>
            </div>
            <div id="personal_info_form" style={{ fontSize: "14px" }}>
              <div id="add_new_div">
                <TextField
                  type="text"
                  required
                  variant="outlined"
                  label="First Name"
                  InputLabelProps={{ shrink: true }}
                  value={initFirstName}
                  onChange={(e) => setInitFirstName(e.target.value)}
                />
                <TextField
                  type="text"
                  required
                  variant="outlined"
                  label="Last Name"
                  InputLabelProps={{ shrink: true }}
                  value={initLastName}
                  onChange={(e) => setInitLastName(e.target.value)}
                />
              </div>
              <div id="add_new_div">
                <TextField
                  type="text"
                  required
                  variant="outlined"
                  label="Email"
                  InputLabelProps={{ shrink: true }}
                  value={initEmail}
                  onChange={(e) => setInitEmail(e.target.value)}
                  error={emailExistAlert}
                  helperText={
                    emailExistAlert && (
                      <span style={{ fontSize: "13px" }}>
                        <span style={{ fontSize: "14px" }}>&#9888; </span>
                        <span> {emailExistAlert}. </span>
                        <br />{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          data-toggle="collapse"
                          data-target="#sign-in-credentials"
                          onClick={() => {
                            executeScroll();
                            setToggleExpand(true);
                          }}
                        >
                          Please Click here to Login.
                        </span>
                      </span>
                    )
                  }
                />
                <TextField
                  type="number"
                  required
                  variant="outlined"
                  label="Phone"
                  InputLabelProps={{ shrink: true }}
                  value={initPhone}
                  onChange={(e) => setInitPhone(e.target.value)}
                  error={initPhone.toString().length > 0 && initPhone.toString().length !== 10}
                  helperText={
                    initPhone.toString().length > 0 &&
                    initPhone.toString().length !== 10 && (
                      <span style={{ fontSize: "13px" }}>
                        <span style={{ fontSize: "14px" }}>&#9888; </span>
                        <span>Phone no. must contain 10 digits only !</span>
                      </span>
                    )
                  }
                />
              </div>
              <div id="add_new_div">
                <TextField
                  type="date"
                  required
                  variant="outlined"
                  label="Date of Birth"
                  InputLabelProps={{ shrink: true }}
                  value={dob || ""}
                  onChange={(e) => setDob(e.target.value)}
                />
                <TextField
                  id="standard-select-currency"
                  variant="outlined"
                  required
                  select
                  label="Gender"
                  InputLabelProps={{ shrink: true }}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  {Genders.map((option) => (
                    <MenuItem key={option.value} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div id="add_new_autocomplete_div">
                <Autocomplete
                  id="tags-outlined"
                  className="type_search_autocomplete_two"
                  options={allCities}
                  getOptionLabel={(option) => option.city}
                  onChange={(e, i) => {
                    i ? setCurrentCity(i) : setCurrentCity(null);
                  }}
                  value={currentCity}
                  renderInput={(params) => <TextField {...params} required variant="outlined" label="Select Current City" />}
                />
                <Autocomplete
                  id="tags-outlined"
                  className="type_search_autocomplete_two"
                  options={allCities}
                  getOptionLabel={(option) => option.city}
                  onChange={(e, i) => {
                    i ? setHomeCity(i) : setHomeCity(null);
                  }}
                  value={homeCity}
                  renderInput={(params) => <TextField {...params} required variant="outlined" label="Select Home City" />}
                />
              </div>
              {/* {(linkedIn || github) && ( */}
              <div id="add_new_div">
                <TextField type="text" variant="outlined" label="LinkedIn" InputLabelProps={{ shrink: true }} value={linkedIn} onChange={(e) => setLinkedIn(e.target.value)} />
                <TextField type="text" variant="outlined" label="Github" InputLabelProps={{ shrink: true }} value={github} onChange={(e) => setGithub(e.target.value)} />
              </div>
              {/* )} */}
              {!parseSuccess && (
                <div style={{ marginBottom: "1rem" }}>
                  <span id="bootstrap_form_control_label">Upload Resume</span>
                  <ResumeUpload setResume={setResume} />
                </div>
              )}
              {resume && (
                <span
                  style={{
                    marginLeft: "1rem",
                    color: "#3eb53e",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FileCopyIcon style={{ height: "1rem", width: "1rem", marginRight: "6px" }} />
                  <b style={{ marginRight: "6px" }}>Resume Uploaded: </b> {resume.name}
                </span>
              )}
              <br />
            </div>
          </div>
          {/* Education Details */}
          <div id="education_section">
            <div id="form_section_heading">
              <div>
                <img id="heading_img_style" src={college} alt="edu" />
                <span>Education Details</span>
              </div>
              <div id="add_btn">
                <Tooltip title="Add Education" placement="left">
                  <Button variant="outlined" color="primary" data-toggle="collapse" data-target="#education-details-enter">
                    + ADD
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div id="education_details_form">
              <EducationDetails alert={alert} positions={positions} educations={educations} setEducations={setEducations} educationBoxOpen={educationBoxOpen} />
            </div>
          </div>
          {/* Work Experience */}
          <div id="experience_section">
            <div id="form_section_heading">
              <div>
                <img id="heading_img_style" src={company} alt="work" />
                <span>Work Experience (optional)</span>
              </div>
              <div id="add_btn">
                <Tooltip title="Add Work Experience" placement="left">
                  <Button variant="outlined" color="primary" data-toggle="collapse" data-target="#work-experience-enter">
                    + ADD
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div id="work_experience_form">
              <WorkExperience
                alert={alert}
                positions={positions}
                experiences={experiences}
                setExperiences={setExperiences}
                allSkills={allSkills}
                experienceBoxOpen={experienceBoxOpen}
              />
            </div>
          </div>
          {/* Skills */}
          <div>
            <div id="form_section_heading">
              <div>
                <img id="heading_img_style" src={skills_img} alt="skills" />
                <span>Skills (optional)</span>
              </div>
              <div id="add_btn">
                <Tooltip title="Add Skill" placement="left">
                  <Button variant="outlined" color="primary" data-toggle="collapse" data-target="#skill-enter">
                    <span>+ ADD</span>
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div id="skills_form">
              <Skills alert={alert} positions={positions} skills={skills} setSkills={setSkills} allSkills={allSkills} allSkillRatings={allSkillRatings} />
            </div>
          </div>
          {/* Projects */}
          <div id="project_section">
            <div id="form_section_heading">
              <div>
                <img id="heading_img_style" src={project_img} alt="proj" />
                <span>Projects (optional)</span>
              </div>
              <div id="add_btn">
                <Tooltip title="Add Project" placement="left">
                  <Button variant="outlined" color="primary" data-toggle="collapse" data-target="#project-enter">
                    + ADD
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div id="projects_form">
              <Projects alert={alert} projects={projects} setProjects={setProjects} allSkills={allSkills} projectBoxOpen={projectBoxOpen} positions={positions} />
            </div>
          </div>

          {/* CTC */}
          <div id="ctc_section">
            <div id="form_section_heading">
              <div>
                <img id="heading_img_style" src={ctc_img} alt="proj" />
                <span>CTC (optional)</span>
              </div>
              <div id="add_btn">
                <Tooltip title="Add CTC" placement="left">
                  <Button variant="outlined" color="primary" data-toggle="collapse" data-target="#ctc-enter">
                    + ADD
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div id="ctc_form" style={{marginTop:"40px"}}>
              <div className="collapse" id="ctc-enter">
                <div id="add_new_div">
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Current CTC (In Lacs)"
                    InputLabelProps={{ shrink: true }}
                    value={currentCtc}
                    onChange={(e) => setCurrentCtc(e.target.value)}
                  />
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Expected CTC (In Lacs)"
                    InputLabelProps={{ shrink: true }}
                    value={expectedCtc}
                    onChange={(e) => setExpectedCtc(e.target.value)}
                  />
                </div>
                <div style={{ margin: "5px 0px 0px 0px", textAlign: "center" }}>
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Notice Period (In Days)"
                    InputLabelProps={{ shrink: true }}
                    value={noticePeriod}
                    onChange={(e) => setNoticePeriod(e.target.value)}
                    style={{ width: "97%" }}
                  />
                </div>
                <span className={classes.sugNoticePeriod}>
                  Enter 0(Zero) for Immediate Join
                </span>
                <div id="add_new_full_length_div">
                  <TextField
                    type="text"
                    label="Write Remarks"
                    placeholder="Write Remarks"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    style={{ width: "97%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <br />
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {isUserApplied ? (
          <CommonButton variant="contained" style={{ outline: "none" }}>
            Applied
          </CommonButton>
        ) : (
          <CommonButton variant="contained" style={{ outline: "none" }} onClick={(e) => signupAndApply(e)} id="submit_application_btn">
            SUBMIT APPLICATION
          </CommonButton>
        )}
      </div>
      <br />
      <br />
    </div>
  );
}

export default RegisterFormJobApply;

import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { positions, useAlert } from "react-alert";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FacebookIcon } from "react-share";
import googleIcon from "../../../assets/job/google-icon.svg";
import { BackendBaseApi, emailRegex, FACEBOOK_APP_ID, FACEBOOK_APP_SECRET, GOOGLE_CLIENT_ID } from "../../../constants/constants";
import CommonButton from "../CommonButton/CommonButton";
import "./SignInSection.css";

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: theme.spacing(2),
    width: "90%",
  },
  button: {
    display: "block",
    margin: "auto",
    marginBottom: "2%",
  },
}));

function SignInSection({
  jobID,
  initEmail,
  setInitEmail,
  initFirstName,
  setInitFirstName,
  setApplySuccessful,
  setNavValue,
  initLastName,
  setInitLastName,
  initPhone,
  setInitPhone,
  setIsUserApplied,
  toggleExpand,
  setToggleExpand,
}) {
  const alert = useAlert();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const firstRender_1 = useRef(true);
  const firstRender_2 = useRef(true);
  // const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [fbAccessToken, setfbAccessToken] = useState("");
  const [googleAccessToken, setgoogleAccessToken] = useState("");

  let passwordFieldType = showPassword ? "text" : "password";
  let tooltipText = showPassword ? "Hide Password" : "Show Password";

  //access short life token from facebook popup response
  const responseFacebook = (response) => {
    setfbAccessToken(response.accessToken);
  };

  useEffect(() => {
    let accessToken = "";
    if (firstRender_1.current) {
      firstRender_1.current = false;
      return;
    }

    //Facebook Auth API calls
    const fbGraphAPICalls = async () => {
      axios
        .get(
          `https://graph.facebook.com/v6.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${FACEBOOK_APP_ID}&client_secret=${FACEBOOK_APP_SECRET}&fb_exchange_token=${fbAccessToken}`
        )
        .then((res) => {
          accessToken = res.data.access_token;
        })
        .then(() => {
          axios
            .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
              headers: {
                "Content-Type": "application/json",
                Connection: "keep-alive",
              },
              withCredentials: true,
              provider: "facebook",
              access_token: accessToken,
            },
            )
            .then((res) => {
              if (res.data.success) {
                let token = "";
                token = res.data.data.token;
                localStorage.setItem("gw_token", token);
                alert.success(res.data.data.message);
                // setLoading(true);
                checkUser(token);
              } else {
                alert.error("Error occured!");
              }
            })
            .catch((err) => {
              alert.error("Error occured!");
            });
        })
        .catch((err) => {});
    };

    fbGraphAPICalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbAccessToken]);

  //access short life token from google popup response
  const responseGoogle = (response) => {
    setgoogleAccessToken(response.accessToken);
  };

  useEffect(() => {
    let token = "";
    if (firstRender_2.current) {
      firstRender_2.current = false;
      return;
    }
    if (googleAccessToken) {
      axios
        .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
          },
          withCredentials: true,
          provider: "google-oauth2",
          access_token: googleAccessToken,
        },
        )
        .then((res) => {
          if (res.data.success) {
            token = res.data.data.token;
            localStorage.setItem("gw_token", token);
            alert.success(res.data.data.message);
            // setLoading(true);
            checkUser(token);
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          alert.error("Error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAccessToken]);

  const applyForJob = (student_user_id) => {
    axios
      .post(
        BackendBaseApi.PRAVESH + "api/public_signup/",
        {
          job_id: jobID,
          user_id: student_user_id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (!res.data.success) {
          alert.error(res.data.data.message, { position: positions.TOP_RIGHT });
        } else {
          alert.success(res.data.data.message, { position: positions.TOP_RIGHT });
          if (Object.keys(res.data.data.verbose_data).length > 0) {
            localStorage.setItem("gw_token", res.data.data.verbose_data.token);
            localStorage.setItem("user_type_id", res.data.data.verbose_data.user_type_id);
            localStorage.setItem("user_type", res.data.data.verbose_data.user_type);
            localStorage.setItem("user_id", res.data.data.verbose_data.user_id);
            localStorage.setItem("email_verified", res.data.data.verbose_data.email_verified);
          }
          setIsUserApplied(true);
          setApplySuccessful(true);
          setNavValue("success");
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
    // alert.success(`Student USer ${student_user_id} applied for job ${jobID}`)
  };

  const checkUser = (token) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        //CASE 1: check if user is Student User
        if (res.data.user_type[0]?.main_user === "Student" || res.data.user_type[0]?.type === 1) {
          res.data.user_type[0]?.type && localStorage.setItem("user_type_id", res.data.user_type[0].type);
          res.data.user_type[0]?.type && localStorage.setItem("user_type", res.data.user_type[0].main_user);
          res.data.id && localStorage.setItem("user_id", res.data.id);
          res.data.email_verified && localStorage.setItem("email_verified", res.data.email_verified);
          if (res.data.is_pref_filled) {
            // call api to apply for job
            applyForJob(res.data.id);
          } else {
            // allow sign in as a normal logged out user
            res.data.first_name && setInitFirstName(res.data.first_name);
            res.data.last_name && setInitLastName(res.data.last_name);
            res.data.email && setInitEmail(res.data.email);
            res.data.mobile && setInitPhone(res.data.mobile);
            alert.show("Complete rest of your profile to apply for this job!");
          }
        } else {
          // localStorage.clear();
          res.data.first_name && setInitFirstName(res.data.first_name);
          res.data.last_name && setInitLastName(res.data.last_name);
          res.data.email && setInitEmail(res.data.email);
          res.data.mobile && setInitPhone(res.data.mobile);
          alert.error("You must be signed in as Student User to apply for this Job !!");
        }
      })
      .catch((err) => {
        alert.error("Oop! There is some error, try again later.");
        localStorage.clear();
        throw err;
      });
  };

  // useEffect(() => { console.log(email) }, [email]);
  const signInUser = (e) => {
    e.preventDefault();
    if (!email) {
      alert.error("Email Id required!");
      return;
    }
    if (!password) {
      alert.error("Password required!");
      return;
    }
    if (!emailRegex.test(email)) {
      alert.error("Invalid Email!");
      return;
    }
    // call login api with email and password & check user
    let token = "";
    axios
      .post(BackendBaseApi.PRAVESH + "api/login/", {
        email: email,
        password: password,
      })
      .then((res) => {
        if (!res.data.success) {
          alert.error(res.data.error);
          return;
        }
        alert.success(res.data.data.message);
        token = res.data.data.token;
        localStorage.setItem("gw_token", token);
        checkUser(token);
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };

  return (
    <div id="sign_in_box">
      <span id="sign_in_text"> Sign In & Apply</span>
      <div id="sign_in_details_box" style={toggleExpand ? {} : { borderRadius: "1.5rem" }}>
        <p>Enter Your Credentials</p>
        <div>
          {!toggleExpand ? (
            <ExpandMoreIcon
              data-toggle="collapse"
              data-target="#sign-in-credentials"
              style={{ paddingTop: "0.5rem", cursor: "pointer", height: 30, width: 30 }}
              className="ml-2"
              onClick={(e) => setToggleExpand(!toggleExpand)}
            />
          ) : (
            <ExpandLessIcon
              data-toggle="collapse"
              data-target="#sign-in-credentials"
              style={{ paddingTop: "0.5rem", cursor: "pointer", height: 30, width: 30 }}
              className="ml-2"
              onClick={(e) => setToggleExpand(!toggleExpand)}
            />
          )}
        </div>
      </div>
      <div className="collapse" id="sign-in-credentials" style={{ paddingBottom: "1rem" }}>
        <form className="my-md-0 my-2" onSubmit={(e) => signInUser(e)}>
          <div className="form-group text-left">
            <label htmlFor="Email" className="fw-500" style={{ color: "#424242", fontStyle: "italic" }}>
              Enter your Email address
            </label>
            <input
              type="email"
              className="form-control mt-2 mb-1 shadow_1-lightest"
              id="Email"
              aria-describedby="emailHelp"
              style={{ borderRadius: "30px" }}
              placeholder=""
              required
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group text-left pt-md-2">
            <label htmlFor="Password" className="fw-500" style={{ color: "#424242", fontStyle: "italic" }}>
              Enter your Password
            </label>
            <div className="row no-gutters">
              <div className="col">
                <input
                  type={passwordFieldType}
                  className="form-control mt-2 mb-1 shadow_1-lightest"
                  style={{ borderRadius: "30px" }}
                  id="Password"
                  placeholder=""
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="col-auto">
                <Tooltip title={tooltipText} placement="right" arrow>
                  <i
                    onClick={() => setShowPassword(!showPassword)}
                    id="toggleShowPassword"
                    className={!showPassword ? "fas fa-eye show-password-icon" : "fa fa-eye-slash show-password-icon"}
                  ></i>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="row my-1 pt-md-2 pt-3" style={{ display: "flex", flexDirection: "row", alignItems: "space-between" }}>
            <div className="col-6 text-left">
              <CommonButton
                // className=" shadow_1 btn btn-lg btn-main btn-login text-uppercase font-weight-bold mb-2"
                type="submit"
              >
                Sign in
              </CommonButton>
            </div>
            <div className="col-6 text-right my-1">
              <a className="fs-14 link-text_2" style={{ color: "#3282c4" }} onClick={(e) => window.open("/forgot-password")}>
                Forgot password?
              </a>
            </div>
          </div>
        </form>
      </div>
      <div id="sign_in_or_div">
        <p> or sign in with </p>
      </div>
      <div id="social_login_btn_div">
        <div id="social_login_btn">
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            callback={responseFacebook}
            render={(renderProps) => (
              // <a
              //     onClick={renderProps.onClick}
              //     className="btn shadow_1 azm-social azm-size-48 azm-circle azm-long-shadow azm-facebook text-light"
              // >
              //     <i className="fa fa-facebook"></i>
              // </a>
              <FacebookIcon round={true} id="social_media_icon" onClick={renderProps.onClick} />
            )}
          />
        </div>
        <div id="social_login_btn">
          <GoogleLogin
            // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
            clientId={GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <a href="" onClick={renderProps.onClick}>
                <img id="social_media_icon" src={googleIcon} />
              </a>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          />
        </div>
      </div>
    </div>
  );
}

export default SignInSection;

// or const { useQuill } = require('react-quilljs');
import "quill/dist/quill.snow.css"; // Add css for snow theme
import React from "react";
import { useQuill } from "react-quilljs";
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme
import "./editor.css";

export default props => {
  const { state, setState, desc, placeholderText, removeAttachments, formSubmitted, setFormSubmitted } = props;
  const placeholder = placeholderText
    ? placeholderText
    : "Write your description.....";

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],

      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["link"],
      [{ color: [] }, { background: [] }],

      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const { quill, quillRef } = useQuill({ modules, placeholder });

  // const { quill, quillRef } = removeAttachments
  //   ? useQuill({ modules, placeholder })
  //   : useQuill({ placeholder });

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", delta => {
        const text = quill.getText();
        setState(quill.root.innerHTML);
      });
    }
  }, [quill]);

  React.useEffect(() => {
    if(formSubmitted){
      quill.clipboard.dangerouslyPasteHTML("\n");
      setState("");
      setFormSubmitted(false);
    }
  }, [formSubmitted])

  React.useEffect(() => {
    if (quill && desc) {
      quill.clipboard.dangerouslyPasteHTML(desc ? desc.replace(/,/g, "") : "");
    }
  }, [quill, desc]);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: 150,
          marginBottom: 50,
          borderRadius: 20,
        }}
      >
        <div
          ref={quillRef}
          style={{
            borderRadius: "0px 0px 30px 30px",
            backgroundColor: "white",
            paddingBottom: "10px",
          }}
        />
      </div>
    </>
  );
};

import { AppBar, Box, createStyles, makeStyles, Toolbar, Typography, Switch, FormControlLabel, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
//   import "./Panes.css";
import SelectSearch from "react-select-search";
import filter_icon from "../../../assets/common/Filter2.png";
// const PaneStyles={
//     background: '#fff',
//     boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
//     width:'87vw',
//     height:'60px',
//     top: '60px',
//     left:'20px',
//     zIndex:'1'
// }
const TrackPane = ({
  profiles,
  jobProfile,
  setJobProfile,
  setShow,
  search,
  setSearch,
  jobTypes,
  companies,
  setJobType,
  setCompany,
  selectedJobType,
  setSelectedJobType,
  allCompanies,
  setSelectedCompany,
  onlyClosed,
  setOnlyClosed,
  campusType,
  setCampusType,
}) => {
  const theme = useTheme();
  // const [selected, setSelected] = useState("All Jobs");
  // const handleSelect = (e) => {
  //   setSelected(e.target.value);
  //   setShow(e.target.value);
  // };
  // const [status, setStatus] = useState("Applied");

  const classes = useStyles();

  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  // });

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  return (
    <>
      <div className={classes.appBar} id={theme.palette.type === "dark" && "theme-select-search"}>
        <Toolbar className={classes.toolBar}>
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            {/* Separate this as a component */}
            {/* <Typography variant="body1">Filter By : </Typography> */}
            <img src={filter_icon} />

            <div className="dropdown ml-4">
              <SelectSearch
                options={
                  jobTypes &&
                  jobTypes.length > 0 &&
                  jobTypes.map((item) => ({
                    name: item.job_type,
                    value: item.id,
                  }))
                }
                onChange={(val) => setSelectedJobType(val)}
                // value={selectedJobType}
                placeholder="Job type"
                search
              />
            </div>
            {campusType === true ? (
              <div className="dropdown ml-4">
                <SelectSearch
                  options={
                    allCompanies &&
                    allCompanies.length > 0 &&
                    allCompanies.map((item) => ({
                      name: item.company_name,
                      value: item.company_id,
                    }))
                  }
                  onChange={(val) => setSelectedCompany(val)}
                  placeholder="Company"
                  search
                />
              </div>
            ) : null}
            <div className="dropdown ml-4">
              <SelectSearch
                options={
                  profiles &&
                  profiles.length > 0 &&
                  profiles.map((item) => ({
                    name: item.job_title,
                    value: item.job_id,
                  }))
                }
                // value={jobProfile}
                onChange={(val) => setJobProfile(val)}
                placeholder="Profile"
                search
              />
            </div>

            <FormControlLabel
              value="end"
              control={<Switch size="small" color="primary" checked={onlyClosed} onChange={(e) => setOnlyClosed(e.target.checked)} />}
              label="Closed jobs"
              labelPlacement="end"
            />
            <FormControlLabel
              value="end"
              control={
                <Switch
                  size="small"
                  color="primary"
                  checked={campusType}
                  onChange={(e) => {
                    setCampusType(e.target.checked);
                  }}
                />
              }
              label="On Campus jobs"
              labelPlacement="end"
            />
          </Box>
        </Toolbar>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      //marginLeft: "220px",

      width: "80%",
      position: "fixed",
      // width: `calc(100% - 220px)`,
      background: "#ffffff",
      zIndex: "1",
      borderRadius: "30px",
      boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      height: "64px",
      backgroundColor: theme.palette.background.paper,
      //marginTop: "66px",
    },
    toolBar: {
      display: "flex",
      borderRadius: "30px",
      justifyContent: "space-between",
      position: "relative",
      backgroundColor: theme.palette.background.paper,
    },
    headerTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000000",
      fontFamily: "OpenSans",
    },
    headerTitle2: {
      fontSize: "18px",
      color: "#000000",
      fontFamily: "Proxima",
    },
    dropdown: {
      width: "220px",
      height: "36px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    searchBox: {
      width: "219px",
      height: "36px",
      background: "#F2F2F2",
      boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 4,
      padding: "0% 2%",
    },
    selectedTabText: {
      color: "#000",
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Proxima",
    },
    normalTabText: {
      color: "#000",
      fontSize: "18px",

      fontFamily: "Proxima",
    },
    selectedTab: {
      height: "40px",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      backgroundColor: "#E5E5E5",
      position: "absolute",
      top: "43%",
      borderRadius: "4px 4px 0px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
    },
    normalTab: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
      height: "40px",
    },
    mainContentBox: {
      width: `calc(100% - 220px)`,
      marginLeft: "220px",
      background: "#E5E5E5",
      padding: "1% 2%",
      boxSizing: "border-box",
      display: "flex",
    },
    filterPanel: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      width: "240px",
    },
  })
);
export default TrackPane;

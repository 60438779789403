import {
  Box,
  createStyles, makeStyles,
  Typography
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { TReduxStore } from "../../../..";
import { getLeads, setRef } from "../action";
import ReportsControlPanel from "../ReportsControlPanel";

export interface LeadsByIndustryProps {
  results?: { [key: string]: { count: number; company: string[] } };
}

export interface ChartDataProps {
  name: string;
  count: number;
}

const LeadByIndustry = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef();

  const leads = useSelector<TReduxStore, LeadsByIndustryProps>(
    //@ts-ignore
    (state) => state.Reports.leadsByIndustry
  );

  const [chartData, setChartData] = useState<ChartDataProps[]>([]);

  const generateChartObj = () => {
    let arr: ChartDataProps[] = [];
    Object.entries(leads.results!).forEach(([key, value]) =>
      arr.push({ name: key, count: value.count })
    );
    setChartData(arr);
  };

  useEffect(() => {
    //@ts-ignore
    dispatch(setRef(ref));
  }, []);

  useEffect(() => {
    if (leads.results !== undefined && leads.results !== null) {
      generateChartObj();
    }
  }, [leads]);

  useEffect(() => {
    if (leads.results === undefined) {
      dispatch(getLeads());
    }
  }, []);

  const data = {
    datasets: [
      {
        data: chartData.length ? chartData.map((item) => item.count) : [],
        backgroundColor: [
          "#BB6BD9",
          "#6FCF97",
          "#F2C94C",
          "#56CCF2",
          "#56CCF2",
        ],
      },
    ],
    labels: chartData.length ? chartData.map((item) => item.name) : [],
  };

  const options = {
    legend: {
      position: "right",
      align: "start",
    },
  };

  return (
    <Box
      padding="2%"
      width="100%"
      style={{ maxHeight: "100%", overflowY: "scroll", }}
    >
      <ReportsControlPanel
        // buttons={["Show Chart", "Export", "Send Email"]}
        buttons={["Export"]}
        title="Leads by industry"
      />

      <div
        className={classes.mainContent}
        //@ts-ignore
        ref={ref}
      >
        <Typography className={classes.title}>
          Industry segment for the companies visiting the college campus.
        </Typography>
        <Pie data={data} options={options} />
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    mainContent: {
      background: theme.palette.background.paper,
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      borderRadius: "25px",
      marginTop: "2%",
      padding: "2%",
      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
    },
    title: {
      fontSize: "24px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      marginBottom: "12%",
    },
  })
);

export default LeadByIndustry;

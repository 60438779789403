import { Box, createStyles, makeStyles } from "@material-ui/core";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../bundles/common/components/UI/Loader";
import { getCompanyUserDetails } from "../../../bundles/company/components/Home/action";
import FormBox from "../../../components/company/CompleteProfileForm/FormBox";
import Navbar from "../../../components/public/Navbar/Navbar";
import { BackendBaseApi } from "../../../constants/constants";
import { updateCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
function CompleteCompanyProfile() {
  const alert = useAlert();
  const [userDetails, setUserDetails] = useState({
    CompanyName: null,
    Password: "",
    PhoneNo: null,
    JobRole: null,
    CompanyTypedName: "",
    CompanyId: null,
    CompanyWebsite: "",
    State: null,
    City: null,
    JobTitle: "",
    Referral: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    password: "",
    phone: "",
    jobRole: "",
    companyName: "",
    companyNameTyped: "",
    companyWebsite: "",
    state: "",
    city: "",
    jobTitle: "",
    referral: "",
  });
  const [companyList, setCompanyList] = useState([]);
  const [jobRolesList, setJobRolesList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [currentState, setCurrentState] = useState();
  const [functionCalled, setFunctionCalled] = useState(false);
  const [companyCreation, setCompanyCreation] = useState(false);
  const [loader, setLoader] = useState(false);
  const [finalReferralCode, setFinalReferralCode] = useState("");
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  const getComp = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/company_list/")
      .then((res) => {
        if (res.data.success) {
          setCompanyList(res.data.data.all_companies);
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Some Error Occured");
      });
  };

  const getJobRoles = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/user_type/?main_user=company")
      .then((res) => {
        let temp = [];
        res.data.data.forEach((data) => {
          if (data.id !== 5) temp.push(data);
        });
        setJobRolesList(temp);
      })
      .catch((err) => {
        throw err;
      });
  };

  const getStateList = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/location/state/")
      .then((res) => {
        setStateList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getComp();
    getJobRoles();
  }, []);

  const handleSubmit = () => {
    var data = {};
    if (localStorage.getItem("mobile") !== "null") {
      if (!companyCreation) {
        data = {
          user_id: localStorage.getItem("user_id"),
          company_id: userDetails.CompanyName,
          user_type_id: userDetails.JobRole,
          job_title: userDetails.JobTitle,
          referral_code: finalReferralCode !== "" ? finalReferralCode : null,
        };
      } else {
        data = {
          user_id: localStorage.getItem("user_id"),
          company_name: userDetails.CompanyTypedName,
          company_webiste: userDetails.CompanyWebsite,
          country_id: 1,
          state_id: userDetails.State,
          city_id: userDetails.City,
          job_title: userDetails.JobTitle,
          referral_code: finalReferralCode !== "" ? finalReferralCode : null,
        };
      }
    } else {
      if (!companyCreation) {
        data = {
          user_id: localStorage.getItem("user_id"),
          company_id: userDetails.CompanyName,
          user_type_id: userDetails.JobRole,
          password: userDetails.password,
          phone: userDetails.PhoneNo,
          job_title: userDetails.JobTitle,
          referral_code: finalReferralCode !== "" ? finalReferralCode : null,
        };
      } else {
        data = {
          user_id: localStorage.getItem("user_id"),
          company_name: userDetails.CompanyTypedName,
          password: userDetails.password,
          company_webiste: userDetails.CompanyWebsite,
          country_id: 1,
          state_id: userDetails.State,
          city_id: userDetails.City,
          phone: userDetails.PhoneNo,
          job_title: userDetails.JobTitle,
          referral_code: finalReferralCode !== "" ? finalReferralCode : null,
        };
      }
    }
    Axios.post(BackendBaseApi.PRAVESH + "api/signup/company_details/", data)
      .then((res) => {
        if (res.data.success) {
          setLoader(true);
          if (!companyCreation) {
            localStorage.setItem("is_pref_filled", true);

            history.push({
              pathname: "/company/admin-approve",
              flag: 19087,

              company: res?.data?.data?.data.company_name,
              admin_email: res?.data?.data?.data.admin_email,
              admin_name: res?.data?.data?.data.admin_name,
              is_admin: res?.data?.data?.data.is_company_admin,
            });
          } else {
            localStorage.setItem("user_type_id", 5);

            dispatch(getCompanyUserDetails(history, alert));
            dispatch(updateCreditInfo(res.data.data.transaction_data));

            localStorage.setItem("is_pref_filled", true);

            setFunctionCalled(true);
          }
        } else {
          alert.error("Unable to submit form. Some Error Occured");
        }
      })
      .catch((err) => {});
  };

  const companyUserDetails = useSelector((state) => state?.CompanyUserDetails?.companyUserDetails);

  useEffect(() => {
    if (functionCalled) {
      localStorage.setItem("comp_pref_filled", false);

      history.push({ pathname: "/company/dashboard", comp_pref_filled: false });
    }
  }, [companyUserDetails]);

  const getCityList = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/location/city/?search=" + currentState.state_id)
      .then((res) => {
        setCityList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (companyCreation) getStateList();

    setUserDetails({ ...userDetails, CompanyName: "", JobRole: null, CompanyTypedName: "", CompanyId: null, CompanyWebsite: "", State: null, City: null });
    setErrorMessages({ ...errorMessages, password: "", phone: "", jobRole: "", companyName: "", companyNameTyped: "", companyWebsite: "", state: "", city: "", jobTitle: "" });
  }, [companyCreation]);

  useEffect(() => {
    if (companyCreation && currentState) getCityList();
  }, [currentState]);
  const classes = useStyles();

  return (
    <div width="100%">
      {!loader ? (
        <>
          <Navbar />
          <div className={classes.MainBox}>
            <FormBox
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
              companyList={companyList}
              jobRolesList={jobRolesList}
              stateList={stateList}
              cityList={cityList}
              companyCreation={companyCreation}
              setCompanyCreation={setCompanyCreation}
              currentState={currentState}
              setCurrentState={setCurrentState}
              handleSubmit={handleSubmit}
              finalReferralCode={finalReferralCode}
              setFinalReferralCode={setFinalReferralCode}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default CompleteCompanyProfile;
const useStyles = makeStyles((theme) =>
  createStyles({
    MainBox: {
      display: "flex",
      margin: "auto",
      maxWidth: 800,
      marginTop: "9%",
      padding: "0px 6px 0px 0px",
    },
    "@media only screen and (max-width: 750px)": {
      MainBox: {
        marginTop: "25%",
      },
    },
  })
);

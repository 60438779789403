/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, FormControlLabel, Grid, Switch, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";
import check from "../../../assets/checked-new.png"
import app_download from "../../../assets/new/qr-code.png";
import app_store from "../../../assets/apple-min.png";
import play_store from "../../../assets/goolge-min.png";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/common/httpRequest";
import Loader from "../../common/UI/Loader";
import Filter from "../filter/filter";
import FilterDialog from "../filter/FilterDIalog";
import Layout from "../Layout/Layout";
import SmallCard from "../SmallCardJob/SmallCard";
import ViewJob from "../viewJob/ViewJob";
import {instituteIdSelectorFn} from "../../../selector";
import "./openjob.css";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    marginTop: "50px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    marginTop: "50px",
    justifyContent: "center",
  },
  playstore: {
    borderRadius: "10px",
  },
}));

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [jobData, setJobData] = useState([]);
  const [wholeData, setWholeData] = useState();

  const [singleJobData, setSingleJobData] = useState(null);
  const [filter, setFiltersData] = useState(null);
  const data = JSON.parse(localStorage.getItem("user_details"));
  const [appliedList, setAppliedList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const [scrollLoader, setScrollLoader] = useState(false);
  const alert = useAlert();
  const [filterObject, setFilterObject] = React.useState({});
  const [filterList, setFilterList] = React.useState({});
  const [filterArray, setFilterArray] = useState([]);
  const [filterData, setFilterData] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [applyButtonClicked, setApplyButtonClicked] = useState(false);
  const [loaderSingleJob, setLoaderSingleJob] = useState(false);
  const [clearAllClicked, setClearAllClicked] = useState(false);
  const instituteId = useSelector(instituteIdSelectorFn);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  const classes = useStyles();

  const checkSpecialization = () => {
    if (!filterDetails[0]?.specialization_id) return "";
    else return `&specialization_id=${btoa(filterDetails[0]?.specialization_id)}`;
  };
  const [hiring, setHiring] = useState(true);

  const checkString = () => {
    if (searchString) return `&${searchString}`;
    else return "";
  };
  const checkHiring = () => {
    if (hiring) return `&hiring_type=${btoa("ON CAMPUS")}`;
    else return `&hiring_type=${btoa("OFF CAMPUS")}`;
  };
  const [firstJobId, setFirstJobId] = useState(null);

  const filterDetails = data.student_user_details.education.filter((item) => item.is_current === true);

  const getAllFilters = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/public/filter/open-jobs", {
      headers: { Authorization: `Token ${localStorage.getItem("gw_token")}` },
    })
      .then((res) => {
        if (res.data.success) {
          Object.keys(res.data.data).forEach((item) => {
            res.data.data[item].selected_data = [];
          });
          setFilterList(res.data.data);
          var temp = {};

          Object.keys(res.data.data).forEach((head) => {
            temp[res.data.data[head].param_name] = [];
          });

          Object.keys(res.data.data).map((item) => {
            if (res.data.data[item].data.length > 0) setFilterData(true);
          });

          setFilterObject(temp);
        }
      })
      .catch((err) => {
        alert.error("Some Error Occured");
      });
  };

  useEffect(() => {
    getAllFilters();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("gw_token");
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/v2/student/job/dweb/?user_id=${window.btoa(data?.id)}&college_id=${window.btoa(filterDetails[0]?.college)}&degree_id=${window.btoa(
        filterDetails[0]?.degree_id
      )}${checkSpecialization()}${checkHiring()}`,
      { headers: token },
      setJobData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (applyButtonClicked) {
      setLoading(true);

      const token = localStorage.getItem("gw_token");
      GetData(
        BackendBaseApi.NIYUKTI,
        `job/v2/student/job/dweb/?user_id=${window.btoa(data?.id)}&college_id=${window.btoa(filterDetails[0]?.college)}&degree_id=${window.btoa(
          filterDetails[0]?.degree_id
        )}${checkSpecialization()}${checkString()}${checkHiring()}`,
        { headers: token },
        setJobData
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyButtonClicked]);

  useEffect(() => {
    setLoading(true);

    const token = localStorage.getItem("gw_token");
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/v2/student/job/dweb/?user_id=${window.btoa(data.id)}&college_id=${window.btoa(filterDetails[0]?.college)}&degree_id=${window.btoa(
        filterDetails[0]?.degree_id
      )}${checkSpecialization()}${checkString()}${checkHiring()}`,
      { headers: token },
      setJobData
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiring]);

  const GetData = async (baseUrl, endPoint, body, updateState) => {
    let res = await httpRequest(baseUrl, endPoint, body);
    setWholeData(res.data.count);
    if (res.data.next === null) {
      setEnd(true);
    } else {
      setNewURL(res.data.next.slice(0, 20));
      setNewEndPoint(res.data.next.slice(20));
    }

    setFirstJobId(res.data.results.data.length && res.data.results.data[0].job_id);
    updateState(jobData.concat([...res.data.results.data]));

    setFiltersData(res.data.filter);
    setLoading(false);
    setScrollLoader(false);
    setApplyButtonClicked(false);
    setClearAllClicked(false);
  };

  const selectedJob = (item) => {
    setSingleJobData(item);
  };

  const handleScroll = (event) => {
    setScrollLoader(true);
    let e = event.nativeEvent;
    if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
      if (end !== true) {
        const token = localStorage.getItem("gw_token");
        GetData(newURL, newEndPoint, { headers: token }, setJobData);
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getSingleJobData = (id) => {
    setLoaderSingleJob(true);

    let jobUrl = BackendBaseApi.NIYUKTI + `job/?job_id=${btoa(id.toString())}`;
    Axios.get(jobUrl, {
      headers: {
        // ...(localStorage.getItem("gw_token") && {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
        // }),
      },
    })
      .then((res) => {
        if (res.data.success) {
          setSingleJobData(res.data.data);
          setLoaderSingleJob(false);
        } else {
        }
        setLoaderSingleJob(false);
      })
      .catch((err) => {
        alert.error("Error");
        setLoaderSingleJob(false);
      });
  };

  useEffect(() => {
    if (firstJobId) getSingleJobData(firstJobId);
  }, [firstJobId]);

  return (
    <>
      <BreakpointProvider>
        <>
          <Breakpoint large up style={{ width: "100%" }}>
            <Layout>
              {!loading ? (
                <>
                  <div className="open-job text-left ">
                    <div
                      className="container__jobs"
                      style={{
                        marginTop: "14px",
                      }}
                    >
                      <div className="fs-16 fw-700 text-left">
                        {jobData.length}/{wholeData} open jobs
                      </div>
                    </div>

                    {/* <Filter setLoading={setLoading} filterData={filter} setJobData={setJobData} fullView={true} setSingleJobData={setSingleJobData} /> */}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <FilterDialog
                        open={open}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        data={filterList}
                        setData={setFilterList}
                        filterObject={filterObject}
                        setFilterObject={setFilterObject}
                        // loader={filterLoader}
                        // setLoader={setFilterLoader}
                        filterArray={filterArray}
                        setFilterArray={setFilterArray}
                        setSearchString={setSearchString}
                        filterData={filterData}
                        setApplyButtonClicked={setApplyButtonClicked}
                        setJobData={setJobData}
                        setSingleJobData={setSingleJobData}
                        setNewURL={setNewURL}
                        setNewEndPoint={setNewEndPoint}
                        setEnd={setEnd}
                        setClearAllClicked={setClearAllClicked}
                        clearAllClicked={clearAllClicked}
                      />
                      &nbsp; &nbsp; &nbsp;&nbsp;
                      <FormControlLabel
                        control={
                          <Switch
                            checked={hiring}
                            onChange={(e) => {
                              setJobData([]);
                              setSingleJobData(null);
                              setNewEndPoint("");
                              setEnd(false);
                              setNewURL("");
                              setHiring(e.target.checked);
                            }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="On Campus"
                      />
                    </div>
                    {jobData.length ? (
                      <>
                        <Grid container className="mg-top-10" spacing={6}>
                          {jobData !== null ? (
                            <>
                              <Grid
                                xs={4}
                                sm={4}
                                className="scrollY"
                                style={{
                                  height: "82vh",
                                  boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)",
                                }}
                                onScroll={handleScroll}
                              >
                                {jobData.length ? (
                                  <SmallCard
                                    setSingleJobData={selectedJob}
                                    singleJobData={singleJobData}
                                    data={jobData}
                                    setIndex={setCurrentIndex}
                                    getSingleJobData={getSingleJobData}
                                  />
                                ) : (
                                  <div className="text-gray fs-20 fw-700 mg-top-20">No Jobs</div>
                                )}
                                {scrollLoader && !end && (
                                  <Box paddingLeft="5%">
                                    {" "}
                                    <JobCardSkeleton />{" "}
                                  </Box>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                sm={8}
                                className="scrollY"
                                style={{
                                  height: "82vh",
                                  marginTop: "24px",
                                  // marginLeft: "5px",
                                }}
                              >
                                {loaderSingleJob ? (
                                  <>
                                    <Box style={{ display: "flex", justifyContent: "center", marginTop: "25%" }}>
                                      <CircularProgress size={40} />
                                    </Box>
                                  </>
                                ) : singleJobData ? (
                                  <ViewJob
                                    data={singleJobData}
                                    open={true}
                                    closeIcon={true}
                                    apply={true}
                                    appliedList={appliedList}
                                    setAppliedList={setAppliedList}
                                    index={currentIndex}
                                  />
                                ) : (
                                  <div className="text-gray fs-20 fw-700 mg-top-20">No Jobs</div>
                                )}
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </>
                    ) : (
                      <div className="fs-20 fw-700 mg-top-20" style={{ marginTop: "45px" }}>
                        {/* No Open Jobs */}
                        <Box display="flex" flexDirection="column" alignItems="center" marginTop="5%" paddingBottom="5%">
                          {!instituteId &&
                          <Grid container spacing={3} style={{ display: "flex", alignItems: "center" }}>
                            <Grid item xs={5}>
                              <img src={"https://s3.ap-south-1.amazonaws.com/getwork-store/prod/common/APP"} height="350px" width="180px" alt="banner" style={{float:"right", marginRight: "20px"}} />
                            </Grid>
                            <Grid item xs={7}>
                              <Typography variant="subtitle2" style={{ fontFamily: " Nunito", color: "#6c757d", fontSize: 17, marginBottom: 10 }}>
                                <b> Download the App to Access On Campus Placement Jobs </b>
                              </Typography>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                                  {" "}
                                  <img src={check} height="20px" width="20px" />
                                  <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                                    Get New Job Alerts
                                  </Typography>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                                  {" "}
                                  <img src={check} height="20px" width="20px" />
                                  <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                                    Create your Resume & Share{" "}
                                  </Typography>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <img src={check} height="20px" width="20px" />
                                  <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                                    Manage your Job Applications{" "}
                                  </Typography>
                                </div>
                              </div>
                              <br />
                              <Grid container spacing={3}>
                                <Grid item xs={5}>
                                  <img id="footerBtn" src={app_download} alt="playstore" height="120px" />
                                </Grid>
                                <Grid item xs={7} style={{ display: "flex", alignItems: "center" }}>
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <a href="https://play.google.com/store/apps/details?id=com.getwork_app" target="_blank" style={{ marginBottom: 10 }}>
                                      <img id="footerBtn" src={play_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                                    </a>

                                    <a href="https://apps.apple.com/in/app/getwork/id1579830388" target="_blank">
                                      <img id="footerBtn" src={app_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                                    </a>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          }
                          <Link to="/student/dashboard">
                            <Button
                              style={{
                                width: 238,
                                height: 44,
                                backgroundColor: (domain?.domain ? domain?.color_code : "#007bff"),
                                textTransform: "capitalize",
                                color: "#ffffff",
                                marginTop:"30px",
                                "&:hover": {
                                  backgroundColor: "#3282C4",
                                },
                              }}
                            >
                              Go Back To Home
                            </Button>
                          </Link>
                        </Box>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </Layout>
          </Breakpoint>
          <Breakpoint medium down>
            {!loading ? (
              <Layout>
                <div className="open-job text-left">
                  <div
                    className="container__jobs"
                    style={{
                      marginTop: "14px",
                    }}
                  >
                    <div className="fs-16 fw-700 text-left">
                      {jobData.length}/{wholeData} open jobs
                    </div>
                  </div>
                  {/* {filter !== null && <Filter filterData={filter} setJobData={setJobData} fullView={true} setSingleJobData={setSingleJobData} />} */}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FilterDialog
                        open={open}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        data={filterList}
                        setData={setFilterList}
                        filterObject={filterObject}
                        setFilterObject={setFilterObject}
                        // loader={filterLoader}
                        // setLoader={setFilterLoader}
                        filterArray={filterArray}
                        setFilterArray={setFilterArray}
                        setSearchString={setSearchString}
                        filterData={filterData}
                        setApplyButtonClicked={setApplyButtonClicked}
                        setJobData={setJobData}
                        setSingleJobData={setSingleJobData}
                        setNewURL={setNewURL}
                        setNewEndPoint={setNewEndPoint}
                        setEnd={setEnd}
                        setClearAllClicked={setClearAllClicked}
                        clearAllClicked={clearAllClicked}
                      />
                     &nbsp;&nbsp;
                    <FormControlLabel
                      control={
                        <Switch
                          checked={hiring}
                          onChange={(e) => {
                            setJobData([]);
                            setSingleJobData(null);
                            setNewEndPoint("");
                            setEnd(false);
                            setNewURL("");
                            setHiring(e.target.checked);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="On Campus"
                    />
                  </div>
                  <Grid container>
                    <Grid xs={12} onScroll={handleScroll} className="scrollY">
                      {jobData.length ? (
                        <SmallCard
                          setSingleJobData={selectedJob}
                          apply={true}
                          viewJob={true}
                          singleJobData={singleJobData}
                          data={jobData}
                          setIndex={setCurrentIndex}
                          getSingleJobData={getSingleJobData}
                        />
                      ) : (
                        <div className="text-gray fs-20 fw-700 " style={{ marginTop: "45px" }}>
                          {/* No Jobs
                          <Box>
                            <img src={NoJobs} width="100%" height="auto" />
                          </Box> */}
                          {!instituteId &&
                          <Grid container style={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={"https://s3.ap-south-1.amazonaws.com/getwork-store/prod/common/APP"} height="350px" width="180px" alt="banner" style={{ margin: "auto", display: "inline-block" }}
                            />
                            <Grid item style={{textAlign:"center"}}>
                              <Typography variant="subtitle2" style={{ fontFamily: " Nunito", color: "#6c757d", fontSize: 17, margin: "20px 0px 10px 0px",}}>
                                <b> Download the App to Access On Campus Placement Jobs </b>
                              </Typography>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                                  {" "}
                                  <img src={check} height="20px" width="20px" />
                                  <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                                    Get New Job Alerts
                                  </Typography>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                                  {" "}
                                  <img src={check} height="20px" width="20px" />
                                  <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                                    Create your Resume & Share{" "}
                                  </Typography>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <img src={check} height="20px" width="20px" />
                                  <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                                    Manage your Job Applications{" "}
                                  </Typography>
                                </div>
                              </div>
                              <br />
                              <Grid container spacing={3} style={{width: "80%"}}>
                                <Grid item xs={5}>
                                  <img id="footerBtn" src={app_download} alt="playstore" height="120px" />
                                </Grid>
                                <Grid item xs={7} style={{ display: "flex", alignItems: "center" }}>
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <a href="https://play.google.com/store/apps/details?id=com.getwork_app" target="_blank" style={{ marginBottom: 10 }}>
                                      <img id="footerBtn" src={play_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                                    </a>

                                    <a href="https://apps.apple.com/in/app/getwork/id1579830388" target="_blank">
                                      <img id="footerBtn" src={app_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                                    </a>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          }
                          <Box display="flex" justifyContent="center" padding="8%">
                            <Link to="/student/dashboard">
                              <Button
                                style={{
                                  width: 238,
                                  height: 44,
                                  backgroundColor: (domain?.domain ? domain?.color_code : "#007bff"),
                                  textTransform: "capitalize",
                                  color: "#ffffff",
                                  "&:hover": {
                                    backgroundColor: "#3282C4",
                                  },
                                }}
                              >
                                Go Back To Home
                              </Button>
                            </Link>
                          </Box>
                        </div>
                      )}
                      {scrollLoader && !end && (
                        <Box>
                          {" "}
                          <JobCardSkeleton />{" "}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Layout>
            ) : (
              <Box>
                <Loader />
              </Box>
            )}
          </Breakpoint>
        </>
      </BreakpointProvider>
    </>
  );
}

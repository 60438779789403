import { CircularProgress } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi } from "../../../constants/constants";

function SsoAuth() {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  var search = location.search;
  var params = new URLSearchParams(search);
  useEffect(() => {
    checkSSO();
  }, []);

  const checkSSO = () => {
    setLoading(true);
    let token = "";
    Axios.get(
      BackendBaseApi.PRAVESH + `api/getwork/sso/validate/?sso_token=${params.get("sso_token")}&utm_source=${params.get("utm_source")}&utm_medium=${params.get("utm_medium")}&utm_campaign=${params.get("utm_campaign")}`,
      { withCredentials: true }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.data.message);
          token = res.data.data.data.token;
          const moveToJobPage = res.data.data.is_job_page;
          checkUser(token, moveToJobPage);
        } else {
          alert.error(res.data.error);
          history.push("/login/student");
        }
      })
      .catch((error) => {
        alert.error(error);
        history.push("/login/student");
      })
    
  };

  const checkUser = (token, moveToJobPage=false) => {
    Axios.get(BackendBaseApi.PRAVESH + "api/profile/details/", {
      headers: {
        Authorization: "Token " + token,
      },
      withCredentials: true,
    })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          alert.success("User Already Create!! Please Complete Your Profile");
          if (!res.data.email_verified) {
            history.push({
              pathname: "/confirm-email",
              email: res.data.email,
              user_id: res.data.user_type[0].user_id,
            });
          }
        } else {
          if (res.data.user_type[0].type === 10) {
            localStorage.clear();
            alert.error("You can not Login with Admin account here");
          } else {
            // if (res.data.email_verified) {
              if (res.data.user_type[0].type !== 10) {
                localStorage.setItem("user_type", res.data.user_type[0].main_user);
              }
              localStorage.setItem("gw_token", token);
              localStorage.setItem("user_type_id", res.data.user_type[0].type);
              localStorage.setItem("user_id", res.data.user_type[0].user_id);
              localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
              localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);

              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem("user_type", res.data.user_type[0].main_user);

                //student with verified email, go to home
                localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
                localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);

                localStorage.setItem("user_id", res.data.id);

                if (location.flag == "102") {
                  history.push("/student/open");
                } else {
                  if (res.data.is_pref_filled) {
                    if (location.search && location.search.includes("?source=")) history.push(`${location.search.replace("?source=", "")}`);
                    else history.push("/student/dashboard");
                  } else {
                    if (location.search && location.search.includes("?source=")) {
                      if (res.data.is_student_resume_uploaded) history.push(`${location.search.replace("?source=", "")}`);
                      else history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                    } else {
                      history.push({
                        pathname: "/student/complete-profile",
                        flag: 12367,
                      });
                    }
                  }
                }
              } 
              else {
              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem("gw_token", token);
                localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
                localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);
                localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);

                localStorage.setItem("user_type", res.data.user_type[0].main_user);
                localStorage.setItem("user_id", res.data.id);

                localStorage.setItem("user_type_id", res.data.user_type[0].type);

                //student without verified email, go to home

                if (res.data.is_pref_filled) {
                  if (location.search && location.search.includes("?source=")) history.push(`${location.search.replace("?source=", "")}`);
                  else {
                    if(moveToJobPage)
                      history.push("/student/open");
                    else
                    history.push("/student/dashboard");
                  }
                } 
              }
              else {
                alert.error("Not An Student User.Please Login As Student!!");
                history.push("/login/student"); 
                }
              
            }
          }
        }

        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        throw err;
      });
  };

  return (
    <CircularProgress size={60} style={{ margin: "auto", marginTop: 100, display: "flex" }} />
  );
}

export default SsoAuth;

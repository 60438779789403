import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import eg from "../../assets/images/banner-bg.jpg";
import eg1 from "../../assets/images/location1.jpg";
import eg2 from "../../assets/images/location2.jpg";
import eg3 from "../../assets/images/location3.jpeg";
import eg4 from "../../assets/images/location4.jpg";
import eg5 from "../../assets/images/location5.jpeg";

import { Box, Button, Grid, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import kFormatter, { LFormatter } from "../../utils/ZeroToK";
import { changeState, UPDATE_LOCATION_EXPLORE, UPDATE_LOCATION_EXPLORE_COLLEGE } from "./action";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles({
  ImageBox: {
    position: "relative",
    width: "100%",
    height: "229px",
    display: "block",
    borderRadius: "30px",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
  },
  subText: {
    fontSize: "13px",
    color: "#fff",
    marginTop: "-10px",
  },

  InnerText: {
    display: "block",
    position: "relative",
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "229px",
    background: "linear-gradient(0deg, rgba(255,255,255,0.4) 0%, rgba(0,0,0,0.85) 100%)",
    color: "white",
    borderRadius: "30px",
    paddingTop: "20px",
    paddingLeft: "30px",
  },
  CTCBox: {
    position: "absolute",
    backgroundColor: "#f15f19",
    // width: "13%",
    padding: "10px 20px 10px 10px",
    right: "20px",
    bottom: "20px",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
  },
});

export default function ImageGroup(props) {
  const { data } = props;
  const [locationData, setLocationData] = React.useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const collegeID = localStorage.getItem("college_id");

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };
  useEffect(() => {
    if (data.location !== undefined) {
      setLocationData(data.location);
    }
  }, [data]);
  const classes = useStyles();
  return (
    <div>
      {/* <Box>
        <img src={eg2} alt="" height="239px" width="676px" />
      </Box> */}
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <div
            onClick={() => {
              if (!collegeID) {
                handleFilterChange(UPDATE_LOCATION_EXPLORE, [
                  {
                    city: locationData ? locationData[0].name : "",
                    city_id: locationData ? locationData[0].id : "",
                  },
                ]);

                history.push({
                  pathname: "/company/invite",
                  flag: 1424,
                });
              } else {
                handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, [
                  {
                    city: locationData ? locationData[0].name : "",
                    city_id: locationData ? locationData[0].id : "",
                  },
                ]);

                history.push({
                  pathname: "/college/invite",
                  flag: 1424,
                });
              }
            }}
          >
            <div
              className={classes.ImageBox}
              // href="#"
              style={{
                background: `url(${locationData ? locationData[0].image : eg1})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <span className={classes.InnerText}>
                <h4 style={{ marginBottom: "-1px" }}>{locationData ? locationData[0].name : ""}</h4>
                <span className={classes.subText}>
                  {locationData ? locationData[0]?.count : ""}&nbsp;
                  {!collegeID ? "Colleges" : "Companies"}
                </span>
                <Box className={classes.CTCBox}>
                  {!collegeID ? <b>Avg CTC</b> : <b>Employees</b>}
                  <span align="right">
                    <b>
                      {!collegeID ? (
                        locationData ? (
                          locationData[0].size_avg_ctc > 99999 ? (
                            LFormatter(locationData[0]?.size_avg_ctc) + "PA"
                          ) : (
                            kFormatter(locationData[0]?.size_avg_ctc) + "PA"
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <>{locationData && locationData[0]?.size_avg_ctc}&nbsp;</>
                      )}
                    </b>
                  </span>
                </Box>
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div
            onClick={() => {
              if (!collegeID) {
                handleFilterChange(UPDATE_LOCATION_EXPLORE, [
                  {
                    city: locationData ? locationData[1].name : "",
                    city_id: locationData ? locationData[1].id : "",
                  },
                ]);

                history.push({
                  pathname: "/company/invite",
                  flag: 1424,
                });
              } else {
                handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, [
                  {
                    city: locationData ? locationData[1].name : "",
                    city_id: locationData ? locationData[1].id : "",
                  },
                ]);

                history.push({
                  pathname: "/college/invite",
                  flag: 1424,
                });
              }
            }}
          >
            <div
              className={classes.ImageBox}
              style={{
                background: `url(${locationData ? locationData[1].image : eg2})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <span className={classes.InnerText}>
                <h4 style={{ marginBottom: "-1px" }}>{locationData ? locationData[1].name : ""}</h4>
                <span className={classes.subText}>
                  {locationData ? locationData[1]?.count : ""}&nbsp; {!collegeID ? "Colleges" : "Companies"}
                </span>
                <Box className={classes.CTCBox}>
                  {!collegeID ? <b>Avg CTC</b> : <b>Employees</b>}
                  <span align="right">
                    <b>
                      {!collegeID ? (
                        locationData ? (
                          locationData[1].size_avg_ctc > 99999 ? (
                            LFormatter(locationData[1]?.size_avg_ctc) + "PA"
                          ) : (
                            kFormatter(locationData[1]?.size_avg_ctc) + "PA"
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <>{locationData && locationData[1]?.size_avg_ctc}&nbsp;</>
                      )}
                    </b>
                  </span>
                </Box>
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div
            onClick={() => {
              if (!collegeID) {
                handleFilterChange(UPDATE_LOCATION_EXPLORE, [
                  {
                    city: locationData ? locationData[2].name : "",
                    city_id: locationData ? locationData[2].id : "",
                  },
                ]);

                history.push({
                  pathname: "/company/invite",
                  flag: 1424,
                });
              } else {
                handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, [
                  {
                    city: locationData ? locationData[2].name : "",
                    city_id: locationData ? locationData[2].id : "",
                  },
                ]);

                history.push({
                  pathname: "/college/invite",
                  flag: 1424,
                });
              }
            }}
          >
            <div
              className={classes.ImageBox}
              style={{
                background: `url(${locationData ? locationData[2].image : eg3})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <span className={classes.InnerText}>
                <h4 style={{ marginBottom: "-1px" }}>{locationData ? locationData[2].name : ""}</h4>
                <span className={classes.subText}>
                  {locationData ? locationData[2]?.count : ""}&nbsp; {!collegeID ? "Colleges" : "Companies"}
                </span>
                <Box className={classes.CTCBox}>
                  {!collegeID ? <b>Avg CTC</b> : <b>Employees</b>}
                  <span align="right">
                    <b>
                      {!collegeID ? (
                        locationData ? (
                          locationData[2].size_avg_ctc > 99999 ? (
                            LFormatter(locationData[2]?.size_avg_ctc) + "PA"
                          ) : (
                            kFormatter(locationData[2]?.size_avg_ctc) + "PA"
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <>{locationData && locationData[2]?.size_avg_ctc}&nbsp;</>
                      )}
                    </b>
                  </span>
                </Box>
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            onClick={() => {
              if (!collegeID) {
                handleFilterChange(UPDATE_LOCATION_EXPLORE, [
                  {
                    city: locationData ? locationData[3].name : "",
                    city_id: locationData ? locationData[3].id : "",
                  },
                ]);

                history.push({
                  pathname: "/company/invite",
                  flag: 1424,
                });
              } else {
                handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, [
                  {
                    city: locationData ? locationData[3].name : "",
                    city_id: locationData ? locationData[3].id : "",
                  },
                ]);

                history.push({
                  pathname: "/college/invite",
                  flag: 1424,
                });
              }
            }}
          >
            <div
              className={classes.ImageBox}
              style={{
                background: `url(${locationData ? locationData[3].image : eg4})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <span className={classes.InnerText}>
                <h4 style={{ marginBottom: "-1px" }}>{locationData ? locationData[3].name : ""}</h4>
                <span className={classes.subText}>
                  {locationData ? locationData[3]?.count : ""}&nbsp; {!collegeID ? "Colleges" : "Companies"}
                </span>
                <Box className={classes.CTCBox}>
                  {!collegeID ? <b>Avg CTC</b> : <b>Employees</b>}
                  <span align="right">
                    <b>
                      {!collegeID ? (
                        locationData ? (
                          locationData[3].size_avg_ctc > 99999 ? (
                            LFormatter(locationData[3]?.size_avg_ctc) + "PA"
                          ) : (
                            kFormatter(locationData[3]?.size_avg_ctc) + "PA"
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <>{locationData && locationData[3]?.size_avg_ctc}&nbsp;</>
                      )}
                    </b>
                  </span>
                </Box>
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            onClick={() => {
              if (!collegeID) {
                handleFilterChange(UPDATE_LOCATION_EXPLORE, [
                  {
                    city: locationData ? locationData[4].name : "",
                    city_id: locationData ? locationData[4].id : "",
                  },
                ]);

                history.push({
                  pathname: "/company/invite",
                  flag: 1424,
                });
              } else {
                handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, [
                  {
                    city: locationData ? locationData[4].name : "",
                    city_id: locationData ? locationData[4].id : "",
                  },
                ]);

                history.push({
                  pathname: "/college/invite",
                  flag: 1424,
                });
              }
            }}
          >
            <div
              className={classes.ImageBox}
              style={{
                background: `url(${locationData ? locationData[4].image : eg5})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <span className={classes.InnerText}>
                <h4 style={{ marginBottom: "-1px" }}>{locationData ? locationData[4].name : ""}</h4>
                <span className={classes.subText}>
                  {locationData ? locationData[4]?.count : ""}&nbsp;
                  {!collegeID ? "Colleges" : "Companies"}
                </span>
                <Box className={classes.CTCBox}>
                  {!collegeID ? <b>Avg CTC</b> : <b>Employees</b>}
                  <span align="right">
                    <b>
                      {!collegeID ? (
                        locationData ? (
                          locationData[4].size_avg_ctc > 99999 ? (
                            LFormatter(locationData[4]?.size_avg_ctc) + "PA"
                          ) : (
                            kFormatter(locationData[4]?.size_avg_ctc) + "PA"
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <>{locationData && locationData[4]?.size_avg_ctc}&nbsp;</>
                      )}
                    </b>
                  </span>
                </Box>
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

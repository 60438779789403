import { Button, IconButton, TextField, withStyles } from "@material-ui/core";

const CommonIconButton = withStyles({
  root: {
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    backgroundColor: "#007bff",

    color: "#fff",
    "&:hover": {
      backgroundColor: "#0069d9",
    },
    "&.MuiIcoButton-outlined": {
      backgroundColor: "#fff",
      borderRadius: 30,
      border: "1px solid #007bff",
      color: "#007bff",
      "&:hover": {
        border: "1px solid #0069d9",
        color: "#0069d9",
      },
    },
    "&.MuiIconButton-root.Mui-disabled": {
      backgroundColor: "#58aaff",
      color: "#fff",
    },
  },
})(IconButton);

export default CommonIconButton;

import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import CustomizedCheckboxes from "../../../../../components/common/JobPostNew/FormComponents/CustomizedCheckboxes";
import { BackendBaseApi, websiteRegexNew } from "../../../../../constants/constants";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import StyledRadio from "../../../../common/JobPostNew/FormComponents/CustomizedRadio";
import CommonChip from "../../../../CommonChip/CommonChip";
import LanguageCard from "./LanguageCard";

const useStyles = makeStyles((theme) => ({
  paper: {
    // minHeight: "600px",
    borderRadius: "20px",
    // textAlign: "center",
    padding: 8,
    width: "100%",
    marginBottom: 10,
  },
  formBox: {
    background: "#fff",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    borderRadius: 30,
    padding: 20,
    width: "100%",
    marginBottom: 65,
  },
  labelField: {
    fontSize: 13,
  },
  RedStar: {
    color: "red",
  },
  heading: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: "0rem",
    color: "#4f4f4f",
  },
  subTitle: {
    color: "grey",
    textAlign: "center",
    fontSize: 14,
    marginBottom: 25,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "6.5px 11px",
  },
  fieldStyling: {
    width: "100%",
    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    flexFlow: "row",
    justifyContent: "center",
    wordWrap: "break-word",
  }
}));

function SkillsDetails(props) {
  const {
    allSkills,
    skillDetails,
    setSkillDetails,
    skillDetailsErrors,
    setSkillDetailsErrors,
    skillDetailsList,
    setSkillDetailsList,
    linkList,
    setLinkList,
    allSocialLinks,
    setButtonClicked,
    buttonClicked,
    linksLoader,
    skillsLoader,
    openAddHobbies,
    setOpenAddHobbies,
    openAddLanguages,
    setOpenAddLanguages,
    extraData,
    setExtraData,
    fetchPrecentage,
  } = props;

  const classes = useStyles();
  const alert = useAlert();
  const [saveLoader, setSaveLoader] = useState(false);
  const [saveLoaderLinks, setSaveLoaderLinks] = useState(false);
  const [saveLoaderLanguage, setSaveLoaderLanguage] = useState(false);
  const [saveLoaderHobbies, setSaveLoaderHobbies] = useState(false);
  const [hobbies, setHobbies] = useState(null);
  const [skills, setSkills] = useState(null);
  const activeSocialLinks = [{"id":1,"name":"LinkedIn","icon":""},
  {"id":2,"name":"Github","icon":""},
  {"id":3,"name":"Facebook","icon":"NULL"},
  {"id":4,"name":"Instagram","icon":"NULL"} 
]

  const handleDeleteSkill = (id) => {
    var arr = [...skillDetailsList];
    const variableOne = arr.filter((itemInArray) => itemInArray.skill_id !== id);
    setSkillDetailsList(variableOne);
    setButtonClicked(false);
  };

  const handleDeleteLink = (id) => {
    var arr = [...linkList];
    const variableOne = arr.filter((itemInArray) => itemInArray.id !== id);

    Axios.patch(
      BackendBaseApi.PRAVESH + "api/shared/user/?user_type=1",
      {
        profile_url: variableOne,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setLinkList(variableOne);
        } else {
          alert.error("Unable to post Links for this Student!");
        }
        // setCircularLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (skillDetails.singleSkillSelected) {
      var arr = [...skillDetailsList];
      arr.push(skillDetails.singleSkillSelected);
      const arrayUniqueByKey = [...new Map(arr.map((item) => [item["skill_id"], item])).values()];
      setSkillDetailsList(arrayUniqueByKey);
    }
  }, [skillDetails.singleSkillSelected]);

  const updateUserSkills = () => {
    //make a post call to send new skills

    setSaveLoader(true);
    Axios.post(
      BackendBaseApi.PRAVESH + "api/education/user_skill",
      { skill: skillDetailsList },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        alert.success("Skills updated successfully!");
        setSaveLoader(false);
        fetchPrecentage();
      })
      .catch((err) => {
        alert.error("There was error updating your skills");
        setSaveLoader(false);
      });
  };
  const [validate, setValidate] = useState(false);

  const validateSkills = () => {
    var skills_error = "";

    setValidate(false);
    if (!skillDetails.singleSkillSelected) {
      skills_error = "Please Select skill first";
      setValidate(true);
    }

    setSkillDetailsErrors({
      ...skillDetailsErrors,
      singleSkillSelected: skills_error,
    });

    setButtonClicked(true);
  };

  const validateLinks = () => {
    var link_error = "";
    var selected_type_error = "";
    setValidate(false);
    if (!skillDetails.singleLinkSelected) {
      selected_type_error = "Please Select Link type";
      setValidate(true);
    }

    if (skillDetails.singleLinkSelected && (checkIfSkillPresent(skillDetails.singleLinkSelected))) {
      selected_type_error = "You have already selected this link type";
      setValidate(true);
    }

    if (skillDetails.link === "") {
      link_error = "Please enter your link";
      setValidate(true);
    }

    if (!websiteRegexNew.test(skillDetails.link)) {
      link_error = "Please enter your link correctly (include http or https)";
      setValidate(true);
    }

    setSkillDetailsErrors({
      ...skillDetailsErrors,
      singleLinkSelected: selected_type_error,
      link: link_error,
    });

    setButtonClicked(true);
  };

  const checkIfSkillPresent = (skill) => {
    var boole = false;
    linkList.map((item) => {
      if ((item.value || item.Value) && item.id === skill.id) boole = true;
    });
    return boole;
  };

  useEffect(() => {
    if (!validate && buttonClicked) {
      addLinks();
      //   if (editOpen) handleEdit();
      //   else
      //   updateUserSkills();
    }
  }, [buttonClicked]);

  const addLinks = () => {
    setSaveLoaderLinks(true);
    var arr = [...linkList];
    arr.push({ id: skillDetails.singleLinkSelected.id, name: skillDetails.singleLinkSelected.name, value: skillDetails.link });
    Axios.patch(
      BackendBaseApi.PRAVESH + "api/shared/user/?user_type=1",
      {
        profile_url: arr,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setLinkList(arr);
          fetchPrecentage();

          setSkillDetails({
            ...skillDetails,
            singleLinkSelected: null,
            link: "",
          });
          setSaveLoaderLinks(false);
        } else {
          alert.error("Unable to post Links for this Student!");
          setSaveLoaderLinks(false);
        }
        // setCircularLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSaveLoaderLinks(false);
      });
  };

  const [checkDisabling, setCheckDisabling] = useState(false);

  useEffect(() => {
    setCheckDisabling(true);
  }, [skillDetailsList.length]);

  const handleDeleteHobby = (index) => {
    var array = [...extraData.hobbies];
    if (index > -1) {
      array.splice(index, 1);
    }
    setExtraData({ ...extraData, hobbies: array });
  };

  const updateUserHobbies = (array) => {
    hobbies && array.push(hobbies);
    setSaveLoaderHobbies(true);
    Axios.patch(
      BackendBaseApi.PRAVESH + "api/education/student_user_extras/",
      {
        hobbies: array,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          fetchPrecentage();
          setHobbies('');
          alert.success(res.data.data.message);
          setExtraData({ ...extraData, singleHobby: null });
          
        } else {
          alert.error(res.data.error);
        }
        setSaveLoaderHobbies(false);
        // setCircularLoading(false);
      })
      .catch((err) => {
        alert.error(err.message);
        setSaveLoaderHobbies(false);
      });
  };

  const updateUserLanguage = () => {
    if(!extraData.singleLanguage || !(extraData.level && extraData.level.length !== 0) || !extraData.proficiency)
    {
      alert.error("Fill all details!");
      return;
    }
    var arr = [...extraData.languages];
    arr.push({
      name: extraData.singleLanguage,
      level: extraData.level,
      proficiency: extraData.proficiency,
    });
    setSaveLoaderLanguage(true);
    Axios.patch(
      BackendBaseApi.PRAVESH + "api/education/student_user_extras/",
      {
        languages: arr,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setExtraData({ ...extraData, languages: arr });
          setOpenAddLanguages(false);
          alert.success(res.data.data.message);
          fetchPrecentage();
        } else {
          alert.error(res.data.error);
        }
        setSaveLoaderLanguage(false);
        // setCircularLoading(false);
      })
      .catch((err) => {
        setSaveLoaderLanguage(false);
        alert.error(err.message);
        console.log(err);
      });
  };

  const handleDeleteLanguage = (index) => {
    var array = [...extraData.languages];
    if (index > -1) {
      array.splice(index, 1);
    }
    setExtraData({ ...extraData, languages: array });
    deleteUserLanguage(array);
  };

  const deleteUserLanguage = (arr) => {
    Axios.patch(
      BackendBaseApi.PRAVESH + "api/education/student_user_extras/",
      {
        languages: arr,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          fetchPrecentage();
          alert.success(res.data.data.message);
        } else {
          alert.error(res.data.error);
        }
        // setCircularLoading(false);
      })
      .catch((err) => {
        alert.error()
        console.log(err);
      });
  };

  return (
    <>
      <div>
        {skillsLoader ? (
          <CircularProgress size={40} style={{ color: "#007bff", marginTop: 30, marginBottom: 30 }} />
        ) : (
          skillDetailsList?.length > 0 && (
            <>
              <Typography variant="body1" style={{ textAlign: "left", fontSize: 15, marginBottom: 10 }}>
                Skills
              </Typography>
              <Paper className={classes.paper}>
                <div>{skillDetailsList?.length > 0 && skillDetailsList.map((item) => <CommonChip label={item.skill_name} onDelete={() => handleDeleteSkill(item.skill_id)} />)}</div>
              </Paper>
            </>
          )
        )}
        {/* fix oncliking on values or onchanging make input blank */}
        <Autocomplete
          value={skills}
          openOnFocus
          onChange={(event, newValue) => {
            if (newValue) {
              setSkills(newValue)
              setSkillDetails({ ...skillDetails, singleSkillSelected: newValue });
              setButtonClicked(false);
            }
          }}
          id="role345"
          options={allSkills}
          getOptionLabel={(option) => option.skill_name ? option.skill_name : ""}
          renderOption={(option) => {
            return <span style={{ fontSize: 13 }}>{`${option.skill_name}`}</span>;
          }}
          className={classes.fieldStyling1}
          renderInput={(params) => {
            params.inputProps.className = classes.comboOptions;
            return <TextField {...params} label={<span style={{ fontSize: 13 }}>Add Skills</span>} variant="outlined" />;
          }}
        />
        <div style={{ display: "flex", justifyContent: "left" }}>
          {skillDetailsList?.length > 0 &&
            <Button onClick={updateUserSkills} style={{ color: "#007bff" }}
              disabled={skillDetails.singleSkillSelected || checkDisabling ? false : true}>
              {saveLoader ? <CircularProgress style={{ color: "#007bff" }} size={15} /> : "Save Skills"}
            </Button>
          }
        </div>
      </div>

      <Divider />

      <div style={{ marginTop: 20 }}>
        {linksLoader ? (
          <CircularProgress size={40} style={{ color: "#007bff", marginTop: 30, marginBottom: 30 }} />
        ) : (
          linkList?.length > 0 && (
            <div>
              <Typography variant="body1" style={{ textAlign: "left", fontSize: 15, marginBottom: 12 }}>
                Links
              </Typography>
              <Grid container spacing={2}>
                {linkList.map((item) => (
                  item.Value || item.value &&
                  <Grid item md={12} xs={12}>
                    <Paper className={classes.paper} style={{marginBottom: 0}}>
                      {/* <Typography variant="body1" style={{ textAlign: "left", fontSize: 13, color:"" }}>
                        {item.Name}
                      </Typography> */}
                      <div className={classes.flexBox} >
                        <Grid item xs={11}>
                          <Typography variant="body1" style={{ textAlign: "left", fontSize: 14, color: "#007bff" }}>
                            {item.Value || item.value}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <DeleteIcon
                            style={{ color: "#007bff", fontSize: 22, cursor: "pointer" }}
                            onClick={() => {
                              handleDeleteLink(item?.id);
                            }}
                          />
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </div>
          )
        )}
        <Typography variant="body1" style={{ textAlign: "left", fontSize: 15, marginBottom: 12, marginTop: 20 }}>
          Add Important Links
        </Typography>
        <Autocomplete
          value={skillDetails.singleLinkSelected}
          openOnFocus
          onChange={(event, newValue) => {
            if (newValue) {
              setSkillDetails({ ...skillDetails, singleLinkSelected: newValue });
              setSkillDetailsErrors({ ...skillDetailsErrors, singleLinkSelected: "" });
              setButtonClicked(false);
            }
          }}
          id="role345"
          options={activeSocialLinks}
          getOptionLabel={(option) => option.name ? option.name : ""}
          renderOption={(option) => {
            return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
          }}
          className={classes.fieldStyling1}
          renderInput={(params) => {
            params.inputProps.className = classes.comboOptions;
            return (
              <TextField
                {...params}
                label={
                  <span style={{ fontSize: 13 }}>
                    Select Link Type <span className={classes.RedStar}>*</span>
                  </span>
                }
                variant="outlined"
                error={skillDetailsErrors.singleLinkSelected !== "" ? true : false}
                helperText={
                  <span style={skillDetailsErrors.singleLinkSelected !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                    {skillDetailsErrors.singleLinkSelected}
                  </span>
                }
              />
            );
          }}
        />
      </div>
      <TextField
        error={skillDetailsErrors.link !== "" ? true : false}
        key="Email"
        id="standard-error-helper-text"
        variant="outlined"
        label={<span className={classes.labelField}>Link</span>}
        helperText={
          <span style={skillDetailsErrors.link !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
            {skillDetailsErrors.link !== "" ? skillDetailsErrors.link : "Link must begin with https://"}
          </span>
        }
        value={skillDetails.link}
        onChange={(e) => {
          setSkillDetails({ ...skillDetails, link: e.target.value });
          setSkillDetailsErrors({ ...skillDetailsErrors, link: "" });

          // setErrorMessages({ ...errorMessages, phone: "" });
          setButtonClicked(false);
        }}
        InputProps={{
          style: { fontSize: 13 },
        }}
        InputLabelProps={{
          style: { backgroundColor: "#fff" },
        }}
        className={classes.fieldStyling}
      />
      <div style={{ display: "flex", justifyContent: "left" }}>
        <Button onClick={validateLinks} style={{ color: "#007bff" }}>
          {saveLoaderLinks ? <CircularProgress style={{ color: "#007bff" }} size={15} /> : "Save Links"}
        </Button>
      </div>

      <Divider />

      <Typography variant="body1" style={{ textAlign: "left", fontSize: 15, marginBottom: 12, marginTop: 20 }}>
        Languages Known
      </Typography>
      {extraData?.languages?.length > 0 &&
        extraData.languages.map((item, index) => (
          <div>
            <LanguageCard data={item} index={index} handleDelete={handleDeleteLanguage} />
          </div>
        ))}
      <div>
        {!openAddLanguages && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#007bff",
            }}
            // onClick={() => {
            //   setOpenAddWorkExp(true);
            //   setWorkExpDetails({
            //     ...workExpDetails,
            //     job_title: "",
            //     company: null,
            //     job_type: null,
            //     start_date: null,
            //     end_date: null,
            //     description: "",
            //     currently_working: false,
            //     website: "",
            //     skills: [],
            //     singleSkill: null,
            //   });
            // }}
          >
            <IconButton
              style={{ color: "#007bff" }}
              onClick={() => {
                setOpenAddLanguages(true);
                setExtraData({ ...extraData, singleLanguage: null, level: [], proficiency: null });
              }}
            >
              <ControlPointIcon />
            </IconButton>
            <span
              onClick={() => {
                setOpenAddLanguages(true);
                setExtraData({ ...extraData, singleLanguage: null, level: [], proficiency: null });
              }}
            >
              Add Language
            </span>
          </div>
        )}

        {openAddLanguages && (
          <>
            <TextField
              key="Email"
              id="standard-error-helper-text"
              variant="outlined"
              label={<span className={classes.labelField}>Language <span style={{color: "Red"}}>*</span></span>}
              value={extraData.singleLanguage}
              onChange={(e) => {
                setExtraData({ ...extraData, singleLanguage: e.target.value });
                setButtonClicked(false);
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={{
                style: { backgroundColor: "#fff" },
              }}
              className={classes.fieldStyling}
            />
            <FormControl component="fieldset" style={{ display: "flex" }}>
              <FormLabel component="legend" style={{ textAlign: "left", fontSize: 15, paddingTop: 20 }}>
                Proficiency<span style={{color: "Red"}}>*</span> :
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="customized-radios"
                row
                value={extraData.proficiency}
                onChange={(e) => {
                  setExtraData({ ...extraData, proficiency: e.target.value });

                  setButtonClicked(false);
                }}
              >
                <FormControlLabel value={"Beginner"} control={<StyledRadio />} label={<span style={{ fontSize: 14 }}>Beginner</span>} />
                <FormControlLabel value={"Intermediate"} control={<StyledRadio />} label={<span style={{ fontSize: 14 }}>Intermediate</span>} />
                <FormControlLabel value={"Expert"} control={<StyledRadio />} label={<span style={{ fontSize: 14 }}>Expert</span>} />
              </RadioGroup>
            </FormControl>
            <FormGroup row>
              <FormLabel component="legend" style={{ textAlign: "left", fontSize: 15, paddingTop: 20 }}>
                Skill Level<span style={{color: "Red"}}>*</span> :
              </FormLabel>
              <FormControlLabel
                // value={state.Basics.internship_salary_type === "NOT DISCLOSED" ? true : false}
                style={{ marginLeft: 0 }}
                control={
                  <CustomizedCheckboxes
                    checked={extraData.level.includes("Read")}
                    onChange={(e) => {
                      var arr = [...extraData.level];

                      if (e.target.checked) {
                        arr.push("Read");
                        setExtraData({ ...extraData, level: arr });
                      } else {
                        const index = arr.indexOf("Read");
                        if (index > -1) {
                          arr.splice(index, 1);
                          setExtraData({ ...extraData, level: arr });
                        }
                      }
                    }}
                    name={"internship_salary_type"}
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    color="primary"
                  />
                }
                label={<span cstyle={{ fontSize: 14 }}>Read</span>}
              />
              <FormControlLabel
                // value={state.Basics.internship_salary_type === "NOT DISCLOSED" ? true : false}
                style={{ marginLeft: 0 }}
                control={
                  <CustomizedCheckboxes
                    checked={extraData.level.includes("Write")}
                    onChange={(e) => {
                      var arr = [...extraData.level];

                      if (e.target.checked) {
                        arr.push("Write");
                        setExtraData({ ...extraData, level: arr });
                      } else {
                        const index = arr.indexOf("Write");
                        if (index > -1) {
                          arr.splice(index, 1);
                          setExtraData({ ...extraData, level: arr });
                        }
                      }
                    }}
                    name={"internship_salary_type"}
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    color="primary"
                  />
                }
                label={<span cstyle={{ fontSize: 14 }}>Write</span>}
              />
              <FormControlLabel
                // value={state.Basics.internship_salary_type === "NOT DISCLOSED" ? true : false}
                style={{ marginLeft: 0 }}
                control={
                  <CustomizedCheckboxes
                    checked={extraData.level.includes("Speak")}
                    onChange={(e) => {
                      var arr = [...extraData.level];

                      if (e.target.checked) {
                        arr.push("Speak");
                        setExtraData({ ...extraData, level: arr });
                      } else {
                        const index = arr.indexOf("Speak");
                        if (index > -1) {
                          arr.splice(index, 1);
                          setExtraData({ ...extraData, level: arr });
                        }
                      }
                    }}
                    name={"internship_salary_type"}
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    color="primary"
                  />
                }
                label={<span cstyle={{ fontSize: 14 }}>Speak</span>}
              />
            </FormGroup>
            <div style={{ display: "flex", justifyContent: "left", marginTop: 10 }}>
              <Button
                onClick={() => updateUserLanguage()}
                style={{ color: "#007bff" }}
                // disabled={extraData.singleLanguage && extraData.proficiency && extraData.level.length > 0 ? false : true}
              >
                {saveLoaderLanguage ? <CircularProgress style={{ color: "#007bff" }} size={15} /> : "Save Language"}
              </Button>

              <Button
                onClick={() => setOpenAddLanguages(false)}
                style={{ color: "#007bff" }}
                // disabled={extraData.singleLanguage && extraData.proficiency && extraData.level.length > 0 ? false : true}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
      <Divider />

      <div>
        {extraData?.hobbies?.length > 0 && (
          <>
            <Typography variant="body1" style={{ textAlign: "left", fontSize: 15, margin: "10px 0px" }}>
              Hobbies
            </Typography>
            <Paper className={classes.paper}>
              <div>{extraData?.hobbies?.length > 0 && extraData?.hobbies.map((item, index) => <CommonChip label={item} onDelete={() => handleDeleteHobby(index)} />)}</div>
            </Paper>
          </>
        )}
        {!openAddHobbies && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#007bff",
            }}
            // onClick={() => {
            //   setOpenAddWorkExp(true);
            //   setWorkExpDetails({
            //     ...workExpDetails,
            //     job_title: "",
            //     company: null,
            //     job_type: null,
            //     start_date: null,
            //     end_date: null,
            //     description: "",
            //     currently_working: false,
            //     website: "",
            //     skills: [],
            //     singleSkill: null,
            //   });
            // }}
          >
            <IconButton
              style={{ color: "#007bff" }}
              onClick={() => {
                setOpenAddHobbies(true);
                setExtraData({ ...extraData, singleHobby: null });
              }}
            >
              <ControlPointIcon />
            </IconButton>
            <span
              onClick={() => {
                setOpenAddHobbies(true);
                setExtraData({ ...extraData, singleHobby: null });
              }}
            >
              Add Hobbies
            </span>
          </div>
        )}

        {openAddHobbies && (
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={9}>
              <TextField
                // error={skillDetailsErrors.link !== "" ? true : false}
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                label={<span className={classes.labelField}>Enter your hobby</span>}
                // helperText={
                //   <span style={skillDetailsErrors.link !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                //     {skillDetailsErrors.link !== "" ? skillDetailsErrors.link : "Link must begin with https://"}
                //   </span>
                // }
                value={hobbies}
                onChange={(e) => {
                  setHobbies(e.target.value)
                  setExtraData({ ...extraData, singleHobby: e.target.value});
                  setButtonClicked(false);
                }}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={{
                  style: { backgroundColor: "#fff" },
                }}
                className={classes.fieldStyling}
              />
            </Grid>
            <Grid item xs={3}>
              <CommonButton
                variant="outlined"
                disabled={extraData.singleHobby ? false : true}
                onClick={() => {
                  var arr = [...extraData.hobbies];
                  arr.push(extraData.singleHobby);
                  setExtraData({ ...extraData, hobbies: arr });
                  setHobbies('')
                }}
              >
                Add
              </CommonButton>
            </Grid>
          </Grid>
        )}
        {extraData.hobbies && (
          <div style={{ display: "flex", justifyContent: "left", marginTop: 10 }}>
            <Button onClick={() => updateUserHobbies(extraData.hobbies)} style={{ color: "#007bff" }} disabled={extraData.hobbies.length === 0 ? true : false}>
            {saveLoaderHobbies ? <CircularProgress style={{ color: "#007bff" }} size={15} /> : "Save Hobbies"}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default SkillsDetails;

import React from "react";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import tick from "../../../assets/svg/Tick.svg";
import close from "../../../assets/svg/Close.svg";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  card: {
    border: "2px solid #C4C4C4",
    boxShadow: "none",
    borderRadius: "15px",
    "&:hover": {
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    },
  },

  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },

  cardPricing: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  plantable: {
    display: "flex",
    alignItems: "center ",
    marginBottom: "10px",
  },
}));

const tickIcon = <img src={tick} height="15px" />;
const closeIcon = <img src={close} height="15px" />;

export default function ProfessionalPaln() {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.cardPricing}>
            <Typography
              component="h6"
              variant="h5"
              color="textPrimary"
              style={{
                marginBottom: "10px",
                fontWeight: "normal",
                marginTop: "20px",
              }}
            >
              Professional
            </Typography>
            <Typography variant="h2">
              ₹8,000{" "}
              <span
                style={{
                  color: "#606F7B",
                  fontSize: "14px",
                }}
              >
                /Month
              </span>
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: "#606F7B",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Pay yearly to get special price:{" "}
              <span style={{ textDecorationLine: "line-through" }}>
                ₹96,000
              </span>
              <span
                style={{
                  color: "#000",
                  marginLeft: "5px",
                }}
              >
                ₹80,000
              </span>
            </Typography>
          </div>

          <Button
            fullWidth
            variant="outlined"
            color="primary"
            style={{
              borderRadius: "30px",
              fontSize: "14px",
              height: "45px",
              display: "flex",
              marginBottom: "23px",
            }}
          >
            <Typography variant="h6" style={{ fontWeight: "300" }}>
              {" "}
              Get Started{" "}
            </Typography>
          </Button>

          <ul>
            <li className={classes.plantable}>
              {tickIcon}
              <Typography
                variant="body2"
                style={{ marginLeft: "10px", color: "#606F7B" }}
              >
                Unlimited Job Posting
              </Typography>
            </li>
            <li className={classes.plantable}>
              {tickIcon}
              <Typography
                variant="body2"
                style={{ marginLeft: "10px", color: "#606F7B" }}
              >
                Unlimited Internship Posting
              </Typography>
            </li>
            <li className={classes.plantable}>
              {tickIcon}
              <Typography
                variant="body2"
                style={{ marginLeft: "10px", color: "#606F7B" }}
              >
                4-5 Good for hire per month
              </Typography>
            </li>
            <li className={classes.plantable}>
              {tickIcon}
              <Typography
                variant="body2"
                style={{ marginLeft: "10px", color: "#606F7B" }}
              >
                ₹ 1600 per hire
              </Typography>
            </li>
            <li className={classes.plantable}>
              {closeIcon}
              <Typography
                variant="body2"
                style={{ marginLeft: "10px", color: "#606F7B" }}
              >
                No Test Pins
              </Typography>
            </li>
            <li className={classes.plantable}>
              {closeIcon}
              <Typography
                variant="body2"
                style={{ marginLeft: "10px", color: "#606F7B" }}
              >
                No KAM{" "}
              </Typography>
            </li>
          </ul>
        </CardContent>
      </Card>
    </>
  );
}

import React, { useRef } from "react";
import { useAlert } from "react-alert";
import "./FormComponents.css";
import GetAppIcon from "@material-ui/icons/GetApp";

function ResumeUpload({ setResume }) {
  const alert = useAlert();

  const fileInputRef = useRef();

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    if (file.length === 1) {
      const ext = file[0].name.substring(file[0].name.lastIndexOf(".") + 1);
      // if (ext !== 'pdf' && ext !== 'doc' && ext !== 'docx') {
      //     alert.error('Only PDF & DOC Format Allowed!');
      // }
      // else {
      if (file[0].size < 2097152) setResume(file[0]);
      else alert.error("File Size Limit: 2MB");
      // }
    } else {
      alert.error("Drag only one File");
    }
  };

  const fileInputClicked = (e) => {
    fileInputRef.current.click();
  };

  return (
    <div id="public_job_apply_resume_upload" onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={fileDrop} onClick={(e) => fileInputClicked(e)}>
      <input
        ref={fileInputRef}
        className="file-input"
        type="file"
        id="file"
        onChange={(e) => {
          if (!e.target.files[0]) return;
          const ext = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".") + 1);
          if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
            alert.error("Only PDF & DOC Format Allowed!");
            return;
          }
          if (e.target.files[0].size < 2097152) setResume(e.target.files[0]);
          else alert.error("File Size Limit: 2MB");
        }}
      />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
        <div style={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
          <GetAppIcon /> <span>Drag & Drop your resume here</span>
        </div>
        <br />
        <span>
          or <b style={{ color: "#3282C4" }}>Browse</b> to upload
        </span>
      </div>
    </div>
  );
}

export default ResumeUpload;

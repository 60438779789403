import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CssTextField from "./FormComponents/CssTextField";
import { useSelector } from "react-redux";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { JobRolesDescription } from "./FormComponents/JobSegment";
import { BackendBaseApi } from "../../../constants/constants";
import Axios from "axios";
import RichTextEditor from "react-rte";
export default function JobDescriptionDialog(props) {
  const {
    open,
    handleClickOpen,
    handleClose,

    state,
    setState,
  } = props;
  const classes = useStyles();
  const jobRoleList = useSelector((state) => state.JobRoleList.JobRoleList);
  const [currentJobRole, setCurrentJobRole] = useState();
  const [jdData, setJDData] = useState(RichTextEditor.createEmptyValue());
  const [mainJD, setMainJD] = useState("");
  const [applyClicked, setApplyClicked] = useState(false);
  const [tempelateAvailable, setTempelateAvailable] = useState(true);
  const getJobDescription = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/search/job_description/?job_role_id=" + currentJobRole.id, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (Object.keys(res.data.data).length > 0) {
            var new_data =
              "<h4>" +
              String(res.data.data.job_title) +
              "</h4>" +
              "<h4>" +
              String(res.data.data.job_role_name) +
              "</h4>" +
              "<p>" +
              String(res.data.data.brief) +
              String(res.data.data.description) +
              String(res.data.data.requirements) +
              "</p>";
            setJDData(RichTextEditor.createValueFromString(new_data, "html"));
            setMainJD(new_data);
          } else setTempelateAvailable(false);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setTempelateAvailable(true);
    if (currentJobRole !== undefined) getJobDescription();
  }, [currentJobRole]);

  useEffect(() => {
    if (applyClicked)
      setState({
        ...state,
        Details: {
          ...state.Details,
          job_description: mainJD,
          raw_job_description: jdData,
        },
      });
  }, [applyClicked]);
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Job Description Template</DialogTitle>
        <DialogContent>
          <DialogContentText>Choose the job role for which you want to edit the template</DialogContentText>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <JobRolesDescription
              jobRoleList={jobRoleList}
              width={classes.InputWidth}
              responsive={classes.labelSize}
              currentJobRole={currentJobRole}
              setCurrentJobRole={setCurrentJobRole}
              MenuSize={classes.MenuSize}
            />
          </Box>
          <br />
          {currentJobRole && (
            <>
              {tempelateAvailable ? (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <RichTextEditor
                    className={classes.mainEditor}
                    toolbarClassName={classes.editorToolbar}
                    editorClassName={classes.editor}
                    value={jdData}
                    style={{
                      maxHeight: "100px",
                      overflowY: "scroll",
                      width: "80%",
                      borderRadius: 30,
                    }}
                    onChange={(value) => {
                      var new_ = value.toString("html");
                      setMainJD(new_);
                      setJDData(value);
                    }}
                  />
                </Box>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    marginTop: "-18px",
                    fontWeight: "bold",
                  }}
                >
                  No Template Available for this Job Role
                </p>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "#D64045",
              color: "#ffffff",
              borderRadius: 30,
            }}
            onClick={(e) => {
              handleClose();
            }}
          >
            <span className={classes.labelSize}>Cancel</span>
          </Button>
          {tempelateAvailable && (
            <Button
              variant="contained"
              style={
                currentJobRole
                  ? {
                      height: "36px",
                      borderRadius: 30,
                    }
                  : {
                      height: "36px",
                      borderRadius: 30,
                      backgroundColor: "grey",
                      color: "white",
                      cursor: "not-allowed",
                    }
              }
              disabled={currentJobRole ? false : true}
              onClick={() => {
                setApplyClicked(true);
                handleClose();
              }}
            >
              <span className={classes.labelSize}>Select this template</span>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    InputWidth: {
      width: "70%",
    },
    labelSize: {
      fontSize: "15px",
    },
    MenuSize: {
      fontSize: "13px",
    },
    editor: {
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#fff",
      color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
      maxHeight: 450,
      minHeight: 400,
      overflowY: "scroll",
      borderRadius: 30,
      width: "80%",
    },

    mainEditor: {
      // border: "0px hidden",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#fff",
      borderRadius: 30,
      width: "80%",
    },
    editorToolbar: {
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#fff",
      borderRadius: 30,
    },
    "@media only screen and (max-width: 1100px)": {
      labelSize: {
        fontSize: "12px",
      },
      MenuSize: {
        fontSize: "12px",
      },
    },
    "@media only screen and (max-width: 900px)": {
      InputWidth: {
        width: "100%",
      },
      editor: {
        width: "100%",
      },
      mainEditor: {
        width: "100%",
      },
    },
  })
);

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import SelectSearch from "react-select-search";

import { BackendBaseApi } from "../../../../constants/constants";
import Loader from "../../../common/components/UI/Loader";

const CreateCompany = ({
  companyUserDetails,
  setCompanyUserDetails,
  step,
  setStep,
}) => {
  //UI state variabes
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  // const showCoworkingField = useRef();
  const disableButton = useRef();
  const history = useHistory();

  const [jobRolesList, setJobRolesList] = useState({});
  const [industryList, setIndustryList] = useState({});
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [coworkingCityList, setCoworkingCityList] = useState({});
  const [coworkingList, setCoworkingList] = useState({});
  const [showCoworking, setShowCoworking] = useState(false);

  const [jobRole, setJobRole] = useState("");
  const [industry, setIndustry] = useState("");
  const [coworking, setCoworking] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [coworkingState, setCoworkingState] = useState("");
  const [coworkingCity, setCoworkingCity] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [logo, setLogo] = useState("");
  const [companyIndustries, setCompanyIndustries] = useState([]);

  const [companyDetails, setCompanyDetails] = useState({
    CompanyName: "",
    CompanyLogo: "",
    CompanyWebsite: "",
    CompanyDescription: "",
  });

  let jobRoles = [],
    //companyIndustries = [],
    companyStates = [],
    companyCities = [],
    companyCoworking = [],
    coworkingCities = [],
    companySizes = [
      { name: "1-10", value: "1-10" },
      { name: "11-50", value: "11-50" },
      { name: "51-100", value: "51-100" },
      { name: "101-1000", value: "101-1000" },
      { name: "1000+", value: "1000+" },
    ];
  jobRoles = Object.keys(jobRolesList).map((jobrole) => {
    return { name: jobrole, value: jobrole };
  });
  // companyIndustries = Object.keys(industryList).map((ind) => {
  //   return { name: ind, value: ind };
  // });
  companyStates = Object.keys(stateList).map((stateData) => {
    return { name: stateData, value: stateData };
  });
  companyCities = Object.keys(cityList).map((cityData) => {
    return { name: cityData, value: cityData };
  });
  companyCoworking = Object.keys(coworkingList).map((coworkingData) => {
    return { name: coworkingData, value: coworkingData };
  });
  coworkingCities = Object.keys(coworkingCityList).map((coworkingCityData) => {
    return { name: coworkingCityData, value: coworkingCityData };
  });

  useEffect(() => {
    if (industryList) {
      let arr = Object.keys(industryList).map((ind) => {
        return { name: ind, value: ind };
      });
      setCompanyIndustries(arr);
    }
  }, [industryList]);

  const TypeAndSearchIndustry = () => (
    <SelectSearch
      options={companyIndustries}
      search={true}
      value={industry}
      onChange={setIndustry}
      placeholder="Type and Search your Company Industry from the list"
    />
  );

  const TypeAndSearchCoWorking = () => (
    <SelectSearch
      options={companyCoworking}
      search
      autoComplete="on"
      value={coworking}
      onChange={setCoworking}
      placeholder="Type and Search your Co-working from the list"
    />
  );

  const TypeAndSearchState = () => (
    <SelectSearch
      options={companyStates}
      search
      autoComplete="on"
      value={companyState}
      onChange={setCompanyState}
      placeholder="Select your state"
    />
  );

  const TypeAndSearchCity = () => (
    <SelectSearch
      options={companyCities}
      search
      autoComplete="on"
      value={companyCity}
      onChange={setCompanyCity}
      placeholder="Select your city"
    />
  );
  const TypeAndSearchCoworkingState = () => (
    <SelectSearch
      options={companyStates}
      search
      autoComplete="on"
      value={coworkingState}
      onChange={setCoworkingState}
      placeholder="Select your state"
    />
  );

  const TypeAndSearchCoworkingCity = () => (
    <SelectSearch
      options={coworkingCities}
      search
      autoComplete="on"
      value={coworkingCity}
      onChange={setCoworkingCity}
      placeholder="Select your city"
    />
  );

  const CompanySizeDropdown = () => (
    <SelectSearch
      options={companySizes}
      value={companySize}
      onChange={setCompanySize}
      placeholder="Select Company Size"
    />
  );

  const JobRolesDropdown = () => (
    <SelectSearch
      options={jobRoles}
      value={jobRole}
      onChange={setJobRole}
      placeholder="Select your Job Role"
      search
      autoComplete="on"
    />
  );

  const getIndustryList = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/industry/")
      .then((res) => {
        let temp = {};
        let i = 0;
        res.data.data.forEach((data) => {
          temp[data.industry_name] = data.id;
        });

        setIndustryList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCoworkingList = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/coworking/")
      .then((res) => {
        let temp = {};
        res.data.forEach((data) => {
          temp[data.name] = data.id;
        });
        setCoworkingList({ ...temp });
        //setStateList({...temp});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStateList = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/location/state/")
      .then((res) => {
        let temp = {};
        res.data.forEach((data) => {
          temp[data.state] = data.state_id;
        });

        setStateList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobRoles = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/user_type/?main_user=company")
      .then((res) => {
        let temp = {};
        res.data.data.forEach((data) => {
          temp[data.sub_user] = data.id;
        });
        setJobRolesList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCityList = () => {
    axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/location/city/?search=" +
          stateList[companyState]
      )
      .then((res) => {
        let temp = {};
        res.data.forEach((data) => {
          temp[data.city] = data.city_id;
        });

        setCityList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getcoworkingCityList = () => {
    axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/location/city/?search=" +
          stateList[coworkingState]
      )
      .then((res) => {
        let temp = {};
        res.data.forEach((data) => {
          temp[data.city] = data.city_id;
        });

        setCoworkingCityList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      companyDetails.CompanyName &&
      companyDetails.CompanyDescription &&
      companyDetails.CompanyWebsite &&
      companyCity &&
      companyState &&
      companySize
    ) {
      disableButton.current.classList.remove("disabled");
    } else disableButton.current.classList.add("disabled");
  }, [companyDetails, jobRole, companyState, companyCity, companySize]);

  useEffect(() => {
    getIndustryList();
    getCoworkingList();
    getStateList();
    getJobRoles();

    // disableButton.current.classList.add("disabled");
    //set display none of coworking field
    //showCoworkingField.current.classList.add('d-none');
  }, []);

  useEffect(() => {
    if (companyState !== "") {
      getCityList();
      setCompanyCity("");
    }
  }, [companyState]);

  useEffect(() => {
    if (coworkingState) getcoworkingCityList();
  }, [coworkingState]);

  const validateForm = () => {
    if (
      !companyDetails.CompanyName ||
      !companyDetails.CompanyDescription ||
      !companyState ||
      !companyCity ||
      !companyDetails.CompanyWebsite ||
      !companySize
    ) {
      disableButton.current.classList.remove("disabled");
      return false;
    }
    return true;
  };

  const sendData = () => {
    const v1 = validURL(companyDetails.CompanyWebsite);
    if (!validateForm()) return;

    setLoading(true);
    let cw_id = null,
      cw_name = null,
      cw_state = null,
      cw_city = null,
      cw_country = null;
    let isCoworking = coworking ? true : false;
    if (isCoworking) {
      if (showCoworking) {
        cw_name = coworking;
        cw_state = stateList[coworkingState];
        cw_city = coworkingCityList[coworkingCity];
        cw_country = 1;
      } else {
        cw_id = coworkingList[coworking];
      }
    }
    let fd = new FormData();

    let data = {
      user: companyUserDetails.CompanyUserID,
      job_title: companyUserDetails.JobTitle,
      preferences: {
        job_segment: companyUserDetails.CandidatePreferences,
        job_type: companyUserDetails.JobPreferences,
      },
      is_third_party: companyUserDetails.isThirdPartyRecruiter,
      company_name: companyDetails.CompanyName,
      company_description: companyDetails.CompanyDescription,
      industry: industryList[industry],
      country: 1,
      state: stateList[companyState],
      city: cityList[companyCity],
      is_coworking: isCoworking,
      coworking_id: cw_id,
      coworking_name: cw_name,
      // company_logo: logo,
      company_website: companyDetails.CompanyWebsite,
      company_size: companySize,
      job_role: 5,
      college_id: companyUserDetails.CollegeID
        ? companyUserDetails.CollegeID
        : null,
      college_name: companyUserDetails.CollegeName
        ? companyUserDetails.CollegeName
        : null,
      graduation_year: companyUserDetails.GraduatingYear
        ? companyUserDetails.GraduatingYear
        : null,
      referral_code: companyUserDetails.RefferalCode
        ? companyUserDetails.RefferalCode
        : null,
    };

    for (let dataKey in data) {
      if (dataKey === "preferences") {
        // append nested object
        for (let previewKey in data[dataKey]) {
          fd.append(`preferences[${previewKey}]`, data[dataKey][previewKey]);
        }
      } else {
        fd.append(dataKey, data[dataKey]);
      }
    }

    if (v1 === true) {
      axios
        .post(BackendBaseApi.PRAVESH + "api/company/company_user/", data)
        .then((res) => {
          setLoading(false);

          if (res.data.success) {
            alert.success(res.data.data.message);
            localStorage.setItem("gw_token", companyUserDetails.Token);
            localStorage.setItem("user_type_id", 5);

            localStorage.setItem("user_type", "Company");

            history.push("/company/dashboard");
          } else {
            alert.error(res.data.error);
            return;
          }
        })
        .catch((err) => {
          localStorage.clear();
          alert.error("Invalid Session!! Please try again");
        });
    } else {
      alert.error(
        "Enter Valid Website URL. Plese Use HTTP HTTPS Protocol in the URL as well."
      );
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setCompanyDetails({ ...companyDetails, [e.target.id]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    sendData();
  };

  const handleImageUpload = (e) => {
    setLogo(e.target.files[0]);
  };

  function validURL(str) {
    var pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return !!pattern.test(str);
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="row pl-3">
        <h1 className="mb-4 login-heading text-left">
          Create your Company Profile
        </h1>
      </div>

      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
        className="my-md-0 my-2"
      >
        <div className="form-group required text-left">
          <label htmlFor="CompanyName" className="fw-500 control-label">
            Company Name
          </label>
          <input
            type="text"
            className="form-control mt-2 mb-1 shadow_1-lightest"
            id="CompanyName"
            aria-describedby="emailHelp"
            placeholder=""
            required
          />
        </div>

        {/* <div className="form-group text-left my-3">
                    <label for="CompanyLogo" className="fw-500 control-label">
                        Company Logo
                    </label>
                    <input
                        type="file"
                        className="form-control-file mt-2 mb-1 "
                        id="CompanyLogo"
                        onChange={(e) => handleImageUpload(e)}
                    />
                </div> */}
        <div className="form-group required text-left my-3">
          <label htmlFor="Location" className="fw-500 control-label">
            Company Location
          </label>
          <div className="row">
            <div className="col-6">
              {/* <TypeAndSearchState className="form-control shadow_1-lightest" /> */}
              <SelectSearch
                options={companyStates}
                search
                value={companyState}
                onChange={setCompanyState}
                placeholder="Select your state"
              />
            </div>
            <div className="col-6">
              {/* <TypeAndSearchCity className="form-control shadow_1-lightest" /> */}
              <SelectSearch
                options={companyCities}
                search
                value={companyCity}
                onChange={setCompanyCity}
                placeholder="Select your city"
              />
            </div>
          </div>
        </div>

        <div className="form-group required text-left my-4">
          <label className="fw-500 control-label">Company Industry</label>
          {/* <TypeAndSearchIndustry /> */}
          <SelectSearch
            options={companyIndustries}
            search={true}
            value={industry}
            onChange={setIndustry}
            placeholder="Type and Search your Company Industry from the list"
          />
        </div>

        <div className="form-group text-left my-3">
          {showCoworking ? (
            <>
              <label htmlFor="CompanyCoWorking" className="fw-500">
                Mention your Company Co-Working
              </label>
              <input
                type="text"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="CompanyCoWorking"
                onChange={(e) => {
                  setCoworking(e.target.value);
                }}
                placeholder=""
                required
              />
            </>
          ) : (
            <>
              <label className="fw-500">Choose your Company Co-Working</label>
              {/* <TypeAndSearchCoWorking className="form-control shadow_1-lightest" /> */}
              <SelectSearch
                options={companyCoworking}
                search
                autoComplete="on"
                value={coworking}
                onChange={setCoworking}
                placeholder="Type and Search your Co-working from the list"
              />
            </>
          )}
          <div className="form-check text-left ">
            <input
              type="checkbox"
              checked={showCoworking}
              onChange={(e) => {
                setShowCoworking(!showCoworking);
              }}
              className="form-check-input"
              id="coworking-checkbox"
            />
            <label className="form-check-label" htmlFor="coworking-checkbox">
              <small id="emailHelp" className="form-text my-1 fw-500">
                My coworking is not in the list
              </small>
            </label>
          </div>
        </div>

        <div className="form-group required text-left my-3">
          <label htmlFor="CompanyWebsite" className="fw-500 control-label">
            Website
          </label>
          <input
            type="text"
            className="form-control mt-2 mb-1 shadow_1-lightest"
            id="CompanyWebsite"
            placeholder=""
            required
          />
        </div>

        <div className="form-group required text-left my-3">
          <label htmlFor="CompanyDescription" className="fw-500 control-label">
            Description
          </label>
          <textarea
            className="form-control mt-2 mb-1 shadow_1-lightest"
            id="CompanyDescription"
            rows="3"
            required
          ></textarea>
        </div>
        <div className="form-group required text-left my-3">
          <label htmlFor="CompanySize" className="fw-500 control-label">
            Company Size
          </label>
          {/* <input type="text" className="form-control mt-2 mb-1 shadow_1-lightest" id="CompanySize" placeholder="" required /> */}
          <CompanySizeDropdown className="form-control shadow_1-lightest" />
        </div>

        <div className="row my-1 pt-3">
          <div className="col-md-4 col-sm-12 text-center">
            <button
              className=" shadow_1 btn btn-lg btn-main btn-login btn-next text-uppercase font-weight-bold mb-2"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <span>Back</span>
            </button>
          </div>
          <div className="col-md-8 col-sm-12 text-center">
            <button
              ref={disableButton}
              style={{ width: "100%" }}
              className=" shadow_1 btn btn-lg btn-main btn-login btn-next text-uppercase font-weight-bold mb-2"
              type="submit"
            >
              <span>Create New Company</span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateCompany;

import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailedCtc } from "../action";
import ReportsControlPanel from "../ReportsControlPanel";
import ReportsTable from "../ReportsTable";

const CtcReport = () => {
  const dispatch = useDispatch();

  const ctcData = useSelector((state) => state.Reports.detailedCtc);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (Object.keys(ctcData).length === 0) {
      dispatch(getDetailedCtc());
    }
  }, []);

  const handleTableData = () => {
    let arr = [];
    ctcData.placement_data.map((item) =>
      item.placement_data.map(
        (item2) =>
          arr.push({
            course: item2.degree_name,
            company_name: item.company_name,
            branch: item2.specialization_name,
            hired: item2.hired,
            ctc_max: item2.ctc_max_value,
            ctc_avg: item2.ctc_avg_value,
          })
      )
    );

    setTableData(arr);
  };

  useEffect(() => {
    if (ctcData.placement_data) {
      handleTableData();
    }
  }, [ctcData]);

  return (
    <Box
      padding="2%"
      width="100%"
      style={{ maxHeight: "100%", overflowY: "scroll" }}
    >
      <ReportsControlPanel
        // buttons={["Show Chart", "Export", "Send Email"]}
        buttons={["Export"]}
        title="CTC Report"
        tableData={tableData}
      />
      <p>Summary Report</p>
      <ReportsTable
        columns={["Total Hired", "Min CTC*", "Max CTC*", "Avg. CTC*"]}
        data={[ctcData]}
      />
      <ReportsTable
        columns={[
          "Company name",
          "Course",
          "Branch",
          "Hired",
          "Max CTC*",
          "Avg. CTC*",
        ]}
        data={tableData}
      />
    </Box>
  );
};

export default CtcReport;

import { Box } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import NoJobs from "../../../assets/images/no-jobs.png";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/httpRequest";
import Layout from "../../../components/college/Layout/Layout";
import InterviewCard from "./InterviewCard";
import TrackPaneInterview from "./TrackPaneInterview";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";

const Interview = () => {
  const [jobData, setJobData] = useState([]);
  const data = JSON.parse(localStorage.getItem("college_id"));
  const [end, setEnd] = useState(false);
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("All");
  const [onlyClosed, setOnlyClosed] = useState(false);
  const [campusType, setCampusType] = useState(true);
  const [allJobProfiles, setAllJobProfiles] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("All");
  const [jobProfile, setJobProfile] = useState("All");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("gw_token");
    const checkJobProfile = () => {
      if (jobProfile === "All") {
        return "";
      } else {
        return `&job_id=${btoa(jobProfile)}`;
      }
    };

    const checkJobType = () => {
      if (selectedJobType === "All") {
        return "";
      } else {
        return `&job_type_id=${btoa(selectedJobType)}`;
      }
    };

    const checkCollege = () => {
      if (selectedCompany === "All") {
        return "";
      } else {
        return `&company_id=${btoa(selectedCompany)}`;
      }
    };

    const checkOnlyClosed = () => {
      if (!onlyClosed) {
        return "";
      } else {
        return `&job_status=${btoa("CLOSE")}`;
      }
    };

    // const checkJobStatus = () => {
    //   return `&status=${btoa(activeTab)}`;
    // };

    const checkCampusType = () => {
      if (!campusType) {
        return `&hiring_type=${btoa("OFF CAMPUS")}`;
      } else {
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      }
    };

    // setLoading(true);
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/college/manage_interview/?college_id=${window.btoa(
        data
      )}${checkJobType()}${checkJobProfile()}${checkCollege()}${checkOnlyClosed()}${checkCampusType()}`,
      { headers: token },
      setJobData
    );
  }, [selectedJobType, jobProfile, selectedCompany, onlyClosed, campusType]);
  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setLoading(true);
    let res = await httpRequest(baseUrl, endPoint, body);

    if (res.data.next === null) {
      setEnd(true);
      updateState(res.data.results);
    } else {
      setNewURL(res.data.next.slice(0, 20));
      setNewEndPoint(res.data.next.slice(20));
      updateState(jobData.concat([...res.data.results]));
    }

    setJobData(res.data.results);
    setLoading(false);
  };

  const getJobProfiles = async () => {
    const appendParam = () => {
      if (selectedJobType === "All") {
        return "";
      } else return `&job_type_id=${btoa(selectedJobType)}`;
    };
    // const appendParam2 = () => {
    //   if (selectedCompany === "All") {
    //     return "";
    //   } else return `&company_id=${btoa(selectedCompany)}`;
    // };

    const checkOnlyClosed = () => {
      if (!onlyClosed) {
        return "";
      } else {
        return `&job_status=${btoa("CLOSE")}`;
      }
    };

    const checkCampusType = () => {
      if (!campusType) {
        return `&hiring_type=${btoa("OFF CAMPUS")}`;
      } else {
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      }
    };

    // const checkJobStatus = () => {
    //   return `&status=${btoa(activeTab)}`;
    // };

    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/job/list/?college_id=${btoa(
          JSON.parse(localStorage.getItem("college_id"))
        )}${appendParam()}${checkOnlyClosed()}${checkCampusType()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });

    let profiles = [];
    let obj = { job_id: "All", job_title: "All" };

    setAllJobProfiles([obj, ...res.data.data]);
  };

  const getJobTypes = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch(
      (err) => {
        throw err;
      }
    );
    let obj = { id: "All", job_type: "All" };
    setJobType([obj, ...res.data.data]);
  };

  const getCompanies = async () => {
    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/get_companies/?college_id=${btoa(
          localStorage.getItem("college_id")
        )}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    let obj = { company_name: "All", company_id: "All" };
    setAllCompanies([obj, ...res.data.data]);
  };

  useEffect(() => {
    getJobProfiles();
    getJobTypes();
    getCompanies();
  }, []);

  useEffect(() => {
    getJobProfiles();
  }, [selectedJobType, selectedCompany, onlyClosed, campusType]);

  const handleScroll = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        const token = localStorage.getItem("gw_token");
        GetData(newURL, newEndPoint, { headers: token }, setJobData);
      }
    }
  };

  useEffect(() => {
    setJobProfile("All");
    // setSelectedCompany("All");
    getCompanies();
    setSelectedCompany("All");
  }, [campusType]);

  return (
    <>
      <div
        className="scrollY"
        style={{
          height: "90vh",
        }}
        onScroll={handleScroll}
      >
        <div style={{display: 'flex',
flexDirection: 'column',
alignItems: 'center', }}>
          <TrackPaneInterview
            profiles={allJobProfiles}
            jobProfile={jobProfile}
            jobTypes={jobType}
            selectedJobType={selectedJobType}
            setSelectedJobType={setSelectedJobType}
            setJobProfile={setJobProfile}
            allCompanies={allCompanies}
            setSelectedCompany={setSelectedCompany}
            onlyClosed={onlyClosed}
            setOnlyClosed={setOnlyClosed}
            campusType={campusType}
            setCampusType={setCampusType}
          /></div>
          
          <div style={{paddingLeft:"6%", paddingRight:"6%", marginTop:"100px" }}>
          {jobData.length > 0 ? (
            jobData.map((item, index) => (
              <>
             
                <InterviewCard
                  data={item}
                  index={index}
                  onlyClosed={onlyClosed}
                />
               
                <br />
              </>
            ))
          ) : loading ? (
            <Box>
              <JobCardSkeleton />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop="5%"
              paddingBottom="5%"
            >
              <img src={NoJobs} width="341px" height="auto" />
              <p
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                }}
              >
                No Interviews
              </p>
            </Box>
          )}
          {loading && (
            <Box>
              <JobCardSkeleton />
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default Interview;

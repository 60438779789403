import React, { useEffect, useState } from "react";
import Header from "../../../components/public/Profile/Header";
import { Backdrop, CircularProgress, Container, makeStyles } from "@material-ui/core";
import { useAlert } from "react-alert";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useHistory, useLocation } from "react-router-dom";
import Navbar from "../../../components/public/Navbar/Navbar";
import Footer from "../../../components/public/FooterNew/Footer";
import { useSelector } from "react-redux";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";

function Index() {
  const [resume, setResume] = useState(null);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const updateStudentResume = (resumeFile) => {
    setLoading(true);

    let personalData = new FormData();
    personalData.append("user", localStorage.getItem("user_id"));

    resumeFile && personalData.append("resume", resumeFile);
    Axios.post(BackendBaseApi.PRAVESH + `api/education/student_details`, personalData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (resume) {
            // let student = JSON.parse(localStorage.getItem("user_details"));
            // student["student_user_details"]["resume"] = res.data.data.data.resume;
            // localStorage.setItem("user_details", JSON.stringify(student));
            alert.success("Resume upload successful!!");
            localStorage.setItem("is_student_resume_uploaded", true);
            setLoading(false);
            if (location.search && location.search.includes("?source")) history.push(`${location.search.replace("?source=", "")}`);
            else history.push(`/student/complete-profile`);
          }
          // alert.success('Resume Uploaded Successfully !!');
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Some Error Occured. Please try again later");
      });
  };

  useEffect(() => {
    if (resume) {
      updateStudentResume(resume);
    }
  }, [resume]);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      {domain?.domain ? <DomainNavbar /> : <Navbar />}
      <Container maxWidth="lg" style={{ marginTop: 50, marginBottom: 40 }}>
        <Header resume={resume} setResume={setResume} loading={loading} />
      </Container>
    </>
  );
}

export default Index;

import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Fade from "@material-ui/core/Fade";
import Icon from "@material-ui/core/Icon";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import {
  createStyles,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import { ArrowRightAlt } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { loadCSS } from "fg-loadcss";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ExpiredCredit from "../../../../assets/icon/expired-credit.png";
import BurnedCredits from "../../../../assets/icon/invoice.png";
import AvailableCredits from "../../../../assets/icon/receive-amount.png";
import NoJobs from "../../../../assets/images/no-jobs.png";
import gifLoader from "../../../../assets/payment-loading.gif";
import { BackendBaseApi, emailRegex } from "../../../../constants/constants";
import {
  getTransactionHistory,
  updateCreditInfo,
} from "../../../../GlobalComponents/CreditBoard/action";
import New_gate from "../../../../GlobalComponents/RechargeDialog/New_gate";
import Payment_sucess from "../../../../GlobalComponents/RechargeDialog/Payment_sucess";
import RechargeDialog from "../../../../GlobalComponents/RechargeDialog/RechargeDialog";
import CommonButton from "../../../common/CommonButton/CommonButton";
import CssTextField from "../../../common/JobPostNew/FormComponents/CssTextField";
import theme2 from "../../../common/JobPostNew/FormComponents/MuiOutlined";
import CompanyVerificationDialog from "../../Dialogs/CompanyVerificationDialog";
import { getCompanyUserDetails } from "../action";
import styles from "./companySettingsCSS";
import PreferencesNew from "./PreferencesNew";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      marginTop: "160px",
      marginBottom: "10px",
      width: "800px",
      borderRadius: 30,
    },
    modal1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    paper1: {
      width: "40%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 30,
    },
    buttonT1: {
      borderRadius: "30px",
      background: "#007BFF",
    },
    para: {
      fontSize: 13,
    },
    postButton: {
      background: "#007BFF",
      height: "40px",
      boxShadow: "none",
      borderRadius: "30px",
      color: "#fff",
      height: "40px",
      display: "flex",
      padding: "10px 20px 10px 20px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "30px",
      },
    },

    expButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "center",
      contentAlign: "center",
      margin: "auto",
      justifyContent: "center",
      float: "center",
      display: "flex",
      boderRadius: "75px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    redstar: {
      color: "red",
    },
  })
);

const CompanySettings = () => {
  let { status } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState("College");
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [address, setAddress] = useState("");
  const [institutionType, setInstitutionType] = useState();
  const [webURL, setWebURL] = useState("");
  const [desc, setDesc] = useState("");
  const [size, setSize] = useState();
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [country, setCountry] = useState("Select");
  const [state, setState] = useState("Select");
  const [city, setCity] = useState("Select City");
  const [about, setAbout] = useState("");
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [teammatesArray, setTeammatesArray] = useState([]);
  const [teammatesInvitedArray, setTeammatesInvitedArray] = useState([]);
  const [preferencesData, setPreferencesData] = useState({});
  const [jobRole, setJobRole] = useState();
  const [jobRoleInv, setJobRoleInv] = useState();

  const [jobRolesList, setJobRolesList] = useState({});
  const [invEmail, setInvEmail] = useState();
  const [teamEmail, setTeamEmail] = useState();

  const [fName, setFName] = useState();
  const [lName, setLName] = useState();
  const [mobile, setMobile] = useState();
  const [teamAbout, setTeamAbout] = useState();
  const [message, setMessage] = useState();
  const [is_check, setIsCheck] = useState(false);
  const [emailList, setEmailList] = useState();

  const [showTransactionTable, setShowTransactionTable] = useState(false);
  const [rechargeDialog, setRechargeDialog] = useState(false);
  const [success, setSuccess] = useState(false);
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const [paymentId, setPaymentId] = useState("");
  const transactionHistory = useSelector(
    (state) => state.CreditInfo.transactionHistory
  );
  const [creditLoader, setCreditLoader] = useState(false);
  const [preferencesFilled, setPreferencesFilled] = useState(false);

  const [open, setOpen] = useState(status ? "Credits" : "Details");
  const [statusCheck, setStatusCheck] = useState(status ? true : false);
  const [orderDetails, setOrderDetails] = useState({});
  const [submitButtonOpen, setSubmitButtonOpen] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  useEffect(() => {
    if (status) getPaymentDetailsOrderID();
  }, [status]);
  const getPaymentDetailsOrderID = () => {
    Axios.get(
      `${BackendBaseApi.PRAVESH}api/paytm_payment/order_detail/?order_id=${status}`,
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
      .then((res) => {
        if (res) {
          dispatch(updateCreditInfo(res.data.data.transaction_data));
          setOrderDetails(res.data.data.order_data);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const [page, setPage] = React.useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handlePaymentSubmit = async () => {
    const res = await Axios({
      method: "POST",
      url: `${BackendBaseApi.PRAVESH}api/common/manual_payment/`,
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      data: {
        user_id: localStorage.getItem("user_id"),
        user_type: localStorage.getItem("user_type_id"),
        reference_id: paymentId,
      },
    }).catch((err) => {
      throw err;
    });
    setSuccess(true);
  };

  const location = useLocation();

  const alert = useAlert();

  const degrees = [
    "Bachelor of Technology",
    "Master of Technology",
    "B.E.",
    "M.E.",
    "B.Sc.",
    "M.Sc.",
  ];
  const specializations = [
    { name: "1st Year", id: 1 },
    { name: "2nd Year", id: 2 },
    { name: "3rd Year", id: 3 },
    { name: "4th Year", id: 4 },
    { name: "5th Year", id: 5 },
    { name: "6th Year", id: 6 },
  ];
  const [graduation, setGraduation] = useState("Search");
  const [degree, setDegree] = useState("Search");
  const [specialization, setSpecialization] = useState("Search");

  const [preferencesArray, setPreferencesArray] = useState([]);
  const [jobTypes, setJobTypes] = useState({});
  const [jobType, setJobType] = useState();
  const [segmentTypes, setSegmentTypes] = useState({});
  const [segmentType, setSegmentType] = useState();
  const [countries, setCountries] = useState({});
  const [countryType, setCountryType] = useState("");
  const [states, setStates] = useState({});
  const [stateType, setStateType] = useState("");
  const [stateID, setStateID] = useState();
  const [cityID, setCityID] = useState();
  const [countryID, setCountryID] = useState();

  const [cities, setCities] = useState({});
  const [cityType, setCityType] = useState("");
  const [title, setTitle] = useState();
  const [updatingLogo, setUpdatingLogo] = useState(false);
  const [updatingBanner, setUpdatingBanner] = useState(false);
  let user_type_id = localStorage.getItem("user_type_id");
  const history = useHistory();

  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const social_links = [
    { id: 1, Name: "LinkedIn", value: linkedIn !== undefined ? linkedIn : "" },
    { id: 1, Name: "facebook", value: facebook !== undefined ? facebook : "" },
    { id: 1, Name: "instagram", value: insta !== undefined ? insta : "" },
  ];

  const [errorMessages, setErrorMessages] = useState({
    email: "",
  });
  // useEffect(() => {
  //   if (emailRegex.test(invEmail)) {
  //     Axios.post(BackendBaseApi.PRAVESH + "api/check_user/", {
  //       email: invEmail,
  //     })
  //       .then((res) => {
  //         console.log(res);
  //         if (res.data.data.is_company_user) {
  //           setErrorMessages({
  //             ...errorMessages,
  //             email: `Email already exists!!`,
  //           });
  //           return;
  //         }
  //       })
  //       .catch((err) => {
  //         alert.error("Error occured!");
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [invEmail]);

  const companySizes = ["1-10", "11-50", "51-100", "101-1000", "1000+"];

  const CompanySize = () => (
    <MuiThemeProvider theme={theme2}>
      <Autocomplete
        value={size}
        onChange={(event, newValue) => {
          setSize(newValue);
        }}
        id="controllable-states-demo"
        options={companySizes}
        renderOption={(option) => {
          return <span style={{ fontSize: 12 }}>{`${option}`}</span>;
        }}
        style={{ width: "40%" }}
        renderInput={(params) => (
          <TextField {...params} label="Industries" variant="outlined" />
        )}
      />
    </MuiThemeProvider>
  );

  let token = localStorage.getItem("gw_token");

  const getUserDetails = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/settings/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setName(res.data.data.company_name);
          setNickName(res.data.data.company_nickname);
          setAddress(res.data.data.address);
          setInstitutionType(res.data.data.organization_type);
          setWebURL(res.data.data.website);
          setSize(res.data.data.size);
          setDesc(res.data.data.company_description);
          if (res.data.data.social_links) {
            setFacebook(
              res.data.data.social_links[1].value
                ? res.data.data.social_links[1].value
                : res.data.data.social_links[1].Value
            );
            setInsta(
              res.data.data.social_links[2].value
                ? res.data.data.social_links[2].value
                : res.data.data.social_links[2].Value
            );
            setLinkedIn(
              res.data.data.social_links[0].value
                ? res.data.data.social_links[0].value
                : res.data.data.social_links[0].Value
            );
          }
          setCityType(res.data.data.city_name);
          setStateType(res.data.data.state_name);
          setCountryType(res.data.data.country_name);
          setCountryID(res.data.data.country);
          setStateID(res.data.data.state);
          setLogo(res.data.data.logo);
          setBanner(res.data.data.banner);
          setAbout(res.data.data.about);
          if (res.data.data.preferences != null) {
            setPreferencesArray(res.data.data.preferences);
          }
          setPreferencesData(res.data.data.preferences);
          setPreferencesFilled(true);

          setJobType("Search");
          setSegmentType("Search");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getUserDetails();
    getJobTypes();
    getJobSegment();
    getTeammates();
    getCountries();
    getRoles();
    getEmail();
    getTeammatesInvited();
  }, []);

  useEffect(() => {
    getTeammatesInvited();
  }, [open === "Invites"]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage(img);
    }
  };

  const blobToImage = (blob) => {
    setUpdatingLogo(true);
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;

      // setImgtemp(blob);

      const fd = new FormData();
      fd.append("logo", blob);
      Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/", fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Logo uploaded Successfully");
            setLogo(res.data.data.logo);

            dispatch(getCompanyUserDetails(history, alert));
          } else {
            alert.error(res.data.error);
          }

          setUpdatingLogo(false);
        })
        .catch((err) => {
          alert.error("Error occured!");
          setUpdatingLogo(false);
        });
    });
  };
  const onImageChange1 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage1(img);
    }
  };

  const blobToImage1 = (blob) => {
    setUpdatingBanner(true);
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;

      const fd = new FormData();
      fd.append("banner", blob);
      Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/", fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Banner uploaded Successfully");
            setBanner(res.data.data.banner);

            dispatch(getCompanyUserDetails(history, alert));
          } else {
            alert.error(res.data.error);
          }
          setUpdatingBanner(false);
        })
        .catch((err) => {
          alert.error("Error occured!");
          setUpdatingBanner(false);
        });
    });
  };

  function validURL(str) {
    var pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return !!pattern.test(str);
  }

  const handleUploadDetails = () => {
    const v1 =
      social_links[0].value === "" ? true : validURL(social_links[0].value);
    const v2 =
      social_links[1].value === "" ? true : validURL(social_links[1].value);
    const v3 =
      social_links[2].value === "" ? true : validURL(social_links[2].value);
    var flag = 0;
    if (!(v1 && v2 && v3)) {
      alert.error(
        "Enter Social Links URL Correctly. Plese Use HTTP HTTPS Protocol in the URL as well"
      );
      return;
    }
    if (webURL && validURL(webURL) === false) flag = 1;

    if (v1 === false && v2 === false && v3 === false) flag = 2;

    const body = {
      company_name: name,
      about: about,
      address: address,
      company_nickname: nickName,
      organization_type: institutionType,
      company_description: desc,
      website: webURL,
      social_links: social_links,
      state: states[stateType],
      city: cities[cityType],
      country: countries[countryType],
      size: size,
    };

    if (flag === 0 && v1 === true && v2 === true && v3 === true) {
      Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/", body, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Updated Successfully");
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          alert.error("Error occured!");
        });
    } else if (
      flag === 0 &&
      ((social_links[0].value
        ? social_links[0].value
        : social_links[0].Value) === "" ||
        (social_links[1].value
          ? social_links[1].value
          : social_links[1].Value) === "" ||
        (social_links[2].value
          ? social_links[2].value
          : social_links[2].Value) === "")
    ) {
      Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/", body, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Updated Successfully");
            localStorage.setItem(
              "comp_profile_completed",
              res.data.data.comp_profile_complete
            );
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          alert.error("Error occured!");
        });
    } else {
      if (flag === 1)
        alert.error(
          "Enter Valid Website URL. Plese Use HTTP HTTPS Protocol in the URL as well."
        );
      else
        alert.error(
          "Enter Social Links URL Correctly. Plese Use HTTP HTTPS Protocol in the URL as well"
        );
    }
  };

  const handleCourseDetails = () => {};

  const handlePreferences = () => {
    const body = {
      preferences: preferencesArray,
    };
    Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/", body, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Successfully");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };

  const getJobTypes = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/type/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((job_type) => {
            temp[job_type.job_type] = job_type.id;
          });
          setJobTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured 4");
      });
  };

  const getJobSegment = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/segment/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((job_type) => {
            temp[job_type.job_segment] = job_type.id;
          });
          setSegmentTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured 5");
      });
  };

  const getTeammates = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/settings/team/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setTeammatesArray(res.data.data);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error Occured 6");
      });
  };

  const getTeammateRemoved = (id_array) => {
    let data = {
      is_verified: 0,
      is_active: 0,
    };
    Axios.patch(
      BackendBaseApi.PRAVESH +
        "api/company/settings/team/manage/" +
        id_array[0].id +
        "/",
      data,
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("Teammate removed successfully");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error Occured 6");
      });
  };

  const getTeammatesInvited = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/settings/team/invited/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setTeammatesInvitedArray(res.data.data);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error Occured 6");
      });
  };

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/settings/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setCountryID(res.data.data.country);
          getStates(res.data.data.country, res.data.data.country_name);
          getCities(res.data.data.state, res.data.data.state_name);
          setCityType(res.data.data.city_name);
          setCountryType(res.data.data.country_name);
          setJobType("Search");
          setSegmentType("Search");
        } else {
          alert.error("error");
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  // useEffect(() => {
  // 	if(stateID){
  // 	getCities(stateID, stateType)
  // 	}
  // },[stateID])

  //location
  const getCountries = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/location/country/")
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            temp[c.country] = c.country_id;
          });
          setCountries({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        throw err;
      });
  };

  const getStates = (id, e) => {
    // setCountryType(e)
    const urll = BackendBaseApi.PRAVESH + "api/location/state/?search=" + id;

    Axios.get(urll)
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            temp[c.state] = c.state_id;
          });
          setStates({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        throw err;
      });
  };

  const getCities = (id, e) => {
    setStateType(e);
    const urll = BackendBaseApi.PRAVESH + "api/location/city/?search=" + id;
    Axios.get(urll)
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            temp[c.city] = c.city_id;
          });
          setCities({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        throw err;
      });
  };

  const textAreaRef = React.useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    alert.success("Copied to clibboard!");
  }

  const state_s = Object.keys(states).map((d) => {
    return { name: d, value: d };
  });

  const countrie_s = Object.keys(countries).map((d) => {
    return { name: d, value: d };
  });

  const citie_s = Object.keys(cities).map((d) => {
    return { name: d, value: d };
  });
  const roles = Object.keys(jobRolesList).map((d) => {
    return { name: d, value: d };
  });

  const getEmail = () => {
    Axios.get(
      BackendBaseApi.PRAVESH + "api/company/settings/team/?verbose=invite",
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setEmailList(res.data.data);
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured 1");
      });
  };

  const Emails = () => (
    <MuiThemeProvider theme={theme2}>
      <Autocomplete
        value={teamEmail}
        onChange={(event, newValue) => {
          getTeamEmail(newValue);
        }}
        id="controllable-states-demo"
        options={emailList.map((option) => option.email)}
        renderOption={(option) => {
          return <span style={{ fontSize: 12 }}>{`${option}`}</span>;
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} label="Emails" variant="outlined" />
        )}
      />
    </MuiThemeProvider>
  );

  const getTeamEmail = (value) => {
    if (value) {
      setTeamEmail(value);
      var temp = emailList.filter((item) => {
        return item.email === value;
      });

      setFName(temp[0].first_name);
      setLName(temp[0].last_name);
      setMobile(temp[0].phone);
    } else {
      alert.error("Select an Email ID");
      setFName("");
      setLName("");
      setMobile("");
      setTeamEmail("");
    }
  };

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    resetFields();
    setErrorMessages({ email: "" });
    setOpen2(false);
  };

  const [openVerification, setOpenVerification] = useState(
    location.company_not_verified ? true : false
  );

  const handleCloseVerification = () => {
    setOpenVerification(false);
  };
  const handleOpenVerification = () => {
    setOpenVerification(true);
  };

  const getRoles = () => {
    Axios.get(
      BackendBaseApi.PRAVESH + "api/company/user_type/?main_user=company"
    )
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((data) => {
            temp[data.sub_user] = data.id;
          });
          setJobRolesList({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured 2");
      });
  };

  const [inviteLoader, setInviteLoader] = useState(false);
  const inviteLink = () => {
    const pass = {
      email: invEmail,
      user_type_id: jobRolesList[jobRoleInv],
    };
    setInviteLoader(true);
    Axios.post(BackendBaseApi.PRAVESH + "api/check_user/", {
      email: invEmail,
    })
      .then((res) => {
        console.log(res);
        if (res.data.data.is_company_user) {
          setErrorMessages({
            ...errorMessages,
            email: `Email already exists!!`,
          });
          setInviteLoader(false);
          return;
        } else {
          if (invEmail && jobRolesList[jobRoleInv] && !errorMessages?.email) {
            Axios.post(
              BackendBaseApi.PRAVESH + "api/company/settings/team/",
              pass,
              {
                headers: {
                  Authorization: "Token " + token,
                },
              }
            )
              .then((res) => {
                if (res.data.success) {
                  alert.success("Invite Sent Successfully");
                  resetFields();

                  handleClose1();
                } else {
                  alert.error(res.data.error);
                }
                setInviteLoader(false);
              })
              .catch((err) => {
                alert.error("Invite Not Sent");
                setInviteLoader(false);
              });
          } else {
            alert.error("Please Fill all the required fields");
            setInviteLoader(false);
          }
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
        setInviteLoader(false);
      });
  };

  const AddTeam = () => {
    const data = {
      email: teamEmail,
      user_type_id: jobRolesList[jobRole],

      first_name: fName,
      last_name: lName,
      phone: mobile,
      job_title: title,
      about: teamAbout,
      message: message,
    };
    if (
      teamEmail &&
      jobRolesList[jobRole] &&
      fName &&
      lName &&
      mobile &&
      title &&
      teamAbout &&
      message
    ) {
      Axios.post(BackendBaseApi.PRAVESH + "api/company/settings/team/", data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          if (res.data.success) alert.success("Invite Sent Successfully");
          else alert.error(res.data.error);
        })
        .catch((err) => {
          alert.error("Error");
        });
    } else {
      alert.error("Please Fill all the required fields");
    }
  };

  const resetFields = () => {
    setInvEmail("");
    setJobRoleInv("");
  };

  const clearFields = () => {
    setTeamEmail("");
    setJobRole("");
    setFName("");
    setLName("");
    setMobile("");
    setTeamAbout("");
    setMessage("");
    setTitle("");
  };

  const handleChangeCheck = (event) => {
    setIsCheck(event.target.checked);
  };

  useEffect(() => {
    if (showTransactionTable) {
      dispatch(getTransactionHistory(page, setCreditLoader));
    }
  }, [showTransactionTable, page]);

  const checkTab = () => {
    switch (open) {
      case "Details":
        return "details";

      case "Preferences":
        return "preferences";

      case "Invites":
        return "invites";

      case "Teammates":
        return "teammates";
      case "Credits":
        return "credits";

      default:
        return "";
    }
  };

  useEffect(() => {
    window.history.pushState(
      {},
      null,
      `/company/company-settings/?${checkTab()}`
    );
  }, [open]);

  useEffect(() => {
    if (location.search === "?details") setOpen("Details");
    if (location.search === "?preferences") setOpen("Preferences");
    if (location.search === "?invites") setOpen("Invites");
    if (location.search === "?credits") setOpen("Credits");
    if (location.search === "?teammates") setOpen("Teammates");
  }, []);

  return (
    <>
      <Grid container spacing={3} style={{ padding: "20px" }}>
        <Grid item sm={2}>
          <div style={styles.container}>
            <div style={styles.leftPaper}>
              <Button
                variant="contained"
                style={
                  open !== "Details"
                    ? styles.leftMenuBox
                    : styles.leftMenuBoxSelected
                }
                size="small"
                onClick={() => setOpen("Details")}
              >
                Details
              </Button>
              <Button
                variant="contained"
                style={
                  open !== "Preferences"
                    ? styles.leftMenuBox
                    : styles.leftMenuBoxSelected
                }
                size="small"
                onClick={() => setOpen("Preferences")}
              >
                Preferences
              </Button>
              <Button
                variant="contained"
                style={
                  open !== "Teammates"
                    ? styles.leftMenuBox
                    : styles.leftMenuBoxSelected
                }
                size="small"
                onClick={() => setOpen("Teammates")}
              >
                Teammates
              </Button>
              <Button
                variant="contained"
                style={
                  open !== "Invites"
                    ? styles.leftMenuBox
                    : styles.leftMenuBoxSelected
                }
                size="small"
                onClick={() => setOpen("Invites")}
              >
                Invites
              </Button>
              <Button
                variant="contained"
                style={
                  open !== "Credits"
                    ? styles.leftMenuBox
                    : styles.leftMenuBoxSelected
                }
                size="small"
                onClick={() => setOpen("Credits")}
              >
                Credits
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item sm={10}>
          {open == "Details" ? (
            <div style={styles.paperStyle}>
              <form>
                <h5>Details</h5>

                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Company Name</div>
                  <input
                    className="form-control"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={styles.inputBox}
                    required
                  />
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Company Nick Name</div>
                  <input
                    className="form-control"
                    type="text"
                    value={nickName}
                    onChange={(e) => setNickName(e.target.value)}
                    style={styles.inputBox}
                    required
                  />
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Company Address</div>
                  <input
                    className="form-control"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    style={styles.inputBox1}
                    required
                  />
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Select Company Type</div>
                  <div>
                    <div style={styles.radioStyle}>
                      <Radio
                        checked={institutionType === "Government"}
                        onChange={() => setInstitutionType("Government")}
                        value="Government"
                        name="radio-button-demo"
                        label="adfv"
                        color="primary"
                        size="small"
                        style={{ marginLeft: "25px" }}
                      />
                      <span style={{ fontSize: 12 }}> Government</span>
                    </div>
                    <div style={styles.radioStyle}>
                      <Radio
                        checked={institutionType === "SemiPrivate"}
                        onChange={() => setInstitutionType("SemiPrivate")}
                        value="SemiPrivate"
                        name="radio-button-demo"
                        color="primary"
                        size="small"
                        style={{ marginLeft: "25px" }}
                      />
                      <span style={{ fontSize: 12 }}> Semi Government</span>
                    </div>
                    <div style={styles.radioStyle}>
                      <Radio
                        checked={institutionType === "Private"}
                        onChange={() => setInstitutionType("Private")}
                        value="Private"
                        name="radio-button-demo"
                        label="adfv"
                        color="primary"
                        size="small"
                        style={{ marginLeft: "25px" }}
                      />
                      <span style={{ fontSize: 12 }}>Private </span>
                    </div>
                  </div>
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Company Size</div>
                  {/* <div style={styles.radioStyle}> */}
                  {/* <input
                  className="form-control"
                  type="text"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                  style={styles.inputBox1}
                  required
                /> */}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <CompanySize />
                  {/* </div> */}
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Company Website URL</div>
                  <input
                    className="form-control"
                    type="text"
                    value={webURL}
                    onChange={(e) => setWebURL(e.target.value)}
                    style={styles.inputBox}
                    required
                  />
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>About</div>
                  <textarea
                    className="form-control"
                    type="text"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    style={styles.inputBoxAbout}
                    required
                  />
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Company Description</div>
                  <textarea
                    className="form-control"
                    type="text"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    style={styles.inputBox2}
                    required
                  />
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Social Links</div>
                  <div style={styles.notRequired}>
                    <input
                      className="form-control"
                      type="text"
                      value={linkedIn}
                      onChange={(e) => setLinkedIn(e.target.value)}
                      style={styles.inputBox3}
                      placeholder="Company LinkedIn Page"
                    />
                    <input
                      className="form-control"
                      type="text"
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                      style={styles.inputBox3}
                      placeholder="Company Facebook Page"
                    />
                    <input
                      className="form-control"
                      type="text"
                      value={insta}
                      onChange={(e) => setInsta(e.target.value)}
                      style={styles.inputBox3}
                      placeholder="Company Instagram Page"
                    />
                  </div>
                </div>
                <div style={styles.fieldDiv}>
                  <div style={styles.heading}>Location</div>
                  <MuiThemeProvider theme={theme2}>
                    <Autocomplete
                      value={countryType}
                      onChange={(event, newValue) => {
                        getStates(countries[newValue], newValue);
                      }}
                      id="controllable-states-demo"
                      options={countrie_s.map((option) => option.name)}
                      renderOption={(option) => {
                        return (
                          <span style={{ fontSize: 12 }}>{`${option}`}</span>
                        );
                      }}
                      style={{
                        width: "25%",
                        paddingRight: "20px",
                        paddingLeft: "30px",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Countries"
                          variant="outlined"
                        />
                      )}
                    />
                  </MuiThemeProvider>
                  <MuiThemeProvider theme={theme2}>
                    <Autocomplete
                      value={stateType}
                      onChange={(event, newValue) => {
                        getCities(states[newValue], newValue);
                      }}
                      id="controllable-states-demo"
                      options={state_s.map((option) => option.name)}
                      renderOption={(option) => {
                        return (
                          <span style={{ fontSize: 12 }}>{`${option}`}</span>
                        );
                      }}
                      style={{ width: "20%", paddingRight: "20px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search States"
                          variant="outlined"
                        />
                      )}
                    />
                  </MuiThemeProvider>

                  <MuiThemeProvider theme={theme2}>
                    <Autocomplete
                      value={cityType}
                      onChange={(event, newValue) => {
                        setCityType(newValue);
                      }}
                      id="controllable-states-demo"
                      options={citie_s.map((option) => option.name)}
                      renderOption={(option) => {
                        return (
                          <span style={{ fontSize: 12 }}>{`${option}`}</span>
                        );
                      }}
                      style={{ width: "20%", paddingRight: "20px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Cities"
                          variant="outlined"
                        />
                      )}
                    />
                  </MuiThemeProvider>
                </div>
                {logo ? (
                  // <div style={styles.fieldDiv}>
                  // <div style={styles.heading}>Location</div>
                  <div style={styles.avatarBox}>
                    <Avatar
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "75px",
                        border: "7px solid #e7e6e6",
                        margin: "auto",
                      }}
                      alt="Remy Sharp"
                      src={updatingLogo ? gifLoader : logo}
                    />
                    <div>
                      <input
                        type="file"
                        id="image"
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: "none" }}
                        onChange={onImageChange}
                      ></input>
                      <label
                        for="image"
                        className="img_edit"
                        style={{ position: "relative", top: 45, left: -310 }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            borderRadius: "75px",
                            border: "2px solid #e7e6e6",
                            padding: "5px 9px 5px 9px",
                            opacity: 0.8,
                          }}
                        >
                          <Icon
                            className="fa fa-pencil"
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                ) : (
                  // </div>
                  // <div style={styles.fieldDiv}>
                  // <div style={styles.heading}>Location</div>
                  <div style={styles.avatarBox}>
                    <Avatar
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "75px",
                        border: "7px solid #e7e6e6",
                        margin: "auto",
                      }}
                      alt="Remy Sharp"
                    />
                    <div>
                      <input
                        type="file"
                        id="image"
                        style={{ display: "none" }}
                        onChange={onImageChange}
                      ></input>
                      <label
                        for="image"
                        className="img_edit"
                        style={{ position: "relative", top: 45, left: -310 }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            borderRadius: "75px",
                            border: "2px solid #e7e6e6",
                            padding: "5px 9px 5px 9px",
                            opacity: 0.8,
                          }}
                        >
                          <Icon
                            className="fa fa-pencil"
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                  // </div>
                )}
                {banner ? (
                  <div style={styles.images}>
                    <div
                      className="hello img_edit1"
                      style={{
                        border: "2px solid #e7e6e6",
                        borderRadius: "3px",
                      }}
                    >
                      <input
                        type="file"
                        id="image1"
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: "none" }}
                        onChange={onImageChange1}
                      ></input>
                      <label
                        for="image1"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={updatingBanner ? gifLoader : banner}
                          style={{ width: "100%", height: "170px" }}
                        />
                        {/* <div
                          style={{
                            // backgroundColor: "white",
                            borderRadius: "75px",
                            border: "2px solid #e7e6e6",
                            padding: "5px 9px 5px 9px",
                            opacity: 0.8,
                            height:"30px"
                          }}
                        >
                          <Icon
                            className="fa fa-pencil"
                            style={{ fontSize: "20px" }}
                          />
                        </div> */}
                      </label>
                    </div>
                  </div>
                ) : (
                  <div style={styles.emptyBanner}>
                    <input
                      type="file"
                      id="image2"
                      style={{ display: "none" }}
                      onChange={onImageChange1}
                    ></input>
                    <label for="image2">Add Banner for your company</label>
                  </div>
                )}
                <div style={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    style={styles.button}
                    onClick={handleUploadDetails}
                  >
                    Update Details
                  </Button>
                </div>
              </form>
            </div>
          ) : null}

          {open == "Preferences" && preferencesFilled ? (
            <div style={styles.paperStyle}>
              <PreferencesNew
                data={preferencesData}
                setPreferencesData={setPreferencesData}
                setSubmitButtonOpen={setSubmitButtonOpen}
                submitButtonClicked={submitButtonClicked}
              />
              {/* <form>
            <h5>Preferences</h5>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "20px",
              }}
              className="text-grey"
            >
              Update the kind of Hiring you do for your company
            </div>
            <div style={styles.fieldDiv}>
              <div style={styles.radioStyle}>
                <FormControl variant="standard" style={styles.dropDown1}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value)}
                    label="Degree"
                  >
                    <MenuItem value="Search" disabled>
                      Search Job Type
                    </MenuItem>
                    {Object.keys(jobTypes).map((data, ind) => {
                      return (
                        <MenuItem value={data} key={ind}>
                          {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="standard" style={styles.dropDown1}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={segmentType}
                    onChange={(e) => setSegmentType(e.target.value)}
                    label="Degree"
                  >
                    <MenuItem value="Search" disabled>
                      Search Segment Type
                    </MenuItem>
                    {Object.keys(segmentTypes).map((data, ind) => {
                      return (
                        <MenuItem value={data} key={ind}>
                          {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="standard" style={styles.dropDown1}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={specialization}
                    onChange={(event) => setSpecialization(event.target.value)}
                    label="Specialization"
                  >
                    <MenuItem value="Search" disabled>
                      Year
                    </MenuItem>
                    {specializations.map((data, ind) => {
                      return (
                        <MenuItem value={data.name} key={ind}>
                          {data.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  style={styles.button3}
                  size="small"
                  onClick={() => {
                    let pr = preferencesArray;
                    let flag = 0;
                    for (var i = 0; i < preferencesArray.length; i++) {
                      if (
                        preferencesArray[i].job_type_name === jobType &&
                        preferencesArray[i].job_segment_name === segmentType &&
                        preferencesArray[i].year === specialization
                      ) {
                        flag = 1;
                        break;
                      } else if (
                        jobType === "Search" &&
                        specialization === "Search" &&
                        segmentType === "Search"
                      ) {
                        flag = 2;
                        break;
                      }
                    }

                    if (flag === 0) {
                      if (
                        jobType &&
                        jobType !== "Search" &&
                        specialization &&
                        specialization !== "Search"
                      ) {
                        pr.push({
                          job_type_name: jobType !== "Search" ? jobType : null,
                          job_type_id:
                            jobType !== "Search" ? jobTypes[jobType] : null,
                          job_segment_name:
                            segmentType !== "Search" ? segmentType : null,
                          job_segment_id:
                            segmentType !== "Search"
                              ? segmentTypes[segmentType]
                              : null,
                          year:
                            specialization !== "Search" ? specialization : null,
                        });
                      } else {
                        alert.error("Please Select Job Type and Year");
                      }
                      setPreferencesArray([...pr]);
                    } else if (flag === 1) {
                      alert.error("Already Selected");
                    } else {
                      alert.error("Please Select Job Type and Year");
                    }
                    setJobType("Search");
                    setSegmentType("Search");
                    setSpecialization("Search");
                  }}
                >
                  ADD&nbsp; &nbsp;
                  <Icon className="fa fa-plus" style={{ fontSize: "16px" }} />
                </Button>
              </div>
            </div>
            {preferencesArray.length > 0
              ? preferencesArray.map((data, index) => {
                  return (
                    <div style={styles.fieldDiv} key={index}>
                      <div style={styles.radioStyle}>
                        <input
                          type="text"
                          className="form-control"
                          value={data.job_type_name}
                          style={styles.inputBox}
                          required
                          disabled
                        />

                        <input
                          type="text"
                          className="form-control"
                          value={data.job_segment_name}
                          style={styles.inputBox}
                          required
                          disabled
                        />

                        <input
                          type="text"
                          className="form-control"
                          value={data.year}
                          style={styles.inputBox}
                          required
                          disabled
                        />
                        <Button
                          variant="contained"
                          style={styles.button2}
                          size="small"
                          onClick={() => {
                            let ca = preferencesArray;
                            ca.splice(index, 1);
                            setPreferencesArray([...ca]);
                          }}
                        >
                          Del&nbsp; &nbsp;
                          <Icon
                            className="fa fa-trash-alt"
                            style={{ fontSize: "16px" }}
                          />
                        </Button>
                      </div>
                    </div>
                  );
                })
              : null}

            <div style={styles.buttonContainer}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={handlePreferences}
              >
                Update Preferences
              </Button>
            </div>
          </form> */}
            </div>
          ) : null}
          {open == "Teammates" ? (
            <div style={styles.paperStyle}>
              <div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open1}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open1}>
                    <div className={classes.paper}>
                      <Typography
                        align="center"
                        className={classes.grid3}
                        variant="h6"
                      >
                        <b>Add New Members to your team</b>&nbsp;
                      </Typography>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography className={classes.para}>
                            <b>Email</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.grid4}>
                          {is_check == true ? (
                            <CssTextField
                              id="date"
                              label="Email"
                              type="email"
                              onChange={(e) => {
                                setTeamEmail(e.target.value);
                              }}
                              InputProps={{
                                style: { fontSize: 12 },
                              }}
                              value={teamEmail}
                              variant="outlined"
                              style={{ width: "100%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          ) : (
                            <Emails />
                          )}
                          <br />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={is_check}
                                onChange={handleChangeCheck}
                                name="gilad"
                              />
                            }
                            label={
                              <span style={{ fontSize: 12 }}>
                                Is Your Email not Listed?
                              </span>
                            }
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography className={classes.para}>
                            <b>Full Name</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.grid4}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <TextField
                                id="date"
                                label="First Name"
                                type="text"
                                onChange={(e) => {
                                  setFName(e.target.value);
                                }}
                                value={fName}
                                variant="outlined"
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  style: { fontSize: 12 },
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="date"
                                label="Last Name"
                                type="text"
                                onChange={(e) => {
                                  setLName(e.target.value);
                                }}
                                value={lName}
                                variant="outlined"
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  style: { fontSize: 12 },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography className={classes.para}>
                            <b>Role</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.grid4}>
                          <MuiThemeProvider theme={theme2}>
                            <Autocomplete
                              value={jobRole}
                              onChange={(event, newValue) => {
                                setJobRole(newValue);
                              }}
                              id="controllable-states-demo"
                              options={roles
                                .slice(0, 3)
                                .map((option) => option.name)}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Role"
                                  variant="outlined"
                                />
                              )}
                            />
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography className={classes.para}>
                            <b>Mobile Number</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.grid4}>
                          <TextField
                            id="date"
                            label="Mobile Number"
                            type="number"
                            // className={classes.textField}
                            onChange={(e) => {
                              setMobile(e.target.value);
                            }}
                            value={mobile}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              style: { fontSize: 12 },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography className={classes.para}>
                            <b>Job Title</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.grid4}>
                          <TextField
                            id="date"
                            label="Job Title"
                            type="text"
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            value={title}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              style: { fontSize: 12 },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography className={classes.para}>
                            <b>About</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.grid4}>
                          <TextareaAutosize
                            rowsMin={4}
                            rowsMax={8}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "30px",
                              borderColor: "#d2cdcd",
                              padding: 12,
                            }}
                            aria-label="maximum height"
                            placeholder="About"
                            onChange={(e) => {
                              setTeamAbout(e.target.value);
                            }}
                            value={teamAbout}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography className={classes.para}>
                            <b>Message</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.grid4}>
                          <TextareaAutosize
                            rowsMin={4}
                            rowsMax={8}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "30px",
                              borderColor: "#d2cdcd",
                              padding: 12,
                            }}
                            aria-label="maximum height"
                            placeholder="Message"
                            onChange={(e) => {
                              setMessage(e.target.value);
                            }}
                            value={message}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} className={classes.grid4}></Grid>
                        <Grid item xs={4} className={classes.grid4}>
                          <Button
                            type="submit"
                            className={classes.postButton}
                            onClick={() => {
                              AddTeam();
                              clearFields();
                              handleClose();
                            }}
                          >
                            Add Teammate
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Fade>
                </Modal>
              </div>

              {/* modal 2 invite link */}
              <div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal1}
                  open={open2}
                  onClose={handleClose1}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open2}>
                    <div className={classes.paper1}>
                      <Typography className={classes.grid3} variant="h6">
                        <b>Invite with Link</b>&nbsp;
                      </Typography>
                      <br />
                      <Typography
                        className={classes.grid3}
                        variant="body2"
                        style={{ color: "grey", fontSize: 12 }}
                      >
                        Anyone can join your company account with this link. As
                        long as they use the invite
                        <br /> link, they will be automatically approved and
                        connected with your Company upon
                        <br />
                        completing the registration process
                      </Typography>
                      <br />
                      <Typography
                        className={classes.grid3}
                        variant="body1"
                        style={{ fontSize: 13 }}
                      >
                        <b>Select user Role for invite</b>
                      </Typography>
                      <br />

                      <Autocomplete
                        value={jobRoleInv}
                        onChange={(event, newValue) => {
                          setJobRoleInv(newValue);
                        }}
                        id="controllable-states-demo"
                        options={roles.slice(0, 2).map((option) => option.name)}
                        style={{ width: "50%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Role"
                            variant="outlined"
                          />
                        )}
                      />
                      <br />

                      <Grid container spacing={3}>
                        <Grid item md={8}>
                          <TextField
                            id="date"
                            label="Email"
                            type="email"
                            error={errorMessages?.email !== "" ? true : false}
                            helperText={
                              <span
                                style={
                                  errorMessages?.email !== ""
                                    ? { color: "#D64045", fontSize: 13 }
                                    : { color: "grey", fontSize: 13 }
                                }
                              >
                                {errorMessages?.email}
                              </span>
                            }
                            onChange={(e) => {
                              setInvEmail(e.target.value);
                              setErrorMessages({ email: "" });
                            }}
                            value={invEmail}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item md={4} className={classes.grid4}>
                          {/*    <Button
                            type="submit"
                            className={classes.postButton}
                            disabled={
                              invEmail &&
                              jobRolesList[jobRoleInv] &&
                              !errorMessages?.email &&
                              emailRegex.test(invEmail)
                                ? false
                                : true
                            }
                            onClick={() => {
                              inviteLink();
                            }}
                          >
                            Send Invite Link
                          </Button> */}
                          <CommonButton
                            variant="contained"
                            style={{ minWidth: "160px", height: "40px" }}
                            onClick={() => {
                              inviteLink();
                            }}
                            disabled={
                              invEmail &&
                              jobRolesList[jobRoleInv] &&
                              !errorMessages?.email &&
                              emailRegex.test(invEmail)
                                ? false
                                : true
                            }
                          >
                            <span className={classes.labelSize}>
                              {inviteLoader ? (
                                <CircularProgress size={17} />
                              ) : (
                                "Send Invite Link"
                              )}
                            </span>
                          </CommonButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Fade>
                </Modal>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  justifyContent: "space-between",
                }}
              >
                <h5>Teammates</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "400px",
                    justifyContent: "space-between",
                    /*  width: "60%", */
                    marginRight: "30px",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ borderRadius: "30px", height: "40px" }}
                      onClick={handleOpen1}
                    >
                      Send Invite Link
                    </Button>
                  </div>

                  {/*    <div>
                    <Button
                      onClick={handleOpen}
                      variant="contained"
                      style={styles.buttonT2}
                    >
                      Add Teammates Manually
                    </Button>
                  </div> */}
                </div>
              </div>
              <Box marginTop="40px">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.para}>Name</TableCell>
                        <TableCell className={classes.para}>Email</TableCell>
                        <TableCell className={classes.para}>
                          Phone No.
                        </TableCell>
                        <TableCell className={classes.para}>Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teammatesArray.map((data, index) => (
                        <TableRow>
                          <TableCell className={classes.para}>
                            {data.first_name}&nbsp;{data.last_name}
                          </TableCell>
                          <TableCell className={classes.para}>
                            {data.email}
                          </TableCell>
                          <TableCell className={classes.para}>
                            {data.phone}
                          </TableCell>
                          <TableCell className={classes.para}>
                            {data.type[0].sub_user}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {/* <div style={styles.tableContainer}>
            <div style={styles.tab}>
              <div style={styles.tableHeader2}>Name</div>
              <div style={styles.tableHeader2}>Email</div>
              <div style={styles.tableHeader2}>Phone No.</div>
              <div style={styles.tableHeader2}>Role</div>
              {parseInt(user_type_id) === 5 && (
                <div style={styles.tableHeader2}></div>
              )}
            </div>
            {teammatesArray.map((data, index) => {
              return (
                <div style={styles.tab1} key={index}>
                  {index % 2 == 0 ? (
                    <div style={styles.tab}>
                      <div style={styles.tableHeader3}>
                        {data.first_name}&nbsp;{data.last_name}
                      </div>
                      <div style={styles.tableHeader3}>{data.email}</div>
                      <div style={styles.tableHeader3}>{data.phone}</div>
                      <div style={styles.tableHeader3}>
                        {data.type.map((item, id) => (
                          <>
                            {id > 0 ? <>, &nbsp;</> : <></>}
                            {item.main_user}-{item.sub_user}
                          </>
                        ))}{" "}
                      </div>
                      {parseInt(user_type_id) === 5 && (
                        <div style={styles.tableHeader3}>
                          {index !== 0 && (
                            <Button
                              variant="contained"
                              style={styles.button2}
                              size="small"
                              onClick={() => {
                                let ca = teammatesArray;
                                let ta = teammatesArray;
                                var new_arr = ta.slice(index, index + 1);

                                ca.splice(index, 1);

                                setTeammatesArray([...ca]);
                                getTeammateRemoved(new_arr);
                              }}
                            >
                              Del&nbsp; &nbsp;
                              <Icon
                                className="fa fa-trash-alt"
                                style={{ fontSize: "16px" }}
                              />
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div style={styles.tab}>
                      <div style={styles.tableHeader}>
                        {data.first_name}&nbsp;{data.last_name}
                      </div>
                      <div style={styles.tableHeader}>{data.email}</div>
                      <div style={styles.tableHeader}>{data.phone}</div>
                      <div style={styles.tableHeader}>
                        {data.type.map((item, id) => (
                          <>
                            {id > 0 ? <>, &nbsp;</> : <></>}
                            {item.main_user}-{item.sub_user}
                          </>
                        ))}
                      </div>
                      {parseInt(user_type_id) === 5 && (
                        <div style={styles.tableHeader}>
                          {" "}
                          {index !== 0 && (
                            <Button
                              variant="contained"
                              style={styles.button2}
                              size="small"
                              onClick={() => {
                                let ca = teammatesArray;
                                let ta = teammatesArray;
                                var new_arr = ta.slice(index, index + 1);

                                ca.splice(index, 1);

                                setTeammatesArray([...ca]);
                                getTeammateRemoved(new_arr);
                              }}
                            >
                              Del&nbsp; &nbsp;
                              <Icon
                                className="fa fa-trash-alt"
                                style={{ fontSize: "16px" }}
                              />
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div> */}
            </div>
          ) : null}

          {open == "Invites" ? (
            <div style={styles.paperStyle}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {teammatesInvitedArray.length > 0 && <h5>Invites</h5>}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "400px",
                    justifyContent: "space-between",
                    width: "60%",
                    marginRight: "30px",
                  }}
                ></div>
              </div>
              <div style={styles.tableContainer}>
                {teammatesInvitedArray.length > 0 && (
                  <div style={styles.tab}>
                    <div style={styles.tableHeader2}>Email</div>
                    <div style={styles.tableHeader2}>Role</div>
                  </div>
                )}
                {teammatesInvitedArray.length > 0 ? (
                  teammatesInvitedArray.map((data, index) => {
                    return (
                      <div style={styles.tab1} key={index}>
                        {index % 2 == 0 ? (
                          <div style={styles.tab}>
                            <div style={styles.tableHeader3}>
                              {data.email ? data.email : "NA"}
                            </div>

                            <div style={styles.tableHeader3}>
                              {data.type.main_user}-{data.type.sub_user}
                            </div>
                          </div>
                        ) : (
                          <div style={styles.tab}>
                            <div style={styles.tableHeader}>
                              {data.email ? data.email : "NA"}
                            </div>

                            <div style={styles.tableHeader}>
                              {data.type.main_user}-{data.type.sub_user}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    marginTop="5%"
                    paddingBottom="5%"
                  >
                    <img src={NoJobs} width="300px" height="auto" />
                    <p
                      style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      No Invites Sent yet
                    </p>
                    <Box width="67%">
                      {/* <p
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#424242",
                      }}
                    >
                      A perfectly enticing pizza box sitting on a table. You open it filled with anticipation. And find… nothing but scraps. Perhaps a half eaten crust. And a lot
                      of grease. The anticipation turns to deep disappointment and despair. There’s nothing left!
                    </p> */}
                    </Box>
                  </Box>
                )}
              </div>
            </div>
          ) : null}
          {open === "Credits" ? (
            <Box style={styles.creditStyle}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <Box
                    padding="6%"
                    boxShadow="0 10px 25px 0 rgb(172 185 196 / 28%)"
                    borderRadius="30px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    margin="2%"
                  >
                    <img src={AvailableCredits} width="60px" height="auto" />
                    <p style={{ fontSize: "16px" }}>Available Credits</p>
                    {creditInfo ? (
                      <p
                        style={{
                          fontSize: "19px",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        {creditInfo.available_credits}
                      </p>
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box
                    padding="6%"
                    boxShadow="0 10px 25px 0 rgb(172 185 196 / 28%)"
                    borderRadius="30px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    margin="2%"
                  >
                    <img src={BurnedCredits} width="60px" height="auto" />
                    <p style={{ fontSize: "16px" }}>Burned Credits</p>
                    {creditInfo ? (
                      <p
                        style={{
                          fontSize: "19px",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        {creditInfo.burned_credits}
                      </p>
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box
                    padding="6%"
                    boxShadow="0 10px 25px 0 rgb(172 185 196 / 28%)"
                    borderRadius="30px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    margin="2%"
                  >
                    <img src={ExpiredCredit} width="60px" height="auto" />
                    <p style={{ fontSize: "16px" }}>Expired Credits</p>
                    {creditInfo ? (
                      <p
                        style={{
                          fontSize: "19px",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        {creditInfo.expired_credits}
                      </p>
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    margin="2%"
                  >
                    {/* <Button
                    onClick={() => setRechargeDialog(true)}
                    style={{
                      backgroundColor: "#219653",
                      borderRadius: "30px",
                      color: "#ffffff",
                      width: "50%",
                      height: "50px",
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                    startIcon={
                      <img src={GetMoney} width="30px" height="auto" />
                    }
                  >
                    Recharge Now
                  </Button> */}
                    <New_gate
                      openCheck={statusCheck}
                      setOpenCheck={setStatusCheck}
                      orderDetails={orderDetails}
                    />
                    <Payment_sucess
                      openCheck={statusCheck}
                      orderDetails={orderDetails}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box padding="4% 0%" margin="2%">
                <p
                  onClick={() => setShowTransactionTable(!showTransactionTable)}
                  style={{
                    display: "flex",
                    color: "#007BFF",
                    fontSize: "19px",
                    cursor: "pointer",
                  }}
                >
                  {showTransactionTable
                    ? "Hide Transaction history"
                    : "View all transaction history"}{" "}
                  <span>
                    <ArrowRightAlt style={{ width: "43px", height: "35px" }} />
                  </span>
                </p>
                {showTransactionTable && (
                  <>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.para}>
                              S. No.
                            </TableCell>
                            <TableCell className={classes.para}>Type</TableCell>
                            <TableCell className={classes.para}>
                              Amount
                            </TableCell>
                            <TableCell className={classes.para}>
                              Transaction Id
                            </TableCell>
                            <TableCell className={classes.para}>
                              Date/Time
                            </TableCell>
                            <TableCell className={classes.para}>
                              Service Type
                            </TableCell>
                            <TableCell className={classes.para}>
                              Closing Amount
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transactionHistory?.results?.length > 0 &&
                          !creditLoader ? (
                            transactionHistory?.results?.map((item, index) => (
                              <TableRow>
                                <TableCell className={classes.para}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.para}>
                                  {item.transaction_type}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      item.transaction_type === "CREDIT"
                                        ? "#219653"
                                        : "#D64045",
                                  }}
                                >
                                  {`${
                                    item.transaction_type === "CREDIT"
                                      ? "+"
                                      : "-"
                                  } ${item.amount}`}
                                </TableCell>
                                <TableCell className={classes.para}>
                                  {item.transaction_id}
                                </TableCell>
                                <TableCell className={classes.para}>
                                  {moment(item.transaction_date).format(
                                    "Do MMMM, YYYY, h:mm:ss a"
                                  )}
                                </TableCell>
                                <TableCell className={classes.para}>
                                  {item.service_type}
                                </TableCell>
                                <TableCell className={classes.para}>
                                  {item.available_credits}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <>
                              <TableCell className={classes.para}></TableCell>
                              <TableCell className={classes.para}></TableCell>
                              <TableCell className={classes.para}></TableCell>
                              <TableCell className={classes.para}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <CircularProgress size={25} />
                                </Box>
                              </TableCell>
                              <TableCell className={classes.para}></TableCell>
                              <TableCell className={classes.para}></TableCell>
                              <TableCell className={classes.para}></TableCell>
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Pagination
                        count={Math.ceil(transactionHistory?.count / 10)}
                        page={page}
                        onChange={handleChangePage}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <RechargeDialog
                open={rechargeDialog}
                setOpen={setRechargeDialog}
                success={success}
                setSuccess={setSuccess}
              />
            </Box>
          ) : null}
          {/* </div> */}
        </Grid>
      </Grid>

      <CompanyVerificationDialog
        open={openVerification}
        handleClose={handleCloseVerification}
      />
    </>
  );
};

export default CompanySettings;

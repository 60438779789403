export const SidebarData = [
  {
    title: "Overview",
    path: "/company/dashboard",
    cName: "nav-text",
    type: "overview",
    sNum: "1",
  },

  {
    title: "Manage Jobs",
    path: "/company/all-jobs",
    cName: "nav-text",
    type: "jobs",
    sNum: "3",
  },
  // {
  //   title: "Job Applicants",
  //   path: "/company/manage-job",
  //   cName: "nav-text",
  //   type: "jobs",
  //   sNum: "3",
  // },
  {
    title: "Interviews",
    path: "/company/interview",
    cName: "nav-text",
    type: "interviews",
    sNum: "4",
  },
  {
    title: "Assignments",
    path: "/company/assignment",
    cName: "nav-text",
    type: "assignments",
    sNum: "5",
  },

  {
    title: "Search College",
    path: "/company/invite",
    cName: "nav-text",
    type: "search_college",
    sNum: "2",
  },
  {
    title: "My Subscription",
    path: "/company/subscription",
    cName: "nav-text",
    type: "mysubscription",
    sNum: "6",
  },
  // {
  //   title: "College Connections",
  //   path: "/company/college-connection",
  //   cName: "nav-text",
  //   type: "college-connection",
  //   sNum: "6",
  // },
  // {
  //   title: "Settings",
  //   cName: "nav-text",
  //   type: "settings",
  //   sNum: "7",
  //   subNav: [
  //     {
  //       title: "User Roles",
  //       path: "/user-roles",
  //     },
  //     {
  //       title: "Placement Rules",
  //       path: "/placement-rules",
  //     },
  //     {
  //       title: "Courses",
  //       path: "/college-courses",
  //     },
  //     {
  //       title: "College Profile",
  //       path: "/college-profile",
  //     },
  //     {
  //       title: "Billing Info",
  //       path: "/billing-info",
  //     },
  //   ],
  // },
];

import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import SelectSearch from "react-select-search";
import { BackendBaseApi } from "../../../../constants/constants";

const EducationForm = ({ state, setState, appendToParentState }) => {
  const disableSaveButton = useRef();

  let qualifications = {
    "10th": 1,
    "12th": 2,
    Graduation: 3,
    "Post Graduation": 4,
    PhD: 5,
  };

  const [boardList, setBoardList] = useState({});
  const [qualification, setQualification] = useState();
  const [collegeList, setCollegeList] = useState({});
  const [board, setBoard] = useState();
  const [college, setCollege] = useState();
  const [endDate, setEndDate] = useState();
  const [percentage, setPercentage] = useState();

  useEffect(() => {
    if (qualification && (college || board) && endDate && percentage)
      disableSaveButton.current.classList.remove("disabled");
    else disableSaveButton.current.classList.add("disabled");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualification, board, college, endDate, percentage]);

  const [showField, setShowField] = useState({
    College: false,
  });

  const QualificationDropdown = () => (
    <SelectSearch
      value={qualification}
      onChange={setQualification}
      options={Object.keys(qualifications).map((q) => {
        return { name: q, value: q };
      })}
      placeholder="Select"
    />
  );

  const TypeAndSearchCollege = () => (
    <SelectSearch
      options={Object.keys(collegeList).map((clg) => {
        return { name: clg, value: clg };
      })}
      value={college}
      onChange={setCollege}
      search
      placeholder="Type and Search your college from the list"
    />
  );

  const BoardDropdown = () => (
    <SelectSearch
      options={Object.keys(boardList).map((board) => {
        return { name: board, value: board };
      })}
      value={board}
      onChange={setBoard}
      search
      placeholder="Type and Search your education board"
    />
  );

  const getBoards = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/education/board")
      .then((res) => {
        let temp = {};
        res.data.data.board_list.forEach((board) => {
          temp[board.name] = board.id;
        });

        setBoardList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCollegeList = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/education/college/")
      .then((res) => {
        let temp = {};
        res.data.forEach((college) => {
          temp[college.name] = college.id;
        });
        setCollegeList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (qualification && (college || board) && endDate && percentage) {
      if (qualifications[qualification] <= 2) {
        //student filled 10th 12th details
        setState({
          education: qualifications[qualification],
          percentage: percentage,
          board: boardList[board],
          end_date: endDate,
        });
      } else {
        //student filled college details
        setState({
          education: qualifications[qualification],
          percentage: percentage,
          college: showField.College ? college : collegeList[college],
          end_date: endDate,
        });
      }
    } else return;
  };

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    let isValid = isEmpty(state) ? false : true;

    Object.values(state).forEach((val, ind) => {
      if (!val) {
        isValid = false;
        return;
      }
    });

    if (isValid) appendToParentState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    getBoards();
    getCollegeList();
    disableSaveButton.current.classList.add("disabled");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mx-2 my-2">
        <div className="row pl-4">
          <Grid container>
            <Grid item sm={3} xs={12}>
              <label htmlFor="percentage" className="fs-12 fw-500 mb-1 my-auto">
                Education Type
              </label>
            </Grid>
            <Grid item sm={9} xs={12}>
              <QualificationDropdown className="form-control shadow_1-lightest" />
            </Grid>
          </Grid>

          {qualification && qualifications[qualification] <= 2 && (
            <Grid container>
              <Grid sm={3} xs={12} style={{ marginTop: "0px" }}>
                <label
                  htmlFor="percentage"
                  className="fs-12 fw-500 mb-1 my-auto"
                >
                  Board
                </label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <BoardDropdown />
              </Grid>
            </Grid>
          )}
          {qualification && qualifications[qualification] >= 3 && (
            <Grid container>
              <Grid item sm={3} xs={12}>
                <label
                  htmlFor="percentage"
                  className="fs-12 fw-500 mb-1 my-auto"
                >
                  College
                </label>
              </Grid>
              <Grid item sm={9} xs={12}>
                {!showField.College ? (
                  <>
                    <TypeAndSearchCollege className="form-control shadow_1-lightest" />
                  </>
                ) : (
                  <input
                    type="text"
                    onChange={(e) => {
                      setCollege(e.target.value);
                    }}
                    class="form-control shadow_1-lightest profile-field "
                    value={college}
                  />
                )}

                {
                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      checked={showField.college}
                      onChange={() => {
                        setShowField({
                          ...showField,
                          College: !showField.College,
                        });
                      }}
                      className="form-check-input my-2"
                      id="college-checkbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="college-checkbox"
                    >
                      <small id="emailHelp" className="form-text my-1 fw-500">
                        My college is not in the list
                      </small>
                    </label>
                  </div>
                }
              </Grid>
            </Grid>
          )}
          {(board || college) && (
            <Grid container>
              <Grid item sm={3} xs={12}>
                <label
                  htmlFor="percentage"
                  className="fs-12 fw-500 mb-1 my-auto"
                >
                  Percentage
                </label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <input
                  type="number"
                  value={percentage}
                  onChange={(e) => {
                    setPercentage(e.target.value);
                  }}
                  class="form-control shadow_1-lightest profile-field "
                />
              </Grid>
            </Grid>
          )}
          {percentage && (
            <Grid container>
              <Grid item sm={3} xs={12}>
                <label
                  htmlFor="percentage"
                  className="fs-12 fw-500 mb-1 my-auto"
                >
                  Year of Passing
                </label>
              </Grid>
              <Grid item sm={9} xs={12}>
                <input
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  vaue={endDate}
                  type="date"
                  class="form-control shadow_1-lightest profile-field "
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>

      <div className="row mt-4 pt-2 justify-content-center">
        <button
          onClick={handleSubmit}
          ref={disableSaveButton}
          className="btn btn-main btn-sm shadow-main"
        >
          Save Details
        </button>
      </div>
    </>
  );
};

export default EducationForm;

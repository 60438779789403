import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: "-40px",
      // marginLeft: "-40px",
      display: "flex",
      paddingLeft:"4%",
      paddingRight:"4%",
      // border: "2px solid #000",
      flexDirection: "row",
    },
    leftPaper: {
      backgroundColor: theme.palette.background.paper,
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      height: "auto",
      width: "260px",
      // border: "2px solid #000",
      background: "#ffffff",
      paddingTop: "20px",
      position: "fixed",
      paddingBottom: '20px',
      marginTop: '39px',
      borderRadius: '25px',
      marginLeft: '18px',
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      borderRight:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
    },
    leftMenuBox: {
      width: "200px",
      height: "38px",
      marginTop: "15px",
      
      borderRadius: 5,
      // backgroundColor: theme.palette.background.default,
      border:
        theme.palette.type === "dark"
          ? "2px solid rgba(255, 255, 255, 0.12)"
          : "2px solid #cdedf6",
      color: theme.palette.type === "dark" ? "white" : "black",
    },
    leftMenuBox1: {
      width: "200px",
      height: "38px",
      marginTop: "15px",
      
      borderRadius: 5,
      // backgroundColor: theme.palette.background.default,
      border:
        theme.palette.type === "dark"
          ? "2px solid rgba(255, 255, 255, 0.12)"
          : "2px solid #cdedf6",
      color: theme.palette.type === "dark" ? "white" : "black",
    },
    leftMenuBoxSelected: {
      width: "200px",
      height: "38px",
      marginTop: "15px",
      // marginLeft: "10px",
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      fontWeight: "bold",
      color: "white",
    },
    leftMenuBoxSelected1: {
      width: "200px",
      height: "38px",
      marginTop: "15px",
      // marginLeft: "10px",
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      fontWeight: "bold",
      color: "#fff",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    paperStyle: {
      marginLeft: "290px",
      borderRadius:"30px",
      backgroundColor: theme.palette.background.paper,
      width: "75%",
      marginTop: "35px",
      paddingTop: "30px",
      paddingLeft: "20px",
      paddingBottom: "15px",
      marginBottom: "35px",
      paddingRight: "20px",
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
    },
    fieldDiv: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginTop: "20px",
      alignItems: "flex-start",
      // border: "2px solid #000",
    },
    inputBox: {
      width: "400px",
      paddingLeft: "5px",
      height: "35px",
      // borderRadius: "5px",
    },
    inputBox1: {
      width: "650px",
      height: "35px",
      paddingLeft: "5px",
    },
    inputBox2: {
      width: "650px",

      paddingLeft: "5px",
      paddingRight: "5px",
    },
    inputBox3: {
      width: "400px",
      height: "35px",

      marginBottom: "18px",
    },
    heading: {
      width: "200px",
      // border: "2px solid #000",
      height: "30px",
      paddingLeft: "5px",
      display: "flex",
      flexDirection: "row",
    },
    radioStyle: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    notRequired: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    dropDown: {
      width: "180px",
    },
    dropDown1: {
      width: "300px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "30px",
      marginTop: "50px",
      marginBottom: "50px",
    },
    button: {
      borderRadius:"30px",
      backgroundColor: "#3282C4",
      color: "#fff",
      paddingRight: "20px",
      paddingLeft: "20px",
      "&:hover": {
        backgroundColor: "#3282C4",
      },
    },
    button2: {
      borderRadius:"30px",
      backgroundColor: "#DF8184",
      paddingRight: "20px",
      paddingLeft: "20px",
      marginLeft: "15px",
      fontWeight: "bold",
    },
    button3: {
      borderRadius:"30px",
      backgroundColor: "#6FCF97",
      paddingRight: "20px",
      paddingLeft: "20px",
      marginLeft: "15px",
      fontWeight: "bold",
    },
    tableContainer: {
      // border: "1px solid #000",
      width: "98%",
      marginLeft: "0px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      flexDirection: "column",
      marginTop: "50px",
      paddingBottom: "30px",
    },
    tab: {
      // border: "1px solid #000",
      width: "100%",
      marginLeft: "0px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    tab1: {
      // border: "1px solid #000",
      width: "100%",
      marginLeft: "0px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    tableHeader: {
      height: "40px",
      width: "48%",
      borderRight: "1px solid #000",
      alignItems: "center",
      paddingLeft: "20px",
      display: "flex",
      backgroundColor: "#E0E0E0",
      fontWeight: "600",
    },
    tableHeader3: {
      height: "40px",
      width: "48%",
      borderRight: "1px solid #000",
      alignItems: "center",
      paddingLeft: "20px",
      display: "flex",
      backgroundColor: "#F2F2F2",
      fontWeight: "600",
    },
    tableHeader1: {
      height: "40px",
      width: "48%",
      // border: "1px solid #000",
      alignItems: "center",
      paddingLeft: "50px",
      display: "flex",
      backgroundColor: "#2F80ED",
      fontWeight: "600",
      color: "#fff",
    },
    tableHeader2: {
      height: "40px",
      width: "48%",
      // border: "1px solid #000",
      alignItems: "center",
      paddingLeft: "50px",
      display: "flex",
      backgroundColor: "#4594FF",
      fontWeight: "600",
      color: "#fff",
    },
    buttonT1: {
      borderRadius:"30px",
      backgroundColor: "#424242",
      color: "#fff",
    },
    buttonT2: {
      borderRadius:"30px",
      backgroundColor: "#424242",
      color: "#fff",
    },
    images: {
      marginLeft: "190px",
      marginTop: "35px",
      marginBottom: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    avatarBox: {
      marginLeft: "240px",
      marginTop: "35px",
      marginBottom: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    emptyLogo: {
      marginLeft: "240px",
      marginTop: "35px",
      marginBottom: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "200px",
      height: "200px",
      borderRadius: "100px",
      backgroundColor: "gray",
      color: "white",
      fontWeight: "bold",
    },
    emptyBanner: {
      marginLeft: "240px",
      marginTop: "35px",
      marginBottom: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "600px",
      height: "200px",
      backgroundColor: "gray",
      color: "white",
      fontWeight: "bold",
    },
    selectorBox: {
      display: "flex",
      alignItems: "center",
    },
    buttonLabel: {
      borderRadius:"30px",
      justifyContent: "left",
      marginLeft: "8%",
    },
    "@media screen and (max-width: 900px)": {
      selectorBox: {
        flexDirection: "column",
        alignItems: "baseline",
      },
    },
  })
);

export default useStyles;

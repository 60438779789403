import {
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import { loadCSS } from "fg-loadcss";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import SelectSearch from "react-select-search";
import styles from "./referral_css";
import { BackendBaseApi } from "../../constants/constants";

import {
  Box,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  Tooltip,
} from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import AvailableCredits from "../../assets/icon/receive-amount.png";
import Refer1 from "../../assets/images/refer1.png";
import Refer2 from "../../assets/images/refer2.png";
import Refer3 from "../../assets/images/Refer3.png";

import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modal1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1000px",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    boxColor: {
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
    },

    expButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "center",
      contentAlign: "center",
      margin: "auto",
      justifyContent: "center",
      float: "center",
      display: "flex",
      boderRadius: "75px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    referralMessage: {
      fontSize: "16px",
      color: "brown",
    },
  })
);

/* This component is the common referral code for the Referral Section --> User Settings */

const UserReferral = () => {
  const [showReferralTable, setShowTransactionReferralTable] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [referralHistory, setReferralHistory] = useState([]);
  const [showCircularProgressBar, setShowCircularProgressBar] = useState(true);
  const token = localStorage.getItem("gw_token");
  const userId = localStorage.getItem("user_id");
  const [referralHistoryCount, setReferralHistoryCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    getReferralCode();
  }, [page]);

  const getReferralCode = () => {
    setLoading(true);

    Axios.get(
      BackendBaseApi.PRAVESH +
        "api/referrals/user_referrals/?user_id=" +
        userId +
        "&page=" +
        page,
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
      .then((res) => {
        if (res.status === 200 && res.data.success === true) {
          setLoading(true);
          if (referralData.length === 0)
            setReferralData(res.data.data.referral_code);
          setReferralHistory(res.data.data.referral_history.results);
          setReferralHistoryCount(res.data.data.referral_history.count);
          setShowTransactionReferralTable(true);
          setShowCircularProgressBar(false);
        } else {
          alert.error(res.data.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert.error(err);
        setShowCircularProgressBar(false);
      });
  };
  const theme = useTheme();
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <Box style={styles.paperStyle} className={classes.boxColor}>
        <Grid container spacing={3}>
          {referralData && referralData.length > 0 ? (
            referralData.map((item, index) => (
              <Grid item xs={6} sm={6}>
                <Box
                  padding="6%"
                  boxShadow="0 10px 25px 0 rgb(172 185 196 / 28%)"
                  borderRadius="30px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  margin="2%"
                >
                  <img
                    src={index === 2 ? Refer3 : index === 0 ? Refer1 : Refer2}
                    width={index === 2 ? "200px" : "120px"}
                    height="auto"
                  />
                  <p
                    style={{
                      fontSize: "16px",
                      color: theme.palette.type === "dark" ? "#fff" : "black",
                    }}
                  >
                    {item?.text}
                  </p>
                  {
                    <>
                      <p
                        style={{
                          fontSize: "19px",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        {item?.code}
                      </p>
                      <p className={classes.referralMessage}>{item?.message}</p>
                    </>
                  }
                </Box>
              </Grid>
            ))
          ) : (
            <Grid item xs={6} sm={6}>
              <Box
                padding="6%"
                boxShadow="0 10px 25px 0 rgb(172 185 196 / 28%)"
                borderRadius="8px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                margin="2%"
              >
                <img src={AvailableCredits} width="60px" height="auto" />
                <br />
                <p style={{ fontSize: "16px" }}>Oops</p>
                <p className={classes.referralMessage}>
                  No Active Referral Code for you!!
                </p>
              </Box>
            </Grid>
          )}
        </Grid>
        <Box padding="4% 0%" margin="2%">
          <p
            onClick={() => setShowTransactionReferralTable(!showReferralTable)}
            style={{
              display: "flex",
              color: "#007BFF",
              fontSize: "19px",
              cursor: "pointer",
            }}
          >
            {showReferralTable
              ? "Hide Referral history"
              : "View All Referral History"}{" "}
            <span>
              <ArrowRightAlt style={{ width: "43px", height: "35px" }} />
            </span>
          </p>
          {showReferralTable && (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ fontSize: 13 }}>
                        S.No.
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: 13 }}>
                        Name
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: 13 }}>
                        Email
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: 13 }}>
                        Amount
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: 13 }}>
                        Referral Code
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: 13 }}>
                        Service Type
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: 13 }}>
                        User Type
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: 13 }}>
                        DateTime
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading ? (
                      referralHistory.map((item, index) => (
                        <TableRow>
                          <StyledTableCell style={{ fontSize: 13 }}>
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell style={{ fontSize: 13 }}>
                            {item.name}
                          </StyledTableCell>
                          <StyledTableCell style={{ fontSize: 13 }}>
                            {item.email}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontWeight: "bold",
                              color: "#219653",
                            }}
                          >
                            {"+"} {item.amount}
                          </StyledTableCell>

                          <StyledTableCell style={{ fontSize: 13 }}>
                            {item.refer_code}
                          </StyledTableCell>
                          <StyledTableCell style={{ fontSize: 13 }}>
                            {item.service_type}
                          </StyledTableCell>
                          <StyledTableCell style={{ fontSize: 13 }}>
                            {item.user_type}
                          </StyledTableCell>
                          <StyledTableCell style={{ fontSize: 13 }}>
                            {moment(item.create_date).format(
                              "Do MMMM, YYYY, h:mm:ss a"
                            )}
                          </StyledTableCell>
                        </TableRow>
                      ))
                    ) : referralHistory?.length > 0 ? (
                      <>
                        <StyledTableCell
                          style={{ fontSize: 13 }}
                        ></StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: 13 }}
                        ></StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: 13 }}
                        ></StyledTableCell>
                        <StyledTableCell style={{ fontSize: 13 }}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CircularProgress size={25} />
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: 13 }}
                        ></StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: 13 }}
                        ></StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: 13 }}
                        ></StyledTableCell>
                      </>
                    ) : (
                      "No History Present"
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Pagination
                  count={Math.ceil(referralHistoryCount / 10)}
                  page={page}
                  onChange={handleChangePage}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default UserReferral;

const StyledTableCell = withStyles(() => ({
  // head: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  body: {
    fontSize: 13,
    padding: "1px 16px 1px 16px",
  },
  "@media only screen and (max-width: 900px)": {
    body: {
      fontSize: 13,
    },
  },
}))(TableCell);

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { fade, ThemeProvider, withStyles, makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Button,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  InputLabel,
  Typography,
  OutlinedInput,
  Paper,
  Grid,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { display } from "@material-ui/system";
import Logo from "../../../../../assets/images/sidepanel/logo.png";
import Facebook from "../../../../../assets/facebook.png";
import Google from "../../../../../assets/google-icon.png";
import { emailRegex, FACEBOOK_APP_ID, NEXT_APP_ENDPOINT } from "../../../../../constants/constants";
import { Link, useHistory, useLocation } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useAlert } from "react-alert";
import Countdown from "react-countdown";
import check from "../../../../../assets/checked-new.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    backgroundColor: "#007bff",
    borderRadius: "30px",
    boxShadow: "none",
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "auto",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  linkDisabled: {
    cursor: "no-allowed",
    color: "grey",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);
export default function EmailVerified(props) {
  const { timerComplete, setTimerComplete, handleSubmit } = props;
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.grid}>
            <img src={Logo} height="70px" width="80px" />
            <br />

            <>
              <Typography variant="body1" style={{ fontFamily: " Nunito" }}>
                <b>Great! You've successfully signed up for GetWork. </b>{" "}
              </Typography>
              <br />
              <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
                Your account is now active. You can{" "}
                <Link to="/login">
                  <span variant="body2" className={classes.text} style={{ cursor: "pointer" }}>
                    <b>login </b>
                  </span>
                </Link>{" "}
                here
              </Typography>
            </>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

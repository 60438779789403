import { Hidden } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Footer from "../../../components/public/FooterNew/Footer";
import FooterDomain from "../../../components/public/FooterNew/Footer/FooterDomain";
import ForgotPasswordForm from "../../../components/public/LogIn/Card/Form/ForgotPasswordForm";
import MobileTabRegister from "../../../components/public/LogIn/Header/MobileTabRegister";
import StudentHeader from "../../../components/public/LogIn/Header/StudentHeader";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";
import { BackendBaseApi } from "../../../constants/constants";

function ForgotPassword() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const params = useParams();
  const [value, setValue] = useState(0);
  const [resetEmail, setResetEmail] = useState();
  const [step, setStep] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    Axios.post(BackendBaseApi.PRAVESH + "api/email/generate", { email: resetEmail })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          alert.success(res.data.data.message);
          setStep(step + 1);
        } else {
          alert.error(res.data.error);
          return;
        }
      })
      .catch((err) => {
        localStorage.clear();
        alert.error("Invalid Session!! Please try again");
      });
    setButtonClicked(false);
    //alert.success(`An email has been sent to ${Email}`)
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      {domain?.domain ? <DomainNavbar /> : <Navbar />}

      <Hidden smDown>
        {" "}
        <StudentHeader
          showGrid={false}
          forgotPasswordForm={true}
          type={"forgot-password"}
          handleSubmit={handleSubmit}
          loading={loading}
          resetEmail={resetEmail}
          setResetEmail={setResetEmail}
          passwordResetStep={step}
          setPasswordResetStep={setStep}
          buttonClickedPassword={buttonClicked}
          setButtonClickedPassword={setButtonClicked}
        />
      </Hidden>

      <Hidden smUp>
        {" "}
        <div style={{ marginTop: "20%" }}>
          <ForgotPasswordForm
            loading={loading}
            resetEmail={resetEmail}
            setResetEmail={setResetEmail}
            handleSubmit={handleSubmit}
            passwordResetStep={step}
            setPasswordResetStep={setStep}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
          />
        </div>
      </Hidden>
      {!domain?.domain ? <Footer /> : <FooterDomain />}
    </>
  );
}

export default ForgotPassword;

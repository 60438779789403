import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Box, Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, FormControlLabel, Switch, Typography, useTheme } from "@material-ui/core";
import Filter_icon from "../../../assets/common/Filter2.png";
import SelectSearch from "react-select-search";
import BootstrapTooltip from "../../../common/Tooltip/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
    backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
  },
}));

function Filter(props) {
  const {
    jobTypes,
    profiles,
    allCompanies,
    onlyClosed,
    setOnlyClosed,
    campusType,
    setCampusType,
    setSelectedCompany,
    setSelectedJobType,
    setJobProfile,
    jobProfileName,
    setJobProfileName,
    jobProfile,
    selectedJobType,
    setTrackData,
    setJobProfileObject,
    selectedCompany,
    applicants,

    setEnd,
    setNextUrl,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div
        style={applicants ? { position: "fixed", width: "76%", left: "20%", top: "8.5%" } : { position: "fixed", width: "59%" }}
        id={theme.palette.type === "dark" && "theme-select-search"}
      >
        <Paper className={classes.paper} style={{ borderRadius: "30px" }}>
          <Grid container direction="row" justify="space-around" alignItems="center">
            <Grid container item xs={1} spacing={1}>
              <BootstrapTooltip title="Job Filter">
                <img src={Filter_icon} fontSize="inherit" alt="" height="35px" />
              </BootstrapTooltip>
            </Grid>
            <Grid container item xs={2} spacing={1}>
              <SelectSearch
                options={
                  jobTypes &&
                  jobTypes.length > 0 &&
                  jobTypes.map((item) => ({
                    name: item.job_type,
                    value: item.id,
                  }))
                }
                onChange={(val) => {
                  setSelectedJobType(val);
                  if (applicants) {
                    setEnd(false);
                    setNextUrl(null);
                    setTrackData([]);
                  }
                  setJobProfile("");
                  if (localStorage.getItem("user_type") !== "College") setSelectedCompany("All");
                }}
                placeholder="Job type"
                value={selectedJobType}
                search
              />{" "}
            </Grid>
            <Grid container item xs={2} spacing={1}>
              <SelectSearch
                options={
                  profiles &&
                  profiles.length > 0 &&
                  profiles.map((item) => ({
                    name: item.job_title,
                    value: item.job_id,
                  }))
                }
                value={jobProfileName}
                onChange={(val) => {
                  setJobProfile(val);
                  setJobProfileName(val);
                  if (applicants) {
                    setEnd(false);
                    setNextUrl(null);
                    setTrackData([]);
                  }
                  if (localStorage.getItem("user_type") !== "College") setSelectedCompany("All");

                  profiles.length > 0 &&
                    localStorage.getItem("user_type") === "College" &&
                    profiles.map((item) => {
                      if (item.job_id === val) setJobProfileObject(item);
                    });
                }}
                placeholder="Profile"
                search
                disabled={profiles.length === 0 ? true : false}
              />{" "}
            </Grid>
            {campusType === true && (
              <Grid container item xs={2} spacing={1}>
                <SelectSearch
                  options={
                    allCompanies &&
                    allCompanies.length > 0 &&
                    allCompanies.map((item) => ({
                      name: item.name,
                      value: item.id,
                    }))
                  }
                  onChange={(val) => {
                    setSelectedCompany(val);
                    if (applicants) {
                      setEnd(false);
                      setNextUrl(null);
                      setTrackData([]);
                    }
                    // setJobProfile("");
                  }}
                  value={selectedCompany}
                  placeholder="College"
                  disabled={allCompanies.length === 1 ? true : false}
                  search
                />{" "}
              </Grid>
            )}
            {localStorage.getItem("user_type") === "College" && (
              <Grid container item xs={2} spacing={1}>
                <SelectSearch
                  options={
                    allCompanies &&
                    allCompanies.length > 0 &&
                    allCompanies.map((item) => ({
                      name: item.company_name,
                      value: item.company_id,
                    }))
                  }
                  value={selectedCompany}
                  onChange={(val) => {
                    setSelectedCompany(val);

                    setJobProfile("");
                  }}
                  placeholder="Company"
                  search
                />{" "}
              </Grid>
            )}

            {!applicants && (
              <Grid container item xs={2} spacing={1} style={{ fontSize: "15px" }}>
                <FormControlLabel
                  style={{ fontSize: "15px" }}
                  value="end"
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      style={{ borderRadius: "30px" }}
                      checked={onlyClosed}
                      onChange={(e) => {
                        setOnlyClosed(e.target.checked);
                        setJobProfile("");
                        if (localStorage.getItem("user_type") !== "College") setSelectedCompany("All");
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 13 }}>Closed Jobs</span>}
                  labelPlacement="end"
                />
              </Grid>
            )}
            {localStorage.getItem("user_type") !== "College" && (
              <Grid container item xs={2} spacing={1}>
                <FormControlLabel
                  value="end"
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      style={{ borderRadius: "30px" }}
                      checked={campusType}
                      onChange={(e) => {
                        setSelectedCompany("");
                        setCampusType(e.target.checked);
                        if (applicants) {
                          setEnd(false);
                          setNextUrl(null);
                          setTrackData([]);
                        }
                        setJobProfile("");
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 13 }}>On Campus</span>}
                  labelPlacement="end"
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </div>
    </>
  );
}

export default Filter;

import { Box, Grid, makeStyles } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { BackendBaseApi } from "../../constants/constants";
import youtube from "../../assets/images/youtube.png";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../bundles/common/components/UI/Loader";

import { httpRequest } from "../../utils/httpRequest";
import BlogCard from "./ImageGroupBlogs";
const useStyles = makeStyles({
  OuterTopBox: {
    width: "97%",
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "4px",
    marginTop: "60px",
    marginLeft: "1.5%",
    marginBottom: "20px",
  },
  OuterBox: {
    width: "97%",
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "4px",
    marginLeft: "1.5%",
    marginBottom: "20px",
  },
});
export default function SingleBlog() {
  const location = useLocation();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  //   const { data } = location;
  const [blogData, setBlogData] = useState([]);
  const [singleBlogData, setSingleBlogData] = useState({});

  const getSingleBlogData = async () => {
    setLoader(true);
    const res = await Axios.get(
      `${BackendBaseApi.PRAVESH}api/blog/?blog_id=${
        location.pathname.split("/")[3]
      }`
    );
    setSingleBlogData(res.data.data);
    setLoader(false);
  };

  useEffect(() => {
    GetData(BackendBaseApi.PRAVESH, `api/blog/all/`, null, setBlogData);
    getSingleBlogData();
  }, []);

  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setLoading(true);
    let res = await httpRequest(baseUrl, endPoint, body);
    updateState(res.data);
    setLoading(false);
  };

  return (
    <div className="single-blog">
      {loader && loading ? (
        <Loader />
      ) : (
        <>
          <Box className={classes.OuterTopBox}>
            <Grid container justify="flex-start">
              {/* <Grid xs={12} className="text-left" style={{ marginTop: "54px" }}>
            <span className="fs-12">content</span>
            <span className="fs-12 fw-700 "> {data.title}</span>
          </Grid> */}
            </Grid>
            <Grid container>
              <Grid xs={12} className="text-left">
                <h2 className="fw-700 text-left">{singleBlogData?.title}</h2>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "-30px" }}>
              <Grid xs={12}>
                <div className="flex align-item-center fs-12 mg-top-2 ">
                  <p>
                    By{" "}
                    <b>
                      {" "}
                      {singleBlogData?.author?.first_name}{" "}
                      {singleBlogData?.author?.last_name}
                    </b>
                  </p>
                </div>

                {singleBlogData?.youtube && (
                  <p style={{ fontSize: "14px" }}>
                    <a href={singleBlogData?.youtube} target="blank">
                      <img src={youtube} alt="" height="18px" />
                      &nbsp; Youtube{" "}
                    </a>
                  </p>
                )}
              </Grid>
            </Grid>
            <Grid container className="mg-top-2" style={{ marginTop: "-30px" }}>
              <Grid xs={12}>
                {singleBlogData?.image !== null && (
                  <img
                    width="100%"
                    height="400px"
                    src={singleBlogData?.image}
                    alt="blog"
                  />
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                xs={12}
                className="fw-300 text-left"
                style={{ fontSize: "16px", color: "#424242" }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: singleBlogData?.body }}
                />
                {/* {singleBlogData?.body} */}
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.OuterBox}>
            <Grid container>
              <Grid xs={12}>
                <div className="flex flex-col align-item-baseline">
                  <h2 className="fs-20 fw-700 float-left text-green heading-underline">
                    More Blogs
                  </h2>
                </div>
                <br />
                <BlogCard blog={blogData} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
}

import { Tooltip } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/PhoneOutlined";
import moment from "moment";
import React from "react";
import { useAlert } from "react-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import facebook from "../../../../../assets/common/facebook.png";
import github from "../../../../assets/png/github.png";
import instagram from "../../../../assets/png/instagram.png";
import linkedin from "../../../../assets/png/linkedin.png";
import user from "../../../../assets/png/user.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 15,
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    //maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    height: "100px",
    width: "100px",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  linkColor: {
    //  color: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  green: {
    width: "10px",
    marginLeft: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#12DC00",
  },
  red: {
    width: "10px",
    marginLeft: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "red",
  },
  resume: {
    height: "35px",
    boxShadow: "none",
    display: "flex",
    borderRadius: "30px",
    marginTop: "20px",
    fontWeight: "normal",
  },
  button: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  resumebtn: {
    borderRadius: "30px",
    boxShadow: "none",
    height: "40px",
    display: "flex",
    marginRight: "10px",
  },
}));

const OverViewNew = ({
  data,
  relevanceScore,
  scoreData,
  createdDate,
  actionsData,
  contactDetails,
  workExperience,
  education,
  salaryData,
}) => {
  const classes = useStyles();
  const contactData = (data) => {
    return [
      {
        icon: <PhoneIcon /* color="primary" */ style={{ color: "#6C757D" }} />,
        info: data?.phone ? data?.phone : "NA",
        link: `tel:+91${data?.phone}`,
      },
      {
        icon: (
          <MailOutlineIcon /* color="primary" */ style={{ color: "#6C757D" }} />
        ),
        info: data?.email ? data?.email : "NA",
        link: `mailto: ${data?.email}`,
      },
      // {
      //   icon: <LanguageIcon color="primary" />,
      //   info: "www.roshankapoor.in",
      //   link: "https://www.roshankapoor.in",
      // },
    ];
  };

  const checkSocialLinkImage = (name) => {
    let link_icon = "";
    switch (name) {
      case "Linkedin":
        link_icon = linkedin;
        return link_icon;

      case "Github":
        link_icon = github;
        return link_icon;

      case "Facebook":
        link_icon = facebook;
        return link_icon;

      case "Instagram":
        link_icon = instagram;
        return link_icon;
      default:
        break;
    }
  };

  const checkSocialLink = (name, value) => {
    let link = "";
    switch (name) {
      case "LinkedIn":
        link = value;
        return link;

      case "Github":
        link = value;
        return link;

      case "Facebook":
        link = value;
        return link;
      case "Instagram":
        link = value;
        return link;

      default:
        break;
    }
  };

  const alert = useAlert();
  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item style={{ marginRight: "12px" }}>
            <ButtonBase className={classes.image}>
              <img
                className={classes.img}
                alt="complex"
                src={data?.profileImage ? data?.profileImage : user}
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item container direction="column" spacing={2}>
              <div
                className={classes.text}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6" style={{ marginRight: "10px" }}>
                    {data?.name}
                  </Typography>

                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.002 11L19.562 8.21L19.902 4.52L16.292 3.7L14.402 0.5L11.002 1.96L7.60195 0.5L5.71195 3.69L2.10195 4.5L2.44195 8.2L0.00195312 11L2.44195 13.79L2.10195 17.49L5.71195 18.31L7.60195 21.5L11.002 20.03L14.402 21.49L16.292 18.3L19.902 17.48L19.562 13.79L22.002 11ZM9.09195 15.72L5.29195 11.91L6.77195 10.43L9.09195 12.76L14.942 6.89L16.422 8.37L9.09195 15.72Z"
                      fill="#00ADEF"
                    />
                  </svg>
                </div>
                <div
                  style={{
                    /* display: "flex", */
                    marginTop: "10px",
                    fontSize: "14px",
                  }}
                >
                  {workExperience?.length > 0
                    ? `${workExperience[0]?.jobDesignation} at ${workExperience[0]?.company}`
                    : education?.length > 0 &&
                      `${education[0]?.degreeSpec} in ${education[0]?.college_name}`}
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  {actionsData?.create_date && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <CalendarTodayOutlined
                        size={14}
                        style={{ color: "#6C757D" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                          marginLeft: 10,
                        }}
                      >
                        <Typography style={{ fontSize: 14, color: "#6C757D" }}>
                          {" "}
                          Applied On: {actionsData?.create_date}
                        </Typography>
                      </div>
                    </div>
                  )} */}
                {/* <Button
                variant="contained"
                color="primary"
                className={classes.button}
                style={{ marginLeft: 10 }}
              >
                Download
              </Button> */}
              </div>
              {/*    <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                > */}
              {/* <Typography variant="body2">
                        Status: {data?.isActive ? "Active" : "Blocked"}
                      </Typography> */}
              {/* <div className={data?.isActive ? classes.green : classes.red} /> */}
              {/*   {scoreData && (
                    <ScoreDialog score={relevanceScore} scoreData={scoreData} />
                  )}
                </div> */}

              {/*     <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.resumebtn}
                  >
                    <a
                      href={data?.resume}
                      download
                      target="blank"
                      className={classes.linkColor}
                      disabled={data?.resume ? false : true}
                    >
                      View Resume
                    </a>
                  </Button>
                </div> */}
              {/* {scoreData && <ScoreDialog score={relevanceScore} scoreData={scoreData} />} */}
              {/*    </div> */}

              <div style={{ /* display: "flex", */ marginTop: "10px" }}>
                {contactData(contactDetails).map((item) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}> {item.icon}</div>{" "}
                    <CopyToClipboard
                      text={item.info}
                      onCopy={() => alert.success("Copied!")}
                    >
                      <div style={{ fontSize: 14, color: "#00ADEF" }}>
                        {item.info}
                      </div>
                    </CopyToClipboard>
                  </div>
                ))}

                {/*       {contactDetails?.profile_url &&
                contactDetails?.profile_url?.length ? (
                  <>
                    <div style={{ marginRight: "10px", cursor: "pointer" }}>
                      {contactDetails?.profile_url !== null &&
                        contactDetails?.profile_url.map((item, ind) => (
                          <Tooltip title={item.name} placement="bottom">
                            <img
                              src={checkSocialLinkImage(item.name)}
                              height="22px"
                              alt="social-link"
                              onClick={() =>
                                window.open(
                                  checkSocialLink(item.name, item.value)
                                )
                              }
                              style={{ marginLeft: 10 }}
                            />
                          </Tooltip>
                        ))}
                    </div>
                  </>
                ) : null} */}
              </div>

              {/*    <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">{`Current CTC : ${
                  salaryData?.currentCtc ? salaryData?.currentCtc : "NA"
                } `}</Typography>
                <Typography variant="body2">{`Expected CTC : ${
                  salaryData?.expectedCtc ? salaryData?.expectedCtc : "NA"
                } `}</Typography>
              </div> */}
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <div style={{ /* display: "flex", */ marginTop: "10px" }}>
              {/*  {contactData(contactDetails).map((item) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}> {item.icon}</div>{" "}
                    <div style={{ fontSize: 14, color: "#6C757D" }}>
                      {item.info}
                    </div>
                  </div>
                ))} */}

              {contactDetails?.profile_url &&
              contactDetails?.profile_url?.length ? (
                <>
                  <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                    {contactDetails?.profile_url !== null &&
                      contactDetails?.profile_url.map(
                        (item, ind) =>
                          item?.logo && (
                            <Tooltip title={item.name} placement="bottom">
                              <img
                                src={
                                  item?.logo
                                    ? item?.logo
                                    : "" /* checkSocialLinkImage(item.name) */
                                }
                                height="22px"
                                alt="social-link"
                                onClick={() =>
                                  item?.value
                                    ? window.open(item.value)
                                    : () => {}
                                }
                                style={{ marginLeft: 10 }}
                              />
                            </Tooltip>
                          )
                      )}
                  </div>
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            {actionsData?.create_date && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {/*  <CalendarTodayOutlined size={14} style={{ color: "#6C757D" }} /> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    /*  marginTop: 10, */
                  }}
                >
                  <Typography
                    variant="body2" /* style={{ color: "#6C757D" }} */
                  >
                    Applied:{" "}
                    {moment(actionsData?.create_date).format("MMM DD, YYYY")}
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
          <Grid
            item
            md={9}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">{`Current CTC : ${
              salaryData?.currentCtc ? salaryData?.currentCtc : "NA"
            } `}</Typography>
            <Typography variant="body2">{`Expected CTC : ${
              salaryData?.expectedCtc ? salaryData?.expectedCtc : "NA"
            } `}</Typography>
            <Typography variant="body2">{`Notice Period : ${
              salaryData?.noticePeriod ? salaryData?.noticePeriod : "NA"
            }`}</Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default OverViewNew;

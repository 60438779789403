import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import next_icon from "../../../assets/common/next.svg";
import delete_icon from "../../../assets/common/delete_icon.svg";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";

import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

import Filter_icon from "../../../assets/common/Filter2.png";
import SelectSearch from "react-select-search";
import cir from "../../../assets/common/Gro.png";
import ci from "../../../assets/common/Icon_check.png";
import sub_user from "../../../assets/common/subUser.png";
import interview from "../../../assets/common/Interview1.png";
import SelectStep from "../../company/cards/Track/SelectStep";
import RejectApplicant from "../../company/cards/Track/RejectApplicant";
import InterviewDialog from "../Dialogs/InterviewDialog";
import AssignSubUserDialog from "../Dialogs/AssignSubUserDialog";
import download1 from "../../../assets/download1.png";
import up_icon from "../../../assets/common/up_icon.png";
import view_job from "../../../assets/details_job.png";

import BootstrapTooltip from "../../../common/Tooltip/Tooltip";
import CsvDownload from "react-json-to-csv";
import { CSVLink } from "react-csv";
import Share from "../../common/Share/Share";
import { Link, useParams } from "react-router-dom";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "Roboto",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paper1: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    //padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "80%",
    fontFamily: "Roboto",
    //marginLeft: "80px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
    backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
  },
  scrollButton: {
    backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
  },
  button: {
    color: "black",
    backgroundColor: "white",
    width: "60px",
  },
}));

function Action(props) {
  const {
    selectAllApplicants,
    setSelectAllApplicants,
    trackData,
    setTrackData,
    selectedApplicants,
    moveNextArray,
    setMoveNextArray,
    feedbackList,
    currentFeedback,
    setCurrentFeedback,
    setFeedbackSentState,
    feedbackSentState,
    feedbackTyped,
    setFeedbackTyped,
    setMoveButtonClicked,
    setSelectedApplicants,
    setFeedbackList,
    interviewData,
    setInterviewData,
    tabData,
    currentStateObject,
    setCurrentStateObject,
    subUserList,
    setSubUserList,
    jobTitle,
    excelData,
    subUserAssignment,
    setSubUserAssignment,
    loaderDownload,
    downloadButtonClicked,
    setDownloadButtonClicked,
    setRejectButtonClicked,
    rejectButtonClicked,
    feedbackListNew,
    jobProfileObject,
    show,
    hideScrollBar,
    setHideScrollBar,
    usersList,
    setUserList,
    getTrackDownloadData,
    csvdownload,
    filterObject,
    jobProfileName,
    jobProfileObjectShare,
    showDownload,
    campusType,
  } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });
  const [currentData, setCurrentData] = useState({});

  const [openFeedback, setOpenFeedback] = React.useState(false);

  const handleClickOpenFeedback = () => {
    setOpenFeedback(true);
  };

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };
  let { token } = useParams();

  const [openFeedbackReject, setOpenFeedbackReject] = React.useState(false);
  const [openInterview, setOpenInterview] = React.useState(false);
  const [openSubUser, setOpenSubUser] = React.useState(false);

  const handleClickOpenFeedbackReject = () => {
    setOpenFeedbackReject(true);
  };

  const handleCloseFeedbackReject = () => {
    setOpenFeedbackReject(false);
  };

  const handleOpenInterview = () => {
    setOpenInterview(true);
  };
  const handleCloseInterview = () => {
    setOpenInterview(false);
  };

  const handleOpenSubUser = () => {
    setOpenSubUser(true);
  };
  const handleCloseSubUser = () => {
    setOpenSubUser(false);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleSelectAll = (e) => {
    setSelectAllApplicants(e.target.checked);
    var array = [...trackData];
    array.forEach((item) => {
      item.checked = e.target.checked;
    });
    setTrackData(array);
  };

  const isChecked = () => {
    var boole = false;
    if (trackData?.length > 0) {
      if (trackData?.every((e) => e.checked) === false) boole = false;
      else boole = true;
    }
    return boole;
  };

  const [barPadding, setBarPadding] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("user_type") === "College") {
      setBarPadding(true);
    } else {
      if (currentStateObject.status_id !== 14 && currentStateObject.status_id !== 18) setBarPadding(false);
      else setBarPadding(true);
    }
  }, [currentStateObject, jobProfileObject]);

  return (
    <>
      <div className={classes.paper1}>
        <Paper className={classes.paper} style={barPadding || !showDownload ? { padding: 7, borderRadius: "30px" } : { borderRadius: "30px" }}>
          <FormGroup row>
            <Grid container direction="row" justify="space-evenly" alignItems="center">
              {show && (
                <>
                  <Grid container item xs={2} spacing={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<img src={cir} fontSize="inherit" alt="" height="35px" />}
                          checkedIcon={<img src={ci} fontSize="inherit" alt="" height="30px" />}
                          checked={isChecked()}
                          onChange={(e) => {
                            handleSelectAll(e);
                          }}
                          name="checkedH"
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>Select All</span>}
                      fontFamily="Roboto"
                    />
                  </Grid>
                  {selectedApplicants.length > 0 && (
                    <>
                      <Grid container item xs={1} spacing={3}>
                        <BootstrapTooltip title={"Move to Next step"} className="">
                          <Button onClick={handleClickOpenFeedback}>
                            <img alt="complex" src={next_icon} />
                          </Button>
                        </BootstrapTooltip>
                      </Grid>
                      <Grid container item xs={1} spacing={3}>
                        <BootstrapTooltip title={"Rejected"} className="">
                          <Button
                            onClick={() => {
                              setRejectButtonClicked(true);
                              handleClickOpenFeedbackReject();
                            }}
                          >
                            <img alt="complex" src={delete_icon} />
                          </Button>
                        </BootstrapTooltip>
                      </Grid>
                    </>
                  )}
                  {localStorage.getItem("user_type") === "Company" && (
                    <Grid container item xs={2} spacing={1}>
                      <BootstrapTooltip title="Assign Subuser after selecting applicants">
                        <IconButton aria-label="delete" style={{ fontSize: "13px" }} onClick={handleOpenSubUser} disabled={selectedApplicants.length > 0 ? false : true}>
                          <img src={sub_user} fontSize="13px" alt="" height="30px" /> {"   "}&nbsp; Assign Sub User
                        </IconButton>
                      </BootstrapTooltip>
                    </Grid>
                  )}
                  <>
                    {currentStateObject?.is_interviewed === true && localStorage.getItem("user_type") && (
                      <Grid container item xs={2} spacing={1}>
                        <BootstrapTooltip title="Schedule Interview after selecting applicants">
                          <IconButton aria-label="delete" style={{ fontSize: "13px" }} onClick={handleOpenInterview} disabled={selectedApplicants.length > 0 ? false : true}>
                            <img src={interview} fontSize="13px" alt="" height="30px" /> Schedule Interview
                          </IconButton>
                        </BootstrapTooltip>
                      </Grid>
                    )}
                  </>
                </>
              )}
              {trackData?.length > 0 && (
                <Grid container item xs={show ? 2 : 12} spacing={1} style={{ display: "flex", flexDirection: "row-reverse" }}>
                  {showDownload && (
                    <BootstrapTooltip title={loaderDownload ? "Downloading....." : "Click here to Download status wise applicants data"}>
                      <div>
                        {excelData.length > 0 ? (
                          <CSVLink
                            data={excelData}
                            style={{
                              border: "none",
                              backgroundColor: "#fff",
                              cursor: "pointer",
                              borderRadius: 30,
                            }}
                            ref={csvdownload}
                            filename={jobTitle !== "All" ? jobTitle.split(" ").join("_") + "_" + currentStateObject.tooltip + ".csv" : "All_Profiles.csv"}
                          >
                            <img src={download1} alt="video" height="30px" />
                          </CSVLink>
                        ) : (
                          <BootstrapTooltip title={loaderDownload ? "Downloading....." : "Click here to Download status wise applicants data"}>
                            <Button onClick={() => getTrackDownloadData(9)}>
                              {" "}
                              <img src={download1} alt="video" height="30px" />
                            </Button>
                          </BootstrapTooltip>
                        )}
                      </div>
                    </BootstrapTooltip>
                  )}
                </Grid>
              )}
            </Grid>
          </FormGroup>
        </Paper>
        {/* <div style={{ marginRight: -30, display: "flex", flexDirection: "row" }}> */}
        {!token && <Share jobProfileObject={jobProfileObjectShare} filterObject={filterObject} jobProfileName={jobProfileName} />}
        <BootstrapTooltip title="View Job Details">
          <Link to={`/public/job/${!campusType ? "offcampus" : "oncampus"}/${jobProfileObjectShare?.job_id}`} target="_blank">
            <IconButton aria-label="delete">
              <img src={view_job} height="30px" width="30px" />
            </IconButton>
          </Link>
        </BootstrapTooltip>
        {/* </div> */}
      </div>
      <Box display="flex" justifyContent="center">
        <IconButton
          aria-label="delete"
          onClick={() => {
            setHideScrollBar(!hideScrollBar);
          }}
          className={classes.scrollButton}
          style={
            hideScrollBar
              ? {
                  top: "65%",
                  position: "absolute",
                  width: 70,
                  height: 31,
                  transition: "all 0.3s ease-in-out 0s",
                  fontSize: "13px" 
                }
              : {
                  top: "86%",
                  position: "absolute",
                  width: 70,
                  height: 31,
                  fontSize: "13px",
                  transition: "all 0.3s ease-in-out 0s",
                }
          }
        >
          <img
            src={up_icon}
            fontSize="13px"
            alt=""
            height="16px"
            style={
              hideScrollBar
                ? {
                    transform: "rotate(180deg)",
                    transition: "transform 0.1s ease 0s",
                  }
                : {}
            }
          />
        </IconButton>
      </Box>

      <SelectStep
        handleClickOpen={handleClickOpenFeedback}
        handleClose={handleCloseFeedback}
        open={openFeedback}
        moveNextArray={moveNextArray}
        feedbackList={feedbackList}
        currentFeedback={currentFeedback}
        setCurrentFeedback={setCurrentFeedback}
        setFeedbackSentState={setFeedbackSentState}
        feedbackSentState={feedbackSentState}
        feedbackTyped={feedbackTyped}
        setFeedbackTyped={setFeedbackTyped}
        currentData={currentData}
        selectedApplicants={selectedApplicants}
        setSelectedApplicants={setSelectedApplicants}
        setMoveButtonClicked={setMoveButtonClicked}
        showWholeApplicantList={true}
      />

      <AssignSubUserDialog
        open={openSubUser}
        handleOpen={handleOpenSubUser}
        handleClose={handleCloseSubUser}
        subUserList={subUserList}
        setSubUserList={setSubUserList}
        subUserAssignment={subUserAssignment}
        setSubUserAssignment={setSubUserAssignment}
        usersList={usersList}
        setUserList={setUserList}
      />

      <RejectApplicant
        handleClickOpen={handleClickOpenFeedbackReject}
        handleClose={handleCloseFeedbackReject}
        open={openFeedbackReject}
        moveNextArray={moveNextArray}
        feedbackList={feedbackList}
        currentFeedback={currentFeedback}
        setCurrentFeedback={setCurrentFeedback}
        setFeedbackSentState={setFeedbackSentState}
        feedbackSentState={feedbackSentState}
        feedbackTyped={feedbackTyped}
        setFeedbackTyped={setFeedbackTyped}
        currentData={currentData}
        selectedApplicants={selectedApplicants}
        setSelectedApplicants={setSelectedApplicants}
        setMoveButtonClicked={setMoveButtonClicked}
        setFeedbackList={setFeedbackList}
        showWholeApplicantList={true}
        setRejectButtonClicked={setRejectButtonClicked}
        rejectButtonClicked={rejectButtonClicked}
        feedbackListNew={feedbackListNew}
      />

      <InterviewDialog
        open={openInterview}
        handleOpen={handleOpenInterview}
        handleClose={handleCloseInterview}
        majorData={interviewData}
        setMajorData={setInterviewData}
        currentStateObject={currentStateObject}
      />
    </>
  );
}

export default Action;

import React, { useEffect, useState } from "react";
import { Breakpoint, BreakpointProvider } from "react-socks";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Navbar from "../Navbar/StudentNavbar";
import "./UI/UI.css";
import StudentProfileEducation from "./StudentProfileEducation";
import StudentProfileLinks from "./StudentProfileLinks";
import StudentProfilePersonal from "./StudentProfilePersonal";
import StudentProfileSkills from "./StudentProfileSkills";
import StudentProfileWorkExperience from "./StudentProfileWorkExperience";
import SudentProfileProjects from "./SudentProfileProjects";

const StudentTabs = () => (
  <>
    <BreakpointProvider>
      <Breakpoint large up>
        <Tabs defaultIndex={1}>
          <TabList>
            <Tab>
              {/* <span
                className="fs-14 fw-500 "
                style={{ letterSpacing: "0.02rem" }}
              > */}
              Education
              {/* </span> */}
            </Tab>
            <Tab>
              <span
                className="fs-14 fw-500 "
                style={{ letterSpacing: "0.02rem" }}
              >
                Work Experience
              </span>
            </Tab>
            <Tab>
              <span
                className="fs-14 fw-500 "
                style={{ letterSpacing: "0.02rem" }}
              >
                Projects
              </span>
            </Tab>
            <Tab>
              <span
                className="fs-14 fw-500 "
                style={{ letterSpacing: "0.02rem" }}
              >
                Skills
              </span>
            </Tab>
            <Tab>
              <span
                className="fs-14 fw-500 "
                style={{ letterSpacing: "0.02rem" }}
              >
                Links
              </span>
            </Tab>
          </TabList>

          {/* <TabPanel>
                        <StudentProfilePersonal />
                    </TabPanel> */}

          <TabPanel>
            <StudentProfileEducation />
          </TabPanel>

          <TabPanel>
            <StudentProfileWorkExperience />
          </TabPanel>

          <TabPanel>
            <SudentProfileProjects />
          </TabPanel>

          <TabPanel>
            <StudentProfileSkills />
          </TabPanel>

          <TabPanel>
            <StudentProfileLinks />
          </TabPanel>
        </Tabs>
      </Breakpoint>

      <Breakpoint medium down>
        <Tabs>
          <TabList>
            <Tab>
              <span className="fs-14 text-uppercase fw-500">Personal</span>
            </Tab>
            <Tab>
              <span className="fs-14 text-uppercase fw-500 ">Education</span>
            </Tab>
            <Tab>
              <span className="fs-14 text-uppercase fw-500 ">
                Work Experience
              </span>
            </Tab>
            <Tab>
              <span className="fs-14 text-uppercase fw-500 ">Projects</span>
            </Tab>
            <Tab>
              <span className="fs-14 text-uppercase fw-500 ">Skills</span>
            </Tab>
            <Tab>
              <span className="fs-14 text-uppercase fw-500 ">Links</span>
            </Tab>
          </TabList>

          <TabPanel>
            <StudentProfilePersonal />
          </TabPanel>

          <TabPanel>
            <StudentProfileEducation />
          </TabPanel>

          <TabPanel>
            <StudentProfileWorkExperience />
          </TabPanel>

          <TabPanel>
            <SudentProfileProjects />
          </TabPanel>

          <TabPanel>
            <StudentProfileSkills />
          </TabPanel>

          <TabPanel>
            <StudentProfileLinks />
          </TabPanel>
        </Tabs>
      </Breakpoint>
    </BreakpointProvider>
  </>
);

const StudentProfile = ({ studentData }) => {
  const [studentDetails, setStudentDetails] = useState();

  useEffect(() => {
    setStudentDetails(JSON.parse(localStorage.getItem("user_details")));
  }, []);

  return (
    <>
      <Navbar />
      <div className="row profile-card-holder" style={{ marginTop: "2.5rem" }}>
        <div className="col-lg-3 col-sm-12 ml-2 ml-md-0 d-none d-lg-block ">
          <StudentProfilePersonal />
        </div>
        <div className="col-lg-8 col-sm-12 ml-2 ml-md-0">
          <div className="card">
            <div className="card-body profile-section">
              <StudentTabs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentProfile;

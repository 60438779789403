import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../../bundles/common/components/UI/Loader";
import Footer from "../../../components/public/FooterNew/Footer";
import FooterDomain from "../../../components/public/FooterNew/Footer/FooterDomain";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";
// import Footer from "../../../components/public/Resume/Footer/Footer";
import Layout from "../../../components/public/Resume/Layout";
import { BackendBaseApi } from "../../../constants/constants";

function Resume({ id }) {
  const alert = useAlert();
  const params = useParams();
  const location = useLocation();
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(false);
  const checkRoute = () => {
    if (
      location.pathname !== "/studen/dashboard" ||
      location.pathname !== "/studen/connection"
    ) {
      if (params.id.includes("-")) {
        var split = params.id.split("-");
        var new_id = split[split.length - 1];
        return new_id;
      } else return params.id;
    } else return id;
  };

  const getData = () => {
    var headers =
      localStorage.getItem("gw_token") &&
      localStorage.getItem("gw_token") !== "null"
        ? {
            Authorization: "Token " + localStorage.getItem("gw_token"),
          }
        : {};
    setLoader(true);
    Axios.get(
      BackendBaseApi.PRAVESH +
        `api/shared/public_profile/?user_id=${checkRoute()}`,
      {
        headers: headers,
      }
    )
      .then((res) => {
        if (res.data.success) {
          setAllData(res.data.data);
        } else {
          alert.error("Some Error Occured");
        }
        setLoader(false);
      })
      .catch((err) => {
        alert.error("Some Error Occured");
        setLoader(true);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  const domain = useSelector(
    (state) => state.SubDomainDetails.subDomainDetails
  );
  const [loaderNav, setLoaderNav] = useState(true);
  const currentUserType = localStorage.getItem("user_type");

  useEffect(() => {
    setTimeout(function () {
      setLoaderNav(false);
    }, 1000);
  }, []);
  return (
    <>
      {loaderNav ? (
        <></>
      ) : domain?.domain ? (
        <DomainNavbar />
      ) : (
        <Navbar isShown={currentUserType === "Student" ? true : false} />
      )}
      {loader ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Layout data={allData} />
          {!domain?.domain ? <Footer /> : <FooterDomain />}{" "}
        </>
      )}
    </>
  );
}

export default Resume;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NoJobs from "../../../assets/images/no-jobs.png";
import Loader from "../../common/UI/Loader";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/common/httpRequest";
import AssignmentJobCard from "../JobsCard/AssignmentJobCard";
import Layout from "../Layout/Layout";
import FilterForm from "./FilterForm.js";

const useStyles = makeStyles((theme) =>
  createStyles({
    emptyLogo: {
      width: "300px",
      height: "auto",
    },
    emptyTitle: {
      fontSize: "36px",
      fontWeight: "bold",
      textAlign: "center",
    },
    emptySubtitle: {
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    "@media screen and (max-width: 768px)": {
      emptyLogo: {
        width: "150px",
        height: "auto",
      },
      emptyTitle: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        marginTop: "4%",
      },
      emptySubtitle: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
  })
);

export default function Index() {
  const classes = useStyles();
  const [jobData, setJobData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filterValue, setFilterValue] = useState("All");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const data = JSON.parse(localStorage.getItem("user_details"));
  const [open, setOpen] = React.useState(false);
  const [allJobProfiles, setAllJobProfiles] = useState([]);
  const [jobProfile, setJobProfile] = useState("All");

  const [jobType, setJobType] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("All");
  const [jobProfileName, setJobProfileName] = useState("All");
  const [jobTypeName, setJobTypeName] = useState("All");

  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("All");
  const [campusType, setCampusType] = useState("All");
  const [interviewType, setInterviewType] = useState("All");
  const [count, setCount] = useState(0);
  const filterDetails = data.student_user_details.education.filter(
    (item) => item.is_current === true
  );

  useEffect(() => {
    let num = 0;
    if (selectedJobType && selectedJobType !== "All") {
      num += 1;
    }
    if (campusType && campusType !== "All") {
      num += 1;
    }
    if (jobProfile && jobProfile !== "All") {
      num += 1;
    }

    setCount(num);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobType, campusType, jobProfile]);
  var flag = 0;
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getJobProfiles = async () => {
    const appendParam = () => {
      if (selectedJobType === "All" || selectedJobType === "") {
        return "";
      } else return `&job_type_id=${btoa(selectedJobType)}`;
    };
    // const appendParam2 = () => {
    //   if (selectedCompany === "All") {
    //     return "";
    //   } else return `&company_id=${btoa(selectedCompany)}`;
    // };

    // const checkOnlyClosed = () => {
    //   if (!onlyClosed) {
    //     return "";
    //   } else {
    //     return `&job_status=${btoa("CLOSE")}`;
    //   }
    // };

    const checkCampusType = () => {
      if (!campusType) {
        return `&hiring_type=${btoa("OFF CAMPUS")}`;
      } else {
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      }
    };

    // const checkJobStatus = () => {
    //   return `&status=${btoa(activeTab)}`;
    // };

    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/student/applied_jobs/?user_id=${btoa(
          JSON.parse(localStorage.getItem("user_details")).id
        )}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });

    let profiles = [];

    let obj = { job_id: "All", job_title: "All" };
    let list = [obj, ...res.data.data];

    let temp = {};
    list.forEach((e) => {
      temp[e.job_title] = e.job_id;
    });
    setAllJobProfiles({ ...temp });
  };

  const getJobTypes = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch(
      (err) => {
        throw err;
      }
    );
    let obj = { id: "All", job_type: "All" };
    let list = [obj, ...res.data.data];

    let temp = {};
    list.forEach((e) => {
      temp[e.job_type] = e.id;
    });
    setJobType({ ...temp });
  };

  useEffect(() => {
    getJobTypes();
    // getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getJobProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobType, selectedCompany, campusType]);

  useEffect(() => {
    const token = localStorage.getItem("gw_token");
    const checkJobProfile = () => {
      flag = 1;
      if (jobProfile === "All" || jobProfile === " ") {
        return "";
      } else {
        return `&job_id=${btoa(jobProfile)}`;
      }
    };

    const checkJobType = () => {
      flag = 1;
      if (selectedJobType === "All" || selectedJobType === " ") {
        return "";
      } else {
        return `&job_type_id=${btoa(parseInt(selectedJobType))}`;
      }
    };

    const checkCampusType = () => {
      if (campusType === "All" || campusType === " ") {
        return "";
      } else if (campusType === "OFF CAMPUS") {
        return `&hiring_type=${btoa("OFF CAMPUS")}`;
      } else {
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      }
    };

    GetData(
      BackendBaseApi.NIYUKTI,
      `job/student/assignment/?user_id=${window.btoa(
        data.id
      )}&college_id=${btoa(
        filterDetails[0].college
      )}&state=T1BFTg==${checkJobType()}${checkJobProfile()}${checkCampusType()}`,
      { headers: token },
      setJobData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobType, jobProfile, campusType]);
  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setLoading(true);
    let res = await httpRequest(baseUrl, endPoint, body);

    if (res.data.next === null) {
      setEnd(true);
      if (flag === 1) updateState(res.data.results);
      else updateState(jobData.concat([...res.data.results]));
    } else {
      setNewURL(res.data.next.slice(0, 20));
      setNewEndPoint(res.data.next.slice(20));
      updateState(jobData.concat([...res.data.results]));
    }
    setLoading(false);

    setLoader(false);
  };
  const handleChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleScroll = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        const token = localStorage.getItem("gw_token");
        GetData(newURL, newEndPoint, { headers: token }, setJobData);
      }
    }
  };

  return (
    <>
      <Layout>
        {!loader ? (
          <div className="container__jobs" style={{ marginTop: "54px" }}>
            {/* {data.map((card, index) => <NewJobCard key={index} {...card} />)} */}
            <Box marginTop="10px" display="flex" justifyContent="flex-end">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  handleOpen();
                }}
              >
                <span style={{ fontSize: "14px", color: "black" }}>
                  {" "}
                  <b>Filter ({count + "/3"})</b>&nbsp;
                </span>
                <Tooltip title="Filter">
                  <FilterListIcon />
                </Tooltip>
              </IconButton>

              <FilterForm
                handleOpen={handleOpen}
                handleClose={handleClose}
                open={open}
                setOpen={setOpen}
                profiles={allJobProfiles}
                jobProfile={jobProfile}
                jobTypes={jobType}
                selectedJobType={selectedJobType}
                setSelectedJobType={setSelectedJobType}
                setJobProfile={setJobProfile}
                allCompanies={allCompanies}
                setSelectedCompany={setSelectedCompany}
                campusType={campusType}
                setCampusType={setCampusType}
                jobProfileName={jobProfileName}
                setJobProfileName={setJobProfileName}
                jobTypeName={jobTypeName}
                setJobTypeName={setJobTypeName}
                invited={false}
                interviewType={interviewType}
                setInterviewType={setInterviewType}
                //   selectedNames={selectedNames}
                //   majorData={majorData}
                //   setMajorData={setMajorData}
              />
            </Box>
            {!loading ? (
              jobData.length > 0 ? (
                <Box onScroll={handleScroll}>
                  <AssignmentJobCard
                    data={jobData}
                    fullView={false}
                    invite={false}
                    reject={true}
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginTop="5%"
                  paddingBottom="5%"
                >
                  <img src={NoJobs} className={classes.emptyLogo} />
                  <p className={classes.emptyTitle}>No Assignments</p>
                  <Link to="/student/dashboard">
                    <Button
                      style={{
                        width: 238,
                        height: 44,
                        backgroundColor: "#3282C4",
                        textTransform: "capitalize",
                        color: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#3282C4",
                        },
                      }}
                    >
                      Go Back To Home
                    </Button>
                  </Link>
                </Box>
              )
            ) : (
              <Box>
                <Loader />
              </Box>
            )}
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </Layout>
    </>
  );
}

{
  /* <Grid container justify='center'>

<Layout>

 

 
<div className='applied mg-left-10'>
    
   
   
   
   
    <Grid container> 
        <Grid xs={12} className='big-card-res'>
        
 {       jobData.length ?  <JobsCard data={jobData} reject={true}/> :   <div className='text-gray fs-20 fw-700 mg-top-20'>No Jobs</div>}
        </Grid>
    </Grid>
    
</div>  
 </Layout>
 </Grid> */
}

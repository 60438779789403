import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import NumberFormat from "react-number-format";
import generateCsv from "../../../../../common/generateCsv/generateCsv";
import {
  BackendBaseApi,
  websiteRegexNew,
} from "../../../../../constants/constants";
import { executeScrollCommon } from "../../../../../utils/utility";
import CurrencyComponent from "../../../../common/JobPostNew/FormComponents/CurrencyComponent";
import CustomizedCheckboxes from "../../../../common/JobPostNew/FormComponents/CustomizedCheckboxes";
import StyledRadio from "../../../../common/JobPostNew/FormComponents/CustomizedRadio";
import CommonChip from "../../../../CommonChip/CommonChip";
import WorkExpDetailsCards from "./WorkExpDetailsCards";

export default function WorkExperience(props) {
  const {
    setButtonClicked,
    workExpDetails,
    setWorkExpDetails,
    workExpDetailsErrors,
    setWorkExpDetailsErrors,
    openAddWorkExp,
    setOpenAddWorkExp,
    workExpDetailsList,
    setWorkExpDetailsList,
    allJobType,
    allCompanies,
    setAllCompanies,
    buttonClicked,
    allSkills,
    expLoader,
    fetchPrecentage,
    allCities,
  } = props;
  const classes = useStyles();
  const [saveLoader, setSaveLoader] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  const [currentCtc, setCurrentCtc] = useState(null);
  const [expectedCtc, setExpectedCtc] = useState(null);
  const [noticePeriod, setNoticePeriod] = useState(null);
  const [buttonCtcClicked, setButtonCtcClicked] = useState(false);
  const [isConfidential, setIsConfidentail] = useState(false);
  const [isNegotiable, setIsNegotiable] = useState(false);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false);
  const [salaryError, setSalaryError] = useState({
    cur_ctc: "",
    expected_ctc: "",
    notice_period: "",
    preferred_locations: "",
  });

  const alert = useAlert();
  const workExpFromRef = useRef(null);
  const [validate, setValidate] = useState(false);

  const validateData = () => {
    var job_title_error = "";
    var job_type_error = "";
    var company_error = "";
    var start_date_error = "";
    var end_date_error = "";
    var website_error = "";

    setValidate(false);
    if (workExpDetails.job_title === "") {
      job_title_error = "Please Enter Job Title";
      setValidate(true);
    }
    if (!workExpDetails.job_type) {
      job_type_error = "Please Enter Job Type";
      setValidate(true);
    }
    if (
      workExpDetails.website !== "" &&
      !websiteRegexNew.test(workExpDetails.website)
    ) {
      website_error = "Please Enter Web url correctly (include http or https)";
      setValidate(true);
    }
    if (!workExpDetails.company && !workExpDetails.companyNotListed) {
      company_error = "Please Enter Company Name";
      setValidate(true);
    }

    if (
      workExpDetails.companyNotListed &&
      !workExpDetails.company_typed === ""
    ) {
      company_error = "Please Enter Company Name";
      setValidate(true);
    }
    if (!workExpDetails.start_date) {
      start_date_error = "Please Enter Start Date";
      setValidate(true);
    }
    if (!workExpDetails.end_date && !workExpDetails.currently_working) {
      end_date_error = "Please Enter End Date";
      setValidate(true);
    }

    setWorkExpDetailsErrors({
      ...workExpDetailsErrors,
      job_title: job_title_error,
      job_type: job_type_error,
      company: company_error,
      start_date: start_date_error,
      end_date: end_date_error,
      website: website_error,
    });

    setButtonClicked(true);
  };

  const validateCtc = () => {
    console.log("jkernfkcenrkc", preferredLocation);
    if (currentCtc === null || currentCtc === "")
      return setSalaryError({ cur_ctc: "Please Enter Current CTC in lakhs" });
    if (expectedCtc === null || expectedCtc === "")
      return setSalaryError({
        expected_ctc: "Please Enter Expected CTC in lakhs",
      });
    if (noticePeriod === null || noticePeriod === "")
      return setSalaryError({
        notice_period: "Please Enter Notice Period in days",
      });
    if (!preferredLocation || preferredLocation.length === 0)
      return setSalaryError({
        preferred_locations: "Please add atleast 1 preferred locations",
      });
    setSalaryError({
      cur_ctc: "",
      expected_ctc: "",
      notice_period: "",
      preferred_locations: "",
    });
    setButtonCtcClicked(true);
  };

  const salaryData = {
    cur_ctc: parseInt(currentCtc),
    expected_ctc: parseInt(expectedCtc),
    notice_period: parseInt(noticePeriod),
    salary_validation: {
      is_confidential: isConfidential,
      is_negotialble: isNegotiable,
    },
    preferred_locations: preferredLocation,
    exp_year: years,
    exp_month: months,
  };

  const addSalary = () => {
    setSaveLoader(true);
    Axios.post(BackendBaseApi.PRAVESH + `api/student/ctc/`, salaryData, {
      headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setSaveLoader(false);
          setButtonCtcClicked(false);
          getSalary();
          setAdditionalInfoOpen(false);
        } else {
          alert.error(res.data.error);
          setSaveLoader(false);
          setButtonCtcClicked(false);
        }
      })
      .catch((err) => {
        alert.error("There was some error adding your Salary. Try again later");
        setSaveLoader(false);
        setButtonCtcClicked(false);
      });
  };

  useEffect(() => {
    getSalary();
  }, []);

  const [mainAddData, setMainAddData] = useState(null);

  const getSalary = () => {
    Axios.get(BackendBaseApi.PRAVESH + `api/student/ctc/`, {
      headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
    })
      .then((res) => {
        if (res.data) {
          setCurrentCtc(res.data.data.cur_ctc);
          setExpectedCtc(res.data.data.expected_ctc);
          setNoticePeriod(res.data.data.notice_period);
          setIsConfidentail(res.data.data.salary_validation?.is_confidential);
          setIsNegotiable(res.data.data.salary_validation?.is_negotialble);
          setPreferredLocation(
            res.data.data.preferred_locations
              ? res.data.data.preferred_locations
              : []
          );
          setYears(res.data.data.exp_year);
          setMonths(res.data.data.exp_month);
          setMainAddData(res.data.data);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error(
          "There was some error fetching your Salary. Try again later"
        );
        setSaveLoader(false);
      });
  };

  useEffect(() => {
    if (!validate && buttonCtcClicked) {
      addSalary();
    }
  }, [buttonCtcClicked]);

  useEffect(() => {
    if (!validate && buttonClicked) {
      if (editOpen) {
        if (editId) handleEdit();
        else handleEditParse();
      } else addExperience();
    }
  }, [buttonClicked]);

  const checkJobType = (id) => {
    var boole = null;
    allJobType.map((item) => {
      if (item.id === id) boole = item.job_type;
    });
    return boole;
  };

  const addExperience = () => {
    setSaveLoader(true);
    let arr = [...workExpDetailsList];
    let temp = {
      company_id: workExpDetails.company ? workExpDetails.company.id : null,
      company_name: workExpDetails.company
        ? workExpDetails.company.company
        : workExpDetails?.company_typed,
      company_website: workExpDetails?.website || null,
      is_current_working: workExpDetails?.end_date ? false : true,
      job_type_id: workExpDetails.job_type ? workExpDetails.job_type : null,
      job_type: checkJobType(parseInt(workExpDetails.job_type))
        ? checkJobType(parseInt(workExpDetails.job_type))
        : null,
      job_designation: workExpDetails?.job_title,
      job_description: workExpDetails?.description
        ? workExpDetails?.description
        : null,
      start_date: workExpDetails?.start_date
        ? moment(moment(workExpDetails?.start_date, "YYYY-mm").toDate()).format(
            "mm/YYYY"
          )
        : null,
      end_date: workExpDetails?.end_date
        ? moment(moment(workExpDetails?.end_date, "YYYY-mm").toDate()).format(
            "mm/YYYY"
          )
        : null,
      skills: workExpDetails.skills.length > 0 ? workExpDetails.skills : null,
      job_type_name: checkJobType(parseInt(workExpDetails.job_type))
        ? checkJobType(parseInt(workExpDetails.job_type))
        : null,
    };
    Axios.post(
      BackendBaseApi.PRAVESH + `api/company/work_experience/`,
      {
        company_id: workExpDetails.company ? workExpDetails.company.id : null,
        company_name: workExpDetails.company
          ? workExpDetails.company.company
          : workExpDetails?.company_typed,
        company_website: workExpDetails?.website || null,
        is_current_working: workExpDetails?.end_date ? false : true,
        job_type_id: workExpDetails.job_type ? workExpDetails.job_type : null,
        job_type: checkJobType(parseInt(workExpDetails.job_type))
          ? checkJobType(parseInt(workExpDetails.job_type))
          : null,
        job_designation: workExpDetails?.job_title,
        job_description: workExpDetails?.description
          ? workExpDetails?.description
          : null,
        start_date: workExpDetails?.start_date
          ? moment(
              moment(workExpDetails?.start_date, "YYYY-mm").toDate()
            ).format("mm/YYYY")
          : null,
        end_date: workExpDetails?.end_date
          ? moment(moment(workExpDetails?.end_date, "YYYY-mm").toDate()).format(
              "mm/YYYY"
            )
          : null,
        skills: workExpDetails.skills.length > 0 ? workExpDetails.skills : null,
      },
      {
        headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          arr.push(res.data.data.data);
          setWorkExpDetailsList(arr);
          setSaveLoader(false);
          setButtonClicked(false);
          fetchPrecentage();

          setOpenAddWorkExp(false);
        } else {
          alert.error(res.data.error);
          setSaveLoader(false);
        }
      })
      .catch((err) => {
        alert.error(
          "There was some error adding your experience. Try again later"
        );
        setSaveLoader(false);
      });
  };

  const handleDelete = (id) => {
    Axios.delete(
      BackendBaseApi.PRAVESH + `api/company/work_experience/${id}/`,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          let temp = workExpDetailsList.filter((exp) => {
            return exp.id !== id;
          });
          setWorkExpDetailsList(temp);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("There was some error deleting this work. Try again later");
      });
  };

  const handleEdit = () => {
    setSaveLoader(true);
    var arr = [...workExpDetailsList];
    Axios.patch(
      BackendBaseApi.PRAVESH + `api/company/work_experience/${editId}/`,
      {
        company_id: workExpDetails.company ? workExpDetails.company.id : null,
        company_name: workExpDetails.company
          ? workExpDetails.company.company
          : workExpDetails?.company_typed,
        company_website: workExpDetails?.website || null,
        is_current_working: workExpDetails?.end_date ? false : true,
        job_type_id: workExpDetails.job_type ? workExpDetails.job_type : null,
        job_type: checkJobType(parseInt(workExpDetails.job_type))
          ? checkJobType(parseInt(workExpDetails.job_type))
          : null,
        job_designation: workExpDetails?.job_title,
        job_description: workExpDetails?.description
          ? workExpDetails?.description
          : null,
        start_date: workExpDetails?.start_date
          ? moment(
              moment(workExpDetails?.start_date, "YYYY-mm").toDate()
            ).format("mm/YYYY")
          : null,
        end_date: workExpDetails?.end_date
          ? moment(moment(workExpDetails?.end_date, "YYYY-mm").toDate()).format(
              "mm/YYYY"
            )
          : null,
        skills: workExpDetails.skills.length > 0 ? workExpDetails.skills : null,
      },
      {
        headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setEditOpen(false);
          setSaveLoader(false);
          setButtonClicked(false);

          var myArray = [];
          myArray.push(res.data.data.data);

          var res = arr.map(
            (obj) => myArray.find((o) => o.id === obj.id) || obj
          );
          setWorkExpDetailsList(res);
          setEditId(null);

          setOpenAddWorkExp(false);
          fetchPrecentage();
        } else {
          alert.error(res.data.error);
          setSaveLoader(false);
        }
      })
      .catch((err) => {
        setSaveLoader(false);
        alert.error(
          "There was some error updating your experience. Try again later"
        );
      });
  };

  const handleEditParse = () => {
    setSaveLoader(true);
    let arr = [...workExpDetailsList];
    let temp = {
      company_id: workExpDetails.company ? workExpDetails.company.id : null,
      company_name: workExpDetails.company
        ? workExpDetails.company.company
        : workExpDetails?.company_typed,
      company_website: workExpDetails?.website || null,
      is_current_working: workExpDetails?.end_date ? false : true,
      job_type_id: workExpDetails.job_type ? workExpDetails.job_type : null,
      job_type: checkJobType(parseInt(workExpDetails.job_type))
        ? checkJobType(parseInt(workExpDetails.job_type))
        : null,
      job_designation: workExpDetails?.job_title,
      job_description: workExpDetails?.description
        ? workExpDetails?.description
        : null,
      start_date: workExpDetails?.start_date
        ? moment(moment(workExpDetails?.start_date, "YYYY-mm").toDate()).format(
            "mm/YYYY"
          )
        : null,
      end_date: workExpDetails?.end_date
        ? moment(moment(workExpDetails?.end_date, "YYYY-mm").toDate()).format(
            "mm/YYYY"
          )
        : null,
      skills: workExpDetails.skills.length > 0 ? workExpDetails.skills : null,
      job_type_name: checkJobType(parseInt(workExpDetails.job_type))
        ? checkJobType(parseInt(workExpDetails.job_type))
        : null,
    };
    Axios.post(
      BackendBaseApi.PRAVESH + `api/company/work_experience/`,
      {
        company_id: workExpDetails.company ? workExpDetails.company.id : null,
        company_name: workExpDetails.company
          ? workExpDetails.company.company
          : workExpDetails?.company_typed,
        company_website: workExpDetails?.website || null,
        is_current_working: workExpDetails?.end_date ? false : true,
        job_type_id: workExpDetails.job_type ? workExpDetails.job_type : null,
        job_type: checkJobType(parseInt(workExpDetails.job_type))
          ? checkJobType(parseInt(workExpDetails.job_type))
          : null,
        job_designation: workExpDetails?.job_title,
        job_description: workExpDetails?.description
          ? workExpDetails?.description
          : null,
        start_date: workExpDetails?.start_date
          ? moment(
              moment(workExpDetails?.start_date, "YYYY-mm").toDate()
            ).format("mm/YYYY")
          : null,
        end_date: workExpDetails?.end_date
          ? moment(moment(workExpDetails?.end_date, "YYYY-mm").toDate()).format(
              "mm/YYYY"
            )
          : null,
        skills: workExpDetails.skills.length > 0 ? workExpDetails.skills : null,
      },
      {
        headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          temp["id"] = res.data.data.work_id;
          temp["is_parsed_data"] = false;

          arr[editIndex] = temp;
          setWorkExpDetailsList(arr);
          setSaveLoader(false);
          setButtonClicked(false);
          fetchPrecentage();

          setOpenAddWorkExp(false);
        } else {
          alert.error(res.data.error);
          setSaveLoader(false);
        }
      })
      .catch((err) => {
        setSaveLoader(false);

        alert.error(
          "There was some error adding your experience. Try again later"
        );
      });
  };

  const editBtn = (id) => {
    // alert.error(id);
    let arr = workExpDetailsList.filter((edu) => edu.id === id);
    let temp = arr[0];

    setOpenAddWorkExp(true);
    setEditId(id);
    setEditOpen(true);

    setWorkExpDetails({
      ...workExpDetails,
      company: temp.company_id
        ? {
            id: temp.company_id,
            company: temp.company_name,
          }
        : null,
      website: temp.company_website ? temp.company_website : "",
      companyNotListed: !temp.company_id ? true : false,
      company_typed: temp.company_name ? temp.company_name : "",
      job_title: temp.job_designation ? temp.job_designation : "",
      start_date: temp.start_date
        ? moment(moment(temp.start_date, "YYYY-mm-dd").toDate()).format(
            "YYYY-mm"
          )
        : null,
      end_date: temp.end_date
        ? moment(moment(temp.end_date, "YYYY-mm-dd").toDate()).format("YYYY-mm")
        : null,
      description: temp.job_description ? temp.job_description : "",
      skills: temp.skills || [],
      singleSkill: null,
      currently_working: temp?.end_date ? false : true,
      job_type: temp.job_type_id ? String(temp.job_type_id) : null,
    });
    executeScrollCommon(workExpFromRef);
  };

  const handleDeleteParse = (index) => {
    var array = [...workExpDetailsList];
    var item = array[index];

    deleteWorkExp(item, index, array);
  };

  const deleteWorkExp = (item, index, array) => {
    Axios.get(
      BackendBaseApi.PRAVESH +
        `api/education/v2/resume_upload/parsed_data_delete/?section=work_experience&id=${item.id}`,

      {
        headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("Work Experience Deleted Successfully!!");
          if (index > -1) {
            array.splice(index, 1);
          }
          setWorkExpDetailsList(array);
        } else {
          alert.error("Error DEleting Work Experience");
        }
      })
      .catch((err) => {
        alert.error(
          "There was some error adding your experience. Try again later"
        );
      });
  };

  const editBtnParse = (id) => {
    // alert.error(id);

    let temp = workExpDetailsList[id];

    setOpenAddWorkExp(true);
    setEditIndex(id);
    setEditOpen(true);

    setWorkExpDetails({
      ...workExpDetails,
      company: temp.company_id
        ? {
            id: temp.company_id,
            company: temp.company_name,
          }
        : null,
      website: temp.company_website ? temp.company_website : "",
      companyNotListed: !temp.company_id ? true : false,
      company_typed: temp.company_name ? temp.company_name : "",
      job_title: temp.job_designation ? temp.job_designation : "",
      start_date: temp.start_date ? temp.start_date : null,
      end_date: temp.end_date ? temp.end_date : null,
      description: temp.job_description ? temp.job_description : "",
      skills: temp.skills || [],
      singleSkill: null,
      currently_working: temp?.end_date ? false : true,
      job_type: String(temp.job_type_id),
    });
    executeScrollCommon(workExpFromRef);
  };

  useEffect(() => {
    if (workExpDetails.singleSkill) {
      var arr = [...workExpDetails.skills];
      arr.push(workExpDetails.singleSkill);
      const arrayUniqueByKey = [
        ...new Map(arr.map((item) => [item["skill_id"], item])).values(),
      ];
      setWorkExpDetails({ ...workExpDetails, skills: arrayUniqueByKey });
    }
  }, [workExpDetails.singleSkill]);

  const handleDeleteSkill = (id) => {
    var arr = [...workExpDetails.skills];
    const variableOne = arr.filter(
      (itemInArray) => itemInArray.skill_id !== id
    );
    setWorkExpDetails({ ...workExpDetails, skills: variableOne });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const handleDeleteLocation = (id) => {
    var arr = [...preferredLocation];
    const variableOne = arr.filter((itemInArray) => itemInArray.city_id !== id);
    setPreferredLocation(variableOne);
  };

  return (
    <>
      {expLoader ? (
        <CircularProgress
          size={40}
          style={{ color: "#007bff", marginTop: 30, marginBottom: 30 }}
        />
      ) : (
        <div>
          {workExpDetailsList &&
          workExpDetailsList?.length &&
          workExpDetailsList[0].is_parsed_data ? (
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                fontSize: 13,
                marginBottom: 10,
                color: "red",
              }}
            >
              Data fetched from Resume. Please check, edit and save the required
              Work Experiences
            </Typography>
          ) : null}
          {workExpDetailsList?.length > 0 &&
            workExpDetailsList.map((item, index) => (
              <>
                {" "}
                <WorkExpDetailsCards
                  data={item}
                  index={index}
                  editBtn={editBtn}
                  handleDelete={handleDelete}
                  editBtnParse={editBtnParse}
                  handleDeleteParse={handleDeleteParse}
                />
              </>
            ))}
          <Divider />
          <div ref={workExpFromRef}>
            {!openAddWorkExp && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#007bff",
                }}
                onClick={() => {
                  setOpenAddWorkExp(true);
                  setWorkExpDetails({
                    ...workExpDetails,
                    job_title: "",
                    company: null,
                    job_type: null,
                    start_date: null,
                    end_date: null,
                    description: "",
                    currently_working: false,
                    website: "",
                    skills: [],
                    singleSkill: null,
                    company_typed: "",
                    companyNotListed: false,
                  });
                }}
              >
                <IconButton style={{ color: "#007bff" }}>
                  <ControlPointIcon />
                </IconButton>
                <span>Add Work Experience</span>
              </div>
            )}
            <br />

            {openAddWorkExp && (
              <>
                <div>
                  <TextField
                    error={workExpDetailsErrors.job_title !== "" ? true : false}
                    key="Email"
                    id="standard-error-helper-text"
                    variant="outlined"
                    label={
                      <span className={classes.labelField}>
                        Job Designation&nbsp;
                        <span className={classes.RedStar}>*</span>
                      </span>
                    }
                    helperText={
                      <span
                        style={
                          workExpDetailsErrors.job_title !== ""
                            ? { color: "#D64045", fontSize: 13 }
                            : { color: "grey", fontSize: 13 }
                        }
                      >
                        {workExpDetailsErrors.job_title}
                      </span>
                    }
                    value={workExpDetails.job_title}
                    onChange={(e) => {
                      setWorkExpDetails({
                        ...workExpDetails,
                        job_title: e.target.value,
                      });
                      setWorkExpDetailsErrors({
                        ...workExpDetailsErrors,
                        job_title: "",
                      });

                      // setErrorMessages({ ...errorMessages, phone: "" });
                      setButtonClicked(false);
                    }}
                    InputProps={{
                      style: { fontSize: 13 },
                    }}
                    InputLabelProps={{
                      style: { backgroundColor: "#fff" },
                    }}
                    className={classes.fieldStyling1}
                  />
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      {workExpDetails.companyNotListed ? (
                        <TextField
                          error={
                            workExpDetailsErrors.company_typed !== ""
                              ? true
                              : false
                          }
                          key="Email"
                          id="standard-error-helper-text"
                          variant="outlined"
                          type="text"
                          label={
                            <span className={classes.labelField}>
                              Enter your Company&nbsp;
                              <span className={classes.RedStar}>*</span>
                            </span>
                          }
                          helperText={
                            <span
                              style={
                                workExpDetailsErrors.company_typed !== ""
                                  ? { color: "#D64045", fontSize: 13 }
                                  : { color: "grey", fontSize: 13 }
                              }
                            >
                              {workExpDetailsErrors.company_typed}
                            </span>
                          }
                          value={workExpDetails.company_typed}
                          onChange={(e) => {
                            setWorkExpDetails({
                              ...workExpDetails,
                              company_typed: e.target.value,
                            });
                            setWorkExpDetailsErrors({
                              ...workExpDetailsErrors,
                              company_typed: "",
                            });

                            setButtonClicked(false);
                          }}
                          InputProps={{
                            style: { fontSize: 13 },
                          }}
                          InputLabelProps={{
                            style: { backgroundColor: "#fff" },
                          }}
                          className={classes.fieldStyling}
                        />
                      ) : (
                        <Autocomplete
                          value={workExpDetails.company}
                          openOnFocus
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setWorkExpDetails({
                                ...workExpDetails,
                                company: newValue,
                              });
                              setWorkExpDetailsErrors({
                                ...workExpDetailsErrors,
                                company: "",
                              });

                              setButtonClicked(false);
                            }
                          }}
                          id="role345"
                          options={allCompanies}
                          getOptionLabel={(option) => option.company}
                          renderOption={(option) => {
                            return (
                              <span
                                style={{ fontSize: 13 }}
                              >{`${option.company}`}</span>
                            );
                          }}
                          className={classes.fieldStyling}
                          renderInput={(params) => {
                            params.inputProps.className = classes.comboOptions;
                            return (
                              <TextField
                                {...params}
                                label={
                                  <span style={{ fontSize: 13 }}>
                                    Company Name{" "}
                                    <span className={classes.RedStar}>*</span>
                                  </span>
                                }
                                variant="outlined"
                                error={
                                  workExpDetailsErrors.company !== ""
                                    ? true
                                    : false
                                }
                                helperText={
                                  <span
                                    style={
                                      workExpDetailsErrors.company !== ""
                                        ? { color: "#D64045", fontSize: 13 }
                                        : { color: "grey", fontSize: 13 }
                                    }
                                  >
                                    {workExpDetailsErrors.company}
                                  </span>
                                }
                              />
                            );
                          }}
                        />
                      )}
                      <FormControlLabel
                        className={classes.fieldStyling1}
                        value={workExpDetails.companyNotListed}
                        style={{ marginLeft: 0 }}
                        control={
                          <CustomizedCheckboxes
                            checked={workExpDetails.companyNotListed}
                            onChange={(e) => {
                              setWorkExpDetails({
                                ...workExpDetails,
                                companyNotListed:
                                  !workExpDetails.companyNotListed,
                                company_typed: "",
                                company: null,
                              });
                            }}
                            name={"college_not_listed"}
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                            color="primary"
                          />
                        }
                        label={
                          <span style={{ fontSize: 13 }}>
                            My Company is not Listed
                          </span>
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={
                          workExpDetailsErrors.website !== "" ? true : false
                        }
                        key="Email"
                        id="standard-error-helper-text"
                        variant="outlined"
                        label={
                          <span className={classes.labelField}>
                            Company Website
                          </span>
                        }
                        helperText={
                          <span
                            style={
                              workExpDetailsErrors.website !== ""
                                ? { color: "#D64045", fontSize: 13 }
                                : { color: "grey", fontSize: 13 }
                            }
                          >
                            {workExpDetailsErrors.website}
                          </span>
                        }
                        value={workExpDetails.website}
                        onChange={(e) => {
                          setWorkExpDetails({
                            ...workExpDetails,
                            website: e.target.value,
                          });
                          setWorkExpDetailsErrors({
                            ...workExpDetailsErrors,
                            website: "",
                          });

                          // setErrorMessages({ ...errorMessages, phone: "" });
                          setButtonClicked(false);
                        }}
                        InputProps={{
                          style: { fontSize: 13 },
                        }}
                        InputLabelProps={{
                          style: { backgroundColor: "#fff" },
                        }}
                        className={classes.fieldStyling}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div>
                  <Autocomplete
                    value={workExpDetails.singleSkill}
                    openOnFocus
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setWorkExpDetails({
                          ...workExpDetails,
                          singleSkill: newValue,
                        });
                        setButtonClicked(false);
                      }
                    }}
                    id="role345"
                    options={allSkills}
                    getOptionLabel={(option) => option.skill_name}
                    renderOption={(option) => {
                      return (
                        <span
                          style={{ fontSize: 13 }}
                        >{`${option.skill_name}`}</span>
                      );
                    }}
                    className={classes.fieldStyling1}
                    renderInput={(params) => {
                      params.inputProps.className = classes.comboOptions;
                      return (
                        <TextField
                          {...params}
                          label={
                            <span style={{ fontSize: 13 }}>Select Skills</span>
                          }
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  {workExpDetails.skills.length > 0 &&
                    workExpDetails.skills.map((item) => (
                      <CommonChip
                        label={item.skill_name}
                        onDelete={() => handleDeleteSkill(item.skill_id)}
                      />
                    ))}
                </div>

                <div style={{ marginTop: 10 }}>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Job Type &nbsp;&nbsp;
                    {workExpDetailsErrors.job_type !== "" ? (
                      <span
                        style={{
                          color: "rgb(214, 64, 69)",
                          fontSize: 13,
                        }}
                      >
                        Please Enter Job Type
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(214, 64, 69)",
                          fontSize: 13,
                        }}
                      >
                        *
                      </span>
                    )}
                  </Typography>

                  <FormControl component="fieldset" style={{ display: "flex" }}>
                    {/* <FormLabel component="legend">Job Type</FormLabel> */}
                    <RadioGroup
                      aria-label="gender"
                      name="customized-radios"
                      value={workExpDetails.job_type}
                      onChange={(e) => {
                        setWorkExpDetails({
                          ...workExpDetails,
                          job_type: String(e.target.value),
                        });
                        setWorkExpDetailsErrors({
                          ...workExpDetailsErrors,
                          job_type: "",
                        });

                        setButtonClicked(false);
                      }}
                    >
                      {allJobType?.map((item) => (
                        <FormControlLabel
                          value={String(item?.id)}
                          control={<StyledRadio />}
                          label={
                            <span style={{ fontSize: 14 }}>
                              {item?.job_type}
                            </span>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>

                  {/* {allJobType?.map((item) => (
              <FormControlLabel
                className={classes.fieldStyling1}
                //  value={educationDetails.is_current}
                style={{ marginLeft: 0 }}
                control={
                  <CustomizedCheckboxes
                    //  checked={educationDetails.is_current}
                    //  onChange={(e) => {
                    //    setEducationDetails({ ...educationDetails, is_current: !educationDetails.is_current });
                    //  }}
                    name={"college_not_listed"}
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    color="primary"
                  />
                }
                label={<span style={{ fontSize: 13 }}>{item?.job_type}</span>}
              />
            ))} */}
                </div>

                <div style={{ marginTop: 20 }}>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Time Period
                  </Typography>
                  <FormControlLabel
                    className={classes.fieldStyling1}
                    value={workExpDetails.currently_working}
                    style={{ marginLeft: 0 }}
                    control={
                      <CustomizedCheckboxes
                        checked={workExpDetails.currently_working}
                        onChange={(e) => {
                          setWorkExpDetails({
                            ...workExpDetails,
                            currently_working:
                              !workExpDetails.currently_working,
                          });
                        }}
                        name={"college_not_listed"}
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        color="primary"
                      />
                    }
                    label={
                      <span style={{ fontSize: 13 }}>
                        I currently work here
                      </span>
                    }
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={
                          workExpDetailsErrors.start_date !== "" ? true : false
                        }
                        key="Email"
                        id="standard-error-helper-text"
                        variant="outlined"
                        type="month"
                        format="mm/yyyy"
                        label={
                          <span className={classes.labelField}>
                            Start Date&nbsp;
                            <span className={classes.RedStar}>*</span>
                          </span>
                        }
                        helperText={
                          <span
                            style={
                              workExpDetailsErrors.start_date !== ""
                                ? { color: "#D64045", fontSize: 13 }
                                : { color: "grey", fontSize: 13 }
                            }
                          >
                            {workExpDetailsErrors.start_date}
                          </span>
                        }
                        value={workExpDetails.start_date}
                        onChange={(e) => {
                          setWorkExpDetails({
                            ...workExpDetails,
                            start_date: e.target.value,
                          });
                          setWorkExpDetailsErrors({
                            ...workExpDetailsErrors,
                            start_date: "",
                          });

                          // setErrorMessages({ ...errorMessages, phone: "" });
                          setButtonClicked(false);
                        }}
                        InputProps={{
                          style: { fontSize: 13 },
                        }}
                        InputLabelProps={{ shrink: true }}
                        className={classes.fieldStyling}
                      />
                    </Grid>
                    {!workExpDetails.currently_working && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          error={
                            workExpDetailsErrors.end_date !== "" ? true : false
                          }
                          key="Email"
                          id="standard-error-helper-text"
                          variant="outlined"
                          type="month"
                          label={
                            <span className={classes.labelField}>
                              End Date&nbsp;
                              <span className={classes.RedStar}>*</span>
                            </span>
                          }
                          helperText={
                            <span
                              style={
                                workExpDetailsErrors.end_date !== ""
                                  ? { color: "#D64045", fontSize: 13 }
                                  : { color: "grey", fontSize: 13 }
                              }
                            >
                              {workExpDetailsErrors.end_date}
                            </span>
                          }
                          value={workExpDetails.end_date}
                          onChange={(e) => {
                            setWorkExpDetails({
                              ...workExpDetails,
                              end_date: e.target.value,
                            });
                            setWorkExpDetailsErrors({
                              ...workExpDetailsErrors,
                              end_date: "",
                            });

                            // setErrorMessages({ ...errorMessages, phone: "" });
                            setButtonClicked(false);
                          }}
                          InputProps={{
                            style: { fontSize: 13 },
                          }}
                          InputLabelProps={{ shrink: true }}
                          className={classes.fieldStyling}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Description
                    <br />
                    <span style={{ color: "grey", fontSize: 13 }}>
                      In 30 or more words describe your professional
                      responsibilities and accomplishments (Eg: Maintained
                      inventory of office supplies and ordered as and when
                      needed)
                    </span>
                  </Typography>

                  <TextareaAutosize
                    rowsMin={5}
                    rowsMax={7}
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      borderColor: "#d2cdcd",
                      padding: 12,
                      outline: "none",
                      marginTop: 10,
                      fontSize: 13,
                    }}
                    aria-label="maximum height"
                    // placeholder="Maximum 100 words.."
                    maxLength={150}
                    onChange={(e) => {
                      setButtonClicked(false);
                      setWorkExpDetails({
                        ...workExpDetails,
                        description: e.target.value,
                      });
                    }}
                    value={workExpDetails.description}
                  />
                </div>

                <div style={{ display: "flex", justifyContent: "left" }}>
                  <Button onClick={validateData} style={{ color: "#007bff" }}>
                    {saveLoader ? (
                      <CircularProgress
                        style={{ color: "#007bff" }}
                        size={15}
                      />
                    ) : (
                      "Save Experience"
                    )}
                  </Button>

                  <Button
                    onClick={() => {
                      setOpenAddWorkExp(false);
                      setEditId(null);
                      setWorkExpDetails({
                        ...workExpDetails,
                        job_title: "",
                        company: null,
                        job_type: null,
                        start_date: null,
                        end_date: null,
                        description: "",
                        currently_working: false,
                        website: "",
                        skills: [],
                        singleSkill: null,
                      });
                    }}
                    style={{ color: "#007bff" }}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
          {!additionalInfoOpen && (
            <div>
              <div style={{ textAlign: "left", margin: "10px 0px" }}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={11}>
                      <Typography variant="body1" className={classes.heading}>
                        <b>Additional Information</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <EditIcon
                        style={{
                          color: "#007bff",
                          fontSize: 15,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setAdditionalInfoOpen(true);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {mainAddData?.cur_ctc && mainAddData?.cur_ctc > 0 && (
                    <Typography variant="body2" className={classes.para1}>
                      Current CTC: {mainAddData?.cur_ctc} LPA
                    </Typography>
                  )}

                  {mainAddData?.expected_ctc && mainAddData?.expected_ctc > 0 && (
                    <Typography variant="body2" className={classes.para1}>
                      Expected CTC: {mainAddData?.expected_ctc} LPA{" "}
                      {mainAddData?.salary_validation?.is_negotialble
                        ? "(Negotiable)"
                        : "(Not Negotiable)"}
                    </Typography>
                  )}
                  {mainAddData?.notice_period > 0 && (
                    <Typography variant="body2" className={classes.para1}>
                      Notice Period: {mainAddData?.notice_period} days
                    </Typography>
                  )}

                  {(mainAddData?.exp_year || mainAddData?.exp_month) && (
                    <Typography variant="body2" className={classes.para1}>
                      Total Work Experience:{" "}
                      {mainAddData?.exp_year ? (
                        <>{mainAddData?.exp_year} years, </>
                      ) : (
                        "0 years, "
                      )}{" "}
                      {mainAddData?.exp_month && (
                        <>{mainAddData?.exp_month} months</>
                      )}
                    </Typography>
                  )}
                  {mainAddData?.preferred_locations?.length > 0 && (
                    <Typography variant="body2" className={classes.para1}>
                      Preferred Locations:{" "}
                      {generateCsv(mainAddData?.preferred_locations, "city")}
                    </Typography>
                  )}
                </Paper>
              </div>
            </div>
          )}
          {additionalInfoOpen && (
            <div>
              <div style={{ textAlign: "left", margin: "10px 0px" }}>
                {" "}
                Additional Information{" "}
              </div>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <CurrencyComponent
                    state={currentCtc}
                    setState={setCurrentCtc}
                    labelName={"Current CTC (in INR)"}
                    width={"100%"}
                    responsive={classes.labelSize1}
                    error={false}
                    required={true}
                  />
                  {/*      <TextField
                    // error={workExpDetailsErrors.website !== "" ? true : false}
                    id="standard-error-helper-text"
                    variant="outlined"
                    type="number"
                    label={<span className={classes.labelField}>Current CTC (In Lacs)</span>}
                    helperText={<span style={{ color: "#ff4545", fontSize: 13 }}>{salaryError.cur_ctc ? salaryError.cur_ctc : null}</span>}
                    value={currentCtc}
                    onChange={(e) => {
                      if (e.target.value && parseFloat(e.target.value) >= 0) setCurrentCtc(e.target.value);
                      else setCurrentCtc(null);
                    }}
                    InputProps={{
                      style: { fontSize: 13 },
                    }}
                    InputLabelProps={
                      ({
                        style: { backgroundColor: "#fff" },
                      },
                      { shrink: true })
                    }
                    className={classes.fieldStyling}
                  /> */}
                  <FormControlLabel
                    className={classes.fieldStyling1}
                    value={isConfidential}
                    style={{ marginLeft: 0 }}
                    control={
                      <CustomizedCheckboxes
                        checked={isConfidential}
                        onChange={(e) => {
                          setIsConfidentail(e.target.checked);
                        }}
                        name={"college_not_listed"}
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        color="primary"
                      />
                    }
                    label={<span style={{ fontSize: 13 }}>Confidential?</span>}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CurrencyComponent
                    state={expectedCtc}
                    setState={setExpectedCtc}
                    labelName={"Expected CTC (in INR)"}
                    width={"100%"}
                    responsive={classes.labelSize1}
                    error={false}
                    required={true}
                  />
                  {/*      <TextField
                    id="standard-error-helper-text"
                    variant="outlined"
                    type="number"
                    label={
                      <span className={classes.labelField}>
                        Expected CTC (In Lacs)
                      </span>
                    }
                    helperText={
                      <span style={{ color: "#ff4545", fontSize: 13 }}>
                        {salaryError.expected_ctc
                          ? salaryError.expected_ctc
                          : null}
                      </span>
                    }
                    value={expectedCtc}
                    onChange={(e) => {
                      if (e.target.value && parseFloat(e.target.value) >= 0)
                        setExpectedCtc(e.target.value);
                      else setExpectedCtc(null);
                    }}
                    InputProps={{
                      style: { fontSize: 13 },
                    }}
                    InputLabelProps={
                      ({
                        style: { backgroundColor: "#fff" },
                      },
                      { shrink: true })
                    }
                    className={classes.fieldStyling}
                  /> */}
                  <FormControlLabel
                    className={classes.fieldStyling1}
                    value={isNegotiable}
                    style={{ marginLeft: 0 }}
                    control={
                      <CustomizedCheckboxes
                        checked={isNegotiable}
                        onChange={(e) => {
                          setIsNegotiable(e.target.checked);
                        }}
                        name={"college_not_listed"}
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        color="primary"
                      />
                    }
                    label={<span style={{ fontSize: 13 }}>Negotiable?</span>}
                  />
                </Grid>
              </Grid>
              <TextField
                id="standard-error-helper-text"
                variant="outlined"
                type="number"
                label={
                  <span className={classes.labelField}>
                    Notice Period (In Days)
                  </span>
                }
                helperText={
                  <span style={{ color: "#ff4545", fontSize: 12 }}>
                    {salaryError.notice_period ? (
                      salaryError.notice_period
                    ) : (
                      <span style={{ color: "gray" }}>
                        Enter 0(Zero) for Immediate Joining
                      </span>
                    )}
                  </span>
                }
                value={noticePeriod}
                onChange={(e) => setNoticePeriod(e.target.value)}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={{
                  style: { backgroundColor: "#fff" },
                }}
                className={classes.fieldStyling1}
                style={{ marginTop: 15 }}
              />
              <Typography variant="body1" style={{ textAlign: "left" }}>
                Total Work Experience
              </Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    // error={workExpDetailsErrors.website !== "" ? true : false}
                    id="standard-error-helper-text"
                    variant="outlined"
                    type="number"
                    label={<span className={classes.labelField}>Years</span>}
                    value={years}
                    onChange={(e) => {
                      setYears(e.target.value);
                    }}
                    InputProps={{
                      style: { fontSize: 13 },
                    }}
                    InputLabelProps={
                      ({
                        style: { backgroundColor: "#fff" },
                      },
                      { shrink: true })
                    }
                    className={classes.fieldStyling}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="standard-error-helper-text"
                    variant="outlined"
                    type="number"
                    label={<span className={classes.labelField}>Months</span>}
                    value={months}
                    onChange={(e) => setMonths(e.target.value)}
                    InputProps={{
                      style: { fontSize: 13 },
                    }}
                    InputLabelProps={
                      ({
                        style: { backgroundColor: "#fff" },
                      },
                      { shrink: true })
                    }
                    className={classes.fieldStyling}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  {preferredLocation?.map((item) => (
                    <CommonChip
                      label={item.city}
                      onDelete={() => handleDeleteLocation(item.city_id)}
                    />
                  ))}
                  <br />
                  <Autocomplete
                    // value={}
                    openOnFocus
                    onChange={(event, newValue) => {
                      if (newValue) {
                        var arr = [...preferredLocation];
                        arr.push(newValue);
                        setPreferredLocation(arr);
                      }
                    }}
                    id="role345"
                    options={allCities}
                    getOptionLabel={(option) => option.city}
                    renderOption={(option) => {
                      return (
                        <span style={{ fontSize: 13 }}>{`${option.city}`}</span>
                      );
                    }}
                    className={classes.fieldStyling1}
                    renderInput={(params) => {
                      params.inputProps.className = classes.comboOptions;
                      return (
                        <TextField
                          {...params}
                          label={
                            <span style={{ fontSize: 13 }}>
                              Preferred Locations
                            </span>
                          }
                          variant="outlined"
                          helperText={
                            <span style={{ color: "#ff4545", fontSize: 12 }}>
                              {salaryError?.preferred_locations
                                ? salaryError.preferred_locations
                                : null}
                            </span>
                          }
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <div style={{ display: "flex", justifyContent: "left" }}>
                <Button
                  onClick={validateCtc}
                  style={{
                    color: "#007bff",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  {saveLoader ? (
                    <CircularProgress style={{ color: "#007bff" }} size={15} />
                  ) : (
                    "Save Details"
                  )}
                </Button>
                <Button
                  onClick={() => {
                    setAdditionalInfoOpen(false);
                  }}
                  style={{ color: "#007bff" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    labelField: {
      fontSize: 13,
    },
    RedStar: {
      color: "red",
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      // marginBottom: 20,
    },
    fieldStyling1: {
      width: "100%",
      marginBottom: 10,
    },
    paper: {
      // minHeight: "600px",
      borderRadius: "20px",
      // textAlign: "center",
      padding: 12,
      width: "100%",
      marginBottom: 10,
    },
    heading: {
      textAlign: "left",
      fontSize: 15,
    },
    para1: {
      textAlign: "left",
      fontSize: 13,
    },
    para2: {
      color: "#6c757d",
      textAlign: "left",
      fontSize: 12,
    },
    para3: {
      textAlign: "left",
      fontSize: 12,
    },
  })
);

import React from 'react';
import { useSelector } from "react-redux";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {
    Button,
    makeStyles,
    createStyles,
    Box
  } from "@material-ui/core";


function ActivateCreditButton() {
    
    const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);

    const classes = useStyles();
    return( 
        creditInfo?.credit_status === "INACTIVE" ?
        <Box className={classes.BoxStyle}>
            <Button className={classes.RechargeButton} endIcon={<AccountBalanceWalletIcon />}>
                Activate Credits
            </Button>
        </Box> : null 
    )
}

const useStyles = makeStyles(() =>
  createStyles({
    RechargeButton: {
        width: "152px",
        height: "36px",
        backgroundColor: "#E63946",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: "ProximaNova",
        fontWeight: "bold",
        textTransform: "capitalize",
        color: "#fff",
        alignSelf: "center",
        borderRadius:30,
        "&:hover": {
          backgroundColor: "#E63946",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        },
        "&:focus": {
          outline: "none",
        },
      },
    BoxStyle: {
        marginTop: "15px", 
        paddingLeft: "23px"
    }
  })
);

export default ActivateCreditButton;
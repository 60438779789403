import Axios from "axios";
import { BackendBaseApi } from "./constants/constants";

export const GET_SUBDOMAIN_DETAILS = "GET_SUBDOMAIN_DETAILS";

export const getSubDomainDetails = (setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    var data = {
      // domain: null,
      // logo: "https://mentorkart.com/wp-content/uploads/2021/07/Group-123-3.png",
      // login_page: {
      //   grid_name_1: "Student",
      //   grid_name_2: "Mentorkart",
      // },
      // color_code: "#f86d6c",
      // light_color_code: "#ffaeae",
      // footer: {
      //   facebook_link: "https://www.facebook.com/getwork2018",
      //   instagram_link: "https://www.instagram.com/getworknow/",
      //   twitter_link: "https://twitter.com/getwork_now",
      //   linkedin_link: "https://www.linkedin.com/company/getworknow/",
      //   youtube_link: "youtube.com/channel/UCwp8aQ6Wp23ZHrw9-vWmV8w",
      // },
    };

    const res = await Axios.get(`${BackendBaseApi.PRAVESH}api/getdomain_data`).catch((err) => {
      localStorage.clear();
    });
    var data = {};
    if (res?.data?.success && res?.status === 200) {
      data = res.data.data;
      // data = { domain: null };

      setLoading(false);
    } else {
      // alert.error(res.data.error);
      // data = { domain: null };
      setLoading(false);
    }
    dispatch({ type: GET_SUBDOMAIN_DETAILS, payload: data });
  };
};

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import user from "../../../Assets/Images/png/aisha.png";
import { CustomTextField } from "../../Common/Input/InputField";
import { BackendBaseApi } from "../../../../constants/constants";
import Axios from "axios";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

export default function InterestedJobArea({
  setStep,
  step,
  finalSelectedJobSegments,
  setFinalSelectedJobSegments,
  finalSelectedJobRoles,
  setFinalSelectedJobRoles,
  finalAllRoleSegment,
  setFinalAllRoleSegment,
  getContinueButtonId
}) {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const [jobSegment, setJobSegement] = useState([]);

  const [filteredJobSegment, setFilteredJobSegment] = useState([]);

  const [selectedJobSegments, setSelectedJobSegments] = useState(
    finalSelectedJobSegments
  );

  const [selectedJobRoles, setSelectedJobRoles] = useState(
    finalSelectedJobRoles
  );

  const [newJobSegment, setNewJobSegment] = useState();

  const [allRoleSegment, setAllRoleSegment] = useState(finalAllRoleSegment);

  const [selectedIndex, setSelectedIndex] = useState();

  const [showAll, setShowAll] = useState([false]);

  const [jobRoleLoader, setJobRoleLoader] = useState(false);

  const [jobSegmentLoader, setJobSegmentLoader] = useState(false);

  const handleToggle = (value, ind) => {
    let selectJobRole = [...selectedJobRoles];
    const currentIndex = selectJobRole[ind]?.findIndex(
      (role) => role?.job_role_group === value?.job_role_group
    );
    if (currentIndex === -1) {
      selectJobRole[ind].push(value);
    } else {
      selectJobRole[ind].splice(currentIndex, 1);
    }
    setSelectedJobRoles(selectJobRole);
  };

  useEffect(() => {
    setJobSegmentLoader(true);
    Axios.get(`${BackendBaseApi.NIYUKTI}job/segment/`)
      .then((res) => {
        if (res?.data?.success) {
          setJobSegement(res?.data?.data);
          setFilteredJobSegment(res?.data?.data);
          setJobSegmentLoader(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (newJobSegment) {
      setJobRoleLoader(true);
      Axios.get(
        `${BackendBaseApi.NIYUKTI}job/role_group/?job_segment_id=${newJobSegment?.id}`
      )
        .then((res) => {
          let newAllRole = [...allRoleSegment];
          newAllRole[selectedIndex] = res?.data?.data;
          setAllRoleSegment(newAllRole);
          setJobRoleLoader(false);
        })
        .catch((err) => {
          // setLoading(false);
          setJobRoleLoader(false);
        });
    }
  }, [newJobSegment]);

  useEffect(() => {
    let allSegments = [...jobSegment];
    selectedJobSegments.map((item) => {
      allSegments = allSegments.filter((x) => x.id !== item?.id);
    });
    setFilteredJobSegment(allSegments);
  }, [selectedJobSegments]);

  const splitTopJobRoles = (jobRoleList, ind) => {
    if (!showAll[ind]) {
      return jobRoleList.slice(0, 5);
    }
    return jobRoleList;
  };

  const isCheckedJobRoles = (value, ind) => {
    if (
      selectedJobRoles[ind]?.findIndex(
        (role) => role?.job_role_group === value?.job_role_group
      ) === -1
    ) {
      return false;
    } else {
      return true;
    }
  };

  const gwToken = localStorage.getItem("gw_token");

  const updateJobRolePreference = () => {
    if (!validateJobRoles()) {
      return;
    }
    setLoading(true);
    let finalJobRoles = [];
    selectedJobRoles.forEach((roleList) => {
      finalJobRoles = finalJobRoles.concat(roleList);
    });
    Axios.post(
      `${BackendBaseApi.PRAVESH}api/student/signup/job-roles/`,
      {
        job_roles: finalJobRoles,
      },
      {
        headers: {
          Authorization: "Token " + gwToken,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        if (res?.data?.success) {
          setLoading(false);
          setFinalSelectedJobSegments(selectedJobSegments);
          setFinalSelectedJobRoles(selectedJobRoles);
          setFinalAllRoleSegment(allRoleSegment);
          setStep(step + 1);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Some error occurred. Please try again!!");
      });
  };

  const validateJobRoles = () => {
    let isError = false;
    if (!selectedJobSegments) { 
      alert.error("Please select atleast one job function and roles!");
      return false;
    } else {
      selectedJobRoles.forEach((value, item) => {
        if (value?.length === 0) {
          isError = true;
          return false;
        }
      });
      if(isError)
      alert.error("Please select atleast one Job Roles");
    }
    return isError ? false : true;
  };

  const deleteJobFunction = (ind) => {
    let selectedJobSeg = [...selectedJobSegments];
    let selectedJobRol = [...selectedJobRoles];
    let allRoleSeg = [...allRoleSegment];
    selectedJobSeg.splice(ind, 1);
    selectedJobRol.splice(ind, 1);
    allRoleSeg.splice(ind, 1);
    setSelectedJobSegments(selectedJobSeg);
    setSelectedJobRoles(selectedJobRol);
    setAllRoleSegment(allRoleSeg);
  };

  useEffect(() => {
    
    if(finalSelectedJobSegments && finalSelectedJobSegments?.length > 0){
      let jobSegmentId = [];
      finalSelectedJobSegments.map((item, ind) => {
        jobSegmentId.push(item?.id);
      })
      setJobRoleLoader(true);
      Axios.get(
        `${BackendBaseApi.NIYUKTI}job/role_group/?job_segment_id=${jobSegmentId.join(",")}`
      )
        .then((res) => {
          if(res?.data?.success){
              let temp = {};
              const data = res?.data?.data;
              data.forEach((item) => {
                setJobSegmentLoader(false);
                if (item?.job_segment in temp){
                  temp[item?.job_segment].push(item);
                }else{
                  temp[item?.job_segment] = [item];
                }
              })
              let finalList = [];
              const jd = jobSegmentId;
              jd.forEach((item) => {
                finalList.push(temp[item]);
              })
              setAllRoleSegment(finalList);
          }else{
            setSelectedJobSegments([]);
            setFinalSelectedJobRoles([]);
          }
        })
        .catch((err) => {
          // setLoading(false);
          setJobRoleLoader(false);
        });
      }
  }, [finalSelectedJobSegments])

  return (
    <>
      <>
        <div>
          {" "}
          <div
            style={{
              textAlign: "center",

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar alt="user-img" src={user} sx={{ width: 66, height: 66 }} />
            <Typography
              variant="h4"
              style={{ fontWeight: "500", lineHeight: 1.4, marginTop: 5 }}
            >
              What kind of job are you interested in?
            </Typography>
          </div>
          <Grid container spacing={2}>
            {selectedJobSegments?.map((segments, ind) => {
              return (
                <>
                  <Grid
                    xs={selectedJobSegments.length < 2 ? 12 : 11}
                    sm={selectedJobSegments.length < 2 ? 12 : 11}
                    md={selectedJobSegments.length < 2 ? 12 : 11}
                    style={{ display: "grid" }}
                  >
                    <Autocomplete
                      // options={jobSegment.map((option) => option?.job_segment)}
                      options={filteredJobSegment}
                      disableClearable
                      value={segments?.job_segment ? segments : null}
                      getOptionLabel={(option) => option?.job_segment}
                      style={{
                        background: "#fff",
                        borderRadius: 30,
                      }}
                      renderOption={(props, option) => {
                        let { key, ...propsNew } = props;
                        return (
                          <Typography
                            style={{
                              fontSize: "14px",
                              padding: "5px 20px",
                              cursor: "pointer",
                            }}
                            {...propsNew}
                          >
                            {option.job_segment}
                          </Typography>
                        );
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          required
                          label="Select job function"
                          size="small"
                          onKeyPress={(e) => {
                            e.preventDefault();
                          }}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        setNewJobSegment(newValue);
                        setSelectedIndex(ind);
                        let jobSeg = [...selectedJobSegments];
                        jobSeg[ind] = newValue;
                        setSelectedJobSegments(jobSeg);
                      }}
                    />
                  </Grid>
                  {selectedJobSegments.length > 1 && (
                    <Grid
                      xs={1}
                      sm={1}
                      md={1}
                      sx={{
                        display: "grid",
                        [theme.breakpoints.only("xs")]: {
                          padding: 0,
                        },
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          deleteJobFunction(ind);
                        }}
                        sx={{
                          [theme.breakpoints.only("xs")]: {
                            padding: 0,
                          },
                        }}
                      >
                        <HighlightOffRoundedIcon />
                      </IconButton>
                    </Grid>
                  )}
                  {jobRoleLoader && selectedIndex === ind ? (
                    // true ?
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    allRoleSegment[ind] &&
                    allRoleSegment[ind].length > 0 && (
                      <Grid xs={12} sm={12} md={12}>
                        <Typography
                          variant="body2"
                          color="initial"
                          style={{ color: "#6C757D" }}
                        >
                          Pick at least 1 job role that you’re interested in:
                        </Typography>
                        <div>
                          <List
                            sx={{
                              width: "100%",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                              // maxWidth: 360,
                              bgcolor: "background.paper",
                            }}
                          >
                            {splitTopJobRoles(allRoleSegment[ind], ind).map(
                              (value) => {
                                const labelId = `checkbox-list-label-${value?.id}`;
                                return (
                                  <ListItem
                                    key={value?.id}
                                    disablePadding
                                    style={{ padding: 0 }}
                                  >
                                    <ListItemButton
                                      role={undefined}
                                      onClick={() => {
                                        let newValue = {
                                          id: null,
                                          job_role_name: null,
                                          job_role_group: value?.id,
                                          job_role_group_name:
                                            value?.job_role_group,
                                          job_segment_id:
                                            selectedJobSegments[ind]?.id,
                                          job_segment_name:
                                            selectedJobSegments[ind]
                                              ?.job_segment,
                                        };
                                        handleToggle(newValue, ind);
                                      }}
                                      dense
                                      sx={{ p: 0 }}
                                    >
                                      <ListItemIcon sx={{ minWidth: "10px" }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isCheckedJobRoles(
                                            {
                                              id: null,
                                              job_role_name: null,
                                              job_role_group: value?.id,
                                              job_role_group_name:
                                                value?.job_role_group,
                                              job_segment_id:
                                                selectedJobSegments[ind]?.id,
                                              job_segment_name:
                                                selectedJobSegments[ind]
                                                  ?.job_segment,
                                            },
                                            ind
                                          )}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        id={labelId}
                                        primary={`${value?.job_role_group}`}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              }
                            )}
                          </List>
                          {allRoleSegment && allRoleSegment[ind] && allRoleSegment[ind].length > 5 && 
                          <Button
                            variant="text"
                            color="primary"
                            sx={{ p: 0 }}
                            endIcon={<ArrowDropDownRoundedIcon />}
                            onClick={() => {
                              let allShowAll = [...showAll];
                              allShowAll[ind] = !allShowAll[ind];
                              setShowAll(allShowAll);
                            }}
                          >
                            {showAll[ind] ? "View Less" : "View More"}
                          </Button>
            }
                        </div>
                      </Grid>
                    )
                  )}
                </>
              );
            })}

            <Grid
              xs={12}
              sx={{
                display: "grid"
              }}
            >
              <Button
                startIcon={<AddRoundedIcon />}
                sx={{
                  height: "40px",
                  width: "100%",
                  boxShadow: "none",
                  borderRadius: "30px",
                  display: "flex",
                  fontWeight: "400",
                  fontSize: "15px",
                  color: "#6C757D",
                  border: "1px dashed",
                  borderColor: "#B0B6BA",
                }}
                variant="outlined"
                onClick={() => {
                  setSelectedJobSegments([...selectedJobSegments, {}]);
                  setShowAll([...showAll, false]);
                  setAllRoleSegment([...allRoleSegment, []]);
                  setSelectedJobRoles([...selectedJobRoles, []]);
                }}
              >
                Add another job function
              </Button>
            </Grid>
          </Grid>
        </div>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            position: "relative",
            marginTop: "10px",
            [theme.breakpoints.only("sm")]: {
              top: "auto",
              bottom: 0,
              boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
              position: "fixed",
            },
            [theme.breakpoints.only("xs")]: {
              top: "auto",
              bottom: 0,
              boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
              position: "fixed",
            },
          }}
        >
          <Box
            sx={{
              pl: 0,
              pr: 0,
              [theme.breakpoints.only("sm")]: { pl: 1, pr: 1 },
              [theme.breakpoints.only("xs")]: { pl: 1, pr: 1 },
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
                <Button
                  color="primary"
                  sx={{
                    height: "40px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "30px",

                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
                <Button
                  color="primary"
                  endIcon={<ArrowForwardRoundedIcon />}
                  sx={{
                    height: "40px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "30px",

                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  variant="contained"
                  onClick={() => {
                    updateJobRolePreference();
                  }}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    <span id={getContinueButtonId()}> Continue</span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AppBar>
      </>
      {/* } */}
    </>
  );
}

import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      marginTop: "20px",
      marginBottom: "10px",
      width: "500px",
    },
    deleteButton: {
      background: "#3282C4",
      color: "#fff",
      borderRadius: "4px",

      // width: "60%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    cancelButton: {
      color: "black",
      backgroundColor: "white",
      border: "2px solid black",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#F2F2F2",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  })
);

export default function ViewDetails(props) {
  const { handleClose, handleOpen, open, setOpen, profile } = props;
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={11}></Grid>
              <Grid item xs={1}>
                <IconButton aria-label="delete" onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Typography align="center" variant="body1">
              <b>Are you sure you want to delete the Scheduled interview for the Job Profile - {profile}?</b>
            </Typography>
            <br />
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button className={classes.cancelButton} onClick={() => handleClose()}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button
                className={classes.deleteButton}
                // onClick={checkValidation}
              >
                Delete Interview{" "}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

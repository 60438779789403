import { Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../../components/public/FooterNew/Footer";
import AdminApproval from "../../../components/public/LogIn/Card/Verifications/adminApproval";
import StudentHeader from "../../../components/public/LogIn/Header/StudentHeader";
import Navbar from "../../../components/public/Navbar/Navbar";

function CompanyUserVerification() {
  const location = useLocation();
  const [company, setCompany] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState("");

  useEffect(() => {
    if (parseInt(location.flag) === 19087) {
      setCompany(location.company);
      setAdminName(location.admin_name);
      setAdminEmail(location.admin_email);
      setIsAdmin(location.is_admin);
    } else {
      setCompany(JSON.parse(localStorage.getItem("company")).company_name);
      setAdminName(JSON.parse(localStorage.getItem("company")).admin_name);
      setAdminEmail(JSON.parse(localStorage.getItem("company")).admin_email);
      setIsAdmin(JSON.parse(localStorage.getItem("company")).admin_approved);
    }

    localStorage.setItem("show_home_menu", false);
  }, []);

  return (
    <>
      <Navbar />

      <Hidden smDown>
        {" "}
        <StudentHeader showGrid={false} userVerify={true} type={"verify-user"} company={company} adminName={adminName} adminEmail={adminEmail} isAdmin={isAdmin} />
      </Hidden>

      <Hidden smUp>
        {" "}
        <div style={{ marginTop: "20%" }}>
          <AdminApproval company={company} adminName={adminName} adminEmail={adminEmail} isAdmin={isAdmin} />{" "}
        </div>
      </Hidden>
      <Footer />
    </>
  );
}

export default CompanyUserVerification;

import { createMuiTheme } from "@material-ui/core";

const Theme = createMuiTheme({
  palette: {
    type: "light",
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      main: "#3282C4",
      light: "#CDEDF6",
    },
    secondary: {
      main: "#E55934",
    },
    text: {
      primary: "#424242",
      secondary: "#828282",
    },
    error: {
      main: "#D64045",
    },
    grey: {
      50: "#F2F2F2",
    },
  },
  typography: {
    fontFamily: "ProximaNova",
    h4: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000000",
    },
    h5: {
      fontSize: "22px",
      fontWeight: "bold",
      color: "#000000",
    },
    h6: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
    },
    body1: {
      fontSize: "15px",
      color: "#000000",
    },
    body2: {
      fontSize: "15px",
      color: "#828282",
    },
    subtitle1: {
      fontSize: "14px",
      color: "#424242",
    },
    caption: {
      fontSize: "15px",
      color: "#424242",
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "capitalize",
        fontSize: "20px",
      },
      contained: {
        "&$disabled": {
          color: "#FFF",
          backgroundColor: "#EE7D66",
        },
      },
      text: {
        color: "#EE7D66",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#EE7D66",
        "&$focused": {
          color: "#EE7D66",
        },
      },
      focused: {
        color: "#EE7D66",
      },
    },
    MuiFab: {
      root: {
        backgroundColor: "#EE7D66",
        color: "#FFF",
      },
    },
    MuiTab: {
      wrapper: {
        color: "#EE7D66",
      },
      textColorInherit: {
        color: "#FFF",
      },
    },
  },
});

export default Theme;

import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import Icon from "@material-ui/core/Icon";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import { ArrowRightAlt } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/Create";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import Axios from "axios";
import { loadCSS } from "fg-loadcss";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SelectSearch from "react-select-search";
import EmptyData from "../../../assets/icon/empty-data.png";
import ExpiredCredit from "../../../assets/icon/expired-credit.png";
import BurnedCredits from "../../../assets/icon/invoice.png";
import AvailableCredits from "../../../assets/icon/receive-amount.png";
import NoJobs from "../../../assets/images/no-jobs.png";
import gifLoader from "../../../assets/payment-loading.gif";
import PreferencesNew from "../../../bundles/company/components/Home/ProfileMenu/PreferencesNew";
import DegreeCourses from "../../../common/DegreeSpecialization/Index";
import { BackendBaseApi } from "../../../constants/constants";
import { getTransactionHistory, updateCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import New_gate from "../../../GlobalComponents/RechargeDialog/New_gate";
import Payment_sucess from "../../../GlobalComponents/RechargeDialog/Payment_sucess";
import RechargeDialog from "../../../GlobalComponents/RechargeDialog/RechargeDialog";
import { getUserDetails } from "../Dashboard/action";
import "./collegeSettings.css";
import { default as styles, default as useStyles } from "./collegeSettingsCSS";
import ExamSession from "./ExamSession";
import PlacementSession from "./PlacementSession";

const useStyles1 = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    buttonLabel: {
      justifyContent: "left",
      marginLeft: "8%",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      marginTop: "160px",
      marginBottom: "10px",

      // position:'absolute',
    },
    modal1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    paper1: {
      width: "40%",
      backgroundColor: theme.palette.background.paper,
      //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),

      // position:'absolute',
    },
    postButton: {
      borderRadius: "30px",
      background: "#3282C4",
      color: "#fff",
      padding: "10px 20px 10px 20px",
      width: "135px",
      height: "36px",
      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },

    expButton: {
      borderRadius: "30px",
      background: "#3282C4",
      color: "#fff",
      alignItems: "center",
      contentAlign: "center",
      margin: "auto",
      justifyContent: "center",
      float: "center",
      display: "flex",
      boderRadius: "75px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    redstar: {
      color: "red",
    },
    editButton: {
      borderRadius: "30px",
      backgroundColor: "rgb(224, 224, 224)",
      color: "black",
      "&:hover": {
        backgroundColor: "#545b62",
        color: "white",
      },
    },
    buttonT1: {},
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CollegeSettings = () => {
  let { status } = useParams();
  const classes = useStyles();
  const classes1 = useStyles1();
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(status ? "Credits" : "Details");
  const [statusCheck, setStatusCheck] = useState(status ? true : false);
  const [value, setValue] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [collegeNickName, setCollegeNickName] = useState("");
  const [collegeAddres, setCollegeAddress] = useState("");
  const [institutionType, setInstitutionType] = useState("Government");
  const [webURL, setWebURL] = useState("");
  const [desc, setDesc] = useState("");
  const [uniName, setUniName] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [city, setCity] = useState("Select City");
  const [messR, setMessR] = useState("");
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [educationTypes, setEducationTypes] = useState({});
  const [eduType, setEduType] = useState();
  const [Degrees, setDegrees] = useState({});
  const [degreeType, setDegreeType] = useState();
  const [Courses, setCourses] = useState({});
  const [courseType, setCourseType] = useState();
  const [universities, setUniversities] = useState({});
  const [universityType, setUniversityType] = useState("Search");
  const [no_university, setNoUniversity] = useState(false);
  const [isGradeSystem, setIsGradeSystem] = useState('');
  const [percentageFactor, setPercentageFactor] = useState(0);
  const [countries, setCountries] = useState({});
  const [countryType, setCountryType] = useState("");
  const [states, setStates] = useState({});
  const [stateType, setStateType] = useState("");
  const [cities, setCities] = useState({});
  const [cityType, setCityType] = useState("");
  const [countryID, setCountryID] = useState();
  const [stateID, setStateID] = useState();
  const [loading, setLoading] = useState(false);
  const [jobRole, setJobRole] = useState();
  const [jobRoleInv, setJobRoleInv] = useState();
  const [examSessionData, setExamSessionData] = useState({});
  const [placementSessionData, setPlacementSessionData] = useState({});

  const [jobRolesList, setJobRolesList] = useState({});
  const [invEmail, setInvEmail] = useState();
  const [teamEmail, setTeamEmail] = useState();

  const [fName, setFName] = useState();
  const [lName, setLName] = useState();
  const [mobile, setMobile] = useState();
  const [teamAbout, setTeamAbout] = useState();
  const [message, setMessage] = useState();
  const [emailList, setEmailList] = useState();
  const [is_check, setIsCheck] = useState(false);
  const [title, setTitle] = useState();
  const [degreeCoursesComing, setDegreeCoursesComing] = useState(false);
  const [allData, setAllData] = useState({});
  const [courseArrayNew, setCourseArrayNew] = useState([]);
  const [showTransactionTable, setShowTransactionTable] = useState(false);
  const [rechargeDialog, setRechargeDialog] = useState(false);
  const [success, setSuccess] = useState(false);
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const [paymentId, setPaymentId] = useState("");
  const [addingTeammate, setAddingTeammate] = useState(false);
  const [fetchingTeamInvites, setFetchingTeamInvites] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [creditLoader, setCreditLoader] = useState(false);
  const [submitButtonOpen, setSubmitButtonOpen] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const transactionHistory = useSelector((state) => state.CreditInfo.transactionHistory);

  const handlePaymentSubmit = async () => {
    const res = await Axios({
      method: "POST",
      url: `${BackendBaseApi.PRAVESH}api/common/manual_payment/`,
      data: {
        user_id: localStorage.getItem("user_id"),
        user_type: localStorage.getItem("user_type_id"),
        reference_id: paymentId,
      },
    }).catch((err) => {
      throw err;
    });
    setSuccess(true);
  };

  React.useEffect(() => {
    const node = loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const alert = useAlert();

  const social_links = [
    { id: 1, Name: "LinkedIn", value: linkedIn !== undefined ? linkedIn : "" },
    { id: 1, Name: "facebook", value: facebook !== undefined ? facebook : "" },
    { id: 1, Name: "instagram", value: insta !== undefined ? insta : "" },
  ];

  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    if (status) getPaymentDetailsOrderID();
  }, [status]);
  const getPaymentDetailsOrderID = () => {
    Axios.get(`${BackendBaseApi.PRAVESH}api/paytm_payment/order_detail/?order_id=${status}`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res) {
          dispatch(updateCreditInfo(res.data.data.transaction_data));
          setOrderDetails(res.data.data.order_data);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const [page, setPage] = React.useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const [logoLoading, setLogoLoading] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [degree, setDegree] = useState("Search");
  const [preferencesData, setPreferencesData] = useState({});

  const [minCTC, setMinCTC] = useState();
  const [avgCTC, setAvgCTC] = useState();
  const [showMore, setShowMore] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [courseArray, setCourseArray] = useState([]);
  const [preferencesArray, setPreferencesArray] = useState([]);
  let token = localStorage.getItem("gw_token");
  const [id, setId] = useState();
  let user_type_id = localStorage.getItem("user_type_id");

  const getUserDetails_college = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/college/settings/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setMessR(res.data.data.message_for_recruiters);
          setDesc(res.data.data.description);
          setCollegeName(res.data.data.name);
          setCollegeNickName(res.data.data.nick_name);
          setCollegeAddress(res.data.data.address);
          setInstitutionType(res.data.data.organization_type);
          setWebURL(res.data.data.website);
          setUniName(res.data.data.university_name);
          setIsGradeSystem(res.data.data.is_grade_system ? "CGPA" : "Percentage ");
          setPercentageFactor(res.data.data.percentage_factor);
          if (res.data.data.social_links) {
            setFacebook(res.data.data.social_links[1].value ? res.data.data.social_links[1].value : res.data.data.social_links[1].Value);
            setInsta(res.data.data.social_links[2].value ? res.data.data.social_links[2].value : res.data.data.social_links[2].Value);
            setLinkedIn(res.data.data.social_links[0].value ? res.data.data.social_links[0].value : res.data.data.social_links[0].Value);
          }
          setCity(res.data.data.location);
          setMinCTC(res.data.data.min_ctc);
          setAvgCTC(res.data.data.avg_ctc);
          setLogo(res.data.data.logo);
          setBanner(res.data.data.banner);
          setUniversityType(res.data.data.university_name);
          setId(res.data.data.university_id);

          setCountryID(res.data.data.country);
          setCountryType(res.data.data.country_name);
          setStateID(res.data.data.state);
          setStateType(res.data.data.state_name);
          setCityType(res.data.data.city_name);
          setExamSessionData(res.data.data.exam_session);
          setPlacementSessionData(res.data.data.placement_session);

          if (res.data.data.preferences !== null && Object.keys(res.data.data.preferences).length > 0) {
            setPreferencesArray(res.data.data.preferences.placement_type);
          }
          setPreferencesData(res.data.data.preferences);

          if (res.data.data.is_university) {
            setValue("University");
          } else {
            setValue("College");
          }

          if (res.data.data.university === null) setNoUniversity(true);
          setCourseArray(res.data.data.degree_specialization);
          setCourseArrayNew(res.data.data.degree_specialization);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setEduType("Search");
    setDegreeType("Search");
    setCourseType("Search");
    getUserDetails_college();
    getUniversities();
    getCountries();

    getTeammates();
    getEducationType();
    getRoles();
    getEmail();
  }, []);

  useEffect(() => {
    getTeammatesInvited();
  }, []);
  const [teammatesArray, setTeammatesArray] = useState([]);
  const [teammatesInvitedArray, setTeammatesInvitedArray] = useState([]);

  const getTeammates = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/college/settings/team/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setTeammatesArray(res.data.data);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeammatesInvited = () => {
    setFetchingTeamInvites(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/college/settings/team/invited/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setTeammatesInvitedArray(res.data.data);
        } else {
          alert.error(res.data.error);
        }
        setFetchingTeamInvites(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingTeamInvites(false);
      });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage(img);
    }
  };

  const blobToImage = (blob) => {
    setLogoLoading(true);
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;

      // setImgtemp(blob);

      const fd = new FormData();
      fd.append("logo", blob);
      Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", fd, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Logo uploaded Successfully");
            setLogo(res.data.data.logo[0]);
            setLogoLoading(false);
            dispatch(getUserDetails(history, alert, localStorage.getItem("user_type_id")));
          } else {
            alert.error(res.data.error);
            setLogoLoading(false);
          }
        })
        .catch((err) => {
          alert.error("Error occured!");
          setLogoLoading(false);
        });
    });
  };
  const onImageChange1 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage1(img);
    }
  };

  const blobToImage1 = (blob) => {
    setBannerLoading(true);
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;
      setBanner(img.src);
      // setImgtemp(blob);

      const fd = new FormData();
      fd.append("banner", blob);
      Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", fd, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Banner uploaded Successfully");
            setBanner(res.data.data.banner[0]);
            setBannerLoading(false);
            dispatch(getUserDetails(history, alert, localStorage.getItem("user_type_id")));
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          alert.error("Error occured!");
          setBannerLoading(false);
        });
    });
  };

  function validURL(str) {
    var pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return !!pattern.test(str);
  }

  const [value1, setValue1] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };

  const handleUploadDetails = () => {
    const v1 = social_links[0].value === "" ? true : validURL(social_links[0].value);
    const v2 = social_links[1].value === "" ? true : validURL(social_links[1].value);
    const v3 = social_links[2].value === "" ? true : validURL(social_links[2].value);
    // if (!(v1 && v2 && v3)) {
    //   alert.error(
    //     "Enter Social Links URL Correctly. Plese Use HTTP HTTPS Protocol in the URL as well"
    //   );
    //   return;
    // }
    var flag = 0;
    if (webURL && validURL(webURL) === false) flag = 1;

    if (v1 === false && v2 === false && v3 === false) flag = 2;

    const body = {
      name: collegeName,
      // university:
      //     no_university === true ? universities[universityType] : null,
      university_name: no_university === true ? universityType : null,

      university: no_university === false ? universities[universityType] : null,

      is_grade_system: isGradeSystem === "CGPA" ? true : false,
      ...(isGradeSystem === "CGPA" && {percentage_factor: percentageFactor}),
      nick_name: collegeNickName,
      address: collegeAddres,
      organization_type: institutionType,
      website: webURL,
      description: desc,
      message_for_recruiters: messR,
      state: states[stateType],
      city: cities[cityType],
      country: countries[countryType],
      social_links: social_links,
      is_university: value === "University" ? true : false,
    };

    if (flag === 0 && v1 === true && v2 === true && v3 === true) {
      setUploadLoader(true);
      Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", body, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Updated Successfully");
          } else {
            alert.error(res.data.error);
          }
          setUploadLoader(false);
        })
        .catch((err) => {
          alert.error("Error occured!");
          setUploadLoader(false);
        });
    } else if (
      flag === 0 &&
      ((social_links[0].value ? social_links[0].value : social_links[0].Value) === "" ||
        (social_links[1].value ? social_links[1].value : social_links[1].Value) === "" ||
        (social_links[2].value ? social_links[2].value : social_links[2].Value) === "")
    ) {
      setUploadLoader(true);
      Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", body, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Updated Successfully");
          } else {
            alert.error(res.data.error);
          }
          setUploadLoader(false);
        })
        .catch((err) => {
          alert.error("Error occured!");
          setUploadLoader(false);
        });
    } else {
      if (flag === 1) alert.error("Enter Valid Website URL. Plese Use HTTP HTTPS Protocol in the URL as well.");
      else alert.error("Enter Social Links URL Correctly. Plese Use HTTP HTTPS Protocol in the URL as well");
    }
  };

  const handleCourseDetails = () => {
    let idModified = courseArray.map((obj) => {
      return {
        specialization_id: obj.id,
        specialization_name: obj.name,
        degree_id: obj.degree_id,
        degree_name: obj.degree_name,
        education_type: obj.education_type,
        education_type_name: obj.education_type_name,
        is_active: true,
      };
    });

    const body1 = {
      degree_specialization: idModified,
    };
    setUploadLoader(true);
    Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", body1, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Successfully");
        } else {
          alert.error(res.data.error);
        }
        setUploadLoader(false);
      })
      .catch((err) => {
        alert.error("Error occured!");
        setUploadLoader(false);
      });
  };

  // specialization
  const getEducationType = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/type/")
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            if (c.id !== 1 && c.id !== 2) temp[c.type] = c.id;
          });
          setEducationTypes({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDegrees = (id, e) => {
    setEduType(e.target.value);
    const urll = BackendBaseApi.PRAVESH + "api/education/degree?search=" + id + "&admin=true";

    Axios.get(urll)
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            if (c.is_specialized === true) {
              temp[c.name] = c.id;
            }
          });
          setDegrees({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCourses = (id, e) => {
    setDegreeType(e.target.value);
    const urll = BackendBaseApi.PRAVESH + "api/education/getspecialization?search=" + id + "&admin=true";

    Axios.get(urll)
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            temp[c.specialization.name] = c.specialization.id;
          });
          setCourses({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //location
  const getCountries = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/location/country/")
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            temp[c.country] = c.country_id;
          });
          setCountries({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStates(countryID, countryType);
    // setCountryType(countryType);
  }, [countryID]);

  useEffect(() => {
    getCities(stateID, stateType);
  }, [stateID]);

  const getStates = (id, e) => {
    // setCountryType(e);
    if (id !== undefined) {
      const urll = BackendBaseApi.PRAVESH + "api/location/state/?search=" + id;

      Axios.get(urll)
        .then((res) => {
          if (res) {
            let temp = {};
            res.data.forEach((c) => {
              temp[c.state] = c.state_id;
            });
            setStates({ ...temp });
          } else alert.error("error");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getCities = (id, e) => {
    setStateType(e);
    if (id !== undefined) {
      const urll = BackendBaseApi.PRAVESH + "api/location/city/?search=" + id;

      Axios.get(urll)
        .then((res) => {
          if (res.data) {
            let temp = {};
            res.data.forEach((c) => {
              temp[c.city] = c.city_id;
            });
            setCities({ ...temp });
          } else alert.error("error");
        })
        .catch((err) => {
          //throw err;
          console.log(err);
        });
    }
  };

  const getUniversities = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/admin/university/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((c) => {
            temp[c.university_name] = c.university_id;
          });
          setUniversities({ ...temp });
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePreferences = () => {
    const body = {
      min_ctc: minCTC,
      avg_ctc: avgCTC,
      message_for_recruiters: messR,
      // preferences: { placement_type: preferencesArray },
    };
    Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", body, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Successfully");
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };

  const state_s = Object.keys(states).map((d) => {
    return { name: d, value: d };
  });

  const countrie_s = Object.keys(countries).map((d) => {
    return { name: d, value: d };
  });

  const citie_s = Object.keys(cities).map((d) => {
    return { name: d, value: d };
  });

  const roles = Object.keys(jobRolesList).map((d) => {
    return { name: d, value: d };
  });

  const getEmail = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/college/settings/team/?verbose=invite", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setEmailList(res.data.data);
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Emails = () => (
    <SelectSearch
      value={teamEmail}
      onChange={(newValue) => {
        getTeamEmail(newValue);
      }}
      options={emailList.map((option) => ({
        name: option.email,
        value: option.value,
      }))}
      style={{ width: "100%" }}
    />
  );

  const getTeamEmail = (value) => {
    if (value) {
      setTeamEmail(value);
      var temp = emailList.filter((item) => {
        return item.email === value;
      });
      setFName(temp[0].first_name);
      setLName(temp[0].last_name);
      setMobile(temp[0].phone);
    } else {
      alert.error("Select an Email ID");
      setFName("");
      setLName("");
      setMobile("");
      setTeamEmail("");
    }
  };

  const getTeammateRemoved = (id_array) => {
    let data = {
      is_verified: 0,
      is_active: 0,
    };
    Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/team/manage/" + id_array[0].id + "/", data, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Teammate removed successfully");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error Occured 6");
      });
  };

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };
  const handleOpen1 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen2(false);
  };

  const getRoles = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/user_type/?main_user=college")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((data) => {
            temp[data.sub_user] = data.id;
          });
          setJobRolesList({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const inviteLink = () => {
    const pass = {
      email: invEmail,
      user_type_id: jobRolesList[jobRoleInv],
    };

    if (invEmail && jobRolesList[jobRoleInv]) {
      setAddingTeammate(true);
      Axios.post(BackendBaseApi.PRAVESH + "api/college/settings/team/", pass, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          if (res.data.success) {
            handleClose1();
            resetFields();
            alert.success("Invite Sent Successfully");
          } else alert.error(res.data.error);
          setAddingTeammate(false);
        })
        .catch((err) => {
          alert.error("Invite Not Sent");
          setAddingTeammate(false);
        });
    } else {
      alert.error("Please Fill all the required Fields");
    }
  };

  const handleChangeCheck = (event) => {
    setIsCheck(event.target.checked);
  };

  const AddTeam = () => {
    const data = {
      email: teamEmail,
      user_type_id: jobRolesList[jobRole],

      first_name: fName,
      last_name: lName,
      phone: mobile,
      job_title: title,
      about: teamAbout,
      message: message,
    };
    if (teamEmail && jobRolesList[jobRole] && fName && lName && mobile && title && teamAbout && message) {
      setAddingTeammate(true);
      Axios.post(BackendBaseApi.PRAVESH + "api/college/settings/team/", data, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Teammate added Successfully");
            handleClose();
            clearFields();
          } else alert.error(res.data.error);
          setAddingTeammate(false);
        })
        .catch((err) => {
          alert.error("Error");
          setAddingTeammate(false);
        });
    } else {
      alert.error("Please Fill All the required Fields");
    }
  };

  const resetFields = () => {
    setInvEmail("");
    setJobRoleInv("");
  };

  const clearFields = () => {
    setTeamEmail("");
    setJobRole("");
    setFName("");
    setLName("");
    setMobile("");
    setTeamAbout("");
    setMessage("");
    setTitle("");
  };
  useEffect(() => {
    var cor_arr = courseArray;
    setCourseArrayNew(cor_arr);
  }, [courseArray]);

  useEffect(() => {
    if (showTransactionTable) {
      dispatch(getTransactionHistory(page, setCreditLoader));
    }
  }, [showTransactionTable, page]);

  const theme = useTheme();
  const location = useLocation();
  const checkTab = () => {
    console.log(open);
    switch (open) {
      case "Details":
        return "details";

      case "Preferences":
        return "preferences";

      case "Invited":
        return "invited";

      case "Teammates":
        return "teammates";
      case "Credits":
        return "credits";
      case "Courses and Branch":
        return "courses-and-branches";

      default:
        return "";
    }
  };

  useEffect(() => {
    window.history.pushState({}, null, `/college/college-settings/?${checkTab()}`);
  }, [open]);

  useEffect(() => {
    if (location.search === "?details") setOpen("Details");
    if (location.search === "?preferences") setOpen("Preferences");
    if (location.search === "?invited") setOpen("Invited");
    if (location.search === "?credits") setOpen("Credits");
    if (location.search === "?teammates") setOpen("Teammates");
    if (location.search === "?courses-and-branches") setOpen("Courses and Branch");
  }, []);

  return (
    <>
      <div
        className={classes.container}
        style={{
          paddingTop: "2%",
          paddingLeft: "4%",
          paddingRight: "4%",
          paddingBottom: "2%",
        }}
      >
        <div className={classes.leftPaper}>
          <Button
            style={{ borderRadius: "30px" }}
            className={open !== "Details" ? classes.leftMenuBox1 : classes.leftMenuBoxSelected1}
            classes={{ label: classes.buttonLabel }}
            size="small"
            onClick={() => setOpen("Details")}
          >
            Details
          </Button>
          <Button
            style={{ borderRadius: "30px" }}
            className={open !== "Courses and Branch" ? classes.leftMenuBox1 : classes.leftMenuBoxSelected1}
            classes={{ label: classes.buttonLabel }}
            size="small"
            onClick={() => setOpen("Courses and Branch")}
          >
            Courses and Branch
          </Button>
          <Button
            style={{ borderRadius: "30px" }}
            className={open !== "Preferences" ? classes.leftMenuBox1 : classes.leftMenuBoxSelected1}
            classes={{ label: classes.buttonLabel }}
            size="small"
            onClick={() => setOpen("Preferences")}
          >
            Preferences
          </Button>
          <Button
            style={{ borderRadius: "30px" }}
            className={open !== "Teammates" ? classes.leftMenuBox1: classes.leftMenuBoxSelected1}
            classes={{ label: classes.buttonLabel }}
            size="small"
            onClick={() => setOpen("Teammates")}
          >
            Teammates
          </Button>
          <Button
            style={{ borderRadius: "30px" }}
            className={open !== "Invited" ? classes.leftMenuBox1 : classes.leftMenuBoxSelected1}
            classes={{ label: classes.buttonLabel }}
            size="small"
            onClick={() => setOpen("Invited")}
          >
            Invites
          </Button>
          <Button
            style={{ borderRadius: "30px" }}
            className={open !== "Credits" ? classes.leftMenuBox1 : classes.leftMenuBoxSelected1}
            classes={{ label: classes.buttonLabel }}
            size="small"
            onClick={() => setOpen("Credits")}
          >
            Credits
          </Button>
        </div>
        {open === "Details" ? (
          <div className={classes.paperStyle}>
            <form>
              <Typography variant="h4">Details</Typography>
              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  Institution <span className="text-red"> *</span>
                </Typography>
                <div className={classes.radioStyle}>
                  <Radio
                    checked={value === "College"}
                    onChange={(e) => setValue(e.target.value)}
                    value="College"
                    name="radio-button-demo"
                    label="adfv"
                    color="primary"
                    size="small"
                    className={{ marginLeft: "25px" }}
                  />
                  <Typography variant="subtitle1">College</Typography>
                </div>
                <div className={classes.radioStyle}>
                  <Radio
                    checked={value === "University"}
                    onChange={(e) => setValue(e.target.value)}
                    value="University"
                    name="radio-button-demo"
                    color="primary"
                    size="small"
                    className={{ marginLeft: "25px" }}
                  />
                  <Typography variant="subtitle1">University</Typography>
                </div>
              </div>

              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  College Name <span className="text-red"> *</span>
                </Typography>
                <TextField variant="outlined" type="text" value={collegeName} onChange={(e) => setCollegeName(e.target.value)} className={classes.inputBox} required />
              </div>
              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  College Nick Name
                  <span className="text-red"> *</span>
                </Typography>
                <TextField variant="outlined" type="text" value={collegeNickName} onChange={(e) => setCollegeNickName(e.target.value)} className={classes.inputBox} required />
              </div>
              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  College Address
                  <span className="text-red"> *</span>
                </Typography>
                <TextField variant="outlined" type="text" value={collegeAddres} onChange={(e) => setCollegeAddress(e.target.value)} className={classes.inputBox1} required />
              </div>
              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  Select College Type
                  <span className="text-red"> *</span>
                </Typography>
                <div>
                  <div className={classes.radioStyle}>
                    <Radio
                      checked={institutionType === "Government"}
                      onChange={() => setInstitutionType("Government")}
                      value="Government"
                      name="radio-button-demo"
                      label="adfv"
                      color="primary"
                      size="small"
                      className={{ marginLeft: "25px" }}
                    />
                    <Typography variant="subtitle1">Government</Typography>
                  </div>
                  <div className={classes.radioStyle}>
                    <Radio
                      checked={institutionType === "SemiPrivate"}
                      onChange={() => setInstitutionType("SemiPrivate")}
                      value="SemiPrivate"
                      name="radio-button-demo"
                      color="primary"
                      size="small"
                      className={{ marginLeft: "25px" }}
                    />

                    <Typography variant="subtitle1">Semi Government</Typography>
                  </div>
                  <div className={classes.radioStyle}>
                    <Radio
                      checked={institutionType === "Private"}
                      onChange={() => setInstitutionType("Private")}
                      value="Private"
                      name="radio-button-demo"
                      label="adfv"
                      color="primary"
                      size="small"
                      className={{ marginLeft: "25px" }}
                    />
                    <Typography variant="subtitle1">Private</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  College Website URL <span className="text-red"> *</span>
                </Typography>
                <TextField variant="outlined" type="email" value={webURL} onChange={(e) => setWebURL(e.target.value)} className={classes.inputBox} required />
              </div>
              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  College Description
                </Typography>
                <TextField style={{ width: "65%" }} rows={12} variant="outlined" multiline={true} type="text" value={desc} onChange={(e) => setDesc(e.target.value)} required />
              </div>
              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  Message to recruiter
                </Typography>
                <TextField rows={12} style={{ width: "65%" }} variant="outlined" multiline={true} type="text" value={messR} onChange={(e) => setMessR(e.target.value)} required />
              </div>

              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  University Name <span className="text-red"> *</span>
                </Typography>
                <div className={classes.radioStyle}>
                  <Grid container spacing={3}>
                    <Grid item sm={12} id={theme.palette.type === "dark" && "theme-select-search"}>
                      {no_university === false ? (
                        <FormControl style={{ marginRight: "10px" }} variant="standard" className={classes.dropDown1}>
                          <SelectSearch
                            options={Object.keys(universities).map((item) => ({
                              name: item,
                              value: item,
                            }))}
                            value={universityType}
                            onChange={setUniversityType}
                            search
                          />
                        </FormControl>
                      ) : (
                        <>
                          <TextField
                            style={{ width: "80%" }}
                            variant="outlined"
                            type="text"
                            //className="form-control"

                            value={universityType}
                            onChange={(e) => setUniversityType(e.target.value)}
                            placeholder="Enter your University"
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item sm={12}>
                      <FormControlLabel
                        control={<Checkbox checked={no_university} onChange={(e) => setNoUniversity(e.target.checked)} color="primary" />}
                        label="University is not listed, let me type my own"
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  Grade System <span className="text-red"> *</span>
                </Typography>
                <div className={classes.radioStyle}>
                  <Radio
                    checked={isGradeSystem === "CGPA"}
                    onChange={(e) => setIsGradeSystem(e.target.value)}
                    value="CGPA"
                    name="radio-button-demo"
                    color="primary"
                    size="small"
                    className={{ marginLeft: "25px" }}
                  />
                  <Typography variant="subtitle1">CGPA</Typography>
                </div>
                <div className={classes.radioStyle}>
                  <Radio
                    checked={isGradeSystem !== "CGPA"}
                    onChange={(e) => setIsGradeSystem(e.target.value)}
                    value="Percentage"
                    name="radio-button-demo"
                    color="primary"
                    size="small"
                    className={{ marginLeft: "25px" }}
                  />
                  <Typography variant="subtitle1">Percentage</Typography>
                </div>
              </div>

              {isGradeSystem === "CGPA" && (
                <div className={classes.fieldDiv}>
                  <Typography variant="body1" style={{ width: "200px" }}>
                    Percentage Factor <span className="text-red"> *</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    type="number"
                    inputProps={{ step: "0.01"}}
                    value={percentageFactor}
                    onChange={(e) => setPercentageFactor(e.target.value)}
                    className={classes.inputBox}
                    required
                  />
                </div>
              )}

              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "200px" }}>
                  Social Links
                </Typography>
                <div className={classes.notRequired}>
                  <TextField
                    variant="outlined"
                    type="text"
                    value={linkedIn}
                    onChange={(e) => setLinkedIn(e.target.value)}
                    className={classes.inputBox3}
                    placeholder="College LinkedIn Page"
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    value={facebook}
                    onChange={(e) => {
                      setFacebook(e.target.value);
                    }}
                    className={classes.inputBox3}
                    placeholder="College Facebook Page"
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    value={insta}
                    onChange={(e) => setInsta(e.target.value)}
                    className={classes.inputBox3}
                    placeholder="College Instagram Page"
                  />
                </div>
              </div>
              <div className={classes.fieldDiv}>
                <Typography variant="body1" style={{ width: "250px" }}>
                  Location <span className="text-red"> *</span>
                </Typography>

                <Grid container spacing={4} id={theme.palette.type === "dark" && "theme-select-search"}>
                  <Grid item sm={4}>
                    <SelectSearch
                      value={countryType}
                      onChange={(newValue) => {
                        getStates(countries[newValue], newValue);
                      }}
                      options={countrie_s.map((option) => ({
                        name: option.name,
                        value: option.name,
                      }))}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <SelectSearch
                      value={stateType}
                      onChange={(newValue) => {
                        getCities(states[newValue], newValue);
                      }}
                      options={state_s.map((option) => ({
                        name: option.name,
                        value: option.name,
                      }))}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <SelectSearch
                      value={cityType}
                      onChange={(newValue) => {
                        setCityType(newValue);
                      }}
                      options={citie_s.map((option) => ({
                        name: option.name,
                        value: option.name,
                      }))}
                    />
                  </Grid>
                </Grid>
              </div>
              {logo ? (
                <div style={styles.avatarBox}>
                  <Avatar
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "75px",
                      border: "7px solid #e7e6e6",
                      margin: "auto",
                      top: 30,
                    }}
                    alt="Remy Sharp"
                    src={logoLoading ? gifLoader : logo}
                  />
                  <div>
                    <input type="file" id="image" style={{ display: "none" }} onChange={onImageChange}></input>
                    <label
                      for="image"
                      className="img_edit"
                      style={{
                        position: "relative",
                        right: -520,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "75px",
                          border: "2px solid #e7e6e6",
                          padding: "5px 9px 5px 9px",
                          opacity: 0.8,
                        }}
                      >
                        <Icon className="fa fa-pencil" style={{ fontSize: "20px" }} />
                      </div>
                    </label>
                  </div>
                </div>
              ) : (
                <div style={styles.avatarBox}>
                  <Avatar
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "75px",
                      border: "7px solid #e7e6e6",
                      margin: "auto",
                      top: 30,
                    }}
                    alt="Remy Sharp"
                  />
                  <div>
                    <input type="file" id="image" style={{ display: "none" }} onChange={onImageChange}></input>
                    <label
                      for="image"
                      className="img_edit"
                      style={{
                        position: "relative",
                        right: -520,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "75px",
                          border: "2px solid #e7e6e6",
                          padding: "5px 9px 5px 9px",
                          opacity: 0.8,
                        }}
                      >
                        <Icon className="fa fa-pencil" style={{ fontSize: "20px" }} />
                      </div>
                    </label>
                  </div>
                </div>
              )}
              {banner ? (
                <div className={classes.images}>
                  <div
                    className="hello img_edit1"
                    style={{
                      width: "676px",
                      height: "170px",
                      borderRadius: "3px",
                    }}
                  >
                    <input type="file" id="image1" style={{ display: "none" }} onChange={onImageChange1}></input>
                    <label
                      for="image1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={bannerLoading ? gifLoader : banner} style={{ width: "100%", height: "170px" }} alt="banner" />
                    </label>
                  </div>
                </div>
              ) : (
                <div className={classes.emptyBanner}>
                  <input type="file" id="image2" style={{ display: "none" }} onChange={onImageChange1}></input>
                  <label for="image2">Add an image</label>
                </div>
              )}
              <div className={classes.buttonContainer}>
                <Button className={classes.button} style={{ width: "132px", height: "36px" }} onClick={handleUploadDetails}>
                  {uploadLoader ? <CircularProgress size={25} style={{ color: "#ffffff" }} /> : "Update Details"}
                </Button>
              </div>
            </form>
          </div>
        ) : null}
        {open === "Courses and Branch" ? (
          <div className={classes.paperStyle}>
            <form>
              <Typography variant="h4">Courses and Branch</Typography>{" "}
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body1">If Course and branch is not listed, please contact admin.</Typography>
                <div
                  style={{
                    display: "flex",
                    float: "right",
                    justifyContent: "right",
                    marginRight: "30px",
                  }}
                >
                  {isEdit === false ? (
                    <Button className={classes.editButton} startIcon={<CreateIcon />} onClick={() => setIsEdit(true)}>
                      Edit
                    </Button>
                  ) : (
                    <Button
                      className={classes.editButton}
                      // startIcon={<CreateIcon />}
                      onClick={() => setIsEdit(false)}
                    >
                      View all Courses
                    </Button>
                  )}
                </div>
              </Box>
              <Grid container spacing={2} style={{ marginTop: "15px" }}>
                {showMore === false
                  ? isEdit === false &&
                    courseArray.length > 0 &&
                    courseArray.slice(0, 4).map((data, index) =>
                      true ? (
                        <>
                          {/* <div className={classes.fieldDiv}>
                          <div
                            className={classes.radioStyle}
                            
                          > */}
                          <Grid item sm={4}>
                            <TextField type="text" variant="outlined" value={data.education_type_name} className={classes.inputBox} style={{ width: "auto" }} required disabled />
                          </Grid>
                          <Grid item sm={4}>
                            <TextField style={{ width: "auto" }} type="text" variant="outlined" value={data.degree_name} className={classes.inputBox} required disabled />
                          </Grid>
                          <Grid item sm={4}>
                            <TextField
                              style={{ width: "auto" }}
                              type="text"
                              variant="outlined"
                              value={data.name ? data.name : data.specialization_name}
                              className={classes.inputBox}
                              required
                              disabled
                            />
                          </Grid>
                          {/* <Button
                              variant="contained"
                              className={classes.button2}
                              size="small"
                              // onClick={() => {
                              // 	let ca = courseArray;
                              // 	// ca.splice(index, 1);
                              // 	setCourseArray([...ca,]);
                              // }}
                              onClick={() => {
                                let ca = courseArray;
                                ca[index].is_active = !data.is_active;
                                setCourseArray([...ca]);
                              }}
                            >
                              Del&nbsp; &nbsp;
                              <Icon
                                className="fa fa-trash-alt"
                                style={{
                                  fontSize: "16px",
                                }}
                              />
                            </Button> */}
                          {/* </div>
                        </div> */}
                        </>
                      ) : null
                    )
                  : isEdit === false &&
                    courseArray.map((data, index) =>
                      true ? (
                        <>
                          {/* <div className={classes.fieldDiv}>
                            <div
                              className={classes.radioStyle}
                              style={{ marginLeft: "100px" }}
                            > */}
                          <Grid item sm={4}>
                            <TextField style={{ width: "auto" }} type="text" variant="outlined" value={data.education_type_name} className={classes.inputBox} required disabled />
                          </Grid>
                          <Grid item sm={4}>
                            <TextField style={{ width: "auto" }} type="text" variant="outlined" value={data.degree_name} className={classes.inputBox} required disabled />
                          </Grid>
                          <Grid item sm={4}>
                            <TextField
                              style={{ width: "auto" }}
                              type="text"
                              variant="outlined"
                              value={data.name ? data.name : data.specialization_name}
                              className={classes.inputBox}
                              required
                              disabled
                            />
                          </Grid>
                          {/* </div>
                          </div> */}
                        </>
                      ) : null
                    )}
              </Grid>
              <br />
              {isEdit === false &&
                (showMore === false ? (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "right",
                      justifyContent: "right",
                      float: "right",
                      color: "#3282C4",
                      cursor: "pointer",
                      marginRight: "300px",
                    }}
                    onClick={() => setShowMore(true)}
                  >
                    Show More
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "right",
                      justifyContent: "right",
                      float: "right",
                      color: "#3282C4",
                      cursor: "pointer",
                      marginRight: "300px",
                    }}
                    onClick={() => setShowMore(false)}
                  >
                    Hide
                  </div>
                ))}
              {/* <div
                className={{
                  marginLeft: "40px",
                  marginTop: "20px",
                  marginRight: "10px",
                  color: "grey",
                }}
              >
                If Course and branch is not listed, please contact admin.
              </div> */}
              {isEdit === true && loading === false && (
                <div className={classes.buttonContainer}>
                  <Button style={{ width: "218px", height: "36px" }} className={classes.button} onClick={handleCourseDetails}>
                    {uploadLoader ? <CircularProgress size={25} style={{ color: "#ffffff" }} /> : "Update Courses and Branch"}
                  </Button>
                </div>
              )}
            </form>

            <br />
            <br />
            {isEdit && (
              <DegreeCourses
                data={courseArray}
                setData={setCourseArray}
                width={"90%"}
                loading={loading}
                setLoading={setLoading}
                degreeCoursesComing={degreeCoursesComing}
                setDegreeCoursesComing={setDegreeCoursesComing}
                allData={allData}
                setAllData={setAllData}
              />
            )}
            {isEdit === true && loading === false && (
              <div className={classes.buttonContainer}>
                <Button style={{ width: "218px", height: "36px" }} className={classes.button} onClick={handleCourseDetails}>
                  {uploadLoader ? <CircularProgress size={25} style={{ color: "#ffffff" }} /> : "Update Courses and Branch"}
                </Button>
              </div>
            )}
          </div>
        ) : null}
        {open === "Preferences" ? (
          <div className={classes.paperStyle}>
            <Typography variant="h4">Preferences</Typography>
            <br />
            <div className={classes.root}>
              <AppBar position="static" color="default" className={classes.fixedBar}>
                <Tabs
                  style={{
                    minHeight: "80px",
                    paddingTop: "20px",
                    backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
                  }}
                  value={value1}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab style={{ color: theme.palette.type === "dark" ? "#fff" : "black", fontWeight: "bold" }} label="CTC Details" {...a11yProps(0)} />
                  <Tab style={{ color: theme.palette.type === "dark" ? "#fff" : "black", fontWeight: "bold" }} label="Preferences" {...a11yProps(1)} />

                  <Tab style={{ color: theme.palette.type === "dark" ? "#fff" : "black", fontWeight: "bold" }} label="Exam Session" {...a11yProps(2)} />
                  <Tab style={{ color: theme.palette.type === "dark" ? "#fff" : "black", fontWeight: "bold" }} label="PLacement Session" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value1} index={0}>
                <form>
                  <div className={classes.fieldDiv}>
                    <Typography variant="body1" style={{ width: "200px" }}>
                      Min. CTC <span className="text-red"> *</span>
                    </Typography>
                    <TextField variant="outlined" type="text" value={minCTC} onChange={(e) => setMinCTC(e.target.value)} className={classes.inputBox} required />
                  </div>
                  <div className={classes.fieldDiv}>
                    <Typography variant="body1" style={{ width: "200px" }}>
                      Avg. CTC
                      <span className="text-red"> *</span>
                    </Typography>
                    <TextField variant="outlined" type="text" value={avgCTC} onChange={(e) => setAvgCTC(e.target.value)} className={classes.inputBox} required />
                  </div>
                  <div className={classes.fieldDiv}>
                    <Typography variant="body1" style={{ width: "200px" }}>
                      Message for recruiters
                    </Typography>
                    <TextField
                      variant="outlined"
                      multiline={true}
                      rows={10}
                      type="text"
                      value={messR}
                      onChange={(e) => setMessR(e.target.value)}
                      className={classes.inputBox2}
                      required
                    />
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button className={classes.button} onClick={handlePreferences}>
                      Update Preferences
                    </Button>
                  </div>
                </form>
              </TabPanel>
              <TabPanel value={value1} index={1}>
                <PreferencesNew
                  data={preferencesData}
                  setPreferencesData={setPreferencesData}
                  setSubmitButtonOpen={setSubmitButtonOpen}
                  submitButtonClicked={submitButtonClicked}
                />
              </TabPanel>
              <TabPanel value={value1} index={2}>
                <ExamSession examSessionData={examSessionData} setExamSessionData={setExamSessionData} />
              </TabPanel>
              <TabPanel value={value1} index={3}>
                <PlacementSession placementSessionData={placementSessionData} setPlacementSessionData={setPlacementSessionData} />
              </TabPanel>
            </div>

            {/* <Typography
                variant="body1"
                style={{
                  marginTop: "30px",
                  marginBottom: "10px",
                }}
              >
                Types of Placements Offered
              </Typography>
              <div className={classes.fieldDiv}>
                <div className={classes.radioStyle} style={{ width: "95%" }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={3}>
                      <SelectSearch
                        value={graduation}
                        onChange={(value) => setGraduation(value)}
                        options={graduations.map((item) => ({
                          name: item.name,
                          value: item.name,
                        }))}
                        placeholder="Graduation"
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <SelectSearch
                        options={degrees.map((item) => ({
                          name: item.name,
                          value: item.name,
                        }))}
                        value={degree}
                        onChange={(value) => setDegree(value)}
                        placeholder="Degree"
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <SelectSearch
                        options={specializations.map((item) => ({
                          name: item.name,
                          value: item.name,
                        }))}
                        value={specialization}
                        onChange={(value) => setSpecialization(value)}
                        placeholder="Specialization"
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Button
                        className={classes.button3}
                        size="small"
                        onClick={() => {
                          let pr = [...preferencesArray];
                          let flag = 0;
                          for (var i = 0; i < preferencesArray.length; i++) {
                            if (
                              preferencesArray[i].placement_type ===
                                graduation &&
                              preferencesArray[i].duration === degree &&
                              preferencesArray[i].year === specialization
                            ) {
                              flag = 1;
                              break;
                            } else if (
                              graduation === "Search" &&
                              specialization === "Search" &&
                              degree === "Search"
                            ) {
                              flag = 2;
                              break;
                            }
                          }

                          if (flag === 0) {
                            if (
                              graduation &&
                              graduation !== "Search" &&
                              specialization &&
                              specialization !== "Search"
                            ) {
                              pr.push({
                                placement_type:
                                  graduation !== "Search" ? graduation : null,
                                duration: degree !== "Search" ? degree : null,
                                year:
                                  specialization !== "Search"
                                    ? specialization
                                    : null,
                              });
                              setPreferencesArray([...pr]);
                            } else {
                              alert.error(
                                "Please Select Placement Type and Year"
                              );
                            }
                          } else if (flag === 1) {
                            alert.error("Already Selected");
                          } else {
                            alert.error(
                              "Please Select Placement Type and Year"
                            );
                          }
                          setGraduation("Search");
                          setDegree("Search");
                          setSpecialization("Search");
                        }}
                      >
                        ADD&nbsp; &nbsp;
                        <Icon
                          className="fa fa-plus"
                          style={{ fontSize: "16px" }}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
              {preferencesArray && preferencesArray.length > 0
                ? preferencesArray.map((data, index) => {
                    return (
                      <div className={classes.fieldDiv}>
                        <div className={classes.radioStyle}>
                          <input
                            type="text"
                            //className="form-control"
                            value={data.placement_type}
                            style={{
                              height: "35px",
                              paddingLeft: "5px",
                              marginRight: "10px",
                            }}
                            required
                            disabled
                          />

                          <input
                            type="text"
                            //className="form-control"
                            value={data.duration}
                            style={{
                              height: "35px",
                              paddingLeft: "5px",
                              marginRight: "10px",
                            }}
                            required
                            disabled
                          />

                          <input
                            type="text"
                            //className="form-control"
                            value={data.year}
                            style={{
                              height: "35px",
                              paddingLeft: "5px",
                              marginRight: "10px",
                            }}
                            required
                            disabled
                          />

                          <Button
                            style={{
                              fontWeight: "bold",
                              marginLeft: "15px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              backgroundColor: "#DF8184",
                              color: "white",
                            }}
                            size="small"
                            onClick={() => {
                              let ca = preferencesArray;
                              ca.splice(index, 1);
                              setPreferencesArray([...ca]);
                            }}
                          >
                            Del&nbsp; &nbsp;
                            <Icon
                              className="fa fa-trash-alt"
                              style={{
                                fontSize: "16px",
                              }}
                            />
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : null}

              <div className={classes.buttonContainer}>
                <Button className={classes.button} onClick={handlePreferences}>
                  Update Preferences
                </Button>
              </div>
            </form>
          </div> */}
          </div>
        ) : null}
        {open === "Teammates" ? (
          <div className={classes.paperStyle}>
            <div>
              <Dialog open={open1} onClose={handleClose}>
                <DialogContent>
                  <div>
                    <Typography align="center" className={classes1.grid3} variant="h6">
                      <b>Add New Members to your team</b>&nbsp;
                    </Typography>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes1.grid3} variant="body1">
                          <b>Email</b>&nbsp;
                          <span className={classes1.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes1.grid4}>
                        {is_check === true || emailList?.length === 0 ? (
                          <TextField
                            id="date"
                            label="Email"
                            type="email"
                            onChange={(e) => {
                              setTeamEmail(e.target.value);
                            }}
                            value={teamEmail}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        ) : (
                          <Emails />
                        )}
                        <br />
                        <FormControlLabel control={<Checkbox checked={is_check} onChange={handleChangeCheck} name="gilad" />} label="Is Your Email not Listed?" />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes1.grid3} variant="body1">
                          <b>Full Name</b>&nbsp;
                          <span className={classes1.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes1.grid4}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <TextField
                              id="date"
                              label="First Name"
                              type="text"
                              onChange={(e) => {
                                setFName(e.target.value);
                              }}
                              value={fName}
                              variant="outlined"
                              style={{ width: "100%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="date"
                              label="Last Name"
                              type="text"
                              onChange={(e) => {
                                setLName(e.target.value);
                              }}
                              value={lName}
                              variant="outlined"
                              style={{ width: "100%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes1.grid3} variant="body1">
                          <b>Role</b>&nbsp;
                          <span className={classes1.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes1.grid4}>
                        <SelectSearch
                          value={jobRole}
                          placeholder="Job Role"
                          onChange={(newValue) => {
                            setJobRole(newValue);
                          }}
                          options={roles.slice(0, 3).map((option) => ({
                            name: option.name,
                            value: option.name,
                          }))}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes1.grid3} variant="body1">
                          <b>Mobile Number</b>&nbsp;
                          <span className={classes1.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes1.grid4}>
                        <TextField
                          id="date"
                          label="Mobile Number"
                          type="number"
                          // className={classes1.textField}
                          onChange={(e) => {
                            setMobile(e.target.value);
                          }}
                          value={mobile}
                          variant="outlined"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes.grid3} variant="body1">
                          <b>Job Title</b>&nbsp;
                          <span className={classes.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes.grid4}>
                        <TextField
                          id="date"
                          label="Job Title"
                          type="text"
                          // className={classes.textField}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                          value={title}
                          variant="outlined"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes1.grid3} variant="body1">
                          <b>About</b>&nbsp;
                          <span className={classes1.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes1.grid4}>
                        <TextareaAutosize
                          rowsMin={4}
                          rowsMax={8}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "6px",
                            borderColor: "#d2cdcd",
                          }}
                          aria-label="maximum height"
                          placeholder="About"
                          onChange={(e) => {
                            setTeamAbout(e.target.value);
                          }}
                          value={teamAbout}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes1.grid3} variant="body1">
                          <b>Message</b>&nbsp;
                          <span className={classes1.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes1.grid4}>
                        <TextareaAutosize
                          rowsMin={4}
                          rowsMax={8}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "6px",
                            borderColor: "#d2cdcd",
                          }}
                          aria-label="maximum height"
                          placeholder="Message"
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          value={message}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4} className={classes1.grid4}></Grid>
                      <Grid item xs={4} className={classes1.grid4}>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            type="submit"
                            className={classes1.postButton}
                            onClick={() => {
                              AddTeam();
                              // clearFields();
                            }}
                          >
                            {addingTeammate ? <CircularProgress size={25} style={{ color: "#ffffff" }} /> : "Add Teammate"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </DialogContent>
              </Dialog>
            </div>

            {/* modal 2 invite link */}
            <div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes1.modal1}
                open={open2}
                onClose={handleClose1}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div className={classes1.paper1}>
                    <Typography className={classes1.grid3} variant="h6">
                      <b>Invite with Link</b>&nbsp;
                    </Typography>
                    <br />
                    <Typography className={classes1.grid3} variant="body2" style={{ color: "grey" }}>
                      Anyone can join your college account with this link. As long as they use the invite
                      <br /> link, they will be automatically approved and connected with your College upon
                      <br /> completing the registration process.
                    </Typography>
                    <br />
                    <Typography className={classes1.grid3} variant="body1">
                      <b>Select user Role for invite</b>
                    </Typography>
                    <br />

                    <Autocomplete
                      value={jobRoleInv}
                      onChange={(event, newValue) => {
                        setJobRoleInv(newValue);
                      }}
                      id="controllable-states-demo"
                      options={roles.slice(0, 3).map((option) => option.name)}
                      style={{ width: "50%" }}
                      renderInput={(params) => <TextField {...params} label="Role" variant="outlined" />}
                    />
                    <br />

                    <Grid container spacing={3}>
                      <Grid item md={8}>
                        <TextField
                          id="date"
                          label="Email"
                          type="email"
                          // className={classes1.textField}
                          onChange={(e) => {
                            setInvEmail(e.target.value);
                          }}
                          value={invEmail}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item md={4} className={classes1.grid4}>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ width: "145px" }}
                          className={classes1.postButton}
                          onClick={() => {
                            inviteLink();
                            resetFields();
                          }}
                        >
                          {addingTeammate ? <CircularProgress size={25} style={{ color: "#ffffff" }} /> : "Send Invite Link"}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Fade>
              </Modal>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h4">Teammates</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div>
                  <Button variant="contained" onClick={handleOpen1} style={{ marginRight: "15px", borderRadius: "30px" }}>
                    Send Invite Link
                  </Button>
                </div>
                {/* <form style={{ display: "none" }}>
                                    <textarea
                                        ref={textAreaRef}
                                        value="the link will be copied"
                                        style={{ display: "none" }}
                                    />
                                </form> */}

                <div>
                  <Button onClick={handleOpen} variant="contained" style={{ borderRadius: "30px" }}>
                    Add Teammates Manually
                  </Button>
                </div>
              </div>
            </div>
            <div className={classes.tableContainer}>
              <Box width="100%">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone No.</TableCell>
                        <TableCell>User Type</TableCell>
                        <TableCell>Designation</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teammatesArray.map((data, index) => (
                        <TableRow>
                          <TableCell>
                            {data.first_name}&nbsp;
                            {data.last_name}
                          </TableCell>
                          <TableCell>{data.email}</TableCell>
                          <TableCell>{data.phone}</TableCell>
                          <TableCell>{data.type[0]?.sub_user}</TableCell>
                          <TableCell>{data.job_title}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {/* {teammatesArray.map((data, index) => {
                return (
                  <div className={classes.tab1}>
                    
                    {index % 2 === 0 ? (
                      <div className={classes.tab}>
                        <div className={classes.tableHeader3}>
                          {data.first_name}&nbsp;
                          {data.last_name}
                        </div>
                        <div className={classes.tableHeader3}>{data.email}</div>
                        <div className={classes.tableHeader3}>{data.phone}</div>
                        
                        <div className={classes.tableHeader3}>
                          {data.type.map((item, id) => (
                            <>
                              {id > 0 ? <>, &nbsp;</> : <></>}
                              {item.main_user}-{item.sub_user}
                            </>
                          ))}{" "}
                        </div>
                        {parseInt(user_type_id) === 7 && (
                          <div className={classes.tableHeader3}>
                            {index !== 0 && (
                              <Button
                                style={styles.button2}
                                size="small"
                                onClick={() => {
                                  let ca = teammatesArray;
                                  let ta = teammatesArray;
                                  var new_arr = ta.slice(index, index + 1);

                                  ca.splice(index, 1);

                                  setTeammatesArray([...ca]);
                                  getTeammateRemoved(new_arr);
                                }}
                              >
                                Del&nbsp; &nbsp;
                                <Icon
                                  className="fa fa-trash-alt"
                                  style={{ fontSize: "16px" }}
                                />
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={classes.tab}>
                        <div className={classes.tableHeader}>
                          {data.first_name}&nbsp;
                          {data.last_name}
                        </div>
                        <div className={classes.tableHeader}>{data.email}</div>
                        <div className={classes.tableHeader}>{data.phone}</div>
                        
                        <div className={classes.tableHeader}>
                          {data.type.map((item, id) => (
                            <>
                              {id > 0 ? <>, &nbsp;</> : <></>}
                              {item.main_user}-{item.sub_user}
                            </>
                          ))}
                        </div>
                        {parseInt(user_type_id) === 7 && (
                          <div className={classes.tableHeader}>
                            {index !== 0 && (
                              <Button
                                style={styles.button2}
                                size="small"
                                onClick={() => {
                                  let ca = teammatesArray;
                                  let ta = teammatesArray;
                                  var new_arr = ta.slice(index, index + 1);

                                  ca.splice(index, 1);

                                  setTeammatesArray([...ca]);
                                  getTeammateRemoved(new_arr);
                                }}
                              >
                                Del&nbsp; &nbsp;
                                <Icon
                                  className="fa fa-trash-alt"
                                  style={{ fontSize: "16px" }}
                                />
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })} */}
            </div>
          </div>
        ) : null}

        {open == "Invited" ? (
          <div className={classes.paperStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {teammatesInvitedArray.length > 0 && <Typography variant="h4">Invited</Typography>}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "400px",
                  justifyContent: "space-between",
                  width: "60%",
                  marginRight: "30px",
                }}
              ></div>
            </div>

            <Box>
              {fetchingTeamInvites ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : teammatesInvitedArray.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teammatesInvitedArray.map((data, index) => (
                        <TableRow>
                          <TableCell>{data.email}</TableCell>
                          <TableCell>{data.type.sub_user}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center" marginTop="5%" paddingBottom="5%">
                  <img src={NoJobs} width="300px" height="auto" />
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    No Invites Sent yet
                  </p>
                  {/* <Box width="67%">
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#424242",
                      }}
                    >
                      A perfectly enticing pizza box sitting on a table. You open it filled with anticipation. And find… nothing but scraps. Perhaps a half eaten crust. And a lot
                      of grease. The anticipation turns to deep disappointment and despair. There’s nothing left!
                    </p>
                  </Box> */}
                </Box>
              )}
            </Box>

            {/* <div className={classes.tableContainer}>
              {teammatesInvitedArray.length > 0 && (
                <div className={classes.tab}>
                  
                  <div className={classes.tableHeader2}>Email</div>
                  
                  <div className={classes.tableHeader2}>Role</div>
                </div>
              )}
              {teammatesInvitedArray.length > 0 ? (
                teammatesInvitedArray.map((data, index) => {
                  return (
                    <div className={classes.tab1}>
                      {index % 2 == 0 ? (
                        <div className={classes.tab}>
                          
                          <div className={classes.tableHeader3}>
                            {data.email ? data.email : "NA"}
                          </div>
                          
                          <div className={classes.tableHeader3}>
                            {data.type.main_user}-{data.type.sub_user}
                          </div>
                        </div>
                      ) : (
                        <div className={classes.tab}>
                          
                          <div className={classes.tableHeader}>
                            {data.email ? data.email : "NA"}
                          </div>
                          
                          <div className={classes.tableHeader}>
                            {data.type.main_user}-{data.type.sub_user}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginTop="5%"
                  paddingBottom="5%"
                >
                  <img src={NoJobs} width="300px" height="auto" />
                  <p
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                    }}
                  >
                    No Invites Sent yet
                  </p>
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                  >
                    This Page Contains Nothing but Scraps
                  </p>
                  <Box width="67%">
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#424242",
                      }}
                    >
                      A perfectly enticing pizza box sitting on a table. You
                      open it filled with anticipation. And find… nothing but
                      scraps. Perhaps a half eaten crust. And a lot of grease.
                      The anticipation turns to deep disappointment and despair.
                      There’s nothing left!
                    </p>
                  </Box>
                </Box>
              )}
            </div> */}
          </div>
        ) : null}
        {open === "Credits" ? (
          <Box className={classes.paperStyle}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
                <Box
                  padding="6%"
                  borderRadius="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1]}
                  border={theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none"}
                >
                  <img src={AvailableCredits} width="60px" height="auto" />
                  <Typography variant="h4">Available Credits</Typography>
                  {creditInfo ? (
                    <Typography
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {creditInfo.available_credits}
                    </Typography>
                  ) : (
                    <CircularProgress />
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box
                  padding="6%"
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1]}
                  border={theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none"}
                  borderRadius="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <img src={BurnedCredits} width="60px" height="auto" />
                  <Typography variant="h4">Burned Credits</Typography>
                  {creditInfo ? (
                    <Typography
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {creditInfo.burned_credits}
                    </Typography>
                  ) : (
                    <CircularProgress />
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box
                  padding="6%"
                  bgcolor={theme.palette.background.default}
                  boxShadow={theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1]}
                  border={theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none"}
                  borderRadius="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <img src={ExpiredCredit} width="60px" height="auto" />
                  <Typography variant="h4">Expired Credits</Typography>
                  {creditInfo ? (
                    <p
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        color: "#424242",
                      }}
                    >
                      {creditInfo.expired_credits}
                    </p>
                  ) : (
                    <CircularProgress />
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  {/* <Button
                    onClick={() => setRechargeDialog(true)}
                    style={{
                      backgroundColor: "#219653",
                      color: "#ffffff",
                      width: "50%",
                      height: "50px",
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                    startIcon={
                      <img src={GetMoney} width="30px" height="auto" />
                    }
                  >
                    Recharge Now
                  </Button> */}
                  <New_gate openCheck={statusCheck} setOpenCheck={setStatusCheck} orderDetails={orderDetails} />
                  <Payment_sucess openCheck={statusCheck} orderDetails={orderDetails} />
                </Box>
              </Grid>
            </Grid>
            <Box padding="4% 0%">
              <p
                onClick={() => setShowTransactionTable(!showTransactionTable)}
                style={{
                  display: "flex",
                  color: "#3282c4",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              >
                {showTransactionTable ? "Hide Transaction history" : "View all transaction history"}{" "}
                <span>
                  <ArrowRightAlt style={{ width: "43px", height: "35px" }} />
                </span>
              </p>
              {showTransactionTable &&
                (transactionHistory?.results?.length !== 0 ? (
                  <>
                    <TableContainer
                      style={{
                        // maxHeight: "400px",
                        border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid #ced4da",
                        borderRadius: "5px",
                        boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[4],
                      }}
                    >
                      <Table stickyHeader={true}>
                        <TableHead>
                          <TableRow>
                            {/* <TableCell align="center">S. No.</TableCell> */}
                            <TableCell>Type</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Transaction Id</TableCell>
                            <TableCell>Date/Time</TableCell>
                            <TableCell>Service Type</TableCell>
                            <TableCell>Closing Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transactionHistory?.results?.length > 0 && !creditLoader ? (
                            transactionHistory?.results?.map((item, index) => (
                              <TableRow>
                                {/* <TableCell>{index + 1}</TableCell> */}
                                <TableCell>{item.transaction_type}</TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: "bold",
                                    color: item.transaction_type === "CREDIT" ? "#219653" : "#D64045",
                                  }}
                                >
                                  {item.amount}
                                </TableCell>
                                <TableCell>{item.transaction_id}</TableCell>
                                <TableCell>{moment(item.transaction_date).format("Do MMMM, YYYY, h:mm:ss a")}</TableCell>
                                <TableCell>{item.service_type}</TableCell>
                                <TableCell>{item.available_credits}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                  <CircularProgress size={25} />
                                </Box>
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Pagination count={Math.ceil(transactionHistory?.count / 10)} page={page} onChange={handleChangePage} />
                    </Box>
                  </>
                ) : (
                  <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                    <img src={EmptyData} width="150px" height="auto" />
                    <Typography variant="h4" style={{ marginTop: "10px" }}>
                      No Transactions yet
                    </Typography>
                  </Box>
                ))}
            </Box>
            <RechargeDialog open={rechargeDialog} setOpen={setRechargeDialog} success={success} setSuccess={setSuccess} />
          </Box>
        ) : null}
      </div>
    </>
  );
};

export default CollegeSettings;

import { Button } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Axios from "axios";
import React, { Fragment, useEffect, useRef } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import EmployeIcon from "../../../../assets/icon/newicon.svg";
import nologo from "../../../../assets/images/no_logo.png";
import demo from "../../../../assets/common/giphy.gif";
import VerifiedIcon from "../../../../assets/svg/ic_baseline-verified.svg";
//import { BackendBaseApi } from "../../../../../constants/constants";
import { BackendBaseApi } from "../../../../constants/constants";
import "../college/collegeCard.css";

const sideLine = {
  width: "33%",
  justifyContent: "center",
  alignItems: "center",
};
const sideLine2 = {
  width: "25%",
  justifyContent: "center",
  alignItems: "center",
};

export default function CompanyCard({ data, apiCall, cardFromCollege }) {
  const alert = useAlert();

  const done = useRef([]);

  useEffect(() => {
    done.current = done.current.slice(0, data.length);
  }, [data.length]);

  const handleReject = (item) => {
    const body = {
      company_id: JSON.parse(localStorage.getItem("company")).company,
      college_id: item.college_id,
      is_approved: false,
    };

    Axios.post(BackendBaseApi.PRAVESH + "api/company/invite/", body, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        alert.alert("Invite Rejected");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAccept = (item) => {
    const body = {
      company_id: JSON.parse(localStorage.getItem("company")).company,
      college_id: item.college_id,
      is_approved: true,
    };
    Axios.post(BackendBaseApi.PRAVESH + "api/company/invite/", body, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        alert.success("Invite Accepted");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {data.map((item, index) => (
        <div
          key={index}
          // className="college-company-card"
          // style={{ width: "100vw" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="mt-3">
                <div
                  className="card mb-3"
                  style={{
                    borderRadius: "30px",
                    backdropFilter: "blur(30px)",
                    // boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                    border: "solid 1px #f1f1f1",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Link
                    to={`/public/college/profile/${
                      item.id ? item.id : item.college_id
                    }`}
                    target="_blank"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-2 col-12 d-flex flex-row bd-highlight  ">
                          <img
                            align="center"
                            src={item.logo ? item.logo : nologo}
                            // style={item.logo?{}:{height:'150px'}}
                            className="img-fluid"
                            style={{ borderRadius: "30px" }}
                          />
                        </div>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-6 d-flex flex-col  ">
                              <div className="d-flex flex-row align-items-center">
                                {/* <img
                                src="https://www.designevo.com/res/templates/thumb_small/red-encircled-torch-and-book-emblem.png"
                                alt="Avatar"
                                className="box-avatar"
                              ></img> */}
                                <div
                                  className="d-flex flex-col ml-1"
                                  style={{ paddingLeft: "5%" }}
                                >
                                  <h5 className="card-title d-flex flex-row bd-highlight   mb-0">
                                    {item.name}
                                  </h5>
                                  <div className="flex justify-space-between align-item-center company-detail-line__company-detail-line">
                                    {item.size != "" && (
                                      <div
                                        className="fs-15 text-grey"
                                        style={{ width: "200px" }}
                                      >
                                        {" "}
                                        <img src={EmployeIcon} /> {item.size}{" "}
                                        {"Students"}
                                      </div>
                                    )}
                                    <div
                                      className="fs-12"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {/* <img src={VerifiedIcon} /> */}
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 18 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17.25 8L15.42 5.9075L15.675 3.14L12.9675 2.525L11.55 0.125L9 1.22L6.45 0.125L5.0325 2.5175L2.325 3.125L2.58 5.9L0.75 8L2.58 10.0925L2.325 12.8675L5.0325 13.4825L6.45 15.875L9 14.7725L11.55 15.8675L12.9675 13.475L15.675 12.86L15.42 10.0925L17.25 8ZM7.5675 11.54L4.7175 8.6825L5.8275 7.5725L7.5675 9.32L11.955 4.9175L13.065 6.0275L7.5675 11.54Z"
                                          fill="#007BFF"
                                        />
                                      </svg>
                                      <span
                                        style={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: "700",
                                          fontFamily: "open-sans",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        Verified
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12 d-flex align-item-center mt-2  justify-end">
                              {apiCall == "pending" ? (
                                <div
                                  className="d-flex align-item-center"
                                  style={{
                                    marginRight: "80px",
                                    width: "220px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    style={{
                                      border: "3px solid #3282C4",
                                      borderRadius: "30px",
                                      width: "100px",
                                      marginHorizontal: "20px",
                                    }}
                                    onClick={() => handleReject(item)}
                                  >
                                    <b
                                      style={{
                                        color: "#3282C4",
                                      }}
                                    >
                                      Reject
                                    </b>
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      border: "2px solid #3282C4",
                                      borderRadius: "30px",
                                      backgroundColor: "#3282C4",
                                      width: "100px",
                                    }}
                                    className="text-white"
                                    onClick={() => handleAccept(item)}
                                  >
                                    <b>Accept</b>
                                  </Button>
                                </div>
                              ) : null}

                              {cardFromCollege === true ? (
                                <div
                                  className="d-flex align-item-center"
                                  style={{
                                    marginRight: "80px",
                                    width: "220px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Link to="/company/post-job">
                                    <Button
                                      variant="outlined"
                                      style={{
                                        border: "3px solid #007BFF",
                                        width: "150px",
                                        marginHorizontal: "20px",
                                        borderRadius: "30px",
                                      }}
                                      // onClick={() => handleReject(item)}
                                    >
                                      <b
                                        style={{
                                          color: "#007BFF",
                                        }}
                                      >
                                        Post Job
                                      </b>
                                    </Button>
                                  </Link>
                                </div>
                              ) : null}
                              {/* <img className="mr-4" src={BookmarkIconSvg} />
                            <img src={ArrowdownIcon} /> */}
                              {/* <Button
                                                        variant="contained"
                                                        style={{ backgroundColor: "#3282C4" }}
                                                        data-toggle="modal"
                                                        data-target="#invitemodal"
                                                        className="text-white fw-700 w-50"
                                                    >
                                                        Invite
                                                    </Button> */}
                              {/* <SaveIcon/> */}
                            </div>
                          </div>
                          <hr className="my-2" />
                          <div className="row ml-1 justify-flex-start">
                            <div className="col-md-4 p-2 rounded bg-f2">
                              <div className="row">
                                <p className="fs-16 fw-700 ml-4 mt-2">
                                  Details
                                </p>
                              </div>
                              <div
                                className="flex"
                                style={{ justifyContent: "space-evenly" }}
                              >
                                <div
                                  className="flex flex-col pd-10"
                                  style={sideLine}
                                >
                                  <h4
                                    className="fs-16 fw-700"
                                    style={{ textAlign: "center" }}
                                  >
                                    {item.city}, {item.state}
                                  </h4>
                                  <span className="fs-14 fw-300 text-left">
                                    Location
                                  </span>
                                </div>
                                <div
                                  style={{
                                    borderLeft: "1px solid #BDBDBD",
                                  }}
                                ></div>
                                <div
                                  className="flex flex-col pd-10"
                                  style={sideLine}
                                >
                                  <h4 className="fs-16 fw-700">
                                    {item.year_founded != null ? (
                                      item.year_founded.slice(0, 4)
                                    ) : (
                                      <b>NA</b>
                                    )}
                                  </h4>
                                  <span className="fs-14 fw-300 text-left">
                                    Founded
                                  </span>
                                </div>
                                <div
                                  style={{
                                    borderLeft: "1px solid #BDBDBD",
                                  }}
                                ></div>
                                <div
                                  className="flex flex-col pd-10"
                                  style={sideLine}
                                >
                                  <h4 className="fs-16 fw-700">
                                    {item.is_onboarded_date
                                      ? item.is_onboarded_date
                                      : "-"}{" "}
                                  </h4>
                                  <span className="fs-14 fw-300">
                                    {item.is_onboarded_date ? "On Getwork" : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-7  des-left-10-mobo-left-zero mobo-top-10 p-2 rounded bg-f2">
                              <div className="row">
                                <p className="fs-16 fw-700 ml-4 mt-2">
                                  Organization
                                </p>
                              </div>
                              <div
                                className="flex"
                                style={{ justifyContent: "space-evenly" }}
                              >
                                {/*<div*/}
                                {/*  className="flex flex-col pd-10"*/}
                                {/*  style={sideLine2}*/}
                                {/*>*/}
                                {/*  <h4 className="fs-16 fw-700">*/}
                                {/*    {item.first_name} {item.last_name}*/}
                                {/*  </h4>*/}
                                {/*  <span className="fs-14 fw-300 text-left">*/}
                                {/*    Contact*/}
                                {/*  </span>*/}
                                {/*</div>*/}
                                <div
                                // style={{
                                //   borderLeft: "1px solid #BDBDBD",
                                // }}
                                ></div>
                                <div
                                  className="flex flex-col pd-10"
                                  style={sideLine2}
                                >
                                  <h4 className="fs-16 fw-700">
                                    {item.organization_type}
                                  </h4>
                                  <span className="fs-14 fw-300 text-left">
                                    Type
                                  </span>
                                </div>
                                <div
                                  style={{
                                    borderLeft: "1px solid #BDBDBD",
                                  }}
                                ></div>
                                <div
                                  className="flex flex-col pd-10"
                                  style={sideLine2}
                                >
                                  <h4 className="fs-16 fw-700">
                                    {item.avg_ctc / 100000} LPA
                                  </h4>
                                  <span className="fs-14 fw-300 text-left">
                                    Average CTC
                                  </span>
                                </div>
                                <div
                                  style={{
                                    borderLeft: "1px solid #BDBDBD",
                                  }}
                                ></div>
                                <div
                                  className="flex flex-col pd-10"
                                  style={sideLine2}
                                >
                                  <h4
                                    className="fs-16 fw-700"
                                    style={{ textAlign: "center" }}
                                  >
                                    {item.skills !== null ? (
                                      <>
                                        {item.skills
                                          .slice(0, 2)
                                          .map((data, i) => {
                                            return (
                                              <Fragment key={i}>
                                                {" "}
                                                {data.skill_name + ", "}
                                              </Fragment>
                                            );
                                          })}
                                        {item.skills.length > 2 && (
                                          <Fragment>...</Fragment>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </h4>
                                  <span className="fs-14 fw-300 text-left">
                                    Popular Skills
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

import { Avatar, Paper } from "@material-ui/core";
import React from "react";

export default function Index() {
  const data = JSON.parse(localStorage.getItem("user_details"));
  return (
    <div>
      {/* <Link to="/student/profile"> */}
      <div>
        <Paper className="top-des__profile-card" style={{ marginTop: "0px", height: "auto" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Avatar src={data.profile_picture} alt="profile picture" className="my-2" style={{ height: 75, width: 75, marginLeft: "1rem" }} />
            <i onClick={() => window.open("/student/edit-profile", "_self")} className="fas fa-edit mt-1 pt-1 text-muted edit-icon" style={{ height: 15, width: 15 }} />
          </div>
          <p
            className=" mb-2 overflow-hidden"
            style={{
              textAlign: "center",
              fontSize: "20px !important",
              fontWeight: "bold",
            }}
          >
            {data.first_name} {data.last_name}
          </p>
          <p className="fs-12 text-center overflow-hidden">
            {data?.student_user_details?.education.find((item) => item.is_current === true)?.temp_college_name !== null &&
            data?.student_user_details.education.find((item) => item.is_current === true)?.college === 142
              ? data?.student_user_details.education.find((item) => item.is_current === true)?.temp_college_name
              : data?.student_user_details.education.find((item) => item.is_current === true)?.college_name &&
                data?.student_user_details.education.find((item) => item.is_current === true)?.college_name}
          </p>

          <p className="fs-14 text-center mb-0 overflow-hidden" style={{ marginTop: -10 }}>
            {data.student_user_details.education.find((item) => item.is_current === true) ? (
              <>
                {data.student_user_details.education.find((item) => item.is_current === true)?.degree
                  ? data.student_user_details.education.find((item) => item.is_current === true)?.degree
                  : "NA"}
              </>
            ) : (
              ""
            )}
          </p>

          {/* <p className="fs-12 text-center overflow-hidden">{data.student_user_details.education[0].college_name}</p> */}
        </Paper>
      </div>
      {/* </Link> */}
    </div>
  );
}

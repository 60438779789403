import React from 'react'
import ReactDOM from 'react-dom'
import Avatar from 'react-avatar-edit'


export default class Avatarimage extends React.Component {


 
  constructor(props) {
    super(props)
    const src = null
    this.state = {
      preview: null,
      src
    }
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
  }

  
  
  onClose() {
    this.setState({preview: null})
  }
  
  onCrop(preview) {
    this.setState({preview})
  }
 
  onBeforeFileLoad(elem) {
    if(elem.target.files[0].size > 7168000000000000){
      alert("File is too big!");
      elem.target.value = "";
    }
  }
  
  render(){
    return (
      <div>
        <Avatar
          width='auto'
          height={230}
          onCrop={this.onCrop}
          onClose={this.onClose}
          onBeforeFileLoad={this.onBeforeFileLoad}
          src={this.state.src}
          
        />
        <img src={this.state.preview} alt="Preview" /> 
        {/* This {this.state.preview should be entered in database} */}
      </div>
    )
  
}


}

 
import React from "react";
import { Paper, makeStyles, Typography, Button } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
  },
  job: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  btn: {
    maxWidth: 520,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

function Certificate({ data }) {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">Courses/Certifications</Typography>
        <br />

        {data?.map((item) => (
          <>
            <div className={classes.job}>
              <Typography variant="subtitle">{item?.name}</Typography>
            </div>
            <Typography variant="subtitle2" style={{ color: "#6c757d" }}>
              {moment(item?.start_date).format("MMM YYYY")}
              {"-"}
              {moment(item?.end_date).format("MMM YYYY")}{" "}
            </Typography>
            <Typography variant="subtitle2" style={{ color: "#6c757d" }}>
              {!item?.expiration && (
                <>
                  {" "}
                  <b>Valid Upto:</b>
                  {moment(item?.valid_date).format("MMM YYYY")}
                </>
              )}
            </Typography>

            <br />
          </>
        ))}
      </Paper>
    </>
  );
}

export default Certificate;

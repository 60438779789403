import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function Cerification({data}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography variant="h6">Courses and Cerification</Typography>
        </div>

        {data?.map((item)=>
          (<div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div>
              <div className={classes.div}>
                <Typography
                    variant="body2"
                    style={{
                      fontWeight: "bold",
                    }}
                >
                  {item?.name}
                </Typography>
                {/*<Typography*/}
                {/*    variant="body2"*/}
                {/*    color="primary"*/}
                {/*    style={{*/}
                {/*      fontWeight: "bold",*/}
                {/*    }}*/}
                {/*>*/}
                {/*  View*/}
                {/*</Typography>*/}
              </div>

              <Typography
                  variant="body2"
                  style={{
                    fontSize: "12px",
                    color: "#6C757D",
                  }}
              >
                {item?.institute}
              </Typography>

              <Typography
                  variant="body2"
                  style={{
                    fontSize: "12px",
                    color: "#6C757D",
                  }}
              >
                {item?.start_date} - {item?.end_date}
              </Typography>
              <Typography
                  variant="body2"
                  style={{
                    fontSize: "12px",
                    color: "#6C757D",
                  }}
              >
                Valid Upto : {item?.expiration ? (item?.valid_date): ("Doesn't expire")}
              </Typography>
            </div>
          </div>


          )
        )}


      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";

const convertDate = (date_start, date_end) => {
  var new_date;
  if (date_start !== null && date_end !== null) {
    new_date =
      date_start.slice(8, 10) +
      "/" +
      date_start.slice(5, 7) +
      "/" +
      date_start.slice(0, 4) +
      " " +
      " to " +
      date_end.slice(8, 10) +
      "/" +
      date_end.slice(5, 7) +
      "/" +
      date_end.slice(0, 4);
  } else {
    new_date =
      date_start.slice(8, 10) +
      "/" +
      date_start.slice(5, 7) +
      "/" +
      date_start.slice(0, 4);
  }

  return new_date;
};

export default convertDate;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import UserInfo from "./UserInfo";
import UserDetail from "../Resume/UserDetails/index";
import Skill from "./UserDetails/Skill";
import { Container, Button, Hidden } from "@material-ui/core";
import Expereince from "./UserDetails/Expereince";
import Education from "./UserDetails/Education";
import Project from "./UserDetails/Project";
import Languages from "./UserDetails/Languages";
import Achievements from "./UserDetails/Achievements";
import Hobbies from "./UserDetails/Hobbies";
import Certificate from "./UserDetails/Certificate";
import { ThemeProvider } from "@material-ui/core/styles";
import Newtheme from "../../../NewAppLayout/components/Theme/Newtheme";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "74%",
    fontFamily: "Nunito",
    background: "#fff",
    margin: "auto",
  },
  btn: {
    maxWidth: 520,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  btn_mobile: {
    width: "100%",
    // margin: `${theme.spacing(1)}px auto`,
    // padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  btnnn: {
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
    "&:disabled": {
      backgroundColor: theme.palette.domain.light,
    },
  },

  "@media only screen and (max-width: 1100px)": {
    root: {
      width: "100%",
      marginTop: "20%",
    },
  },
}));

export default function Layout({ data }) {
  const classes = useStyles();
  const [projectArray, setProjectArray] = useState([]);
  const [publicationArray, setPublicationArray] = useState([]);

  useEffect(() => {
    console.log(data);
    var arr_project = data?.student_user_details?.project?.filter(
      (item) => item.type === "Project"
    );
    var arr_publication = data?.student_user_details?.project?.filter(
      (item) => item.type === "Publication"
    );
    setProjectArray(arr_project);
    setPublicationArray(arr_publication);
  }, [data]);

  return (
    <ThemeProvider theme={Newtheme}>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={3} style={{ paddingTop: "10%" }}>
            <Grid item xs={12} sm={6}>
              <UserInfo data={data} />
              {localStorage.getItem("gw_token") ? (
                <>
                  <br />
                  <UserDetail data={data} />
                </>
              ) : null}
              {data?.student_user_details?.skill?.length > 0 && (
                <>
                  <br />
                  <Skill data={data} />
                </>
              )}
              <br />
              <Hidden smDown>
                {" "}
                {data?.student_user_details?.languages?.length > 0 && (
                  <>
                    <Languages data={data} />
                    <br />
                  </>
                )}
                {data?.student_user_details?.resume && (
                  <>
                    <div className={classes.btn}>
                      <Button variant="contained" className={classes.btnnn}>
                        {" "}
                        <a
                          href={data?.student_user_details?.resume}
                          download
                          target="blank"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Download Resume
                        </a>
                      </Button>
                    </div>
                    <br />
                  </>
                )}
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={6}>
              {data?.student_user_details?.work_ex?.length > 0 && (
                <>
                  <Expereince data={data} />
                  <br />
                </>
              )}
              {data?.student_user_details?.education?.length > 0 && (
                <>
                  <Education data={data} />
                  <br />
                </>
              )}
              {projectArray?.length > 0 && (
                <>
                  <Project data={projectArray} type={"Projects"} />
                  <br />
                </>
              )}

              {publicationArray?.length > 0 && (
                <>
                  <Project data={publicationArray} type={"Publication"} />
                  <br />
                </>
              )}

              {data?.student_user_details?.certification_data?.length > 0 && (
                <>
                  <Certificate
                    data={data?.student_user_details?.certification_data}
                  />
                  <br />
                </>
              )}

              {data?.student_user_details?.achievements?.length > 0 && (
                <>
                  <Achievements
                    data={data?.student_user_details?.achievements}
                  />
                  <br />
                </>
              )}
              {data?.student_user_details?.hobbies?.length > 0 && (
                <>
                  <Hobbies data={data} />
                  <br />
                </>
              )}

              <Hidden smUp>
                {" "}
                {data?.student_user_details?.languages?.length > 0 && (
                  <>
                    <Languages data={data} />
                    <br />
                  </>
                )}
                {data?.student_user_details?.resume && (
                  <>
                    <div className={classes.btn_mobile}>
                      <Button variant="contained" className={classes.btnnn}>
                        {" "}
                        <a
                          href={data?.student_user_details?.resume}
                          download
                          target="blank"
                          style={{
                            textDecoration: "none",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          Download Resume
                        </a>
                      </Button>
                    </div>
                    <br />
                  </>
                )}
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
}

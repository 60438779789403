import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import list from "../../../assets/images/list.jpg";

const useStyles = makeStyles((theme) =>
  createStyles({
    elevation1: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "20px 40px 40px 40px",
      padding: "40px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
    },
    WhiteBox2: {
      backgroundColor: "#fff",
      margin: "0 40px 0 0",
      padding: "40px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    WhiteBox3: {
      backgroundColor: "#fff",
      margin: "40px 40px 40px 0",
      padding: "40px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    imageAndText: {
      float: "left",
      padding: "0 20px 15px 0",
    },
    SplitBox: {
      display: "flex",
      flexDirection: "column",
    },
    para: {
      color: "#4c4949",
      fontSize: "18px",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
    },
    heading1: {
      fontWeight: "bolder",
      color: "#444343",
    },
    borderBox: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      // border: "1px solid grey",
      padding: "10px",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        transform: "scale(1.02)",
        transition: "transform 0.2s",
      },
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
    },
    "@media only screen and (max-width: 768px)": {
      WhiteBox: {
        width: "100%",
        marginLeft: "-5px",
        marginRight: "0px",
      },
      heading: {
        fontSize: "14px",
      },
      newpara: {
        fontSize: "12px",
      },
    },
  })
);

export default function OverviewBox(props) {
  const { data } = props;
  const classes = useStyles();
  const [courseData, setCourseData] = useState({});
  useEffect(() => {
    if (data) {
      setCourseData(data);
    }
  }, [data]);
  return (
    <Box className={classes.WhiteBox}>
      <h2 className={classes.heading1}>
        More Details on Courses and Specializations
      </h2>
      <br />
      <Grid container spacing={3}>
        {courseData?.courses?.courses?.courses.map((item) => (
          <Grid item md={4}>
            {/* <Box className={classes.borderBox}> */}
            <div className={classes.root}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {item.display_name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.listItem}>
                  {item.admission && (
                    <>
                      <p className={classes.newpara}>
                        <img src={list} alt="" height="20px" />
                        &nbsp; &nbsp; &nbsp;<b>Admission Start Date:</b>&nbsp;
                        &nbsp;
                        {item.admission
                          ? item.admission.admission_start_date
                          : "NA"}
                      </p>

                      <p className={classes.newpara}>
                        <img src={list} alt="" height="20px" />
                        &nbsp; &nbsp; &nbsp;<b>Admission End Date:</b>&nbsp;
                        &nbsp;
                        {item.admission
                          ? item.admission.admission_end_date
                          : "NA"}
                      </p>
                    </>
                  )}
                  {item.course_rating && (
                    <p className={classes.newpara}>
                      <img src={list} alt="" height="20px" />
                      &nbsp; &nbsp; &nbsp;<b>Course Rating:</b>&nbsp; &nbsp;
                      {parseInt(item?.course_rating).toFixed(2)}/10
                    </p>
                  )}
                  <p className={classes.newpara}>
                    <img src={list} alt="" height="20px" />
                    &nbsp; &nbsp; &nbsp;<b>Duration:</b>&nbsp; &nbsp;
                    {item.duration_year} Years
                  </p>
                  {item.exams && item.exams.length > 0 && (
                    <p className={classes.newpara}>
                      <img src={list} alt="" height="20px" />
                      &nbsp; &nbsp; &nbsp;<b>Exam:</b>&nbsp; &nbsp;
                      {item.exams.map((inner) =>
                        item.exams.indexOf(inner) !== item.exams.length - 1
                          ? inner.name + ", "
                          : inner.name
                      )}
                    </p>
                  )}
                  {Object.keys(item?.fees_data) && item?.fees_data?.amount && (
                    <p className={classes.newpara}>
                      <img src={list} alt="" height="20px" />
                      &nbsp; &nbsp; &nbsp;<b>Fees:</b>&nbsp; &nbsp;
                      {item?.fees_data?.amount}&nbsp; INR
                    </p>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
            {/* </Box> */}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

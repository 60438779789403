import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { BackendBaseApi } from "../../../../constants/constants";

const Skills = ({ type, newSkill, setNewSkill }) => {
  const [skillList, setSkillList] = useState({});
  const [skill, setSkill] = useState();

  const Skills = Object.keys(skillList).map((skill) => {
    return { name: skill, value: skill };
  });

  const TypeAndSearchskill = () => (
    <SelectSearch
      options={Skills}
      value={skill}
      onChange={setSkill}
      search
      placeholder="Search skill"
    />
  );

  const getAllSkills = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/education/skills")
      .then((res) => {
        let temp = {};
        res.data.data.skills.forEach((skill) => {
          if (type == "all") temp[skill.skill_name] = skill.skill_id;
          else if (skill.skill_type == type)
            temp[skill.skill_name] = skill.skill_id;
        });

        setSkillList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNewSkill({
      skill_name: skill,
      skill_id: skillList[skill],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skill]);

  return (
    <>
      <TypeAndSearchskill />
    </>
  );
};

export default Skills;

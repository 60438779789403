import React from "react";
import "./ResumeAutofill.css";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import CommonButton from "../CommonButton/CommonButton";
import { Tooltip, Backdrop, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import { uniqueArray } from "../../../utils/common/filterJob";
import { makeStyles } from "@material-ui/core/styles";
import loaderGIF from "../../../assets/images/loopingcircles-3.gif";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function ResumeAutofill({ alert, setResume, setResumeParsedData, clearForm, setClearForm, parseSuccess, setParseSuccess, positions }) {
  const classes = useStyles();
  const [resumeLoading, setResumeLoading] = React.useState(false);

  const uploadResumeForParsing = (file) => {
    // e.preventDefault();
    // const file = e.target.files[0];
    const ext = file.name.substring(file.name.lastIndexOf(".") + 1);
    // if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
    //   alert.error("Only PDF & DOC Format Allowed!", { position: positions.TOP_RIGHT });
    //   return;
    // } else {
    if (file.size < 2097152) {
      setResume(file);
      let resumeData = new FormData();
      // resumeData.append('user', 29);
      resumeData.append("resume", file);
      setResumeLoading(true);
      Axios.post(BackendBaseApi.PRAVESH + "api/education/resume_upload/", resumeData, { withCredentials: true, headers: { "content-type": "multipart/form-data" } })
        .then((res) => {
          if (!res.data.success) {
            alert.error(res.data.error, { position: positions.TOP_RIGHT });
          } else {
            // alert.success(res.data.data.message);
            setParseSuccess(true);
            setResumeParsedData(res.data.data.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 429) {
            var matches = err.response.data.detail.match(/(\d+)/);
            if (matches) alert.error(`Request was throttled. Expected available in ${Math.ceil(matches[0] / 60)} minutes.`, { position: positions.TOP_RIGHT });
            else alert.error(err.response.data.detail, { position: positions.TOP_RIGHT });
          } else alert.error("Some error occured while parsing your resume!", { position: positions.TOP_RIGHT });
        })
        .finally(() => {
          setResumeLoading(false);
        });
    } else alert.error("File Size Limit: 2MB", { position: positions.TOP_RIGHT });
    // }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };
  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    if (file.length === 1) {
      uploadResumeForParsing(file[0]);
    } else {
      alert.error("Drag only one File", { position: positions.TOP_RIGHT });
    }
  };

  const clearParsing = () => {
    setParseSuccess(false);
    setClearForm(clearForm + 1);
  };

  return (
    <div id="resume_autofill">
      {resumeLoading && (
        <Backdrop className={classes.backdrop} open={resumeLoading}>
          <img src={loaderGIF} alt="Loading..." width="160px" height="90px" />
        </Backdrop>
      )}
      <div id="resume_autofill_text" onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={fileDrop}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <span style={{ color: "black", fontWeight: "bold", marginBottom: "0.5rem" }}>
            <i class="fas fa-bolt"></i> AUTOFILL USING RESUME <span id="required_sign">* &nbsp;</span>
          </span>
          <span id="resume_autofill_save_time" style={{ color: "#636d77" }}>
            Save time by importing your resume.
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <span id="drag-drop-parsing-text" style={{ color: "#636d77" }}>
            <i class="fas fa-upload" style={{ color: "black" }}></i> Drag & Drop your Resume here
          </span>
          <span style={{ color: "#636d77", fontSize: "10px" }}>or</span>
          <CommonButton id="resume_autofill_btn" variant="contained" component="label" style={{ outline: "none", minWidth: "200px" }}>
            Browse Resume
            <input id="parsing_resume_field" type="file" hidden onChange={(e) => uploadResumeForParsing(e.target.files[0])} />
          </CommonButton>
        </div>
      </div>
      {parseSuccess && (
        <div>
          <Alert
            style={{ marginTop: "1rem", fontSize: "14px" }}
            action={
              <Tooltip title="Clear Form" placement="right">
                <DeleteIcon style={{ cursor: "pointer", color: "#35a063" }} onClick={() => clearParsing()} />
              </Tooltip>
            }
          >
            Autofill completed! Please review the information we have filled in below.
          </Alert>
        </div>
      )}
    </div>
  );
}

export default ResumeAutofill;

import React from "react";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PhoneIcon from "@material-ui/icons/Phone";
import MarkunreadIcon from "@material-ui/icons/Markunread";
// import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
  },
}));

function UserDetails({ data }) {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">Contact and Portfolio</Typography>
        <br />
        {data.phone && (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <PhoneIcon style={{ color: "#007bff" }} />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography noWrap style={{ color: "#6c757d" }}>
                {data?.phone}
              </Typography>
            </Grid>
          </Grid>
        )}
        {data?.email && (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <MarkunreadIcon style={{ color: "#007bff" }} />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography noWrap style={{ color: "#6c757d" }}>
                {" "}
                {data?.email}
              </Typography>
            </Grid>
          </Grid>
        )}
        {/* <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <LanguageIcon style={{ color: "#007bff" }} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography noWrap>www.roshankapoor.in</Typography>
          </Grid>
        </Grid> */}
        <div>
          <br />

          {data?.profile_url?.length > 0 &&
            data?.profile_url?.map((item) => (
              // <Button>
              <a href={item.Value || item.value} target="blank" style={{ marginRight: 30 }}>
                <img src={item?.logo} height="32px" />
              </a>
              // </Button>
            ))}
        </div>
        <br />

        {data?.student_user_details?.about && (
          <>
            <Typography variant="h6">About Me</Typography>
            <Typography style={{ color: "#6c757d" }}>{data?.student_user_details?.about}</Typography>
          </>
        )}
      </Paper>
    </>
  );
}

export default UserDetails;

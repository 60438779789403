import { Typography, Button, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EmployerTab from "../components/Common/Tabs/EmployerTabs";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { BackendBaseApi } from "../../constants/constants";
import fetchApi from "../apiCall/fetchApi";
import { useLocation, useParams } from "react-router-dom";
import { generateCsvWithoutSpacing } from "../../common/generateCsv/generateCsv";
import PassCodeDialog from "../components/Dialog/PassCodeDialog";
import loader_gif from "../../assets/images/loopingcircles-3.gif";
import Axios from "axios";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

function PublicTrackEmployee() {
  const [jobList, setJobList] = useState(null);
  const [navbarData, setNavbarData] = useState(null);
  const [applicantData, setApplicantData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [applicantDataError, setApplicantDataError] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterDataError, setFilterDataError] = useState(false);
  let { token } = useParams();
  const alert = useAlert();
  const [activeStatusObject, setActiveStatusObject] = useState({
    count: 0,
    heading: "Applied",
    param: "&status_id=MQ==",
    round_id: null,
    status_id: 1,
    tooltip: "Applied",
  });
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobOptionClicked, setJobOptionClicked] = useState(false);
  const [selectedRow, setSelectedRow] = useState({
    details: [],
    selectedIds: [],
  });
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [clickFilterApplyButton, setClickFilterApplyButton] = useState(false);
  const [oneApiCallMade, setOneApiCallMade] = useState(false);
  const [searchByName, setSearchByName] = useState({
    searchString: "",
    searchNow: "stable",
  });
  const [cookie, setCookie] = useState();
  const [pageData, setPageData] = useState({ currentPage: 0, pageSize: 10 });
  const [paginationResponse, setPaginationResponse] = useState(null);
  const [applicantCountData, setApplicantCountData] = useState(null);
  const [openPasscode, setOpenPasscode] = useState(false);
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [sharedTrackData, setSharedTrackData] = useState(null);
  const [totalApplicantList, setTotalApplicantList] = useState([]);
  const [linkValid, setLinkValid] = useState(false);
  const initialSortData = { id: 9, name: "Applied On", value: "create_time" };
  const [sort, setSort] = useState(initialSortData);
  const [order, setOrder] = useState("dsc");

  const checkSelectedJob = (string) => {
    if (selectedJob || sharedTrackData?.job_id)
      return `${string}job_id=${btoa(
        selectedJob ? selectedJob?.job_id : sharedTrackData?.job_id
      )}`;
    else return ``;
  };

  const checkSearchByName = () => {
    if (searchByName?.searchNow) return `&search=${searchByName?.searchString}`;
    else return ``;
  };

  const checkPageData = () => {
    return `&page=${pageData?.currentPage + 1}&item_per_page=${
      pageData?.pageSize
    }`;
  };

  const checkSort = () => {
    if (order === "dsc" && sort.name === "Candidate Name") {
      const firstAndLast = sort.value.split(",");
      return `&sort_by=-${firstAndLast[0]},-${firstAndLast[1]}`;
    }
    return order === "asc"
      ? `&sort_by=${sort.value}`
      : `&sort_by=-${sort.value}`;
  };

  const checkFilterString = () => {
    let filterString = "";
    if (selectedFilters && Object.keys(selectedFilters).length > 0)
      Object.keys(selectedFilters).forEach((item) => {
        if (selectedFilters[item]?.length > 0) {
          filterString = `${filterString}&${item}=${generateCsvWithoutSpacing(
            selectedFilters[item],
            "id"
          )}`;
        }
      });
    return filterString;
  };

  const handleClickOpenPasscode = () => {
    setOpenPasscode(true);
  };

  const handleClosePasscode = () => {
    setOpenPasscode(false);
  };

  useEffect(() => {
    Axios.get(
      BackendBaseApi.NIYUKTI +
        `api/track_sharer/company/cookie_validation/?code=${token}`,
      {
        withCredentials: true,
      }
    )
      .then((res) => {
        if (res.data.success) ValidatePassCode();
        else setOpenPasscode(true);
      })
      .catch((err) => {
        setOpenPasscode(true);
      });
  }, []);

  useEffect(() => {
    if (sharedTrackData?.job_id) {
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/track_sharer/company/navbar/?action=${token}${checkSelectedJob(
          "&"
        )}`,
        headers: JSON.stringify({ withCredentials: true }),
        setResponse: setNavbarData,
      });
    }
  }, [sharedTrackData?.job_id]);

  const ValidatePassCode = () => {
    Axios.post(
      BackendBaseApi.NIYUKTI + "api/track_sharer/company/link_validation/",
      {
        link: window.location.href,
      }
    )
      .then((res) => {
        handleClosePasscode();
        setSharedTrackData(res.data);
        setSelectedJob({
          job_id: Number(res.data?.job_id),
          job_title: res.data?.job_title,
        });
        setLoadingValidation(true);
        setLinkValid(true);
      })
      .catch((err) => {
        alert.error("Some Error Occured");
        setLinkValid(true);
      });
  };

  useEffect(() => {
    if (
      navbarData?.data &&
      Object.keys(navbarData?.data)?.length > 0 &&
      !oneApiCallMade
    ) {
      const paramName = Object.keys(navbarData?.data).map(
        (item) => navbarData?.data[item]
      );
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/track_sharer/company/applicants/?action=${token}${
          paramName[0].param
        }${checkSelectedJob("&")}`,
        headers: JSON.stringify({ withCredentials: true }),
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
        setOneApiCallMade: setOneApiCallMade,
      });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/track_sharer/company/advance_filter/?action=${token}${
          paramName[0].param
        }${checkSelectedJob("&")}`,
        headers: JSON.stringify({ withCredentials: true }),
        setResponse: setFilterData,
        setLoading: setFilterLoading,
        setError: setFilterDataError,
      });
      setSelectedFilters(null);
    }
  }, [navbarData]);

  useEffect(() => {
    if (selectedJob) {
      if (activeStatusObject?.param && oneApiCallMade) {
        let filterString = checkFilterString();
        fetchApi({
          url: `${
            BackendBaseApi.NIYUKTI
          }api/track_sharer/company/applicants/?action=${token}${
            activeStatusObject?.param
          }${checkSelectedJob("&")}${checkSort()}`,
          headers: JSON.stringify({ withCredentials: true }),
          setResponse: setApplicantData,
          setLoading: setLoading,
          setError: setApplicantDataError,
          setPagination: setPaginationResponse,
          setOneApiCallMade: setOneApiCallMade,
        });
        fetchApi({
          url: `${
            BackendBaseApi.NIYUKTI
          }api/track_sharer/company/advance_filter/?action=${token}${
            activeStatusObject?.param
          }${checkSelectedJob("&")}`,
          headers: JSON.stringify({ withCredentials: true }),
          setResponse: setFilterData,
          setLoading: setFilterLoading,
          setError: setFilterDataError,
        });
        fetchApi({
          url: `${
            BackendBaseApi.NIYUKTI
          }api/track_sharer/company/navbar/?action=${token}${checkSelectedJob(
            "&"
          )}`,
          headers: JSON.stringify({ withCredentials: true }),
          setResponse: setNavbarData,
        });
        setSelectedFilters(null);
        setPageData({ currentPage: 0, pageSize: 10 });
      }
    }
  }, [activeStatusObject]);

  useEffect(() => {
    let filterString = "";

    if (clickFilterApplyButton) {
      if (selectedFilters && Object.keys(selectedFilters).length > 0)
        Object.keys(selectedFilters).forEach((item) => {
          if (selectedFilters[item]?.length > 0) {
            filterString = `${filterString}&${item}=${generateCsvWithoutSpacing(
              selectedFilters[item],
              "id"
            )}`;
          }
        });

      // fetchApi({
      //   url: `${
      //     BackendBaseApi.NIYUKTI
      //   }api/track_sharer/company/navbar/?action=${token}${checkSelectedJob(
      //     "&"
      //   )}${filterString}${checkSearchByName()}`,
      //   headers: JSON.stringify({ withCredentials: true }),
      //   setResponse: setNavbarData,
      // });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/track_sharer/company/applicants/?action=${token}${
          activeStatusObject?.param
        }${checkSelectedJob(
          "&"
        )}${filterString}${checkSearchByName()}${checkSort()}`,
        headers: JSON.stringify({ withCredentials: true }),
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
      setClickFilterApplyButton(false);
      // setSearchByName({ ...searchByName, searchNow: "stable" });
    }
  }, [clickFilterApplyButton]);

  useEffect(() => {
    // let filterString = "";

    if (
      searchByName?.searchNow === "search" ||
      searchByName?.searchNow === "cancel"
    ) {
      // if (selectedFilters && Object.keys(selectedFilters).length > 0)
      //   Object.keys(selectedFilters).forEach((item) => {
      //     if (selectedFilters[item]?.length > 0) {
      //       filterString = `${filterString}&${item}=${generateCsvWithoutSpacing(
      //         selectedFilters[item],
      //         "id"
      //       )}`;
      //     }
      //   });

      // fetchApi({
      //   url: `${
      //     BackendBaseApi.NIYUKTI
      //   }api/track_sharer/company/navbar/?action=${token}${checkSelectedJob(
      //     "&"
      //   )}${filterString}${checkSearchByName()}`,
      //   headers: JSON.stringify({ withCredentials: true }),
      //   setResponse: setNavbarData,
      // });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/track_sharer/company/applicants/?action=${token}${
          activeStatusObject?.param
        }${checkSelectedJob(
          "&"
        )}${checkFilterString()}${checkSearchByName()}${checkSort()}`,
        headers: JSON.stringify({ withCredentials: true }),
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
      // setClickFilterApplyButton(false);
      setSearchByName({ ...searchByName, searchNow: "stable" });
    }
  }, [searchByName?.searchNow]);

  function getSearchParameters() {
    var prmstr = location.search.substr(1);
    return prmstr != null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
  }

  function transformToAssocArray(prmstr) {
    var params = {};
    var prmarr = prmstr.split("&");
    for (var i = 0; i < prmarr.length; i++) {
      var tmparr = prmarr[i].split("=");
      params[tmparr[0]] = tmparr[1];
    }
    return params;
  }

  useEffect(() => {
    if (oneApiCallMade) {
      let filterString = checkFilterString();
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/track_sharer/company/applicants/?action=${token}${
          activeStatusObject?.param
        }${checkSelectedJob(
          "&"
        )}${checkPageData()}${filterString}${checkSearchByName()}${checkSort()}`,
        headers: JSON.stringify({ withCredentials: true }),
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
    }
  }, [pageData]);

  useEffect(() => {
    if (sort && oneApiCallMade) {
      let filterString = checkFilterString();
      setApplicantData(null);
      setTotalApplicantList([]);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/track_sharer/company/applicants/?action=${token}${
          activeStatusObject?.param
        }${checkSelectedJob(
          "&"
        )}${checkPageData()}${filterString}${checkSort()}`,
        headers: JSON.stringify({ withCredentials: true }),
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
    }
  }, [sort, order]);

  const callBackMainApplicantApi = () => {
    let filterString = checkFilterString();
    fetchApi({
      url: `${
        BackendBaseApi.NIYUKTI
      }api/track_sharer/company/applicants/?action=${token}${
        activeStatusObject?.param
      }${checkSelectedJob("&")}${checkPageData()}${filterString}${checkSort()}`,
      headers: JSON.stringify({ withCredentials: true }),
      setResponse: setApplicantData,
      setLoading: setLoading,
      setError: setApplicantDataError,
      setPagination: setPaginationResponse,
    });
    fetchApi({
      url: `${
        BackendBaseApi.NIYUKTI
      }api/track_sharer/company/navbar/?action=${token}${checkSelectedJob(
        "&"
      )}`,
      headers: JSON.stringify({ withCredentials: true }),
      setResponse: setNavbarData,
    });
  };

  const [selectedDownloadApplicantList, setSelectedDownloadApplicantList] =
    useState([]);

  useEffect(() => {
    if (selectedRow?.selectedIds?.length > 0) {
      let tempArray = [...applicantData?.data?.results?.applicant_data];
      tempArray = tempArray.filter((item) =>
        selectedRow.selectedIds.includes(item?.id)
      );
      setSelectedDownloadApplicantList(tempArray);
    } else setSelectedDownloadApplicantList([]);
  }, [selectedRow]);

  return (
    <>
      {!linkValid ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <img src={loader_gif} alt="" height="170px" />
        </Box>
      ) : (
        !openPasscode && (
          <>
            {sharedTrackData && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  padding: "20px 40px",
                  // background: "rgba(0, 173, 239, 0.06)",
                  border: "1px solid #00ADEF",
                  borderRadius: 12,
                  width: "40%",
                  flexDirection: "column",
                }}
              >
                <div>
                  <Typography
                    style={{ size: 20, weight: "bolder", color: "#00ADEF" }}
                  >
                    {" "}
                    Shared by : {sharedTrackData?.user_name},{" "}
                    {sharedTrackData?.college_company_name}
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{ size: 20, weight: "bolder", color: "#00ADEF" }}
                  >
                    {" "}
                    Job Title : {sharedTrackData?.job_title}
                  </Typography>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div>
                <Typography variant="h2">Job Applicants</Typography>
              </div>
              {sharedTrackData?.action_performed?.authority?.download && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CSVLink
                    data={
                      selectedDownloadApplicantList?.length > 0
                        ? selectedDownloadApplicantList
                        : totalApplicantList
                    }
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    filename={"Applicants.csv"}
                  >
                    <Button
                      startIcon={<FileDownloadOutlinedIcon />}
                      variant="contained"
                      color="primary"
                      style={{
                        height: "40px",
                        borderRadius: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        display: "flex",
                        boxShadow: "none",
                        marginLeft: "20px",
                      }}
                    >
                      Export
                    </Button>
                  </CSVLink>
                </div>
              )}
            </div>

            <EmployerTab
              navbarData={navbarData?.data}
              setActiveStatusObject={setActiveStatusObject}
              applicantData={applicantData}
              applicantDataError={applicantDataError}
              filterData={filterData?.data}
              filterDataError={filterDataError}
              filterLoading={filterLoading}
              loading={loading}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              activeStatusObject={activeStatusObject}
              value={value}
              setValue={setValue}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
              setClickFilterApplyButton={setClickFilterApplyButton}
              searchByName={searchByName}
              setSearchByName={setSearchByName}
              pageData={pageData}
              setPageData={setPageData}
              paginationResponse={paginationResponse}
              setPaginationResponse={setPaginationResponse}
              callBackMainApplicantApi={callBackMainApplicantApi}
              selectedJob={selectedJob}
              applicantCountData={applicantCountData?.data}
              setApplicantData={setApplicantData}
              isPublic={true}
              sharedPublicTrackData={sharedTrackData}
              totalApplicantList={totalApplicantList}
              setTotalApplicantList={setTotalApplicantList}
              setSort={setSort}
              sort={sort}
              order={order}
              setOrder={setOrder}
              isPublic={true}
            />
          </>
        )
      )}
      <br />
      <PassCodeDialog
        open={openPasscode}
        handleClickOpen={handleClickOpenPasscode}
        handleClose={handleClosePasscode}
        loadingValidation={loadingValidation}
        setLoadingValidation={setLoadingValidation}
        setCookie={setCookie}
        setSharedTrackData={setSharedTrackData}
        setSelectedJob={setSelectedJob}
        setLinkValid={setLinkValid}
      />
    </>
  );
}

export default PublicTrackEmployee;

import {
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Icon from "@material-ui/core/Icon";
import {
  createStyles,
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import { loadCSS } from "fg-loadcss";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SelectSearch from "react-select-search";
import p from "../../../../assets/common/edit.svg";
import plus from "../../../../assets/images/plus.png";
import gifLoader from "../../../../assets/payment-loading.gif";
import Refresh from "../../../../assets/common/refresh.svg";
import UserReferral from "../../../../common/Referrer/Referrer";
import { BackendBaseApi } from "../../../../constants/constants";
import theme from "../../../common/JobPostNew/FormComponents/MuiOutlined";
import ChangePasswordDialog from "../../Dialogs/ChangePasswordDialog";
import { getCompanyUserDetails } from "../action";
import "./userSettings.css";
import styles from "./userSettingsCSS";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 30,
    },
    modal1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1000px",
      borderRadius: 30,
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },

    expButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "center",
      contentAlign: "center",
      margin: "auto",
      justifyContent: "center",
      float: "center",
      display: "flex",
      boderRadius: "75px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      "@media(minWidth: 1199px)": {
        width: 120 + "!important",
        height: 120 + "!important",
      },
    },
  })
);

const UserSettings = () => {
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState("Edit Profile");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [bio, setBio] = useState("");
  const [college, setCollege] = useState("");
  const [year, setYear] = useState("");
  const [year33, setYear33] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [workexp, setWorkexp] = useState([]);

  const [colleges, setCollegeList] = useState({});
  const [colType, setColType] = useState();
  const [no_college, setNoCollege] = useState(false);
  const [imgtemp, setImgtemp] = useState();
  const [comp, setComp] = useState({});
  const [compType, setCompType] = useState();
  const [empTypes, setEmpTypes] = useState({});
  const [empType, setEmpType] = useState();
  const [oldpass, setOldPass] = useState();
  const [newpass, setNewPass] = useState();
  const [confpass, setConfPass] = useState();
  const [compActive, setCompActive] = useState(false);
  const [durationActive, setDurationActive] = useState(false);

  const [expDesignation, setexpDesignation] = useState();
  const [expEmpType, setExpEmpType] = useState("");
  const [expCompany, setExpCompany] = useState("");
  const [expStart, setExpStart] = useState();
  const [expEnd, setExpEnd] = useState();
  const [expCompName, setExpCompanyName] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [addingExperience, setAddingExperience] = useState(false);
  const [updatingExperience, setUpdatingExperience] = useState(false);
  const [selectedUpdateButton, setSelectedUpdateButton] = useState("");

  const year1 = 1980;
  const years = Array.from(new Array(46), (val, index) => index + year1);
  const classes = useStyles();

  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  let token = localStorage.getItem("gw_token");

  const getUserDetails = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/settings/user/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setFirst(res.data.data.first_name);
          setLast(res.data.data.last_name);
          setTitle(res.data.data.job_title);
          setEmail(res.data.data.email);
          setMobile(res.data.data.phone);
          setBio(res.data.data.about);
          setProfileImage(res.data.data.profile_picture);
          setCollege(res.data.data.college_name);
          setColType(res.data.data.college_name);
          setYear(res.data.data.graduation_year);
          setYear33(res.data.data.graduation_year);
          setWorkexp(res.data.data.work_experience);
          const temp = res.data.data.work_experience;

          var result = temp.map((v) => ({
            ...v,
            isActive: v.company_id === null ? true : false,
            isChecked: v.end_date === null ? true : false,
          }));

          if (res.data.data.college_id === null) setNoCollege(true);
          setWorkexp(result);

          setCompActive(false);

          setDurationActive(false);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUserDetails();
    getCollegeList();
    getComp();
    getEmployementTypes();
  }, []);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage(img);
    }
  };

  const blobToImage = (blob) => {
    setImageLoading(true);
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;

      setImgtemp(blob);

      const fd = new FormData();
      fd.append("profile_picture", blob);
      Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/user/", fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Updated Successfully");
            setProfileImage(res.data.data.profile_picture[0]);

            dispatch(getCompanyUserDetails(history, alert));
            setImageLoading(false);
          } else {
            alert.error(res.data.error);
            setImageLoading(false);
          }
        })
        .catch((err) => {
          alert.error("Error occured!");
          setImageLoading(false);
        });
    });
  };

  const handleUploadDetails = () => {
    setUpdatingDetails(true);
    var data1;

    if (no_college === true) {
      data1 = {
        first_name: first,
        last_name: last,
        job_title: title,
        phone: mobile,
        about: bio ? bio : "",
        college_id: null,
        college_name: college,
        work_experience: workexp,
        graduation_year: year ? year + "-01-01" : null,
      };
    } else {
      data1 = {
        first_name: first,
        last_name: last,
        job_title: title,
        phone: mobile,
        about: bio ? bio : "",
        college_name: null,
        college_id: colleges[colType],
        work_experience: workexp,
        graduation_year: year ? year + "-01-01" : null,
      };
    }

    Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/user/", data1, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Successfully");

          dispatch(getCompanyUserDetails(history, alert));
        } else {
          alert.error(res.data.error);
        }
        setUpdatingDetails(false);
      })
      .catch((err) => {
        alert.error("Error occured!");
        setUpdatingDetails(false);
      });
  };

  const handleUploadDetails1 = (index, id) => {
    setSelectedUpdateButton(index);
    setUpdatingExperience(true);
    var dat;

    if (workexp[index].isActive === true) {
      dat = {
        job_designation: workexp[index].job_designation,
        company_id: null,
        company_name: workexp[index].company_name,
        end_date:
          workexp[index].isChecked === true ? null : workexp[index].end_date,
        start_date: workexp[index].start_date,
        job_type_id: empTypes[empType],
        job_type: empType,
      };
    } else {
      dat = {
        job_designation: workexp[index].job_designation,
        company_id: comp[compType],
        company_name: null,
        start_date: workexp[index].start_date,
        end_date:
          workexp[index].isChecked === true ? null : workexp[index].end_date,
        job_type_id: empTypes[empType],
        job_type: empType,
      };
    }

    const url =
      BackendBaseApi.PRAVESH + "api/company/work_experience/" + id + "/";
    Axios.patch(url, dat, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Successfully");
        } else {
          alert.error(res.data.error);
        }
        setUpdatingExperience(false);
      })
      .catch((err) => {
        alert.error("Error occured!");
        setUpdatingExperience(false);
      });
  };

  const AddExperience = () => {
    setAddingExperience(true);
    var dat;

    if (compActive === true) {
      dat = {
        job_designation: expDesignation,
        company_id: null,
        company_name: expCompName,
        end_date: durationActive === false ? expEnd : null,
        start_date: expStart,
        job_type_id: empTypes[expEmpType],
        job_type: expEmpType,
        city_id: null,
        job_description: null,
        skills: null,
        company_website: null,
      };
    } else {
      dat = {
        company_id: comp[expCompany],
        job_designation: expDesignation,
        company_name: null,
        end_date: durationActive === false ? expEnd : null,
        start_date: expStart,
        job_type_id: empTypes[expEmpType],
        job_type: expEmpType,
        city_id: null,
        job_description: null,
        skills: null,
        company_website: null,
      };
    }
    let list = [...workexp];
    let fat = {};
    fat = dat;
    fat.isChecked = dat.end_date === null ? true : false;
    fat.isActive = dat.company_id === null ? true : false;

    if (compActive === false) {
      fat.company_name = expCompany;
    } else {
      fat.company_name = expCompName;
    }

    list.push(fat);

    setWorkexp(list);
    const url = BackendBaseApi.PRAVESH + "api/company/work_experience/";

    Axios.post(url, dat, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Added Successfully");
        } else {
          alert.error(res.data.error);
        }
        setAddingExperience(false);
      })
      .catch((err) => {
        alert.error("Error occured!");
        setAddingExperience(false);
      });
  };

  const getComp = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/company_list")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.all_companies.forEach((c) => {
            temp[c.company] = c.id;
          });
          setComp({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured");
      });
  };

  const getEmployementTypes = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/type/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((emp_type) => {
            temp[emp_type.job_type] = emp_type.id;
          });
          setEmpTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured");
      });
  };

  const getCollegeList = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/college")
      .then((res) => {
        let temp = {};
        res.data.forEach((d) => {
          temp[d.name] = d.id;
        });
        setCollegeList({ ...temp });
      })
      .catch((err) => {
        alert.error("Error Occured");
      });
  };

  const coll = Object.keys(colleges).map((d) => {
    return { name: d, value: d };
  });
  const comps = Object.keys(comp).map((i) => {
    return { name: i, value: i };
  });
  const emptypes = Object.keys(empTypes).map((emp_type) => {
    return { name: emp_type, value: emp_type };
  });
  const yearsss = years.map((y) => {
    return { name: y, value: y };
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });
  const CollegesList = () => (
    // <SelectSearch
    //   options={coll}
    //   value={colType}
    //   onChange={setColType}
    //   placeholder="Select"
    //   search
    // />
    <MuiThemeProvider theme={theme}>
      <Autocomplete
        value={colType}
        filterOptions={filterOptions}
        onChange={(event, newValue) => {
          setColType(newValue);
        }}
        id="controllable-states-demo"
        options={coll.map((option) => option.name)}
        renderOption={(option) => {
          return <span style={{ fontSize: 12 }}>{`${option}`}</span>;
        }}
        style={{ width: "90%" }}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </MuiThemeProvider>
  );

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen2(false);
  };

  const passwordChange = () => {
    setUpdatingPassword(true);
    const pass = {};
    if (newpass === confpass) {
      const pass = {
        password: oldpass,
        new_password: newpass,
      };

      Axios.post(BackendBaseApi.PRAVESH + "api/shared/user_password/", pass, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Password Changed Successfully");
            setOpen1(false);
          } else {
            alert.error(res.data.error);
          }
          setUpdatingPassword(false);
        })
        .catch((err) => {
          alert.error("Enter Correct Password");
          setUpdatingPassword(false);
        });
    } else {
      alert.error("New Password mismatch with confirm Pasword");
      setUpdatingPassword(false);
    }
  };

  const resetFields = () => {
    setOldPass("");
    setNewPass("");
    setConfPass("");
  };

  const clearFields = () => {
    setexpDesignation("");
    setExpEmpType("");
    setExpCompany("");
    setExpCompanyName("");

    setExpEnd();
    setExpStart();
    setDurationActive(false);
    setCompActive(false);
  };

  const checkTab = () => {
    switch (open) {
      case "Edit Profile":
        return "edit-profile";

      case "Edit Experience":
        return "edit-experience";

      case "Referral":
        return "referral";

      default:
        return "";
    }
  };

  useEffect(() => {
    window.history.pushState({}, null, `/company/user-settings/?${checkTab()}`);
  }, [open]);

  useEffect(() => {
    if (location.search === "?edit-profile") setOpen("Edit Profile");
    if (location.search === "?edit-experience") setOpen("Edit Experiences");
    if (location.search === "?referral") setOpen("Referral");
  }, []);

  return (
    <>
      <ChangePasswordDialog
        open1={open1}
        handleClose={handleClose}
        setOldPass={setOldPass}
        setNewPass={setNewPass}
        oldpass={oldpass}
        newpass={newpass}
        setConfPass={setConfPass}
        passwordChange={passwordChange}
        resetFields={resetFields}
        confpass={confpass}
        updatingPassword={updatingPassword}
      />

      <Grid container spacing={3} style={{ padding: "20px" }}>
        <Grid item sm={2}>
          <div style={styles.container}>
            <div style={styles.leftPaper}>
              <Button
                variant="contained"
                style={
                  open !== "Edit Profile"
                    ? styles.leftMenuBox
                    : styles.leftMenuBoxSelected
                }
                size="small"
                onClick={() => setOpen("Edit Profile")}
              >
                Edit Profile
              </Button>
              <Button
                variant="contained"
                style={
                  open !== "Edit Experience"
                    ? styles.leftMenuBox
                    : styles.leftMenuBoxSelected
                }
                size="small"
                onClick={() => setOpen("Edit Experience")}
              >
                Edit Experience
              </Button>
              <Button
                variant="contained"
                style={
                  open !== "Referral"
                    ? styles.leftMenuBox
                    : styles.leftMenuBoxSelected
                }
                size="small"
                onClick={() => setOpen("Referral")}
              >
                Referrals
              </Button>
              <Button
                variant="contained"
                style={styles.leftMenuBox}
                size="small"
                onClick={() => handleOpen()}
              >
                Change Password
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item sm={10}>
          {open === "Edit Profile" ? (
            <div style={{ width: "99%", display: "flex" }}>
              <div style={styles.paperStyle}>
                <h5>Details</h5>
                <Grid container spacing={0.2}>
                  <Grid item xs={12} sm={12}>
                    <div style={styles.avatarBox}>
                      <MuiThemeProvider theme={theme}>
                        {imageLoading ? (
                          <Avatar
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "75px",
                              border: "3px solid #e7e6e6",
                            }}
                            className={classes.large}
                            alt="user-image"
                            src={gifLoader}
                          />
                        ) : (
                          <Avatar
                            // style={{
                            //   width: "150px",
                            //   height: "150px",
                            //    borderRadius: "75px",
                            //   border: "3px solid #e7e6e6",
                            // }}
                            className={classes.large}
                            alt="user-image"
                            src={profileImage}
                          />
                        )}
                      </MuiThemeProvider>
                      <div>
                        <input
                          type="file"
                          id="image"
                          accept="image/png, image/gif, image/jpeg"
                          style={{ display: "none" }}
                          onChange={onImageChange}
                        ></input>
                        <label
                          for="image"
                          className="img_edit"
                          style={{ position: "relative", top: 110, left: -50 }}
                        >
                          <div
                          // style={{
                          //   backgroundColor: "white",
                          //   borderRadius: "75px",
                          //   border: "2px solid #e7e6e6",
                          //   padding: "5px 9px 5px 9px",
                          //   opacity: 0.8,
                          // }}
                          >
                            {/* <Icon className="fa fa-pencil" style={{ fontSize: "20px" }} /> */}

                            <img
                              src={p}
                              fontSize="inherit"
                              alt=""
                              height="40px"
                              style={{ margin: "5px 0px 0px 16px" }}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div style={styles.firstBox}>
                      <div style={styles.fieldDiv}>
                        <div style={styles.heading}>First Name</div>
                        <input
                          className="form-control"
                          type="text"
                          value={first}
                          onChange={(e) => setFirst(e.target.value)}
                          style={styles.inputBox}
                          required
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div style={styles.fieldDiv}>
                      <div style={styles.heading}>Last Name</div>
                      <input
                        className="form-control"
                        type="text"
                        value={last}
                        onChange={(e) => setLast(e.target.value)}
                        style={styles.inputBox}
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <div style={styles.fieldDiv}>
                      <div style={styles.heading}>Designation</div>
                      <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        style={styles.inputBox}
                        required
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    {" "}
                    <div style={styles.fieldDiv}>
                      <div style={styles.heading}>Mobile</div>
                      <input
                        className="form-control"
                        type="number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        style={styles.inputBox}
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <div style={styles.fieldDiv}>
                      <div style={styles.heading}>Email</div>
                      <div style={styles.inputBoxE}>
                        <b style={{ color: "#0645AD" }}>{email}</b>
                      </div>
                      <div style={{ fontSize: "13px" }}>
                        (Contact{" "}
                        <span style={{ color: "#007BFF" }}>
                          <a
                            style={{ color: "#007BFF" }}
                            href={`mailto:support@getwork.org?cc=prerit.chaudhary@getwork.org&Subject=Request%20to%20edit%20User%20Email%20id`}
                          >
                            getwork admin
                          </a>
                        </span>{" "}
                        to change the Email.)
                      </div>
                    </div>
                  </Grid>

                  {/* <Grid item xs={2}>
                    <div style={styles.heading1}>Alma Mater</div>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    // style={{ marginLeft: "-22px", marginTop: "6px" }}
                  >
                    <div style={styles.fieldDiv}>
                      <div style={styles.heading1}>Alma Mater</div>
                      {no_college === false ? (
                        <div style={styles.radioStyle}>
                          <>
                            <CollegesList className={classes.fullWidth} />
                          </>
                        </div>
                      ) : (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            value={college}
                            onChange={(e) => setCollege(e.target.value)}
                            placeholder="Enter your College"
                            style={{
                              width: "75%",
                              borderRadius: "30px",
                              fontWeight: "bold",
                              fontSize: "13px",
                              height: "40px",
                            }}
                          />
                        </>
                      )}{" "}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "110px",
                        marginTop: "-5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{
                          marginRight: "10px",
                        }}
                        checked={no_college}
                        onChange={() => {
                          setNoCollege(!no_college);
                        }}
                      ></input>
                      <span style={{ fontSize: 13 }}>
                        Alma Mater is not listed, let me type my own
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={styles.fieldDiv}>
                      <div style={styles.heading3}>Year </div>
                      {/* </Grid> */}

                      {/* <Grid item xs={2}> */}
                      <div>
                        <SelectSearch
                          options={yearsss}
                          value={year33}
                          onChange={setYear}
                          placeholder="Select"
                          search
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <div style={styles.fieldDiv}>
                      <div style={styles.heading}>About yourself</div>
                      <textarea
                        className="form-control"
                        type="text"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        style={styles.inputBox2}
                        required
                      />
                    </div>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <input
                      type="checkbox"
                      style={{
                        marginRight: "10px",
                      }}
                      checked={no_college}
                      onChange={() => {
                        setNoCollege(!no_college);
                      }}
                    ></input>
                    <span style={{ fontSize: 13 }}>
                      Alma Mater is not listed, let me type my own
                    </span>
                  </Grid> */}
                </Grid>

                {/* <div
            style={{
              marginLeft: "140px",
              marginTop: "20px",
              marginRight: "10px",
            }}
          >
            <Button
              onClick={handleOpen}
              variant="contained"
              style={styles.button}
            >
              Change Password
            </Button>
          </div> */}
                <div style={styles.buttonContainer}>
                  <Button
                    disabled={updatingDetails}
                    variant="contained"
                    //  style={styles.button}
                    onClick={handleUploadDetails}
                    style={{
                      width: "153px",
                      height: "40px",
                      borderRadius: "30px",
                      fontFamily: "Roboto",
                      color: "#fff",
                      backgroundColor: "#007BFF",
                      boxShadow: "none",
                      display: "flex",
                    }}
                  >
                    {updatingDetails ? (
                      <CircularProgress
                        size={25}
                        style={{ color: "#ffffff" }}
                      />
                    ) : (
                      "Update Profile"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}

          {open === "Edit Experience" ? (
            <div style={styles.paperStyle}>
              <div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal1}
                  open={open2}
                  onClose={handleClose1}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open2}>
                    <div className={classes.paper1}>
                      <Typography
                        align="center"
                        className={classes.grid3}
                        variant="h6"
                      >
                        <b>Add Work Experience</b>&nbsp;
                      </Typography>
                      <br />
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            className={classes.grid3}
                            style={{ fontFamily: "Roboto" }}
                            variant="body1"
                          >
                            <b>Designation</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          className={classes.grid4}
                          style={{ borderRadius: "30px" }}
                        >
                          <TextField
                            id="date"
                            label="Designation"
                            type="text"
                            onChange={(e) => {
                              setexpDesignation(e.target.value);
                            }}
                            value={expDesignation}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            className={classes.grid3}
                            style={{ fontFamily: "Roboto" }}
                            variant="body1"
                          >
                            <b>Employment Type</b>
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={10} className={classes.grid4}>
                          <SelectSearch
                            value={expEmpType}
                            onChange={(newValue) => {
                              setExpEmpType(newValue);
                            }}
                            options={emptypes.map((option) => ({
                              name: option.name,
                              value: option.name,
                            }))}
                            placeholder="Select Employment Type"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <br />

                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            className={classes.grid3}
                            style={{ fontFamily: "Roboto" }}
                            variant="body1"
                          >
                            <b>Company</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={10} className={classes.grid4}>
                          {compActive === false ? (
                            <SelectSearch
                              value={expCompany}
                              onChange={(newValue) => {
                                setExpCompany(newValue);
                              }}
                              options={comps.map((option) => ({
                                name: option.name,
                                value: option.name,
                              }))}
                              placeholder="Select Company"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <TextField
                              id="date"
                              label="Company"
                              type="text"
                              onChange={(e) => {
                                setExpCompanyName(e.target.value);
                              }}
                              value={expCompName}
                              variant="outlined"
                              style={{ width: "100%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10} style={{ marginTop: "-30px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={compActive}
                                onChange={(event) =>
                                  setCompActive(event.target.checked)
                                }
                                name="gilad"
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "Roboto",
                                  // fontWeight: "bold",
                                }}
                              >
                                Your Company is Not Listed here. Write your own
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            className={classes.grid3}
                            style={{ fontFamily: "Roboto" }}
                            variant="body1"
                          >
                            <b>Duration</b>&nbsp;
                            <span className={classes.redstar}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.grid4}>
                          <TextField
                            id="date"
                            label="Start Date"
                            type="date"
                            style={{ width: "80%" }}
                            className={classes.textField}
                            onChange={(e) => {
                              setExpStart(e.target.value);
                            }}
                            value={expStart}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={5}
                          className={classes.grid4}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {durationActive === false ? (
                            <TextField
                              min="2020-13-11"
                              id="date"
                              label="End Date"
                              type="date"
                              style={{ width: "80%" }}
                              className={classes.textField}
                              onChange={(e) => {
                                setExpEnd(e.target.value);
                              }}
                              value={expEnd}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputProps: { min: String(expStart) },
                              }}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={2}></Grid>
                        <Grid
                          item
                          xs={10}
                          className={classes.grid4}
                          style={{ marginTop: "-10px" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={durationActive}
                                onChange={(event) =>
                                  setDurationActive(event.target.checked)
                                }
                                name="gilad"
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "Roboto",
                                  //fontWeight: "bold",
                                }}
                              >
                                I am currently working at this role{" "}
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} className={classes.grid4}></Grid>
                        <Grid
                          item
                          xs={4}
                          className={classes.grid4}
                          justify="flex-end"
                        >
                          <Button
                            type="submit"
                            disabled={addingExperience}
                            style={{
                              marginLeft: "50%",
                              fontFamily: "Roboto",
                              borderRadius: 30,
                              height: "40px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              backgroundColor: "#007BFF",
                              boxShadow: "none",
                            }}
                            className={classes.postButton}
                            onClick={() => {
                              AddExperience();
                              clearFields();
                              handleClose1();
                            }}
                          >
                            {addingExperience ? (
                              <CircularProgress
                                size={25}
                                style={{ color: "#ffffff" }}
                              />
                            ) : (
                              "Add Experience"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Fade>
                </Modal>
              </div>

              <Button
                onClick={handleOpen1}
                variant="contained"
                style={{
                  borderRadius: "30px",
                  padding: "10px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  backgroundColor: "#007BFF",
                  boxShadow: "none",
                }}
                className={classes.expButton}
              >
                <img src={plus} height="17px" style={{ marginRight: "5px" }} />{" "}
                Add Experience
              </Button>

              {workexp.map((data, index) => {
                return (
                  <div key={index}>
                    <br />
                    <h5>Edit Experience {index + 1}</h5>
                    {/* <br /> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div style={styles.fieldDiv}>
                        <div style={styles.heading}>Designation</div>
                        <input
                          type="text"
                          className="form-control"
                          value={data.job_designation}
                          onChange={(e) => {
                            let ar = workexp;
                            ar[index].job_designation = e.target.value;
                            setWorkexp([...ar]);
                          }}
                          style={styles.inputBox}
                          required
                        />
                      </div>
                      <div style={styles.fieldDivnew}>
                        <div style={styles.heading}>Employment Type</div>
                        <div style={styles.radioStylenew}>
                          {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                          <SelectSearch
                            options={emptypes}
                            value={data.job_type}
                            onChange={setEmpType}
                            placeholder="Select"
                          />
                          <div>
                            <input
                              type="checkbox"
                              style={{
                                //marginLeft: "100px",
                                // marginTop: "-20px",
                                marginRight: "10px",
                              }}
                              onChange={(e) => {
                                let ar = workexp;
                                ar[index].isChecked = !data.isChecked;
                                setWorkexp([...ar]);
                              }}
                              checked={data.isChecked}
                            ></input>
                            I am currently working in this role
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <br /> */}
                    <div style={styles.fieldDivExp}>
                      <div style={styles.heading}>Company</div>
                      {data.isActive === false ? (
                        <div style={styles.radioStylesecond}>
                          {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                          <SelectSearch
                            options={comps}
                            value={data.company_name}
                            onChange={setCompType}
                            placeholder="Select"
                          />
                        </div>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          value={data.company_name}
                          onChange={(e) => {
                            let ar = workexp;
                            ar[index].company_name = e.target.value;
                            setWorkexp([...ar]);
                          }}
                          style={styles.inputBox}
                          required
                        />
                      )}
                    </div>
                    <input
                      type="checkbox"
                      style={{
                        marginLeft: "100px",
                        marginRight: "10px",
                        marginTop: "-10px",
                      }}
                      checked={data.isActive}
                      onChange={(e) => {
                        let ar = workexp;
                        ar[index].isActive = !data.isActive;
                        setWorkexp([...ar]);
                      }}
                    ></input>{" "}
                    Your company is not listed here. Write your own.
                    <br />
                    <br />
                    <div style={styles.firstBox}>
                      <div style={styles.fieldDiv}>
                        <div style={styles.heading}>Start Date</div>
                        <div style={styles.radioStyle}>
                          &nbsp; &nbsp;
                          <input
                            onChange={(e) => {
                              let ar = workexp;
                              ar[index].start_date = e.target.value;
                              setWorkexp([...ar]);
                            }}
                            type="date"
                            value={data.start_date}
                            className="form-control input-small"
                            style={{ borderRadius: "30px", height: "40px" }}
                          />
                        </div>
                      </div>
                      {data.isChecked === false ? (
                        <div style={styles.fieldDiv}>
                          <div style={styles.heading}>End Date</div>
                          <div style={styles.radioStyle}>
                            {/* &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; */}
                            <input
                              style={{ borderRadius: "30px", height: "40px" }}
                              onChange={(e) => {
                                let ar = workexp;
                                ar[index].end_date = e.target.value;
                                setWorkexp([...ar]);
                              }}
                              type="date"
                              value={data.end_date}
                              className="form-control input-small"
                              min={data.start_date}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div style={styles.buttonContainer}>
                      <Button
                        variant="contained"
                        disabled={
                          updatingExperience && selectedUpdateButton === index
                            ? true
                            : false
                        }
                        style={{
                          width: "210px",
                          height: "40px",
                          borderRadius: "30px",
                        }}
                        onClick={() => handleUploadDetails1(index, data.id)}
                      >
                        {updatingExperience &&
                        selectedUpdateButton === index ? (
                          <CircularProgress
                            size={25}
                            style={{ color: "#ffffff" }}
                          />
                        ) : (
                          "Update Work Experience" + " " + (index + 1)
                        )}
                      </Button>
                    </div>
                    <div
                      style={{
                        borderTop: "1px dotted #000",
                        marginRight: "20px",
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {open === "Referral" ? <UserReferral /> : null}
        </Grid>
      </Grid>
    </>
  );
};

export default UserSettings;

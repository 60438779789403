import {
  Box, Container,
  useTheme
} from "@mui/material";
import React from "react";
import LoginNavbar from "../Common/Navbar/LoginNavbar";

function PublicLayout({ children, step, setStep, signupComplete, getSkipButtonId }) {
  const theme = useTheme();
  return (
    <>
      <LoginNavbar step={step} setStep={setStep} signupComplete={signupComplete} getSkipButtonId={getSkipButtonId} />
      <Box
        sx={{
          backgroundColor: `${"#fff"}`,
          paddingTop: "40px",
          paddingBottom: "40px",
          [theme.breakpoints.down("sm")]: {
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
            backgroundColor: "#fff !important",
          }
        }}
      >
        <Container maxWidth="lg">{children}</Container>
      </Box>
    </>
  );
}

export default PublicLayout;

/* eslint-disable */
import { createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import search from "../../../assets/images/closedjob.png";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/httpRequest";
//import CompanyCard from "./CollegeCard";
import CompanyCard from "../cards/college/College";
import Layout from "./Layout"
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Pending from"../../../assets/common/pending.svg"
import Connected from "../../../assets/common/link.svg"
import Rejected from"../../../assets/common/rejected.svg"



export default function CollegeConenction() {
  const [jobData, setJobData] = useState([]);
  const location = useLocation();

  const step = useRef(1);
  const [apiCall, setApiCall] = useState("pending");

  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const [pending, setPending] = useState([]);
  const [connected, setConnected] = useState([]);
  const [rejected, setRejected] = useState([]);
  const token = localStorage.getItem("gw_token");
  const [jobDataPending, setJobDataPending] = useState([]);
  const [jobDataConnected, setJobDataConnected] = useState([]);
  const [jobDataRejected, setJobDataRejected] = useState([]);
  const [jobDataPendingCheck, setJobDataPendingCheck] = useState(false);
  const [jobDataConnectedCheck, setJobDataConnectedCheck] = useState(false);
  const [jobDataRejectedCheck, setJobDataRejectedCheck] = useState(false);
  const [cardFromCollege, setCardFromCollege] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  useEffect(() => {
    if (!jobDataPendingCheck && apiCall === "pending") {
      GetData(
        // baseUrl.pravesh.BASE_URL,
        BackendBaseApi.PRAVESH,
        "api/company/invite/?state=" + apiCall,
        { headers: token },
        setJobData
      );
    }
    if (!jobDataConnectedCheck && apiCall === "connected") {
      GetData(
        // baseUrl.pravesh.BASE_URL,
        BackendBaseApi.PRAVESH,
        "api/company/invite/?state=" + apiCall,
        { headers: token },
        setJobData
      );
    }

    if (!jobDataRejectedCheck && apiCall === "rejected") {
      GetData(
        // baseUrl.pravesh.BASE_URL,
        BackendBaseApi.PRAVESH,
        "api/company/invite/?state=" + apiCall,
        { headers: token },
        setJobData
      );
    }
    setEnd(false);
  }, [apiCall]);
  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setIsApiCalled(true);
    let res = await httpRequest(baseUrl, endPoint, body);

    if (res.data.next === null) {
      setEnd(true);
    } else {
      setNewURL(res.data.next.slice(0, 31));
      setNewEndPoint(res.data.next.slice(31));
    }
    // updateState(jobData.concat([...res.data.results]));
    if (apiCall === "pending") {
      setJobDataPending(jobData.concat([...res.data.results]));
      setJobDataPendingCheck(true);
    } else if (apiCall === "connected") {
      setJobDataConnected(jobData.concat([...res.data.results]));
      setJobDataConnectedCheck(true);
    } else {
      setJobDataRejected(jobData.concat([...res.data.results]));
      setJobDataRejectedCheck(true);
    }
    // updateState(jobData.concat([...res.data.results]));

    // updateState(jobData.concat([...res.data.results]));
    if (apiCall == "pending") {
      setPending();
    } else if (apiCall == "connected") {
      setConnected();
    } else if (apiCall == "rejected") {
      setRejected();
    }
    setIsApiCalled(false);
  };

  const handleScroll = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && !isApiCalled) {
        GetData(newURL, newEndPoint, { headers: token }, setJobData);
      }
    }
  };

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

      <Layout>

{/* 
        <div
          // className={classes.appbar}
          // style={{
        
          // }}
        >
          <ul
            className="nav nav-tabs "
            id="myTab"
            role="tablist"
            style={{ background: "#fff",  borderRadius:"30px"}}
          >
            
            <li
              className="nav-item mb-12 ml-5 mr-3 mx-xs-0"
              onClick={() => {
                step.current = 2;
                setApiCall("pending");
                setJobData([]);
              }}
            >
              <a
                className="nav-link active"
                id="home-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                style={{ color: "#000" ,borderRadius:"30px", marginTop:"8px",height: "42px" }}
              >
                Pending
              </a>
            </li>
            <li
              className="nav-item mt-3 mr-3"
              onClick={() => {
                step.current = 1;
                setApiCall("connected");
                setJobData([]);
              }}
            >
              <a
                className="nav-link"
                id="contact-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                style={{ color: "#000" }}
              >
                Connected
              </a>
            </li>
            <li
              className="nav-item mt-3 mr-3"
              onClick={() => {
                step.current = 3;
                setApiCall("rejected");
                setJobData([]);
              }}
            >
              <a
                className="nav-link"
                id="contact-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                style={{ color: "#000" }}
              >
                Rejected
              </a>
            </li>
          </ul>
        </div> */}
        <div id="myflex">
          <div className="row">
            <div
              id="myid"
              className="scrollY3"
              style={{ minWidth: "90vw",
            height:"60vw" }}
              onScroll={handleScroll}
            >
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                
      <BottomNavigation style={{borderRadius:"30px", width:"80%", marginLeft:"120px", justifyContent:"space-evenly"}}



  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
  showLabels
  className={classes.root}
>
  <BottomNavigationAction label={
    
    <>
    
    <img
    src={Pending}
    fontSize="inherit"
    alt=""
    height="28px"
    
  />
  <br/>
  {"   "}&nbsp; Pending
  </>

  
  }   onClick={() => {
    step.current = 2;
    setApiCall("pending");
    setJobData([]);
  }}   />
  <BottomNavigationAction label={
    
    <>
    
    <img
    src={Connected}
    fontSize="inherit"
    alt=""
    height="28px"
    
  />
  <br/>
  {"   "}&nbsp; Connected
  </>
  }    
  onClick={() => {
    step.current = 1;
    setApiCall("connected");
    setJobData([]);
  }}
          
              
              />
  <BottomNavigationAction label={
    
    <>
    
    <img
    src={Rejected}
    fontSize="inherit"
    alt=""
    height="28px"
    
   />
   <br/>
   {"   "}&nbsp;  Rejected
    </>

  
  } 
  
  onClick={() => {
    step.current = 3;
    setApiCall("rejected");
    setJobData([]);
  }}/>
</BottomNavigation>
                {jobDataPending.length > 0 && apiCall === "pending" ? (
                  <CompanyCard
                    data={jobDataPending}
                    apiCall={apiCall}
                    cardFromCollege={cardFromCollege}
                  />
                ) : (
                  apiCall === "pending" && (
                    <>
                      <br />
                      <br />
                      <img
                        src={search}
                        align="center"
                        style={{
                          margin: "auto",
                          height: "250px",
                          paddingLeft: "37%",
                        }}
                      />
                      <br />
                      <p align="center" style={{ color: "black" }}>
                        No Results Found
                      </p>
                      <p align="center">
                        <span style={{ fontSize: "15px", color: "grey" }}>
                          Invite Companies to create new connections
                        </span>
                      </p>
                    </>
                  )
                )}

                {jobDataConnected.length > 0 && apiCall === "connected" ? (
                  <CompanyCard
                    data={jobDataConnected}
                    apiCall={apiCall}
                    cardFromCollege={cardFromCollege}
                  />
                ) : (
                  apiCall === "connected" && (
                    <>
                      <br />
                      <br />
                      <img
                        src={search}
                        align="center"
                        style={{
                          margin: "auto",
                          height: "250px",
                          paddingLeft: "37%",
                        }}
                      />
                      <br />
                      <p align="center" style={{ color: "black" }}>
                        No Results Found
                      </p>
                      <p align="center">
                        <span style={{ fontSize: "15px", color: "grey" }}>
                          Invite Companies to create new connections
                        </span>
                      </p>
                    </>
                  )
                )}
                {jobDataRejected.length > 0 && apiCall === "rejected" ? (
                  <CompanyCard
                    data={jobDataRejected}
                    apiCall={apiCall}
                    cardFromCollege={cardFromCollege}
                  />
                ) : (
                  apiCall === "rejected" && (
                    <>
                      <br />
                      <br />
                      <img
                        src={search}
                        align="center"
                        style={{
                          margin: "auto",
                          height: "250px",
                          paddingLeft: "37%",
                        }}
                      />
                      <br />
                      <p align="center" style={{ color: "black" }}>
                        No Results Found
                      </p>
                      <p align="center">
                        <span style={{ fontSize: "15px", color: "grey" }}>
                          Invite Companies to create new connections
                        </span>
                      </p>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>


        
      </Layout>
    </div>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appbar: {
      width: `100%`,
      marginLeft: "16px",
      marginRight:"26px",
      marginTop:'10px',
      marginBottom:'20px',
      
    
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.up(1000)]: {
        width: "calc(75.6% - -3% )",
       borderRadius:"30px",
        marginRight:"42px",
       height:"64px",

      
 
  
 
  backdropFilter: "blur(30px)",
  boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
  border: "solid 1px #f1f1f1",
  backgroundColor: "#ffffff"
      },
    },
  })
);

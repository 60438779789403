import React, { useEffect, useState } from "react";
import todaydate from "../../../../utils/TodayDate";
import CssTextField from "./CssTextField";

const DateSelector = ({ date, setDate, dateType, responsive }) => (
  <CssTextField
    id="date"
    label={<span className={responsive}>{dateType}</span>}
    type="date"
    onChange={(e) => {
      setDate(e.target.value);
    }}
    value={date}
    variant="outlined"
    InputLabelProps={{
      shrink: true,
    }}
    onKeyDown={(e) => e.preventDefault()}
    InputProps={{
      inputProps: { min: String(todaydate()) },
      style: { fontSize: 13 },
    }}
    style={{ width: "100%" }}
  />
);

export default DateSelector;

import { Button, CircularProgress, Divider, FormControl, FormControlLabel, Grid, IconButton, Paper, RadioGroup, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import CommonChip from "../../../../CommonChip/CommonChip";
import Axios from "axios";
import { BackendBaseApi, websiteRegexNew } from "../../../../../constants/constants";
import { useAlert } from "react-alert";
import ProjectDetailsCards from "./ProjectDetailsCards";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import StyledRadio from "../../../../common/JobPostNew/FormComponents/CustomizedRadio";
import { executeScrollCommon } from "../../../../../utils/utility";
import CommonButton from "../../../../common/CommonButton/CommonButton";
import AchievementCards from "./AchievementsCards";
import CustomizedCheckboxes from "../../../../common/JobPostNew/FormComponents/CustomizedCheckboxes";
import CertificateCards from "./CertificateCards";

const useStyles = makeStyles((theme) => ({
  paper: {
    // minHeight: "600px",
    borderRadius: "20px",
    // textAlign: "center",
    padding: 12,
    width: "100%",
    marginBottom: 10,
  },
  formBox: {
    background: "#fff",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    borderRadius: 30,
    padding: 20,
    width: "100%",
    marginBottom: 65,
  },
  labelField: {
    fontSize: 13,
  },
  RedStar: {
    color: "red",
  },
  heading: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: "0rem",
    color: "#4f4f4f",
  },
  subTitle: {
    color: "grey",
    textAlign: "center",
    fontSize: 14,
    marginBottom: 25,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "6.5px 11px",
  },
  fieldStyling: {
    width: "100%",
    marginBottom: 10,

    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

function Projects(props) {
  const {
    projectDetails,
    setProjectDetails,
    projectDetailsErrors,
    setProjectDetailsErrors,
    projectList,
    setProjectList,
    buttonClicked,
    setButtonClicked,
    projectLoader,
    openAddProjects,
    setOpenAddProjects,
    openAddAchievements,
    setOpenAddAchievements,
    extraData,
    setExtraData,
    fetchPrecentage,
    openAddCertificate,
    setOpenAddCertificate,
  } = props;
  const alert = useAlert();
  const classes = useStyles();
  const [saveLoader, setSaveLoader] = useState(false);
  const [saveLoaderCerti, setSaveLoaderCerti] = useState(false);

  const projectFormRef = useRef(null);
  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [validate, setValidate] = useState(false);
  const [achievementValue, setAchievementValue] = useState(null);
  const [buttonClickedCerti, setButtonClickedCerti] = useState(false);
  const [validateCerti, setValidateCerti] = useState(false);
  const [certiList, setCertiList] = useState([]);
  const [editOpenCerti, setEditOpenCerti] = useState(false);
  const [editIdCerti, setEditIdCerti] = useState(null);
  const [editIndexCerti, setEditIndexCerti] = useState(null);

  const [certiData, setCertiData] = useState({
    user: localStorage.getItem("user_id"),
    user_type: localStorage.getItem("user_type_id"),
    name: null,
    institute: null,
    start_date: null,
    end_date: null,
    expiration: false,
    valid_date: null,
  });

  const [certiDataError, setCertiDataError] = useState({
    institute: null,
    start_date: null,
    end_date: null,
    expiration: null,
    valid_date: null,
  });

  const clearCerti = () => {
    setCertiData({
      ...certiData,
      name: "",
      institute: "",
      start_date: "",
      end_date: "",
      expiration: false,
      valid_date: null,
    });
    setOpenAddCertificate(false);
  };

  useEffect(() => {
    setCertiData({ ...certiData, valid_date: null });
  }, [certiData?.expiration]);

  const getCertifications = () => {
    Axios.get(`${BackendBaseApi.PRAVESH}/api/education/user_certifications/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          var temp = [];
          res.data.data.map((item) => {
            temp.push({
            ...item,
            end_date: item?.end_date ? moment(moment(item?.end_date, 'YYYY-mm-DD').toDate()).format('YYYY-mm') : null,
            start_date: item?.start_date ? moment(moment(item?.start_date, 'YYYY-mm-DD').toDate()).format('YYYY-mm') : null,
            valid_date: item?.valid_date ? moment(moment(item?.valid_date, 'YYYY-mm-DD').toDate()).format('YYYY-mm') : null
                })
          })
          setCertiList(temp);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getCertifications();
  }, []);

  const postCertifications = () => {
    var data = { ...certiData };
    data.start_date = moment(certiData.start_date).format("MM/YYYY");
    data.end_date = moment(certiData.end_date).format("MM/YYYY");
    if (certiData?.valid_date) data.valid_date = moment(certiData.valid_date).format("MM/YYYY");
    setSaveLoaderCerti(true);
    Axios.post(`${BackendBaseApi.PRAVESH}/api/education/user_certifications/`, data, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          var arr = [...certiList];
          arr.push(certiData);
          setCertiList(arr);
          clearCerti();
          alert.success("Course/Certificate Added Successfully");
          setButtonClickedCerti(false);
        } else {
          alert.error(res.data.error);
        }
        setSaveLoaderCerti(false);
      })
      .catch((err) => {
        setSaveLoaderCerti(false);
        alert.error(err.message);
      });
  };

  const validateCertiData = () => {
    var name_error = "";
    var institute_error = "";
    var start_date_error = "";
    var end_date_error = "";
    var expiration = "";
    setValidateCerti(false);
    if (!certiData?.name) {
      name_error = "Please Enter Course/Certificate Name";
      setValidateCerti(true);
    }
    if (!certiData?.institute) {
      institute_error = "Please Enter Institute";
      setValidateCerti(true);
    }
    if (!certiData?.start_date) {
      start_date_error = "Please Enter Start Date";
      setValidateCerti(true);
    }
    if (!certiData?.end_date) {
      end_date_error = "Please Enter End Date";
      setValidateCerti(true);
    }
    if (!certiData.expiration && !certiData?.valid_date) {
      expiration = "Please Enter Expiry Date";
      setValidateCerti(true);
    }

    setCertiDataError({
      ...certiDataError,
      name: name_error,
      institute: institute_error,
      start_date: start_date_error,
      end_date: end_date_error,
      valid_date: expiration,
    });

    setButtonClickedCerti(true);
  };

  useEffect(() => {
    if (!validateCerti && buttonClickedCerti) {
      if (editOpenCerti) handleEditCerti();
      else postCertifications();
    }
  }, [buttonClickedCerti]);

  const editBtnCerti = (id, index) => {
    setEditOpenCerti(true);
    setOpenAddCertificate(true);
    setEditIdCerti(id);
    setEditIndexCerti(index);
    // alert.error(id);
    let temp = certiList[index];
    setCertiData({
      ...certiData,
      name: temp?.name,
      institute: temp?.institute,
      start_date: temp?.start_date,
      end_date: temp?.end_date,
      expiration: temp?.expiration,
      valid_date: temp?.valid_date,
    });
  };
  const handleEditCerti = () => {
    setSaveLoaderCerti(true);
    var data = { ...certiData };
    data.start_date = moment(certiData.start_date).format("MM/YYYY");
    data.end_date = moment(certiData.end_date).format("MM/YYYY");
    if (certiData?.valid_date) data.valid_date = moment(certiData.valid_date).format("MM/YYYY");
    Axios.patch(
      `${BackendBaseApi.PRAVESH}/api/education/user_certifications/${editIdCerti}/`,

      data,
      { headers: { Authorization: "Token " + localStorage.getItem("gw_token") } }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setSaveLoaderCerti(false);
          setOpenAddCertificate(false);
          var arr = [...certiList];
          data.start_date = certiData.start_date;
          data.end_date = certiData.end_date;
          data.id = editIdCerti;
          if (certiData?.valid_date) data.valid_date = certiData.valid_date;

          arr[editIndexCerti] = data;
          setCertiList(arr);
          setEditOpenCerti(false);
          setEditIdCerti(null);
          setEditIndexCerti(null);
          setButtonClickedCerti(false);
          clearCerti();
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        setSaveLoaderCerti(false);

        alert.error("There was some error updating your project. Try again later");
      });
  };

  const validateData = () => {
    var title_error = "";
    var link_error = "";
    var description = "";
    setValidate(false);
    if (projectDetails.project_title === "") {
      title_error = "Please Enter Title";
      setValidate(true);
    }
    // if (projectDetails.project_link === "") {
    //   link_error = "Please Enter Link";
    //   setValidate(true);
    // }
    if (projectDetails.project_link !== "" && !websiteRegexNew.test(projectDetails.project_link)) {
      link_error = "Please Enter Link";
      setValidate(true);
    }
    if (projectDetails.description === "") {
      description = "Description can't be empty..";
      setValidate(true);
    }
    setProjectDetailsErrors({
      ...projectDetailsErrors,
      project_title: title_error,
      project_link: link_error,
      description: description,
    });

    setButtonClicked(true);
  };

  useEffect(() => {
    if (!validate && buttonClicked) {
      if (editOpen) handleEdit();
      else addProject();
    }
  }, [buttonClicked]);

  const addProject = (e) => {
    setSaveLoader(true);
    let arr = [...projectList];
    let temp = {
      title: projectDetails.project_title,
      links: projectDetails.project_link || null,
      description: projectDetails.description || null,
      start_date: null,
      end_date: null,
      skills: null,
      type: projectDetails.type,
    };
    Axios.post(
      BackendBaseApi.PRAVESH + `api/education/user_project`,
      {
        title: projectDetails.project_title,
        links: projectDetails.project_link || null,
        description: projectDetails.description || null,
        start_date: null,
        end_date: null,
        skills: null,
        type: projectDetails.type,
      },
      { headers: { Authorization: "Token " + localStorage.getItem("gw_token") } }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          temp["id"] = res.data.data.project_id;
          arr.push(temp);
          setProjectList(arr);
          setSaveLoader(false);

          setProjectDetails({
            ...projectDetails,
            project_title: "",
            project_link: "",
            description: "",
          });
          fetchPrecentage();
        } else alert.error(res.data.error);
        setSaveLoader(false);

        setButtonClicked(false);
      })
      .catch((err) => {
        setSaveLoader(false);

        alert.error("There was some error adding your project. Try again later");
      });
  };

  const editBtn = (id) => {
    setEditOpen(true);
    setOpenAddProjects(true);
    setEditId(id);
    // alert.error(id);
    let arr = projectList.filter((edu) => edu.id === id);
    let temp = arr[0];
    setProjectDetails({ ...projectDetails, project_title: temp.title || "", project_link: temp.links || "", description: temp.description || "", type: temp.type });
    executeScrollCommon(projectFormRef);
  };
  const handleEdit = () => {
    setSaveLoader(true);
    var arr = [...projectList];
    Axios.patch(
      BackendBaseApi.PRAVESH + `api/education/user_project/${editId}/`,
      {
        title: projectDetails.project_title,
        links: projectDetails.project_link || null,
        description: projectDetails.description || null,
        start_date: null,
        end_date: null,
        skills: null,
        type: projectDetails.type || "",
      },
      { headers: { Authorization: "Token " + localStorage.getItem("gw_token") } }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setSaveLoader(false);
          setOpenAddProjects(false);
          var myArray = [];
          myArray.push(res.data.data.data);

          var res = arr.map((obj) => myArray.find((o) => o.id === obj.id) || obj);
          setProjectList(res);
          setEditOpen(false);
          setEditId(null);
          fetchPrecentage();
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        setSaveLoader(false);

        alert.error("There was some error updating your project. Try again later");
      });
  };

  const handleDelete = (id) => {
    Axios.delete(BackendBaseApi.PRAVESH + `api/education/user_project/${id}/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          let temp = projectList.filter((proj) => {
            return proj.id !== id;
          });
          setProjectList(temp);
          fetchPrecentage();
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("There was some error deleting this project. Try again later");
      });
  };

  const handleDeleteCerti = (index) => {
    var array = [...certiList];
    if (index > -1) {
      array.splice(index, 1);
    }
    setCertiList(array);
  };

  const handleDeleteCertiId = (id) => {
    Axios.delete(BackendBaseApi.PRAVESH + `/api/education/user_certifications/${id}/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          var arr = [...certiList];
          var index2 = certiList.findIndex((item) => item?.id === id);
          arr.splice(index2, 1);

          setCertiList(arr);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("There was some error deleting this project. Try again later");
      });
  };

  const handleDeleteAchievement = (index) => {
    var array = [...extraData.achievements];
    if (index > -1) {
      array.splice(index, 1);
    }
    setExtraData({ ...extraData, achievements: array });

    updateUserAchievements(array);
  };

  const updateUserAchievements = (array) => {
    setSaveLoader(true);
    achievementValue && array.push(achievementValue);
    achievementValue && setAchievementValue("");
    Axios.patch(
      BackendBaseApi.PRAVESH + "api/education/student_user_extras/",
      {
        achievements: array,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          fetchPrecentage();
          alert.success(res.data.data.message);
          // alert.success("Saved Hobbies Successfully!");
        } else {
          alert.error("Unable to post Links for this Student!");
        }
        setSaveLoader(false);
        // setCircularLoading(false);
      })
      .catch((err) => {
        setSaveLoader(false);
        alert.error("There was some error adding your project. Try again later");
      });
  };
  return (
    <>
      <div>
        {projectLoader ? (
          <CircularProgress size={40} style={{ color: "#007bff", marginTop: 30, marginBottom: 30 }} />
        ) : (
          projectList?.length > 0 &&
          projectList.map((item, index) => (
            <>
              {" "}
              <ProjectDetailsCards data={item} index={index} editBtn={editBtn} handleDelete={handleDelete} />
            </>
          ))
        )}
      </div>
      <div ref={projectFormRef}>
        {!openAddProjects && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#007bff",
            }}
            onClick={() => {
              setOpenAddProjects(true);
              setProjectDetails({
                ...projectDetails,
                project_title: "",
                project_link: "",
                description: "",
              });
            }}
          >
            <IconButton
              style={{ color: "#007bff" }}
              onClick={() => {
                setOpenAddProjects(true);
              }}
            >
              <ControlPointIcon />
            </IconButton>
            <span>Add Project or Publications</span>
          </div>
        )}

        {openAddProjects && (
          <>
            <div>
              <Typography variant="body1" style={{ textAlign: "left", fontSize: 15, marginBottom: 18 }}>
                Add Projects or Publications
              </Typography>
              <FormControl component="fieldset" style={{ display: "flex", marginBottom: 13 }}>
                {/* <FormLabel component="legend" style={{ textAlign: "left", fontSize: 15, paddingTop: 20 }}>
                Proficiency :
              </FormLabel> */}
                <RadioGroup
                  aria-label="gender"
                  name="customized-radios"
                  row
                  value={projectDetails.type}
                  onChange={(e) => {
                    setProjectDetails({ ...projectDetails, type: e.target.value });

                    setButtonClicked(false);
                  }}
                >
                  <FormControlLabel value={"Project"} control={<StyledRadio />} label={<span style={{ fontSize: 14 }}>Project</span>} />
                  <FormControlLabel value={"Publication"} control={<StyledRadio />} label={<span style={{ fontSize: 14 }}>Publication</span>} />
                </RadioGroup>
              </FormControl>
              <TextField
                error={projectDetailsErrors.project_title !== "" ? true : false}
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                label={<span className={classes.labelField}>{projectDetails.type === "Project" ? "Project Title" : "Publication Title"}</span>}
                helperText={
                  <span style={projectDetailsErrors.project_title !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                    {projectDetailsErrors.project_title}
                  </span>
                }
                value={projectDetails.project_title}
                onChange={(e) => {
                  setProjectDetails({ ...projectDetails, project_title: e.target.value });
                  setProjectDetailsErrors({ ...projectDetailsErrors, project_title: "" });

                  // setErrorMessages({ ...errorMessages, phone: "" });
                  setButtonClicked(false);
                }}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={{
                  style: { backgroundColor: "#fff" },
                }}
                className={classes.fieldStyling}
              />
            </div>
            <div>
              <TextField
                error={projectDetailsErrors.project_link !== "" ? true : false}
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                label={<span className={classes.labelField}>{projectDetails.type === "Project" ? "Project Link" : "Publication Link"}</span>}
                helperText={
                  <span style={projectDetailsErrors.project_link !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                    {projectDetailsErrors.project_link}
                  </span>
                }
                value={projectDetails.project_link}
                onChange={(e) => {
                  setProjectDetails({ ...projectDetails, project_link: e.target.value });

                  // setErrorMessages({ ...errorMessages, phone: "" });
                  setButtonClicked(false);
                }}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={{
                  style: { backgroundColor: "#fff" },
                }}
                className={classes.fieldStyling}
              />
            </div>
            <div>
              <TextareaAutosize
                rowsMin={3}
                rowsMax={5}
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  borderColor: "#d2cdcd",
                  padding: 12,
                  outline: "none",
                  marginTop: 10,
                  fontSize: 13,
                }}
                aria-label="maximum height"
                placeholder="Description"
                onChange={(e) => {
                  setButtonClicked(false);
                  setProjectDetails({ ...projectDetails, description: e.target.value });
                  setProjectDetailsErrors({ ...projectDetailsErrors, description: "" });
                }}
                value={projectDetails.description}
              />
              <span style={projectDetailsErrors.description !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey" }}>{projectDetailsErrors.description}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "left" }}>
              <Button onClick={validateData} style={{ color: "#007bff" }}>
                {saveLoader ? <CircularProgress style={{ color: "#007bff" }} size={15} /> : projectDetails.type === "Project" ? "Save Project" : "Save Publication"}
              </Button>
              <Button
                onClick={() => {
                  setOpenAddProjects(false);
                  setProjectDetails({
                    ...projectDetails,
                    project_title: "",
                    project_link: "",
                    description: "",
                  });
                }}
                style={{ color: "#007bff" }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>

      <Divider />
      <Typography variant="body1" style={{ textAlign: "left", fontSize: 15, marginTop: 18, marginBottom: 10 }}>
        Certifications/Courses
      </Typography>
      {certiList?.length > 0 &&
        certiList?.map((item, index) => (
          <CertificateCards data={item} index={index} editBtn={editBtnCerti} handleDeleteId={handleDeleteCertiId} handleDelete={handleDeleteCerti} />
        ))}
      {!openAddCertificate && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: "#007bff",
          }}
          onClick={() => {
            setOpenAddCertificate(true);
          }}
        >
          <IconButton
            style={{ color: "#007bff" }}
            onClick={() => {
              setOpenAddCertificate(true);
            }}
          >
            <ControlPointIcon />
          </IconButton>
          <span> Add Certifications/Courses</span>
        </div>
      )}

      {openAddCertificate && (
        <>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <TextField
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                label={
                  <span className={classes.labelField}>
                    {" "}
                    Course Name&nbsp;<span className={classes.RedStar}>*</span>
                  </span>
                }
                value={certiData?.name}
                onChange={(e) => {
                  setCertiData({ ...certiData, name: e.target.value });
                  setCertiDataError({ ...certiDataError, name: "" });
                  setButtonClicked(false);
                }}
                error={certiDataError?.name ? true : false}
                helperText={<span style={certiDataError?.name ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{certiDataError?.name}</span>}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={
                  ({
                    style: { backgroundColor: "#fff" },
                  },
                  { shrink: true })
                }
                className={classes.fieldStyling}
              />
              <TextField
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                label={
                  <span className={classes.labelField}>
                    {" "}
                    Institute Name&nbsp;<span className={classes.RedStar}>*</span>
                  </span>
                }
                value={certiData?.institute}
                onChange={(e) => {
                  setCertiData({ ...certiData, institute: e.target.value });
                  setCertiDataError({ ...certiDataError, institute: "" });
                  setButtonClicked(false);
                }}
                error={certiDataError?.institute ? true : false}
                helperText={<span style={certiDataError?.institute ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{certiDataError?.institute}</span>}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={
                  ({
                    style: { backgroundColor: "#fff" },
                  },
                  { shrink: true })
                }
                className={classes.fieldStyling}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                // error={workExpDetailsErrors.start_date !== "" ? true : false}
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                type="month"
                label={
                  <span className={classes.labelField}>
                    Start Date&nbsp;<span className={classes.RedStar}>*</span>
                  </span>
                }
                value={certiData?.start_date}
                onChange={(e) => {
                  setCertiData({ ...certiData, start_date: e.target.value });
                  setCertiDataError({ ...certiDataError, start_date: "" });
                  setButtonClicked(false);
                }}
                error={certiDataError?.start_date ? true : false}
                helperText={<span style={certiDataError?.start_date ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{certiDataError?.start_date}</span>}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={{ shrink: true }}
                className={classes.fieldStyling}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                type="month"
                label={
                  <span className={classes.labelField}>
                    End Date&nbsp;<span className={classes.RedStar}>*</span>
                  </span>
                }
                value={certiData?.end_date}
                onChange={(e) => {
                  setCertiData({ ...certiData, end_date: e.target.value });
                  setCertiDataError({ ...certiDataError, end_date: "" });
                  setButtonClicked(false);
                }}
                error={certiDataError?.end_date ? true : false}
                helperText={<span style={certiDataError?.end_date ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{certiDataError?.end_date}</span>}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={{ shrink: true }}
                className={classes.fieldStyling}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                type="month"
                label={
                  <span className={classes.labelField}>
                    Valid Upto&nbsp;<span className={classes.RedStar}>*</span>
                  </span>
                }
                disabled={certiData?.expiration}
                value={certiData?.valid_date}
                onChange={(e) => {
                  setCertiData({ ...certiData, valid_date: e.target.value });
                  setCertiDataError({ ...certiDataError, valid_date: "" });
                  setButtonClicked(false);
                }}
                error={certiDataError?.valid_date ? true : false}
                helperText={<span style={certiDataError?.valid_date ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{certiDataError?.valid_date}</span>}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={{ shrink: true }}
                className={classes.fieldStyling}
              />
              <FormControlLabel
                className={classes.fieldStyling1}
                value={certiData?.expiration}
                style={{ marginLeft: 0 }}
                control={
                  <CustomizedCheckboxes
                    checked={certiData?.expiration}
                    onChange={(e) => {
                      setCertiData({ ...certiData, expiration: e.target.checked });
                    }}
                    name={"college_not_listed"}
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    color="primary"
                  />
                }
                label={<span style={{ fontSize: 13 }}>My certificate/course doesn't expires</span>}
              />
            </Grid>
            <div style={{ display: "flex", justifyContent: "left", marginBottom: 20 }}>
              <Button onClick={validateCertiData} style={{ color: "#007bff" }}>
                {saveLoaderCerti ? <CircularProgress style={{ color: "#007bff" }} size={15} /> : "Save Certifications"}
              </Button>
              <Button
                onClick={() => {
                  setOpenAddCertificate(false);
                  setCertiData({
                    ...certiData,
                    name: null,
                    institute: null,
                    start_date: null,
                    end_date: null,
                    expiration: false,
                    valid_date: null,
                  });
                }}
                style={{ color: "#007bff" }}
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </>
      )}

      <Divider />
      <Typography variant="body1" style={{ textAlign: "left", fontSize: 15, marginTop: 18, marginBottom: 10 }}>
        Your Achievements
      </Typography>
      {extraData?.achievements?.length > 0 && extraData.achievements.map((item, index) => <AchievementCards data={item} index={index} handleDelete={handleDeleteAchievement} />)}
      {!openAddAchievements && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: "#007bff",
          }}
          onClick={() => {
            setOpenAddAchievements(true);
          }}
        >
          <IconButton
            style={{ color: "#007bff" }}
            onClick={() => {
              setOpenAddAchievements(true);
            }}
          >
            <ControlPointIcon />
          </IconButton>
          <span> Add Other Achievements</span>
        </div>
      )}

      {openAddAchievements && (
        <>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={9}>
              <TextField
                // error={skillDetailsErrors.link !== "" ? true : false}
                key="Email"
                id="standard-error-helper-text"
                variant="outlined"
                label={<span className={classes.labelField}> About Achievements</span>}
                // helperText={
                //   <span style={skillDetailsErrors.link !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                //     {skillDetailsErrors.link !== "" ? skillDetailsErrors.link : "Link must begin with https://"}
                //   </span>
                // }
                value={achievementValue}
                onChange={(e) => {
                  setAchievementValue(e.target.value);
                  setExtraData({ ...extraData, singleAchievement: e.target.value });
                  setButtonClicked(false);
                }}
                InputProps={{
                  style: { fontSize: 13 },
                }}
                InputLabelProps={{
                  style: { backgroundColor: "#fff" },
                }}
                className={classes.fieldStyling}
              />
            </Grid>
            <Grid item xs={3}>
              <CommonButton
                variant="outlined"
                disabled={extraData.singleAchievement ? false : true}
                onClick={() => {
                  var arr = [...extraData.achievements];
                  arr.push(extraData.singleAchievement);
                  setExtraData({ ...extraData, achievements: arr });
                  setAchievementValue("");
                }}
              >
                Add
              </CommonButton>
            </Grid>
          </Grid>

          {extraData.achievements.length > 0 && (
            <div style={{ display: "flex", justifyContent: "left", marginTop: 10 }}>
              <Button onClick={() => {
                if(!extraData.achievements){
                  alert.error("Please add achievements");
                  return;
                }
                updateUserAchievements(extraData.achievements)
          }} style={{ color: "#007bff" }}>
                {saveLoader ? <CircularProgress style={{ color: "#007bff" }} size={15} /> : "Save Achievements"}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Projects;

import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  withStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "20px 40px 40px 40px",
      padding: "40px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
    },
    WhiteBox2: {
      backgroundColor: "#fff",
      margin: "0 40px 0 0",
      padding: "40px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    WhiteBox3: {
      backgroundColor: "#fff",
      margin: "40px 40px 40px 0",
      padding: "40px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    imageAndText: {
      float: "left",
      padding: "0 20px 15px 0",
    },
    SplitBox: {
      display: "flex",
      flexDirection: "column",
    },
    para: {
      color: "#4c4949",
      fontSize: "18px",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
    },
    borderBox: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      // border: "1px solid grey",
      padding: "10px",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        transform: "scale(1.02)",
        transition: "transform 0.2s",
      },
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
    },
    "@media only screen and (max-width: 768px)": {
      WhiteBox: {
        width: "100%",
        marginLeft: "-5px",
        marginRight: "0px",
      },
    },
  })
);

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#3e3d3d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
    "@media only screen and (max-width: 768px)": {
      body: {
        fontSize: 12,
      },
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export default function OverviewBox(props) {
  const { data } = props;
  const classes = useStyles();
  const [courseData, setCourseData] = useState({});
  useEffect(() => {
    if (data) {
      setCourseData(data);
    }
  }, [data]);
  return (
    <Box className={classes.WhiteBox}>
      <h2 className={classes.heading}>Eligibility Criteria</h2>
      <br />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ width: "30%" }}
              >
                <b>Courses</b>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row">
                <b>Details</b>
              </StyledTableCell>
            </StyledTableRow>
            {courseData?.courses?.compare_courses.map((item) => (
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={{ width: "30%" }}
                >
                  {item.course}
                </StyledTableCell>

                <StyledTableCell component="th" scope="row">
                  <b>Eligibility:</b>&nbsp;{" "}
                  {item.eligibility ? item.eligibility : "NA"}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

import { Box, Checkbox, createStyles, FormControlLabel, FormLabel, Grid, IconButton, makeStyles } from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../constants/constants";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import line from "../../assets/images/lineOr.png";
import { array } from "prop-types";
import Loader from "../../bundles/common/components/UI/Loader";
import CustomizedCheckboxes from "../../components/common/JobPostNew/FormComponents/CustomizedCheckboxes";
import { Accordion, AccordionDetails, AccordionSummary } from "../../components/common/JobPostNew/FormComponents/AccordianCustom";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) =>
  createStyles({
    grid1: {
      marginTop: "20px",

      fontWeight: "bolder",
      paddingLeft: "7%",
      paddingTop: "4%",
    },
    grid2: {
      marginTop: "20px",
    },
    grid3: {
      fontWeight: "bolder",
      paddingLeft: "20%",
      paddingTop: "4%",
    },
    grid4: {
      marginTop: "10px",
    },
    redstar: {
      color: "red",
      fontWeight: "bolder",
    },
    head1: {
      alignItems: "left",
      textAlign: "left",
      margin: "20px 10px 20px 20px",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "left",
      width: "60%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    shadowBox: {
      backgroundColor: theme.palette.background.default,
      padding: "5px 0 5px 20px",
      borderRadius: "4px",
      boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none",
    },
    innerBox: {
      padding: "5px 0 5px 50px",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
  })
);
const DegreeCourses = (props) => {
  const { data, setData, width, loading, setLoading, degreeCoursesComing, setDegreeCoursesComing, allData, setAllData, specializationNew, jobData } = props;

  const classes = useStyles();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [degree, setDegree] = useState([]);
  const [specialization, setSpecialization] = useState({});

  const [selectAll, setSelectAll] = useState(false);
  const [dataAll, setDataALl] = useState(allData);
  let { job_id } = useParams();

  useEffect(() => {
    let temp = {};

    dataAll.forEach((item) => {
      temp[item.degree_id] = [];
    });
    setSpecialization(temp);
  }, []);

  useEffect(() => {
    if (job_id && jobData && jobData !== undefined) {
      let temp = {};
      if (jobData?.college?.length > 0)
        jobData.college[0].preferences.forEach((item) => {
          temp[item.degree_id] = item.specialization;
        });
      setSpecialization(temp);
    }
  }, [jobData]);

  const handleSelectAllSpecializations = (e, item) => {
    var arr = specialization[item.degree_id];
    var loc = specialization;
    var data_arr = [...data];
    const item_spec = [...item.specialization];
    if (item.select_all === false) {
      loc[item.degree_id] = item_spec;
      var temp = {
        degree_id: item.degree_id,
        degree_name: item.degree_name,
        select_all: true,
        specialization: item_spec,
      };

      const found = data_arr.some((el) => el.degree_id === item.degree_id);
      if (found) {
        data_arr.forEach((inner_item) => {
          if (inner_item.degree_id === item.degree_id) inner_item.specialization = item_spec;
        });
      } else {
        data_arr.push(temp);
      }

      setData(data_arr);

      setSpecialization(loc);
    } else {
      var indexx;
      data_arr.map((inn, new_index) => {
        if (inn.degree_id === item.degree_id) indexx = new_index;
      });

      data_arr.splice(indexx, 1);
      setData(data_arr);
      loc[item.degree_id] = [];
      setSpecialization(loc);
    }
    item.select_all = e.target.checked;
    item.specialization.map((i, index) => {
      i.selected = e.target.checked;
    });

    var result = false;
    if (allData.length === data_arr.length) result = true;
    else if (allData.length !== data_arr.length) {
      result = false;
      setSelectAll(false);
    }
    if (result) setSelectAll(true);

    if (data_arr.length === 0) setSelectAll(false);
  };

  const handleSelectSingleSpecializations = (e, i, item) => {
    const arr = [...specialization[item.degree_id]];
    var loc = specialization;
    var data_arr = [...data];
    if (i.selected === false) {
      arr.push(i);
      loc[item.degree_id] = arr;
      var temp = {
        degree_id: item.degree_id,
        degree_name: item.degree_name,
        specialization: arr,
      };
      if (specialization[item.degree_id].length === 1) data_arr.push(temp);

      const found = data_arr.some((el) => el.degree_id === item.degree_id);

      if (found) {
        data_arr.forEach((inner_item) => {
          inner_item.specialization = arr;
        });
      } else {
        data_arr.push(temp);
      }

      setData(data_arr);

      i.selected = e.target.checked;
      setSpecialization(loc);
    } else {
      var indexx;
      arr.map((u, iin) => {
        if (u.specialization_id === i.specialization_id) {
          indexx = iin;
        }
      });

      arr.splice(indexx, 1);

      loc[item.degree_id] = arr;
      var temp = {
        degree_id: item.degree_id,
        degree_name: item.degree_name,
        specialization: arr,
      };
      var indexing;

      data_arr.forEach((inn, new_index) => {
        if (inn.degree_id === item.degree_id) {
          if (inn.specialization.length === 1) {
            indexing = new_index;
            data_arr.splice(indexing, 1);
          } else {
            inn.specialization = arr;
          }
        }
      });

      setData(data_arr);

      setSpecialization(loc);
      i.selected = e.target.checked;
    }

    var result = !e.target.checked;

    for (var i in item.specialization) {
      if (item.specialization[i].selected === e.target.checked) {
        result = e.target.checked;
        break;
      }
    }
    if (result) item.select_all = e.target.checked;
    else if (data_arr.length === 0 || item.specialization.length === 1) item.select_all = false;
  };

  const handleAll = (e) => {
    let arrayy = [...dataAll];
    var loc = specialization;
    var new_arr = [...allData];
    var spec = specializationNew;
    var temp = {};
    var listOfObjects = [];

    if (selectAll === false) {
      new_arr.forEach((item) => {
        var singleObj = {};
        singleObj["degree_id"] = item.degree_id;
        singleObj["degree_name"] = item.degree_name;
        singleObj["specialization"] = item.specialization;
        singleObj["select_all"] = item.select_all;
        listOfObjects.push(singleObj);
      });
      setData(listOfObjects);
      setSpecialization(spec);
    } else {
      let temp = {};
      arrayy.forEach((item) => {
        temp[item.degree_id] = [];
      });
      setData([]);
      setSpecialization(temp);
    }
    setSelectAll(e.target.checked);
    allData.map((item, index1) => {
      item.select_all = e.target.checked;
      item.specialization.map((i, index) => {
        i.selected = e.target.checked;
      });
    });
  };
  return (
    <>
      {loading === false ? (
        allData && (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormLabel className={classes.grid1} component="legend" style={{ fontSize: 13 }}>
                Courses
              </FormLabel>
            </Grid>
            <Grid item xs={9} className={classes.grid2}>
              <FormControlLabel
                control={
                  <CustomizedCheckboxes
                    onChange={(e) => {
                      handleAll(e);
                    }}
                    checked={selectAll}
                    name="gilad"
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label={<span style={{ fontSize: 12 }}>Select All Courses</span>}
              />

              <img src={line} width="90%" />
              <br />
              <br />

              {allData.map((item, index1) => {
                return (
                  <Accordion square>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <FormControlLabel
                        control={
                          <CustomizedCheckboxes
                            onChange={(e) => {
                              handleSelectAllSpecializations(e, item);
                            }}
                            checked={item.select_all}
                            name="gilad"
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={<span style={{ fontSize: 12 }}>{item.degree_name}</span>}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <Box className={classes.innerBox}>
                          {item.specialization.map((i, index) => (
                            <Fragment key={index}>
                              {i ? (
                                <FormControlLabel
                                  control={
                                    <CustomizedCheckboxes
                                      checked={i.selected}
                                      onChange={(e) => {
                                        handleSelectSingleSpecializations(e, i, item);
                                      }}
                                      name="gilad"
                                      classes={{
                                        root: classes.radio,
                                        checked: classes.checked,
                                      }}
                                    />
                                  }
                                  label={<span style={{ fontSize: 12 }}>{i.specialization_name}</span>}
                                />
                              ) : null}
                              <br />
                            </Fragment>
                          ))}
                        </Box>
                      </>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
        )
      ) : (
        <Box>
          <Loader />
        </Box>
      )}
    </>
  );
};

export default DegreeCourses;

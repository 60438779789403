import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
// import { CustomButton } from "../../InputComponent/Button/CustomButton";
import { Typography } from "@material-ui/core";
import { NEXT_APP_ENDPOINT } from "../../../constants/constants";
import { Link, useLocation } from "react-router-dom";
import CommonButton from "../../common/CommonButton/CommonButton";
import { useSelector } from "react-redux";

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "10px",
  },
})((props) => (
  <Menu
    style={{ top: "14px" }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function RegisterBtnDomain() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      {/* <Link to={{ pathname: `/signup/student`, search: location.search ? location.search : "" }}> */}
      <Link to={{ pathname: `/candidate/signup`, search: location.search ? location.search : "" }}>
        <CommonButton
          type="button"
          buttonStyle="btn--primary--solid"
          buttonSize="btn--medium"
          aria-controls="customized-menu"
          style={{ background: domain?.domain ? domain?.color_code : "#007bff" }}
        >
          <Typography variant="body2" color="inherit">
            Register
          </Typography>
        </CommonButton>
      </Link>
    </>
  );
}

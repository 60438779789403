import { Box, createStyles, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import job_segment from "../../../assets/images/company-7.jpg";

const useStyles = makeStyles((theme) =>
  createStyles({
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "0 40px 40px 40px",
      padding: "40px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
    },
    WhiteBox2: {
      backgroundColor: "#fff",
      margin: "0 40px 0 0",
      padding: "40px",
    },
    WhiteBox3: {
      backgroundColor: "#fff",
      margin: "40px 40px 40px 0",
      padding: "40px",
    },
    imageAndText: {
      float: "left",
      padding: "0 20px 15px 0",
    },
    SplitBox: {
      display: "flex",
      flexDirection: "column",
    },
    para: {
      color: "#4c4949",
      fontSize: "18px",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
    },
    borderBox: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      // border: "1px solid grey",
      padding: "10px",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        transform: "scale(1.02)",
        transition: "transform 0.2s",
      },
    },
  })
);

export default function OverviewBox() {
  const classes = useStyles();
  return (
    <Box className={classes.WhiteBox}>
      <h2 className={classes.heading}>Top Talents</h2>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box className={classes.borderBox}>
            <Grid item xs={2}>
              <img
                src={job_segment}
                alt=""
                height="80px"
                width="100px"
                style={{ borderRadius: "4PX" }}
              />
            </Grid>
            <Grid item xs={10}>
              <p className={classes.para}>
                <b>Agriculture and Something else</b>
              </p>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.borderBox}>
            <Grid item xs={2}>
              <img
                src={job_segment}
                alt=""
                height="80px"
                width="100px"
                style={{ borderRadius: "4PX" }}
              />
            </Grid>
            <Grid item xs={10}>
              <p className={classes.para}>
                <b>Agriculture and Something else</b>
              </p>
            </Grid>
          </Box>
        </Grid>{" "}
        <Grid item xs={6}>
          <Box className={classes.borderBox}>
            <Grid item xs={2}>
              <img
                src={job_segment}
                alt=""
                height="80px"
                width="100px"
                style={{ borderRadius: "4PX" }}
              />
            </Grid>
            <Grid item xs={10}>
              <p className={classes.para}>
                <b>Agriculture and Something else</b>
              </p>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.borderBox}>
            <Grid item xs={2}>
              <img
                src={job_segment}
                alt=""
                height="80px"
                width="100px"
                style={{ borderRadius: "4PX" }}
              />
            </Grid>
            <Grid item xs={10}>
              <p className={classes.para}>
                <b>Agriculture and Something else</b>
              </p>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.borderBox}>
            <Grid item xs={2}>
              <img
                src={job_segment}
                alt=""
                height="80px"
                width="100px"
                style={{ borderRadius: "4PX" }}
              />
            </Grid>
            <Grid item xs={10}>
              <p className={classes.para}>
                <b>Agriculture and Something else</b>
              </p>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.borderBox}>
            <Grid item xs={2}>
              <img
                src={job_segment}
                alt=""
                height="80px"
                width="100px"
                style={{ borderRadius: "4PX" }}
              />
            </Grid>
            <Grid item xs={10}>
              <p className={classes.para}>
                <b>Agriculture and Something else</b>
              </p>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

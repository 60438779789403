import React, { useState, useEffect } from "react";
import {  useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import SideImage from "../../../common/components/UI/SideImage";
import AuthHeader from "../../../common/components/UI/AuthHeader";
import axios from "axios";
import {
  BackendBaseApi,
} from "../../../../constants/constants";
import loader_gif from "../../../../assets/images/loader_data.gif";

const UserApproved = () => {
  const alert = useAlert();
  const location = useLocation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const approveUser = (uid, cid, token) => {
    setIsLoading(true);
    axios
      .post(BackendBaseApi.PRAVESH + "api/email/company_user", {
        uid: uid,
        cid: cid,
        token: token,
      })
      .then((res) => {
        if (res.data.success) {
            alert.success(res.data.data.message);
            setIsSuccess(true);
            setName(res.data.data.data.name);
            setEmail(res.data.data.data.email);
        }
        else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        localStorage.clear();
        alert.error("Invalid Session!! Please try again");
      });
      setIsLoading(false);
  };

  useEffect(() => {
    let temp = location.pathname.split("/");
    approveUser(temp[2], temp[3], temp[4]);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div className="col-md-8 col-lg-8 y-scroll full-page-view">
            <div className="row">
              <AuthHeader />
            </div>
            <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-6 mx-auto">
                    <div className="row justify-content-center mx-auto my-2">
                      <i class="fas fa-user-check fa-5x"></i>
                    </div>
                    <div className="row justify-content-center mx-auto my-2">
                      {isLoading ? <img
                          src={loader_gif}
                          alt=""
                          height="500px"
                          width="700px"
                          style={{ marginLeft: "300px" }}
                        /> : (isSuccess ? 
                      <p className="fs-18 fw-500">
                        You have successfully approved <b>{name} : {email}</b> to your team. We will also notify them. Go to your company setting's user section to manage your team.
                      </p> : 
                              <p className="fs-18 fw-500">
                                  There is some error occurred. Please contact our helpline e-mail, <b>{"support@getwork.org"}</b> or call us <br /> on <b>94275 21611</b>.
                          </p>
                      )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserApproved;

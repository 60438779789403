import { Box, styled, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import emp from "../Assets/Images/png/student-hero.png";
import RegisterGetworkDialog from "../Components/Dialog/RegisterGetworkDialog";
import SignupLayout from "../Components/Layout/SignupLayout";
import EnterEmail from "../Components/Section/LoginRightSection/EnterEmail";

function CandidateSignUp() {
  
  const [step, setStep] = useState(1);
  const theme = useTheme();

  const RootPaper = styled(Box)(({ theme }) => ({
    width: `${"80%"}`,
    // color: `${clicked ? "#6C757D" : theme.palette.primary.main}`,
    boxShadow: "0px 0px 10px 2px #0000001A",
    padding: "20px",
    height: "fit-content",
    borderRadius: "20px",
    backgroundColor: "#fff",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      padding: "5px",
      boxShadow: "none",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "5px",
      boxShadow: "none",
    },
  }));

  return (
    <>
      <SignupLayout step={1}>
        <Box sx={{ pt: 1 }}>
          <Grid container spacing={2}>
            
              <Grid
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  mb: "-20px",
                  [theme.breakpoints.only("sm")]: {
                    display: "none",
                  },
                  [theme.breakpoints.only("xs")]: {
                    display: "none",
                  },
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",

                    justifyContent: "flex-end",
                  }}
                >

                  <img src={emp} height="450px" alt="welcome to getwork" />
                </div>
              </Grid>
            <Grid
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <RootPaper>
              <Box
            sx={{
              height: "100%",
              [theme.breakpoints.only("sm")]: {
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
              [theme.breakpoints.only("xs")]: {
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            {" "}
            <EnterEmail />{" "}
          </Box>
              </RootPaper>
              <RegisterGetworkDialog />
            </Grid>
          </Grid>
        </Box>
      </SignupLayout>
    </>
  );
}

export default CandidateSignUp;

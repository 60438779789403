import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import icon_a from "../../../assets/common/1st.png";
import icon_b from "../../../assets/common/Review.png";
import icon_e from "../../../assets/common/reject.png";
import icon_f from "../../../assets/common/Hired.png";
import icon_g from "../../../assets/common/join.svg";
import icon_h from "../../../assets/common/offer.svg";
import view_job from "../../../assets/details_job.png";

import Share from "../../common/Share/Share";

import Action from "../Track_Navbar/Action";
import Track_Detail from "../cards/Track/Track_Detail";
import sandclock from "../../../assets/sandclock.gif";
import BootstrapTooltip from "../../../common/Tooltip/Tooltip";
import { Link, useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`scrollable-force-tabpanel-${index}`} aria-labelledby={`scrollable-force-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  tabLabel: {
    fontSize: 13,
    color: theme.palette.type === "dark" ? "#fff" : "black",
  },
  new: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  hide: {
    backgroundColor: "transparent",
    boxShadow: "none",
    maxHeight: "0%",
    transition: "0.5s",
    overflow: "hidden",
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
  customBadge: {
    backgroundColor: "#393939",
    color: "white",
    marginTop: "3px",
  },

  step_count: {
    backgroundColor: "red",
    height: "30px",
    width: " 30px",
    margin: "0 auto",
    borderRadius: "50%",
    color: "white",
    lineHeight: "30px",
    zIndex: "100",
    border: "7px solid white",
    fontSize: "0.8rem",
    fontWweight: "500",
  },
  ch: {
    textTransform: "none",
    color: "black",
    backgroundColor: "none",
    boxShadow: "none",
    fontFamily: "Roboto",
  },
}));

export default function Track_Navbar(props) {
  const {
    value,
    handleChange,
    trackData,
    loader,
    setLoader,
    tabData,
    setCurrentState,
    currentState,
    setCurrentStateString,
    currentStateString,
    feedbackList,
    currentFeedback,
    setCurrentFeedback,
    setFeedbackSentState,
    feedbackSentState,
    feedbackTyped,
    setFeedbackTyped,
    selectedApplicants,
    setSelectedApplicants,
    setMoveButtonClicked,
    selectAllApplicants,
    setSelectAllApplicants,
    setTrackData,
    setFeedbackList,
    interviewData,
    setInterviewData,
    currentStateObject,
    setCurrentStateObject,
    setSubUserList,
    subUserList,
    errorIcon,
    jobTitle,
    excelData,
    totalCount,
    filterArray,
    setTabData,
    errorMessage,
    downloadButtonClicked,
    setDownloadButtonClicked,
    loaderDownoad,
    rejectButtonClicked,
    setRejectButtonClicked,
    feedbackListNew,
    showTabPanel,
    jobProfileObject,
    hideScrollBar,
    setHideScrollBar,
    usersList,
    setUserList,
    getTrackDownloadData,
    csvdownload,
    filterObject,
    publicTrack,
    actionPerformed,
    jobProfileName,
    jobProfileObjectShare,
    showDownload,
    campusType,
  } = props;
  const classes = useStyles();
  const [moveNextArray, setMoveNextArray] = useState([]);
  const [subUserAssignment, setSubUserAssignment] = useState();

  useEffect(() => {
    var obj = { ...tabData };
    Object.keys(obj).forEach((item) => {
      if (obj[item].tooltip === currentStateObject.tooltip) {
        obj[item].count = totalCount;
      }
    });

    setTabData(obj);
  }, [filterArray.length, totalCount]);

  const [show, setShow] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("user_type") === "College") {
      if (jobProfileObject.is_posted_by_college === true && currentStateObject.status_id !== 14 && currentStateObject.status_id !== 18) {
        if (publicTrack && actionPerformed === true) setShow(true);
        else if (!publicTrack) setShow(true);
        else setShow(false);
      } else {
        if (currentStateObject.status_id !== 1 && currentStateObject.status_id !== 19) setShow(false);
        else {
          if (publicTrack && actionPerformed === true) setShow(true);
          else if (!publicTrack) setShow(true);
          else setShow(false);
        }
      }
    } else if (localStorage.getItem("user_type") === "Company") {
      if (currentStateObject.status_id !== 14 && currentStateObject.status_id !== 18) {
        if (publicTrack && actionPerformed === true) setShow(true);
        else if (!publicTrack) setShow(true);
        else setShow(false);
      } else setShow(false);
    } else {
      if (currentStateObject.status_id !== 14 && currentStateObject.status_id !== 18) {
        if (actionPerformed === true) setShow(true);
        else setShow(false);
      } else setShow(false);
    }
  }, [currentStateObject, jobProfileObject, actionPerformed]);

  const toggleIcon = (id, name) => {
    switch (id) {
      case 1:
        return icon_a;
      case 13:
        return icon_f;
      case 14:
        return icon_e;
      case 19:
        return icon_b;
      case 18:
        return icon_g;
      case 17:
        return icon_h;
      case "end_date":
        return "Batch";
      case "work_experience":
        return "Work experience";
      default:
        return icon_a;
    }
  };

  const handleScroll = (event) => {
    let e = event.nativeEvent;

    if (e.target.scrollTop >= 40) {
      setHideScrollBar(true);
    } else setHideScrollBar(false);
  };

  let { token } = useParams();

  return (
    <>
      <div
        style={{
          position: "fixed",
          width: "60%",
          fontFamily: "Roboto",
          marginLeft: "-35px",
        }}
      >
        <div>
          {showTabPanel && (
            <AppBar
              position="static"
              color="red"
              className={classes.new}
              style={
                hideScrollBar
                  ? {
                      height: "0px",
                      transition: "all 0.3s ease-in-out 0s",
                      overflow: "hidden",
                    }
                  : {
                      height: "70px",
                      transition: "all 0.3s ease-in-out 0s",
                      overflow: "visible",
                    }
              }
            >
              <Tabs
                style={{ boxShadow: "none", backgroundColor: "transparent" }}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >
                {Object.keys(tabData).map((item, index) => {
                  return (
                    <BootstrapTooltip title={tabData[item].tooltip} className="">
                      <Tab
                        key={index}
                        id={"tab" + index}
                        label={<span className={classes.tabLabel}>{tabData[item].heading}</span>}
                        className={classes.ch}
                        style={{ boxShadow: "none", background: "none" }}
                        onClick={() => {
                          if (currentStateString !== tabData[item].param) {
                            setTrackData([]);
                            setCurrentState(tabData[item].status_id);
                            setCurrentStateString(tabData[item].param);
                            setCurrentStateObject(tabData[item]);
                          }
                        }}
                        icon={
                          <Badge badgeContent={tabData[item].count} max={999} classes={{ badge: classes.customBadge }}>
                            <img src={toggleIcon(tabData[item].status_id, tabData[item].tooltip)} fontSize="inherit" alt="" height="30px" />{" "}
                          </Badge>
                        }
                        {...a11yProps(index)}
                      />
                    </BootstrapTooltip>
                  );
                })}
              </Tabs>
            </AppBar>
          )}
        </div>
        {trackData?.length > 0 && (showDownload || show) ? (
          <Action
            selectAllApplicants={selectAllApplicants}
            setSelectAllApplicants={setSelectAllApplicants}
            trackData={trackData}
            setTrackData={setTrackData}
            selectedApplicants={selectedApplicants}
            moveNextArray={moveNextArray}
            setMoveNextArray={setMoveNextArray}
            feedbackList={feedbackList}
            currentFeedback={currentFeedback}
            setCurrentFeedback={setCurrentFeedback}
            setFeedbackSentState={setFeedbackSentState}
            feedbackSentState={feedbackSentState}
            feedbackTyped={feedbackTyped}
            setFeedbackTyped={setFeedbackTyped}
            setMoveButtonClicked={setMoveButtonClicked}
            setSelectedApplicants={setSelectedApplicants}
            setFeedbackList={setFeedbackList}
            interviewData={interviewData}
            setInterviewData={setInterviewData}
            tabData={tabData}
            currentStateObject={currentStateObject}
            setCurrentStateObject={setCurrentStateObject}
            subUserList={subUserList}
            setSubUserList={setSubUserList}
            jobTitle={jobTitle}
            excelData={excelData}
            subUserAssignment={subUserAssignment}
            setSubUserAssignment={setSubUserAssignment}
            downloadButtonClicked={downloadButtonClicked}
            setDownloadButtonClicked={setDownloadButtonClicked}
            loaderDownoad={loaderDownoad}
            setRejectButtonClicked={setRejectButtonClicked}
            rejectButtonClicked={rejectButtonClicked}
            feedbackListNew={feedbackListNew}
            jobProfileObject={jobProfileObject}
            show={show}
            hideScrollBar={hideScrollBar}
            setHideScrollBar={setHideScrollBar}
            usersList={usersList}
            setUserList={setUserList}
            getTrackDownloadData={getTrackDownloadData}
            csvdownload={csvdownload}
            filterObject={filterObject}
            jobProfileName={jobProfileName}
            jobProfileObjectShare={jobProfileObjectShare}
            showDownload={showDownload}
            campusType={campusType}
          />
        ) : (
          !token &&
          !loader && (
            <div style={{ display: "flex", justifyContent: "center", fliexDirection: "row" }}>
              {" "}
              <Share jobProfileObject={jobProfileObjectShare} filterObject={filterObject} jobProfileName={jobProfileName} fromWithoutAction={true} />
              <Link to={`/public/job/${!campusType ? "offcampus" : "oncampus"}/${jobProfileObjectShare?.job_id}`} target="_blank">
                <IconButton aria-label="delete">
                  <img src={view_job} height="30px" width="30px" />
                  &nbsp;
                  <span style={{ fontSize: 14 }}>View Job</span>
                </IconButton>
              </Link>
            </div>
          )
        )}
      </div>
      {loader ? (
        <Box style={{ display: "flex", justifyContent: "center", marginTop: 240 }}>
          <img src={sandclock} alt="" height="120px" />
        </Box>
      ) : trackData?.length > 0 ? (
        <div
          style={
            !publicTrack
              ? hideScrollBar
                ? {
                    marginTop: "77px",
                    transition: "all 0.3s ease-in-out 0s",
                    overflowY: "scroll",
                    // height: "64vh",
                    height: "59vh",

                    scrollBehavior: "smooth",
                  }
                : {
                    marginTop: "135px",
                    transition: "all 0.3s ease-in-out 0s",
                    overflowY: "scroll",
                    height: "56vh",
                    scrollBehavior: "smooth",
                  }
              : hideScrollBar
              ? showDownload || show
                ? {
                    marginTop: "77px",
                    transition: "all 0.3s ease-in-out 0s",
                    overflowY: "scroll",
                    // height: "66vh",
                    height: "59vh",

                    scrollBehavior: "smooth",
                  }
                : {
                    marginTop: "6px",
                    transition: "all 0.3s ease-in-out 0s",
                    overflowY: "scroll",
                    // height: "70vh",
                    height: "65vh",

                    scrollBehavior: "smooth",
                  }
              : showDownload || show
              ? {
                  marginTop: "135px",
                  transition: "all 0.3s ease-in-out 0s",
                  overflowY: "scroll",
                  // height: "55vh",
                  height: "50vh",

                  scrollBehavior: "smooth",
                }
              : {
                  marginTop: "80px",
                  transition: "all 0.3s ease-in-out 0s",
                  overflowY: "scroll",
                  // height: "65vh",
                  height: "62vh",
                  scrollBehavior: "smooth",
                }
          }
          onScroll={handleScroll}
        >
          {Object.keys(tabData).map((item, index) => (
            <TabPanel value={value} index={index}>
              <Track_Detail
                trackData={trackData}
                tabData={tabData}
                currentState={currentState}
                currentStateString={currentStateString}
                feedbackList={feedbackList}
                currentFeedback={currentFeedback}
                setCurrentFeedback={setCurrentFeedback}
                setFeedbackSentState={setFeedbackSentState}
                feedbackSentState={feedbackSentState}
                feedbackTyped={feedbackTyped}
                setFeedbackTyped={setFeedbackTyped}
                selectedApplicants={selectedApplicants}
                setSelectedApplicants={setSelectedApplicants}
                setMoveButtonClicked={setMoveButtonClicked}
                selectAllApplicants={selectAllApplicants}
                setSelectAllApplicants={setSelectAllApplicants}
                setTrackData={setTrackData}
                setFeedbackList={setFeedbackList}
                moveNextArray={moveNextArray}
                setMoveNextArray={setMoveNextArray}
                interviewData={interviewData}
                setInterviewData={setInterviewData}
                setSubUserList={setSubUserList}
                currentStateObject={currentStateObject}
                subUserAssignment={subUserAssignment}
                setSubUserAssignment={setSubUserAssignment}
                setRejectButtonClicked={setRejectButtonClicked}
                rejectButtonClicked={rejectButtonClicked}
                feedbackListNew={feedbackListNew}
                jobProfileObject={jobProfileObject}
                show={show}
              />
            </TabPanel>
          ))}
        </div>
      ) : (
        <>
          <Box display="flex" justifyContent="center" style={{ marginTop: 150 }}>
            <img src={errorIcon} alt="" height="250px" />
          </Box>
          <div>
            <p align="center" style={{ fontSize: 14, color: "#6a6a6a" }}>
              {errorMessage}
            </p>
          </div>
        </>
      )}
    </>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { BackendBaseApi } from "../../../../constants/constants";

const Ratings = ({ type, newRating, setNewRating }) => {
  const [ratingList, setRatingList] = useState({});
  const [rating, setRating] = useState();

  const ratings = Object.keys(ratingList).map((rating) => {
    return { name: rating, value: rating };
  });
  const TypeAndSearchRating = () => (
    <SelectSearch
      options={ratings}
      value={rating}
      onChange={setRating}
      placeholder="Select rating"
    />
  );

  const getAllRatings = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/education/skill_rating")
      .then((res) => {
        let temp = {};
        res.data.data.skill_rating.forEach((rating) => {
          //if(rating.rating_type==type)
          temp[rating.rating_name] = rating.rating_value;
        });
        setRatingList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllRatings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNewRating({
      rating_name: rating,
      rating_value: ratingList[rating],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  return (
    <>
      <TypeAndSearchRating />
    </>
  );
};

export default Ratings;

import { Avatar, Box, Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import BlogCardSkeleton from "../../../assets/skeletons/BlogCardSkeleton";
import "./blog.css";
import oj from "../../../assets/images/openjob.png";
import configs from "../../../config";

//const color = ["yellow", "white", "#68F7F7", "#F2F2F2"];

export default function BlogCard({ data, isBlogComing, setIsBlogComing }) {
  const location = useLocation();

  const useStyles = makeStyles({
    overlay: {
      display: "inlineBlock",
      backgroundSize: "100 100%",
      boxShadow: "inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)",
      borderColor: "rgba(0, 0, 0, 1)",
      color: "white",
    },
    blogCard: {
      transition: "all 0.3s ease",
      display: "flex",
      alignItems: "center",
      borderRadius: "16px",
      padding: "10%",
      "&:hover": {
        transform: "scale(1.1)",
        marginBottom: "24px",
        boxShadow: "0 30px 60px 0 rgba(0, 0, 0, 0.3)",
        marginLeft: "8px",
      },
    },
    blogTitle: {
      fontSize: "18px",
      fontWeight: 400,
      textShadow: "0.5px 0.5px #424242",
      marginRight: "1px",
      marginBottom: "27px",
    },
    "@media only screen and (max-width: 768px)": {
      blogTitle: {
        fontSize: "18px",
        fontWeight: 400,
        textShadow: "0.5px 0.5px #424242",
        marginRight: "1px",
        marginBottom: "27px",
      },
    },
  });
  const classes = useStyles();

  var imageLink = configs.apis.cms.slice(0, configs.apis.cms.length - 1);

  return (
    <div style={{ width: "100%", marginTop: "0px" }}>
      <Hidden smDown>
        {data.length > 0 ? (
          <Grid container>
            <Grid xs={12} style={{ marginTop: "10px" }}>
              <Grid container>
                <Grid item xs={6} sm={3}>
                  {data.slice(0, data.length / 4 + 1).map((item, index) => (
                    <Link
                      to={{
                        pathname: `/student/singleblog/${item?.id}`,
                        data: item,
                      }}
                    >
                      <div
                        className={classes.blogCard}
                        style={{
                          width: "90%",
                          background:
                            item?.Banner?.formats?.small?.url === null
                              ? randomColor()
                              : `url(${imageLink+item?.Banner?.formats?.small?.url}), #c4c4c4`,
                          backgroundSize: "cover",
                          color: item?.Banner?.formats?.small?.url === null ? "#000000" : "#ffffff",
                          height: index % 2 !== 0 ? "408px" : "225px",
                        }}
                      >
                      </div>
                      <div style={{padding: "3px"}}>
                        <div
                          className="flex align-item-center fs-12 mg-top-10 "
                          style={{
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          <span style={{ color: "gray" }}>By </span>
                          {item?.author.FirstName}{" "}{item?.author?.LastName}
                        </div>
                        <p className={classes.blogTitle}>{item?.Title}</p>
                      </div>
                    </Link>
                  ))}
                </Grid>
                <Grid item xs={6} sm={3}>
                  {data
                    .slice(data.length / 4 + 1, (data.length / 4) * 2 + 1)
                    .map((item, index) => (
                      <Link
                        to={{
                          pathname: `/student/singleblog/${item?.id}`,
                          data: item,
                        }}
                      >
                        <div
                          className={classes.blogCard}
                          style={{
                            width: "90%",
                            display: "flex",
                            alignItems: "center",
                            background:
                              item?.Banner?.formats?.small?.url === null
                                ? randomColor()
                                : `url(${imageLink+item?.Banner?.formats?.small?.url}), #c4c4c4`,
                            backgroundSize: "cover",
                            color: item?.Banner?.formats?.small?.url === null ? "#000000" : "#ffffff",
                            height: index % 2 === 0 ? "340px" : "225px",
                          }}
                        >
                        </div>
                        <div style={{padding: "3px"}}>
                          <div
                            className="flex align-item-center fs-12 mg-top-10 "
                            style={{
                              marginBottom: "2px",
                              fontSize: "14px",
                            }}
                          >
                            <span style={{ color: "gray" }}>By </span>
                            {item?.author?.FirstName}{" "}{item?.author?.LastName}
                          </div>
                          <p className={classes.blogTitle}>{item?.Title}</p>
                        </div>
                      </Link>
                    ))}
                </Grid>
                <Grid item xs={6} sm={3}>
                  {data
                    .slice((data.length / 4) * 2 + 1, (data.length / 4) * 3 + 1)
                    .map((item, index) => (
                      <Link
                        to={{
                          pathname: `/student/singleblog/${item?.id}`,
                          data: item,
                        }}
                      >
                        <div
                          className={classes.blogCard}
                          style={{
                            width: "90%",
                            background:
                              item?.Banner?.formats?.small?.url === null
                                ? randomColor()
                                : `url(${imageLink+item?.Banner?.formats?.small?.url}), #c4c4c4`,
                            backgroundSize: "cover",
                            color: item?.Banner?.formats?.small?.url === null ? "#000000" : "#ffffff",
                            height: index % 2 !== 0 ? "290px" : "225px",
                          }}
                        >
                        </div>
                        <div style={{padding: "3px"}}>
                          <div
                            className="flex align-item-center fs-12 mg-top-10 "
                            style={{
                              marginBottom: "2px",
                              fontSize: "14px",
                            }}
                          >
                            <span style={{ color: "gray" }}>By </span>
                            {item?.author?.FirstName}{" "}{item?.author?.LastName}
                          </div>
                          <p className={classes.blogTitle}>{item?.Title}</p>
                        </div>
                      </Link>
                    ))}
                </Grid>
                <Grid item xs={6} sm={3}>
                  {data
                    .slice((data.length / 4) * 3 + 1, data.length + 1)
                    .map((item, index) => (
                      <Link
                        to={{
                          pathname: `/student/singleblog/${item?.id}`,
                          data: item,
                        }}
                      >
                        <div
                          className={classes.blogCard}
                          style={{
                            width: "90%",
                            background:
                            item?.Banner?.formats?.small?.url === null
                                ? randomColor()
                                : `url(${imageLink+item?.Banner?.formats?.small?.url}), #c4c4c4`,
                            backgroundSize: "cover",
                            color: item?.Banner?.formats?.small?.url === null ? "#000000" : "#ffffff",
                            height: index % 2 === 0 ? "340px" : "225px",
                          }}
                        >
                        </div>
                        <div style={{padding: "3px"}}>
                          <div
                            className="flex align-item-center fs-12 mg-top-10 "
                            style={{
                              marginBottom: "2px",
                              fontSize: "14px",
                            }}
                          >
                            <span style={{ color: "gray" }}>By </span>
                            {item?.author?.FirstName}{" "}{item?.author?.LastName}
                          </div>
                          <p className={classes.blogTitle}>{item?.Title}</p>
                        </div>
                      </Link>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : isBlogComing === false ? (
          <Box>
            <Grid container>
              <Grid xs={12} style={{ marginTop: "54px" }}>
                <Grid container>
                  <Grid item xs={6} sm={3}>
                    <BlogCardSkeleton />
                    <BlogCardSkeleton />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BlogCardSkeleton />
                    <BlogCardSkeleton />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BlogCardSkeleton />
                    <BlogCardSkeleton />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BlogCardSkeleton />
                    <BlogCardSkeleton />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            {" "}
            <img
              src={oj}
              align="center"
              style={{
                marginLeft: "35%",
                height: "250px",
                // paddingLeft: "30%",
              }}
              alt=""
            />
            <p align="center" style={{ color: "black" }}>
              No Blogs for now.
            </p>
          </>
        )}
      </Hidden>
      <Hidden smUp>
        {data.length > 0 ? (
          <Grid container>
            <Grid xs={12} style={{ marginTop: "54px" }}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  {data.slice(0, data.length / 2 + 1).map((item, index) => (
                    <Link
                      to={{
                        pathname: `/student/singleblog/${item?.id}`,
                        data: item,
                      }}
                    >
                      <div
                        className={classes.blogCard}
                        style={{
                          width: "100%",
                          background:
                            item?.Banner?.formats?.small?.url === null
                              ? randomColor()
                              : `url(${imageLink+item?.Banner?.formats?.small?.url})`,
                          backgroundSize: "cover",
                          color: item?.Banner?.formats?.small?.url === null ? "#000000" : "#ffffff",
                          height: index % 2 !== 0 ? "408px" : "225px",
                        }}
                      >
                      </div>
                      <div style={{padding: "3px"}}>
                        <div
                          className="flex align-item-center fs-12 mg-top-10 "
                          style={{
                            marginBottom: "2px",
                            fontSize: "14px",
                          }}
                        >
                          <span style={{ color: "gray" }}>By </span>
                          {item?.author?.FirstName}{" "}{item?.author?.LastName}
                        </div>
                        <p className={classes.blogTitle}>{item?.Title}</p>
                      </div>
                    </Link>
                  ))}
                </Grid>
                <Grid item xs={6} sm={3} alignItems="center">
                  {data
                    .slice(data.length / 2 + 1, (data.length / 2) * 2 + 2)
                    .map((item, index) => (
                      <Link
                        to={{
                          pathname: `/student/singleblog/${item?.id}`,
                          data: item,
                        }}
                      >
                        <div
                          className={classes.blogCard}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            background:
                            item?.Banner?.formats?.small?.url === null
                                ? randomColor()
                                : `url(${imageLink+item?.Banner?.formats?.small?.url})`,
                            backgroundSize: "cover",
                            color: item?.Banner?.formats?.small?.url === null ? "#000000" : "#ffffff",
                            height: index % 2 === 0 ? "340px" : "225px",
                          }}
                        >
                        </div>
                        <div style={{padding: "3px"}}>
                          <div
                            className="flex align-item-center fs-12 mg-top-10 "
                            style={{
                              marginBottom: "2px",
                              fontSize: "14px",
                            }}
                          >
                            <span style={{ color: "gray" }}>By </span>
                            {item?.author?.FirstName}{" "}{item?.author?.LastName}
                          </div>
                          <p className={classes.blogTitle}>{item?.Title}</p>
                        </div>
                      </Link>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : isBlogComing === false ? (
          <Box>
            <Grid container>
              <Grid xs={12} style={{ marginTop: "54px" }}>
                <Grid container>
                  <Grid item xs={6} sm={3}>
                    <BlogCardSkeleton />
                    <BlogCardSkeleton />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BlogCardSkeleton />
                    <BlogCardSkeleton />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            {" "}
            <img
              src={oj}
              align="center"
              style={{
                marginLeft: "35%",
                height: "250px",

                // paddingLeft: "30%",
              }}
              alt=""
            />
            <p align="center" style={{ color: "black" }}>
              No Blogs for now.
            </p>
          </>
        )}
      </Hidden>
    </div>
  );
}

const randomColor = () => {
  // const random = Math.floor(Math.random() * color.length);
  // return color[random];
  // var letters = "0123456789ABCDEF";
  // var color = "#";
  // for (var i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  // }
  // return color;
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import SelectSearch from "react-select-search";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  EndPointPrefix,
  BackendBaseApi,
} from "../../../../constants/constants";
import Loader from "../../../common/components/UI/Loader";

const JoinCompany = ({
  companyUserDetails,
  setCompanyUserDetails,
  step,
  setStep,
}) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [isJoined, setIsJoined] = useState(false);

  const [tempCompList, setTempCompList] = useState([]);
  const [companyID, setCompanyID] = useState(null);
  const [domainCompany, setDomainCompany] = useState("");
  const [domainCompanyID, setDomainCompanyID] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [jobRolesList, setJobRolesList] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState("");

  const TypeAndSearchCompany = () => (
    <SelectSearch
      options={tempCompList}
      search
      value={company}
      onChange={setCompany}
      placeholder="Type and Search your company from the list"
    />
  );

  let jobRoles = Object.keys(jobRolesList).map((jobrole) => {
    return { name: jobrole, value: jobrole };
  });

  const JobRolesDropdown = () => (
    <SelectSearch
      options={jobRoles}
      value={jobRole}
      onChange={setJobRole}
      placeholder="Select your Job Role"
    />
  );

  useEffect(() => {
    setLoading(true);
    // ?domain='+companyUserDetails.
    getJobRoles();
    axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/company/company_list/?domain=" +
          companyUserDetails.Email
      )
      .then((res) => {
        setLoading(false);
        let temp = [];

        if (res.data.data.domain_company.length > 0) {
          //set domain company and return
          let domainCompanies = res.data.data.domain_company;
          domainCompanies.forEach((company) => {
            setDomainCompany(company.company);
            setCompany(company.company);
            setDomainCompanyID(company.id);
          });
          // return;
        }
        let allCompanies = res.data.data.all_companies;
        allCompanies.forEach((company) => {
          let tempObj = {
            [company.company]: company.id,
          };
          temp.push(tempObj);
        });
        let domainCompanies = res.data.data.domain_company;
        domainCompanies.forEach((company) => {
          let tempObj = {
            [company.company]: company.id,
          };
          temp.push(tempObj);
        });
        setCompanyList(temp);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  useEffect(() => {
    if (companyList) {
      let arr = companyList.map((comp) => {
        for (let key in comp) return { name: key, value: key };
      });
      setTempCompList(arr);
    }
  }, [companyList]);

  const joinCompany = () => {
    let cid = null;
    let flag = false;
    companyList.forEach((comp) => {
      let c_name = Object.keys(comp)[0];
      let c_id = Object.values(comp)[0];

      if (c_name == company) {
        setCompanyID(c_id);
        cid = c_id;
        flag = true;
        return;
      }
    });
    if (flag) {
      sendData(cid);
    }
  };

  const getJobRoles = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/user_type/?main_user=company")
      .then((res) => {
        let temp = {};
        res.data.data.forEach((data) => {
          if (data.id !== 5) temp[data.sub_user] = data.id;
        });
        setJobRolesList({ ...temp });
      })
      .catch((err) => {
        throw err;
      });
  };

  const sendData = (compID) => {
    setLoading(true);
    let arr = [
      ...companyUserDetails.CandidatePreferences,
      ...companyUserDetails.JobPreferences,
    ];
    axios
      .post(BackendBaseApi.PRAVESH + "api/company/company_user/", {
        user: companyUserDetails.CompanyUserID,
        company_id: compID,
        job_title: companyUserDetails.JobTitle,
        is_third_party: false,
        preferences: {
          job_segment: companyUserDetails.CandidatePreferences,
          job_type: companyUserDetails.JobPreferences,
        },
        is_coworking: false,
        job_role: jobRolesList[jobRole],
        college_id: companyUserDetails.CollegeID
          ? companyUserDetails.CollegeID
          : null,
        college_name: companyUserDetails.CollegeName
          ? companyUserDetails.CollegeName
          : null,
        graduation_year: companyUserDetails.GraduatingYear
          ? companyUserDetails.GraduatingYear
          : null,
        referral_code: companyUserDetails.RefferalCode
          ? companyUserDetails.RefferalCode
          : null,
      })
      .then((res) => {
        setLoading(false);

        if (res.data.success) {
          alert.success(res.data.data.message);
          
          history.push({
            pathname: "/company/admin-approve",
            company: company,
            admin_email: res.data.data.data.admin_email,
            admin_name: res.data.data.data.admin_name,
            is_admin: false
          });
        } else {
          alert.error(res.data.error);
          return;
        }
      })
      .catch((err) => {
        localStorage.clear();
        alert.error("Invalid Session!! Please try again");
        throw err;
      });
  };

  const history = useHistory();
  return loading ? (
    <Loader />
  ) : (
    <>
      <h1 className="mb-4 login-heading text-left">
        Find and Join your company{" "}
      </h1>

      {!domainCompany ? (
        <>
          <TypeAndSearchCompany></TypeAndSearchCompany>
          <div className="row pl-3 my-2 ">
            <span className="fs-16">
              Can't find yours?{" "}
              <strong
                onClick={() => {
                  setStep(step + 1);
                }}
                className="link-text"
              >
                Create it here
              </strong>{" "}
            </span>
          </div>
          <div className="row my-1 pt-3">
            {/* <div className="col-6 text-left">
                            <button onClick={()=>{setStep(step-1)}} className=" shadow_1 btn btn-lg btn-main btn-login btn-next 
                            text-uppercase font-weight-bold mb-2" type="submit"><span>
                                 <i class="fas fa-angle-double-left"/> Back</span></button>
                          </div> */}
          </div>
        </>
      ) : (
        <>
          <div class="card shadow_1">
            <div class="card-body">
              We found your company to be <b>{domainCompany}</b>
              <button
                className="shadow_1 btn btn-lg btn-main btn-login btn-next"
                onClick={joinCompany}
              >
                Request to Join!
              </button>
            </div>
          </div>
        </>
      )}
      <div className="form-group text-left my-4">
        <label className="fw-500">Your Job Role</label>
        <JobRolesDropdown className="form-control shadow_1-lightest" />
      </div>
      <div
        className="col-12"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px",
        }}
      >
        <button
          style={{
            width: "35%",
            height: "36px",
            fontSize: "16px",
            fontWeight: "bold",
            textTransform: "capitalize ",
            border: "2px solid #3282c4",
            color: "#3282c4",
            background: "#fff",
            borderRadius: 4,
            cursor: "pointer",
          }}
          onClick={(e) => {
            setStep(step - 1);
          }}
        >
          <span>Back</span>
        </button>
        <button
          style={{
            width: "60%",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          className="shadow_1 btn btn-lg btn-main btn-login btn-next"
          onClick={joinCompany}
        >
          Join
        </button>
      </div>
    </>
  );
};

export default JoinCompany;

import {
  Button,
  CircularProgress,
  createStyles,
  Divider, FormControlLabel, Grid, makeStyles, TextField,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../../constants/constants";
import { educationTypes, executeScrollCommon } from "../../../../../utils/utility";
import CustomizedCheckboxes from "../../../../common/JobPostNew/FormComponents/CustomizedCheckboxes";
import EducationDetailsCards from "./EducationDetailsCards";
import {instituteIdSelectorFn} from '../../../../../selector';
import {useSelector} from 'react-redux';
import moment from "moment";

export default function EducationDetails(props) {
  const {
    openAddEducation,
    setOpenAddEducation,
    educationDetails,
    setEducationDetails,
    setButtonClicked,
    allBoards,
    allColleges,
    handleNext,
    handleBack,
    activeStep,
    educationDetailsErrors,
    setEducationDetailsErrors,
    buttonClicked,
    educationDetailsList,
    setEducationDetailsList,
    educationLoader,
    fetchPrecentage,
    instituteList,
  } = props;
  const classes = useStyles();
  const [saveLoader, setSaveLoader] = useState(false);
  const [allDegrees, setAllDegrees] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [isSaas, setIsSaas] = useState(false);
  const instituteId = useSelector(instituteIdSelectorFn);
  const [isGradeSystem, setIsGradeSystem] = useState(false);

  const educationFormRef = useRef(null);

  const alert = useAlert();
  const checkCollege = () => {
    if (educationDetails?.college && educationDetails?.college !== 142 && educationDetails?.college?.is_onboarded) return `&college_id=${educationDetails.college.id}`;
    else return ``;
  };

  useEffect(() => {
    if (educationDetails.education_level) {
      Axios.get(`${BackendBaseApi.PRAVESH}api/education/get_degree?education_level=${educationDetails.education_level.education_id}${checkCollege()}`)
        .then((res) => {
          setAllDegrees(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationDetails.college, educationDetails.education_level]);

  useEffect(() => {
    if (educationDetails.degree) {
      Axios.get(`${BackendBaseApi.PRAVESH}api/education/get_specialization?degree_id=${educationDetails.degree.id}${checkCollege()}`)
        .then((res) => {
          setAllCourses(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationDetails.degree]);

  const addEducation = (e) => {
    setSaveLoader(true);
    if (educationDetails.is_current) {
      let flag = false;
      educationDetailsList.forEach((edu) => {
        if (edu.is_current && edu.education_id !== editId) flag = true;
      });
      if (flag) {
        alert.error("You cannot have 2 Highest Qualifications !");
        setSaveLoader(false);
        setButtonClicked(false);
        return;
      }
    }
    var arr = [...educationDetailsList];
    Axios.post(
      BackendBaseApi.PRAVESH + `api/education/user_education`,
      {
        college: educationDetails.college ? educationDetails.college.id : 142,
        college_name: educationDetails.college ? educationDetails.college.name : educationDetails.college_typed,
        education: educationDetails.education_level.education_id,
        is_current: educationDetails.is_current,
        ...(!isGradeSystem && { percentage: educationDetails.percentage }),
        ...(isGradeSystem && { cgpa: educationDetails.cgpa }),
        board: educationDetails.board ? educationDetails.board.id : null,
        start_date: educationDetails?.start_date ? moment(moment(educationDetails.start_date, 'yyyy-mm').toDate()).format("yyyy-mm-DD") : null,
        end_date: educationDetails?.end_date ? moment(moment(educationDetails.end_date, 'yyyy-mm').toDate()).format("yyyy-mm-DD") : null,
        degree: educationDetails.degree ? educationDetails.degree.id : null,
        specialization: educationDetails.courses ? educationDetails.courses.specialization_id : null,
        institute: instituteId ? instituteId : (educationDetails?.institute ? educationDetails?.institute.id : null),
        enrollment_id: isSaas && educationDetails?.enrollment_id ? educationDetails?.enrollment_id : null,
      },
      { headers: { Authorization: "Token " + localStorage.getItem("gw_token") } }
    )
      .then((res) => {
        if (res.data.success) {
          setSaveLoader(false);
          alert.success(res.data.data.message);
          setOpenAddEducation(false);
          arr.push(res.data.data.data);
          setEducationDetailsList(arr);
          setButtonClicked(false);
          fetchPrecentage();

          //   clearStates();
          //   window.document.getElementById("student-edit-education-details").className = "collapse";
        } else {
          alert.error(res.data.error);
          setSaveLoader(false);
        }
      })
      .catch((err) => {
        setSaveLoader(false);

        alert.error("There was some error adding your education details. Try again later");
      });
  };
  const [validate, setValidate] = useState(false);

  const validateData = () => {
    var education_level_error = "";
    var passing_year_error = "";
    var board_error = "";
    var percentage_error = "";
    var cgpa_error = "";
    var college_error = "";
    var college_typed_error = "";
    var start_date_error = "";
    var end_date_error = "";
    var degree_error = "";
    var courses_error = "";
    var enrollment_id_error = "";

    setValidate(false);
    if (!educationDetails.education_level) {
      education_level_error = "Please Enter Education Level";
      setValidate(true);
    }
    // if (!educationDetails.passing_years) {
    //   passing_year_error = "Please Enter Passing Year";
    //   setValidate(true);
    // }

    if (educationDetails.education_level && educationDetails.education_level.education_id <= 2) {
      if(!educationDetails.board){
      board_error = "Please Enter your board";
      setValidate(true);
      }
      if (!educationDetails.college_typed){
        college_typed_error = "Please enter school name";
        setValidate(true);
      }
    }
    if (!educationDetails.board && educationDetails.education_level && educationDetails.education_level.education_id <= 2) {
      board_error = "Please Enter your board";
      setValidate(true);
    }
    if (!isGradeSystem && !educationDetails.percentage) {
      percentage_error = "Please Enter your Percentage";
      setValidate(true);
    }
    if (educationDetails.percentage && (educationDetails.percentage > 100 || educationDetails.percentage < 0)) {
      percentage_error = "Please Enter your Percentage correctly within range of 0 to 100";
      setValidate(true);
    }
    if (isGradeSystem && !educationDetails.cgpa) {
      cgpa_error = "Please Enter your CGPA";
      setValidate(true);
    }
    if (educationDetails.cgpa && (educationDetails.cgpa > 10 || educationDetails.cgpa < 0)) {
      cgpa_error = "Please Enter your CGPA correctly within range of 0 to 10";
      setValidate(true);
    }

    if (!educationDetails.board && educationDetails.education_level && educationDetails.education_level.education_id > 2) {
      if (educationDetails.collegeNotListed && educationDetails.college_typed === "") {
        college_typed_error = "Please Enter your College";
        setValidate(true);
      }
      if (!educationDetails.collegeNotListed && !educationDetails.college) {
        college_error = "Please Enter your College";
        setValidate(true);
      }

      if (!educationDetails.degree) {
        degree_error = "Please Enter your degree";
        setValidate(true);
      }
      if (!educationDetails.courses) {
        courses_error = "Please Enter your Specialization";
        setValidate(true);
      }
    }
    if (!educationDetails.start_date) {
      start_date_error = "Please Enter Start Date";
      setValidate(true);
    }

    if (!educationDetails.still_persuing && !educationDetails.end_date) {
      end_date_error = "Please Enter End Date";
      setValidate(true);
    }
    if (!educationDetails.enrollment_id && isSaas) {
      enrollment_id_error = "Please Enter Enrollment Id";
      setValidate(true);
    }

    setEducationDetailsErrors({
      ...educationDetailsErrors,
      education_level: education_level_error,
      // passing_years: passing_year_error,
      board: board_error,
      percentage: percentage_error,
      cgpa: cgpa_error,
      college: college_error,
      college_typed: college_typed_error,
      degree: degree_error,
      courses: courses_error,
      start_date: start_date_error,
      end_date: end_date_error,
      enrollment_id: enrollment_id_error,
    });

    setButtonClicked(true);
  };

  useEffect(() => {
    if (!validate && buttonClicked) {
      if (editOpen) {
        if (editId) handleEdit();
        else handleEditParse();
      } else addEducation();
    }
  }, [buttonClicked]);

  useEffect(() => {
    if (educationDetails.college) {
      allColleges.forEach((item) => {
        if (item.id === educationDetails.college.id) {
          setIsSaas(item.is_saas);
          setIsGradeSystem(item.is_grade_system);
        }
      });
    } else setIsSaas(false);
  }, [educationDetails.college, allColleges]);

  const handleDelete = (id) => {
    Axios.delete(BackendBaseApi.PRAVESH + `api/education/user_education/${id}/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          let temp = educationDetailsList.filter((edu) => {
            return edu.education_id !== id;
          });
          setEducationDetailsList(temp);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("There was some error deleting this education. Try again later");
      });
  };

  const handleEdit = () => {
    setSaveLoader(true);
    let  currInstituteId = null;
    if (educationDetails.is_current) {
      let flag = false;
      currInstituteId = instituteId ? instituteId : (educationDetails?.institute ? educationDetails?.institute.id : null);
      educationDetailsList.forEach((edu) => {
        if (edu.is_current && edu.education_id !== editId) flag = true;
      });
      if (flag) {
        alert.error("You cannot have 2 Highest Qualifications !");
        setSaveLoader(false);
        setButtonClicked(false);

        return;
      }
    }
    let arr = [...educationDetailsList];
    Axios.patch(
      BackendBaseApi.PRAVESH + `api/education/user_education/${editId}/`,
      {
        college: educationDetails.college ? educationDetails.college.id : null,
        college_name: educationDetails.college ? educationDetails.college.name : educationDetails.college_typed,
        education: educationDetails.education_level.education_id,
        is_current: educationDetails.is_current,
        percentage: !isGradeSystem ? educationDetails.percentage : 0,
        ...(isGradeSystem && { cgpa: isGradeSystem ? educationDetails.cgpa : null }),
        board: educationDetails.board ? educationDetails.board.id : null,
        start_date: educationDetails?.start_date ? moment(moment(educationDetails.start_date, 'yyyy-mm').toDate()).format("yyyy-mm-DD") : null,
        end_date: educationDetails?.end_date ? moment(moment(educationDetails.end_date, 'yyyy-mm').toDate()).format("yyyy-mm-DD") : null,
        degree: educationDetails.degree ? educationDetails.degree.id : null,
        specialization: educationDetails.courses ? educationDetails.courses.specialization_id : null,
        institute: currInstituteId,
        enrollment_id: isSaas && educationDetails?.enrollment_id ? educationDetails?.enrollment_id : null
      },
      { headers: { Authorization: "Token " + localStorage.getItem("gw_token") } }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setEditOpen(false);
          setSaveLoader(false);
          setButtonClicked(false);
          var myArray = [];
          myArray.push(res.data.data.data);

          var res = arr.map((obj) => myArray.find((o) => o.education_id === obj.education_id) || obj);
          setEducationDetailsList(res);

          setEditId(null);
          setOpenAddEducation(false);
          fetchPrecentage();
        } else {
          alert.error(res.data.error);
          setSaveLoader(false);
        }
      })
      .catch((err) => {
        alert.error("There was some error updating your education details. Try again later");
        setSaveLoader(false);
      });
  };

  const editBtn = (id) => {
    // alert.error(id);
    let arr = educationDetailsList.filter((edu) => edu.education_id === id);
    let temp = arr[0];

    setOpenAddEducation(true);
    setEditId(id);
    setEditOpen(true);
    setEducationDetails({
      ...educationDetails,

      education_level: { education_id: temp.education, education_name: temp.highest_qualification },
      passing_years: temp.end_date ? { year: temp.end_date.substring(0, 4) } : null,
      board: { id: temp.board, name: temp.board_name },
      percentage: temp.percentage,
      institute: instituteId ? instituteId : (temp.institute_id ? { id: temp.institute_id, name: temp.institute_name } : null),
      cgpa: temp.cgpa,
      college: temp.college
        ? {
            id: temp.college,
            name: temp.college_name,
          }
        : null,
      college_typed: temp.temp_college_name || temp.college_name,
      degree: temp.degree_id
        ? {
            id: temp.degree_id,
            name: temp.degree,
          }
        : null,
      courses: temp.specialization_id
        ? {
            specialization_id: temp.specialization_id,
            specialization_name: temp.specialization,
          }
        : null,
      collegeNotListed: temp.temp_college_name ? true : false,
      is_current: temp.is_current ? true : false,
      still_persuing: false,
      start_date: temp.start_date ? moment(moment(temp.start_date, 'yyyy-mm-dd').toDate()).format('yyyy-mm') : null,
      end_date: temp.end_date ? moment(moment(temp.end_date, 'yyyy-mm-dd').toDate()).format('yyyy-mm') : null,
      enrollment_id: temp.enrollment_id ? temp.enrollment_id : null,
    });
    executeScrollCommon(educationFormRef);
  };

  const editBtnParse = (id) => {
    // alert.error(id);
    let temp = educationDetailsList[id];

    setOpenAddEducation(true);
    setEditIndex(id);
    setEditOpen(true);
    setEducationDetails({
      ...educationDetails,

      education_level: { education_id: temp.education, education_name: temp.highest_qualification },
      passing_years: temp.end_date ? { year: temp.end_date.substring(0, 4) } : null,
      board: { id: temp.board, name: temp.board_name },
      percentage: temp.percentage,
      institute: instituteId ? instituteId : (temp.institute_id ? { id: temp.institute_id, name: temp.institute_name } : null),
      cgpa: temp.cgpa,
      college: temp.college
        ? {
            id: temp.college,
            name: temp.college_name,
          }
        : null,
      college_typed: temp.temp_college_name || temp.college_name,
      degree: temp.degree_id
        ? {
            id: temp.degree_id,
            name: temp.degree,
          }
        : null,
      courses: temp.specialization_id
        ? {
            specialization_id: temp.specialization_id,
            specialization_name: temp.specialization,
          }
        : null,
      collegeNotListed: temp.temp_college_name ? true : false,
      is_current: temp.is_current,
      still_persuing: false,
      start_date: temp.start_date ? temp.start_date : null,
      end_date: temp.end_date ? temp.end_date : null,
      enrollment_id: temp.enrollment_id ? temp.enrollment_id : null,
    });
    executeScrollCommon(educationFormRef);
  };

  const clearAll = () => {
    setEducationDetails({
      ...educationDetails,
      education_level: null,
      passing_years: null,
      board: null,
      percentage: null,
      cgpa: null,
      college: null,
      college_typed: "",
      degree: null,
      courses: null,
      collegeNotListed: false,
      is_current: false,
      still_persuing: false,
      start_date: null,
      end_date: null,
      enrollment_id: null,
    });

    setEducationDetailsErrors({
      ...educationDetailsErrors,
      education_level: "",
      passing_years: "",
      board: "",
      percentage: "",
      cgpa: "",
      college: "",
      college_typed: "",
      degree: "",
      courses: "",
      collegeNotListed: "",
      is_current: "",
      still_persuing: "",
      start_date: "",
      end_date: "",
      enrollment_id: "",
    });
  };

  const handleEditParse = () => {
    setSaveLoader(true);
    let  currInstituteId = null;
    if (educationDetails.is_current) {
    //   let flag = false;
    //   educationDetailsList.forEach((edu) => {
    //     if (edu.is_current && edu.education_id !== editId) flag = true;
    //   });
    //   if (flag) {
    //     alert.error("You cannot have 2 Highest Qualifications !");
    //     setSaveLoader(false);
    //     setButtonClicked(false);
    //     return;
    //   }
    currInstituteId = instituteId ? instituteId : (educationDetails?.institute ? educationDetails?.institute.id : null);
    }
    var arr = [...educationDetailsList];
    Axios.post(
      BackendBaseApi.PRAVESH + `api/education/user_education`,
      {
        college: educationDetails.college ? educationDetails.college.id : 142,
        college_name: educationDetails.college ? educationDetails.college.name : educationDetails.college_typed,
        education: educationDetails.education_level.education_id,
        is_current: educationDetails.is_current,
        ...(!isGradeSystem && { percentage: educationDetails.percentage }),
        ...(isGradeSystem && { cgpa: educationDetails.cgpa }),
        board: educationDetails.board ? educationDetails.board.id : null,
        start_date: educationDetails?.start_date ? educationDetails?.start_date : null,
        end_date: educationDetails?.end_date ? educationDetails?.end_date : null,
        degree: educationDetails.degree ? educationDetails.degree.id : null,
        specialization: educationDetails.courses ? educationDetails.courses.specialization_id : null,
        institute: currInstituteId,
        enrollment_id: isSaas && educationDetails?.enrollment_id ? educationDetails?.enrollment_id : null
      },
      { headers: { Authorization: "Token " + localStorage.getItem("gw_token") } }
    )
      .then((res) => {
        if (res.data.success) {
          setSaveLoader(false);
          alert.success(res.data.data.message);
          setOpenAddEducation(false);
          arr[editIndex] = res.data.data.data;
          setEducationDetailsList(arr);
          setButtonClicked(false);
          fetchPrecentage();

          //   clearStates();
          //   window.document.getElementById("student-edit-education-details").className = "collapse";
        } else {
          alert.error(res.data.error);
          setSaveLoader(false);
        }
      })
      .catch((err) => {
        setSaveLoader(false);

        alert.error("There was some error adding your education details. Try again later");
      });
  };

  const handleDeleteParse = (index) => {
    var array = [...educationDetailsList];
    var item = array[index];

    deleteParseEdu(item, index, array);
  };

  const deleteParseEdu = (item, index, array) => {
    Axios.get(
      BackendBaseApi.PRAVESH + `api/education/v2/resume_upload/parsed_data_delete/?section=education&id=${item.education_id}`,

      { headers: { Authorization: "Token " + localStorage.getItem("gw_token") } }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("Education Deleted Successfully!!");
          if (index > -1) {
            array.splice(index, 1);
          }
          setEducationDetailsList(array);
        } else {
          alert.error("Error Deleting Education");
        }
      })
      .catch((err) => {
        alert.error("There was some error adding your experience. Try again later");
      });
  };

  return (
    <div>
      {educationLoader ? (
        <CircularProgress size={40} style={{ color: "#007bff", marginTop: 30, marginBottom: 30 }} />
      ) : (
        <>
          {educationDetailsList && educationDetailsList?.length && educationDetailsList[0].is_parsed_data ? (
            <Typography variant="body1" style={{ textAlign: "left", fontSize: 13, marginBottom: 10, color: "red" }}>
              Data fetched from Resume. Please check, edit and save the required Educations
            </Typography>
          ) : null}
          {educationDetailsList?.length > 0 &&
            educationDetailsList.map((item, index) => (
              <>
                <EducationDetailsCards
                  data={item}
                  index={index}
                  editBtn={editBtn}
                  handleDelete={handleDelete}
                  educationFormRef={educationFormRef}
                  handleDeleteParse={handleDeleteParse}
                  editBtnParse={editBtnParse}
                />
              </>
            ))}
        </>
      )}
      <Divider />
      <div ref={educationFormRef}>
        {!openAddEducation && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#007bff",
            }}
            onClick={() => {
              setOpenAddEducation(true);
              clearAll();
            }}
          >
            <IconButton style={{ color: "#007bff" }}>
              <ControlPointIcon />
            </IconButton>
            <span>Add Education</span>
          </div>
        )}
        <br />

        {openAddEducation && (
          <>
            <div ref={educationFormRef}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    value={educationDetails.education_level}
                    openOnFocus
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setEducationDetails({ ...educationDetails, education_level: newValue });
                        setEducationDetailsErrors({ ...educationDetailsErrors, education_level: "" });

                        setButtonClicked(false);
                      }
                    }}
                    id="role345"
                    options={educationTypes}
                    getOptionLabel={(option) => option.education_name}
                    renderOption={(option) => {
                      return <span style={{ fontSize: 13 }}>{`${option.education_name}`}</span>;
                    }}
                    className={classes.fieldStyling1}
                    renderInput={(params) => {
                      params.inputProps.className = classes.comboOptions;
                      return (
                        <TextField
                          {...params}
                          label={
                            <span style={{ fontSize: 13 }}>
                              Level of Education <span className={classes.RedStar}>*</span>
                            </span>
                          }
                          variant="outlined"
                          error={educationDetailsErrors.education_level !== "" ? true : false}
                          helperText={
                            <span style={educationDetailsErrors.education_level !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                              {educationDetailsErrors.education_level}
                            </span>
                          }
                        />
                      );
                    }}
                  />
                </Grid>
                {educationDetails.education_level && educationDetails?.education_level.education_id >= 3 && isSaas && (
                  <Grid item md={6} xs={12} className={classes.pcEnrollId}>
                    <TextField
                      error={educationDetailsErrors.enrollment_id !== "" ? true : false}
                      key="Email"
                      id="standard-error-helper-text"
                      variant="outlined"
                      type="text"
                      label={
                        <span className={classes.labelField}>
                          Enrollment Id&nbsp;<span className={classes.RedStar}>*</span>
                        </span>
                      }
                      helperText={
                        <span style={educationDetailsErrors.enrollment_id !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                          {educationDetailsErrors.enrollment_id}
                        </span>
                      }
                      value={educationDetails.enrollment_id}
                      onChange={(e) => {
                        setEducationDetails({ ...educationDetails, enrollment_id: e.target.value });
                        setEducationDetailsErrors({ ...educationDetailsErrors, enrollment_id: "" });

                        // setErrorMessages({ ...errorMessages, phone: "" });
                        setButtonClicked(false);
                      }}
                      InputProps={{
                        inputProps: { min: 0, max: 100 },

                        style: { fontSize: 13 },
                      }}
                      InputLabelProps={{
                        style: { backgroundColor: "#fff" },
                      }}
                      className={classes.fieldStyling}
                    />
                  </Grid>
                )}
                {
                  educationDetails.education_level && educationDetails?.education_level.education_id <= 2 && (
                    <Grid item md={6} xs={12} className={classes.pcEnrollId}>
                        <TextField
                            error={educationDetailsErrors.college_typed !== "" ? true : false}
                            key="10_college_name"
                            id="standard-error-helper-text"
                            variant="outlined"
                            type="text"
                            label={
                              <span className={classes.labelField}>
                                School Name&nbsp;<span className={classes.RedStar}>*</span>
                              </span>
                            }
                            helperText={
                              <span style={educationDetailsErrors.college_typed !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                                {educationDetailsErrors.college_typed}
                              </span>
                            }
                            value={educationDetails.college_typed}
                            onChange={(e) => {
                              setEducationDetails({ ...educationDetails, college_typed: e.target.value });
                              setEducationDetailsErrors({ ...educationDetailsErrors, college_typed: "" });
                            }}
                            InputProps={{
                              style: { fontSize: 13 },
                            }}
                            InputLabelProps={{
                              style: { backgroundColor: "#fff" },
                            }}
                            className={classes.fieldStyling}
                        /> 
                    </Grid>
                  )
                }
              </Grid>
            </div>

            {educationDetails.education_level && educationDetails.education_level.education_id <= 2 && (
              <>
                <div>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        value={educationDetails.board}
                        openOnFocus
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setEducationDetails({ ...educationDetails, board: newValue });
                            setEducationDetailsErrors({ ...educationDetailsErrors, board: "" });

                            //   setUserDetails({ ...userDetails, reference: newValue.value });
                            //   setErrorMessages({ ...errorMessages, reference: "" });

                            setButtonClicked(false);
                          }
                        }}
                        id="role345"
                        options={allBoards}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => {
                          return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
                        }}
                        className={classes.fieldStyling1}
                        renderInput={(params) => {
                          params.inputProps.className = classes.comboOptions;
                          return (
                            <TextField
                              {...params}
                              label={
                                <span style={{ fontSize: 13 }}>
                                  Board <span className={classes.RedStar}>*</span>
                                </span>
                              }
                              variant="outlined"
                              error={educationDetailsErrors.board !== "" ? true : false}
                              helperText={
                                <span style={educationDetailsErrors.board !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                                  {educationDetailsErrors.board}
                                </span>
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={educationDetailsErrors.percentage !== "" ? true : false}
                        key="Email"
                        id="standard-error-helper-text"
                        variant="outlined"
                        type="number"
                        label={
                          <span className={classes.labelField}>
                            Percentage&nbsp;<span className={classes.RedStar}>*</span>
                          </span>
                        }
                        helperText={
                          <span style={educationDetailsErrors.percentage !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                            {educationDetailsErrors.percentage}
                          </span>
                        }
                        value={educationDetails.percentage}
                        onChange={(e) => {
                          setEducationDetails({ ...educationDetails, percentage: e.target.value });
                          setEducationDetailsErrors({ ...educationDetailsErrors, percentage: "" });

                          // setErrorMessages({ ...errorMessages, phone: "" });
                          setButtonClicked(false);
                        }}
                        InputProps={{
                          inputProps: { min: 0, max: 100 },

                          style: { fontSize: 13 },
                        }}
                        InputLabelProps={{
                          style: { backgroundColor: "#fff" },
                        }}
                        className={classes.fieldStyling}
                      />
                      {educationDetailsErrors.percentage === "" && (
                        <span style={{ color: "grey", fontSize: 12, textAlign: "left", float: "left" }}>
                          Please Convert your CGPA into percentage as per your College/University Standards
                        </span>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}

            {educationDetails.education_level && educationDetails.education_level.education_id >= 3 && (
              <>
                <div>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      {educationDetails.collegeNotListed ? (
                        <>
                          <TextField
                            error={educationDetailsErrors.college_typed !== "" ? true : false}
                            key="Email"
                            id="standard-error-helper-text"
                            variant="outlined"
                            type="text"
                            label={
                              <span className={classes.labelField}>
                                Enter your College&nbsp;<span className={classes.RedStar}>*</span>
                              </span>
                            }
                            helperText={
                              <span style={educationDetailsErrors.college_typed !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                                {educationDetailsErrors.college_typed}
                              </span>
                            }
                            value={educationDetails.college_typed}
                            onChange={(e) => {
                              setEducationDetails({ ...educationDetails, college_typed: e.target.value });
                              setEducationDetailsErrors({ ...educationDetailsErrors, college_typed: "" });

                              // setErrorMessages({ ...errorMessages, phone: "" });
                              setButtonClicked(false);
                            }}
                            InputProps={{
                              style: { fontSize: 13 },
                            }}
                            InputLabelProps={{
                              style: { backgroundColor: "#fff" },
                            }}
                            className={classes.fieldStyling}
                          />
                        </>
                      ) : (
                        <Autocomplete
                          value={educationDetails.college}
                          openOnFocus
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setEducationDetails({ ...educationDetails, college: newValue });
                              setEducationDetailsErrors({ ...educationDetailsErrors, college: "" });
                              //   setUserDetails({ ...userDetails, reference: newValue.value });
                              //   setErrorMessages({ ...errorMessages, reference: "" });
                              setIsSaas(newValue.is_saas);
                              setIsGradeSystem(newValue.is_grade_system);
                              setButtonClicked(false);
                            }
                          }}
                          id="role345"
                          options={allColleges}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => {
                            return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
                          }}
                          renderInput={(params) => {
                            params.inputProps.className = classes.comboOptions;
                            return (
                              <TextField
                                {...params}
                                label={
                                  <span style={{ fontSize: 13 }}>
                                    Select College <span className={classes.RedStar}>*</span>
                                  </span>
                                }
                                variant="outlined"
                                error={educationDetailsErrors.college !== "" ? true : false}
                                helperText={
                                  <span style={educationDetailsErrors.college !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                                    {educationDetailsErrors.college}
                                  </span>
                                }
                              />
                            );
                          }}
                        />
                      )}
                      <FormControlLabel
                        className={classes.fieldStyling1}
                        value={educationDetails.collegeNotListed}
                        style={{ marginLeft: 0 }}
                        control={
                          <CustomizedCheckboxes
                            checked={educationDetails.collegeNotListed}
                            onChange={(e) => {
                              setEducationDetails({ ...educationDetails, collegeNotListed: !educationDetails.collegeNotListed, college_typed: "", college: null });
                            }}
                            name={"college_not_listed"}
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                            color="primary"
                          />
                        }
                        label={<span style={{ fontSize: 13 }}>My College is not Listed</span>}
                      />
                    </Grid>
                    {isSaas && (
                      <Grid item md={6} xs={12} className={classes.mobileEnrollId}>
                        <TextField
                          error={educationDetailsErrors.enrollment_id !== "" ? true : false}
                          key="Email"
                          id="standard-error-helper-text"
                          variant="outlined"
                          type="text"
                          label={
                            <span className={classes.labelField}>
                              Enrollment Id&nbsp;<span className={classes.RedStar}>*</span>
                            </span>
                          }
                          helperText={
                            <span style={educationDetailsErrors.enrollment_id !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                              {educationDetailsErrors.enrollment_id}
                            </span>
                          }
                          value={educationDetails.enrollment_id}
                          onChange={(e) => {
                            setEducationDetails({ ...educationDetails, enrollment_id: e.target.value });
                            setEducationDetailsErrors({ ...educationDetailsErrors, enrollment_id: "" });

                            // setErrorMessages({ ...errorMessages, phone: "" });
                            setButtonClicked(false);
                          }}
                          InputProps={{
                            inputProps: { min: 0, max: 100 },

                            style: { fontSize: 13 },
                          }}
                          InputLabelProps={{
                            style: { backgroundColor: "#fff" },
                          }}
                          className={classes.fieldStyling}
                        />
                      </Grid>
                    )}
                    {!isGradeSystem && (
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={educationDetailsErrors.percentage !== "" ? true : false}
                          key="Email"
                          id="standard-error-helper-text"
                          variant="outlined"
                          type="number"
                          label={
                            <span className={classes.labelField}>
                              Percentage&nbsp;<span className={classes.RedStar}>*</span>
                            </span>
                          }
                          helperText={
                            <span style={educationDetailsErrors.percentage !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                              {educationDetailsErrors.percentage}
                            </span>
                          }
                          value={educationDetails.percentage}
                          onChange={(e) => {
                            setEducationDetails({ ...educationDetails, percentage: e.target.value });
                            setEducationDetailsErrors({ ...educationDetailsErrors, percentage: "" });

                            // setErrorMessages({ ...errorMessages, phone: "" });
                            setButtonClicked(false);
                          }}
                          InputProps={{
                            inputProps: { min: 0, max: 100 },

                            style: { fontSize: 13 },
                          }}
                          InputLabelProps={{
                            style: { backgroundColor: "#fff" },
                          }}
                          className={classes.fieldStyling}
                        />
                        {/* {educationDetailsErrors.percentage === "" && (
                          <span style={{ color: "grey", fontSize: 12, textAlign: "left", float: "left" }}>
                            Please Convert your CGPA into percentage as per your College/University Standards
                          </span>
                        )} */}
                      </Grid>
                    )}
                    {isGradeSystem && (
                      <Grid item md={6} xs={12}>
                        <TextField
                          error={educationDetailsErrors.cgpa !== "" ? true : false}
                          key="Email"
                          id="standard-error-helper-text"
                          variant="outlined"
                          type="number"
                          label={
                            <span className={classes.labelField}>
                              CGPA&nbsp;<span className={classes.RedStar}>*</span>
                            </span>
                          }
                          helperText={
                            <span style={educationDetailsErrors.cgpa !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                              {educationDetailsErrors.cgpa}
                            </span>
                          }
                          value={educationDetails.cgpa}
                          onChange={(e) => {
                            setEducationDetails({ ...educationDetails, cgpa: e.target.value });
                            setEducationDetailsErrors({ ...educationDetailsErrors, cgpa: "" });

                            // setErrorMessages({ ...errorMessages, phone: "" });
                            setButtonClicked(false);
                          }}
                          InputProps={{
                            inputProps: { min: 0, max: 10, step: "0.01" },

                            style: { fontSize: 13 },
                          }}
                          InputLabelProps={{
                            style: { backgroundColor: "#fff" },
                          }}
                          className={classes.fieldStyling}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>

                <>
                  <div>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        {console.log(allDegrees)}
                        <Autocomplete
                          value={educationDetails.degree}
                          openOnFocus
                          disabled={educationDetails.college || educationDetails.college_typed ? false : true}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setEducationDetails({ ...educationDetails, degree: newValue });
                              setEducationDetailsErrors({ ...educationDetailsErrors, degree: "" });
                              setButtonClicked(false);
                            }
                          }}
                          id="role345"
                          options={allDegrees}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => {
                            return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
                          }}
                          className={classes.fieldStyling1}
                          renderInput={(params) => {
                            params.inputProps.className = classes.comboOptions;
                            return (
                              <TextField
                                {...params}
                                label={
                                  <span style={{ fontSize: 13 }}>
                                    Select Degrees <span className={classes.RedStar}>*</span>
                                  </span>
                                }
                                variant="outlined"
                                error={educationDetailsErrors.degree !== "" ? true : false}
                                helperText={
                                  <span style={educationDetailsErrors.degree !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                                    {educationDetailsErrors.degree}
                                  </span>
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          value={educationDetails.courses}
                          openOnFocus
                          disabled={(educationDetails.college || educationDetails.college_typed) && educationDetails.degree ? false : true}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setEducationDetails({ ...educationDetails, courses: newValue });
                              setEducationDetailsErrors({ ...educationDetailsErrors, courses: "" });

                              //   setUserDetails({ ...userDetails, reference: newValue.value });
                              //   setErrorMessages({ ...errorMessages, reference: "" });

                              setButtonClicked(false);
                            }
                          }}
                          id="role345"
                          options={allCourses}
                          getOptionLabel={(option) => option.specialization_name}
                          renderOption={(option) => {
                            return <span style={{ fontSize: 13 }}>{`${option.specialization_name}`}</span>;
                          }}
                          className={classes.fieldStyling1}
                          renderInput={(params) => {
                            params.inputProps.className = classes.comboOptions;
                            return (
                              <TextField
                                {...params}
                                label={
                                  <span style={{ fontSize: 13 }}>
                                    Select Specialization <span className={classes.RedStar}>*</span>
                                  </span>
                                }
                                variant="outlined"
                                error={educationDetailsErrors.courses !== "" ? true : false}
                                helperText={
                                  <span style={educationDetailsErrors.courses !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                                    {educationDetailsErrors.courses}
                                  </span>
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </>
              </>
            )}
            {educationDetails.education_level && (
              <>
                {educationDetails.education_level.education_id > 2 && (
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          className={classes.fieldStyling1}
                          value={educationDetails.is_current}
                          style={{ marginLeft: 0 }}
                          control={
                            <CustomizedCheckboxes
                              checked={educationDetails.is_current}
                              onChange={(e) => {
                                setEducationDetails({ ...educationDetails, is_current: !educationDetails.is_current });
                              }}
                              name={"college_not_listed"}
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                              color="primary"
                            />
                          }
                          label={<span style={{ fontSize: 13 }}>Is this your highest Qualification?</span>}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
                <div style={{ marginTop: 20 }}>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    TIme Period
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div>
                        <FormControlLabel
                          className={classes.fieldStyling1}
                          value={educationDetails.still_persuing}
                          style={{ marginLeft: 0 }}
                          control={
                            <CustomizedCheckboxes
                              checked={educationDetails.still_persuing}
                              onChange={(e) => {
                                setEducationDetails({ ...educationDetails, still_persuing: !educationDetails.still_persuing });
                              }}
                              name={"college_not_listed"}
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                              color="primary"
                            />
                          }
                          label={<span style={{ fontSize: 13 }}>I am currently pursuing this education</span>}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={educationDetailsErrors.start_date !== "" ? true : false}
                        key="Email"
                        id="standard-error-helper-text"
                        variant="outlined"
                        type="month"
                        label={
                          <span className={classes.labelField}>
                            Education Start Date&nbsp;<span className={classes.RedStar}>*</span>
                          </span>
                        }
                        helperText={
                          <span style={educationDetailsErrors.start_date !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                            {educationDetailsErrors.start_date}
                          </span>
                        }
                        value={educationDetails.start_date}
                        onChange={(e) => {
                          setEducationDetails({ ...educationDetails, start_date: e.target.value });
                          setEducationDetailsErrors({ ...educationDetailsErrors, start_date: "" });

                          // setErrorMessages({ ...errorMessages, email: "" });
                          setButtonClicked(false);
                        }}
                        InputProps={{
                          style: { fontSize: 13 },
                        }}
                        InputLabelProps={{ shrink: true }}
                        className={classes.fieldStyling1}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={educationDetailsErrors.end_date !== "" ? true : false}
                        key="Email"
                        id="standard-error-helper-text"
                        variant="outlined"
                        type="month"
                        label={
                          <span className={classes.labelField}>
                            {educationDetails.still_persuing ? "Education End Date (Tentative)" : "Education End Date"}&nbsp;<span className={classes.RedStar}>*</span>
                          </span>
                        }
                        helperText={
                          <span style={educationDetailsErrors.end_date !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                            {educationDetailsErrors.end_date}
                          </span>
                        }
                        value={educationDetails.end_date}
                        onChange={(e) => {
                          setEducationDetails({ ...educationDetails, end_date: e.target.value });
                          setEducationDetailsErrors({ ...educationDetailsErrors, end_date: "" });

                          // setErrorMessages({ ...errorMessages, email: "" });
                          setButtonClicked(false);
                        }}
                        InputProps={{
                          style: { fontSize: 13 },
                        }}
                        InputLabelProps={{ shrink: true }}
                        className={classes.fieldStyling1}
                      />
                    </Grid>
                  </Grid>
                </div>
                {!instituteId && educationDetails?.is_current && instituteList?.length > 0 && (
                  <div style={{ marginTop: 20 }}>
                    <Typography variant="body1" style={{ textAlign: "left", marginBottom: 20 }}>
                      Do you belong to any particular institute as well?
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Autocomplete
                          value={educationDetails.institute}
                          openOnFocus
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setEducationDetails({ ...educationDetails, institute: newValue });
                              setEducationDetailsErrors({ ...educationDetailsErrors, institute: "" });

                              //   setUserDetails({ ...userDetails, reference: newValue.value });
                              //   setErrorMessages({ ...errorMessages, reference: "" });

                              setButtonClicked(false);
                            }
                          }}
                          id="role345"
                          options={instituteList}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => {
                            return <span style={{ fontSize: 13 }}>{`${option.name}`}</span>;
                          }}
                          className={classes.fieldStyling1}
                          renderInput={(params) => {
                            params.inputProps.className = classes.comboOptions;
                            return (
                              <TextField
                                {...params}
                                label={<span style={{ fontSize: 13 }}>Select Institute</span>}
                                variant="outlined"
                                error={educationDetailsErrors.institute !== "" ? true : false}
                                helperText={
                                  <span style={educationDetailsErrors.institute !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
                                    {educationDetailsErrors.institute}
                                  </span>
                                }
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </>
            )}

            <div style={{ display: "flex", justifyContent: "left" }}>
              {educationDetails?.education_level && (
                <Button onClick={validateData} style={{ color: "#007bff" }}>
                  {saveLoader ? <CircularProgress style={{ color: "#007bff" }} size={15} /> : "Save Education"}
                </Button>
              )}
              {openAddEducation && (
                <Button
                  onClick={() => {
                    setOpenAddEducation(false);
                    clearAll();
                  }}
                  style={{ color: "#007bff" }}
                >
                  Cancel{" "}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    labelField: {
      fontSize: 13,
    },
    RedStar: {
      color: "red",
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      // marginBottom: 20,
    },
    fieldStyling1: {
      width: "100%",
      marginBottom: 10,
    },
    "@media screen and (min-width: 960.5px)": {
      pcEnrollId: {
        display: "block",
        color: "blue",
      },
      mobileEnrollId: {
        display: "none",
      },
    },
    "@media screen and (max-width: 960.5px)": {
      pcEnrollId: {
        display: "none",
      },
      mobileEnrollId: {
        display: "block",
      },
    },
  })
);

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import moment from "moment";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import facebook from "../../../../../assets/common/facebook.png";
import github from "../../../../assets/png/github.png";
import instagram from "../../../../assets/png/instagram.png";
import linkedin from "../../../../assets/png/linkedin.png";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  icon: {
    color: "#007bff",
  },

  sociallink: {
    marginRight: "10px",
    cursor: "pointer",
  },
  about: {
    color: "#6C757D",
  },
  showMoreBtn: {
    color: "#007BFF",
    fontWeight: 600,
    cursor: "pointer",
    background: "none",
    border: "none",
    padding: "0px",
  },
}));

const ContactNew = ({ data }) => {
  const classes = useStyles();
  const alert = useAlert();
  const [showAbout, setShowAbout] = useState(false);
  const basicData = (data) => {
    return [
      {
        heading: "Gender",
        info: data?.gender ? data?.gender : "NA",
        show: data?.gender ? true : false,
      },
      {
        heading: "DOB",
        info: data?.dob ? moment(data?.dob).format("MMM d, YYYY") : "NA",
        show: data?.dob ? true : false,
      },
      {
        heading: "Current Location",
        info: data?.currentCity ? data?.currentCity : "NA",
        show: data?.currentCity ? true : false,
      },
      /*    {
        heading: "Current CTC",
        info: data?.currentCtc ? `${data?.currentCtc}` : "NA",
        show: data?.currentCtc ? true : false,
      },
      {
        heading: "Expected CTC",
        info: data?.expectedCtc ? `${data?.expectedCtc}` : "NA",
        show: data?.expectedCtc ? true : false,
      },
      {
        heading: "Notice Period",
        info: data?.noticePeriod ? `${data?.noticePeriod}` : "NA",
        show: data?.noticePeriod ? true : false,
      }, */
    ];
  };

  const contactData = (data) => {
    return [
      {
        icon: <PhoneIcon color="primary" />,
        info: data?.phone,
        link: `tel:+91${data?.phone}`,
      },
      {
        icon: <MailOutlineIcon color="primary" />,
        info: data?.email,
        link: `mailto: ${data?.email}`,
      },
      // {
      //   icon: <LanguageIcon color="primary" />,
      //   info: "www.roshankapoor.in",
      //   link: "https://www.roshankapoor.in",
      // },
    ];
  };

  const checkSocialLinkImage = (name) => {
    let link_icon = "";
    switch (name) {
      case "Linkedin":
        link_icon = linkedin;
        return link_icon;

      case "Github":
        link_icon = github;
        return link_icon;

      case "Facebook":
        link_icon = facebook;
        return link_icon;

      case "Instagram":
        link_icon = instagram;
        return link_icon;
      default:
        break;
    }
  };

  const checkSocialLink = (name, value) => {
    let link = "";
    switch (name) {
      case "Linkedin":
        link = value;
        return link;

      case "Github":
        link = value;
        return link;

      case "Facebook":
        link = value;
        return link;
      case "Instagram":
        link = value;
        return link;

      default:
        break;
    }
  };

  return (
    <>
      <div>
        <div className={classes.overview}>
          {/*  <Typography variant="h6">Contact and Portfolio</Typography>
          <div style={{ display: "flex", marginTop: "10px" }}>
            {contactData(data).map((item) => (
              <div style={{ marginRight: "10px", cursor: "pointer" }}>
                <a href={item.link} target="_blank" style={{ textDecoration: "none" }}> 
                <CopyToClipboard
                  text={item.info}
                  onCopy={() => alert.success("Copied to clipboard!")}
                >
                  <Tooltip title={item.info} placement="bottom">
                    {item.icon}
                  </Tooltip>
                </CopyToClipboard>
                 </a>
              </div>
            ))}

            {SocialData.map((item) => (
              <div style={{ marginRight: "10px", cursor: "pointer" }}>
                <a href={item.link} target="_blank" style={{ textDecoration: "none" }}>
                  <Tooltip title={item.info} placement="bottom">
                    <img
                      src={item.icon}
                      height="22px"
                      alt="social-link"
                      // className={classes.sociallink}
                    />
                  </Tooltip>
                </a>
              </div>
            ))} 

            {data?.profile_url && data?.profile_url?.length ? (
              <>
                <div style={{ marginRight: "10px", cursor: "pointer" }}>
                  {data?.profile_url !== null &&
                    data?.profile_url.map((item, ind) => (
                      <Tooltip title={item.name} placement="bottom">
                        <img
                          src={checkSocialLinkImage(item.name)}
                          height="22px"
                          alt="social-link"
                          onClick={() =>
                            window.open(checkSocialLink(item.name, item.value))
                          }
                          style={{ marginLeft: 10 }}
                        />
                      </Tooltip>
                    ))}
                </div>
              </>
            ) : null}
          </div> */}

          <div /* style={{ marginTop: "10px", marginBottom: "10px" }} */>
            <Typography
              variant="h5"
              /*   style={{
                marginBottom: "10px",
              }} */
            >
              Basic Info
            </Typography>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                /* flexDirection: "column", */
              }}
            >
              {basicData(data).map(
                (val, ind) =>
                  val.show && (
                    <div
                      variant="body2"
                      style={{
                        fontWeight: "400",
                        marginTop: "10px",
                        color: "#6C757D",
                        margin: "5px 15px 5px 0",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fonttSize: "16px",
                        }}
                      >
                        {" "}
                        {val.heading}
                      </span>{" "}
                      :{" "}
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fonttSize: "16px",
                        }}
                      >
                        {val.info}
                      </span>
                    </div>
                  )
              )}
            </div>
          </div>
          {data?.about && (
            <div>
              <Typography variant="h5">About Us</Typography>
              {showAbout ? (
                <>
                  <Typography variant="body2" className={classes.about}>
                    {data?.about}
                  </Typography>
                  {data?.about?.length > 256 && (
                    <button
                      onClick={() => {
                        setShowAbout(!showAbout);
                      }}
                      className={classes.showMoreBtn}
                    >
                      Read Less
                    </button>
                  )}
                </>
              ) : (
                <>
                  <Typography variant="body2" className={classes.about}>
                    {data?.about?.substring(0, 256)}{" "}
                    {data?.about?.length > 256 && (
                      <button
                        onClick={() => {
                          setShowAbout(!showAbout);
                        }}
                        className={classes.showMoreBtn}
                      >
                        Read More
                      </button>
                    )}
                  </Typography>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactNew;

import React from "react";
import { useAlert } from "react-alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import rocket from "../../../assets/gif/rocket.gif";
import { Divider, IconButton, InputBase, Paper, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BackendBaseApi } from "../../../constants/constants";
import Axios from "axios";
import JobSharerButton from "../JobSharer/JobSharerButton";
import LinkIcon from "@material-ui/icons/Link";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CropFreeIcon from "@material-ui/icons/CropFree";
import CommonIconButton from "../CommonButton/CommonIconButton";
import ShareIcon from "@material-ui/icons/Share";
import Shareicon from "../../../assets/svg/share.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  btn: {
    backgroundColor: "#007bff",
    borderRadius: "30px",
    paddingLeft: "60px",
    paddingRight: "60px",
  },
  btn2: {
    borderRadius: "30px",
    backgroundColor: "#007bff",
  },
  paper1: {
    padding: "2px 4px",
    marginBottom: "30px",

    display: "flex",
    alignItems: "center",
    width: "auto",
    border: "solid 0.5px rgba(0 0 0 0.12)",
    borderRadius: "12px",
    boxShadow: "rgba(0 0 0 0.25) 0px 54px 55px rgba(0 0 0 0.12) 0px -12px 30px rgba(0 0 0 0.12) 0px 4px 6px rgba(0 0 0 0.17) 0px 12px 13px rgba(0 0 0 0.09) 0px -3px 5px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Submit({ jobID, passCode, generatedLink, filterObject, usermail, actionState, detailState, handleShareOpen, comingFromTable, jobProfileObject }) {
  const [open, setOpen] = React.useState(false);
  const alert = useAlert();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // setOpen(true);

  const handleClickOpen = () => {
    if (!jobID) {
      alert.error("Job ID not provided!");
      return;
    }
    if (!generatedLink) {
      alert.error("No Job Link generated!");
      return;
    }
    if (!passCode) {
      alert.error("Passcode is required!");
      return;
    }
    // if (usermail.length === 0) {
    //   alert.error("Email IDs not provided!");
    //   return;
    // }

    // setOpen(true);

    Axios.post(
      BackendBaseApi.NIYUKTI + "job/track/sharer/",
      {
        job: jobID,
        job_link: generatedLink,
        passcode: passCode,
        shared_by: localStorage.getItem("user_type") === "Company" ? "COMPANY" : "COLLEGE",
        sharer_id: localStorage.getItem("user_type") === "Company" ? JSON.parse(localStorage.getItem("company")).company : localStorage.getItem("college_id"),
        sharer_user_id: localStorage.getItem("user_id"),
        ...(localStorage.getItem("user_type") === "Company" && {
          filtered_by: filterObject.applicant_college_id.length === 0 ? null : filterObject.applicant_college_id.map((t) => t.id),
        }),
        action_performed: {
          email: usermail,
          authority: actionState,
          action: detailState,
        },
        is_active: true,
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("Sharable Link generated!");
          setOpen(true);
          if (generatedLink) {
            navigator.clipboard.writeText(generatedLink);
          }
        } else alert.error("Link was not generated. Some error occurred");
      })
      .catch((err) => {
        // alert.error("Some error occured!");
        console.log(err);
      });
  };

  const handleClose = () => {
    setOpen(false);
    handleShareOpen();
  };
  const classes = useStyles();
  return (
    <div>
      {comingFromTable ? (
        <IconButton
          aria-label="delete"
          onClick={() => {
            setOpen(true);
          }}
        >
          <img src={Shareicon} height="23px" width="23px" />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          className={classes.btn}
          onClick={() => {
            if (!comingFromTable) handleClickOpen();
            else setOpen(true);
          }}
        >
          Share
        </Button>
      )}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            border: "solid 0.5px #e2e6ea",
            boxShadow: "rgba(149 157 165 0.2) 0px 8px 24px",
          },
        }}
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <img src={rocket} width="300px" height="300px" />

            <Grid container spacing={3} style={{ marginTop: "-30px" }}>
              <Grid item xs={8}>
                {/* Details section start here */}
                <Typography variant="h6">Link</Typography>
                <Paper className={classes.paper1}>
                  <IconButton className={classes.iconButton} aria-label="menu">
                    <LinkIcon />
                  </IconButton>
                  <Divider className={classes.divider} orientation="vertical" />

                  <InputBase className={classes.input} placeholder="Track Link" value={generatedLink} inputProps={{ "aria-label": "https://www.getwork.org/" }} />

                  <CopyToClipboard text={generatedLink} onCopy={() => alert.info("Link Copied!")}>
                    <Button variant="contained" style={{ backgroundColor: "#007bff", borderRadius: "12px" }}>
                      Copy
                    </Button>
                  </CopyToClipboard>
                </Paper>
              </Grid>

              <Grid item xs={2} sm={4}>
                {/* passcode input section start here */}
                <Typography variant="h6">Passcode</Typography>
                <Paper className={classes.paper1}>
                  <IconButton className={classes.iconButton} aria-label="menu">
                    <CropFreeIcon />
                  </IconButton>
                  <Divider className={classes.divider} orientation="vertical" />
                  <InputBase
                    className={classes.input}
                    value={passCode}
                    placeholder="Passcode"
                    inputProps={{
                      minLength: 6,
                      maxLength: 6,
                    }}
                    // defaultValue="859306"
                  />
                  <CopyToClipboard text={passCode} onCopy={() => alert.info("Passcode Copied!")}>
                    <Button variant="contained" style={{ backgroundColor: "#007bff", borderRadius: "12px" }}>
                      Copy
                    </Button>
                  </CopyToClipboard>
                </Paper>
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ textAlign: "center", fontSize: 17, marginBottom: 30 }}>
              Your sharable link has been copied. Share it wherever you want!
            </Typography>
            <JobSharerButton
              detail={{
                url: generatedLink,
                user:
                  localStorage.getItem("user_type") === "Company"
                    ? JSON.parse(localStorage.getItem("company")).company_name
                    : JSON.parse(localStorage.getItem("user_details")).college_name,
                passcode: passCode,
                jobProfile: jobProfileObject,
              }}
              page="SHARE_TRACK"
            />

            <br />
            <Button variant="contained" className={classes.btn} onClick={() => handleClose()}>
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

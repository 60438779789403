import React, { useState, useEffect, useRef } from "react";
import GenerateRandomPassword from "../../common/UI/GenerateRandomPassword";
import Axios from "axios";
import {
  EndPointPrefix,
  BackendBaseApi,
} from "../../../constants/constants";
import { useAlert } from "react-alert";
import Navbar from "../../student/Navbar/StudentNavbar";
import StudentProfilePersonal from "../../student/Profile/StudentProfilePersonal";
import { Dialog, Button, makeStyles, createStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    submitButton: {
      backgroundColor: "#3282c4",
      height: 36,
      color: "#ffffff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#3282c4",
      },
    },
  })
);

const ForgotPassword = () => {
  const alert = useAlert();
  const disableButton = useRef();
  const history = useHistory();
  const classes = useStyles();

  const [isDisable, setIsDisable] = useState(true);

  const [state, setState] = useState({
    password: "",
    new_password: "",
    confirm_new_password: "",
  });

  useEffect(() => {
    if (
      state.password !== "" &&
      state.new_password !== "" &&
      state.confirm_new_password !== ""
    ) {
      if (state.new_password === state.confirm_new_password)
        setIsDisable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post(
      BackendBaseApi.PRAVESH + "api/shared/user_password/",
      {
        password: state.password,
        new_password: state.new_password,
      },
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
        } else {
          alert.error(res.data.error);
        }
        history.push("/student/profile");
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <>
      <div
        className="change-password-container mx-2 px-2"
        style={{ marginTop: "5vh" }}
      >
        <div className="card ml-4">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-5 col-lg-11 col-md-11 col-sm-10 col-xs-10 m-3 py-3">
                <div className="row justify-content-center my-3">
                  <h3 className="fw-800">Change your Password</h3>
                </div>
                <form
                  action=""
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                  className="my-2 mx-4"
                >
                  <div className="form-group text-left ">
                    <label htmlFor="password" className="fs-16 fw-500">
                      Old Password
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2 mb-2"
                      id="password"
                      aria-describedby="emailHelp"
                      placeholder=""
                      required
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      Forgot your old password? Reset it{" "}
                      <a className="">here</a>.
                    </small>
                  </div>
                  <div className="form-group text-left">
                    <label htmlFor="new_password" className="fs-16 fw-500">
                      Create New Password
                    </label>
                    <input
                      type="password"
                      className="form-control mt-2 mb-2"
                      id="new_password"
                      placeholder=""
                      required
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    />
                  </div>
                  <div className="form-group text-left">
                    <label
                      htmlFor="confirm_new_password "
                      className="fs-16 fw-500"
                    >
                      Confirm New Password
                    </label>
                    <input
                      type="password"
                      className="form-control mt-2 mb-2"
                      id="confirm_new_password"
                      placeholder=""
                      required
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    />
                  </div>
                  <div className="row pl-3 pt-3">
                    <Button
                      disabled={isDisable}
                      type="submit"
                      className={classes.submitButton}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
              <div className="col-xl-6 col-lg-11 col-md-11 col-sm-10 col-xs-10">
                <div className="row row px-5 pt-5">
                  <div className="card w-100">
                    <div className="card-body bg-light text-left fs-12">
                      <span>Ensure that your new password contains-</span>
                      <ul>
                        <li>At least one uppercase character</li>
                        <li>At least one lowercase character</li>
                        <li>At least one numeric digit</li>
                        <li>Total length of 8 characters</li>
                      </ul>
                      <span>
                        Doesn't hurt to create a strong password, right? :)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <GenerateRandomPassword />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ChangePassword = ({ studentData }) => {
  return (
    <>
      <Navbar />
      <div className="row profile-card-holder" style={{ marginTop: "6rem" }}>
        <div className="col-lg-3 col-md-0 col-sm-0 ml-2 ml-md-0 d-none d-lg-block ">
          <StudentProfilePersonal />
        </div>
        <div className="col-lg-8 col-md-11 col-sm-12 ml-2 ml-md-0 d-none d-lg-block">
          <div className="card">
            <div className="card-body profile-section">
              <ForgotPassword />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;

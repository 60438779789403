import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import MailHistory from "./MailHistory";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  details: {
    alignItems: "center",
  },
  heading: {
    flexBasis: "33.33%",
    fontSize: theme.typography.pxToRem(15),
    marginLeft: "0%",
  },
}));

export default function UserTable({ jobID, filterObject }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion style={{ border: "none", boxShadow: "none" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
          <div className={classes.heading}>
            <Typography variant="h6">History</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <MailHistory jobID={jobID} filterObject={filterObject} />
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import SideImage from "../../../common/components/UI/SideImage";
import AuthHeader from "../../../common/components/UI/AuthHeader";
import axios from "axios";
import { EndPointPrefix, BackendBaseApi } from "../../../../constants/constants";

const VerifyEmail = () => {
    const alert = useAlert();
    const location = useLocation();

    const [uid, setUid] = useState("");
    const [token, setToken] = useState("");

    const verifyUserEmail = () => {
        axios
            .post(BackendBaseApi.PRAVESH  + "api/email/verify", {
                uid: uid,
                token: token,
            })
            .then((res) => {
                if (res.data.success) alert.success(res.data.data.message);
                else {
                    alert.error(res.data.error);
                    return;
                }
            })
            .catch((err) => {
                localStorage.clear();
                alert.error("Invalid Session!! Please try again");
                throw err;
            });
    };

    useEffect(() => {
        if (uid && token) verifyUserEmail();
    }, [uid, token]);

    useEffect(() => {
        let temp = location.pathname.split("/");
        setUid(temp[2]);
        setToken(temp[3]);
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row no-gutter full-page-view">
                    <SideImage />
                    <div
                        className="col-md-8 col-lg-8 y-scroll full-page-view"
                        style={{ backgroundColor: "#ffffff" }}
                    >
                        <div className="row">
                            <AuthHeader />
                        </div>
                        <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-9 col-lg-6 mx-auto">
                                        <div className="row justify-content-center mx-auto my-2">
                                            <i class="fas fa-envelope-square fa-5x"></i>
                                        </div>
                                        <div className="row justify-content-center mx-auto my-2">
                                            <p className="fs-18 fw-500">
                                                Great! You've successfully
                                                signed up for GetWork.
                                            </p>
                                            <p className="fs-14">
                                                Your account is now active. You
                                                can login{" "}
                                                <Link to="/login">here</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerifyEmail;

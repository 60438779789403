import React, {useState, useEffect} from "react";
import { BackendBaseApi } from "../../constants/constants";
import Axios from "axios";
import { CircularProgress } from "@material-ui/core";
import {useAlert} from "react-alert";
import {useHistory, useLocation} from "react-router";


export default function GetWorkRedirect(){

    const [loader, setLoader] = useState(false);
    const alert = useAlert();
    const history = useHistory();
    const location = useLocation();

    const params = new URLSearchParams(location.search); 

    useEffect(() => {
        setLoader(true);
        Axios.get(BackendBaseApi.PRAVESH + "api/student/getwork/check-redirect/", {
            withCredentials: true,
          }).then(res => {
                if(res?.data?.success){
                    localStorage.setItem("gw_token", res?.data?.data?.token);
                    localStorage.setItem("user_type_id", 1);
                    localStorage.setItem("user_type", "Student");
                    if(res?.data?.data?.profile_complete){
                        if(params.get('url')){
                        history.push(`/${params.get('url')}`);
                        }
                    }
                    else{
                        if(params.get('url')){
                        history.push(`/candidate/basic-details?url=${params.get('url')}`);
                        }else{
                            history.push(`/candidate/basic-details`);
                        }
                    }
                }
                else{
                    alert.error("Session Expired. Login Again");
                    history.push("/login/student");
                }
          }).catch(err => {
            alert.error("Session Expired. Login Again");
            history.push("/login/student");
          })
    }, [])

    return (
        <CircularProgress size={60}
        style={{ margin: "auto", marginTop: 100, display: "flex" }}/>
    )

}
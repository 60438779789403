import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import app_store from "../../../../assets/apple-min.png";
import check from "../../../../assets/checked-new.png";
import play_store from "../../../../assets/goolge-min.png";
import app_download from "../../../../assets/new/qr-code.png";
import collegeLogin from "../../../../assets/svg/collegeLogin.svg";
import { ReactComponent as CollegeLogin } from "../../../../assets/svg/collegeLogin_changable.svg";
import LogImage2 from "../../../../assets/svg/companyLogin.svg";
import company_signup from "../../../../assets/svg/companySignup.svg";
import { ReactComponent as Passwords } from "../../../../assets/svg/ForgotPassword.svg";
import LogImage from "../../../../assets/svg/login/Resume-rafiki.svg";
import student_signup from "../../../../assets/svg/studentSignup.svg";
import { ReactComponent as StudentSignup } from "../../../../assets/svg/studentSignup_changable.svg";
import userVerify from "../../../../assets/svg/userVerification.svg";
import emailVerify from "../../../../assets/svg/verifyemail.svg";
import LoginGrid from "../Card/LoginGrid";
import LoginGridDomain from "../Card/LoginGridDomain";
import {instituteIdSelectorFn} from '../../../../selector';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffff",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    marginTop: "50px",
  },
  img: {
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "flex-end",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    marginTop: "50px",
    justifyContent: "center",
  },
  playstore: {
    borderRadius: "10px",
  },
}));

export default function CommonLeftComponent({ type, registerForm, showGrid }) {
  const classes = useStyles();
  const location = useLocation();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  const instituteId = useSelector(instituteIdSelectorFn);

  return (
    <>

      {type === "Student" && (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" || location.pathname === "/login/student") && (
        <>
          <Typography
            style={{
              fontSize: "40px",
              fontFamily: " Nunito",
              fontStyle: "normal",
              marginBottom: 20,
            }}
          >
            A Free Ride to your Dream career
          </Typography>

          {!registerForm && showGrid && <> {domain?.domain ? <LoginGridDomain type={"Company"} /> : <LoginGrid type={type} />}</>}
          <br />
          {!instituteId &&
            <Grid container spacing={3} style={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={5}>
                <img src={"https://s3.ap-south-1.amazonaws.com/getwork-store/prod/common/APP"} height="350px" width="180px" alt="banner" style={{ marginLeft: 15 }} />
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle2" style={{ fontFamily: " Nunito", color: "#6c757d", fontSize: 17, marginBottom: 10 }}>
                  <b> {location.pathname === "/signup/student" ? "Register and Apply to 10000+ opportunities !" : "The “Adda” of jobs is now on Play Store and App Store"}</b>
                </Typography>
                {/* <Typography variant="subtitle2" style={{ fontFamily: " Nunito", color: "#6c757d", fontSize: 13 }}>
                  <b>Take the job search with you. Apply to unlimited jobs & internships from anywhere. Download the GetWork App Now! </b>
                </Typography>
                <br /> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                    {" "}
                    <img src={check} height="20px" width="20px" />
                    <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                      Get New Job Alerts
                    </Typography>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                    {" "}
                    <img src={check} height="20px" width="20px" />
                    <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                      Create your Resume & Share{" "}
                    </Typography>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img src={check} height="20px" width="20px" />
                    <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                      Manage your Job Applications{" "}
                    </Typography>
                  </div>
                </div>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <img id="footerBtn" src={app_download} alt="playstore" height="120px" />
                  </Grid>
                  <Grid item xs={7} style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a href="https://play.google.com/store/apps/details?id=com.getwork_app" target="_blank" style={{ marginBottom: 10 }}>
                        <img id="footerBtn" src={play_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                      </a>

                      <a href="https://apps.apple.com/in/app/getwork/id1579830388" target="_blank">
                        <img id="footerBtn" src={app_store} height="50px" width="160px" alt="playstore" className={classes.playstore} />
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          <br />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          ></div>
        </>
      )}

      {type === "Student" && (location.pathname === "/signup/student" || location.pathname === "/signup") && (
        <>
          <Typography
            style={{
              fontSize: "40px",
              fontFamily: " Nunito",
              fontStyle: "normal",
            }}
          >
            A Free Ride to your Dream career
          </Typography>

          {!registerForm && showGrid && <LoginGrid type={type} />}

          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            {location.pathname === "/signup/student" ? "Register and Apply to 10000+ opportunities !" : "Sign In and Apply to 10000+ opportunities !"}
          </Typography>
          <br />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {" "}
              <img src={check} height="20px" width="20px" />
              <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                Interships
              </Typography>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {" "}
              <img src={check} height="20px" width="20px" />
              <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                Freshers Jobs
              </Typography>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={check} height="20px" width="20px" />
              <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                Work from home jobs
              </Typography>
            </div>
          </div>
          {domain?.domain ? (
            <StudentSignup height="300px" width="300px" fill={domain?.color_code} />
          ) : (
            <img src={location.pathname === "/signup/student" || location.pathname === "/candidate/signup" ? student_signup : LogImage} height="300px" width="300px" alt="banner" />
          )}
          <br />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          ></div>
        </>
      )}
      {type === "College" && location.pathname === "/login/college" && (
        <>
          <Typography variant="h2" style={{ fontFamily: " Nunito", fontSize: 40, marginBottom: 20 }}>
            Manage your Placement Drives{" "}
          </Typography>
          {!registerForm && showGrid && <> {domain?.domain ? <LoginGridDomain type={"Company"} /> : <LoginGrid type={type} />}</>}
          <br />
          <Grid container spacing={3} style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={6}>
              {" "}
              {domain?.domain ? (
                <CollegeLogin height="300px" width="300px" fill={domain?.color_code} style={{ marginTop: -20 }} />
              ) : (
                <img src={collegeLogin} height="300px" width="300px" alt="banner" style={{ marginTop: -20 }} />
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" style={{ fontFamily: " Nunito", color: "#6c757d", fontSize: 16 }}>
                <b> See up to 5x increase in the number of reputable job opportunities. </b>
              </Typography>
              <br />
              <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
                Trusted by 1000+ Companies{" "}
              </Typography>
            </Grid>
          </Grid>

          <br />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          ></div>
          <br />
        </>
      )}

      {type === "College" && location.pathname === "/signup/college" && (
        <>
          <Typography variant="h2" style={{ fontFamily: " Nunito", fontSize: 40, marginBottom: 20 }}>
            Manage your Placement Drives{" "}
          </Typography>

          <Typography variant="subtitle2" style={{ fontFamily: " Nunito", color: "#6c757d", fontSize: 16 }}>
            <b> See up to 5x increase in the number of reputable job opportunities. </b>
          </Typography>
          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            Trusted by 1000+ Companies{" "}
          </Typography>
          <br />
          <br />
          <img src={collegeLogin} height="400px" width="400px" alt="banner" style={{ marginTop: -20 }} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          ></div>
          <br />
        </>
      )}

      {type === "Company" && (location.pathname === "/signup/company" || location.pathname === "/signup/employer") && (
        <>
          <Typography variant="h2" style={{ fontFamily: " Nunito", marginBottom: 20, fontSize: 40 }}>
            Hire the best!
          </Typography>

          {!registerForm && showGrid && <LoginGrid type={type} />}
          <br />
          <br />
          <Grid container spacing={3} style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={6}>
              <img
                src={location.pathname === "/signup/company" || location.pathname === "/signup/employer" ? company_signup : LogImage2}
                height="450px"
                width="450px"
                alt="banner"
                style={location.pathname === "/signup/employer" || location.pathname === "/signup/employer" ? { marginTop: -100, marginLeft: -100 } : {}}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Typography variant="subtitle2" style={{ fontFamily: " Nunito", color: "#6c757d", fontSize: 16 }}>
                <b>{location.pathname === "/signup/company" || location.pathname === "/signup/employer" ? "Register and post your Job !" : "Sign In and post your Job !"}</b>
              </Typography>
              <br />
              <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
                1 Lac+ job seekers from 18,000+ colleges across India
              </Typography>
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <br />
          </div>
        </>
      )}

      {type === "Company" && (location.pathname === "/signup/company" || location.pathname === "/signup/employer") && (
        <>
          <Typography variant="h2" style={{ fontFamily: " Nunito", marginBottom: 10, fontSize: 40 }}>
            Hire the best!
          </Typography>

          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            {location.pathname === "/signup/company" || location.pathname === "/signup/employer" ? "Register and post your Job !" : "Sign In and post your Job !"}
          </Typography>

          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            1 Lac+ job seekers from 18,000+ colleges across India
          </Typography>

          <br />
          <img
            src={location.pathname === "/signup/company" || location.pathname === "/signup/employer" ? company_signup : LogImage2}
            height="300px"
            width="300px"
            alt="banner"
            style={location.pathname === "/signup/employer" || location.pathname === "/signup/employer" ? { marginTop: -60 } : {}}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <br />
          </div>
        </>
      )}

      {(type === "forgot-password" || type === "reset-password") && (
        <>
          <Typography variant="h2" style={{ fontFamily: " Nunito" }}>
            Forgot Password?
          </Typography>
          <br />
          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            We’ll help you reset it and get back on track.{" "}
          </Typography>
          <br />
          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            If there's a password needed at the gates of heaven, only Latin will unlock it, he thinks.{" "}
          </Typography>
          <br />
          {/* <img src={passwords} height="300px" width="300px" alt="banner" /> */}
          <Passwords height="300px" width="300px" fill={domain?.domain ? domain?.color_code : "#007bff"} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <br />
          </div>
        </>
      )}

      {(type === "verify-email" || type === "verified-email") && (
        <>
          <Typography variant="h2" style={{ fontFamily: " Nunito" }}>
            Verify your Email
          </Typography>
          <br />
          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            Please verify your email and get updated with getwork.
          </Typography>
          <br />
          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            The major advances in speed of communication and ability to interact took place more than a century ago. The shift from sailing ships to telegraph was far more radical
            than that from telephone to email!{" "}
          </Typography>
          <br />
          <img src={emailVerify} height="450px" width="450px" alt="banner" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <br />
          </div>
        </>
      )}

      {type === "verify-user" && (
        <>
          <Typography variant="h2" style={{ fontFamily: " Nunito" }}>
            Are you an employer?
          </Typography>
          <br />
          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            Wait for your Company's response for your approval{" "}
          </Typography>
          {/* <br />
          <Typography variant="subtitle2" style={{ fontFamily: " Nunito" }}>
            10000+ internships seekers from 30,000+ colleges across India
          </Typography> */}
          <br />
          <img src={userVerify} height="450px" width="450px" alt="banner" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <br />
          </div>
        </>
      )}
    </>
  );
}

import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import React from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import error from "../../assets/svg/Error.svg";
import "./drop.css";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "20px",
    minHeight: "500px",
    maxWidth: "500px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "30px",
    margin: theme.spacing(2),
    boxShadow: "none",
    fontSize: "16px",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
}));

const TrackSharerExpiredPopUp = ({
  fromOverview = false,
  setShowTempPlanPopUp = () => {},
  tempPlanPopUp = false,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    
  };
  
  return (
    <>
      {show && (
        <div>
          <Dialog
            PaperProps={{ classes: { root: classes.root1 } }}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <div
              style={{
                padding: "40px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 auto",
                width: "450px",
                textAlign: "center",
              }}
            >
              <img
                src={error}
                height="300px"
                style={{ marginBottom: "5px" }}
              />
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
              This link is expired.<br /> 
              </Typography>
              <Typography variant="h6" style={{ marginBottom: "10px", fontWeight: "400" }}>
              Please connect with us/your KAM to get new link. Contact us on:
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "20px",
                    color: "#007BFF",
                    fontSize: "1rem",
                  }}
                >
                  <span
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MailOutlineOutlinedIcon fontSize={"inherit"} />
                  </span>
                  <a
                    href="mailto:support@getwork.org"
                    style={{
                      textDecoration: "none",
                      color: "#007BFF",
                      fontSize: "14px",
                    }}
                  >
                    support@getwork.org
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "20px",
                    color: "#007BFF",
                    fontSize: "0.9rem",
                  }}
                >
                  <span
                    style={{
                      marginRight: "3px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PhoneOutlinedIcon fontSize={"inherit"} />
                  </span>
                  <a
                    href="tel:+91-8595351311"
                    style={{
                      textDecoration: "none",
                      color: "#007BFF",
                      fontSize: "14px",
                    }}
                  >
                    +91 8595351311
                  </a>
                </div>
                  </div>
            </div>
            

          </Dialog>
        </div>
      )}
    </>
  );
};

export default TrackSharerExpiredPopUp;

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import React from "react";
import { useAlert } from "react-alert";
// import purchasePlan from "../../assets/png/purchasePlan.png";
import PostJob from "../../../assets/credit-unlock.png";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import "./drop.css";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "20px",
    minHeight: "500px",
    maxWidth: "500px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "30px",
    margin: theme.spacing(2),
    boxShadow: "none",
    fontSize: "16px",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
}));

const NewSignUpDialog = ({
  fromOverview = false,
  setShowTempPlanPopUp = () => {},
  tempPlanPopUp = false,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (tempPlanPopUp) setShowTempPlanPopUp(false);
    else {
      setOpen(false);
    }
  };

  return (
    <>
      {show && (
        <div>
          <Dialog
            PaperProps={{ classes: { root: classes.root1 } }}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            {fromOverview ? (
              <Button
                style={{
                  position: "absolute",
                  color: "gray",
                  backgroundColor: "inherit",
                  top: "5%",
                  right: "2px",
                }}
                onClick={() => {
                  sessionStorage.setItem("plan", "false");
                  setShow(false);
                }}
              >
                X
              </Button>
            ) : (
              <Button
                style={{
                  position: "absolute",
                  color: "gray",
                  backgroundColor: "inherit",
                  top: "5%",
                  right: "2px",
                }}
                onClick={() => {
                  /* sessionStorage.setItem("plan", "false"); */
                  /* setOpen(false); */
                  if (tempPlanPopUp) setShowTempPlanPopUp(false);
                  else {
                    setOpen(false);
                  }
                }}
              >
                X
              </Button>
            )}
            <div
              style={{
                padding: "40px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 auto",
                width: "450px",
                textAlign: "center",
              }}
            >
              <img
                src={PostJob}
                height="300px"
                style={{ marginBottom: "5px" }}
              />
              <Typography variant="h5" style={{ marginBottom: "10px" }}>
              GetWork Welcomes You !
              </Typography>
              <Typography variant="body2" style={{ color: "#6C757D" }}>
              Congratulations ! Your account has been activated with GetWork Credits and Profile Unlocks. Post Job and enjoy hassle free recruitment services by GetWork.
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <CommonButton onClick={(e) =>{
                    history.push("/company/post-job")
                }}>
                    Post Job
                </CommonButton>
            </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default NewSignUpDialog;

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideImage from "../../../common/components/UI/SideImage";
import AuthHeader from "../../../common/components/UI/AuthHeader";
import Navbar from "../../../../components/public/Navbar/Navbar";

const AdminApprove = () => {
  const [company, setCompany] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (parseInt(location.flag) === 19087) {
      setCompany(location.company);
      setAdminName(location.admin_name);
      setAdminEmail(location.admin_email);
      setIsAdmin(location.is_admin);
    } else {
      setCompany(JSON.parse(localStorage.getItem("company")).company_name);
      setAdminName(JSON.parse(localStorage.getItem("company")).admin_name);
      setAdminEmail(JSON.parse(localStorage.getItem("company")).admin_email);
      setIsAdmin(JSON.parse(localStorage.getItem("company")).admin_approved);
    }

    localStorage.setItem("show_home_menu", false);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div className="col-md-8 col-lg-8">
            <div className="row">
              {/* <AuthHeader /> */}
              <Navbar />
            </div>
            <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2" style={{ marginTop: 110 }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-5 mx-auto">
                    <div className="row justify-content-center mx-auto my-2">
                      <i className="fas fa-user-shield fa-3x"></i>
                    </div>

                    <div className="row justify-content-center mx-auto my-10">
                      {!isAdmin ? (
                        <>
                          <p className="fs-18 fw-500">Awaiting admin approval</p>
                          <p className="fs-14">
                            Your request to join <span className="color-blue fw-500">{company}</span> has been sent to the Admin,
                            <br />{" "}
                            <span className="color-blue fw-500">
                              {adminName} : {adminEmail}
                            </span>
                            . We'll notify you when you get approved via email.
                          </p>
                          <p className="fs-14">
                            Facing issues? Get in touch with us{" "}
                            <a href="mailto:team@getwork.org" className="link-text fw-500">
                              {" "}
                              here
                            </a>
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="fs-18 fw-500">Your Account Not Verified</p>
                          <p className="fs-14">
                            Your account is not verified for <span className="color-blue fw-500">{company}</span>. Please contact our helpline e-mail, support@getwork.org or call
                            us on 94275 21611.
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminApprove;

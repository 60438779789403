import React from 'react';

import MentorshipComponent from './MentorshipComponent';
import Layout from "../Layout/Layout";

export default function Mentorship () {
    return (
      <>
    <Layout>
    <MentorshipComponent />
    </Layout>
    </>
    )
}
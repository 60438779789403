import React from "react";
import { Paper, makeStyles, Typography, Divider } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
  },
  job: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
}));

function Expereince({ data }) {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">Work Expereince</Typography>
        <br />
        {data?.student_user_details?.work_ex?.map((item) => (
          <>
            <div className={classes.job}>
              <Typography variant="body1">
                {item?.job_designation} {item?.company_name && " at " + item?.company_name}
              </Typography>
              <Typography variant="body2"></Typography>
            </div>
            <Typography variant="subtitle2" style={{ color: "#007bff", marginBottom: "5px" }}>
              <span style={{ color: "#212121" }}>
                {item?.start_date && item?.start_date !== null && (
                  <>
                    {moment(item?.start_date).format("MMM, YYYY")}{" "}
                    {item.end_date && item.end_date !== null ? (
                      <>
                        {" - "}
                        {moment(item?.end_date).format("MMM, YYYY")}{" "}
                      </>
                    ) : (
                      <>- Present</>
                    )}
                  </>
                )}{" "}
              </span>

              {item?.job_type_name && "(" + item?.job_type_name + ")"}
            </Typography>
            <Typography variant="subtitle2" style={{ color: "#6c757d", marginBottom: 10 }}>
              {item?.job_description}
            </Typography>

            {/* <Divider style={{ marginBottom: 10, marginTop: 10 }} /> */}
            <br />
          </>
        ))}
      </Paper>
    </>
  );
}

export default Expereince;

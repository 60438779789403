import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import popup from "../../../assets/pop-up.png";
import popup2 from "../../../assets/new-pop-up.jpg";

import { Hidden } from "@material-ui/core";
import setNewCookie, { getCookie } from "../../../utils/common/Cookies";
export default function AppDownloadDialog({ open, handleClickOpen, handleClose }) {
  useEffect(() => {
    if (!getCookie("open-modal-app")) setNewCookie("open-modal-app", true, 0.5);
  });
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent style={{ padding: 0 }}>
          <Hidden smDown>
            <a href="https://bit.ly/3uuJj54" style={{textDecoration:'none'}}><img src={popup2} height="550px" /></a>

          </Hidden>
          <Hidden smUp>
            <a href="https://bit.ly/3uuJj54" style={{textDecoration:'none'}}><img src={popup2} height="300px" /></a>
          </Hidden>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { Fragment, useEffect, useState } from "react";

export default function generateCsv(arr, name) {
  let str = "";
  arr.forEach((item, index) => (arr.indexOf(item) !== arr.length - 1 ? (str += item[name] + ", ") : (str += item[name])));
  return str;
}

export function generateCsvNew(arr) {
  let str = "";
  arr.forEach((item, index) => (arr.indexOf(item) !== arr.length - 1 ? (str += item + ",") : (str += item)));
  return str;
}

export function generateCsvCommas(arr) {
  let str = "";
  arr.forEach((item, index) => (arr.indexOf(item) !== arr.length - 1 ? (str += item + ", ") : (str += item)));
  return str;
}

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import {
  Grid,
  Chip,
  Typography,
  Paper,
  InputBase,
  RadioGroup,
  FormControlLabel,
  FormControl,
  IconButton,
  Radio,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AutoFillEmail from "../../Common/AutoFillEmail/index";
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
// import "./time.css";

import TagFacesIcon from "@material-ui/icons/TagFaces";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import pSBC from "shade-blend-color";
import { min } from "date-fns";
import moment from "moment";
import { useAlert } from "react-alert";
// import { emailRegex } from "../../../utility/regex";
// import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
// import { collegeDetailSelectorFn } from "../../../api/SignIn/selector";
import { useDispatch, useSelector } from "react-redux";
// import { createInterview } from "../../../api/Assignment/action";
import { useHistory, useLocation } from "react-router-dom";
import Editor from "../../Common/Editor";
import Calendar from "../../Common/Calendar/Calednar";
// import TimePicker from "../../Common/TimePicker.js/TimePicker";
import { emailRegex } from "../../../utility/regex";
import { BackendBaseApi } from "../../../../constants/constants";
// import  TimePicker  from "@syncfusion/ej2-react-calendars";
import fetchApi from "../../../apiCall/fetchApi";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "30px",
    height: "40px",
    boxShadow: "none",
    width: "100%",
    display: "flex",
    fontWeight: "500",
  },
  btn: {
    borderRadius: "30px",
    // height: "100%",
    padding: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginRight: "10px",
    boxShadow: "none",
    //width: "100%",
    display: "flex",
    fontWeight: "500",
  },

  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "30px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "20px",
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    // fontSize: "14px",
    transform: "translate(15px, 14px) scale(1)",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  move: {
    boxShadow: "none",
    borderRadius: "30px",
    height: "40px",
    paddingLeft: "20px",
    //marginLeft: "10px",
    paddingRight: "20px",
    marginRight: "25px",
    width: "100%",
    fontWeight: "500",
    display: "flex",
  },
  labellink: {
    color: theme.palette.primary.main,
  },

  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    borderColor: theme.palette.primary.main,
    border: "1px solid",
  },
  chipview: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    //  padding: theme.spacing(0.5),
    margin: 0,
  },
  chipviewemail: {
    display: "flex",
    // marginTop: "20px",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    //  padding: theme.spacing(0.5),
    margin: 0,
  },
  view: {
    borderRadius: "30px",
    marginRight: "10px",
    boxShadow: "none",
    padding: "10px",
    height: "48px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
    justifyContent: "flex-start",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  time: {
    marginTop: "0px",
    marginBottom: "10px",
  },
  chipicon: {
    color: theme.palette.primary.main,
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      /// className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function InterviewScheduling({
  selectedRow,
  setSelectedRow,
  selectedJob,
  activeStatusObject,
  fromStudentDetail,
}) {
  const classes = useStyles();
  const initialInterviewDetailsState = {
    title: activeStatusObject?.tooltip,
    start_time: null,
    end_time: null,
    date: null,
    email_list: [],
    type: "ONLINE",
    address: null,
    link: "Auto generate",
    slot_interval: "",
    description: "",
    custom_link: null,
  };
  const [interviewDetails, setInterviewDetails] = useState(
    initialInterviewDetailsState
  );
  useEffect(() => {
    if (activeStatusObject && activeStatusObject?.tooltip) {
      setInterviewDetails({
        ...interviewDetails,
        title: activeStatusObject?.tooltip,
      });
    }
  }, [activeStatusObject]);

  const [loading, setLoading] = useState(false);
  const initialInterviewDetailsError = {
    title: null,
    start_time: null,
    end_time: null,
    date: null,
    email_list: null,
    type: "Online",
    address: null,
    link: "Auto generate",
    slot_interval: null,
    description: null,
    custom_link: null,
  };
  const [interviewDetailsError, setInterviewDetailsError] = useState(
    initialInterviewDetailsError
  );
  const alert = useAlert();
  const [value, onChange] = useState(new Date());
  const [checked, setChecked] = React.useState(true);
  const [email, setEmail] = useState();
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [interviewDate, setInterviewDate] = useState(null);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (selectedRow?.selectedIds?.length > 0) {
      var arr = [];
      var tempArr = [...selectedRow?.details];
      tempArr.forEach((item, index) => {
        arr.push({
          student_id: item.id,
          slot_number: index + 1,
          name: item.name,
          user_id: item.user_id,
        });
      });
      setSelectedStudent(arr);
    }
  }, [selectedRow]);
  const [editor, setEditor] = useState();

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [emailList, setEmailList] = useState([]);
  // const collegeDetails = useSelector(collegeDetailSelectorFn);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInterviewDetails(initialInterviewDetailsState);
    setInterviewDetailsError(initialInterviewDetailsError);
    setInterviewDate(null);
    setSelectedRow({
      details: [],
      selectedIds: [],
    });
    setEditor();
    setOpen(false);
  };

  // const handleDelete = (chipToDelete) => () => {
  //   setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  // };

  const handleDelete = (index) => {
    if (index !== -1) {
      var arr = [...interviewDetails?.email_list];
      arr.splice(index, 1);
      setInterviewDetails({ ...interviewDetails, email_list: arr });
    }
  };

  const handleDeleteChip = (id, index) => {
    var arr = [...selectedRow?.details];
    if (arr?.length > 1) {
      if (index !== -1) {
        arr.splice(index, 1);
        setSelectedRow({
          ...selectedRow,
          details: arr,
          selectedIds: arr.map((item) => item.id),
        });
      }
    } else alert.error("You can't remove this applicant");
  };

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Rohan Singh | A103193131" },
    { key: 1, label: "Rohan Singh | A103193131" },
    { key: 2, label: "Rohan Singh | A103193131" },
    { key: 3, label: "Rohan Singh | A103193131" },
  ]);

  useEffect(() => {
    calculateEndTime();
  }, [interviewDetails.start_time, interviewDetails.slot_interval]);

  useEffect(() => {
    perObjectTime();
  }, [interviewDetails.start_time, interviewDetails.end_time]);

  const perObjectTime = () => {
    let endTime = calculateTime(
      interviewDetails.start_time,
      interviewDetails.slot_interval,
      1
    );
    selectedStudent.forEach((item, index) => {
      item.start_date = String(
        value.getFullYear() +
          "-" +
          (value.getMonth() + 1) +
          "-" +
          value.getDate() +
          " " +
          calculateTime(
            interviewDetails.start_time,
            interviewDetails.slot_interval,
            index
          )
      );
      item.end_date = String(
        value.getFullYear() +
          "-" +
          (value.getMonth() + 1) +
          "-" +
          value.getDate() +
          " " +
          calculateTime(endTime, interviewDetails.slot_interval, index)
      );
      item.slot_number = index + 1;
    });
  };

  const calculateTime = (start, min, i) => {
    min = min * i;
    var start_time = String(start + ":00");
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }

    var piece = start_time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +min;
    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  };

  const calculateEndTime = () => {
    var len = selectedRow?.selectedIds?.length;
    var min_fixed;

    var min = len * interviewDetails.slot_interval;

    var start_time = String(interviewDetails.start_time + ":00");
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }

    var piece = start_time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +min;

    setInterviewDetails({
      ...interviewDetails,
      end_time: D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60),
    });
  };

  const validateData = () => {
    var title_error = null;
    var date_error = null;
    var start_time_error = null;
    var email_error = null;
    var address_error = null;
    var custom_link_error = null;
    var slot_int_error = null;
    var is_valid = true;

    if (!interviewDetails?.title) {
      title_error = "Please Enter Title";
      is_valid = false;
    }
    if (!interviewDetails?.date) {
      date_error = "Please Enter Date";
      is_valid = false;
    }
    // if (!interviewDetails?.start_time) {
    //   start_time_error = "Please Enter start time";
    //   is_valid = false;
    // }
    if (interviewDetails?.email_list?.length === 0) {
      email_error = "Please enter atleast one email";
      is_valid = false;
    }
    if (interviewDetails?.type === "OFFLINE" && !interviewDetails?.address) {
      address_error = "Please enter your address";
      is_valid = false;
    }
    if (
      interviewDetails?.type === "ONLINE" &&
      interviewDetails?.link !== "Auto generate" &&
      !interviewDetails?.custom_link
    ) {
      custom_link_error = "Please enter custom link";
      is_valid = false;
    }
    if (!interviewDetails?.slot_interval) {
      slot_int_error = "Please enter Slot Duration";
      is_valid = false;
    }

    setInterviewDetailsError({
      ...interviewDetailsError,
      title: title_error,
      start_time: start_time_error,
      date: date_error,
      email_list: email_error,
      address: address_error,
      slot_interval: slot_int_error,
      custom_link: custom_link_error,
    });
    return is_valid;
  };
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = () => {
    if (validateData()) {
      var data = {
        job_id: selectedJob?.job_id,
        company_id: JSON.parse(localStorage.getItem("company")).company,
        start_date: `${interviewDetails?.date} ${interviewDetails?.start_time}`,
        end_date: `${moment(
          `${interviewDetails?.date} ${interviewDetails?.start_time}`
        )
          .add(
            interviewDetails?.slot_interval * selectedStudent.length,
            "minutes"
          )
          .format("YYYY-MM-DD HH:mm")}`,
        user_id: parseInt(localStorage.getItem("user_id")),
        type: interviewDetails?.type.toUpperCase(),
        address: interviewDetails?.address,
        student_detail: selectedStudent,
        round: activeStatusObject?.round_id,
        description: editor,
        custom_email: interviewDetails?.email_list,
        title: interviewDetails?.title,
        time_slot: interviewDetails?.slot_interval,
        total_slots: selectedStudent?.length,
        location: null,
      };

      if (interviewDetails?.link !== "Auto generated") {
        data.custom_link = interviewDetails?.custom_link;
      }

      fetchApi({
        url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/interview/`,
        method: "post",
        alert: alert,
        body: JSON.stringify(data),
        setLoading: setLoading,
        callBackFn: handleClose,
      });
    }
  };
  const [htmlDesc, setHtmlDesc] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (
        selectedStudent.length >= 1 &&
        interviewDetails.end_time &&
        interviewDetails.slot_interval &&
        interviewDetails.start_time
      ) {
        var des = selectedStudent.map((item) => {
          return `<p><b>${item.name} ${moment(item.start_date).format(
            "LT"
          )} - ${moment(item.end_date).format("LT")}${"\n"}</p>`;
        });
      }
      console.log("flag_inside", des, selectedStudent);
      if (des?.length >= 1) {
        setHtmlDesc(String(des));

        // setInterviewDetails({
        //   ...interviewDetails,
        //   end_time: moment(`${interviewDetails?.end_time} ${interviewDetails?.start_time}`)
        //     .add(interviewDetails?.slot_interval * 5, "minutes")
        //     .format("hh:mm:ss"),
        // });
      }
    }, 2000);
  }, [
    selectedStudent,
    interviewDetails.end_time,
    interviewDetails.slot_interval,
    interviewDetails.start_time,
  ]);

  useEffect(() => {
    setInterviewDetails({
      ...interviewDetails,
      date: moment(interviewDate).format("YYYY-MM-DD"),
    });
    setInterviewDetailsError({
      ...interviewDetailsError,
      date: "",
    });
  }, [interviewDate]);

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        disabled={
          selectedRow?.selectedIds?.length === 0 ||
          activeStatusObject?.status_id !== 7
        }
        onClick={handleClickOpen}
        className={classes.move}
      >
        {fromStudentDetail ? "Interview" : "Schedule Interview"}
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <div style={{ padding: "40px" }}>
            {/* <Typography variant="h6">Interview Scheduling</Typography> */}

            <TextField
              variant="outlined"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              fullWidth
              label="Add Title"
              value={interviewDetails?.title}
              onChange={(e) => {
                setInterviewDetails({
                  ...interviewDetails,
                  title: e.target.value,
                });
                setInterviewDetailsError({
                  ...interviewDetailsError,
                  title: "",
                });
              }}
              error={interviewDetailsError?.title ? true : false}
              helperText={<span>{interviewDetailsError?.title}</span>}
            />
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {" "}
              <Calendar
                selectedDate={interviewDate}
                setSelectedDate={setInterviewDate}
                title="Date"
                // onDateChange={(date) => {
                //   console.log("wsedr", date)
                //   setInterviewDetails({
                //     ...interviewDetails,
                //     date: moment(date).format("YYYY-MM-DD"),
                //   });
                //   setInterviewDetailsError({
                //     ...interviewDetailsError,
                //     date: "",
                //   });
                // }}
                errorMessage={interviewDetailsError?.date}
                minDate={new Date()}
              />
              <div
                style={{ width: "170px", display: "grid", marginLeft: "20px" }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <TimePickerComponent
                    style={{ border: "none", borderBottom: "1px solid #fff" }}
                    placeholder="Start time"
                    value={interviewDetails?.start_time}
                    format="HH:mm"
                    onChange={(e) => {
                      setInterviewDetails({
                        ...interviewDetails,
                        start_time: moment(e.target.value).format("HH:mm"),
                      });
                    }}
                  /> */}
                  <TextField
                    id="outlined-name"
                    label="Start Time"
                    value={interviewDetails?.start_time}
                    onChange={(e) => {
                      setInterviewDetails({
                        ...interviewDetails,
                        start_time: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    type="time"
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div component="ul" className={classes.chipview}>
              {!showMore
                ? selectedRow?.details?.slice(0, 5)?.map((data, index) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          label={data.name}
                          size="small"
                          onDelete={() => handleDeleteChip(data.id, index)}
                          deleteIcon={
                            <CloseIcon
                              className={classes.chipicon}
                              color="primary"
                            />
                          }
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : selectedRow?.details?.map((data, index) => {
                    return (
                      <li key={data.id}>
                        <Chip
                          label={data.name}
                          size="small"
                          onDelete={() => handleDeleteChip(data.id, index)}
                          deleteIcon={
                            <CloseIcon
                              className={classes.chipicon}
                              color="primary"
                            />
                          }
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
              <br />
              {selectedRow?.details?.length > 5 && (
                <Button
                  className={classes.button}
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                  style={{
                    padding: "5px",
                    marginLeft: "7px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  color="primary"
                >
                  {showMore ? "Hide" : "View All"}
                </Button>
              )}{" "}
            </div>

            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <Typography variant="h6">Invite Interviewer(s)</Typography>
              </Grid>
              <Grid item xs={10} className={classes.grid}>
                <div className={classes.paper}>
                  <AutoFillEmail
                    value={email}
                    title=""
                    onchange={(event) => {
                      setEmail(event.target.value);
                    }}
                    error={interviewDetailsError?.email_list}
                  />{" "}
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  onClick={() => {
                    if (emailRegex.test(email)) {
                      var arr = [...interviewDetails.email_list];
                      arr.push(email);
                      setInterviewDetails({
                        ...interviewDetails,
                        email_list: arr,
                      });
                      setEmail("");
                    } else {
                      alert.error("Invalid Email");
                    }
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={12}>
                <div component="ul" className={classes.chipviewemail}>
                  {interviewDetails?.email_list?.length > 0 &&
                    interviewDetails?.email_list?.map((item, index) => (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(index)}
                        deleteIcon={<CloseIcon className={classes.chipicon} />}
                        label={item}
                        className={classes.chip}
                      />
                    ))}
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "-10px" }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue="Admin"
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    }}
                    name="customized-radios"
                    value={interviewDetails?.type}
                    onChange={(e) => {
                      setInterviewDetails({
                        ...interviewDetails,
                        type: e.target.value,
                      });
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginRight: "10px" }}
                      variant="body2"
                    >
                      Interview Type
                    </Typography>
                    <FormControlLabel
                      value="ONLINE"
                      control={<StyledRadio />}
                      label={<Typography variant="body2">Online</Typography>}
                    />
                    <FormControlLabel
                      value="OFFLINE"
                      control={<StyledRadio />}
                      label={<Typography variant="body2">Offline</Typography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {interviewDetails?.type && interviewDetails?.type === "OFFLINE" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      fullWidth
                      label="Add your venue"
                      value={interviewDetails.address}
                      onChange={(event) => {
                        setInterviewDetails({
                          ...interviewDetails,
                          address: event.target.value,
                        });
                        setInterviewDetailsError({
                          ...interviewDetailsError,
                          address: "",
                        });
                      }}
                      error={interviewDetailsError?.address ? true : false}
                      helperText={interviewDetailsError?.address}
                    />
                  </Grid>
                </>
              )}
              {interviewDetails?.type && interviewDetails?.type === "ONLINE" && (
                <>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        defaultValue="Admin"
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                        }}
                        name="customized-radios"
                        value={interviewDetails?.link}
                        onChange={(e) => {
                          setInterviewDetails({
                            ...interviewDetails,
                            link: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="Auto generate"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">
                              Auto Link Generate
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="Enter link"
                          control={<StyledRadio />}
                          label={
                            <Typography variant="body2">
                              Enter Your Link
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className={classes.grid}>
                    <div className={classes.paper}>
                      {interviewDetails?.link === "Auto generate" ? (
                        <Typography variant="body2">
                          Interview link has been generated, you can see the
                          link in your mail after Scheduling your Interview.
                        </Typography>
                      ) : (
                        <>
                          <TextField
                            variant="outlined"
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                            fullWidth
                            label="Add Meet Link"
                            value={interviewDetails.custom_link}
                            onChange={(event) => {
                              setInterviewDetails({
                                ...interviewDetails,
                                custom_link: event.target.value,
                              });
                              setInterviewDetailsError({
                                ...interviewDetailsError,
                                custom_link: "",
                              });
                            }}
                            error={
                              interviewDetailsError?.custom_link ? true : false
                            }
                            helperText={interviewDetailsError?.custom_link}
                          />
                        </>
                      )}
                      {/* <Paper component="form" className={classes.link}>
                        <InputBase
                          className={classes.input}
                          placeholder="https://getwork-ejd-mba.in"
                          inputProps={{
                            className: classes.labellink,
                            "aria-label": "https://getwork-ejd-mba.in",
                          }}
                        />
                        <IconButton type="copy" className={classes.iconButton} aria-label="copy">
                          <FileCopyOutlinedIcon color="primary" />
                        </IconButton>
                      </Paper> */}
                    </div>
                  </Grid>{" "}
                </>
              )}
            </Grid>

            <div
              style={{
                display: "flex",
                marginTop: "30px",
                flexDirection: "column",
              }}
            >
              <form className={classes.time}>
                <TextField
                  id="time"
                  label="Interview Slot Duration (in mint) "
                  // type="time"
                  variant="outlined"
                  style={{ width: "300px" }}
                  //  defaultValue="07:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: min, // 5 min
                  }}
                  value={interviewDetails?.slot_interval}
                  onChange={(e) => {
                    setInterviewDetails({
                      ...interviewDetails,
                      slot_interval: e.target.value,
                    });
                    setInterviewDetailsError({
                      ...interviewDetailsError,
                      slot_interval: "",
                    });
                  }}
                  error={interviewDetailsError?.slot_interval ? true : false}
                  helperText={interviewDetailsError?.slot_interval}
                />
              </form>

              {/* <Location /> */}
              <br />
              {/* <TextField
                id="outlined-multiline-static"
                label="Add Description"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                multiline
                style={{ width: "100%" }}
                rows={6}
                variant="outlined"
                value={interviewDetails?.description}
                onChange={(e) => {
                  setInterviewDetails({ ...interviewDetails, description: e.target.value });
                }}
              /> */}
              <Editor state={editor} setState={setEditor} desc={htmlDesc} />
            </div>
          </div>
          <div
            style={{
              marginTop: "-40px",
              padding: "25px",
              marginLeft: "10px",
              display: "flex",
              paddingLeft: "25px",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              className={classes.btn}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.btn}
              style={{ minWidth: "200px" }}
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? (
                <CircularProgress size={18} style={{ color: "#fff" }} />
              ) : (
                "Schedule Interview"
              )}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default InterviewScheduling;

import { Drawer, Typography, IconButton, List, Button, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import * as React from "react";
import { useState } from "react";
//import menu_icon from "../../"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import axios from "axios";
import { BackendBaseApi, NEXT_APP_ENDPOINT } from "../../../constants/constants";
import { Link, useHistory } from "react-router-dom";
import DialogSearch from "../Filter/Mobile/DialogSearch";
import { useDispatch } from "react-redux";
// import Logo from "../../../assets/images/sidepanel/logo.png";
import default_icon from "../../../assets/images/user/user-default.png";
import Logo from "../../../assets/png/logo-long.png";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  linkText: {
    textDecoration: `none`,
    // textTransform: 'capitalize',
    color: `black`,
  },
  img: {
    borderRadius: "50%",
  },
});

const SideDrawer = ({ navLinks }) => {
  const classes = useStyles();
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ [anchor]: open });
  };

  var userDetails;
  if (localStorage.getItem("user_type") === "Company") userDetails = JSON.parse(localStorage.getItem("user"));
  else userDetails = JSON.parse(localStorage.getItem("user_details"));
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUserType = localStorage.getItem("user_type");

  const Logout = () => {
    axios
      .post(BackendBaseApi.PRAVESH + "api/logout/", null, {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
        withCredentials: true
      })
      .then((res) => {
        localStorage.clear();
        history.push("/login");
        dispatch({ type: "USER_LOGOUT" });
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/login");
      });
  };
  const [jobRoleList, setJobRoleList] = useState([]);

  React.useEffect(() => {
    callJobSegement();
  }, []);

  const callJobSegement = async () => {
    const job_role_url = `${BackendBaseApi.NIYUKTI}job/role/`;
    const job_role_response = await axios.get(job_role_url);
    return setJobRoleList(job_role_response.data.data);
  };

  function capitalizeFirstLetter(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }

  const sideDrawerList = (anchor) => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      {localStorage.getItem("gw_token") ? (
        <>
          <div style={{ backgroundColor: "#007bff", display: "flex", padding: "20px" }}>
            <img
              src={localStorage.getItem("user_type") && userDetails?.profile_picture ? userDetails?.profile_picture : default_icon}
              height="60px"
              width="60px"
              alt="demo"
              className={classes.img}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
              }}
            >
              <Typography varient="h6" style={{ color: "white", marginLeft: "8px" }}>
                Hi 
              </Typography>
              {/* <Link to={`/login`}> */}
              <Button className={classes.menuButton} color="inherit" aria-label="menu" style={{ flexGox: 1 }}>
                <Typography varient="h6" style={{ color: "white" }}>
                {userDetails?.first_name + " " + userDetails?.last_name}
                </Typography>
              </Button>
              {/* </Link> */}
            </div>
          </div>
        </>
      ) : (
        <div style={{ backgroundColor: "#007bff", display: "flex", padding: "20px" }}>
          <img
            src={localStorage.getItem("user_type") && userDetails?.profile_picture ? userDetails?.profile_picture : default_icon}
            height="60px"
            width="60px"
            alt="demo"
            className={classes.img}
          />{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <Typography varient="h6" style={{ color: "white", marginLeft: "8px" }}>
              Hi Guest
            </Typography>
            <Link to={`/login`} id="navBtn">
              <Button className={classes.menuButton} color="inherit" aria-label="menu" style={{ flexGox: 1 }} id="navBtn">
                <Typography varient="h6" style={{ color: "white" }}>
                  Log in/Sign up
                </Typography>
              </Button>
            </Link>
          </div>
        </div>
      )}
      <List component="nav">
        {navLinks.map((item) => (
          <a href={item.path} className={classes.linkText} id="navBtn">
            <ListItem button id="navBtn">
              <ListItemText primary={item.title} />
            </ListItem>
          </a>
        ))}
        {localStorage.getItem("gw_token") && (
          <ListItem button onClick={() => Logout()} id="navBtn">
            <ListItemText primary={"Log Out"} />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <IconButton edge="start" aria-label="menu" onClick={toggleDrawer("right", true)} id="navBtn">
        <Menu fontSize="large" style={{ color: `black` }} />
      </IconButton>
      <a
        href={currentUserType === 'Student' ? `/student/dashboard` : currentUserType === 'Company' ? `/company/dashboard` : currentUserType === 'College' ? `/college/dashboard` : NEXT_APP_ENDPOINT}
      >
        <Button edge="start" className={classes.menuButton} color="inherit" aria-label="menu" style={{ flexGox: 1 }} id="navBtn">
          <img
            src={Logo}
            alt="Getwork"
            style={{
              height: 25,
              marginTop: 11,
            }}
            // width="70px" height="50px"
          />
        </Button>
      </a>
      <IconButton style={{ padding: "0px" }} edge="start" aria-label="menu" id="navBtn">
        {/* <Image src="/svg/search.svg" alt="Getwork" width="20px" height="20px" /> */}
        {/* <DialogSearch jobRoleList={jobRoleList} /> */}
      </IconButton>
      <Drawer anchor="left" open={state.right} onOpen={toggleDrawer("right", true)} onClose={toggleDrawer("right", false)}>
        {sideDrawerList("left")}
      </Drawer>
    </React.Fragment>
  );
};

export default SideDrawer;

import { LinearProgress, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import pSBC from "shade-blend-color";
import AssignmentDownloadTable from "../AssingmentTable/AssignmentDownloadTable";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "20px",
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "30px",
    border: "none",
  },
  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "30px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    // border:"1px solid #007bff"
  },
  chipsection: {
    //  display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.7),
    },
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "12px",
  },
  move: {
    boxShadow: "none",
    borderRadius: "30px",
    height: "48px",
    marginLeft: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  jobCardBtn: {
    boxShadow: "none",
    borderRadius: "30px",
    height: "40px",
    marginLeft: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginRight: "25px",
    "&:hover": {
      backgroundColor: "#0f549d",
      color: "#fff",
    },
  },
}));

export default function AssignmentTable({ data, jobCard = false }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedAssignments, setSelectedAssignments] = useState([]);

  return (
    <div>
      {jobCard ? (
        <Button
          color="primary"
          size="small"
          onClick={handleClickOpen}
          className={classes?.jobCardBtn}
        >
          Assignment
        </Button>
      ) : (
        <Button color="primary" size="small" onClick={handleClickOpen}>
          View
        </Button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "649px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">
              {data?.name} Submitted Assignment
            </Typography>
          </div>
          <AssignmentDownloadTable
            data={data?.assignment_list ? data?.assignment_list : []}
            setSelectedAssignments={setSelectedAssignments}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-20px",
            }}
          >
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Back
            </Button>

            {/*    <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => {
                if (selectedAssignments?.length === 0) {
                  for (let i = 0; i < data?.assignment_list.length; i++) {
                    window.open(data?.assignment_list[i].file);
                  }
                  //  data?.assignment_list?.forEach((element) =>
                  //   window.open(element.file)
                  // );
                } else {
                  for (let i = 0; i < selectedAssignments.length; i++) {
                    window.open(selectedAssignments[i].file);
                  }
                  // selectedAssignments?.forEach((element) => {
                  //   window.open(element.file);
                  // });
                }
              }}
            >
              Download (
              {selectedAssignments?.length === 0
                ? data?.assignment_list?.length
                : selectedAssignments?.length}
              )
            </Button> */}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

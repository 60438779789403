import React from "react";
import Navbar from "../../components/public/Navbar/Navbar";
import MuiCustomTheme from "../components/Theme/theme";
import linkimg from "../assets/png/linknotfound.png";
import { Typography } from "@material-ui/core";

export default function LinkNotFound() {
  return (
    <>
      <Navbar />
      <MuiCustomTheme>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
          }}
        >
          <img src={linkimg} height="400px" alt="link-not-found" />
          <Typography variant="h4" style={{ color: "#6C757D" }}>
            This link is not valid
            <br />
            Please contact to source person for the right link.
          </Typography>
        </div>
      </MuiCustomTheme>
    </>
  );
}

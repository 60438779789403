import React, { useRef, useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
} from "@material-ui/core";

const EmployerGuidelines = ({
    companyUserDetails,
    setCompanyUserDetails,
    renderModal,
    setRenderModal,
    step,
    setStep,
}) => {
    //setStep(step+1)
    // const [isThirdParty,setIsThirdParty]=useState(companyUserDetails.isThirdPartyRecruiter)
    const isThirdParty = useRef();
    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        isThirdParty.current = companyUserDetails.isThirdPartyRecruiter;
    }, []);

    const handleChange = () => {
        isThirdParty.current = !isThirdParty.current;
    };

    const EmployerGuidelinesModal = (props) => {
        const [showModal, setShowModal] = useState(renderModal);

        const toggle = () => {
            setShowModal(!showModal);
            setRenderModal(!renderModal);
        };

        const confirmEmail = () => {
            setCompanyUserDetails({
                ...companyUserDetails,
                isThirdPartyRecruiter: isThirdParty.current,
            });
            setStep(step + 1);
        };

        const [scroll, setScroll] = React.useState("paper");

        const Transition = React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
        });

        return (
            <div>
                <Dialog
                    open={showModal}
                    onClose={toggle}
                    maxWidth="md"
                    TransitionComponent={Transition}
                >
                    <DialogTitle>
                        <div className="row justify-content-center fs-27">
                            GetWork Employer Guidelines
                        </div>
                    </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                        <div
                            className="row justify-content-center container mx-auto"
                            style={{ marginTop: "0px" }}
                        >
                            <p className="fs-13">
                                {" "}
                                Millions of students place their trust in
                                GetWork and the companies on our platform. To
                                maintain that trust, all employers on GetWork
                                must agree to the following general guidelines,
                                in addition to our{" "}
                                <span
                                    onClick={() =>
                                        window.open(
                                            "http://appreacttest.getwork.org/terms-and-services"
                                        )
                                    }
                                    style={{
                                        color: "#3282c4",
                                        cursor: "pointer",
                                    }}
                                >
                                    Terms of Service
                                </span>
                                :
                            </p>
                        </div>
                        <div className="row justify-content-center my-2 mx-auto">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 text-center">
                                        {" "}
                                        <i class="far fa-check-circle fa-3x color-blue-light"></i>
                                    </div>

                                    <div className="col-9 my-auto ">
                                        <p className="fs-13">
                                            <b>Be Accurate and Trustworthy:</b>{" "}
                                            Tell the truth about your company,
                                            your team and the jobs available.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 text-center">
                                        <i class="fas fa-handshake fa-3x color-blue-light"></i>
                                    </div>
                                    <div className="col-9 my-auto pl-3">
                                        <p className="fs-13">
                                            <b>Keep Your Commitments:</b> When
                                            you make a commitment to a school or
                                            student, keep it. If you can't, work
                                            to provide a fair and equitable path
                                            for affected students
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center my-2 mx-auto">
                            <div className="col-md-6">
                                <div className="row my-md-0 my-3">
                                    <div className="col-3 text-center">
                                        <i class="fas fa-balance-scale fa-3x color-blue-light"></i>
                                    </div>
                                    <div className="col-9">
                                        <p className="fs-13 my-auto">
                                            <b>Be Fair:</b> Do not discriminate
                                            based on ethnicity, national origin,
                                            religion*, age, gender, sexual
                                            orientation, disability or military
                                            / veteran status or lack thereof.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 text-center">
                                        <i class="fas fa-lock fa-3x color-blue-light"></i>
                                    </div>
                                    <div className="col-9 my-auto ">
                                        <p className="fs-13">
                                            <b>
                                                Keep Student Info Confidential:
                                            </b>{" "}
                                            Guard student information as if it
                                            were your own. Do not disclose any
                                            personal information without the
                                            prior consent of a student
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center my-2 mx-md-auto mx-3">
                            <p className="fs-13">
                                In addition, most career service centers require
                                employers to abide by the full
                                <a className="fw-500" href="#">
                                    {" "}
                                    NACE Principles for Employment
                                    Professionals.
                                </a>
                            </p>
                        </div>
                        <div className="row no-gutters justify-content-center mx-auto my-3 bg-grey-light py-md-3 py-0 px-md-0 px-3">
                            <div className="form-group">
                                <span className="fs-13 pt-1">
                                    Are you a 3rd party recruiter working on
                                    behalf of another company?
                                    <input
                                        onChange={handleChange}
                                        type="checkbox"
                                        className="form-check-input ml-2"
                                        style={{ position: "inherit" }}
                                        id="exampleCheck1"
                                        defaultChecked={isThirdParty.current}
                                    />
                                    <label
                                        className="form-check-label fs-13 ml-4"
                                        htmlFor="exampleCheck1"
                                    >
                                        Yes
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12 mx-3 ">
                            <p className="fs-13">
                                By continuing, you agree to the Terms of
                                Service, acknowledge you have read the{" "}
                                <span
                                    onClick={() =>
                                        window.open(
                                            "http://appreacttest.getwork.org/privacy-policy"
                                        )
                                    }
                                    style={{
                                        color: "#3282c4",
                                        cursor: "pointer",
                                    }}
                                >
                                    Privacy Policy
                                </span>
                                , and agree to GetWork’s Employer Guidelines.
                                You will also receive communication from GetWork
                                related to your jobs and on campus activities.
                            </p>
                            <p className="fs-11 text-muted font-italic">
                                *As with EEOC's Title VII, this does not apply
                                to institutions whose purpose and character are
                                primarily religious (i.e. a ministry).
                            </p>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <div className="row ">
                            <div className="col-md-3 text-right my-auto px-3">
                                <button
                                    onClick={confirmEmail}
                                    className="btn btn-outline-primary 
                        shadow_1-lightest"
                                >
                                    Next: Join your Company
                                </button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
    return (
        <>
            <EmployerGuidelinesModal />
        </>
    );
};

export default EmployerGuidelines;

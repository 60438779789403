import React, { Fragment, useEffect, useState } from "react";

export default function generateCsv(arr, name) {
  let str = "";
  arr.forEach((item, index) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ", ")
      : (str += item[name])
  );
  return str;
}

export function generateCsvNew(arr) {
  let str = "";
  if (arr) {
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item + ", ")
        : (str += item)
    );
  }
  str = str.replace(/,\s*$/, "");
  return str;
}

export function generateCsvWithName(arr, name) {
  let str = "";
  arr.forEach((item, index) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ", ")
      : (str += item[name])
  );
  return str;
}

export function generateCsvDegreeName(arr) {
  let str = "";
  arr.forEach((item, index) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item.degree_name + " | ")
      : (str += item.degree_name)
  );
  return str;
}

export function generateCsvWithoutSpacing(arr, name) {
  let str = "";
  arr.forEach((item, index) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ",")
      : (str += item[name])
  );
  return str;
}

import { Box, ClickAwayListener, createMuiTheme, createStyles, Divider, FormControlLabel, Grid, makeStyles, MuiThemeProvider, Switch } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BootstrapTooltip from "../../../common/Tooltip/Tooltip";
import addMonths, { monthDiff } from "../../../utils/AddMonths";
import uniqueArray from "../../../utils/UniqueArray";
import { getAllCompanies, getEmploymentTypes, getJobRoles, getJobSegmentNew, getJobTypes, getSkills } from "../../company/action";
import CssTextField from "./FormComponents/CssTextField";
import CurrencyComponent from "./FormComponents/CurrencyComponent";
import DateSelector from "./FormComponents/DateComponent";
import HelpFunction from "./FormComponents/help";
import JobSegment, { Skills, JobSegmentSingle, JobRoles, JobTypes, EmploymentType, Companies } from "./FormComponents/JobSegment";
import theme from "./FormComponents/MuiOutlined";
import NumberTextField from "./FormComponents/NumberTextField";
import CustomizedCheckboxes from "./FormComponents/CustomizedCheckboxes";

const Basics = (props) => {
  let { job_id } = useParams();
  const { state, setState, basicsRef, jobData, setApplyButtonClicked } = props;
  const [currentJobRole, setCurrentJobRole] = useState({});

  const [currentJobType, setCurrentJobType] = useState({});
  const [currentEmpType, setCurrentEmpType] = useState({});
  const [currentJobSegment, setCurrentJobSegment] = useState({});
  const [currentCompany, setCurrentCompany] = useState({});
  const [noCompany, setNoCompany] = useState(false);
  const [date, setDate] = useState(null);
  const [months, setMonths] = useState(0);
  const dispatch = useDispatch();
  const segmentList = useSelector((state) => state.JobSegmentListNew.JobSegmentList);
  const skillList = useSelector((state) => state.SkillList.SkillList);
  const empTypeList = useSelector((state) => state.EmploymentTypeList.EmploymentTypeList);
  const companyList = useSelector((state) => state.CompanyList.CompanyList);
  const jobRoleList = useSelector((state) => state.JobRoleList.JobRoleList);
  // const jobTypeList = useSelector((state) => state.JobTypeList.JobTypeList);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [monthAquired, setMonthAcquired] = useState();
  const [typingCompany, setTypingCompany] = useState();
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [stipened, setStipened] = useState(0);
  const [durationStartDate, setDurationStartDate] = useState();
  const [durationEndDate, setDurationEndDate] = useState();
  useEffect(() => {
    if (Object.keys(segmentList).length === 0) dispatch(getJobSegmentNew());
    // if (Object.keys(jobTypeList).length === 0) dispatch(getJobTypes());
    if (Object.keys(skillList).length === 0) dispatch(getSkills());
    if (Object.keys(empTypeList).length === 0) dispatch(getEmploymentTypes());
    if (localStorage.getItem("user_type") === "College" && Object.keys(companyList).length === 0) dispatch(getAllCompanies());
  }, []);

  // useEffect(() => {
  //   setCurrentCompany({});
  //   setTypingCompany();
  //   setState({
  //     ...state,
  //     Basics: {
  //       ...state.Basics,
  //       company_id: 0,
  //       company_details: null,
  //     },
  //   });
  // }, [noCompany]);

  // console.log(jobTypeList)

  const jobTypeList = [
    { id: 1, job_type: "Internship" },
    { id: 7, job_type: "Full Time Job" },
  ];

  useEffect(() => {
    if (typingCompany !== undefined)
      setState({
        ...state,
        Basics: {
          ...state.Basics,
          company_id: 0,
          company_details: typingCompany,
        },
      });
  }, [typingCompany]);

  useEffect(() => {
    var arr = [];
    if (job_id && jobData && jobData !== undefined) {
      if (jobData?.company) setCurrentCompany({ company_id: jobData?.company?.company_id, company_name: jobData?.company?.company_name });
      else {
        setTypingCompany(jobData?.company_details);
        setNoCompany(true);
      }

      setStartDate(jobData.apply_start_date);
      setEndDate(jobData.apply_end_date);
      setDurationStartDate(jobData.job_duration_start);
      setDurationEndDate(jobData.job_end_start);
      setCurrentJobType({
        id: jobData?.job_type,
        job_type: jobData?.job_type_name,
      });
      setCurrentJobSegment({ job_segment_id: jobData?.job_segment, job_segment_name: jobData?.job_segment_name });
      setDate(jobData.job_duration_start);
      setStipened(jobData?.stipend ? jobData.stipend : 0);
      setCurrentEmpType({
        id: jobData?.employment_type,
        job_type: jobData?.employment_type_name,
      });
      setCurrentJobRole({
        id: jobData?.job_role,
        job_role_name: jobData?.job_role_name,
      });
      jobData &&
        jobData.eligibility_criteria &&
        jobData.eligibility_criteria.skills.length > 0 &&
        jobData.eligibility_criteria.skills.map((item) => {
          arr.push({ skill_id: item.skill_id, skill_name: item.skill_name });
        });
      setSelectedSkills(arr);

      var month_get = monthDiff(new Date(String(jobData?.job_duration_start)), new Date(String(jobData?.job_duration_end)));
      if (month_get && month_get !== undefined && month_get !== null) setMonths(month_get);
    }
  }, [jobData]);

  useEffect(() => {
    dispatch(getJobRoles(setLoader, currentJobSegment?.job_segment_id));
  }, [currentJobSegment]);

  useEffect(() => {
    if (date !== null) {
      setState({
        ...state,
        Basics: {
          ...state.Basics,
          job_duration_start: date,
        },
      });
    }
  }, [date]);

  // useEffect(() => {
  //   if (stipened !== null && stipened !== undefined && stipened !== 0) {
  //     setState({
  //       ...state,
  //       Basics: {
  //         ...state.Basics,
  //         stipend: stipened,
  //       },
  //     });
  //   }
  // }, [stipened]);

  useEffect(() => {
    if (startDate) {
      setState({
        ...state,
        Colleges: {
          ...state.Colleges,
          apply_start_date: startDate,
        },
      });
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setState({
        ...state,
        Colleges: {
          ...state.Colleges,
          apply_end_date: endDate,
        },
      });
    }
  }, [endDate]);

  useEffect(() => {
    if (durationStartDate) {
      setState({
        ...state,
        Basics: {
          ...state.Basics,
          job_duration_start: durationStartDate,
        },
      });
    }
  }, [durationStartDate]);

  useEffect(() => {
    if (durationEndDate) {
      setState({
        ...state,
        Basics: {
          ...state.Basics,
          job_duration_end: durationEndDate,
        },
      });
    }
  }, [durationEndDate]);

  // useEffect(() => {
  //   if (state.Basics.employment_type) {
  //     setState({
  //       ...state,
  //       Basics: {
  //         ...state.Basics,
  //         job_duration_end: null,
  //         job_duration_start: null,
  //       },
  //     });
  //     setDurationEndDate();
  //     setDurationStartDate();
  //   }
  // }, [state.Basics.employment_type]);

  useEffect(() => {
    var temp_arr = [...selectedSkills];
    temp_arr.forEach((item) => {
      item.rating_name = "Expert";
      item.rating_value = 5;
    });

    setState({
      ...state,
      Preferences: {
        ...state.Preferences,
        eligibility_criteria: {
          ...state.Preferences.eligibility_criteria,
          skills: temp_arr,
        },
      },
    });
  }, [selectedSkills]);

  useEffect(() => {
    if (date !== null && months !== null && date !== undefined && months !== 0) {
      var newDate = new Date(date);
      var endDate = addMonths(newDate, months);
      const duration_date = endDate.toISOString().substring(0, 10);
      setState({
        ...state,
        Basics: {
          ...state.Basics,
          job_duration_end: duration_date,
        },
      });
    }
  }, [months, date]);

  useEffect(() => {
    if (state.Basics.job_type !== 1) {
      setState({
        ...state,
        Basics: { ...state.Basics, job_duration_end: null },
      });
      // setMonths(null);
    }
  }, [currentJobType]);

  const classes = useStyles();

  return (
    <div ref={basicsRef}>
      <h5 className={classes.heading}>Basics</h5>
      <Divider />

      <Grid container style={{ marginTop: "30px" }}>
        {localStorage.getItem("user_type") === "College" && (
          <>
            <Grid item xs={3} className={classes.section}>
              <p className={classes.formLabel}>
                Company Details<span className={classes.RedStar}>*</span>
              </p>
            </Grid>
            <Grid item xs={9} className={classes.section}>
              {!noCompany ? (
                <Companies
                  state={state}
                  setState={setState}
                  companyList={companyList}
                  width={classes.InputWidth}
                  responsive={classes.labelSize}
                  currentCompany={currentCompany}
                  setCurrentCompany={setCurrentCompany}
                  MenuSize={classes.MenuSize}
                />
              ) : (
                <CssTextField
                  id="outlined-name"
                  label={<span className={classes.labelSize}>Enter your Company Details</span>}
                  variant="outlined"
                  // className={classes.InputWidth}
                  style={{ width: "70%" }}
                  value={typingCompany}
                  InputProps={{
                    style: { fontSize: 13 },
                  }}
                  onChange={(e) => {
                    setTypingCompany(e.target.value);
                  }}
                />
              )}

              <FormControlLabel
                value={noCompany}
                style={{ marginLeft: 0 }}
                control={
                  <CustomizedCheckboxes
                    checked={noCompany}
                    onChange={(e) => {
                      setNoCompany(e.target.checked);
                    }}
                    name={"allow_remote"}
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    color="primary"
                  />
                }
                label={
                  <span className={classes.labelSize}>
                    Company not in the list <HelpFunction title={"If your company is not present in the list. Click here to type your own"} />
                  </span>
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>
            Title<span className={classes.RedStar}>*</span>
          </p>
        </Grid>
        <Grid item xs={9} className={classes.section}>
          <CssTextField
            id="outlined-name"
            label={<span className={classes.labelSize}>Job Title</span>}
            variant="outlined"
            className={classes.InputWidth}
            value={state.Basics.job_title}
            InputProps={{
              style: { fontSize: 13 },
            }}
            onChange={(e) => {
              setState({
                ...state,
                Basics: {
                  ...state.Basics,
                  job_title: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>
            Job Segment<span className={classes.RedStar}>*</span>
          </p>
        </Grid>
        <Grid item xs={9} className={classes.section}>
          <JobSegmentSingle
            state={state}
            setState={setState}
            segmentList={segmentList}
            width={classes.InputWidth}
            responsive={classes.labelSize}
            currentJobSegment={currentJobSegment}
            setCurrentJobSegment={setCurrentJobSegment}
            MenuSize={classes.MenuSize}
            setApplyButtonClicked={setApplyButtonClicked}
          />
        </Grid>
        {state.Basics.job_segment && currentJobSegment && jobRoleList && (
          <>
            <Grid item xs={3} className={classes.section}>
              <p className={classes.formLabel}>
                Job Roles<span className={classes.RedStar}>*</span>
              </p>
            </Grid>
            <Grid item xs={9} className={classes.section}>
              <JobRoles
                state={state}
                setState={setState}
                jobRoleList={jobRoleList}
                width={classes.InputWidth}
                responsive={classes.labelSize}
                currentJobRole={currentJobRole}
                setCurrentJobRole={setCurrentJobRole}
                MenuSize={classes.MenuSize}
              />{" "}
              <p className={classes.subTitle}>
                <i>
                  Job roles are search facets for students who are looking for a certain type of work. Your selection(s) will help the students interested in these roles find your
                  jobs.
                </i>
              </p>
            </Grid>
          </>
        )}
        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>
            Job Type<span className={classes.RedStar}>*</span>
          </p>
        </Grid>
        <Grid item xs={9} className={classes.section}>
          <JobTypes
            state={state}
            setState={setState}
            jobTypeList={jobTypeList}
            width={classes.InputWidth}
            responsive={classes.labelSize}
            currentJobType={currentJobType}
            setCurrentJobType={setCurrentJobType}
            MenuSize={classes.MenuSize}
          />
        </Grid>
        {state.Basics.job_type && (
          <>
            <Grid container className={classes.sectionResponsive}>
              <Grid item md={3} className={classes.section}></Grid>
              {/* {localStorage.getItem("user_type") === "Company" && ( */}
              <>
                <Grid item md={3} className={classes.section} style={{ marginRight: "2%" }}>
                  <DateSelector date={date} setDate={setDate} dateType={"Joining Date"} responsive={classes.labelSize} />
                </Grid>

                <Grid item md={3} className={classes.section} style={{ marginRight: "2%" }}>
                  {state.Basics.job_type === 1 && (
                    <NumberTextField state={months} setState={setMonths} labelName={"Internship Duration (in months)"} width={"100%"} responsive={classes.labelSizeNew} />
                  )}
                </Grid>
              </>
              {/* )} */}
              <Grid item md={2} className={classes.section}>
                {state.Basics.job_type === 1 && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.Basics.ppo}
                        onChange={(e) => {
                          setState({
                            ...state,
                            Basics: {
                              ...state.Basics,
                              ppo: e.target.checked,
                            },
                          });
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={<span className={classes.labelSize}>PPO Offered</span>}
                  />
                )}
              </Grid>
              {/* {localStorage.getItem("user_type") === "College" && (
                <Grid item md={2} className={classes.section}>
                  {state.Basics.job_type === 1 && (
                    <CurrencyComponent state={stipened} setState={setStipened} labelName={"Stipend (Per Month)"} width={"100%"} responsive={classes.labelSize} />
                  )}
                </Grid>
              )} */}
              <Grid item xs={1} className={classes.section}></Grid>
            </Grid>
          </>
        )}
        {/* {localStorage.getItem("user_type") === "Company" && state.Basics.job_type === 1 && (
          <>
            <Grid item xs={3} className={classes.stipenedSection}></Grid>
            <Grid item xs={9} className={classes.stipenedSection}>
              <CurrencyComponent state={stipened} setState={setStipened} labelName={"Stipend (Per Month)"} width={"50%"} responsive={classes.labelSize} />{" "}
            </Grid>
          </>
        )} */}

        {localStorage.getItem("user_type") === "College" && (
          <>
            <Grid item sm={3} className={classes.section}>
              <p className={classes.formLabel}>
                Application Deadline
                <span className={classes.RedStar}>*</span>
              </p>
            </Grid>
            <Grid item sm={3} className={classes.section} style={{ marginRight: "2%" }}>
              <DateSelector date={startDate} setDate={setStartDate} dateType={"Apply Start Date"} />
            </Grid>
            <Grid item sm={3} className={classes.section}>
              <DateSelector date={endDate} setDate={setEndDate} dateType={"Apply End Date"} />
            </Grid>
          </>
        )}

        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>
            Employment Type
            <span className={classes.RedStar}>*</span>
          </p>
        </Grid>
        <Grid item xs={9} className={classes.section}>
          <EmploymentType
            state={state}
            setState={setState}
            empTypeList={empTypeList}
            width={classes.InputWidth}
            responsive={classes.labelSize}
            currentEmpType={currentEmpType}
            setCurrentEmpType={setCurrentEmpType}
            MenuSize={classes.MenuSize}
          />{" "}
        </Grid>
        {/* {localStorage.getItem("user_type") === "College" && state.Basics.employment_type && (
          <>
            <Grid item sm={3} className={classes.section}></Grid>
            <Grid item sm={3} className={classes.section} style={{ marginRight: "2%" }}>
              <DateSelector date={durationStartDate} setDate={setDurationStartDate} dateType={"Job Duration Start"} />
            </Grid>
            {state.Basics.employment_type !== 1 ? (
              <Grid item sm={3} className={classes.section}>
                <DateSelector date={durationEndDate} setDate={setDurationEndDate} dateType={"Job Duration End"} />
              </Grid>
            ) : (
              <Grid item sm={3} className={classes.section}></Grid>
            )}
          </>
        )} */}
        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>
            Key Skills<span className={classes.RedStar}>*</span>
          </p>
        </Grid>
        <Grid item xs={9} className={classes.section} id="box_value">
          <Skills state={selectedSkills} setState={setSelectedSkills} skillList={skillList} width={classes.InputWidth} responsive={classes.labelSize} />
          <p className={classes.subTitle}>
            <i>Skills not available</i>
            <HelpFunction title={"Contact Account Manager for addition of skills desired in the above dropdown list"} />
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formLabel: {
      fontSize: "13px",
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    subTitle: {
      fontSize: "11px",
      color: "grey",
      width: "70%",
      margin: "6px 0 0 10px",
    },
    RedStar: {
      color: "red",
      fontWeight: "bold",
      marginLeft: "2px",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "bolder",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    section: {
      marginBottom: "20px",
    },
    stipenedSection: {
      marginBottom: "20px",
      marginTop: -20,
    },
    sectionResponsive: {},
    InputWidth: {
      width: "70%",
    },
    labelSize: {
      fontSize: "12px",
    },
    MenuSize: {
      fontSize: "12px",
    },
    "@media only screen and (max-width: 900px)": {
      InputWidth: {
        width: "100%",
      },
      formLabel: {
        fontSize: "11px",
        fontWeight: "bold",
        paddingTop: "10px",
      },
      sectionResponsive: {
        marginLeft: "115px",
      },
    },
    labelSizeNew: {
      fontSize: "11px",
    },
    "@media only screen and (max-width: 1100px)": {
      labelSize: {
        fontSize: "11px",
      },
      MenuSize: {
        fontSize: "11px",
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "auto",
      },
    },
  })
);
export default Basics;

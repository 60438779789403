import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { BackendBaseApi } from "../../../../constants/constants";

const JobTypes = ({ defaultValue, state, setState }) => {
  const [jobtypeList, setJobtypeList] = useState({});
  const [jobtype, setJobtype] = useState(defaultValue);

  const JobTypes = Object.keys(jobtypeList).map((jobtype) => {
    return { name: jobtype, value: jobtype };
  });
  const TypeAndSearchJobtype = () =>
    defaultValue == null ? (
      <SelectSearch
        options={JobTypes}
        value={jobtype}
        onChange={setJobtype}
        placeholder="Select"
      />
    ) : (
      <SelectSearch options={JobTypes} value={jobtype} onChange={setJobtype} />
    );

  const getAllJobTypes = () => {
    axios
      .get(BackendBaseApi.NIYUKTI + "job/type")
      .then((res) => {
        let temp = {};
        res.data.data.forEach((jobtype) => {
          temp[jobtype.job_type] = jobtype.id;
        });

        setJobtypeList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
    //setJobtype(jobtypeList[Id])
  };

  useEffect(() => {
    getAllJobTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState({ job_type_id: jobtypeList[jobtype], job_type: jobtype });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobtype, jobtypeList]);

  return (
    <>
      <TypeAndSearchJobtype />
    </>
  );
};

export default JobTypes;

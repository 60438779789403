import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { NEXT_APP_ENDPOINT } from "../../../../../constants/constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },

  linktext: {
    color: "white",
    paddingBottom: "10px",
    "&:hover": {
      textDecoration: "underline",
      color: "white",
    },
  },
  text: {
    marginBottom: "7px",
    fontSize: 14,
    fontFamily: "Nunito",
  },
  title: {
    color: "#fff",
    fontSize: 20,
    fontFamily: "Nunito",
  },
}));

export default function JobsByLocations() {
  const classes = useStyles();
  const data = [
    {
      heading: "Jobs in Delhi / NCR",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-delhi`,
    },
    {
      heading: "Jobs in Bangalore",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-bangalore`,
    },
    {
      heading: "Jobs in Mumbai",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-mumbai`,
    },
    {
      heading: "Jobs in Pune",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-pune`,
    },
    {
      heading: "Jobs in Hyderabad",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-hyderabad`,
    },
    {
      heading: "Jobs in Jaipur",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-jaipur`,
    },
    {
      heading: "Jobs in Bhopal",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-bhopal`,
    },
    {
      heading: "Jobs in Ahmedabad",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-ahmedabad`,
    },
    // {
    //   heading: "Jobs in Indore",
    //   link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-indore`,
    // },
    {
      heading: "Jobs in Chandigarh",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-chandigarh`,
    },
    {
      heading: "Jobs in Surat",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-surat`,
    },
    {
      heading: "Jobs in Nagpur",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-nagpur`,
    },
    {
      heading: "Jobs in Lucknow",
      link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-lucknow`,
    },
  ];
  return (
    <>
      <Typography variant="h4" className={classes.title} gutterBottom>
        Jobs by Locations
      </Typography>
      <br />
      {data.map((item) => (
        <a href={item.link} className={classes.linktext}>
          <Typography
            variant="body2"
            color="inherit"
            className={classes.text}
            id="footerBtn"
          >
            {item.heading}
          </Typography>{" "}
        </a>
      ))}
    </>
  );
}

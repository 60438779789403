import { Box, CircularProgress, Dialog, DialogContent, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
// import {Icon, Rating, Grid, Image, Segment } from 'semantic-ui-react';
import Icon from "@material-ui/core/Icon";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Axios from "axios";
import { loadCSS } from "fg-loadcss";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SelectSearch from "react-select-search";
import plus from "../../../assets/images/plus.png";
import GifLoader from "../../../assets/payment-loading.gif";
import UserReferral from "../../../common/Referrer/Referrer";
import ChangePasswordDialog from "../../../components/company/Dialogs/ChangePasswordDialog";
import { BackendBaseApi } from "../../../constants/constants";
import { getUserDetails } from "../Dashboard/action";
import "./userSettings.css";
import useStyles_usersSettings from "./userSettingsCSS";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    postButton: {
      background: "#3282C4",
      borderRadius: "30px",
      color: "#fff",
      width: "114px",
      height: "36px",
      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    expButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "center",
      contentAlign: "center",
      margin: "auto",
      justifyContent: "center",
      float: "center",
      display: "flex",
      boderRadius: "75px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    fullWidth: {
      width: "200%",
    },
    main: {
      paddingLeft: "4%",
      paddingRight: "4%",
    },
    contactText: {
      "&:hover": {
        color: "#3282c4",
      },
    },
  })
);

const UserSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState("Edit Profile");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [bio, setBio] = useState("");
  const [college, setCollege] = useState("");
  const [year, setYear] = useState("");
  const [year33, setYear33] = useState("");

  const [xpTitle, setXpTitle] = useState("");
  // const [empType, setEmpType] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const loc = useLocation();
  const [sYear, setsYear] = useState("Select");
  const [sMonth, setsMonth] = useState("Select");
  const [eYear, seteYear] = useState("Select");
  const [eMonth, seteMonth] = useState("Select");
  const [profileImage, setProfileImage] = useState();
  const [current, setCurrent] = useState(true);
  const [workexp, setWorkexp] = useState([]);
  const alert = useAlert();
  const [colleges, setCollegeList] = useState({});
  const [colType, setColType] = useState("");
  const [colType1, setColType1] = useState("");

  const [collegeID, setCollegeID] = useState();

  const [no_college, setNoCollege] = useState();
  const [imgtemp, setImgtemp] = useState();
  const [comp, setComp] = useState({});
  const [compType, setCompType] = useState();
  const [no_company, setNoCompany] = useState(false);
  const [empTypes, setEmpTypes] = useState({});
  const [empType, setEmpType] = useState();
  const [data, setData] = useState({});
  const [oldpass, setOldPass] = useState();
  const [newpass, setNewPass] = useState();
  const [confpass, setConfPass] = useState();

  const [compActive, setCompActive] = useState(false);
  const [durationActive, setDurationActive] = useState(false);

  const [expDesignation, setexpDesignation] = useState();
  const [expEmpType, setExpEmpType] = useState();
  const [expCompany, setExpCompany] = useState();
  const [expStart, setExpStart] = useState();
  const [expEnd, setExpEnd] = useState();
  const [expCompName, setExpCompanyName] = useState();
  const [workExperience, setWorkExperience] = useState([]);
  const [addingExperience, setAddingExperience] = useState(false);
  const [updatingExperience, setUpdatingExperience] = useState(false);
  const [selectedExperienceButton, setSelectedExperienceButton] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  let token = localStorage.getItem("gw_token");

  // const year1 = (new Date()).getFullYear();
  const year1 = 2000;
  const years = Array.from(new Array(30), (val, index) => index + year1);
  const theme = useTheme();
  React.useEffect(() => {
    const node = loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const getUserDetails_local = (updateFlag) => {
    setImageLoading(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/college/settings/user/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setFirst(res.data.data.first_name);
          setLast(res.data.data.last_name);
          setTitle(res.data.data.job_title);
          setEmail(res.data.data.email);
          setMobile(res.data.data.phone);
          setBio(res.data.data.about);
          setProfileImage(res.data.data.profile_picture);
          setCollege(res.data.data.college_name);

          setColType(res.data.data.college_name);
          setYear(res.data.data.graduation_year);
          setYear33(res.data.data.graduation_year);
          setCompActive(false);
          setDurationActive(false);

          setWorkexp(res.data.data.work_experience);
          setImageLoading(false);

          const temp = res.data.data.work_experience;
          const temp1 = res.data.data;

          var result = temp.map((v) => ({
            ...v,
            isActive: v.company_id === null ? true : false,
            isChecked: v.end_date === null ? true : false,
          }));
          setWorkexp(result);
        } else {
          alert.error(res.data.error);
        }
        setCollegeID(res.data.data.college_id);

        if (res.data.data.college_id === null) setNoCollege(true);
        else setNoCollege(false);
        if (updateFlag) {
          alert.success("Updated Successfully");
          setUpdatingDetails(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setImageLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails_local(false);
    getCollegeList();
    getComp();
    getEmployementTypes();
  }, []);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage(img);
    }
  };

  const blobToImage = (blob) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;

      setImgtemp(blob);

      const fd = new FormData();
      fd.append("profile_picture", blob);
      setImageLoading(true);
      Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/user/", fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Profile Picture uploaded Successfully");
            setProfileImage(res.data.data.profile_picture);
          } else {
            alert.error(res.data.error);
          }
          setImageLoading(false);
          dispatch(getUserDetails(history, alert, localStorage.getItem("user_type_id")));
        })
        .catch((err) => {
          console.log(err);
          alert.error("Error occured!");
          setImageLoading(false);
        });
    });
  };

  const handleUploadDetails = () => {
    var data1;

    if (no_college == true) {
      data1 = {
        first_name: first,
        last_name: last,
        job_title: title,
        phone: mobile,
        about: bio ? bio : "",
        college_id: null,
        college_name: college,
        work_experience: workexp,
        graduation_year: year ? year + "-01-01" : null,
      };
    } else {
      data1 = {
        first_name: first,
        last_name: last,
        job_title: title,
        phone: mobile,
        about: bio ? bio : "",
        college_name: null,
        college_id: colleges[colType],
        work_experience: workexp,
        graduation_year: year ? year + "-01-01" : null,
      };
    }
    setUpdatingDetails(true);
    Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/user/", data1, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
        } else {
          alert.error(res.data.error);
        }
        getUserDetails_local(true);
        dispatch(getUserDetails(history, alert, localStorage.getItem("user_type_id")));
      })
      .catch((err) => {
        alert.error("Error occured!");
        setUpdatingDetails(true);
      });
  };

  const handleUploadDetails1 = (index, id) => {
    var dat;

    if (workexp[index].isActive == true) {
      dat = {
        job_designation: workexp[index].job_designation,
        company_id: null,
        company_name: workexp[index].company_name,
        end_date: workexp[index].isChecked === true ? null : workexp[index].end_date,
        start_date: workexp[index].start_date,
        job_type_id: empTypes[empType],
        job_type: empType,
      };
    } else {
      dat = {
        job_designation: workexp[index].job_designation,
        company_id: comp[compType],
        company_name: null,
        start_date: workexp[index].start_date,
        end_date: workexp[index].isChecked === true ? null : workexp[index].end_date,
        job_type_id: empTypes[empType],
        job_type: empType,
      };
    }

    const url = BackendBaseApi.PRAVESH + "api/company/work_experience/" + id + "/";
    setSelectedExperienceButton(index);
    setUpdatingExperience(true);

    Axios.patch(url, dat, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
        } else {
          alert.error(res.data.error);
        }
        alert.success("Updated Successfully");
        setUpdatingExperience(false);
      })
      .catch((err) => {
        alert.error("Error occured!");
        setUpdatingExperience(false);
      });
  };

  const getWorkExperience = async () => {
    const res = await Axios({
      method: "GET",
      headers: {
        Authorization: "Token " + token,
      },
      url: `${BackendBaseApi.PRAVESH}api/company/work_experience/`,
    }).catch((err) => {
      throw err;
    });
    setWorkExperience(res.data.data);
  };

  useEffect(() => {
    getWorkExperience();
  }, []);

  const AddExperience = () => {
    var dat;

    if (compActive == true) {
      dat = {
        job_designation: expDesignation,
        company_id: null,
        company_name: expCompName,
        end_date: durationActive == false ? expEnd : null,
        start_date: expStart,
        job_type_id: empTypes[expEmpType],
        job_type: expEmpType,
        city_id: null,
        job_description: null,
        skills: null,
        company_website: null,
      };
    } else {
      dat = {
        company_id: comp[expCompany],
        job_designation: expDesignation,
        company_name: null,
        end_date: durationActive == false ? expEnd : null,
        start_date: expStart,
        job_type_id: empTypes[expEmpType],
        job_type: expEmpType,
        city_id: null,
        job_description: null,
        skills: null,
        company_website: null,
      };
    }

    const url = BackendBaseApi.PRAVESH + "api/company/work_experience/";
    setAddingExperience(true);

    Axios.post(url, dat, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success("Added Successfully");
          getWorkExperience();
          handleClose1();
          let list = [...workexp];
          let fat = {};
          fat = dat;
          fat.isChecked = dat.end_date === null ? true : false;
          fat.isActive = dat.company_id === null ? true : false;

          if (compActive == false) {
            fat.company_name = expCompany;
          } else {
            fat.company_name = expCompName;
          }

          list.push(fat);

          setWorkexp(list);
        } else {
          alert.error(res.data.error);
        }
        setAddingExperience(false);
      })
      .catch((err) => {
        console.log(err);
        setAddingExperience(false);
        alert.error("Error occured!");
      });
  };

  const getComp = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/company_list/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.all_companies.forEach((c) => {
            temp[c.company] = c.id;
          });
          setComp({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployementTypes = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/type/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((emp_type) => {
            temp[emp_type.job_type] = emp_type.id;
          });
          setEmpTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCollegeList = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/college")
      .then((res) => {
        let temp = {};
        res.data.forEach((d) => {
          temp[d.name] = d.id;
        });
        setCollegeList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const passwordChange = () => {
    const pass = {};
    if (newpass == confpass) {
      const pass = {
        password: oldpass,
        new_password: newpass,
      };
      setUpdatingPassword(true);
      Axios.post(BackendBaseApi.PRAVESH + "api/shared/user_password/", pass, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert.success("Password Changed Successfully");
            handleClose();
          } else alert.error(res.data.error);
          setUpdatingPassword(false);
        })
        .catch((err) => {
          alert.error("Enter Correct Password");
          setUpdatingPassword(false);
        });
    } else {
      alert.error("New Password mismatch with confirm Pasword");
    }
  };

  const resetFields = () => {
    setOldPass("");
    setNewPass("");
    setConfPass("");
  };

  const clearFields = () => {
    setexpDesignation("");
    setExpEmpType("");
    setExpCompany("");
    setExpCompanyName("");
    setCompActive(false);
    setDurationActive(false);
    setExpEnd("");
    setExpStart("");
  };

  const coll = Object.keys(colleges).map((d) => {
    return { name: d, value: d };
  });
  const comps = Object.keys(comp).map((i) => {
    return { name: i, value: i };
  });
  const emptypes = Object.keys(empTypes).map((emp_type) => {
    return { name: emp_type, value: emp_type };
  });
  const yearsss = years.map((y) => {
    return { name: y, value: y };
  });

  // useEffect(() => {
  //   if (colType !== undefined) {
  //     console.log("check", colType);

  //     setColType1(colType);
  //   }
  // }, [colType]);
  const CollegesList = () => (
    <SelectSearch options={coll} value={colType} onChange={setColType} placeholder="Select" search />

    // <Autocomplete
    //   value={colType}
    //   onChange={(event, newValue) => {
    //     setColType(newValue);
    //   }}
    //   id="controllable-states-demo"
    //   options={coll.map((option) => option.name)}
    //   style={{ width: "90%" }}
    //   renderInput={(params) => <TextField {...params} variant="outlined" />}
    // />
  );

  // const YearS= () =>( <SelectSearch options={yearsss} value={year} onChange={setYear} placeholder="Select" search />  )

  // const Companies= () =>( <SelectSearch options={comps} value={compType} onChange={setCompType} placeholder="Select"  />  )
  // const Employementypes= () =>( <SelectSearch options={emptypes} value={empType} onChange={setEmpType} placeholder="Select"/>  )
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen2(false);
  };

  const classes1 = useStyles_usersSettings();

  const checkTab = () => {
    switch (open) {
      case "Edit Profile":
        return "edit-profile";

      case "Edit Experience":
        return "edit-experience";

      case "Referral":
        return "referral";

      default:
        return "";
    }
  };

  useEffect(() => {
    window.history.pushState({}, null, `/college/user-settings/?${checkTab()}`);
  }, [open]);

  useEffect(() => {
    if (loc.search === "?edit-profile") setOpen("Edit Profile");
    if (loc.search === "?edit-experience") setOpen("Edit Experiences");
    if (loc.search === "?referral") setOpen("Referral");
    const collegeName = JSON.parse(localStorage.getItem("user_details")).college_name;
    if(collegeName) setCollege(collegeName);
  }, []);

  return (
    <>
      <ChangePasswordDialog
        open1={open1}
        handleClose={handleClose}
        setOldPass={setOldPass}
        setNewPass={setNewPass}
        oldpass={oldpass}
        newpass={newpass}
        setConfPass={setConfPass}
        passwordChange={passwordChange}
        resetFields={resetFields}
        confpass={confpass}
        updatingPassword={updatingPassword}
      />

      <br />
      <main className={classes.main}>
        <div className={classes1.container}>
          <div className={classes1.leftPaper}>
            <Button
              style={{ borderRadius: "30px" }}
              className={open !== "Edit Profile" ? classes1.leftMenuBox1 : classes1.leftMenuBoxSelected1}
              size="small"
              onClick={() => setOpen("Edit Profile")}
            >
              Edit Profile
            </Button>
            <Button
              style={{ borderRadius: "30px" }}
              className={open !== "Edit Experience" ? classes1.leftMenuBox1 : classes1.leftMenuBoxSelected1}
              size="small"
              onClick={() => setOpen("Edit Experience")}
            >
              Edit Experience
            </Button>
            <Button
              style={{ borderRadius: "30px" }}
              className={open !== "Referral" ? classes1.leftMenuBox1 : classes1.leftMenuBoxSelected1}
              size="small"
              onClick={() => setOpen("Referral")}
            >
              Referrals
            </Button>
            <Button
              style={{ borderRadius: "30px" }}
              onClick={() => {
                handleOpen();
              }}
              className={classes1.leftMenuBox}
            >
              Change Password
            </Button>
          </div>
        </div>
        {open === "Edit Profile" ? (
          <div className={classes1.paperStyle}>
            <Typography variant="h3">Details</Typography>
            <div className={classes1.avatarBox}>
              <Avatar
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "75px",
                  border: "7px solid #e7e6e6",
                }}
                alt="Remy Sharp"
                src={imageLoading ? GifLoader : profileImage}
              />
              <div>
                <input type="file" id="image" style={{ display: "none" }} onChange={onImageChange}></input>
                <label
                  for="image"
                  className="img_edit"
                  style={{
                    position: "relative",
                    top: 110,
                    left: -50,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "75px",
                      border: "2px solid #e7e6e6",
                      padding: "5px 9px 5px 9px",
                      opacity: 0.8,
                    }}
                  >
                    <Icon className="fa fa-pencil" style={{ fontSize: "20px" }} />
                  </div>
                </label>
              </div>
            </div>
            <div className={classes1.firstBox}>
              <div className={classes1.fieldDiv}>
                <Typography variant="body1" style={{ width: "100px" }}>
                  First Name <span className="text-red"> *</span>
                </Typography>
                <TextField variant="outlined" type="text" value={first} onChange={(e) => setFirst(e.target.value)} className={classes1.inputBox} required />
              </div>
              <div className={classes1.fieldDiv} style={{marginLeft:'5px'}}>
                <Typography variant="body1" style={{ width: "100px",}}>
                  Last Name<span className="text-red"> *</span>
                </Typography>
                <TextField variant="outlined" type="text" value={last} onChange={(e) => setLast(e.target.value)} className={classes1.inputBox} required />
              </div>
            </div>
            <div className={classes1.fieldDiv}>
              <Typography variant="body1" style={{ width: "100px" }}>
                Title<span className="text-red"> *</span>
              </Typography>
              <TextField variant="outlined" type="text" value={title} onChange={(e) => setTitle(e.target.value)} className={classes1.inputBox} required />
            </div>
            <div className={classes1.fieldDiv}>
              <Typography variant="body1" style={{ width: "100px" }}>
                Email<span className="text-red"> *</span>
              </Typography>
              <div className={classes1.inputBoxE}>
                <b style={{ color: "#0645AD" }}>{email}</b>
              </div>
              <a
                href={`mailto:support@getwork.org?cc=prerit.chaudhary@getwork.org&Subject=${localStorage.getItem("college_id")}|%20Request%20to%20edit%20User%20Email%20id`}
                target="_blank"
              >
                <Typography
                  className={classes.contactText}
                  style={{
                    fontSize: "15px",
                    paddingTop: "7px",
                    cursor: "pointer",
                  }}
                >
                  (Contact getwork admin to change the Email.)
                </Typography>
              </a>
            </div>
            <div className={classes1.fieldDiv}>
              <Typography variant="body1" style={{ width: "100px" }}>
                Mobile<span className="text-red"> *</span>
              </Typography>
              <TextField variant="outlined" type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} className={classes1.inputBox} required />
            </div>
            <div className={classes1.fieldDiv}>
              <Typography variant="body1" style={{ width: "100px" }}>
                Bio <span className="text-red"> *</span>
              </Typography>
              <TextField variant="outlined" multiline={true} type="text" value={bio} onChange={(e) => setBio(e.target.value)} className={classes1.inputBox2} required />
            </div>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs={2}>
                <Typography variant="body1" style={{ width: "100px" }}>
                  Alma Mater
                </Typography>
              </Grid>
              <Grid item xs={4} id={theme.palette.type === "dark" && "theme-select-search"}>
                {no_college === false ? (
                  <div className={classes1.radioStyle}>
                    <>
                      <CollegesList className={classes.fullWidth} />
                    </>
                  </div>
                ) : (
                  <>
                    <TextField
                      variant="outlined"
                      type="text"
                      value={college}
                      onChange={(e) => setCollege(e.target.value)}
                      placeholder="Enter your College"
                      style={{ width: "90%" }}
                    />
                  </>
                )}{" "}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" style={{ width: "100px" }}>
                  Year{" "}
                </Typography>
              </Grid>

              <Grid item xs={2} id={theme.palette.type === "dark" && "theme-select-search"}>
                <SelectSearch options={yearsss} value={parseInt(year33)} onChange={setYear} placeholder="Select" search />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <br />
            <br />
            <Box display="flex" alignItems="center">
              <input
                type="checkbox"
                style={{
                  marginLeft: "140px",

                  marginRight: "10px",
                }}
                checked={no_college}
                onChange={() => {
                  setNoCollege(!no_college);
                }}
              ></input>
              <Typography variant="body1">My Alma mater is not listed, let me type my own</Typography>
            </Box>

            <div
              style={{
                marginLeft: "140px",
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              {/* <Button
                            onClick={handleOpen}
                            variant="contained"
                            className={classes1.button}
                        >
                            Change Password
                        </Button> */}
            </div>
            <div className={classes1.buttonContainer2}>
              <Button variant="contained" className={classes1.button} onClick={handleUploadDetails}>
                {updatingDetails ? <CircularProgress size={25} style={{ color: "#ffffff" }} /> : "Update Profile"}
              </Button>
            </div>
          </div>
        ) : null}
        {open === "Edit Experience" ? (
          <div className={classes1.paperStyle}>
            <div>
              <Dialog open={open2} onClose={handleClose1} maxWidth="md">
                <DialogContent>
                  <div style={{ width: "650px" }}>
                    <Typography align="center" className={classes.grid3} variant="h6">
                      <b>Add Work Experience</b>&nbsp;
                    </Typography>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes.grid3} variant="body1">
                          <b>Title</b>&nbsp;
                          <span className={classes.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes.grid4}>
                        <TextField
                          id="date"
                          label="Job Title"
                          type="text"
                          // className={classes.textField}
                          onChange={(e) => {
                            setexpDesignation(e.target.value);
                          }}
                          value={expDesignation}
                          variant="outlined"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes.grid3} variant="body1">
                          <b>Employment Type</b>&nbsp;
                          <span className={classes.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes.grid4} id={theme.palette.type === "dark" && "theme-select-search"}>
                        <SelectSearch
                          value={expEmpType}
                          onChange={(newValue) => {
                            setExpEmpType(newValue);
                          }}
                          options={emptypes.map((option) => ({
                            name: option.name,
                            value: option.name,
                          }))}
                          placeholder="Select Employment Type"
                          style={{ width: "100%" }}
                          search
                        />
                      </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes.grid3} variant="body1">
                          <b>Company</b>&nbsp;
                          <span className={classes.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes.grid4} id={theme.palette.type === "dark" && "theme-select-search"}>
                        {compActive === false ? (
                          <SelectSearch
                            value={expCompany}
                            onChange={(newValue) => {
                              setExpCompany(newValue);
                            }}
                            options={comps.map((option) => ({
                              name: option.name,
                              value: option.name,
                            }))}
                            placeholder="Select Company"
                            style={{ width: "100%" }}
                            search
                          />
                        ) : (
                          <TextField
                            id="date"
                            label="Company"
                            type="text"
                            // className={classes.textField}
                            onChange={(e) => {
                              setExpCompanyName(e.target.value);
                            }}
                            value={expCompName}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={8} className={classes.grid4}>
                        <FormControlLabel
                          control={<Checkbox checked={compActive} onChange={(event) => setCompActive(event.target.checked)} name="gilad" />}
                          label="Your Company is Not Listed here. Write your own"
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Typography className={classes.grid3} variant="body1">
                          <b>Duration</b>&nbsp;
                          <span className={classes.redstar}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.grid4}>
                        <TextField
                          id="date"
                          label="Start Date"
                          type="date"
                          style={{ width: "100%" }}
                          className={classes.textField}
                          onChange={(e) => {
                            setExpStart(e.target.value);
                          }}
                          value={expStart}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={4} className={classes.grid4}>
                        {durationActive == false ? (
                          <TextField
                            min="2020-13-11"
                            id="date"
                            label="End Date"
                            type="date"
                            style={{ width: "100%" }}
                            className={classes.textField}
                            onChange={(e) => {
                              setExpEnd(e.target.value);
                            }}
                            value={expEnd}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{ inputProps: { min: String(expStart) } }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={8} className={classes.grid4}>
                        <FormControlLabel
                          control={<Checkbox checked={durationActive} onChange={(event) => setDurationActive(event.target.checked)} name="gilad" />}
                          label="I am currently working at this role"
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4} className={classes.grid4}></Grid>
                      <Grid item xs={4} className={classes.grid4}>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            type="submit"
                            className={classes.postButton}
                            onClick={() => {
                              AddExperience();
                              clearFields();
                            }}
                          >
                            {!addingExperience ? "Add Experience" : <CircularProgress size={25} style={{ color: "#ffffff" }} />}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </DialogContent>
              </Dialog>
            </div>

            <Button onClick={handleOpen1} variant="contained" className={classes.expButton}>
              <img src={plus} height="22px" />
            </Button>

            {workexp.map((data, index) => {
              return (
                <div>
                  <br />
                  <Typography variant="body1">Edit Experience {index + 1}</Typography>
                  <br />
                  <div className={classes1.fieldDiv}>
                    <Typography variant="body1" style={{ width: "170px" }}>
                      Title
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="text"
                      value={data.job_designation}
                      onChange={(e) => {
                        let ar = workexp;
                        ar[index].job_designation = e.target.value;
                        setWorkexp([...ar]);
                      }}
                      className={classes1.inputBox}
                      required
                    />
                  </div>
                  <div className={classes1.fieldDiv}>
                    <Typography variant="body1" style={{ width: "170px" }}>
                      Employment Type
                    </Typography>
                    <div className={classes1.radioStyle} id={theme.palette.type === "dark" && "theme-select-search"}>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <SelectSearch options={emptypes} value={data.job_type} onChange={setEmpType} placeholder="Select" />
                    </div>
                  </div>

                  <div className={classes1.fieldDiv}>
                    <Typography variant="body1" style={{ width: "170px" }}>
                      Company
                    </Typography>
                    {data.isActive == false ? (
                      <div className={classes1.radioStyle} id={theme.palette.type === "dark" && "theme-select-search"}>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <SelectSearch options={comps} value={data.company_name} onChange={setCompType} placeholder="Select" />
                      </div>
                    ) : (
                      <TextField
                        variant="outlined"
                        type="text"
                        value={data.company_name}
                        onChange={(e) => {
                          let ar = workexp;
                          ar[index].company_name = e.target.value;
                          setWorkexp([...ar]);
                        }}
                        className={classes1.inputBox}
                        required
                      />
                    )}
                  </div>
                  <Box display="flex" alignItems="center">
                    <input
                      type="checkbox"
                      style={{
                        marginLeft: "210px",
                        marginRight: "10px",
                      }}
                      checked={data.isActive}
                      onChange={(e) => {
                        let ar = workexp;
                        ar[index].isActive = !data.isActive;
                        setWorkexp([...ar]);
                      }}
                    ></input>{" "}
                    <Typography variant="body1">Your company is not listed here. Write your own.</Typography>
                  </Box>
                  <br />

                  <div className={classes1.firstBox}>
                    <div className={classes1.fieldDiv}>
                      <Typography variant="body1" style={{ width: "170px" }}>
                        Start Date
                      </Typography>
                      <div className={classes1.radioStyle}>
                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                        <TextField
                          variant="outlined"
                          onChange={(e) => {
                            let ar = workexp;
                            ar[index].start_date = e.target.value;
                            setWorkexp([...ar]);
                          }}
                          type="date"
                          value={data.start_date}
                        />
                      </div>
                    </div>
                    {data.isChecked === false ? (
                      <div className={classes1.fieldDiv}>
                        <div className={classes1.heading}>End Date</div>
                        <div className={classes1.radioStyle}>
                          &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                          <TextField
                            variant="outlined"
                            type="date"
                            onChange={(e) => {
                              let ar = workexp;
                              ar[index].end_date = e.target.value;
                              setWorkexp([...ar]);
                            }}
                            value={data.end_date}
                            min={data.start_date}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <Box display="flex" alignItems="center">
                    <input
                      type="checkbox"
                      style={{
                        marginLeft: "210px",

                        marginRight: "10px",
                      }}
                      onChange={(e) => {
                        let ar = workexp;
                        ar[index].isChecked = !data.isChecked;
                        setWorkexp([...ar]);
                      }}
                      checked={data.isChecked}
                    ></input>
                    <Typography variant="body1">I am currently working in this role</Typography>
                  </Box>
                  <br />
                  <div className={classes1.buttonContainer}>
                    <Button variant="contained" className={classes1.button} onClick={() => handleUploadDetails1(index, data.id)}>
                      {updatingExperience && selectedExperienceButton === index ? (
                        <CircularProgress size={25} style={{ color: "#ffffff" }} />
                      ) : (
                        `Update Work Experience ${index + 1}`
                      )}
                    </Button>
                  </div>
                  <div style={{ borderTop: "1px dotted #000", marginRight: "20px" }}></div>
                </div>
              );
            })}
          </div>
        ) : null}
        {open === "Referral" ? 
        <div style={{marginLeft: "290px", marginTop: "20px"}}>
          <UserReferral />
        </div> : null}
      </main>
    </>
  );
};

export default UserSettings;

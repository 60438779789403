import { Avatar, Box, Button, Card, CardContent, CircularProgress, createStyles, Grid, makeStyles, Modal } from "@material-ui/core";
import { CreditCardOutlined, WorkOutline } from "@material-ui/icons";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from 'react-redux';
import { Breakpoint, BreakpointProvider } from "react-socks";
import CompanyLogo from "../../../components/common/CompanyLogo/CompanyLogo";
import { GetMentoredButton } from "../../../components/student/Mentorship/GetMentoredButtton";
import { BackendBaseApi } from "../../../constants/constants";
import kFormatter from "../../../utils/common/ZeroToK";
import ViewJob from "../viewJob/ViewJob";

export default function SmallCard({ data, setSingleJobData, singleJobData, viewjob, apply, setIndex, getSingleJobData }) {
  const [open, handleClose] = useState(false);
  const [jobData, setJobData] = useState([]);
  const classes = useStyles();
  const [selectedJobData, setSelectedJobData] = useState(null);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  const openJob = (item, index) => {
    setJobData(item);
    setIndex(index);

    handleClose(!open);
  };
  const callback = (value) => {
    handleClose(value);
    // do something with value in parent component, like save to state
  };

  useEffect(() => {
    if (singleJobData) setSelectedJobData(singleJobData);
  }, [singleJobData]);

  return data.length && selectedJobData
    ? data.map((item, index) => (
        <BreakpointProvider>
          <Breakpoint large up>
            <div className="small-card" onClick={() => openJob(item, index)}>
              <Grid container>
                <Grid xs={12}>
                  <Card
                    style={{ borderRadius: "0px" }}
                    onClick={() => {
                      // setSingleJobData(item);
                      getSingleJobData(item.job_id);
                      setIndex(index);
                    }}
                  >
                    <CardContent style={{borderLeft:`3px solid ${domain?.domain ? domain?.color_code : "#007bff"}`}}  className={selectedJobData.id === item.job_id ? classes.activecard : classes.inActiveCard}>
                      <Grid container>
                        <Grid xs={12}>
                          <Grid container className="job-card__top-header">
                            <Grid sm={3} xs={2}>
                              <Box className={classes.avatarBox}>
                                <CompanyLogo
                                  companyWebsite={item.company?.company_logo}
                                  styles={{ width: "75%", height: "75%",}}
                                />
                              </Box>
                            </Grid>
                            <Grid xs={9} className="flex justify-space-between">
                              <div className="flex flex-col job-card__top-header__company-detail relative">
                                <h4 className={classes.cardTitle} onClick={() => openJob(item, index)}>
                                  {item.job_title}
                                </h4>
                                <p className={classes.otherText}>{item.is_posted_by_college ? "ON CAMPUS" : item.hiring_type}</p>

                                <p className={classes.companyName}>{item.company ? item.company.company_name : item.company_details}</p>
                                <p className={classes.otherText}>{item.company?.company_location}</p>
                              </div>
                              <div>
                                <div className=" flex align-item-center">
                                  <SaveIcon jobId={item.job_id} isSave={item.is_saved} />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container className="mg-top-10">
                          <Grid
                            xs={12}
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            <div className={classes.detailsBox}>
                              <WorkOutline
                                style={{
                                  fontSize: "20px",
                                  marginRight: "4px",
                                  marginLeft: "8px",
                                }}
                              />
                              <span className="mg-right-5 text-green" style={{ fontSize: "14px" }}>
                                {item.job_type_name}
                              </span>
                              <CreditCardOutlined
                                style={{
                                  fontSize: "20px",
                                  marginRight: "4px",
                                  marginLeft: "12px",
                                }}
                              />
                              <span className="mg-right-5 text-green" style={{ fontSize: "14px" }}>
                                {kFormatter(item.ctc_min)} - {kFormatter(item.ctc_max)} {item.salary_payment_type.toLowerCase()}
                              </span>{" "}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Breakpoint>
          <Breakpoint medium down>
            <div className="small-card mg-bottom-10" onClick={() => openJob(item, index)}>
              <Grid container>
                <Grid xs={12}>
                  <Card
                    style={{
                      borderLeft: `3px solid ${domain?.domain ? domain?.color_code : "#007bff"}`,
                    }}
                    //onClick={() => setSingleJobData(item)}
                    onClick={() => {
                      // setSingleJobData(item);
                      setIndex(index);
                    }}
                  >
                    <CardContent style={{borderLeft:`3px solid ${domain?.domain ? domain?.color_code : "#007bff"}`}} className={selectedJobData?.id === item?.job_id ? classes.activecard : classes.inActiveCard}>
                      <Grid container>
                        <Grid xs={12}>
                          <Grid container justify="space-around" className="job-card__top-header">
                            <Grid sm={3} xs={2}>
                              <Box className={classes.avatarBox}>
                                <CompanyLogo
                                  companyWebsite={item.company.company_logo}
                                  styles={{ width: "75%", height: "75%",}}
                                />
                              </Box>
                            </Grid>
                            <Grid xs={9} className="flex justify-space-between">
                              <div className="flex flex-col job-card__top-header__company-detail relative">
                                <h4 className={classes.cardTitle} onClick={() => openJob(item)}>
                                  {item.job_title}
                                </h4>
                                <p className={classes.otherText}>{item.hiring_type}</p>
                                <p className={classes.companyName}>{item.company ? item.company.company_name : item.company_details}</p>
                                <p className={classes.otherText}>{item.company?.company_location}</p>
                              </div>
                              <div>
                                <div className="mg-right-15 mg-top-10 flex align-item-center">
                                  <SaveIcon jobId={item.job_id} isSave={item.is_saved} />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container className="mg-top-10">
                          <Grid
                            xs={12}
                            className="fs-12 job-card__job-details"
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            <div className={classes.detailsBox}>
                              <span className="mg-right-15">{item.employment_type_name}</span>
                              <span className="mg-right-15 text-green">
                                {kFormatter(item.ctc_min)} - {kFormatter(item.ctc_max)} per {item.salary_payment_type.toLowerCase()}
                              </span>
                              <span className="mg-right-15">{/* 1.5%-2% */}</span>
                            </div>
                            </Grid>
                            <Grid
                            xs={12}
                            className="fs-12 job-card__job-details"
                            style={{
                              boxShadow: "none",
                              justifyContent: "end"
                            }}
                          >
                              <GetMentoredButton jobRole={item.job_role_name}/>
                            {apply && (
                              
                                <ApplyButton jobId={item.job_id} reject={false} />
                             
                            )} 
                             </Grid>
                            {/* {
                                   interview && (<> <ApplyButton jobId={item.job_id}/></>)
                               }

                               {
                                   reject && (<><RejectButton jobId={item.job_id}/> <ApplyButton jobId={item.job_id}/></>)
                               } */}
                          </Grid>
                        </Grid>
                      {/* </Grid> */}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Modal open={open} onClose={() => handleClose(!open)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <Grid container justify="center">
                  <Grid xs={12} sm={12}>
                    <ViewJob data={jobData} apply={true} open={true} handleClosey={callback} />
                  </Grid>
                </Grid>
              </Modal>
            </div>
          </Breakpoint>
        </BreakpointProvider>
      ))
    : "";
}

const useStyles = makeStyles((theme) =>
  createStyles({
    activecard: {
      cursor: "pointer",
      borderLeft: `7px solid #3282c4`,
    },
    inActiveCard: {
      backgroundColor: "#ffffff",
      cursor: "pointer",
      borderLeft: "7px solid #ffffff",
      "&:hover": {
        backgroundColor: "#EEFBFF",
      },
    },
    avatarBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
      width: "68px",
      height: "68px",
    },
    cardTitle: {
      fontSize: "14px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      color: "#000000",
      marginBottom: "0px",
      textTransform: "capitalize",
    },
    companyName: {
      fontSize: "14px",
      fontFamily: "ProximaNova",
      color: "#424242",
      fontWeight: "bold",
      marginBottom: "0px",
    },
    otherText: {
      fontSize: "14px",
      fontFamily: "ProximaNova",
      color: "#424242",
      marginBottom: "0px",
    },
    detailsBox: {
      width: "100%",
      display: "flex",
    },
    actionButton: {
      color: "#ffffff",
      fontWeight: "700",
      borderRadius:"30px"
    },
    "@media only screen and (max-width: 768px)": {
      inActiveCard: {
        borderLeft: "3px solid #ffffff",
      },
      activecard: {
        borderLeft: "none",
      },
      actionButton: {
        // height: "28px",
        // width: "40px",
        fontSize: "12px",
        // float: "right",
      }
    },
  })
);

const ApplyButton = ({ jobId, reject }) => {
  const [done, setDone] = useState(false);
  const alert = useAlert();
  const classes = useStyles();
  const [buttonLoading, setButtonLoading] = useState(false);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  const data = JSON.parse(localStorage.getItem("user_details"));

  const handelActive = (id) => {
    setButtonLoading(true);
    fetch(BackendBaseApi.NIYUKTI + "job/student/apply/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${id}&user_id=${data.id}&round=1&status=1&feedback=1&college_id=${
        JSON.parse(localStorage.getItem("user_details")).student_user_details.education.find((item) => item.is_current === true).college
      }`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert.success(data.data.message);
          setDone(true);
        } else {
          alert.error(data.error);
        }
        setButtonLoading(false);
      });
  };
  return (
    <Button
      variant="contained"
      style={{backgroundColor: (domain?.domain ? domain?.color_code : "#007bff"), float: "right" }}
      className={classes.actionButton}
      disabled={done}
      onClick={(e) => {
        e.stopPropagation();
        handelActive(jobId);
      }}
    >
      {buttonLoading ? <CircularProgress size={24} style={{ color: "#ffffff" }} /> : reject ? done ? "Accepted" : "Accept" : done ? "Applied" : "Apply"}
    </Button>
  );
};

const SaveIcon = ({ jobId, isSave }) => {
  const data = JSON.parse(localStorage.getItem("user_details"));
  const [done, setDone] = useState(isSave);
  const alert = useAlert();
  const handelActive = (jobId) => {
    if (done) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=unsave`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert.success(data.data.message);
          setDone(!done);
          // updateData(jobId)
        })
        .catch((err) => {
          throw err;
        });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=saved`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert.success(data.data.message);
          setDone(!done);
          // updateData(jobId)
        });
    }
  };
  return (
    <>
      {done ? (
        <>
          {" "}
          <BookmarkIcon
            className="pointer text-blue"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          />
          <span className="fs-12 text-blue"></span>
        </>
      ) : (
        <>
          {" "}
          <BookmarkBorderIcon
            className="pointer"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          />
          <span className="fs-12 text-blue"></span>
        </>
      )}
    </>
  );
};

import { Paper } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ResumeStepper from "./ResumeSteper";

const useStyles = makeStyles((theme) => ({
  paper: {
    // minHeight: "600px",
    borderRadius: "20px",
    textAlign: "center",
    padding: 25,
    width: "100%",
  },
}));

function FillResume(props) {
  const {
    basicDetailsFilled,
    setBasicDetailsFilled,
    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    currentCity,
    setCurrentCity,
    currentHomeCity,
    setCurrentHomeCity,
    currentGender,
    setCurrentGender,
    allCities,
    buttonClicked,
    setButtonClicked,
    openAddEducation,
    setOpenAddEducation,
    allColleges,
    allBoards,
    educationDetails,
    setEducationDetails,
    educationDetailsErrors,
    setEducationDetailsErrors,
    educationDetailsList,
    setEducationDetailsList,
    activeStep,
    setActiveStep,
    workExpDetails,
    setWorkExpDetails,
    workExpDetailsErrors,
    setWorkExpDetailsErrors,
    openAddWorkExp,
    setOpenAddWorkExp,
    workExpDetailsList,
    setWorkExpDetailsList,
    allCompanies,
    setAllCompanies,
    allJobType,
    allSkills,
    skillDetails,
    setSkillDetails,
    skillDetailsErrors,
    setSkillDetailsErrors,
    skillDetailsList,
    setSkillDetailsList,
    linkList,
    setLinkList,
    allSocialLinks,
    projectDetails,
    setProjectDetails,
    projectDetailsErrors,
    setProjectDetailsErrors,
    projectList,
    setProjectList,
    educationLoader,
    expLoader,
    skillsLoader,
    projectLoader,
    linksLoader,
    previewImage,
    setPreviewImage,
    setProfilePicChanged,
    profilePicChanged,
    openAddAchievements,
    setOpenAddAchievements,
    openAddHobbies,
    setOpenAddHobbies,
    openAddLanguages,
    setOpenAddLanguages,
    openAddProjects,
    setOpenAddProjects,
    extraData,
    setExtraData,
    fetchPrecentage,
    getUserDetails,
    mainRef,
    executeScroll,
    instituteList,
    openAddCertificate,
    setOpenAddCertificate,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <ResumeStepper
          basicDetailsFilled = {basicDetailsFilled}
          setBasicDetailsFilled = {setBasicDetailsFilled}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
          currentCity={currentCity}
          setCurrentCity={setCurrentCity}
          currentHomeCity={currentHomeCity}
          setCurrentHomeCity={setCurrentHomeCity}
          currentGender={currentGender}
          setCurrentGender={setCurrentGender}
          allCities={allCities}
          buttonClicked={buttonClicked}
          setButtonClicked={setButtonClicked}
          openAddEducation={openAddEducation}
          setOpenAddEducation={setOpenAddEducation}
          educationDetails={educationDetails}
          setEducationDetails={setEducationDetails}
          allBoards={allBoards}
          allColleges={allColleges}
          educationDetailsErrors={educationDetailsErrors}
          setEducationDetailsErrors={setEducationDetailsErrors}
          setEducationDetailsList={setEducationDetailsList}
          educationDetailsList={educationDetailsList}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          workExpDetails={workExpDetails}
          setWorkExpDetails={setWorkExpDetails}
          workExpDetailsErrors={workExpDetailsErrors}
          setWorkExpDetailsErrors={setWorkExpDetailsErrors}
          openAddWorkExp={openAddWorkExp}
          setOpenAddWorkExp={setOpenAddWorkExp}
          workExpDetailsList={workExpDetailsList}
          setWorkExpDetailsList={setWorkExpDetailsList}
          allCompanies={allCompanies}
          setAllCompanies={setAllCompanies}
          allJobType={allJobType}
          allSkills={allSkills}
          skillDetails={skillDetails}
          setSkillDetails={setSkillDetails}
          skillDetailsErrors={skillDetailsErrors}
          setSkillDetailsErrors={setSkillDetailsErrors}
          skillDetailsList={skillDetailsList}
          setSkillDetailsList={setSkillDetailsList}
          linkList={linkList}
          setLinkList={setLinkList}
          allSocialLinks={allSocialLinks}
          projectDetails={projectDetails}
          setProjectDetails={setProjectDetails}
          projectDetailsErrors={projectDetailsErrors}
          setProjectDetailsErrors={setProjectDetailsErrors}
          projectList={projectList}
          setProjectList={setProjectList}
          educationLoader={educationLoader}
          expLoader={expLoader}
          skillsLoader={skillsLoader}
          projectLoader={projectLoader}
          linksLoader={linksLoader}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
          setProfilePicChanged={setProfilePicChanged}
          profilePicChanged={profilePicChanged}
          openAddAchievements={openAddAchievements}
          setOpenAddAchievements={setOpenAddAchievements}
          openAddHobbies={openAddHobbies}
          setOpenAddHobbies={setOpenAddHobbies}
          openAddLanguages={openAddLanguages}
          setOpenAddLanguages={setOpenAddLanguages}
          openAddProjects={openAddProjects}
          setOpenAddProjects={setOpenAddProjects}
          extraData={extraData}
          setExtraData={setExtraData}
          fetchPrecentage={fetchPrecentage}
          getUserDetails={getUserDetails}
          mainRef={mainRef}
          executeScroll={executeScroll}
          instituteList={instituteList}
          openAddCertificate={openAddCertificate}
          setOpenAddCertificate={setOpenAddCertificate}
        />
      </Paper>
    </>
  );
}

export default FillResume;

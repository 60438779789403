import React, { useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Dialog,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddCommentDialog from "./AddCommentDialog";
import AllComment from "../Cards/AllComment";
import fetchApi from "../../apiCall/fetchApi";
import { BackendBaseApi } from "../../../constants/constants";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "20px",
    border: ".5px solid #707070",
    boxShadow: "none",
    padding: "5px",
    paddingBottom: "10px",
    overflowY: "scroll",
  },
  btn: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    height: "40px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CommentDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [commentsData, setCommentsData] = useState(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentsError, setCommentsError] = useState(false);
  const [currentApplicant, setCurrentApplicant] = useState(null);
  let { token } = useParams();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getCommentsApi();
  };

  useEffect(() => {
    if (props?.currentApplicant) {
      getCommentsApi();
      setCurrentApplicant(props?.currentApplicant);
    }
  }, [props?.currentApplicant]);

  const getCommentsApi = () => {
    fetchApi({
      url: props?.isPublic
        ? `${BackendBaseApi.NIYUKTI}api/track_sharer/company/comments/?action=${token}&job_applicant_id=${props?.currentApplicant?.applicantId}`
        : `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/comments/?job_applicant_id=${props?.currentApplicant?.applicantId}`,
      headers: props?.isPublic
        ? JSON.stringify({ withCredentials: true })
        : JSON.stringify({
            headers: {
              Authorization: `Token ${localStorage.getItem("gw_token")}`,
            },
          }),
      setResponse: setCommentsData,
      setLoading: setLoadingComments,
      setError: setCommentsError,
    });
  };

  return (
    <div>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
        {loadingComments ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size={60}
              style={{ marginTop: 30, marginBottom: 30 }}
            />
          </div>
        ) : (
          <div style={{ padding: "15px" }}>
            {commentsError ? (
              <Typography
                style={{ color: "red", display: "flex", margin: "auto" }}
              >
                {commentsError}
              </Typography>
            ) : (
              <AllComment
                data={
                  commentsData?.data?.comments
                    ? commentsData?.data?.comments
                    : []
                }
                currentApplicant={props?.currentApplicant}
              />
            )}
            {!props?.isPublic && (
              <Grid container spacing={4} style={{ marginTop: 10 }}>
                <Grid item sm={6} style={{ display: "grid" }}>
                  <Button
                    className={classes.btn}
                    variant="outlined"
                    color="primary"
                    onClick={props.handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item sm={6} style={{ display: "grid" }}>
                  <Button
                    className={classes.btn}
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    Add Comment
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        )}

        {currentApplicant?.applicantId && (
          <AddCommentDialog
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            selectedRow={{
              details: [
                {
                  id: currentApplicant?.applicantId,
                  name: currentApplicant?.applicantName,
                },
              ],
              selectedIds: [currentApplicant?.applicantId],
            }}
            setSelectedRow={null}
            activeStatusObject={props?.activeStatusObject}
          />
        )}
      </Dialog>
    </div>
  );
}

import {
  FILTER_COUNT,
  GET_LOCATIONS,
  UPDATE_EDUCATION_LEVELS,
  UPDATE_LOCATIONS,
  UPDATE_MIN_CTC,
  UPDATE_JOB_SEGMENT,
  GET_JOB_SEGMENT,
} from "./Forms/action";
import {
  GET_DEGREES_WITH_ID,
  GET_COURSES_WITH_ID,
  UPDATE_DEGREES,
  UPDATE_COURSES,
  GET_DEGREES_ALL,
  UPDATE_COLLEGE_FILTER,
} from "./Forms/action";
const initialState = {
  education_levels: [],
  degrees: [],
  courses: [],
  location: [],
  ctc: 0,
  job_segment: [],
  num: 0,
};

const initialStateApply = {
  education_levels: [],
  degrees: [],
  courses: [],
  location: [],
  ctc: 0,
  job_segment: [],
  num: 0,
};

const CollegeFilterState = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EDUCATION_LEVELS:
      return { ...state, education_levels: action.payload };
    case UPDATE_DEGREES:
      return { ...state, degrees: action.payload };
    case UPDATE_COURSES:
      return { ...state, courses: action.payload };
    case UPDATE_LOCATIONS:
      return { ...state, location: action.payload };
    case UPDATE_MIN_CTC:
      return { ...state, ctc: action.payload };
    case UPDATE_JOB_SEGMENT:
      return { ...state, job_segment: action.payload };
    case FILTER_COUNT:
      return { ...state, num: action.payload };
    case UPDATE_COLLEGE_FILTER:
      return { ...state, initialStateApply: action.payload };

    default:
      return state;
  }
};

export const DegreesList = (state = { degreeList: {} }, action) => {
  switch (action.type) {
    case GET_DEGREES_WITH_ID:
      return { ...state, degreeList: action.payload };
    default:
      return state;
  }
};

export const CoursesList = (state = { courseList: {} }, action) => {
  switch (action.type) {
    case GET_COURSES_WITH_ID:
      return { ...state, courseList: action.payload };
    default:
      return state;
  }
};
export const AllDegreesList = (state = { list: {} }, action) => {
  switch (action.type) {
    case GET_DEGREES_ALL:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};

export const CityList = (state = { cityList: {} }, action) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return { ...state, cityList: action.payload };
    default:
      return state;
  }
};

export const JobSegmentList = (state = { JobSegmentList: {} }, action) => {
  switch (action.type) {
    case GET_JOB_SEGMENT:
      return { ...state, JobSegmentList: action.payload };
    default:
      return state;
  }
};
export const FilterCount = (state = { num: {} }, action) => {
  switch (action.type) {
    case FILTER_COUNT:
      return { ...state, num: action.payload };
    default:
      return state;
  }
};

export default CollegeFilterState;

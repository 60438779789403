import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Chip,
  Grid,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import pSBC from "shade-blend-color";

let red = "#C4161C";
let cgreen = "#0B8A00";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    borderRadius: "12px",
  },
  rate: {
    backgroundColor: pSBC(0.8, cgreen),
    color: cgreen,
    borderColor: cgreen,
    border: "1px solid",
  },

  greenchip: {
    marginLeft: "20px",
    backgroundColor: pSBC(0.8, cgreen),
    color: cgreen,
    borderColor: cgreen,
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  redchip: {
    marginLeft: "20px",
    backgroundColor: pSBC(0.8, red),
    color: red,
    borderColor: red,
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  item: {
    display: "flex",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  griditem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
}));

export default function SkillsRatingNew({ data, relevanceScore }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.overview}>
        <Grid container spacing={3} colSpacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5" style={{ color: "#00B533" }}>
              {relevanceScore}
            </Typography>
          </Grid>
          <Grid item sm={4} className={classes.griditem}>
            <Typography variant="body2">Key Skills</Typography>

            <div style={{ display: "flex", alignItems: "center" }}>
              <CircularProgress
                variant="determinate"
                size={15}
                style={{
                  color:
                    data?.key_skills >= 70
                      ? "green"
                      : data?.key_skills > 50
                      ? "orange"
                      : "red",
                }}
                value={data?.key_skills}
              />
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                {data?.key_skills ?? 0}% Match
              </Typography>
            </div>
          </Grid>
          <Grid item sm={4} className={classes.griditem}>
            <Typography variant="body2">Location</Typography>

            <Chip
              size="small"
              label={
                <Typography
                  color="inherit"
                  style={{ fontWeight: "400", fontSize: "12px" }}
                >
                  {data?.location ? "Match" : "Not Match"}
                </Typography>
              }
              className={data?.location ? classes.greenchip : classes.redchip}
            />
          </Grid>
          <Grid item sm={4} className={classes.griditem}>
            <Typography variant="body2">Salary</Typography>

            <Chip
              size="small"
              label={
                <Typography
                  color="inherit"
                  style={{ fontWeight: "400", fontSize: "12px" }}
                >
                  {data?.salary ? "Match" : "Not Match"}
                </Typography>
              }
              className={data?.salary ? classes.greenchip : classes.redchip}
            />
          </Grid>{" "}
          <Grid item sm={4} className={classes.griditem}>
            <Typography variant="body2">Comm Skills</Typography>{" "}
            <div style={{ display: "flex", alignItems: "center" }}>
              <CircularProgress
                variant="determinate"
                style={{
                  color:
                    data?.key_skills >= 70
                      ? "green"
                      : data?.key_skills > 50
                      ? "orange"
                      : "red",
                }}
                size={15}
                value={data?.communication_skills}
              />
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                {data?.communication_skills ?? 0}% Match
              </Typography>
            </div>
          </Grid>
          <Grid item sm={4} className={classes.griditem}>
            <Typography variant="body2">Work Ex</Typography>

            <Chip
              size="small"
              label={
                <Typography
                  color="inherit"
                  style={{ fontWeight: "400", fontSize: "12px" }}
                >
                  {data?.work_exp ? "Match" : "Not Match"}
                </Typography>
              }
              className={data?.work_exp ? classes.greenchip : classes.redchip}
            />
          </Grid>{" "}
          <Grid item sm={4} className={classes.griditem}>
            <Typography variant="body2">Education</Typography>

            <Chip
              size="small"
              label={
                <Typography
                  color="inherit"
                  style={{ fontWeight: "400", fontSize: "12px" }}
                >
                  {data?.education ? "Match" : "Not Match"}
                </Typography>
              }
              className={data?.education ? classes.greenchip : classes.redchip}
            />
          </Grid>
          {/*  <Grid item sm={12}>
            <Divider />
          </Grid> */}
        </Grid>
      </div>
    </>
  );
}

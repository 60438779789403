import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import SideImage from "../UI/SideImage";
import AuthHeader from "../UI/AuthHeader";
import { Tooltip } from "reactstrap";
import { EndPointPrefix, BackendBaseApi } from "../../../../constants/constants";
import Loader from "../UI/Loader";

const ForgotPassword = () => {
  //axios.defaults.withCredentials=true;
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  //UI state variables
  const [step, setStep] = useState(1);
  const disableButton = useRef();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  //state variable
  const [Email, setEmail] = useState("");

  useEffect(() => {
    if (step == 1) disableButton.current.classList.add("disabled");
  }, [step]);

  useEffect(() => {
    if (step == 1) {
      if (Email) disableButton.current.classList.remove("disabled");
      else disableButton.current.classList.add("disabled");
    }
  }, [Email]);

  const callComponentMountState = () => {
    setStep(step - 1);
    setEmail("");
    // disableButton.current.classList.add('disabled');
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(BackendBaseApi.PRAVESH + "api/email/generate", { email: Email })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          alert.success(res.data.data.message);
          setStep(step + 1);
        } else {
          alert.error(res.data.error);
          return;
        }
      })
      .catch((err) => {
        localStorage.clear();
        alert.error("Invalid Session!! Please try again");
      });
    //alert.success(`An email has been sent to ${Email}`)
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div
            className="col-md-8 col-lg-8"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="row">
              <AuthHeader />
            </div>
            <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-5 mx-auto">
                    {step == 1 && (
                      <>
                        <h1
                          className="mb-4 login-heading text-left"
                          style={{ color: "#000" }}
                        >
                          Reset your password
                        </h1>

                        <form onSubmit={handleSubmit} className="my-md-0 my-2">
                          <div className="form-group text-left">
                            <label
                              htmlFor="Email"
                              className="fw-500"
                              style={{ color: "#424242" }}
                            >
                              {" "}
                              Email{" "}
                            </label>
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              {" "}
                              Enter your associated Email Address and we'll send
                              you a link to reset your password
                            </small>
                            <input
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              type="email"
                              className="form-control mt-2 mb-1 shadow_1-lightest"
                              id="Email"
                              value={Email}
                              aria-describedby="emailHelp"
                              placeholder=""
                              required
                              autoFocus
                            />
                          </div>

                          <div className="row my-1 pt-md-2 pt-3">
                            <div className="col-6 text-left">
                              <button
                                ref={disableButton}
                                className=" shadow_1 btn btn-lg btn-main btn-login text-uppercase font-weight-bold mb-2"
                                type="submit"
                              >
                                Send <i className="far fa-paper-plane"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                    {step == 2 && (
                      <>
                        <div className="row justify-content-center mx-auto my-2">
                          <i className="far fa-check-square fa-3x"></i>
                        </div>

                        <div className="row justify-content-center mx-auto my-2">
                          <p className="fs-18 fw-500">
                            Password reset link sent!
                          </p>
                          <p className="fs-14">
                            We've sent you a link to reset your password on{" "}
                            <span className="color-blue">{Email}</span>. Please
                            follow the steps further to reset your password.
                          </p>

                          <p className="fs-14">
                            Didn't receive an email?{" "}
                            <a
                              className="link-text fw-500"
                              style={{
                                color: "#3282c4",
                              }}
                              onClick={callComponentMountState}
                            >
                              Click here
                            </a>{" "}
                            to send the link again.
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

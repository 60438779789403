import {
  Box,
  Button, CircularProgress,
  TextField, Typography, useTheme
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
// import {Icon, Rating, Grid, Image, Segment } from 'semantic-ui-react';
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ArrowBackIos } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { loadCSS } from "fg-loadcss";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-images-uploader/font.css";
import "react-images-uploader/styles.css";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { CardBody, Col, Form, Row } from "reactstrap";
import pic from "../../../assets/common/edit3.svg";
import view from "../../../assets/common/search.svg";
import eye from "../../../assets/images/eye.png";
import logoLoading from "../../../assets/payment-loading.gif";
import { BackendBaseApi } from "../../../constants/constants";
import { getUserDetails } from "../Dashboard/action";
import "./custom.css";
import styles from "./ProfileCSS";

export default function CollegeProfile(props) {
  const [urll, setUrl] = useState(
    BackendBaseApi.PRAVESH + "api/college/edit_profile/"
  );
  const alert = useAlert();

  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false);
  const [isAbout, setIsAbout] = useState();
  const [isDescription, setIsDescription] = useState();
  const [isName, setIsName] = useState();
  const [isMessage, setIsMessage] = useState();
  const [location, setIsLocation] = useState();
  const [logo, setIsLogo] = useState("");
  const [banner, setIsBanner] = useState("");
  const [faqs, setIsFaq] = useState("");
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [post, setPost] = useState({ data: {} });
  const [file, setFile] = useState({ data: {} });
  const [bannerurl, setBanner] = useState();
  const [image, setImage] = useState({ preview: "" });
  const [imagelogo, setImagelogo] = useState({ previewlogo: "" });
  const [educationTypes, setEducationTypes] = useState({});
  const [eduType, setEduType] = useState("Search");
  const [Degrees, setDegrees] = useState({});
  const [degreeType, setDegreeType] = useState("Search");
  const [Courses, setCourses] = useState({});
  const [courseType, setCourseType] = useState("Search");
  const [courseArray, setCourseArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [FAQ, setFAQ] = useState("");
  const [bannerLoading, setBannerLoading] = useState(false);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText(FAQ))
  );

  const handleEditorStateChange = (editor_state) => {
    setEditorState(editor_state);
  };

  useEffect(() => {
    const Faqs = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (Faqs) {
      setFAQ(Faqs.toString());
    }
  }, [editorState]);

  useEffect(() => {
    setIsLogo(localStorage.getItem("logo"));
  }, [localStorage.getItem("logo")]);

  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  const fetchData = () => {
    setLoading(true);
    axios
      .get(urll, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          setFile(res.data.data);

          setPost(res.data.data);
          setIsName(res.data.data.name);
          setIsAbout(res.data.data.about);
          setIsDescription(res.data.data.description);
          setIsMessage(res.data.data.message_for_recruiters);
          setIsLocation(res.data.data.location);
          setIsLogo(res.data.data.logo);
          setIsBanner(res.data.data.banner);
          setFAQ(String(res.data.data.faqs));
          // setIsFaq("hek");
          setCity(res.data.data.city_name);
          setState(res.data.data.state_name);
          setCourseArray(res.data.data.degree_specialization);
          setEduType("Search");
          setDegreeType("Search");
          setCourseType("Search");
          localStorage.setItem("logo", res.data.data.logo);
          setLoading(false);
        }
      })
      .catch((error) => {
        alert.error(error);
      });
  };
  useEffect(() => {
    fetchData();
    getEducationType();
  }, []);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
  };

  const { buttonLabel, className } = props;

  const [modall, setModal1] = useState(false);

  const toggle1 = () => setModal1(!modall);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  function submit(e) {
    e.preventDefault();
    // debugger
    // const fd = new FormData();
    // fd.append('about',post.about)
    // fd.append('description', post.description)
    // fd.append('message_for_recruiters', post.message_for_recruiters)
    // fd.append('faqs', post.faqs)
    // fd.append('banner', file)
    if (isAbout.replace(/\s/g, "").length <= 512) {
      const fd = {
        about: isAbout,
        description: isDescription,
        message_for_recruiters: isMessage,
        faqs: FAQ,
      };

      // fd.append('message_for_recruiters', post.message_for_recruiters)
      axios
        .patch(urll, fd, {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            alert.success("Data Updated Successfully");
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((error) => {
          alert.error(error);
        });
    } else alert.error("About Section should be less then 512 characters..");
  }

  const handleCourseDetails = () => {
    const body1 = {
      degree_specialization: courseArray,
    };
    axios
      .patch(urll, body1, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Successfully");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        alert.error("Error occured!");
      });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage(img);
    }
  };

  const blobToImage = (blob) => {
    return new Promise((resolve) => {
      setLoading(true);
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;

      // setImgtemp(blob);

      const fd = new FormData();
      fd.append("logo", blob);
      axios
        .patch(urll, fd, {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success) {
            alert.success("Logo uploaded Successfully");
            setIsLogo(res.data.data.logo[0]);
            dispatch(
              getUserDetails(
                history,
                alert,
                localStorage.getItem("user_type_id")
              )
            );
            setLoading(false);
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          alert.error("Error occured!");
          setLoading(false);
        });
    });
  };

  const onImageChange1 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      blobToImage1(img);
    }
  };

  const blobToImage1 = (blob) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;

      // setImgtemp(blob);

      const fd = new FormData();
      fd.append("banner", blob);
      setBannerLoading(true);
      axios
        .patch(urll, fd, {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success) {
            alert.success("Banner uploaded Successfully");
            setIsBanner(res.data.data.banner[0]);
          } else {
            alert.error(res.data.error);
          }
          setBannerLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setBannerLoading(false);
          alert.error("Error occured!");
        });
    });
  };

  // specialization
  const getEducationType = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/education/type")
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            if (c.id !== 1 && c.id !== 2) temp[c.type] = c.id;
          });
          setEducationTypes({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDegrees = (id, e) => {
    setEduType(e.target.value);
    const urll =
      BackendBaseApi.PRAVESH +
      "api/education/degree?search=" +
      id +
      "&admin=true";

    axios
      .get(urll)
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            if (c.is_specialized == true) {
              temp[c.name] = c.id;
            }
          });
          setDegrees({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCourses = (id, e) => {
    setDegreeType(e.target.value);
    const urll =
      BackendBaseApi.PRAVESH +
      "api/education/getspecialization?search=" +
      id +
      "&admin=true";

    axios
      .get(urll)
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.forEach((c) => {
            temp[c.specialization.name] = c.specialization.id;
          });
          setCourses({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setCourseArray(
      courseArray.filter((item, pos) => courseArray.indexOf(item) === pos)
    );
  }, [courseArray.length]);

  const theme = useTheme();
  const history = useHistory();

  return (
    <div className="collegeprofile">
      <br />
      <Box padding="4%">
        <div
          style={{
            padding: "40px",
            backgroundColor: theme.palette.background.paper,
            border:
              theme.palette.type === "dark"
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "1px solid #ced4da",
            borderRadius: "25px",
            boxShadow:
              theme.palette.type === "dark"
                ? theme.shadows[0]
                : theme.shadows[4],
          }}
        >
          <CardBody>
            <Desktop>
              <Box marginBottom="15px" marginTop="-25px">
                <Button
                  variant="contained"
                  style={{
                    borderRadius:"30px"
                  }}
                  startIcon={<ArrowBackIos />}
                  onClick={() => history.goBack()}
                >
                  Back
                </Button>
              </Box>
              {banner ? (
                <>
                  <div style={styles.images12}>
                    <div
                      className="hello img_edit1"
                      style={{
                        border: "2px solid #e7e6e6",
                        borderRadius: "25px",
                        width: "100%",
                      }}
                    >
                      <input
                        type="file"
                        id="image1"
                        style={{ display: "none" }}
                        onChange={onImageChange1}
                      ></input>
                      <label
                        for="image1"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {bannerLoading ? (
                          <Box
                            height="200px"
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress />
                          </Box>
                        ) : (
                          <img
                            src={banner}
                            width="100%"
                            height="200"
                            style={{
                              objectFit: "cover",borderRadius:"30px"
                            }}
                          />
                        )}
                      </label>
                    </div>
                  </div>
                  <Link to="/public/college/profile" target="_blank">
                    <p
                      style={{
                        textAlign: "right",
                        color: "rgb(76, 135, 190)",
                        marginTop: "10px",
                      }}
                    >
                      <img src={view} height="20px" />
                      &nbsp; View your public profile
                    </p>
                  </Link>
                </>
              ) : (
                <>
                  <div style={styles.emptyBanner12}>
                    <input
                      type="file"
                      id="image1"
                      style={{ display: "none" }}
                      onChange={onImageChange1}
                    ></input>
                    <label for="image1">Add an image</label>
                  </div>
                  <Link to="/public/college/profile" target="_blank">
                    <p
                      style={{
                        textAlign: "right",
                        color: "rgb(76, 135, 190)",
                      }}
                    >
                     <img src={view} height="20px" />
                      &nbsp; View your public profile
                    </p>
                  </Link>
                </>
              )}

              {logo ? (
                <div style={styles.avatarBox12}>
                  <Avatar
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "75px",
                      border: "7px solid #e7e6e6",
                      margin: "auto",
                      top: -100,
                    }}
                    alt="Remy Sharp"
                    src={loading ? logoLoading : logo}
                  />
                  <div>
                    <input
                      type="file"
                      id="image"
                      style={{ display: "none" }}
                      onChange={onImageChange}
                    ></input>
                    <label
                      for="image"
                      className="img_edit"
                      style={{
                        position: "relative",
                        top: -50,
                        left: -520,
                      }}
                    >
                    
            <img
                onClick={toggle}
                src={pic}
                fontSize="inherit"
                alt=""
                height="30px"
                style={{ float: "right", cursor: "pointer" }}
              />
                     
                    </label>
                  </div>
                </div>
              ) : (
                <div style={styles.avatarBox12}>
                  <Avatar
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "75px",
                      border: "7px solid #e7e6e6",
                      margin: "auto",
                      top: -100,
                    }}
                    alt="Remy Sharp"
                  />

                  <div>
                    <input
                      type="file"
                      id="image"
                      style={{ display: "none" }}
                      onChange={onImageChange}
                    ></input>
                    <label
                      for="image"
                      className="img_edit"
                      style={{
                        position: "relative",
                        top: -50,
                        left: -520,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "75px",
                          border: "2px solid #e7e6e6",
                          padding: "5px 9px 5px 9px",
                          opacity: 0.8,
                        }}
                      >
                        <Icon
                          className="fa fa-pencil"
                          style={{
                            fontSize: "20px",
                          }}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              )}
            </Desktop>

            <Mobile>
              {banner ? (
                <div style={styles.images12}>
                  <div
                    className="hello img_edit1"
                    style={{
                      border: "2px solid #e7e6e6",
                      borderRadius: "25px",
                      width: "100%",
                    }}
                  >
                    <input
                      type="file"
                      id="image1"
                      style={{ display: "none" }}
                      onChange={onImageChange1}
                    ></input>
                    <label
                      for="image1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={banner} width="100%" height="200" />
                    </label>
                  </div>
                </div>
              ) : (
                <div style={styles.emptyBanner12}>
                  <input
                    type="file"
                    id="image1"
                    style={{ display: "none" }}
                    onChange={onImageChange1}
                  ></input>
                  <label for="image1">Add an image</label>
                </div>
              )}

              {logo ? (
                <>
                  <div style={styles.avatarBox98}>
                    <Avatar
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "75px",
                        border: "7px solid #e7e6e6",
                        margin: "auto",
                        top: -100,
                      }}
                      alt="Remy Sharp"
                      src={logo}
                    />
                    <div>
                      <input
                        type="file"
                        id="image"
                        style={{ display: "none" }}
                        onChange={onImageChange}
                      ></input>
                      <label
                        for="image"
                        className="img_edit"
                        style={{
                          position: "relative",
                          top: -40,
                          left: -120,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            borderRadius: "75px",
                            border: "2px solid #e7e6e6",
                            padding: "5px 9px 5px 9px",
                            opacity: 0.8,
                          }}
                        >
                          <Icon
                            className="fa fa-pencil"
                            style={{
                              fontSize: "20px",
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                  <Link to="/public/college/profile" target="_blank">
                    <p
                      style={{
                        textAlign: "center",
                        color: "rgb(76, 135, 190)",
                      }}
                    >
                      <img src={view} height="20px" />
                      &nbsp; View your public profile
                    </p>
                  </Link>
                </>
              ) : (
                <>
                  <div style={styles.avatarBox98}>
                    <Avatar
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "75px",
                        border: "7px solid #e7e6e6",
                        margin: "auto",
                        top: -50,
                      }}
                      alt="Remy Sharp"
                    />

                    <div>
                      <input
                        type="file"
                        id="image"
                        style={{ display: "none" }}
                        onChange={onImageChange}
                      ></input>
                      <label
                        for="image"
                        className="img_edit"
                        style={{
                          position: "relative",
                          top: -40,
                          left: -120,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            borderRadius: "75px",
                            border: "2px solid #e7e6e6",
                            padding: "5px 9px 5px 9px",
                            opacity: 0.8,
                          }}
                        >
                          <Icon
                            className="fa fa-pencil"
                            style={{
                              fontSize: "20px",
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  </div>{" "}
                  <Link to="/public/college/profile" target="_blank">
                    <p
                      style={{
                        textAlign: "center",
                        color: "rgb(76, 135, 190)",
                      }}
                    >
                      <img src={eye} height="16px" />
                      &nbsp; View your public profile
                    </p>
                  </Link>
                </>
              )}
              <br />
              <br />
            </Mobile>

            <Row>
              <Col x1="12">
                {isName ? (
                  <Typography variant="h4">
                    {isName} - {city},&nbsp;{state}
                  </Typography>
                ) : (
                  <Skeleton variant="text" animation="wave" />
                )}
                {/* <p>9.2/10  Based on 57 Reviews</p> */}
              </Col>
              {/* <Col x1="2">
            <Link to="/college/Profile_update">
                <Button className="bbtn" > <Icon name='pencil' size='miny' /><b>Edit Profile</b></Button></Link>
            </Col> */}
            </Row>
            <hr />
            <Typography variant="h4">About</Typography>
            <Form onSubmit={(e) => submit(e)}>
              <TextField
                style={{
                  width: "100%",
                  margin: "15px 0px",
                }}
                variant="outlined"
                rows={5}
                multiline={true}
                onChange={(e) => setIsAbout(e.target.value)}
                value={isAbout}
              />
              <br />

              <Button
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "rgb(76, 135, 190)",
                  borderColor: "rgb(76, 135, 190)",
                  borderRadius:"30px"
                }}
              >
                Update About
              </Button>
            </Form>

            <br />

            <Typography variant="h4">Description</Typography>
            <Form onSubmit={(e) => submit(e)}>
              <TextField
                style={{
                  width: "100%",
                  margin: "15px 0px",
                }}
                variant="outlined"
                rows={10}
                multiline={true}
                onChange={(e) => setIsDescription(e.target.value)}
                value={isDescription}
              />
              <br />

              <Button
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "rgb(76, 135, 190)",
                  borderColor: "rgb(76, 135, 190)",
                  borderRadius:"30px"
                }}
              >
                Update Description
              </Button>
            </Form>

            <br />

            <Typography variant="h4">Message for recruiters</Typography>
            <Form onSubmit={(e) => submit(e)}>
              <TextField
                style={{
                  width: "100%",
                  margin: "15px 0px",
                }}
                variant="outlined"
                rows={10}
                multiline={true}
                onChange={(e) => setIsMessage(e.target.value)}
                value={isMessage}
              />
              <br />

              <Button
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "rgb(76, 135, 190)",
                  borderColor: "rgb(76, 135, 190)",
                  borderRadius:"30px"
                }}
              >
                Update Message
              </Button>
            </Form>
            <br />
            <br />

            <Typography variant="h4">Specializations and Course</Typography>

            <div
              style={{
                marginLeft: "100px",
                backgroundColor: "#fff",
                width: "65vw",
                marginTop: "35px",
                paddingTop: "30px",
                paddingLeft: "20px",
                paddingBottom: "15px",
                marginBottom: "35px",
                backgroundColor: theme.palette.background.default,
              }}
            >
              <form>
                {/* <h5>Courses and Branch</h5> */}
                <div style={styles.fieldDiv}>
                  <div style={styles.radioStyle}>
                    <FormControl variant="standard" style={styles.dropDown1}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={eduType}
                        onChange={(e) =>
                          getDegrees(educationTypes[e.target.value], e)
                        }
                        label="Graduation"
                      >
                        <MenuItem value="Search" disabled>
                          Search Graduation
                        </MenuItem>
                        {Object.keys(educationTypes).map((data) => {
                          return <MenuItem value={data}>{data}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                    <FormControl variant="standard" style={styles.dropDown1}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={degreeType}
                        onChange={(e) => getCourses(Degrees[e.target.value], e)}
                        label="Degree"
                      >
                        <MenuItem value="Search" disabled>
                          Search Degree
                        </MenuItem>
                        {Object.keys(Degrees).map((data) => {
                          return <MenuItem value={data}>{data}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                    <FormControl variant="standard" style={styles.dropDown1}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={courseType}
                        onChange={(e) => setCourseType(e.target.value)}
                        label="Specialization"
                      >
                        <MenuItem value="Search" disabled>
                          Search Specialization
                        </MenuItem>
                        {Object.keys(Courses).map((data) => {
                          return <MenuItem value={data}>{data}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      style={styles.button3}
                      size="small"
                      onClick={() => {
                        let ca = courseArray;
                        let validateArr = [];
                        courseArray.forEach((item) => {
                          if (
                            Object.values(item).includes(degreeType) &&
                            Object.values(item).includes(courseType)
                          ) {
                            validateArr.push(1);
                          } else validateArr.push(0);
                        });

                        if (validateArr.includes(1)) {
                          alert.error("Course already exist");
                        } else {
                          ca.push({
                            education_type: educationTypes[eduType],
                            degree_id: Degrees[degreeType],
                            specialization_id:
                              courseType === "Search"
                                ? null
                                : Courses[courseType],
                            is_active: true,
                            degree_name: degreeType,
                            specialization_name:
                              courseType === "Search" ? null : courseType,
                            education_type_name: eduType,
                            id: ca.find(
                              (item) =>
                                item.degree_name === degreeType &&
                                item.specialization_name === courseType
                            )?.id,
                          });
                        }

                        setCourseArray([...ca]);
                        setCourseType("Search");
                      }}
                    >
                      Add&nbsp; &nbsp;
                      <Icon
                        className="fa fa-plus"
                        style={{ fontSize: "16px" }}
                      />
                    </Button>
                  </div>
                </div>
                {courseArray.length > 0
                  ? courseArray.map((data, index) =>
                      data.is_active == true ? (
                        <>
                          <div style={styles.fieldDiv}>
                            <div style={styles.radioStyle}>
                              <input
                                type="text"
                                className="form-control"
                                value={data.education_type_name}
                                style={styles.inputBox}
                                required
                                disabled
                              />

                              <input
                                type="text"
                                className="form-control"
                                value={data.degree_name}
                                style={styles.inputBox}
                                required
                                disabled
                              />

                              <input
                                type="text"
                                className="form-control"
                                value={data.specialization_name}
                                style={styles.inputBox}
                                required
                                disabled
                              />
                              <Button
                                variant="contained"
                                style={styles.button2}
                                size="small"
                                onClick={() => {
                                  let ca = courseArray;
                                  ca[index].is_active = !data.is_active;
                                  setCourseArray([...ca]);
                                }}
                              >
                                Del&nbsp; &nbsp;
                                <Icon
                                  className="fa fa-trash-alt"
                                  style={{
                                    fontSize: "16px",
                                    borderRadius:"30px"
                                  }}
                                />
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : null
                    )
                  : null}
                <div
                  style={{
                    marginLeft: "40px",
                    marginTop: "20px",
                    marginRight: "10px",
                    color: "grey",
                  }}
                >
                  If Course and branch is not listed, please contact admin.
                </div>
                {/* <div style={styles.buttonContainer1}>
								<Button
									variant="contained"
									style={styles.button}
									onClick={handleCourseDetails}
								>
									Update Courses and Branch
								</Button>
							</div> */}
              </form>
            </div>
            <br />
            <Button
              variant="contained"
              onClick={handleCourseDetails}
              style={{
               // backgroundColor: "rgb(76, 135, 190)",
                //borderColor: "rgb(76, 135, 190)",
                borderRadius:"30px"
              }}
            >
              Update Courses and Branch
            </Button>

            <br />
            <br />
            <br />

            <br />

            <br />

            <Typography variant="h4">
              FAQ's (Write Your Questions and Answers here)
            </Typography>
            <Form onSubmit={(e) => submit(e)}>
              {/* <Input
                                    onChange={(e) => setIsFaq(e.target.value)}
                                    type="textarea"
                                    name="faqs"
                                    value={faqs}
                                    rows="13"
                                /> */}
              <Editor
                value={FAQ}
                editorState={editorState}
                toolbarClassName=""
                wrapperClassName="editor-wrapper"
                editorClassName="editor"
                onEditorStateChange={handleEditorStateChange}
                onChange={(editorEvent) => {
                  setFAQ(editorEvent.blocks[0].text);
                }}
              />
              <br />

              <Button
                variant="contained"
                type="submit"
                style={{
                  //backgroundColor: "rgb(76, 135, 190)",
                  //borderColor: "rgb(76, 135, 190)",
                  borderRadius:"30px"
                }}
              >
                Update Faq's
              </Button>
            </Form>
          </CardBody>
        </div>
      </Box>
    </div>
  );
}

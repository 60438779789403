const filterJob = (allData, key, value) => {
  if (value == "All") {
    return allData;
  } else {
    let tempData = [];
    tempData = allData.filter((item) => item[`${key}`] === value);

    return tempData;
  }
};

export function sortThings(a, b, key) {
  a = a.company.company_name.toLowerCase();
  b = b.company.company_name.toLowerCase();

  return a > b ? 1 : b > a ? -1 : 0;
}

export default filterJob;


export const uniqueArray = (arr) => {
    return [...new Set(arr.map(JSON.stringify))].map(JSON.parse);
};
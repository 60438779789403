import React from "react";
import { Paper, Typography, Button } from "@material-ui/core";
import AllPlanDialog from "../../Dialog/AllPlanDialog";

function Plan() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Paper
        style={{
          backgroundColor: "#FFEFD0",
          borderRadius: "15px",
          height: "180px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Typography variant="h5" style={{ color: "#C58D00" }}>
          Your Plan is Expiring Soon!
        </Typography>
        <Typography variant="body2" style={{ fontWeight: "400" }}>
          Your Business Plan with “100 Talents-Unlocks” ends soon. Choose a new
          plan to keep enjoying the benefits of GetWork ATS & Talent Unlocks
          services.
        </Typography>
        <div style={{ display: "flex", marginTop: "40px" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#EBB241",
              borderRadius: "30px",
              boxShadow: "none",
              height: "40px",
              display: "flex",
              color: "#fff",
            }}
          >
            Renew Plan
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#EBB241",
              borderRadius: "30px",
              boxShadow: "none",
              height: "40px",
              display: "flex",
              color: "#fff",
              marginLeft: "20px",
            }}
            onClick={handleClickOpen}
          >
            Change Plan
          </Button>
        </div>
      </Paper>
      <AllPlanDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </>
  );
}

export default Plan;

import React, {useState, useEffect} from 'react';
import {
    makeStyles,
    Box,
    Grid,
    Paper,
    Typography
  } from "@material-ui/core";
import wallet_icon from "../../../assets/gif/welcome_pop.gif";
import new_wallet from "../../../assets/gif/new_wallet.gif";
import logo from "../../../assets/img/getwork-logo.png";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import JobSharerButton from './JobSharerButton';

  const useStyles = makeStyles({
    paper2: {
      textAlign: "center",
    //  height: "270px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      backgroundColor: "#ffffff",
      borderRadius: "30px",
  },
  Button: {
    borderRadius: "30px",
    marginBottom: "12px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#32B2F9",
  },
  });


function JobSharerModal({openModal=true, setOpenModal, detail = {}, page = "RECOMMENDED" }) {
    const classes = useStyles();
    const [creditActivated, setCreditActivated] = useState(false);
    const [disableBackdropClick, setDisableBackdropClick] = useState(false);
    const handleClose = () => {
      setOpenModal(false);
      };
return (
<Dialog
  open={openModal}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  disableBackdropClick={disableBackdropClick ? "true" : undefined}
>
<Box
          padding="3%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ overflowX: "hidden" }}
        >
          <div className={classes.root}></div>
         <Grid container spacing={3}>
        
        <Grid item xs={6}>
      
    
          <img
             src={creditActivated ? new_wallet : wallet_icon}
             alt="Notification"
            height="280px"/>
        </Grid>

        <Grid item xs={6}>
        <Paper className={classes.paper2}>
        <DialogContent>
          <DialogContentText>
          <img
             src={logo}
             alt="Notification"
            height="40px"/>
            <Typography variant="h4" gutterBottom>
                Job successfully accepted. We have already informed your eligible students.
              </Typography>

              <p>
              You can also share this job to various social media platforms. 
              </p>
            <JobSharerButton detail={detail} page={page} />
          </DialogContentText>
        </DialogContent>
      
        </Paper>
        </Grid>
      </Grid>
      
       </Box>
</Dialog>
)
}

export default JobSharerModal;
import {
  Box,
  Button, createStyles, makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
//@ts-ignore
import Pdf from "react-to-pdf";
import { TReduxStore } from "../../..";
import BackArrow from "../../../assets/images/reports/back-arrow.png";

interface ReportsControlPanelProps {
  buttons: string[];
  title: string;
  tableData?: any;
}

const ReportsControlPanel = (props: ReportsControlPanelProps) => {
  const { buttons, title, tableData } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const ref = useSelector<TReduxStore, any>(
    (state) =>
      //@ts-ignore
      state.Reports.ref
  );

  const data = [
    {
      company_id: 2,
      company_name: "ABCD",
      company_user: {
        first_name: "abcd",
        last_name: "name",
        phone: 12345678900,
      },
      ctc_min: 6,
      ctc_max: 800000,
      ctc_av: 352750.65,
      hired: 4,
    },
    {
      company_id: 2,
      company_name: "ABCDefgh",
      company_user: {
        first_name: "abcdefg",
        last_name: "name-test",
        phone: 9999,
      },
      ctc_min: 26,
      ctc_max: 802,
      ctc_av: 350.65,
      hired: 41,
    },
    {
      company_id: 2,
      company_name: "ABCD",
      company_user: {
        first_name: "abcd",
        last_name: "name",
        phone: 12345678900,
      },
      ctc_min: 6,
      ctc_max: 800000,
      ctc_av: 352750.65,
      hired: 4,
    },
    {
      company_id: 2,
      company_name: "ABCDefgh",
      company_user: {
        first_name: "abcdefg",
        last_name: "name-test",
        phone: 9999,
      },
      ctc_min: 26,
      ctc_max: 802,
      ctc_av: 350.65,
      hired: 41,
    },
  ];

  return (
    <Box className={classes.mainCont}>
      <Box display="flex" alignItems="center">
        <img src={BackArrow} onClick={() => history.goBack()} />
        <Typography className={classes.title}>{title}</Typography>
      </Box>

      <Box>
        {/* <CSVLink data={data}>Download me</CSVLink> */}
        {buttons.map(
          (item, index) =>
            item !== "Export" ? (
              <Button
                className={classes.buttons}
                key={index}
                //onClick={() => download(JSON.stringify(data))}
              >
                {item}
              </Button>
            ) : location.pathname === "/college/reports/leads-by-industry" ||
              location.pathname === "/college/reports/placement-report" ||
              location.pathname === "/college/reports/skills-report" ||
              location.pathname === "/college/reports/openings-report" ? (
              <Pdf
                targetRef={ref}
                filename={`${location.pathname.split("/")[3]}.pdf`}
              >
                {({ toPdf }: { toPdf: any }) => (
                  <Button
                    onClick={toPdf}
                    className={classes.buttons}
                    key={index}
                  >
                    Export
                  </Button>
                )}
              </Pdf>
            ) : (
              <CSVLink
                data={tableData ? tableData : []}
                filename={`${location.pathname.split("/")[3]}.csv`}
              >
                <Button className={classes.buttons} key={index}>
                  {item}
                </Button>
              </CSVLink>
            )

          // (item !== "Export" ?
          //     <Button
          //         className={classes.buttons}
          //         key={index}
          //         //onClick={() => download(JSON.stringify(data))}
          //     >
          //         {item}
          //     </Button>
          // :

          //         location.pathname ===
          //             "/college/reports/leads-by-industry" ||
          //     location.pathname ===
          //         "/college/reports/placement-report" ||
          //     location.pathname ===
          //         "/college/reports/skills-report" ||
          //     location.pathname ===
          //         "/college/reports/openings-report" ?
          //         <Pdf targetRef={ref} filename="code-example.pdf">
          //             {({ toPdf }) => (
          //                 <button onClick={toPdf}>
          //                     Generate Pdf
          //                 </button>
          //             )}
          //         </Pdf>
          //      :
          //           location.pathname ===
          //               "/college/reports/yearly-visit-report" ||
          //       location.pathname ===
          //           "/college/reports/ctc-report" ?
          //         <CSVLink data={tableData ? tableData : []}>
          //             <Button
          //                 className={classes.buttons}
          //                 key={index}
          //                 //onClick={() => download(JSON.stringify(data))}
          //             >
          //                 {item}
          //             </Button>
          //         </CSVLink>

          // )
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    mainCont: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    buttons: {
      width: "164px",
      height: "44px",
      background: "#828282",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "30px",
      fontSize: "18px",
      fontFamily: "ProximaNova",
      textTransform: "capitalize",
      color: "#fff",
      marginLeft: "15px",
      "&:hover": {
        background: "#828282",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    title: {
      fontSize: "18px",
      fontFamily: "ProximaNova",
      marginLeft: "9px",
    },
  })
);

export default ReportsControlPanel;

import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import kFormater, { LFormatter } from "../../../utils/common/ZeroToK";
import {
  Avatar,
  Button,
  Modal,
  Dialog,
  Hidden,
  Chip,
  TextareaAutosize,
} from "@material-ui/core";
import ViewJob from "../viewJob/ViewJob";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core";
import upload from "../../../assets/images/assignmentUpload.png";
import upload1 from "../../../assets/images/upload_icon.png";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { useAlert } from "react-alert";
import { BreakpointProvider, Breakpoint } from "react-socks";
import { BackendBaseApi } from "../../../constants/constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    fileBox: {
      padding: "20px",
      backgroundColor: "#FFF",
      borderRadius: "4px",
      border: "1px solid black",
      width: "450px",
      textAlign: "center",
      margin: "auto",
    },
    modal1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    paper1: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: "4px",
    },
    root: {
      fontWeight: "bolder",
      border: "2px solid #3282C4",
      color: "#3282C4",
      marginTop: "10px",
    },
    greyBox: {
      width: "100%",
      backgroundColor: "#f0f8ff",
      padding: "20px",
      borderRadius: "4px",
      textAlign: "left",
      boxShadow: "0px 0px 4px 0px rgba(2, 57, 83, 0.15)",
    },
    textLeft: {
      textAlign: "left",
    },
    outerDottedBox: {
      border: "2px dotted black",
      padding: "20px",
      width: "100%",
      borderRadius: "6px",
      backgroundColor: "#F2F2F2",
      textAlign: "center",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      padding: "10px 20px 10px 20px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    buttonBox: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "6px",
      flexDirection: "column",
    },
    "@media screen and (max-width: 768px)": {
      greyBox: {
        width: "100%",
      },
      submitButton: {
        width: "100%",
        height: "43px",
      },
      dwnldAssignment: {
        width: "100%",
        height: "43px",
        borderRadius: 4,
        border: "solid 2px #3282c4",
        backgroundColor: "#ffffff",
        color: "#3282c4",
        margin: "4% 0%",
      },
      paper1: {
        width: "100%",
      },
      buttonBox: {
        flexDirection: "column",
      },
    },
  })
);

const AssignmentJobCard = ({
  data,
  applied,
  interview,
  reject,
  fullView,
  all,
  hideStatus,
  saveJob,
  invite,
}) => {
  const classes = useStyles();

  const [open, handleClose] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [isReject, setIsReject] = useState(false);
  const loc = useLocation().pathname;
  let show = 0;

  const openJob = (item) => {
    setJobData(item);
    handleClose(!open);
  };
  const callback = (value) => {
    handleClose(value);
    // do something with value in parent component, like save to state
  };

  return (
    <BreakpointProvider>
      <Breakpoint large up>
        {data.length
          ? data.map((item) => (
              <div
                className="new-job-card"
                key={item.job.job_id}
                onClick={() => openJob(item.job)}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="container"
                  style={{ borderLeft: "5px solid #3282C4" }}
                >
                  <div className="container__header">
                    <Avatar
                      src={item.job.company?.company_logo}
                      style={{ width: 50, height: 50, borderRadius: 0 }}
                      alt="company-picture"
                    />
                    <div className="container__header__names">
                      <div className="container__header__role position-relative">
                        <h3 className="fs-20"> {item.job.job_title}</h3>
                        {applied && (
                          <div
                            className="absolute top-0 fs-16 fw-700 text-green"
                            style={{ right: "23px" }}
                          >
                            {item.job.applicant_status_name}
                          </div>
                        )}
                        {/* <p>{item.job.hiring_type}</p> */}

                        <Chip
                          label={
                            item.job.hiring_type === "ON CAMPUS"
                              ? "ON CAMPUS HIRING"
                              : "OFF CAMPUS HIRING"
                          }
                          variant="outlined"
                          className={classes.root}
                          color="primary"
                        />

                        <Chip
                          label={
                            item.assignment.assignment_status === "OPEN"
                              ? "ASSIGNMENT OPEN"
                              : "ASSIGNMENT CLOSED"
                          }
                          variant="outlined"
                          className={classes.root}
                          color="primary"
                        />

                        <div>
                          <ChevronRightIcon />
                        </div>
                      </div>
                      <div className="container__header__company">
                        <span className="container__header__cname">
                          {" "}
                          {item.job.company
                            ? item.job.company.company_name
                            : item.job.company_details}
                        </span>
                        <br />
                        <span className="container__header__location">
                          {item.job.job_location.map((item) => `${item.city},`)}
                          {item.job.job_location.length > 0 ? (
                            <>&nbsp;&middot;&nbsp;</>
                          ) : null}
                          {item.job.company?.company_size}
                          {item.job.company.company_size ? " employees" : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="container__body">
                    <div className="container__body__info">
                      <span className="fs-16">{item.job.job_type_name}</span>
                      {item.job.ctc_min === 0 &&
                      item.job.ctc_max === 0 ? null : (
                        <>
                          &nbsp;&middot;&nbsp;
                          <span className="orange fs-16">
                            {" "}
                            {item.job.ctc_min > 99999
                              ? LFormatter(item.job.ctc_min)
                              : kFormater(item.job.ctc_min)}{" "}
                            -{" "}
                            {item.job.ctc_max > 99999
                              ? LFormatter(item.job.ctc_max)
                              : kFormater(item.job.ctc_max)}{" "}
                            {item.job.salary_payment_type.toLowerCase()}
                          </span>
                        </>
                      )}
                      &nbsp;&middot;&nbsp;
                      <span className="fs-16">
                        {" "}
                        {item.job.equity_min === 0 && item.job.equity_max === 0
                          ? "No equity"
                          : `${item.job.equity_min} % - ${item.job.equity_max} %`}
                      </span>
                    </div>
                    <Hidden smDown>
                      <div>
                        {loc == "/student/open-assignment" ? (
                          item.assignment.assignment_status &&
                          item.assignment.deadline === false &&
                          item.assignment.assignment_status === "OPEN" ? (
                            <AssignmentButton
                              AssignmentID={item.assignment.assignment_id}
                              jobId={item.job.job_id}
                            />
                          ) : item.assignment.deadline === true ? (
                            <span className="orange fs-16">
                              <b>Assignment Deadline Passed!</b>
                            </span>
                          ) : (
                            <span className="orange fs-16">
                              <b>Assignment is Closed!</b>
                            </span>
                          )
                        ) : null}
                      </div>
                    </Hidden>
                  </div>
                  <div className="container__footer flex justify-content-between">
                    <div className="w-70">
                      {loc != "/student/OpenAssignment" &&
                      item?.interview?.length ? (
                        <div className="container__body__info">
                          {item.job.interview[0]?.location ? (
                            <>
                              {" "}
                              <span className="fs-12">
                                {item.job.interview[0]?.location?.city}
                              </span>
                              &nbsp;&middot;&nbsp;
                            </>
                          ) : (
                            <>
                              {" "}
                              <span className="fs-12">
                                {item.job.interview[0]?.link}
                              </span>
                              &nbsp;&middot;&nbsp;{" "}
                            </>
                          )}

                          <span className="orange fs-12">
                            {" "}
                            {moment(item.job.interview[0]?.start_date).format(
                              "DD MMM, YYYY, h:mm a"
                            )}{" "}
                            -{" "}
                            {moment(item.job.interview[0]?.end_date).format(
                              "DD MMM, YYYY, h:mm a"
                            )}{" "}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <br />
                  <div className="w-70">
                    {loc == "/student/open-assignment" ||
                    loc == "/student/closed-assignment" ||
                    loc == "/student/submitted-assignment" ? (
                      <>
                        <Typography
                          className={classes.textLeft}
                          align="left"
                          variant="body1"
                          style={{ color: "#3282C4" }}
                        >
                          <b>
                            {loc == "/student/open-assignment" && (
                              <>Assignment Allocated</>
                            )}
                            {loc == "/student/closed-assignment" && (
                              <>Assignment Closed</>
                            )}
                            {loc == "/student/submitted-assignment" && (
                              <>Assignment Submitted</>
                            )}
                          </b>
                        </Typography>
                        <br />
                        <Box className={classes.greyBox}>
                          <Typography
                            className={classes.textLeft}
                            align="left"
                            variant="body2"
                          >
                            <b>Title:</b>
                            <span style={{ color: "#255a9b" }}>
                              &nbsp; {item.assignment.title}
                            </span>{" "}
                          </Typography>
                          <Typography
                            className={classes.textLeft}
                            align="left"
                            variant="body2"
                          >
                            <b>Description:</b>
                            <span style={{ color: "#255a9b" }}>
                              &nbsp; {item.assignment.description}
                            </span>{" "}
                          </Typography>
                          <Typography align="left" variant="body2">
                            <b>Submission Date:</b>{" "}
                            <span style={{ color: "#255a9b" }}>
                              &nbsp; {item.assignment.end_date}
                            </span>{" "}
                          </Typography>
                          <Typography variant="body2">
                            <b>Attachment:</b>{" "}
                            <span style={{ color: "#255a9b" }}>
                              &nbsp;{" "}
                              {item.assignment.file
                                ? item.assignment.file
                                    .split("/")[5]
                                    .split("?")[0]
                                : null}
                            </span>
                            <a
                              href={
                                item.assignment.file
                                  ? item.assignment.file
                                  : null
                              }
                              download="GFG"
                              target="blank"
                            >
                              &nbsp; <img src={upload} />
                            </a>
                          </Typography>
                        </Box>
                        <Box>
                          <Hidden smUp>
                            <div>
                              <Button className={classes.dwnldAssignment}>
                                download Assignment
                              </Button>
                              {loc == "/student/open-assignment" ? (
                                <AssignmentButton
                                  AssignmentID={item.assignment.assignment_id}
                                  jobId={item.job.job_id}
                                />
                              ) : null}
                            </div>
                          </Hidden>
                        </Box>
                      </>
                    ) : null}
                  </div>

                  <Dialog
                    open={open}
                    onClose={() => handleClose(!open)}
                    maxWidth="md"
                  >
                    <ViewJob
                      data={jobData}
                      apply={interview}
                      open={fullView}
                      handleClosey={callback}
                    />
                  </Dialog>
                </div>
              </div>
            ))
          : null}
      </Breakpoint>
      <Breakpoint medium down>
        {data.length
          ? data.map((item) => (
              <div
                className="new-job-card"
                key={item.job.job_id}
                onClick={() => openJob(item.job)}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="container"
                  style={{ borderLeft: "5px solid #3282C4" }}
                >
                  <div className="container__header">
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Avatar
                          src={item.job.company?.company_logo}
                          style={{ width: 50, height: 50, borderRadius: 0 }}
                          alt="company-picture"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <div className="container__header__names">
                          <div className="container__header__role position-relative">
                            <h3> {item.job.job_title}</h3>
                            {applied && (
                              <div
                                className="absolute top-0 fs-16 fw-700 text-green"
                                style={{ right: "23px" }}
                              >
                                {item.job.applicant_status_name}
                              </div>
                            )}
                          </div>
                          <div className="container__header__company">
                            <span className="container__header__cname">
                              {" "}
                              {item.job.company
                                ? item.job.company.company_name
                                : item.job.company_details}
                            </span>
                            <br />
                            <span className="container__header__location">
                              {item.job.job_location.map(
                                (item) => `${item.city},`
                              )}
                              {item.job.job_location.length > 0 ? (
                                <>&nbsp;&middot;&nbsp;</>
                              ) : null}
                              {item.job.company?.company_size}
                              {item.job.company.company_size
                                ? " employees"
                                : null}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          marginTop: "6px",
                        }}
                      >
                        <Chip
                          label={
                            item.job.hiring_type === "ON CAMPUS"
                              ? "ON CAMPUS HIRING"
                              : "OFF CAMPUS HIRING"
                          }
                          variant="outlined"
                          className={classes.root}
                          color="primary"
                        />

                        <Chip
                          label={
                            item.assignment.assignment_status === "OPEN"
                              ? "ASSIGNMENT OPEN"
                              : "ASSIGNMENT CLOSED"
                          }
                          variant="outlined"
                          className={classes.root}
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="container__body">
                    <div className="container__body__info">
                      <span className="fs-16">{item.job.job_type_name}</span>
                      &nbsp;&middot;&nbsp;
                      <span className="orange fs-16">
                        {" "}
                        {item.job.ctc_min > 99999
                          ? LFormatter(item.job.ctc_min)
                          : kFormater(item.job.ctc_min)}{" "}
                        -{" "}
                        {item.job.ctc_max > 99999
                          ? LFormatter(item.job.ctc_max)
                          : kFormater(item.job.ctc_max)}{" "}
                        {item.job.salary_payment_type.toLowerCase()}
                      </span>
                      &nbsp;&middot;&nbsp;
                      <span className="fs-16">
                        {" "}
                        {item.job.equity_min === 0 && item.job.equity_max === 0
                          ? "No equity"
                          : `${item.job.equity_min} % - ${item.job.equity_max} %`}
                      </span>
                    </div>
                    <Hidden smDown>
                      <div>
                        {loc == "/student/open-assignment" ? (
                          item.assignment.assignment_status &&
                          item.assignment.deadline === false &&
                          item.assignment.assignment_status === "OPEN" ? (
                            <AssignmentButton
                              AssignmentID={item.assignment.assignment_id}
                              jobId={item.job.job_id}
                            />
                          ) : item.assignment.deadline === true ? (
                            <span className="orange fs-16">
                              <b>Assignment Deadline Passed!</b>
                            </span>
                          ) : (
                            <span className="orange fs-16">
                              <b>Assignment is Closed!</b>
                            </span>
                          )
                        ) : null}
                      </div>
                    </Hidden>
                  </div>
                  <div className="container__footer flex justify-content-between">
                    <div className="w-70">
                      {loc != "/student/OpenAssignment" &&
                      item?.interview?.length ? (
                        <div className="container__body__info">
                          {item.job.interview[0]?.location ? (
                            <>
                              {" "}
                              <span className="fs-12">
                                {item.job.interview[0]?.location?.city}
                              </span>
                              &nbsp;&middot;&nbsp;
                            </>
                          ) : (
                            <>
                              {" "}
                              <span className="fs-12">
                                {item.job.interview[0]?.link}
                              </span>
                              &nbsp;&middot;&nbsp;{" "}
                            </>
                          )}

                          <span className="orange fs-12">
                            {" "}
                            {moment(item.job.interview[0]?.start_date).format(
                              "DD MMM, YYYY, h:mm a"
                            )}{" "}
                            -{" "}
                            {moment(item.job.interview[0]?.end_date).format(
                              "DD MMM, YYYY, h:mm a"
                            )}{" "}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <br />
                  <div className="w-70">
                    {loc == "/student/open-assignment" ||
                    loc == "/student/closed-assignment" ||
                    loc == "/student/submitted-assignment" ? (
                      <>
                        <Typography
                          className={classes.textLeft}
                          align="left"
                          variant="body1"
                          style={{ color: "#3282C4" }}
                        >
                          <b>
                            {loc == "/student/open-assignment" && (
                              <>Assignment Allocated</>
                            )}
                            {loc == "/student/closed-assignment" && (
                              <>Assignment Closed</>
                            )}
                            {loc == "/student/submitted-assignment" && (
                              <>Assignment Submitted</>
                            )}
                          </b>
                        </Typography>
                        <br />
                        <Box className={classes.greyBox}>
                          <Typography
                            className={classes.textLeft}
                            align="left"
                            variant="body2"
                          >
                            <b>Title:</b>
                            <span style={{ color: "#255a9b" }}>
                              &nbsp; {item.assignment.title}
                            </span>{" "}
                          </Typography>
                          <Typography
                            className={classes.textLeft}
                            align="left"
                            variant="body2"
                          >
                            <b>Description:</b>
                            <span style={{ color: "#255a9b" }}>
                              &nbsp; {item.assignment.description}
                            </span>{" "}
                          </Typography>
                          <Typography align="left" variant="body2">
                            <b>Submission Date:</b>{" "}
                            <span style={{ color: "#255a9b" }}>
                              &nbsp; {item.assignment.end_date}
                            </span>{" "}
                          </Typography>
                          <Typography variant="body2">
                            <b>Attachment:</b>{" "}
                            <span style={{ color: "#255a9b" }}>
                              &nbsp;{" "}
                              {item.assignment.file
                                ? item.assignment.file
                                    .split("/")[5]
                                    .split("?")[0]
                                : null}
                            </span>
                            <a
                              href={
                                item.assignment.file
                                  ? item.assignment.file
                                  : null
                              }
                              download="GFG"
                              target="blank"
                            >
                              &nbsp; <img src={upload} />
                            </a>
                          </Typography>
                        </Box>
                        <Box>
                          <Hidden smUp>
                            <div>
                              <a
                                href={
                                  item.assignment.file
                                    ? item.assignment.file
                                    : null
                                }
                                download="GFG"
                                target="blank"
                              >
                                <Button
                                  className={classes.dwnldAssignment}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  download Assignment
                                </Button>
                              </a>
                              {loc == "/student/open-assignment" ? (
                                <AssignmentButton
                                  AssignmentID={item.assignment.assignment_id}
                                  jobId={item.job.job_id}
                                />
                              ) : null}
                            </div>
                          </Hidden>
                        </Box>
                      </>
                    ) : null}
                  </div>

                  <Dialog
                    open={open}
                    onClose={() => handleClose(!open)}
                    maxWidth="md"
                  >
                    <ViewJob
                      data={jobData}
                      apply={interview}
                      open={fullView}
                      handleClosey={callback}
                    />
                  </Dialog>
                </div>
              </div>
            ))
          : null}
      </Breakpoint>
    </BreakpointProvider>
  );
};

export default AssignmentJobCard;

const AssignmentButton = ({ AssignmentID, jobId }) => {
  const [done, setDone] = useState(false);
  const alert = useAlert();
  const classes = useStyles();
  const [fileDetails, setFileDetails] = useState();
  const [oldFileDetails, setOldFileDetails] = useState();
  const [assignmentText, setAssignmentText] = useState("");
  const [fileProgress, setFileProgress] = useState(0);

  const data = JSON.parse(localStorage.getItem("user_details"));

  const configu = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setFileProgress(percentCompleted);
    },
  };

  const sendAssignment = (e) => {
    e.stopPropagation();

    if (fileDetails) {
      const fd = new FormData();

      fd.append("job_id", jobId);
      fd.append("user_id", data.id);
      fd.append("assignment_id", AssignmentID);
      fd.append("url", null);
      fd.append("text", assignmentText);

      fd.append("file", fileDetails);

      Axios.post(
        BackendBaseApi.NIYUKTI + "job/student/assignment/",
        fd,
        configu
      )
        .then((res) => {
          if (res.data.success) {
            alert.success("Assignment Added Successfully");
            setFileDetails();
            setDone(true);
          } else {
            handleClose1(e);
            setFileDetails();

            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          alert.error("Assignment addition unsuccessful");
        });
    } else {
      alert.error("Choose File");
    }
  };

  const sendAssignmentText = (e) => {
    e.stopPropagation();

    const fd = new FormData();

    fd.append("job_id", jobId);
    fd.append("user_id", data.id);
    fd.append("assignment_id", AssignmentID);
    fd.append("url", null);
    fd.append("text", assignmentText);
    const dat = {
      job_id: jobId,
      user_id: data.id,
      assignment_id: AssignmentID,
      url: null,
      text: assignmentText,
    };

    Axios.post(BackendBaseApi.NIYUKTI + "job/student/assignment/", dat)
      .then((res) => {
        if (res.data.success) {
          alert.success("Text Submitted Successfully");
          setDone(true);
          handleClose2();
          setAssignmentText("");
        } else {
          handleClose1(e);
          setAssignmentText("");

          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error(" unsuccessful");
      });
  };

  const [open2, setOpen2] = React.useState(false);
  const handleOpen1 = (e) => {
    e.stopPropagation();
    setOpen2(true);
  };

  const handleClose1 = (e) => {
    e.stopPropagation();

    setOpen2(false);
    setFileDetails();
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
      name: newName,
    });
  }

  const getFileDetails = (e) => {
    var extension = e.target.files[0].name.split(".").pop();
    const first_name = JSON.parse(localStorage.getItem("user_details"))
      .first_name;
    const last_name = JSON.parse(localStorage.getItem("user_details"))
      .last_name;

    const newName = `${first_name}_${last_name}_${localStorage.getItem(
      "user_id"
    )}_${AssignmentID}.${extension}`;
    var new_file = renameFile(e.target.files[0], newName);
    setFileDetails(new_file);
    setOldFileDetails(e.target.files[0]);
  };

  return (
    <>
      <div>
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal1}
          open={open2}
          onClose={(e) => handleClose1(e)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          maxWidth="lg"
          minWidth="lg"
        >
          <div className={classes.paper1} onClick={(e) => e.stopPropagation()}>
            <Box
              className={classes.outerDottedBox}
              onClick={(e) => e.stopPropagation()}
            >
              <input
                onChange={(e) => {
                  e.stopPropagation();

                  getFileDetails(e);
                }}
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
              />
              <Box>
                {fileDetails ? (
                  <>
                    <Box className={classes.fileBox}>
                      <Grid container spacing={3}>
                        <Grid item xs={10}>
                          <Typography
                            align="center"
                            style={{
                              justifyContent: "center",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                            variant="body1"
                          >
                            <b> {oldFileDetails.name}</b>
                            &nbsp;
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setFileDetails();
                            }}
                            aria-label="delete"
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                    <br />

                    <TextareaAutosize
                      rowsMin={3}
                      rowsMax={5}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                        borderColor: "#d2cdcd",
                      }}
                      aria-label="maximum height"
                      placeholder="Description (Optional)"
                      onChange={(e) => {
                        setAssignmentText(e.target.value);
                      }}
                      value={assignmentText}
                    />
                    <br />
                    <Button
                      className={classes.postButton}
                      variant="contained"
                      component="span"
                      onClick={(e) => {
                        e.stopPropagation();
                        sendAssignment(e);
                      }}
                    >
                      Submit
                    </Button>

                    <br />
                    <br />
                    <br />
                    {fileProgress !== 0 && (
                      <>
                        <CircularProgress
                          variant="determinate"
                          value={fileProgress}
                        />
                        <Box
                          top={20}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            style={{
                              color: "#fff",
                            }}
                          >
                            <b>{`${fileProgress}%`}</b>
                          </Typography>
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <label htmlFor="contained-button-file">
                      <img
                        src={upload1}
                        style={{
                          alignItems: "center",
                          contentAlign: "center",
                          float: "center",
                          display: "flex",
                          margin: "auto",
                        }}
                      />
                      <br />
                      <Typography align="center" variant="h4">
                        <b>Click here to Select your File to Upload</b>
                      </Typography>
                    </label>
                  </>
                )}
              </Box>
              &nbsp;
              <br />
              <br />
            </Box>
            {!fileDetails && (
              <>
                <p style={{ textAlign: "center" }}>OR</p>

                <TextareaAutosize
                  rowsMin={3}
                  rowsMax={5}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "5px",
                    borderColor: "#d2cdcd",
                  }}
                  aria-label="maximum height"
                  placeholder="Description (Optional)"
                  onChange={(e) => {
                    setAssignmentText(e.target.value);
                  }}
                  value={assignmentText}
                />
                <br />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    float: "center",
                  }}
                >
                  <Button
                    className={classes.postButton}
                    disabled={assignmentText !== "" ? false : true}
                    variant="contained"
                    component="span"
                    onClick={(e) => {
                      e.stopPropagation();
                      sendAssignmentText(e);
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </div>
        </Dialog>
      </div>

      <Button
        variant="contained"
        style={
          done === false
            ? { backgroundColor: "#3282C4", color: "WHITE" }
            : { backgroundColor: "grey", color: "black" }
        }
        className={classes.submitButton}
        disabled={done}
        onClick={handleOpen1}
      >
        {done === false ? "Submit Assignment" : "Assignment Submitted"}
      </Button>
    </>
  );
};

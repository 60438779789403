import { Button, TextField, withStyles } from "@material-ui/core";

const CommonButton = withStyles({
  root: {
    padding: "6.5px 18.7px 6.5px 18.5px",
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    backgroundColor: "#007bff",
    borderRadius: 30,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0069d9",
    },
    "&.MuiButton-outlined": {
      backgroundColor: "#fff",
      borderRadius: 30,
      border: "1px solid #007bff",
      color: "#007bff",
      "&:hover": {
        border: "1px solid #0069d9",
        color: "#0069d9",
      },
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "#58aaff",
      color: "#fff",
    },
  },
})(Button);

export default CommonButton;

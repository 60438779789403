import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Grid } from "@material-ui/core";
import college from "../../../../../assets/svg/login/college.svg";
import office from "../../../../../assets/svg/login/office-building.svg";
import graduated from "../../../../../assets/svg/graduated.svg";

import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 8,
    textAlign: "center",
    color: theme.palette.common.black,
    borderRadius: "15px",
    backgroundColor: theme.palette.common.white,
    border: "solid 1px #007bff",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
  },
  paperBlue: {
    padding: 8,
    textAlign: "center",
    color: theme.palette.common.black,
    borderRadius: "15px",
    backgroundColor: "#007bff",
    border: "solid 1px #007bff",
    boxShadow: "none",
  },
  img: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default function LoginGrid({ type }) {
  const classes = useStyles();
  const [selectData, setSelectData] = useState([]);
  const location = useLocation();
  const data_student = [
    {
      title: "College / Institute",
      logo: college,
      link: "/login/college",
    },
    {
      title: "Employer",
      logo: office,
      link: "/signup/employer",
    },
  ];

  const data_college = [
    {
      title: "Student",
      logo: graduated,
      link: "/login/student",
    },
    {
      title: "Employer",
      logo: office,
      link: "/signup/employer",
    },
  ];

  const data_company = [
    {
      title: "Student",
      logo: graduated,
      link: "/login/student",
    },
    {
      title: "College / Institute",
      logo: college,
      link: "/login/college",
    },
  ];

  const dataSelect = () => {
    switch (type) {
      case "Student":
        setSelectData(data_student);
        break;
      case "College":
        setSelectData(data_college);
        break;
      case "Company":
        setSelectData(data_company);
        break;
      default:
      // code block
    }
  };

  useEffect(() => {
    dataSelect();
  }, [type]);
  return (
    <>
      <Grid container spacing={3}>
        {selectData?.length > 0 &&
          selectData?.map((items) => (
            <Grid item xs={6} sm={6}>
              <Link to={{ pathname: items.link, search: location.search ? location.search : "" }}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className={classes.img}>
                      <img src={items.logo} height="70px" width="70px" alt="icons" />
                    </Grid>
                    <Grid item xs={8} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#007bff",
                          fontFamily: " Nunito",
                          fontWeight: "900",
                          marginBottom: 10,
                        }}
                      >
                        <b>{items.title}</b>
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          color: "#007bff",
                          fontFamily: " Nunito",
                          fontWeight: "900",
                        }}
                      >
                        Login
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

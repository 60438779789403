import React, { useEffect, useState } from "react";
import Skills from "../UI/Skills";

const ProjectForm = ({ type, newProject, setNewProject }) => {
  const [project, setProject] = useState(
    type === "ADD_PROJECT"
      ? {
          title: "",
          links: null,
          description: "",
          start_date: null,
          end_date: null,
          skills: [],
        }
      : newProject
  );

  const [projectSkills, setProjectSkills] = useState(
    type === "ADD_PROJECT" ? [] : [...newProject.skills]
  );

  const [newProjectSkill, setNewProjectSkill] = useState({
    skill_name: "",
    skill_id: null,
  });

  useEffect(() => {
    if (
      !newProjectSkill ||
      !Object.values(newProjectSkill)[0] ||
      Object.values(newProjectSkill)[0].length === 0
    )
      return;

    let temp = [...projectSkills];
    temp.push(newProjectSkill);
    setProjectSkills(temp);
    setProject({ ...project, skills: [...temp] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProjectSkill]);

  const removeSkill = (id) => {
    let temp = projectSkills.filter((skill) => {
      return id !== skill.skill_id;
    });
    setProjectSkills(temp);
    setProject({ ...project, skills: temp });
  };

  useEffect(() => {
    setNewProject(project);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {}, [newProject]);

  const handleChange = (e) => {
    setProject({
      ...project,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <>
      <div className="mx-5 px-1">
        <div className="row my-2">
          <div className="form-group text-left w-100">
            <label htmlFor="title" className="fs-14 fw-500 mb-1 ">
              Project Name
            </label>
            <input
              onChange={handleChange}
              type="text"
              class="form-control shadow_1-lightest profile-field "
              id="title"
              aria-describedby="emailHelp"
              value={project.title}
              placeholder=""
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="form-group text-left w-100">
            <label htmlFor="links" className="fs-14 fw-500 mb-1 ">
              Project Link
            </label>
            <input
              onChange={handleChange}
              type="text"
              class="form-control shadow_1-lightest profile-field "
              id="links"
              aria-describedby="emailHelp"
              value={project.links}
              placeholder=""
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="form-group text-left w-100">
            <label htmlFor="description" className="fs-14 fw-500 mb-1">
              Project Description
            </label>
            <textarea
              onChange={handleChange}
              class="form-control shadow_1-lightest profile-field "
              id="description"
              aria-describedby="emailHelp"
              value={project.description}
              placeholder=""
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-6 pl-0">
            <div className="form-group text-left w-100">
              <label htmlFor="start_date" className="fs-14 fw-500 mb-1 ">
                Start Date
              </label>
              <input
                onChange={handleChange}
                type="date"
                class="form-control shadow_1-lightest profile-field "
                id="start_date"
                aria-describedby="emailHelp"
                value={project.start_date}
                placeholder=""
              />
            </div>
          </div>
          <div className="col-6 pr-0">
            <div className="form-group text-left w-100">
              <label htmlFor="end_date" className="fs-14 fw-500 mb-1 ">
                End Date
              </label>
              <input
                onChange={handleChange}
                type="date"
                class="form-control shadow_1-lightest profile-field "
                id="end_date"
                aria-describedby="emailHelp"
                value={project.end_date}
                placeholder=""
                min={project.start_date ? project.start_date : null}
              />
            </div>
          </div>
        </div>

        <div className="row my-2">
          <div className="form-group text-left w-100">
            <label htmlFor="skills" className="fs-14 fw-500 mb-1 t">
              Skills
            </label>
            <Skills
              type={"all"}
              newSkill={newProjectSkill}
              setNewSkill={setNewProjectSkill}
            />
            <div className="selected-skill">
              {type === "ADD_PROJECT"
                ? projectSkills &&
                  projectSkills.map((skill) => {
                    return (
                      <span
                        key={skill.skill_id}
                        className={
                          "gradient-5  m-2 px-3 py-3 badge badge-primary skill-badge fs-13"
                        }
                      >
                        {skill.skill_name}
                        <i
                          onClick={() => {
                            removeSkill(skill.skill_id);
                          }}
                          className="fas fa-times-circle pl-1 skill-remove"
                        ></i>
                      </span>
                    );
                  })
                : projectSkills &&
                  projectSkills.map((skill) => {
                    return (
                      <span
                        key={skill.skill_id}
                        className={
                          "gradient-5  m-2 px-3 py-3 badge badge-primary skill-badge fs-13"
                        }
                      >
                        {skill.skill_name}
                        <i
                          onClick={() => {
                            removeSkill(skill.skill_id);
                          }}
                          className="fas fa-times-circle pl-1 skill-remove"
                        ></i>
                      </span>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectForm;

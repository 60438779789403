import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        height: "40px",
        borderRadius: 30,
        "&&& $input": {
          padding: "4px 4px",
          fontSize: 13,
        },
      },
    },
  },
});

export default theme;

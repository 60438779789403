import { createStyles, FormControlLabel, IconButton, makeStyles, Switch, TextField, Toolbar, useTheme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { useLocation } from "react-router-dom";
import SelectSearch from "react-select-search";
import filter_icon from "../../../assets/common/Filter2.png";
import { AllCompanyProps, AllJobProfilesProps, JobTypeProps } from "../../../pages/college/NewJob/@types";
import "./Panes.css";

interface TrackPaneProps {
  profiles?: AllJobProfilesProps[];
  jobProfile?: string;
  setJobProfile: any;
  setShow?: any;
  search?: any;
  setSearch?: any;
  jobTypes: JobTypeProps[];
  companies?: any;
  setJobType?: any;
  setCompany?: any;
  selectedJobType: string | number;
  setSelectedJobType: any;
  allCompanies: AllCompanyProps[];
  setSelectedCompany: any;
  onlyClosed?: any;
  setOnlyClosed?: any;
  jobProfileObject?: any;
  setJobProfileObject?: any;
  JobProfileObject?: any;
  searching?: any;
  setSearching?: any;
  buttonClickSearch?: any;
  setButtonClickSearch?: any;
  setJobData?: any;
  setEnd?: any;
  setNewURL?: any;
  setNewEndPoint?: any;
  path?: string;
}

const TrackPane = (Props: TrackPaneProps) => {
  const {
    profiles,
    jobProfile,
    setJobProfile,
    setShow,
    search,
    setSearch,
    jobTypes,
    companies,
    setJobType,
    setCompany,
    selectedJobType,
    setSelectedJobType,
    allCompanies,
    setSelectedCompany,
    onlyClosed,
    setOnlyClosed,
    jobProfileObject,
    setJobProfileObject,
    searching,
    setSearching,
    buttonClickSearch,
    setButtonClickSearch,
    setJobData,
    setEnd,
    setNewURL,
    setNewEndPoint,
    path = "DEFAULT",
  } = Props;
  const location = useLocation();

  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div
        className={classes.appBar} // @ts-ignore
        id={theme.palette.type === "dark" && "theme-select-search"}
      >
        <Toolbar className={classes.toolBar}>
          <img src={filter_icon} />

          <div className="dropdown ml-4">
            <SelectSearch
              //@ts-ignore
              options={
                jobTypes &&
                jobTypes.length > 0 &&
                jobTypes.map((item) => ({
                  name: item.job_type,
                  value: item.id,
                }))
              }
              //@ts-ignore

              //@ts-ignore
              onChange={(val) => {
                setJobData([]);
                setSelectedJobType(val);
              }}
              placeholder="Job Type"
              search
            />
          </div>

          <div className="dropdown ml-4">
            <SelectSearch
              //@ts-ignore
              options={
                allCompanies &&
                allCompanies.length > 0 &&
                allCompanies.map((item) => ({
                  //@ts-ignore
                  name: item.company_name,
                  value: item.company_id,
                }))
              }
              //@ts-ignore
              onChange={(val) => {
                setJobData([]);
                setSelectedCompany(val);
              }}
              placeholder="Company"
              search
            />
          </div>
          {!["AVAILABLE", "OPEN", "CLOSE"].includes(path) && (
            <div className="dropdown ml-4">
              <SelectSearch
                //@ts-ignore
                options={
                  profiles &&
                  profiles.length > 0 &&
                  profiles.map((item) => ({
                    name: item.job_title,
                    value: item.job_id,
                  }))
                }
                onChange={(val) => {
                  setJobProfile(val);
                  //@ts-ignore
                  profiles.length > 0 &&
                    //@ts-ignore
                    profiles.map((item) => {
                      //@ts-ignore
                      if (item.job_id === val) setJobProfileObject(item);
                    });
                }}
                placeholder="Profile"
                search
              />
            </div>
          )}

          {!["AVAILABLE", "OPEN", "CLOSE"].includes(path) && (
            <FormControlLabel
              value="end"
              control={<Switch size="small" color="primary" checked={onlyClosed} onChange={(e) => setOnlyClosed(e.target.checked)} />}
              label="Closed jobs"
              labelPlacement="end"
            />
          )}

          {["AVAILABLE", "OPEN", "CLOSE"].includes(path) && (
            <>
              <div className="dropdown ml-3" style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  id="outlined-name-26867"
                  label="Search"
                  type="text"
                  value={searching}
                  onChange={(e) => {
                    setSearching(e.target.value);
                  }}
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                />
                <IconButton
                  color="primary"
                  aria-label="search"
                  // style={{ marginTop: "20" }}
                  onClick={() => {
                    if (buttonClickSearch !== searching) {
                      setButtonClickSearch(searching);
                      setJobData([]);
                      setEnd(false);
                      setNewEndPoint("");
                      setNewURL("");
                    }
                  }}
                  disabled={searching === "" ? true : false}
                >
                  <SearchIcon />
                </IconButton>
              </div>
            </>
          )}
        </Toolbar>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      // width: `calc(100% - 215px)`,
      // marginLeft: "220px",
      width: "78%",
      position: "fixed",
      borderRadius: "30px",
      zIndex: 1,
      background: theme.palette.background.paper,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      // marginTop: "66px",
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
    },
    headerTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000000",
      fontFamily: "OpenSans",
    },
    headerTitle2: {
      fontSize: "18px",
      color: "#000000",
      fontFamily: "Proxima",
    },
    dropdown: {
      width: "220px",
      height: "36px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    searchBox: {
      width: "219px",
      height: "36px",
      background: "#F2F2F2",
      boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 4,
      padding: "0% 2%",
    },
    selectedTabText: {
      color: "#000",
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Proxima",
    },
    normalTabText: {
      color: "#000",
      fontSize: "18px",

      fontFamily: "Proxima",
    },
    selectedTab: {
      height: "40px",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      backgroundColor: "#E5E5E5",
      position: "absolute",
      top: "43%",
      borderRadius: "4px 4px 0px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
    },
    normalTab: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
      height: "40px",
    },
    mainContentBox: {
      width: `calc(100% - 220px)`,
      marginLeft: "220px",
      background: "#E5E5E5",
      padding: "1% 2%",
      boxSizing: "border-box",
      display: "flex",
    },
    filterPanel: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      width: "240px",
    },
  })
);
export default TrackPane;

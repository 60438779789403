import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Cards from './Cards/Cards';
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import MobileFilter from '../Filter/MobileFilter';
import Pagination from '@material-ui/lab/Pagination';
import Loader from '../../../bundles/common/components/UI/Loader';
import NoData from '../../../assets/gif/No_data.gif';

const useStyles = makeStyles((theme) => ({
    content: {
      flexGrow: 1,
      minHeight: '100vh',
      overflow: 'auto',
      marginTop: '64px',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: "auto",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      flexDirection: 'column',
      backgroundColor: "#ffffff",
    },
    paginationBlock:{
      display: "flex",
      justifyContent: "center",
      paddingTop: "15px"
    }
  }));


export default function PublicCollegeMain({filterData, collegeData, collegeCount,setPageChange, collegeDataFetched, setFilters, isFilterDataPresent, appliedFilters}) {
    const classes = useStyles();

    return (
        <main className={classes.content}>
        <Container className={classes.container}>
         {/* Number of College & filter by option SubMenu  */}
         <Hidden only={['lg','md','xl']}> 
          <MobileFilter filterData={filterData} 
          setFilters={setFilters}
          isFilterDataPresent = {isFilterDataPresent}
          appliedFilters={appliedFilters}/>                     {/* Mobile View Filter  */}
         </Hidden>
        {collegeData?.count > 0 &&
              <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="1% 0%"
              style={{marginTop:"-20px"}}
              >     
              <p
                style={{
                      marginBottom: "0px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      }}>
                {collegeData?.count} Colleges
              </p>
              
              </Box>
        }

        {
         !collegeDataFetched && 
         <Loader />
       }

      {
         collegeData?.results && collegeData?.results.length === 0 && (
           <div className={classes.noJobs}>
            <img src={NoData} width="75%"/>
            </div>
         )
       }
        <Grid container spacing={3}>
        {collegeDataFetched && collegeData?.results && collegeData.results.map((data) => (
            <Cards data={data}/>
            ))}
        </Grid>
        {collegeCount > 0 &&
          <Box className={classes.paginationBlock}>
            <Pagination count={Math.ceil(collegeCount/10)} color="secondary" onChange={(event, page) => {setPageChange(page)}} />
          </Box>
        }
        </Container>
        </main>
        

    )
}
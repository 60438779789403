import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { NEXT_APP_ENDPOINT } from "../../../../../constants/constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },

  linktext: {
    color: "white",
    paddingBottom: "10px",
    "&:hover": {
      textDecoration: "underline",
      color: "white",
    },
  },
  text: {
    marginBottom: "7px",
    fontSize: 14,
    fontFamily: "Nunito",
  },
  title: {
    color: "#fff",
    fontSize: 20,
    fontFamily: "Nunito",
  },
}));

export default function PostJobsInCity() {
  const classes = useStyles();

  const jobChipData = [
    {
      label: "Post Job in Delhi/NCR",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-delhi`,
    },
    {
      label: "Post Job in Bangalore",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-bangalore`,
    },
    {
      label: "Post Job in Mumbai",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-mumbai`,
    },
    {
      label: "Post Job in Pune",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-pune`,
    },
    {
      label: "Post Job in Hyderabad",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-hyderabad`,
    },
    {
      label: "Post Job in Jaipur",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-jaipur`,
    },
    {
      label: "Post Job in Bhopal",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-bhopal`,
    },
    // {
    //   label: "Post Job in Indore",
    //   link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-indore",
    // },
    {
      label: "Post Job in Chandigarh",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-chandigarh`,
    },
    {
      label: "Post Job in Ahmedabad",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-ahmedabad`,
    },
    {
      label: "Post Job in Surat",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-surat`,
    },

    {
      label: "Post Job in Nagpur",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-nagpur`,
    },
    {
      label: "Post Job in Lucknow",
      link: `${NEXT_APP_ENDPOINT}/employer/post-jobs-in-lucknow`,
    },
    // {
    //   label: "Post job in Mohali",
    //   link: "/employer/post-jobs-in-Mohali",
    // },
    // {
    //   label: "Post job in Sonipat",
    //   link: "/employer/post-jobs-in-sonipat",
    // },
    // {
    //   label: "Post job in Rudrapur",
    //   link: "/employer/post-jobs-in-rudrapur",
    // },
  ];

  return (
    <>
      <Typography variant="h4" className={classes.title} gutterBottom>
        Post Jobs in City
      </Typography>
      <br />
      {jobChipData.map((item) => (
        <a href={item.link} className={classes.linktext}>
          <Typography
            variant="body2"
            color="inherit"
            className={classes.text}
            id="footerBtn"
          >
            {item.label}{" "}
          </Typography>{" "}
        </a>
      ))}
    </>
  );
}

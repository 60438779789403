import { GET_SUBDOMAIN_DETAILS } from "./action";

const SubDomainDetails = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBDOMAIN_DETAILS:
      return { ...state, subDomainDetails: action.payload };
    default:
      return state;
  }
};

export default SubDomainDetails;

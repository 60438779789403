import React from "react"
import { Avatar } from "@material-ui/core";
import logo from "../../../assets/company/png/de.png"

export default function CompanyLogo({ styles, companyWebsite }) {
    return (
        <Avatar
            src={companyWebsite ? `//logo.clearbit.com/${companyWebsite}` : logo}
            onError={(e) => {
                e.target.onerror = () => null
                e.target.src = logo
            }}
            style={styles ? styles : { width: 50, height: 50 }}
            alt="company-picture"
        />
    )
}
import React from "react";
import { useHistory } from "react-router-dom";
import notfoundImg from "../../../assets/images/404.png";

const NotFound = () => {
  const history = useHistory();
  const check = localStorage.getItem("gw_token") ? true : false;
  return (
    <>
      {/* <h1>404 not found</h1> */}
      <img src={notfoundImg} alt="" width="100%" height="auto" />
      <div className="row justify-content-center mt-5">
        <p className="fs-18 text-secondary" style={{ color: "#4F4F4F" }}>
          Oopes! The webpage you are looking for is not here
        </p>
      </div>
      <div className="row justify-content-center mt-3">
        {check ? (
          <button
            onClick={() => {
              history.push("/student/dashboard");
            }}
            className="shadow_1 btn btn-lg btn-main btn-login font-weight-bold mb-2"
            style={{ borderRadius: "24px" }}
          >
            Dashboard
          </button>
        ) : (
          <button
            onClick={() => {
              history.push("/login");
            }}
            className="shadow_1 btn btn-lg btn-main btn-login font-weight-bold mb-2"
            style={{ borderRadius: "24px" }}
          >
            Login
          </button>
        )}
      </div>
    </>
  );
};

export default NotFound;

import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { BackendBaseApi } from "../../../../constants/constants";

const Companies = ({ type, defaultID, defaultName, state, setState }) => {
  const [companyList, setCompanyList] = useState({});
  const [company, setCompany] = useState(defaultName);
  const [showCompanyInput, setShowCompanyInput] = useState(
    type == "EDIT_WORKEX" && defaultID == null ? true : false
  );

  const companies = Object.keys(companyList).map((company) => {
    return { name: company, value: company };
  });
  const TypeAndSearchCompany = () =>
    defaultName == null ? (
      <SelectSearch
        options={companies}
        value={company}
        onChange={setCompany}
        search
        placeholder="Search company"
      />
    ) : (
      <SelectSearch
        options={companies}
        value={company}
        onChange={setCompany}
        search
      />
    );

  const getAllcompanies = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/company_list")
      .then((res) => {
        let temp = {};
        res.data.data.all_companies.forEach((company) => {
          temp[company.company] = company.id;
        });

        setCompanyList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllcompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showCompanyInput
      ? setState({ company_id: null, company_name: company })
      : setState({ company_id: companyList[company], company_name: company });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <>
      {showCompanyInput ? (
        <input
          onChange={(e) => {
            setCompany(e.target.value);
          }}
          type="text"
          value={company}
          className="form-control shadow_1-lightest"
        />
      ) : (
        <TypeAndSearchCompany />
      )}
      <div className="form-check">
        <input
          type="checkbox"
          checked={showCompanyInput}
          onChange={() => {
            setShowCompanyInput(!showCompanyInput);
          }}
          className="form-check-input my-2"
          id="company-checkbox"
        />
        <label className="form-check-label" htmlFor="company-checkbox">
          <small id="emailHelp" className="form-text my-1 fw-500">
            My company is not in the list
          </small>
        </label>
      </div>
    </>
  );
};

export default Companies;

import { TextField, withStyles } from "@material-ui/core";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiFormLabel-root": {
      top: -1,
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 30,
        width: "100%",
      },
      "&:hover fieldset": {
        // borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
      },
    },
  },
})(TextField);

export default CssTextField;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import SelectSearch from "react-select-search";
import {
  BackendBaseApi,
  highestQualifications,
  years,
} from "../../../../constants/constants";
import "../../../../SelectSearch.css";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});
const StudentEducationalDetails = ({
  step,
  setStep,
  showField,
  setShowField,
  studentDetails,
  setStudentDetails,
}) => {
  //UI state variables
  const firstTime = useRef(true);
  const alert = useAlert();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [showTooltip, setShowTooltip] = useState(false);

  //UI state variables for conditionally showing fields

  // const [collegeList,setCollegeList]=useState([])
  let hashQualification = {
    Diploma: 6,
    Graduation: 3,
    "Post Graduation": 4,
    PhD: 5,
  };
  const [qualificationList, setQualificationList] = useState([]);

  //hash maps for storing ids and returning them in O(1)
  let [hashState, setHashState] = useState({});
  let [hashCity, setHashCity] = useState({});
  let [hashCollege, setHashCollege] = useState({});
  let [hashCourse, setHashCourse] = useState({});
  let [hashBranch, setHashBranch] = useState({});
  const [loader, setLoader] = useState(false);
  const classes = useStyles();

  let allState = useRef(false);
  let allCity = useRef(false);
  // let  [hashCollege,setHashCollege]=useState({})
  // let  [hashCourse,setHashCourse]=useState({})
  // let  [hashBranch,setHashBranch]=useState({})

  //state variables for storing user details
  const [stateList, setStateList] = useState([]);
  let [cityList, setCityList] = useState([]);
  const [collegeList, setCollegeList] = useState("");
  const [courseList, setCourseList] = useState("");
  const [branchList, setBranchList] = useState("");
  const [college, setCollege] = useState("");
  const [collegeState, setCollegeState] = useState("");
  const [collegeCity, setCollegeCity] = useState("");
  const [collegeID, setCollegeID] = useState(null);
  const [courseID, setCourseID] = useState(null);
  const [branchID, setBranchID] = useState(null);
  const [qualification, setQualification] = useState("");
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [year, setYear] = useState("");
  const [checking, setChecking] = useState(false);

  //Fields and Dropdown components
  const QualificationDropdown = () => (
    <SelectSearch
      value={qualification}
      onChange={setQualification}
      options={highestQualifications}
      placeholder="Select your ongoing/recent acquired degree"
    />
  );
  const YearDropdown = () => (
    <SelectSearch
      value={year}
      options={years}
      placeholder="Select"
      onChange={setYear}
    />
  );
  const TypeAndSearchCollege = () => (
    <SelectSearch
      options={collegeList}
      emptyMessage={() => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop="10px"
        >
          <CircularProgress size={20} />
        </Box>
      )}
      value={college}
      onChange={setCollege}
      search
      placeholder="Type and Search your college from the list"
    />
  );

  const TypeAndSearchState = () => (
    <SelectSearch
      options={stateList}
      emptyMessage={() => (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={20} />
        </Box>
      )}
      value={collegeState}
      onChange={setCollegeState}
      search
      placeholder="Search State"
    />
  );

  const TypeAndSearchCity = () => (
    <SelectSearch
      options={cityList}
      emptyMessage={() => (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={20} />
        </Box>
      )}
      value={collegeCity}
      onChange={setCollegeCity}
      search
      placeholder="Search City"
    />
  );

  const TypeAndSearchCourse = () => (
    <SelectSearch
      options={courseList}
      emptyMessage={() => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop="15px"
        >
          {courseList === "" ? (
            <CircularProgress size={20} />
          ) : (
            "No courses available"
          )}
        </Box>
      )}
      search
      value={course}
      onChange={setCourse}
      placeholder="Type and Search your course from the list"
    />
  );

  const TypeAndSearchBranch = () => (
    <SelectSearch
      options={branchList}
      emptyMessage={() => (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={20} />
        </Box>
      )}
      search
      value={branch}
      onChange={setBranch}
      placeholder="Type and Search your branch from the list"
    />
  );

  //LIFECYCLE HOOKS

  //this lifecycle hook fires when component first mounts
  useEffect(() => {
    getCollegeList();
    if (firstTime.current) {
      firstTime.current = false;
      return;
    } else {
      setCollege(studentDetails.CollegeName);
      setCollegeID(studentDetails.CollegeID);
      setCourse(studentDetails.Course);
      setCourseID(studentDetails.CourseID);
      setBranch(studentDetails.branch);
      setBranchID(studentDetails.BranchID);
      setQualification(studentDetails.Qualification);
      setYear(studentDetails.year);
    }
  }, []);

  //this lifecycle hook fires when some details are set
  useEffect(() => {
    if (!showField.College && hashCollege[college]) {
      let courseID = "",
        branchID = "";

      if (hashCourse[course] && hashCollege[college].onBoard)
        courseID = hashCourse[course].d_id;
      else courseID = hashCourse[course];

      if (!showField.Branch && hashBranch[branch])
        branchID = hashBranch[branch].b_id;

      setStudentDetails({
        ...studentDetails,
        // CollegeState:hashState[collegeState],
        // collegeCity:hashCity[collegeCity],
        CollegeName: college,
        CollegeID: hashCollege[college].c_id,
        Course: course,
        CourseID: courseID,
        Branch: branch,
        BranchID: branchID,
        Qualification: qualification,
        QualificationID: hashQualification[qualification],
        Year: year,
      });
    } else {
      let branchID = "";
      if (!showField.Branch && hashBranch[branch])
        branchID = hashBranch[branch].b_id;

      setStudentDetails({
        ...studentDetails,
        CollegeStateID: hashState[collegeState],
        CollegeCityID: hashCity[collegeCity],
        CollegeName: college,
        CourseID: hashCourse[course],
        BranchID: branchID,
        Course: course,
        Branch: branch,
        Qualification: qualification,
        QualificationID: hashQualification[qualification],
        Year: year,
      });
    }
  }, [college, collegeState, collegeCity, qualification, course, branch, year]);

  // this lifecycle hook fires when qualification,college,branch,or course is selected by the user
  useEffect(() => {
    //check if college is on boarded or not

    if (!showField.College && hashQualification[qualification]) {
      //CASE 1: college is on board, GET degrees for the selected college ID
      if (
        hashCollege[college] &&
        hashCollege[college].onBoard &&
        hashCollege[college].c_id
      ) {
        let collegeID = hashCollege[college].c_id;
        let qualificationID = hashQualification[qualification];

        getDegreeListByQualification(collegeID, qualificationID);
      }

      //CASE 2: college is not on board but in GetWork db, get all degrees
      if (hashCollege[college] && !hashCollege[college].onBoard) {
        getAllDegrees(hashQualification[qualification]);
      }
    } else if (showField.College) {
      //CASE 3: college is not in GetWork db

      getAllDegrees(hashQualification[qualification]);
    }
  }, [qualification, college]);

  //this lifecycle hook fires when course is selected by the user

  //this lifecycle hook fires when college's state is set
  useEffect(() => {
    let stateID = hashState[collegeState];
    if (stateID) getCityList(stateID);
  }, [collegeState]);

  useEffect(() => {
    if (showField.College)
      if (!allState.current) {
        getStateList();
        allState.current = true;
      }
  }, [showField.College]);

  useEffect(() => {
    if (showField.College && showField.Course) {
      setShowField({ ...showField, Branch: true });
    }

    if (
      !showField.College &&
      hashCollege[college.toString()] &&
      hashCollege[college.toString()]["onBoard"]
    ) {
      if (
        hashCourse[course] &&
        hashCourse[course].isBranch &&
        hashCourse[course].d_id
      ) {
        let collegeID = hashCollege[college].c_id;
        let qualificationID = hashQualification[qualification];
        let courseID = hashCourse[course].d_id;

        getBranchListByID(collegeID, qualificationID, courseID);
      }
    } else if (
      (!showField.College &&
        hashCollege[college] &&
        !hashCollege[college].onBoard) ||
      showField.College
    ) {
      let courseID = hashCourse[course];
      getAllBranchesByDegree(courseID);
    } else {
    }
  }, [course]);

  //API Calls
  //GET all degrees
  const getAllDegrees = (qualificationID) => {
    axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/education/degree/" +
          (qualificationID ? "?search=" + qualificationID : "")
      )
      .then((res) => {
        let temp = [];
        res.data.forEach((degreeData) => {
          hashCourse[degreeData.name] = degreeData.id;
          let tempObj = {
            name: degreeData.name,
            value: degreeData.name,
            id: degreeData.id,
            // is_spcl:degreeData.is_specialized
          };
          temp.push(tempObj);
        });

        setCourseList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //GET branches by Course/Degree id
  const getAllBranchesByDegree = (degreeID) => {
    setLoader(true);

    const url =
      BackendBaseApi.PRAVESH +
      "api/education/getspecialization/" +
      (degreeID === undefined ? "" : "?search=" + degreeID);

    axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/education/getspecialization/" +
          (degreeID === undefined ? "" : "?search=" + degreeID)
      )
      .then((res) => {
        let temp = [];

        res.data
          .map((item) => item.specialization)
          .forEach((branchData) => {
            if (branchData !== null) {
              hashBranch[branchData.name] = {
                b_id: branchData.id,
              };
              let tempObj = {
                name: branchData.name,
                value: branchData.name,
                id: branchData.id,
              };
              temp.push(tempObj);
            }
          });
        const uniqueAddresses = Array.from(new Set(temp.map((a) => a.id))).map(
          (id) => {
            return temp.find((a) => a.id === id);
          }
        );
        setBranchList(uniqueAddresses);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        console.log(err);
      });
  };

  //GET all citites by state id
  const getCityList = (stateID) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/location/city/?search=" + stateID)
      .then((res) => {
        let temp = [];
        let citySet = new Set();
        res.data.forEach((cityObj) => {
          if (citySet.has(cityObj.city)) return;
          hashCity[cityObj.city] = cityObj.city_id;
          citySet.add(cityObj.city);
          let tempObj = {
            name: cityObj.city,
            value: cityObj.city,
            state_id: cityObj.state_id,
            id: cityObj.city_id,
          };
          temp.push(tempObj);
        });

        setCityList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //GET all colleges
  const getCollegeList = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/education/college/")
      .then((res) => {
        let temp = [];
        res.data.forEach((collegeData) => {
          hashCollege[collegeData.name] = {
            c_id: collegeData.id,
            onBoard: collegeData.is_onboarded,
          };
          let tempObj = {
            name: collegeData.name,
            // + (collegeData.is_onboarded===true?<React.Fragment>
            //     <VerifiedUserIcon/></Fragment>:'')
            value: collegeData.name,
            id: collegeData.id,
          };
          temp.push(tempObj);
        });

        setCollegeList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //GET all states
  const getStateList = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/location/state/")
      .then((res) => {
        let temp = [];
        res.data.forEach((stateObj) => {
          hashState[stateObj.state] = stateObj.state_id;
          let tempObj = {
            name: stateObj.state,
            value: stateObj.state,
            id: stateObj.state_id,
          };
          temp.push(tempObj);
        });

        setStateList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //GET all course/degrees by qualification/education level
  const getDegreeListByQualification = (collegeID, qualificationID) => {
    axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/education/collegeeducation/?college_id=" +
          collegeID +
          "&qualification_level=" +
          qualificationID
      )
      .then((res) => {
        let temp = [];
        let uniqueDegrees = new Set();
        res.data.forEach((courseData) => {
          if (uniqueDegrees.has(courseData.degree.id)) return;
          else uniqueDegrees.add(courseData.degree.id);

          hashCourse[courseData.degree.name] = {
            d_id: courseData.degree.id,
            isBranch: courseData.degree.is_specialized,
          };
          let tempObj = {
            name: courseData.degree.name,
            value: courseData.degree.name,
            id: courseData.degree.id,
          };
          temp.push(tempObj);
        });

        setCourseList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //GET all branches by college id, education lv and degree id
  const getBranchListByID = (collegeID, qualificationID, degreeID) => {
    setLoader(true);
    axios
      .get(
        BackendBaseApi.PRAVESH +
          "api/education/collegeeducation/?college_id=" +
          collegeID +
          "&qualification_level=" +
          qualificationID +
          "&degree_id=" +
          degreeID
      )
      .then((res) => {
        let temp = [];

        res.data.forEach((branchData) => {
          hashBranch[branchData.specialization.name] = {
            b_id: branchData.specialization.id,
          };
          let tempObj = {
            name: branchData.specialization.name,
            value: branchData.specialization.name,
            id: branchData.specialization.id,
          };
          temp.push(tempObj);
        });

        setBranchList(temp);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoader(false);
      });
  };

  //EVENT HANDLERS

  const handleChange = (e) => {
    let val = e.target.value;
    switch (e.target.id) {
      case "CollegeName":
        setCollege(val);
        break;
      case "Course":
        setCourse(val);
        break;
      case "HighestQualification":
        setQualification(val);
        break;
      case "Branch":
        setBranch(val);
        break;
      case "Year":
        setYear(val);
        break;
    }
  };

  const validateRefferalCode = async () => {
    let isValid = false;
    const response = await axios.post(
      BackendBaseApi.PRAVESH + "api/referrals/user_referrals/",
      {
        type: 1,
        referral_code: studentDetails.RefferalCode,
      }
    );

    response.data.success
      ? alert.success(response.data.message)
      : alert.error(response.data.message);
    return response;
  };

  const setDetails = () => {
    if (!showField.College) {
      //College is selected from the list
      if (hashCollege[college] && hashCollege[college].onBoard) {
        //College is on board
        if (!showField.Course && !showField.Branch) {
          setStudentDetails({ ...studentDetails, Case: 1 });
          alert.show("CASE 1 education details");
          setStep(step + 1);
        } else {
          alert.error(
            "Please select your course from dropdown only, if you will not find your course kindly contact your college!"
          );
        }
      } else if (hashCollege[college] && !hashCollege[college].onBoard) {
        //college is not on board
        if (!showField.Course && !showField.Branch) {
          //all fields selected from dropdown only
          setStudentDetails({ ...studentDetails, Case: 2 });
          alert.show("CASE 2 education details");
          setStep(step + 1);
        } else if (!showField.Course && showField.Branch) {
          //course from list branch not in list
          setStudentDetails({ ...studentDetails, Case: 3 });
          alert.show("CASE 3 education details");
          setStep(step + 1);
        } else {
          //both course and branch not in list
          setStudentDetails({ ...studentDetails, Case: 4 });
          alert.show("CASE 4 education details");
          setStep(step + 1);
        }
      }
    } else {
      //College is not selected from the list
      if (!showField.Course && !showField.Branch) {
        //all fields selected from dropdown only
        setStudentDetails({ ...studentDetails, Case: 5 });
        alert.show("CASE 5 education details");
        setStep(step + 1);
      } else if (!showField.Course && showField.Branch) {
        //course from list branch not in list
        setStudentDetails({ ...studentDetails, Case: 6 });
        alert.show("CASE 6 education details");
        setStep(step + 1);
      } else {
        //both course and branch not in list
        setStudentDetails({ ...studentDetails, Case: 7 });
        alert.show("CASE 7 education details");
        setStep(step + 1);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.document.getElementById("myForm").onkeypress = function (e) {
      var key = e.charCode || e.keyCode || 0;

      if (key === 13) {
        return false;
      }
    };

    e.preventDefault();
    // if (!studentDetails.RefferalCode) {
    //   setDetails();
    //   return;
    // }
    // const res = validateRefferalCode();

    // res.then((data) => {
    //   if (!data.data.success) return;

    //   setDetails();
    // });
  };

  const submitOnClick = () => {
    // if (!studentDetails.RefferalCode) {
    //   setDetails();
    //   return;
    // }
    // const res = validateRefferalCode();

    // res.then((data) => {
    //   if (!data.data.success) return;

    //   setDetails();
    // });
    setDetails();
  };

  // window.document.getElementById("myForm").onkeypress = function (e) {
  //   var key = e.charCode || e.keyCode || 0;
  //   if (key == 13) {
  //     alert("I told you not to, why did you do it?");
  //     e.preventDefault();
  //   }
  // };

  // COMPONENT UI
  return (
    <>
      <div className="row pl-3">
        <h1 className="mb-4 login-heading text-left" style={{ color: "#000" }}>
          Enter your Educational Details
        </h1>
      </div>

      <form onSubmit={handleSubmit} className="my-md-0 my-2" id="myForm">
        <div className="form-group required text-left mb-3">
          {showField.College && (
            <>
              <label
                htmlFor="CollegeName"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Enter your College Name{" "}
              </label>
              <input
                type="text"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="CollegeName"
                aria-describedby="emailHelp"
                placeholder=""
                onChange={handleChange}
                required
                autoFocus
              />

              {/* {showField.CollegeState && ( */}
              <div className="form-group text-left mb-2 mt-3">
                <label
                  htmlFor="CollegeLocation"
                  className="fw-500 control-label"
                  style={{ color: "#424242" }}
                >
                  Choose your college Location{" "}
                </label>
                <div className="row">
                  <div className="col-6">
                    <TypeAndSearchState className="form-control shadow_1-lightest" />
                  </div>

                  <div className="col-6">
                    <TypeAndSearchCity className="form-control shadow_1-lightest" />
                  </div>
                </div>
              </div>
            </>
          )}
          {!showField.College && (
            <>
              <label
                htmlFor="CollegeName"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Choose your College{" "}
              </label>
              <TypeAndSearchCollege className="form-control shadow_1-lightest" />
            </>
          )}

          {
            <div className="form-check mb-3">
              <input
                type="checkbox"
                checked={showField.college}
                onChange={() => {
                  setShowField({ ...showField, College: !showField.College });
                }}
                className="form-check-input my-2"
                id="college-checkbox"
              />
              <label className="form-check-label" htmlFor="college-checkbox">
                <small id="emailHelp" className="form-text my-1 fw-500">
                  My college is not in the list
                </small>
              </label>
            </div>
          }
        </div>
        <div className="form-group required text-left mb-3">
          {college && showField.Qualification && (
            <>
              <label
                htmlFor="Qualification"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Mention your Highest Qualification
              </label>
              <input
                type="text"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="HighestQualification"
                aria-describedby="emailHelp"
                placeholder=""
                onChange={handleChange}
                required
                autoFocus
              />
            </>
          )}
          {college && !showField.Qualification && (
            <>
              <label
                htmlFor="Qualification"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Select your Highest Qualification
              </label>
              <QualificationDropdown className="form-control shadow_1-lightest" />
            </>
          )}
        </div>
        <div className="form-group required text-left mb-3">
          {qualification && showField.Course && (
            <>
              <label
                htmlFor="Course"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Mention your Course
              </label>
              <input
                type="text"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="Course"
                aria-describedby="emailHelp"
                placeholder=""
                onChange={handleChange}
                required
                autoFocus
              />
            </>
          )}
          {qualification && !showField.Course && (
            <>
              <label
                htmlFor="Course"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Choose your Course
              </label>
              <TypeAndSearchCourse className="form-control shadow_1-lightest" />
            </>
          )}
          {qualification && (
            <>
              <div className="form-check mb-3">
                <input
                  // disabled={hashCollege[college]!=='undefined'?hashCollege[college].onBoard:false}
                  type="checkbox"
                  checked={showField.Course}
                  onChange={() => {
                    setShowField({ ...showField, Course: !showField.Course });
                  }}
                  className="form-check-input my-2"
                  id="course-checkbox"
                />
                <label className="form-check-label" htmlFor="course-checkbox">
                  <small id="emailHelp" className="form-text my-1 fw-500">
                    My Course is not in the list
                  </small>
                </label>
              </div>
            </>
          )}

          {loader ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="5%"
              >
                <CircularProgress className={classes.progress} />
              </Box>
            </>
          ) : (
            qualification &&
            branchList.length !== 0 && (
              <>
                <div className="form-check mb-3">
                  <input
                    // disabled={hashCollege[college]!=='undefined'?hashCollege[college].onBoard:false}
                    type="checkbox"
                    checked={checking}
                    onChange={() => {
                      setChecking(!checking);
                    }}
                    className="form-check-input my-2"
                    id="course-checkbox"
                  />
                  <label className="form-check-label" htmlFor="course-checkbox">
                    <small id="emailHelp" className="form-text my-1 fw-500">
                      Is your Course related to any branch?
                    </small>
                  </label>
                </div>
              </>
            )
          )}
        </div>
        <div className="form-group required text-left mb-3">
          {course && showField.Branch && checking === true && (
            <>
              <label
                htmlFor="Branch"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Mention your Branch
              </label>
              <input
                type="text"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="Branch"
                aria-describedby="emailHelp"
                placeholder=""
                onChange={handleChange}
                required
              />
            </>
          )}
          {course &&
            !showField.Branch &&
            branchList.length !== 0 &&
            checking === true && (
              <>
                <label
                  htmlFor="Branch"
                  className="fw-500 control-label"
                  style={{ color: "#424242" }}
                >
                  Choose your Branch
                </label>
                <TypeAndSearchBranch className="form-control shadow_1-lightest" />
              </>
            )}
          {course && branchList.length !== 0 && checking === true && (
            <>
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  checked={showField.Branch}
                  onChange={() => {
                    setShowField({ ...showField, Branch: !showField.Branch });
                  }}
                  className="form-check-input my-2"
                  id="branch-checkbox"
                />
                <label className="form-check-label" htmlFor="branch-checkbox">
                  <small id="emailHelp" className="form-text my-1 fw-500">
                    My Branch is not in the list
                  </small>
                </label>
              </div>
            </>
          )}
        </div>
        <div className="form-group required text-left mb-3">
          {course && loader === false && (
            <>
              <label
                htmlFor="Year"
                className="fw-500 control-label"
                style={{ color: "#424242" }}
              >
                Select your current year
              </label>
              <YearDropdown className="form-control shadow_1-lightest" />
            </>
          )}
        </div>
        {/* <div className="form-group text-left mb-3">
          {year && (
            <>
              <label
                htmlFor="RefferalCode"
                className="fw-500"
                style={{ color: "#424242" }}
              >
                Referral Code
              </label>
              <input
                onChange={(e) => {
                  setStudentDetails({
                    ...studentDetails,
                    RefferalCode: e.target.value,
                  });
                }}
                type="text"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                id="RefferalCode"
                aria-describedby="emailHelp"
                placeholder=""
              />
            </>
          )}
        </div> */}

        <div className="row my-1 pt-md-2 pt-3">
          <div className="col-6 text-left">
            {year && (
              <button
                className=" shadow_1 btn btn-lg btn-main btn-login btn-next text-uppercase font-weight-bold mb-2"
                type="submit"
                onClick={() => submitOnClick()}
              >
                <span>
                  Next <i class="fas fa-angle-double-right" />
                </span>
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default StudentEducationalDetails;

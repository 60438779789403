import React, { useState } from "react";
import "./FormComponents.css";
import { Tooltip, TextField, Button } from "@material-ui/core";
import { Chip, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { uniqueArray } from "../../../../utils/common/filterJob";
import CommonButton from "../../CommonButton/CommonButton";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

function Skills({ allSkills, allSkillRatings, skills, setSkills, alert, positions }) {
  const [skill, setSkill] = useState();
  const [skillRating, setSkillRating] = useState();

  const [viewMore, setViewMore] = useState(false);

  const classes = useStyles();

  const addSkill = (e) => {
    e.preventDefault();
    if (!skill) {
      alert.error("Select Skill", { position: positions.TOP_RIGHT });
      return;
    }
    if (!skillRating) {
      alert.error("Select Skill Rating", { position: positions.TOP_RIGHT });
      return;
    }
    let arr = [...skills];
    let temp = {
      skill_name: skill.skill_name,
      skill_id: skill.skill_id,
      skill_rating: skillRating.rating_name,
      skill_rating_value: skillRating.rating_value,
      type: skill.type,
    };
    arr.push(temp);
    setSkills(uniqueArray(arr));
  };

  const handleDelete = (chipId) => {
    var arr = skills.filter((sk) => sk.skill_id !== chipId);
    setSkills(arr);
  };

  return (
    <div id="skills-form" style={{ marginBottom: "1rem" }}>
      <div className="collapse" id="skill-enter">
        <div id="add_new_autocomplete_div">
          <Autocomplete
            id="tags-outlined"
            className="type_search_autocomplete_two"
            options={allSkills}
            getOptionLabel={(option) => option.skill_name}
            onChange={(e, i) => {
              i ? setSkill(i) : setSkill(null);
            }}
            value={skill}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Select Skill" />}
          />
          <Autocomplete
            id="tags-outlined"
            options={allSkillRatings}
            getOptionLabel={(option) => option.rating_name}
            className="type_search_autocomplete_two"
            onChange={(e, i) => {
              i ? setSkillRating(i) : setSkillRating(null);
            }}
            value={skillRating}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Select Skill Rating" />}
          />
        </div>
        <CommonButton variant="contained" color="primary" style={{ display: "block", margin: "1rem auto", outline: "none" }} onClick={(e) => addSkill(e)}>
          Add Skill
        </CommonButton>
      </div>
      <div>
        {skills.length > 0 ? (
          <span id="box_value" style={{ marginLeft: "1%" }}>
            {skills.length <= 5 ? (
              skills.map((skill, key) => {
                return (
                  <Chip
                    variant="outlined"
                    size="medium"
                    onDelete={() => handleDelete(skill.skill_id)}
                    label={<b style={{ fontSize: "16px" }}>{skill.skill_name}</b>}
                    color="primary"
                    style={{ margin: "0 8px 8px", maxHeight: "25px" }}
                  />
                );
              })
            ) : (
              <>
                {skills.map(
                  (skill, key) =>
                    (key < 5 || viewMore) && (
                      <Chip
                        variant="outlined"
                        size="medium"
                        onDelete={() => handleDelete(skill.skill_id)}
                        label={<b style={{ fontSize: "16px" }}>{skill.skill_name}</b>}
                        color="primary"
                        style={{ margin: "0 8px 8px", maxHeight: "25px" }}
                      />
                    )
                )}
                <Button onClick={() => setViewMore(!viewMore)} style={{ marginLeft: "8px" }}>
                  {viewMore ? "...View Less" : "...View More"}
                </Button>
              </>
            )}
          </span>
        ) : (
          <div id="no_content">
            <span>No Skills Selected yet !!</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Skills;

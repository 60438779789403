import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, createStyles, makeStyles, Grid, Avatar, Chip, useTheme, IconButton, Button, Dialog, TextField, withStyles } from "@material-ui/core";
// import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import kFormatter, { LFormatter } from "../../../utils/ZeroToK";
import { WorkOutlineOutlined, LocationOnOutlined, AssignmentOutlined, BookmarkBorderOutlined, RestoreOutlined } from "@material-ui/icons";
import "./main_style.css";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import AcceptDialog from "./AcceptDialog";
import ViewJob from "../../../components/college/viewjob/Viewjob";
import JobSharerButton from "../../../components/common/JobSharer/JobSharerButton";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import { valueLabelFormat } from "../../../utils/utility";
import { generateCsvNew } from "../../../components/company/common/generateCsv/generateCsv";
import generateCsv from "../../../common/generateCsv/generateCsv";
import logo from "../../../assets/company/png/de.png"

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "red",
        borderRadius: 30,
        width: "100%",
        boxShadow: "0 25px 40px 0 rgba(0, 0, 0, 0.1)",
      },
      "&:hover fieldset": {
        // borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        // borderColor: "green",
      },
    },
  },
})(TextField);

const RecommendedJobCard = ({
  data,
  setData,
  count,
  dataDegree,
  setDataDegree,
  loading,
  setLoading,
  degreeCoursesComing,
  setDegreeCoursesComing,
  allData,
  setAllData,
  handleSubmit,
  end,
  setEnd,
  isApiCall,
  setNextUrl,
  search,
  setSearch,
  buttonClickSearch,
  setButtonClickSearch,
  setButtonClicked,
  getData,
  open,
  handleClickOpen,
  handleClose,
  mainItem,
  setMainItem,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const grey = theme.palette.type === "dark" ? "#ffffff" : "grey";
  const [currentItem, setCurrentItem] = useState({});

  const [openJobData, handleCloseJob] = React.useState(false);

  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const openJob = (item) => {
    setCurrentItem(item);
    setMainItem(item);
    handleCloseJob(!openJobData);
  };
  const callback = (value) => {
    handleCloseJob(value);
  };

  const handleScroll = (event) => {
    let e = event.nativeEvent;

    if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
      if (end !== true && isApiCall) {
        getData();
      }
    }
  };

  useEffect(() => {
    if (open === false) {
      allData.forEach((item, index1) => {
        item.degree_selected = false;
        item.specialization.forEach((i, index) => {
          i.course_selected = false;
        });
      });
      setDataDegree([]);
    }
  }, [open]);

  return (
    <>
      <Grid container marginBottom="2%">
        <Grid item xs={3}>
          <Button className="center notification icon btn btn-default" color="Primary" variant="outlined">
            {" "}
            Total Jobs
            <span className="notification-number">{count}</span>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <div class="containerA2" style={{ marginTop: "10px" }}>
            <span data-title="These Jobs are recommended to you by GetWork." class="text">
              These Jobs are recommended to you by GetWork.
            </span>
          </div>
        </Grid>
        <Grid item xs={3} className={classes.grid2} style={{ display: "flex", flexDirection: "row" }}>
          <CssTextField
            id="outlined-name-2"
            label="Search"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            variant="outlined"
            style={{
              width: "100%",
              borderRadius: 30,
              color: "black",
            }}
          />
          <Box
            style={{
              display: "flex",
              float: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="primary"
              aria-label="search"
              style={{ marginTop: "20" }}
              onClick={() => {
                if (buttonClickSearch !== search) {
                  setButtonClickSearch(search);
                  setButtonClicked(true);
                  setData([]);
                  setEnd(false);
                  setNextUrl(null);
                }
              }}
              disabled={search === "" ? true : false}
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {buttonClickSearch && (
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              marginLeft: 15,
              marginRight: 15,
              marginTop: 10,
            }}
          >
            You searched for "{buttonClickSearch}"
          </p>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSearch("");
              setButtonClickSearch("");
              setData([]);
              setEnd(false);
              setNextUrl(null);
            }}
            style={{ borderRadius: 30, padding: 6 }}
          >
            Clear your Search
          </Button>
        </Box>
      )}

      <AcceptDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        data={dataDegree}
        setData={setDataDegree}
        loading={loading}
        setLoading={setLoading}
        degreeCoursesComing={degreeCoursesComing}
        setDegreeCoursesComing={setDegreeCoursesComing}
        allData={allData}
        setAllData={setAllData}
        handleSubmit={handleSubmit}
        item={mainItem}
      />

      <Dialog
        open={openJobData}
        onClose={() => handleCloseJob(!openJobData)}
        minWidth="md"
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid container justify="flex-end">
          <Grid xs={12} sm={12} style={{ marginTop: "0" }}>
            <ViewJob
              data={currentItem}
              apply={false}
              reject={false}
              open={false}
              handleClosey={callback}
              setLoading={setLoading}
              setJobData={setData}
              confirmationDialog={confirmationDialog}
              setConfirmationDialog={setConfirmationDialog}
            />
          </Grid>
        </Grid>
      </Dialog>
      <div
      // style={{ overflowY: "scroll", width: "inherit", maxHeight:900 }}
      // onScroll={handleScroll}
      >
        {data &&
          data.map((item, index) => (
            <Box className={classes.card} onClick={() => openJob(item)}>
              <Grid container>
                <Grid item xs={10} sm={10}>
                  <Typography style={{ fontSize: 18, fontWeight: "bold" }}>{item.job.job_title}</Typography>

                  <Typography style={{ fontSize: 14 }}>{item.job.company ? item.job.company.company_name : item.job.company_details}</Typography>

                  <Box display="flex" alignItems="center" padding="2px 0px">
                    <Box display="flex" alignItems="center" marginRight="7px">
                      <WorkOutlineOutlined style={{ color: grey }} fontSize="small" />
                      <Typography variant="subtitle1" style={{ color: grey, marginLeft: "5px", fontSize: 13 }}>
                        {item.job.eligibility_criteria?.experience}
                      </Typography>
                    </Box>
                    &nbsp;&middot;&nbsp;
                    <Box display="flex" alignItems="center" marginRight="7px" marginLeft="7px">
                      <Typography style={{ color: grey, fontSize: 13 }} variant="subtitle1">
                        ₹
                      </Typography>
                      {item.job.job_type !== 1 ? (
                        <>
                          {item.job.ctc_min !== 0 && item.job.ctc_max !== 0 && item.job.salary_type === "PAID" ? (
                            <>
                              <Typography variant="subtitle1" style={{ color: grey, marginLeft: "5px", fontSize: 13 }}>
                                {" "}
                                {item.job.ctc_min > 999 ? LFormatter(item.job.ctc_min) : kFormatter(item.job.ctc_min)} -{" "}
                                {item.job.ctc_max > 999 ? LFormatter(item.job.ctc_max) : kFormatter(item.job.ctc_max)} {item.job.salary_payment_type.toLowerCase()}
                              </Typography>
                            </>
                          ) : (item.job.ctc_min !== 0 && item.job.ctc_max !== 0) || item.job.salary_type === "UNPAID" ? (
                            <>
                              <Typography variant="subtitle1" style={{ color: grey, fontSize: 13 }}>
                                Unpaid
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="subtitle1" style={{ color: grey, fontSize: 13 }}>
                                Not Disclosed
                              </Typography>
                            </>
                          )}
                        </>
                      ) : item.job.ppo ? (
                        <>
                          {item.job.ctc_min !== 0 && item.job.ctc_max !== 0 && item.job.salary_type === "PAID" ? (
                            <>
                              <Typography variant="subtitle1" style={{ color: grey, marginLeft: "5px", fontSize: 13 }}>
                                {" "}
                                {item.job.ctc_min > 999 ? LFormatter(item.job.ctc_min) : kFormatter(item.job.ctc_min)} -{" "}
                                {item.job.ctc_max > 999 ? LFormatter(item.job.ctc_max) : kFormatter(item.job.ctc_max)} {item.job.salary_payment_type.toLowerCase()} (PPO)
                              </Typography>
                            </>
                          ) : (item.job.ctc_min !== 0 && item.job.ctc_max !== 0) || item.job.salary_type === "UNPAID" ? (
                            <>
                              <Typography variant="subtitle1" style={{ color: grey, fontSize: 13 }}>
                                Unpaid
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="subtitle1" style={{ color: grey, fontSize: 13 }}>
                                Not Disclosed
                              </Typography>
                            </>
                          )}
                        </>
                      ) : (
                        <Typography variant="subtitle1" style={{ color: grey, fontSize: 13 }}>
                          Unpaid
                        </Typography>
                      )}
                    </Box>
                    &nbsp;&middot;&nbsp;
                    <Box display="flex" alignItems="center" marginLeft="7px">
                      <LocationOnOutlined style={{ color: grey, fontSize: 13 }} fontSize="small" />
                      <Typography variant="subtitle1" style={{ color: grey, marginLeft: "5px", fontSize: 13 }}>
                        {item.job?.job_location?.length > 0 ? generateCsv(item.job?.job_location, "city") : "Job Location NA"}
                        &nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;
                        {item?.job?.company && item?.job?.company?.company_size ? item.job.company.company_size + " " + "employess" : <>Company Size NA</>}
                      </Typography>
                    </Box>
                  </Box>
                  {/* {item.job.eligibility_criteria.skills.length > 0 && (
                    <Box display="flex" alignItems="center" padding="3px 0px 5px 0px">
                      {item.job.eligibility_criteria.skills.map((skill, index) =>
                        index === 0 ? (
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle1"
                              style={{
                                color: grey,
                                marginRight: "5px",
                                fontSize: 13,
                              }}
                            >
                              {skill.skill_name}
                            </Typography>
                          </Box>
                        ) : (
                          <Box display="flex" alignItems="center">
                            &nbsp;&middot;&nbsp;
                            <Typography
                              variant="subtitle1"
                              style={{
                                color: grey,
                                marginLeft: "5px",
                                marginRight: "5px",
                                fontSize: 13,
                              }}
                            >
                              {skill.skill_name}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  )} */}
                </Grid>
                <Grid item xs={2} sm={2} style={{ paddingLeft: "6%" }}>
                  <Avatar
                    src={item?.job?.company?.company_website ? `//logo.clearbit.com/${item?.job?.company?.company_website}` : logo}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = logo;
                    }}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: 30,
                      marginLeft: 10,
                    }}
                    alt="company-picture"
                  />

                  {/* {item?.job?.up_coming_state.code !== -1 ? ( */}
                    <Box>
                      <CommonButton
                        variant="outlined"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleClickOpen(e, item);
                        }}
                        style={{ borderRadius: 30, marginBottom: 10, marginTop: 10 }}
                      >
                        Accept
                      </CommonButton>
                    </Box>
                  {/* ) : (
                    <Chip
                      label={"Deadline Passed"}
                      style={{
                        backgroundColor: theme.palette.type === "dark" ? theme.palette.background.default : "#fff",
                        fontWeight: "bold",
                        borderRadius: "30px",
                        color: "red",
                        border: "1px solid red",
                        marginLeft: "-60%",
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    />
                  )
                  } */}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.innerCardBox}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle1" style={{ fontSize: 13 }}>
                        {item.job.job_type_name}
                        {item.job.job_type === 1 ? (item.job.stipend ? " (₹ " + valueLabelFormat(item.job.stipend) + " per month)" : " (Unpaid)") : null}{" "}
                      </Typography>

                      {item.job.equity_min !== 0 && item.job.equity_max !== 0 ? (
                        <>
                          &nbsp;&middot;&nbsp;
                          <Typography variant="subtitle1" style={{ fontSize: 13 }}>
                            {item.job.equity_min}% - {item.job.equity_max}% Equity
                          </Typography>
                        </>
                      ) : (
                        <>
                          &nbsp;&middot;&nbsp;
                          <Typography variant="subtitle1" style={{ fontSize: 13 }}>
                            No Equity
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="7px">
                    {item.job.round.length > 0 && (
                      <Box display="flex" alignItems="center" padding="3px 0px 5px 0px">
                        {item.job.round.map((round, index) =>
                          index === 0 ? (
                            <Box display="flex" alignItems="center">
                              <Typography variant="subtitle1" style={{ color: grey, marginRight: "5px", fontSizeL: 13 }}>
                                {round.round_name}
                              </Typography>
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              &nbsp;&middot;&nbsp;
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color: grey,
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                  fontSize: !3,
                                }}
                              >
                                {round.round_name}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    )}
                    <Box display="flex" alignItems="center">
                      <Chip
                        icon={<RestoreOutlined />}
                        label={item.job.formatted_time}
                        style={{
                          backgroundColor: theme.palette.type === "dark" ? theme.palette.background.default : "#f4f5f7",
                          height: "24px",
                          fontWeight: "bold",
                          borderRadius: "4px",
                          fontSize: 13,
                          borderRadius: 30,
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      padding: "1% 2%",
      backgroundColor: theme.palette.background.paper,
      marginBottom: "2%",
      borderRadius: 30,
      cursor: "pointer",
      borderLeft: "4px solid #3282c4",

      WebkitBackdropFilter: "blur(30px)",
      backdropFilter: "blur(30px)",

      border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "solid 2px #F1F1F1",
    },
    innerCardBox: {
      display: "flex",
      padding: "6px",
      justifyContent: "space-between",
      border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid #d3d3d3",
      borderRadius: "5px",
    },
    table: {
      minWidth: 700,
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #26a95b",
      color: "#26a95b",
    },
    root: {
      fontWeight: "bolder",
      border: theme.palette.type === "dark" ? "2px solid #CDEDF6" : "2px solid #3282C4",
      color: theme.palette.type === "dark" ? "#CDEDF6" : "#3282C4",
    },
  })
);

export default RecommendedJobCard;

import React, { useState } from "react";
import {
  Avatar,
  Grid,
  Modal,
  makeStyles,
  createStyles,
  Dialog,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import "./connection.css";
import PublicProfile from "../PublicProfile/Index";
import Profile from "./Profile";
import ConnectionBg from "../../../assets/connection-bg.jpg";

export default function ConnectionCard({ data }) {
  const [open, handleClose] = useState(false);
  const [value, setValue] = useState("");
  const loc = useLocation().pathname;
  let show = 0;

  if (loc == "/student/dashboard") {
    data = data.slice(0, 4);
    show = 1;
  }
  const callback = (value) => {
    handleClose(value);
  };

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <Grid container>
        <Grid xs={12}>
          <Grid container spacing={3}>
            {data.map((item) => (
              <Grid
                item
                sm={3}
                xs={6}

                // onClick={() => {
                //   setValue(item.id);
                //   //handleClose(!open);
                // }}
              >
                <Link to={`/public/student/profile/${item.id}`} target="_blank">
                  <div className={classes.connectionCard}>
                    <Avatar src={item.profile_picture} alt="profile" />

                    <div className="fs-16 fw-700 mg-top-10 text-white">
                      {item.first_name} {item.last_name}
                    </div>

                    <div className="fs-16 text-white">{item.degree}</div>
                    <div className="fs-16 fw-700 mg-top-10 text-white">
                      {item.about}
                    </div>
                  </div>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {show ? (
        <Link to="/student/connection" className="connection_link">
          View All Connections <i class="fa fa-arrow-right"></i>
        </Link>
      ) : (
        ""
      )}

      <Dialog open={open} onClose={() => handleClose(!open)}>
        <PublicProfile id={value} />
        {/* <Profile data={data} /> */}
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    connectionCard: {
      width: "100%",
      textAlign: "left",
      padding: "9%",
      overflow: "hidden",
      backgroundImage: `url(${ConnectionBg})`,
      borderRadius: 16,
      cursor: "pointer",
      backgroundSize: "cover",
      height: "100%",
      "&:hover": {
        transform: "scale(1.1)",
        boxShadow: "0 30px 60px 0 rgba(0, 0, 0, 0.3)",
        opacity: "0.8",
        marginLeft: "8px",
      },
    },
  })
);

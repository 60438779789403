import React, {useEffect, useState, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../../components/public/Navbar/Navbar';
import Footer from '../../components/public/Footer/Footer';
import OpenJobMain from '../../components/public/OpenJob/OpenJobMain';
import Box from "@material-ui/core/Box";
import Grid from '@material-ui/core/Grid';
import Filter from '../../components/public/Filter/Filter';
import Hidden from "@material-ui/core/Hidden";
import Axios from "axios";
import { BackendBaseApi } from "../../constants/constants";
import { useAlert } from "react-alert";
import {useParams} from "react-router-dom";


const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex'
    
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  mainJob: {
    minHeight: '100vh'
  }
}));

export default function OpenJob() {
  const classes = useStyles();
  const [filterData, setFilterData] = useState({});
  const [jobData, setJobData] = useState([]);
  const [jobDataFetched, setJobDataFetched] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [jobCount, setJobCount] = useState(0);
  const [isFilterDataPresent, setIsFilterDataPresent] = useState(false);
  const {page: searchedJob} = useParams();

const [appliedFilters, setAppliedFilters] = useState({});
  const alert = useAlert();
  const getFilterData = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/public/filter/open-jobs").
    then((res) => {
      let respone = {};
      if(res.data && res.data.success){
        Object.keys(res.data.data).map((key) => {
          if (key !== 'ppo' && key !== 'salary'){
            respone[key] = res.data.data[key]
          }
        } )
        setFilterData(respone);
          setIsFilterDataPresent(true);
      }
      else{
        alert.error("Some error in fetching filters");
        setIsFilterDataPresent(true);
      }
    }).catch(err => {
      alert.error("Some error in fetching filters");
      setIsFilterDataPresent(true);
    })
  }

  const getJobData = () => {
    let urlQueryParam = currentPage ? `page=${currentPage}` : "";
    
    Object.keys(appliedFilters).map((key) => {
        if (key === "job_segment_id" && appliedFilters.job_segment_id)
        urlQueryParam += ("&" + key + "=" + appliedFilters.job_segment_id)
        else if (key === "job_type_id" && appliedFilters.job_type_id)
        urlQueryParam += ("&" + key + "=" + appliedFilters.job_type_id)
        else if (key === "emp_type_id" && appliedFilters.emp_type_id)
        urlQueryParam += ("&" + key + "=" + appliedFilters.emp_type_id)
        else if (key === "city_id" && appliedFilters.city_id)
        urlQueryParam += ("&" + key + "=" + appliedFilters.city_id)
    })
    setJobDataFetched(false);
    Axios.get(BackendBaseApi.NIYUKTI + "job/public/open-jobs?" + urlQueryParam).
    then((res) => {
      if(res.data && res.data.success){
          setJobData(res.data.data);
          setJobDataFetched(true);
          setJobCount(res.data.data.count);
      }
      else{
        alert.error("Some error in fetching jobs");
        setJobDataFetched(true);
      }
    }).catch(err => {
      alert.error("Some error in fetching jobs");
      setJobDataFetched(true);
      setJobData([]);
    })
    
  }

  const getJobType = (searchedJobType) => {
    if(searchedJobType.toLowerCase() === 'internship' || searchedJobType.toLowerCase() === 'internships')
        return "1"
    else if(searchedJobType.toLowerCase() === 'jobs' || searchedJobType.replaceAll('-', ' ').toLowerCase() === 'full time jobs')
        return "7"
    return "";
  }

  const setInitialFilters = () => {
    let initialFilters = {
      job_segment_id: "",
      job_type_id: "",
      emp_type_id: "",
      city_id: ""
    }
    if(!searchedJob){
      setAppliedFilters(initialFilters);
      return;
    }
    let searchedList = searchedJob.split("-in-");
    let jobSegmentName, cityName;
    if (searchedList.length === 2) {
      cityName = searchedList[1].replaceAll('-', ' ');
      const searchJobParameter = searchedList[0].split("-")
      if (searchJobParameter.length === 2){
        jobSegmentName = searchJobParameter[0].replaceAll('-', ' ');
        initialFilters.job_type_id = getJobType(searchJobParameter[1])
      }
      else if (searchJobParameter.length === 1)
        initialFilters.job_type_id = getJobType(searchedList[0])
      }
    else if (searchedList.length === 1){
        let searchedParam = searchedList[0].split('-')
        let jobType = searchedParam.pop();
        jobSegmentName = searchedParam.length > 0 ? searchedParam.join(" ") : ""
        initialFilters.job_type_id = getJobType(jobType)
      }
      if (cityName || jobSegmentName){
        let bodyData = {};
        if (cityName)
          bodyData.city_name = cityName;
        if (jobSegmentName)
          bodyData.segment_name = jobSegmentName;
        Axios.post(BackendBaseApi.PRAVESH + "api/common/filter_id_by_name", bodyData, {}).
        then((res) => {
          if(res.data && res.data.success){
              if(res.data.data.city)
                initialFilters.city_id = res.data.data.city.city_id.toString();
              if(res.data.data.segment)
                initialFilters.job_segment_id = res.data.data.segment.id.toString();
          }
            setAppliedFilters(initialFilters);
        }).catch(err => {
          setAppliedFilters(initialFilters);
        })
      }
      else
        setAppliedFilters(initialFilters);
    }

  useEffect(() => {
    setInitialFilters();
  }, []);

  useEffect(() => {
    if(appliedFilters && Object.keys(appliedFilters).length > 0){
    getJobData();
    getFilterData();
    }
  }, [appliedFilters]);

  useEffect(() => {
    if(currentPage)
    getJobData();
  }, [currentPage]);
 
  return (
    <div  className={classes.root}>    
     <Navbar/>
     <div className={classes.mainBox}>  
     <Grid container  style={{flexWrap: "nowrap"}} >
       <Hidden only={['sm','xs']}>    
          <Filter filterData={filterData} dataFetched={jobDataFetched} setFilters={setAppliedFilters} isFilterDataPresent = {isFilterDataPresent} appliedFilters={appliedFilters} />      
        </Hidden>
        <Grid item xs={12} sm={12} md={9} className={classes.mainJob}>
          <OpenJobMain filterData={filterData} 
          jobData={jobData} 
          jobDataFetched={jobDataFetched}
          setPageChange={setCurrentPage}
          jobCount={jobCount}
          setFilters={setAppliedFilters}
          isFilterDataPresent = {isFilterDataPresent}
          appliedFilters={appliedFilters}
          />
        <Box>
          <Footer/>
        </Box>
        </Grid>
     </Grid>   
     </div>
    </div>
  );
}
// import StudentNavbar from "../StudentSection/Components/Navbar/StudentNavbar";
import { Box, Container, Hidden } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Footer from "../components/public/FooterNew/Footer";
import FooterDomain from "../components/public/FooterNew/Footer/FooterDomain";
// import Login from "../bundles/common/components/Auth/Login";
import ChangePassword from "../pages/common/Auth/ChangePassword";
import Login from "../pages/common/Auth/Login";
// import StudentSidebar from "../StudentSection/Components/Sidebar/Sidebar";
import NotFound from "../pages/common/UI/NotFound";
import Profile from "../pages/public/Profile";
import CompleteProfile from "../pages/public/Profile/CompleteProfile";
import ClosedAssignment from "../pages/student/Assignment/Closed";
// import ClosedAssignment from "../StudentSection/Page/Assignment/Closed";
import OpenAssignment from "../pages/student/Assignment/Open";
// import OpenAssignment from "../StudentSection/Page/Assignment/Open";
import SubmittedAssignment from "../pages/student/Assignment/Submitted";
// import SubmittedAssignment from "../StudentSection/Page/Assignment/Submitted";
import StudentBlog from "../pages/student/Blog/Blog";
// import StudentBlog from "../StudentSection/Page/Blog/Index";
import ConnectionJob from "../pages/student/Connections/Connection";
// import ConnectionJob from "../StudentSection/Page/connection/Connection";
import InterviewJob from "../pages/student/InterviewJob/Index";
// import InterviewJob from "../StudentSection/Page/InterviewJob/Index";
import InvitedJob from "../pages/student/Invited/Index";
// import NotFound from "../bundles/common/components/UI/NotFound";
import Mentorship from "../pages/student/Mentorship/Mentorship";
// import StudentProfile from "../bundles/student/components/Home/Profile/StudentProfile";
import MyJobs from "../pages/student/MyJobs/Index";
// import InvitedJob from "../StudentSection/Page/Invited/Index";
import OpenJob from "../pages/student/OpenJob/NewIndex";
// import ChangePassword from "../bundles/student/components/Auth/ChangePassword";
import StudentProfile from "../pages/student/Profile/StudentProfile";
// import PublicProfile from "../StudentSection/Page/publicprofile/Index";
import ScheduledJob from "../pages/student/scheduled/Index";
// import ScheduledJob from "../StudentSection/Page/scheduled/Index";
import StudentSingleBlog from "../pages/student/singleblog/SingleBlog";
// import StudentSingleBlog from "../StudentSection/Page/singleblog/SingleBlog";
import StudentDashboard from "../pages/student/StudentDashboard/Index";

const StudentRoutes = ({ studentData }) => {
  const domain = useSelector(
    (state) => state.SubDomainDetails.subDomainDetails
  );
  

  return (
    <>
      <Container>
        {/* <StudentNavbar /> */}

        <Box display="flex" marginTop="64px" flexDirection={"column"}>
          {/* <StudentSidebar /> */}

          <Switch>
            <Route
              path="/student/profile"
              render={(props) => (
                <StudentProfile {...props} studentData={studentData} />
              )}
            />

            <Route path="/student/edit-profile" component={CompleteProfile} />
            <Route
              path="/student/complete-profile"
              component={CompleteProfile}
            />
            <Route
              path="/student/referral/update-profile-and-apply"
              component={Profile}
            />
            <Route
              path="/student/update-profile-and-apply"
              component={Profile}
            />
            <Route path="/student/my-jobs/:action" component={MyJobs} />
            <Route path="/student/my-jobs" component={MyJobs} />
            <Route path="/student/open" component={OpenJob} />
            <Route path="/student/interview" component={InterviewJob} />
            <Route path="/student/blog" component={StudentBlog} />
            <Route
              path="/student/singleblog/:id"
              component={StudentSingleBlog}
            />
            {/* <Route path="/student/dashboard" component={StudentDashboard} /> */}
            <Route
              path="/student/dashboard"
              render={(props) => (
                <StudentDashboard {...props} studentData={studentData} />
              )}
            />
            {/* <Route path="/public/profile/:id" component={PublicProfile} /> */}
            <Route path="/student/invited" component={InvitedJob} />
            <Route path="/student/scheduled" component={ScheduledJob} />
            <Route path="/student/open-assignment" component={OpenAssignment} />
            <Route path="/student/mentorship" component={Mentorship} />

            <Route
              path="/student/closed-assignment"
              component={ClosedAssignment}
            />
            <Route
              path="/student/submitted-assignment"
              component={SubmittedAssignment}
            />

            <Route path="/login" component={Login} />
            <Route path="/student/connection" component={ConnectionJob} />
            <Route path="/student/update-password" component={ChangePassword} />
            <Route component={NotFound} />

            {/* <Route component={NotFound}/> */}
          </Switch>
        </Box>
      </Container>
      <Hidden smDown>{!domain?.domain ? <Footer /> : <FooterDomain />}</Hidden>
    </>

    // <BrowserRouter>
    //   <Switch>
    //     <Route
    //       path="/student/profile"
    //       render={(props) => (
    //         <StudentProfile {...props} studentData={studentData} />
    //       )}
    //     />

    //     <Route path="/student/applied" component={AppliedJob} />
    //     <Route path="/student/hidden" component={HiddenJob} />
    //     <Route path="/student/saved" component={SavedJob} />
    //     <Route path="/student/open" component={OpenJob} />
    //     <Route path="/student/interview" component={InterviewJob} />
    //     <Route path="/student/blog" component={StudentBlog} />
    //     <Route path="/student/singleblog/:id" component={StudentSingleBlog} />
    //     <Route path="/student/dashboard" component={StudentDashboard} />
    //     <Route path="/student/public/profile/:id" component={PublicProfile} />
    //     <Route path="/student/closed" component={ClosedJob} />
    //     <Route path="/student/invited" component={InvitedJob} />
    //     <Route path="/student/scheduled" component={ScheduledJob} />
    //     <Route path="/student/open-assignment" component={OpenAssignment} />
    //     <Route path="/student/closed-assignment" component={ClosedAssignment} />
    //     <Route
    //       path="/student/submitted-assignment"
    //       component={SubmittedAssignment}
    //     />

    //     <Route path="/login" component={Login} />
    //     <Route path="/student/connection" component={ConnectionJob} />
    //     <Route path="/student/update-password" component={ChangePassword} />

    //     {/* <Route component={NotFound}/> */}
    //   </Switch>
    // </BrowserRouter>
  );
};

export default StudentRoutes;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import Box from "@material-ui/core/Box"
import { Divider } from '@material-ui/core';
import filter from "../../../assets/svg/icon/filter.svg";
import FilterList from './FilterList';
import Button from '@material-ui/core/Button';
import loader_gif from "../../../assets/images/filter_load.gif";


const useStyles = makeStyles((theme) => ({
 
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: '97px'
  },
  container: {
    paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(4),
    // paddingLeft: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
   
  },
  fixedHeight: {
    width:"300px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    flexDirection: 'column',
    backgroundColor: "#ffffff"
 
},
filterLoader: {
  display: "flex",
  flexDirection: 'column',
},
bottomButtons: {
  marginTop: "10px",
  display: "flex",
  justifyContent: "space-between"
}
}));

export default function Filter({filterData = {}, isFilterDataPresent, dataFetched, setFilters, appliedFilters}) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.container, classes.fixedHeight);

  return (
    <main className={classes.content}>
    <Container className={fixedHeightPaper}  style={{borderRadius:"25px",}}>
    <Box display="flex"
         justifyContent="center"
         alignItems="center"
         padding="2% 0%"
        >
       <img src={filter} height="25px"/>
       <p
         style={{
                marginBottom: "0px",
                fontSize: "18px",
                fontWeight: "bold",
                }}
                >
                Filters
        </p>
    </Box>
    <Divider/>
        {isFilterDataPresent && dataFetched ? 
        <>
        <FilterList filterData={filterData} setFilters={setFilters} appliedFilters={appliedFilters} />
        {/*   <Divider/> */}
        {/* <div className={classes.bottomButtons}>
        <Button size= "small" variant="outlined" color="primary" style={{borderRadius:"30px",marginBottom:"10px"}}> 
          Apply 
         </Button>
         <Button size= "small" variant="outlined" color="primary" style={{borderRadius:"30px", color:"#13C37B",marginBottom:"10px", borderColor:"#13C37B"}}> 
          Clear All 
         </Button>
         </div> */}
        </> :
        <div className={classes.filterLoader}>
        <img src={loader_gif} height="150px" />
        </div>
      }
    </Container>
    </main>
    
  );
}

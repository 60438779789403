import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { fade, ThemeProvider, withStyles, makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Button,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  InputLabel,
  Typography,
  OutlinedInput,
  Paper,
  Grid,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { display } from "@material-ui/system";
import Logo from "../../../../../assets/images/sidepanel/logo.png";
import Facebook from "../../../../../assets/facebook.png";
import Google from "../../../../../assets/google-icon.png";
import { emailRegex, FACEBOOK_APP_ID, NEXT_APP_ENDPOINT, passwordRegex } from "../../../../../constants/constants";
import { Link, useHistory, useLocation } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "auto",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);
export default function ResetPasswordForm(props) {
  const {
    loading,

    handleSubmit,
    resPassword,
    setResPassword,
    confirmPassword,
    setConfirmPassword,
    resetSteps,
    setResetSteps,
  } = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [passwordError, setPasswordError] = useState("");
  const [confPasswordError, setConfPasswordError] = useState("");

  const [buttonClicked, setButtonClicked] = useState(false);
  const [validate, setValidate] = useState(false);

  const validateData = () => {
    var pass_error = "";
    var conf_pass_error = "";

    setValidate(false);
    if (resPassword === "") {
      pass_error = "Please Enter Password";
      setValidate(true);
    }

    if (!passwordRegex.test(resPassword)) {
      pass_error = "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit";

      setValidate(true);
    }

    if (confirmPassword === "") {
      conf_pass_error = "Please Enter Password";
      setValidate(true);
    }

    if (resPassword !== confirmPassword) {
      conf_pass_error = "Password doesn't match. Please enter again";

      setValidate(true);
    }

    setPasswordError(pass_error);
    setConfPasswordError(conf_pass_error);
    setButtonClicked(true);
  };

  useEffect(() => {
    if (!validate && buttonClicked && resPassword !== "" && confirmPassword !== "") {
      handleSubmit();
    }
  }, [buttonClicked]);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.grid}>
            {!domain?.domain && <img src={Logo} height="70px" width="80px" />}
            <br />
            {resetSteps === 0 && (
              <>
                <Typography variant="body1" style={{ fontFamily: " Nunito" }}>
                  Hi <b> {location.pathname.split("/")[4] + " " + location.pathname.split("/")[5]}</b>, set your new password{" "}
                </Typography>
                <br />
                <FormControl color="#007bff" className={clsx(classes.margin, classes.textField)} variant="outlined" error={passwordError !== "" ? true : false}>
                  <InputLabel htmlFor="outlined-adornment-password" style={{ borderBottomColor: "#007bff" }}>
                    Password *
                  </InputLabel>

                  <OutlinedInput
                    style={{
                      borderRadius: "30px",
                      // padding: "22px",
                      borderColor: "#007bff",
                    }}
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={resPassword}
                    // labelWidth={80}
                    onChange={(e) => {
                      setResPassword(e.target.value);
                      setPasswordError("");
                      setButtonClicked(false);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />

                  <FormHelperText
                    style={{
                      fontFamily: " Nunito",

                      fontSize: "12px",
                    }}
                    id="standard-weight-helper-text"
                    className={passwordError !== "" ? classes.errorPassword : classes.noErrorPassword}
                  >
                    {passwordError !== "" ? passwordError : "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit"}
                  </FormHelperText>
                </FormControl>
                <br />
                <FormControl color="#007bff" className={clsx(classes.margin, classes.textField)} variant="outlined" error={confPasswordError !== "" ? true : false}>
                  <InputLabel htmlFor="outlined-adornment-password" style={{ borderBottomColor: "#007bff" }}>
                    Confirm Password *
                  </InputLabel>

                  <OutlinedInput
                    style={{
                      borderRadius: "30px",
                      // padding: "22px",
                      borderColor: "#007bff",
                    }}
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={confirmPassword}
                    // labelWidth={80}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfPasswordError("");
                      setButtonClicked(false);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />

                  <FormHelperText
                    style={{
                      fontFamily: " Nunito",

                      fontSize: "12px",
                    }}
                    id="standard-weight-helper-text"
                    className={confPasswordError !== "" ? classes.errorPassword : classes.noErrorPassword}
                  >
                    {confPasswordError !== "" && confPasswordError}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            {resetSteps === 1 && (
              <>
                <Typography variant="body1" style={{ fontFamily: " Nunito" }}>
                  <b> Bingo! You've successfully changed your password.</b>{" "}
                </Typography>
                <br />
                <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
                  You can login with your new credentials{" "}
                </Typography>
              </>
            )}
            {resetSteps === 2 && (
              <>
                <Typography variant="body1" style={{ fontFamily: " Nunito" }}>
                  <b>Ohh!!!!! </b>{" "}
                </Typography>
                <br />
                <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
                  This link is expired. Please generate new reset password link{" "}
                </Typography>
              </>
            )}
          </Grid>

          {resetSteps === 2 && (
            <div className={classes.create}>
              <Typography variant="body2" style={{ fontFamily: " Nunito", textAlign: "center" }}>
                {/* Didn't receive an email?{" "} */}
                <span
                  variant="body2"
                  className={classes.text}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/forgot-password");
                  }}
                >
                  {" "}
                  Click here
                </span>{" "}
                to send the link again.
              </Typography>
            </div>
          )}
          {resetSteps === 1 && (
            <>
              <br />
              <Grid item md={12} xs={12}>
                <div className={classes.btndiv}>
                  <Button
                    className={classes.btn}
                    variant="contained"
                    //   disabled={!resetEmail}
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    <Typography variant="body2" style={{ color: "#fff", fontFamily: " Nunito" }}>
                      {" "}
                      Sign In
                    </Typography>
                  </Button>
                </div>
              </Grid>
            </>
          )}
          {resetSteps === 0 && (
            <Grid item md={12} xs={12}>
              <div className={classes.btndiv}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  //   disabled={!resetEmail}
                  onClick={() => {
                    validateData();
                  }}
                >
                  <Typography variant="body2" style={{ color: "#fff", fontFamily: " Nunito" }}>
                    {" "}
                    {/* Sign In{" "} */}
                    {loading ? <CircularProgress style={{ color: "#fff" }} size={18} /> : "Send"}
                  </Typography>
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
}

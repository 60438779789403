import { Button, createMuiTheme, Tooltip, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { DriveEtaSharp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
//import { checkForUrl } from "../../../utility/checkForUrl";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.type === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const columns = [
  {
    field: "assignment_title",
    headerName: "Job Title",
    type: "text",
    width: 182,
    renderHeader: (params) => (
      <strong
        style={{
          color: "white",
          textAlign: "left",
        }}
      >
        Assignment Title
      </strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          /* textAlign: "center", */
          display: "flex",
          margin: "auto",
        }}
      >
        <Tooltip title={params?.row?.assignment_title} placement="right">
          <Typography style={{ fontSize: "14px", cursor: "pointer" }}>
            {params?.row?.assignment_title}
          </Typography>
        </Tooltip>
      </div>
    ),
  },
  // {
  //   field: "description",
  //   headerName: "description",
  //   type: "text",
  //   width: 120,
  //   renderHeader: (params) => (
  //     <strong
  //       style={{
  //         color: "white",
  //       }}
  //     >
  //       Description
  //     </strong>
  //   ),
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         /*  textAlign: "center", */
  //         display: "flex",
  //         margin: "auto",
  //       }}
  //     >
  //       {params?.row?.text ? params?.row?.text : "NA"}
  //     </span>
  //   ),
  // },
  {
    field: "submission_date",
    headerName: "submission_date",
    type: "text",
    width: 165,
    renderHeader: (params) => (
      <strong
        style={{
          color: "white",
        }}
      >
        Submission Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          /* textAlign: "center", */
          display: "flex",
          margin: "auto",
        }}
      >
        {params?.row?.submission_date}
      </span>
    ),
  },
  {
    field: "file",
    type: "button",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "white", marginLeft: "20px" }}>File</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        <Button
          color="primary"
          onClick={() => {
            if (params?.row?.file && params?.row?.file !== "null")
              window.open(params?.row?.file);
          }}
        >
          {params?.row?.file && params?.row?.file !== "null"
            ? "Download"
            : "NA"}
        </Button>
      </span>
    ),
  },
  {
    field: "url",
    type: "button",
    width: 100,

    renderHeader: (params) => (
      <strong style={{ color: "white", marginLeft: "20px" }}>Link</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        <Button
          color="primary"
          onClick={() => {
            if (params?.row?.url && params?.row?.url !== "null")
              window.open(params?.row?.url);
          }}
        >
          {params?.row?.url && params?.row?.url !== "null" ? "View" : "NA"}
        </Button>
      </span>
    ),
  },

  /*  {
    field: "url",
    type: "button",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "white", marginLeft: "25px" }}>Link</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        {params.row.url ? (
           <a href={checkForUrl(params.row.url)}> 
          <Button color="primary">View</Button>
        ) : (
         </a>
          "NA"
        )}
      </span>
    ),
  }, */
];

const defaultTheme = createMuiTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: 0,
      color:
        theme.palette.type === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.primary.main,
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-cell": {
        color: "#000",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      ...customCheckbox(theme),
    },
    "& .super-app-theme--header": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  { defaultTheme }
);

export default function AssignmentDownloadTable({
  data,
  setSelectedAssignments,
}) {
  const classes = useStyles();

  return (
    <div style={{ height: 300, width: "98%" }} className={classes.root}>
      <DataGrid
        style={{
          borderRadius: "20px",
          backgroundColor: "#fff",
          border: "none",
        }}
        rows={data}
        columns={columns}
        pageSize={5}
        /* checkboxSelection */
        hideFooterPagination
        disableSelectionOnClick
        onSelectionChange={(newSelection) => {
          setSelectedAssignments(newSelection.rows);
        }}
      />
    </div>
  );
}

import React, { useState } from "react";
import { useAlert } from "react-alert";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Button, Tooltip } from "@material-ui/core";
import { BackendBaseApi } from "../../../constants/constants";
import Axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Submit from "./Submit";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  btn: {
    borderRadius: "30px",
    backgroundColor: "#007bff",
  },
  box: {
    backgroundColor: theme.palette.type === "dark" ? "#4a4a4a" : "#F1F8FF",
    padding: "10px",
    borderRadius: "15px",
  },
}));

function Row(props) {
  const alert = useAlert();
  const { row, filterObject } = props;
  // const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row" style={{ fontSize: 14 }}>
          <CopyToClipboard text={row.link} onCopy={() => alert.info("Link Copied!")}>
            <Typography
              noWrap
              style={{ width: "300px", cursor: "pointer", fontSize: 14 }}
              // onClick={() => {
              //   if(row.link) {
              //       navigator.clipboard.writeText(row.link)
              //       alert.info('Link Copied!')
              //   }
              // }}
            >
              <Tooltip title={row.link} arrow placement="bottom-start">
                <span>{row.link}</span>
              </Tooltip>
            </Typography>
          </CopyToClipboard>
        </TableCell>
        <TableCell style={{ fontSize: 14 }}>{row.passcode}</TableCell>
        <TableCell style={{ fontSize: 14 }}>{row.actions.join(", ")}</TableCell>
        <TableCell style={{ fontSize: 14 }}>{row.details.join(", ")}</TableCell>
        <TableCell style={{ fontSize: 14 }}>{row.emails.join(", ")}</TableCell>
        <TableCell>
          <Submit
            jobID={row.jobID}
            generatedLink={row.link}
            passCode={row.passcode}
            filterObject={filterObject}
            usermail={row.emails}
            detailState={row.details}
            actionState={row.actionsObj}
            handleShareOpen={handleClose}
            comingFromTable={true}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function MailHistory({ jobID, filterObject }) {
  const alert = useAlert();
  const classes = useRowStyles();
  const [linkHistory, setLinkHistory] = React.useState([]);

  const checkJobId = () => {
    if (jobID) {
      return `?job_id=${jobID}`;
    }
    return "";
  };

  React.useEffect(() => {
    if (!jobID) return;
    Axios.get(BackendBaseApi.NIYUKTI + `job/track/sharer/${checkJobId()}`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          const json = res.data.data;
          let tempArr = [];
          for (let i = 0; i < json.length; i++) {
            let actionArr = [];
            let detailsArray = [];
            if (json[i].action_performed.authority?.track) actionArr.push("Track");
            if (json[i].action_performed.authority?.download) actionArr.push("Download");
            json[i].action_performed.action.map((item) => {
              if (item === "mail") detailsArray.push("Email");
              if (item === "phone") detailsArray.push("Phone");
              if (item === "resume") detailsArray.push("Resume");
            });
            let obj = {
              emails: json[i].action_performed?.email || [],
              link: json[i].job_link,
              passcode: json[i].Passcode,
              actions: actionArr,
              details: detailsArray,
              actionsObj: json[i].action_performed.authority,
              jobID: jobID,
            };
            tempArr.push(obj);
          }
          setLinkHistory(tempArr);
        } else alert.error("Link History was not fetched. Some error occurred");
      })
      .catch((err) => {
        alert.error("Some error occured!");
      });
  }, [jobID]);

  return (
    <TableContainer component={Paper}>
      {
        // linkHistory.length > 0
        // &&
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.box}>
              <TableCell>Job Link</TableCell>
              <TableCell>Passcode</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Details</TableCell>

              <TableCell>Emails</TableCell>
              <TableCell>Share</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {linkHistory?.length > 0 ? (
              linkHistory.map((row, key) => <Row key={key} row={row} filterObject={filterObject} />)
            ) : (
              <>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> No Data Available</TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
              </>
            )}
          </TableBody>
        </Table>
      }
    </TableContainer>
  );
}

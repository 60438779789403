import React from "react";
import { Grid, Paper, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  image: {
    border: "1px solid #e2e6ea",
    borderRadius: "12px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "80px",
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  subheading: {
    fontSize: "15px",
    color: "#6c757d",
  },
  subheading2: {
    fontSize: "13px",
    color: "#6c757d",
  },
  info: {
    fontSize: "12px",
    color: "#6c757d",
  },
  icon: {
    color: "#6c757d",
    fontSize: "16px",
  },
}));

export default function BasicDetails(props) {
  const classes = useStyles();
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <div className={classes.icon}>{props.icon}</div>
          </Grid>
          <Grid item xs>
            <Typography className={classes.info}>{props.heading}</Typography>
            <Typography className={classes.subheading2}>
              <b>{props.details}</b>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

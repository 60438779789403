import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { Grid, Card, CardContent, Divider, Button, Chip, Tooltip } from "@material-ui/core";
import "./viewjob.css";
import httpRequest from "../../../utils/httpRequest";
import CloseIcon from "@material-ui/icons/Close";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import RoomIcon from "@material-ui/icons/Room";
import { AccountBalanceWallet, CreditCardOutlined } from "@material-ui/icons";
import kFormatter, { LFormatter } from "../../../utils/ZeroToK";
import ReactHtmlParser from "react-html-parser";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { createStyles, makeStyles, Theme, useTheme, withStyles } from "@material-ui/core/styles";

import { BackendBaseApi } from "../../../constants/constants";
import ConfirmationDialog from "../../../GlobalComponents/ConfirmationDialog/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { updateCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import { useAlert } from "react-alert";
import BootstrapTooltip from "../../../common/Tooltip/Tooltip";
import CommonButton from "../../common/CommonButton/CommonButton";
import Header from "../../public/JobDetails/Header";
import Description from "../../public/JobDetails/Description";
import Container from "@material-ui/core/Container";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#3e3d3d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  root1: {
    fontWeight: "bolder",
    border: "2px solid #26a95b",
    color: "#26a95b",
  },
  root: {
    fontWeight: "bolder",
    border: "2px solid #3282C4",
    color: "#3282C4",
  },
});
const JobViewStyles = {
  Page: {
    height: "auto",
    background: "#E5E5E5",
  },
  Logo: {
    height: "100px",
    width: "100px",
  },
};
const ViewJob = ({ data, handleClosey, open, apply, reject, setLoading, setJobData, confirmationDialog, setConfirmationDialog }) => {
  const [isReject, setIsReject] = useState(false);
  // const [jobData, setJobData] = useState();
  const classes = useStyles();

  // useEffect(() => {
  //   setJobData(data);
  // }, [jobData]);
  const theme = useTheme();
  const generateCsv = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += (item.specialization_name ? item.specialization_name : item.name) + " | ")
        : (str += item.specialization_name ? item.specialization_name : item.name)
    );
    return str;
  };
  const [isUserApplied, setIsUserApplied] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [dataNew, setDataNew] = useState([]);
  useEffect(() => {
    var object = {};
    var detail = data?.job;
    if (detail.hiring_type === "OFF CAMPUS") {
      detail.eligibility_criteria.degrees.map((item) => {
        var arr = [];

        detail.eligibility_criteria.courses.map((obj) => {
          if (obj.degree_id === item.id) arr.push(obj);
        });

        if (item.all_degree_selected && item.all_spec_selected) object["Open to All"] = {};
        else {
          if (item.all_spec_selected) object[item.name] = [{ name: "All Courses" }];
          else object[item.name] = arr;
        }
      });
    } else if (detail.hiring_type === "ON CAMPUS" && (localStorage.getItem("user_type") === "College" || localStorage.getItem("user_type") === "Student")) {
      if (detail.college.length > 0) {
        var list = detail.college[0].preferences;
        var checkSpecSelect = false;
        var checkSelectAllArray = detail.college.map((item) => item.preferences.every((e) => e.degree_selected || e.select_all));

        var checkSelectAll = checkSelectAllArray.every((e) => e === true);
        if (checkSelectAll) object["Open to All"] = {};
        else {
          detail.college.map((item) =>
            item.preferences.map((item2) => {
              object[item2.degree_name] = checkValue(item2);
            })
          );
        }
      }
    } else if (detail.hiring_type === "ON CAMPUS" && localStorage.getItem("user_type") === "Company") {
      detail.college.map((item) => {
        object[item.name + ", " + item.location] = checkIfAllSelected(item);
      });
    }
    detail.eligibility_criteria["qualifications"] = object;
    data.job.eligibility_criteria["qualifications"] = object;
    setDataNew(detail);
  }, [data]);

  const checkValue = (item) => {
    var boole = item.specialization.every((e) => e.selected || e.course_selected);
    var obj;
    if (boole) {
      obj = [{ name: "All Courses" }];
    } else {
      obj = item.specialization;
    }
    return obj;
  };

  const checkIfAllSelected = (item) => {
    var boole = item.preferences.every((e) => e.select_all || e.degree_selected);
    item.preferences.forEach((item2) => {
      item2["name"] = item2.degree_name;
    });
    var obj;
    if (boole) {
      obj = [{ name: "Open to All Degrees" }];
    } else {
      obj = item.preferences;
    }
    return obj;
  };
  return (
    <>
      {data !== null ? (
        <Grid container justify="center">
          <Grid xs={open ? 12 : 12} style={{ marginTop: "0" }}>
            <Card style={{ height: "100vh", overflowY: "scroll", scrollbarWidth: "none" }}>
              <CardContent style={{ padding: 20 }}>
                <span className="float-right pointer" onClick={() => handleClosey(false)}>
                  <CloseIcon />
                </span>
                <Container className={classes.jobGrid} maxWidth="md">
                  {/* <Paper className={classes.job}> */}
                  <Header data={dataNew} fromCollegeView={true} />
                  <Description data={dataNew} isUserApplied={isUserApplied} setIsUserApplied={setIsUserApplied} setApplyClicked={setApplyClicked} />
                  {/* </Paper> */}
                </Container>
                {/* <Grid container>
                  <Grid xs={12} style={{ marginTop: "0" }}>
                    <Grid container>
                      <Grid item md={6} style={{ marginTop: "0" }}>
                        <p style={{ fontSize: "20px" }}>
                          <b>{data.job.job_title}</b>
                        </p>
                      </Grid>
                      <Grid item md={2} style={{ marginTop: "0" }}>
                        {data.job.up_coming_state.code === 1 && data.job.job_status !== "CLOSE" && (
                          <Chip label={"UPCOMING JOB"} variant="outlined" className={classes.root1} color="primary" />
                        )}
                      </Grid>
                      <Grid item md={2} style={{ marginTop: "0" }}>
                        {data.job.is_posted_by_college === true && <Chip label={"POSTED BY YOU"} variant="outlined" className={classes.root} color="primary" />}
                      </Grid>
                      <Grid item md={2} style={{ marginTop: "0" }}>
                        {data.job.is_posted_by_college === true && <Chip className={classes.root} label={data.job.hiring_type + " HIRING"} color="primary" variant="outlined" />}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid xs={12} sm={12} style={{ marginTop: "0" }}>
                        <p
                          style={{
                            color: "rgb(66, 66, 66)",
                            fontSize: "14px",
                          }}
                        >
                          {data.job.company ? data.job.company.company_name : data.job.company_details}
                        </p>
                      </Grid>
                    </Grid>
                    {data.job.eligibility_criteria.skills.length > 0 && (
                      <Grid container>
                        <Grid xs={12} sm={10}>
                          <div className="flex flex-wrap" style={{ marginLeft: "-6px" }}>
                            {data.job.eligibility_criteria.skills.map((item) => (
                              <span className="viewjob_tag" style={{ fontSize: "14px" }}>
                                {item.skill_name}
                              </span>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container style={{ marginTop: "20px" }}>
                      <Grid xs={4} style={{ marginLeft: "80%" }}>
                        {reject && (
                          <>
                            {data.job.up_coming_state.code !== -1 ? (
                              <>
                                <RejectButton isReject={isReject} id={data.id} companyId={data.job.company && data.job.company.company_id} jobId={data.job.job_id} />{" "}
                                <AcceptButton
                                  setIsReject={setIsReject}
                                  companyId={data.job.company && data.job.company.company_id}
                                  id={data.id}
                                  reject={reject}
                                  jobId={data.job.job_id}
                                  setLoading={setLoading}
                                  setJobData={setJobData}
                                  confirmationDialog={confirmationDialog}
                                  setConfirmationDialog={setConfirmationDialog}
                                />
                              </>
                            ) : (
                              <Chip
                                label={"Deadline Passed"}
                                style={{
                                  backgroundColor: theme.palette.type === "dark" ? theme.palette.background.default : "#fff",
                                  fontWeight: "bold",
                                  borderRadius: "30px",
                                  color: "red",
                                  border: "1px solid red",
                                }}
                              />
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "20px" }}>
                      {data.job.ctc_min !== 0 && data.job.ctc_max !== 0 && (
                        <Grid xs={5} sm={4} className="mg-top-10">
                          <b>
                            <h6 className="viewjob_heading flex align-item-center">
                              <CreditCardOutlined
                                style={{
                                  fontSize: "20px",
                                  marginRight: "4px",
                                }}
                              />
                              Compensation
                            </h6>
                          </b>
                          <div className="flex align-item-center viewjob_data">
                            ₹ {data.job.ctc_min > 99999 ? LFormatter(data.job.ctc_min) : kFormatter(data.job.ctc_min)} -{" "}
                            {data.job.ctc_max > 99999 ? LFormatter(data.job.ctc_max) : kFormatter(data.job.ctc_max)} {data.job.salary_payment_type}
                          </div>
                        </Grid>
                      )}
                      {data.job.equity_min !== 0 && data.job.equity_max !== 0 && (
                        <Grid xs={4} sm={4} className="mg-top-10">
                          <b>
                            <h6 className="viewjob_heading flex align-item-center">
                              <AccountBalanceWallet
                                style={{
                                  fontSize: "20px",
                                  marginRight: "4px",
                                }}
                              />
                              Equity range
                            </h6>
                          </b>
                          <div className="viewjob_data">
                            {" "}
                            {data.job.equity_min}% - {data.job.equity_max}%
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Divider />

                    <Grid container style={{ marginTop: "20px" }}>
                      <Grid md={4} sm={5}>
                        <b>
                          <h6 className="viewjob_heading flex align-item-center">
                            {" "}
                            <RoomIcon /> Office location
                          </h6>
                        </b>
                        <div className="viewjob_data">
                          {data.job.job_location.length > 0 ? (
                            data.job.job_location.map((item, i) => <>{i != data.job.job_location.length - 1 ? <>{item.city}, </> : <>{item.city}</>}</>)
                          ) : (
                            <>PAN India</>
                          )}
                        </div>
                      </Grid>
                      <Grid md={4} sm={5} className="mg-top-10">
                        <b>
                          <h6 className="viewjob_heading flex align-item-center">
                            <WorkOutlineIcon />
                            Job type
                          </h6>
                        </b>
                        <div className="viewjob_data">{data.job.job_type_name}</div>
                      </Grid>
                      {data.job?.apply_start_date && (
                        <Grid md={4} sm={5}>
                          <b>
                            <h6 className="viewjob_heading flex align-item-center">
                              {" "}
                              <AccessTimeIcon /> Apply Date
                            </h6>
                          </b>
                          {data.job.hiring_type === "ON CAMPUS" ? (
                            <div className="viewjob_data">
                              {data.job?.college[0]?.apply_start_date
                                ? data.job?.college[0]?.apply_start_date.slice(8, 10) +
                                  "/" +
                                  data.job?.college[0]?.apply_start_date.slice(5, 7) +
                                  "/" +
                                  data?.job?.college[0]?.apply_start_date.slice(0, 4)
                                : data.job?.apply_start_date.slice(8, 10) + "/" + data.job?.apply_start_date.slice(5, 7) + "/" + data?.job?.apply_start_date.slice(0, 4)}
                              {data?.job?.college[0]?.apply_end_date ? (
                                <>
                                  {" "}
                                  to{" "}
                                  {data?.job.college[0]?.apply_end_date.slice(8, 10) +
                                    "/" +
                                    data?.job.college[0]?.apply_end_date.slice(5, 7) +
                                    "/" +
                                    data?.job.college[0]?.apply_end_date.slice(0, 4)}
                                </>
                              ) : (
                                <> to {data?.job.apply_end_date.slice(8, 10) + "/" + data?.job.apply_end_date.slice(5, 7) + "/" + data?.job.apply_end_date.slice(0, 4)}</>
                              )}
                            </div>
                          ) : (
                            <>
                              <div className="viewjob_data">
                                {data.job?.apply_start_date.slice(8, 10) + "/" + data.job?.apply_start_date.slice(5, 7) + "/" + data?.job?.apply_start_date.slice(0, 4)}
                                {data?.job?.apply_end_date ? (
                                  <> to {data?.job.apply_end_date.slice(8, 10) + "/" + data?.job.apply_end_date.slice(5, 7) + "/" + data?.job.apply_end_date.slice(0, 4)}</>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      <Grid md={4} xs={12} sm={5} className="mg-top-10">
                        <b>
                          <div className="flex align-item-center">
                            <HourglassEmptyIcon />
                            <h6 className="mg-0 viewjob_heading">Employment type</h6>
                          </div>
                        </b>
                        <div className="mg-left-10 viewjob_data">{data.job.employment_type_name}</div>
                      </Grid>
                      <Grid md={4} xs={12} sm={5} className="mg-top-10">
                        {data.job.ppo === true && (
                          <>
                            <b>
                              <h6 className="viewjob_heading">PPO Provision</h6>
                            </b>
                            <div className="viewjob_data">{data.job.ppo === true ? "Yes" : "No"}</div>
                          </>
                        )}
                      </Grid>
                      <Grid md={4} xs={12} sm={5} className="mg-top-10">
                        {data.job.service_bond != 0 && (
                          <>
                            <b>
                              <h6 className="viewjob_heading">Service Bond</h6>
                            </b>
                            <div className="viewjob_data">{data.job.service_bond} Months</div>
                          </>
                        )}
                      </Grid>
                      {data.job.job_type_name === "Internship" && (
                        <Grid md={4} xs={12} sm={5} className="mg-top-10">
                          <b>
                            <h6 className="viewjob_heading flex align-item-center">
                              {" "}
                              <AccessTimeIcon /> Internship Duration
                            </h6>
                          </b>
                          <div className="mg-left-10 viewjob_data" style={{ fontSize: "14px", marginLeft: "0px" }}>
                            {data.job.job_duration_start ? (
                              <>
                                {" From "}
                                {data.job.job_duration_start.slice(8, 10)}
                                {"/"}
                                {data.job.job_duration_start.slice(5, 7)}
                                {"/"}
                                {data.job.job_duration_start.slice(0, 4)}
                              </>
                            ) : (
                              data.job.job_duration_end && (
                                <>
                                  {" to "}
                                  {data.job.job_duration_end.slice(8, 10)}
                                  {"/"}
                                  {data.job.job_duration_end.slice(5, 7)}
                                  {"/"}
                                  {data.job.job_duration_end.slice(0, 4)}
                                </>
                              )
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>

                    <br />
                    <Grid container>
                      <Grid x={12} className="mg-top-10">
                        <b>
                          <h6 className="viewjob_heading">Description</h6>
                        </b>
                        <div className="viewjob_data">{ReactHtmlParser(data.job.job_description)}</div>
                      </Grid>
                    </Grid>

                    {data.job.hiring_type == "ON CAMPUS" && (
                      <div className="row my-5 text-left pl-2">
                        <h6>
                          <b>On Campus Hiring - College Information</b>
                        </h6>
                        <br />

                        <div className="col-md-12">
                          <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell align="left">Degrees and Specializations</StyledTableCell>
                                
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data.job.college.map(
                                  (colgObj, ind) =>
                                    colgObj.id === parseInt(localStorage.getItem("college_id")) && (
                                      <StyledTableRow key={ind}>
                                        
                                        <StyledTableCell align="left">
                                          {colgObj.preferences.map((prefObj, ind) => (
                                            <Fragment key={ind}>
                                              {colgObj.preferences.indexOf(prefObj) !== colgObj.preferences.length - 1 ? (
                                                <>
                                                  <BootstrapTooltip title={generateCsv(prefObj.specialization)} placement="top-start">
                                                    <Button
                                                      style={{
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {prefObj.degree_name}
                                                    </Button>
                                                  </BootstrapTooltip>
                                                  {" | "}
                                                </>
                                              ) : (
                                                <>
                                                  <BootstrapTooltip title={generateCsv(prefObj.specialization)} placement="top-start">
                                                    <Button
                                                      style={{
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {prefObj.degree_name}
                                                    </Button>
                                                  </BootstrapTooltip>{" "}
                                                </>
                                              )}
                                            </Fragment>
                                          ))}
                                        </StyledTableCell>
                                      
                                      </StyledTableRow>
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    )}

                    <Grid container>
                      <Grid xs={12} className="mg-top-10">
                        <b>
                          <h6 className="viewjob_heading">Preferences</h6>
                        </b>
                        <div>
                          <ul className="fs-14 gray-2 m-0 py-2 px-4">
                            <li>
                              <b> Work Experience: </b>
                              {data.job.eligibility_criteria.experience}
                            </li>
                            <li>
                              <b> Eligible Graduation Years: </b>
                              {data.job.eligibility_criteria.graduation_years.map((year) => (
                                <span className="mr-2">{year}</span>
                              ))}
                            </li>

                          
                            {data.job.eligibility_criteria.percentage && (
                              <li>
                                <b> Percentage: </b>
                                <span>{data.job.eligibility_criteria.percentage}</span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </Grid>
                    </Grid>
                    {data.job.company && data.job.company ? (
                      <>
                        <Grid container>
                          <Grid xs={12} className="mg-top-10">
                            <b>
                              <h6 className="viewjob_heading">About {data.job.company ? data.job.company.company_name : data.job.company_details}</h6>
                            </b>

                            <div className="flex flex-wrap" style={{ marginLeft: "-6px" }}>
                              <span className="viewjob_tag" style={{ fontWeight: "0" }}>
                                {data?.job.company?.industry_name}
                              </span>
                            </div>
                            <div className="mg-top-10 viewjob_data ">{data.job.company && data.job.company.company_description}</div>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid xs={12} className="mg-top-10">
                            <div>
                              <span className="fs-16 fw-700">Company size :</span> <span>{data?.job.company?.company_size}</span>
                            </div>
                            <div>
                              <span className="fs-16 fw-700">Check out us @</span>
                              <span className="viewjob_data">{data?.job.company?.company_website}</span>
                            </div>
                          </Grid>
                        </Grid>
                        <br />
                      </>
                    ) : null}
                    <br />
                    <br />
                    <Grid container>
                      <Grid className="">
                        {data.job.documents && data.job.documents !== undefined && (
                          <div>
                            <b>
                              <h6 className="viewjob_heading">Documents required</h6>
                            </b>

                            <ul className="fs-16 gray-2 m-0 py-2 px-4">
                              {data.job.documents &&
                                Array.isArray(data.job.documents) &&
                                data.job.documents.map((doc) => {
                                  return (
                                    <li>
                                      <span>{doc}</span>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    <br />
                    <br />
                    <br />
                    <Grid container>
                      <Grid className="">
                        {data.job.round && data.job.round.length > 0 && (
                          <div>
                            <b>
                              <h6 className="viewjob_heading">Selection Procedure</h6>
                            </b>

                            <ul className="fs-16 gray-2 m-0 py-2 px-4">
                              {data.job.round &&
                                data.job.round.map((doc, index) => {
                                  return (
                                    <li>
                                      <span>
                                        Round&nbsp; {index + 1}:&nbsp; {doc.round_name}
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid xs={12} className="flex" justify="flex-end">
                        {apply && (
                          <>
                            {" "}
                            <HiddenBtn jobId={data.job.job_id} reject={false} /> <ApplyButton jobId={data.job.job_id} reject={false} />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default ViewJob;

const ApplyButton = ({ jobId, reject }) => {
  const [done, setDone] = useState(false);

  const data = JSON.parse(localStorage.getItem("user_details"));

  const handelActive = (jobId) => {
    fetch(BackendBaseApi.NIYUKTI + "job/student/apply/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.job.id}&round=1&status=1&feedback=1`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.job.data.job.message);

        setDone(true);
      });
  };
  return (
    <CommonButton variant="contained" style={{ float: "right" }} disabled={done} onClick={() => handelActive(jobId)}>
      {reject ? (done ? "Accepted" : "Accept") : done ? "Applied" : "Apply"}
    </CommonButton>
  );
};

const AcceptButton = ({ id, reject, setIsReject, companyId, jobId, setLoading, setJobData, confirmationDialog, setConfirmationDialog }) => {
  const data = JSON.parse(localStorage.getItem("user_details"));

  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const dispatch = useDispatch();
  const alert = useAlert();

  const [done, setDone] = useState(false);

  const handelActive = async (jobId) => {
    // fetch(
    //   BackendBaseApi.NIYUKTI +
    //     "job/college/invite/?college_id=MQ==&job_status=TkVX",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Token ${localStorage.getItem("gw_token")}`,

    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },

    //     body: `id=${id}&job_id=${jobId}&college_id=1&company_id=${companyId}&is_approved=${true}&is_rejected=${false}&user_id=31`,
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     alert(data.data.message);

    //     setDone(true);
    //     setIsReject(true);
    //   });
    setLoading(true);

    const res = await Axios({
      method: "POST",
      headers: {
        Authorization: localStorage.getItem("gw_token"),
      },
      url: BackendBaseApi.NIYUKTI + "job/college/invite/",
      data: {
        college_id: data.college_id,
        company_id: companyId,
        id: id,
        is_approved: true,
        is_rejected: false,
        user_type_id: localStorage.getItem("user_type_id"),
        user_id: localStorage.getItem("user_id"),
        service_type: "JOB_ACCEPTANCE",
        amount: creditInfo?.user_service?.find((item) => item.service_type === "JOB_ACCEPTANCE")?.credits,
        transaction_type: "DEBIT",
      },
    }).catch((err) => {
      alert.error("Error occured");
      setLoading(false);
      throw err;
    });

    if (res.data.success) {
      alert.success(res.data.data.message);
      dispatch(updateCreditInfo(res.data.data.transaction_data));
    }

    const res2 = await Axios({
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("gw_token"),
      },
      url: `${BackendBaseApi.NIYUKTI}job/college/invite/?college_id=${btoa(JSON.parse(localStorage.getItem("user_details")).college_id)}&job_status=TkVX`,
    }).catch((err) => {
      setLoading(false);
      throw err;
    });
    setJobData(res2.data.data.results);
    setLoading(false);
    // GetData(
    //   BackendBaseApi.NIYUKTI,
    //   `job/college/invite/?college_id=${btoa(data.college_id)}&job_status=TkVX`,
    //   {
    //     headers: { Authorization: `Token ${localStorage.getItem("gw_token")}` },
    //   }
    // );
    setConfirmationDialog(false);

    setDone(true);
    setIsReject(true);
  };
  return (
    <>
      <CommonButton
        variant="contained"
        disabled={done}
        onClick={(event) => {
          event.stopPropagation();
          // handelActive(jobId);
          setConfirmationDialog(true);
        }}
      >
        {reject ? (done ? "Accepted" : "Accept") : done ? "Applied" : "Apply"}
      </CommonButton>
      <ConfirmationDialog
        open={confirmationDialog}
        setOpen={setConfirmationDialog}
        poster={handelActive}
        posterArg={jobId}
        creditsRequired={creditInfo?.user_service?.find((item) => item.service_type === "JOB_ACCEPTANCE")?.credits}
        serviceType={creditInfo?.user_service?.find((item) => item.service_type === "JOB_ACCEPTANCE")?.service_type}
      />
    </>
  );
};

const RejectButton = ({ id, jobId, isReject, companyId }) => {
  const data = JSON.parse(localStorage.getItem("user_details"));

  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    fetch(BackendBaseApi.NIYUKTI + "job/college/invite/?college_id=MQ==&job_status=TkVX", {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,

        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `id=${id}&job_id=${jobId}&college_id=1&company_id=${companyId}&is_approved=${false}&is_rejected=${true}&user_id=31`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.data.message);

        setDone(true);
      });
  };
  return (
    !isReject && (
      <CommonButton
        variant="outlined"
        disabled={done}
        onClick={(event) => {
          event.stopPropagation();
          handelActive(jobId);
        }}
      >
        {done ? "Rejected" : "Reject"}
      </CommonButton>
    )
  );
};

const HiddenBtn = ({ jobId, hideStatus }) => {
  const [done, setDone] = useState(hideStatus);
  const handelActive = (jobId) => {
    const data = JSON.parse(localStorage.getItem("user_details"));

    if (hideStatus) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.job.id}&status=unhide`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.job.data.job.message);
          setDone(false);
        });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.job.id}&status=hidden`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.job.data.job.message);
          setDone(true);
        });
    }
  };

  return (
    <>
      {done ? (
        <>
          {" "}
          <Button className="mg-right-15" style={{ marginRight: 15 }} disabled={true} variant="outlined">
            Hidden
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            style={{ marginRight: 15 }}
            className="pointer text-blue mg-right-15"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          >
            <span className="fs-12 text-blue">hide</span>
          </Button>
        </>
      )}
    </>
  );
};

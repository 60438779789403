import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import SideImage from "../../../common/components/UI/SideImage";
import AuthHeader from "../../../common/components/UI/AuthHeader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import { BackendBaseApi } from "../../../../constants/constants";

import {
  FormLabel,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import { useCurrentBreakpointName } from "react-socks";

const useStyles = makeStyles((theme) =>
  createStyles({
    whitebox: {
      width: "100%",
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "6px",
      boxShadow: "0px 0px 4px 0px #000000 25%",
      height: "50%",
      overflowY: "scroll",
    },

    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },

    expButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "center",
      contentAlign: "center",
      margin: "auto",
      justifyContent: "center",
      float: "center",
      display: "flex",
      boderRadius: "75px",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  })
);

const EmailConfirmation = () => {
  const classes = useStyles();

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();

  const [firname, setFirName] = useState(location.Fname);
  const [lastname, setLastName] = useState(location.Lname);
  const [mobile1, setMobile1] = useState(location.Mob);
  const [collegeList, setCollegeList] = useState({});
  const [collegeType, setCollegeType] = useState();
  const [is_check, setIsCheck] = useState(false);
  const [cname, setCompanyName] = useState("");
  const [password, setPassword] = useState();
  const [title, setTitle] = useState(location.JobTitle);

  const year1 = 2000;
  const years = Array.from(new Array(30), (val, index) => index + year1);

  const [year, setYear] = useState();

  useEffect(() => {
    getClgList();
    // if (!location.ifn) {
    //   history.push("/login");
    // }
  }, []);

  const handleChangeCheck = (event) => {
    setIsCheck(event.target.checked);
  };

  const getClgList = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/college")
      .then((res) => {
        let temp = {};
        res.data.forEach((d) => {
          temp[d.name] = d.id;
        });
        setCollegeList({ ...temp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const colleges = Object.keys(collegeList).map((c) => {
    return { name: c, value: c };
  });
  const yearsss = years.map((y) => {
    return { name: y, value: y };
  });

  const Colleges = () => (
    <Autocomplete
      value={collegeType}
      onChange={(event, newValue) => {
        setCollegeType(newValue);
      }}
      id="controllable-states-demo"
      options={colleges.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Colleges" variant="outlined" />
      )}
    />
  );

  const teamAdd = () => {
    const data = {
      uid: location.u_id,
      token: location.to_ken,
      email: location.Email,
      first_name: firname,
      last_name: lastname,
      phone: mobile1,
      password: password,
      alma_mater_id: is_check == false ? collegeList[collegeType] : null,
      alma_mater_name: is_check == false ? null : cname,
      graduation_year: year + "-01-01",
      new_user_flag: location.flag,
      user_type_id: location.userTypeID,
      college_id: location.collegeID,
      college_name: location.collegeName,
      job_title: title,
      about: location.About ? location.About : null,
    };

    if (
      location.Email &&
      firname &&
      lastname &&
      password &&
      mobile1 &&
      (collegeList[collegeType] || cname) &&
      year &&
      title
    ) {
      Axios.post(
        BackendBaseApi.PRAVESH + "api/college/settings/team/add/",
        data
        // , {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // }
      )
        .then((res) => {
          if (res.data.success) {
            alert.success("Signed Up Successfully. Please Login to Continue");
            history.push("/login");
          } else alert.error(res.data.error);
        })
        .catch((err) => {
          alert.error("Error");
        });
    } else {
      alert.error("Fill All the required Fields");
    }
  };

  const GradYears = () => (
    <Autocomplete
      value={year}
      //   onChange={(event, newValue) => {
      //     setYear(newValue);
      //   }}
      id="controllable-states-demo"
      options={yearsss.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Graduation Year" variant="outlined" />
      )}
    />
  );
  return (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div className="col-md-8 col-lg-8 y-scroll full-page-view">
            <div className="row">
              <AuthHeader />
            </div>
            <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-6 mx-auto">
                    {/* <div className="row justify-content-center mx-auto my-2">
                <i class="fas fa-envelope-square fa-5x"></i>
            </div> */}

                    <Typography
                      align="left"
                      className={classes.grid3}
                      variant="h5"
                      style={{ color: "#E55934" }}
                    >
                      <b>
                        {location.ifn} {location.iln} has invited you to team{" "}
                        {location.collegeName}
                      </b>
                    </Typography>
                    <br />
                    <Typography
                      align="left"
                      className={classes.grid3}
                      variant="h6"
                      style={{ color: "black" }}
                    >
                      <b>
                        GetWork is a platform that helps you in all recruitment
                        solutions
                      </b>
                    </Typography>

                    <Box className={classes.whitebox}>
                      <Typography
                        align="left"
                        className={classes.grid3}
                        variant="body1"
                        style={{ color: "#424242", textAlign: "left" }}
                      >
                        <b>Email</b>&nbsp;
                      </Typography>
                      <br />
                      <TextField
                        id="date"
                        label="Email"
                        type="email"
                        // onChange={(e)=>{setFName(e.target.value)}}
                        value={location.Email}
                        variant="outlined"
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <br />
                      <br />

                      <Typography
                        align="left"
                        className={classes.grid3}
                        variant="body1"
                        style={{ color: "#424242", textAlign: "left" }}
                      >
                        <b>Name</b>&nbsp;
                      </Typography>
                      <br />
                      {location.flag == "False" ? (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <TextField
                                id="date"
                                label="First Name"
                                type="text"
                                // onChange={(e)=>{setFName(e.target.value)}}
                                value={firname}
                                variant="outlined"
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="date"
                                label="Last Name"
                                type="text"
                                // onChange={(e)=>{setLName(e.target.value)}}
                                value={lastname}
                                variant="outlined"
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <br />
                          <br />
                          <Typography
                            align="left"
                            className={classes.grid3}
                            variant="body1"
                            style={{ color: "#424242", textAlign: "left" }}
                          >
                            <b>Phone</b>&nbsp;
                          </Typography>
                          <br />
                          <TextField
                            id="date"
                            label="Mobile Number"
                            type="number"
                            // onChange={(e)=>{setMobile(e.target.value)}}
                            value={parseInt(mobile1)}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <TextField
                                id="date"
                                label="First Name"
                                type="text"
                                onChange={(e) => {
                                  setFirName(e.target.value);
                                }}
                                value={firname}
                                variant="outlined"
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="date"
                                label="Last Name"
                                type="text"
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                }}
                                value={lastname}
                                variant="outlined"
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <br />
                          <br />
                          <Typography
                            align="left"
                            className={classes.grid3}
                            variant="body1"
                            style={{ color: "#424242", textAlign: "left" }}
                          >
                            <b>Phone</b>&nbsp;
                          </Typography>
                          <br />
                          <TextField
                            id="date"
                            label="Mobile Number"
                            type="number"
                            onChange={(e) => {
                              setMobile1(e.target.value);
                            }}
                            value={parseInt(mobile1)}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </>
                      )}
                      <br />
                      <br />
                      <Typography
                        align="left"
                        className={classes.grid3}
                        variant="body1"
                        style={{ color: "#424242", textAlign: "left" }}
                      >
                        <b>Job Title</b>&nbsp;
                      </Typography>
                      <br />
                      {location.JobTitle ? (
                        <TextField
                          id="date"
                          label="Job Title"
                          type="text"
                          // onChange={(e)=>{setTitle(e.target.value)}}
                          value={title}
                          variant="outlined"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      ) : (
                        <TextField
                          id="date"
                          label="Job Title"
                          type="text"
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                          value={title}
                          variant="outlined"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      <br />
                      <br />
                      <Typography
                        align="left"
                        className={classes.grid3}
                        variant="body1"
                        style={{ color: "#424242", textAlign: "left" }}
                      >
                        <b>Alma Meter</b>&nbsp;
                      </Typography>
                      <br />
                      {is_check == false ? (
                        <Colleges />
                      ) : (
                        <TextField
                          id="outlined-name"
                          label="Company"
                          value={cname}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                          variant="outlined"
                          style={{ width: "100%" }}
                          required={true}
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={is_check}
                            onChange={handleChangeCheck}
                            name="gilad"
                          />
                        }
                        label="Is Your College not Listed?"
                      />
                      <br />
                      <br />
                      <Typography
                        align="left"
                        className={classes.grid3}
                        variant="body1"
                        style={{ color: "#424242", textAlign: "left" }}
                      >
                        <b>Year of Graduation</b>&nbsp;
                      </Typography>
                      <br />
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Years
                        </InputLabel>
                        <Select
                          //   labelId="demo-simple-select-outlined-label"
                          //   id="demo-simple-select-outlined"
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                          label="Graduation Years"
                          style={{ width: "300%" }}
                        >
                          {yearsss.map((data, ind) => {
                            return (
                              <MenuItem value={data.value} key={ind}>
                                {data.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        className={classes.grid3}
                        variant="body1"
                        style={{ color: "#424242", textAlign: "left" }}
                      >
                        <b>Create a Password</b>&nbsp;
                      </Typography>
                      <br />

                      <TextField
                        type="password"
                        id="outlined-name"
                        label="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        variant="outlined"
                        style={{ width: "100%" }}
                        required={true}
                      />
                      <br />
                      <br />
                      <Typography
                        align="left"
                        className={classes.grid3}
                        variant="body2"
                        style={{ color: "grey", textAlign: "left" }}
                      >
                        <b>
                          By signing up you indicate that you have read and
                          agree to the Terms and Services
                        </b>
                        &nbsp;
                      </Typography>
                      <br />
                      <Button
                        type="submit"
                        className={classes.postButton}
                        onClick={() => {
                          teamAdd();
                          // clearFields();
                        }}
                      >
                        Sign Up
                      </Button>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row justify-content-center mx-auto my-2">
                <i class="fas fa-envelope-square fa-5x"></i>
            </div>
            <div className="row justify-content-center mx-auto my-2">
                <p className="fs-18 fw-500">
                Great! You've successfully signed up for GetWork.
                </p>
                <p className="fs-14">
                We've sent you a link to confirm your email address. Please check your inbox. It could take up to 10 minutes to show up in your inbox.

                </p>
            </div>
            <div className="row mt-4 fs-12 text-left helper-box">

                    <p><b>Didn’t receive the email?</b></p><br></br>
                    <ul>
                        <li><p>Is {location.email} your correct email without typos? If not, you can 
                        <a href=""> restart the sign up process</a></p></li>
                        <li><p>Check your spam folder</p></li>
                        <li><p>Add getwork@m.joingetwork.com to your contacts</p></li>
                        <li><p>Click here to resend the email</p></li>
                    </ul>

                <p><b>Still having trouble? </b></p><br/> &nbsp;
                <p><Link to="/company/register"> Contact us</Link></p>
                
              
            

            </div> */}
    </>
  );
};

export default EmailConfirmation;

import {
  Accordion,
  AccordionDetails, AccordionSummary,
  Checkbox,
  Chip,
  CircularProgress, FormControl, FormControlLabel, FormGroup,
  FormHelperText, Grid,
  InputLabel, ListItemText,
  MenuItem, Radio,
  Select, TextField, Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
// import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import pSBC from "shade-blend-color";
import { BackendBaseApi } from "../../../../constants/constants";
import fetchApi from "../../../apiCall/fetchApi";
import { emailRegex } from "../../../utility/regex";
import History from "../../Common/Table/ShareHistory";
import TrackShareSuccessfully from "../TrackShareSuccessfull/index";
//import ShareHistory from "../../Common/Table/ShareHistory";
import { tableColumns } from "./tableColumnsList";

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  button: {
    borderRadius: "30px",
    boxShadow: "none",
    width: "100%",
    height: "40px",
    fontWeight: "500",
    display: "flex",
  },

  link: {
    boxShadow: "none",
    border: " 1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "30px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  del: {
    borderRadius: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    padding: "7px",
    boxShadow: "none",
  },
  btngrid: {
    display: "flex",
    flexDirection: "column",
  },
  root1: {
    borderRadius: "20px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    // marginTop: "20px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    transform: "translate(15px, 14px) scale(1)",
    //fontSize: "14px",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  labellink: {
    color: theme.palette.primary.main,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  share: {
    borderRadius: "30px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
}));

export default function ShareApplicant({ navbarData, selectedJob, open, setOpen }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const handleChange = (event) => {};
  const alert = useAlert();
  // const [open, setOpen] = React.useState(false);
  const [openShareSuccessfull, setOpenShareSuccessfull] = React.useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [loading, setLoading] = useState(false);
  const [shareHistory, setShareHistory] = useState(null);
  const [shareTableLoader, setShareTableLoader] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(null);
  const [shareResponse, setShareResponse] = useState({});
  const [shareObject, setShareObject] = useState({
    passcode: null,
    email: [],
    action: [],
    authority: { track: false, download: false },
    current_email: null,
    selectedJobRounds: [],
    selectedTableColumns: [],
  });

  const [shareObjectError, setShareObjectError] = useState({
    passcode: null,
    current_email: null,
    selectedJobRounds: null,
    selectedTableColumns: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenShareSuccessfull = () => {
    setOpenShareSuccessfull(true);
  };

  const handleCloseShareSuccessfull = () => {
    setOpenShareSuccessfull(false);
  };
  const [jobRounds, setJobRounds] = useState([]);

  useEffect(() => {
    
    if ( navbarData && Object.keys(navbarData)?.length > 0) {
      let jobRoundsArray = Object.keys(navbarData).map((item) => {
        return {
          id: Math.floor(Math.random() * 100),
          title: navbarData[item]?.tooltip,
          status_id: navbarData[item]?.status_id,
          round_id: navbarData[item]?.round_id,
        };
      });
      setJobRounds(jobRoundsArray);
      setShareObject({...shareObject, selectedJobRounds: jobRoundsArray, selectedTableColumns: tableColumns});
      // let temp = { ...shareObject, selectedJobRounds: jobRoundsArray };
      // setShareObject(temp);
    }
  }, [navbarData]);

  useEffect(() => {
    if (selectedJob?.job_id && open) {
      getShareHistory();
      // shareLinkGenerateAPI();
    }
  }, [selectedJob, open]);

  const getShareHistory = () => {
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/track_sharer/company/sharer_history/?job_id=${selectedJob?.job_id}`,
      setResponse: setShareHistory,
      setLoading: setShareTableLoader,
    });
  };

  const handleDelete = (index) => {
    if (index !== -1) {
      var arr = [...shareObject?.email];
      arr.splice(index, 1);
      setShareObject({ ...shareObject, email: arr });
    }
  };

  const validateData = () => {
    var passcode_error = null;
    var job_round_error = null;
    var column_error = null;
    var is_valid = true;
    // if (!generatedLink?.data?.link) {
    //   alert.error(
    //     "Link has not been generated. Some error occurred!!! Please try later"
    //   );
    //   return;
    // }

    if (!shareObject?.passcode) {
      passcode_error = "Please enter Passcode";
      is_valid = false;
    }
    if (shareObject?.selectedJobRounds?.length === 0) {
      job_round_error = "Please Select Job Rounds to be displayed";
      is_valid = false;
    }
    if (shareObject?.selectedTableColumns?.length === 0) {
      column_error = "Please Select Columns to be displayed in Table";
      is_valid = false;
    }
    setShareObjectError({
      shareObjectError,
      passcode: passcode_error,
      selectedJobRounds: job_round_error,
      selectedTableColumns: column_error,
    });
    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      setLoading(true);
      sharePostAPI();
    }
  };

  const shareLinkGenerateAPI = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const companyData = JSON.parse(localStorage.getItem("company"));
    var data = {
      job_id: selectedJob?.job_id,
      shared_by:
        localStorage.getItem("user_type") === "Company" ? "COMPANY" : "COLLEGE",
      sharer_id: companyData?.company,
      sharer_user_id: userData?.id,
      sharer_user_type_id: localStorage.getItem("user_type_id"),
    };
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/track_sharer/company/link_generation/`,
      method: "post",
      body: JSON.stringify(data),
      setResponse: setGeneratedLink,
      setLoading: setLoading,
    });
  };

  const sharePostAPI = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const companyData = JSON.parse(localStorage.getItem("company"));

    var data = {
      job: selectedJob?.job_id,
      passcode: shareObject?.passcode,
      // job_link: generatedLink?.data?.link,
      action_performed: {
        email: shareObject?.email,
        action: shareObject?.action,
        authority: shareObject?.authority,
        job_rounds: shareObject?.selectedJobRounds?.map((item) => {
          return {
            status_id: item?.status_id,
            round_id: item?.round_id,
            round_name: item?.title,
          };
        }),
        table_columns: shareObject?.selectedTableColumns?.map(
          (item) => item?.field
        ),
      },
      shared_by:
        localStorage.getItem("user_type") === "Company" ? "COMPANY" : "COLLEGE",
      sharer_id: companyData?.company,
      sharer_user_id: userData?.id,
      sharer_user_type_id: localStorage.getItem("user_type_id"),
    };
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/track_sharer/company/sharer_main/`,
      method: "post",
      alert: alert,
      body: JSON.stringify(data),

      setResponse: setShareResponse,
      setLoading: setLoading,
      callBackFn: callBackAfterPost,
    });
  };

  const callBackAfterPost = () => {
    handleClickOpenShareSuccessfull();
    getShareHistory();
    setShareObject({
      passcode: null,
      email: [],
      action: [],
      authority: { track: false, download: false },
      current_email: null,
      selectedJobRounds: [],
      selectedTableColumns: [],
    });
  };

  const handleChangeCheck = (event) => {
    const {
      target: { value },
    } = event;
    const preventDuplicate = value.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    setShareObject({
      ...shareObject,
      selectedJobRounds:
        typeof preventDuplicate === "string"
          ? preventDuplicate.split(",")
          : preventDuplicate,
    });
    setShareObjectError({ ...shareObjectError, selectedJobRounds: null });
  };

  const handleChangeCheckTableColumn = (event) => {
    const {
      target: { value },
    } = event;
    const preventDuplicate = value.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    setShareObject({
      ...shareObject,
      selectedTableColumns:
        typeof preventDuplicate === "string"
          ? preventDuplicate.split(",")
          : preventDuplicate,
    });
    setShareObjectError({ ...shareObjectError, selectedTableColumns: null });
  };

  const [variantName, setVariantName] = React.useState([]);

  // useEffect(() => {
  //   if(open)
  //   setShareObject({ ...shareObject, selectedTableColumns: tableColumns });
  // }, [open]);

  return (
    <div>
      {/* <Button
        variant="contained"
        className={classes.share}
        color="primary"
        onClick={handleClickOpen}
        startIcon={<ShareOutlinedIcon />}
      >
        Share
      </Button> */}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <div style={{ padding: "40px" }}>
            <Typography variant="h4">
              Share Applicant Tracking Info - {selectedJob?.job_title}
            </Typography>
            <Typography variant="body2" style={{ color: "#B0B6BA" }}>
              {/* Share Applicant Tracking - {data?.job_title} */}
              *An unique track application details share link will be auto
              generated once you fill all the below details.
            </Typography>

            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <Typography variant="h6">Invite People (Optional)</Typography>
              </Grid>
              <Grid item xs={10} className={classes.grid}>
                <div className={classes.paper}>
                  <TextField
                    variant="outlined"
                    // required
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "16px",
                        borderRadius: "30px",
                        backgroundColor: "#fff",
                      },
                    }}
                    id="email"
                    label="Email Id"
                    name="email"
                    autoComplete="email"
                    value={shareObject?.current_email}
                    onChange={(e) => {
                      setShareObject({
                        ...shareObject,
                        current_email: e.target.value,
                      });
                      setShareObjectError({
                        ...shareObjectError,
                        current_email: null,
                      });
                    }}
                    error={shareObjectError?.current_email ? true : false}
                    helperText={shareObjectError?.current_email}
                    //autoFocus
                  />
                  {/* <br /> */}
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    if (emailRegex.test(shareObject.current_email)) {
                      var arr = [...shareObject.email];
                      arr.push(shareObject.current_email);
                      setShareObject({
                        ...shareObject,
                        email: arr,
                        current_email: "",
                      });
                    } else {
                      alert.error("Invalid Email");
                    }
                  }}
                >
                  Add
                </Button>
              </Grid>
              {shareObject?.email?.length > 0 && (
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "15px", marginBottom: "10px" }}
                >
                  <div>
                    {shareObject?.email?.map((item, index) => (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => handleDelete(index)}
                        deleteIcon={<CloseIcon />}
                        label={item}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </Grid>
              )}
              <Grid item xs={3} className={classes.grid}>
                <TextField
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  fullWidth
                  required
                  label="Passcode"
                  inputProps={{
                    style: {
                      padding: "16px",
                      borderRadius: "30px",
                      backgroundColor: "#fff",
                    },
                  }}
                  value={shareObject?.passcode}
                  onChange={(e) => {
                    setShareObject({
                      ...shareObject,
                      passcode: e.target.value,
                    });
                    setShareObjectError({
                      ...shareObjectError,
                      passcode: null,
                    });
                  }}
                  error={shareObjectError?.passcode ? true : false}
                  helperText={shareObjectError?.passcode}
                />
              </Grid>{" "}
              <Grid item md={12}>
                <Typography variant="h6">
                  Select Informations to be visible
                </Typography>
              </Grid>
              <Grid item md={5}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  error={shareObjectError?.selectedJobRounds}
                  // style={{
                  //   boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  //   borderColor: "none",
                  // }}
                >
                  <InputLabel
                    className={classes.floatingLabelFocusStyle}
                    id="demo-multiple-checkbox-label"
                  >
                    Select Job Round
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    inputProps={{
                      style: {
                        padding: "16px",
                        borderRadius: "30px",
                      },
                    }}
                    style={{
                      width: "350px",
                    }}
                    variant="outlined"
                    value={shareObject?.selectedJobRounds}
                    label="Select job round"
                    onChange={handleChangeCheck}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) =>
                      selected.map((x) => x.title).join(", ")
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          maxWidth: "200px",
                          borderRadius: "15px",
                        },
                      },
                    }}
                  >
                    {jobRounds?.length > 0 &&
                      jobRounds.map((item) => (
                        <MenuItem key={item?.id} value={item}>
                          <Checkbox
                            color="primary"
                            checked={
                              shareObject?.selectedJobRounds?.indexOf(item) > -1
                            }
                          />
                          <ListItemText primary={item.title} />
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {shareObjectError?.selectedJobRounds}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={5}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  error={shareObjectError?.selectedTableColumns}

                  // style={{
                  //   boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  //   borderColor: "none",
                  // }}
                >
                  <InputLabel
                    className={classes.floatingLabelFocusStyle}
                    id="demo-multiple-checkbox-label"
                  >
                    Select Table Columns
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    inputProps={{
                      style: {
                        padding: "16px",
                        borderRadius: "30px",
                      },
                    }}
                    style={{
                      width: "350px",
                    }}
                    variant="outlined"
                    value={shareObject?.selectedTableColumns}
                    label="Select Table Columns"
                    onChange={handleChangeCheckTableColumn}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) =>
                      selected.map((x) => x.displayName).join(", ")
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          maxWidth: "220px",
                          borderRadius: "15px",
                        },
                      },
                    }}
                  >
                    {tableColumns.map((variant) => (
                      <MenuItem key={variant.id} value={variant}>
                        <Checkbox
                          color="primary"
                          checked={
                            shareObject?.selectedTableColumns?.indexOf(
                              variant
                            ) > -1
                          }
                        />
                        <ListItemText primary={variant.displayName} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {shareObjectError?.selectedTableColumns}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                // marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Choose User Actions
              </Typography>

              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={shareObject?.authority?.track}
                      onChange={(e) => {
                        setShareObject({
                          ...shareObject,
                          authority: {
                            ...shareObject.authority,
                            track: e.target.checked,
                          },
                        });
                      }}
                      name="checkedA"
                    />
                  }
                  label={<Typography variant="body2">Track</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={shareObject?.authority?.download}
                      onChange={(e) => {
                        setShareObject({
                          ...shareObject,
                          authority: {
                            ...shareObject.authority,
                            download: e.target.checked,
                          },
                        });
                      }}
                      name="checkedA"
                    />
                  }
                  label={<Typography variant="body2">Download</Typography>}
                />
              </FormGroup>
            </div>
            {shareHistory && (
              <Accordion
                style={{
                  boxShadow: "none",
                  marginTop: "-2px",
                }}
                classes={{
                  root: classes.MuiAccordionroot,
                }}
                // classes={{
                //   root: classes.test,
                // }}
              >
                <AccordionSummary
                  style={{ width: "19%" }}
                  expandIcon={<ExpandMoreIcon color="primary" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ marginLeft: "-10px" }}
                  >
                    View History
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <History
                    data={shareHistory?.data}
                    jobTitle={selectedJob?.job_title}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{
                  height: "40px",
                  borderRadius: "30px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  marginRight: "10px",
                  boxShadow: "none",
                  display: "flex",
                  fontWeight: "500",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "40px",
                  borderRadius: "30px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  minWidth: "120px",
                  boxShadow: "none",
                  display: "flex",
                  fontWeight: "500",
                }}
                onClick={() => {
                  if (!loading) {
                    handleSubmit();
                  }
                }}
              >
                {loading ? (
                  <CircularProgress size={18} style={{ color: "#fff" }} />
                ) : (
                  "Share"
                )}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <TrackShareSuccessfully
        open={openShareSuccessfull}
        handleClickOpen={handleClickOpenShareSuccessfull}
        handleClose={handleCloseShareSuccessfull}
        data={{
          link: shareResponse?.data?.link,
          passcode: shareResponse?.data?.passcode,
        }}
        jobTitle={selectedJob?.job_title}
      />
    </div>
  );
}

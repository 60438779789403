import { Box } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CommonButton from "../../common/CommonButton/CommonButton";

const DetailsComplete = () => {
  const history = useHistory();
  return (
    <div style={{ width: "70%", margin: "auto" }}>
      <p style={{ fontSize: 14, fontWeight: "bold", textAlign: "center" }}>
        Company Details already entered.{" "}
        {!localStorage.getItem("show_home_menu") ? "Click the below respective buttons to view the settings" : "Click the below Buttons to explore GetWork"}
      </p>
      {localStorage.getItem("show_home_menu") ? (
        <Box display="flex" justifyContent="space-between" margin="auto" marginBottom="20px" marginTop="20px">
          <CommonButton
            onClick={() => {
              history.push("/public/open-jobs");
            }}
            variant="contained"
          >
            Explore Jobs{" "}
          </CommonButton>
          <CommonButton
            onClick={() => {
              history.push("/public/india-colleges");
            }}
            variant="contained"
          >
            Explore Colleges
          </CommonButton>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" margin="auto" marginBottom="20px" marginTop="20px">
          <CommonButton
            onClick={() => {
              history.push("/company/company-settings");
            }}
            variant="contained"
          >
            Company Settings
          </CommonButton>
          <CommonButton
            onClick={() => {
              history.push("/company/user-settings");
            }}
            variant="contained"
          >
            User Settings
          </CommonButton>
        </Box>
      )}
    </div>
  );
};
export default DetailsComplete;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import fakebanner from "../../../../assets/images/noimg.png";
import fakelogo from "../../../../assets/images/user/user-default.png";

import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { loadCSS } from "fg-loadcss";
import eye from "../../../../assets/images/eye.png";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../constants/constants";
import ReactHtmlParser from "react-html-parser";
import pic from "../../../../assets/common/edit3.svg";
import view from "../../../../assets/common/search.svg";
import edit from "../../../../assets/common/pen.svg";
import "./styles";
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
} from "reactstrap";
import Icon from "@material-ui/core/Icon";
// import Loader from "../../../common/components/UI/Loader.js";
import Loader from "../../../../bundles/common/components/UI/Loader";
import { CircularProgress, Button } from "@material-ui/core";
import { getCompanyUserDetails } from "../action";
import { useDispatch } from "react-redux";
import PictureChangeDialog from "../../Dialogs/PictureChangeDialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 200,
  },
}));

export default function CompanyProfile(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [url, setUrl] = useState(
    BackendBaseApi.PRAVESH + "api/company/edit_profile/"
  );
  const [isError, setIsError] = useState(false);
  const [isAbout, setIsAbout] = useState();
  const [isDescription, setIsDescription] = useState();
  const [isName, setIsName] = useState();
  const [industry, setIsIndustry] = useState();
  const [location, setIsLocation] = useState();
  const [logo, setIsLogo] = useState();
  const [banner, setIsBanner] = useState();
  const [web, setIsweb] = useState();
  const [year, setIsyear] = useState();
  const [spcl, setIsSpecialities] = useState();
  const [size, setIsSize] = useState();
  const [org, setOrg] = useState();
  const alert = useAlert();
  const [check, setCheck] = useState(false);
  const [checkLogo, setCheckLogo] = useState(false);
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [updatingLogo, setUpdatingLogo] = useState(false);
  const [updatingBanner, setUpdatingBanner] = useState(false);

  let token = localStorage.getItem("gw_token");

  const [post, setPost] = useState({ data: {} });
  const [file, setFile] = useState({ data: {} });
  const [image, setImage] = useState({ preview: "" });
  const [imagelogo, setImagelogo] = useState({ previewlogo: "" });
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            setPost(res.data.data);
            setFile(res.data.data);
            setIsName(res.data.data.company_name);
            setIsAbout(res.data.data.about);
            setIsDescription(res.data.data.company_description);
            setIsIndustry(res.data.data.industry_name);
            setIsLocation(res.data.data.location);
            setIsLogo(res.data.data.logo);
            setIsBanner(res.data.data.banner);
            setIsSize(res.data.data.size);
            setIsweb(res.data.data.website);
            setIsyear(res.data.data.year_founded);
            setIsSpecialities(res.data.data.specialities);
            setOrg(res.data.data.organization_type);
            setImage({ preview: res.data.data.banner });
            setImagelogo({ previewlogo: res.data.data.logo });
            setCity(res.data.data.city_name);
            setState(res.data.data.state_name);
            setLoading(false);
          }
        })
        .catch((error) => {
          alert.error("Some Error Occured. Try Again Later");
          setLoading(false);
        });
    };

    fetchData();
  }, []);

  const IMAGES = [
    {
      src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
      thumbnail:
        "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
      thumbnailWidth: 1000,
      thumbnailHeight: 174,
      // isSelected: true,
      caption: "After Rain (Jeshu John - designerspics.com)",
    },
    {
      src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
      thumbnail:
        "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
      tags: [
        { value: "Ocean", title: "Ocean" },
        { value: "People", title: "People" },
      ],
      caption: "Boats (Jeshu John - designerspics.com)",
    },

    {
      src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
      thumbnail:
        "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
    },

    {
      src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
      thumbnail:
        "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
    },
  ];

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
  };

  function myTileViewportStyleFn() {
    if (this.props.item.isSelected) return {};
  }

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { buttonLabel, className } = props;

  const [modall, setModal1] = useState(false);

  const toggle1 = () => setModal1(!modall);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  function imagehandle(e) {
    const newdata = { ...file };
    newdata[e.target.name] = e.target.files[0];

    setFile(newdata);
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
    });
  }

  function imagesubmit(e) {
    setUpdatingBanner(true);
    e.preventDefault();
    const fdd = new FormData();

    fdd.append("banner", file.banner);

    axios
      .patch(url, fdd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Banner Uploaded Successfully");
          setCheck(true);
          toggle();

          dispatch(getCompanyUserDetails(history, alert));
        } else {
          alert.error(res.data.error);
        }
        setUpdatingBanner(false);
      })
      .catch((error) => {
        alert.error("Please Select Banner before clicking here.");
        setUpdatingBanner(false);
      });
  }

  function imagehandle1(e) {
    const newdata = { ...file };
    newdata[e.target.name] = e.target.files[0];

    setFile(newdata);
    setImagelogo({
      previewlogo: URL.createObjectURL(e.target.files[0]),
    });
  }

  function imagesubmit1(e) {
    setUpdatingLogo(true);
    e.preventDefault();
    const fdd = new FormData();

    fdd.append("logo", file.logo);

    axios
      .patch(url, fdd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Logo Uploaded Successfully");
          setCheckLogo(true);
          toggle1();

          dispatch(getCompanyUserDetails(history, alert));
        } else {
          alert.error(res.data.error);
        }

        setUpdatingLogo(false);
      })
      .catch((error) => {
        alert.error("Please Select Profile picture before clicking here.");
        setUpdatingLogo(false);
      });
  }

  return loading ? (
    <Box>
      <Loader />
    </Box>
  ) : (
    <>
      <Container fluid style={{ width: "96%", marginTop: "20px" }}>
        <div>
          <Card
            className="shadow"
            style={{
              borderRadius: "30px",
              backdropFilter: "blur(30px)",
              boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
              border: "solid 1px #f1f1f1",
              backgroundColor: "#ffffff",
            }}
          >
            <CardBody>
              {/* <Button
              variant="contained"
              style={{
                float: "right",
              }}
             
            >
              <Icon name="pencil" size="miny" /> */}
              {/* Add/Edit Cover Photo */}
              <img
                onClick={toggle}
                src={pic}
                fontSize="inherit"
                alt=""
                height="30px"
                style={{ float: "right", cursor: "pointer" }}
              />

              {/* </Button> */}
              <PictureChangeDialog
                open={modal}
                handleClickOpen={toggle}
                handleClose={toggle}
                image={image.preview}
                banner={banner}
                updatingBanner={updatingBanner}
                fakebanner={fakebanner}
                imagehandle={imagehandle}
                imagesubmit={imagesubmit}
              />

              <div
                style={
                  banner
                    ? {
                        width: "100%",
                        height: "200px",
                        backgroundImage: `url(${
                          check == true ? image.preview : banner
                        })`,
                        boxShadow: "2px 2px 10px #9E9E9E",
                        zIndex: "-10",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "30px",
                      }
                    : {
                        width: "100%",
                        height: "200px",
                        backgroundImage: `url(${
                          check == true ? image.preview : fakebanner
                        })`,
                        boxShadow: "2px 2px 10px #9E9E9E",
                        zIndex: "-10",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "30px",
                      }
                }
              >
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div>
                  <Button
                    onClick={toggle1}
                    style={{
                      borderRadius: "50%",
                      padding: "0px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginRight: "auto",
                      marginLeft: "auto",
                      float: "center",
                      backgroundColor: "white",
                      borderColor: "white",
                    }}
                  >
                    <img
                      onClick={toggle1}
                      src={pic}
                      fontSize="inherit"
                      alt=""
                      height="30px"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        zIndex: 100,
                        position: "fixed",
                        left: "53%",
                        top: "35%",
                      }}
                    />
                    {logo ? (
                      <img
                        src={checkLogo == true ? imagelogo.previewlogo : logo}
                        alt="logo"
                        height="150px"
                        width="160px"
                        style={{
                          border: "6px solid rgb(121 185 242)",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginRight: "auto",
                          marginLeft: "auto",
                          float: "center",
                        }}
                      />
                    ) : (
                      <img
                        src={
                          checkLogo == true ? imagelogo.previewlogo : fakelogo
                        }
                        alt="logo"
                        height="150px"
                        width="160px"
                        style={{
                          border: "6px solid rgb(121 185 242)",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginRight: "auto",
                          marginLeft: "auto",
                          float: "center",
                        }}
                      />
                    )}
                  </Button>
                </div>
              </div>

              <PictureChangeDialog
                open={modall}
                handleClickOpen={toggle1}
                handleClose={toggle1}
                image={imagelogo.previewlogo}
                banner={logo}
                updatingBanner={updatingLogo}
                fakebanner={fakelogo}
                imagehandle={imagehandle1}
                imagesubmit={imagesubmit1}
                logo={true}
              />

              <Desktop>
                <Link to="/public/company/profile" target="_blank">
                  <p
                    style={{
                      textAlign: "right",
                      color: "rgb(76, 135, 190)",
                      paddingTop: "10px",
                    }}
                  >
                    <img src={view} height="20px" />
                    &nbsp; View your public profile
                  </p>
                </Link>
              </Desktop>
              <Mobile>
                <Link to="/public/company/profile">
                  <p
                    style={{
                      textAlign: "center",
                      color: "rgb(76, 135, 190)",
                      paddingTop: "100px",
                    }}
                  >
                    <img src={eye} height="16px" />
                    &nbsp; View your public profile
                  </p>
                </Link>
              </Mobile>

              <br />
              <br />
              <br />

              <Row>
                <Col xl="10" style={{ marginBottom: "-15px" }}>
                  <h3>{isName}</h3>
                  <p style={{ color: "grey" }}>
                    {industry} • {city}, {state} • {size} Employees
                  </p>
                </Col>
                <Col xl="2" style={{ marginBottom: "-15px" }}>
                  <Link to="/company/profile_update">
                    <Button className="bbtn" style={{ marginTop: 20 }}>
                      <img src={edit} height="20px" />
                      <b>Edit Profile</b>
                    </Button>
                  </Link>
                </Col>
              </Row>

              <hr />

              <h4>About</h4>
              {isAbout ? (
                <p>{isAbout}</p>
              ) : (
                <p>Please Edit Details to Enter About Section...</p>
              )}

              {web && (
                <>
                  <Row>
                    <Col sm="2">
                      <h6>Website</h6>
                    </Col>
                    <Col sm="10">
                      <a href={web} target="blank">
                        <p style={{ color: "#0000EE" }}>{web}</p>
                      </a>
                    </Col>
                  </Row>
                </>
              )}
              {industry && (
                <>
                  <Row>
                    <Col sm="2">
                      <h6>Industry</h6>
                    </Col>
                    <Col sm="10">
                      <p>{industry}</p>
                    </Col>
                  </Row>
                </>
              )}
              {size && (
                <>
                  <Row>
                    <Col sm="2">
                      <h6>Employees</h6>
                    </Col>
                    <Col sm="10">
                      <p>{size}</p>
                    </Col>
                  </Row>
                </>
              )}
              {org && (
                <>
                  <Row>
                    <Col sm="2">
                      <h6>Type</h6>
                    </Col>
                    <Col sm="10">
                      <p>{org === "SemiPrivate" ? "Semi Government" : org}</p>
                    </Col>
                  </Row>
                </>
              )}
              {year && (
                <>
                  <Row>
                    <Col sm="2">
                      <h6>Founded</h6>
                    </Col>
                    <Col sm="10">
                      <p>{year}</p>
                    </Col>
                  </Row>
                </>
              )}
              {spcl && (
                <>
                  <Row>
                    <Col sm="2">
                      <h6>Specialities</h6>
                    </Col>
                    <Col sm="10">
                      <p>{ReactHtmlParser(spcl)}</p>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

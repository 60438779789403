import calendar from "../assets/job/calendar.svg";
import stopwatch from "../assets/job/stopwatch.svg";
import skills from "../assets/job/skills.svg";
import location from "../assets/job/location.svg";
import college from "../assets/job/college.svg";
import company from "../assets/job/company.png";
import money from "../assets/job/money.svg";
import job_b from "../assets/job/job_b.svg";
import permanentJob from "../assets/job/permanent-job.png";
import serviceBond from "../assets/job/service-bond.png";
import documents from "../assets/job/documents.png";
import vacancy from "../assets/job/vacancy.png";
import hiringType from "../assets/job/hiringType.png";
import equity from "../assets/job/equity.png";
import ppo from "../assets/job/ppo.png";
import jobRounds from "../assets/job/jobRounds.png";
import description from "../assets/job/description.png";
import preferences from "../assets/job/preferences.png";
import kFormatter, { LFormatter, MFormatter } from "./ZeroToK";
import { emailDomainsBlocked } from "../constants/constants";

export const jobSharerDetail = (data) => {
  if (!data) return {};
  return {
    job_id: data?.job_id || data?.id,
    company_name: data.company ? data.company.company_name : data.company_details ? data.company_details : JSON.parse(localStorage.getItem("company"))?.company_name,
    job_title: data?.job_title,
    job_type: data?.job_type_name,
    apply_end_date: (data?.college && data?.college[0]?.apply_end_date) || data?.apply_end_date,
    job_role: data?.job_role_name,
    payment_type: data?.salary_payment_type.toLowerCase(),
    ctc_min: data?.ctc_min,
    ctc_max: data?.ctc_max,
    hiring_type: data?.hiring_type === "ON CAMPUS" ? "oncampus" : "offcampus",
  };
};

export const getUserDashboardHomeLink = (userType) => {
  let link = "";
  if (userType.toUpperCase() === "COLLEGE") link = "/college/dashboard";
  else if (userType.toUpperCase() === "COMPANY") link = "/company/dashboard";
  else if (userType.toUpperCase() === "STUDENT") link = "/student/dashboard";
  return link;
};

export function checkLinkingHomePage(name) {
  if (name) {
    var link;
    if (name.toUpperCase() === "STUDENT") {
      if (localStorage.getItem("is_pref_filled") === "true") link = `/student/dashboard`;
      else link = `/student/complete-profile`;
    }
    if (name.toUpperCase() === "COMPANY") {
      if (localStorage.getItem("is_pref_filled") === "false") link = `/company/complete-profile`;
      else if (localStorage.getItem("is_pref_filled") === "true") link = `/company/dashboard`;
    }
    if (name.toUpperCase() === "COLLEGE") link = `/college/dashboard`;
  }
  return link;
}

export function digits_count(n) {
  var count = 0;
  if (n >= 1) ++count;

  while (n / 10 >= 1) {
    n /= 10;
    ++count;
  }

  return count;
}

export const getIconPath = (iconName) => {
  switch (iconName) {
    case "JOB_SEGMENT":
      break;
    case "JOB_TYPE":
      break;
    case "JOB_LOCATION":
      break;
    default:
      break;
  }
};

const year1 = 1990;
const years = Array.from(new Array(30), (val, index) => index + year1);

export const years_array = Array.from(new Array(30), (val, index) => index + 1990).map((y) => {
  return { name: y, value: String(y) };
});

export const educationTypes = [
  { education_id: 1, education_name: "Secondary" },
  { education_id: 2, education_name: "Senior Secondary" },
  { education_id: 3, education_name: "Graduation (UG)" },
  { education_id: 4, education_name: "Post Graduation (PG)" },
  { education_id: 5, education_name: "PhD" },
  { education_id: 6, education_name: "Diploma" },
];

export const executeScrollCommon = (value) => {
  if (value !== null && value?.current?.scrollIntoView) {
    value.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export function valueLabelFormat(value) {
  var new_value;
  if (value > 99999) {
    if (value > 999999) new_value = MFormatter(value);
    else new_value = LFormatter(value);
  } else new_value = kFormatter(value);
  return new_value;
}

export function stringEllipsify(str, n) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export const validateEmployerEmail = (email) => {
  let emailValid = true;
  let emailToCheck = email?.toLowerCase();

  // we are checking mail server excluding username.
  if (emailToCheck) {

    emailToCheck = emailToCheck.split('@')[1];

  };

  for (let x in emailDomainsBlocked) {
    emailValid = emailToCheck?.includes(emailDomainsBlocked[x]);
    if (emailValid) {
      break;
    };

  }
  return emailValid;

};
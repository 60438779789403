import { Typography, Link, Breadcrumbs, Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import Expereince from "../components/Cards/Students/Expereince/Expereince";
import Skill from "../components/Cards/Students/Skill/Skill";
import Languages from "../components/Cards/Students/Languages/Languages";
import Hobbies from "../components/Cards/Students/Hobbies/Hobbies";
import Project from "../components/Cards/Students/Project/Project";
import Education from "../components/Cards/Students/Education/Education";
import Publications from "../components/Cards/Students/Publications/Publications";
import Achievements from "../components/Cards/Students/Achievements/Achievements";
import Contact from "../components/Cards/Students/Contact/Contact";
import OverView from "../components/Cards/Students/Overview/OverView";
import CommentSection from "../components/Cards/Students/CommentSection/CommentSection";
import JobRoles from "../components/Cards/Students/JobRoles/JobRoles";
import Location from "../components/Cards/Students/Location/Location";
import JobType from "../components/Cards/Students/JobRoles/JobType";
import Cerification from "../components/Cards/Students/Cerification/Cerification";
import SkillsRating from "../components/Cards/SkillsRating/SkillsRating";
import PerformAction from "../components/Cards/Students/PerformAction/PerformAction";
import fetchApi from "../apiCall/fetchApi";
import { APP_ENDPOINT, BackendBaseApi } from "../../constants/constants";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import YM from "convert-months-year";
import moment from "moment";
import CommonButton from "../../components/common/CommonButton/CommonButton";
import Icon401 from "../../assets/svg/401-Error-Unauthorized-rafiki.svg";

export default function EmployeeDetails() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDataError, setUserDataError] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [paramData, setParamData] = useState(null);
  const [actionsData, setActionsData] = useState(null);
  const [loadingActions, setLoadingActions] = useState(false);
  const [actionsDataError, setActionsDataError] = useState(null);
  const [commentsData, setCommentsData] = useState(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentsError, setCommentsError] = useState(false);
  const [preferencesData, setPreferencesData] = useState(null);
  const [preferencesDataError, setPreferencesDataError] = useState(null);
  const [loadingPreferences, setLoadingPreferences] = useState(false);
  const [structuredData, setStructuredData] = useState({
    overviewData: null,
    contactDetails: null,
    skills: [],
    jobRoles: null,
    location: null,
    workExperiece: [],
    education: [],
    projects: [],
    publications: [],
    achievements: [],
    languages: [],
    hobbies: [],
  });
  const [hidden, setHidden] = useState(false);

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  useEffect(() => {
    if (location.search && Object.keys(getSearchParameters()).length > 0) {
      if (Object.keys(getSearchParameters()).includes("user_id"))
        setParamData(getSearchParameters());
      else history.push("/company/manage-job");
    } else history.push("/company/manage-job");
  }, [location.search]);

  useEffect(() => {
    if (paramData?.user_id) {
      fetchApi({
        url: `${BackendBaseApi.PRAVESH}api/shared/v3/public_profile/?user_id=${paramData?.user_id}&job_id=${paramData?.job_id}`,
        setResponse: setUserData,
        setLoading: setLoading,
        setError: setUserDataError,
      });
      getActionsData();
      getComments();
      getUserPreferences();
    }
  }, [paramData]);

  const getActionsData = () => {
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/job_round_check/?applicant_id=${paramData?.applicant_id}&job_id=${paramData?.job_id}`,
      setResponse: setActionsData,
      setLoading: setLoadingActions,
      setError: setActionsDataError,
    });
  };

  const getUserPreferences = () => {
    fetchApi({
      url: `${BackendBaseApi.PRAVESH}api/company/student_user_preferences/?user_id=${paramData?.user_id}`,
      setResponse: setPreferencesData,
      setLoading: setLoadingPreferences,
      setError: setPreferencesDataError,
    });
  };

  const getComments = () => {
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/comments/?job_applicant_id=${paramData?.applicant_id}`,
      setResponse: setCommentsData,
      setLoading: setLoadingComments,
      setError: setCommentsError,
    });
  };

  useEffect(() => {
    if (userData?.data) {
      let data = userData?.data;
      if (data?.hiddenData) {
        setHidden(true);
      }
      setStructuredData({
        ...structuredData,
        overviewData: {
          name: data?.first_name + " " + data?.last_name,
          isActive: !data?.student_user_details?.is_blocked,
          resume: data?.student_user_details?.resume,
          profileImage: data?.profile_picture,
        },
        contactDetails: {
          gender: data?.student_user_details?.gender,
          currentCity: data?.current_city_name,
          currentCtc: data?.student_user_details?.current_ctc,
          expectedCtc: data?.student_user_details?.expected_ctc,
          noticePeriod: data?.student_user_details?.notice_period,
          workExp: data?.student_user_details?.total_work_exp
            ? data?.student_user_details?.total_work_exp
            : null,
          // ? YM.getYearsFromMonth(data?.student_user_details?.total_work_exp)?.years &&
          //   YM.getYearsFromMonth(data?.student_user_details?.total_work_exp)?.months
          //   ? `${
          //       YM.getYearsFromMonth(data?.student_user_details?.total_work_exp)?.years
          //     } years  ${
          //       YM.getYearsFromMonth(data?.student_user_details?.total_work_exp)?.months
          //     } months`
          //   : YM.getYearsFromMonth(data?.student_user_details?.total_work_exp)?.years
          //   ? `${YM.getYearsFromMonth(data?.student_user_details?.total_work_exp)?.years} years`
          //   : `${YM.getYearsFromMonth(data?.student_user_details?.total_work_exp)?.months} months`
          // : null,
          about: data?.student_user_details?.about,
          phone: data?.phone,
          email: data?.email,
          profile_url: data?.profile_url,
        },
        skills: data?.student_user_details?.skill?.map(
          (item) => item.skill_name && item.skill_name
        ),
        workExperiece: data?.student_user_details?.work_ex?.map((item) => {
          let obj = {
            jobDesignation: item?.job_designation,
            company: item?.company_name,
            jobType: item?.job_type_name,
            jobDescription: item?.job_description,
            timePeriod: item?.start_date
              ? `${moment(item?.start_date).format("MMM, YYYY")}${
                  item?.end_date
                    ? ` - ${moment(item?.end_date).format("MMM, YYYY")}`
                    : " - Present"
                }`
              : null,
          };
          return obj;
        }),
        certification_data: data?.student_user_details?.certification_data,
        education: data?.student_user_details?.education?.map((item) => {
          let cur_start_date = item?.start_date
            ? moment(item?.start_date).format("YYYY")
            : "NA";
          let cur_end_date = item?.end_date
            ? moment(item?.end_date).format("YYYY")
            : "PRESENT";
          return {
            college: item?.board
              ? item.highest_qualification
              : item?.temp_college_name && item?.college === 142
              ? item?.temp_college_name
              : item?.college_name,
            degreeSpec: item?.board
              ? item?.board_name
              : item?.degree
              ? `${item?.degree}${
                  item?.specialization ? ` in ${item?.specialization}` : ""
                }`
              : item?.specialization,
            grade: item?.percentage
              ? item?.is_grade_system
                ? `CGPA: ${item?.percentage}`
                : `Percentage: ${item?.percentage}`
              : null,
            timePeriod: cur_start_date + " - " + cur_end_date,
            college_name: item?.college_name,
            highest_qualification: item?.highest_qualification,
          };
        }),
        projects: data?.student_user_details?.project?.filter(
          (item) => item.type === "Project"
        ),
        publications: data?.student_user_details?.project?.filter(
          (item) => item.type === "Publications"
        ),
        achievements: data?.student_user_details?.achievements,
        languages: data?.student_user_details?.languages,
        hobbies: data?.student_user_details?.hobbies,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (preferencesData?.data?.preference) {
      var temp_data = preferencesData?.data?.preference;
      setStructuredData({
        ...structuredData,
        job_roles: temp_data?.job_roles,
        preferred_location: temp_data?.job_location,
        industry_type: temp_data?.industry_type,
        job_type: temp_data?.job_type ? temp_data?.job_type : "NA",
      });
    }
  }, [preferencesData]);

  function getSearchParameters() {
    var prmstr = location.search.substr(1);
    return prmstr != null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
  }

  function transformToAssocArray(prmstr) {
    var params = {};
    var prmarr = prmstr.split("&");
    for (var i = 0; i < prmarr.length; i++) {
      var tmparr = prmarr[i].split("=");
      params[tmparr[0]] = tmparr[1];
    }
    return params;
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={24} />
        </div>
      ) : userDataError ? (
        <>
          <div
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "400" }}
            >
              {userDataError}
            </Typography>
          </div>
        </>
      ) : hidden ? (
        <>
          <div
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={Icon401} style={{ height: "400px", width: "400px" }} />
            <Link
              href={`${APP_ENDPOINT}/company/manage-job/?job_id=${btoa(
                paramData?.job_id
              )}`}
            >
              <CommonButton
                type="button"
                buttonStyle="btn--primary--outline"
                buttonSize="btn--medium"
                id="navBtn"
                variant="outlined"
              >
                <Typography
                  variant="body2"
                  color="inherit"
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "300",
                    padding: 2,
                  }}
                >
                  GO BACK TO TRACK APPLICANTS
                </Typography>
              </CommonButton>
            </Link>
          </div>
        </>
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link href="/company/all-jobs" style={{ textDecoration: "none" }}>
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "400" }}
              >
                Manage Jobs
              </Typography>
            </Link>
            <Link
              href={`/company/manage-job/?job_id=${btoa(paramData?.job_id)}`}
              aria-current="page"
            >
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "400" }}
              >
                {actionsData?.data?.job_title} - Applicants
              </Typography>
            </Link>
            <Link color="textPrimary" aria-current="page">
              {structuredData?.overviewData?.name}
            </Link>
          </Breadcrumbs>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item sm={4}>
                {structuredData?.overviewData && actionsData?.data && (
                  <OverView
                    data={structuredData?.overviewData}
                    relevanceScore={actionsData?.data?.relevance_score}
                    scoreData={actionsData?.data?.student_job_matching_data}
                    createdData={actionsData?.data?.create_date}
                    actionsData={actionsData?.data}
                  />
                )}
                <br />
                <Contact data={structuredData?.contactDetails} />
                <br />
                {structuredData?.skills?.length > 0 && (
                  <Skill data={structuredData?.skills} />
                )}

                {structuredData?.job_roles?.length > 0 && (
                  <>
                    <br />
                    <JobRoles data={structuredData?.job_roles} />
                  </>
                )}
                {structuredData?.preferred_location?.length > 0 && (
                  <>
                    <br />
                    <Location data={structuredData?.preferred_location} />
                  </>
                )}
                {(structuredData?.industry_type?.length > 0 ||
                  structuredData?.job_type) && (
                  <>
                    <br />
                    <JobType
                      data={{
                        industryType: structuredData?.industry_type,
                        jobType: structuredData?.job_type,
                      }}
                    />
                  </>
                )}
              </Grid>
              <Grid item sm={4}>
                {structuredData?.workExperiece?.length > 0 && (
                  <>
                    <Expereince data={structuredData?.workExperiece} />
                    <br />
                  </>
                )}
                {structuredData?.education?.length > 0 && (
                  <>
                    <Education data={structuredData?.education} />
                    <br />
                  </>
                )}
                {structuredData?.projects?.length > 0 && (
                  <>
                    <Project data={structuredData?.projects} />
                    <br />
                  </>
                )}
                {structuredData?.publications?.length > 0 && (
                  <>
                    <Publications data={structuredData?.publications} />
                    <br />
                  </>
                )}
                {structuredData?.certification_data?.length > 0 && (
                  <>
                    <Cerification data={structuredData?.certification_data} />
                    <br />
                  </>
                )}

                {structuredData?.achievements?.length > 0 && (
                  <>
                    {" "}
                    <Achievements data={structuredData?.achievements} />
                    <br />
                  </>
                )}
                {structuredData?.languages?.length > 0 && (
                  <>
                    <Languages data={structuredData?.languages} />
                    <br />
                  </>
                )}
                {structuredData?.hobbies?.length > 0 && (
                  <>
                    <Hobbies data={structuredData?.hobbies} />
                    <br />
                  </>
                )}
              </Grid>
              <Grid item sm={4}>
                {paramData?.applicant_id && paramData?.job_id && (
                  <>
                    <PerformAction
                      details={{
                        name: structuredData?.overviewData?.name,
                        applicantId: paramData?.applicant_id,
                        user_id: paramData?.user_id,
                        job_id: paramData?.job_id,
                        round_id:
                          actionsData?.data?.current_job_round?.round_id,
                        status_id:
                          actionsData?.data?.current_job_round?.status_id,
                        round_name:
                          actionsData?.data?.current_job_round?.round_name,
                      }}
                      getActionsData={getActionsData}
                      moveNextArray={actionsData?.data?.job_round_list?.map(
                        (item) => {
                          return {
                            status_id: item.status_id,
                            name: item.tooltip,
                            round_id: item.round_id
                              ? item.round_id
                              : `gw-${Math.floor(Math.random() * 100)}`,
                          };
                        }
                      )}
                    />
                    <br />
                    {actionsData?.data?.student_job_matching_data && (
                      <SkillsRating
                        data={actionsData?.data?.student_job_matching_data}
                      />
                    )}

                    <br />
                    <CommentSection
                      commentsData={
                        commentsData?.data?.comments
                          ? commentsData?.data?.comments
                          : []
                      }
                      loadingComments={loadingComments}
                      commentsError={commentsError}
                      currentApplicant={{
                        applicantId: paramData?.applicant_id,
                        applicantName: structuredData?.overviewData?.name,
                        status_id:
                          actionsData?.data?.current_job_round?.status_id,
                        status_name:
                          actionsData?.data?.current_job_round?.status_name,
                      }}
                      getComments={getComments}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}

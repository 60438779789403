import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@mui/icons-material/Email";
import { Button, CircularProgress, Typography } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import GoogleLogin from "react-google-login";
import { useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi, GOOGLE_CLIENT_ID } from "../../../../constants/constants";
import LoginDialog from "../../Dialog/LoginDialog";
import RegisterGetworkDialog from "../../Dialog/RegisterGetworkDialog";

const linkdin = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.5781 18.0618C35.5781 27.7695 27.7078 35.6399 18 35.6399C8.29216 35.6399 0.421875 27.7695 0.421875 18.0618C0.421875 8.35393 8.29216 0.483643 18 0.483643C27.7078 0.483643 35.5781 8.35393 35.5781 18.0618Z"
      fill="#0A7BBA"
    />
    <path
      d="M31 19.4865V27.9999H26.0692V20.0579C26.0692 18.0631 25.3582 16.7008 23.5715 16.7008C22.2105 16.7008 21.4005 17.6191 21.0432 18.507C20.9122 18.8233 20.8798 19.2636 20.8798 19.7094V28H15.9473C15.9473 28 16.0138 14.5477 15.9473 13.1551H20.8798V15.2594C20.8709 15.2756 20.8566 15.2915 20.8475 15.3077H20.8798V15.2594C21.5353 14.2495 22.7042 12.8062 25.324 12.8062C28.5687 12.8062 31 14.9287 31 19.4865ZM10.7904 6C9.10246 6 8 7.1089 8 8.5647C8 9.98986 9.07019 11.1292 10.7258 11.1292H10.7563C12.4765 11.1292 13.5467 9.98994 13.5467 8.5647C13.5143 7.10899 12.4765 6 10.7904 6ZM8.2927 28H13.2216V13.1551H8.2927V28Z"
      fill="#F2F2F2"
    />
  </svg>
);

const fb = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4419_4048)">
      <path
        d="M36 18C36 8.05887 27.9411 0 18 0C8.05888 0 0 8.05887 0 18C0 26.9843 6.58234 34.431 15.1875 35.7812V23.2031H10.6172V18H15.1875V14.0344C15.1875 9.52312 17.8748 7.03125 21.9863 7.03125C23.9557 7.03125 26.0156 7.38281 26.0156 7.38281V11.8125H23.7458C21.5098 11.8125 20.8125 13.2 20.8125 14.6235V18H25.8047L25.0066 23.2031H20.8125V35.7812C29.4177 34.431 36 26.9843 36 18Z"
        fill="#1877F2"
      />
      <path
        d="M25.0066 23.2031L25.8047 18H20.8125V14.6235C20.8125 13.2 21.5098 11.8125 23.7458 11.8125H26.0156V7.38281C26.0156 7.38281 23.9557 7.03125 21.9863 7.03125C17.8748 7.03125 15.1875 9.52312 15.1875 14.0344V18H10.6172V23.2031H15.1875V35.7812C17.0512 36.0729 18.9488 36.0729 20.8125 35.7812V23.2031H25.0066Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4419_4048">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const googleicon = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E3E6EA" />
    <g clip-path="url(#clip0_4419_4051)">
      <path
        d="M30.9862 18.7527C30.9862 17.6465 30.8978 16.8393 30.7063 16.0022H18.2589V20.9949H25.5653C25.418 22.2357 24.6226 24.1043 22.8549 25.3599L22.8301 25.527L26.7657 28.621L27.0384 28.6486C29.5426 26.3017 30.9862 22.8486 30.9862 18.7527Z"
        fill="#4285F4"
      />
      <path
        d="M18.2589 31.9073C21.8384 31.9073 24.8434 30.7114 27.0384 28.6485L22.8549 25.3598C21.7353 26.1521 20.2328 26.7052 18.2589 26.7052C14.753 26.7052 11.7775 24.3583 10.7167 21.1145L10.5613 21.1279L6.46892 24.3418L6.41541 24.4928C8.59552 28.8876 13.0737 31.9073 18.2589 31.9073Z"
        fill="#34A853"
      />
      <path
        d="M10.7168 21.1144C10.4369 20.2773 10.2749 19.3803 10.2749 18.4536C10.2749 17.5267 10.4369 16.6298 10.7021 15.7927L10.6947 15.6144L6.55103 12.3489L6.41545 12.4143C5.51692 14.2381 5.00134 16.286 5.00134 18.4536C5.00134 20.6211 5.51692 22.669 6.41545 24.4927L10.7168 21.1144Z"
        fill="#FBBC05"
      />
      <path
        d="M18.2589 10.202C20.7484 10.202 22.4276 11.2933 23.3852 12.2052L27.1267 8.49794C24.8288 6.33042 21.8384 5 18.2589 5C13.0737 5 8.59552 8.01957 6.41541 12.4144L10.702 15.7928C11.7775 12.549 14.753 10.202 18.2589 10.202Z"
        fill="#EB4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_4419_4051">
        <rect width="26" height="27" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  btnN: {
    padding: "14px",
    // paddingLeft: "32%",
    // paddingRight: "32%",
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
    border: `1px solid ${theme.palette.domain.main}`,
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
      border: `1px solid ${theme.palette.domain.main}`,
    },
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
}));

export default function EnterEmail({ setStep }) {
  const alert = useAlert();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [socialLoading, setSocialLoading] = useState(false);
  const [googleAccessToken, setgoogleAccessToken] = useState();
  const [userData, setUserData] = useState();
  const [isNewUser, setIsNewUser] = useState(false);

  const responseGoogle = (response) => {
    setgoogleAccessToken(response?.accessToken);
  };

  useEffect(() => {
    let token = "";
    if (googleAccessToken) {
      setSocialLoading(true);
      Axios.post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
        },
        user_type: "Student",
        provider: "google-oauth2",
        access_token: googleAccessToken,
        withCredentials: true,
      },
      )
        .then((res) => {
          if (res?.data?.success) {
            token = res?.data?.data?.token;
            localStorage.setItem("gw_token", token);
            localStorage.setItem("user_type", "Student");
            setIsNewUser(res?.data?.data?.new_user);
            // setLoading(true);
            if(res?.data?.data?.new_user || !res?.data?.data?.user_details?.phone || !res?.data?.data?.user_details?.current_location){
              setUserData(res?.data?.data?.user_details);
              setOpen(true);
            }
            else
              checkUser(token);
          } else {
            alert.error("Error in google signup");
          }
          setSocialLoading(false);
        })
        .catch((err) => {
          alert.error("Error in google signup");
          setSocialLoading(false);
        });
    }
  }, [googleAccessToken]);

  const checkUser = (token) => {
    Axios.get(BackendBaseApi.PRAVESH + "api/profile/details/", {
      headers: {
        Authorization: "Token " + token,
      },
      withCredentials: true,
    })
      .then((res) => {
        if(res?.data?.success){
          if (res?.data?.is_pref_filled) history.push("/student/dashboard");
              else
                history.push({
                  pathname: "/student/complete-profile",
                  flag: 12367,
                });
              }
              setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        localStorage.clear();
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openlogin, setLoginOpen] = React.useState(false);

  const handleClickloginOpen = () => {
    setLoginOpen(true);
  };

  const handleloginClose = () => {
    setLoginOpen(false);
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography variant="h4" style={{ fontWeight: "600", lineHeight: 1.8 }}>
          Let us find your <span style={{ color: "#007BFF" }}> Dream Job </span>
          <br />
          Join Getwork
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid xs={12} md={12} style={{ display: "grid" }}>
          <Button
            color="primary"
            startIcon={<EmailIcon />}
            // onClick={() => {
            //   setStep(2);
            // }}
            onClick={handleClickOpen}
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "30px",
              display: "flex",
              fontWeight: "400",
              fontSize: "15px",
            }}
            variant="contained"
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : <Typography
            variant="body1"
            style={{
              color: "#fff",
              marginLeft: "12px",
              fontFamily: " Nunito",
            }}
          >
            <b id="candidate_signup_with_email"> Sign up with Email</b>
          </Typography>
          }
          </Button>
        </Grid>
        <Grid xs={12} md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              color="initial"
              style={{ color: "#6C757D" }}
            >
              OR
            </Typography>
          </div>
        </Grid>
        <Grid xs={12} md={12}>
          <div style={{ textAlign: "center" }}>
            <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
              // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
              // clientId="1030834411741-bhh7rnbq4qnq6t918soifunk4rbqee9k.apps.googleusercontent.com"
              isSignedIn={false}
              disabled={false}
              render={(renderProps) => (
                <Button
                  onClick={(e) => {
                    renderProps.onClick();
                  }}
                  sx={{ borderRadius: "30px", height: "40px", width: "100%" }}
                  className={classes.btnsocial}
                  variant="outlined"
                  color="primary"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    { socialLoading ?
                    <CircularProgress size={20}/> :
                    <>
                    {googleicon}
                    <Typography
                      variant="body1"
                      style={{
                        color: "#007bff",
                        marginLeft: "12px",
                        fontFamily: " Nunito",
                      }}
                    >
                        <b id="candidate_signup_with_google"> Sign up with Google</b>
                    </Typography>
                    </>
                    }
                    
                  </div>
                </Button>
              )}
              onSuccess={(response) => {
                responseGoogle(response, setSocialLoading);
              }}
              onFailure={(response) => {
                responseGoogle(response, setSocialLoading);
              }}
            />
          </div>
        </Grid>
        <Grid xs={12} md={12}>

          <Typography
            variant="body2"
            color="initial"
            style={{ color: "#6C757D" }}
          >
            Already have a GetWork account?{" "}
            <span
              style={{ color: "#007BFF", cursor: "pointer" }}
              onClick={() => {
                history.push("/login/student");
              }}
              id="candidate_signup_with_already_account"
            >
              Login
            </span>
          </Typography>
          <Typography
            variant="body2"
            color="initial"
            style={{ color: "#6C757D" }}
          >
            If you’re an employer, visit
            <a href="/signup/employer" target="_blank">
              {" "}
              <span style={{ color: "#007BFF" }} id="candidate_signup_with_employer"> this page </span>{" "}
            </a>
            instead.
          </Typography>
          <br />
        </Grid>
      </Grid>

      <RegisterGetworkDialog
        open={open}
        // setStep={setStep}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        userData={userData}
        checkUser={checkUser}
        isNewUser={isNewUser}
      />
      <LoginDialog
        open={openlogin}
        setStep={setStep}
        handleClickOpen={handleClickloginOpen}
        handleClose={handleloginClose}
      />
    </>
  );
}

import {
  Typography,
  TextField,
  Grid,
  AppBar,
  Toolbar,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import OtpInput from "react-otp-input";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import otpgif from "../../../assets/gif/otp-unscreen.gif";

import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import Countdown from "react-countdown";
import Axios from "axios";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {/* <Typography variant="h6">{children}</Typography> */}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
    },
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    height: "48px",
    fontSize: "16px",
    paddingLeft: "50px",
    paddingRight: "50px",
    backgroundColor: "#007bff",
  },
  card: {
    //paddingBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
    fontFamily: "Nunito",
  },
  btngrid: {
    display: "flex",
    //paddingLeft: "25px",
    marginTop: "50px",
    justifyContent: "flex-start",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#fff",
  },
  title: {
    flex: 1,
    textAlign: "center",
    marginRight: "50px",
    fontFamily: "Nunito",
  },
  //   upload: {
  //     "@media only screen and (max-width: 2000px)": {
  //       marginTop: "100px",
  //     },
  //     "@media only screen and (max-width: 1700px)": {
  //       marginTop: "70px",
  //     },
  //     "@media only screen and (max-width: 1300px)": {
  //       marginTop: "100px",
  //     },
  //   },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
      fontFamily: "Nunito",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);

export default function EnterOtp({
  handleCloseotp,
  otpopen,
  otpDetails,
  type,
  checkUser,
  code,
  codeError,
  setCode,
  setCodeError,
  handleClickOpen,
  fromTeamInvite = false,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [timerComplete, setTimerComplete] = React.useState(false);

  const handleChange = (code) => {
    setCode(code);

    setCodeError(null);
  };

  const resendOtp = () => {
    Axios.post(`${BackendBaseApi.PRAVESH}api/resend_otp/`, {
      email: otpDetails?.email,
      user_type: type.toLowerCase(),
    })
      .then((res) => {
        if (res.data.success) {
          setTimerComplete(false);
        } else {
          if (res.data.data.code === 2) {
            alert.error(res.data.data.code_text);
          } else alert.error("Some Error Occurred");
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const validateDataOtp = () => {
    if (!code) {
      setCodeError("Please Enter Your Verification Code");
      return;
    }
    if (code && code?.length < 4) {
      setCodeError("Please Enter Your Verification Code Correctly");
      return;
    }

    setLoadingSubmit(true);
    Axios.post(`${BackendBaseApi.PRAVESH}api/validate_otp/`, {
      email: otpDetails?.email,
      user_type: type.toLowerCase(),
      otp: code,
    },
    { withCredentials: true }
    )
      .then((res) => {
        if (res.data.success) {
          checkUser(res.data.data.token);
          handleCloseotp();
        } else {
          setCodeError(res.data.error);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  return (
    <div>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        // fullWidth={fullWidth}
        fullScreen={fullScreen}
        // maxWidth={maxWidth}
        open={otpopen}
        onClose={handleCloseotp}
        aria-labelledby="responsive-dialog-title"
      >
        <Hidden smUp>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseotp}
                aria-label="close"
              >
                <ArrowBackIcon style={{ color: "#000" }} />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Verify your OTP
              </Typography>
            </Toolbar>
          </AppBar>
        </Hidden>

        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseotp}
        ></DialogTitle>
        <div style={{ padding: "20px" }}>
          <Hidden smDown>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                fontFamily: "Nunito",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Verify your OTP
            </Typography>
          </Hidden>
          <Hidden smUp>
            <div style={{ textAlign: "center" }}>
              <img
                src={otpgif}
                height="140px"
                alt="getwork-logo"
                style={{ marginBottom: "50px" }}
              />
            </div>
          </Hidden>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Nunito",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "bold",
                padding: 12,
              }}
            >
              <span style={{ color: "#707070" }}>Sent to</span>{" "}
              <span style={{ color: "#007bff" }}> {otpDetails?.email} </span>
            </Typography>
            {!fromTeamInvite && (
              <EditIcon
                onClick={() => {
                  handleClickOpen();
                  handleCloseotp();
                }}
                style={{
                  color: "#007bff",
                  fontSize: "18px",
                  cursor: "pointer",
                  marginLeft: "-7px",
                }}
              />
            )}
          </div>
          <div className={classes.card}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      textAlign: "center",
                      fontSize: "12px",
                      marginBottom: "20px",
                      fontWeight: "bold",
                      padding: 6,
                    }}
                  >
                    Enter OTP
                  </Typography>
                  <OtpInput
                    value={code}
                    onChange={(e) => handleChange(e)}
                    numInputs={4}
                    separator={<span style={{ width: "20px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      border: ".5px solid #a5a5a5",
                      borderRadius: "8px",
                      width: "54px",
                      height: "54px",
                      fontSize: "20px",
                      fontFamily: "Nunito",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue",
                    }}
                    focusStyle={{
                      border: "1px solid #CFD3DB",
                      outline: "none",
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontSize: "12px",
                      fontWeight: "bold",
                      padding: 12,
                    }}
                  >
                    <span style={{ color: "#ed1111" }}> {codeError}</span>{" "}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      textAlign: "center",
                      fontSize: "12px",
                      marginTop: "20px",
                      fontWeight: "bold",
                      padding: 12,
                    }}
                  >
                    <span
                      style={
                        timerComplete
                          ? { color: "#007bff", cursor: "pointer" }
                          : { color: "#707070" }
                      }
                      onClick={() => {
                        if (timerComplete) resendOtp();
                      }}
                    >
                      Resend OTP
                    </span>
                    {!timerComplete && (
                      <span style={{ color: "#707070" }}>{""}&nbsp; in </span>
                    )}
                    <span style={{ color: "#007bff" }}>
                      {!timerComplete && (
                        <Countdown
                          date={Date.now() + 30000}
                          onComplete={() => setTimerComplete(true)}
                          renderer={(props) => (
                            <div>
                              <b>{props.seconds + " sec"}</b>
                            </div>
                          )}
                        />
                      )}
                    </span>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} style={{ display: "grid" }}>
                {" "}
                <Button
                  variant="contained"
                  className={classes.button}
                  color="primary"
                  onClick={() => {
                    validateDataOtp();
                  }}
                  //color="primary"
                >
                  {loadingSubmit ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    " Verify"
                  )}
                </Button>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    textAlign: "center",
                    fontSize: "12px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    fontWeight: "bold",
                    padding: 12,
                  }}
                >
                  Check spam folders if email is not in the inbox.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

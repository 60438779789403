import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container"
import facebook from "../../../assets/svg/social_media/facebook.svg"
import twi from "../../../assets/svg/social_media/twi.svg"
import linkedin from "../../../assets/svg/social_media/linkedin.svg"
import insta from "../../../assets/svg/social_media/insta.png"
import youtube from "../../../assets/svg/social_media/youtube.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export default function Footer() {
    
  const classes = useStyles();

  return (
    <>
    <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://www.getwork.org/">
      GetWork
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
  <div>
  <Container maxWidth="sm" className={classes.container}>
  <Grid container spacing={1}>
  <Grid item xs={2} sm={4}>
        
        </Grid>
        <Grid item  xs={8} sm={4} spacing={1}>
        <Link href="https://www.facebook.com/getwork2018">
        <img src={facebook} height="25px" style={{margin:"7px"}}/>
        </Link>
        <Link href="https://www.instagram.com/getworknow/">
        <img src={insta} height="22px" style={{margin:"7px"}}/>
        </Link>
        <Link href="https://twitter.com/getwork_now">
        <img src={twi} height="23px" style={{margin:"7px"}}/>
        </Link>
        <Link href="https://www.linkedin.com/company/getworknow/">
        <img src={linkedin} height="25px" style={{margin:"7px"}}/>
        </Link>
        <Link href="https://www.youtube.com/channel/UCwp8aQ6Wp23ZHrw9-vWmV8w">
        <img src={youtube} height="25px" />
        </Link>
        </Grid>
      
        <Grid item xs={2} sm={4} >
        
        </Grid>
      </Grid>
      </Container>
  </div>
     </>
  );
}


import {
  Box,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import bullet from "../../../assets/bullets.png";
import compensation from "../../../assets/compensation.png";
import duration from "../../../assets/duration.png";
import employment from "../../../assets/employment.png";
import internship_duration from "../../../assets/internship_duration.png";
import job from "../../../assets/job.png";
import location from "../../../assets/location.png";
import ppo from "../../../assets/ppo.png";
import service_bond from "../../../assets/service_bond.png";
import generateCsv, {
  generateCsvDegreeName,
  generateCsvNew,
} from "../../../common/generateCsv/generateCsv";
import BootstrapTooltip, {
  BootstrapTooltipNew,
} from "../../../common/Tooltip/Tooltip";
import convertDate from "../../../utils/dateConvertor";
import kFormatter, { LFormatter } from "../../../utils/ZeroToK";
import CommonChip from "../../CommonChip/CommonChip";
import CommonButton from "../CommonButton/CommonButton";
import CreditDialog from "../CreditDialog/CreditDialog";
const PreviewDialog = (props) => {
  const {
    state,
    setState,
    open,
    setOpen,
    CreateJob,
    confirmationDialog,
    setConfirmationDialog,
    creditInfo,
    progress,
    basicsCheck,
    detailsCheck,
    preferencesCheck,
    selectionCheck,
    hiringCheck,
    courseArray,
    setCourseArray,
    locationUrl,
    validateData,
    salaryRange,
    draftJobLoader,
    postJobLoader,
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const [qualificationData, setQualificationData] = useState([]);
  const [preferencesText, setPreferencesText] = useState("");

  const classes = useStyles();
  var ctc_detail;
  var locations;
  var date;
  var internship_date;
  var ctc_min;
  var ctc_max;
  var bond;
  var stipend;
  if (state.Details.salary_type === "PAID") {
    if (state.Details.ctc_min > 99999)
      ctc_min = LFormatter(state.Details.ctc_min);
    else ctc_min = kFormatter(state.Details.ctc_min);

    if (state.Details.ctc_max > 99999)
      ctc_max = LFormatter(state.Details.ctc_max);
    else ctc_max = kFormatter(state.Details.ctc_max);
    ctc_detail =
      ctc_min + " - " + ctc_max + " " + state.Details.salary_payment_type;
  } else {
    ctc_detail = "Unpaid";
  }

  if (state.Basics.stipend > 99999) stipend = LFormatter(state.Basics.stipend);
  else stipend = kFormatter(state.Basics.stipend);

  if (parseInt(state.Basics.stipend) === 0) stipend = "No stipend";

  if (state.Details.job_location.length > 0)
    locations = generateCsv(state.Details.job_location, "city");
  else if (state.Details.work_from_home === true) locations = "Work from Home";
  else locations = "PAN India";

  if (state.Colleges.apply_start_date && state.Colleges.apply_end_date) {
    date = convertDate(
      state.Colleges.apply_start_date,
      state.Colleges.apply_end_date
    );
  } else {
    date = "NA";
  }
  if (parseInt(state.Basics.job_type) === 1) {
    if (state.Basics.job_duration_start && state.Basics.job_duration_end) {
      internship_date = convertDate(
        state.Basics.job_duration_start,
        state.Basics.job_duration_end
      );
    } else {
      internship_date = "NA";
    }
  }

  if (state.Details.is_service_bond === true)
    bond = state.Details.service_bond + " months";
  else bond = "No Bond";

  const previewData = [
    {
      title: "Compensation",
      subtitle: ctc_detail,
      icon: compensation,
    },
    { title: "Location", subtitle: locations, icon: location },
    {
      title: "Employment Type",
      subtitle: state.extra1.emp_type_name,
      icon: employment,
    },
    {
      title: "Apply Date",
      subtitle: date,
      icon: duration,
    },
    {
      title: "Job Type",
      subtitle: state.extra1.job_type_name,
      icon: job,
    },
    {
      title: "Service Bond",
      subtitle: bond,
      icon: service_bond,
    },
  ];

  var internshipData;
  if (localStorage.getItem("user_type") === "Company") {
    internshipData = [
      {
        title: "PPO",
        subtitle: state.Basics.ppo === true ? "Yes" : "No",
        icon: ppo,
      },
      {
        title: "Internship Duration",
        subtitle: internship_date,
        icon: internship_duration,
      },
      {
        title: "Stipend",
        subtitle: stipend,
        icon: compensation,
      },
    ];
  } else {
    internshipData = [
      {
        title: "PPO",
        subtitle: state.Basics.ppo === true ? "Yes" : "No",
        icon: ppo,
      },
      // {
      //   title: "Internship Duration",
      //   subtitle: internship_date,
      //   icon: internship_duration,
      // },
      {
        title: "Stipend",
        subtitle: stipend,
        icon: compensation,
      },
    ];
  }

  useEffect(() => {
    const temp = {};

    var arr = [...state.Preferences.eligibility_criteria.courses];
    arr.forEach((item) => {
      if (temp[item.degree_name] === undefined) {
        temp[item.degree_name] = [{ ...item }];
      } else {
        temp[item.degree_name].push({ ...item });
      }
    });
    setQualificationData(temp);
  }, [
    state.Preferences.eligibility_criteria.courses.length,
    state.Preferences.eligibility_criteria.degrees.length,
  ]);
  useEffect(() => {
    if (state.Colleges.hiring_type === "ON CAMPUS")
      setPreferencesText("Colleges");
    else setPreferencesText("Courses");
  }, [state.Colleges.hiring_type]);
  return (
    <Dialog
      //open
      PaperProps={{
        style: {
          borderRadius: 30,
        },
      }}
      open={open}
      onClose={(e) => {
        setOpen(false);
      }}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <p className={classes.Heading}>{state.Basics.job_title}</p>
            <p className={classes.Heading}>
              {localStorage.getItem("user_type") === "Company"
                ? JSON.parse(localStorage.getItem("company")).company_name
                : state.Basics.company_details}
            </p>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {state.Preferences.eligibility_criteria.skills.map((item) => (
              <Chip
                color="primary"
                className={classes.root1}
                label={
                  <span>
                    <b>{item.skill_name}</b>
                  </span>
                }
                style={{ marginRight: "1%", marginBottom: "1%" }}
              />
            ))}
          </Grid>
        </Grid>
        <Divider />
        <Grid container style={{ marginTop: 20 }}>
          {previewData.map((item) => (
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={2}>
                  <img src={item.icon} alt="" className={classes.imageStyle} />
                </Grid>
                <Grid item xs={10}>
                  <p
                    style={{ marginBottom: 0 }}
                    className={classes.upperHeading}
                  >
                    {item.title}
                  </p>
                  <p className={classes.lowerHeading}>{item.subtitle}</p>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {state.Basics.job_type === 1 && (
          <>
            <p className={classes.titleHeading} style={{ marginTop: 25 }}>
              Internship Details
            </p>
            <Divider />
            <Grid container style={{ marginTop: 20 }}>
              {internshipData.map((item) => (
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={2}>
                      <img
                        src={item.icon}
                        alt=""
                        className={classes.imageStyle}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <p
                        style={{ marginBottom: 0 }}
                        className={classes.upperHeading}
                      >
                        {item.title}
                      </p>
                      <p className={classes.lowerHeading}>{item.subtitle}</p>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <p className={classes.titleHeading} style={{ marginTop: 30 }}>
          Job Description
        </p>
        <Divider />

        <p className={classes.titleInteriors} style={{ marginTop: 9 }}>
          {ReactHtmlParser(state.Details.job_description.toString("html"))}
        </p>

        <p className={classes.titleHeading} style={{ marginTop: 30 }}>
          Preferences
        </p>
        <Divider />
        <p className={classes.list} style={{ marginTop: 9 }}>
          <img src={bullet} alt="" className={classes.listIcon} />
          <span className={classes.listItemHeading}>
            {" "}
            Work Experience:&nbsp;
          </span>
          {generateCsvNew(state.Preferences.eligibility_criteria.experience)}
        </p>
        {state.Preferences.eligibility_criteria.skills.length > 0 && (
          <p className={classes.listBelow}>
            <img src={bullet} alt="" className={classes.listIcon} />
            <span className={classes.listItemHeading}> Key Skills:&nbsp;</span>
            {generateCsv(
              state.Preferences.eligibility_criteria.skills,
              "skill_name"
            )}
          </p>
        )}
        {state.Preferences.eligibility_criteria.graduation_years.length > 0 && (
          <p className={classes.listBelow}>
            <img src={bullet} alt="" className={classes.listIcon} />
            <span className={classes.listItemHeading}>
              {" "}
              Eligible Graduation Years:&nbsp;
            </span>
            {generateCsvNew(
              state.Preferences.eligibility_criteria.graduation_years
            )}
          </p>
        )}
        {state.Preferences.eligibility_criteria.percentage &&
        state.Preferences.eligibility_criteria.percentage !== null ? (
          <p className={classes.listBelow}>
            <img src={bullet} alt="" className={classes.listIcon} />
            <span className={classes.listItemHeading}> Percentage:&nbsp; </span>
            {state.Preferences.eligibility_criteria.percentage}
          </p>
        ) : null}

        {state.Basics.posted_on_job_lake ? (
          <p className={classes.listBelow}>
            <img src={bullet} alt="" className={classes.listIcon} />
            <span className={classes.listItemHeading}>
              {" "}
              Job Posted on Job Lake&nbsp;{" "}
            </span>
          </p>
        ) : null}

        {localStorage.getItem("user_type") === "Company" &&
          (state.Colleges.college.length > 0 ||
            state.Preferences.eligibility_criteria.courses.length > 0) && (
            <Box className={classes.selectedPreferences}>
              <Grid container>
                <Grid item xs={3}>
                  <p className={classes.formLabel}>
                    Selected {" " + preferencesText}
                  </p>
                </Grid>
              </Grid>
              {state.Colleges.hiring_type === "ON CAMPUS" ? (
                state.Colleges.college.map((clg) => (
                  <>
                    <BootstrapTooltip
                      title={generateCsvDegreeName(clg.preferences)} // placement="top-start"
                      className=""
                    >
                      <CommonChip
                        label={clg.college_name}
                        style={{ marginRight: "1%", marginBottom: "1%" }}
                      />
                    </BootstrapTooltip>
                  </>
                ))
              ) : (
                <>
                  {Object.keys(qualificationData).map((item) => (
                    <>
                      <BootstrapTooltipNew
                        title={generateCsv(qualificationData[item], "name")}
                        className=""
                      >
                        <CommonChip
                          label={item}
                          style={{ marginRight: "1%", marginBottom: "1%" }}
                        />
                      </BootstrapTooltipNew>
                    </>
                  ))}
                </>
              )}
            </Box>
          )}

        {localStorage.getItem("user_type") === "College" &&
          (state.Colleges.college.length > 0 ||
            state.Preferences.eligibility_criteria.courses.length > 0) && (
            <Box className={classes.selectedPreferences}>
              <Grid container>
                <Grid item xs={3}>
                  <p className={classes.formLabel}>Selected Courses</p>
                </Grid>
              </Grid>

              <>
                {courseArray.map((item) => (
                  <>
                    <BootstrapTooltipNew
                      title={generateCsv(
                        item.specialization,
                        "specialization_name"
                      )}
                      className=""
                    >
                      <CommonChip
                        label={
                          <span style={{ fontSize: 12 }}>
                            {item.degree_name}
                          </span>
                        }
                        style={{ marginRight: "1%", marginBottom: "1%" }}
                      />
                    </BootstrapTooltipNew>
                  </>
                ))}
              </>
            </Box>
          )}

        <p className={classes.titleHeading} style={{ marginTop: 30 }}>
          Documents Required
        </p>
        <Divider />
        {state.Details.documents.map((item, index) =>
          index === 0 ? (
            <p className={classes.list} style={{ marginTop: 9 }}>
              <img src={bullet} alt="" className={classes.listIcon} />
              <span className={classes.listItemHeading}>{item}</span>
            </p>
          ) : (
            <p className={classes.listBelow}>
              <img src={bullet} alt="" className={classes.listIcon} />
              <span className={classes.listItemHeading}>{item}</span>
            </p>
          )
        )}

        <p className={classes.titleHeading} style={{ marginTop: 30 }}>
          Selection Procedure
        </p>
        <Divider />
        {state.Basics.rounds.map((item, index) =>
          index === 0 ? (
            <p className={classes.list} style={{ marginTop: 9 }}>
              <img src={bullet} alt="" className={classes.listIcon} />
              <span className={classes.listItemHeading}>
                {item.r_name}
                {item.is_interviewed && " (Interview Round)"}
              </span>
            </p>
          ) : (
            <p className={classes.listBelow}>
              <img src={bullet} alt="" className={classes.listIcon} />
              <span className={classes.listItemHeading}>{item.r_name}</span>
            </p>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            // marginTop: "30px",
          }}
        >
          {(localStorage.getItem("user_type") === "College" ||
            localStorage.getItem("user_type") === "Company") && (
            <CommonButton
              variant="contained"
              /*  onClick={() => CreateJob("DRAFT")} */
              disabled={postJobLoader ? true : false}
              onClick={() => {
                if (validateData()) {
                  if (salaryRange(state) === true)
                    alert.error("Please Enter the right CTC Package");
                  else CreateJob("DRAFT");
                }
              }}
              /*    disabled={
                basicsCheck &&
                detailsCheck &&
                // preferencesCheck &&
                selectionCheck &&
                hiringCheck &&
                locationUrl.flag !== 1976
                  ? false
                  : true
              } */
            >
              <span className={classes.labelSize}>
                {" "}
                {draftJobLoader ? (
                  <CircularProgress color="white" size={17} />
                ) : (
                  "Save as Draft"
                )}
              </span>
            </CommonButton>
          )}
          &nbsp;
          <CommonButton
            variant="contained"
            disabled={postJobLoader ? true : false}
            onClick={() => {
              if (validateData()) {
                if (salaryRange(state) === true) {
                  alert.error("Please Enter the right CTC Package");
                  return;
                }
                CreateJob("OPEN");
              }
            }}
            /* onClick={() => setConfirmationDialog(true)} */
            // onClick={() => {CreateJob(localStorage.getItem("user_type") === "Company" || localStorage.getItem("user_type") === "College" ? "OPEN" : "PENDING")}}
            /*   disabled={
              basicsCheck &&
              detailsCheck &&
              // preferencesCheck &&
              selectionCheck &&
              hiringCheck
                ? false
                : true
            } */
          >
            <span className={classes.labelSize}>
              {" "}
              {postJobLoader ? (
                <CircularProgress color="white" size={17} />
              ) : (
                "Save as Draft"
              )}
            </span>
          </CommonButton>
        </Box>
      </DialogActions>

      <CreditDialog
        open={confirmationDialog}
        setOpen={setConfirmationDialog}
        poster={CreateJob}
        posterArg={
          localStorage.getItem("user_type") === "Company" ||
          localStorage.getItem("user_type") === "College"
            ? "OPEN"
            : "PENDING"
        }
        creditsRequired={
          creditInfo?.user_service?.find(
            (item) => item.service_type === "POST_JOB"
          )?.credits
        }
        serviceType={
          creditInfo?.user_service?.find(
            (item) => item.service_type === "POST_JOB"
          )?.service_type
        }
        progress={progress}
      />
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    Heading: {
      fontSize: 20,
      fontWeight: "bold",
    },
    formLabel: {
      fontSize: "13px",
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    selectedPreferences: {
      padding: "20px",
      borderRadius: 30,
      WebkitBackdropFilter: "blur(30px)",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "1px solid rgba(152, 152, 152, 0.27)",
      marginBottom: 30,
    },
    listItemHeading: {
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    preferenceButton: {
      borderRadius: 30,
      background: "#3282C4",
      color: "white",
      "&:hover": {
        border: "2px solid #3282C4",
        background: "white",
        color: "#3282C4",
      },
    },
    labelSize: {
      fontSize: "12px",
    },
    imageStyle: {
      //   marginRight: 10,
      marginTop: 3,
      height: 40,
    },
    upperHeading: {
      fontSize: 14,
      fontWeight: "bold",
    },
    lowerHeading: {
      fontSize: 13,
      fontWeight: "bold",
      color: "grey",
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
    },
    titleHeading: {
      fontSize: 17,
      fontWeight: "bold",
    },
    titleInteriors: {
      fontSize: 14,
      color: "grey",
      fontWeight: "bold",
    },
    list: {
      fontSize: 14,
      color: "grey",
      fontWeight: "bold",
    },
    listBelow: {
      fontSize: 14,
      color: "grey",
      fontWeight: "bold",
      marginTop: "-11px",
    },
    listIcon: {
      marginBottom: 2,
      marginRight: 8,
      height: "20px",
    },
    "@media only screen and (max-width: 1000px)": {
      FormBox: {
        width: "100%",
      },
      listIcon: {
        marginBottom: 2,
        marginRight: 8,
        height: "18px",
      },
      labelSize: {
        fontSize: "12px",
      },
      Heading: {
        fontSize: 17,
        fontWeight: "bold",
      },
      upperHeading: {
        fontSize: 13,
        fontWeight: "bold",
      },
      titleInteriors: {
        fontSize: 12,
        color: "grey",
        fontWeight: "bold",
      },
      listBelow: {
        fontSize: 13,
        color: "grey",
        fontWeight: "bold",
        marginTop: "-11px",
      },
      list: {
        fontSize: 13,
        color: "grey",
        fontWeight: "bold",
      },
      lowerHeading: {
        fontSize: 12,
        fontWeight: "bold",
        color: "grey",
      },
      imageStyle: {
        //   marginRight: 10,
        marginTop: 2,
        height: 30,
      },
      titleHeading: {
        fontSize: 15,
        fontWeight: "bold",
      },
    },
  })
);

export default PreviewDialog;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import upload from "../../../assets/images/assignmentUpload.png";

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { handleClose, handleClickOpen, open, setOpen, assignment } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Assignments
        </DialogTitle>
        <DialogContent dividers>
          {assignment && (
            <TableContainer>
              <Table stickyHeader={true}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontSize: 13 }}>
                      S. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ fontSize: 13 }}>
                      Assignment Title
                    </StyledTableCell>
                    <StyledTableCell style={{ fontSize: 13 }}>
                      File
                    </StyledTableCell>
                    <StyledTableCell style={{ fontSize: 13 }}>
                      Description
                    </StyledTableCell>

                    <StyledTableCell style={{ fontSize: 13 }}>
                      Submission Date{" "}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {assignment.length > 0 ? (
                    assignment.map((item, index) => (
                      <TableRow>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>
                          {item.assignment_title}
                        </StyledTableCell>

                        <StyledTableCell>
                          <a
                            href={item.file}
                            style={{
                              textDecoration: "none",
                            }}
                            download={
                              item.file
                                ? item.file.split("/")[6].split("?")[0]
                                : null
                            }
                            target="blank"
                          >
                            {" "}
                            {item.file
                              ? item.file.split("/")[6].split("?")[0]
                              : "No File Atttached"}
                            &nbsp; {item.file ? <img src={upload} /> : null}
                          </a>
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.text ? item.text : "NA"}
                        </StyledTableCell>

                        <StyledTableCell>
                          {moment(item.submission_date).format("DD MMM, YYYY")}
                        </StyledTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <CircularProgress size={25} />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
const StyledTableCell = withStyles(() => ({
  // head: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  body: {
    fontSize: 13,
    padding: "7px 16px 5px 16px",
  },
  "@media only screen and (max-width: 900px)": {
    body: {
      fontSize: 12,
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    fontSize: 13,
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

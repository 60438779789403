import { BackendBaseApi } from "../../constants/constants";
import { ThunkAction } from "redux-thunk";
import Axios, { AxiosPromise } from "axios";
import { TReduxStore } from "../..";
import { CityListProps } from "./reducer";
export const GET_INDUSTRY = "GET_INDUSTRY";

export const GET_JOB_SEGMENT = "GET_JOB_SEGMENT";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const UPDATE_CTC_EXPLORE = "UPDATE_CTC_EXPLORE";
export const UPDATE_LOCATION_EXPLORE = "UPDATE_LOCATION_EXPLORE";
export const UPDATE_JOB_SEGMENT = "UPDATE_JOB_SEGMENT";
export const FILTER_COUNT = "FILTER_COUNT";
export const FILTER_COUNT_COLLEGE = "FILTER_COUNT_COLLEGE";
export const UPDATE_INDUSTRY = "UPDATE_INDUSTRY";
export const UPDATE_LOCATION_EXPLORE_COLLEGE =
  "UPDATE_LOCATION_EXPLORE_COLLEGE";
export const UPDATE_SIZE = "UPDATE_SIZE";
export const GET_CITY_ARRAY = "GET_CITY_ARRAY";
export interface JobSegmentProps {
  job_segment_name: string;
  job_segment_id: number;
}

export const getJobSegment = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/company/job_segment/",
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    let temp: any = {};
    res.data.data.forEach((data: JobSegmentProps) => {
      temp[data.job_segment_name] = data;
    });
    dispatch({
      type: GET_JOB_SEGMENT,
      payload: temp,
    });
    return res;
  };
};

export interface TAction {
  type: String;
  data?: any;
  additionalDispatchData?: any;
  payload?: any;
}

export interface LocationProps {
  city: string;
  city_id: number;
}

export interface IndustryProps {
  industry_name: string;
  industry_id: number;
}

export const getIndustries = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/college/industry/",
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    let temp: any = {};
    res?.data?.data?.forEach((data: IndustryProps) => {
      temp[data?.industry_name] = data;
    });
    dispatch({
      type: GET_INDUSTRY,
      payload: temp,
    });
    return res;
  };
};

export const getAllLocations = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/location/city/"
    ).catch((error) => {
      throw error;
    });

    let temp: any = {};

    res.data.forEach((data: LocationProps) => {
      temp[data.city] = data.city_id;
    });
    dispatch({
      type: GET_CITY_ARRAY,
      payload: res.data,
    });

    dispatch({
      type: GET_LOCATIONS,
      payload: temp,
    });
    return res;
  };
};

// export const getCities = (): ThunkAction<
//   AxiosPromise,
//   TReduxStore,
//   {},
//   TAction
// > => {
//   return async (dispatch) => {
//     const res = await Axios.get(
//       `${BackendBaseApi.PRAVESH}api/location/city/`
//     ).catch((err) => {
//       throw err;
//     });
//     dispatch({
//       type: GET_CITY_ARRAY,
//       payload: res.data,
//     });
//     return res;
//   };
// };

export const changeState = (
  dispatcher: string,
  data: any
): ThunkAction<any, TReduxStore, {}, TAction> => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
    return;
  };
};

import React, { Fragment } from "react";
import {
  Box,
  makeStyles,
  createStyles,
  Container,
  AppBar,
  Toolbar,
  Button,
} from "@material-ui/core";
import Logo from "../../../../assets/images/sidepanel/logo.png";
import CompanyNavbar_C from "../UI/CompanyNavbar_c";

const data = [
  {
    title: "",

    detail: [
      {
        text: `<p>Welcome to GetWork, India’s first career development platform that connects students to the right employers to democratize the job search process, in college and universities. It is our utmost priority to structure our platform while adhering to privacy regulations to respect the data and rights of our student, College and company stakeholders. We strive to provide a safe and secure experience to all our users and have built this privacy statement on the following key principles: ·</p>`,
      },
      {
        subtitle:
          "Students are in control of all the information they make public",
        text: `<p>We give students the option to make their profile public to employers which includes their career preferences and education related details. Employers will not be able to view any student data uploaded on to GetWork unless the student chooses to apply for a job or make their profile public.</p>`,
      },
      {
        subtitle:
          "Universities have complete control of the student data shared on GetWork",
        text: `<p>We are determined to secure student’s privacy and are inclined to work with Colleges & Universities to optimize their training & placement team’s values without compromising student’s personal data.</p>`,
      },
      {
        subtitle: "‍GetWork will not resell personal data to third parties",
        text: `<p>GetWork has never and will never sell student data to external vendors! Our business model is built to offer enhanced career and recruitment resources to all of our users respectively. Your email and personal information will not be sold. To understand how we collect and use the data you provide on GetWork please refer to our Privacy Policy. If you reside in a country outside of India, the information we collect on our platform will be transferred and processed in India or elsewhere. By adhering to GetWork Services, you give your consent to any transfer and process your data in accordance to this policy. For any queries please contact us at team@getwork.org.</p>`,
      },
    ],
  },
  {
    title: "‍Welcome to GetWork!",
    detail: [
      {
        text: `<p>India’s first career development platform that connects students to the right employers to democratize the job search process, in college and universities. This Privacy Policy applies to your use of the GetWork Service (or ‘the Service’) including the app.getwork.org website application, getwork.org website; as well as your relationship with Veerwal GetWork Services Private Limited (“GetWork”). This policy is part of GetWork’s Terms & Conditions. By using our Service, you agree to both our Terms & Conditions and this Privacy Policy; if you do not agree, please do not use the Service. This Privacy Policy may change over time. Minor changes that do not change your rights will be reflected and modified in the Policy on our website. You will be notified via email or through the GetWork service if we make alterations to this Policy that materially changes your rights. When you use the GetWork Service after a modification is posted, you are telling us that you accept the modified terms.</p>`,
      },
    ],
  },
  {
    title: "‍What information we collect",
    detail: [
      {
        text: `<p>We collect both personal and non-personal data. Personal data includes personal identifiers like your name, email address, phone number, and IP address or UDI. We may also collect demographical, location, educational and commercial information as detailed below. To use GetWork, all of users will have to create an account whether you’re a student seeking jobs or career advice, an employer looking to attract fresh talent or an educational institution/placement team. </p>`,
      },
      {
        subtitle: "Student data:",
        text: `<p>We collect personal data about you when you create a Student Account, update your profile, complete our surveys and questionnaires on our website or otherwise use our Site, from our Partner Colleges, and from other third-party data sources.</p>`,
      },
      {
        subtitle: "From College Partners:",
        text: `<p>Our College Partners give us their student information so that we can provide them with services to manage their placement office. Each College Partner chooses to share information such as name, email address, course, specialization, and secondary and tertiary grades.</p>`,
      },
      {
        subtitle: "‍From you, your account and your profile:",
        text: `<p>You can choose to share other data with us by uploading documents or updating your profile. Information such as an updated email address, phone number, work experience, resume and transcript, and your skills, interests and activities will be covered by this Privacy Policy and our Terms & Conditions.</p>`,
      },
      {
        subtitle: "From employer testimonials",
        text: `<p>We request reviews and testimonials from our partner employers where you may choose to share any personal information. GetWork collects this information even if the review is not published or made public.</p>`,
      },
      {
        subtitle: "From third parties",
        text: `<p>We may receive additional information about you from other colleges, career services, or from other sources.</p>`,
      },
      {
        subtitle: "‍Usage and log data",
        text: `<p>When you visit the Site, we log and store your IP Address and technical information about your visit like your browser type and how you progressed through the Site, where you abandoned it, etc. (“Usage Data”). We can use your IP address to determine your general location.</p>`,
      },
      {
        subtitle: "Mobile data",
        text: `<p>When you use the GetWork mobile app, we collect analytic information about your device, such as IP address, Android/OS version, and clickstream.</p>`,
      },
      {
        subtitle: "‍Precise location data",
        text: `<p>For specific features on our platform we may allow users to opt-in to share their precise GPS location to indicatepresence at events such as job fairs. We will not share precise location datawith third parties, or misuse this information for marketing purposes.</p>`,
      },
      {
        subtitle: "‍Employer data",
        text: `<p>When Employers create a GetWork Employer Account, we request information, including your company name, work email address, personal and work number, company location to provide a point of contact for Colleges and administrative staff and which is also available on your public profile.</p>`,
      },
    ],
  },
  {
    title: "How we use your personal data",
    detail: [
      {
        text: `<p>We use, process and store your Personal Data to provide the GetWork Service and to optimize opportunities to connect you with potential employers. This includes: ·<br /><br />Providing services such as allowing you to make appointments with placement team coordinators or sign up for job or internship fairs and other events.<br /><br />Delivering job opportunities and updates from potential employers by email in accordance with your account settings and communication preferences.<br /><br />Allowing you to toggle your profile setting between public and private so potential employers, or others, can find you--or not.<br /><br />Allowing you to contact employers and employers to contact you.<br /><br />Allowing you to give reviews and ratings on employers and employment experiences.<br /><br />Personalizing the Service. For example, we may recommend employers and job opportunities to you based on the information in your resume, your profile, and/or the demographic information you provide. If set on public we may suggest your profile to employers based on search criteria.<br /><br />Contacting you about additional GetWork or Partner services you might be interested in, unless you opt-out.<br /><br />Researching new ways to improve the GetWork Service.</p>`,
      },
    ],
  },
  {
    title: "When we may share your personal data",
    detail: [
      {
        text: `<p>We will only share your personal data with third parties under the following circumstances:<br /><br />
                With your consent. When you choose to make your public profile so that it may be viewed by potential employers or if you decide to apply for a job –we will share your profile information, resume, transcripts and other certificates with the respective employer. You may choose to make your profile public to ease the networking process and build on your personal brand. Whenever you post reviews about an event or employer or any other content publicly, you will be publishing your name and college name alongside the content.<br /><br />
                With vendors that are engaged in a contract with us to provide services, such as cloud service providers and other system maintenance, order fulfillment, email management and credit card processing. These companies are bounded by a contract to safeguard any personal data they receive from us.<br /><br />
                Under very dire circumstances we may disclose information only in order to comply with a law, regulation, valid legal process (e.g., subpoenas or warrants served), or regulatory request, to enforce or apply the Terms & Conditions, to protect the integrity of the GetWork Service, and/or to protect the rights, property, or safety of GetWork, its employees, and its stakeholders. If such an event shall ever occur, we will notify you in advance by email unless we are prohibited to do so by law.<br /><br />
                In the event of a sale, merger, bankruptcy, or reorganization of our company, we may share/transfer your personal data. Should a different company begin receiving and processing your data, you will by duly notified and the clauses of this Privacy Policy will apply to your data as transferred to the new entity.</p>`,
      },
    ],
  },
  {
    title: "‍When we use and share non-personal data",
    detail: [
      {
        text: `<p>Non-personal data refers to the information that cannot be linked back to you. This data is primarily used for us to generate reports, market and industry trends to help our Partners and the general public understand the development of the job market and all the factors that impact it. These reports include and are not limited to analytical reports, statistics, metrics and guides. For example, to understand:<br /><br />
                The percentage of students from certain colleges and courses that work in a specific geographical region.
Whether a certain specialization or extra-curricular activity (projects, internships, etc.) correlates with a particular employer’s hiring patterns.<br /><br />
How employers compare to one another in the number of applicants they receive from different colleges.</p>`,
      },
    ],
  },
  {
    title: "Cookies",
    detail: [
      {
        text: `<p>As a Site visitor, we place a small piece of software referred to as a “session cookie” on your computer. If you use the Services and create an account, we also use these cookies to recognize when you return to our site and quicken the login process for you. You can remove this and other cookies through your browser preferences menu. However it is important to note that you won’t be able to log into our service if your cookies are disabled. If you visit from a mobile device, we may record your device identifier or other information about where your visit originated. Our email communications contain trackers or similar technology which allows us to know when an email has been opened. This functionality sends the resulting records back to our Service, which will be analyzed and process in association with other information you have shared.</p>`,
      },
    ],
  },
  {
    title: "‍How to opt-out of email communications",
    detail: [
      {
        text: `<p>To stop receiving notifications or promotions, please click the unsubscribe link found at the bottom of each email or update your account preferences.</p>`,
      },
    ],
  },
  {
    title: "‍Storage, security and how to remove your information",
    detail: [
      {
        text: `<p>Our data protection and storage is up to industry standards which includes data encryption whenever you share any information with us. We try to take reasonable precautions against possible security breaches on our web app and website but we cannot guarantee total prevention of hacking, data loss, or unauthorized access. Should such an event occur, we will investigate the situation thoroughly and take reasonable steps to notify affected individuals in accordance to the laws and regulations.</p>`,
      },
    ],
  },
  {
    title: "‍De-activating your account or deleting your personal data",
    detail: [
      {
        text: `<p>Should you wish to discontinue our services, you will be able to deactivate your account or toggle the privacy setting of your profile. You may also request GetWork to delete all your data from our servers but we are obligated as a service provider to your college to retain specific details about you. This means that if they re-send us your data to process on their behalf, we will retain it unless they request we delete it. You can request deactivation or deletion by sending a message to <a href="mailto: team@getwork.org" style="color: #3282c4">team@getwork.org</a> or by contacting your College to ask them to delete your information.</p>`,
      },
    ],
  },
  {
    title: "Third party links",
    detail: [
      {
        text: `<p>The Services may contain links to and from third party websites of our Employer and College Partners or other GetWork Partners. It is important to note that these third party vendors follow their own privacy policies and we are not held liable for their terms and agreements. We also cannot be responsible for your personal data that you submit to employers using the Services. Please check their individual policies before you submit any information to those websites.</p>`,
      },
    ],
  },
  {
    title: "‍Children",
    detail: [
      {
        text: `<p>Minors under 16 are not permitted to use our Site without parental consent to both use the Services and be bound by our Terms & Condition and this Policy. We do not intentionally collect information from minors under 13. If you have reason to suspect that we have collected information from a child younger than 13 years of age, please notify us immediately at <a href="mailto: team@getwork.org" style="color: #3282c4">team@getwork.org</a> and we will take reasonable measures to remove that information from our systems.</p>`,
      },
    ],
  },
  {
    title: "Payments",
    detail: [
      {
        text: `<p>GetWork provides the ability for employers to pay schools for collateral expenses, such as hosting a booth at a job fair, through the use of a third party payment processor. GetWork does not collect or use any of that payment information. If an employer has any conflicts regarding the method of payment acceptance, they should contact the school. GetWork claims no responsibility or liability for payment transactions. We do not sell any personal information collected from your use of the GetWork Service. To make a request under the India Consumer Privacy Act, or for any questions or concerns about our Privacy Policy or practices, please contact us at <a href="mailto: team@getwork.org" style="color: #3282c4">team@getwork.org</a></p>`,
      },
    ],
  },
  {
    title: "Contact us and accessing your information",
    detail: [
      {
        text: `<p>If you have any questions about our privacy policies, or if you wish to remove any of your personal information from our records please contact us at either:<br /><br />Veerwal GetWork Services Private Limited - GetWork<br />Attn: Privacy<br />UG-06, MGF Metropolis Mall<br />Sector 28, GurugramHaryana, 122016<br /><br />or <a href="mailto: team@getwork.org" style="color: #3282c4">team@getwork.org</a></p>`,
      },
    ],
  },
];

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <Fragment>
      {!localStorage.getItem("gw_token") ? (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <a href="/login">
              <img src={Logo} width="80px" height="auto" alt="logo" />
            </a>
            <Box className={classes.buttonBox}>
              <a href="/login">
                <Button variant="outlined" className={classes.outlinedButton}>
                  Login
                </Button>
              </a>
              <a href="/signup">
                <Button className={classes.button}>Sign up</Button>
              </a>
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <>
          <CompanyNavbar_C />
        </>
      )}

      <Box bgcolor="#ffffff" padding="10% 12%">
        <Container style={{ width: "80%" }}>
          <h1 style={{ textAlign: "center" }} className={classes.title}>
            GETWORK PRIVACY POLICY AND COOKIE POLICY
          </h1>

          {data.map((item, index) => (
            <Box paddingTop="2%" key={index}>
              <h1 className={classes.title2}>{item.title}</h1>

              {item.detail.map((item2, index2) => (
                <Fragment key={index2}>
                  {item2.subtitle && (
                    <p className={classes.subtitle}>{item2.subtitle}</p>
                  )}
                  <div
                    key={index2}
                    className={classes.details}
                    dangerouslySetInnerHTML={{
                      __html: item2.text,
                    }}
                  />
                </Fragment>
              ))}
            </Box>
          ))}
        </Container>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: "36px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
    },
    helpText: {
      fontSize: "16px",
      fontStyle: "italic",
    },
    warningText: {
      fontSize: "16px",
      fontFamily: "ProximaNona",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    title2: {
      fontSize: "40px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
    },
    details: {
      fontSize: "16px",
      fontFamily: "ProximaNova",
      textAlign: "justify",
    },
    subtitle: {
      fontSize: "22px",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      marginTop: "3%",
    },
    appBar: {
      width: `100%`,
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      height: "36px",
      borderRadius: 4,
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
      backgroundColor: "#3282c4",
      color: "#ffffff",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      "&:hover": {
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor: "#3282c4",
      },
    },
    outlinedButton: {
      height: "36px",
      borderRadius: 4,
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
      border: "2px solid #3282c4",
      color: "#3282c4",
      fontFamily: "ProximaNova",
      fontWeight: "bold",
      "&:hover": {
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor: "#ffffff",
      },
    },
    buttonBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "12%",
    },
    "@media only screen and (max-width: 768px)": {
      buttonBox: {
        width: "48%",
      },
      details: {
        textAlign: "left",
      },
    },
  })
);

export default PrivacyPolicy;

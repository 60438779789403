import { Grid, Paper, Hidden } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi } from "../../constants/constants";
import { getCreditInfo } from "../../GlobalComponents/CreditBoard/action";
import LeftSideSection from "../components/Common/Login/LeftSideSection/LeftSideSection";
import CompanyInfo from "../components/Common/Login/RightSection/CompanyInfo";
import EnterEmail from "../components/Common/Login/RightSection/EnterEmail";
import EnterOtp from "../components/Common/Login/RightSection/EnterOtp";
import Approval from "../components/Common/Login/RightSection/Approval";
import ChatWithUs from "../components/Common/Login/RightSection/ChatWithUs";
import Login from "../components/Common/Login/RightSection/Login";
import Register from "../components/Common/Login/RightSection/Register";
import { makeStyles } from "@material-ui/core/styles";
import { validateEmployerEmail } from "../../utils/utility";

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: "auto !important",
    padding: "40px !important",
    display: "flex !important",
    justifyContent: "flex-end !important",
    [theme.breakpoints.down("sm")]: {
      padding: "20px !important",
    },
  },
  formPaper: {
    width: "80%",
    padding: "40px",
    boxShadow: "0px 0px 10px 2px #0000001A",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "20px",
    },
  },
  imggrid: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "15px !important",
    },
  },
}));

export default function ContinueEmployer() {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [otpEditStep, setOtpEditStep] = useState(null);
  const [email, setEmail] = useState("");
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // this is for blocked email domain's or account logging with google account using gmail and
   //others.
  const [googleValidationFailed, setGoogleValidationFailed] = useState(false);

  function responseGoogle(response, setLoading) {
    if (!response?.accessToken) {
      // alert.error("Error in google login!");
      return;
    };
    setGoogleValidationFailed(false);
    setLoading(true);
    // const accessToken = "ya29.a0AVA9y1vOhc-4CLD0xrHsAlrTpVMRzioBu5QoeTyXOXbdeEbP-UyL0zVADY3X3_cfmvxNOPpMgYMPDokpQiUMJCcbV8SyKawmDV7Yf7ikhL_KqKTiQ5aPg5dAVMD9rvvmuvYuSRH1Tnv3YjthmXKzorka-Wt42gsaCgYKATASAQASFQE65dr8YVj_Ze4S5w38rPztuYJGSw0166";
    if (response?.accessToken) {

      axios
        .post(BackendBaseApi.PRAVESH + "api/company_saas/login/social/", {
          provider: "google-oauth2",
          access_token: response?.accessToken,
        },
        {withCredentials: true}
        )
        .then((res) => {
          if (res?.data?.success) {

            // console.log('validation happening here.',res?.data?.data?.email);
            // alert.success(res?.data?.data?.message); 
            // if(validateEmployerEmail(res?.data?.data?.email)){
            //   setGoogleValidationFailed(true);
            //   setLoading(false);
            //   return;
            // };
            alert.success(res?.data?.data?.message); 
            if (res?.data?.data?.code === 1) {
              setStep(5);
              setEmail(res?.data?.data?.email);
            } else if (res?.data?.data?.code === 2) {
              checkUser(res?.data?.data?.token, setLoading);
            } else if(res?.data?.data?.code === 3){
              setStep(6);
              setEmail(res.data?.data?.email);
            } else if(res?.data?.data?.code === 5){
              setStep(4);
              setData(res?.data?.data?.data);
            } else if (res?.data?.data?.code === 4) {
              setStep(7);
            }
          } else {
            alert.error(res?.data?.error);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          alert.error("Error in conitnuing with Google");
        });
    }
  }

  const checkUser = (token, setLoading) => {
    setLoading(true);
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          alert.success("User Already Create!! Please Complete Your Profile");
          if (!res.data.email_verified) {
            // history.push({
            //   pathname: "/signup",
            //   step: 2,
            //   userid: res.data.id,
            //   email: res.data.email,
            //   token,
            //   isemailVerified: false,
            // });
            history.push({
              pathname: "/confirm-email",
              email: res.data.email,
              user_id: res.data.user_type[0].user_id,
            });
          }
          // else {
          //   history.push({
          //     pathname: "/signup",
          //     step: 2,
          //     userid: res.data.id,
          //     token,
          //     email: res.data.email,
          //     isemailVerified: true,
          //   });
          // }
        } else {
          if (res.data.user_type[0].type === 10) {
            localStorage.clear();
            alert.error("You can not Login with Admin account here");
          } else {
            // alert.success("Login Successful");

            // alert.success("Login Successful");

            if (res.data.email_verified) {
              if (res.data.user_type[0].type !== 10) {
                localStorage.setItem(
                  "user_type",
                  res.data.user_type[0].main_user
                );
              }
              localStorage.setItem("gw_token", token);
              localStorage.setItem("user_type_id", res.data.user_type[0].type);
              localStorage.setItem("user_id", res.data.user_type[0].user_id);
              localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem(
                  "user_type",
                  res.data.user_type[0].main_user
                );

                //student with verified email, go to home
                localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

                if (location.flag == "102") {
                  history.push("/student/open");
                } else {
                  if (res.data.is_pref_filled)
                    history.push("/student/dashboard");
                  else
                    history.push({
                      pathname: "/student/complete-profile",
                      flag: 12367,
                    });
                }
              } else if (res.data.user_type[0].type === 7) {
                dispatch(getCreditInfo(setLoader));

                if (location.flag === 28071998)
                  history.push("/college/post-job");
                else history.push("/college/dashboard");
              } else if (res.data.user_type[0].type === 8) {
                dispatch(getCreditInfo(setLoader));
                if (location.flag === 28071998)
                  history.push("/college/post-job");
                else history.push("/college/dashboard");
              } else {
                //company user with verified email, check if admin approved

                if (res.data.is_pref_filled) {
                  if (!res.data.company_details.admin_approved) {
                    localStorage.setItem(
                      "user",
                      JSON.stringify({
                        email: res.data.email,
                        first_name: res.data.first_name,
                        last_name: res.data.last_name,
                        id: res.data.id,
                        profile_picture: null,
                      })
                    );

                    history.push({
                      pathname: "/company/admin-approve",
                      flag: 19087,

                      company: res.data.company_details.company_name,
                      admin_email: res.data.company_details.admin_email,
                      admin_name: res.data.company_details.admin_name,
                      is_admin: res.data.is_company_admin,
                    });
                  } else {
                    if (res?.data?.company_details !== null)
                      localStorage.setItem(
                        "company",
                        JSON.stringify(res.data.company_details)
                      );

                    dispatch(getCreditInfo(setLoader));
                    if (location.flag === 28071998)
                      history.push("/company/post-job");
                    else {
                      if (res.data.user_type[0].type === 5) {
                        localStorage.setItem(
                          "comp_pref_filled",
                          res.data.comp_pref_filled
                        );
                        history.push({
                          pathname: "/company/dashboard",
                          comp_pref_filled: res.data.comp_pref_filled,
                        });
                      } else {
                        history.push({ pathname: "/company/dashboard" });
                      }
                    }
                  }
                } else {
                  localStorage.setItem("mobile", res.data.mobile);
                  history.push("/company/complete-profile");
                }
              }
            } else {
              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem("gw_token", token);
                localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
                localStorage.setItem(
                  "user_type",
                  res.data.user_type[0].main_user
                );

                localStorage.setItem(
                  "user_type_id",
                  res.data.user_type[0].type
                );

                //student without verified email, go to home

                if (res.data.is_pref_filled) history.push("/student/dashboard");
                else
                  history.push({
                    pathname: "/student/complete-profile",
                    flag: 12367,
                  });
              } else {
                //company user without verified email
                history.push({
                  pathname: "/confirm-email",
                  email: res.data.email,
                  user_id: res.data.user_type[0].user_id,
                });
              }
            }
          }
        }
        setLoading(false);
        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        localStorage.clear();
        throw err;
      });
  };

  function getComponent() {
    switch (step) {
      case 1:
        return (
          <EnterEmail
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            responseGoogle={responseGoogle}
            setOtpEditStep={setOtpEditStep}
            googleValidationFailed={googleValidationFailed}
          />
        );
      case 2:
        return (
          <Login
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            responseGoogle={responseGoogle}
            checkUser={checkUser}
            setOtpEditStep={setOtpEditStep}
            googleValidationFailed={googleValidationFailed}
          />
        );
      case 3:
        return <EnterOtp setStep={setStep} email={email} setEmail={setEmail} checkUser={checkUser} otpEditStep={otpEditStep} />;
      case 4:
        return <Register setStep={setStep} email={email} setEmail={setEmail} setOtpEditStep={setOtpEditStep} data={data} />;
      case 5:
        return <CompanyInfo setStep={setStep} email={email} setEmail={setEmail} checkUser={checkUser} />;
      case 6:
        return <Approval email={email} />;
      case 7:
        return <ChatWithUs />;
      default:
        return <EnterEmail setStep={setStep} />;
    }
  }

  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Hidden only={["xs"]}>
            <Grid item xs={12} sm={6} md={6} className={classes.imggrid}>
              <LeftSideSection />
              {/* <LeftSideSectionSecond /> */}
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={6} className={classes.grid}>
            <Paper className={classes.formPaper}>
              {getComponent()}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import React from "react";
// import Sidebar from '../Components/Sidebar/Sidebar'
// import './layout.css'
import { Breakpoint, BreakpointProvider } from "react-socks";
import Navbar from "../NavBar/Navbar";
import { Container, Grid, Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Sidebar from '../../bundles/common/components/UI/Sidebar';
import SideBar from "../SideBar/SideBar";
import CollegeNavbar from "../NavBar/CollegeNavbar";

export default function Layout({ children }) {
  return (
    <>
      <BreakpointProvider>
        <Breakpoint large up>
          <Grid container>
            <Grid item xs={12}>
              <CollegeNavbar />
            </Grid>
            <Grid item xs={12}>
              <Box>
                {/* <SideBar2 /> */}
                <Box marginTop="90px">{children}</Box>
              </Box>
            </Grid>
          </Grid>
        </Breakpoint>
        <Breakpoint medium down>
          <Navbar />
          <div className="mt-5">{children}</div>
        </Breakpoint>
      </BreakpointProvider>
      {/* <Sidebar userType='COMPANY'/> */}
    </>
  );
}

import { Button, Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { generateCsvNew } from "../../../../common/generateCsv/generateCsv";
import { stringEllipsify } from "../../../../utils/utility";
// import TrackShareSuccessfully from "../../DialogSection/TrackShareSuccessfull";
import TrackShareSuccessfully from "../../Dialog/TrackShareSuccessfull/index";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    width: 200,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "https://app.getwork.org/public/trac...",
    "List Item",
    "Track, Download",
    "Email, Resume",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "https://app.getwork.org/public/trac...",
    "List Item",
    "Track, Download",
    "Email, Resume",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "https://app.getwork.org/public/trac...",
    "List Item",
    "Track, Download",
    "Email, Resume",
    "prerit.chaudhary@getwork.org"
  ),
  createData(
    "https://app.getwork.org/public/trac...",
    "List Item",
    "Track, Download",
    "Email, Resume",
    "prerit.chaudhary@getwork.org"
  ),
];

const useStyles = makeStyles({
  table: {
    minWidth: 900,
  },
});

export default function History({ data, jobTitle }) {
  const classes = useStyles();

  const checkActions = (obj) => {
    var arr = [];
    var str = "";
    if (obj.track === true) arr.push("Track");

    if (obj.download === true) arr.push("Download");
    var str = generateCsvNew(arr);
    if (!str) {
      str = "NA";
    }
    return str;
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [shareSuccessData, setShareSuccessData] = useState({
    link: null,
    passcode: null,
  });

  const capitalizeFirstLetter = (newArr) => {
    var new_arr = [];
    for (let day of newArr) {
      day = day.charAt(0).toUpperCase() + day.substr(1);
      new_arr.push(day);
    }
    return new_arr;
  };

  const generateCsvForJobRound = (arr) => {
    let newStr = ``;
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].hasOwnProperty("round_name")) {
        newStr += `${arr[i]?.round_name}, `;
      }
    }
    newStr = newStr.replace(/,\s*$/, "");
    return newStr;
  };
  return (
    <>
      <TableContainer component={Paper}>
        {data.length <= 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <NoData
              height="180px"
              width="180px"
              alt="404 Error"
              fill={"#007BFF"}
            />
            <span align="center" style={{ fontSize: 14, color: "#6c757d" }}>
              <b>No Data Available</b>
            </span>
          </div>
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {/*<StyledTableCell>Track Link</StyledTableCell>*/}
                {/*<StyledTableCell align="left">PassCode</StyledTableCell>*/}
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Job_Rounds</StyledTableCell>
                <StyledTableCell align="center">Table Colums</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
                {/*  <StyledTableCell align="left">Details</StyledTableCell> */}
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 &&
                data?.map((row) => (
                  <StyledTableRow key={row?.job_link}>
                    {/*<Tooltip title={row.job_link} placement="right">*/}
                    {/*  <StyledTableCell component="th" scope="row">*/}
                    {/*    {stringEllipsify(row.job_link, 20)}*/}
                    {/*  </StyledTableCell>*/}
                    {/*</Tooltip>*/}
                    {/*<StyledTableCell align="center">*/}
                    {/*  {row.Passcode}*/}
                    {/*</StyledTableCell>*/}
                    <Tooltip
                      title={generateCsvNew(row?.action_performed?.email)}
                      placement="right"
                    >
                      <StyledTableCell align="center">
                        {generateCsvNew(row?.action_performed?.email)
                          ? generateCsvNew(row?.action_performed?.email)
                          : "NA"}
                      </StyledTableCell>
                    </Tooltip>
                    <Tooltip
                      title={generateCsvForJobRound(
                        row?.action_performed?.job_rounds
                      )}
                      placement="right"
                    >
                      <StyledTableCell align="center">
                        {generateCsvForJobRound(
                          row?.action_performed?.job_rounds
                        )}
                      </StyledTableCell>
                    </Tooltip>
                    <Tooltip
                      title={generateCsvNew(
                        row?.action_performed?.table_columns
                      )}
                      placement="right"
                    >
                      <StyledTableCell align="center">
                        {generateCsvNew(row?.action_performed?.table_columns)}
                      </StyledTableCell>
                    </Tooltip>

                    <StyledTableCell align="center">
                      {checkActions(row?.action_performed?.authority)}
                    </StyledTableCell>

                    {/*  <StyledTableCell align="center">
                    {generateCsvNew(
                      capitalizeFirstLetter(row.action_performed.action)
                    )}
                  </StyledTableCell> */}
                    <StyledTableCell align="center">
                      <Button
                        color="primary"
                        onClick={() => {
                          setShareSuccessData({
                            ...shareSuccessData,
                            link: row?.job_link,
                            passcode: row?.Passcode,
                          });
                          handleClickOpen();
                        }}
                      >
                        Copy Details
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TrackShareSuccessfully
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        data={shareSuccessData}
        jobTitle={jobTitle}
      />
    </>
  );
}

import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CssTextField from "./FormComponents/CssTextField";
import SearchIcon from "@material-ui/icons/Search";
import collegeicon from "../../../assets/college.png";
import noLogo from "../../../assets/no-logo.png";
import line from "../../../assets/images/lineOr.png";
import sandclock from "../../../assets/sandclock.gif";

import CustomizedCheckboxes from "./FormComponents/CustomizedCheckboxes";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./FormComponents/AccordianCustom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DateSelector from "./FormComponents/DateComponent";
import no_data from "../../../assets/images/openjob.png";
import todaydate from "../../../utils/TodayDate";
import { useAlert } from "react-alert";
import BootstrapTooltip from "../../../common/Tooltip/Tooltip";

export default function CollegeList(props) {
  const {
    state,
    setState,
    collegeList,
    newCollegeList1,
    setNewCollegeList1,
    setCollegeList,
    allCollegeCount,
    setAllCollegeCount,
    end,
    setEnd,
    getAllColleges,
    isApiCall,
    setIsApiCall,
    getDegreeAndCourses,
    loading,
    loader,
    college,
    setCollege,
    search,
    setSearch,
    setButtonClickSearch,
    buttonClickSearch,
    setNextUrl,
    nextUrl,
    stateMemory,
    page,
    setPage,
  } = props;
  const classes = useStyles();
  const alert = useAlert();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [allSpecializationChange, setAllSpecializationChange] = useState(false);
  const [specializationChange, setSpecializationChange] = useState(false);

  /*   const handleScroll = (event) => {
    console.log("EEEEE", event);
    var bottomel = document.getElementById("bottom");
    bottomel.scrollIntoView({ behavior: "smooth", block: "start" });
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && isApiCall) {
        getAllColleges(1);
      }
    }
  }; */

  const loaderRef = useRef(null);
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && end !== true && isApiCall) {
      //getAllColleges(1);
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    if (page > 0 && allCollegeCount > 8 && end !== true) {
      getAllColleges(1);
    }
  }, [page]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loaderRef.current) observer.observe(loaderRef.current);
  }, [handleObserver]);

  const handleSelectAllSpecialization = (event, college_id) => {
    var degree_id = event.target.value;
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[college_id].education_details.filter(
      (data) => data.degree_id === parseInt(degree_id)
    )[0];
    var allDegreesChecked = true;
    newDegreeObj.specialization.forEach((spec) => {
      spec.selected = !newDegreeObj.select_all;
    });
    setAllSpecializationChange(true);

    newDegreeObj.select_all = !newDegreeObj.select_all;
    newCollegeList[college_id].education_details.forEach((education) => {
      if (education.select_all === false) allDegreesChecked = false;
    });
    newCollegeList[college_id].select_all = allDegreesChecked;
    setCollegeList({ ...newCollegeList });
  };
  const handleSelectSingleSpecialization = (event, college_id, degree_id) => {
    var specialization_id = event.target.value;
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[college_id].education_details.filter(
      (data) => data.degree_id == parseInt(degree_id)
    )[0];
    setSpecializationChange(true);
    var allSpecializationChecked = true;
    var allDegreesChecked = true;
    newDegreeObj.specialization.forEach((spec) => {
      if (spec.specialization_id === parseInt(specialization_id))
        spec.selected = !spec.selected;
      if (spec.selected === false) allSpecializationChecked = false;
    });
    newDegreeObj.select_all = allSpecializationChecked;
    newCollegeList[college_id].education_details.forEach((education) => {
      if (education.select_all === false) allDegreesChecked = false;
    });
    newCollegeList[college_id].select_all = allDegreesChecked;
    setCollegeList({ ...newCollegeList });
    setState({ ...state });
  };
  const handleSelectStartDate = (event, college_id) => {
    var degree_id = event.target.value;
    var newCollegeList = collegeList;
    newCollegeList[college_id].apply_start_date = event.target.value;
    setCollegeList({ ...newCollegeList });
  };
  const handleSelectEndDate = (event, college_id) => {
    var degree_id = event.target.value;
    var newCollegeList = collegeList;
    newCollegeList[college_id].apply_end_date = event.target.value;
    setCollegeList({ ...newCollegeList });
  };
  const handleSelectAllCourses = (event) => {
    var key = event.target.value;
    var newCollegeList = collegeList;
    updateCollegeListAllCourses(key, !newCollegeList[key].select_all);
  };

  const updateCollegeListAllCourses = (college_id, value) => {
    var newCollegeList = collegeList;
    var newAllSelectVal = value;
    newCollegeList[college_id].select_all = newAllSelectVal;
    newCollegeList[college_id].education_details.forEach((education) => {
      education.specialization.forEach((spec) => {
        spec.selected = newAllSelectVal;
      });
      education.select_all = newAllSelectVal;
    });
    setAllSpecializationChange(true);
    setSpecializationChange(true);
    setCollegeList({ ...newCollegeList });
  };

  const addSelectedCollege = (college_id, college_name) => {
    var newCollegeList = collegeList;
    var newDegreeObj = newCollegeList[college_id].education_details;
    var specialization_selected = false;
    if (newDegreeObj.select_all) specialization_selected = true;
    else {
      alert.success(college_name + " Added Successfully");
      newDegreeObj.forEach((dets) => {
        if (dets.select_all) {
          specialization_selected = true;
        }
        dets.specialization.forEach((spec) => {
          if (spec.selected) {
            specialization_selected = true;
          }
        });
      });
    }
    if (specialization_selected) setCollege(college_id);
    else alert.error("Please Select Atleast One Course or Specialization");
  };

  useEffect(() => {
    if (!college) return;

    const currentCollege = collegeList[college];
    let allColleges = state.Colleges.college;
    let education_details = currentCollege.education_details;
    let preferences = [];
    if (currentCollege.select_all) preferences = education_details;
    else {
      education_details.forEach((degree) => {
        let temp = {
          degree_id: degree.degree_id,
          degree_name: degree.degree_name,
          specialization: [],
        };
        if (degree.select_all) {
          preferences.push(degree);
        } else {
          let found = false;
          degree.specialization.forEach((specialization) => {
            if (specialization.selected) {
              temp.specialization.push(specialization);
              found = true;
            }
          });
          if (found) preferences.push(temp);
        }
      });
    }
    let newCollege = {
      college_id: currentCollege.college_id,
      college_name: currentCollege.college_name,
      preferences: preferences,
      apply_start_date: collegeList[college].apply_start_date,
      apply_end_date: collegeList[college].apply_end_date,
      select_all: currentCollege.select_all,
    };
    let collegeExist = false;
    allColleges.forEach((clg) => {
      if (clg.college_id == college) {
        //college already in the list
        collegeExist = true;
        return;
      }
    });
    if (collegeExist) {
      if (!specializationChange && !allSpecializationChange) return;
      else {
        var index = allColleges.findIndex((x) => x.college_id === college);
        allColleges.splice(index, 1);
      }
    }

    //college is not in the list
    allColleges.push(newCollege);
    setState({
      ...state,
      Colleges: {
        ...state.Colleges,
        college: allColleges,
      },
    });
    setAllSpecializationChange(false);
    setSpecializationChange(false);
    setCollege();
  }, [college]);

  const checkDisabled = (clg) => {
    var disabling = true;
    if (collegeList[clg]?.education_details.length > 0) {
      disabling = true;
      collegeList[clg].education_details.map((clg_edu, id) =>
        clg_edu?.specialization.map((spec, ind) => {
          if (spec.selected === true) {
            disabling = false;
          }
        })
      );
    } else disabling = false;

    return disabling;
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      if (buttonClickSearch !== search) {
        setCollegeList({});

        setEnd(false);
        setNextUrl(null);
        setButtonClickSearch(search);
      }
    }
  };

  return (
    <>
      <div>
        <Box
          id="bottom"
          className={classes.BlackBox} /* onScroll={handleScroll} */
        >
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={6}>
              <p className={classes.whiteHeading}>
                Choose the Colleges where this job should be posted
              </p>
            </Grid>
            <Grid item xs={5}>
              <CssTextField
                id="outlined-name-2"
                label="Search"
                disabled={
                  state.college_id_list.checkAll ||
                  state.Basics.posted_on_job_lake
                }
                type="text"
                value={search}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    if (buttonClickSearch !== search) {
                      setCollegeList({});
                      setNewCollegeList1([]);
                      setEnd(false);
                      setNextUrl(null);
                      setButtonClickSearch(search);
                    }
                  }
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                variant="outlined"
                style={{
                  width: "100%",
                  background: "white",
                  borderRadius: 30,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                type="submit"
                color="primary"
                aria-label="add"
                onClick={() => {
                  if (buttonClickSearch !== search) {
                    setCollegeList({});
                    setNewCollegeList1([]);
                    setEnd(false);
                    setNextUrl(null);
                    setButtonClickSearch(search);
                  }
                }}
                disabled={
                  state.college_id_list.checkAll ||
                  state.Basics.posted_on_job_lake
                }
              >
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
          <p className={classes.para}>
            Each College can have its own apply start date or expiration date,
            or you can set global dates that apply to all postings of this job.
          </p>
          <Box className={classes.BlueBox}>
            <p className={classes.blueboxtext} style={{}}>
              We generate and show you college rankings as per your choosen
              preferences, you can still filter colleges as per courses whenever
              you want.
            </p>
          </Box>
          <Grid container style={{ marginBottom: 10, marginTop: 20 }}>
            {buttonClickSearch && (
              <>
                <Grid item xs={3}>
                  {/* {buttonClickSearch && ( */}
                  <p className={classes.clearPara}>
                    You searched for "{buttonClickSearch}"...
                  </p>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={
                      state.college_id_list.checkAll ||
                      state.Basics.posted_on_job_lake
                    }
                    onClick={() => {
                      setCollegeList({});
                      setNewCollegeList1([]);
                      setEnd(false);
                      setNextUrl(null);
                      setSearch("");
                      setButtonClickSearch("");
                    }}
                    style={{ borderRadius: 30, background: "#fff" }}
                  >
                    <span className={classes.labelSize}>Clear your Search</span>
                  </Button>
                  {/* )} */}
                </Grid>
              </>
            )}
            <Grid item xs={5}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<img src={collegeicon} alt="" height="20px" />}
                style={{ borderRadius: 30, background: "#fff" }}
              >
                <span className={classes.labelSize}>
                  College Total Count :{" "}
                  {!loader ? (
                    <>{allCollegeCount}</>
                  ) : (
                    <img src={sandclock} alt="" height="18px" />
                  )}
                </span>
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            {
              /* stateMemory.num > 0 &&  */ parseInt(allCollegeCount) > 0 && (
                <>
                  <FormControlLabel
                    // style={{
                    //   marginLeft: 20,
                    //   marginTop: "-20px",
                    // }}
                    control={
                      <CustomizedCheckboxes
                        disabled={state.Basics.posted_on_job_lake}
                        onChange={(e) => {
                          // setCheckAll(e.target.checked);
                          setState({
                            ...state,
                            college_id_list: {
                              ...state.college_id_list,
                              checkAll: e.target.checked,
                            },
                          });
                        }}
                        checked={state.college_id_list.checkAll}
                        // iconStyle={{ color: "white" }}
                        name="gilad"
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        Select All Colleges &nbsp;
                        {state.college_id_list.checkAll &&
                          "(No action can be performed on selecting this)"}
                      </Typography>
                    }
                  />{" "}
                </>
              )
            }
          </Grid>
          {Object.keys(collegeList).length > 0 ? (
            // Object.keys(collegeList).map((college, i) => {
            newCollegeList1?.map((clg, i) => {
              // let clg = collegeList[college];
              return (
                <Accordion
                  disabled={state.college_id_list.checkAll}
                  square
                  onChange={(event, expanded) => {
                    if (expanded === true) {
                      if (
                        collegeList[clg.college_id].education_details.length ===
                        0
                      )
                        getDegreeAndCourses(clg.college_id, clg.college_name);
                    }
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <div className={classes.imagedata}>
                          <img
                            className={classes.imgdata}
                            alt="complex"
                            src={clg?.college_logo ? clg.college_logo : noLogo}
                          />
                        </div>

                        {/* <img
                          src={clg?.college_logo ? clg.college_logo : noLogo}
                          alt=""
                          style={{
                            width: "100%",
                            height: "65px",
                            borderRadius: 30,
                            boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                          }}
                        /> */}
                      </Grid>
                      <Grid item xs={10}>
                        <p
                          className={classes.heading}
                          style={{
                            marginBottom: "-1px",
                            marginTop: "-2px",
                          }}
                        >
                          {clg.college_name}{" "}
                        </p>
                        <p className={classes.subTitleNew}>
                          {" "}
                          {clg.college_location}
                        </p>
                      </Grid>
                    </Grid>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <Grid container>
                      {loading &&
                        collegeList[clg.college_id].education_details.length ===
                          0 && (
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              marginTop="5%"
                            >
                              <CircularProgress className={classes.progress} />
                            </Box>
                          </Grid>
                        )}
                      {!loading && clg.education_details.length === 0 && (
                        <Grid item xs={12}>
                          <p
                            align="center"
                            className={classes.labelSize}
                            style={{ marginTop: "5%" }}
                          >
                            <b>No Courses Available for this college</b>
                          </p>
                        </Grid>
                      )}

                      <Grid item xs={3} className={classes.sectionNew}>
                        {clg.education_details.length > 0 && (
                          <p className={classes.formLabel}>
                            Course
                            <span className={classes.RedStar}>
                              &nbsp;&nbsp;*
                            </span>
                          </p>
                        )}
                      </Grid>

                      <Grid item xs={9} className={classes.sectionNew}>
                        {clg.education_details.length > 0 && (
                          <>
                            <FormControlLabel
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                              control={
                                <CustomizedCheckboxes
                                  checked={clg.select_all ? true : false}
                                  onChange={handleSelectAllCourses}
                                  value={clg.college_id}
                                  name="gilad"
                                  classes={{
                                    root: classes.radio,
                                    checked: classes.checked,
                                  }}
                                />
                              }
                              label={
                                <span className={classes.labelSize}>
                                  Select All Courses
                                </span>
                              }
                            />

                            <img src={line} width="100%" />
                            <br />
                            <br />
                          </>
                        )}
                        {loader === false &&
                          clg.education_details.length > 0 &&
                          clg.education_details.map((clg_edu, id) => {
                            return (
                              <Fragment key={id}>
                                <Accordion square>
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    expandIcon={<ExpandMoreIcon />}
                                  >
                                    <FormControlLabel
                                      onClick={(event) =>
                                        event.stopPropagation()
                                      }
                                      onFocus={(event) =>
                                        event.stopPropagation()
                                      }
                                      control={
                                        <CustomizedCheckboxes
                                          checked={
                                            clg_edu.select_all ? true : false
                                          }
                                          onChange={(event) => {
                                            handleSelectAllSpecialization(
                                              event,
                                              clg.college_id
                                            );
                                            event.stopPropagation();
                                            event.preventDefault();
                                          }}
                                          value={clg_edu.degree_id}
                                          name="gilad"
                                          classes={{
                                            root: classes.radio,
                                            checked: classes.checked,
                                          }}
                                        />
                                      }
                                      label={
                                        <span className={classes.labelSize}>
                                          {clg_edu.degree_name}
                                        </span>
                                      }
                                    />
                                  </AccordionSummary>
                                  {clg_edu?.specialization.map((spec, ind) => (
                                    <AccordionDetails>
                                      <>
                                        <Box className={classes.innerBox}>
                                          <Fragment key={ind}>
                                            <FormControlLabel
                                              control={
                                                <CustomizedCheckboxes
                                                  checked={
                                                    spec.selected ? true : false
                                                  }
                                                  onChange={(event) =>
                                                    handleSelectSingleSpecialization(
                                                      event,
                                                      clg.college_id,
                                                      clg_edu.degree_id
                                                    )
                                                  }
                                                  value={spec.specialization_id}
                                                  name="gilad"
                                                  classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                  }}
                                                />
                                              }
                                              label={
                                                <span
                                                  className={classes.labelSize}
                                                >
                                                  {spec.specialization_name}
                                                </span>
                                              }
                                            />
                                          </Fragment>
                                        </Box>
                                      </>
                                    </AccordionDetails>
                                  ))}
                                </Accordion>
                              </Fragment>
                            );
                          })}
                      </Grid>

                      <Grid item xs={3} className={classes.sectionNew}>
                        <p className={classes.formLabel}>
                          Select Dates
                          <span className={classes.RedStar}>&nbsp;&nbsp;*</span>
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className={classes.sectionNew}
                        style={{ marginRight: "2%" }}
                      >
                        <CssTextField
                          id="date"
                          label="Apply Start Date"
                          type="date"
                          onChange={(e) => {
                            handleSelectStartDate(e, clg.college_id);
                          }}
                          value={collegeList[clg.college_id]?.apply_start_date}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => e.preventDefault()}
                          InputProps={{
                            inputProps: { min: String(todaydate()) },
                            style: { fontSize: 13 },
                          }}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className={classes.sectionNew}
                        style={{ marginRight: "2%" }}
                      >
                        <CssTextField
                          id="date"
                          label="Apply End Date"
                          type="date"
                          onChange={(e) => {
                            handleSelectEndDate(e, clg.college_id);
                          }}
                          value={collegeList[clg.college_id]?.apply_end_date}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => e.preventDefault()}
                          InputProps={{
                            inputProps: { min: String(todaydate()) },
                            style: { fontSize: 13 },
                          }}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.sectionNew}>
                        <Box
                          style={{
                            display: "flex",
                            float: "right",
                            justifyContent: "right",
                            width: "100%",
                          }}
                        >
                          <BootstrapTooltip
                            title={
                              "Add the unselected college or edit the already selected college from here"
                            }
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<AddCircleOutlineIcon />}
                              style={{ borderRadius: 30 }}
                              onClick={() =>
                                addSelectedCollege(
                                  clg.college_id,
                                  clg.college_name
                                )
                              }
                              disabled={checkDisabled(clg.college_id)}
                            >
                              <span className={classes.labelSize}>
                                Add/Edit
                              </span>
                            </Button>
                          </BootstrapTooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : // allCollegeCount === 0 && !loading ? (
          //   <div
          //     style={{
          //       color: "white",
          //       display: "flex",
          //       flexDirection: "column",
          //       alignItems: "center",
          //       width: "100%",
          //     }}
          //   >
          //     <img
          //       src={no_data}
          //       alt=""
          //       style={{ height: "250px", marginTop: "25px" }}
          //     />
          //     <div align="center" className={classes.labelSize}>
          //       {" "}
          //       No data Found. Please Change the filters to get data
          //     </div>
          //   </div>
          // ) : (
          loader ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "30px auto 30px auto",
              }}
            >
              <CircularProgress className={classes.progress} />
            </Box>
          ) : (
            <div
              style={{
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                src={no_data}
                alt=""
                style={{ height: "250px", marginTop: "25px" }}
              />
              <div align="center" className={classes.labelSize}>
                {" "}
                No data Found. Please Change the filters to get data
              </div>
            </div>
          )}
          {/*   {allCollegeCount > 8 && ( */}
          <div
            ref={loaderRef}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {allCollegeCount > 8 && <CircularProgress />}
          </div>
          {/*    )} */}
        </Box>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formLabel: {
      fontSize: "13px",
      fontWeight: "bold",
    },
    para: {
      fontSize: "13px",
      color: "grey",
      fontWeight: "bold",
      textAlign: "center",
    },
    subTitle: {
      fontSize: "11px",
      color: "grey",
      width: "70%",
      margin: "6px 0 0 10px",
    },
    subTitleNew: {
      fontSize: "13px",
      color: "grey",
      fontWeight: "bold",
    },
    whiteHeading: {
      fontSize: "15px",
      fontWeight: "bolder",
      color: "#fff",
      textAlign: "center",
      paddingTop: 8,
    },
    RedStar: {
      color: "red",
      fontWeight: "bold",
    },
    heading: {
      fontSize: "18px",
      fontWeight: "bolder",
    },
    section: {
      marginBottom: "20px",
    },
    sectionNew: {
      marginBottom: "20px",
      marginTop: "20px",
    },
    BlackBox: {
      backgroundColor: "#171717",
      padding: "20px",
      borderRadius: 30,
      // maxHeight: "700px",
      // minHeight: "700px",

      // overflowY: "scroll",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    },
    BlueBox: {
      backgroundColor: "#cdedf6",
      padding: "20px",
      borderRadius: 30,
    },
    labelSize: {
      fontSize: "12px",
    },
    blueboxtext: {
      textAlign: "center",
      fontSize: 13,
      fontWeight: "bold",
    },
    clearPara: {
      fontSize: "13px",
      fontWeight: "bold",
      color: "#fff",
      textAlign: "center",
      paddingTop: 8,
    },
    imagedata: {
      padding: "5px",
      display: "flex",
      maxWidth: "100%",
      backgroundColor: "#fff",
      height: "65px",
      borderRadius: "10px",
      boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    },
    imgdata: {
      margin: "auto",
      maxWidth: "100%",
      maxHeight: "100%",

      //  borderRadius: "50%",
    },

    "@media only screen and (max-width: 900px)": {
      InputWidth: {
        width: "100%",
      },
      clearPara: {
        fontSize: "11px",
        fontWeight: "bold",
        color: "#fff",
        textAlign: "center",
        paddingTop: 8,
      },
      subTitleNew: {
        fontSize: "11px",
        color: "grey",
        fontWeight: "bold",
      },
      heading: {
        fontSize: "14px",
        fontWeight: "bolder",
      },
      para: {
        fontSize: "11px",
        color: "grey",
        fontWeight: "bold",
        textAlign: "center",
      },
      whiteHeading: {
        fontSize: "13px",
        fontWeight: "bolder",
        color: "#fff",
        textAlign: "center",
        paddingTop: 4,
      },
      formLabel: {
        fontSize: "11px",
        fontWeight: "bold",
        paddingTop: "10px",
      },
      blueboxtext: {
        textAlign: "center",
        fontSize: 11,
        fontWeight: "bold",
      },

      labelSize: {
        fontSize: "12px",
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "auto",

        // border: "1px solid #ced4da",
        // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
      },
    },
  })
);

import {
  Box,
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import Layout from "../../../components/college/Layout/Layout_c";

import location from "../../../assets/images/map_white.png";
import TabPanel from "./TabBar";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants.js";
import { useAlert } from "react-alert";
import Rating from "@material-ui/lab/Rating";
import Loader from "../../../bundles/common/components/UI/Loader.js";
const useStyles = makeStyles((theme) =>
  createStyles({
    BlueBox: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    InnerText: {
      display: "block",
      position: "relative",
      bottom: "0",
      left: "0",
      right: "0",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(0deg, rgba(255,255,255,0.4) 0%, rgba(0,0,0,0.95) 100%)",
      color: "white",
      padding: "5%",
    },
    ImageBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    imageStyle: {
      borderRadius: "20px",
      height: "160px",
      marginTop: "20%",
      width: "180px",
    },
    innerBox: {
      position: "absolute",
      borderRadius: "50%",
      padding: "18px",
      background:
        "linear-gradient(180deg, rgba(127,224,102) 0%, rgba(0,80,17) 100%)",
      top: "90%",
      height: "27%",
      width: "25%",
      textAlign: "center",
      color: "#fff",
      fontWeight: "bold",
    },
    MainHeading: {
      fontWeight: "bolder",
      fontSize: "50px",
    },
    SubHeading: {
      fontSize: "18px",
    },
    HeadingMargin: {
      marginTop: "30px",
      marginLeft: "50px",
    },
    SubHeadingMargin: {
      marginTop: "20px",
      marginLeft: "50px",
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #3282C4",
      color: "#3282C4",
      backgroundColor: "#fff",
    },
    socialMediaBox: {
      display: "flex",
      justifyContent: "center",
      marginTop: "70px",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      padding: "20px 50px 20px 50px",
      width: "50%",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "8px",
      fontSize: "18px",
      "&:hover": {
        background: "#fff",
        color: "#3282C4",
        border: "2px solid #3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    WhiteButton: {
      background: "#fff",
      color: "#3282C4",
      padding: "20px 50px 20px 50px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "8px",
      fontSize: "18px",

      width: "50%",
      "&:hover": {
        background: "#fff",

        border: "2px solid #3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    ButtonsGroup: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: "70px",
      alignItems: "center",
      width: "100%",
    },
    ratingBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      marginTop: "5px",
      backgroundColor: "#fff",
      width: "200px",
      borderRadius: "40px",
      padding: "10px",
      border: "2px solid #3282C4",
      marginLeft: "6%",
    },
    "@media only screen and (max-width: 768px)": {
      MainHeading: {
        fontWeight: "bolder",
        fontSize: "30px",
        textAlign: "center",
        marginRight: "10%",
        marginTop: "-40px",
      },
      imageStyle: {
        height: "60px",
        width: "100px",
        marginLeft: "90%",
      },
      ImageBox: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginLeft: "75%",
      },
      SubHeadingMargin: {
        display: "flex",
        justifyContent: "center",
        marginRight: "10%",
      },
      ratingBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        marginLeft: "20%",
        width: "200px",
      },
      postButton: {
        marginTop: "-200px",
        padding: "10px 30px 10px 30px",
        width: "100%",
      },
      ButtonsGroup: {
        marginLeft: "90%",
      },
    },
  })
);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    top: 406,
    left: 1340,
    width: 300,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#3282C4",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CompanyProfileView() {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const params = useParams();
  const [mainData, setMainData] = useState({});
  const [banner, setBanner] = useState();
  const [logo, setLogo] = useState();
  const [rating, setRating] = useState(0);
  const [loader, setLoader] = useState(false);

  const getData = () => {
    setLoader(true);

    Axios.get(
      BackendBaseApi.PRAVESH +
        "api/colleges/college_data/?college_id=" +
        parseInt(params.college_id)
    )
      .then((res) => {
        // setIsApiCall(true);

        if (res.data.success) {
          setMainData(res.data.data);
          setBanner(res.data.data.banner);
          setLogo(res.data.data.logo);
          setRating(parseFloat(res.data.data.college_info.reviews.rating) / 2);
        } else {
          alert.error(res.data.error);
        }
        setLoader(false);
      })
      .catch((err) => {
        alert.error("Error Occured");
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Layout>
          <Box
            className={classes.BlueBox}
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${banner})`,
            }}
          >
            <div className={classes.InnerText}>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <Box className={classes.ImageBox}>
                    <img className={classes.imageStyle} src={logo} alt="" />
                  </Box>
                </Grid>
                <Grid item md={5}>
                  <Box className={classes.HeadingMargin}>
                    <Typography className={classes.MainHeading} variant={"h2"}>
                      {mainData.college_name}
                    </Typography>
                  </Box>
                  <Box className={classes.SubHeadingMargin}>
                    <Chip
                      label={"Since " + mainData?.college_info?.year_founded}
                      variant="outlined"
                      className={classes.root1}
                      color="primary"
                    />
                    &nbsp; &nbsp;
                    {mainData?.college_info?.type_of_college ? (
                      <Chip
                        label={
                          mainData?.college_info?.type_of_college + " College"
                        }
                        variant="outlined"
                        className={classes.root1}
                        color="primary"
                      />
                    ) : (
                      <Chip
                        label={
                          mainData?.college_info?.university_type +
                          " University"
                        }
                        variant="outlined"
                        className={classes.root1}
                        color="primary"
                      />
                    )}
                  </Box>
                  <Box className={classes.SubHeadingMargin}>
                    <Typography
                      className={classes.SubHeading}
                      style={{ color: "#fff" }}
                      variant={"body2"}
                    >
                      <img src={location} alt="" height="20px" /> &nbsp; &nbsp;
                      <b>{mainData.city}, India</b>
                    </Typography>
                  </Box>
                  <br />
                  <Box
                    component="fieldset"
                    mb={3}
                    borderColor="black"
                    className={classes.ratingBox}
                  >
                    <Rating
                      name="read-only"
                      value={
                        parseFloat(mainData?.college_info?.reviews?.rating) / 2
                      }
                      defaultValue={
                        parseFloat(mainData?.college_info?.reviews?.rating) / 2
                      }
                      precision={0.25}
                      readOnly
                    />
                    &nbsp;{" "}
                    <span style={{ color: "grey" }}>
                      <b>
                        {parseFloat(mainData?.college_info?.reviews?.rating) /
                          2}
                        /5
                      </b>
                    </span>
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Box className={classes.socialMediaBox}>
                  </Box>
                  <Box className={classes.ButtonsGroup}>
                    {localStorage.getItem("gw_token") ? (
                      <Button
                        type="submit"
                        className={classes.postButton}
                        style={{ marginBottom: "20px" }}
                        onClick={() => {
                          if (localStorage.getItem("user_type") === "Company")
                            history.push({ pathname: "/company/post-job" });
                          else if (
                            localStorage.getItem("user_type") === "College"
                          )
                            history.push({ pathname: "/college/post-job" });
                        }}
                      >
                        Post Job
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className={classes.postButton}
                        style={{ marginBottom: "20px" }}
                        onClick={() => {
                          history.push({ pathname: "/login", flag: 28071998 });
                        }}
                      >
                        Post Job
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Box>
          <TabPanel data={mainData} />
        </Layout>
      )}
    </>
  );
}

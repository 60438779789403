import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Slide,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React, { useState } from "react";
import { Breakpoint } from "react-socks";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/common/httpRequest";
import "./filter.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    thumb: {
      width: "auto",
      backgroundColor: "#000000",
      color: "#ffffff",
    },
    scrollPaper: {
      width: "100%",
    },
    filterButton: {
      marginTop: "0px !important",
      width: "100%",
      float: "right",
      cursor: "pointer",
      backgroundColor: "#f2f2f2",
    },
    dropdownBox: {
      display: "flex",
      alignItems: "center",
      width: "65%",
      justifyContent: "space-between",
    },
    containedButton: {
      backgroundColor: "#3282c4",
      color: "#ffffff",
    },
    outlinedButton: {
      border: "1px solid #3282c4",
      color: "#3282c4",
    },
    "@media screen and (max-width: 768px)": {
      filterButton: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#f2f2f2",
      },
      DropDown: {
        height: "27px !important",
      },
      dropdownFocus: {
        "&:focus": {
          backgroundColor: "#ffffff",
        },
      },
      dropdownBox: {
        width: "100%",
        padding: "4% 0%",
      },
    },
  })
);

export default function Filter({
  filterData,
  setJobData,
  setSingleJobData,
  fullView,
  setLoading,
}) {
  const [open, handleClose] = useState(false);
  const classes = useStyles();

  const token = localStorage.getItem("gw_token");
  const data = JSON.parse(localStorage.getItem("user_details"));

  const filterDetails = data.student_user_details.education.filter(
    (item) => item.is_current === true
  );

  const checkSpecialization = () => {
    if (filterDetails.specialization_id === null) return "";
    else
      return `&specialization_id=${btoa(filterDetails[0].specialization_id)}`;
  };

  const initialState = {
    ctc_max: filterData.ctc_max,
    ctc_min: 0,
    equity_max: 3.5,
    equity_min: 0,
    job_title: [],
    job_type: [],
    job_type_name: [],
    location: [],
    company_id: [],
    company_name: [],
    temp: "",
    skills: [],
    salary_payment_type: "PER ANNUM",
    filter: false,
  };
  const outerInitialState = {
    ctc_max: filterData.ctc_max,
    ctc_min: 0,
    equity_max: 3.5,
    equity_min: 0,
    job_title: [],
    job_type: [],
    job_type_name: [],
    location: [],
    company_id: [],
    company_name: [],
    temp: "",
    skills: [],
    salary_payment_type: "PER ANNUM",
    filter: false,
  };
  const [state, setState] = useState(initialState);
  const [outerState, setOuterState] = useState(outerInitialState);

  const handleClick = () => {
    // const token = localStorage.getItem("gw_token");
    // const data = JSON.parse(localStorage.getItem("user_details"));

    let temp = "";

    const generateCsv = (arr) => {
      let str = "";
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item + ",")
          : (str += item)
      );
      return str;
    };

    const checkJobTitle = () => {
      if (state.job_title.length > 0) {
        return `&id=${generateCsv(state.job_title)}`;
      } else return "";
    };

    const checkSkills = () => {
      if (state.skills.length > 0) {
        return `&skills=${generateCsv(state.skills)}`;
      } else return "";
    };

    const checkJobType = () => {
      if (state.job_type.length > 0) {
        return `&job_type=${generateCsv(state.job_type)}`;
      } else return "";
    };

    const checkCompany = () => {
      if (state.company_id.length > 0) {
        return `&company_id=${generateCsv(state.company_id)}`;
      } else return "";
    };

    const checkCity = () => {
      if (state.location.length > 0) {
        return `&location=${generateCsv(state.location)}`;
      } else return "";
    };

    GetData(
      BackendBaseApi.NIYUKTI,
      `job/student/job/?user_id=${window.btoa(
        data.id
      )}&college_id=${window.btoa(
        data.student_user_details.education[0].college
      )}&degree_id=${window.btoa(
        filterDetails[0].degree_id
      )}${checkSpecialization()}${checkJobTitle()}${checkSkills()}${checkJobType()}${checkCompany()}${checkCity()}`,
      { headers: token },
      setJobData
    );
    setOuterState(state);
  };

  const GetData = async (baseUrl, endPoint, body, updateState) => {
    let res = await httpRequest(baseUrl, endPoint, body);

    if (res.data.results.length) {
      setSingleJobData(res.data.results[0]);
      updateState(res.data.results);
    } else {
      updateState([]);
      setSingleJobData(null);
    }
    handleClose(!open);
  };

  const GetDataOut = async (baseUrl, endPoint, body, updateState) => {
    setLoading(true);
    let res = await httpRequest(baseUrl, endPoint, body);

    if (res.data.results.length) {
      setSingleJobData(res.data.results[0]);
      updateState(res.data.results);
    } else {
      updateState([]);
      setSingleJobData(null);
    }
    setLoading(false);
  };

  const callback = (value) => {
    handleClose(value);
  };

  const resetAll = () => {
    setState(initialState);
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/student/job/?user_id=${window.btoa(
        data.id
      )}&college_id=${window.btoa(
        data.student_user_details.education[0].college
      )}&degree_id=${window.btoa(
        filterDetails[0].degree_id
      )}${checkSpecialization()}`,
      { headers: token },
      setJobData
    );

    setTimeout(() => handleClose(!open), 3000);
  };
  const resetAllOut = () => {
    // const token = localStorage.getItem("gw_token");
    // const data = JSON.parse(localStorage.getItem("user_details"));
    setState(initialState);
    setOuterState(outerInitialState);

    GetDataOut(
      BackendBaseApi.NIYUKTI,
      `job/student/job/?user_id=${window.btoa(
        data.id
      )}&college_id=${window.btoa(
        data.student_user_details.education[0].college
      )}&degree_id=${window.btoa(
        filterDetails[0].degree_id
      )}${checkSpecialization()}`,
      { headers: token },
      setJobData
    );
  };

  const checkJobTypeChip = (id) => {
    return filterData.job_types.find((item) => item.job_type_id === id)
      .job_type_name;
  };

  const checkJobTitleChip = (id) => {
    return filterData.job_titles.find((item) => item.job_id === id).job_title;
  };

  const checkCompanyChip = (id) => {
    return filterData.companies.find((item) => item.company_id === id)
      .company_name;
  };

  // const deleteStateData = (key, value) => {
  //   let tempState = state;

  //   let index = tempState[key].findIndex((item) => item === value);
  //   if (index > -1) {
  //     tempState[key].splice(index, 1);
  //   }

  //   setState({ ...tempState });
  //   setOuterState({ ...tempState });
  // };

  return (
    <div className={classes.filterButton}>
      <Breakpoint large up>
        {outerState.filter && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<HighlightOffIcon />}
            style={{
              float: "right",
              color: "#3282c4",
              border: "1px solid #3282c4",
              marginTop: "10px",
            }}
            className="fw-700"
            onClick={resetAllOut}
          >
            Clear All
          </Button>
        )}
        <Button
          variant="outlined"
          style={{
            color: "#3282c4",
            border: "1px solid #3282c4",
            marginTop: "10px",
          }}
          startIcon={<FilterListIcon />}
          onClick={() => handleClose(true)}
        >
          Filters
        </Button>

        {outerState.filter ? (
          <>
            <Divider style={{ marginTop: "6px" }} />
            <Grid container>
              <Grid xs={12} className="mb-1" style={{ marginBottom: "10px" }}>
                {outerState.job_title.length > 0
                  ? outerState.job_title.map((item) => (
                      <span className="filter__tag">
                        {checkJobTitleChip(item)}{" "}
                        {/* <CloseIcon
                        onClick={() => deleteStateData("job_title", item)}
                      /> */}
                      </span>
                    ))
                  : null}
                {outerState.job_type.length > 0
                  ? outerState.job_type.map((item) => (
                      <span className="filter__tag">
                        {checkJobTypeChip(item)}{" "}
                        {/* <CloseIcon
                        onClick={() => deleteStateData("job_type", item)}
                      /> */}
                      </span>
                    ))
                  : null}
                {outerState.location.length > 0
                  ? outerState.location.map((item) => (
                      <span className="filter__tag">
                        {item}{" "}
                        {/* <CloseIcon
                        onClick={() => deleteStateData("location", item)}
                      /> */}
                      </span>
                    ))
                  : null}
                {outerState.skills.length > 0
                  ? outerState.skills.map((item) => (
                      <span className="filter__tag">
                        {item}
                        {/* <CloseIcon
                        onClick={() => deleteStateData("skills", item)}
                      /> */}
                      </span>
                    ))
                  : null}
                {outerState.company_id.length > 0
                  ? outerState.company_id.map((item) => (
                      <span className="filter__tag">
                        {checkCompanyChip(item)}{" "}
                        {/* <CloseIcon
                        onClick={() => deleteStateData("company_id", item)}
                      /> */}
                      </span>
                    ))
                  : null}

                {!outerState.filter && <div style={{ height: 50 }}> </div>}
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Breakpoint>
      <Breakpoint medium down style={{ width: "100%" }}>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {outerState.filter && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<HighlightOffIcon />}
                style={{
                  float: "right",
                  color: "#3282c4",
                  border: "1px solid #3282c4",
                  marginTop: "10px",
                }}
                className="fw-700"
                onClick={resetAllOut}
              >
                Clear All
              </Button>
            )}
            <Button
              variant="outlined"
              style={{
                color: "#3282c4",
                border: "1px solid #3282c4",
                marginTop: "10px",
              }}
              startIcon={<FilterListIcon />}
              onClick={() => handleClose(true)}
            >
              Filters
            </Button>
          </Box>
          <Box>
            {outerState.filter ? (
              <>
                <Divider style={{ marginTop: "6px" }} />
                <Grid container>
                  <Grid xs={12} className="mb-1" style={{ marginTop: "10px" }}>
                    {outerState.job_title.length > 0
                      ? outerState.job_title.map((item) => (
                          <span className="filter__tag">
                            {checkJobTitleChip(item)}{" "}
                            {/* <CloseIcon
                        onClick={() => deleteStateData("job_title", item)}
                      /> */}
                          </span>
                        ))
                      : null}
                    {outerState.job_type.length > 0
                      ? outerState.job_type.map((item) => (
                          <span className="filter__tag">
                            {checkJobTypeChip(item)}{" "}
                            {/* <CloseIcon
                        onClick={() => deleteStateData("job_type", item)}
                      /> */}
                          </span>
                        ))
                      : null}
                    {outerState.location.length > 0
                      ? outerState.location.map((item) => (
                          <span className="filter__tag">
                            {item}{" "}
                            {/* <CloseIcon
                        onClick={() => deleteStateData("location", item)}
                      /> */}
                          </span>
                        ))
                      : null}
                    {outerState.skills.length > 0
                      ? outerState.skills.map((item) => (
                          <span className="filter__tag">
                            {item}
                            {/* <CloseIcon
                        onClick={() => deleteStateData("skills", item)}
                      /> */}
                          </span>
                        ))
                      : null}
                    {outerState.company_id.length > 0
                      ? outerState.company_id.map((item) => (
                          <span className="filter__tag">
                            {checkCompanyChip(item)}{" "}
                            {/* <CloseIcon
                        onClick={() => deleteStateData("company_id", item)}
                      /> */}
                          </span>
                        ))
                      : null}

                    {!outerState.filter && <div style={{ height: 50 }}> </div>}
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Breakpoint>

      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={() => handleClose(!open)}
        maxWidth="md"
        classes={{ paperScrollPaper: classes.scrollPaper }}
      >
        <DialogContent>
          <Grid container justify="center">
            <Grid xs={fullView ? 12 : 9} style={{ marginTop: "0px" }}>
              {filterData !== null && (
                <FilterModal
                  fullView={fullView}
                  outerState={outerState}
                  setOuterState={setOuterState}
                  initialState={initialState}
                  resetAllOut={resetAllOut}
                  handleClose={callback}
                  handleClick={handleClick}
                  state={state}
                  setState={setState}
                  data={filterData}
                  handleClosey={callback}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FilterModal = ({
  data,
  state,
  fullView,
  handleClick,
  handleClose,
  setState,
  resetAllOut,
  initialState,
}) => {
  let allIDCompany = {};

  data.companies.map((obj) => {
    allIDCompany[obj.company_id] = obj.company_name;
  });
  let allIdJobType = {};
  data.job_types.map((obj) => {
    allIdJobType[obj.job_type_id] = obj.job_type_name;
  });

  const [valueeq, setvlaueeq] = useState([data.equity_min, data.equity_max]);
  const classes = useStyles();

  const [ctcValue, setCtcValue] = useState([data.ctc_min, data.ctc_max]);
  const handleChangeCtc = (event, newValue) => {
    setCtcValue(newValue);

    setState({ ...state, ctc_min: newValue[0], ctc_max: newValue[1] });
  };
  const handleChange = (event, newValue) => {
    setvlaueeq(newValue);

    setState({
      ...state,
      equity_min: newValue[0],
      equity_max: newValue[1],
    });
  };

  const deleteStateData = (key, value) => {
    let tempState = state;

    let index = tempState[key].findIndex((item) => item === value);
    if (index > -1) {
      tempState[key].splice(index, 1);
    }

    setState({ ...tempState });
  };

  const updateState = (key, value) => {
    setState({ ...state, filter: true });
    let tempState = state;

    if (!tempState[key].includes(value)) {
      tempState[key].push(value);
    }
    tempState.filter = true;

    setState({ ...tempState });
  };

  const updateStateCompany = (key, value) => {
    updateState("company_name", allIDCompany[value]);
    updateState("company_id", value);
  };
  const updateStateJobType = (key, value) => {
    updateState("job_type_name", allIdJobType[value]);
    updateState("job_type", value);
  };

  const checkJobTypeChip = (id) => {
    return data.job_types.find((item) => item.job_type_id === id).job_type_name;
  };

  const checkJobTitleChip = (id) => {
    return data.job_titles.find((item) => item.job_id === id).job_title;
  };

  const checkCompanyChip = (id) => {
    return data.companies.find((item) => item.company_id === id).company_name;
  };

  return (
    <div>
      <Paper
        className="scroll "
        justify="center"
        style={
          fullView
            ? {
                overflowY: "scroll",
                height: "auto",
                paddingBottom: "10px",
                boxShadow: "none",
              }
            : { textAlign: "center" }
        }
      >
        <Grid container className="pd-10">
          <Grid xs={fullView ? 12 : 9} style={{ marginTop: "0px" }}>
            {state.job_title.length > 0
              ? state.job_title.map((item) => (
                  <span className="filter__tag">
                    {checkJobTitleChip(item)}{" "}
                    <CloseIcon
                      onClick={() => deleteStateData("job_title", item)}
                    />
                  </span>
                ))
              : null}
            {state.job_type.length > 0
              ? state.job_type.map((item) => (
                  <span className="filter__tag">
                    {checkJobTypeChip(item)}{" "}
                    <CloseIcon
                      onClick={() => deleteStateData("job_type", item)}
                    />
                  </span>
                ))
              : null}
            {state.location.length > 0
              ? state.location.map((item) => (
                  <span className="filter__tag">
                    {item}{" "}
                    <CloseIcon
                      onClick={() => deleteStateData("location", item)}
                    />
                  </span>
                ))
              : null}
            {state.skills.length > 0
              ? state.skills.map((item) => (
                  <span className="filter__tag">
                    {item}{" "}
                    <CloseIcon
                      onClick={() => deleteStateData("skills", item)}
                    />
                  </span>
                ))
              : null}
            {state.company_id.length > 0
              ? state.company_id.map((item) => (
                  <span className="filter__tag">
                    {checkCompanyChip(item)}{" "}
                    <CloseIcon
                      onClick={() => deleteStateData("company_id", item)}
                    />
                  </span>
                ))
              : null}
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12}>
            <div>
              <p
                className="fw-700 text-black pd-10"
                style={{ fontSize: "16px" }}
              >
                Area of Interest
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid sm={5} xs={11}>
            <div className={classes.dropdownBox}>
              <p
                className="text-black"
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginRight: "10px",
                }}
              >
                Positon
              </p>
              <DropDown
                data={data.job_titles}
                value={state.job_title}
                handleChange={(event) =>
                  updateState("job_title", event.target.value)
                }
              />
            </div>
            <div className={classes.dropdownBox}>
              <p
                className="text-black"
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginRight: "10px",
                }}
              >
                Job Type
              </p>
              {/* <DropDown data={data.job_types} value={state.job_type} handleChange={(event)=>updateState('job_type',event.target.value)}/> */}
              <DropDownJobType
                data={data.job_types}
                value={state.job_type}
                handleChange={(event) =>
                  updateStateJobType("job_type", event.target.value)
                }
              />
            </div>
          </Grid>
          <Grid sm={5} xs={11}>
            <div>
              <h3 className="fs-16 fw-700 text-black"></h3>
            </div>
            <div className={classes.dropdownBox}>
              <p
                className="text-black"
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginRight: "10px",
                }}
              >
                Skills
              </p>
              <DropDownSkills
                data={data.skills}
                handleChange={(event) =>
                  updateState("skills", event.target.value)
                }
              />
            </div>
            <div className={classes.dropdownBox}>
              <p
                className="text-black"
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginRight: "10px",
                }}
              >
                Company
              </p>
              <DropDownCompany
                data={data.companies}
                value={state.company_id}
                handleChange={(event) =>
                  updateStateCompany("company_id", event.target.value)
                }
              />
            </div>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid sm={5} xs={11}>
            <div className="">
              <p
                className="fw-700 text-black pd-10"
                style={{ fontSize: "16px" }}
              >
                Job Location
              </p>
            </div>
            <div className={classes.dropdownBox}>
              <p
                className="text-black"
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginRight: "10px",
                }}
              >
                City
              </p>
              <DropDownCity
                data={data.locations}
                value={state.location}
                handleChange={(event) =>
                  updateState("location", event.target.value)
                }
              />
            </div>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid xs={12} className="pd-10 flex" justify="center">
            <Button
              variant="outlined"
              color="primary"
              style={{ float: "left", marginRight: "10px" }}
              className={classes.outlinedButton}
              onClick={() => {
                setState(initialState);
                handleClose(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{ float: "left", marginRight: "10px" }}
              className={classes.outlinedButton}
              onClick={resetAllOut}
            >
              Clear All
            </Button>
            <Hidden smDown>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                className={classes.containedButton}
              >
                View Results
              </Button>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden smUp>
          <Grid
            xs={12}
            style={{
              marginTop: "0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              className={classes.containedButton}
            >
              View Results
            </Button>
          </Grid>
        </Hidden>
      </Paper>
    </div>
  );
};

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

export function DropDown({ data, handleChange, value }) {
  // const[value2,setVlaue]=useState(value)
  const classes = useStyles();

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      style={{ width: 150, background: "white", height: "40px" }}
      className={classes.DropDown}
      classes={{ select: classes.dropdownFocus }}
      variant="outlined"
      value={value}
      onChange={handleChange}
    >
      {data.map((item) => (
        <MenuItem value={item.job_id}>{item.job_title}</MenuItem>
      ))}
    </Select>
  );
}

export function DropDownSkills({ data, handleChange, value }) {
  const classes = useStyles();

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      style={{ width: 150, background: "white", height: "40px" }}
      classes={{ select: classes.dropdownFocus }}
      className={classes.DropDown}
      variant="outlined"
      value={value}
      onChange={handleChange}
    >
      {data.map((item) => (
        <MenuItem value={item}>{item}</MenuItem>
      ))}
    </Select>
  );
}

export function DropDownCompany({ data, handleChange, value }) {
  const classes = useStyles();

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      style={{ width: 150, background: "white", height: "40px" }}
      classes={{ select: classes.dropdownFocus }}
      className={classes.DropDown}
      variant="outlined"
      value={value}
      onChange={handleChange}
    >
      {data.map((item) => (
        <MenuItem value={item.company_id}>{item.company_name}</MenuItem>
      ))}
    </Select>
  );
}

export function DropDownCity({ data, handleChange, value }) {
  const classes = useStyles();

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      style={{ width: 150, background: "white", height: "40px" }}
      classes={{ select: classes.dropdownFocus }}
      className={classes.DropDown}
      variant="outlined"
      value={value}
      onChange={handleChange}
    >
      {data.map((item) => (
        <MenuItem value={item}>{item}</MenuItem>
      ))}
    </Select>
  );
}

export function DropDownJobType({ data, handleChange, value }) {
  const classes = useStyles();

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      style={{ width: 150, background: "white", height: "40px" }}
      classes={{ select: classes.dropdownFocus }}
      className={classes.DropDown}
      variant="outlined"
      value={value}
      onChange={handleChange}
    >
      {data.map((item) => (
        <MenuItem value={item.job_type_id}>{item.job_type_name}</MenuItem>
      ))}
    </Select>
  );
}

import { Box, createStyles, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SelectSearch from "react-select-search";
import PreferencesNew from "../../../../components/company/Home/ProfileMenu/PreferencesNew";
import { BackendBaseApi, emailRegex } from "../../../../constants/constants";
import { getCreditInfo } from "../../../../GlobalComponents/CreditBoard/action";
import AuthHeader from "../UI/AuthHeader";
import Loader from "../UI/Loader";
import SideImage from "../UI/SideImage";
import SignupCondition from "./SignupCondition";
import SignupForm from "./SignupForm";

const useStyles = makeStyles((theme) =>
  createStyles({
    socialLoginCard: {
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: 4,
      display: "flex",
      marginBottom: "25px",
      height: "50px",
    },
    socialLogoBox: {
      width: "20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px 0px 0px 4px",
    },
    socialTextBox: {
      width: "80%",
      backgroundColor: "#000000",
      display: "flex",
      alignItems: "center",
      paddingLeft: 25,
      borderRadius: "0px 4px 4px 0px",
    },
    otherText: {
      fontSize: "16px",
      fontFamily: "ProximaNova",
      color: "#424242",
    },
    emailSignupText: {
      color: "#3282C4",
      fontWeight: 800,
      fontSize: 18,
      cursor: "pointer",
    },
    policyLinkText: {
      color: "#3282C4",
      fontWeight: 800,
      fontSize: 18,
      cursor: "pointer",
    },
    withEmailCont: {
      padding: "0% 24% 0% 30%",
    },
    "@media only screen and (max-width: 768px)": {
      withEmailCont: {
        padding: "0px",
      },
    },
  })
);
const SocialLogin = ({ logo, logoColor, text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.socialLoginCard}>
      <Box className={classes.socialLogoBox} style={{ backgroundColor: logoColor }}>
        <img src={logo} width="28px" height="auto" />
      </Box>
      <Box className={classes.socialTextBox}>
        <p
          style={{
            color: "#ffffff",
            fontFamily: "ProximaNova",
            fontSize: "16px",
            marginBottom: "0px",
          }}
        >
          {text}
        </p>
      </Box>
    </Box>
  );
};

const Signup = () => {
  const classes = useStyles();
  //prevent first render calls
  const firstRender_1 = useRef(true);
  const firstRender_2 = useRef(true);
  const firstRender_3 = useRef(true);

  //refs
  const disableButton = useRef();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  //UI state variables

  const history = useHistory();
  const location = useLocation();

  const alert = useAlert();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [showPassword, setShowPassword] = useState(false);
  const [fbAccessToken, setfbAccessToken] = useState("");
  const [googleAccessToken, setgoogleAccessToken] = useState("");

  let passwordFieldType = showPassword ? "text" : "password";
  let tooltipText = showPassword ? "Hide Password" : "Show Password";
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
  });

  //state variables
  const [userDetails, setUserDetails] = useState({
    Flag: false,
    UserID: null,
    FirstName: "",
    LastName: "",
    PhoneNo: null,
    Email: "",
    Password: "",
    Token: null,
    isemailVerified: false,
  });

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.id]: e.target.value });
  };

  const userType = useParams();

  useEffect(() => {
    setLoading(false);
    if (location.step) {
      setUserDetails({
        ...userDetails,
        UserID: location.userid,
        Email: location.email,
        isemailVerified: location.isemailVerified,
        Token: location.token,
      });
      setStep(location.step);
    }
    //disable next button
    if (!loading && step == 1) disableButton.current.classList.add("disabled");
  }, [location.step]);

  function digits_count(n) {
    var count = 0;
    if (n >= 1) ++count;

    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }

    return count;
  }

  const createUserProfile = () => {
    var flag = 0;

    if (digits_count(userDetails.PhoneNo) !== 10) flag = 1;
    if (emailRegex.test(userDetails.Email) === false) {
      flag = 2;
    }

    if (flag === 0) {
      setLoading(true);
      axios
        .post(
          BackendBaseApi.PRAVESH + "api/profile/create/",
          {
            email: userDetails.Email,
            first_name: userDetails.FirstName,
            last_name: userDetails.LastName,
            password: userDetails.Password,
            phone: userDetails.PhoneNo,
            user_type: "Company",
          },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.success) alert.success(res.data.data.message);
          else {
            alert.error(res.data.error);
            return;
          }

          let data = res.data.data;

          setUserDetails({
            ...userDetails,
            UserID: data.id,
            Token: data.token,
          });
          // getDetails(data.token);
          history.push({
            pathname: "/confirm-email",
            email: res.data.data.email,
            user_id: res.data.data.id,
          });
        })
        .catch((err) => {
          localStorage.clear();
          setLoading(false);
          alert.error("Invalid Session!! Please try again");
          throw err;
        });
    } else {
      if (flag === 1) alert.error("Enter a valid  10 digit Phone No.");
      if (flag === 2) alert.error("Enter valid Email Address");
    }
  };

  const createUserProfileStudent = () => {
    var flag = 0;

    // if (digits_count(userDetails.PhoneNo) !== 10) flag = 1;
    // if (emailRegex.test(userDetails.Email) === false) {
    //   flag = 2;
    // }

    if (flag === 0) {
      setLoading(true);
      axios
        .post(
          BackendBaseApi.PRAVESH + "api/profile/create/",
          {
            email: userDetails.Email,
            first_name: userDetails.FirstName,
            last_name: userDetails.LastName,
            password: userDetails.Password,
            phone: userDetails.PhoneNo,
            user_type: "Student",
          },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);

          if (res.data.success) alert.success(res.data.data.message);
          else {
            alert.error(res.data.error);
            setErrorMessages({
              ...errorMessages,
              email: "Email Address already exists",
            });
            return;
          }

          let data = res.data.data;

          setUserDetails({
            ...userDetails,
            UserID: data.id,
            Token: data.token,
          });
          getDetails(data.token);

          // setStep(step + 1);
        })
        .catch((err) => {
          localStorage.clear();
          setLoading(false);
          alert.error("Invalid Session!! Please try again");
          throw err;
        });
    } else {
      if (flag === 1) alert.error("Enter a valid  10 digit Phone No.");
      if (flag === 2) alert.error("Enter valid Email Address");
    }
  };

  const getDetails = (token) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.user_type[0].type === 1) {
          localStorage.setItem("gw_token", token);
          localStorage.setItem("user_type_id", res.data.user_type[0].type);
          localStorage.setItem("user_type", "Student");

          localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
          //student without verified email, go to home

          if (res.data.is_pref_filled) history.push("/student/dashboard");
          else
            history.push({
              pathname: "/student/complete-profile",
              flag: 12367,
            });
        }
      });
  };
  const checkUser = (token) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          if (!res.data.email_verified) {
            //move to signup options component
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              email: res.data.email,
              token,
              isemailVerified: false,
            });
          } else {
            //move to signup options component
            setStep(step + 2);
            setLoading(false);
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              token,
              email: res.data.email,
              isemailVerified: true,
            });
          }
        } else {
          localStorage.setItem("user_type", res.data.user_type[0].main_user);
          localStorage.setItem("user_type_id", res.data.user_type[0].type);
          localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

          if (res.data.email_verified) {
            localStorage.setItem("gw_token", token);
            localStorage.setItem("user_type_id", res.data.user_type[0].type);

            localStorage.setItem("user_id", res.data.user_type[0].user_id);
            localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

            //check if its student or company
            if (res.data.user_type[0].user_id == 1) {
              //student with verified email, go to home

              history.push("/student/dashboard");
            } else {
              if (res.data.is_pref_filled) {
                if (!res.data.company_details.admin_approved) {
                  localStorage.setItem(
                    "user",
                    JSON.stringify({
                      email: res.data.email,
                      first_name: res.data.first_name,
                      last_name: res.data.last_name,
                      id: res.data.id,
                      profile_picture: null,
                    })
                  );

                  history.push({
                    pathname: "/company/admin-approve",
                    flag: 19087,

                    company: res.data.company_details.company_name,
                    admin_email: res.data.company_details.admin_email,
                    admin_name: res.data.company_details.admin_name,
                    is_admin: res.data.is_company_admin,
                  });
                } else {
                  if (res?.data?.company_details !== null) localStorage.setItem("company", JSON.stringify(res.data.company_details));

                  dispatch(getCreditInfo(setLoader));
                  if (location.flag === 28071998) history.push("/company/post-job");
                  else {
                    if (res.data.user_type[0].type === 5) {
                      localStorage.setItem("comp_pref_filled", res.data.comp_pref_filled);
                      history.push({ pathname: "/company/dashboard", comp_pref_filled: res.data.comp_pref_filled });
                    } else {
                      history.push({ pathname: "/company/dashboard" });
                    }
                  }
                }
              } else {
                localStorage.setItem("mobile", res.data.mobile);
                history.push("/company/complete-profile");
              }
            }

            // else {
            //   //company user with verified email, check if admin approved
            //   if (!res.data.company_details.admin_approved) {
            //     history.push({
            //       pathname: "/company/admin-approve",
            //       company: res.data.company_details.company_name,
            //       admin_email: res.data.company_details.admin_email,
            //       admin_name: res.data.company_details.admin_name,
            //       is_admin: res.data.is_company_admin,
            //     });
            //   } else history.push("/company/dashboard");
            // }
          } else {
            //check if its student or company
            if (res.data.user_type[0].type == 1) {
              localStorage.setItem("gw_token", token);
              localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

              //student without verified email, go to home

              if (res.data.is_pref_filled) history.push("/student/dashboard");
              else
                history.push({
                  pathname: "/student/complete-profile",
                  flag: 12367,
                });
            } else {
              //company user without verified email
              history.push({
                pathname: "/confirm-email",
                email: res.data.email,
                user_id: res.data.user_type[0].user_id,
              });
            }
          }
        }

        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        localStorage.clear();
        throw err;
      });
  };
  const handleSubmit = () => {
    if (userType.token === "company") createUserProfile();
    else createUserProfileStudent();
  };

  const responseGoogle = (response) => {
    setgoogleAccessToken(response.accessToken);
  };
  const responseFacebook = (response) => {
    setfbAccessToken(response.accessToken);
  };

  useEffect(() => {
    let token = "";
    if (firstRender_1.current) {
      firstRender_1.current = false;
      return;
    }
    if (fbAccessToken)
      axios
        .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
          },
          withCredentials: true,
          provider: "facebook",
          access_token: fbAccessToken,
          user_type: userType.token,
        },
        )
        .then((res) => {
          token = res.data.data.token;
          if (res.status === 200) {
            localStorage.setItem("gw_token", token);
            alert.success(res.data.data.message);
            setLoading(true);
            checkUser(token);
          } else {
            alert.error("No Valid response");
          }
        })
        .catch((err) => {
          alert.error("Error");
          throw err;
        });
  }, [fbAccessToken]);

  useEffect(() => {
    let token = "";
    if (firstRender_2.current) {
      firstRender_2.current = false;
      return;
    }
    if (googleAccessToken)
      axios
        .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
          },
          withCredentials: true,
          provider: "google-oauth2",
          access_token: googleAccessToken,
          user_type: userType.token,
        })
        .then((res) => {
          if (res.data.success) {
            token = res.data.data.token;

            localStorage.setItem("gw_token", token);
            alert.success(res.data.data.message);
            setLoading(true);
            checkUser(token);
          } else {
            alert.error("Error");
          }
        })
        .catch((err) => {
          alert.error("Error");
          throw err;
        });
  }, [googleAccessToken]);

  const [jobRole, setJobRole] = useState("");
  const [jobRolesList, setJobRolesList] = useState({});

  let jobRoles = Object.keys(jobRolesList).map((jobrole) => {
    return { name: jobrole, value: jobrole };
  });

  const JobRolesDropdown = () => <SelectSearch options={jobRoles} value={jobRole} onChange={setJobRole} placeholder="Select your Job Role" />;

  useEffect(() => {
    // setLoading(true);
    // ?domain='+companyUserDetails.
    getJobRoles();
  }, []);

  const getJobRoles = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/user_type/?main_user=company")
      .then((res) => {
        let temp = {};
        res.data.data.forEach((data) => {
          if (data.id !== 5) temp[data.sub_user] = data.id;
        });
        setJobRolesList({ ...temp });
      })
      .catch((err) => {
        throw err;
      });
  };

  const [compType, setCompType] = useState();
  const [no_companies, setNoCompanies] = useState(false);
  const [comp, setComp] = useState({});

  const comps = Object.keys(comp).map((company_id) => {
    return { name: company_id, value: company_id };
  });

  const [preferencesData, setPreferencesData] = useState([]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div style={{ background: "#ffffff" }} className="col-md-8 col-lg-8 y-scroll full-page-view">
            <div className="row">
              <AuthHeader />
            </div>
            <div className="login d-flex align-items-center py-0 mx-md-0 mx-0 px-md-0 px-2">
              <div className="container" style={{ marginTop: "70px" }}>
                <div className="row">
                  <div className="col-md-9 col-lg-9 mx-auto">
                    {step === 0 && (
                      <Fragment>
                        {/* <div className="row justify-content-center mt-md-0 mt-0 pt-2"></div> */}

                        <SignupCondition responseGoogle={responseGoogle} responseFacebook={responseFacebook} setStep={setStep} type={userType.token} />
                      </Fragment>
                    )}
                    {step === 1 && (
                      <>
                        <div
                          className="row mt-md-0 mt-4"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backdropFilter: "blur(30px)",
                            boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                            border: "solid 1px #f1f1f1",
                            borderRadius: 30,
                            padding: 20,
                          }}
                        >
                          <SignupForm
                            userDetails={userDetails}
                            setUserDetails={setUserDetails}
                            errorMessages={errorMessages}
                            setErrorMessages={setErrorMessages}
                            type={userType.token}
                            handleSubmit={handleSubmit}
                          />
                        </div>

                        {step === 2 && (
                          <>
                            <PreferencesNew data={preferencesData} setPreferencesData={setPreferencesData} />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* <Footer /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;

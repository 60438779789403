import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
  },
}));

export default function PublicationsNew({ data }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography variant="h5">Publications</Typography>
        </div>

        {data?.map((item) => (
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div>
              <div className={classes.div}>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(item?.link);
                  }}
                >
                  View Live
                </Typography>
              </div>

              <Typography
                variant="body2"
                style={{
                  color: "#6C757D",
                }}
              >
                {item?.description}{" "}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

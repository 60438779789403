import React, { useState, useEffect } from "react";
import SideImage from "../../../common/components/UI/SideImage";
import AuthHeader from "../../../common/components/UI/AuthHeader";
import { useLocation, useHistory } from "react-router-dom";
import Preferences from "./Preferences";
import JoinCompany from "./JoinCompany";
import CreateCompany from "./CreateCompany";

const CompanySignup = () => {
    const location = useLocation();
    const history = useHistory();

    const [signupStep, setSignupStep] = useState(1);
    const [companyUserDetails, setCompanyUserDetails] = useState({
        Type: 5,
        CompanyUserID: location.userID,
        FirstName: "",
        LastName: "",
        PhoneNo: "",
        Email: location.email,
        Password: "",
        CollegeID: null,
        CollegeName: "",
        GraduatingYear: "",
        JobTitle: "",
        CandidatePreferences: [],
        JobPreferences: [],
        isThirdPartyRecruiter: false,
        Token: location.token,
    });

    useEffect(() => {
        if (!location.userID) history.push("/login");
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row no-gutter full-page-view">
                    <SideImage />
                    <div
                        className="col-md-8 col-lg-8 y-scroll full-page-view"
                        style={{ backgroundColor: "#ffffff" }}
                    >
                        <div className="row">
                            <AuthHeader />
                        </div>
                        <div className="login d-flex align-items-center pb-5 mx-md-0 mx-3 px-md-0 px-2">
                            <div
                                className="container"
                                style={{ marginTop: "12px" }}
                            >
                                <div className="row">
                                    <div className="col-md-9 col-lg-6 mx-auto">
                                        {signupStep == 1 && (
                                            <Preferences
                                                companyUserDetails={
                                                    companyUserDetails
                                                }
                                                setCompanyUserDetails={
                                                    setCompanyUserDetails
                                                }
                                                step={signupStep}
                                                setStep={setSignupStep}
                                            />
                                        )}
                                        {signupStep == 2 && (
                                            <JoinCompany
                                                companyUserDetails={
                                                    companyUserDetails
                                                }
                                                setCompanyUserDetails={
                                                    setCompanyUserDetails
                                                }
                                                step={signupStep}
                                                setStep={setSignupStep}
                                            />
                                        )}

                                        {signupStep == 3 && (
                                            <CreateCompany
                                                companyUserDetails={
                                                    companyUserDetails
                                                }
                                                setCompanyUserDetails={
                                                    setCompanyUserDetails
                                                }
                                                step={signupStep}
                                                setStep={setSignupStep}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompanySignup;

import React, { useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import c1 from "../../../assets/png/companylist/c1.png";
import c2 from "../../../assets/png/companylist/c2.png";
import c3 from "../../../assets/png/companylist/c3.png";
import c4 from "../../../assets/png/companylist/c4.png";
import c5 from "../../../assets/png/companylist/c5.png";
import c6 from "../../../assets/png/companylist/c6.png";
import c7 from "../../../assets/png/companylist/c7.png";
import c8 from "../../../assets/png/companylist/c8.png";
import c9 from "../../../assets/png/companylist/c9.png";
import c10 from "../../../assets/png/companylist/c10.png";
import c11 from "../../../assets/png/companylist/c11.png";

export default function CompanySlider() {
  return (
    <>
      <AliceCarousel
        disableDotsControls
        autoPlay
        autoPlayStrategy="none"
        autoPlayInterval={1000}
        animationDuration={1000}
        animationType="fadeout"
        infinite
        disableButtonsControls
        responsive={{
          0: {
            items: 3,
          },
          550: {
            items: 3,
          },
          700: {
            items: 3,
          },
          1024: {
            items: 4,
          },
        }}
        items={items}
      />
    </>
  );
}

const items = [
  <img src={c1} height="auto" width="90px" style={{ marginTop: "5px" }} />,
  <img src={c2} height="auto" width="90px" style={{ marginTop: "5px" }} />,
  <img src={c3} height="auto" width="100px" style={{ marginTop: "5px" }} />,
  <img src={c4} height="auto" width="90px" style={{ marginTop: "5px" }} />,
  <img src={c5} height="auto" width="80px" style={{ marginTop: "5px" }} />,
  <img src={c6} height="auto" width="60px" style={{ marginTop: "5px" }} />,
  <img src={c7} height="auto" width="80px" style={{ marginTop: "5px" }} />,
  <img src={c8} height="auto" width="100px" style={{ marginTop: "5px" }} />,
  // <img src={c9} height="auto" width="100px" />,
  <img src={c10} height="auto" width="100px" style={{ marginTop: "5px" }} />,
  <img src={c11} height="auto" width="100px" />,
];

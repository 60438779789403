import React, { useEffect, useState } from "react";

const salaryRange = (state) => {
  if (state.Details.salary_type === "PAID") {
    if (parseInt(state.Details.ctc_max) < parseInt(state.Details.ctc_min)) {
      return true; // alert.error('PleaseEnter right CTC package.')
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default salaryRange;

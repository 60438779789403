import React, { useState, useEffect } from "react";
import "./FormComponents.css";
// import moment from 'moment';
import { Tooltip, TextField, InputLabelProps } from "@material-ui/core";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import Axios from "axios";
import { BackendBaseApi, passingYears } from "../../../../constants/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { uniqueArray } from "../../../../utils/common/filterJob";
import CommonButton from "../../CommonButton/CommonButton";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

function EducationDetails({ educations, setEducations, alert, educationBoxOpen, positions }) {
  // const [allEducations, setAllEducations] = useState([]);
  const [allColleges, setAllColleges] = useState([]);
  const [allBoards, setAllBoards] = useState([]);
  const [allDegrees, setAllDegrees] = useState([]);
  const [allSpecializations, setAllSpecializations] = useState([]);

  const [educationType, setEducationType] = useState(null);
  const [college, setCollege] = useState(null);
  const [collegeName, setCollegeName] = useState(null);
  const [isCollegeListed, setIsCollegeListed] = useState(false);
  const [isCurrent, setIsCurrent] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [year, setYear] = useState(null);
  const [board, setBoard] = useState(null);
  const [degree, setDegree] = useState(null);
  const [specialization, setSpecialization] = useState(null);
  const [count, setCount] = useState();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    setCount(educations.length);
  }, [educations]);

  const classes = useStyles();

  let checkClg = () => {
    if (college && college.is_onboarded) return `&college_id=${college.id}`;
    else return "";
  };

  // All Colleges in DB
  useEffect(() => {
    // if (educationType) {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/college/")
      .then((res) => {
        setAllColleges(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Education Boards in DB
  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/board")
      .then((res) => {
        if (res.data.success) {
          setAllBoards(res.data.data.board_list);
        } else {
          alert.error("Unable to fetch Education Board from Server!", { position: positions.TOP_RIGHT });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Education Degrees in DB
  useEffect(() => {
    if (!educationType) return;
    if (educationType.education_id <= 2) return;
    !isEditOpen && setDegree(null);
    !isEditOpen && setSpecialization(null);
    setAllDegrees([]);
    setAllSpecializations([]);
    Axios.get(BackendBaseApi.PRAVESH + `api/education/get_degree?education_level=${educationType.education_id}${checkClg()}`)
      .then((res) => {
        if (res.data.success) {
          setAllDegrees(res.data.data);
        } else {
          alert.error("Unable to fetch Degrees from Server!", { position: positions.TOP_RIGHT });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [college, isCollegeListed, educationType]);

  // Education Specializations in DB
  useEffect(() => {
    if (!degree) return;
    !isEditOpen && setSpecialization(null);
    setAllSpecializations([]);
    Axios.get(BackendBaseApi.PRAVESH + `api/education/get_specialization?degree_id=${degree.id}${checkClg()}`)
      .then((res) => {
        if (res.data.success) {
          setAllSpecializations(res.data.data);
        } else {
          alert.error("Unable to fetch Specialization from Server!", { position: positions.TOP_RIGHT });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degree]);

  useEffect(() => {
    if (educationBoxOpen.success) {
      editBtn(educationBoxOpen.id);
    }
  }, [educationBoxOpen]);

  let years = passingYears.map((p) => p.year);

  const clearStates = () => {
    setEducationType(null);
    setCollege(null);
    setCollegeName("");
    setIsCollegeListed(false);
    setBoard(null);
    setDegree(null);
    setSpecialization(null);
    setIsCurrent(false);
    setPercentage("");
    setYear(null);
  };

  const addEducation = (e) => {
    e.preventDefault();
    if (!educationType) {
      alert.error("Select Education to Continue !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!year) {
      alert.error("Select Year of Passing !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!percentage) {
      alert.error("Percentage Required !", { position: positions.TOP_RIGHT });
      return;
    }
    if (percentage < 0 || percentage > 100) {
      alert.error("Percentage must be in range of 0 to 100 ", { position: positions.TOP_RIGHT });
      return;
    }
    if (educationType.education_id > 2) {
      if (!isCollegeListed && !college) {
        alert.error("Select College from list !", { position: positions.TOP_RIGHT });
        return;
      }
      if (isCollegeListed && !collegeName) {
        alert.error("Enter College Name to proceed !", { position: positions.TOP_RIGHT });
        return;
      }
      if (!degree) {
        alert.error("Select degree from dropdown !", { position: positions.TOP_RIGHT });
        return;
      }
      if (allSpecializations.length > 0 && !specialization) {
        alert.error("Select specialization from dropdown !", { position: positions.TOP_RIGHT });
        return;
      }
    }
    if (isCurrent) {
      let flag = false;
      educations.forEach((edu) => {
        if (edu.is_current) flag = true;
      });
      if (flag) {
        alert.error("You cannot have 2 Highest Qualifications !", { position: positions.TOP_RIGHT });
        return;
      }
    }
    let arr = [...educations];
    let temp = {
      id: count,
      education_type: educationType.education_id,
      education_name: educationType.education_name,
      college_id: college ? college.id : null,
      college_name: college ? college.name : collegeName,
      is_current: isCurrent,
      percentage: percentage,
      year_of_passing: `${year.year}-01-01`,
      degree_id: degree ? degree.id : null,
      specialization_id: specialization ? specialization.specialization_id : null,
      degree_name: degree ? degree.name : null,
      specialization_name: specialization ? specialization.specialization_name : null,
      board_id: board ? board.id : null,
      board_name: board ? board.name : null,
      is_valid: true,
    };
    arr.push(temp);
    setEducations(uniqueArray(arr));
    setCount(count + 1);
    clearStates();
    window.document.getElementById("education-details-enter").className = "collapse";
  };

  const educationTypes = [
    { education_id: 1, education_name: "Secondary" },
    { education_id: 2, education_name: "Senior Secondary" },
    { education_id: 3, education_name: "Graduation (UG)" },
    { education_id: 4, education_name: "Post Graduation (PG)" },
    { education_id: 5, education_name: "PhD" },
    { education_id: 6, education_name: "Diploma" },
  ];

  const handleDelete = (boxId) => {
    var arr = educations.filter((ed) => ed.id !== boxId);
    setEducations(arr);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (!educationType) {
      alert.error("Select Education to Continue !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!year) {
      alert.error("Select Year of Passing !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!years.includes(year.year)) {
      alert.error("Select Year from dropdown!", { position: positions.TOP_RIGHT });
      return;
    }
    if (!percentage) {
      alert.error("Percentage Required !", { position: positions.TOP_RIGHT });
      return;
    }
    if (percentage < 0 || percentage > 100) {
      alert.error("Percentage must be in range of 0 to 100 ", { position: positions.TOP_RIGHT });
      return;
    }
    if (educationType.education_id > 2) {
      if (!isCollegeListed && !college) {
        alert.error("Select College from list !", { position: positions.TOP_RIGHT });
        return;
      }
      if (isCollegeListed && !collegeName) {
        alert.error("Enter College Name to proceed !", { position: positions.TOP_RIGHT });
        return;
      }
      if (!degree) {
        alert.error("Select degree from dropdown !", { position: positions.TOP_RIGHT });
        return;
      }
      if (allSpecializations.length > 0 && !specialization) {
        alert.error("Select specialization from dropdown !", { position: positions.TOP_RIGHT });
        return;
      }
    }
    if (isCurrent) {
      let flag = false;
      educations.forEach((edu) => {
        if (edu.is_current && edu.id !== editId) flag = true;
      });
      if (flag) {
        alert.error("You cannot have 2 Highest Qualifications !", { position: positions.TOP_RIGHT });
        return;
      }
    }
    let arr = [...educations];
    let temp = {
      id: editId,
      education_type: educationType.education_id,
      education_name: educationType.education_name,
      college_id: college ? college.id : null,
      college_name: college ? college.name : collegeName,
      is_current: isCurrent,
      percentage: percentage,
      year_of_passing: `${year.year}-01-01`,
      degree_id: degree ? degree.id : null,
      specialization_id: specialization ? specialization.specialization_id : null,
      degree_name: degree ? degree.name : null,
      specialization_name: specialization ? specialization.specialization_name : null,
      board_id: board ? board.id : null,
      board_name: board ? board.name : null,
      is_valid: true,
    };
    let a = arr.map((obj) => (obj.id === editId ? temp : obj));
    setEducations(uniqueArray(a));
    cancelEdit();
  };

  const editBtn = (id) => {
    let arr = educations.filter((edu) => edu.id === id);
    let temp = arr[0];
    console.log(temp);
    setIsEditOpen(true);
    setEditId(id);
    setEducationType({
      education_id: temp.education_type,
      education_name: temp.education_name,
    });
    setCollege(
      temp.college_id
        ? {
            id: temp.college_id,
            name: temp.college_name,
          }
        : null
    );
    setDegree(
      temp.degree_id
        ? {
            id: temp.degree_id,
            name: temp.degree_name,
          }
        : null
    );
    setSpecialization(
      temp.specialization_id
        ? {
            specialization_id: temp.specialization_id,
            specialization_name: temp.specialization_name,
          }
        : null
    );
    setCollegeName(temp.temp_college_name || temp.college_name);
    setIsCollegeListed(temp.college_id ? false : true);

    setBoard(
      temp.board_id
        ? {
            id: temp.board_id,
            name: temp.board_name,
          }
        : null
    );
    setIsCurrent(temp.is_current);
    setPercentage(temp.percentage || "");
    setYear(temp.year_of_passing ? { year: temp.year_of_passing.substring(0, 4) } : null);
    window.document.getElementById("education-details-enter").className = "collapse show";
    window.document.getElementById("education_section").scrollIntoView(true);
  };

  const cancelEdit = () => {
    window.document.getElementById("education-details-enter").className = "collapse";
    setIsEditOpen(false);
    setEditId(null);
    clearStates();
  };

  return (
    <div id="educations-form">
      <div className="collapse" id="education-details-enter">
        <div>
          <div id="add_new_autocomplete_div">
            <Autocomplete
              id="tags-outlined"
              className="type_search_autocomplete_two"
              options={educationTypes}
              getOptionLabel={(option) => option.education_name}
              onChange={(e, i) => {
                i ? setEducationType(i) : setEducationType(null);
              }}
              value={educationType}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Select Education *" />}
            />
            <Autocomplete
              id="tags-outlined"
              options={passingYears}
              getOptionLabel={(option) => option.year}
              className="type_search_autocomplete_two"
              onChange={(e, i) => {
                i ? setYear(i) : setYear(null);
              }}
              value={year}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Year of Passing *" />}
            />
          </div>
          {educationType?.education_id > 2 && (
            <>
              <div id="add_new_div">
                {!isCollegeListed ? (
                  <Autocomplete
                    id="tags-outlined"
                    options={allColleges}
                    className="type_search_autocomplete_half"
                    getOptionLabel={(option) => option.name}
                    onChange={(e, i) => {
                      i ? setCollege(i) : setCollege(null);
                    }}
                    value={college}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Select College *" />}
                  />
                ) : (
                  <TextField
                    type="text"
                    required
                    variant="outlined"
                    label="College Name"
                    style={{ marginBottom: "2rem" }}
                    value={collegeName}
                    onChange={(e) => setCollegeName(e.target.value)}
                  />
                )}
                <TextField type="number" required variant="outlined" label="Percentage" value={percentage} onChange={(e) => setPercentage(e.target.value)} />
              </div>
              <div id="add_checkbox">
                <FormControlLabel
                  style={{ color: "#3a3838", marginLeft: "4px" }}
                  control={
                    <Checkbox
                      checked={isCollegeListed}
                      onChange={(e) => {
                        setIsCollegeListed(!isCollegeListed);
                        setCollege(null);
                        setCollegeName(null);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="College Not Listed ?"
                />
              </div>
              <div id="add_new_autocomplete_div">
                <Autocomplete
                  id="tags-outlined"
                  className="type_search_autocomplete_two"
                  options={allDegrees}
                  disabled={college || isCollegeListed ? false : true}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, i) => {
                    i ? setDegree(i) : setDegree(null);
                  }}
                  value={degree}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Select Degree *" />}
                />
                <Autocomplete
                  id="tags-outlined"
                  options={allSpecializations}
                  getOptionLabel={(option) => option.specialization_name}
                  className="type_search_autocomplete_two"
                  disabled={(college || isCollegeListed) && degree ? false : true}
                  onChange={(e, i) => {
                    i ? setSpecialization(i) : setSpecialization(null);
                  }}
                  value={specialization}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Select Specialization" />}
                />
              </div>
            </>
          )}
          {educationType?.education_id <= 2 && (
            <div id="add_new_div">
              <Autocomplete
                id="tags-outlined"
                options={allBoards}
                className="type_search_autocomplete_half"
                getOptionLabel={(option) => option.name}
                onChange={(e, i) => {
                  i ? setBoard(i) : setBoard(null);
                }}
                value={board}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Select Board" />}
              />
              <TextField type="number" required variant="outlined" label="Percentage" value={percentage} onChange={(e) => setPercentage(e.target.value)} />
            </div>
          )}
          {educationType?.education_id > 2 && (
            <div>
              <FormControlLabel
                style={{ color: "#3a3838", marginLeft: "4px" }}
                control={<Checkbox checked={isCurrent} onChange={(e) => setIsCurrent(!isCurrent)} name="checkedB" color="primary" />}
                label="Is this your Highest Qualification ?"
              />
            </div>
          )}
        </div>
        {isEditOpen ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CommonButton variant="contained" style={{ display: "block", margin: "1rem 8px", outline: "none", backgroundColor: "#e0e0e0" }} onClick={() => cancelEdit()}>
              Cancel
            </CommonButton>
            <CommonButton variant="contained" style={{ display: "block", margin: "1rem 8px", outline: "none" }} onClick={(e) => handleEdit(e)}>
              Update
            </CommonButton>
          </div>
        ) : (
          <CommonButton variant="contained" style={{ display: "block", margin: "1rem auto", outline: "none" }} onClick={(e) => addEducation(e)}>
            Add Education
          </CommonButton>
        )}
      </div>
      <div>
        {educations.length > 0 ? (
          <div id="box_value" style={{ marginLeft: "1%" }}>
            {educations.map((edu, key) => {
              return (
                <div id="card_outer_div" style={!edu.is_valid ? { border: "1px solid #dc3545", backgroundColor: "#fff6ff" } : {}}>
                  {/* Heading & Delete */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginBottom: "0.6rem" }}>
                    <div>
                      <span>
                        <b>Education {key + 1}</b>
                      </span>
                    </div>
                    <div style={{ marginRight: "0.5rem", cursor: "pointer" }}>
                      <Tooltip title="Edit Education" placement="right">
                        <span
                          style={{ marginRight: "1rem" }}
                          onClick={(e) => {
                            editBtn(edu.id);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete Education" placement="right">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleDelete(edu.id);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  {/* Qualification & Percentage */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    {edu.education_name && (
                      <div>
                        <span>
                          <b>Qualification: </b> {edu.education_name} {edu.is_current && `*`}
                        </span>
                      </div>
                    )}
                    {edu.percentage && (
                      <div>
                        <span>
                          <b>Percentage: </b> {edu.percentage}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* Class 10th & 12th */}
                  {edu.education_type <= 2 && (
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                      {edu.board_name && (
                        <div>
                          <span>
                            <b>Board: </b> {edu.board_name}
                          </span>
                        </div>
                      )}
                      {edu.year_of_passing && (
                        <div>
                          <span>
                            <b>Passing Year: </b> {edu.year_of_passing.substring(0, 4)}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {/* Grad, Post Grad, PhD */}
                  {edu.education_type > 2 && (
                    <>
                      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        {(edu.temp_college_name || edu.college_name) && (
                          <div>
                            <span>
                              <b>College: </b> {edu.temp_college_name || edu.college_name}
                            </span>
                          </div>
                        )}
                        {edu.year_of_passing && (
                          <div>
                            <span>
                              <b>Passing Year: </b> {edu.year_of_passing.substring(0, 4)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        {edu.degree_name && (
                          <div>
                            <span>
                              <b>Degree: </b> {edu.degree_name}
                            </span>
                          </div>
                        )}
                        {edu.specialization_name && (
                          <div>
                            <span>
                              <b>Specialization: </b> {edu.specialization_name}
                            </span>{" "}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div id="no_content">
            <span>Add your Education Details !!</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default EducationDetails;

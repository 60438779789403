import React from "react";
import { Button, Typography, SvgIcon, Divider } from "@material-ui/core";

function ExpiredIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4 18.9L15.75 9.25C16.3167 8.78333 16.9583 8.425 17.675 8.175C18.3917 7.925 19.1667 7.8 20 7.8C21.9333 7.8 23.5583 8.45833 24.875 9.775C26.1917 11.0917 26.85 12.7167 26.85 14.65C26.85 15.4833 26.725 16.2583 26.475 16.975C26.225 17.6917 25.8667 18.3333 25.4 18.9ZM7.1 31.25C9.2 29.7833 11.2583 28.6583 13.275 27.875C15.2917 27.0917 17.5333 26.7 20 26.7C21.2333 26.7 22.325 26.7917 23.275 26.975C24.225 27.1583 25.0833 27.3833 25.85 27.65L19.7 21.5C17.9 21.4333 16.4083 20.825 15.225 19.675C14.0417 18.525 13.3667 17.05 13.2 15.25L6.9 8.95C5.66667 10.45 4.70833 12.1083 4.025 13.925C3.34167 15.7417 3 17.7667 3 20C3 22.0333 3.31667 23.9667 3.95 25.8C4.58333 27.6333 5.63333 29.45 7.1 31.25ZM33.15 30.95C34.3167 29.55 35.25 27.925 35.95 26.075C36.65 24.225 37 22.2 37 20C37 15.1667 35.375 11.125 32.125 7.875C28.875 4.625 24.8333 3 20 3C17.6667 3 15.6 3.35 13.8 4.05C12 4.75 10.4 5.66667 9 6.8L33.15 30.95ZM20 40C17.2667 40 14.6833 39.475 12.25 38.425C9.81667 37.375 7.69167 35.9417 5.875 34.125C4.05833 32.3083 2.625 30.1833 1.575 27.75C0.525 25.3167 0 22.7333 0 20C0 17.2333 0.525 14.6417 1.575 12.225C2.625 9.80833 4.05833 7.69167 5.875 5.875C7.69167 4.05833 9.81667 2.625 12.25 1.575C14.6833 0.525 17.2667 0 20 0C22.7667 0 25.3583 0.525 27.775 1.575C30.1917 2.625 32.3083 4.05833 34.125 5.875C35.9417 7.69167 37.375 9.80833 38.425 12.225C39.475 14.6417 40 17.2333 40 20C40 22.7333 39.475 25.3167 38.425 27.75C37.375 30.1833 35.9417 32.3083 34.125 34.125C32.3083 35.9417 30.1917 37.375 27.775 38.425C25.3583 39.475 22.7667 40 20 40ZM20 37C21.9667 37 23.7917 36.7167 25.475 36.15C27.1583 35.5833 28.85 34.6667 30.55 33.4C28.85 32.2 27.1167 31.2833 25.35 30.65C23.5833 30.0167 21.8 29.7 20 29.7C18.2 29.7 16.4167 30.0167 14.65 30.65C12.8833 31.2833 11.15 32.2 9.45 33.4C11.15 34.6667 12.85 35.5833 14.55 36.15C16.25 36.7167 18.0667 37 20 37Z"
        fill="#FF0000"
      />
    </SvgIcon>
  );
}
const openChatBot = () => {
  window.Wotnot.open();
};

export default function ChatWithUs() {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <ExpiredIcon style={{ fontSize: "45px" }} />

        <Typography
          variant="body1"
          style={{
            fontWeight: "500",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "10px",
            color: "#808080",
          }}
        >
          Your account has been temporarily suspended by GetWork.
        </Typography>

        <Typography
          variant="body1"
          style={{
            fontWeight: "500",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "15px",
          }}
        >
          You can contact us on{" "}
          <span
            style={{
              color: "#007BFF",
              cursor: "pointer",
            }}
          >
            support@getwork.org{" "}
          </span>
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Divider style={{ width: "20%" }} />
          <Typography
            variant="body2"
            color="initial"
            style={{
              color: "#B0B6BA",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            OR
          </Typography>
          <Divider style={{ width: "20%" }} />
        </div>
        <Button
          color="primary"
          variant="contained"
          style={{
            boxShadow: "none",
            height: "40px",
            display: "flex",
            borderRadius: "30px",
            fontWeight: "400",
            width: "100%",
          }}
          onClick={openChatBot}
        >
          Chat with us
        </Button>
      </div>
    </>
  );
}

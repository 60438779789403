import {
  AppBar,
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Axios from "axios";
import clsx from "clsx";
import React, { useState } from "react";
import { IconContext } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import ActivateCreditModal from "../../../components/common/ActivateCredit/ActivateCreditModal";
import { BackendBaseApi } from "../../../constants/constants";
import ProfileUnlock from "../../../GlobalComponents/CreditBoard/ProfileUnlock";
import CreditBoard from "../../../GlobalComponents/CreditBoard/CreditBoard";
import getwork_logo from "../../assets/png/getwork-logo.png";
import logo from "../../assets/png/white-logo.png";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    // paddingRight: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolbarIcon: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    //padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: ".5px solid #b0b6ba",
    // borderBottom: "1px solid rgba(0 0 0 0.12)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
    borderRight: "1px solid #b0b6ba",
    alignItems: "center",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#F5F7F8",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "auto",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "20px",
    //boxShadow: "none",
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "10px",
  },
  std: {
    marginLeft: "20px",
    boxShadow: "none",
    borderRadius: "30px",
    display: "flex",
    height: "40px",
  },
  postJob: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "30px",
    width: "80%",
    display: "flex",
    boxShadow: "none",
    // "&:hover": {
    //   backgroundColor: theme.palette.primary.secondary,
    // },
  },
  pro: {
    backgroundColor: theme.palette.primary.secondary,
  },
}));

const StyledMenu = withStyles({
  paper: {
    //border: ".5px solid #d3d4d5",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "20px",
    display: "flex",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

export default function Layout({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [creditModal, showCreditModal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const companyUserDetails = useSelector(
    (state) => state?.CompanyUserDetails?.companyUserDetails
  );

  const creditInfo = useSelector((state) => state.CreditInfo.profileUnlockInfo);
  const [mySideBar, setMySideBar] = useState(SidebarData);
  const [addSub, setAddSub] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  /*   useEffect(()=>{
    if(creditInfo?.credit_details){
      let arr = [...mySideBar]
      if(!addSub){
        arr.push({
          title: "My Subscription",
          path: "/company/subscription",
          cName: "nav-text",
          type: "mysubscription",
          sNum: "6",
        });
        setAddSub(true);
      }

      setMySideBar(arr);
    }
  }, [creditInfo]) */

  const Logout = () => {
    Axios.post(BackendBaseApi.PRAVESH + "api/logout/", null, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
      withCredentials: true
    })
      .then((res) => {
        let track = localStorage.getItem("track_column_fields");
        let toggleSwitch = localStorage.getItem("switchToggled");
        localStorage.clear();
        sessionStorage.clear();
        if (track !== null) localStorage.setItem("track_column_fields", track);
        if (toggleSwitch !== null)
          localStorage.setItem("switchToggled", toggleSwitch);
        history.push("/signup/employer");
        dispatch({ type: "USER_LOGOUT" });
      })
      .catch((err) => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/signup/employer");
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <Grid
            container
            spacing={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  paddingLeft: "40px",
                  width: 150,
                  backgroundColor: "#fff",
                  height: 50,
                }}
              >
                <img
                  src={getwork_logo}
                  alt="logo"
                  style={{
                    margin: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  // className={classes.collegelogo}
                />
              </div>
            </Grid>
            <Grid item xs={5}>
              <div style={{ display: "flex" }}>
                <CreditBoard />
                <ProfileUnlock />
                {/* <Button
                  startIcon={<AccountBalanceWalletOutlinedIcon />}
                  variant="contained"
                  style={{
                    height: "40px",
                    borderRadius: "30px",
                    display: "flex",
                    boxShadow: "none",
                    marginRight: "30px",
                  }}
                  color="primary"
                  onClick={() => showCreditModal(true)}
                >
                  Activate Credits
                </Button> */}
                {location.pathname !== "/company/explore" ? (
                  <Link to="/company/explore">
                    <Button
                      startIcon={<ExploreOutlinedIcon />}
                      variant="text"
                      color="primary"
                      style={{
                        height: "40px",
                        borderRadius: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        display: "flex",
                        boxShadow: "none",
                        backgroundColor: "#fff",
                        border: "1px solid #C4C4C4",
                      }}
                    >
                      Explore X
                    </Button>
                  </Link>
                ) : (
                  <Link to="/company/dashboard">
                    <Button
                      startIcon={<DashboardIcon />}
                      variant="text"
                      color="primary"
                      style={{
                        height: "40px",
                        borderRadius: "30px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        display: "flex",
                        boxShadow: "none",
                        backgroundColor: "#fff",
                        border: "1px solid #C4C4C4",
                      }}
                    >
                      Dashboard
                    </Button>
                  </Link>
                )}
              </div>
            </Grid>
            {/*  <Grid item xs={3} style={{ display: "flex" }}>
               <Search placeholder="Search Student, College, Job…" /> }
            </Grid> */}

            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", marginRight: "50px" }}>
                {/* <Badge badgeContent={2} color="error">
                  <NotificationsNoneIcon style={{ color: "#6C757D", fontSize: "26px" }} />
                </Badge> */}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h6"
                  style={{ color: "#000", fontSize: "15px" }}
                >
                  {companyUserDetails?.first_name}{" "} {companyUserDetails?.last_name ? companyUserDetails?.last_name : ""}
                </Typography>

                <div>
                  <Avatar
                    alt="user"
                    src={companyUserDetails?.profile_picture}
                    onClick={handleClick}
                    style={{ marginLeft: "15px", cursor: "pointer" }}
                  />
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <Link
                      to={
                        localStorage.getItem("company") !== "null"
                          ? `/public/company/profile/${
                              JSON.parse(localStorage.getItem("company"))
                                .company
                            }`
                          : ""
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <StyledMenuItem>
                        <ListItemIcon
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <AccountBoxIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Company Public Profile" />
                      </StyledMenuItem>
                    </Link>
                    <Link
                      to="/company/user-settings"
                      style={{ textDecoration: "none" }}
                    >
                      <StyledMenuItem
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <ListItemIcon
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="User Settings" />
                      </StyledMenuItem>
                    </Link>
                    <Link
                      to="/company/company-settings"
                      style={{ textDecoration: "none" }}
                    >
                      <StyledMenuItem
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <ListItemIcon
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <BusinessIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Company Settings" />
                      </StyledMenuItem>
                    </Link>
                    <StyledMenuItem
                      onClick={() => {
                        Logout();
                        handleClose();
                      }}
                    >
                      <ListItemIcon
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </StyledMenuItem>
                  </StyledMenu>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* <div style={{ display: "flex" }}>
            <SendEjd />
          </div> */}
        </Toolbar>
      </AppBar>
      {/* <Sidebar /> */}
      {/* <Hidden smUp>
          <Drawer
            variant="temporary"
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <img src={collegeDetails?.logo} height="40px" width="70%" />
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Link
                to={collegeId ? "/post-job" : "/educational-group/post-job"}
                style={{ textDecoration: "none", display: "contents" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.postJob}
                  startIcon={<PostAddIcon />}
                >
                  Post Job
                </Button>
              </Link>
            </div>
            <div style={{ height: "70vh" }}>
              <IconContext.Provider>
                {sidebarDataNew &&
                  sidebarDataNew.map((item, index) => {
                    return <UniversitySubMenu items={item} key={index} />;
                  })}
              </IconContext.Provider>
  
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="overline">Powered by</Typography>
                <img src={logo} height="17px" width="70px" />
              </div>
            </div>
            <Divider />
          </Drawer>
        </Hidden> */}

      <Hidden smDown>
        {/* <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
          > */}
        <div
          className="nav"
          id="navbar"
          style={{
            borderRight: "1px solid #b0b6ba",
            padding: 6,
            boxShadow: "1px 0 0 rgba(22, 8, 43, 0.1)",
            height: "100vh",
          }}
        >
          <nav className="nav__container">
            {/* <div className={classes.toolbarIcon}>
                <img src={collegeDetails?.logo} height="40px" width="70%" />
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div> */}
            <div
              className={classes.sidepanel}
              style={{ marginTop: "86px" }}
              // onMouseOver={handleDrawerOpen}
            >
              <Link
                to={"/company/post-job"}
                style={{ textDecoration: "none", display: "contents" }}
              >
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    marginBottom: "20px",
                    height: "45px",
                    marginLeft: "6px",

                    cursor: "pointer",
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.postJob}
                >
                  <PostAddIcon
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "3px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{
                      margin: "auto",
                      marginLeft: "25px",
                      color: "white",
                    }}
                  >
                    {" "}
                    Post Job{" "}
                  </Typography>
                </div>
              </Link>
              {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to="/educational-group/post-job"
                    style={{ textDecoration: "none", display: "contents" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.postJob}
                      startIcon={<PostAddIcon />}
                    >
                      Post Job
                    </Button>
                  </Link>
                </div> */}
              <div style={{ height: "70vh" }}>
                <IconContext.Provider value={{ color: "#6c757d" }}>
                  {mySideBar &&
                    mySideBar.map((item, index) => {
                      return <SubMenu items={item} key={index} />;
                    })}
                </IconContext.Provider>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {/* <Typography variant="overline">Powered by</Typography> */}
                  <img src={logo} height="auto" width="50px" />
                </div>
              </div>
            </div>
            {/* <Divider /> */}
            {/* </Drawer> */}
          </nav>
        </div>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {children}
        </Container>
      </main>
      {creditModal && (
        <ActivateCreditModal
          isFromSideBar={true}
          openModal={creditModal}
          setOpenModal={showCreditModal}
        />
      )}
    </div>
  );
}

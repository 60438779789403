import {
  Button,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import { emailRegex, BackendBaseApi, GOOGLE_CLIENT_ID} from "../../../../../constants/constants";
import logo from "../../../../assets/png/getwork-logo.png";
import axios from "axios";
import { useAlert } from "react-alert";
import { validateEmployerEmail } from "../../../../../utils/utility";

const googleicon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9995 12.5151C23.9995 11.5083 23.9178 10.7736 23.741 10.0117H12.2441V14.556H18.9925C18.8565 15.6853 18.1218 17.386 16.4891 18.5288L16.4662 18.681L20.1013 21.497L20.3531 21.5221C22.6661 19.386 23.9995 16.2431 23.9995 12.5151Z"
      fill="#4285F4"
    />
    <path
      d="M12.2457 24.4913C15.5518 24.4913 18.3273 23.4028 20.3546 21.5252L16.4906 18.5319C15.4566 19.253 14.0688 19.7564 12.2457 19.7564C9.00752 19.7564 6.25919 17.6204 5.27949 14.668L5.13588 14.6802L1.35607 17.6054L1.30664 17.7428C3.32026 21.7429 7.45641 24.4913 12.2457 24.4913Z"
      fill="#34A853"
    />
    <path
      d="M5.27896 14.6656C5.02046 13.9037 4.87085 13.0873 4.87085 12.2438C4.87085 11.4002 5.02046 10.5839 5.26536 9.82199L5.25851 9.65972L1.43133 6.6875L1.30612 6.74706C0.476205 8.40698 0 10.271 0 12.2438C0 14.2166 0.476205 16.0806 1.30612 17.7405L5.27896 14.6656Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2457 4.73475C14.545 4.73475 16.096 5.72796 16.9804 6.55797L20.4362 3.18373C18.3138 1.21091 15.5518 0 12.2457 0C7.45641 0 3.32026 2.74833 1.30664 6.74838L5.26588 9.82331C6.25919 6.87087 9.00752 4.73475 12.2457 4.73475Z"
      fill="#EB4335"
    />
  </svg>
);

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    //fontSize: "14px",
    color: "#c5c4ba",
    transform: "translate(15px, 14px) scale(1)",
  },
  btn: {
    height: "40px",
    boxShadow: "none",
    borderRadius: "30px",
    display: "flex",
    fontWeight: "400",
  },
  btnsocial: {
    height: "40px",
    boxShadow: "none",
    borderRadius: "30px",
    display: "flex",
    fontWeight: "400",
    width: "100%",
  },
  errorPassword: {
    color: "#D64045",
    fontSize: 13,
  },
}));

export default function EnterEmail({
  setStep,
  email,
  setEmail,
  responseGoogle,
  setOtpEditStep,
  googleValidationFailed,
}) {
  const classes = useStyles();
  const [emailError, setEmailError] = useState("");
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);

  function onContinue() {
    if (!email || !emailRegex.test(email)) {
      setEmailError("Please enter valid email address");
      return;
    };

    const body = { email: email };
    setLoading(true);
    axios
      .post(BackendBaseApi.PRAVESH + "api/company_saas/continue/", body, {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.success) {
          // alert.success(res?.data?.data?.message);
          if (res?.data?.data?.code === 1) {
            if(validateEmployerEmail(email)){
              setEmailError("Please enter corporate email address to continue");
                // alert.error("Please Enter Corporate email address!");
                setLoading(false);
                return;
              };
            setStep(4);
          } else if (res?.data?.data?.code === 2) {
            setStep(2);
          }
          else if (res?.data?.data?.code === 3) {
            setOtpEditStep(1);
            setStep(3);
          }
        } else {
          alert.error(res?.data?.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Some error occurred. Please try again!");
        throw err;
      });
  }

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography variant="h4" style={{ fontWeight: "500" }}>
          Get started with
        </Typography>
        <img
          src={logo}
          height="30px"
          alt="getwork-logo"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        />
        <Typography variant="h4" style={{ fontWeight: "500" }}>
          to build your Dream Team
        </Typography>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <TextField
            label="Enter Your Email ID "
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            error={emailError !== "" ? true : false}
            helperText={
              emailError ? (
                <span className={classes.errorPassword}>{emailError}</span>
              ) : (
                ""
              )
            }
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Button
            color="primary"
            className={classes.btn}
            variant="contained"
            onClick={() => onContinue()}
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Continue"
            )}
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Divider style={{ width: "45%" }} />
            <Typography
              variant="body2"
              color="initial"
              style={{ color: "#B0B6BA" }}
            >
              OR
            </Typography>
            <Divider style={{ width: "45%" }} />
          </div>
        </Grid>
        <Grid item xs={12} md={12} styl={{ display: "grid" }}>
          <GoogleLogin
            // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
            clientId={GOOGLE_CLIENT_ID}
            isSignedIn={false}
            render={(renderProps) => (
              <Button
                onClick={() => {

                  if(validateEmployerEmail(email)){   
                    
                          setEmailError("Please enter corporate email address to continue");

                      // alert.error("Please Enter Corporate email address!");

                      return;
                    };
                  renderProps.onClick();
                }
              }
                className={classes.btnsocial}
                variant="outlined"
                color="primary"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {socialLoading ? (
                    <CircularProgress style={{ color: "#007BFF" }} size={18} />
                  ) : (
                    <>
                      {googleicon}
                      <Typography
                        variant="body1"
                        color="primary"
                        style={{ marginLeft: "10px" }}
                      >
                        {" "}
                        Continue with Google
                      </Typography>
                    </>
                  )}
                </div>
              </Button>
            )}
            onSuccess={(response) => {
              responseGoogle(response, setSocialLoading);
            }}
            onFailure={(response) => {
              responseGoogle(response, setSocialLoading);
            }}
          />
          {googleValidationFailed ? 
          <Typography
                        variant="body1"
                        color="red"
                        style={{ marginLeft: "10px", marginTop:"5px",color:"red" }}
                      >
                        
                      Please enter corporate email address to continue
            </Typography>
            : null
        }
        </Grid>
      </Grid>
    </>
  );
}

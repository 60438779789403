import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Box, Chip, Grid } from "@material-ui/core";
import CollegeList from "./CollegeList";
import { useDispatch, useSelector } from "react-redux";
import { BackendBaseApi } from "../../../constants/constants";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import BootstrapTooltip from "../../../common/Tooltip/Tooltip";
import generateCsv, {
  generateCsvDegreeName,
} from "../../../common/generateCsv/generateCsv";
import {
  changeState,
  FILTER_COUNT,
} from "../../company/jobs/PostJob/Forms/action";
import Filter from "./FilterJobPost";
import { useAlert } from "react-alert";
import CommonChip from "../../CommonChip/CommonChip";
import CommonButton from "../CommonButton/CommonButton";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) =>
  createStyles({
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
    },
    preferenceButton: {
      borderRadius: 30,
      float: "center",
      "&:hover": {
        border: "2px solid #3282C4",
        background: "white",
        color: "#3282C4",
      },
    },
  })
);
export default function CustomizedDialogs(props) {
  const {
    open,
    handleClickOpen,
    handleClose,
    state,
    setState,
    setApplyButtonClicked,
    applyButtonClicked,
  } = props;
  const alert = useAlert();
  const dispatch = useDispatch();
  const [collegeList, setCollegeList] = useState({});
  const [allCollegeCount, setAllCollegeCount] = useState(0);
  const [search, setSearch] = useState("");
  const [buttonClickSearch, setButtonClickSearch] = useState();
  const [collegeListID, setCollegeListID] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);
  const [flag, setFlag] = useState(0);
  const [end, setEnd] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const stateMemory = useSelector((state) => state.CollegeFilterState);
  const [college, setCollege] = useState();
  const [minCTC, setMinCTC] = useState("");
  const [currentJobSegment, setCurrentJobSegment] = useState();
  const [degreeNew, setDegreeNew] = React.useState();
  const [course, setCourse] = React.useState();
  const [city, setCity] = React.useState();
  const [scrolling, setScrolling] = useState(false);
  const [page, setPage] = useState(0);

  const [newCollegeList1, setNewCollegeList1] = useState([]);

  const classes = useStyles();
  // useEffect(() => {
  //   getAllColleges(1);
  // }, []);

  useEffect(() => {
    if (state.Colleges.hiring_type === "ON CAMPUS") {
      setPage(0);
      setCollegeList({});
      setNewCollegeList1([]);
      setEnd(false);
      setNextUrl(null);
      getAllColleges(1);
    }
  }, [
    stateMemory.education_levels.length,
    stateMemory.degrees.length,
    stateMemory.courses.length,
    stateMemory.location.length,
    stateMemory.ctc,
    stateMemory.job_segment.length,
    state.Colleges.hiring_type,
  ]);
  const getAllColleges = (flag_c) => {
    setLoader(true);

    const generateCsv = (arr) => {
      let str = "";
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item.city_id + ",")
          : (str += item.city_id)
      );
      return str;
    };

    const generateCsvJobSegment = (arr) => {
      let str = "";
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item.job_segment_id + ",")
          : (str += item.job_segment_id)
      );
      return str;
    };

    const generateCsvqualification = (arr) => {
      let str = "";
      var item_id;
      arr.forEach((item, index) => {
        if (item === "Graduation") item_id = 3;
        if (item === "Post Graduation") item_id = 4;
        if (item === "Ph.D") item_id = 5;
        arr.indexOf(item) !== arr.length - 1
          ? (str += item_id + ",")
          : (str += item_id);
      });

      return str;
    };

    const generateCsvDegrees = (arr) => {
      let str = "";
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item.id + ",")
          : (str += item.id)
      );
      return str;
    };
    const generateCsvSpecialization = (arr) => {
      let str = "";
      arr.forEach((item, index) =>
        arr.indexOf(item) !== arr.length - 1
          ? (str += item.specialization_id + ",")
          : (str += item.specialization_id)
      );
      return str;
    };
    const checkLocation = () => {
      if (stateMemory.location.length > 0) {
        return `&city=${generateCsv(stateMemory.location)}`;
      } else return "";
    };

    const checkJobSegment = () => {
      if (stateMemory.job_segment.length > 0) {
        return `&job_segment=${generateCsvJobSegment(stateMemory.job_segment)}`;
      } else return "";
    };
    const checkCTC = () => {
      if (parseInt(stateMemory.ctc) > 0) {
        return `&max_ctc=${parseInt(stateMemory.ctc)}`;
      } else return "";
    };

    const checkQualification = () => {
      if (stateMemory.education_levels.length > 0) {
        return `&qualification_required=${generateCsvqualification(
          stateMemory.education_levels
        )}`;
      } else return "";
    };

    const checkDegrees = () => {
      if (stateMemory.degrees.length > 0) {
        return `&degree=${generateCsvDegrees(stateMemory.degrees)}`;
      } else return "";
    };

    const checkSpecialization = () => {
      if (stateMemory.courses.length > 0) {
        return `&specialization=${generateCsvSpecialization(
          stateMemory.courses
        )}`;
      } else return "";
    };

    const checkSearch = () => {
      if (buttonClickSearch) {
        return `&query=${buttonClickSearch}`;
      } else return "";
    };

    const new_uuid = uuidv4();

    const tempDegreeData =
      state.Preferences.eligibility_criteria.courses_rank.map((item) => ({
        id: item.degree_id,
        name: item.degree_name,
      }));
    const tempSpecData =
      state.Preferences.eligibility_criteria.courses_rank.map((item) => ({
        id: item.specialization_id,
        name: item.specialization_name,
      }));

    const urlCollegeRank = nextUrl
      ? nextUrl
      : BackendBaseApi.PRAVESH +
        `api/company/new_college_rank/?per_page=8&uuid=${new_uuid}${checkSearch()}${checkLocation()}${checkCTC()}${checkJobSegment()}${checkQualification()}${checkDegrees()}${checkSpecialization()}`;

    Axios.post(urlCollegeRank, {
      company_id: state.Basics.company_id,
      company_skills: state.Preferences.eligibility_criteria.skills,
      company_courses: tempDegreeData,
      company_specialization: tempSpecData,
    })
      .then((res) => {
        setIsApiCall(true);
        setLoader(true);

        if (res.data.success) {
          setAllCollegeCount(res.data.data.data.count);
          let temp = {};
          res.data.data.data.results.forEach((data) => {
            let details = [];
            data.education.forEach((dets) => {
              const { degree_id, degree_name, specialization } = dets;
              const tempObj = {
                degree_id,
                degree_name,
                specialization,
              };
              dets.specialization.forEach((spec) => {
                spec.selected = false;
              });
              dets.select_all = false;
              details.push(tempObj);
            });
            temp[data.college_id] = {
              college_name: data.college_name,
              college_id: data.college_id,
              college_logo: data.college_logo,
              college_location: data.college_location,
              education_details: [],

              select_all: false,
              apply_start_date: null,
              apply_end_date: null,
            };
          });

          if (res.data.data.data.next) {
            //console.log("iam inside");
            setNextUrl(res.data.data.data.next);
          } else {
            setEnd(true);
          }

          setCollegeList({ ...collegeList, ...temp });
          let newTemp = [...newCollegeList1];
          // console.log("CCC", Object.values(temp));
          // newTemp.concat(Object.values(temp));
          // console.log("BBBBB", newTemp, temp);
          setNewCollegeList1([...newTemp, ...Object.values(temp)]);
          setCollegeListID(res.data.data.college_ids);

          setFlag(0);
          setLoader(false);
        }
      })
      .catch((err) => {
        setFlag(0);
        setLoader(false);
      });
    setIsApiCall(false);
  };

  const checkAllSpecializationsSelected = (
    college_id,
    specialization_array_length,
    degree_id
  ) => {
    var index = state.Colleges.college.findIndex(
      (x) => x.college_id === college_id
    );
    var boole = false;
    if (index !== -1) {
      state.Colleges.college[index].preferences.map((item) => {
        if (
          item.degree_id === degree_id &&
          item.specialization.length === specialization_array_length
        )
          boole = true;
      });
    }
    return boole;
  };
  const checkSpecializationSelected = (college_id, spec_id) => {
    var boole = false;

    var index = state.Colleges.college.findIndex(
      (x) => x.college_id === college_id
    );

    if (index !== -1) {
      state.Colleges.college[index].preferences.map((item) => {
        item.specialization.map((item2) => {
          if (item2.specialization_id === spec_id) boole = true;
        });
      });
    }
    return boole;
  };

  useEffect(() => {
    if (buttonClickSearch !== undefined) getAllColleges(1);
  }, [buttonClickSearch]);

  useEffect(() => {
    if (Object.keys(collegeList).length > 0) {
      Object.keys(collegeList).map((college) => {
        collegeList[college].apply_start_date = state.Colleges.apply_start_date;
        collegeList[college].apply_end_date = state.Colleges.apply_end_date;
      });
    }
  }, [state.Colleges.apply_start_date, state.Colleges.apply_end_date]);

  useEffect(() => {
    if (state.college_id_list.checkAll === true)
      setState({
        ...state,
        college_id_list: { ...state.college_id_list, list: collegeListID },
      });
    else
      setState({
        ...state,
        college_id_list: { ...state.college_id_list, list: [] },
      });
  }, [state.college_id_list.checkAll]);

  const getDegreeAndCourses = (clg_id, clg_name) => {
    setLoading(true);
    // if (collegeList[clg_name].education_details.length === 0) {
    Axios.get(
      BackendBaseApi.PRAVESH +
        "/api/company/new_college_rank/education/?college_id=" +
        clg_id,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        setLoading(false);

        if (res.data?.success && res?.data.data) {
          let temp = collegeList[clg_id];
          let details = [];
          res.data.data.forEach((dets) => {
            const { degree_id, degree_name, specialization } = dets;
            const tempObj = {
              degree_id,
              degree_name,
              specialization,
            };
            dets.specialization.forEach((spec) => {
              spec.selected = checkSpecializationSelected(
                clg_id,
                spec.specialization_id
              );
            });
            dets.select_all = checkAllSpecializationsSelected(
              clg_id,
              dets.specialization.length,
              dets.degree_id
            );
            details.push(tempObj);
          });
          collegeList[clg_id].education_details = res.data.data;
          setCollegeList({ ...collegeList });
          setNewCollegeList1([...Object.values(collegeList)]);
        } else {
          alert.error("Error Occured");
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Error");
      });
    // }
  };

  const removeCollege = (remove_college) => {
    let allColleges = state.Colleges.college;
    let collegeExist = false;

    allColleges.forEach((clg) => {
      if (clg.college_id == remove_college.college_id) {
        //college exists in the list
        collegeExist = true;

        return;
      }
    });
    if (!collegeExist) return;
    //college in the list,remove it

    allColleges = allColleges.filter(
      (clg) => clg.college_id !== remove_college.college_id
    );
    setState({
      ...state,
      Colleges: {
        ...state.Colleges,
        college: allColleges,
      },
    });
    setCollege();
    updateCollegeListAllCourses(remove_college.college_id, false);
  };

  const updateCollegeListAllCourses = (college_id, value) => {
    var newCollegeList = collegeList;
    var newAllSelectVal = value;
    newCollegeList[college_id].select_all = newAllSelectVal;
    newCollegeList[college_id].education_details.forEach((education) => {
      education.specialization.forEach((spec) => {
        spec.selected = newAllSelectVal;
      });
      education.select_all = newAllSelectVal;
    });
    setCollegeList({ ...newCollegeList });
    setNewCollegeList1([...newCollegeList1]);
  };

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };

  useEffect(() => {
    let num = 0;

    if (stateMemory.education_levels.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }
    if (stateMemory.degrees.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }
    if (stateMemory.courses.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }
    if (stateMemory.location.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }

    if (stateMemory.ctc !== 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }
    if (stateMemory.job_segment.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }

    if (
      stateMemory.location.length === 0 &&
      stateMemory.degrees.length === 0 &&
      stateMemory.courses.length === 0 &&
      stateMemory.job_segment.length === 0 &&
      stateMemory.education_levels.length === 0 &&
      stateMemory.ctc === 0
    ) {
      handleFilterChange(FILTER_COUNT, 0);
    }

    // dispatch(changeFilterCount(FILTER_COUNT, num));
    // setFilterCount(num);
  }, [
    stateMemory.education_levels.length,
    stateMemory.degrees.length,
    stateMemory.courses.length,
    stateMemory.location.length,
    stateMemory.ctc,
    stateMemory.job_segment.length,
  ]);

  const handleScroll = (event) => {
    // var bottomel = document.getElementById("bottom");

    // bottomel.scrollIntoView({ behavior: "smooth", block: "start" });

    let e = event.nativeEvent;

    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && isApiCall) {
        getAllColleges(1);
      }
    }
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xl"
      >
        <div /* onScroll={handleScroll} */>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {state.college_id_list.checkAll ? (
              <Box>
                {" "}
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Selected Colleges
                </p>
                <BootstrapTooltip
                  //title={} // placement="top-start"
                  className=""
                >
                  <CommonChip
                    label={
                      <span style={{ fontSize: 12 }}>
                        {"All Colleges Selected"}
                      </span>
                    }
                    style={{ marginRight: "1%", marginBottom: "1%" }}
                  />
                </BootstrapTooltip>
              </Box>
            ) : (
              <Box>
                {" "}
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {state.Colleges.college.length > 0
                    ? "Selected Colleges"
                    : "Select Colleges"}
                </p>
                {state.Colleges.college.map((clg) => (
                  <BootstrapTooltip
                    title={generateCsvDegreeName(clg.preferences)} // placement="top-start"
                    className=""
                  >
                    <CommonChip
                      label={
                        <span style={{ fontSize: 12 }}>{clg.college_name}</span>
                      }
                      onDelete={() => {
                        removeCollege(clg);
                      }}
                      style={{ marginRight: "1%", marginBottom: "1%" }}
                    />
                  </BootstrapTooltip>
                ))}
              </Box>
            )}
          </DialogTitle>
          <DialogContent dividers style={{ height: "800px" }}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <CollegeList
                  state={state}
                  setState={setState}
                  collegeList={collegeList}
                  newCollegeList1={newCollegeList1}
                  setNewCollegeList1={setNewCollegeList1}
                  setCollegeList={setCollegeList}
                  allCollegeCount={allCollegeCount}
                  end={end}
                  setEnd={setEnd}
                  isApiCall={isApiCall}
                  setIsApiCall={setIsApiCall}
                  getAllColleges={getAllColleges}
                  getDegreeAndCourses={getDegreeAndCourses}
                  loading={loading}
                  loader={loader}
                  college={college}
                  setCollege={setCollege}
                  search={search}
                  setSearch={setSearch}
                  setButtonClickSearch={setButtonClickSearch}
                  buttonClickSearch={buttonClickSearch}
                  setNextUrl={setNextUrl}
                  nextUrl={nextUrl}
                  stateMemory={stateMemory}
                  page={page}
                  setPage={setPage}
                />
              </Grid>
              <Grid item xs={4}>
                <Filter
                  newStateMemory={stateMemory}
                  handleFilterChange={handleFilterChange}
                  degree={degreeNew}
                  setDegree={setDegreeNew}
                  course={course}
                  setCourse={setCourse}
                  city={city}
                  setCity={setCity}
                  minCTC={minCTC}
                  setMinCTC={setMinCTC}
                  currentJobSegment={currentJobSegment}
                  setCurrentJobSegment={setCurrentJobSegment}
                  setCollegeList={setCollegeList}
                  setNextUrl={setNextUrl}
                  setEnd={setEnd}
                  getAllColleges={getAllColleges}
                  checkAll={state.college_id_list.checkAll}
                  checkJobLake={state.Basics.posted_on_job_lake}
                  setState={setState}
                  state={state}
                  setLoader={setLoader}
                  setApplyButtonClicked={setApplyButtonClicked}
                  applyButtonClicked={applyButtonClicked}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Box display="flex" justifyContent="center" margin="auto">
              <CommonButton
                onClick={handleClose}
                className={classes.preferenceButton}
                variant="contained"
              >
                Done
              </CommonButton>
            </Box>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

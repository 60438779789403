import {
  Box,
  Button,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../constants/constants";
import Axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
const useStyles = makeStyles((theme) =>
  createStyles({
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "20px 10px 0 20px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
      minHeight: "700px",
      maxHeight: "700px",
      padding: "7px",
      position: "fixed",
      overflowY: "scroll",
      width: "17%",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
    listItem: {
      display: "flex",
      flexDirection: "column",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
      fontSize: "18px",
    },
    deleteIcon: {
      color: "#ffffff",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      padding: "10px 20px 10px 20px",
      fontWeight: "bold",
      // marginLeft: "42%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  })
);

export default function CollegeList(props) {
  const { examSessionData, setExamSessionData } = props;
  const [examData, setExamData] = useState(
    examSessionData && examSessionData.exam_details
      ? examSessionData.exam_details
      : []
  );
  const [startMonth, setStartMonth] = useState();
  const alert = useAlert();
  const [endMonth, setEndMonth] = useState();
  const months = [
    { name: "January", id: 1 },
    { name: "February", id: 2 },
    { name: "March", id: 3 },
    { name: "April", id: 4 },
    { name: "May", id: 5 },
    { name: "June", id: 6 },
    { name: "July", id: 7 },
    { name: "August", id: 8 },
    { name: "September", id: 9 },
    { name: "October", id: 10 },
    { name: "November", id: 11 },
    { name: "December", id: 12 },
  ];

  var month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDelete = (key, arr, setArr) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);
    }
  };

  const handleAdd = () => {
    var flag = 0;
    if (parseInt(startMonth) > parseInt(endMonth)) {
      alert.error("Enter End Month greater than starting month");
      flag = 1;
    }
    if (!startMonth) {
      alert.error("Please Enter Start Month");
      flag = 1;
    }
    if (!endMonth) {
      alert.error("Please Enter End Month");
      flag = 1;
    }
    if (flag === 0) {
      let sampleArr = [...examData];
      sampleArr.push({
        start_month: startMonth,
        end_month: endMonth,
      });
      setExamData(sampleArr);
    }
  };
  let token = localStorage.getItem("gw_token");

  const handleExamSessions = () => {
    var examSessionObject = {
      total_sem: examData?.length,
      exam_details: examData,
    };
    const body = {
      exam_session: examSessionObject,
    };
    Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", body, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setExamSessionData(examSessionObject);
          alert.success("Updated Successfully");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };
  return (
    <>
      <Grid container spacing={1}>
        {examData &&
          examData.map((item, index) => (
            <>
              <Grid item xs={3}>
                <TextField
                  id="outlined-name"
                  label={"Semester " + (index + 1)}
                  type="number"
                  value="hello"
                  disabled
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-name"
                  label={month[parseInt(item.start_month) - 1]}
                  type="number"
                  value="hello"
                  disabled
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-name"
                  label={month[parseInt(item.end_month) - 1]}
                  type="number"
                  value="hello"
                  disabled
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  // className={classes.button}
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDelete(item, examData, setExamData)}
                >
                  Delete
                </Button>
              </Grid>
            </>
          ))}
        <>
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              label={
                examData ? "Semester " + (examData.length + 1) : "Semester 1"
              }
              type="number"
              value="hello"
              disabled
              variant="outlined"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Start Month
              </InputLabel>
              <Select
                value={startMonth}
                onChange={(e) => setStartMonth(e.target.value)}
                label="Start Month"
              >
                {months.map((data, ind) => {
                  return (
                    <MenuItem value={data.id} key={ind}>
                      {data.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                End Month
              </InputLabel>
              <Select
                value={endMonth}
                onChange={(e) => setEndMonth(e.target.value)}
                label="End Month"
              >
                {months.map((data, ind) => {
                  return (
                    <MenuItem value={data.id} key={ind}>
                      {data.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              // className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => handleAdd()}
            >
              Add
            </Button>
          </Grid>
        </>
      </Grid>
      <br />
      <br />
      <Box
        style={{
          display: "flex",
          justifyContent: "right",
          float: "right",
          marginRight: "150px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          // className={classes.button}
          onClick={() => handleExamSessions()}
        >
          Update Exam Sessions
        </Button>
      </Box>
    </>
  );
}

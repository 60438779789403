/* eslint-disable no-use-before-define */
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    // border:"1px solid #007bff"
  },
  root: {
    // width: 500,
    // "& > * + *": {
    //   marginTop: theme.spacing(3),
    // },
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    // fontSize: "14px",
    transform: "translate(15px, 14px) scale(1)",
  },
}));

export default function AutoFillEmail({ onchange, value, error }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        variant="outlined"
        InputLabelProps={{
          className: classes.floatingLabelFocusStyle,
        }}
        fullWidth
        label="Add Email"
        value={value}
        onChange={onchange}
        error={error ? true : false}
        helperText={error}
      />
      {/* <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={props.title}
            placeholder="Candidates"
          />
        )}
        multiple
        id="tags-filled"
        options={emails.map((option) => option)}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              className={classes.chip}
              variant="outlined"
              label={option}
              color="primary"
              deleteIcon={<CloseIcon />}
              {...getTagProps({ index })}
            />
          ))
        }
      /> */}
    </div>
  );
}

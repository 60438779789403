import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SaveExperience from "./SaveExperience";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function Expereince({ data }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography variant="h6">Expereince</Typography>
        </div>
        {data?.map((item) => (
          <SaveExperience data={item}/>
        ))}
      </div>
    </>
  );
}

import { Box, Button, createStyles, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { ArrowRightAlt, TramRounded } from "@material-ui/icons";
import clsx from "clsx";
import { easeQuadInOut } from "d3-ease";
import { loadCSS } from "fg-loadcss";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { TReduxStore } from "../../..";
import student_icon from "../../../assets/common/college_search.svg";
import company_icon from "../../../assets/common/company.png";
import arrow from "../../../assets/images/arrow.png";
import clip from "../../../assets/images/clip.png";
import notify from "../../../assets/images/notify.png";
import search from "../../../assets/images/search.png";
import user_d from "../../../assets/images/user/user-default.png";
import Loader from "../../../bundles/common/components/UI/Loader";
import ActivateCreditModal from "../../../components/common/ActivateCredit/ActivateCreditModal";
import kFormatter, { LFormatter, MFormatter } from "../../../utils/ZeroToK";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import "./custom.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerCard: {
      borderRadius: "25px",
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2%",
      boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
    },
    reportsCard: {
      display: "flex",
      // justifyContent: "center",
      alignItems: "center",
      padding: "7%",
      borderRadius: "25px",
      boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[3],
      cursor: "pointer",
      backgroundColor: theme.palette.background.paper,
    },
    card: {
      boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[3],
      cursor: "pointer",
      backgroundColor: theme.palette.background.paper,
      padding: "6%",
      borderRadius: "25px",
      height: "100%",
    },
    inviteIcon: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
      marginRight: "10px",
    },
    viewAllText: {
      color: theme.palette.primary.main,
    },
    bannerImage: {
      width: "100%",
      height: "240px",
      borderRadius: "25px",
    },
    hoverClass: {
      "&:hover": {
        transform: "scale(1.03)",
      },
    },
    main: {
      paddingLeft: "4%",
      paddingRight: "4%",
      paddingTop: "2%",
      paddingBottom: "2%",
    },
    listItem: {
      "&:hover": {
        border: "1px solid #CDEDF6",
        borderRadius: "25px",
      },
    },
  })
);

export interface InviteObjProps {
  id: Number;
  logo: string;
  company_name: string;
}

export interface NewJobProps {
  job_id: Number;
  company_logo: string;
  company_location: string;
  job_title: string;
  company_name: string;
  hiring_type: string;
}

export interface SkillProps {
  avg_rating_value: number;
  skill_name: string;
  skill_id: number;
}

export interface NotificationInviteProps {
  company: string;
}

export interface NotificationsProps {
  invites?: NotificationInviteProps[];
}

export interface CtcProps {
  month: number;
  min_ctc: number;
  max_ctc: number;
}

export interface UserDetailsProps {
  invite?: InviteObjProps[];
  jobs?: NewJobProps[];
  registered_students?: number;
  skills?: SkillProps[];
  visited_company?: number;
  student_placed?: number;
  banner_image?: any[];
  notifications?: NotificationsProps;
  ctc_data?: CtcProps[];
}

export default function DashBoard() {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const history = useHistory();

  const [invite, setInvitedata] = useState<InviteObjProps[]>([]);

  const [newjob, setNewjobdata] = useState<NewJobProps[]>([]);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  // @ts-ignore
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);

  const [registration, setRegisteredstudents] = useState<number>(0);
  const [skills, setSkills] = useState<SkillProps[]>([]);
  const [placed, setPlaced] = useState<number>(0);

  const [viscomp, setVisitedcompany] = useState<number>(0);
  const [banner, setBanner] = useState<string>("");
  const [notifications, setNotifications] = useState<NotificationInviteProps[]>([]);

  const [chartData, setChartData] = useState({});

  const userDetails_Redux = useSelector<TReduxStore, UserDetailsProps>((state) => {
    //@ts-ignore
    return state.UserDetails?.userDetails;
  });

  function valueLabelFormat(value: any) {
    var new_value;
    if (value > 99999) {
      if (value > 999999) new_value = MFormatter(value);
      else new_value = LFormatter(value);
    } else new_value = kFormatter(value);
    return new_value;
  }

  function hashCode(str: any) {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRGB(i: any) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  var monthsName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    let minctc = [];
    let maxctc = [];
    let month_new = [];
    if (userDetails_Redux !== undefined && userDetails_Redux?.ctc_data !== undefined) {
      for (const dataObj of userDetails_Redux?.ctc_data!) {
        minctc.push(dataObj.min_ctc);
        maxctc.push(dataObj.max_ctc);
        month_new.push(monthsName[dataObj.month - 1]);
      }
    }

    setChartData({
      labels: month_new,
      datasets: [
        {
          label: "Minimum CTC",

          backgroundColor: "#e55934",
          borderColor: userDetails_Redux?.ctc_data?.map((item) => `#${intToRGB(hashCode(`Month-${item.max_ctc}`))}`),
          data: minctc,
          maxBarThickness: 10,
        },
        {
          label: "Maximum CTC",
          backgroundColor: "#219653",
          borderColor: userDetails_Redux?.ctc_data?.map((item) => `#${intToRGB(hashCode(`Month-${item.max_ctc}`))}`),
          data: maxctc,
          maxBarThickness: 10,
        },
      ],
    });
    if (creditInfo?.credit_status == "INACTIVE") {
      setOpenActivateModal(true);
    }
  }, [userDetails_Redux]);

  const barData = {
    labels: userDetails_Redux?.ctc_data?.map((item: CtcProps) => `Month-${item.month}`),
    datasets: [
      {
        data: userDetails_Redux?.ctc_data?.map((item: CtcProps) => item.max_ctc),
        backgroundColor: userDetails_Redux?.ctc_data?.map((item) => `#${intToRGB(hashCode(`Month-${item.max_ctc}`))}`),

        borderColor: userDetails_Redux?.ctc_data?.map((item) => `#${intToRGB(hashCode(`Month-${item.max_ctc}`))}`),
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const options = {
    legend: {
      display: true,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (label: any) {
              if (label > 99999) {
                if (label > 999999) return MFormatter(label);
                else return LFormatter(label);
              } else return kFormatter(label);
              //                         return '$' + label;
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const toggleBgColor = (ind: number) => {
    if (ind === 0) {
      return theme.palette.info.light;
    } else if (ind === 1) {
      return theme.palette.info.main;
    } else if (ind === 2) {
      return theme.palette.info.dark;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("gw_token") === null) {
      history.push("/login");
    }
  }, []);

  React.useEffect(() => {
    const node = loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css" as any));

    return () => {
      //@ts-ignore
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(userDetails_Redux).length > 0) setInvitedata(userDetails_Redux.invite || []);
    setNewjobdata(userDetails_Redux.jobs || []);
    setRegisteredstudents(userDetails_Redux.registered_students || 0);
    setSkills(userDetails_Redux.skills || []);
    setVisitedcompany(userDetails_Redux.visited_company || 0);
    setPlaced(userDetails_Redux.student_placed || 0);
    setBanner(userDetails_Redux.banner_image ? userDetails_Redux.banner_image[0]?.banner_path : "");
    setNotifications(userDetails_Redux.notifications?.invites || []);
  }, [userDetails_Redux]);

  return Object.keys(userDetails_Redux).length > 0 ? (
    <>
      <Box className={classes.main}>
        <Grid container spacing={4}>
          {/* <Grid item xs={12}>
          {banner !== "" ? (
            <img
              src={banner}
              alt="banner"
              className={clsx(classes.bannerImage, classes.hoverClass)}
            />
          ) : (
            <Skeleton
              variant="rect"
              width="100%"
              height={200}
              animation="wave"
            />
          )}
        </Grid> */}
          <Grid item sm={8}>
            <Box className={classes.bannerCard}>
              <Typography variant="caption" style={{ color: "#000000" }}>
                Universities with complete profile are noticed more by
                <br /> companies. <b>Keep your profile upto date.</b>
              </Typography>
              <Link className="updatePro" to="/college/college-settings">
                <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
                  Update Profile &nbsp; <img src={arrow} alt="image" height="24px" />
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Link to="/college/reports">
              <Box className={classes.reportsCard}>
                <Typography variant="h4">
                  <img src={clip} alt="image" height="30x" />
                  &nbsp; &nbsp; Generate <b>Reports</b>
                </Typography>
              </Box>
            </Link>
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={4}>
              {/* <Grid item sm={3}>
                <Box className={clsx(classes.card, classes.hoverClass)}>
                  <Typography variant="h5">
                    <b>Invite Companies</b>
                  </Typography>
                  <List>
                    {invite !== undefined && Array.isArray(invite)
                      ? invite
                          .slice(0, 4)
                          .filter((x) => x)
                          .map((d: InviteObjProps) => {
                            if (!d) return;
                            const urll = "/public/company/profile/" + d.id;
                            return (
                              <ListItem className={classes.listItem} onClick={() => window.open(urll, "_blank")}>
                                <ListItemIcon>{d.logo ? <img src={d.logo} className={classes.inviteIcon} /> : <img src={user_d} className={classes.inviteIcon} />}</ListItemIcon>
                                <ListItemText>{d.company_name}</ListItemText>
                              </ListItem>
                            );
                          })
                      : null}
                    <ListItem>
                      <ListItemText>
                        <Link to="/college/invite">
                          <Box display="flex" alignItems="center">
                            <Typography variant="body1" className={classes.viewAllText}>
                              <b>View all Companies</b>
                            </Typography>
                            <ArrowRightAlt className={classes.viewAllText} />
                          </Box>
                        </Link>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
               */}
              <Grid item sm={6}>
                <Box className={clsx(classes.card, classes.hoverClass)}>
                  <Typography variant="h5">
                    <b>Available/Live Jobs</b>
                  </Typography>
                  <List>
                    {newjob !== undefined && Array.isArray(newjob)
                      ? newjob
                          .slice(0, 3)
                          .filter((x) => x)
                          .map((d: NewJobProps) => {
                            if (!d) return;
                            const urll = `/public/job/${d.hiring_type === "ON CAMPUS" ? "oncampus" : "offcampus"}/${d.job_id}`;
                            return (
                              <ListItem className={classes.listItem} onClick={() => window.open(urll, "_blank")}>
                                <ListItemIcon>
                                  {d.company_logo ? <img src={d.company_logo} className={classes.inviteIcon} /> : <img src={user_d} className={classes.inviteIcon} />}
                                </ListItemIcon>
                                <ListItemText>
                                  <b>{d.job_title}</b>
                                  <br />
                                  {d.company_name}
                                  <br />
                                  {d.company_location}
                                </ListItemText>
                              </ListItem>
                            );
                          })
                      : null}

                    {(!newjob || newjob.length === 0) && (
                      <>
                        <img
                          src={search}
                          style={{
                            margin: "auto",
                            height: "90px",
                            paddingLeft: "25%",
                          }}
                        />
                        <br />
                        <br />
                        <p>No results Found</p>
                        <p style={{ color: "grey", fontSize: "15px" }}>Connect with companies to find suitable new jobs</p>
                        <p>
                          <Link to="college/invite">
                            <Button variant="contained">Invite Companies</Button>
                          </Link>
                        </p>
                      </>
                    )}

                    {newjob.length > 3 ? (
                      <>
                        <ListItem>
                          <ListItemText>
                            <Link to="/college/new-job">
                              <Box display="flex" alignItems="center">
                                <Typography variant="body1" className={classes.viewAllText}>
                                  <b>View all Available Jobs</b>
                                </Typography>
                                <ArrowRightAlt className={classes.viewAllText} />
                              </Box>
                            </Link>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            <Link to="/college/open-job">
                              <Box display="flex" alignItems="center">
                                <Typography variant="body1" className={classes.viewAllText}>
                                  <b>View all Live Jobs</b>
                                </Typography>
                                <ArrowRightAlt className={classes.viewAllText} />
                              </Box>
                            </Link>
                          </ListItemText>
                        </ListItem>
                      </>
                    ) : null}
                  </List>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className={clsx(classes.card, classes.hoverClass)}>
                  <Typography variant="h5">
                    <b>CTC Offered </b>&nbsp;
                    <span style={{ fontSize: "15px" }}> (in LPA - INR)</span>
                  </Typography>
                  <Divider style={{ margin: "10% 0%" }} />
                  <Box>
                    <Bar data={chartData} options={options} width={410} height={250} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={4}>
              <Grid item sm={3}>
                <Link to="/college/students_connections">
                  <Box className={clsx(classes.card, classes.hoverClass)} style={{ height: "auto" }}>
                    <img className="imgmoddd" src={student_icon} alt="Logo" height="60px" />

                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      <b>
                        {registration}
                        <br />
                        Students Registered
                      </b>
                    </Typography>
                  </Box>
                </Link>
              </Grid>
              <Grid item sm={3}>
                <Link to="/college/company-connection">
                  <Box className={clsx(classes.card, classes.hoverClass)} style={{ height: "auto" }}>
                    <img className="imgmoddd" src={company_icon} alt="Logo" height="60px" />

                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      <b>
                        {viscomp}
                        <br />
                        Connected Companies
                      </b>
                    </Typography>
                  </Box>
                </Link>
              </Grid>
              <Grid item sm={6}>
                <Box className={clsx(classes.card, classes.hoverClass)} style={{ cursor: "auto" }}>
                  <Typography variant="h5">
                    <b>Most Popular Skills your Students have</b>
                  </Typography>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    {skills &&
                      skills.length > 0 &&
                      skills.slice(0, 3).map((f: SkillProps, ind) => {
                        var per = (f.avg_rating_value / 5) * 100;

                        return (
                          <Box key={f.skill_id} display="flex" flexDirection="column" justifyContent="space-between" paddingTop="1%">
                            <Typography
                              variant="h6"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {f.skill_name}
                            </Typography>
                            <Box height="auto">
                              <AnimatedProgressProvider valueStart={0} valueEnd={per} duration={1.8} easingFunction={easeQuadInOut}>
                                {(value: any) => {
                                  const roundedValue = Math.round(value);
                                  return (
                                    <div
                                      style={{
                                        width: 120,
                                        height: 120,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "auto",
                                        textAlign: "center",
                                      }}
                                    >
                                      <CircularProgressbar
                                        className="CircularProgressbar"
                                        value={value}
                                        text={`${roundedValue}%`}
                                        styles={buildStyles({
                                          pathTransition: "none",
                                          pathColor: toggleBgColor(ind),
                                          strokeLinecap: "butt",
                                        })}
                                      />
                                    </div>
                                  );
                                }}
                              </AnimatedProgressProvider>
                            </Box>
                          </Box>
                        );
                      })}
                    {(!skills || skills.length === 0) && (
                      <>
                        {/* <Typography variant="h6">There is no data for today</Typography> */}
                        <Typography variant="body2">We don't have enough data right now to generate skills</Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={4}>
              <Grid item sm={3}>
                <Box className={clsx(classes.card, classes.hoverClass)} style={{ cursor: "auto" }}>
                  <Typography variant="h4">Students Placed </Typography>
                  <Typography variant="body2">In past 30 days</Typography>
                  <Typography variant="h5">
                    {placed}/{registration}
                  </Typography>

                  {placed > 0 && registration > 0 && (
                    <AnimatedProgressProvider valueStart={0} valueEnd={(placed / registration) * 100} duration={1.8} easingFunction={easeQuadInOut}>
                      {(value: any) => {
                        const roundedValue = Math.round(value);
                        return (
                          <div
                            style={{
                              width: 120,
                              height: 120,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: "auto",
                              textAlign: "center",
                            }}
                          >
                            <CircularProgressbar
                              value={roundedValue}
                              text={`${roundedValue}%`}
                              styles={buildStyles({
                                pathTransition: "none",
                                strokeLinecap: "butt",
                                pathColor: "#28a745",
                              })}
                            />
                          </div>
                        );
                      }}
                    </AnimatedProgressProvider>
                  )}
                </Box>
              </Grid>
              <Grid item sm={3}>
                <Box className={clsx(classes.card, classes.hoverClass)} style={{ cursor: "auto" }}>
                  <Typography variant="h4">Recent Notifications</Typography>
                  <br />
                  <>
                    {notifications &&
                      notifications.length > 0 &&
                      notifications.slice(0, 3).map((g: NotificationInviteProps) => {
                        return <Typography variant="body1">- {g.company} has accepted your invite</Typography>;
                      })}
                    {(!notifications || notifications.length === 0) && (
                      <>
                        <img
                          src={notify}
                          style={{
                            height: "60px",
                            width: "120px",
                            margin: "auto",
                          }}
                        />
                        <br />
                        <Typography variant="body1">No notifications</Typography>
                        <Typography variant="subtitle1">You have no active Chats</Typography>
                      </>
                    )}
                  </>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* 
    // @ts-ignore */}
      <ActivateCreditModal isFromSideBar={false} openModal={openActivateModal} setOpenModal={setOpenActivateModal} />
    </>
  ) : (
    <Box>
      <Loader />
    </Box>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Chip } from "@material-ui/core";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: "0px",
    marginRight: "10px",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
}));

export default function Hobbies({data}) {
  const classes = useStyles();
  return (
    <>
      <div>
        <div className={classes.overview}>
          <div className={classes.div}>
            <Typography variant="h6">Hobbies</Typography>
          </div>

          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div component="ul" className={classes.root}>
              {data.map((data) => {
                return (
                  <li key={data}>
                    <Chip
                      size="small"
                      label={
                        <Typography
                          color="inherit"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          {data}
                        </Typography>
                      }
                      variant="outlined"
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

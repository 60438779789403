import { BackendBaseApi } from "../../constants/constants";
import { ThunkAction } from "redux-thunk";
import Axios, { AxiosPromise } from "axios";
import { TReduxStore } from "../..";
import { CityListProps } from "./reducer";
export const GET_INDUSTRY = "GET_INDUSTRY";

export const GET_JOB_SEGMENT = "GET_JOB_SEGMENT";
export const GET_JOB_SEGMENT_NEW = "GET_JOB_SEGMENT_NEW";
export const GET_JOB_ROLES = "GET_JOB_ROLES";
export const GET_JOB_TYPES = "GET_JOB_TYPES";
export const GET_SKILLS = "GET_SKILLS";
export const GET_EMPLOYMENT_TYPES = "GET_EMPLOYMENT_TYPES";

export const GET_LOCATIONS = "GET_LOCATIONS";
export const UPDATE_CTC_EXPLORE = "UPDATE_CTC_EXPLORE";
export const UPDATE_LOCATION_EXPLORE = "UPDATE_LOCATION_EXPLORE";
export const UPDATE_JOB_SEGMENT = "UPDATE_JOB_SEGMENT";
export const FILTER_COUNT = "FILTER_COUNT";
export const FILTER_COUNT_COLLEGE = "FILTER_COUNT_COLLEGE";
export const UPDATE_INDUSTRY = "UPDATE_INDUSTRY";
export const UPDATE_LOCATION_EXPLORE_COLLEGE =
  "UPDATE_LOCATION_EXPLORE_COLLEGE";
export const UPDATE_SIZE = "UPDATE_SIZE";
export const GET_CITY_ARRAY = "GET_CITY_ARRAY";
export const GET_CITY_ARRAY_NEW = "GET_CITY_ARRAY_NEW";
export const GET_ROUNDS = "GET_ROUNDS";
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";

export interface JobSegmentProps {
  job_segment_name: string;
  job_segment_id: number;
}

export const getJobSegment = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/company/job_segment/",
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    let temp: any = {};
    res.data.data.forEach((data: JobSegmentProps) => {
      temp[data.job_segment_name] = data;
    });
    dispatch({
      type: GET_JOB_SEGMENT,
      payload: temp,
    });
    return res;
  };
};

export const getJobSegmentNew = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/company/job_segment/",
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    // let temp: any = {};
    // res.data.data.forEach((data: JobSegmentProps) => {
    //   temp[data.job_segment_name] = data;
    // });
    dispatch({
      type: GET_JOB_SEGMENT_NEW,
      payload: res.data.data,
    });
    return res;
  };
};

export const getJobRoles = (
  setLoader: any,
  id: any
): ThunkAction<AxiosPromise, TReduxStore, {}, TAction> => {
  return async (dispatch) => {
    setLoader(true);

    const checkJobSegment = () => {
      if (id) return `?job_segment_id=${id}`;
      else return ``;
    };
    const res = await Axios.get(
      `${BackendBaseApi.NIYUKTI}job/role/${checkJobSegment()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    dispatch({
      type: GET_JOB_ROLES,
      payload: res.data.data,
    });
    setLoader(false);

    return res;
  };
};

export const getSkills = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/education/skills",
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    dispatch({
      type: GET_SKILLS,
      payload: res.data.data.skills,
    });
    return res;
  };
};

export const getEmploymentTypes = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/emp_type/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    }).catch((error) => {
      throw error;
    });

    dispatch({
      type: GET_EMPLOYMENT_TYPES,
      payload: res.data.data,
    });
    return res;
  };
};

export const getAllCompanies = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/college/visiting_companies/",
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    dispatch({
      type: GET_ALL_COMPANIES,
      payload: res.data.data,
    });
    return res;
  };
};

export const getJobTypes = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/", {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    }).catch((error) => {
      throw error;
    });

    dispatch({
      type: GET_JOB_TYPES,
      payload: res.data.data,
    });
    return res;
  };
};

export const getRounds = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/round/", {
      headers: { Authorization: `Token ${localStorage.getItem("gw_token")}` },
    }).catch((error) => {
      throw error;
    });

    dispatch({
      type: GET_ROUNDS,
      payload: res.data.data,
    });
    return res;
  };
};

export interface TAction {
  type: String;
  data?: any;
  additionalDispatchData?: any;
  payload?: any;
}

export interface LocationProps {
  city: string;
  city_id: number;
}

export interface IndustryProps {
  industry_name: string;
  industry_id: number;
}

export const getIndustries = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/college/industry/",
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    let temp: any = {};
    res.data.data.forEach((data: IndustryProps) => {
      temp[data.industry_name] = data;
    });
    dispatch({
      type: GET_INDUSTRY,
      payload: temp,
    });
    return res;
  };
};

export const getAllLocations = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/location/city/"
    ).catch((error) => {
      throw error;
    });

    let temp: any = {};

    res.data.forEach((data: LocationProps) => {
      temp[data.city] = data.city_id;
    });
    dispatch({
      type: GET_CITY_ARRAY,
      payload: res.data,
    });

    dispatch({
      type: GET_LOCATIONS,
      payload: temp,
    });
    return res;
  };
};

export const getAllLocationsArray = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/location/city/"
    ).catch((error) => {
      throw error;
    });

    let temp: any = {};

    dispatch({
      type: GET_CITY_ARRAY_NEW,
      payload: res.data,
    });

    return res;
  };
};

// export const getCities = (): ThunkAction<
//   AxiosPromise,
//   TReduxStore,
//   {},
//   TAction
// > => {
//   return async (dispatch) => {
//     const res = await Axios.get(
//       `${BackendBaseApi.PRAVESH}api/location/city/`
//     ).catch((err) => {
//       throw err;
//     });
//     dispatch({
//       type: GET_CITY_ARRAY,
//       payload: res.data,
//     });
//     return res;
//   };
// };

export const getDegreeCourse = (
  state: any,
  setState: any,
  setLoader: any
): ThunkAction<AxiosPromise, TReduxStore, {}, TAction> => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/education/job/get_specialization"
    ).catch((error) => {
      throw error;
    });

    if (res.data?.success && res?.data.data) {
      setLoader(false);

      setState({
        ...state,
        OffCampus: { ...state.OffCampus, allData: res.data.data },
      });
      //   setAllData(res.data.data)
      const temp: any = {};
      res.data.data.forEach((item: any) => {
        var tempSpzl = {
          ...item,
          isChecked: false,
          isActive: false,
          isValue: false,
        };
        if (temp[item.degree.name] === undefined) {
          if (item.specialization === null)
            temp[item.degree.name] = [
              {
                degree: item.degree,
                specialization: {},
                isChecked: false,
                isValue: false,
              },
            ];
          else {
            temp[item.degree.name] = [tempSpzl];
          }
        } else {
          if (item.specialization === null)
            temp[item.degree.name].push({
              degree: item.degree,
              specialization: {},
              isChecked: false,
              isValue: false,
            });
          else temp[item.degree.name].push(tempSpzl);
        }
      });
      //   setAllData(temp);
      setState({
        ...state,
        OffCampus: { ...state.OffCampus, allData: temp },
      });
    }

    // dispatch({
    //   type: GET_LOCATIONS,
    //   payload: temp,
    // });
    return res;
  };
};

export const changeState = (
  dispatcher: string,
  data: any
): ThunkAction<any, TReduxStore, {}, TAction> => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
    return;
  };
};

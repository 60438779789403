import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import BasicInfo from "./BasicInfo";
import { BackendBaseApi } from "../../../../../constants/constants";
import Axios from "axios";
import EducationDetails from "./EducationDetails";
import WorkExperience from "./WorkExperience";

import CommonButton from "../../../../common/CommonButton/CommonButton";
import SkillsDetails from "./Skills";
import Projects from "./Project";
import { Link } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import { useAlert } from "react-alert";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  step_label_root: {
    fontSize: "12px",
  },
  button: {
    marginRight: theme.spacing(1),
    background: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    color: "#fff",
    "&:hover": {
      background: theme.palette.domain.hover,
    },
    "&:disabled": {
      backgroundColor: theme.palette.domain.light,
      color: "#fff",
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  step: {
    // marginBottom: theme.spacing.unit * 5,
  },
  iconContainer: {
    transform: "scale(2)",
    marginRight: theme.spacing.unit * 5,
  },
  newRoot: {
    // padding: 0, marginLeft:-20
  },
  "@media only screen and (max-width: 1108px)": {
    newRoot: {
      padding: 0,
      marginLeft: -20,
    },
  },

  overrides: {
    MuiStepIcon: {
      "&$text": {
        fill: "#034691",
        fontSize: 13,
      },
      root: {
        fontSize: 13,
        "&$text": {
          fill: "#034691",
          fontSize: 13,
        },
        "&$active": {
          fill: "white",
          "&&& $text": {
            fill: "#034691",
            fontSize: 13,
          },
        },
      },
      text: {
        fill: "#D3D3D3",
        fontSize: 13,
      },
    },
  },
}));

function getSteps() {
  return ["Basic", "Education", "Experience", "Skills", "Projects"];
}

export default function ResumeStepper(props) {
  const {
    basicDetailsFilled,
    setBasicDetailsFilled,
    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    currentCity,
    setCurrentCity,
    currentHomeCity,
    setCurrentHomeCity,
    currentGender,
    setCurrentGender,
    allCities,
    buttonClicked,
    setButtonClicked,
    openAddEducation,
    setOpenAddEducation,
    allColleges,
    allBoards,
    educationDetails,
    setEducationDetails,
    educationDetailsErrors,
    setEducationDetailsErrors,
    educationDetailsList,
    setEducationDetailsList,
    activeStep,
    setActiveStep,
    workExpDetails,
    setWorkExpDetails,
    workExpDetailsErrors,
    setWorkExpDetailsErrors,
    openAddWorkExp,
    setOpenAddWorkExp,
    workExpDetailsList,
    setWorkExpDetailsList,
    allJobType,
    allCompanies,
    setAllCompanies,
    allSkills,
    skillDetails,
    setSkillDetails,
    skillDetailsErrors,
    setSkillDetailsErrors,
    skillDetailsList,
    setSkillDetailsList,
    linkList,
    setLinkList,
    allSocialLinks,
    projectDetails,
    setProjectDetails,
    projectDetailsErrors,
    setProjectDetailsErrors,
    projectList,
    setProjectList,
    educationLoader,
    expLoader,
    skillsLoader,
    projectLoader,
    linksLoader,
    previewImage,
    setPreviewImage,
    setProfilePicChanged,
    profilePicChanged,
    openAddAchievements,
    setOpenAddAchievements,
    openAddHobbies,
    setOpenAddHobbies,
    openAddLanguages,
    setOpenAddLanguages,
    openAddProjects,
    setOpenAddProjects,
    extraData,
    setExtraData,
    fetchPrecentage,
    getUserDetails,
    mainRef,
    executeScroll,
    instituteList,
    openAddCertificate,
    setOpenAddCertificate,
  } = props;
  const classes = useStyles();
  const alert = useAlert();
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [phoneValid,setPhoneValid] = React.useState(false);
  const [phoneUpdated,setPhoneUpdated] = React.useState(false);
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <BasicInfo
            basicDetailsFilled = {basicDetailsFilled}
            setBasicDetailsFilled = {setBasicDetailsFilled}
            setPhoneUpdated={setPhoneUpdated}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            setButtonClicked={setButtonClicked}
            currentGender={currentGender}
            setCurrentGender={setCurrentGender}
            allCities={allCities}
            currentCity={currentCity}
            currentHomeCity={currentHomeCity}
            setCurrentCity={setCurrentCity}
            setCurrentHomeCity={setCurrentHomeCity}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            buttonClicked={buttonClicked}
            setProfilePicChanged={setProfilePicChanged}
            profilePicChanged={profilePicChanged}
            fetchPrecentage={fetchPrecentage}
            getUserDetails={getUserDetails}
          />
        );
      case 1:
        return (
          <EducationDetails
            openAddEducation={openAddEducation}
            setOpenAddEducation={setOpenAddEducation}
            setButtonClicked={setButtonClicked}
            allBoards={allBoards}
            educationDetails={educationDetails}
            setEducationDetails={setEducationDetails}
            allColleges={allColleges}
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            educationDetailsErrors={educationDetailsErrors}
            setEducationDetailsErrors={setEducationDetailsErrors}
            buttonClicked={buttonClicked}
            setEducationDetailsList={setEducationDetailsList}
            educationDetailsList={educationDetailsList}
            educationLoader={educationLoader}
            fetchPrecentage={fetchPrecentage}
            instituteList={instituteList}
          />
        );
      case 2:
        return (
          <WorkExperience
            workExpDetails={workExpDetails}
            setWorkExpDetails={setWorkExpDetails}
            workExpDetailsErrors={workExpDetailsErrors}
            setWorkExpDetailsErrors={setWorkExpDetailsErrors}
            openAddWorkExp={openAddWorkExp}
            setOpenAddWorkExp={setOpenAddWorkExp}
            workExpDetailsList={workExpDetailsList}
            setWorkExpDetailsList={setWorkExpDetailsList}
            allCompanies={allCompanies}
            setAllCompanies={setAllCompanies}
            allJobType={allJobType}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
            allSkills={allSkills}
            expLoader={expLoader}
            fetchPrecentage={fetchPrecentage}
            allCities={allCities}
          />
        );
      case 3:
        return (
          <SkillsDetails
            skillDetails={skillDetails}
            setSkillDetails={setSkillDetails}
            skillDetailsErrors={skillDetailsErrors}
            setSkillDetailsErrors={setSkillDetailsErrors}
            skillDetailsList={skillDetailsList}
            setSkillDetailsList={setSkillDetailsList}
            linkList={linkList}
            setLinkList={setLinkList}
            allSocialLinks={allSocialLinks}
            allSkills={allSkills}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
            skillsLoader={skillsLoader}
            linksLoader={linksLoader}
            openAddHobbies={openAddHobbies}
            setOpenAddHobbies={setOpenAddHobbies}
            openAddLanguages={openAddLanguages}
            setOpenAddLanguages={setOpenAddLanguages}
            extraData={extraData}
            setExtraData={setExtraData}
            fetchPrecentage={fetchPrecentage}
          />
        );
      case 4:
        return (
          <Projects
            projectDetails={projectDetails}
            setProjectDetails={setProjectDetails}
            projectDetailsErrors={projectDetailsErrors}
            setProjectDetailsErrors={setProjectDetailsErrors}
            projectList={projectList}
            setProjectList={setProjectList}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
            projectLoader={projectLoader}
            openAddProjects={openAddProjects}
            setOpenAddProjects={setOpenAddProjects}
            openAddAchievements={openAddAchievements}
            setOpenAddAchievements={setOpenAddAchievements}
            extraData={extraData}
            setExtraData={setExtraData}
            fetchPrecentage={fetchPrecentage}
            openAddCertificate={openAddCertificate}
            setOpenAddCertificate={setOpenAddCertificate}
          />
        );
      default:
        return "Unknown step";
    }
  }

  const totalSteps = () => {
    return getSteps().length;
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    var phone_error ="";
    setPhoneValid(true);
    if (!userDetails?.PhoneNo) {
      phone_error = "Phone Number cannot be left blank";
      setPhoneValid(false);
      setPhoneUpdated(false);
    }
    if (userDetails.PhoneNo && String(userDetails.PhoneNo).length !== 10) {
      phone_error = "Enter a valid phone number";
      setPhoneValid(false);
      setPhoneUpdated(false);
    }
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;
    if(phoneValid && phoneUpdated){
    setActiveStep(newActiveStep);
    setButtonClicked(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    executeScroll();
    }
    else if(!phoneValid){
      setErrorMessages({
        ...errorMessages,
        PhoneNo: phone_error,
      });
    }
    else if(!phoneUpdated) {
      alert.error("Please Save Details");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setButtonClicked(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    executeScroll();
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    setButtonClicked(false);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    if (completed.size !== totalSteps() - skippedSteps()) {
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep} style={{}} className={classes.newRoot}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          //   if (isStepOptional(index)) {
          //     buttonProps.optional = (
          //       <Typography variant="caption">Optional</Typography>
          //     );
          //   }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps} className={classes.step}>
              <StepButton onClick={handleStep(index)} completed={isStepComplete(index)} {...buttonProps}>
                {/* <StepLabel classes={{ label: classes.step_label_root }} style={{ fontSize: 13 }}> */}
                {label}
                {/* </StepLabel> */}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      <div>
        <div>
          <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: 40 }}>
            <Hidden smDown>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button} style={{ paddingLeft: 50, paddingRight: 50 }}>
                Back
              </Button>
            </Hidden>
            <Link to={`/public/student/profile/${localStorage.getItem("user_id")}`} target="_blank">
              <Button className={classes.button} style={{ paddingLeft: 50, paddingRight: 50 }}>
                Preview
              </Button>
            </Link>
            <Button disabled={activeStep === 4} onClick={handleNext} className={classes.button} style={{ paddingLeft: 50, paddingRight: 50 }}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Typography, Chip, Button, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LinkIcon from "@material-ui/icons/Link";
import ReactHtmlParser from "react-html-parser";
import { generateCsvCommas, generateCsvNew } from "../../../company/common/generateCsv/generateCsv";
import CommonButton from "../../../common/CommonButton/CommonButton";
import { useHistory } from "react-router-dom";
import { APP_ENDPOINT } from "../../../../constants/constants";
import BootstrapTooltip from "../../../../common/Tooltip/Tooltip";
import generateCsv from "../../../../common/generateCsv/generateCsv";
import { useSelector } from "react-redux";

const data = [
  {
    round: "Round 1",
    details: "Telephonic/Skype Interview",
  },
  {
    round: "Round 2",
    details: "Technical Interview",
  },
  {
    round: "Round 3",
    details: "Hr Round",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  image: {
    border: "1px solid #e2e6ea",
    borderRadius: "12px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "80px",
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  subheading: {
    fontSize: "15px",
    color: "#6c757d",
    marginLeft: "15px",
  },
  info: {
    fontSize: "14px",
    color: "#6c757d",
  },
  icon: {
    color: "#6c757d",
    fontSize: "16px",
    display: "flex",
  },
  chip: {
    color: "#007bff",
    backgroundColor: "#e8f3ff",
    borderColor: "#007bff",
  },
  chipsection: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  btn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  btn2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 10,
  },
}));

function Description({ data, isUserApplied, setIsUserApplied, setApplyClicked }) {
  const classes = useStyles();
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      <Hidden smDown>
        <div className={classes.btn2}>
          {((localStorage.getItem("gw_token") &&
            localStorage.getItem("user_type_id") === "1" &&
            (localStorage.getItem("user_id") || JSON.parse(localStorage.getItem("user_details")).id)) ||
            !localStorage.getItem("gw_token")) && (
            <>
              {isUserApplied ? (
                <CommonButton disabled variant="contained" style={!domain?.domain ? { borderRadius: "30px" } : { backgroundColor: domain?.light_color_code, borderRadius: "30px" }}>
                  Applied
                </CommonButton>
              ) : data?.job_status === "CLOSE" ? (
                <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                  Job Already Closed
                </Button>
              ) : (
                <>
                  {/* <>
                    {data?.up_coming_state?.code === -1 && (
                      <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                        Job Deadline Passed!
                      </Button>
                    )}
                  </> */}
                  <>
                    {data?.up_coming_state?.code === 1 && (
                      <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                        Upcoming Job!{" "}
                      </Button>
                    )}
                  </>

                  <>
                    {data?.up_coming_state?.code === 0 && (
                      <Button
                        variant="contained"
                        style={domain?.domain ? { backgroundColor: domain?.color_code, borderRadius: "30px" } : { borderRadius: "30px", backgroundColor: "#007bff" }}
                        onClick={() => {
                          if (!localStorage.getItem("gw_token")) {
                            // localStorage.setItem("job-detail-state", data?.id);

                            history.push({
                              pathname: "/apply-for-the-job",
                              search: `?source=/public/job/${data?.hiring_type === "OFF CAMPUS" ? "offcampus" : "oncampus"}/${data?.id}-${data?.job_title
                                .replace(/\s+/g, "-")
                                .toLowerCase()}`,

                              flag: 39856,
                              state: "job-detail-state",
                            });
                          } else {
                            if (localStorage.getItem("is_pref_filled") === "true" || localStorage.getItem("is_student_resume_uploaded") === "true") setApplyClicked(true);
                            else
                              history.push({
                                pathname: "/student/complete-profile",
                                search: `?source=/public/job/${data?.hiring_type === "OFF CAMPUS" ? "offcampus" : "oncampus"}/${data?.id}-${data?.job_title
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}`,
                              });
                          }
                        }}
                      >
                        Apply Now{" "}
                      </Button>
                    )}
                  </>
                </>
              )}
            </>
          )}

          {(localStorage.getItem("user_type") === "Company" || localStorage.getItem("user_type") === "College") && (
            <div className={classes.btn2}>
              <>
                <>
                  {data?.job_status === "CLOSE" ? (
                    <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                      Job Already Closed
                    </Button>
                  ) : (
                    <>
                      {/* <>
                        {data?.up_coming_state?.code === -1 && (
                          <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                            Job Deadline Passed!
                          </Button>
                        )}
                      </> */}
                      <>
                        {data?.up_coming_state?.code === 1 && (
                          <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                            Upcoming Job!{" "}
                          </Button>
                        )}
                      </>
                    </>
                  )}
                </>
              </>
            </div>
          )}
        </div>
      </Hidden>

      <Hidden smUp>
        {localStorage.getItem("user_type") === "College" && (
          <div className={classes.btn2}>
            <>
              <>
                {data?.job_status === "CLOSE" ? (
                  <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                    Job Already Closed
                  </Button>
                ) : (
                  <>
                    {/* <>
                      {data?.up_coming_state?.code === -1 && (
                        <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                          Job Deadline Passed!
                        </Button>
                      )}
                    </> */}
                    <>
                      {data?.up_coming_state?.code === 1 && (
                        <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                          Upcoming Job!{" "}
                        </Button>
                      )}
                    </>
                  </>
                )}
              </>
            </>
          </div>
        )}
      </Hidden>
      <div style={{ marginTop: "10px" }}>
        {data?.job_description && (
          <>
            <Typography variant="h6">Job Description</Typography>
            <Typography className={classes.info}>{ReactHtmlParser(data?.job_description.toString("html"))}</Typography>
          </>
        )}

        {data?.eligibility_criteria?.skills?.length > 0 && (
          <>
            <Typography variant="h6">Skills</Typography>
            <div className={classes.chipsection}>
              {data?.eligibility_criteria?.skills?.map((item, index) => (
                <Chip variant="outlined" size="small" label={item?.skill_name} className={classes.chip} />
              ))}{" "}
            </div>
          </>
        )}
        <br />
        <Typography variant="h6">Who can apply</Typography>
        <Typography className={classes.info} style={{ marginTop: -15 }}>
          {data?.eligibility_criteria?.experiecne?.length > 0 && (
            <>
              <b>Work Experience : </b>
              {data?.eligibility_criteria?.experiecne[0]}
            </>
          )}
          {data?.eligibility_criteria?.graduation_years?.length > 0 && (
            <>
              <br /> <b>Eligible Graduation Years : </b> {generateCsvCommas(data?.eligibility_criteria?.graduation_years)}
            </>
          )}
          {data?.eligibility_criteria?.percentage && (
            <>
              <br /> <b>Min. Percentage : </b> {data?.eligibility_criteria?.percentage}
            </>
          )}
          <br />
        </Typography>

        {data?.eligibility_criteria?.qualifications && Object.keys(data?.eligibility_criteria?.qualifications)?.length > 0 && localStorage.getItem("user_type") !== "Company" && (
          <>
            <br />
            <Typography variant="h6">Eligible Degrees</Typography>
            <div className={classes.chipsection}>
              <>
                {Object.keys(data?.eligibility_criteria?.qualifications)?.map((item, index) => (
                  <>
                    <>{item === "Open to All" && <Chip variant="outlined" size="small" label={"Open to All Courses"} className={classes.chip} />}</>
                  </>
                ))}
              </>
              <>
                <>
                  {!showMore &&
                    Object.keys(data?.eligibility_criteria?.qualifications)
                      ?.slice(0, 1)
                      .map((item, index) => (
                        <>
                          {item !== "Open to All" &&
                            !showMore &&
                            data?.eligibility_criteria?.qualifications[item]
                              .slice(0, 5)
                              .map((item2) => (
                                <>
                                  {
                                    <Chip
                                      variant="outlined"
                                      size="small"
                                      label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                      className={classes.chip}
                                    />
                                  }
                                </>
                              ))}
                          {/* <span onClick={() => setShowMore(true)} style={{ color: "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 2 }}>
                            Show More...
                          </span> */}

                          {!showMore && data?.eligibility_criteria?.qualifications[Object.keys(data?.eligibility_criteria?.qualifications)[0]].length > 5 && (
                            <span onClick={() => setShowMore(true)} style={{ color: "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 3 }}>
                              Show More...
                            </span>
                          )}
                        </>
                      ))}
                </>
                <>
                  {showMore &&
                    Object.keys(data?.eligibility_criteria?.qualifications)?.map((item, index) => (
                      <>
                        {item !== "Open to All" &&
                          showMore &&
                          data?.eligibility_criteria?.qualifications[item].map((item2) => (
                            <>
                              <Hidden smUp>
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                  className={classes.chip}
                                  style={{ maxWidth: "100%" }}
                                />
                              </Hidden>
                              <Hidden smDown>
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                  className={classes.chip}
                                />
                              </Hidden>
                            </>
                          ))}
                      </>
                    ))}
                  {showMore && (
                    <span onClick={() => setShowMore(false)} style={{ color: "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 3 }}>
                      Show less...
                    </span>
                  )}
                </>
              </>
            </div>
          </>
        )}

        {localStorage.getItem("user_type") === "Company" && data?.college?.length > 0 && (
          <>
            <br />
            <Typography variant="h6">Open to Colleges</Typography>
            <div className={classes.chipsection}>
              <>
                {!showMore &&
                  Object.keys(data?.eligibility_criteria?.qualifications)
                    ?.slice(0, 1)
                    ?.map((item, index) => (
                      <BootstrapTooltip title={generateCsv(data?.eligibility_criteria?.qualifications[item].slice(0, 5), "name")}>
                        <Chip variant="outlined" size="small" label={item} className={classes.chip} />
                      </BootstrapTooltip>
                    ))}
                {!showMore && data?.eligibility_criteria?.qualifications[Object.keys(data?.eligibility_criteria?.qualifications)[0]].length > 5 && (
                  <span onClick={() => setShowMore(true)} style={{ color: "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 3 }}>
                    Show More...
                  </span>
                )}
              </>
              <>
                <>
                  {showMore &&
                    Object.keys(data?.eligibility_criteria?.qualifications)
                      ?.slice(0, 1)
                      ?.map((item, index) => (
                        <BootstrapTooltip title={generateCsv(data?.eligibility_criteria?.qualifications[item].slice(0, 5), "name")}>
                          <Chip variant="outlined" size="small" label={item} className={classes.chip} style={{ maxWidth: "100%" }} />
                        </BootstrapTooltip>
                      ))}

                  <span onClick={() => setShowMore(false)} style={{ color: "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 3 }}>
                    Show Less...
                  </span>
                </>
              </>
            </div>
          </>
        )}
        {/* <br />
        <Typography variant="h6">Perks and Benefits</Typography>
        <div className={classes.chipsection}>
          <Chip variant="outlined" size="small" label="Certificate" className={classes.chip} />
          <Chip variant="outlined" size="small" label="Swags and T-shirt" className={classes.chip} />
        </div> */}
        {data?.documents?.length > 0 && (
          <>
            <br />
            <Typography variant="h6">Documents Required</Typography>
            {data?.documents?.map((item, index) => (
              <Typography className={classes.info}>
                {index + 1 + ". " + item}
                <br />
              </Typography>
            ))}
          </>
        )}
        <br />
        <Typography variant="h6">Job Round</Typography>
        {data?.job_rounds?.map((item, index) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.info} style={{ marginRight: "10px" }}>
              <b>Round {" " + (index + 1)}</b>
            </Typography>{" "}
            :
            <Typography className={classes.info} style={{ marginLeft: "10px" }}>
              {item?.round_name}
            </Typography>
          </div>
        ))}
        <br />

        {data?.company && (
          <>
            <Typography variant="h6">About {data?.company ? data?.company?.company_name : data?.company_details}</Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <LinkIcon style={{ color: "#007bff" }} />
              <Typography variant="h6" style={{ color: "#007bff" }}>
                <a href={data?.company?.company_website} target="blank" variant="h6" style={{ color: "#007bff" }}>
                  {" "}
                  {data?.company?.company_website}
                </a>
              </Typography>
            </div>
            {data?.company?.company_description && <Typography className={classes.info}>{ReactHtmlParser(data?.company?.company_description.toString("html"))}</Typography>}
            <br />
          </>
        )}

        {/* <br />
        <Typography variant="h6">Number Employment Type: Part-Time</Typography> */}
        <br />
        {data?.vacancy && <Typography variant="h6">Number of Openings: {data?.vacancy}</Typography>}
      </div>
      <Hidden smDown>
        <div className={classes.btn}>
          {((localStorage.getItem("gw_token") &&
            localStorage.getItem("user_type_id") === "1" &&
            (localStorage.getItem("user_id") || JSON.parse(localStorage.getItem("user_details")).id)) ||
            !localStorage.getItem("gw_token")) && (
            <>
              {isUserApplied ? (
                <CommonButton disabled variant="contained" style={!domain?.domain ? { borderRadius: "30px" } : { backgroundColor: domain?.light_color_code, borderRadius: "30px" }}>
                  Applied
                </CommonButton>
              ) : data?.job_status === "CLOSE" ? (
                <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                  Job Already Closed
                </Button>
              ) : (
                <>
                  <>
                    {data?.up_coming_state?.code === -1 && (
                      <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                        Job Deadline Passed!
                      </Button>
                    )}
                  </>
                  <>
                    {data?.up_coming_state?.code === 1 && (
                      <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                        Upcoming Job!{" "}
                      </Button>
                    )}
                  </>

                  <>
                    {data?.up_coming_state?.code === 0 && (
                      <Button
                        variant="contained"
                        style={!domain?.domain ? { borderRadius: "30px", backgroundColor: "#007bff" } : { backgroundColor: domain?.color_code, borderRadius: "30px" }}
                        onClick={() => {
                          if (!localStorage.getItem("gw_token")) {
                            // localStorage.setItem("job-detail-state", data?.id);

                            history.push({
                              pathname: "/apply-for-the-job",
                              search: `?source=/public/job/${data?.hiring_type === "OFF CAMPUS" ? "offcampus" : "oncampus"}/${data?.id}-${data?.job_title
                                .replace(/\s+/g, "-")
                                .toLowerCase()}`,

                              flag: 39856,
                              state: "job-detail-state",
                            });
                          } else {
                            if (localStorage.getItem("is_pref_filled") === "true" || localStorage.getItem("is_student_resume_uploaded") === "true") setApplyClicked(true);
                            else
                              history.push({
                                pathname: "/student/complete-profile",
                                search: `?source=/public/job/${data?.hiring_type === "OFF CAMPUS" ? "offcampus" : "oncampus"}/${data?.id}-${data?.job_title
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}`,
                              });
                          }
                        }}
                      >
                        Apply Now{" "}
                      </Button>
                    )}
                  </>
                </>
              )}
            </>
          )}
        </div>

        {localStorage.getItem("gw_token") && localStorage.getItem("user_type") === "College" && (
          <>
            {data?.college_job_url ? (
              <>
                <br />

                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <CommonButton
                    variant="contained"
                    onClick={() => {
                      window.open(data?.college_job_url, "_blank");
                    }}
                    style={!domain?.domain ? { borderRadius: "30px", backgroundColor: "#007bff", outline: "none"  } : { backgroundColor: domain?.color_code, borderRadius: "30px", outline: "none"  }}
                  >
                    View Job
                  </CommonButton>
                </div>
              </>
            ) : data?.job_status === "CLOSE" ? (
              <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                Job Already Closed
              </Button>
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  {/* <>
                    {data?.up_coming_state?.code === -1 && (
                      <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                        Job Deadline Passed!
                      </Button>
                    )}
                  </> */}
                  <>
                    {data?.up_coming_state?.code === 1 && (
                      <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                        Upcoming Job!{" "}
                      </Button>
                    )}
                  </>
                </div>
              </>
            )}
          </>
        )}

        {localStorage.getItem("user_type") === "Company" && (
          <div className={classes.btn}>
            <br />

            <>
              <>
                {data?.job_status === "CLOSE" ? (
                  <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                    Job Already Closed
                  </Button>
                ) : (
                  <>
                    <>
                      {data?.up_coming_state?.code === -1 && (
                        <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                          Job Deadline Passed!
                        </Button>
                      )}
                    </>
                    <>
                      {data?.up_coming_state?.code === 1 && (
                        <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                          Upcoming Job!{" "}
                        </Button>
                      )}
                    </>
                  </>
                )}
              </>
            </>
          </div>
        )}
      </Hidden>
    </>
  );
}

export default Description;

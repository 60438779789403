import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Iron from "../../../../assets/common/ironman.png";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { emphasize } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectStep from "./SelectStep";
import RejectApplicant from "./RejectApplicant";
// import assignment from "../../../../assets/assignment.png";
import subuser_icon from "../../../../assets/common/subuser_icon.png";

import {
  IconButton,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Menu,
  ListItemIcon,
} from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import EmailIcon from "@material-ui/icons/Email";

import Assign_icon from "../../../../assets/common/cv.png";
import assignment from "../../../../assets/common/assignment.png";

import next_icon from "../../../../assets/common/next.svg";
import delete_icon from "../../../../assets/common/delete_icon.svg";
import next_icon_grey from "../../../../assets/common/next-grey.svg";
import delete_icon_grey from "../../../../assets/common/reject-grey.svg";
import cir from "../../../../assets/common/Gro.png";
import ci from "../../../../assets/common/Icon_check.png";
import ApplicantsDetailDialog from "../../Dialogs/ApplicantsDetailDialog";
import DownloadAssignment from "../../Dialogs/DownloadAssignmentDialog.js";

import BootstrapTooltip from "../../../../common/Tooltip/Tooltip";
import ConvertMonthsToYear from "../../../../utils/CovertMonthsToYear";
import CommonButton from "../../../common/CommonButton/CommonButton";
import CommonChip from "../../../CommonChip/CommonChip";
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    height: theme.spacing(3),
    border: "solid 0.5px #007bff",
    backgroundColor: "#e8f3ff",
    color: "#007bff",
    // "&:hover, &:focus": {
    //   backgroundColor: theme.palette.grey[300],
    // },
    // "&:active": {
    //   boxShadow: theme.shadows[1],
    //   backgroundColor: theme.palette.type === "dark" ? "#454444" : emphasize(theme.palette.grey[300], 0.12),
    // },
  },
}))(Chip);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  //   paper: {
  //     padding: theme.spacing(2),
  //     margin: 'auto',
  //     maxWidth: 500,
  //   },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    borderRadius: "30px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
    backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
    marginBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.type === "dark" ? "#474646" : "#f8f8f8",
      cursor: "pointer",
    },
  },
  image: {
    width: 128,
    height: 128,
    // borderRadius:"25px",
  },
  img: {
    margin: "auto",
    display: "block",
    // maxWidth: "100%",
    // maxHeight: "100%",
    borderRadius: "50%",
    height: 125,
    width: 125,
  },
  Card_Heading: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "0.14px",
    textAlign: "left",
    color: theme.palette.type === "dark" ? "#fff" : "#000000",
    fontFamily: "Roboto",
  },
  Card_Body: {
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.12px",
    textAlign: "left",
    color: "#707070",
    marginBottom: "8px",
    // margin: '2px 37.5px 1px 0',
  },
  subuser1: {
    display: "inline-flex",
    marginLeft: "-14px",
  },
  subuser: {
    marginTop: -40,
  },
  centerAlignment: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  assignmentButton: {
    fontSize: 12,
    marginTop: "-23px",
    marginBottom: 10,
    marginLeft: 13,
    padding: "5px 20px",
  },

  "@media only screen and (max-width: 1700px)": {
    subuser1: {
      marginLeft: "-14px",
    },
    subuser: {
      marginTop: "-32px",
      marginBottom: 10,
    },
  },
  "@media only screen and (max-width: 1350px)": {
    subuser: {
      marginTop: "-10px",
      marginBottom: 10,
    },
  },
}));

function Track_Detail(props) {
  const {
    trackData,
    setTrackData,
    tabData,
    currentState,
    currentStateString,
    feedbackList,
    currentFeedback,
    setCurrentFeedback,
    setFeedbackSentState,
    feedbackSentState,
    feedbackTyped,
    setFeedbackTyped,
    selectedApplicants,
    setSelectedApplicants,
    setMoveButtonClicked,
    selectAllApplicants,
    setSelectAllApplicants,
    setFeedbackList,
    moveNextArray,
    setMoveNextArray,
    setInterviewData,
    interviewData,
    setSubUserList,
    currentStateObject,
    subUserAssignment,
    setSubUserAssignment,
    setRejectButtonClicked,
    rejectButtonClicked,
    feedbackListNew,
    show,
  } = props;
  const [currentData, setCurrentData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = () => {};
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (item, index) => {
    setOpen(true);
    setCurrentData(item);
    setCurrentIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openFeedback, setOpenFeedback] = React.useState(false);

  const handleClickOpenFeedback = (item) => {
    setOpenFeedback(true);
    setCurrentData(item);
  };

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };

  const [openFeedbackReject, setOpenFeedbackReject] = React.useState(false);

  const handleClickOpenFeedbackReject = (item) => {
    setOpenFeedbackReject(true);
    setCurrentData(item);
  };

  const handleCloseFeedbackReject = () => {
    setOpenFeedbackReject(false);
  };

  const [openAssignment, setOpenAssignment] = React.useState(false);
  const [currentAssignment, setCurrentAssignment] = useState([]);
  const handleClickOpenAssignment = (item) => {
    setCurrentAssignment(item);
    setOpenAssignment(true);
  };
  const handleCloseAssignment = () => {
    setOpenAssignment(false);
  };

  useEffect(() => {
    var arr = Object.keys(tabData);
    var new_arr = [];
    var index;
    Object.keys(tabData).map((item) => {
      if (tabData[item].param === currentStateString) {
        if (tabData[item].status_id !== 19) index = Object.keys(tabData).indexOf(item);
        else index = -1;
      }
    });
    if (index !== -1) arr.splice(0, index + 1);
    else arr.splice(1, 1);

    arr.map((item) => {
      if (item !== "rejected") {
        new_arr.push({
          status_id: tabData[item].status_id,
          name: tabData[item].tooltip,
          round_id: tabData[item].round_id,
        });
      }
    });
    setMoveNextArray(new_arr);
  }, [tabData, currentState]);

  const handleSelectSingleApplicant = (e, item) => {
    item.checked = e.target.checked;
    var index;
    trackData.forEach((item1) => {
      if (item1.applicant_id === item.applicant_id) index = trackData.indexOf(item1);
    });
    var arr = [...trackData];
    arr.splice(index, 1, item);
    setTrackData(arr);
  };

  useEffect(() => {
    var myArray = trackData?.filter((item) => item.checked);
    var data = [];
    var list = [];
    var list2 = [];
    var feedbacks = [];
    var job_id;

    for (let i = 0; i < myArray?.length; i++) {
      data.push({
        id: myArray[i].applicant_id,
        name: myArray[i].user_details?.first_name + " " + myArray[i].user_details?.last_name,

        user_id: myArray[i].user_details.user_id,
        first_name: myArray[i].user_details.first_name,
        last_name: myArray[i].user_details.last_name,
        job_id: myArray[i].job_id,
      });
      list.push({
        first_name: myArray[i].user_details?.first_name,
        last_name: myArray[i].user_details?.last_name,
        email: myArray[i].user_details?.email,
        user_id: myArray[i].user_details?.user_id,
        applicant_id: myArray[i].applicant_id,
      });
      list2.push({
        applicant_first_name: myArray[i].user_details?.first_name,
        applicant_last_name: myArray[i].user_details?.last_name,
        user_id: myArray[i].user_details?.user_id,
        applicant_id: myArray[i].applicant_id,
        status: currentState,
        is_active: 1,
      });
      job_id = myArray[0].job_id;
    }
    setSelectedApplicants(data);

    const data1 = {
      job_id: job_id,
      company_id:
        localStorage.getItem("user_type") === "Company"
          ? JSON.parse(localStorage.getItem("company")).company
          : null,
      user_id:
        localStorage.getItem("user_type") === "Company"
          ? JSON.parse(localStorage.getItem("user")).id
          : localStorage.getItem("user_type_id") === "7"
          ? localStorage.getItem("user_id")
          : null,
      type: "ONLINE",
      address: null,
      location: [],
      total_slots: data.length,
      student_detail: list ? list : [],
    };
    var object = {
      student_data: list2,
    };
    setSubUserList(object);
    setInterviewData(data1);
  }, [trackData]);

  useEffect(() => {
    if (subUserAssignment !== undefined) {
      var myArray = trackData?.filter((item) => item.checked);
      myArray.forEach((item) => {
        // if (
        //   item.assigned_company_user.map((idn) => idn.user_id) !==
        //   subUserAssignment.user_id
        // )
        if (item.assigned_company_user && item.assigned_company_user.length > 0) {
          if (item.assigned_company_user.some((el) => el.user_id !== subUserAssignment.user_id))
            item.assigned_company_user = [subUserAssignment];
        } else {
          item.assigned_company_user = [subUserAssignment];
        }
      });
      var arr = [...trackData];
      var res = arr.map((obj) => myArray.find((o) => o.applicant_id === obj.applicant_id) || obj);
      setTrackData(res);
    }
  }, [subUserAssignment]);

  return (
    <>
      {trackData &&
        trackData.length &&
        trackData.map((item, index) => {
          var skill_arr = [];
          if (item?.user_details?.skill && item.user_details.skill.length > 0) {
            item.user_details.skill.forEach((inn) => {
              if (inn.skill_name !== null && inn.skill_name !== "") {
                skill_arr.push(inn);
              }
            });
          }
          return (
            <Paper className={classes.paper} onClick={() => handleClickOpen(item, index)}>
              <Grid container spacing={2}>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {show && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={
                            <img src={cir} fontSize="inherit" alt="" height="28px" width="28px" />
                          }
                          checkedIcon={
                            <img src={ci} fontSize="inherit" alt="" height="24px" width="28px" />
                          }
                          name="checkedH"
                          checked={item.checked}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            handleSelectSingleApplicant(e, item);
                          }}
                        />
                      }
                    />
                  )}
                  <ButtonBase className={classes.image} disabled>
                    <img
                      className={classes.img}
                      alt="complex"
                      src={
                        item?.user_details?.profile_picture
                          ? item.user_details.profile_picture
                          : Iron
                      }
                    />
                  </ButtonBase>
                </Grid>

                <Grid item xs={5} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs className={classes.centerAlignment}>
                      <Typography gutterBottom className={classes.Card_Heading}>
                        {item.user_details.first_name + " " + item.user_details.last_name}
                      </Typography>
                      <Typography variant="body2" className={classes.Card_Body}>
                        {item?.user_details.education.find((item) => item.is_current === true) ? (
                          <>
                            {item?.user_details.education.find((item) => item.is_current === true)
                              .temp_college_name !== null &&
                            item?.user_details.education.find((item) => item.is_current === true)
                              .college === 142
                              ? item?.user_details.education.find(
                                  (item) => item.is_current === true
                                ).temp_college_name
                              : item?.user_details.education.find(
                                  (item) => item.is_current === true
                                ).college_name &&
                                item?.user_details.education.find(
                                  (item) => item.is_current === true
                                ).college_name}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography gutterBottom className={classes.Card_Heading}>
                        {item?.user_details.education.find((item) => item.is_current === true) ? (
                          <>
                            {item.user_details.education.find((item) => item.is_current === true)
                              .degree
                              ? item.user_details.education.find((item) => item.is_current === true)
                                  .degree
                              : "NA"}

                            {item.user_details.education.find((item) => item.is_current === true)
                              .specialization
                              ? ", " +
                                item.user_details.education.find((item) => item.is_current === true)
                                  .specialization
                              : "NA"}
                          </>
                        ) : (
                          ""
                        )}
                      </Typography>

                      {localStorage.getItem("user_type") === "College" && (
                        <Typography gutterBottom className={classes.Card_Body}>
                          {item?.user_details.education.find((item) => item.is_current === true) ? (
                            <>
                              {item.user_details.education.find((item) => item.is_current === true)
                                .percentage &&
                              item.user_details.education.find((item) => item.is_current === true)
                                .percentage !== 0
                                ? "Percentage: " +
                                  item.user_details.education.find(
                                    (item) => item.is_current === true
                                  ).percentage +
                                  " %"
                                : "CGPA: " +
                                  item.user_details.education.find(
                                    (item) => item.is_current === true
                                  ).cgpa}
                            </>
                          ) : null}
                        </Typography>
                      )}
                      {localStorage.getItem("user_type") === "Company" && (
                        <Typography gutterBottom className={classes.Card_Body}>
                          {item?.user_details.education.find((item) => item.is_current === true) ? (
                            <>
                              {item.user_details.education.find((item) => item.is_current === true)
                                .percentage &&
                              item.user_details.education.find((item) => item.is_current === true)
                                .percentage !== 0
                                ? "Percentage: " +
                                  item.user_details.education.find(
                                    (item) => item.is_current === true
                                  ).percentage +
                                  " %"
                                : "Percentage: " +
                                  item.user_details.education.find(
                                    (item) => item.is_current === true
                                  ).cgpa *
                                    item.user_details.education.find(
                                      (item) => item.is_current === true
                                    ).percentage_factor +
                                  " %"}
                            </>
                          ) : null}
                        </Typography>
                      )}
                      <Typography gutterBottom className={classes.Card_Heading}>
                        <span style={{ color: "gray" }}> Work Experience :</span>
                        {parseInt(item.user_details.work_ex) !== 0
                          ? " " + ConvertMonthsToYear(item.user_details.work_ex)
                          : " Fresher"}
                      </Typography>
                      <Typography gutterBottom className={classes.Card_Heading}>
                        {item.user_details.cur_ctc ? (
                          <span>
                            <span style={{ color: "gray" }}> Cur. CTC:&nbsp;</span>
                            {item.user_details.cur_ctc} Lacs&nbsp;
                          </span>
                        ) : null}
                        <br />
                        {item.user_details.expected_ctc ? (
                          <span>
                            <span style={{ color: "gray" }}> Exp. CTC:</span> &nbsp;
                            {item.user_details.expected_ctc} Lacs
                          </span>
                        ) : null}
                        <br />
                        {item.user_details.notice_period ? (
                          <span>
                            <span style={{ color: "gray" }}> Notice Period:</span>&nbsp;
                            {item.user_details.notice_period} days
                          </span>
                        ) : null}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* {localStorage.getItem("user_type") === "Company" && (
                <>
                  <div>
                    <Divider orientation="vertical" />
                  </div>
                  <Grid item xs={3} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {item?.assigned_company_user?.length > 0 ? (
                            <>
                              <Typography
                                gutterBottom
                                className={classes.Card_Heading}
                                style={{ marginBottom: 10 }}
                              >
                                Assigned Subuser
                              </Typography>
                              {item?.assigned_company_user?.length > 0 &&
                                item?.assigned_company_user?.map((item) => (
                                  <Chip
                                    avatar={
                                      <Avatar
                                        src={
                                          item?.profile_picture
                                            ? item?.profile_picture
                                            : subuser_icon
                                        }
                                        height="20px"
                                      />
                                    }
                                    label={
                                      item.first_name + " " + item.last_name
                                    }
                                    onClick={handleClick}
                                    // className={classes.subuser}
                                  />
                                ))}
                            </>
                          ) : (
                            <>
                              <Typography
                                gutterBottom
                                className={classes.Card_Body}
                                style={{ marginBottom: 10 }}
                              >
                                No Subuser assigned to this applicant
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )} */}

                <div>
                  <Divider orientation="vertical" />
                </div>
                <Grid item xs={3} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid
                      item
                      xs
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {localStorage.getItem("user_type") === "Company" && (
                        <Grid item>
                          <div className={classes.subuser1}>
                            <BootstrapTooltip
                              title={
                                item.assignment && item.assignment.length > 0
                                  ? "View and Download Applicant Assignments"
                                  : "No Applicant Assignment present"
                              }
                            >
                              <CommonButton
                                aria-label="add"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (item.assignment && item.assignment.length > 0)
                                    handleClickOpenAssignment(item.assignment);
                                }}
                                style={{ fontSize: 12, marginTop: "-23px" }}
                                className={classes.assignmentButton}
                                disabled={
                                  item.assignment && item.assignment.length > 0 ? false : true
                                }
                                startIcon={
                                  <img
                                    src={assignment}
                                    width="22px"
                                    // style={{ marginTop: "-23px" }}
                                  />
                                }
                              >
                                <span
                                // style={{ marginTop: "-23px" }}
                                >
                                  {item.assignment && item.assignment.length > 0
                                    ? "View Assignments"
                                    : "No Assignment yet"}
                                </span>
                              </CommonButton>
                            </BootstrapTooltip>
                          </div>

                          {/* {item?.assigned_company_user?.length > 0 &&
                        item?.assigned_company_user?.map((item) => (
                          <Chip
                            avatar={
                              <Avatar
                                src={
                                  item?.profile_picture
                                    ? item?.profile_picture
                                    : subuser_icon
                                }
                                height="20px"
                              />
                            }
                            label={item.first_name + " " + item.last_name}
                            onClick={handleClick}
                            className={classes.subuser}
                          />
                        ))} */}
                        </Grid>
                      )}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ marginBottom: 10 }}
                      >
                        {/* <Breadcrumbs aria-label="breadcrumb"> */}
                        {skill_arr && skill_arr?.length > 0 ? (
                          skill_arr
                            .slice(0, 4)
                            .map((in_item) => (
                              <StyledBreadcrumb
                                label={in_item.skill_name}
                                style={{ marginRight: "1%", marginBottom: "1%" }}
                              />
                            ))
                        ) : (
                          <>
                            <Typography
                              gutterBottom
                              className={classes.Card_Body}
                              style={{ marginBottom: 10 }}
                            >
                              No Skills added by this applicant
                            </Typography>
                          </>
                        )}
                        {/* </Breadcrumbs> */}
                        {item?.user_details?.skill && item.user_details.skill.length >= 5 && (
                          <BootstrapTooltip
                            title={
                              // <Breadcrumbs aria-label="breadcrumb">
                              <>
                                {skill_arr &&
                                  skill_arr?.length > 0 &&
                                  skill_arr.map((in_item) => (
                                    <StyledBreadcrumb
                                      // component="a"
                                      label={in_item.skill_name}
                                      style={{
                                        marginRight: "1%",
                                        marginBottom: "1%",
                                      }}
                                    />
                                  ))}
                              </>
                              // </Breadcrumbs>
                            }
                          >
                            <span
                              style={{
                                fontSize: 10,
                                marginLeft: 4,
                                cursor: "pointer",
                              }}
                            >
                              More..
                            </span>
                          </BootstrapTooltip>
                        )}
                      </Typography>
                      {localStorage.getItem("user_type") === "Company" &&
                        item?.assigned_company_user?.length > 0 && (
                          <>
                            <Divider />
                            <Grid
                              item
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              {item?.assigned_company_user?.length > 0 ? (
                                <>
                                  {item?.assigned_company_user?.length > 0 &&
                                    item?.assigned_company_user?.slice(0, 1).map((item) => (
                                      <BootstrapTooltip title="Assigned Subuser">
                                        <CommonChip
                                          avatar={
                                            <Avatar
                                              src={
                                                item?.profile_picture
                                                  ? item?.profile_picture
                                                  : subuser_icon
                                              }
                                              height="20px"
                                            />
                                          }
                                          label={item.first_name + " " + item.last_name}
                                          onClick={handleClick}
                                          style={{
                                            marginRight: "1%",
                                            marginBottom: "1%",
                                          }}
                                          // className={classes.subuser}
                                        />
                                      </BootstrapTooltip>
                                    ))}
                                </>
                              ) : (
                                <>
                                  <Typography
                                    gutterBottom
                                    className={classes.Card_Body}
                                    style={{ marginBottom: 10 }}
                                  >
                                    No Subuser assigned to this applicant
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
                {show && (
                  <>
                    <div>
                      <Divider orientation="vertical" />
                    </div>

                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs container direction="column" spacing={2}>
                        <div style={{ display: "grid" }}>
                          <BootstrapTooltip title={"Move to Next step"} className="">
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClickOpenFeedback(item);
                              }}
                              disabled={item.checked || currentState === 18 || currentState === 14}
                            >
                              <img
                                alt="complex"
                                src={
                                  item.checked === false &&
                                  currentState !== 18 &&
                                  currentState !== 14
                                    ? next_icon
                                    : next_icon_grey
                                }
                              />
                            </Button>
                          </BootstrapTooltip>
                          <BootstrapTooltip title={"Reject"} className="">
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                setRejectButtonClicked(true);

                                handleClickOpenFeedbackReject(item);
                              }}
                              disabled={item.checked || currentState === 18 || currentState === 14}
                            >
                              <img
                                alt="complex"
                                src={
                                  item.checked === false &&
                                  currentState !== 18 &&
                                  currentState !== 14
                                    ? delete_icon
                                    : delete_icon_grey
                                }
                              />
                            </Button>
                          </BootstrapTooltip>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          );
        })}
      <SelectStep
        handleClickOpen={handleClickOpenFeedback}
        handleClose={handleCloseFeedback}
        open={openFeedback}
        moveNextArray={moveNextArray}
        feedbackList={feedbackList}
        currentFeedback={currentFeedback}
        setCurrentFeedback={setCurrentFeedback}
        setFeedbackSentState={setFeedbackSentState}
        feedbackSentState={feedbackSentState}
        feedbackTyped={feedbackTyped}
        setFeedbackTyped={setFeedbackTyped}
        currentData={currentData}
        selectedApplicants={selectedApplicants}
        setSelectedApplicants={setSelectedApplicants}
        setMoveButtonClicked={setMoveButtonClicked}
        showWholeApplicantList={false}
      />
      <RejectApplicant
        handleClickOpen={handleClickOpenFeedbackReject}
        handleClose={handleCloseFeedbackReject}
        open={openFeedbackReject}
        moveNextArray={moveNextArray}
        feedbackList={feedbackList}
        currentFeedback={currentFeedback}
        setCurrentFeedback={setCurrentFeedback}
        setFeedbackSentState={setFeedbackSentState}
        feedbackSentState={feedbackSentState}
        feedbackTyped={feedbackTyped}
        setFeedbackTyped={setFeedbackTyped}
        currentData={currentData}
        selectedApplicants={selectedApplicants}
        setSelectedApplicants={setSelectedApplicants}
        setMoveButtonClicked={setMoveButtonClicked}
        setFeedbackList={setFeedbackList}
        showWholeApplicantList={false}
        setRejectButtonClicked={setRejectButtonClicked}
        rejectButtonClicked={rejectButtonClicked}
        feedbackListNew={feedbackListNew}
      />
      <ApplicantsDetailDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        currentData={currentData}
        setCurrentData={setCurrentData}
        trackData={trackData}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        moveNextArray={moveNextArray}
        setMoveButtonClicked={setMoveButtonClicked}
        setSelectedApplicants={setSelectedApplicants}
        setFeedbackSentState={setFeedbackSentState}
        feedbackTyped={feedbackTyped}
        setFeedbackTyped={setFeedbackTyped}
        currentState={currentState}
        show={show}
      />
      <DownloadAssignment
        handleClose={handleCloseAssignment}
        handleClickOpen={handleClickOpenAssignment}
        open={openAssignment}
        setOpen={setOpenAssignment}
        assignment={currentAssignment}
      />
    </>
  );
}

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    top: 406,
    left: 1340,
    width: 300,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#3282C4",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default Track_Detail;

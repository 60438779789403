import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useAlert } from "react-alert";
import Axios from "axios";
import { BackendBaseApi } from "../../constants/constants";
import FilterListIcon from "@material-ui/icons/FilterList";

import loader_gif from "../../assets/images/loader.gif";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "20px 10px 0 20px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
      minHeight: "700px",
      maxHeight: "700px",
      padding: "7px",
      position: "fixed",
      overflowY: "scroll",
      width: "18%",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
    listItem: {
      display: "flex",
      flexDirection: "column",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
      fontSize: "18px",
    },
    deleteIcon: {
      color: "#ffffff",
    },
  })
);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {
    open,
    setOpen,
    handleClickOpen,
    handleClose,
    end,
    nextUrl,
    searchString,
    setSearchString,
    setCollegeData,
    setNextUrl,
    setEnd,
  } = props;
  const classes = useStyles();
  const [filterObject, setFilterObject] = useState({});
  const [filterArray, setFilterArray] = useState([]);
  const [filterNames, setFilterNames] = useState([]);
  const [totalFilterCount, setTotalFilterCount] = useState();
  const [appliedFilterCount, setAppliedFilterCount] = useState();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [firstApiCall, setFirstApiCall] = useState(false);
  let token = localStorage.getItem("gw_token");

  const alert = useAlert();

  const [newData, setNewData] = useState(data);
  const [allData, setAllData] = useState(data);
  const checkString = (str) => {
    if (str !== "") return `?${str}`;
    else return "";
  };
  const getFilters = (str) => {
    if (!nextUrl || end) setLoader(true);

    Axios.get(
      `${BackendBaseApi.PRAVESH}api/colleges/college_filters/${checkString(
        str
      )}`,
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    )
      .then((res) => {
        setData(res.data.data);
        var newArr = {};
        var temp = {};
        var arr = [];
        setTotalFilterCount(Object.keys(res.data.data).length);
        Object.keys(res.data.data).forEach((head) => {
          arr = res.data.data[head].data.map((v) => ({
            ...v,
            isChecked: !firstApiCall
              ? false
              : checkIfTrue(v, res.data.data[head].param_name),
          }));

          newArr[head] = {
            heading: res.data.data[head].heading,
            param_name: res.data.data[head].param_name,
            data: arr,
          };

          temp[res.data.data[head].param_name] = [];
        });

        setNewData(newArr);

        if (!firstApiCall) {
          setAllData(newArr);

          setFilterObject(temp);
        }

        setFirstApiCall(true);
        setLoader(false);
      })
      .catch((err) => {
        alert.error("Error occured!");
        setLoader(false);
      });
  };

  const checkIfTrue = (obj, head) => {
    var boole = false;
    filterObject[head].map((u, iin) => {
      if (u.id === obj.id) {
        boole = true;
      }
    });
    return boole;
  };

  const generateCsv = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item.id + ",")
        : (str += item.id)
    );
    return str;
  };

  const handleDelete = (key, array, setArr, head) => {
    let sampleArr = [...array];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      //   sampleArr.splice(index, 1);
      //   setArr(sampleArr);
      // }
      var new_arr = [...filterArray];
      var arr = filterObject[head];

      var temp = filterObject;

      arr.splice(index, 1);
      new_arr.push(key);
      setFilterArray(new_arr);

      temp[head] = arr;
      setFilterObject(temp);

      setCollegeData([]);
      setNextUrl(null);
      setEnd(false);
    }
  };

  useEffect(() => {
    let str = "";
    var count = 0;

    Object.keys(filterObject).forEach((head, index) =>
      Object.keys(filterObject).indexOf(head) !==
      Object.keys(filterObject).length - 1
        ? (str +=
            filterObject[head].length > 0
              ? head + "=" + generateCsv(filterObject[head]) + "&"
              : "")
        : (str +=
            filterObject[head].length > 0
              ? head + "=" + generateCsv(filterObject[head])
              : "")
    );

    Object.keys(filterObject).forEach((head, index) => {
      if (filterObject[head].length > 0) count = count + 1;
    });
    setAppliedFilterCount(count);

    setSearchString(str);
    getFilters(str);
  }, [filterArray.length]);
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<FilterListIcon />}
        style={{
          marginLeft: "20px",
          marginBottom: "20px",
          marginTop: "-30px",
        }}
      >
        Filters ({appliedFilterCount}/{totalFilterCount})
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Applied Filters ({appliedFilterCount}/{totalFilterCount})
          <br />
          {Object.keys(filterObject).map((item, index) =>
            filterObject[item].map((item1) => (
              <Chip
                label={item1.name}
                onDelete={() =>
                  handleDelete(item1, filterObject[item], setFilterObject, item)
                }
                classes={{ deleteIcon: classes.deleteIcon }}
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#424242",
                  color: "#ffffff",
                  fontWeight: "bold",
                  marginRight: "8px",
                  marginBottom: "8px",
                }}
              />
            ))
          )}
        </DialogTitle>
        <DialogContent dividers>
          {loader === true ? (
            <img src={loader_gif} alt="" height="170px" />
          ) : (
            <>
              <div className={classes.root}>
                {Object.keys(newData).map((heading) => (
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      <Typography className={classes.heading}>
                        {newData[heading].heading}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.listItem}
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      {newData[heading].data.map((item, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.isChecked}
                              onChange={(e) => {
                                if (item.isChecked === false) {
                                  var new_arr = [...filterArray];
                                  var arr =
                                    filterObject[newData[heading].param_name];
                                  var temp = filterObject;
                                  arr.push({ id: item.id, name: item.name });
                                  new_arr.push(item.id);
                                  setFilterArray(new_arr);
                                  temp[newData[heading].param_name] = arr;
                                  setFilterObject(temp);
                                } else {
                                  var new_arr = [...filterArray];
                                  var arr =
                                    filterObject[newData[heading].param_name];

                                  var temp = filterObject;

                                  var indexx;
                                  arr.map((u, iin) => {
                                    if (u.id === item.id) {
                                      indexx = iin;
                                    }
                                  });

                                  arr.splice(indexx, 1);
                                  new_arr.push(item.id);
                                  setFilterArray(new_arr);

                                  temp[newData[heading].param_name] = arr;
                                  setFilterObject(temp);
                                }
                                setCollegeData([]);
                                setNextUrl(null);
                                setEnd(false);
                                item.isChecked = e.target.checked;
                              }}
                              name="gilad"
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label={item.name + " (" + item.count + ")"}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </>
          )}
        </DialogContent>
        {/* <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Save changes
              </Button>
            </DialogActions> */}
      </Dialog>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    // border: "2px solid #000",
    flexDirection: "column",
    backgroundColor: "#fff",
    alignItems: "center",
  },
  leftPaper: {
    backgroundColor: "#fff",
    borderRadius: "30px",
    flex: 1,
    //display: "flex",
    display: "grid",
    alignItems: "center",
    flexDirection: "column",
    // height: "260px",
    // width: "220px",
    //marginLeft: "40px",
    // border: "2px solid #000",
    background: "#ffffff",
    //paddingTop: "100px",
    // paddingTop: "1%",
    position: "fixed",
    paddingBottom: "15px",
  },
  leftMenuBox: {
    // width: "170px",
    height: "38px",
    marginTop: "15px",
    marginRight: "10px",
    marginLeft: "10px",
    borderRadius: 30,
    backgroundColor: "#fff",
    color: "#007BFF",
    boxShadow: "none",
    border: "1px solid #007BFF",
  },
  leftMenuBoxSelected: {
    //width: "170px",
    boxShadow: "none",
    height: "38px",
    marginTop: "15px",
    marginRight: "10px",
    marginLeft: "10px",
    borderRadius: "30px",
    color: "#fff",
    backgroundColor: "#007BFF",
    fontWeight: "bold",
  },
  paperStyle: {
    //  marginLeft: "280px",
    width: "100%",
    borderRadius: "30px",
    marginTop: "2%",
    paddingTop: "30px",
    paddingLeft: "20px",
    paddingBottom: "15px",
    marginBottom: "35px",
    borderRadius: "25px",

    //boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",

    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
  },
  avatarBox: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
    borderRadius: "30px",
  },

  fieldDiv: {
    display: "flex",
    flexDirection: "row",
    marginTop: "15px",
    alignItems: "center",
    borderRadius: "30px",
    marginBottom: "15px",
    // border: "2px solid #000",
  },
  heading1: {
    // width: "111px",
    //border: "2px solid #000",
    height: "30px",
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //paddingTop: "8%",
    borderRadius: "30px",
    paddingRight: "30px",
    fontSize: 13,
    fontWeight: "bold",
  },
  heading3: {
    width: "100px",
    //border: "2px solid #000",
    height: "30px",
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "-20px",
    //paddingTop: "8%",
    paddingRight: "30px",
    fontSize: 13,
    fontWeight: "bold",
  },
  inputBox: {
    // marginLeft: "35px",
    borderRadius: "30px",
    width: "250px",
    paddingLeft: "10px",
    height: "40px",
    fontSize: 14,
    fontWeight: "bold",

    // borderRadius: "5px",
  },
  inputBoxE: {
    // marginLeft: "35px",
    // paddingLeft: "5px",
    height: "35px",
    borderRadius: "30px",
    marginRight: "30px",
    display: "flex",
    alignItems: "center",
    fontSize: 12,

    // borderRadius: "5px",
  },
  heading: {
    width: "100px",
    // border: "2px solid #000",
    height: "30px",
    paddingLeft: "5px",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 13,
    fontWeight: "bold",
  },
  firstBox: {
    display: "flex",
    flexDirection: "row",
    // border: "1px solid #000",
    justifyContent: "space-between",
    borderRadius: "30px",
    alignItems: "center",
    paddingRight: "100px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "30px",
    marginTop: "20px",
    borderRadius: "30px",
    marginBottom: "20px",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: "#3282C4",
    color: "#fff",
    paddingRight: "20px",
    borderRadius: "30px",
    paddingLeft: "20px",
  },
  inputBox2: {
    // marginLeft: "35px",
    //width: "650px",
    width: "80%",
    borderRadius: "20px",
    height: "150px",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontSize: 12,
  },
  radioStyle: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "30px",
    alignItems: "center",
    width: "83%",
  },
  radioStylenew: {
    display: "flex",
    marginTop: "20px",
    flexDirection: "column",
    borderRadius: "30px",
    alignItems: "flex-start",
    width: "77%",
  },
  radioStylesecond: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "30px",
    alignItems: "flex-start",
    width: "77%",
  },
  fieldDivnew: {
    display: "flex",
    flexDirection: "row",
    marginTop: "15px",
    alignItems: "center",
    borderRadius: "30px",
    marginBottom: "15px",
    width: "48%",
    marginLeft: "40px",
    // border: "2px solid #000",
  },
  fieldDivExp: {
    display: "flex",
    flexDirection: "row",
    marginTop: "15px",
    alignItems: "center",
    borderRadius: "30px",
    //marginBottom: "15px",
    // border: "2px solid #000",
  },
};

export default styles;

import {
  Grid,
  Paper,
  InputBase,
  IconButton,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Menu,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { purple } from "@material-ui/core/colors";
import FilterCTC from "./FilterCTC";
import Calendar from "../Calendar/Calednar";
import FilterStipend from "./FilterCTC/FilterStipend";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       //  width: 250,
//     },
//   },
// };

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const PurpleSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: "100%",

    backgroundColor: "#fff",
    borderRadius: "30px",
    border: "none",
  },
  button: {
    borderRadius: "30px",
    height: "35px",
    paddingLeft: "30px",
    display: "flex",
    marginRight: "12px",
    padding: "7px",
    paddingRight: "30px",
    boxShadow: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    // border: ".5px solid #b0b7c3",
    width: "100%",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    boxShadow: "none",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: "30px",
    height: "40px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    // fontSize: "14px",
    color: "#c5c4ba",
    // height: "40px",
    // boxShadow: " 0px 0px 4px 0px #00000040",
    // border: "none",

    transform: "translate(15px, 14px) scale(1)",
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "30px",
    display: "flex",
    height: "40px",
    paddingLeft: "30px",
    marginRight: "10px",
    paddingRight: "30px",
  },
  inputctc: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingLeft: "10px",
    borderRadius: "30px",
    border: "1px solid #b0b7c3",
    width: "60px",
  },
  clearIndicator: {
    backgroundColor: "gray",
    "& span": {
      "& svg": {
        backgroundColor: "red",
      },
    },
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z')",
        },
      },
    },
  },
}));

const variants = [
  {
    id: 3,
    name: "Voucher",
    slug: "voucher",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:27:23.000Z",
    updated_at: "2021-11-15T08:27:23.000Z",
    cover: null,
  },
  {
    id: 1,
    name: "Top Up",
    slug: "top-up",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:26:44.000Z",
    updated_at: "2021-11-15T08:26:44.000Z",
    cover: null,
  },
  {
    id: 2,
    name: "Game Key",
    slug: "game-key",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:27:03.000Z",
    updated_at: "2021-11-15T08:27:03.000Z",
    cover: null,
  },
  {
    id: 12,
    name: "Other",
    slug: "other",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:50.000Z",
    updated_at: "2021-11-15T08:30:50.000Z",
    cover: null,
  },
  {
    id: 11,
    name: "Nintendo",
    slug: "nintendo",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:22.000Z",
    updated_at: "2021-11-15T08:30:22.000Z",
    cover: null,
  },
  {
    id: 10,
    name: "Xbox",
    slug: "xbox",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:08.000Z",
    updated_at: "2021-11-15T08:30:08.000Z",
    cover: null,
  },
];

function ManageJobActionBar({
  filters,
  setFilters,
  clickSearchButton,
  setClickSearchButton,
  setApplyClickedCTC,
  setApplyClickedStipend,
}) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(null);

  useEffect(() => {
    setFilters({ ...filters, created: selectedDate });
  }, [selectedDate]);
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  return (
    <>
      <div>
        <Grid container spacing={4}>
          <Grid item sm={2}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search by Name"
                inputProps={{
                  style: { fontSize: "14px" },
                }}
                value={filters.job_title}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    job_title: e.target.value,
                  });
                }}
              />
              {filters?.job_title && (
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      job_title: "",
                    });
                    setClickSearchButton(true);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => {
                  setClickSearchButton(true);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              id="country-select-demo"
              options={data}
              getOptionLabel={(option) => option.title}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>
                  {option.title}
                </Typography>
              )}
              style={{ background: "#fff", borderRadius: 30 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // size="small"
                  variant="outlined"
                  label="Job Type"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
              value={filters.job_type}
              onChange={(e, newValue) => {
                setFilters({ ...filters, job_type: newValue });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              id="country-select-demo"
              options={hiringType}
              getOptionLabel={(option) => option}
              renderOption={(option) => (
                <Typography style={{ fontSize: "14px" }}>{option}</Typography>
              )}
              style={{ background: "#fff", borderRadius: 30 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Hiring Type"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              )}
              value={filters.hiring_type}
              onChange={(e, newValue) => {
                setFilters({ ...filters, hiring_type: newValue });
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ display: "grid" }}>
            <FilterCTC
              ctcData={filters}
              setCtcData={setFilters}
              setApplyClickedCTC={setApplyClickedCTC}
            />{" "}
          </Grid>
          <Grid item xs={2} style={{ display: "grid" }}>
            <FilterStipend
              ctcData={filters}
              setCtcData={setFilters}
              setApplyClickedStipend={setApplyClickedStipend}
            />{" "}
          </Grid>

          <Grid item xs={2} style={{ display: "grid" }}>
            <Calendar
              title="Posted On"
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />{" "}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ManageJobActionBar;

const data = [
  { title: "Internship", id: 1 },
  { title: "Full-time", id: 7 },
];

const hiringType = ["On Campus", "Off Campus"];

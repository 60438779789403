import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function AchievementsNew({ data }) {
  const classes = useStyles();

  const getData = (data) => {
    return [
      {
        line: data,
      },
    ];
  };

  return (
    <>
      <div className={classes.overview}>
        <div className={classes.div}>
          <Typography variant="h5">Achievements</Typography>
        </div>

        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {getData(data).map((data) => (
            <div style={{ marginTop: "7px" }}>
              <ul style={{ marginLeft: "-22px", color: "#000" }}>
                {data.line.map((line) => (
                  <li>
                    <Typography
                      variant="body2"
                      key={line}
                      style={{
                        marginBottom: "10px",
                        fontWeight: "400",
                        color: "#000",
                      }}
                    >
                      {line}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

import * as React from "react";
import {
  Box,
  Container,
  Dialog,
  useMediaQuery,
  useTheme,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  IconButton,
} from "@mui/material";
import RegisterApplyJob from "../Section/LoginRightSection/RegisterApplyJob";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterGetworkDialog({
  open,
  handleClickOpen,
  handleClose,
  setStep,
  userData,
  isNewUser,
  checkUser
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [maxWidth, setMaxWidth] = React.useState("sm");
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            [theme.breakpoints.only("sm")]: {
              borderRadius: 0,
            },
            [theme.breakpoints.only("xs")]: {
              borderRadius: 0,
            },
          },
        }}
      >
        <AppBar
          color="inherit"
          sx={{
            borderBottom: "1px solid #E3E6EA",
            position: "relative",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackRoundedIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sign Up
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            pl: 1,
            pr: 1,
            pb: 2,
            [theme.breakpoints.only("sm")]: {
              pb: 0,
            },
            [theme.breakpoints.only("xs")]: {
              pb: 0,
            },
          }}
        >
          <Container maxWidth="lg">
            <RegisterApplyJob userData={userData} isNewUser={isNewUser} checkUser={checkUser}/>
          </Container>
        </Box>
      </Dialog>
    </div>
  );
}

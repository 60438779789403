import { Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const Green = {
  border: "1.5px solid #0B8A00",
  color: "#0B8A00",
};

const Red = {
  border: "1.5px solid red",
  color: "red",
};

const closeIconGreen = {
  marginLeft: "10px",
  cursor: "pointer",
  color: "green",
};

const closeIconRed = {
  marginLeft: "10px",
  cursor: "pointer",
  color: "red",
};

const NewAlertTemplate = ({ style, options, message, close }) => {
  return (
    <div style={style}>
      <>
        <Alert
          icon={false}
          severity={options.type === "success" ? "success" : "error"}
          sx={options.type === "success" ? Green : Red}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              sx={options.type === "success" ? closeIconGreen : closeIconRed}
              size="small"
              onClick={close}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </>
    </div>
  );
};
export default NewAlertTemplate;

export default function setNewCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=getwork.org";
}
export function getCookie(cname) {
  var name = cname + "=";
  var decoded_cookie = decodeURIComponent(document.cookie);

  var carr = decoded_cookie.split(";");

  for (var i = 0; i < carr.length; i++) {
    var c = carr[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}
export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function getNewCookie(name) {
  console.log(document.cookie);
  const cookieValue = document.cookie.split("; ").find((row) => row.startsWith(name));
  // .split("=")[1];

  return cookieValue;
}

import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Shareicon from "../../../assets/svg/share.svg";
import { IconButton, withStyles, Typography, Divider, Grid, Paper, InputBase } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LinkIcon from "@material-ui/icons/Link";
import CropFreeIcon from "@material-ui/icons/CropFree";
import MultipleGmail from "./MultipleGmail";
import UserDetail from "./UserDetail";
import sharelink from "../../../assets/gif/Sharelink.gif";
import sharelink_dark from "../../../assets/gif/sharelink_dark.gif";

import WhatsAppIcon from "../../../assets/common/icons8-whatsapp.svg";
import MailOutlineIcon from "../../../assets/common/icons8-email-100.png";
import Action from "./Action";
import Submit from "./Submit";
import UserTable from "./UserTable";
import { BackendBaseApi } from "../../../constants/constants";
import Axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: "30px",
  },
  paper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "auto",
    border: "solid 0.5px rgba(0 0 0 0.12)",
    borderRadius: "12px",
    boxShadow: "rgba(0 0 0 0.25) 0px 54px 55px rgba(0 0 0 0.12) 0px -12px 30px rgba(0 0 0 0.12) 0px 4px 6px rgba(0 0 0 0.17) 0px 12px 13px rgba(0 0 0 0.09) 0px -3px 5px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  img: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  share: {
    display: "flex",
    flexDirection: "column",
  },
  icons: {
    display: "flex",
    marginLeft: "-15px",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function Share({ jobProfileObject, filterObject, jobProfileName, fromWithoutAction }) {
  const classes = useStyles();
  const alert = useAlert();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [generatedLink, setGeneratedLink] = useState("");
  const [passCode, setPassCode] = useState("");
  const [actionState, setActionState] = useState({
    track: false,
    download: false,
  });
  const [detailState, setDetailState] = useState([]);
  const [usermail, setUsermail] = useState([]);

  // console.log('fgnbg', filterObject)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (jobProfileName && open && localStorage.getItem("user_type_id")) {
      Axios.post(
        BackendBaseApi.NIYUKTI + "job/track/sharer/link/",
        {
          job_id: jobProfileName,
          shared_by: localStorage.getItem("user_type") === "Company" ? "COMPANY" : "COLLEGE",
          sharer_user_id: localStorage.getItem("user_id"),
          sharer_user_type_id: localStorage.getItem("user_type_id"),
          sharer_id: localStorage.getItem("user_type") === "Company" ? JSON.parse(localStorage.getItem("company")).company : localStorage.getItem("college_id"),
          // ...(localStorage.getItem("user_type") === "Company" && {
          //   filtered_by: filterObject.applicant_college_id.length === 0 ? null : filterObject.applicant_college_id.map((t) => t.id),
          // }),
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
          },
        }
      )
        .then((res) => {
          if (res.data.success) {
            setGeneratedLink(res.data.data.link);
          } else alert.error("Link was not generated. Some error occurred");
        })
        .catch((err) => {
          alert.error("Some error occured!");
        });
    }
  }, [open, jobProfileName, filterObject]);

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <img src={Shareicon} height="30px" width="30px" /> &nbsp; {fromWithoutAction && <span style={{ fontSize: 14 }}>Share</span>}
      </IconButton>
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            borderRadius: 30,
            border: "solid 0.5px #e2e6ea",
            boxShadow: "rgba(149 157 165 0.2) 0px 8px 24px",
          },
        }}
        fullScreen={fullScreen}
        // maxWidth="xl"
        open={open}
        onClose={handleClose}
        className={classes.dialog}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Share Applicant Tracking - {jobProfileObject?.job_title}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} className={classes.img}>
              <img src={theme.palette.type === "dark" ? sharelink_dark : sharelink} height="300px" width="300px" />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  {/* Details section start here */}
                  <Typography variant="h6">Link</Typography>
                  <Paper className={classes.paper}>
                    <CopyToClipboard text={generatedLink} onCopy={() => alert.info("Link Copied!")}>
                      <IconButton className={classes.iconButton} aria-label="menu">
                        <LinkIcon />
                      </IconButton>
                    </CopyToClipboard>
                    <Divider className={classes.divider} orientation="vertical" />

                    <InputBase className={classes.input} placeholder="Track Link" value={generatedLink} inputProps={{ "aria-label": "https://www.getwork.org/" }} />

                    {/* <CopyToClipboard text={generatedLink} onCopy={() => alert.info("Link Copied!")}>
                      <Button variant="contained" style={{ backgroundColor: "#007bff", borderRadius: "12px" }}>
                        Copy
                      </Button>
                    </CopyToClipboard> */}
                  </Paper>
                </Grid>

                <Grid item xs={2} sm={4}>
                  {/* passcode input section start here */}
                  <Typography variant="h6">Passcode</Typography>
                  <Paper className={classes.paper}>
                    <IconButton className={classes.iconButton} aria-label="menu">
                      <CropFreeIcon />
                    </IconButton>
                    <Divider className={classes.divider} orientation="vertical" />
                    <InputBase
                      className={classes.input}
                      value={passCode}
                      onChange={(e) => setPassCode(e.target.value)}
                      placeholder="Passcode"
                      inputProps={{
                        minLength: 6,
                        maxLength: 6,
                      }}
                      // defaultValue="859306"
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12}>
                  {/* Multiple Mail section here */}
                  <Typography variant="h6">Invite People (Optional)</Typography>
                  <Paper className={classes.paper}>
                    <MultipleGmail usermail={usermail} setUsermail={setUsermail} />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  {/* choose some basic details here section*/}
                  <Typography variant="h6">Choose Details to be visible</Typography>
                  <UserDetail detailState={detailState} setDetailState={setDetailState} />
                </Grid>
                <Grid item xs={6} sm={3} md={4}>
                  {/* social media share icons here*/}
                  {/* <div className={classes.share}>
             <Typography variant="h6">Share With</Typography>
          <div className={classes.icons}>
          <IconButton  aria-label="delete" onClick={handleClickOpen}>
                <img src={WhatsAppIcon} height="35px" width="35px" />
          </IconButton>
          <IconButton aria-label="delete" onClick={handleClickOpen}>
                <img src={MailOutlineIcon} height="35px" width="35px" />
          </IconButton>
        </div> 
        </div> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: "-20px" }}>
                  {/*track action section */}
                  <Typography variant="h6">User Actions</Typography>
                  <Action actionState={actionState} setActionState={setActionState} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* User Mail History*/}
          <UserTable jobID={jobProfileName} filterObject={filterObject} />
          <Divider />
        </DialogContent>
        <DialogActions>
          <Submit
            jobID={jobProfileName}
            generatedLink={generatedLink}
            passCode={passCode}
            filterObject={filterObject}
            usermail={usermail}
            detailState={detailState}
            actionState={actionState}
            handleShareOpen={handleClose}
            jobProfileObject={jobProfileObject?.job_title}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import Filter from "../../components/company/Filter/Filter";
import Filter2 from "../../components/company/Filter/Filter2";
import Track_Navbar from "../../components/company/Track_Navbar/Track_Navbar";
import Pagination_Track from "../../components/common/Pagination/Pagination_Track";
import { BackendBaseApi } from "../../constants/constants";
import Axios from "axios";
import No_data from "../../assets/No_data.gif";
import No_data_dark from "../../assets/No_Data_dark.gif";

import warning from "../../assets/warning.gif";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  OuterTopBox: {
    width: "93.8%",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "30px",
    marginTop: "92px",
    marginLeft: "3.1%",
    marginBottom: "20px",
  },
}));

function Track() {
  const theme = useTheme();
  const [filterArray, setFilterArray] = useState([]);
  const [allJobProfiles, setAllJobProfiles] = useState([]);
  const params = useParams();
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const [trackData, setTrackData] = React.useState();
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState();
  const [oneApiCall, setOneApiCall] = React.useState(false);
  const [filterList, setFilterList] = React.useState({});
  const [filterObject, setFilterObject] = React.useState({});
  const [loader, setLoader] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [allCompanies, setAllCompanies] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [tabData, setTabData] = useState({});
  const [onlyClosed, setOnlyClosed] = useState(false);
  const [campusType, setCampusType] = useState(false);
  const [currentState, setCurrentState] = useState(1);
  const [currentStateObject, setCurrentStateObject] = useState({
    count: 0,
    heading: "Applied",
    param: "&status_id=MQ==",
    round_id: null,
    status_id: 1,
    tooltip: "Applied",
  });
  const history = useHistory();
  const alert = useAlert();
  const [jobProfileObjectShare, setJobProfileObjectShare] = useState({});

  const [currentStateString, setCurrentStateString] = useState("&status_id=MQ==");
  const [feedbackList, setFeedbackList] = useState();
  const [currentFeedback, setCurrentFeedback] = useState();
  const [feedbackSentState, setFeedbackSentState] = useState();
  const [feedbackTyped, setFeedbackTyped] = useState();
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [moveButtonClicked, setMoveButtonClicked] = useState(false);
  const [selectAllApplicants, setSelectAllApplicants] = useState(false);
  const [selectedApplicantsIds, setSelectedApplicantsIds] = useState([]);
  const [interviewData, setInterviewData] = useState({});
  const [subUserList, setSubUserList] = useState({});
  const [jobProfileName, setJobProfileName] = useState();
  const [errorIcon, setErrorIcon] = useState();
  const [fillDataComplete, setFillDataComplete] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [jobTitle, setJobTitile] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [loaderDownload, setLoaderDownload] = useState(false);
  const [rejectButtonClicked, setRejectButtonClicked] = useState(false);
  const [showTabPanel, setShowTabPanel] = useState(true);
  const [filterData, setFilterData] = useState(false);
  const [jobProfileObject, setJobProfileObject] = useState({});
  const [hideScrollBar, setHideScrollBar] = useState(false);
  const csvdownload = React.useRef(null);
  const [searchByName, setSearchByName] = useState("");
  const [searchButton, setSearchButton] = useState(false);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkToggleName = () => {
    if (currentStateString) return `${currentStateString}`;
    else return ``;
  };

  const checkString = () => {
    if (searchString) return `&${searchString}`;
    else return "";
  };

  const appendParam = () => {
    if (selectedJobType === "All" || selectedJobType === "") {
      return "";
    } else return `&job_type_id=${btoa(selectedJobType)}`;
  };

  const checkOnlyClosed = () => {
    if (!onlyClosed) {
      return `&job_status=${btoa("OPEN")}`;
    } else {
      return `&job_status=${btoa("CLOSE")}`;
    }
  };

  const checkCampusType = () => {
    if (!campusType) {
      return `&hiring_type=${btoa("OFF CAMPUS")}`;
    } else {
      return `&hiring_type=${btoa("ON CAMPUS")}`;
    }
  };

  const checkCollege = () => {
    if (selectedCompany === "All" || selectedCompany === "") {
      return "";
    } else {
      return `&company_id=${btoa(selectedCompany)}`;
    }
  };
  const checkJobProfile = () => {
    if (jobProfile === "") {
      return "";
    } else {
      return `&job_id=${btoa(jobProfile)}`;
    }
  };

  const appendParam1 = () => {
    if (selectedJobType === "All" || selectedJobType === "") {
      return "";
    } else return `&job_type_id:${btoa(selectedJobType)}`;
  };

  const checkOnlyClosed1 = () => {
    if (!onlyClosed) {
      return `&job_status:${btoa("OPEN")}`;
    } else {
      return `&job_status:${btoa("CLOSE")}`;
    }
  };

  const checkCampusType1 = () => {
    if (!campusType) {
      return `&hiring_type:${btoa("OFF CAMPUS")}`;
    } else {
      return `&hiring_type:${btoa("ON CAMPUS")}`;
    }
  };

  const checkCollege1 = () => {
    if (selectedCompany === "All" || selectedCompany === "") {
      return "";
    } else {
      return `&company_id:${btoa(selectedCompany)}`;
    }
  };
  const checkJobProfile1 = () => {
    if (jobProfile === "") {
      return "";
    } else {
      return `&job_id:${btoa(jobProfile)}`;
    }
  };

  useEffect(() => {
    var params_new = getSearchParameters();
    params_new &&
      Object.keys(params_new).length > 0 &&
      Object.keys(params_new).map((item) => {
        // setDiffStates(item, params_new[item]);
        if (item === "job_type_id") {
          setSelectedJobType(parseInt(window.atob(params_new[item])));
        }
        if (item === "job_id") {
          setJobProfile(parseInt(window.atob(params_new[item])));
          setJobProfileName(parseInt(window.atob(params_new[item])));
        }

        if (item === "job_status") {
          if (window.atob(params_new[item]) === "OPEN") setOnlyClosed(false);
          else setOnlyClosed(true);
        }
        if (item === "company_id") {
          setSelectedCompany(parseInt(window.atob(params_new[item])));
        }
      });
    setFillDataComplete(true);
    // getTrackData(1);
  }, [location.search]);

  useEffect(() => {
    if (fillDataComplete) {
      getTrackData(1, 2);
      getFilters();
      getRoundsDynamic();
      getJobProfiles();
    }
  }, [fillDataComplete]);

  function getSearchParameters() {
    var prmstr = location.search.substr(1);
    return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
  }

  function transformToAssocArray(prmstr) {
    var params = {};
    var prmarr = prmstr.split("&");
    for (var i = 0; i < prmarr.length; i++) {
      var tmparr = prmarr[i].split(":");
      params[tmparr[0]] = tmparr[1];
    }
    return params;
  }

  const setDiffStates = (key, value) => {
    switch (key) {
      case "job_id":
        setJobProfile(parseInt(window.atob(value)));
      case "hiring_type":
        window.atob(value) === "ON CAMPUS" ? setCampusType(true) : setCampusType(false);
      // case "job_type_id":
      //   setSelectedJobType(parseInt(window.atob(value)));
      case "job_status":
        window.atob(value) === "OPEN" ? setOnlyClosed(false) : setOnlyClosed(true);
      // case "college_id":
      //   setSelectedCompany(window.atob(value));

      default:
        return "All";
    }
  };

  const getJobProfiles = async () => {
    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/job/list/?college_id=${btoa(localStorage.getItem("college_id"))}${appendParam()}${checkOnlyClosed()}${checkToggleName()}${checkCollege()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });

    let profiles = [];
    let obj = { job_id: "All", job_title: "All" };

    setAllJobProfiles(res.data.data);
  };
  const getJobTypes = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch((err) => {
      throw err;
    });
    let obj = { id: "All", job_type: "All" };
    setJobType([obj, ...res.data.data]);
  };

  const getCompanies = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + `job/college/get_companies/?college_id=${btoa(localStorage.getItem("college_id"))}`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    }).catch((err) => {
      throw err;
    });
    let obj = { company_name: "All", company_id: "All" };
    setAllCompanies([obj, ...res.data.data]);
  };

  useEffect(() => {
    // getJobProfiles();
    getJobTypes();
    getCompanies();
  }, []);

  const checkSearch = () => {
    if (searchByName) return `&search=${searchByName}`;
    else return ``;
  };

  const getTrackData = (flag, val) => {
    setLoader(true);

    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/track/applicants/?college_id=${btoa(localStorage.getItem("college_id"))}&page=${
          flag === 1 ? 1 : page
        }${checkToggleName()}${checkJobProfile()}${appendParam()}${checkOnlyClosed()}${checkCollege()}${val === 9 ? "" : checkString()}${val === 9 ? "" : checkSearch()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success && !res.data?.data?.message) {
          setLoader(true);
          if (theme.palette.type === "dark") setErrorIcon(No_data_dark);
          else setErrorIcon(No_data);

          setErrorMessage("No Data Present");
          setOneApiCall(true);
          res.data.data.results.applicant_data &&
            res.data.data.results.applicant_data.length > 0 &&
            res.data.data.results.applicant_data.forEach((item) => {
              item.checked = false;
            });
          setTrackData(res.data.data.results.applicant_data);
          setTotalCount(res.data.data.count);

          setJobProfileName(parseInt(res.data.data.results.job_id));
          allJobProfiles.length > 0 &&
            allJobProfiles.map((item) => {
              if (item.job_id === parseInt(res.data.data.results.job_id)) setJobProfileObject(item);
            });

          if (res.data.data.results.applicant_data.length > 0) setJobTitile(res.data.data.results.applicant_data[0].job_title);
          setShowTabPanel(true);
          setSearchButton(false);
        } else if (res.data.success && res.data?.data?.message) {
          setOneApiCall(true);
          setTotalCount(0);

          if (theme.palette.type === "dark") setErrorIcon(No_data_dark);
          else setErrorIcon(No_data);
          setErrorMessage("No recent job with this filter");
          setShowTabPanel(false);

          setSearchButton(false);
        } else {
          setOneApiCall(true);
          setTotalCount(0);

          setErrorIcon(warning);
          setErrorMessage("Some Error Occurred");
          setShowTabPanel(false);

          setSearchButton(false);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setOneApiCall(true);
        setTotalCount(0);

        setErrorIcon(warning);
        setErrorMessage("Some Error Occurred");
        setShowTabPanel(false);
      });
  };

  const getTrackDownloadData = (flag) => {
    setLoaderDownload(true);

    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/track/applicants/?college_id=${btoa(
          localStorage.getItem("college_id")
        )}&download=${true}${checkToggleName()}${checkJobProfile()}${appendParam()}${checkOnlyClosed()}${checkCollege()}${flag === 9 ? "" : checkString()}${
          flag === 9 ? "" : checkSearch()
        }`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success && !res.data?.data?.message) {
          // if (res.data.data.results.applicant_data.length > 0)
          //   setJobTitile(res.data.data.results.applicant_data[0].job_title);
          getDownloadData(res);
          setDownloadButtonClicked(false);
        }
      })
      .catch((err) => {
        setLoaderDownload(false);
        setDownloadButtonClicked(false);
      });
  };

  const getFilters = () => {
    setFilterLoader(true);
    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/track/filters/?college_id=${btoa(
          localStorage.getItem("college_id")
        )}${checkToggleName()}${checkJobProfile()}${appendParam()}${checkOnlyClosed()}${checkCollege()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          Object.keys(res.data.data).forEach((item) => {
            res.data.data[item].selected_data = [];
          });
          setFilterList(res.data.data);
          var temp = {};

          Object.keys(res.data.data).forEach((head) => {
            temp[res.data.data[head].param_name] = [];
          });

          Object.keys(res.data.data).map((item) => {
            if (res.data.data[item].data.length > 0) setFilterData(true);
          });

          setFilterObject(temp);
        }
        setFilterLoader(false);
      })
      .catch((err) => {
        setFilterLoader(false);
      });
  };

  const getRoundsDynamic = () => {
    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/track/applicants/count?college_id=${btoa(localStorage.getItem("college_id"))}${checkJobProfile()}${appendParam()}${checkOnlyClosed()}${checkCollege()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setTabData(res.data.data);
        }
      })
      .catch((err) => {
        console.log("Some Error Occured");
      });
  };
  const getAllReasons = () => {
    Axios.get(
      BackendBaseApi.NIYUKTI + `job/company/feedback/?company_id=${window.btoa(1)}&status=${window.btoa(feedbackSentState?.status_id ? feedbackSentState?.status_id : 14)}`,
      {
        headers: { Authorization: `Token ${localStorage.getItem("gw_token")}` },
      }
    )
      .then((res) => {
        setFeedbackList([...res.data.data, { feedback_id: "others", feedback_name: "Others" }]);
      })
      .catch((err) => {
        alert.error("Some Error Occured");
      });
  };

  const moveToNextStep = () => {
    var selectedApplicantIds = [];
    selectedApplicants.map((item) => {
      selectedApplicantIds.push(item.id);
    });

    var data = [];
    for (let i = 0; i < selectedApplicants.length; i++) {
      data.push({
        id: selectedApplicantIds[i],
        round: feedbackSentState?.round_id,
        status: feedbackSentState?.status_id,
      });
    }
    Axios.post(
      BackendBaseApi.NIYUKTI + "job/company/status_update/",
      {
        student_data: data,
        applicant_feedback: {
          user_id: parseInt(localStorage.getItem("user_id")),
          status_id: feedbackSentState?.status_id,
          status_name: feedbackSentState?.name,
          feedback_id: currentFeedback && currentFeedback?.feedback_name !== "Others" ? currentFeedback?.feedback_id : null,
          feedback_name: currentFeedback && currentFeedback?.feedback_name !== "Others" ? currentFeedback?.feedback_name : feedbackTyped,
        },
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          var myArray = [];
          let temp = trackData.filter((data) => selectedApplicantIds.includes(data.applicant_id));
          myArray = trackData.filter((item) => !temp.includes(item));
          alert.success("Applicant(s) succesfully moved to " + feedbackSentState?.name + " state");

          setSelectedApplicants([]);
          setFeedbackSentState();
          getRoundsDynamic();
          if (totalCount > 10 && myArray.length <= 10 - (totalCount % 10)) {
            getTrackData(1, 2);
            setTotalCount(totalCount - myArray.length);
          } else setTrackData(myArray);

          setMoveButtonClicked(false);

          setFeedbackTyped();
          setCurrentFeedback();
          setRejectButtonClicked(false);
        } else {
          alert.error("Applicant was not moved. Some Error Occurred");
        }
      })
      .catch((err) => {});
  };

  const getDownloadData = (res) => {
    var arr = [];

    var temp = {
      first_name: "",
    };

    for (var i = 0; i < res.data.data.applicant_data.length; i++) {
      var ten_percentage = [];
      var twelve_percentage = [];
      var grad_percentage = [];
      var postgrad_percentage = [];
      var phd = [];
      res.data.data.applicant_data[i].user_details.education.filter((item) => {
        if (item.education_level === 1 && "percentage" in item) {
          ten_percentage.push(item);
        }
      });

      res.data.data.applicant_data[i].user_details.education.filter((item) => {
        if (item.education_level === 3 && "percentage" in item) {
          grad_percentage.push(item);
        }
      });
      res.data.data.applicant_data[i].user_details.education.filter((item) => {
        if (item.education_level === 4 && "percentage" in item) {
          postgrad_percentage.push(item);
        }
      });
      res.data.data.applicant_data[i].user_details.education.filter((item) => {
        if (item.education_level === 2 && "percentage" in item) {
          twelve_percentage.push(item);
        }
      });
      res.data.data.applicant_data[i].user_details.education.filter((item) => {
        if (item.education_level === 5 && "percentage" in item) {
          phd.push(item);
        }
      });

      arr.push({
        first_name: res.data.data.applicant_data[i].user_details.first_name,
        last_name: res.data.data.applicant_data[i].user_details.last_name,
        email: res.data.data.applicant_data[i].user_details.email,
        phone: res.data.data.applicant_data[i].user_details.phone,
        current_city: res.data.data.applicant_data[i].user_details.current_city,
        resume: res.data.data.applicant_data[i].user_details.resume,
        tenth: ten_percentage.length > 0 ? ten_percentage[0].percentage : "NA",

        twelfth: twelve_percentage.length > 0 ? twelve_percentage[0].percentage : "NA",

        graduation: grad_percentage.length > 0 ? grad_percentage[0].percentage : "NA",

        Post_graduation: postgrad_percentage.length > 0 ? postgrad_percentage[0].percentage : "NA",

        Phd: phd.length > 0 ? phd[0].percentage : "NA",
      });
    }
    setExcelData(arr);
    setLoaderDownload(false);
  };

  useEffect(() => {
    if (excelData.length > 0 && csvdownload && csvdownload.current && csvdownload.current.link) {
      setTimeout(() => {
        csvdownload.current.link.click();
        setExcelData([]);
      });
    }
    // setexcelData([]);
  }, [excelData]);

  useEffect(() => {
    if (oneApiCall) getRoundsDynamic();
  }, [jobProfile, selectedCompany, selectedJobType, onlyClosed, campusType]);

  useEffect(() => {
    window.history.pushState({}, null, `/college/track/?${checkJobProfile1()}${appendParam1()}${checkOnlyClosed1()}${checkCollege1()}`);
  }, [jobProfile, jobProfileName, selectedCompany, selectedJobType, onlyClosed, campusType]);

  useEffect(() => {
    var flag = 1;
    var val = 9;
    if (oneApiCall) {
      getTrackData(flag, val);
      setFilterData(false);

      getFilters();
    }
  }, [
    selectedCompany,
    selectedJobType,
    jobProfile,
    // jobProfileName,
    onlyClosed,
    campusType,
    value,
  ]);

  useEffect(() => {
    if (oneApiCall) getJobProfiles();
  }, [selectedCompany, selectedJobType, onlyClosed, campusType]);

  useEffect(() => {
    var flag = 1;
    if (oneApiCall) getTrackData(flag, 2);
  }, [searchString]);

  useEffect(() => {
    var flag = 0;
    if (oneApiCall) getTrackData(flag, 2);
  }, [page]);

  useEffect(() => {
    if (feedbackSentState) {
      getAllReasons();
    }
  }, [feedbackSentState]);

  useEffect(() => {
    if (moveButtonClicked && selectedApplicants) {
      moveToNextStep();
    }
  }, [moveButtonClicked]);

  useEffect(() => {}, [selectedApplicants]);

  useEffect(() => {
    if (allJobProfiles.length === 0) setTrackData([]);
  }, [allJobProfiles]);

  useEffect(() => {
    if (downloadButtonClicked) getTrackDownloadData(9);
  }, [downloadButtonClicked]);

  //reject
  const [feedbackListNew, setFeedbackListNew] = useState([]);
  useEffect(() => {
    if (rejectButtonClicked && feedbackListNew?.length === 0) getAllReasonsRejected();
  }, [rejectButtonClicked]);

  const getAllReasonsRejected = () => {
    Axios.get(BackendBaseApi.NIYUKTI + `job/company/feedback/?company_id=${window.btoa(1)}&status=${window.btoa(14)}`, {
      headers: { Authorization: `Token ${localStorage.getItem("gw_token")}` },
    })
      .then((res) => {
        setFeedbackListNew([...res.data.data, { feedback_id: "others", feedback_name: "Others" }]);
      })
      .catch((err) => {
        alert.error("Some Error Occured");
      });
  };
  useEffect(() => {
    allJobProfiles.length > 0 &&
      jobProfileName &&
      allJobProfiles.map((item) => {
        if (item.job_id === parseInt(jobProfileName)) {
          setJobProfileObjectShare(item);
        }
      });
  }, [jobProfileName, allJobProfiles.length]);

  useEffect(() => {
    if (searchButton) {
      var flag = 1;
      getTrackData(flag, 2);
    }
  }, [searchButton]);

  const classes = useStyles();
  return (
    <>
      <div style={{ width: "93.8%", marginLeft: "40px", marginTop: "6.3%" }}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <div style={{ position: "fixed", zIndex: "1", height: "75vh" }}>
              <Filter
                jobTypes={jobType}
                profiles={allJobProfiles}
                allCompanies={allCompanies}
                onlyClosed={onlyClosed}
                setOnlyClosed={setOnlyClosed}
                campusType={campusType}
                setCampusType={setCampusType}
                setSelectedCompany={setSelectedCompany}
                setSelectedJobType={setSelectedJobType}
                setJobProfile={setJobProfile}
                setJobProfileName={setJobProfileName}
                jobProfileName={jobProfileName}
                jobProfile={jobProfile}
                selectedJobType={selectedJobType}
                selectedCompany={selectedCompany}
                setTrackData={setTrackData}
                setJobProfileObject={setJobProfileObject}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <Filter2
              data={filterList}
              setData={setFilterList}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              loader={filterLoader}
              setLoader={setFilterLoader}
              filterArray={filterArray}
              setFilterArray={setFilterArray}
              setSearchString={setSearchString}
              filterData={filterData}
              trackLoader={loader}
              searchButton={searchButton}
              setSearchButton={setSearchButton}
              setSearchByName={setSearchByName}
              searchByName={searchByName}
            />
          </Grid>
          <Grid item xs={9} style={{ marginTop: "40px" }}>
            <Track_Navbar
              value={value}
              handleChange={handleChange}
              trackData={trackData}
              loader={loader}
              setLoader={setLoader}
              tabData={tabData}
              setCurrentState={setCurrentState}
              setCurrentStateString={setCurrentStateString}
              currentState={currentState}
              currentStateString={currentStateString}
              feedbackList={feedbackList}
              currentFeedback={currentFeedback}
              setCurrentFeedback={setCurrentFeedback}
              setFeedbackSentState={setFeedbackSentState}
              feedbackSentState={feedbackSentState}
              feedbackTyped={feedbackTyped}
              setFeedbackTyped={setFeedbackTyped}
              selectedApplicants={selectedApplicants}
              setSelectedApplicants={setSelectedApplicants}
              setMoveButtonClicked={setMoveButtonClicked}
              selectAllApplicants={selectAllApplicants}
              setSelectAllApplicants={setSelectAllApplicants}
              setTrackData={setTrackData}
              setFeedbackList={setFeedbackList}
              setInterviewData={setInterviewData}
              interviewData={interviewData}
              currentStateObject={currentStateObject}
              setCurrentStateObject={setCurrentStateObject}
              setSubUserList={setSubUserList}
              subUserList={subUserList}
              errorIcon={errorIcon}
              errorMessage={errorMessage}
              jobTitle={jobTitle}
              excelData={excelData}
              totalCount={totalCount}
              filterArray={filterArray}
              setTabData={setTabData}
              loaderDownload={loaderDownload}
              downloadButtonClicked={downloadButtonClicked}
              setDownloadButtonClicked={setDownloadButtonClicked}
              rejectButtonClicked={rejectButtonClicked}
              setRejectButtonClicked={setRejectButtonClicked}
              feedbackListNew={feedbackListNew}
              showTabPanel={showTabPanel}
              jobProfileObject={jobProfileObject}
              hideScrollBar={hideScrollBar}
              setHideScrollBar={setHideScrollBar}
              getTrackDownloadData={getTrackDownloadData}
              csvdownload={csvdownload}
              jobProfileName={jobProfileName}
              jobProfileObjectShare={jobProfileObjectShare}
              showDownload={true}
              campusType={campusType}
            />
            {!loader && (
              // trackData?.length > 0 &&
              <div
                style={{
                  display: "flex",
                  // marginLeft: "28%",
                  justifyContent: "center",
                }}
              >
                <Pagination_Track page={page} handleChangePage={handleChangePage} dataCount={totalCount} />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Track;

import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  paper: {
    // minHeight: "600px",
    borderRadius: "20px",
    // textAlign: "center",
    padding: 12,
    width: "100%",
    marginBottom: 10,
  },
  paperRed: {
    // minHeight: "600px",
    borderRadius: "20px",
    // textAlign: "center",
    padding: 12,
    width: "100%",
    marginBottom: 10,
    background: "#ffdadd",
  },
  para1: {
    textAlign: "left",
    fontSize: 13,
  },
  para2: {
    color: "#6c757d",
    textAlign: "left",
    fontSize: 12,
  },
  para3: {
    textAlign: "left",
    fontSize: 12,
  },
  heading: {
    textAlign: "left",
    fontSize: 15,
  },

  paraRed1: {
    color: "#ff172c",
    textAlign: "left",
    fontSize: 13,
  },
  paraRed2: {
    color: "#ff6775",
    textAlign: "left",
    fontSize: 12,
  },
  paraRed3: {
    textAlign: "left",
    fontSize: 12,
    color: "#ca0000",
  },
  headingRed: {
    textAlign: "left",
    fontSize: 15,
    color: "#ca0000",
  },
}));

function WorkExpDetailsCards(props) {
  const { data, index, editBtn, handleDelete, editBtnParse, handleDeleteParse } = props;

  const classes = useStyles();

  const checkIfAllDataPresent = (item) => {
    var boole = false;
    if ((item.company_id || item.company_name) && item.job_designation && item.start_date && item.job_type_id) boole = true;
    return boole;
  };

  return (
    <>
      <Paper className={checkIfAllDataPresent(data) ? classes.paper : classes.paperRed}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="body1" className={checkIfAllDataPresent(data) ? classes.heading : classes.headingRed}>
              Experience&nbsp;{index + 1}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <EditIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                if (!data.is_parsed_data) editBtn(data?.id);
                else editBtnParse(index);
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <DeleteIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                if (!data.is_parsed_data) handleDelete(data?.id);
                else handleDeleteParse(index);
              }}
            />
          </Grid>
        </Grid>
        <Typography variant="body2" className={checkIfAllDataPresent(data) ? classes.para1 : classes.paraRed1}>
          {data?.job_designation + " at "}
          {
            data.company_website ? <a href={data.company_website} target="blank" style={{ color: "#007bff" }}>
              {data?.company_name}
            </a> : data?.company_name
          }
        </Typography>

        {/* Job start and End date */}
        <Typography variant="body2" className={checkIfAllDataPresent(data) ? classes.para3 : classes.paraRed3}>
          {/* {data?.formatted_start_date ? data?.formatted_start_date + " - " : ""} {data?.formatted_end_date} */}
          {moment(data?.start_date).format("MMM, YYYY")}

          {data?.end_date ? " - " + moment(data?.end_date).format("MMM, YYYY") : " - Present"}
          {data?.job_type_id && (
            <>
              &nbsp; <span style={{ color: "#003670" }}>({data?.job_type_name})</span>
            </>
          )}
        </Typography>

        <Typography variant="body2" className={checkIfAllDataPresent(data) ? classes.para2 : classes.paraRed2}>
          {data?.job_description}
        </Typography>

      </Paper>
    </>
  );
}

export default WorkExpDetailsCards;

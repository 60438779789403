import React from "react";
import { Paper, makeStyles, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
  },
  job: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  btn: {
    maxWidth: 520,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

function Project({ data, type }) {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">{type}</Typography>
        <br />

        {data?.map((item) => (
          <>
            <div className={classes.job}>
              <Typography variant="subtitle">{item?.title}</Typography>
              <Button style={{ color: "#007bff" }}>
                <a
                  href={item.links}
                  target="blank"
                  style={{ color: "#007bff", fontSize: "14px" }}
                >
                  View Live
                </a>
              </Button>
            </div>
            <Typography variant="subtitle2" style={{ color: "#6c757d" }}>
              {item.description}
            </Typography>

            <br />
          </>
        ))}
      </Paper>
    </>
  );
}

export default Project;

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import { BackendBaseApi } from "../../../constants/constants";
import kFormater from "../../../utils/common/ZeroToK";
import Loading from "../customHooks/Loading";
import ViewJob from "../viewJob/ViewJob";
import "./jobCard.css";

const AvatarStyle = {
  height: 60,
  width: 50,
  borderRadius: 5,
};

export default function JobsCard({
  data,
  interview,
  all,
  reject,
  applied,
  updateData,
  hideStatus,
  sideArrow,
  fullView,
  saveJob,
  apply,
  isReject,
  setIsReject,
}) {
  const [open, handleClose] = useState(false);
  const [jobData, setJobData] = useState([]);

  const openJob = (item) => {
    setJobData(item);
    handleClose(!open);
  };
  const callback = (value) => {
    handleClose(value);
    // do something with value in parent component, like save to state
  };

  return data.length ? (
    data.map((item) => (
      <div className="job-card" key={item.id}>
        <Grid container>
          <Grid sm={11} xs={12} lg={12} xl={12}>
            <Card
              style={{ borderLeft: "3px solid #E55935" }}
              onClick={() => openJob(item)}
            >
              <CardContent className="relative">
                {sideArrow && (
                  <div className="absolute pointer" style={{ right: 10 }}>
                    {" "}
                    <ArrowForwardIosIcon onClick={() => openJob(item)} />{" "}
                  </div>
                )}{" "}
                <Grid container>
                  <Grid xs={12} lg={12} xl={12}>
                    <Grid container className="job-card__top-header">
                      <Grid sm={1} xs={2} lg={1} xl={1} className="mg-right-5">
                        <Avatar
                          src={item.company.company_logo}
                          alt="company-picture"
                          style={AvatarStyle}
                        />
                      </Grid>
                      <Grid
                        xs={9}
                        lg={9}
                        xl={9}
                        className="flex flex-col job-card__top-header__company-detail relative"
                      >
                        {applied && (
                          <div
                            className="absolute fs-16 fw-700 text-green"
                            style={{ right: 0 }}
                          >
                            {item.applicant_status_name}
                          </div>
                        )}
                        <h4
                          className="fs-16 job-card__top-header__company-detail__title"
                          onClick={() => openJob(item)}
                        >
                          {item.job_title}
                        </h4>

                        <p className="fs-12 mg-0">
                          {item.company.company_name}
                        </p>
                        <p className="fs-12 mg-0 text-green">
                          {item.company.company_location}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container className="mg-top-10">
                    <Grid xs={12} className="fs-12 job-card__job-details">
                      <div>
                        <span className="mg-right-15 ">
                          {item.employment_type_name}
                        </span>
                        {"•"}{" "}
                        <span className="mg-right-15 text-green">
                          {kFormater(item.ctc_min)} - {kFormater(item.ctc_max)}{" "}
                          {item.salary_payment_type.toLowerCase()}
                        </span>
                        <span className="mg-right-15">{/* 1.5%-2% */}</span>
                      </div>

                      {interview && (
                        <>
                          {" "}
                          <ApplyButton jobId={item.job_id} reject={reject} />
                        </>
                      )}

                      {/* {
                                           reject && (<><RejectButton jobId={item.job_id}/> <AcceptButton setIsReject={setIsReject}  reject={reject} jobId={item.job_id}/></>)
                                       } */}
                      {
                        //    item.interview?.length ? ( <RejectAccept interviewId={item.interview[0]?.interview_id} jobId={item.job_id} reject={reject}/> ):<div className='fs-16 text-black-50'>invited for interview, details will be posted soon</div>
                      }
                    </Grid>
                  </Grid>
                  {all && (
                    <Grid container>
                      <Grid xs={12}>
                        <div className="flex justify-end">
                          <div className="mg-right-15 mg-top-10 flex align-item-center">
                            <SaveIcon
                              jobId={item.job_id}
                              isSave={item.is_saved}
                              updateData={updateData}
                            />
                          </div>
                          <div className="mg-right-15 mg-top-10 flex align-item-center">
                            <HiddenIcon
                              jobId={item.job_id}
                              hideStatus={hideStatus}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  {saveJob && (
                    <Grid container>
                      <Grid xs={12}>
                        <div className="flex justify-end">
                          <div className="mg-right-15 mg-top-10 flex align-item-center">
                            <SaveIcon
                              jobId={item.job_id}
                              isSave={true}
                              updateData={updateData}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={() => handleClose(!open)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Grid container justify="center">
            <Grid xs={12} sm={12}>
              <ViewJob
                data={jobData}
                apply={apply}
                open={fullView}
                handleClosey={callback}
              />
            </Grid>
          </Grid>
        </Modal>
      </div>
    ))
  ) : (
    <div className="text-gray fs-20 fw-700 mg-top-20">No Jobs</div>
  );
}

const ApplyButton = ({ jobId, reject }) => {
  const loading = Loading();
  const [done, setDone] = useState(false);

  const handelActive = (jobId) => {
    const data = JSON.parse(localStorage.getItem("user_details"));
    loading.changeLoading(true);
    fetch(BackendBaseApi.NIYUKTI + "job/student/apply/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&round=1&status=1&feedback=1`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.data.message);
        loading.changeLoading(false);
        setDone(true);
      });
  };
  return (
    <Button
      variant="contained"
      style={{ backgroundColor: "#3282C4" }}
      className="text-white fw-700"
      disabled={done}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
    >
      {reject ? (done ? "Accepted" : "Accept") : done ? "Applied" : "Apply"}
      {/* {
              done ? 'Applied' : 'Apply'
          } */}
    </Button>
  );
};

const AcceptButton = ({ jobId, reject, setIsReject, interviewId }) => {
  const data = JSON.parse(localStorage.getItem("user_details"));
  const loading = Loading();
  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    loading.changeLoading(true);
    if (reject) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=true&interview_id=${interviewId}`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          loading.changeLoading(false);
          setDone(true);
          setIsReject(true);
        });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=4`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          loading.changeLoading(false);
          setDone(true);
          setIsReject(true);
        });
    }
  };
  return (
    <Button
      variant="contained"
      style={{ backgroundColor: "#3282C4" }}
      className="text-white fw-700"
      disabled={done}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
    >
      {reject ? (done ? "Accepted" : "Accept") : done ? "Applied" : "Apply"}
      {/* {
              done ? 'Applied' : 'Apply'
          } */}
    </Button>
  );
};

const RejectButton = ({ jobId, isReject }) => {
  const data = JSON.parse(localStorage.getItem("user_details"));
  const loading = Loading();
  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    loading.changeLoading(true);
    fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&status=3`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.data.message);
        loading.changeLoading(false);
        setDone(true);
      });
  };
  return !isReject ? (
    <Button
      variant="outlined"
      color="primary"
      style={{ marginLeft: "18rem" }}
      className="fw-700"
      disabled={loading.loading}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
    >
      {done ? "Rejected" : "Reject"}
    </Button>
  ) : null;
};

const SaveIcon = ({ jobId, updateData, isSave }) => {
  const data = JSON.parse(localStorage.getItem("user_details"));
  const [done, setDone] = useState(isSave);
  const handelActive = (jobId) => {
    if (done) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=unsave`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          setDone(!done);
          // updateData(jobId)
        });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=saved`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          setDone(!done);
          // updateData(jobId)
        });
    }
  };
  return (
    <>
      {done ? (
        <>
          {" "}
          <BookmarkIcon
            className="pointer text-blue mg-right-5"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          />
          <span className="fs-12 text-blue">Saved</span>
        </>
      ) : (
        <>
          {" "}
          <BookmarkBorderIcon
            className="pointer text-blue mg-right-5"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          />
          <span className="fs-12 text-blue">Save</span>
        </>
      )}
    </>
  );
};
const HiddenIcon = ({ jobId, hideStatus }) => {
  const [done, setDone] = useState(hideStatus);
  const handelActive = (jobId) => {
    const data = JSON.parse(localStorage.getItem("user_details"));

    if (hideStatus) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=unhide`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          setDone(false);
        });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=hidden`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          setDone(true);
        });
    }
  };

  return (
    <>
      {done ? (
        <>
          {" "}
          <VisibilityOffIcon
            className="pointer text-blue mg-right-5"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          />
          <span className="fs-12 text-blue">hidden</span>
        </>
      ) : (
        <>
          <VisibilityIcon
            className="pointer text-blue mg-right-5"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          />
          <span className="fs-12 text-blue">hide</span>
        </>
      )}
    </>
  );
};

const RejectAccept = ({ jobId, reject, interviewId }) => {
  const [isReject, setIsReject] = useState(false);
  return (
    <>
      {!isReject && <RejectButton jobId={jobId} />}
      <AcceptButton
        setIsReject={setIsReject}
        interviewId={interviewId}
        reject={reject}
        jobId={jobId}
      />
    </>
  );
};

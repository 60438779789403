import axios from "axios";
import PurchasePlanDialog from "../components/Dialog/PurchasePlanDialog";

const fetchApi = async ({
  url,
  method = "get",
  headers = JSON.stringify({
    headers: { Authorization: `Token ${localStorage.getItem("gw_token")}` },
  }),
  body = null,
  alert = null,
  setResponse = () => {},
  setLoading = () => {},
  setError = () => {},
  setPagination = () => {},
  setOneApiCallMade = () => {},
  callBackFn = () => {},
  errorCallback = () => {},
  source = null,
}) => {
  setLoading(true);
  await axios[method](
    url,
    body
      ? JSON.parse(body)
      : source
      ? { ...JSON.parse(headers), cancelToken: source?.token }
      : JSON.parse(headers),
    body
      ? source
        ? { ...JSON.parse(headers), cancelToken: source?.token }
        : JSON.parse(headers)
      : null
  )
    .then((res) => {
      if (method === "get") {
        if (res.data.success) {
          setResponse(res.data);
          if (Object.keys(res.data.data).includes("next")) {
            setPagination({
              end: res?.data?.data?.next ? false : true,
              next: res?.data?.data?.next ? true : false,
              totalDataCount: res?.data?.data?.count,
            });
          }
          setError(null);
          setOneApiCallMade(true);
        } else {
          setError(res.data.error);
        }
      } else {
        if (res.data.success) {
          if (alert) alert.success(res.data.data.message);
          setResponse(res.data);
          callBackFn();
          console.log(" 2nd if");
        } else {
          if (res.data.data.code === 1) {
            errorCallback();
          } else {
            alert.error(res.data.error);
            setError(res.data.error);
          }
        }
      }
    })
    .catch((err) => {
      console.log("catch");
      if (axios.isCancel(err)) {
        console.log("cancelled");
      }
      setError(err?.response?.data?.error);
      if (method !== "get") alert.error(err?.response?.data?.error);
      throw err;
    })
    .finally(() => {
      setLoading(false);
    });
};
export default fetchApi;

import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Hidden,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SideDrawer from "./SideDrawer";
import SearchIcon from "@material-ui/icons/Search";
import LogInBtn from "./LogInBtn";
import RegisterBtn from "./RegisterBtn";
import DropDown from "./DropDown";
import axios from "axios";
import AvatarMenu from "./AvatarMenu";
import Logo from "../../../assets/images/sidepanel/logo.png";

import { Link, useLocation } from "react-router-dom";
import {
  BackendBaseApi,
  NEXT_APP_ENDPOINT,
} from "../../../constants/constants";
import { checkLinkingHomePage } from "../../../utils/utility";
const useStyles = makeStyles({
  appbar: {
    borderBottom: "1px solid #e2e6ea",
    backgroundColor: "white",
  },
  navbar: {
    backgroundColor: "white",
    BorderBottom: " 4px solid black",
  },
  navtab: {
    float: "right",
    paddingRight: "0px",
    padding: "6px",
  },
  navicon: {
    marginRight: "5px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    //paddingLeft: "5px",
    //paddingRight: "5px",
  },
  menuButton: {
    padding: "0px",
    // justifyContent: "flex-start",
    display: "flex",
    justifyContent: "center",
  },
  sectionDesktop: {
    display: "none",

    display: "flex",
  },
  btn: {
    "&:hover": {
      color: "#007bff",
    },
    fontSize: "14px",
    padding: "10px",
    fontFamily: "Nunito",
    fontWeight: "400",
    //fontWeight: "600",
  },
  btn_active: {
    color: "#007bff",
    padding: "10px",
    fontWeight: "20px",
  },
  tab: {
    borderRadius: "30px",
  },
  tab_active: {
    borderRadius: "30px",
    // backgroundColor: "#e8f3ff",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
});

const navLinks = [
  { title: "Students", path: `${NEXT_APP_ENDPOINT}/students` },
  { title: "Jobs", path: `${NEXT_APP_ENDPOINT}/full-time-jobs` },
  { title: "WFH Jobs", path: `${NEXT_APP_ENDPOINT}/work-from-home-jobs` },
  { title: "Internships", path: `${NEXT_APP_ENDPOINT}/internships` },

  {
    title: "WFH Internships",
    path: `${NEXT_APP_ENDPOINT}/work-from-home-internships`,
  },
  { title: "Employers", path: `${NEXT_APP_ENDPOINT}/employer` },

  {
    title: "Colleges",
    path: `${NEXT_APP_ENDPOINT}/colleges-campus-placements`,
  },
  { title: "Blogs", path: `${NEXT_APP_ENDPOINT}/blogs` },
  { title: "Student Registration", 
  // path: `/signup/student` 
  path: "/candidate/signup"
},
  { title: "Employer Registration", path: `/signup/employer` },
  // { title: "Log In", path: `/login` },

  { title: "About us", path: `${NEXT_APP_ENDPOINT}/about-us` },
  { title: "Contact us", path: `${NEXT_APP_ENDPOINT}/contact-us` },
];

const navLinksLoggedIn = [
  {
    title: "Dashboard",
    path: checkLinkingHomePage(localStorage.getItem("user_type")),
  },

  { title: "Students", path: `${NEXT_APP_ENDPOINT}/students` },
  { title: "Jobs", path: `${NEXT_APP_ENDPOINT}/full-time-jobs` },
  { title: "WFH Jobs", path: `${NEXT_APP_ENDPOINT}/work-from-home-jobs` },
  { title: "Internships", path: `${NEXT_APP_ENDPOINT}/internships` },

  {
    title: "WFH Internships",
    path: `${NEXT_APP_ENDPOINT}/work-from-home-internships`,
  },
  { title: "Employers", path: `${NEXT_APP_ENDPOINT}/employer` },

  {
    title: "Colleges",
    path: `${NEXT_APP_ENDPOINT}/colleges-campus-placements`,
  },
  { title: "Blogs", path: `${NEXT_APP_ENDPOINT}/blogs` },

  { title: "About us", path: `${NEXT_APP_ENDPOINT}/about-us` },
  { title: "Contact us", path: `${NEXT_APP_ENDPOINT}/contact-us` },
];

function Navbar({ isShown, toggled }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState("");
  const token = localStorage.getItem("gw_token");
  const location = useLocation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentUserType = localStorage.getItem("user_type");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (token) {
      axios
        .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
          headers: {
            Authorization: "Token " + token,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (
            res.status === 200 &&
            res.data.success &&
            res.data.profile_complete
          ) {
            if (res.data.user_type.length > 0) {
              setUserType(res.data.user_type[0].main_user);
              setIsLoggedIn(true);
            }
            if (res.data.company_details !== null)
              localStorage.setItem(
                "company",
                JSON.stringify(res.data.company_details)
              );
            localStorage.setItem(
              "user",
              JSON.stringify({
                email: res.data.email,
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                id: res.data.id,
                profile_picture: res.data?.profile_picture,
              })
            );
            let user_details = localStorage.getItem("user_details")
              ? localStorage.getItem("user_details")
              : "{}";
            user_details = JSON.parse(user_details);
            user_details["email"] = res.data.email;
            user_details["first_name"] = res.data.first_name;
            user_details["last_name"] = res.data.last_name;
            user_details["id"] = res.data.id;
            user_details["profile_picture"] = res.data.profile_picture;
            localStorage.setItem("user_details", JSON.stringify(user_details));
          }
        })
        .catch((err) => {
          localStorage.clear();
          console.log(err);
        });
    }
  }, []);

  return (
    <AppBar
      position={!toggled ? "fixed" : "relative"}
      elevation={0}
      className={classes.appbar}
    >
      <Toolbar className={classes.navbar}>
        <Container
          maxWidth="lg"
          component="main"
          className={classes.header}
          disableGutters
        >
          <Hidden smDown>
            <a
              style={{ display: "flex" }}
              href={
                currentUserType === "Student"
                  ? `/student/dashboard`
                  : currentUserType === "Company"
                  ? `/company/dashboard`
                  : currentUserType === "College"
                  ? `/college/dashboard`
                  : NEXT_APP_ENDPOINT
              }
            >
              <Button
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                style={{ flexGox: 1 }}
              >
                <img src={Logo} alt="Getwork" width="62px" height="53px" />
              </Button>
            </a>

            {!isShown ? (
              <div className={classes.navtab}>
                <a href={`${NEXT_APP_ENDPOINT}/students`}>
                  <Button color="primary" className={classes.tab}>
                    <Typography variant="body2" className={classes.btn}>
                      Students
                    </Typography>
                    {/* <ExpandMoreIcon /> */}
                  </Button>
                </a>
                {/* <Link href="/public/jobs">
                  <Button color="primary" className={router.pathname === "/public/jobs" ? classes.tab_active : classes.tab}>
                    <Typography variant="body2" className={router.pathname === "/public/jobs" ? classes.btn_active : classes.btn}>
                      Career Advice
                    </Typography>
                  </Button>
                </a> */}
                {/* <Button color="primary" className={classes.tab}>
                    <Typography variant="body2" className={classes.btn}>
                      Opurtunities
                    </Typography>
                  </Button> */}{" "}
                <DropDown
                  handleClick={handleClick}
                  handleClose={handleClose}
                  anchorEl={anchorEl}
                />
                <a href={`${NEXT_APP_ENDPOINT}/employer`}>
                  <Button color="primary" className={classes.tab}>
                    <Typography variant="body2" className={classes.btn}>
                      Employers
                    </Typography>
                  </Button>
                </a>
                <a href={`${NEXT_APP_ENDPOINT}/colleges-campus-placements`}>
                  <Button color="primary" className={classes.tab}>
                    <Typography variant="body2" className={classes.btn}>
                      Colleges
                    </Typography>
                  </Button>
                </a>
                <a href={`${NEXT_APP_ENDPOINT}/super-app`}>
                  <Button color="primary" className={classes.tab}>
                    <Typography
                      variant="body2"
                      className="newlaunch"
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      SuperApp
                    </Typography>
                  </Button>
                </a>
                <a href={`${NEXT_APP_ENDPOINT}/one-app`}>
                  <Button color="primary" className={classes.tab}>
                    <Typography
                      variant="body2"
                      className="newlaunch"
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      OneApp
                    </Typography>
                  </Button>
                </a>
                <a href={`${NEXT_APP_ENDPOINT}/blogs`}>
                  <Button id="navBtn" color="primary" className={classes.tab}>
                    <Typography variant="body2" className={classes.btn}>
                      Blogs
                    </Typography>
                  </Button>
                </a>
              </div>
            ) : null}
            {localStorage.getItem("user_type") ? (
              <AvatarMenu />
            ) : (
              <div className={classes.sectionDesktop}>
                {/* <IconButton>
                  <SearchIcon color="inherit" />
                </IconButton> */}
                <MenuItem
                  style={{ color: "inherit", backgroundColor: "inherit" }}
                >
                  <RegisterBtn />
                </MenuItem>
                <MenuItem
                  style={{
                    paddingRight: "3px",
                    color: "inherit",
                    backgroundColor: "inherit",
                  }}
                >
                  <LogInBtn />
                </MenuItem>

                {/* </div> */}
              </div>
            )}
          </Hidden>
          <Hidden mdUp>
            
            <SideDrawer
              navLinks={
                localStorage.getItem("gw_token") ? navLinksLoggedIn : navLinks
              }
            />
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;

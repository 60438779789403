import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  Menu,
  MenuItem,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SubList from "./SubList";
import DropDownList from "./DropDownList";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { NEXT_APP_ENDPOINT } from "../../../constants/constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  btn: {
    "&:hover": {
      color: "#007bff",
    },
    padding: "10px",
  },
  tab: {
    borderRadius: "30px",
  },
  paper: {
    padding: 10,
    //textAlign: "center",

    boxShadow: "none",

    width: "260px",
  },
  drop: {
    marginLeft: "16px",
    color: "#007bff",
  },
});

const StyledMenu = withStyles({
  paper: {
    border: ".5px solid #d3d4d5",
    borderRadius: "12px",
  },
})((props) => (
  <Menu
    style={{ top: "7px" }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  //   root: {
  //     "&:focus": {
  //       //   backgroundColor: theme.palette.primary.main,
  //       "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
  //         color: theme.palette.common.white,
  //       },
  //     },
  //   },
}))(MenuItem);

export default function DropDown({ handleClick, handleClose, anchorEl }) {
  const classes = useStyles();

  const data = [
    {
      title: "Internships",
      sub_title: [
        {
          heading: "Location",
          data: [
            {
              heading: "Internships in Delhi / NCR",
              link: `${NEXT_APP_ENDPOINT}/internships-in-delhi`,
            },
            {
              heading: "Internships in Bangalore",
              link: `${NEXT_APP_ENDPOINT}/internships-in-bangalore`,
            },
            {
              heading: "Internships in Mumbai",
              link: `${NEXT_APP_ENDPOINT}/internships-in-mumbai`,
            },
            {
              heading: "Internships in Pune",
              link: `${NEXT_APP_ENDPOINT}/internships-in-pune`,
            },
            {
              heading: "Internships in Hyderabad",
              link: `${NEXT_APP_ENDPOINT}/internships-in-hyderabad`,
            },
            {
              heading: "Internships in Jaipur",
              link: `${NEXT_APP_ENDPOINT}/internships-in-jaipur`,
            },
            {
              heading: "Internships in Bhopal",
              link: `${NEXT_APP_ENDPOINT}/internships-in-bhopal`,
            },
            {
              heading: "Internships in Ahmedabad",
              link: `${NEXT_APP_ENDPOINT}/internships-in-ahmedabad`,
            },
            // {
            //   heading: "Internships in Indore",
            //   link: `${NEXT_APP_ENDPOINT}/internships-in-indore`,
            // },
            {
              heading: "Internships in Chandigarh",
              link: `${NEXT_APP_ENDPOINT}/internships-in-chandigarh`,
            },
            {
              heading: "Internships in Surat",
              link: `${NEXT_APP_ENDPOINT}/internships-in-surat`,
            },
            {
              heading: "Internships in Nagpur",
              link: `${NEXT_APP_ENDPOINT}/internships-in-nagpur`,
            },
            {
              heading: "Internships in Lucknow",
              link: `${NEXT_APP_ENDPOINT}/internships-in-lucknow`,
            },
            // {
            //   heading: "Internships in Mohali",
            //   link: `${NEXT_APP_ENDPOINT}/internships-in-mohali`,
            // },
            // {
            //   heading: "Internships in Sonipat",
            //   link: `${NEXT_APP_ENDPOINT}/internships-in-sonipat`,
            // },
            // {
            //   heading: "Internships in Rudrapur",
            //   link: `${NEXT_APP_ENDPOINT}/internships-in-rudrapur`,
            // },
          ],
        },
        // {
        //   heading: "Categories",
        //   data: [
        //     {
        //       heading: "Communications Internship",
        //       link: `${NEXT_APP_ENDPOINT}/internships-in-communications-field`,
        //     },
        //   ],
        // },
        {
          heading: "",
          data: [
            {
              heading: "View all internships",
              link: `${NEXT_APP_ENDPOINT}/internships`,
              color: true,
            },
          ],
        },
      ],
    },
    {
      title: "Full Time Jobs",
      sub_title: [
        {
          heading: "Location",
          data: [
            {
              heading: "Jobs in Delhi / NCR",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-delhi`,
            },
            {
              heading: "Jobs in Bangalore",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-bangalore`,
            },
            {
              heading: "Jobs in Mumbai",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-mumbai`,
            },
            {
              heading: "Jobs in Pune",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-pune`,
            },
            {
              heading: "Jobs in Hyderabad",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-hyderabad`,
            },
            {
              heading: "Jobs in Jaipur",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-jaipur`,
            },
            {
              heading: "Jobs in Bhopal",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-bhopal`,
            },
            {
              heading: "Jobs in Ahmedabad",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-ahmedabad`,
            },
            // {
            //   heading: "Jobs in Indore",
            //   link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-indore`,
            // },
            {
              heading: "Jobs in Chandigarh",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-chandigarh`,
            },
            {
              heading: "Jobs in Surat",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-surat`,
            },
            {
              heading: "Jobs in Nagpur",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-nagpur`,
            },
            {
              heading: "Jobs in Lucknow",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-lucknow`,
            },
            // {
            //   heading: "Jobs in Mohali",
            //   link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-mohali`,
            // },
            // {
            //   heading: "Jobs in Sonipat",
            //   link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-sonipat`,
            // },
            // {
            //   heading: "Jobs in Rudrapur",
            //   link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-rudrapur`,
            // },
          ],
        },
        // {
        //   heading: "Categories",
        //   data: [
        //     {
        //       heading: "Communications Full time Job",
        //       link: `${NEXT_APP_ENDPOINT}/full-time-jobs-in-communications-field`,
        //     },
        //   ],
        // },
        {
          heading: "",
          data: [
            {
              heading: "View all Full time Jobs",
              link: `${NEXT_APP_ENDPOINT}/full-time-jobs`,
              color: true,
            },
          ],
        },
      ],
    },
    {
      title: "Category",
      sub_title: [
        {
          heading: "",
          data: [
            {
              heading: "Work From Home",
              link: `${NEXT_APP_ENDPOINT}/work-from-home-jobs`,
            },

            {
              heading: "Digital Marketing",
              link: `${NEXT_APP_ENDPOINT}/marketing-jobs`,
            },
            {
              heading: "Human Resources",
              link: `${NEXT_APP_ENDPOINT}/human-resource-jobs`,
            },
            {
              heading: "Operations",
              link: `${NEXT_APP_ENDPOINT}/operation-jobs`,
            },
            {
              heading: "Engineering",
              link: `${NEXT_APP_ENDPOINT}/engineering-jobs`,
            },
            {
              heading: "Sales & Business Development",
              link: `${NEXT_APP_ENDPOINT}/sales-jobs`,
            },

            {
              heading: "Software Development",
              link: `${NEXT_APP_ENDPOINT}/engineering-jobs`,
            },
            { heading: "Design", link: `${NEXT_APP_ENDPOINT}/design-jobs` },
            {
              heading: "Content Writer",
              link: `${NEXT_APP_ENDPOINT}/content-writer-jobs`,
            },
            {
              heading: "Customer Services",
              link: `${NEXT_APP_ENDPOINT}/customer-services-jobs`,
            },
            { heading: "Tourism", link: `${NEXT_APP_ENDPOINT}/tourism-jobs` },
            { heading: "Finance", link: `${NEXT_APP_ENDPOINT}/finance-jobs` },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Button
        color="primary"
        className={classes.tab}
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Typography
          variant="body2"
          className={classes.btn}
          style={{
            cursor: "pointer",
            fontFamily: "Nunito",
            fontWeight: "400",
            fontSize: "14px",
          }}
        >
          Opportunities
        </Typography>

        <ExpandMoreIcon
          style={
            anchorEl
              ? {
                  transform: "rotate(180deg)",
                  transition: "transform 0.1s ease 0s",
                  color: "black",
                  marginLeft: "-5px",
                }
              : { color: "black", marginLeft: "-5px" }
          }
        />
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {" "}
        <Grid container spacing={3}>
          {data.map((item) => (
            <Grid item xs={4} sm={4}>
              <Paper className={classes.paper}>
                {" "}
                <Typography className={classes.drop} variant="h6">
                  {item.title}
                </Typography>
                {/* <SubList type={"internships"} /> */}
                {item.sub_title.map((item2, index) => (
                  <>
                    <DropDownList
                      array={item2.data}
                      heading={item2.heading}
                      handleClose={handleClose}
                    />
                    {/* {(index === 0 || index === 1) && item.title !== "Category" && <Divider />} */}
                  </>
                ))}
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Divider style={{ backgroundColor: "#8484841f" }} />
        <a href={`${NEXT_APP_ENDPOINT}/public/jobs`}>
          <Box
            display="flex"
            justifyContent="center"
            style={{ cursor: "pointer" }}
          >
            <span style={{ color: "#007bff", fontSize: 14, marginTop: 10 }}>
              View All Jobs&nbsp;
              {/* <ArrowRightAltIcon /> */}
            </span>
            <ArrowRightAltIcon style={{ color: "#007bff", marginTop: 5 }} />
          </Box>
        </a>
      </StyledMenu>
    </>
  );
}

import React, { Component } from "react";
import ClipBoard from "./ClipBoard";
import { RandomPassword } from "../../../utils/common/RandomPassword";

const root = document.documentElement;
const theme = {
  dark: {
    background: "#222225",
    font: "#ffffff"
  },
  light: {
    background: "#ffffff",
    font: "#222225"
  }
};
class GenerateRandomPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 8,
      newLength: 8,
      pwd: "",
      upperCase: true,
      lowerCase: true,
      numeric: true,
      symbol: false,
      theme: "light",
      typing: false,
      typingTimeout: 0
    };
  }

  componentDidMount() {
    this.generatePwd();
  }
  generatePwd() {
    const { upperCase, lowerCase, numeric, symbol, length } = this.state;
    let pwd = new RandomPassword()
      .setLength(length)
      .setLowerCase(lowerCase)
      .setUpperCase(upperCase)
      .setNumberCase(numeric)
      .setSymbol(symbol)
      .generate();
    this.setState({ pwd });
  }

  handleCheckbox(e) {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
    this.generatePwd();
  }

  handleLenghtChange({ target: { value } }) {
    if (value >= 40) {
      value = 40;
    }
    this.setState({ length: value }, () => this.generatePwd());
  }

  changeTheme(e) {
    this.setState({
      theme: e.target.checked ? "dark" : "light"
    });
    root.style.setProperty("--background-color", theme[this.state.theme].font);
    root.style.setProperty("--font-color", theme[this.state.theme].background);
  }
  render() {
    return (
      <div>
        <div className="container px-5">

            
              <div className="row my-2">
                  <h6 className="pl-3">
                    Or let GetWork generate a strong password for you!
                  </h6>
              
              </div>
            
            <div className="row no-gutters">
                <div className="col">
                    <input
                    id="input"
                    className="form-control shadow_1-lightest"
                    name="password"
                    type="text"
                    readOnly
                    value={this.state.pwd}
                />
                </div>
                <div className="col-auto ml-2">
                    
                <div className="clipboard">
                    <ClipBoard password={this.state.pwd}/>
                </div>
                </div>

            </div>

       
         
              <span className="fw-500 my-2">Customize your password</span>
           
                <div className="row my-2">
                    <div className="col-6">
                        <div className="form-group">
                        <div className="row my-1">
                        <label className="checkbox-container fs-14">
                        <input
                            type="checkbox"
                            className="ml-3 mr-2"
                            checked={this.state.upperCase}
                            name="upperCase"
                            onChange={e => this.handleCheckbox(e)}
                        />
                        Uppercase
                        <span className="checkmark" />
                        </label>
                        </div>
                        <div className="row my-1">
                        <label className="checkbox-container fs-14">
                        
                        <input
                            type="checkbox"
                            className="ml-3 mr-2"
                            checked={this.state.lowerCase}
                            name="lowerCase"
                            onChange={e => this.handleCheckbox(e)}
                        />
                        Lowercase
                        <span className="checkmark" />
                        </label>
                        </div>

                        <div className="row my-1">
                        <label className="checkbox-container fs-14">
                        <input
                            type="checkbox"
                            className="ml-3 mr-2"
                            checked={this.state.numeric}
                            name="numeric"
                            onChange={e => this.handleCheckbox(e)}
                        />
                        Numeric
                        <span className="checkmark" />
                        </label>
                        </div>

                        <div className="row">
                        <label className="checkbox-container fs-14">
                        <input
                            type="checkbox"
                            className="ml-3 mr-2"
                            checked={this.state.symbol}
                            name="symbol"
                            onChange={e => this.handleCheckbox(e)}
                        />
                        Symbols
                        <span className="checkmark" />
                        </label>
                        </div>

                    </div>
                    </div>
                    <div className="col-6">
                    <div className="form-group row">
                        <div className="col">
                            <div className="row my-1">
                                <label htmlFor="email" className="fs-14">Password Length:</label>
                                
                                <input
                                    className="ml-2"
                                    type="number"
                                    min="8"
                                    max="40"
                                    value={this.state.length}
                                    onChange={e => this.handleLenghtChange(e)}
                                />
                            </div>
                            <div className="row my-1">
                                <div className="slider-container">
                                <input
                                className="slider"
                                type="range"
                                min="8"
                                max="40"
                                value={this.state.length}
                                onChange={e => this.handleLenghtChange(e)}
                                />
                            </div>
                            </div>
                            <div className="row justify-content-center my-2">
                                <button
                                    className="btn shadow_1 btn btn-lg btn-main btn-login text-uppercase font-weight-bold mb-2"
                                    onClick={() => {
                                    this.generatePwd();
                                    }}
                                >
                                    Generate
                                </button>
                            
                            </div>
                        </div>
                            

                        

                        </div>
                    </div>
                </div>

         

           
        

        </div>
      </div>
    );
  }
}

export default GenerateRandomPassword;

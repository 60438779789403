import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import loaderGIF from "../../assets/images/loopingcircles-3.gif";
import { BackendBaseApi } from "../../constants/constants";
import fetchApi from "../apiCall/fetchApi";
import contactUs from "../assets/png/contactUs.png";
//import loaderGIF from "../../../assets/images/loopingcircles-3.gif";
//import { ReactComponent as Error } from "../../assets/svg/Error.svg";
import { ReactComponent as Error } from "../assets/svg/Error.svg";
import CommonTable from "../components/Common/Table/CommonTable";
import PurchasePlanDialog from "../components/Dialog/PurchasePlanDialog";
import TempBillingContact from "../components/TempMySubscriptionComponents/TempBillingContact";
import TempCurrentPlan from "../components/TempMySubscriptionComponents/TempCurrentPlan";
import TempPlan from "../components/TempMySubscriptionComponents/TempPlan";
import purchasePlan from "../assets/png/purchasePlan.png";
const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    border: "50px",
    hover: {
      color: theme.palette.primary.main,
    },
    root1: {
      borderRadius: "50px",
    },
  },
}));

const TempMySubscription = () => {
  const classes = useStyles();

  const [pageData, setPageData] = useState({ currentPage: 0, pageSize: 10 });
  const [selectedRow, setSelectedRow] = useState({
    details: [],
    selectedIds: [],
  });
  const [tableData, setTableData] = useState(null);
  const [tableDataError, setTableDataError] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [planDetailsData, setPlanDetailsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(false);

  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [paginationResponse, setPaginationResponse] = useState(null);
  const [firstApiCallMade, setFirstApiCallMade] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const profileUnlockInfo = useSelector(
    (state) => state?.CreditInfo?.profileUnlockInfo
  );

  const getPlanDetails = () => {
    const url = `${BackendBaseApi.NIYUKTI}job/company/plan_detail`;
    fetchApi({
      url,
      setResponse: setPlanDetailsData,
      setError: setError,
      setLoading: setLoadingData,
    });
  };

  const getTableData = () => {
    const url = `${BackendBaseApi.NIYUKTI}job/company/unlock_history`;
    fetchApi({
      url,
      setResponse: setTableData,
      setLoading: setTableLoading,
      setError: setTableDataError,
      setPagination: setPaginationResponse,
      setOneApiCallMade: setFirstApiCallMade,
    });
  };

  useEffect(() => {
    getTableData();
    getPlanDetails();
  }, [refresh]);

  useEffect(() => {
    if (firstApiCallMade) {
      setTableData(null);
      const url = `${BackendBaseApi.NIYUKTI}job/company/unlock_history?page=${
        pageData.currentPage + 1
      }`;
      fetchApi({
        url: url,
        setResponse: setTableData,
        setLoading: setTableLoading,
        setError: setTableDataError,
        setPagination: setPaginationResponse,
      });
    }
  }, [pageData]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {profileUnlockInfo && !planDetailsData?.data ? (
        <div
          style={{
            display: "flex",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loaderGIF}
            alt="GetWork Loading Gif..."
            style={{ marginTop: "10%" }}
          />
        </div>
      ) : tableDataError || error ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            heigh: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Error height="400px" alt="404 Error" fill={"#007BFF"} />
            <Button
              variant="text"
              variant="contained"
              color="primary"
              size="large"
              style={{ width: "160px", height: "40px" }}
              onClick={() => {
                //setLoading(true);
                setRefresh(!refresh);
              }}
            >
              {loadingData ? <CircularProgress size={16} /> : "Try Agian"}
            </Button>
          </div>
        </div>
      ) : profileUnlockInfo?.code !== 3 && planDetailsData?.data ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h2">My Subscriptions</Typography>
            <Button
              variant="text"
              /*  className={classes.btn} */
              variant="contained"
              color="primary"
              style={{
                borderRadius: "30px",
                boxShadow: "none",
                height: "40px",
                display: "flex",
                fontWeight: "500",
              }}
              onClick={() => setOpen(true)}
            >
              Add More Unlocks
            </Button>
            <Dialog
              PaperProps={{ style: { borderRadius: "40px" } }}
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              style={{ borderRadius: "50px" }}
            >
              <div
                style={{
                  padding: "40px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "0 auto",
                  width: "500px",
                  textAlign: "center",
                }}
              >
                <img src={contactUs} style={{ height: "220px" }} />
                <Typography variant="h3" style={{ marginBottom: "10px" }}>
                  Contact GetWork Admin
                </Typography>
                <Typography variant="body1">
                  Please contact your key account manager or GetWork admins to
                  add more profile unlocks.
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "20px",
                      color: "#007BFF",
                      fontSize: "1rem",
                    }}
                  >
                    <span
                      style={{
                        marginRight: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <MailOutlineOutlinedIcon fontSize={"inherit"} />
                    </span>
                    <a
                      href="mailto:support@getwork.org"
                      style={{
                        textDecoration: "none",
                        color: "#007BFF",
                        fontSize: "14px",
                      }}
                    >
                      support@getwork.org
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "20px",
                      color: "#007BFF",
                      fontSize: "0.9rem",
                    }}
                  >
                    <span
                      style={{
                        marginRight: "3px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PhoneOutlinedIcon fontSize={"inherit"} />
                    </span>
                    <a
                      href="tel:+91-7906777763"
                      style={{
                        textDecoration: "none",
                        color: "#007BFF",
                        fontSize: "14px",
                      }}
                    >
                      +91 7906777763
                    </a>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
          <Grid container spacing={2}>
            {(profileUnlockInfo?.code === 1 || profileUnlockInfo?.code === 2) &&
              (profileUnlockInfo?.availableCredits < 10 ||
                profileUnlockInfo?.days_remaining < 10) && <TempPlan />}
            <Grid item md={6}>
              <TempCurrentPlan
                profileUnlocks={planDetailsData?.data?.profile_unlocks}
                creditStatus={planDetailsData?.data?.credit_status}
                nextBillingDate={planDetailsData?.data?.next_billing_date}
                jobPosting={planDetailsData?.data?.job_posting}
                keyAccountManager={planDetailsData?.data?.key_account_manager}
              />
            </Grid>
            {planDetailsData?.data?.key_account_manager &&
              (planDetailsData?.data?.key_account_manager_details?.email ||
                planDetailsData?.data?.key_account_manager_details?.name.trim() ||
                planDetailsData?.data?.key_account_manager_details?.phone) && (
                <Grid item md={6}>
                  <TempBillingContact
                    heading="Key Account Manager"
                    dataDetails={
                      planDetailsData?.data?.key_account_manager_details
                    }
                  />
                </Grid>
              )}

            <Grid item md={12}>
              <Typography variant="h2" style={{ marginBottom: "20px" }}>
                Profile Unlock History
              </Typography>
              <CommonTable
                columns={columns}
                rowData={
                  tableData?.data?.results ? [...tableData?.data?.results] : []
                }
                loading={tableLoading}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                pageData={pageData}
                setPageData={setPageData}
                checkboxSelection={false}
                //applicantDataError={tableDataError}
                totalDataCount={paginationResponse?.totalDataCount}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <div
          style={{
            padding: "40px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto",
            width: "450px",
            textAlign: "center",
          }}
        >
          <img
            src={purchasePlan}
            height="300px"
            style={{ marginBottom: "20px" }}
          />
          <Typography variant="h5" style={{ marginBottom: "10px" }}>
            Purchase A Plan Now !
          </Typography>
          <Typography variant="body2" style={{ color: "#6C757D" }}>
            Please Contact to Team GetWork to buy a plan and get more details
            about ideal talent hiring solutions.
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "20px",
                color: "#007BFF",
                fontSize: "1rem",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MailOutlineOutlinedIcon fontSize={"inherit"} />
              </span>
              <a
                href="mailto:support@getwork.org"
                style={{
                  textDecoration: "none",
                  color: "#007BFF",
                  fontSize: "14px",
                }}
              >
                support@getwork.org
              </a>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "20px",
                color: "#007BFF",
                fontSize: "0.9rem",
              }}
            >
              <span
                style={{
                  marginRight: "3px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PhoneOutlinedIcon fontSize={"inherit"} />
              </span>
              <a
                href="tel:+91-7906777763"
                style={{
                  textDecoration: "none",
                  color: "#007BFF",
                  fontSize: "14px",
                }}
              >
                +91 7906777763
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TempMySubscription;

const columns = [
  {
    field: "id",
    width: 250,
    displayName: "Transaction ID",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "65px" }}>
        Transaction ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.transaction_id}
      </span>
    ),
  },
  {
    field: "type",
    width: 200,
    displayName: "Transaction type",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Transaction Type
      </strong>
    ),
    renderCell: (params) => (
      <>
        {params.row.transaction_type === "DEBIT" ? (
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              color: "#C4161C",
              fontSize: "12px",
            }}
          >
            {params?.row?.transaction_type === "DEBIT" ? "DEBIT" : "CREDIT"}
          </span>
        ) : (
          <span
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              color: "#12CB02",
              fontSize: "12px",
            }}
          >
            {params?.row?.transaction_type === "DEBIT" ? "DEBIT" : "CREDIT"}
          </span>
        )}
      </>
    ),
  },
  {
    field: "unlocks",
    width: 180,
    displayName: "unlocks",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
        No. of Unlocks
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.no_of_unlock}
      </span>
    ),
  },
  {
    field: "remaining_unlocks",
    width: 180,
    displayName: "remaining_unlocks",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "12px" }}>
        Remaining Unlocks
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.remaining_unlocks}
      </span>
    ),
  },
  {
    field: "transaction_by",
    // type: "text",
    sortable: false,
    displayName: "transaction_by",
    hide: false,
    width: 180,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
        Transaction by
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.name}
        </span>
      </>
    ),
  },
  {
    field: "date",
    width: 200,
    hide: false,
    displayName: "date",
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "60px" }}>Date</strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {moment(params?.row?.date).format("MMMM Do, YYYY")}
        </span>
      </>
    ),
  },

  /*  {
    field: "action",
    width: 150,
    displayName: "Action",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Action</strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        <Button
          color="primary"
          variant="text"
          //onClick={() => handleClickOpen(params?.row?.id, params?.row?.name)}
        >
          Download
        </Button>
      </div>
    ),
  }, */
];

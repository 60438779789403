import React from "react";
import Layout from "../components/Layout/Layout";
import Navbar from "../../components/public/Navbar/Navbar";
// import EmployeeDetails from "./EmployeeDetails";
import EmployeeDetailsNew from "./EmployeeDetailsNew";
import StudentDetailTrackShare from "./StudentDetailTrackShare";
import MuiCustomTheme from "../components/Theme/theme";

function ApplicantDetails(props) {
  return (
    <>
      <div>
          <div style={{ margin: 0 }}>
            { props?.isPublic ? 
            <>
            <Navbar />
              <MuiCustomTheme>
                <div style={{ margin: 20, marginTop: "80px" }}>
                  <StudentDetailTrackShare isPublic={true}/>
                </div>
              </MuiCustomTheme>
            </>
             :
            <EmployeeDetailsNew isPublic={false}/> 
            }
          </div>
      </div>
    </>
  );
}

export default ApplicantDetails;

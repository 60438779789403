import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Input } from "reactstrap";
import { CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PictureChangeDialog(props) {
  const {
    open,
    handleClickOpen,
    handleClose,
    image,
    banner,
    updatingBanner,
    fakebanner,
    imagehandle,
    imagesubmit,
    logo,
  } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update {logo ? " Logo" : " Cover Picture"}
        </DialogTitle>
        <DialogContent dividers>
          <h6 align="center" style={{ paddingBottom: "10px" }}>
            Click on the below image to select your Image
          </h6>{" "}
          {/* <Avatarimage/> */}
          <label
            htmlFor="upload-button"
            style={{ cursor: "pointer", width: "100%" }}
          >
            {image ? (
              <img
                src={image}
                alt="dummy"
                width="100%"
                height="auto"
                style={
                  logo
                    ? { borderRadius: "50%", width: 380 }
                    : { borderRadius: 30, width: "100%" }
                }
              />
            ) : (
              <>
                <img
                  src={banner ? banner : fakebanner}
                  alt="banner"
                  height="auto"
                  style={
                    logo
                      ? { borderRadius: "50%", width: 380 }
                      : { borderRadius: 30, width: "100%" }
                  }
                />
              </>
            )}
          </label>
          <br />
          {logo ? (
            <Input
              onChange={(e) => imagehandle(e)}
              type="file"
              name="logo"
              id="upload-button"
              style={{ display: "none" }}
            />
          ) : (
            <Input
              onChange={(e) => imagehandle(e)}
              type="file"
              name="banner"
              id="upload-button"
              style={{ display: "none" }}
            />
          )}
          <br />
        </DialogContent>
        <DialogActions>
          {image !== banner ? (
            <Button
              variant="contained"
              disabled={updatingBanner}
              style={{ width: "189px", height: "36px", borderRadius: 30 }}
              onClick={(e) => {
                imagesubmit(e);
              }}
              type="submit"
            >
              {updatingBanner ? (
                <CircularProgress size={25} style={{ color: "#fff" }} />
              ) : logo ? (
                "Update Logo"
              ) : (
                "Update Cover Picture"
              )}
            </Button>
          ) : null}

          <Button
            variant="contained"
            onClick={handleClose}
            style={{ borderRadius: 30 }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

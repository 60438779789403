import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import bg from "../../../assets/images/iim.jpg";
import phone from "../../../assets/images/phone.png";
import list from "../../../assets/images/list.jpg";
import Gallery from "react-grid-gallery";

import job_segment from "../../../assets/images/company-7.jpg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import React, { Fragment, useEffect, useState } from "react";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "20px 40px 40px 40px",
      padding: "40px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
    },
    WhiteBox2: {
      backgroundColor: "#fff",
      margin: "0 40px 0 0",
      padding: "40px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    WhiteBox3: {
      backgroundColor: "#fff",
      margin: "40px 40px 40px 0",
      padding: "40px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    imageAndText: {
      float: "left",
      padding: "0 20px 15px 0",
    },
    SplitBox: {
      display: "flex",
      flexDirection: "column",
    },
    para: {
      color: "#4c4949",
      fontSize: "18px",
      textAlign: "center",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
    },
    borderBox: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      // border: "1px solid grey",
      padding: "10px",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        transform: "scale(1.02)",
        transition: "transform 0.2s",
      },
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
    },
    "@media only screen and (max-width: 768px)": {
      WhiteBox: {
        width: "100%",
        marginLeft: "-5px",
        marginRight: "0px",
        backgroundColor: "inherit",
        marginTop: "20px",
        marginBottom: "90px",
        padding: "0px",
        boxShadow: "none",
        borderRadius: "4px",
      },
    },
  })
);

export default function OverviewBox(props) {
  const { data } = props;
  const classes = useStyles();
  const [galleryData, setGalleryData] = useState([]);
  const [newGalleryData, setNewGalleryData] = useState([]);

  useEffect(() => {
    if (data) {
      setGalleryData(data?.gallery);
      var gallery = [];

      data &&
        data.gallery.map((item) => {
          var g_data = {
            src: validateUrl(item)
              ? item
              : "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
            thumbnail: validateUrl(item)
              ? item
              : "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 212,
          };
          gallery.push(...newGalleryData, g_data);
          setNewGalleryData(gallery);
        });
    }
  }, [data]);

  const validateUrl = (link) => {
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0)
      return true;
    else return false;
  };
  const IMAGES = [
    {
      src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
      thumbnail:
        "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      //   isSelected: true,
      //   caption: "After Rain (Jeshu John - designerspics.com)",
    },
    {
      src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
      thumbnail:
        "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 212,
      //   tags: [
      //     { value: "Ocean", title: "Ocean" },
      //     { value: "People", title: "People" },
      //   ],
      //   caption: "Boats (Jeshu John - designerspics.com)",
    },

    {
      src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
      thumbnail:
        "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 212,
    },
  ];

  return (
    <Box className={classes.WhiteBox}>
      <h2 className={classes.heading}>Gallery </h2>
      <br />

      <Gallery images={newGalleryData} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Box>
  );
}

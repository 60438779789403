import { Grid } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { BackendBaseApi } from "../../../constants/constants";
import youtube from "../../../assets/images/youtube.png";
import { httpRequest } from "../../../utils/common/httpRequest";
import BlogCard from "../Blog/BlogCard";
import Layout from "../Layout/Layout";
import configs from "../../../config";

export default function SingleBlog({ location }) {
  const { data } = location;
  const [blogData, setBlogData] = useState([]);
  const [singleBlogData, setSingleBlogData] = useState(null);

  const GetDataBlog = async (updateState) => {
    fetch(`${BackendBaseApi.CMS}blogs/and?category.id=${1}&_sort=published_at:DESC`)
      .then(response => response.json())
      .then(result => {
        if (result.length>1) {
          var arr=[];
          result &&
          result.map(item =>arr.push(item))
          updateState(arr);
        } else {
          updateState(result);
        }
      })
      .catch(error => console.log('error', error));
  };
  const GetSingleDataBlog = async (updateState) => {
    fetch(`${BackendBaseApi.CMS}blogs/${location.pathname.split("/")[3]}`)
      .then(response => response.json())
      .then(result => {
        if (result.length>1) {
          var arr=[];
          result &&
          result.map(item => arr.push(item))
          updateState(arr);
        } else {
          updateState(result);
        }
      })
      .catch(error => console.log('error', error));
  };

  useEffect(() => {
    GetSingleDataBlog(setSingleBlogData);
    GetDataBlog(setBlogData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const GetData = async (baseUrl, endPoint, body, updateState) => {
  //   let res = await httpRequest(baseUrl, endPoint, body);
  //   updateState(res.data.results);
  // };

  var imageLink = configs.apis.cms.slice(0, configs.apis.cms.length - 1);

  return (
    <Layout>
      <div className="single-blog" style={{fontFamily: " Nunito", fontWeight: 400}}>
        <Grid container justify="flex-start">
          {/* <Grid xs={12} className="text-left" style={{ marginTop: "54px" }}>
            <span className="fs-12">content</span>
            <span className="fs-12 fw-700 "> {data.title}</span>
          </Grid> */}
        </Grid>
        <Grid container>
          <Grid xs={12} className="text-left">
            <h2 className="fw-700 text-left">{singleBlogData?.Title}</h2>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "-30px" }}>
          <Grid xs={12}>
            <div className="flex align-item-center fs-12 mg-top-2 ">
              <p style={{fontSize: 14,fontWeight:500}}>
                <b>
                  By{" "}
                  {" "}
                  {singleBlogData?.author.FirstName}{" "}
                  {singleBlogData?.author.LastName}
                </b>
                <br />
                {singleBlogData?.updated_at &&
                  (new Date(singleBlogData.created_at).toDateString() !== new Date(singleBlogData.updated_at).toDateString()) ?
                  <>
                    <small>Last updated: {singleBlogData?.created_at && new Date(singleBlogData.updated_at).toDateString()}</small>
                    <br />
                  </>
                  : null
                }
                <small>Posted at: {singleBlogData?.created_at && new Date(singleBlogData.created_at).toDateString()}</small>
              </p>
            </div>

            {/* {singleBlogData?.youtube && (
              <p style={{ fontSize: "14px" }}>
                <a href={singleBlogData?.youtube} target="blank">
                  <img src={youtube} alt="" height="18px" />
                  &nbsp; Youtube{" "}
                </a>
              </p>
            )} */}
          </Grid>
        </Grid>
        <Grid container className="mg-top-2" style={{ marginTop: "-30px" }}>
          <Grid xs={12}>
            {singleBlogData?.image !== null && (
              <img
                width="100%"
                height="400px"
                src={imageLink+(singleBlogData?.Banner.formats.large?.url || singleBlogData?.Banner.formats.medium?.url || singleBlogData?.Banner.formats.small?.url || singleBlogData?.Banner.formats.thumbnail?.url)}
                alt="blog"
              />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            xs={12}
            className="fw-300 text-left"
            style={{ fontSize: "16px", fontWeight: 600, lineHeight: 1.75, }}
          >
            <div dangerouslySetInnerHTML={{ __html: singleBlogData?.Description }} />
            {/* {singleBlogData?.body} */}
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12}>
            <div className="flex flex-col align-item-baseline">
              <h2 className="fs-20 fw-700 float-left text-green mg-top-10 heading-underline">
                Blog
              </h2>
            </div>
            <BlogCard data={blogData} />
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
}

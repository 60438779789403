import {
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CalendarTodayOutlined } from "@material-ui/icons";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import EventIcon from "@material-ui/icons/Event";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import moment from "moment";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import pSBC from "shade-blend-color";
import facebook from "../../../../assets/common/facebook.png";
import generateCsv from "../../../../components/company/common/generateCsv/generateCsv";
import { BackendBaseApi } from "../../../../constants/constants";
import { getProfileUnlockInfo } from "../../../../GlobalComponents/CreditBoard/action";
import fetchApi from "../../../apiCall/fetchApi";
import gitlog from "../../../assets/png/github.png";
import instagram from "../../../assets/png/instagram.png";
import linkedin from "../../../assets/png/linkedin.png";
import user from "../../../assets/png/user.png";
import AssignmentTable from "../../Common/Table/AssingmentTable/AssignmentTable";
import AddCommentDialog from "../../Dialog/AddCommentDialog";
import CommentDialog from "../../Dialog/CommentDialog";
import InterviewScheduling from "../../Dialog/InterviewScheduling";
import MoveStep from "../../Dialog/MoveStep";
import Rejected from "../../Dialog/Rejected";
import ScoreDialog from "../../Dialog/ScoreDialog";

let cgreen = "#0B8A00";

const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
  },
  loadingState: {
    justifyContent: "center",
    marginTop: 50,
    display: "flex",
  },
  paper: {
    //padding: theme.spacing(2),
    // margin: "auto",
    borderRadius: "20px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    // maxWidth: 500,
    marginBottom: 20,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    height: "150px",
    width: "150px",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  rate: {
    backgroundColor: pSBC(0.8, cgreen),
    color: cgreen,
    borderColor: cgreen,
    border: "1px solid",
  },
  tag: {
    background: theme.palette.primary.main,
    display: "inline-block",
    color: "#fff",
    position: "relative",
    padding: "5px",
    marginTop: "20px",
    // borderTopLeftRadius: "4px",
    // borderBottomLeftRadius: "4px",
    margin: "0 30px 0 0",
    textDecoration: "none",

    // "&:before": {
    //   background: "#fff",
    //   width: "10px",
    //   height: "10px",
    //   content: '""',
    //   display: "inline-block",
    //   borderRadius: "20px",
    //   margin: "0 5px 0 0",
    // },

    "&:after": {
      display: "inline-block",
      border: "15px solid",
      borderColor: "transparent transparent transparent #fff",
      height: "0",
      width: "0",
      position: "absolute",
      right: "-1px",
      transform: "rotate(180deg)",
      top: "0",
      content: '""',
      display: "inline-block",
    },
  },
  btn: {
    boxShadow: "none",
    borderRadius: "30px",
    marginRight: "25px",
    display: "flex",
    height: "40px",
    fontWeight: "500",
  },
  verify: {
    backgroundColor: "#fff",
    borderRadius: "50%",
    padding: "5px",
    marginTop: "-40px",
    zIndex: 100,
    display: "flex",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  mainimg: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  sociallink: {
    marginRight: "10px",
    cursor: "pointer",
  },
  emailtext: {
    // maxWidth: "0px",
    cursor: "pointer",
  },
  imggrid: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    margin: 0,
  },
  hideAndShowBtn: {
    background: "none",
    color: theme.palette.primary.main,
    padding: "0 10px",
  },
}));

export default function JobApplicants({
  cardViewApplicantList,
  loading,
  selectedRow,
  setSelectedRow,
  moveNextArray,
  callBackMainApplicantApi,
  selectedJob,
  activeStatusObject,
  isPublic,
  sharedPublicTrackData,
  item,
  errorCallback,
  handleUnlockCandidate,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });
  const [viewAll, setViewAll] = useState(false);
  const getBasicData = (item) => {
    return [
      {
        icon: <PhoneIcon style={{ color: "#6C757D" }} />,
        info: item?.user_details?.phone ? item?.user_details?.phone : "NA",
        type: "Phone Number",
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "phone"
          )
            ? true
            : false,
      },
      {
        icon: <MailOutlineIcon style={{ color: "#6C757D" }} />,
        info: item?.user_details?.email ? item?.user_details?.email : "NA",
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "email"
          )
            ? true
            : false,
      },
      {
        icon: <EventIcon style={{ color: "#6C757D" }} />,
        info: item?.formatted_time ? item?.formatted_time : "NA",
      },
    ];
  };

  const getSecondaryData = (item) => {
    return [
      {
        heading: "Total Experience",
        info: item?.user_details?.work_ex ? item?.user_details?.work_ex : "NA",
        // ? YM.getYearsFromMonth(item?.user_details?.work_ex)?.years &&
        //   YM.getYearsFromMonth(item?.user_details?.work_ex)?.months
        //   ? `${YM.getYearsFromMonth(item?.user_details?.work_ex)?.years} years  ${
        //       YM.getYearsFromMonth(item?.user_details?.work_ex)?.months
        //     } months`
        //   : YM.getYearsFromMonth(item?.user_details?.work_ex)?.years
        //   ? `${YM.getYearsFromMonth(item?.user_details?.work_ex)?.years} years`
        //   : `${YM.getYearsFromMonth(item?.user_details?.work_ex)?.months} months`
        // : "NA",
        show:
          (!isPublic ||
            !sharedPublicTrackData?.action_performed?.table_columns ||
            sharedPublicTrackData?.action_performed?.table_columns?.some(
              (item) => item === "experience"
            )) &&
          item?.user_details?.work_ex
            ? true
            : false,
      },
      {
        heading: "Current CTC",
        info: item?.user_details?.cur_ctc
          ? `${item?.user_details?.cur_ctc}`
          : "NA",
        show:
          (!isPublic ||
            !sharedPublicTrackData?.action_performed?.table_columns ||
            sharedPublicTrackData?.action_performed?.table_columns?.some(
              (item) => item === "current_salary"
            )) &&
          item?.user_details?.cur_ctc
            ? true
            : false,
      },
      {
        heading: "Expected CTC",
        info: item?.user_details?.expected_ctc
          ? `${item?.user_details?.expected_ctc}`
          : "NA",
        show:
          (!isPublic ||
            !sharedPublicTrackData?.action_performed?.table_columns ||
            sharedPublicTrackData?.action_performed?.table_columns?.some(
              (item) => item === "expected_salary"
            )) &&
          item?.user_details?.expected_ctc
            ? true
            : false,
      },
      {
        heading: "Notice Period",
        info: item?.notice_period ? `${item?.notice_period}` : "NA",
        show:
          (!isPublic ||
            !sharedPublicTrackData?.action_performed?.table_columns ||
            sharedPublicTrackData?.action_performed?.table_columns?.some(
              (item) => item === "notice_period"
            )) &&
          item?.user_details?.notice_period
            ? true
            : false,
      },
    ];
  };

  const getDetailedData = (item) => {
    var skill_arr = [];
    if (item?.user_details?.skill && item.user_details.skill.length > 0) {
      item.user_details.skill.forEach((inn) => {
        if (inn?.skill_name !== null && inn?.skill_name !== "") {
          skill_arr.push(inn);
        }
      });
    }
    let skill_csv = generateCsv(skill_arr, "skill_name");
    let skill_csv_arr = [];
    if (skill_arr?.length) skill_csv_arr = [skill_csv];
    return [
      {
        icon: <WorkOutlineOutlinedIcon style={{ color: "#6C757D" }} />,
        heading: "Experience",
        info: item?.user_details?.work_experience
          ?.slice(0, 2)
          .map((item) =>
            item?.end_date
              ? `${item?.job_designation} at ${
                  item?.company_name
                }: ${item?.start_date?.slice(0, 4)} - ${item?.end_date?.slice(
                  0,
                  4
                )}`
              : `${item?.job_designation} at ${
                  item?.company_name
                }: ${item?.start_date?.slice(0, 4)} - Present`
          ),
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "experience"
          )
            ? true
            : false,
      },
      {
        icon: <SchoolOutlinedIcon style={{ color: "#6C757D" }} />,
        heading: "Education",
        info: item?.user_details?.education
          ?.slice(0, 2)
          .map((item) =>
            item?.temp_college_name && item?.college === 142
              ? `${item?.degree ? item?.degree + " at " : ""}${
                  item?.temp_college_name
                }: ${
                  item?.is_current && !item?.end_date
                    ? (item?.start_date
                        ? item?.start_date?.slice(0, 4)
                        : "NA") + " - Present"
                    : (item?.start_date
                        ? item?.start_date?.slice(0, 4)
                        : "NA") +
                      " - " +
                      (item?.end_date ? item?.end_date?.slice(0, 4) : "NA")
                }`
              : item?.college
              ? `${item?.degree ? item?.degree + " at " : ""}${
                  item?.college_name
                }: ${
                  item?.is_current && !item?.end_date
                    ? (item?.start_date
                        ? item?.start_date?.slice(0, 4)
                        : "NA") + " - Present"
                    : (item.start_date ? item?.start_date?.slice(0, 4) : "NA") +
                      " - " +
                      (item?.end_date ? item?.end_date?.slice(0, 4) : "NA")
                }`
              : "Invalid College Entry (NA)"
          ),
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "education"
          )
            ? true
            : false,
      },
      // {
      //   icon: <ArticleOutlinedIcon style={{ color: "#6C757D" }} />,
      //   heading: "Skills",
      //   info: skill_csv_arr,
      //   show:
      //     !isPublic ||
      //     sharedPublicTrackData?.action_performed?.table_columns?.some(
      //       (item) => item === "skills"
      //     )
      //       ? true
      //       : false,
      // },
      // {
      //   icon: <LocationOnOutlinedIcon style={{ color: "#6C757D" }} />,
      //   heading: "Preferred Location",
      //   info: item?.preferred_locations?.map((item) => item.city),
      //   show:
      //     !isPublic ||
      //     sharedPublicTrackData?.action_performed?.table_columns?.some(
      //       (item) => item === "preferred_location"
      //     )
      //       ? true
      //       : false,
      // },
    ];
  };

  const getDetailedSecondData = (item) => {
    var skill_arr = [];
    if (
      item?.user_details?.skill &&
      item.user_details.skill.length > 0 &&
      viewAll
    ) {
      item.user_details.skill.forEach((inn) => {
        if (inn?.skill_name !== null && inn?.skill_name !== "") {
          skill_arr.push(inn);
        }
      });
    } else if (
      item?.user_details?.skill &&
      item.user_details.skill.length > 0 &&
      !viewAll
    ) {
      for (let i = 0; i < 10; i++) {
        if (item?.user_details?.skill[i]?.skill_name) {
          skill_arr.push(item?.user_details?.skill[i]);
        }
      }
    }
    let skill_csv = generateCsv(skill_arr, "skill_name");
    let skill_csv_arr = [];
    if (skill_arr?.length) skill_csv_arr = [skill_csv];

    let prefferedLocationDetails = [];
    let prefLocStr = ``;
    if (item?.preferred_locations?.length > 0) {
      for (let i = 0; i < item?.preferred_locations?.length; i++) {
        prefLocStr += `${item?.preferred_locations[i].city}, `;
      }
    }
    prefLocStr &&
      prefferedLocationDetails.push(prefLocStr.replace(/,\s*$/, ""));

    return [
      {
        icon: <ArticleOutlinedIcon style={{ color: "#6C757D" }} />,
        heading: "Skills",
        info: skill_csv_arr,
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "skills"
          )
            ? true
            : false,
      },
      {
        icon: <LocationOnOutlinedIcon style={{ color: "#6C757D" }} />,
        heading: "Preferred Location",
        info: prefferedLocationDetails,
        show:
          !isPublic ||
          !sharedPublicTrackData?.action_performed?.table_columns ||
          sharedPublicTrackData?.action_performed?.table_columns?.some(
            (item) => item === "preferred_location"
          )
            ? true
            : false,
      },
    ];
  };

  const checkSocialLinkImage = (name) => {
    let link_icon = "";
    switch (name) {
      case "Linkedin":
        link_icon = linkedin;
        return link_icon;

      case "Github":
        link_icon = gitlog;
        return link_icon;

      case "Facebook":
        link_icon = facebook;
        return link_icon;
      case "Instagram":
        link_icon = instagram;
        return link_icon;

      default:
        break;
    }
  };

  const checkSocialLink = (name, value) => {
    let link = "";
    switch (name) {
      case "Linkedin":
        link = value;
        return link;

      case "Github":
        link = value;
        return link;

      case "Facebook":
        link = value;
        return link;

      case "Instagram":
        link = value;
        return link;

      default:
        break;
    }
  };

  const handleChange = (item, value) => {
    var temp_item_arr = [...selectedRow?.details];
    var temp_id_arr = [...selectedRow?.selectedIds];
    if (temp_id_arr?.includes(item.applicant_id)) {
      temp_id_arr = temp_id_arr.filter((id) => id !== item?.applicant_id);
      temp_item_arr = temp_item_arr.filter(
        (obj) => obj?.id !== item?.applicant_id
      );
    } else {
      temp_id_arr.push(item?.applicant_id);
      temp_item_arr.push({
        id: item?.applicant_id,
        name:
          item?.user_details?.first_name + " " + item?.user_details?.last_name,
        user_id: item?.user_id,
        job_id: item?.job_id,
        is_locked: item?.is_locked,
      });
    }
    setSelectedRow({
      ...selectedRow,
      details: temp_item_arr,
      selectedIds: temp_id_arr,
    });
  };

  const [open, setOpen] = React.useState(false);
  const [currentApplicant, setCurrentApplicant] = useState(null);
  const handleClickOpen = (item) => {
    setOpen(true);
    setCurrentApplicant({
      applicantName:
        item?.user_details?.first_name + " " + item?.user_details?.last_name,
      applicantId: item?.applicant_id,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openAddComment, setOpenAddComment] = React.useState(false);
  const handleClickOpenAddComment = (item) => {
    setOpenAddComment(true);
    setCurrentApplicant({
      applicantName:
        item?.user_details?.first_name + " " + item?.user_details?.last_name,
      applicantId: item?.applicant_id,
    });
  };

  const handleCloseAddComment = () => {
    setOpenAddComment(false);
  };

  const [unlockedData, setUnlockedData] = useState(null);
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const alert = useAlert();
  const [openMove, setOpenMove] = React.useState(false);
  const [currentItem, setCurrentItem] = useState(false);
  const [currentRejectItem, setCurrentRejectItem] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpenMove = (item) => {
    setCurrentItem(item);
    setOpenMove(true);
  };

  const handleCloseMove = () => {
    setOpenMove(false);
  };

  const [openReject, setOpenReject] = React.useState(false);

  const handleClickOpenReject = (item) => {
    setCurrentRejectItem(item);
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const unlockSingle = (item) => {
    setUnlockedData(null);
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/unlock_applicant/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        job_applicant_ids: [item?.applicant_id],
        company_id: JSON.parse(localStorage.getItem("company")).company,
      }),
      setResponse: setUnlockedData,
      setLoading: setLoadingUnlock,
      callBackFn: helper,
      errorCallback: errorCallback,
    });
  };

  const helper = () => {
    dispatch(getProfileUnlockInfo(() => {}));
    callBackMainApplicantApi();
    setSelectedRow({
      details: [],
      selectedIds: [],
    });
  };

  return (
    <>
      <div className={classes.root}>
        {/*   {cardViewApplicantList?.map((item) => ( */}
        <Paper className={classes.paper} key={item?.applicant_id}>
          <Grid container>
            <div
              style={{ width: "40%" }}
              // item xs={4}
            >
              {item?.new_flag && (
                <div className={classes.tag}>
                  {" "}
                  <Typography
                    variant="body2"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "20px",
                      color: "#fff",
                    }}
                  >
                    New{" "}
                  </Typography>{" "}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                  paddingBottom: "0px",
                }}
              >
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={selectedRow?.selectedIds?.find(
                          (id) => id === item?.applicant_id
                        )}
                        onChange={(e) => handleChange(item, e.target.checked)}
                        name="checkedA"
                      />
                    }
                    label={
                      !isPublic ||
                      !sharedPublicTrackData?.action_performed?.table_columns ||
                      sharedPublicTrackData?.action_performed?.table_columns?.some(
                        (item) => item === "name"
                      ) ? (
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "Antinoin" }}
                        >
                          {item?.user_details?.first_name}{" "}
                          {item?.user_details?.last_name}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "Antinoin" }}
                        >
                          Hidden Name
                        </Typography>
                      )
                    }
                  />
                </div>
              </div>

              <Grid container spacing={2} className={classes.imggrid}>
                <Grid
                  item
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.mainimg}>
                    <ButtonBase className={classes.image}>
                      <img
                        className={classes.img}
                        alt="complex"
                        src={
                          item?.user_details?.profile_picture
                            ? item?.user_details?.profile_picture
                            : user
                        }
                      />
                    </ButtonBase>
                    <div className={classes.verify}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.25 8L15.42 5.9075L15.675 3.14L12.9675 2.525L11.55 0.125L9 1.22L6.45 0.125L5.0325 2.5175L2.325 3.125L2.58 5.9L0.75 8L2.58 10.0925L2.325 12.8675L5.0325 13.4825L6.45 15.875L9 14.7725L11.55 15.8675L12.9675 13.475L15.675 12.86L15.42 10.0925L17.25 8ZM7.5675 11.54L4.7175 8.6825L5.8275 7.5725L7.5675 9.32L11.955 4.9175L13.065 6.0275L7.5675 11.54Z"
                          fill="#00ADEF"
                        />
                      </svg>
                    </div>
                  </div>
                  {(!isPublic ||
                    sharedPublicTrackData?.action_performed?.table_columns?.some(
                      (item) => item === "score"
                    )) && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <ScoreDialog
                        score={item?.relevance_score}
                        scoreData={item?.student_job_matching_data}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={6} sm container>
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    // spacing={2}
                    style={{ display: "contents" }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      {getBasicData(item).map(
                        (val, ind) =>
                          val.show && (
                            <Grid container wrap="nowrap" spacing={2}>
                              <Grid item>{val.icon}</Grid>
                              <Grid item xs style={{ display: "grid" }}>
                                {/* action= */}

                                <CopyToClipboard
                                  text={val.info}
                                  onCopy={() => alert.success("Copied!")}
                                >
                                  <Typography
                                    variant="body2"
                                    color="primary"
                                    noWrap
                                    className={classes.emailtext}
                                  >
                                    {val.info}{" "}
                                  </Typography>
                                </CopyToClipboard>
                              </Grid>
                              {/* </CopyToClipboard> */}
                            </Grid>
                          )
                      )}
                      {item?.user_details.profile_url &&
                      item?.user_details.profile_url?.length ? (
                        <>
                          <Grid
                            container
                            wrap="nowrap"
                            spacing={2}
                            style={{ marginBottom: 10 }}
                          >
                            {/* <Grid item>
                                <LanguageIcon color="primary" />
                              </Grid> */}

                            <Grid item xs>
                              <div>
                                {item?.user_details.profile_url !== null &&
                                  item?.user_details.profile_url.map(
                                    (item, ind) =>
                                      item?.logo && (
                                        <Tooltip
                                          title={item.name}
                                          placement="bottom"
                                        >
                                          <img
                                            alt="alt-img"
                                            src={
                                              item?.logo
                                                ? item?.logo
                                                : "" /* checkSocialLinkImage(item.name) */
                                            }
                                            height="22px"
                                            className={classes.sociallink}
                                            onClick={() =>
                                              window.open(
                                                checkSocialLink(
                                                  item.name,
                                                  item.value
                                                )
                                              )
                                            }
                                          />
                                        </Tooltip>
                                      )
                                  )}
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      ) : null}

                      {item?.create_date && (
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item>
                            <CalendarTodayOutlined
                              style={{ color: "#6C757D" }}
                            />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body2">
                              <span style={{ color: "#6C757D" }}>
                                Applied On :{" "}
                              </span>
                              <span /* style={{ fontWeight: "bold" }} */>
                                {`${moment(item?.create_date).format(
                                  " DD MMM YYYY"
                                )} `}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ padding: "0px" }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ padding: "20px" }}>
                    {item?.assigned_company_user &&
                      item?.assigned_company_user?.length > 0 && (
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item>
                            <PeopleAltOutlinedIcon
                              style={{ color: "#6C757D" }}
                            />
                          </Grid>
                          {item?.assigned_company_user &&
                            item?.assigned_company_user?.length > 0 && (
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  style={{ color: "#6C757D" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    Assigned Team Member :
                                  </span>
                                  {item?.assigned_company_user[0]?.first_name +
                                    " " +
                                    item?.assigned_company_user[0]?.last_name}
                                </Typography>
                              </Grid>
                            )}
                        </Grid>
                      )}
                    {(!isPublic ||
                      sharedPublicTrackData?.action_performed?.table_columns?.some(
                        (item) => item === "Comments"
                      )) && (
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                          <CommentOutlinedIcon style={{ color: "#6C757D" }} />
                        </Grid>

                        <Grid item xs>
                          <Typography
                            variant="body2"
                            onClick={() => handleClickOpen(item)}
                            color="primary"
                            style={{ fontWeight: 600, cursor: "pointer" }}
                          >
                            View Comments
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div
              // item xs={8}

              style={{ borderLeft: "1px solid #E2E6EA", width: "60%" }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    minHeight: "54px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      display: "flex",
                    }}
                  >
                    {getSecondaryData(item)?.map((val, ind) => (
                      <>
                        {val.show && (
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginRight: "30px",
                              color: "#000",
                            }}
                          >
                            <span style={{ color: "#71787F" }}>
                              {val.heading}
                            </span>{" "}
                            <span>{` : ${val.info}`}</span>
                          </Typography>
                        )}
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ height: ".5px" }} />
                </Grid>
                {getDetailedData(item).map((val, ind) =>
                  val.show ? (
                    <Grid
                      container
                      style={{ borderBottom: "1px solid #E3E6EA" }}
                    >
                      <Grid item xs={3}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            minHeight: "80px",
                          }}
                        >
                          {val.icon}
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginLeft: "20px",
                              color: "#71787F",
                            }}
                          >
                            {val.heading}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        style={{ borderLeft: "1px solid #E3E6EA" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "80px",
                          }}
                        >
                          <ul>
                            {val?.info?.length > 0 ? (
                              val.info.map((line) => (
                                <Typography
                                  component="li"
                                  variant="body2"
                                  key={line}
                                  style={{ margin: "5px" }}
                                >
                                  {line}{" "}
                                </Typography>
                              ))
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ marginLeft: "-10px" }}
                              >
                                NA
                              </Typography>
                            )}
                          </ul>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      style={{ borderBottom: "1px solid #E3E6EA" }}
                    >
                      <Grid item xs={3}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            minHeight: "80px",
                          }}
                        >
                          {val.icon}
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginLeft: "20px",
                              color: "#71787F",
                            }}
                          >
                            {val.heading}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        style={{ borderLeft: "1px solid #E3E6EA" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "80px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{ marginLeft: 30 }}
                          >
                            NA
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  )
                )}
                {getDetailedSecondData(item).map((val, ind) =>
                  val.show ? (
                    <Grid
                      container
                      style={{ borderBottom: "1px solid #E3E6EA" }}
                    >
                      <Grid item xs={3}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            minHeight: "80px",
                          }}
                        >
                          {val.icon}
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginLeft: "20px",
                              color: "#71787F",
                            }}
                          >
                            {val.heading}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        style={{ borderLeft: "1px solid #E3E6EA" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            /*   display: "flex",
                              alignItems: "center", */
                            minHeight: "80px",
                          }}
                        >
                          <ul
                            style={{
                              paddingLeft: "25px" /* display: "flex" */,
                            }}
                          >
                            {val?.info?.length > 0 ? (
                              val.info.map((line) => (
                                <Typography
                                  //component="li"
                                  variant="body2"
                                  key={line}
                                  style={{ margin: "5px" }}
                                >
                                  {line}
                                </Typography>
                              ))
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ marginLeft: "4px" }}
                              >
                                NA
                              </Typography>
                            )}
                            {item?.user_details?.skill?.length >= 10 &&
                              val?.heading === "Skills" && (
                                <Button
                                  className={classes.hideAndShowBtn}
                                  //className={classes.hideAndShowBtn}
                                  onClick={() => setViewAll(!viewAll)}
                                >
                                  {viewAll ? "Show Less" : "Show More"}
                                </Button>
                              )}
                          </ul>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      style={{ borderBottom: "1px solid #E3E6EA" }}
                    >
                      <Grid item xs={3}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            minHeight: "80px",
                          }}
                        >
                          {val.icon}
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: "400",
                              marginLeft: "20px",
                              color: "#71787F",
                            }}
                          >
                            {val.heading}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        style={{ borderLeft: "1px solid #E3E6EA" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "80px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{ marginLeft: 30 }}
                          >
                            NA
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </div>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div style={{ padding: "10px", display: "flex" }}>
                {!item?.is_locked ? (
                  <a
                    href={`${
                      isPublic
                        ? "/public/students/track/"
                        : "/company/students/"
                    }?user_id=${item?.user_id}&applicant_id=${
                      item?.applicant_id
                    }&job_id=${item?.job_id}`}
                    style={{ textDecoration: "none" }}
                    target="blank"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                    >
                      View Details
                    </Button>
                  </a>
                ) : !isPublic ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ minWidth: "130px" }}
                    className={classes.btn}
                    onClick={() => {
                      if (!loadingUnlock)
                        handleUnlockCandidate(item?.applicant_id);
                    }}
                  >
                    {loadingUnlock ? (
                      <CircularProgress size={15} style={{ color: "#fff" }} />
                    ) : (
                      "Unlock"
                    )}
                  </Button>
                ) : (
                  <></>
                )}
                {(!isPublic ||
                  sharedPublicTrackData?.action_performed?.authority
                    ?.track) && (
                  <>
                    {activeStatusObject?.status_id !== 14 &&
                      activeStatusObject?.status_id !== 18 && (
                        <>
                          {" "}
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.btn}
                            onClick={() => handleClickOpenMove(item)}
                            disabled={item?.is_locked}
                          >
                            Move to Next Round
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.btn}
                            onClick={() => handleClickOpenReject(item)}
                            disabled={item?.is_locked}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                  </>
                )}
                {!isPublic && activeStatusObject?.status_id === 7 && (
                  <InterviewScheduling
                    selectedRow={{
                      details: [
                        {
                          id: item?.applicant_id,
                          name:
                            item?.user_details?.first_name +
                            " " +
                            item?.user_details?.last_name,
                          user_id: item?.user_id,
                          job_id: item?.job_id,
                        },
                      ],
                      selectedIds: [item?.applicant_id],
                    }}
                    setSelectedRow={setSelectedRow}
                    selectedJob={selectedJob}
                    activeStatusObject={activeStatusObject}
                  />
                )}
                {item?.assignment?.length > 0 && (
                  <AssignmentTable
                    data={{
                      name: item?.first_name,
                      assignment_list: item?.assignment ? item?.assignment : [],
                    }}
                    jobCard={true}
                  />
                )}

                {(!isPublic ||
                  !sharedPublicTrackData?.action_performed?.table_columns ||
                  sharedPublicTrackData?.action_performed?.table_columns?.some(
                    (item) => item === "resume"
                  )) && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    style={
                      activeStatusObject?.status_id === 7
                        ? { marginLeft: "25px", marginRight: "25px" }
                        : {}
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(item?.user_details?.resume);
                    }}
                    disabled={
                      item?.is_locked ||
                      (item?.user_details?.resume ? false : true)
                    }
                  >
                    <span>View Resume</span>
                  </Button>
                )}

                {(!isPublic ||
                  !sharedPublicTrackData?.action_performed?.table_columns ||
                  sharedPublicTrackData?.action_performed?.table_columns?.some(
                    (item) => item === "comment"
                  )) && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    style={activeStatusObject?.status_id === 7 ? {} : {}}
                    onClick={() => handleClickOpenAddComment(item)}
                  >
                    Comment
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
        {/* ))} */}
        <div style={{ display: "flex" }}>
          {" "}
          <MoveStep
            open={openMove}
            handleClickOpen={handleClickOpenMove}
            handleClose={handleCloseMove}
            selectedRow={{
              details: [
                {
                  id: currentItem?.applicant_id,
                  name:
                    currentItem?.user_details?.first_name +
                    " " +
                    currentItem?.user_details?.last_name,
                  user_id: currentItem?.user_id,
                  job_id: currentItem?.job_id,
                },
              ],
              selectedIds: [currentItem?.applicant_id],
            }}
            setSelectedRow={null}
            moveNextArray={moveNextArray}
            callBackMainApplicantApi={callBackMainApplicantApi}
            isPublic={isPublic}
            sharedPublicTrackData={sharedPublicTrackData}
          />
          <Rejected
            open={openReject}
            handleClickOpen={handleClickOpenReject}
            handleClose={handleCloseReject}
            selectedRow={{
              details: [
                {
                  id: currentRejectItem?.applicant_id,
                  name:
                    currentRejectItem?.user_details?.first_name +
                    " " +
                    currentRejectItem?.user_details?.last_name,
                  user_id: currentItem?.user_id,
                  job_id: currentItem?.job_id,
                },
              ],
              selectedIds: [currentRejectItem?.applicant_id],
            }}
            setSelectedRow={null}
            callBackMainApplicantApi={callBackMainApplicantApi}
            isPublic={isPublic}
            sharedPublicTrackData={sharedPublicTrackData}
          />
          <AddCommentDialog
            open={openAddComment}
            handleClickOpen={handleClickOpenAddComment}
            handleClose={handleCloseAddComment}
            selectedRow={{
              details: [
                {
                  id: currentApplicant?.applicantId,
                  name: currentApplicant?.applicantName,
                },
              ],
              selectedIds: [currentApplicant?.applicantId],
            }}
            setSelectedRow={null}
            activeStatusObject={activeStatusObject}
          />
          <CommentDialog
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            activeStatusObject={activeStatusObject}
            currentApplicant={currentApplicant}
            isPublic={isPublic}
          />
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import SelectSearch from "react-select-search";
import { BackendBaseApi, years } from "../../../../constants/constants";
import ScrollableCheckboxList from "../../../common/components/UI/ScollableCheckboxList";
import EmployerGuidelines from "../UI/EmployerGuidelines";
import CompanyUserDetails from "./PersonalDetails";

const Preferences = ({
  companyUserDetails,
  setCompanyUserDetails,
  step,
  setStep,
}) => {
  const disableButton = useRef();
  const alert = useAlert();
  const [showGuidelinesModal, setShowGuidelinesModal] = useState(false);

  //UI state variables
  const [showField, setShowField] = useState({
    College: false,
  });
  const [jobTitles, setJobTitles] = useState([]);
  const [jobTitle, setJobTitle] = useState(companyUserDetails.JobTitle);
  const [collegeList, setCollegeList] = useState({});
  const [candidatePreferences, setcandidatePreferences] = useState([]);
  const [jobPreferences, setJobPreferences] = useState([]);
  const [moveToNext, setMoveToNext] = useState(false);

  //state variables
  const [
    selectedCandidatePreferences,
    setSelectedCandidatePreferences,
  ] = useState([]);
  const [selectedJobPreferences, setSelectedJobPreferences] = useState([]);
  const [college, setCollege] = useState(CompanyUserDetails.CollegeName);
  const [year, setYear] = useState(companyUserDetails.GraduatingYear);
  const [showReferralMessage, setShowReferralMessage] = useState(false);
  const [referralMessage, setReferralMessage] = useState("");
  const [referralError, setReferralError] = useState(false);
  const [inputReferralCode, setInputReferralCode] = useState("");

  let colleges = Object.keys(collegeList).map((clg) => {
    return { name: clg, value: clg };
  });
  const TypeAndSearchCollege = () => (
    <SelectSearch
      options={colleges}
      search
      value={college}
      onChange={setCollege}
      placeholder="Select your college"
      autoComplete="on"
    />
  );

  const YearDropdown = () => (
    <SelectSearch
      value={year}
      options={years}
      placeholder="Select your grad year"
      onChange={setYear}
    />
  );

  const getCollegeList = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/education/college/")
      .then((res) => {
        let temp = {};
        res.data.forEach((collegeData) => {
          temp[collegeData.name] = collegeData.id;
        });

        setCollegeList({ ...temp });
      })
      .catch((err) => {
        throw err;
      });
  };

  const getJobTitles = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/user_type/?main_user=company")
      .then((res) => {
        let temp = [];
        res.data.data.forEach((data) => {
          let tempObj = {
            id: data.id,
            name: data.sub_user,
          };
          temp.push(tempObj);
          setJobTitles(temp);
        });
      })
      .catch((err) => {
        throw err;
      });
  };

  const getCandidatePreferences = () => {
    axios
      .get(BackendBaseApi.NIYUKTI + "job/segment/")
      .then((res) => {
        let temp = [];
        res.data.data.forEach((data) => {
          let tempObj = {
            id: data.id,
            name: data.job_segment,
          };
          temp.push(tempObj);
          setcandidatePreferences(temp);
        });
      })
      .catch((err) => {
        throw err;
      });
  };

  const getJobPreferences = () => {
    axios
      .get(BackendBaseApi.NIYUKTI + "job/type/")
      .then((res) => {
        let temp = [];
        res.data.data.forEach((data) => {
          let tempObj = {
            id: data.id,
            name: data.job_type,
          };
          temp.push(tempObj);
          setJobPreferences(temp);
        });
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getCollegeList();
    getJobTitles();
    getCandidatePreferences();
    getJobPreferences();
    disableButton.current.classList.add("disabled");
  }, []);

  useEffect(() => {
    if (
      jobTitle &&
      selectedCandidatePreferences.length > 0 &&
      selectedJobPreferences.length > 0
    ) {
      setMoveToNext(true);
      disableButton.current.classList.remove("disabled");
    } else {
      setMoveToNext(false);
      disableButton.current.classList.add("disabled");
    }
  }, [jobTitle, selectedCandidatePreferences, selectedJobPreferences]);

  const ValidateRefferalCode = async () => {
    const response = await axios.post(
      BackendBaseApi.PRAVESH + "api/referrals/check_referral/",
      {
        type: "company",
        referral_code: companyUserDetails.RefferalCode,
      }
    );

    response.data.success
      ? alert.success(response.data.data.message)
      : alert.error(response.data.error);
    return response;
  };

  const newValidateReferralCode = () => {
    if(!inputReferralCode){
      setReferralMessage("Please try again with valid referral code");
      setReferralError(true);
      setShowReferralMessage(true);
      setInputReferralCode("");
      setCompanyUserDetails({
        ...companyUserDetails,
        RefferalCode: "",
      });
      return;
    }
    axios.post(
      BackendBaseApi.PRAVESH + "api/referrals/check_referral/",
      {
        type: "company",
        referral_code: inputReferralCode,
      }
    ).then(res => {
      if(res.status === 200 && res.data.success){
        setCompanyUserDetails({
          ...companyUserDetails,
          RefferalCode: inputReferralCode,
        });
      setReferralMessage(res.data.data.message);
      setReferralError(false);
      }
      else{
        setReferralMessage(res.data.error);
        setReferralError(true);
      }
      setShowReferralMessage(true);
    }).catch((err) => {
      setShowReferralMessage(true);
      setReferralError(true);
      setReferralMessage(err);
    })
  };

  const setDetails = () => {
    let cid = null,
      cname = null;
    if (showField.College) {
      cid = null;
      cname = college;
    } else {
      cname = null;
      cid = collegeList[college];
    }

    setCompanyUserDetails({
      ...companyUserDetails,
      JobTitle: jobTitle,
      CandidatePreferences: selectedCandidatePreferences,
      JobPreferences: selectedJobPreferences,
      CollegeID: showField.College ? null : collegeList[college],
      CollegeName: showField.College ? college : null,
      GraduatingYear: year,
    });
  };

  const handleSubmit = (e) => {
    if (!moveToNext) {
      alert.info("Please fill all the required fields");
      return;
    }
    setDetails();
    showEmployerGuidelines();
  };
  const showEmployerGuidelines = () => {
    //setStep(step+1);
    setShowGuidelinesModal(true);
  };

  return (
    <>
      <div className="row pl-3">
        <h1 className="mb-4 login-heading text-left">Tell us about yourself</h1>
      </div>

      <div className="my-md-0 my-2">
        <div className="form-group required text-left">
          <label htmlFor="JobTitle" className="fw-500 control-label">
            Your Designation
          </label>
          <input
            onChange={(e) => {
              setJobTitle(e.target.value);
            }}
            type="text"
            className="form-control mt-2 mb-1 shadow_1-lightest"
            id="JobTitle"
            aria-describedby="emailHelp"
            placeholder="i.e. University Recruiter"
            value={jobTitle}
            required
          />
        </div>

        <div className="form-group required text-left">
          <label htmlFor="Preferences" className="fw-500 control-label">
            Candidate Preferences
          </label>
          <small id="emailHelp" className="form-text text-muted">
            Tell us the type of candidates you'd like us to find
          </small>
          <ScrollableCheckboxList
            state={selectedCandidatePreferences}
            setState={setSelectedCandidatePreferences}
            listData={candidatePreferences}
            from="candidate"
          />
        </div>

        {/* <div className="form-group required text-left">
                    <label
                        htmlFor="Preferences"
                        className="fw-500 control-label"
                    >
                        Candidate Preferences
                    </label>
                    <small id="emailHelp" className="form-text text-muted">
                        Tell us the type of candidates you'd like us to find
                    </small>
                    <ScrollableCheckboxList
                        state={selectedCandidatePreferences}
                        setState={setSelectedCandidatePreferences}
                        listData={candidatePreferences}
                    />
                </div> */}

        <div className="form-group required text-left">
          <label htmlFor="Email" className="fw-500 control-label">
            Job Preferences
          </label>
          <small id="emailHelp" className="form-text text-muted">
            You'll be mostly hiring for{" "}
          </small>
          <ScrollableCheckboxList
            state={selectedJobPreferences}
            setState={setSelectedJobPreferences}
            listData={jobPreferences}
            from="job"
          />
        </div>

        <div className="form-group text-left">
          <div className="row">
            <div className="col-6">
              <label htmlFor="almaMatter" className="fw-500">
                Your alma mater
              </label>
              {showField.College ? (
                <>
                  <input
                    onChange={(e) => {
                      setCollege(e.target.value);
                    }}
                    type="text"
                    className="form-control mt-2 mb-1 shadow_1-lightest"
                    id="almaMatter"
                    aria-describedby="emailHelp"
                    placeholder=""
                  />
                </>
              ) : (
                <>
                  <TypeAndSearchCollege className="form-control shadow_1-lightest" />
                </>
              )}
              <div className="form-group text-left ml-4 mb-1">
                <input
                  type="checkbox"
                  checked={showField.college}
                  onChange={() => {
                    setShowField({
                      ...showField,
                      College: !showField.College,
                    });
                  }}
                  className="form-check-input my-2"
                  id="college-checkbox"
                />
                <label className="form-check-label" htmlFor="college-checkbox">
                  <small id="emailHelp" className="form-text my-1 fw-500">
                    My college is not in the list
                  </small>
                </label>
              </div>
            </div>
            <div className="col-6">
              <label htmlFor="gradYear" className="fw-500">
                Year of Graduation
              </label>
              <input
                onChange={(e) => {
                  setYear(`${e.target.value}-01-01`);
                }}
                type="number"
                id="gradYear"
                className="form-control mt-2 mb-1 shadow_1-lightest"
                aria-describedby="emailHelp"
                value={parseInt(year.split("-")[0])}
                placeholder="e.g. 1998"
              />
            </div>
          </div>
        </div>

        <div className="form-group text-left">
          <label htmlFor="RefferalCode" className="fw-500">
            Referral Code
          </label>
          <div className="row">
          <div style={{width:"67%"}}>
          <input
            onChange={(e) => {
              setInputReferralCode(e.target.value);
            }}
            type="text"
            className="form-control mt-2 mb-1 ml-3 shadow_1-lightest"
            id="RefferalCode"
            aria-describedby="emailHelp"
            placeholder=""
          />
          </div>
          
          <div className="col-2 text-left">
            <button
              ref={disableButton}
              // disabled={false}
              className=" shadow_1 btn btn-lg btn-main btn-login btn-next 
                            text-uppercase font-weight-bold mt-2 mb-1 ml-2 shadow_1-lightest"
              onClick={(e) => newValidateReferralCode()}
            >
              <span>
              Apply
              </span>
            </button>
          </div>
        </div>
          {
          showReferralMessage &&
          <div className="row ml-2" style={{
            color: referralError ? "red" : "green",
            fontStyle: "italic"
          }}>
            <p>{referralMessage}</p>
          </div>
      }
        </div>

        <div className="row my-1 pt-3">
          <div className="col-6 text-left">
            <button
              ref={disableButton}
              // disabled={false}
              className=" shadow_1 btn btn-lg btn-main btn-login btn-next 
                            text-uppercase font-weight-bold mb-2"
              onClick={(e) => handleSubmit(e)}
            >
              <span>
                Next <i class="fas fa-angle-double-right" />
              </span>
            </button>
          </div>
        </div>
      </div>
      {showGuidelinesModal && (
        <EmployerGuidelines
          companyUserDetails={companyUserDetails}
          setCompanyUserDetails={setCompanyUserDetails}
          renderModal={showGuidelinesModal}
          setRenderModal={setShowGuidelinesModal}
          step={step}
          setStep={setStep}
        />
      )}
    </>
  );
};

export default Preferences;

import { Container } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";
import ProfileGrid from "../../../components/public/Profile/ProfileGrid";
import { BackendBaseApi } from "../../../constants/constants";
import Layout from "../../student/Layout/Layout";

export default function CompleteProfile() {
  const [currentGender, setCurrentGender] = useState();
  const [openAddEducation, setOpenAddEducation] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [userDetails, setUserDetails] = useState({
    PhoneNo: null,
    Email: "",

    FirstName: "",
    LastName: "",
    dob: null,
    about: "",
    currentCity: null,
    currentHomeCity: null,
    gender: null,
    resume: null,
  });

  const [errorMessages, setErrorMessages] = useState({
    PhoneNo: "",
    Email: "",

    FirstName: "",
    LastName: "",
    dob: "",
    currentCity: "",
    currentHomeCity: "",
    gender: "",
  });

  const [buttonClicked, setButtonClicked] = useState(false);
  const [educationDetailsList, setEducationDetailsList] = useState([]);
  const [openAddWorkExp, setOpenAddWorkExp] = useState(true);
  const [allCities, setAllCities] = useState([]);
  const [allBoards, setAllBoards] = useState([]);
  const [allColleges, setAllColleges] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allJobType, setAllJobType] = useState([]);
  const [workExpDetailsList, setWorkExpDetailsList] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [allSocialLinks, setAllSocialLinks] = useState([]);
  const [allSkillsCompressed, setAllSkillsCompressed] = useState([]);
  const [educationLoader, setEducationLoader] = useState(false);
  const [expLoader, setExpLoader] = useState(false);
  const [skillsLoader, setSkillsLoader] = useState(false);
  const [linksLoader, setLinksLoader] = useState(false);
  const [projectLoader, setProjectLoader] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [profilePicChanged, setProfilePicChanged] = useState(false);
  const [openAddLanguages, setOpenAddLanguages] = useState(false);
  const [openAddHobbies, setOpenAddHobbies] = useState(false);
  const [openAddAchievements, setOpenAddAchievements] = useState(false);
  const [openAddCertificate, setOpenAddCertificate] = useState(false);
  const [openAddProjects, setOpenAddProjects] = useState(false);
  const [instituteList, setInstituteList] = useState([]);
  const mainRef = useRef(null);

  const executeScroll = () => {
    mainRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const [educationDetails, setEducationDetails] = useState({
    education_level: null,
    passing_years: null,
    board: null,
    percentage: null,
    cgpa: null,
    college: null,
    college_typed: "",
    degree: null,
    courses: null,
    collegeNotListed: false,
    is_current: false,
    still_persuing: false,
    start_date: null,
    end_date: null,
    institute: null,
    enrollment_id: null,
  });
  const [educationDetailsErrors, setEducationDetailsErrors] = useState({
    education_level: "",
    passing_years: "",
    board: "",
    percentage: "",
    cgpa: "",
    college: "",
    college_typed: "",
    degree: "",
    courses: "",
    collegeNotListed: "",
    is_current: "",
    still_persuing: "",
    start_date: "",
    end_date: "",
    institute: "",
    enrollment_id: "",
  });

  const [workExpDetails, setWorkExpDetails] = useState({
    job_title: "",
    company: null,
    job_type: null,
    start_date: null,
    end_date: null,
    description: "",
    currently_working: false,
    website: "",
    skills: [],
    singleSkill: null,
    company_typed: "",
    companyNotListed: false,
  });
  const [workExpDetailsErrors, setWorkExpDetailsErrors] = useState({
    job_title: "",
    company: "",
    job_type: "",
    start_date: "",
    end_date: "",
    website: "",
    company_typed: "",
  });
  const [skillDetailsList, setSkillDetailsList] = useState([]);
  const [linkList, setLinkList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [extraData, setExtraData] = useState({
    achievements: [],
    hobbies: [],
    languages: [],
    singleHobby: null,
    singleLanguage: null,
    level: [],
    name: "",
    proficiency: null,
    singleAchievement: null,
  });

  const [skillDetails, setSkillDetails] = useState({
    singleSkillSelected: null,
    skillsSelected: [],
    singleLinkSelected: null,
    link: "",
  });
  const [skillDetailsErrors, setSkillDetailsErrors] = useState({
    singleSkillSelected: "",
    singleLinkSelected: "",
    link: "",
  });

  const [projectDetails, setProjectDetails] = useState({
    project_title: "",
    project_link: "",
    description: "",
    type: "Project",
  });
  const [projectDetailsErrors, setProjectDetailsErrors] = useState({
    project_title: "",
    project_link: "",
    description: "",
  });
  useEffect(() => {
    if (allCities.length === 0) {
      Axios.get(BackendBaseApi.PRAVESH + "api/location/city/")
        .then((res) => {
          setAllCities(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [currentCity, setCurrentCity] = useState();
  const [currentHomeCity, setCurrentHomeCity] = useState();
  const alert = useAlert();

  const getUserDetails = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/shared/user/?user_type=1", { headers: { Authorization: "Token " + localStorage.getItem("gw_token") } })
      .then((res) => {
        if (res.data.success) {
          setUserDetails({
            ...userDetails,
            FirstName: res.data?.data?.first_name,
            LastName: res.data?.data?.last_name,
            Email: res.data?.data?.email,
            PhoneNo: Number(res.data?.data?.phone),
            dob: res.data?.data?.dob,
            about: res.data?.data?.student_user_details?.about ? res.data?.data?.student_user_details?.about : "",
            currentCity: { city_id: res.data?.data?.current_city_id, city: res.data?.data?.current_city_name },
            currentHomeCity: { city_id: res.data?.data?.hometown_city_id, city: res.data?.data?.hometown_city_name },
            gender: { name: res.data?.data?.student_user_details?.gender, value: res.data?.data?.student_user_details?.gender },
            resume: res.data?.data?.student_user_details?.resume,
          });
          setPreviewImage(res.data.data.profile_picture);
          setCurrentCity({ city_id: res.data.data.current_city_id, city: res.data.data.current_city_name });
          setCurrentHomeCity({ city_id: res.data.data.hometown_city_id, city: res.data.data.hometown_city_name });
          setCurrentGender({ name: res.data.data.student_user_details.gender, value: res.data.data.student_user_details.gender });
        } else {
          alert.error("Unable to fetch User Data for this Student!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (activeStep === 0) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/college/")
      .then((res) => {
        let tmpArray = [];
        for (var i = 0; i < res.data.length; i++) {
          tmpArray.push(res.data[i]);
        }
        setAllColleges(tmpArray);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeStep === 2) {
      setExpLoader(true);
      executeScroll();

      Axios.get(BackendBaseApi.PRAVESH + "api/company/work_experience/", {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
        .then((res) => {
          if (res.data.success) {
            setWorkExpDetailsList(res.data.data);
            if (res.data.data.length > 0) {
              setOpenAddWorkExp(false);
            }
            // executeScroll();
          } else {
            alert.error("Unable to fetch Experience for this Student!");
          }
          setExpLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setExpLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    // setCircularLoading(true);
    if (activeStep === 1) {
      setEducationLoader(true);
      Axios.get(BackendBaseApi.PRAVESH + "api/education/user_education", {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
        .then((res) => {
          if (res.data.success) {
            setEducationDetailsList(res.data.data);
          } else {
            alert.error("Unable to fetch Education for this Student!");
          }
          setEducationLoader(false);

          // setCircularLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setEducationLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/company_list/")
      .then((res) => {
        if (res.data.success) {
          setAllCompanies(res.data.data.all_companies);
        } else {
          alert.error("Unable to fetch Companies from Server!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/college/institute_list/")
      .then((res) => {
        if (res.data.success) {
          setInstituteList(res.data.data);
        } else {
          alert.error("Unable to fetch Colleges from Server!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/type/")
      .then((res) => {
        if (res.data.success) {
          setAllJobType(res.data.data);
        } else {
          alert.error("Unable to fetch Companies from Server!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeStep === 3) {
      // setCircularLoading(true);
      setLinksLoader(true);
      Axios.get(BackendBaseApi.PRAVESH + "api/education/user_social_links", {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
        .then((res) => {
          if (res.data.success) {
            setLinkList(res.data.data ? res.data.data : []);
          } else {
            alert.error("Unable to fetch Links for this Student!");
          }
          setLinksLoader(false);

          // setCircularLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLinksLoader(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 3) {
      // setCircularLoading(true);
      setSkillsLoader(true);

      Axios.get(BackendBaseApi.PRAVESH + "api/education/user_skill", {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
        .then((res) => {
          if (res.data.success) {
            setSkillDetailsList(res.data.data.user_skill ? res.data.data.user_skill : []);
          } else {
            alert.error("Unable to fetch Skills for this Student!");
          }
          setSkillsLoader(false);

          // setCircularLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSkillsLoader(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 3) {
      // setCircularLoading(true);
      // setSkillsLoader(true);

      Axios.get(BackendBaseApi.PRAVESH + "api/education/student_user_extras/", {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
        .then((res) => {
          if (res.data.success) {
            setExtraData({ ...extraData, hobbies: res.data.data.hobbies ? res.data.data.hobbies : [], languages: res.data.data.languages ? res.data.data.languages : [] });
          } else {
            alert.error("Unable to fetch Skills for this Student!");
          }
          // setSkillsLoader(false);

          // setCircularLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // setSkillsLoader(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 4) {
      // setCircularLoading(true);
      // setSkillsLoader(true);

      Axios.get(BackendBaseApi.PRAVESH + "api/education/student_user_extras/", {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
        .then((res) => {
          if (res.data.success) {
            setExtraData({ ...extraData, achievements: res.data.data.achievements ? res.data.data.achievements : [] });
          } else {
            alert.error("Unable to fetch Skills for this Student!");
          }
          // setSkillsLoader(false);

          // setCircularLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // setSkillsLoader(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [activeStep]);

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/board")
      .then((res) => {
        if (res.data.success) {
          setAllBoards(res.data.data.board_list);
        } else {
          alert.error("Unable to fetch Education Board from Server!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/shared/social_links/")
      .then((res) => {
        if (res.data.success) {
          setAllSocialLinks(res.data.data);
        } else {
          alert.error("Unable to fetch Education Board from Server!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/skills")
      .then((res) => {
        if (res.data.success) {
          let tmpArray = [];
          let tmpArray1 = [];
          for (var i = 0; i < res.data.data.skills.length; i++) {
            let obj = {
              skill_id: res.data.data.skills[i].skill_id,
              skill_name: res.data.data.skills[i].skill_name,
            };
            let obj1 = {
              skill_name: res.data.data.skills[i].skill_name,
              skill_id: res.data.data.skills[i].skill_id,
              skill_rating: "Intermediate",
              skill_rating_value: 3,
              type: res.data.data.skills[i].type,
            };
            tmpArray.push(obj);
            tmpArray1.push(obj1);
          }
          setAllSkills(tmpArray1);
          setAllSkillsCompressed(tmpArray);
        } else {
          alert.error("Unable to fetch Skills from Server!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeStep === 4) {
      setProjectLoader(true);

      Axios.get(BackendBaseApi.PRAVESH + "api/education/user_project", {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
        .then((res) => {
          if (res.data.success) {
            setProjectList(res.data.data);
          } else {
            alert.error("Unable to fetch Experience for this Student!");
          }
          setProjectLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setProjectLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const [varyPercentage, setVaryPercentage] = useState(0);
  const [prefFilled, setPrefFilled] = useState(false);
  const [educationDetailsFilled, setEducationDetailsFilled] = useState(false);
  const [skillsFilled, setSkillsFilled] = useState(false);
  const [basicDetailsFilled, setBasicDetailsFilled] =  useState(false);
  const fetchPrecentage = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/common/get_profile_percentage", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          setVaryPercentage(res.data.data.percentage);
          if(basicDetailsFilled){
            setPrefFilled(res.data.data.is_pref_filled);
          }
          

          setEducationDetailsFilled(res.data.data.current_education_filled);
          setSkillsFilled(res.data.data.skill_filled);

          localStorage.setItem("profile_complete", res.data.data.percentage);
          localStorage.setItem("is_pref_filled", res.data.data.is_pref_filled);
        } else {
          // alert.error("Unable to fetch Experience for this Student!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPrecentage();
  }, []);
  useEffect(()=>{
    fetchPrecentage();
  },[basicDetailsFilled])

  useEffect(() => {
    executeScroll();
  }, [activeStep]);
  
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <>
      {/* {domain?.domain ? <DomainNavbar /> : <Navbar isShown={true} />} */}
      <Layout hideDownload={true}>
      <div ref={mainRef}>
        <Container maxWidth="lg">
          <ProfileGrid
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            currentCity={currentCity}
            setCurrentCity={setCurrentCity}
            currentHomeCity={currentHomeCity}
            setCurrentHomeCity={setCurrentHomeCity}
            currentGender={currentGender}
            setCurrentGender={setCurrentGender}
            allCities={allCities}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
            openAddEducation={openAddEducation}
            setOpenAddEducation={setOpenAddEducation}
            educationDetails={educationDetails}
            setEducationDetails={setEducationDetails}
            allBoards={allBoards}
            allColleges={allColleges}
            educationDetailsErrors={educationDetailsErrors}
            setEducationDetailsErrors={setEducationDetailsErrors}
            setEducationDetailsList={setEducationDetailsList}
            educationDetailsList={educationDetailsList}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            workExpDetails={workExpDetails}
            setWorkExpDetails={setWorkExpDetails}
            workExpDetailsErrors={workExpDetailsErrors}
            setWorkExpDetailsErrors={setWorkExpDetailsErrors}
            openAddWorkExp={openAddWorkExp}
            setOpenAddWorkExp={setOpenAddWorkExp}
            workExpDetailsList={workExpDetailsList}
            setWorkExpDetailsList={setWorkExpDetailsList}
            allCompanies={allCompanies}
            setAllCompanies={setAllCompanies}
            allJobType={allJobType}
            allSkills={allSkills}
            skillDetails={skillDetails}
            setSkillDetails={setSkillDetails}
            skillDetailsErrors={skillDetailsErrors}
            setSkillDetailsErrors={setSkillDetailsErrors}
            skillDetailsList={skillDetailsList}
            setSkillDetailsList={setSkillDetailsList}
            linkList={linkList}
            setLinkList={setLinkList}
            allSocialLinks={allSocialLinks}
            projectDetails={projectDetails}
            setProjectDetails={setProjectDetails}
            projectDetailsErrors={projectDetailsErrors}
            setProjectDetailsErrors={setProjectDetailsErrors}
            projectList={projectList}
            setProjectList={setProjectList}
            educationLoader={educationLoader}
            expLoader={expLoader}
            skillsLoader={skillsLoader}
            projectLoader={projectLoader}
            linksLoader={linksLoader}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            setProfilePicChanged={setProfilePicChanged}
            profilePicChanged={profilePicChanged}
            openAddAchievements={openAddAchievements}
            setOpenAddAchievements={setOpenAddAchievements}
            openAddHobbies={openAddHobbies}
            setOpenAddHobbies={setOpenAddHobbies}
            openAddLanguages={openAddLanguages}
            setOpenAddLanguages={setOpenAddLanguages}
            openAddProjects={openAddProjects}
            setOpenAddProjects={setOpenAddProjects}
            extraData={extraData}
            setExtraData={setExtraData}
            getUserDetails={getUserDetails}
            fetchPrecentage={fetchPrecentage}
            varyPercentage={varyPercentage}
            prefFilled={prefFilled}
            educationDetailsFilled={educationDetailsFilled}
            skillsFilled={skillsFilled}
            mainRef={mainRef}
            executeScroll={executeScroll}
            instituteList={instituteList}
            openAddCertificate={openAddCertificate}
            setOpenAddCertificate={setOpenAddCertificate}
            basicDetailsFilled = {basicDetailsFilled}
            setBasicDetailsFilled = {setBasicDetailsFilled}
          />
        </Container>
       
      </div>
      </Layout>
    </>
  );
}

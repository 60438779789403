import { GET_COMPANY_USER_DETAILS } from "./action";

const CompanyUserDetails = (state = {}, action) => {
  switch (action.type) {
    case GET_COMPANY_USER_DETAILS:
      return { ...state, companyUserDetails: action.payload };
    default:
      return state;
  }
};

export default CompanyUserDetails;

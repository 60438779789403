import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor:"white",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function MultipleGmail({ usermail, setUsermail }) {
  const classes = useStyles();

  return (
    <div className={classes.root} id="box_value_sharer">
      <Autocomplete
        multiple
        // filterSelectedOptions
        id="tags-filled"
        options={usermail}
        freeSolo
        autoSelect // value={usermail}
        onChange={(e, val) => {
          setUsermail(val);
        }}
        renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)}
        renderInput={(params) => <TextField variant="standard" style={{ border: "none" }} {...params} placeholder="User" />}
      />
    </div>
  );
}

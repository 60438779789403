import {
  Box,
  Checkbox,
  createStyles,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../constants/constants";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import line from "../../assets/images/lineOr.png";
import { array } from "prop-types";
import Loader from "../../bundles/common/components/UI/Loader";

const useStyles = makeStyles((theme) =>
  createStyles({
    grid1: {
      marginTop: "20px",

      fontWeight: "bolder",
      paddingLeft: "7%",
      paddingTop: "4%",
    },
    grid2: {
      marginTop: "20px",
    },
    grid3: {
      fontWeight: "bolder",
      paddingLeft: "20%",
      paddingTop: "4%",
    },
    grid4: {
      marginTop: "10px",
    },
    redstar: {
      color: "red",
      fontWeight: "bolder",
    },
    head1: {
      alignItems: "left",
      textAlign: "left",
      margin: "20px 10px 20px 20px",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "left",
      width: "60%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    shadowBox: {
      backgroundColor: theme.palette.background.default,
      padding: "5px 0 5px 20px",
      borderRadius: "4px",
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[1],
      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
    },
    innerBox: {
      padding: "5px 0 5px 50px",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
  })
);
const DegreeCourses = (props) => {
  const {
    data,
    setData,
    width,
    loading,
    setLoading,
    degreeCoursesComing,
    setDegreeCoursesComing,
    allData,
    setAllData,
  } = props;

  let idModified = data.map((obj) => {
    return {
      id: obj.specialization_id ? obj.specialization_id : obj.id,
      name: obj.specialization_name ? obj.specialization_name : obj.name,
      degree_id: obj.degree_id,
      degree_name: obj.degree_name,
      education_type: obj.education_type,
      education_type_name: obj.education_type_name,
    };
  });
  var temp = {};
  let idModifiedDegree = [];

  data.map((obj) => {
    temp = {
      id: obj.degree_id,
      name: obj.degree_name,
      education_type: obj.education_type,
      education_type_name: obj.education_type_name,
    };

    idModifiedDegree.push(temp);
  });

  var obj = {};

  for (var i = 0, len = idModifiedDegree.length; i < len; i++)
    obj[idModifiedDegree[i]["id"]] = idModifiedDegree[i];

  idModifiedDegree = new Array();
  for (var key in obj) idModifiedDegree.push(obj[key]);
  const [courseArray, setCourseArray] = useState(idModified);
  const [degreeArray, setDegreeArray] = useState(idModifiedDegree);

  const classes = useStyles();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [degree, setDegree] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  // const [allData, setAllData] = useState({});

  const getDegreeCourse = () => {
    setLoading(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/education/job/get_specialization")
      .then((res) => {
        if (res.data?.success && res?.data.data) {
          const temp = {};
          setLoading(false);

          res.data.data.forEach((item) => {
            var tempSpzl = {
              ...item,
              isChecked: false,
              isActive: false,
              isValue: false,
            };
            if (temp[item.degree.name] === undefined) {
              if (item.specialization === null)
                temp[item.degree.name] = [
                  {
                    degree: item.degree,
                    specialization: {},
                    isChecked: false,
                    isValue: false,
                  },
                ];
              else {
                temp[item.degree.name] = [tempSpzl];
              }
            } else {
              if (item.specialization === null)
                temp[item.degree.name].push({
                  degree: item.degree,
                  specialization: {},
                  isChecked: false,
                  isValue: false,
                });
              else temp[item.degree.name].push(tempSpzl);
            }
          });
          //   setAllData(temp);

          // setAllData(temp);
          Object.values(temp).forEach((item) => {
            item.forEach((inner, index) => {
              data.map((item2) => {
                let specialization_id = item2.specialization_id
                  ? item2.specialization_id
                  : item2.id;
                if (inner.specialization.id === specialization_id) {
                  inner.isActive = true;
                  inner.isValue = true;
                  inner.isChecked = true;
                }
                if (index === 0) {
                  if (inner.degree.id === item2.degree_id) {
                    inner.isValue = true;
                    inner.isChecked = true;
                  }
                }
              });
            });
          });
          setAllData(temp);
          setDegreeCoursesComing(true);
        } else {
          alert.error("Error Occured");
          setLoading(false);
        }
      })
      .catch((err) => {
        alert.error("Error");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (degreeCoursesComing === false) getDegreeCourse();
  }, []);

  const handleSelectAllSpecialization1 = (e, item, index) => {
    // var arr = allData;
    var obj = allData;
    var deg_arr = degreeArray;
    var cor_arr = courseArray;
    obj[item].forEach((it) => {
      it.isActive = e.target.checked;

      setAllData({ ...obj });

      if (it.isActive === true) {
        var temp2 = [];

        degreeArray.map((a, o) => {
          temp2.push(a.id);
        });

        if (temp2.includes(it.degree.id) === false) {
          deg_arr.push(it.degree);
        }
      } else {
        deg_arr = deg_arr.filter((item) => item.id !== it.degree.id);

        // state.OffCampus.degree.splice(index, 1);
      }

      if (it.isActive === true) {
        cor_arr.push(it.specialization);
      } else {
        cor_arr = cor_arr.filter((item) => item.degree_id !== it.degree.id);
      }

      setDegreeArray(deg_arr);
      setCourseArray(cor_arr);
    });

    obj[item][0].isChecked = e.target.checked;

    setAllData({ ...obj });
  };

  const handleSelectSingleSpecialization1 = (e, item, index, indexDegree) => {
    // var arr = allData;
    var arr = allData;
    var deg_arr = degreeArray;
    var cor_arr = courseArray;
    arr[item][index].isActive = e.target.checked;

    setAllData({ ...arr });
    var array1 = [];
    cor_arr.map((item) => {
      array1.push(item.degree_id);
    });
    if (arr[item][index].isActive === true) {
      var temp2 = [];

      degreeArray.map((a, o) => {
        temp2.push(a.id);
      });

      if (temp2.includes(arr[item][index].degree.id) === false) {
        deg_arr.push(arr[item][index].degree);
      }
    } else {
      if (!checkForDuplicates(array1)) {
        var indexing;

        degreeArray.map((u, iin) => {
          if (u.id === arr[item][index].degree.id) {
            indexing = iin;
          }
        });
        deg_arr.splice(indexing, 1);
      }
    }
    var indexx;
    if (arr[item][index].isActive === true)
      cor_arr.push(arr[item][index].specialization);
    else {
      courseArray.map((u, iin) => {
        if (u.id === arr[item][index].specialization.id) {
          indexx = iin;
        }
      });

      cor_arr.splice(indexx, 1);
    }

    setDegreeArray(deg_arr);
    setCourseArray(cor_arr);

    // cor_arr.map((u, iin) => {
    //   arr[item].map((itemr) => {
    //     if (u.degree_id !== itemr.degree.id) arr[item][0].isValue = false;
    //     else arr[item][0].isValue = true;
    //   });
    // });
    setAllData({ ...arr });

    if (checkForDuplicates(array1)) {
      arr[item][0].isValue = true;
      setAllData({ ...arr });
    } else {
      arr[item][0].isValue = false;
      setAllData({ ...arr });
    }
  };

  function checkForDuplicates(array) {
    let valuesAlreadySeen = [];

    for (let i = 0; i < array.length; i++) {
      let value = array[i];
      if (valuesAlreadySeen.indexOf(value) !== -1) {
        return true;
      }
      valuesAlreadySeen.push(value);
    }
    return false;
  }

  useEffect(() => {
    var cor_arr = courseArray;
    setData(cor_arr);
  }, [courseArray]);

  return (
    <>
      {loading === false ? (
        allData && (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormLabel className={classes.grid1} component="legend">
                Courses
              </FormLabel>
            </Grid>
            <Grid item xs={9} className={classes.grid2}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      //   var len = Object.keys(allData);
                      var len = Object.keys(allData);
                      var obj = allData;
                      var deg_arr = degreeArray;
                      var cor_arr = courseArray;

                      len.forEach((item) => {
                        obj[item][0].isValue = e.target.checked;

                        obj[item].forEach((n) => {
                          n.isActive = e.target.checked;

                          // setState({
                          //   ...state,
                          //   OffCampus: {
                          //     ...state.OffCampus,
                          //     allData: { ...allData },
                          //   },
                          // });
                          setAllData({ ...obj });

                          if (n.isActive === true) {
                            var temp2 = [];

                            degreeArray.map((a, o) => {
                              temp2.push(a.id);
                            });

                            if (temp2.includes(n.degree.id) === false) {
                              deg_arr.push(n.degree);
                            }
                          } else
                            while (degreeArray.length > 0) {
                              deg_arr.pop();
                            }

                          if (n.isActive === true)
                            cor_arr.push(n.specialization);
                          else
                            while (courseArray.length > 0) {
                              cor_arr.pop();
                            }

                          setDegreeArray(deg_arr);
                          setCourseArray(cor_arr);
                        });
                      });
                    }}
                    // checked={state.offCampus.value === true ? true : false}
                    name="gilad"
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Select All Courses"
              />

              <img src={line} width="90%" />
              <br />
              <br />

              {Object.keys(allData).map((item, index1) => {
                return (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              handleSelectAllSpecialization1(e, item, index1);
                              var obj = allData;
                              obj[item][0].isValue = e.target.checked;
                              setAllData({ ...obj });
                            }}
                            checked={allData[item][0].isValue}
                            name="gilad"
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={item}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <Box className={classes.innerBox}>
                          {allData[item].map((i, index) => (
                            <Fragment key={index}>
                              {i.specialization ? (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={i.isActive}
                                      onChange={(e) => {
                                        handleSelectSingleSpecialization1(
                                          e,
                                          item,
                                          index,
                                          index1
                                        );
                                      }}
                                      name="gilad"
                                      classes={{
                                        root: classes.radio,
                                        checked: classes.checked,
                                      }}
                                    />
                                  }
                                  label={i.specialization.name}
                                />
                              ) : null}
                              <br />
                            </Fragment>
                          ))}
                        </Box>
                      </>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
        )
      ) : (
        <Box>
          <Loader />
        </Box>
      )}
    </>
  );
};

export default DegreeCourses;

import { Hidden } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import LoginDialog from "../../../components/common/Dialogs/LoginDialog/LoginDialog";
import Footer from "../../../components/public/FooterNew/Footer";
import FooterDomain from "../../../components/public/FooterNew/Footer/FooterDomain";
import MobileTab from "../../../components/public/LogIn/Header/MobileTab";
import MobileTabDomain from "../../../components/public/LogIn/Header/MobileTabDomain";
import StudentHeader from "../../../components/public/LogIn/Header/StudentHeader";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";
import { BackendBaseApi, emailRegex, FACEBOOK_APP_ID, FACEBOOK_APP_SECRET } from "../../../constants/constants";
import { getCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import setNewCookie, { getCookie } from "../../../utils/common/Cookies";
import { checkLinkingHomePage, executeScrollCommon } from "../../../utils/utility";
import AppDownloadDialog from "../../student/StudentDashboard/AppDownloadDialog";

function StudentLogin() {
  const history = useHistory();
  const location = useLocation();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  const [emailUser, setEmailUser] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [fbAccessToken, setfbAccessToken] = useState("");
  const [ldAccessToken, setldAccessToken] = useState("");
  const [googleAccessToken, setgoogleAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const firstRender_1 = useRef(true);
  const firstRender_2 = useRef(true);
  const firstRender_3 = useRef(true);
  const alert = useAlert();
  const params = useParams();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [buttonClickedLogin, setButtonClickedLogin] = useState(false);

  const responseFacebook = (response) => {
    setfbAccessToken(response.accessToken);
  };

  // useEffect(() => {
  //   if (localStorage.getItem("user_type")) {
  //     console.log("inside");
  //     var link = checkLinkingHomePage(localStorage.getItem("user_type"));
  //     console.log(link);

  //     history.push("/company/dashboard");
  //   }
  // }, []);

  useEffect(() => {
    axios.get(
        BackendBaseApi.PRAVESH + "api/shared/user/?user_type=1",
        {
          headers: { Authorization: "Token " + localStorage.getItem("gw_token") },
          withCredentials: true
        }
    ).then((res)=>{

      if(res?.data?.success){
        if(res?.data?.data?.email){
          localStorage.setItem("email", res?.data?.data?.email);
          localStorage.setItem("email_verified", res?.data?.data?.email_verified);
          localStorage.setItem("first_name", res?.data?.data?.first_name);
          localStorage.setItem('full_name', res?.data?.data?.full_name);
          localStorage.setItem("gw_token", res?.data?.data?.gw_token);
          localStorage.setItem("user_id", res?.data?.data?.id);
          localStorage.setItem("user_type_id", "1");
          localStorage.setItem('user_type', 'Student');

          if (localStorage.getItem("gw_token") && localStorage.getItem("user_type") && localStorage.getItem("user_type") !== "Admin") {
            checkUser(localStorage.getItem("gw_token"));
          }
        }
      }
    }).catch((err)=>{
      console.log(err);
    })
    if (localStorage.getItem("gw_token") && localStorage.getItem("user_type") && localStorage.getItem("user_type") !== "Admin") {
      checkUser(localStorage.getItem("gw_token"));
    }
  }, []);

  const checkUserType = () => {
    if (params && params?.token) {
      if (params?.token === "student") return "Student";
      if (params?.token === "college") return "College";
      if (params?.token === "employer" || params?.token === "company") return "Company";
    } else if (!params.token && params.token === undefined) return "Student";
    else return "Student";
  };

  const [open, setOpen] = React.useState(location.pathname === "/" || location.pathname === "/login" || location.pathname === "/login/" ? true : false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //access short life token from google popup response
  const responseGoogle = (response) => {
    setgoogleAccessToken(response.accessToken);
  };

  const checkTab = () => {
    switch (value) {
      case 0:
        return "Student";
      case 1:
        return "College";
      case 2:
        return "Company";
    }
  };

  const loginUser = (user_type) => {
    setLoading(true);
    let token = "";
    axios
      .post(
        BackendBaseApi.PRAVESH + "api/login_new/",
        {
          email: emailUser,
          password: passwordUser,
          user_type: user_type,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          token = res.data.data.token;

          setAuthToken(token);
          checkUser(token);
        } else {
          alert.error(res.data.error);
          setEmailErrorMessage("Enter a valid Email Address");
          setPasswordErrorMessage("Enter a valid Password");

          setLoading(false);
          // return;
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 400) {
          alert.error(error.response.data.error);
          setEmailErrorMessage("Enter a valid Email Address");
          setPasswordErrorMessage("Enter a valid Password");
        } else {
          setEmailErrorMessage("");
          setPasswordErrorMessage("");
        }
        setButtonClickedLogin(false);
      });
  };

  const checkUser = (token) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          alert.success("User Already Create!! Please Complete Your Profile");
          if (!res.data.email_verified) {
            history.push({
              pathname: "/confirm-email",
              email: res.data.email,
              user_id: res.data.user_type[0].user_id,
            });
          }
        } else {
          if (res.data.user_type[0].type === 10) {
            localStorage.clear();
            alert.error("You can not Login with Admin account here");
          } else {
            if (res.data.email_verified) {
              if (res.data.user_type[0].type !== 10) {
                localStorage.setItem("user_type", res.data.user_type[0].main_user);
              }
              localStorage.setItem("gw_token", token);
              localStorage.setItem("user_type_id", res.data.user_type[0].type);
              localStorage.setItem("user_id", res.data.user_type[0].user_id);
              localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
              localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);

              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem("user_type", res.data.user_type[0].main_user);

                //student with verified email, go to home
                localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
                localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);
                localStorage.setItem("signupName", `${res.data?.first_name} ${res.data?.last_name}`);

                localStorage.setItem("user_id", res.data.id);

                if (location.flag == "102") {
                  history.push("/student/open");
                } else {
                  if (res.data.is_pref_filled && res.data.mobile) {
                    // if (location.search && location.search.includes("?source=")) history.push(`${location.search.replace("?source=", "")}`);
                    // else
                    history.push("/student/dashboard");
                  } else {
                    // if (location.search && location.search.includes("?source=")) {
                    //   if (res.data.is_student_resume_uploaded) history.push(`${location.search.replace("?source=", "")}`);
                    //   else history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                    // } else {
                    //   history.push({
                    //     pathname: "/student/complete-profile",
                    //     flag: 12367,
                    //   });
                    // }
                    history.push({
                      // pathname: "/student/complete-profile",
                      pathname: "/candidate/basic-details",
                      flag: 12367,
                    });
                  }
                }
              } else if (res.data.user_type[0].type === 7) {
                dispatch(getCreditInfo(setLoader));

                if (location.flag === 28071998) history.push("/college/post-job");
                else {
                  if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                  else history.push("/college/dashboard");
                }
              } else if (res.data.user_type[0].type === 8) {
                dispatch(getCreditInfo(setLoader));
                if (location.flag === 28071998) history.push("/college/post-job");
                else {
                  if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                  else history.push("/college/dashboard");
                }
              } else {
                //company user with verified email, check if admin approved

                if (res.data.is_pref_filled) {
                  if (!res.data.company_details.admin_approved) {
                    localStorage.setItem(
                      "user",
                      JSON.stringify({
                        email: res.data.email,
                        first_name: res.data.first_name,
                        last_name: res.data.last_name,
                        id: res.data.id,
                        profile_picture: null,
                      })
                    );

                    history.push({
                      pathname: "/company/admin-approve",
                      flag: 19087,

                      company: res.data.company_details.company_name,
                      admin_email: res.data.company_details.admin_email,
                      admin_name: res.data.company_details.admin_name,
                      is_admin: res.data.is_company_admin,
                    });
                  } else {
                    if (res?.data?.company_details !== null) localStorage.setItem("company", JSON.stringify(res.data.company_details));

                    dispatch(getCreditInfo(setLoader));
                    if (location.flag === 28071998) history.push("/company/post-job");
                    else {
                      if (res.data.user_type[0].type === 5) {
                        localStorage.setItem("comp_pref_filled", res.data.comp_pref_filled);
                        if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                        else history.push({ pathname: "/company/dashboard", comp_pref_filled: res.data.comp_pref_filled });
                      } else {
                        if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                        else history.push({ pathname: "/company/dashboard" });
                      }
                    }
                  }
                } else {
                  localStorage.setItem("mobile", res.data.mobile);
                  if (location.search && location.search.includes("redirect")) history.push(location.search.replace("?redirect=", ""));
                  else history.push("/company/complete-profile");
                }
              }
            } else {
              //check if its student or company
              if (res.data.user_type[0].type === 1) {
                localStorage.setItem("gw_token", token);
                localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
                localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);
                localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);

                localStorage.setItem("user_type", res.data.user_type[0].main_user);
                localStorage.setItem("user_id", res.data.id);
                localStorage.setItem("signupName", `${res.data?.first_name} ${res.data?.last_name}`);

                localStorage.setItem("user_type_id", res.data.user_type[0].type);

                //student without verified email, go to home

                if (res.data.is_pref_filled && res.data.mobile) {
                  // if (location.search && location.search.includes("?source=")) history.push(`${location.search.replace("?source=", "")}`);
                  // else
                  history.push("/student/dashboard");
                } else {
                  // if (location.search && location.search.includes("?source=")) {
                  //   if (res.data.is_student_resume_uploaded) history.push(`${location.search.replace("?source=", "")}`);
                  //   else history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                  // } else {
                  //   history.push({
                  //     pathname: "/student/complete-profile",
                  //     flag: 12367,
                  //   });
                  // }
                  history.push({
                    // pathname: "/student/complete-profile",
                    pathname: "/candidate/basic-details",
                    flag: 12367,
                  });
                }
              } else {
                //company user without verified email
                history.push({
                  pathname: "/confirm-email",
                  email: res.data.email,
                  user_id: res.data.user_type[0].user_id,
                });
              }
            }
          }
        }

        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        throw err;
      });
  };

  useEffect(() => {
    let accessToken = "";
    if (firstRender_1.current) {
      firstRender_1.current = false;
      return;
    }

    //Facebook Auth API calls
    const fbGraphAPICalls = async () => {
      axios
        .get(
          `https://graph.facebook.com/v6.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${FACEBOOK_APP_ID}&client_secret=${FACEBOOK_APP_SECRET}&fb_exchange_token=${fbAccessToken}`
        )
        .then((res) => {
          accessToken = res.data.access_token;
        })
        .then(() => {
          axios
            .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
              headers: {
                "Content-Type": "application/json",
                Connection: "keep-alive",
              },
              withCredentials: true,
              provider: "facebook",
              access_token: accessToken,
              user_type: checkUserType(),
            },
            )
            .then((res) => {
              if (res.data.success) {
                let token = "";
                token = res.data.data.token;
                localStorage.setItem("gw_token", token);
                alert.success(res.data.data.message);
                setLoading(true);
                checkUser(token);
              } else {
                alert.error(res.data.error);
              }
            })
            .catch((err) => {
              alert.error("Error occured!");
            });
        })
        .catch((err) => {});
    };

    fbGraphAPICalls();
  }, [fbAccessToken]);

  useEffect(() => {
    let token = "";
    if (firstRender_2.current) {
      firstRender_2.current = false;
      return;
    }
    if (googleAccessToken) {
      axios
        .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
          },
          withCredentials: true,
          provider: "google-oauth2",
          access_token: googleAccessToken,
          user_type: checkUserType(),
        },
        )
        .then((res) => {
          if (res.data.success) {
            token = res.data.data.token;
            localStorage.setItem("gw_token", token);
            alert.success(res.data.data.message);
            setLoading(true);
            checkUser(token);
          } else {
            alert.error(res.data.error);
          }
        })
        .catch((err) => {
          alert.error("Error");
        });
    }
  }, [googleAccessToken]);
  const mainRef = useRef(null);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   executeScrollCommon(mainRef);
  // }, [params?.token]);

  // useEffect(() => {
  //   setOpen(checkUserType() === "Student" ? true : false);
  // }, [params?.token]);

  return (
    <>
      {domain?.domain ? <DomainNavbar /> : <Navbar />}
      <Hidden smDown>
        {" "}
        <div ref={mainRef}>
          <StudentHeader
            type={checkUserType()}
            email={emailUser}
            setEmail={setEmailUser}
            setPassword={setPasswordUser}
            password={passwordUser}
            loginUser={loginUser}
            emailErrorMessage={emailErrorMessage}
            passwordErrorMessage={passwordErrorMessage}
            setEmailErrorMessage={setEmailErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            responseGoogle={responseGoogle}
            responseFacebook={responseFacebook}
            loading={loading}
            showGrid={true}
            buttonClickedLogin={buttonClickedLogin}
            setButtonClickedLogin={setButtonClickedLogin}
            checkUser={checkUser}
          />
        </div>
      </Hidden>
      <Hidden smUp>
        {" "}
        {domain?.domain ? (
          <MobileTabDomain
            type={checkUserType()}
            email={emailUser}
            setEmail={setEmailUser}
            setPassword={setPasswordUser}
            password={passwordUser}
            loginUser={loginUser}
            emailErrorMessage={emailErrorMessage}
            passwordErrorMessage={passwordErrorMessage}
            setEmailErrorMessage={setEmailErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            responseGoogle={responseGoogle}
            responseFacebook={responseFacebook}
            loading={loading}
            buttonClickedLogin={buttonClickedLogin}
            setButtonClickedLogin={setButtonClickedLogin}
            checkUser={checkUser}
          />
        ) : (
          <MobileTab
            type={checkUserType()}
            email={emailUser}
            setEmail={setEmailUser}
            setPassword={setPasswordUser}
            password={passwordUser}
            loginUser={loginUser}
            emailErrorMessage={emailErrorMessage}
            passwordErrorMessage={passwordErrorMessage}
            setEmailErrorMessage={setEmailErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            responseGoogle={responseGoogle}
            responseFacebook={responseFacebook}
            loading={loading}
            buttonClickedLogin={buttonClickedLogin}
            setButtonClickedLogin={setButtonClickedLogin}
            checkUser={checkUser}
          />
        )}
      </Hidden>
      {!domain?.domain ? <Footer /> : <FooterDomain />}

      {/* <AppDownloadDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} /> */}
      {!domain?.domain && (
        <>
          <Hidden smDown>
            <LoginDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
          </Hidden>

          <Hidden smUp>
            <LoginDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} forMobile={true} />
          </Hidden>
        </>
      )}
    </>
  );
}

export default StudentLogin;

import React from "react";
import { Paper, Typography, Button, Grid } from "@material-ui/core";
import p1 from "../../../assets/svg/plan/p1.svg";
import p2 from "../../../assets/svg/plan/p2.svg";
import p3 from "../../../assets/svg/plan/p3.svg";
import p4 from "../../../assets/svg/plan/p4.svg";
import { ContactlessTwoTone } from "@material-ui/icons";
import AllPlanDialog from "../../Dialog/AllPlanDialog";

function CurrentPlanDetails() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Paper
        style={{
          padding: "20px",
          borderRadius: "20px",
          //   boxShadow: "0px, 0px rgba(0, 0, 0, 0.09)",
          boxShadow: "none",
          border: ".5px solid #6C757D",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="h3">
            Current Plan Details
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: "30px", boxShadow: "none" }}
            onClick={handleClickOpen}
          >
            Change Plan
          </Button>
        </div>
        {data.map((item) => (
          <Grid container spacing={2}>
            <Grid item>
              <img src={item.icon} height="15px" />
            </Grid>
            <Grid item md={3}>
              <div style={{ display: "flex" }}>
                <Typography variant="body2">{item.title}</Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <Typography variant="body2">{item.res}</Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
      <AllPlanDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </>
  );
}

export default CurrentPlanDetails;

const data = [
  {
    icon: p1,
    title: "plan Name",
    res: "Startup",
  },
  {
    icon: p2,
    title: "Acount Status",
    res: "Active",
  },
  {
    icon: p3,
    title: "Talent Unlocks",
    res: "100/120",
  },
  {
    icon: p4,
    title: "Job Posting",
    res: "Unlimited",
  },
];

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Chip,
  createStyles,
  makeStyles,
  MuiThemeProvider,
  TextareaAutosize,
} from "@material-ui/core";
import next from "../../../../assets/next.png";
import cancel from "../../../../assets/cancel.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import theme from "../../../common/JobPostNew/FormComponents/MuiOutlined";
import CssTextField from "../../../common/JobPostNew/FormComponents/CssTextField";
import { BackendBaseApi } from "../../../../constants/constants";
import Axios from "axios";

export default function FormDialog(props) {
  const {
    open,
    handleClose,
    handleClickOpen,
    moveNextArray,
    feedbackList,
    setFeedbackList,
    currentFeedback,
    setCurrentFeedback,
    setFeedbackSentState,
    feedbackSentState,
    feedbackTyped,
    setFeedbackTyped,
    currentData,
    selectedApplicants,
    setSelectedApplicants,
    setMoveButtonClicked,
    showWholeApplicantList,
    setRejectButtonClicked,
    rejectButtonClicked,
    feedbackListNew,
  } = props;
  const [currentSelectedNextStep, setCurrentSelectedNextStep] = useState({});
  const classes = useStyles();
  const [feedbackSelectedCheck, setFeedbackSelectedCheck] = useState(false);

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,
            // background:
            //   "linear-gradient(15deg, #d0d5dd, #d5d9e1, #d9dee5, #dee2e9, #e2e7ed, #e7ebf2, #ebf0f6, #f0f4fa)",
          },
        }}
        open={open}
        onClose={() => {
          handleClose();
          setCurrentSelectedNextStep();
          setFeedbackSelectedCheck(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reject</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            Fill the feedback and reject the{" "}
            {showWholeApplicantList ? " selected applicants" : " applicant"}
          </DialogContentText>
          {showWholeApplicantList &&
            selectedApplicants &&
            selectedApplicants.length > 0 &&
            selectedApplicants.map((item) => (
              <Chip
                label={<span style={{ fontSize: 12 }}>{item.name}</span>}
                className={classes.root1}
                style={{ marginRight: "1%", marginBottom: "1%" }}
              />
            ))}
          <>
            <p
              style={{
                fontSize: 15,
                fontWeight: "bold",
                marginTop: 30,
                textAlign: "center",
              }}
            >
              Enter the feedback for Selected Applicant(s)?{" "}
            </p>

            <MuiThemeProvider theme={theme} style={{ marginTop: 20 }}>
              <Autocomplete
                value={currentFeedback}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCurrentFeedback(newValue);
                  } else {
                    setCurrentFeedback();
                  }
                }}
                id="rolenew"
                options={feedbackListNew}
                getOptionLabel={(option) => option.feedback_name}
                style={{
                  width: "100%",
                  background: "white",
                  borderRadius: 30,
                }}
                renderOption={(option) => {
                  return (
                    <span
                      style={{ fontSize: 13 }}
                    >{`${option.feedback_name}`}</span>
                  );
                }}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label={<span style={{ fontSize: 13 }}>Select Reason</span>}
                    variant="outlined"
                  />
                )}
              />
            </MuiThemeProvider>

            <br />
            {currentFeedback?.feedback_id === "others" && (
              <TextareaAutosize
                rowsMin={3}
                rowsMax={5}
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  borderColor: "#d2cdcd",
                  padding: 12,
                  outline: "none",
                }}
                aria-label="maximum height"
                placeholder="Enter your Feedback"
                onChange={(e) => {
                  setFeedbackTyped(e.target.value);
                }}
                value={feedbackTyped}
              />
            )}
          </>
        </DialogContent>

        <DialogActions>
          {currentFeedback &&
            currentFeedback?.feedback_id === "others" &&
            feedbackTyped && (
              <Button
                variant="contained"
                className={classes.rejectButton}
                startIcon={<img src={cancel} alt="" height="20px" />}
                onClick={() => {
                  if (!showWholeApplicantList)
                    setSelectedApplicants([
                      {
                        id: currentData.applicant_id,
                        name:
                          currentData.user_details.first_name +
                          " " +
                          currentData.user_details.last_name,
                        user_id: currentData.user_details.user_id,
                        first_name: currentData.user_details.first_name,
                        last_name: currentData.user_details.last_name,
                        job_id: currentData.job_id,
                      },
                    ]);
                  setMoveButtonClicked(true);
                  handleClose();

                  setFeedbackSelectedCheck(false);
                }}
              >
                <span style={{ fontSize: 13 }}>Reject</span>
              </Button>
            )}

          {currentFeedback && currentFeedback?.feedback_id !== "others" && (
            <Button
              variant="contained"
              className={classes.rejectButton}
              startIcon={<img src={cancel} alt="" height="20px" />}
              onClick={() => {
                if (!showWholeApplicantList)
                  setSelectedApplicants([
                    {
                      id: currentData.applicant_id,
                      name:
                        currentData.user_details.first_name +
                        " " +
                        currentData.user_details.last_name,
                      user_id: currentData.user_details.user_id,
                      first_name: currentData.user_details.first_name,
                      last_name: currentData.user_details.last_name,
                      job_id: currentData.job_id,
                    },
                  ]);
                setFeedbackSentState({
                  status_id: 14,
                  round_id: null,
                  name: "Rejected",
                });

                setMoveButtonClicked(true);
                setFeedbackSelectedCheck(false);

                handleClose();
              }}
            >
              <span style={{ fontSize: 13 }}>Reject</span>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    mainButton: {
      // height: "36px",
      borderRadius: 30,

      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      "&:hover": {
        backgroundColor: "#84cbff",
      },
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      fontSize: 13,
    },
    rejectButton: {
      backgroundColor: "rgb(249 188 202)",
      border: "1px solid rgb(188 12 30 / 1)",
      height: "36px",
      borderRadius: 30,
      color: "rgb(188 12 30 / 1)",
      "&:hover": {
        backgroundColor: "#ff86a2",
      },
    },
    nextButton: {
      backgroundColor: "#76e5ac",
      border: "1px solid #145f38",
      height: "36px",
      borderRadius: 30,
      color: "#145f38",
      "&:hover": {
        backgroundColor: "#5dd698",
      },
    },
  })
);

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { Provider } from "react-redux";
import RootReducer from "./RootReducer/RootReducer";
import { BrowserRouter } from "react-router-dom";

let md = applyMiddleware(thunk, logger);
const production = process.env.REACT_APP_ENVIRONMENT === "production" ? true : false;
if (production) {
  md = applyMiddleware(thunk);
}
const store = createStore(RootReducer, md);

export type TReduxStore = ReturnType<typeof RootReducer>;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

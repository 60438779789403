import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { createStyles, Grid, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function CreateNewCompany(props) {
  const {
    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    buttonClicked,
    setButtonClicked,
    currentState,
    setCurrentState,
    stateList,
    cityList,
    currentCity,
    setCurrentCity,
  } = props;
  const classes = useStyles();

  return (
    <div>
      <TextField
        error={errorMessages.companyNameTyped !== "" ? true : false}
        key="jhvjh"
        id="standard-error-helper-text-1"
        variant="outlined"
        type="text"
        label={<span>Company Name</span>}
        helperText={
          <span style={errorMessages.companyNameTyped !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.companyNameTyped}</span>
        }
        value={userDetails.CompanyTypedName}
        onChange={(e) => {
          setUserDetails({ ...userDetails, CompanyTypedName: e.target.value });
          setErrorMessages({ ...errorMessages, companyNameTyped: "" });
          setButtonClicked(false);
        }}
        InputProps={{
          style: { fontSize: 13 },
        }}
        InputLabelProps={{
          style: { backgroundColor: "#fff" },
        }}
        className={classes.fieldStyling}
      />
      <Grid container spaciing={3}>
        <Grid item md={6}>
          <Autocomplete
            value={currentState}
            onChange={(event, newValue) => {
              if (newValue) {
                setUserDetails({ ...userDetails, State: newValue.state_id });
                setCurrentState(newValue);
              } else {
                setUserDetails({ ...userDetails, State: null });

                setCurrentState();
              }
              setErrorMessages({ ...errorMessages, state: "" });

              setButtonClicked(false);
            }}
            id="role345"
            options={stateList}
            getOptionLabel={(option) => option.state}
            renderOption={(option) => {
              return <span style={{ fontSize: 13 }}>{`${option.state}`}</span>;
            }}
            className={classes.fieldStyling1}
            renderInput={(params) => {
              params.inputProps.className = classes.comboOptions;
              return (
                <TextField
                  {...params}
                  label={<span>Select State</span>}
                  variant="outlined"
                  error={errorMessages.state !== "" ? true : false}
                  helperText={<span style={errorMessages.state !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.state}</span>}
                />
              );
            }}
          />
        </Grid>

        <Grid item md={6}>
          {userDetails.State && (
            <Autocomplete
              value={currentCity}
              onChange={(event, newValue) => {
                if (newValue) {
                  setUserDetails({ ...userDetails, City: newValue.city_id });
                  setCurrentCity(newValue);
                } else {
                  setUserDetails({ ...userDetails, City: null });

                  setCurrentCity();
                }
                setErrorMessages({ ...errorMessages, city: "" });

                setButtonClicked(false);
              }}
              id="role345"
              options={cityList}
              getOptionLabel={(option) => option.city}
              renderOption={(option) => {
                return <span style={{ fontSize: 13 }}>{`${option.city}`}</span>;
              }}
              className={classes.fieldStyling}
              renderInput={(params) => {
                params.inputProps.className = classes.comboOptions;
                return (
                  <TextField
                    {...params}
                    label={<span>Select City</span>}
                    variant="outlined"
                    error={errorMessages.city !== "" ? true : false}
                    helperText={<span style={errorMessages.city !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.city}</span>}
                  />
                );
              }}
            />
          )}
        </Grid>
      </Grid>
      <TextField
        error={errorMessages.companyWebsite !== "" ? true : false}
        key="jhvjh"
        id="standard-error-helper-text-1"
        variant="outlined"
        type="text"
        label={<span>Company Website</span>}
        helperText={<span style={errorMessages.companyWebsite !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.companyWebsite}</span>}
        value={userDetails.CompanyWebsite}
        onChange={(e) => {
          setUserDetails({ ...userDetails, CompanyWebsite: e.target.value });
          setErrorMessages({ ...errorMessages, companyWebsite: "" });
          setButtonClicked(false);
        }}
        InputProps={{
          style: { fontSize: 13 },
        }}
        InputLabelProps={{
          style: { backgroundColor: "#fff" },
        }}
        className={classes.fieldStyling}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      marginBottom: 20,
    },
    fieldStyling1: {
      width: "95%",
      marginBottom: 20,
    },
  })
);

import React from "react";
import { Paper, Typography, Button, Grid } from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";

export default function BillingContact({ heading, dataDetails }) {
  const data = [
    {
      icon: <AccountCircleOutlinedIcon fontSize="small" />,
      title: "Acount Holder",
      res: dataDetails?.name ? dataDetails?.name : "NA",
    },
    {
      icon: <MailOutlineOutlinedIcon fontSize="small" />,
      title: "Email",
      res: dataDetails?.email ? dataDetails?.email : "NA",
    },
    {
      icon: <PhoneOutlinedIcon fontSize="small" />,
      title: "Phone",
      res: dataDetails?.phone ? dataDetails?.phone : "NA",
    },
  ];
  return (
    <>
      <Paper
        style={{
          padding: "20px",
          borderRadius: "20px",
          //   boxShadow: "0px, 0px rgba(0, 0, 0, 0.09)",
          boxShadow: "none",
          //border: ".5px solid #6C757D",
          boxShadow: "0px 0px 5px 2px #00000017",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h5" component="h3" style={{ fontWeight: "500" }}>
            {heading}
          </Typography>
        </div>
        {data.map((item) => (
          <Grid container spacing={2}>
            <Grid item md={1}>
              {item.icon}
            </Grid>
            <Grid item md={3}>
              <Typography variant="body2">{item.title}</Typography>
            </Grid>
            <Grid item md={5}>
              <Typography variant="body2">{item.res}</Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
    </>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, Toolbar, AppBar } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import moment from "moment";
import CommonButton from "../../../common/CommonButton/CommonButton";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    height: "100px",
    backgroundColor: "#fff",
  },
  apply: {
    display: "flex",
    flexDirection: "column",
  },
  grow: {
    flexGrow: 1,
  },
  btn: {
    boxShadow: "none",
    borderRadius: "30px",
    backgroundColor: "#007bff",
  },
}));

export default function JobMobile({ data, isUserApplied, setApplyClicked }) {
  const classes = useStyles();
  const history = useHistory();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.apply}>
          {localStorage.getItem("user_type") !== "Company" && localStorage.getItem("user_type") !== "College" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {data?.apply_end_date && (
                <>
                  <HourglassEmptyIcon style={{ color: "#6c757d", fontSize: "14px" }} /> &nbsp;
                  <Typography style={{ fontSize: "14px", marginTop: "5px" }}>Apply By {moment(data?.apply_end_date).format("DD MMM 'YY")}</Typography>
                </>
              )}
            </div>
          )}
          <br />
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ margin: "auto" }}>
                {((localStorage.getItem("gw_token") &&
                  localStorage.getItem("user_type_id") === "1" &&
                  (localStorage.getItem("user_id") || JSON.parse(localStorage.getItem("user_details")).id)) ||
                  !localStorage.getItem("gw_token")) && (
                  <>
                    {isUserApplied ? (
                      <CommonButton
                        disabled
                        variant="contained"
                        style={!domain?.domain ? { borderRadius: "30px" } : { backgroundColor: domain?.light_color_code, borderRadius: "30px" }}
                      >
                        Applied
                      </CommonButton>
                    ) : data?.job_status === "CLOSE" ? (
                      <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                        Job Already Closed
                      </Button>
                    ) : (
                      <>
                        <>
                          {data?.up_coming_state?.code === -1 && (
                            <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                              Job Deadline Passed!
                            </Button>
                          )}
                        </>
                        <>
                          {data?.up_coming_state?.code === 1 && (
                            <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                              Upcoming Job!{" "}
                            </Button>
                          )}
                        </>

                        <>
                          {data?.up_coming_state?.code === 0 && (
                            <Button
                              variant="contained"
                              style={
                                !domain?.domain
                                  ? { borderRadius: "30px", backgroundColor: "#007bff", padding: "6px 150px" }
                                  : { backgroundColor: domain?.color_code, borderRadius: "30px", padding: "6px 150px" }
                              }
                              onClick={() => {
                                if (!localStorage.getItem("gw_token")) {
                                  // localStorage.setItem("job-detail-state", data?.id);

                                  history.push({
                                    pathname: "/apply-for-the-job",
                                    search: `?source=/public/job/${data?.hiring_type === "OFF CAMPUS" ? "offcampus" : "oncampus"}/${data?.id}-${data?.job_title
                                      .replace(/\s+/g, "-")
                                      .toLowerCase()}`,

                                    flag: 39856,
                                    state: "job-detail-state",
                                  });
                                } else {
                                  if (localStorage.getItem("is_pref_filled") === "true" || localStorage.getItem("is_student_resume_uploaded") === "true") setApplyClicked(true);
                                  else
                                    history.push({
                                      pathname: "/student/complete-profile",
                                      search: `?source=/public/job/${data?.hiring_type === "OFF CAMPUS" ? "offcampus" : "oncampus"}/${data?.id}-${data?.job_title
                                        .replace(/\s+/g, "-")
                                        .toLowerCase()}`,
                                    });
                                }
                              }}
                            >
                              Apply Now{" "}
                            </Button>
                          )}
                        </>
                      </>
                    )}
                  </>
                )}

                {localStorage.getItem("gw_token") && localStorage.getItem("user_type") === "College" && (
                  <>
                    {data?.college_job_url ? (
                      <>
                        <br />

                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <CommonButton
                            variant="contained"
                            onClick={() => {
                              window.open(data?.college_job_url, "_blank");
                            }}
                            style={!domain?.domain ? { borderRadius: "30px", backgroundColor: "#007bff", outline: "none" } : { backgroundColor: domain?.color_code, borderRadius: "30px", outline: "none" }}
                          >
                            View Job
                          </CommonButton>
                        </div>
                      </>
                    ) : data?.job_status === "CLOSE" ? (
                      <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                        Job Already Closed
                      </Button>
                    ) : (
                      <>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <>
                            {data?.up_coming_state?.code === -1 && (
                              <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                                Job Deadline Passed!
                              </Button>
                            )}
                          </>
                          <>
                            {data?.up_coming_state?.code === 1 && (
                              <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                                Upcoming Job!{" "}
                              </Button>
                            )}
                          </>
                        </div>
                      </>
                    )}
                  </>
                )}

                {localStorage.getItem("user_type") === "Company" && (
                  <>
                    <>
                      {data?.job_status === "CLOSE" ? (
                        <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                          Job Already Closed
                        </Button>
                      ) : (
                        <>
                          <>
                            {data?.up_coming_state?.code === -1 && (
                              <Button variant="outlined" disabled style={{ borderRadius: "30px", backgroundColor: "white", border: "2px solid red", color: "red" }}>
                                Job Deadline Passed!
                              </Button>
                            )}
                          </>
                          <>
                            {data?.up_coming_state?.code === 1 && (
                              <Button variant="contained" disabled style={{ borderRadius: "30px", backgroundColor: "#28a745" }}>
                                Upcoming Job!{" "}
                              </Button>
                            )}
                          </>
                        </>
                      )}
                    </>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import LanguageIcon from "@material-ui/icons/Language";
import { IconButton, Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  overview: {
    // paddingLeft: "15px",
    // paddingRight: "15px",
    paddingBottom: "15px",
    paddingTop: "5px",
    borderRadius: "10px",
    // boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
  },
  icon: {
    color: "#007bff",
  },
  font: {
    fontFamily: "",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function SaveExperienceNew({ data }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "4px",
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontWeight: "600",
            }}
          >
            {data?.jobDesignation} (
            <span className={classes.icon}> {data?.jobType}</span> ){" "}
          </Typography>

          <Typography
            variant="body2"
            style={{
              color: "#6C757D",
              fontWeight: "600",
            }}
          >
            {data?.timePeriod}
          </Typography>
        </div>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
            fontWeight: "600",
            color: "#6C757D",
          }}
        >
          {" "}
          {data?.company}
        </Typography>
        {/* <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
          }}
        >
          Gurgaon, Haryana
        </Typography> */}
        {/* {data?.jobType && (
          <Typography
            variant="body2"
            style={{
              marginBottom: "4px",
              fontWeight: "bold",
            }}
          >
            {" "}
            <span className={classes.icon}> ({data?.jobType})</span>{" "}
          </Typography>
        )} */}

        <Typography
          variant="body2"
          style={{
            fontWeight: "400",
            color: "#6C757D",
          }}
        >
          {data?.jobDescription}
        </Typography>
      </div>
    </>
  );
}

import moment from 'moment';

const dateDifference = (start, end) => {
    let startDate = moment(start);
    let endDate = moment(end);

    if(startDate.isValid() && endDate.isValid()) {
        var years = endDate.diff(startDate, 'years');
        var months = endDate.diff(startDate, 'months') - (years * 12);
        startDate.add(years, 'years').add(months, 'months');
        var days = endDate.diff(startDate, 'days')
        var str = ''
        if(years > 0) str += `${years} years `
        if(months > 0) str += `${months} months `
        if(days > 0) str += `${days} days `
        return str;
    }
    else return '';
}

export default dateDifference;
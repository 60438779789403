import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AllComment from "../../AllComment";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import AddCommentDialog from "../../../Dialog/AddCommentDialog";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  btn: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    fontWeight: "500",
    height: "40px",
  },
}));

export default function CommentSectionNew({
  commentsData,
  loadingComments,
  commentsError,
  currentApplicant,
  getComments,
  isPublic,
  // open,
  // setOpen
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.overview}>
        {loadingComments ? (
          <div
            style={{ display: "flex", justifyContent: "center", margin: 50 }}
          >
            <CircularProgress size={40} />
          </div>
        ) : (
          <>
            <AllComment
              data={commentsData}
              currentApplicant={currentApplicant}
              isDetail={true}
            />
            <Grid container spacing={4} style={{ marginTop: "10px" }}>
              <Grid item sm={6} style={{ display: "grid" }}>
                {!isPublic && (
                  <Button
                    className={classes.btn}
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    Add Comment
                  </Button>
                )}
                <AddCommentDialog
                  open={open}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  selectedRow={{
                    details: [
                      {
                        id: currentApplicant?.applicantId,
                        name: currentApplicant?.applicantName,
                      },
                    ],
                    selectedIds: [currentApplicant?.applicantId],
                  }}
                  setSelectedRow={null}
                  activeStatusObject={{
                    status_id: currentApplicant?.status_id,
                    heading: currentApplicant?.status_name,
                  }}
                  getComments={getComments}
                  fromStudentDetail={true}
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
}

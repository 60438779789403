import React, { useEffect, useState } from "react";
import Ratings from "../UI/Ratings";
import Skills from "../UI/Skills";

const SkillsForm = ({ type, newSkill, setNewSkill }) => {
  const [newSkillDetails, setNewSkillDetails] = useState({
    skill_name: "",
    skill_id: null,
  });

  const [newRatingDetails, setNewRatingDetails] = useState({
    rating_name: "",
    rating_value: null,
  });

  // useEffect(() => {}, []);

  useEffect(() => {
    if (newSkillDetails && newRatingDetails) {
      setNewSkill({
        skill_name: newSkillDetails.skill_name,
        skill_id: newSkillDetails.skill_id,
        rating_name: newRatingDetails.rating_name,
        rating_value: newRatingDetails.rating_value,
        type: type == "Technical Skills" ? 2 : 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSkillDetails, newRatingDetails]);

  return (
    <>
      <div className="form-group text-left w-100">
        <label htmlFor="skill" className="fw-500 control-label fs-14">
          Select Skill
        </label>
        <Skills
          type={type}
          newSkill={newSkillDetails}
          setNewSkill={setNewSkillDetails}
        />
      </div>
      <div className="form-group text-left w-100">
        <label htmlFor="level" className="fw-500 control-label fs-14">
          Select Level
        </label>
        <Ratings
          type={type}
          newRating={newRatingDetails}
          setNewRating={setNewRatingDetails}
        />
      </div>
    </>
  );
};

export default SkillsForm;

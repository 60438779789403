import {
  Button,
  Checkbox,
  CircularProgress, FormControl,
  FormControlLabel, FormHelperText, Grid,
  Hidden, IconButton, InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper, TextField,
  Typography
} from "@material-ui/core";
import {
  makeStyles, withStyles
} from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PublishIcon from "@material-ui/icons/Publish";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  BackendBaseApi,
  emailRegex,

  passwordRegex
} from "../../../../../constants/constants";
import { stringEllipsify } from "../../../../../utils/utility";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
  },
  text: {
    fontFamily: " Nunito",
    color: theme.palette.domain.main,
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  ctcGrid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "row",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  createNew: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
  floatingLabelFocusStyle: {
    color: "#6c757d",
    padding: "6px",
    fontSize: "14px",
    paddingLeft: "10px",
    fontFamily: "Nunito",
    fontWeight: "600",
  },

  input: {
    height: 48,
    fontFamily: "Nunito",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const BlueCheckbox = withStyles({
  root: {
    color: "#6c757d",
    "&$checked": {
      color: "#007bff",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);
export default function RegisterForm(props) {
  const {
    type,

    errorMessages,
    setErrorMessages,

    handleSubmit,
    userDetails,
    setUserDetails,
    loading,
    fromApplyPage,
    setValue,
  } = props;
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const domain = useSelector(
    (state) => state.SubDomainDetails.subDomainDetails
  );

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const location = useLocation();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [buttonClicked, setButtonClicked] = useState(false);
  const [validate, setValidate] = useState(false);

  const validateData = () => {
    var first_name_error = "";
    var last_name_error = "";
    var phone_error = "";
    var email_error = "";
    var password_error = "";
    var temp_resume_error = "";
    var college_error = "";
    setValidate(false);
    if (userDetails.FirstName === "") {
      first_name_error = "First Name cannot be left blank";
      setValidate(true);
    }
    if (userDetails.PhoneNo === null) {
      phone_error = "Phone Number cannot be left blank";
      setValidate(true);
    }
    if (userDetails.PhoneNo && userDetails.PhoneNo.length !== 10) {
      phone_error = "Enter a valid phone number";

      setValidate(true);
    }
    if (userDetails.Email === "") {
      email_error = "Email cannot be left blank";
      setValidate(true);
    }
    if (!emailRegex.test(userDetails.Email)) {
      email_error = "Please enter a valid email address";

      setValidate(true);
    }
    if (userDetails.Password === "") {
      password_error = "Password field cannot be left blank";
      setValidate(true);
    }
    if (!passwordRegex.test(userDetails.Password)) {
      password_error =
        "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit";

      setValidate(true);
    }

    if (fromApplyPage && !userDetails.temp_resume) {
      temp_resume_error = "Please Add Resume as well";
      setValidate(true);
    }

    if (fromApplyPage && !userDetails?.college && !userDetails?.temp_college_name) {
      college_error = " Please enter your college";
      setValidate(true);
    }

    setErrorMessages({
      ...errorMessages,
      firstName: first_name_error,
      // lastName: last_name_error,
      phone: phone_error,
      email: email_error,
      password: password_error,
      temp_resume: temp_resume_error,
      college: college_error,
    });
    setButtonClicked(true);
  };

  useEffect(() => {
    if (
      !validate &&
      buttonClicked &&
      userDetails.Email !== "" &&
      userDetails.Password !== ""
    ) {
      handleSubmit();
    }
  }, [buttonClicked]);

  const fileInputRef = useRef();

  const fileInputClicked = (e) => {
    fileInputRef.current.click();
  };

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangecheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [allColleges, setAllColleges] = useState([]);

  useEffect(() => {
    Axios.get(BackendBaseApi.PRAVESH + "api/education/college/")
      .then((res) => {
        let tmpArray = [];
        for (var i = 0; i < res.data.length; i++) {
          tmpArray.push(res.data[i]);
        }
        setAllColleges(tmpArray);
      })
      .catch((err) => {
        throw err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alert = useAlert();

  useEffect(() => {
    if (emailRegex.test(userDetails?.Email)) {
      Axios.post(BackendBaseApi.PRAVESH + "api/check_user/", {
        email: userDetails?.Email,
      })
        .then((res) => {
          if (res.data.data.is_student_user) {
            setErrorMessages({
              ...errorMessages,
              email: `Email '${userDetails?.Email}' already exists!!`,
            });
            return;
          }
        })
        .catch((err) => {
          alert.error("Error occured!");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.Email]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.grid}>
            {/* <img src={Logo} height="70px" width="80px" />
            <br /> */}
            <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
              {fromApplyPage ? (
                <b>Create your Account to Apply for the Job</b>
              ) : (
                <b>
                  {location.pathname === "/signup/student" ? (
                    <>
                      Register to Get Personalized Job
                      <br /> Recommendations
                    </>
                  ) : (
                    <>
                      Register to Hire high-quality candidates <br />
                      with GetWork
                    </>
                  )}
                </b>
              )}
            </Typography>
          </Grid>
          <br />
          <Grid item xs={6} md={6} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="First Name *"
              variant="outlined"
              id="custom-css-outlined-input"
              type="text"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={errorMessages.firstName !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages.firstName !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.firstName}
                </span>
              }
              value={userDetails.FirstName}
              onChange={(e) => {
                setUserDetails({ ...userDetails, FirstName: e.target.value });
                setErrorMessages({ ...errorMessages, firstName: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="Last Name"
              variant="outlined"
              type="text"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              id="custom-css-outlined-input"
              error={errorMessages.lastName !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages.lastName !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.lastName}
                </span>
              }
              value={userDetails.LastName}
              onChange={(e) => {
                setUserDetails({ ...userDetails, LastName: e.target.value });
                setErrorMessages({ ...errorMessages, lastName: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="Phone No. *"
              variant="outlined"
              type="number"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              error={errorMessages.phone !== "" ? true : false}
              id="custom-css-outlined-input"
              //error={errorMessages.firstName !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages.phone !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.phone}
                </span>
              }
              value={userDetails.PhoneNo}
              onChange={(e) => {
                setUserDetails({ ...userDetails, PhoneNo: e.target.value });
                setErrorMessages({ ...errorMessages, phone: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={12} className={classes.grid}>
            <CssTextField
              className={classes.margin}
              label="Email *"
              variant="outlined"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              id="custom-css-outlined-input"
              type="email"
              error={errorMessages.email !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages.email !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages.email}
                </span>
              }
              value={userDetails.Email}
              onChange={(e) => {
                setUserDetails({ ...userDetails, Email: e.target.value });
                setErrorMessages({ ...errorMessages, email: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>
          {fromApplyPage && 
          <Grid item xs={12} md={12} className={classes.grid}>
            <Autocomplete
              id="autocomplete"
              freeSolo
              options={allColleges}
              getOptionLabel={(option) => option.name}
              style={{ width: "100%" }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setUserDetails({ ...userDetails, college: newValue });
                  setErrorMessages({ ...errorMessages, college: "" });
                  setButtonClicked(false);
                }
              }}
              onInputChange={(e) => {
                setUserDetails({
                  ...userDetails,
                  temp_college_name: e.target.value,
                });
                setErrorMessages({ ...errorMessages, college: "" });
                setButtonClicked(false);
              }}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="College *"
                  margin="normal"
                  variant="outlined"
                  error={errorMessages.college !== "" ? true : false}
                  // InputProps={{
                  //   className: classes.input,
                  // }}
                  // InputLabelProps={{
                  //   className: classes.floatingLabelFocusStyle,
                  // }}
                  helperText={
                    <span
                      style={
                        errorMessages.college !== ""
                          ? { color: "#D64045", fontSize: 13 }
                          : { color: "grey", fontSize: 13 }
                      }
                    >
                      {errorMessages.college
                        ? errorMessages.college
                        : "Enter your College Name if not present in the List"}
                    </span>
                  }
                />
              )}
            />
          </Grid>
}
          <Grid item xs={12} md={12}>
            <FormControl
              color="#007bff"
              className={clsx(classes.margin, classes.textField, classes.form)}
              variant="outlined"
              error={errorMessages.password !== "" ? true : false}
            >
              <InputLabel
                className={classes.floatingLabelFocusStyle}
                style={{ borderBottomColor: "#007bff" }}
              >
                Password*
              </InputLabel>

              <OutlinedInput
                style={{
                  borderRadius: "30px",
                  // padding: "22px",
                  borderColor: "#007bff",
                  height: "48px",
                }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={userDetails.Password}
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                // labelWidth={80}
                onChange={(e) => {
                  setUserDetails({ ...userDetails, Password: e.target.value });
                  setErrorMessages({ ...errorMessages, password: "" });
                  setButtonClicked(false);
                }}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />

              <FormHelperText
                style={{
                  fontFamily: " Nunito",

                  fontSize: "12px",
                }}
                id="standard-weight-helper-text"
                className={
                  errorMessages.password !== ""
                    ? classes.errorPassword
                    : classes.noErrorPassword
                }
              >
                {errorMessages.password !== ""
                  ? errorMessages.password
                  : "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit"}
              </FormHelperText>
            </FormControl>

            {/* <CssTextField
              className={classes.margin}
              label="College Name"
              variant="outlined"
              id="custom-css-outlined-input"
            /> */}
          </Grid>

          {/* Salary expectation */}
          {fromApplyPage && (
            <>
              <div style={{ fontSize: 13, marginLeft: 15 }}>
                Salary Expectation
              </div>
              <Grid item xs={12} md={12} className={classes.ctcGrid}>
                <CssTextField
                  className={classes.margin}
                  style={{ marginRight: 1.5 }}
                  label={
                    <span style={{ fontSize: 12 }}>Current CTC (In Lacs)</span>
                  }
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  variant="outlined"
                  type="number"
                  id="custom-css-outlined-input"
                  value={userDetails.CurrentCtc}
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      CurrentCtc: e.target.value,
                    });
                    setButtonClicked(false);
                  }}
                />
                <CssTextField
                  className={classes.margin}
                  style={{ marginLeft: 1.5 }}
                  label={
                    <span style={{ fontSize: 12 }}>Expected CTC (In Lacs)</span>
                  }
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  type="number"
                  id="custom-css-outlined-input"
                  value={userDetails.ExpectedCtc}
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      ExpectedCtc: e.target.value,
                    });
                    setButtonClicked(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.grid}>
                <CssTextField
                  className={classes.margin}
                  label="Notice Period (In Days)"
                  variant="outlined"
                  type="number"
                  id="custom-css-outlined-input"
                  helperText={
                    <span style={{ color: "grey", fontSize: 12 }}>
                      Enter 0(Zero) for Immediate Join
                    </span>
                  }
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  value={userDetails.NoticePeriod}
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      NoticePeriod: e.target.value,
                    });
                    setButtonClicked(false);
                  }}
                />
              </Grid>
            </>
          )}

          {fromApplyPage && (
            <Grid item md={12} xs={12}>
              <div className={classes.btndiv}>
                {userDetails.temp_resume ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "auto",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #007bff",
                        borderRadius: 30,
                        padding: "0px 60px",
                      }}
                    >
                      <span style={{ color: "#007bff" }}>
                        {stringEllipsify(userDetails.temp_resume.name, 14)}
                      </span>
                      <IconButton
                        onClick={() => {
                          setUserDetails({ ...userDetails, temp_resume: null });
                          setButtonClicked(false);
                          setErrorMessages({
                            ...errorMessages,
                            temp_resume: "",
                          });
                        }}
                      >
                        <DeleteOutlineIcon
                          style={{ color: "red", fontSize: 18 }}
                        />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* <Button variant="outlined" className={classes.upload} startIcon={<PublishOutlinedIcon />} >
                    Upload Resume to Apply
                  </Button> */}

                    <Button
                      className={classes.btnsocial}
                      variant="outlined"
                      onClick={(e) => fileInputClicked(e)}
                      startIcon={
                        <PublishIcon
                          style={
                            domain?.domain
                              ? errorMessages.temp_resume === ""
                                ? {
                                    color: domain?.color_code,
                                  }
                                : {
                                    color: "#D64045",
                                  }
                              : errorMessages.temp_resume === ""
                              ? {
                                  color: "#007bff",
                                }
                              : {
                                  color: "#D64045",
                                }
                          }
                        />
                      }
                      style={{ height: 45 }}
                    >
                      <Typography
                        variant="body2"
                        style={
                          domain?.domain
                            ? errorMessages.temp_resume === ""
                              ? {
                                  color: domain?.color_code,
                                  fontFamily: " Nunito",
                                }
                              : {
                                  color: "#D64045",
                                  fontFamily: " Nunito",
                                }
                            : errorMessages.temp_resume === ""
                            ? {
                                color: "#007bff",
                                fontFamily: " Nunito",
                              }
                            : {
                                color: "#D64045",
                                fontFamily: " Nunito",
                              }
                        }
                      >
                        <b>Upload Resume *</b>
                      </Typography>
                    </Button>

                    <input
                      ref={fileInputRef}
                      className="file-input"
                      type="file"
                      id="file"
                      onChange={(e) => {
                        if (!e.target.files[0]) return;
                        const ext = e.target.files[0].name.substring(
                          e.target.files[0].name.lastIndexOf(".") + 1
                        );
                        if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
                          alert.error("Only PDF & DOC Format Allowed!");
                          return;
                        }
                        if (e.target.files[0].size < 2097152) {
                          setUserDetails({
                            ...userDetails,
                            temp_resume: e.target.files[0],
                          });

                          setButtonClicked(false);
                          setErrorMessages({
                            ...errorMessages,
                            temp_resume: "",
                          });
                        } else alert.error("File Size Limit: 2MB");
                      }}
                    />
                    {errorMessages.temp_resume !== "" && (
                      <Typography
                        style={{
                          color: "#D64045",
                          fontSize: 13,
                          fontFamily: " Nunito",
                          marginLeft: 10,
                        }}
                      >
                        {errorMessages.temp_resume}
                      </Typography>
                    )}
                  </>
                )}
              </div>
            </Grid>
          )}
          <Grid item md={12} xs={12} style={{ paddingTop: "0px" }}>
            <FormControlLabel
              control={
                <BlueCheckbox
                  size="small"
                  checked={state.checkedA}
                  onChange={handleChangecheck}
                  name="checkedA"
                />
              }
              label={
                <Typography
                  style={{
                    fontSize: "12px",
                    fontFamily: "Nunito",
                    marginTop: "10px",
                    color: "#000",
                  }}
                >
                  I want to receive GetWork newsletter to receive Product
                  updates and useful tips.
                </Typography>
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <div className={classes.btndiv}>
              <Button
                className={classes.btn}
                variant="contained"
                onClick={() => {
                  validateData();
                }}
                style={{ height: 45 }}
              >
                <Typography
                  variant="body2"
                  style={{ color: "#fff", fontFamily: " Nunito" }}
                >
                  {" "}
                  {/* Sign In{" "} */}
                  {loading ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    <b>Register</b>
                  )}
                </Typography>
              </Button>
            </div>
          </Grid>

          {fromApplyPage && (
            <Hidden smUp>
              <div
                className={classes.create}
                style={{ marginTop: 30, margin: "auto" }}
              >
                <Typography
                  variant="body2"
                  style={{ fontFamily: " Nunito", textAlign: "center" }}
                >
                  Existing User?
                </Typography>

                <Button
                  size="small"
                  style={{ color: "#007bff" }}
                  onClick={() => {
                    setValue(0);
                  }}
                >
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ textAlign: "center" }}
                  >
                    Click here to Login and Apply
                  </Typography>
                </Button>
              </div>
            </Hidden>
          )}
        </Grid>
      </Paper>
    </div>
  );
}

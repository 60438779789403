import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Chip } from "@material-ui/core";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    borderRadius: "12px",
  },
  icon: {
    color: "#007bff",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: "0px",
    marginRight: "10px",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
}));

export default function JobType({ data }) {
  const classes = useStyles();

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Information Technology" },
    { key: 1, label: "FMCG" },
  ]);

  return (
    <>
      <div>
        <div className={classes.overview}>
          <div className={classes.div}>
            <Typography variant="h6">Job Type</Typography>
            <Typography variant="body2">{data?.jobType}</Typography>
            {data?.industryType?.length > 0 && <Typography variant="h6">Industry Type</Typography>}
          </div>

          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div component="ul" className={classes.root}>
              {data?.industryType?.length > 0 &&
                data?.industryType.map((data) => {
                  return (
                    <li key={data.id}>
                      <Chip
                        size="small"
                        label={
                          <Typography style={{ fontSize: "12px", fontWeight: 600 }} color="inherit">
                            {data.industry_name}
                          </Typography>
                        }
                        variant="outlined"
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

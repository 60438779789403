import {
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  RadioGroup,
  Switch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RichTextEditor from "react-rte";
import { getAllLocationsArray } from "../../company/action";
import {
  changeState,
  UPDATE_MIN_CTC,
} from "../../company/jobs/PostJob/Forms/action";
import CurrencyComponent from "./FormComponents/CurrencyComponent";
import CustomizedCheckboxes from "./FormComponents/CustomizedCheckboxes";
import StyledRadio from "./FormComponents/CustomizedRadio";
import HelpFunction from "./FormComponents/help";
import { Experiences, Locations, Salaries } from "./FormComponents/JobSegment";
import NumberTextField from "./FormComponents/NumberTextField";
import JobDescriptionDialog from "./JobDescriptionDialog";
import "./style.css";

const Details = (props) => {
  let { job_id } = useParams();

  const { state, setState, detailsRef, jobData, setApplyButtonClicked } = props;
  const alert = useAlert();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [minSalary, setMinSalary] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [hiringCount, setHiringCount] = useState();
  const [stipened, setStipened] = useState(0);

  // var work_experiences = [];
  // if (localStorage.getItem("user_type") === "Company") {
  //   work_experiences = [
  //     { id: "Fresher", name: "Fresher" },
  //     { id: "0-1 years", name: "0-1 years" },
  //     { id: "2-5 years", name: "2-5 years" },
  //     { id: "5 and more years", name: "5 and more years" },
  //   ];
  // } else {
  //   work_experiences = [
  //     { id: "Fresher", name: "Fresher" },
  //     { id: "0-1 years", name: "0-1 years" },
  //   ];
  // }
  var work_experiences = [];
  const company_work_experiences = [
    { id: "0", name: "0 years" },
    { id: "1", name: "1 years" },
    { id: "2", name: "2 years" },
    { id: "3", name: "3 years" },
    { id: "4", name: "4 years" },
    { id: "5", name: "5 years" },
    { id: "6", name: "6 years" },
    { id: "7", name: "7 years" },
    { id: "8", name: "8 years" },
    { id: "9", name: "9 years" },
    { id: "10", name: "10 years" },
  ];

  const college_work_experiences = [
    { id: "0", name: "0 years" },
    { id: "1", name: "1 years" },
    { id: "2", name: "2 years" },
    { id: "3", name: "3 years" },
  ];

  if (localStorage.getItem("user_type") === "Company") {
    work_experiences = company_work_experiences;
  } else {
    work_experiences = college_work_experiences;
  }

  const SalaryTypes = [
    { name: "Yearly", value: "PER ANNUM" },
    { name: "Monthly", value: "PER MONTH" },
    { name: "Hourly", value: "PER HOUR" },
  ];

  const [minWorkExp, setMinWorkExp] = useState("");
  const [maxWorkExp, setMaxWorkExp] = useState("");

  const [currentSalaryType, setCurrentSalaryType] = useState({
    name: "Yearly",
    value: "PER ANNUM",
  });
  const [serviceBond, setServiceBond] = useState();
  const [editableJobDescription, setEditableJobDescription] = useState();
  const cityList = useSelector((state) => state.CityArrayNew.cityArray);
  useEffect(() => {
    if (cityList.length === 0) dispatch(getAllLocationsArray());
  }, []);

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };
  useEffect(() => {
    var arr = [];
    if (job_id && jobData && jobData !== undefined) {
      setMinSalary(jobData?.ctc_min);
      setMaxSalary(jobData?.ctc_max);
      setHiringCount(jobData?.vacancy);
      setEditableJobDescription(jobData?.job_description);
      setServiceBond(jobData?.service_bond);
      setStipened(jobData?.stipend ? jobData.stipend : 0);

      // setCurrentWorkExperience({
      //   id: jobData?.eligibility_criteria?.experience[0],
      //   name: jobData?.eligibility_criteria?.experience[0],
      // });
      if (jobData?.eligibility_criteria?.experience[0]) {
        let first = "";
        let second = "";
        if (!isNaN(jobData.eligibility_criteria.experience[0].split("-")[0])) {
          first = jobData.eligibility_criteria.experience[0].split("-");
        }
        if (first.length > 1)
          second = jobData.eligibility_criteria.experience[0]
            .split("-")[1]
            .split(" ")[0];
        first[0] &&
          setMinWorkExp({
            id: first[0],
            name: `${first[0]} years`,
          });
        second &&
          setMaxWorkExp({
            id: second,
            name: second + " years",
          });
      }
      setCurrentSalaryType({
        name: jobData?.salary_payment_type,
        value: jobData?.salary_payment_type,
      });
    }
  }, [jobData]);

  useEffect(() => {
    setState({
      ...state,
      Details: {
        ...state.Details,
        ctc_min: parseFloat(minSalary),
      },
    });
  }, [minSalary]);
  useEffect(() => {
    setState({
      ...state,
      Details: {
        ...state.Details,
        ctc_max: parseFloat(maxSalary),
      },
    });
    handleFilterChange(UPDATE_MIN_CTC, maxSalary);
    setApplyButtonClicked(true);
  }, [maxSalary]);

  useEffect(() => {
    if (state.Details.salary_type !== "PAID") {
      setState({
        ...state,
        Details: {
          ...state.Details,
          ctc_max: 0,
          ctc_min: 0,
          salary_payment_type: "",
        },
      });
      setMinSalary();
      setMaxSalary();
    } else if (state.Details.salary_type === "PAID") {
      setState({
        ...state,
        Details: {
          ...state.Details,
          ctc_max: 0,
          ctc_min: 0,
          salary_payment_type: "PER ANNUM",
        },
      });
    }
  }, [state.Details.salary_type]);

  useEffect(() => {
    setState({
      ...state,
      Details: {
        ...state.Details,
        vacancy: parseFloat(hiringCount),
      },
    });
  }, [hiringCount]);

  useEffect(() => {
    if (state.Details.allow_remote) {
      setState({
        ...state,
        Details: {
          ...state.Details,
          job_location: [],
        },
      });
    }
  }, [state.Details.allow_remote]);

  useEffect(() => {
    if (state.Details.work_from_home) {
      setState({
        ...state,
        Details: {
          ...state.Details,
          job_location: [],
          allow_remote: false,
        },
      });
    }
  }, [state.Details.work_from_home]);

  useEffect(() => {
    setState({
      ...state,
      Details: {
        ...state.Details,
        service_bond: serviceBond,
      },
    });
  }, [serviceBond]);

  useEffect(() => {
    if (stipened !== null && stipened !== undefined && stipened !== 0) {
      setState({
        ...state,
        Basics: {
          ...state.Basics,
          stipend: parseFloat(stipened),
          internship_salary_type: stipened === 0 ? "UNPAID" : "PAID",
        },
      });
    }
  }, [stipened]);

  useEffect(() => {
    if (minWorkExp?.id && maxWorkExp?.id) {
      // console.log(minWorkExp, maxWorkExp);
      if (parseInt(minWorkExp.id) > parseInt(maxWorkExp.id)) {
        alert.error("Select correct range for Work Experience...");
        return;
      }
      setState({
        ...state,
        Preferences: {
          ...state.Preferences,
          eligibility_criteria: {
            ...state.Preferences.eligibility_criteria,
            experience: [`${minWorkExp.id}-${maxWorkExp.id} years`],
          },
        },
      });
    }
  }, [minWorkExp, maxWorkExp]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   console.log("STSTSTSTSTSTSTS", SalaryTypes);
  // }, [SalaryTypes]);

  return (
    <div style={{ marginTop: "50px" }} ref={detailsRef}>
      <JobDescriptionDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        state={state}
        setState={setState}
      />
      <h5 className={classes.heading}>Details</h5>
      <Divider />

      <Grid container style={{ marginTop: "30px" }}>
        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>
            Description<span className={classes.RedStar}>*</span>
          </p>
        </Grid>
        <Grid item xs={9} className={classes.section}>
          <p className={classes.subTitleLink}>
            <b>
              <i>
                <span className={classes.link} onClick={handleClickOpen}>
                  <u>Click here</u>
                </span>{" "}
                to choose from GetWork's Job description templates available.
              </i>
            </b>
          </p>
          <RichTextEditor
            className={classes.mainEditor}
            toolbarClassName={classes.editorToolbar}
            editorClassName={classes.editor}
            value={state.Details.raw_job_description}
            style={{
              maxHeight: "100px",
              overflowY: "scroll",
              width: "80%",
              borderRadius: 30,
            }}
            onChange={(value) => {
              var new_ = value.toString("html");
              setState({
                ...state,
                Details: {
                  ...state.Details,
                  job_description: new_,
                  raw_job_description: value,
                },
              });
            }}
          />
          <p className={classes.subTitle}>
            <i>
              You can copy - paste a description from your website, we'll retain
              all the formatting
            </i>
          </p>
        </Grid>
        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>
            Work Experience
            <span className={classes.RedStar}>*</span>
            <HelpFunction title={"Work Experience (in years)"} />
          </p>
        </Grid>
        <Grid item xs={4} className={classes.section}>
          <Experiences
            state={state}
            setState={setState}
            work_experiences={work_experiences}
            width={classes.InputWidth}
            responsive={classes.labelSize}
            currentWorkExperience={minWorkExp}
            setCurrentWorkExperience={setMinWorkExp}
            MenuSize={classes.MenuSize}
            name={"Min"}
          />
        </Grid>
        <Grid item xs={4} className={classes.section}>
          <Experiences
            state={state}
            setState={setState}
            work_experiences={work_experiences}
            width={classes.InputWidth}
            responsive={classes.labelSize}
            currentWorkExperience={maxWorkExp}
            setCurrentWorkExperience={setMaxWorkExp}
            MenuSize={classes.MenuSize}
            name={"Max"}
          />
        </Grid>
        {state.Basics.job_type === 1 && (
          <>
            <Grid item xs={3} className={classes.section}>
              <p className={classes.formLabel}>
                Internship Type
                <span className={classes.RedStar}>*</span>
              </p>
            </Grid>
            <Grid item md={9}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender111"
                  row
                  value={state.Basics.internship_salary_type}
                  onChange={(e) => {
                    setState({
                      ...state,
                      Basics: {
                        ...state.Basics,
                        internship_salary_type: e.target.value,
                      },
                    });
                  }}
                >
                  <FormControlLabel
                    value="PAID"
                    control={<StyledRadio />}
                    label={<span className={classes.labelSize}>{"Paid"}</span>}
                  />
                  <FormControlLabel
                    value="UNPAID"
                    control={<StyledRadio />}
                    label={
                      <span className={classes.labelSize}>{"Unpaid"}</span>
                    }
                  />
                  {/* <FormControlLabel
                    value="NOT DISCLOSED"
                    control={<StyledRadio />}
                    label={
                      <span className={classes.labelSize}>
                        {"Not Disclosed"}
                      </span>
                    }
                  /> */}
                </RadioGroup>
              </FormControl>
            </Grid>

            {state.Basics.internship_salary_type === "PAID" && (
              <Grid item xs={3} className={classes.section}>
                <p className={classes.formLabel}>
                  Stipend
                  <span className={classes.RedStar}>*</span>
                  <HelpFunction title={"Stipend for internship (per month)"} />
                </p>
              </Grid>
            )}
            {state.Basics.internship_salary_type === "PAID" && (
              <Grid item xs={9} className={classes.section}>
                {localStorage.getItem("user_type") === "College" && (
                  <CurrencyComponent
                    state={stipened}
                    setState={setStipened}
                    labelName={"Stipend (Per Month)"}
                    width={"50%"}
                    responsive={classes.labelSize}
                    disableWhen={
                      state?.Basics?.internship_salary_type === "NOT DISCLOSED"
                        ? true
                        : false
                    }
                  />
                )}

                {localStorage.getItem("user_type") === "Company" && (
                  <>
                    <CurrencyComponent
                      state={stipened}
                      setState={setStipened}
                      labelName={"Stipend (Per Month)"}
                      width={"50%"}
                      responsive={classes.labelSize}
                      disableWhen={
                        state?.Basics?.internship_salary_type ===
                        "NOT DISCLOSED"
                          ? true
                          : false
                      }
                    />{" "}
                  </>
                )}
                <FormControlLabel
                  value={
                    state.Basics.internship_salary_type === "NOT DISCLOSED"
                      ? true
                      : false
                  }
                  style={{ marginLeft: 0 }}
                  control={
                    <CustomizedCheckboxes
                      checked={
                        state.Basics.internship_salary_type === "NOT DISCLOSED"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          Basics: {
                            ...state.Basics,
                            internship_salary_type:
                              e.target.checked === true
                                ? "NOT DISCLOSED"
                                : state.Basics.stipend !== 0
                                ? "PAID"
                                : "UNPAID",
                          },
                        });
                      }}
                      name={"internship_salary_type"}
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <span className={classes.labelSize}>
                      Stipend Not to be disclosed
                    </span>
                  }
                />
              </Grid>
            )}
          </>
        )}

        {(state.Basics.job_type === 7 ||
          (state.Basics.job_type === 1 && state.Basics.ppo)) && (
          <Grid container>
            <Grid item xs={3} className={classes.section}>
              <p className={classes.formLabel}>
                Salary Range
                <span className={classes.RedStar}>*</span>
                {state.Basics.job_type === 1 && (
                  <HelpFunction title={"Salary Range after Internship"} />
                )}
              </p>
            </Grid>
            <Grid item xs={9}>
              {/* <FormControlLabel
              control={
                <Switch
                  checked={state.Details.salary_type === "PAID" ? true : false}
                  onChange={(e) => {
                    var type;
                    if (e.target.checked) type = "PAID";
                    else type = "UNPAID";
                    setState({
                      ...state,
                      Details: {
                        ...state.Details,
                        salary_type: type,
                      },
                    });
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<span className={classes.labelSize}>{state.Details.salary_type === "PAID" ? "Paid" : "Unpaid"}</span>}
            /> */}

              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender111"
                  row
                  value={state.Details.salary_type}
                  onChange={(e) => {
                    setState({
                      ...state,
                      Details: {
                        ...state.Details,
                        salary_type: e.target.value,
                        /*   ctc_max: 0,
                        ctc_min: 0,
                        salary_payment_type: "", */
                      },
                    });
                  }}
                >
                  <FormControlLabel
                    value="PAID"
                    control={<StyledRadio />}
                    label={<span className={classes.labelSize}>{"Paid"}</span>}
                  />
                  <FormControlLabel
                    value="UNPAID"
                    control={<StyledRadio />}
                    label={
                      <span className={classes.labelSize}>{"Unpaid"}</span>
                    }
                  />
                  <FormControlLabel
                    value="NOT DISCLOSED"
                    control={<StyledRadio />}
                    label={
                      <span className={classes.labelSize}>
                        {"Not Disclosed"}
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              {state.Details.salary_type === "PAID" && (
                <CurrencyComponent
                  state={minSalary}
                  setState={setMinSalary}
                  labelName={"Min Salary"}
                  width={"90%"}
                  responsive={classes.labelSize}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              {state.Details.salary_type === "PAID" && (
                <CurrencyComponent
                  state={maxSalary}
                  setState={setMaxSalary}
                  labelName={"Max Salary"}
                  width={"90%"}
                  responsive={classes.labelSize}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              {state.Details.salary_type === "PAID" && (
                <Salaries
                  state={state}
                  setState={setState}
                  SalaryTypes={SalaryTypes}
                  width={classes.InputWidth}
                  labelSizeSalary={classes.labelSizeSalary}
                  currentSalaryType={currentSalaryType}
                  setCurrentSalaryType={setCurrentSalaryType}
                  MenuSize={classes.MenuSize}
                />
              )}
            </Grid>
          </Grid>
        )}
        <br />
        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel} style={{ paddingRight: "20px" }}>
            Work from Home?
            <span className={classes.RedStar}>*</span>
            <HelpFunction title={"Allow Work from Home?"} />
          </p>
        </Grid>
        <Grid item xs={9} className={classes.section}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender111"
              value={state.Details.work_from_home === true ? "true" : "false"}
              onChange={(e) => {
                setState({
                  ...state,
                  Details: {
                    ...state.Details,
                    work_from_home: e.target.value === "true" ? true : false,
                  },
                });
              }}
            >
              <FormControlLabel
                value="true"
                control={<StyledRadio />}
                label={<span className={classes.labelSize}>{"Yes"}</span>}
              />
              <FormControlLabel
                value="false"
                control={<StyledRadio />}
                label={<span className={classes.labelSize}>{"No"}</span>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {!state.Details.work_from_home && (
          <>
            <Grid item xs={3} className={classes.section}>
              <p className={classes.formLabel}>
                Location<span className={classes.RedStar}>*</span>
              </p>
            </Grid>
            <Grid item xs={9} className={classes.section} id="box_value">
              <Locations
                state={state}
                setState={setState}
                cityList={cityList}
                width={classes.InputWidth}
                responsive={classes.labelSize}
                setApplyButtonClicked={setApplyButtonClicked}
              />
              <FormControlLabel
                value={state.Details.allow_remote}
                style={{ marginLeft: 0 }}
                control={
                  <CustomizedCheckboxes
                    checked={state.Details.allow_remote}
                    onChange={(e) => {
                      setState({
                        ...state,
                        Details: {
                          ...state.Details,
                          allow_remote: e.target.checked,
                        },
                      });
                    }}
                    name={"allow_remote"}
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                    color="primary"
                  />
                }
                label={<span className={classes.labelSize}>PAN India</span>}
              />
            </Grid>
          </>
        )}
        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel} style={{ paddingRight: "20px" }}>
            Vacancy <span className={classes.RedStar}>*</span>
            <HelpFunction
              title={
                "How many students do you expect to hire for this position?"
              }
            />
          </p>
        </Grid>
        <Grid item xs={9}>
          <NumberTextField
            state={hiringCount}
            setState={setHiringCount}
            labelName={"Vacancy"}
            width={"70%"}
            responsive={classes.labelSize}
          />
          <p className={classes.subTitle}>
            <i>
              This number can be approximate and will not be displayed to
              students
            </i>
          </p>
        </Grid>

        <Grid item xs={3} className={classes.section}>
          <p className={classes.formLabel}>
            Service Bond <span className={classes.RedStar}>*</span>
          </p>
        </Grid>
        <Grid item md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={state.Details.is_service_bond === true ? true : false}
                onChange={(e) => {
                  setState({
                    ...state,
                    Details: {
                      ...state.Details,
                      is_service_bond: e.target.checked,
                    },
                  });
                }}
                name="checkedBsc"
                color="primary"
              />
            }
            label={
              <span className={classes.labelSize}>
                {state.Details.is_service_bond === true ? "Yes" : "No"}
              </span>
            }
          />
        </Grid>
        <Grid item xs={6}>
          {state.Details.is_service_bond && (
            <NumberTextField
              state={serviceBond}
              setState={setServiceBond}
              labelName={"Bond (in months)"}
              width={"70%"}
              responsive={classes.labelSize}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formLabel: {
      fontSize: "13px",
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    RedStar: {
      color: "red",
      fontWeight: "bold",
      marginLeft: "2px",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "bolder",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    section: {
      marginBottom: "20px",
    },
    subTitle: {
      fontSize: "11px",
      color: "grey",
      width: "70%",
      margin: "6px 0 0 10px",
    },
    subTitleLink: {
      fontSize: "13px",
      color: "grey",
      width: "70%",
      margin: "6px 0 0 10px",
    },
    link: {
      color: "#3282C4",

      "&:hover": {
        cursor: "pointer",
      },
    },
    editor: {
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#fff",
      color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
      maxHeight: 450,
      minHeight: 400,
      overflowY: "scroll",
      borderRadius: 30,
      width: "80%",
    },

    mainEditor: {
      // border: "0px hidden",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#fff",
      borderRadius: 30,
      width: "80%",
    },
    editorToolbar: {
      // backgroundColor: theme.palette.type === "dark" ? "#C1D9ED" : "#F2F2F2",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#fff",

      borderRadius: 30,
    },
    InputWidth: {
      width: "70%",
    },
    labelSize: {
      fontSize: 13,
    },
    labelSizeSalary: {
      fontSize: 12,
    },
    MenuSize: {
      fontSize: "12px",
    },
    "@media only screen and (max-width: 900px)": {
      InputWidth: {
        width: "100%",
      },
      MenuSize: {
        fontSize: "11px",
      },
      formLabel: {
        fontSize: "11px",
        fontWeight: "bold",
        paddingTop: "10px",
      },
      editor: {
        width: "100%",
      },
      mainEditor: {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 1100px)": {
      labelSize: {
        fontSize: 11,
      },
      subTitleLink: {
        fontSize: "11px",
        color: "grey",
        width: "70%",
        margin: "6px 0 0 10px",
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "auto",

        // border: "1px solid #ced4da",
        // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
      },
    },
  })
);
export default Details;

import Axios from "axios";

import {BackendBaseApi} from "../../../../../constants/constants";
export const GET_DEGREES_WITH_ID = "GET_DEGREES_WITH_ID";

export const UPDATE_EDUCATION_LEVELS = "UPDATE_EDUCATION_LEVELS";
export const UPDATE_DEGREES = "UPDATE_DEGREES";
// export const UPDATE_JOB_SEGMENT = "UPDATE_JOB_SEGMENT";
export const GET_JOB_SEGMENT = "GET_JOB_SEGMENT";
export const UPDATE_MIN_CTC = "UPDATE_MIN_CTC";
export const UPDATE_COURSES = "UPDATE_COURSES";
export const UPDATE_LOCATIONS = "UPDATE_LOCATIONS";
export const GET_DEGREES_ALL = "GET_DEGREES_ALL";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const FILTER_COUNT = "FILTER_COUNT";
export const UPDATE_JOB_SEGMENT = "UPDATE_JOB_SEGMENT";
export const UPDATE_COLLEGE_FILTER = "UPDATE_COLLEGE_FILTER";
export const GET_COURSES_WITH_ID = "GET_COURSES_WITH_ID";
export const getDegrees = (url) => {
  return async (dispatch) => {
    const res = await Axios.get(BackendBaseApi.PRAVESH + "api" + url).catch(
      (error) => {
        throw error;
      }
    );

    let temp = {};
    res.data.data.forEach((data) => {
      if (!data.user_created) temp[data.name] = data;
    });

    dispatch({
      type: GET_DEGREES_WITH_ID,
      payload: temp,
    });
  };
};

export const getAllDegrees = () => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/education/degree/"
    ).catch((error) => {
      throw error;
    });

    let temp = {};
    res.data.forEach((data) => {
      temp[data.name] = data;
    });
    dispatch({
      type: GET_DEGREES_ALL,
      payload: temp,
    });
  };
};

export const getJobSegment = () => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/company/job_segment/",
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      throw error;
    });

    let temp = {};
    res.data.data.forEach((data) => {
      temp[data.job_segment_name] = data;
    });
    dispatch({
      type: GET_JOB_SEGMENT,
      payload: temp,
    });
  };
};

export const getCourses = (url) => {
  return async (dispatch) => {
    const res = await Axios.get(BackendBaseApi.PRAVESH + "api" + url).catch(
      (error) => {
        throw error;
      }
    );

    let temp = {};
    res.data.data.forEach((data) => {
      if (!data.user_created) temp[data.specialization_name] = data;
    });

    dispatch({
      type: GET_COURSES_WITH_ID,
      payload: temp,
    });
  };
};

export const getAllLocations = () => {
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/location/city/"
    ).catch((error) => {
      throw error;
    });

    let temp = {};

    res.data.forEach((data) => {
      temp[data.city] = data.city_id;
    });

    dispatch({
      type: GET_LOCATIONS,
      payload: temp,
    });
  };
};

export const changeState = (dispatcher, data) => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
  };
};

export const changeFilterCount = (dispatcher, data) => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
  };
};

import React, { useState, useEffect } from "react";
import axios from "axios";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatarimage from "./Avatarimage.jsx";
import iima from "../../../../assets/images/iim.jpg";
import { useAlert } from "react-alert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import fakebanner from "../../../../assets/images/noimg.png";
import fakelogo from "../../../../assets/images/user/user-default.png";
import pic from "../../../../assets/common/edit3.svg";

import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";

import { BackendBaseApi } from "../../../../constants/constants";
import "react-images-uploader/styles.css";
import "react-images-uploader/font.css";
import { loadCSS } from "fg-loadcss";
import Icon from "@material-ui/core/Icon";

import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
} from "reactstrap";
import { Button, CircularProgress } from "@material-ui/core";
import { getCompanyUserDetails } from "../action.js";
import { useDispatch } from "react-redux";
import SelectSearch from "react-select-search";
import PictureChangeDialog from "../../Dialogs/PictureChangeDialog.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 200,
  },
}));

export default function CompanyProfileUpdate(props) {
  const IMAGES = [
    {
      src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
      thumbnail:
        "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
      thumbnailWidth: 1000,
      thumbnailHeight: 174,
      caption: "After Rain (Jeshu John - designerspics.com)",
    },
    {
      src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
      thumbnail:
        "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
      tags: [
        { value: "Ocean", title: "Ocean" },
        { value: "People", title: "People" },
      ],
      caption: "Boats (Jeshu John - designerspics.com)",
    },

    {
      src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
      thumbnail:
        "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
    },

    {
      src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
      thumbnail:
        "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
    },
  ];

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
  };

  function myTileViewportStyleFn() {
    if (this.props.item.isSelected) return {};
  }

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { buttonLabel, className } = props;

  const [modall, setModal1] = useState(false);

  const toggle1 = () => setModal1(!modall);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  let token = localStorage.getItem("gw_token");

  const [url, setUrl] = useState(
    BackendBaseApi.PRAVESH + "api/company/edit_profile/"
  );
  const [isError, setIsError] = useState(false);
  const [isAbout, setIsAbout] = useState();
  const [isDescription, setIsDescription] = useState();
  const [isName, setIsName] = useState();
  const [industry, setIsIndustry] = useState();
  const [location, setIsLocation] = useState();
  const [logo, setIsLogo] = useState();
  const [banner, setIsBanner] = useState();
  const [web, setIsweb] = useState();
  const [year, setIsyear] = useState();
  const [spcl, setIsSpecialities] = useState();
  const [size, setIsSize] = useState();
  const [industryList, setIndustryList] = useState({});
  const [industryType, setIndustryType] = useState();
  const [check, setCheck] = useState(false);
  const [checkLogo, setCheckLogo] = useState(false);
  const [city, setCity] = useState();
  const [state, setState] = useState();

  const [post, setPost] = useState({ data: {} });
  const [file, setFile] = useState({ data: {} });
  const [image, setImage] = useState({ preview: "" });
  const [imagelogo, setImagelogo] = useState({ previewlogo: "" });
  const [updatingLogo, setUpdatingLogo] = useState(false);
  const [updatingBanner, setUpdatingBanner] = useState(false);
  const [updatingAbout, setUpdatingAbout] = useState(false);
  const [updatingWebsite, setUpdatingWebsite] = useState(false);
  const [updatingIndustry, setUpdatingIndustry] = useState(false);
  const [updatingSize, setUpdatingSize] = useState(false);
  const [updatingFounded, setUpdatingFounded] = useState(false);
  const [updatingSpecialities, setUpdatingSpecialities] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(url, {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            setPost(res.data.data);
            setFile(res.data.data);

            setIsName(res.data.data.company_name);
            setIsAbout(res.data.data.about);
            setIsDescription(res.data.data.company_description);
            setIsIndustry(res.data.data.industry_name);
            setIsLocation(res.data.data.location);
            setIsLogo(res.data.data.logo);
            setIsBanner(res.data.data.banner);
            setIsSize(res.data.data.size);
            setIsweb(res.data.data.website);
            setIsyear(res.data.data.year_founded);
            setIsSpecialities(res.data.data.specialities);
            setIndustryType(res.data.data.industry_name);
            setImage({ preview: res.data.data.banner });
            setImagelogo({ previewlogo: res.data.data.logo });
            setCity(res.data.data.city_name);
            setState(res.data.data.state_name);
          }
        })
        .catch((error) => {
          alert.error("Error Occured");
        });
    };

    fetchData();
    getIndustries();
  }, [url]);

  const getIndustries = () => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/company/industry/")
      .then((res) => {
        if (res) {
          let temp = {};
          res.data.data.forEach((c) => {
            temp[c.industry_name] = c.id;
          });
          setIndustryList({ ...temp });
        } else alert.error("error");
      })
      .catch((err) => {
        alert.error("Error Occured");
      });
  };

  function validURL(str) {
    var pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return !!pattern.test(str);
  }

  function submitWebsite(e) {
    setUpdatingWebsite(true);
    e.preventDefault();
    const fd = new FormData();
    const vu = validURL(post.website);
    if (vu == false) {
      if (post.website == "") {
        fd.append("website", post.website);
        axios
          .patch(url, fd, {
            headers: {
              Authorization: "Token " + token,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((res) => {
            if (res.data.success) {
              alert.success("Uploaded Website Successfully");
              setUpdatingWebsite(false);
            } else {
              alert.error(res.data.error);
              setUpdatingWebsite(false);
            }
          })
          .catch((error) => {
            alert.error("Error Occured");
            setUpdatingWebsite(false);
          });
      } else
        alert.error(
          "Enter valid Website URL. Please use HTTP/HTTPS protocol as well"
        );
    } else {
      fd.append("website", post.website);
      axios
        .patch(url, fd, {
          headers: {
            Authorization: "Token " + token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          if (res.data.success) {
            alert.success("Uploaded Website Successfully");
            setUpdatingWebsite(false);
          } else {
            alert.error(res.data.error);
            setUpdatingWebsite(false);
          }
        })
        .catch((error) => {
          alert.error("Error Occured");
          setUpdatingWebsite(false);
        });
    }
  }

  function submitAbout(e) {
    setUpdatingAbout(true);
    e.preventDefault();
    const fd = new FormData();

    fd.append("about", post.about);

    axios
      .patch(url, fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Description Successfully");
        } else {
          alert.error(res.data.error);
        }
        setUpdatingAbout(false);
      })
      .catch((error) => {
        alert.error("Error Occured");
        setUpdatingAbout(false);
      });
  }

  function submitIndustry(e) {
    setUpdatingIndustry(true);
    e.preventDefault();
    const fd = new FormData();

    fd.append("industry", industryList[industryType]);

    axios
      .patch(url, fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Industry Name Successfully");
        } else {
          alert.error(res.data.error);
        }
        setUpdatingIndustry(false);
      })
      .catch((error) => {
        alert.error("Error Occured");
        setUpdatingIndustry(false);
      });
  }

  function submitDate(e) {
    setUpdatingFounded(true);
    e.preventDefault();
    const fd = new FormData();

    fd.append("year_founded", post.year_founded);

    axios
      .patch(url, fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Updated Founding Year Successfully");
        } else {
          alert.error(res.data.error);
        }
        setUpdatingFounded(false);
      })
      .catch((error) => {
        alert.error("Error Occured");
        setUpdatingFounded(false);
      });
  }
  function submitSize(e) {
    setUpdatingSize(true);
    e.preventDefault();
    const fd = new FormData();

    fd.append("size", size);
    axios
      .patch(url, fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Uploaded Industry Size Successfully");
        } else {
          alert.error(res.data.error);
        }
        setUpdatingSize(false);
      })
      .catch((error) => {
        alert.error("Error Occured");
        setUpdatingSize(false);
      });
  }

  function submitSpcl(e) {
    setUpdatingSpecialities(true);
    e.preventDefault();
    const fd = new FormData();

    fd.append("specialities", post.specialities);
    axios
      .patch(url, fd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Uploaded Specialities Successfully");
        } else {
          alert.error(res.data.error);
        }
        setUpdatingSpecialities(false);
      })
      .catch((error) => {
        alert.error("Error Occured");
        setUpdatingSpecialities(false);
      });
  }

  function handle(e) {
    const newdata = { ...post };
    newdata[e.target.name] = e.target.value;
    setPost(newdata);
  }

  function imagehandle(e) {
    const newdata = { ...file };
    newdata[e.target.name] = e.target.files[0];

    setFile(newdata);

    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
    });
  }

  function imagesubmit(e) {
    setUpdatingBanner(true);
    e.preventDefault();
    const fdd = new FormData();

    fdd.append("banner", file.banner);

    axios
      .patch(url, fdd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Banner Uploaded Successfully");
          setCheck(true);
          toggle();

          dispatch(getCompanyUserDetails(history, alert));
        } else {
          alert.error(res.data.error);
        }
        setUpdatingBanner(false);
      })
      .catch((error) => {
        alert.error("Error Occured");
        setUpdatingBanner(false);
      });
  }

  function imagehandle1(e) {
    const newdata = { ...file };
    newdata[e.target.name] = e.target.files[0];

    setFile(newdata);
    setImagelogo({
      previewlogo: URL.createObjectURL(e.target.files[0]),
    });
  }

  function imagesubmit1(e) {
    setUpdatingLogo(true);
    e.preventDefault();
    const fdd = new FormData();

    fdd.append("logo", file.logo);

    axios
      .patch(url, fdd, {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success) {
          alert.success("Logo Uploaded Successfully");
          setCheckLogo(true);
          toggle1();

          dispatch(getCompanyUserDetails(history, alert));
        } else {
          alert.error(res.data.error);
        }
        setUpdatingLogo(false);
      })
      .catch((error) => {
        alert.error("Error Occured");
        setUpdatingLogo(false);
      });
  }

  const industries = Object.keys(industryList).map((c) => {
    return { name: c, value: c };
  });

  const Industry11 = () => (
    <SelectSearch
      value={industryType}
      onChange={(newValue) => {
        setIndustryType(newValue);
      }}
      options={industries.map((option) => ({
        name: option.name,
        value: option.name,
      }))}
      style={{ width: "100%" }}
    />
  );

  const companySizes = ["1-10", "11-50", "51-100", "101-1000", "1000+"];

  const CompanySize = () => (
    <SelectSearch
      value={size}
      onChange={(newValue) => {
        setIsSize(newValue);
      }}
      options={companySizes.map((option) => ({
        name: option,
        value: option,
      }))}
      style={{ width: "100%" }}
    />
  );

  return (
    <>
      <Container className="mt--7" fluid style={{ width: "96%" }}>
        <div style={{ marginTop: "25px" }}>
          <Card
            className="shadow"
            style={{
              borderRadius: "30px",
              backdropFilter: "blur(30px)",
              boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
              border: "solid 1px #f1f1f1",
              backgroundColor: "#ffffff",
            }}
          >
            <CardBody>
              {/* <Button
              variant="contained"
              style={{
                float: "right",
              }}
              onClick={toggle}
            > */}
              <img
                onClick={toggle}
                src={pic}
                fontSize="inherit"
                alt=""
                height="30px"
                style={{ float: "right", cursor: "pointer" }}
              />
              {/* <Icon name="pencil" size="miny" />
              Add/Edit Cover Photo */}
              {/* </Button> */}

              <PictureChangeDialog
                open={modal}
                handleClickOpen={toggle}
                handleClose={toggle}
                image={image.preview}
                banner={banner}
                updatingBanner={updatingBanner}
                fakebanner={fakebanner}
                imagehandle={imagehandle}
                imagesubmit={imagesubmit}
              />

              <div
                style={
                  banner
                    ? {
                        width: "100%",
                        height: "200px",
                        backgroundImage: `url(${
                          check == true ? image.preview : banner
                        })`,
                        boxShadow: "2px 2px 10px #9E9E9E",
                        zIndex: "-10",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "30px",
                      }
                    : {
                        width: "100%",
                        height: "200px",
                        backgroundImage: `url(${
                          check == true ? image.preview : fakebanner
                        })`,
                        boxShadow: "2px 2px 10px #9E9E9E",
                        zIndex: "-10",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "30px",
                      }
                }
              >
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div>
                  <Button
                    onClick={toggle1}
                    style={{
                      borderRadius: "50%",
                      padding: "0px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginRight: "auto",
                      marginLeft: "auto",
                      float: "center",
                      backgroundColor: "white",
                      borderColor: "white",
                    }}
                  >
                    <img
                      onClick={toggle1}
                      src={pic}
                      fontSize="inherit"
                      alt=""
                      height="30px"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        zIndex: 100,
                        position: "fixed",
                        left: "53%",
                        top: "25%",
                      }}
                    />
                    {logo ? (
                      <img
                        src={checkLogo == true ? imagelogo.previewlogo : logo}
                        alt="logo"
                        height="150px"
                        width="160px"
                        style={{
                          border: "6px solid rgb(121 185 242)",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginRight: "auto",
                          marginLeft: "auto",
                          float: "center",
                        }}
                      />
                    ) : (
                      <img
                        src={
                          checkLogo == true ? imagelogo.previewlogo : fakelogo
                        }
                        alt="logo"
                        height="150px"
                        width="160px"
                        style={{
                          border: "6px solid rgb(121 185 242)",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          marginRight: "auto",
                          marginLeft: "auto",
                          float: "center",
                        }}
                      />
                    )}
                  </Button>
                </div>
              </div>

              <PictureChangeDialog
                open={modall}
                handleClickOpen={toggle1}
                handleClose={toggle1}
                image={imagelogo.previewlogo}
                banner={logo}
                updatingBanner={updatingLogo}
                fakebanner={fakelogo}
                imagehandle={imagehandle1}
                imagesubmit={imagesubmit1}
                logo={true}
              />

              <br />
              <br />

              <Row style={{ marginTop: "11%" }}>
                <Col xl="12" style={{ marginBottom: "-15px" }}>
                  <h3>{isName}</h3>
                  <p style={{ color: "grey" }}>
                    {industry} • {city}, {state} • {size} Employees
                  </p>
                </Col>
              </Row>

              <hr />

              <h6>
                About Company
                <span
                  style={{
                    color: "#828282",
                    fontStyle: "italic",
                    fontSize: "12px",
                  }}
                >
                  (MAX. 255 CHARACTERS --{" "}
                  {post?.about ? 255 - post?.about?.length : 255} left)
                </span>
              </h6>
              <Form onSubmit={(e) => submitAbout(e)}>
                <Input
                  onChange={(e) => handle(e)}
                  type="textarea"
                  name="about"
                  value={post.about}
                  rows="5"
                  maxLength="255"
                  style={{ borderRadius: "25px" }}
                />
                <br />

                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    width: "122px",
                    height: "36px",
                    borderRadius: "30px",
                  }}
                  disabled={updatingAbout}
                >
                  {updatingAbout ? (
                    <CircularProgress size={25} style={{ color: "#ffffff" }} />
                  ) : (
                    "Update About"
                  )}
                </Button>
              </Form>

              <br />
              <br />
              <Row>
                <Col sm="2">
                  <h6>Website</h6>
                </Col>
                <Col sm={6}>
                  <Form onSubmit={(e) => submitWebsite(e)}>
                    <Row>
                      <Col sm="7">
                        <Input
                          onChange={(e) => handle(e)}
                          type="textarea"
                          name="website"
                          id=""
                          value={post.website}
                          rows="1"
                          style={{ width: "100%", borderRadius: "30px" }}
                        />
                      </Col>
                      <Col sm="5">
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={updatingWebsite}
                          style={{
                            width: "165px",
                            height: "36px",
                            borderRadius: "30px",
                          }}
                        >
                          {updatingWebsite ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#ffffff" }}
                            />
                          ) : (
                            "Update Website"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm="2">
                  <h6>Industry</h6>
                </Col>
                <Col sm="6">
                  <Form onSubmit={(e) => submitIndustry(e)}>
                    <Row>
                      <Col sm="7">
                        <Industry11 />
                      </Col>
                      <Col sm="5">
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={updatingIndustry}
                          style={{
                            width: "165px",
                            height: "36px",
                            borderRadius: "30px",
                          }}
                        >
                          {updatingIndustry ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#ffffff" }}
                            />
                          ) : (
                            "Update Industry"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <br />
              <Row>
                <Col md="2">
                  <h6>Employees</h6>
                </Col>
                <Col sm="6">
                  <Form onSubmit={(e) => submitSize(e)}>
                    <Row>
                      <Col md="7">
                        <CompanySize />
                      </Col>
                      <Col md="5">
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={updatingSize}
                          style={{
                            width: "165px",
                            height: "36px",
                            borderRadius: "30px",
                          }}
                        >
                          {updatingSize ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#ffffff" }}
                            />
                          ) : (
                            "Update Size"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <br />

              <Row>
                <Col sm="2">
                  <h6>Founded</h6>
                </Col>
                <Col sm="6">
                  <Form onSubmit={(e) => submitDate(e)}>
                    <Row>
                      <Col sm="7">
                        <Input
                          onChange={(e) => handle(e)}
                          type="date"
                          name="year_founded"
                          id=""
                          value={post.year_founded}
                          rows="1"
                          style={{ width: "100%", borderRadius: "30px" }}
                        />
                      </Col>
                      <Col sm="5">
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={updatingFounded}
                          style={{
                            width: "165px",
                            height: "36px",
                            borderRadius: "30px",
                          }}
                        >
                          {updatingFounded ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#ffffff" }}
                            />
                          ) : (
                            "Update Year"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm="2">
                  <h6>Specialities</h6>
                </Col>
                <Col sm="6">
                  <Form onSubmit={(e) => submitSpcl(e)}>
                    <Row>
                      <Col sm="7">
                        <Input
                          onChange={(e) => handle(e)}
                          type="textarea"
                          name="specialities"
                          id=""
                          value={post.specialities}
                          rows="5"
                          placeholder="*Company specialities serve as keywords for your Company Page that will help people find your business easier."
                          style={{ width: "100%", borderRadius: "25px" }}
                        />
                      </Col>
                      <Col sm="5">
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={updatingSpecialities}
                          style={{
                            width: "165px",
                            height: "36px",
                            borderRadius: "30px",
                          }}
                        >
                          {updatingSpecialities ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "#ffffff" }}
                            />
                          ) : (
                            "Update Speacialities"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

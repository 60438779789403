import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import user from "../../../../assets/png/user.png";
import ScoreDialog from "../../../Dialog/ScoreDialog";
import { CalendarTodayOutlined } from "@material-ui/icons";
import { moment } from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 15,
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    //maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    height: "100px",
    width: "100px",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },
  linkColor: {
    //  color: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  green: {
    width: "10px",
    marginLeft: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#12DC00",
  },
  red: {
    width: "10px",
    marginLeft: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "red",
  },
  resume: {
    height: "35px",
    boxShadow: "none",
    display: "flex",
    borderRadius: "30px",
    marginTop: "20px",
    fontWeight: "normal",
  },
  button: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    height: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  resumebtn: {
    borderRadius: "30px",
    boxShadow: "none",
    height: "40px",
    display: "flex",
    marginRight: "10px",
  },
}));

export default function OverView({
  data,
  relevanceScore,
  scoreData,
  createdDate,
  actionsData,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase className={classes.image}>
              <img
                className={classes.img}
                alt="complex"
                src={data?.profileImage ? data?.profileImage : user}
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid
              item
              xs
              container
              direction="column"
              spacing={2}
              style={{ display: "contents" }}
            >
              <div
                className={classes.text}
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6" style={{ marginRight: "10px" }}>
                    {data?.name}
                  </Typography>

                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.002 11L19.562 8.21L19.902 4.52L16.292 3.7L14.402 0.5L11.002 1.96L7.60195 0.5L5.71195 3.69L2.10195 4.5L2.44195 8.2L0.00195312 11L2.44195 13.79L2.10195 17.49L5.71195 18.31L7.60195 21.5L11.002 20.03L14.402 21.49L16.292 18.3L19.902 17.48L19.562 13.79L22.002 11ZM9.09195 15.72L5.29195 11.91L6.77195 10.43L9.09195 12.76L14.942 6.89L16.422 8.37L9.09195 15.72Z"
                      fill="#00ADEF"
                    />
                  </svg>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  {/* <Typography variant="body2">
                    Status: {data?.isActive ? "Active" : "Blocked"}
                  </Typography> */}
                  {/* <div className={data?.isActive ? classes.green : classes.red} /> */}
                  {scoreData && (
                    <ScoreDialog score={relevanceScore} scoreData={scoreData} />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {actionsData?.create_date && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <CalendarTodayOutlined
                        size={14}
                        style={{ color: "#6C757D" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                          marginLeft: 10,
                        }}
                      >
                        <Typography style={{ fontSize: 14, color: "#6C757D" }}>
                          {" "}
                          Applied On: {actionsData?.create_date}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
                {/* {scoreData && <ScoreDialog score={relevanceScore} scoreData={scoreData} />} */}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            marginLeft: "10px",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.resumebtn}
          >
            <a
              href={data?.resume}
              download
              target="blank"
              className={classes.linkColor}
              disabled={data?.resume ? false : true}
            >
              View Resume
            </a>
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{ marginLeft: 10 }}
          >
            Download
          </Button> */}
        </div>
      </div>
    </>
  );
}

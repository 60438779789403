import { Hidden } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Footer from "../../../components/public/FooterNew/Footer";
import EmailVerified from "../../../components/public/LogIn/Card/Verifications/emailVerified";
import EmailVerify from "../../../components/public/LogIn/Card/Verifications/emailVerify";
import MobileTabRegister from "../../../components/public/LogIn/Header/MobileTabRegister";
import StudentHeader from "../../../components/public/LogIn/Header/StudentHeader";
import Navbar from "../../../components/public/Navbar/Navbar";
import { BackendBaseApi } from "../../../constants/constants";

function VerifyEmail() {
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [uid, setUid] = useState("");
  const [token, setToken] = useState("");

  const verifyUserEmail = () => {
    Axios.post(BackendBaseApi.PRAVESH + "api/email/verify", {
      uid: uid,
      token: token,
    })
      .then((res) => {
        if (res.data.success) alert.success(res.data.data.message);
        else {
          alert.error(res.data.error);
          return;
        }
      })
      .catch((err) => {
        localStorage.clear();
        alert.error("Invalid Session!! Please try again");
        throw err;
      });
  };

  useEffect(() => {
    if (uid && token) verifyUserEmail();
  }, [uid, token]);

  useEffect(() => {
    let temp = location.pathname.split("/");
    setUid(temp[2]);
    setToken(temp[3]);
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Navbar />

      <Hidden smDown>
        {" "}
        <StudentHeader showGrid={false} verifiedEmail={true} type={"verified-email"} />
      </Hidden>

      <Hidden smUp>
        {" "}
        <div style={{ marginTop: "20%" }}>
          <EmailVerified />
        </div>
      </Hidden>
      <Footer />
    </>
  );
}

export default VerifyEmail;

import { makeStyles, Tooltip } from "@material-ui/core";
import React, { useState, useEffect, Fragment } from "react";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}));

const useStylesBootstrapHeight = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    maxHeight: "600px",
    maxWidth: "700px",
    overflowY: "scroll",
  },
}));

export default function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export function BootstrapTooltipNew(props) {
  const classes = useStylesBootstrapHeight();

  return <Tooltip arrow classes={classes} {...props} />;
}

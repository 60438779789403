import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  createStyles,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";

export const sampleData = [
  {
    company_id: 2,
    company_name: "1",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "2",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "3",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "4",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "5",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "6",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "7",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "8",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "9",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "10",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "11",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "12",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "13",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "14",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "15",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "16",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "17",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "18",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
  {
    company_id: 2,
    company_name: "19",
    company_user: {
      first_name: "abcd",
      last_name: "name",
      phone: 12345678900,
    },
    ctc_min: 6,
    ctc_max: 800000,
    ctc_av: 352750.65,
    hired: 4,
  },
  {
    company_id: 2,
    company_name: "20",
    company_user: {
      first_name: "abcdefg",
      last_name: "name-test",
      phone: 9999,
    },
    ctc_min: 26,
    ctc_max: 802,
    ctc_av: 350.65,
    hired: 41,
  },
];

const ReportsTable = ({ columns, data }) => {
  const classes = useStyles();

  const skills = useSelector((state) => state.Reports.skills);

  const [page, setPage] = useState(1);

  const createData = (arr) => {
    return arr.map((item) => ({ item }));
  };

  const findTableCell = (obj, key) => {
    if (obj) {
      switch (key) {
        case "Company name":
          return obj.company_name;
        case "Hired":
          return obj.hired;
        case "Min CTC*":
          return obj.ctc_min;
        case "Max CTC*":
          return obj.ctc_max;
        case "Avg. CTC*":
          return obj.ctc_avg ? obj.ctc_avg.toFixed(2) : "";
        case "Contact":
          return obj.company_user.phone;
        case "Contact Person":
          return `${obj.company_user.first_name} ${obj.company_user.last_name}`;
        case "Total Students":
          return obj.total_students
            ? obj.total_students
            : obj.total_students_registered;
        case "Total Skills":
          return obj.total_skills;
        case "Most Popular Skill":
          return obj.most_popular_skill_name;
        case "Avg. Skills per Student":
          return skills
            ? skills.average_skill_per_student.toFixed(2)
            : obj.average_skill_per_student;
        case "Students placed":
          return obj.placed_students;
        case "Course":
          return obj.course;
        case "Branch":
          return obj.branch;
        case "Offers":
          return obj.offers;
        case "Total Offers":
          return obj.total_offers;
        case "Total Hired":
          return obj.total_hired;
        case "% Placed":
          return obj.placed_percentage ? obj.placed_percentage.toFixed(2) : "";

        default:
          return "";
      }
    }
  };

  const generateCell = (item, col) => {
    return findTableCell(item, col.item);
  };

  const handlePaginationChange = (event, page) => {
    setPage(page);
  };

  return data !== undefined ? (
    <Box margin="2% 0%">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHead}>
              {createData(columns).map((item, index) => (
                <TableCell key={index} className={classes.headCell}>
                  {item.item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice((page - 1) * 12, page * 12).map((item, index) => (
              <TableRow
                key={index}
                className={
                  index % 2 !== 0 || index === 1 ? classes.colouredRow : null
                }
              >
                {createData(columns).map((col, colInd) => (
                  <TableCell key={colInd}>{generateCell(item, col)}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data.length + 1 > 12 && (
        <Box display="flex" justifyContent="center" marginTop="3%">
          <Pagination
            count={Math.round((data.length + 1) / 12)}
            classes={{ ul: classes.ul }}
            onChange={(event, page) => handlePaginationChange(event, page)}
          />
        </Box>
      )}
    </Box>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop="25%"
    >
      <CircularProgress className={classes.progress} />
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    headCell: {
      color: theme.palette.type === "dark" ? "#3282c4" : "#fff",
      fontWeight: "bold",
    },
    tableHead: {
      backgroundColor: "#424242",
    },
    colouredRow: {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.background.paper
          : "#EEFBFF",
    },
    ul: {
      "& button": {
        "&:focus": {
          outline: "none",
        },
        color: "#3282C4",
      },
    },
    progress: {
      color: "#3282C4",
    },
  })
);

export default ReportsTable;

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import ez from "../../assets/ez.gif";
import failed from "../../assets/loader_failed.gif";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";

const products = [
  { name: "Amount Paid", desc: "", price: "₹ 100" },
  { name: "Bank", desc: "", price: "Paytm" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper1: {
    padding: theme.spacing(1),
    textAlign: "center",
    height: "270px",
    color: theme.palette.text.secondary,
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    width: "100%",
  },

  recharge: {
    borderRadius: "30px",
    marginBottom: "12px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#32B2F9",
  },
}));

export default function Payment_sucess(props) {
  const { openCheck, orderDetails } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(openCheck);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [maxWidth, setMaxWidth] = React.useState("md");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let hangmanImage = null;
  let message = null;
  let colorDisplayed = null;

  switch (orderDetails.order_status) {
    case "TXN_SUCCESS":
      hangmanImage = ez;
      message = `Recharge of amount ₹ ${orderDetails.order_amount} successful!`;
      colorDisplayed = "green";
      break;
    case "TXN_FAILURE":
      hangmanImage = failed;
      message = `Payment Failed of amount ₹ ${orderDetails.total_amount}! Please Try again.`;
      colorDisplayed = "red";

      break;
    case "PENDING":
      hangmanImage = failed;
      message = "Payment status Pending. Please Check after sometime. !";
      colorDisplayed = "red";

      break;
    default:
      hangmanImage = null;
      message = "";
  }

  return (
    <>
      {/* <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        className={classes.recharge}
      >
        Pay Now
      </Button> */}
      <Dialog
        //fullScreen={fullScreen}
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          padding="3%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <img src={hangmanImage} width="50%" height="auto" />

          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
              color: colorDisplayed,
            }}
          >
            {message}
          </p>

          <p
            style={{
              fontSize: "17px",
              textAlign: "center",
            }}
          >
            Transaction Number : {orderDetails.txn_id}
          </p>

          {/*            
        <Grid container spacing={3}>
        <Grid item xs={12} ><Divider/></Grid>

   
        <Grid item xs={12} >
          <Paper className={classes.paper1}>     
   

      <List disablePadding>
        {products.map((product) => (
          <ListItem className={classes.listItem} key={product.name}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
        <Divider/>

        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
          ₹ 100
          </Typography>
        </ListItem>
       </List> 
        </Paper>
        </Grid>

        
      </Grid>
   */}
        </Box>
      </Dialog>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import CommonButton from "../../common/CommonButton/CommonButton";
import refer_img from "../../../assets/common/refer.png";
import { createStyles, makeStyles } from "@material-ui/core";
import CommonIconButton from "../../common/CommonButton/CommonIconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import { useAlert } from "react-alert";
export default function ReferralCode(props) {
  const {
    open,
    handleClickOpen,
    handleClose,
    errorMessages,
    setErrorMessages,
    userDetails,
    setUserDetails,
    setButtonClicked,
    finalReferralCode,
    setFinalReferralCode,
    setRefferalMessage,
  } = props;
  const classes = useStyles();
  const alert = useAlert();

  const checkValidReferral = () => {
    Axios.post(BackendBaseApi.PRAVESH + "api/referrals/check_referral/", {
      type: "company",
      referral_code: userDetails.Referral,
    })
      .then((res) => {
        if (res.data.success) {
          handleClose();
          setFinalReferralCode(userDetails.Referral);
          setRefferalMessage(res.data.data.message);

          alert.success(res.data.data.message);
        } else {
          alert.error(res.data.error);
          setErrorMessages({ ...errorMessages, referral: res.data.error });
        }
      })
      .catch((err) => {});
  };

  const validateData = () => {
    if (userDetails.Referral === "") setErrorMessages({ ...errorMessages, referral: "Please Enter your Referral code" });
    else checkValidReferral();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>

        <DialogContent>
          <img src={refer_img} style={{ width: "483px", margin: "auto", display: "flex " }} alt="" />
          <div style={{ width: "70%", margin: "auto" }}>
            <TextField
              error={errorMessages.referral !== "" ? true : false}
              key="jhvjh"
              id="standard-error-helper-text-1"
              variant="outlined"
              type="text"
              label={<span>Enter your Referral Code</span>}
              helperText={<span style={errorMessages.referral !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.referral}</span>}
              value={userDetails.Referral}
              onChange={(e) => {
                setUserDetails({ ...userDetails, Referral: e.target.value });
                setErrorMessages({ ...errorMessages, referral: "" });
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={{
                style: { backgroundColor: "#fff" },
              }}
              className={classes.fieldStyling}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <CommonIconButton onClick={handleClose}>
            <CloseIcon />
          </CommonIconButton>{" "}
          <CommonIconButton onClick={() => validateData()}>
            <ArrowForwardIcon />
          </CommonIconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    formBox: {
      background: "#fff",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      borderRadius: 30,
      padding: 20,
      width: "100%",
      marginBottom: 65,
    },
    heading: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    headingNew: {
      textAlign: "left",
      fontSize: 14,
      // fontWeight: "bold",
      marginBottom: "0rem",
      color: "#4f4f4f",
    },
    subTitle: {
      color: "grey",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 25,
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    fieldStyling: {
      width: "100%",
      marginBottom: 20,
      marginTop: "20%",
    },
  })
);

import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { generateCsvWithoutSpacing } from "../../common/generateCsv/generateCsv";
import { BackendBaseApi } from "../../constants/constants";
import fetchApi from "../apiCall/fetchApi";
import EmployerTab from "../components/Common/Tabs/EmployerTabs";
import ShareApplicant from "../components/Dialog/ShareApplicant";
import axios from "axios";
import { ReactComponent as NewError } from "../assets/svg/NewError.svg";

const useStyles = makeStyles((theme) => ({
  share: {
    borderRadius: "30px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

function TrackEmployee() {
  const classes = useStyles();
  const [jobList, setJobList] = useState(null);
  const [navbarData, setNavbarData] = useState(null);
  const [applicantData, setApplicantData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [applicantDataError, setApplicantDataError] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterDataError, setFilterDataError] = useState(false);
  const [navbarDataError, setNavbarDataError] = useState(null);
  const [open, setOpen] = useState(false);
  const alert = useAlert();
  const [activeStatusObject, setActiveStatusObject] = useState({
    count: 0,
    heading: "Applied",
    param: "&status_id=MQ==",
    round_id: null,
    status_id: 1,
    tooltip: "Applied",
  });
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobOptionClicked, setJobOptionClicked] = useState(false);
  const [selectedRow, setSelectedRow] = useState({
    details: [],
    selectedIds: [],
  });
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [clickFilterApplyButton, setClickFilterApplyButton] = useState(false);
  const [oneApiCallMade, setOneApiCallMade] = useState(false);
  const [searchByName, setSearchByName] = useState({
    searchString: "",
    searchNow: "stable",
  });
  const [pageData, setPageData] = useState({ currentPage: 0, pageSize: 10 });
  const [paginationResponse, setPaginationResponse] = useState(null);
  const [applicantCountData, setApplicantCountData] = useState(null);
  const [totalApplicantList, setTotalApplicantList] = useState([]);
  const [selectedApplicantList, setSelectedApplicantList] = useState([]);
  const initialSortData = { id: 9, name: "Applied On", value: "create_time" };
  const [sort, setSort] = useState(initialSortData);
  const [order, setOrder] = useState("dsc");

  const checkSelectedJob = (string) => {
    if (selectedJob) return `${string}job_id=${btoa(selectedJob?.job_id)}`;
    else return ``;
  };

  const checkSearchByName = () => {
    if (searchByName?.searchNow) return `&search=${searchByName?.searchString}`;
    else return ``;
  };

  const CheckSort = () => {
    if (order === "dsc" && sort.name === "Candidate Name") {
      const firstAndLast = sort.value.split(",");
      return `&sort_by=-${firstAndLast[0]},-${firstAndLast[1]}`;
    }
    return order === "asc"
      ? `&sort_by=${sort.value}`
      : `&sort_by=-${sort.value}`;
  };

  const checkFilterString = () => {
    let filterString = "";
    if (selectedFilters && Object.keys(selectedFilters).length > 0)
      Object.keys(selectedFilters).forEach((item) => {
        if (selectedFilters[item]?.length > 0) {
          filterString = `${filterString}&${item}=${generateCsvWithoutSpacing(
            selectedFilters[item],
            "id"
          )}`;
        }
      });
    return filterString;
  };

  const checkPageData = () => {
    return `&page=${pageData?.currentPage + 1}&item_per_page=${
      pageData?.pageSize
    }`;
  };

  useEffect(() => {
    let JobId = atob(getSearchParameters()?.job_id);
    if (JobId) {
      //console.log("1111111");
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_list/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }&job_id=${btoa(JobId)}`,
        setResponse: setJobList,
      });
      //console.log("22222222");
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/navbar/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}&job_id=${btoa(JobId)}`,
        setResponse: setNavbarData,
        setError: setNavbarDataError,
      });
      //console.log("3333333");
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/applicant/count/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}&job_id=${btoa(JobId)}`,
        setResponse: setApplicantCountData,
      });
    }
  }, [location.search]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    if (activeStatusObject?.param) {
      let JobId = atob(getSearchParameters()?.job_id);
      let filterString = checkFilterString();
      //console.log("4444444444");
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/main/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${activeStatusObject?.param}&job_id=${btoa(
          JobId
        )}${filterString}${CheckSort()}`,
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
        setOneApiCallMade: setOneApiCallMade,
        source: source,
      });
      //console.log("55555555555");
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/navbar/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}&job_id=${btoa(JobId)}`,
        setResponse: setNavbarData,
        source: source,
        setError: setNavbarDataError,
      });
      //console.log("66666666");
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/filter/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${activeStatusObject?.param}&job_id=${btoa(JobId)}`,
        setResponse: setFilterData,
        setLoading: setFilterLoading,
        setError: setFilterDataError,
        source: source,
      });
      setSelectedFilters(null);
      // setOrder("asc");
      // setSort({
      //   id: 6,
      //   name: "Relevance score",
      //   value: "student_score",
      // });
      setOneApiCallMade(false);
      setPageData({ currentPage: 0, pageSize: 10 });
    }

    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [activeStatusObject]);

  useEffect(() => {
    if (applicantData && applicantData?.data?.results?.job_id && !selectedJob) {
      let latestJobId = null;
      if (Object.keys(getSearchParameters())?.length === 0) {
        latestJobId = applicantData?.data?.results?.job_id;
      } else {
        latestJobId = atob(getSearchParameters()?.job_id);
        setJobOptionClicked(true);
      }
      let latestJob = jobList?.data?.find(
        (item) => item?.job_id === Number(latestJobId)
      );
      setSelectedJob(latestJob);
    }
  }, [applicantData, jobList, selectedJob, location.search]);

  useEffect(() => {
    let filterString = "";

    if (clickFilterApplyButton) {
      filterString = checkFilterString();
      setApplicantData(null);
      setTotalApplicantList([]);
      // fetchApi({
      //   url: `${
      //     BackendBaseApi.NIYUKTI
      //   }api/manage_jobs/company/navbar/?company_id=${btoa(
      //     JSON.parse(localStorage.getItem("company")).company
      //   )}${checkSelectedJob(
      //     "&"
      //   )}${filterString}${checkSearchByName()}${CheckSort()}`,
      //   setResponse: setNavbarData,
      // });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/main/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${activeStatusObject?.param}${checkSelectedJob(
          "&"
        )}${filterString}${checkSearchByName()}${CheckSort()}`,
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
      setClickFilterApplyButton(false);
      // setSearchByName({ ...searchByName, searchNow: "stable" });
    }
  }, [clickFilterApplyButton]);

  useEffect(() => {
    let filterString = "";
    if (
      searchByName?.searchNow === "search" ||
      searchByName?.searchNow === "cancel"
    ) {
      filterString = checkFilterString();

      // fetchApi({
      //   url: `${
      //     BackendBaseApi.NIYUKTI
      //   }api/manage_jobs/company/navbar/?company_id=${btoa(
      //     JSON.parse(localStorage.getItem("company")).company
      //   )}${checkSelectedJob(
      //     "&"
      //   )}${filterString}${checkSearchByName()}${CheckSort()}`,
      //   setResponse: setNavbarData,
      // });
      setApplicantData(null);
      setTotalApplicantList([]);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/main/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${activeStatusObject?.param}${checkSelectedJob(
          "&"
        )}${filterString}${checkSearchByName()}${CheckSort()}`,
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
      setClickFilterApplyButton(false);
      setSearchByName({ ...searchByName, searchNow: "stable" });
    }
  }, [searchByName?.searchNow]);

  useEffect(() => {
    let JobId = atob(getSearchParameters()?.job_id);
    if (sort) {
      let filterString = checkFilterString();
      setApplicantData(null);
      setTotalApplicantList([]);
      //console.log("7777777777");
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/main/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${activeStatusObject?.param}&job_id=${btoa(
          JobId
        )}${filterString}${checkSearchByName()}${CheckSort()}`,
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
    }
  }, [sort, order]);

  function getSearchParameters() {
    var prmstr = location.search.substr(1);
    return prmstr != null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
  }

  function transformToAssocArray(prmstr) {
    var params = {};
    var prmarr = prmstr.split("&");
    for (var i = 0; i < prmarr.length; i++) {
      var tmparr = prmarr[i].split("=");
      params[tmparr[0]] = tmparr[1];
    }
    return params;
  }

  // useEffect(() => {
  //   if (selectedJob && jobOptionClicked) {
  //     console.log("8888888888");
  //     fetchApi({
  //       url: `${
  //         BackendBaseApi.NIYUKTI
  //       }api/manage_jobs/company/navbar/?company_id=${btoa(
  //         JSON.parse(localStorage.getItem("company")).company
  //       )}${checkSelectedJob("&")}`,
  //       setResponse: setNavbarData,
  //       setError: setNavbarDataError,
  //     });
  //     console.log("9999999999");
  //     fetchApi({
  //       url: `${
  //         BackendBaseApi.NIYUKTI
  //       }api/manage_jobs/company/main/?company_id=${btoa(
  //         JSON.parse(localStorage.getItem("company")).company
  //       )}${activeStatusObject?.param}${checkSelectedJob("&")}`,
  //       setResponse: setApplicantData,
  //       setLoading: setLoading,
  //       setError: setApplicantDataError,
  //       setPagination: setPaginationResponse,
  //     });
  //     console.log("10000000000");
  //     fetchApi({
  //       url: `${
  //         BackendBaseApi.NIYUKTI
  //       }api/manage_jobs/company/filter/?company_id=${btoa(
  //         JSON.parse(localStorage.getItem("company")).company
  //       )}${activeStatusObject?.param}${checkSelectedJob("&")}`,
  //       setResponse: setFilterData,
  //       setLoading: setFilterLoading,
  //       setError: setFilterDataError,
  //     });
  //     console.log("11 111 11 1 11 11 1");
  //     fetchApi({
  //       url: `${
  //         BackendBaseApi.NIYUKTI
  //       }api/manage_jobs/company/applicant/count/?company_id=${btoa(
  //         JSON.parse(localStorage.getItem("company")).company
  //       )}${checkSelectedJob("&")}`,
  //       setResponse: setApplicantCountData,
  //     });

  //     setValue(0);
  //     window.history.pushState(
  //       {},
  //       null,
  //       `/company/manage-job/?${checkSelectedJob("")}`
  //     );
  //     setJobOptionClicked(false);
  //     setSelectedFilters(null);
  //   }
  // }, [selectedJob, jobOptionClicked, location.search]);

  useEffect(() => {
    if (oneApiCallMade) {
      let filterString = checkFilterString();
      setTotalApplicantList([]);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/main/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${activeStatusObject?.param}${checkSelectedJob(
          "&"
        )}${checkPageData()}${filterString}${checkSearchByName()}${CheckSort()}`,
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
    }
  }, [pageData]);

  const callBackMainApplicantApi = (unlockByclick = false) => {
    let JobId = atob(getSearchParameters()?.job_id);
    let filterString = checkFilterString();
    /*   if (unlockByclick) {
     
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/main/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${activeStatusObject?.param}&job_id=${btoa(
          JobId
        )}${checkPageData()}${filterString}${CheckSort()}`,
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
    } else {
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/main/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${activeStatusObject?.param}${checkSelectedJob(
          "&"
        )}${checkPageData()}${filterString}${CheckSort()}`,
        setResponse: setApplicantData,
        setLoading: setLoading,
        setError: setApplicantDataError,
        setPagination: setPaginationResponse,
      });
    } */

    // fetchApi({
    //   url: `${
    //     BackendBaseApi.NIYUKTI
    //   }api/manage_jobs/company/navbar/?company_id=${btoa(
    //     JSON.parse(localStorage.getItem("company")).company
    //   )}&job_id=${btoa(JobId)}`, //${checkSelectedJob("&")}
    //   setResponse: setNavbarData,
    // });
    fetchApi({
      url: `${
        BackendBaseApi.NIYUKTI
      }api/manage_jobs/company/applicant/count/?company_id=${btoa(
        JSON.parse(localStorage.getItem("company")).company
      )}&job_id=${btoa(JobId)}`,
      setResponse: setApplicantCountData,
    });
  };

  const getNavBarData = () => {
    let JobId = atob(getSearchParameters()?.job_id);
    fetchApi({
      url: `${
        BackendBaseApi.NIYUKTI
      }api/manage_jobs/company/navbar/?company_id=${btoa(
        JSON.parse(localStorage.getItem("company")).company
      )}&job_id=${btoa(JobId)}`,
      setResponse: setNavbarData,
      setError: setNavbarDataError,
    });
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  useEffect(() => {
    if (selectedRow?.selectedIds?.length > 0) {
      let tempArray = [...totalApplicantList];
      tempArray = tempArray.filter((item) =>
        selectedRow.selectedIds.includes(item?.id)
      );
      setSelectedApplicantList(tempArray);
    } else setSelectedApplicantList([]);
  }, [selectedRow]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link href="/company/all-jobs" style={{ textDecoration: "none" }}>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "400" }}
          >
            Manage Jobs
          </Typography>
        </Link>
        <Link
          href={`/public/job/${
            selectedJob?.hiring_type === "OFF CAMPUS" ? "offcampus" : "oncampus"
          }/${selectedJob?.job_id}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Typography
            variant="body2"
            color="primary"
            style={{ fontWeight: "400" }}
          >
            {selectedJob?.job_title}
          </Typography>
        </Link>
        <Link color="textPrimary" aria-current="page">
          Applicants
        </Link>
      </Breadcrumbs>
      {/* <div style={{ marginTop: "20px" }}>
        {jobList?.data && (
          <EmployerField
            jobList={jobList?.data}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            setJobOptionClicked={setJobOptionClicked}
          />
        )}
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // marginTop: "10px",
          alignItems: "center",
          // marginBottom: "10px",
        }}
      >
        <div>
          <Typography variant="h2">Job Applicants</Typography>
          {/* <Typography variant="body2" style={{ color: "#6C757D", fontWeight: "400" }}>
            Track all applicants job status
          </Typography> */}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <ShareApplicant
            navbarData={navbarData?.data}
            selectedJob={selectedJob}
          /> */}
          <Button
            variant="contained"
            className={classes.share}
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
            startIcon={<ShareOutlinedIcon />}
          >
            Share
          </Button>
          {selectedApplicantList?.length > 0 &&
          selectedApplicantList?.some((item) => item?.is_locked) ? (
            <Button
              startIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
              color="primary"
              style={{
                height: "40px",
                borderRadius: "30px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                boxShadow: "none",
                marginLeft: "20px",
              }}
              onClick={() => alert.error("Please unlock the profiles first!!")}
            >
              Export{" "}
            </Button>
          ) : (
            selectedApplicantList?.length > 0 && (
              <CSVLink
                data={selectedApplicantList}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                filename={"Applicants.csv"}
              >
                <Button
                  startIcon={<FileDownloadOutlinedIcon />}
                  variant="contained"
                  color="primary"
                  style={{
                    height: "40px",
                    borderRadius: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    display: "flex",
                    boxShadow: "none",
                    marginLeft: "20px",
                  }}
                >
                  Export{" "}
                </Button>
              </CSVLink>
            )
          )}
          {selectedApplicantList?.length === 0 &&
          totalApplicantList?.length > 0 &&
          totalApplicantList?.some((item) => item?.is_locked) ? (
            <Button
              startIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
              color="primary"
              style={{
                height: "40px",
                borderRadius: "30px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                boxShadow: "none",
                marginLeft: "20px",
              }}
              onClick={() => alert.error("Please unlock the profiles first!!")}
            >
              Export{" "}
            </Button>
          ) : (
            selectedApplicantList?.length === 0 && (
              <CSVLink
                data={totalApplicantList}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                filename={"Applicants.csv"}
              >
                <Button
                  startIcon={<FileDownloadOutlinedIcon />}
                  variant="contained"
                  color="primary"
                  style={{
                    height: "40px",
                    borderRadius: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    display: "flex",
                    boxShadow: "none",
                    marginLeft: "20px",
                  }}
                >
                  Export{" "}
                </Button>
              </CSVLink>
            )
          )}
        </div>
      </div>

      {!navbarDataError && navbarData?.data ? (
        <EmployerTab
          navbarData={navbarData?.data}
          setActiveStatusObject={setActiveStatusObject}
          applicantData={applicantData}
          applicantDataError={applicantDataError}
          filterData={filterData?.data}
          filterDataError={filterDataError}
          filterLoading={filterLoading}
          loading={loading}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          activeStatusObject={activeStatusObject}
          value={value}
          setValue={setValue}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          setClickFilterApplyButton={setClickFilterApplyButton}
          searchByName={searchByName}
          setSearchByName={setSearchByName}
          pageData={pageData}
          setPageData={setPageData}
          paginationResponse={paginationResponse}
          setPaginationResponse={setPaginationResponse}
          callBackMainApplicantApi={callBackMainApplicantApi}
          selectedJob={selectedJob}
          applicantCountData={applicantCountData?.data}
          setApplicantData={setApplicantData}
          isPublic={false}
          totalApplicantList={totalApplicantList}
          setTotalApplicantList={setTotalApplicantList}
          setSort={setSort}
          sort={sort}
          order={order}
          setOrder={setOrder}
          getNavBarData={getNavBarData}
        />
      ) : navbarDataError ? (
        <NewError
          height="180px"
          width="180px"
          alt="404 Error"
          fill={"#007BFF"}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <CircularProgress />
          </div>
        </div>
      )}
      <br />
      {open && (
        <ShareApplicant
          navbarData={navbarData?.data}
          selectedJob={selectedJob}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
}

export default TrackEmployee;

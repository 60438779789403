import { Checkbox, FormControlLabel, FormGroup, FormLabel, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import clsx from "clsx";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import search from "../../../assets/images/closedjob.png";
import Loader from "../../../bundles/common/components/UI/Loader";
import { BackendBaseApi } from "../../../constants/constants";
import GetAppIcon from "@material-ui/icons/GetApp";
const customStyles = (theme) => ({
  BlueLine: {
    "& td": { backgroundColor: "#EEFBFF" },
  },
  GreyLine: {
    "& td": { backgroundColor: "#F2F2F2" },
  },
  NameCell: {
    fontWeight: 900,
  },
  postButton: {
    background: "#3282C4",
    color: "#fff",

    "&:hover": {
      background: "#3282C4",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
});

function ConnectionStudents(props) {
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [datatable, setDatatable] = useState([]);
  const [ageFilterChecked, setageFilterChecked] = useState();
  const [twelveFilterChecked, settwelveFilterChecked] = useState();
  const [tenFilterChecked, settenFilterChecked] = useState();
  const [dummy, setDummydata] = useState([]);
  const [url, setUrl] = useState(BackendBaseApi.PRAVESH + "api/college/student/");
  const [loader, setLoader] = useState(false);
  const alert = useAlert();
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#AAF",
          },
          paper: {
            boxShadow: "1px 1px 20px #888888",
          },
        },
        MuiToolbar: {
          root: {
            backgroundColor: "#F2F2F2",
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: "#e3e3e3",
            color: "black",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#424242",
          },
        },
        MuiTableFooter: {
          root: {
            "& .MuiToolbar-root": {
              backgroundColor: "white",
            },
          },
        },
      },
    });

  const isGradeSystem = JSON.parse(localStorage.getItem("user_details"))?.is_grade_system;

  const columns = [
    // {
    //   name: "first_name",
    //   label: "First Name",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: "full_name",
      label: "Full Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "batch",
      label: "Batch",
      options: {
        filterType: "multiselect",
        filter: true,
        sort: false,
      },
    },
    {
      name: "degree_name",
      label: "Course",
      options: {
        filterType: "multiselect",
        filter: true,
        sort: false,
      },
    },
    {
      name: "specialization_name",
      label: "Branch",
      options: {
        filterType: "multiselect",
        filter: true,
        sort: false,
      },
    },
    {
      name: isGradeSystem ? "cgpa" : "percentage",
      label: isGradeSystem ? "CGPA" : "Percentage",
      options: {
        filter: true,
        filterType: "custom",

        // if the below value is set, these values will be used every time the table is rendered.
        // it's best to let the table internally manage the filterList
        //filterList: [25, 50],

        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && ageFilterChecked) {
              return isGradeSystem ? [`Min CGP: ${v[0]}`, `Max CGP: ${v[1]}`] : [`Min percentage: ${v[0]}`, `Max percentage: ${v[1]}`];
            } else if (v[0] && v[1] && !ageFilterChecked) {
              return isGradeSystem ? `Min CGP: ${v[0]}, Max CGP: ${v[1]}` : `Min percentage: ${v[0]}, Max percentage: ${v[1]}`;
            } else if (v[0]) {
              return isGradeSystem ? `Min CGP: ${v[0]}` :  `Min percentage: ${v[0]}`;
            } else if (v[1]) {
              return isGradeSystem ? `Max CGP: ${v[1]}` : `Max percentage: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(age, filters) {
            if (filters[0] && filters[1]) {
              return age < filters[0] || age > filters[1];
            } else if (filters[0]) {
              return age < filters[0];
            } else if (filters[1]) {
              return age > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel> {isGradeSystem ? "CGPA" : "Percentage"} </FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={ageFilterChecked}
                      onChange={(event) =>
                        setageFilterChecked({
                          ageFilterChecked: event.target.checked,
                        })
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                /> */}
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "twelfth",
      label: "12th",
      options: {
        filter: true,
        filterType: "custom",

        // if the below value is set, these values will be used every time the table is rendered.
        // it's best to let the table internally manage the filterList
        //filterList: [25, 50],

        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && twelveFilterChecked) {
              return [`Min 12th Percentage: ${v[0]}`, `Max 12th Percentage: ${v[1]}`];
            } else if (v[0] && v[1] && !twelveFilterChecked) {
              return `Min 12th Percentage: ${v[0]}, Max 12th Percentage: ${v[1]}`;
            } else if (v[0]) {
              return `Min 12th Percentage: ${v[0]}`;
            } else if (v[1]) {
              return `Max 12th Percentage: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(age, filters) {
            if (filters[0] && filters[1]) {
              return age < filters[0] || age > filters[1];
            } else if (filters[0]) {
              return age < filters[0];
            } else if (filters[1]) {
              return age > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>12th Percentage</FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={twelveFilterChecked}
                      onChange={(event) =>
                        settwelveFilterChecked({
                          twelveFilterChecked: event.target.checked,
                        })
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                /> */}
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "tenth",
      label: "10th",
      options: {
        filter: true,
        filterType: "custom",

        // if the below value is set, these values will be used every time the table is rendered.
        // it's best to let the table internally manage the filterList
        //filterList: [25, 50],

        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && tenFilterChecked) {
              return [`Min 10th Percentage: ${v[0]}`, `Max 10th Percentage: ${v[1]}`];
            } else if (v[0] && v[1] && !tenFilterChecked) {
              return `Min 10th Percentage: ${v[0]}, Max 10th Percentage: ${v[1]}`;
            } else if (v[0]) {
              return `Min 10th Percentage: ${v[0]}`;
            } else if (v[1]) {
              return `Max 10th Percentage: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(age, filters) {
            if (filters[0] && filters[1]) {
              return age < filters[0] || age > filters[1];
            } else if (filters[0]) {
              return age < filters[0];
            } else if (filters[1]) {
              return age > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>10th Percentage</FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%" }}
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={tenFilterChecked}
                      onChange={(event) =>
                        settenFilterChecked({
                          tenFilterChecked: event.target.checked,
                        })
                      }
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                /> */}
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "placement",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone",
      label: "Contact",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "total_job",
      label: "Total Applied Job",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "hired_count",
      label: "Hired Job",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "rejected_count",
      label: "Rejected Job",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "offcampus_hired_count",
      label: "Offcampus Hired Job",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "resume",
      label: "Resume",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filter: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    downloadOptions: {
      filename: "student_data.csv",
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: "primary",
        variant: "outlined",
        className: "testClass123",
      };
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: clsx({
          [props.classes.BlueLine]: rowIndex % 2 !== 0,
          [props.classes.GreyLine]: rowIndex % 2 === 0,
        }),
      };
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoader(true);

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          let temp = [];
          temp = res.data.data;
          temp.forEach((item) => {
            item.full_name = item.first_name + " " + item.last_name;
            // item.phone =
            //   item.phone +
            //   " " +
            //   (
            //     <a href="https://wa.me/9805104370">
            //       <img src={whatsapp} height="18px" alt="" />
            //     </a>
            //   )
            if (item.resume) {
              item.resume = (
                <a href={item.resume} target="_blank">
                  <GetAppIcon />
                </a>
              );
            } else {
              item.resume = "";
            }
            if (item.percentage && isGradeSystem) {
              item.cgpa = Math.round((item.percentage / JSON.parse(localStorage.getItem("user_details"))?.percentage_factor) * 100) / 100
            }
            if (item.cgpa && !isGradeSystem) {
              item.percentage = Math.round((item.cgpa * JSON.parse(localStorage.getItem("user_details"))?.percentage_factor) * 100) / 100
            }
            if (item.start_date && item.end_date) {
              item.batch = item.start_date.slice(0, 4) + " - " + item.end_date.slice(0, 4);
            } else {
              item.batch = "Batch N/A";
            }
          });
          setDatatable(temp);
          setDummydata(temp);
        } else alert.error("Some error occured!");
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  const textAreaRef = React.useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    alert.success("Copied to clibboard!");
  }
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
          <Container className="mt--7" fluid>
            {datatable.length ? (
              <React.Fragment>
                <br />
                <br />
                <MuiThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable title={dummy.length + " Students Registered"} data={datatable} columns={columns} options={options} />
                </MuiThemeProvider>
              </React.Fragment>
            ) : (
              <>
                <br />
                <br />
                <img src={search} align="center" style={{ margin: "auto", height: "250px", paddingLeft: "37%" }} alt="" />
                <br />
                <p align="center" style={{ color: "black" }}>
                  No Results found
                </p>
                <p align="center">
                  <span style={{ fontSize: "15px", color: "grey" }}>No students registered yet</span>
                </p>
                <br />
                <div
                  style={{
                    border: "2px dotted black",
                    borderRadius: "6px",
                    width: "60%",
                    margin: "auto",
                  }}
                >
                  <p align="center" style={{ padding: "30px" }}>
                    Share this link with students for them to register
                    <div>
                      <br />
                      <Button variant="contained" onClick={copyToClipboard}>
                        Copy Link
                      </Button>
                    </div>
                    <form style={{ display: "none" }}>
                      <textarea ref={textAreaRef} value="the link will be copied" style={{ display: "none" }} />
                    </form>
                    <br />
                    <b>or</b>
                    <br />
                    <Link to="/college/upload_student_data">
                      <Button variant="contained" className={props.classes.postButton}>
                        Upload student Data
                      </Button>
                    </Link>
                  </p>
                </div>
              </>
            )}
            <br />
            <br />
          </Container>
        </div>
      )}
    </>
  );
}

export default withStyles(customStyles, { name: "ExampleCard.js" })(ConnectionStudents);

import {
  CircularProgress,
  createMuiTheme,
  fade,
  ThemeProvider,
} from "@material-ui/core";
import { Shadows } from "@material-ui/core/styles/shadows";
// import cookie from "react-cookies";
import React, { useEffect, useState } from "react";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
//import AlertTemplate from "react-alert-template-basic";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import { getSubDomainDetails } from "./action";
import "./App.css";
// import VerifyEmail from "./bundles/common/components/Auth/VerifyEmail";
import PrivacyPolicy from "./bundles/common/components/TermsAndService/PrivacyPolicy";
import TermsAndService from "./bundles/common/components/TermsAndService/TermsAndService";
import ActivationPage from "./bundles/common/components/UI/ActivationPage";
import Maintainence from "./bundles/common/components/UI/Maintainence";
import UserApproved from "./bundles/common/components/UI/UserApproved";
import CompanySignup from "./bundles/company/components/Signup/Signup";
// import EmailConfirmation from "./bundles/company/components/UI/EmailConfirmation";
import TeamForm from "./bundles/company/components/UI/TeammateForm";
import TeamFormCollege from "./bundles/company/components/UI/TeammateForm1";
import StudentSignup from "./bundles/student/components/Auth/Signup";
import NavigationLayout from "./components/college/Layout/NavigationLayout";
import { MAINTAINENCE_FLAG } from "./constants/constants";
import CancellationPolicy from "./pages/common/CancellationPolicy";
// import ResetPassword from "./bundles/common/components/Auth/ResetPassword";
// import ForgotPassword from "./bundles/common/components/Auth/ForgotPassword";
import NotFound from "./pages/common/UI/NotFound";
import CompanyUserVerification from "./pages/public/CompanyUserVerification";
import EmailConfirmation from "./pages/public/EmailConfirmation";
import ForgotPassword from "./pages/public/ForgotPassword";
import StudentLogin from "./pages/public/Log-in/StudentLogin";
import Register from "./pages/public/Register/Register";
import SsoAuth from "./pages/public/SsoAuth";
import GetWorkRedirect from "./pages/common/GetworkRedirect";
import ResetPassword from "./pages/public/ResetPassword";
import StudentLoginApplyJob from "./pages/public/StudentLogin";
import ThankYouForApplying from "./pages/public/ThankYouForApplying";
import VerifyEmail from "./pages/public/VerifyEmail";
import AlertTemplate from "./components/common/AlertTempelate";
import NewSignUp from "./NewAppLayout/pages/NewSignUp";
import LoginFlow from "./NewStudent/Pages/LoginFlow";
import PrimaryTheme from "./NewStudent/Components/Common/Theme/PrimaryTheme";
import CandidateSignUp from "./NewStudent/Pages/CandidateSignup";
import NewAlertTemplate from "./NewStudent/Components/Common/Theme/NewAlertTemplate";
import Login from "./NewAppLayout/components/Common/Login/RightSection/Login";

// const AlertTemplate = require("react-alert-template-basic");

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10001,
    fontSize: "14px",
    // textTransform: "lowercase",
  },
};

const AuthGuard = () => {
  //decides which components to render
};

const App: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  //@ts-ignore
  const dispatch = useDispatch();
  const userType = localStorage.getItem("user_type");
  const UrlList = [
    "job",
    "privacy",
    "terms",
    "public",
    "privacy-and-cookie-policy",
    "cancellation-and-refund",
    "terms-and-services",
    "confirm-email",
    "getwork"
  ];
  const validateRoutes = (url: string) => {
    if (!UrlList.includes(url)) return true;
    else return false;
  };

  const [candidateSignup, setCandidateSignup] = useState(false);

  const checkValidRoute = (url: string) => {

    if (url == "company-user") {
      var fname = location.search.slice(1).split("&")[0].split("=")[1];
      var lname = location.search.slice(1).split("&")[1].split("=")[1];
      var email = location.search.slice(1).split("&")[2].split("=")[1];
      var phone = location.search.slice(1).split("&")[3].split("=")[1];
      var company_id = location.search.slice(1).split("&")[4].split("=")[1];
      var company_name = location.search.slice(1).split("&")[5].split("=")[1];
      var new_user_flag = location.search.slice(1).split("&")[6].split("=")[1];
      var user_type_id = location.search.slice(1).split("&")[7].split("=")[1];
      var about = location.search.slice(1).split("&")[8].split("=")[1];
      var job_title = location.search.slice(1).split("&")[9].split("=")[1];
      var invited_by_first_name = location.search
        .slice(1)
        .split("&")[10]
        .split("=")[1];
      var invited_by_last_name = location.search
        .slice(1)
        .split("&")[11]
        .split("=")[1];

      var uid = location.pathname.split("/")[2];
      var tokn = location.pathname.split("/")[3];

      //@ts-ignore
      history.push({
        //@ts-ignore
        pathname: "/public/team-invite-new" /* "/company-team-invite" */,
        //@ts-ignore
        Fname: fname,
        //@ts-ignore
        Lname: lname,
        //@ts-ignore
        Email: email.replace("%40", "@"),
        //@ts-ignore
        Mob: phone,
        //@ts-ignore
        companyID: company_id,
        //@ts-ignore
        companyName: decodeURI(company_name),
        //@ts-ignore
        flag: new_user_flag,
        //@ts-ignore
        About: decodeURI(about),
        //@ts-ignore
        JobTitle: decodeURI(job_title),
        //@ts-ignore
        userTypeID: user_type_id,
        //@ts-ignore
        u_id: uid,
        //@ts-ignore
        to_ken: tokn,
        //@ts-ignore
        ifn: invited_by_first_name,
        //@ts-ignore
        iln: invited_by_last_name,
      });
    }

    if (url == "college-user") {
      var f_name = location.search.slice(1)?.split("&")[0]?.split("=")[1];

      var l_name = location.search.slice(1).split("&")[1]?.split("=")[1];

      var e_mail = location.search.slice(1).split("&")[2]?.split("=")[1];

      var p_hone = location.search.slice(1).split("&")[3]?.split("=")[1];

      var college_id = location.search.slice(1).split("&")[4]?.split("=")[1];
      var college_name = location.search.slice(1).split("&")[5]?.split("=")[1];
      var new_user_flag1 = location.search
        .slice(1)
        .split("&")[6]
        ?.split("=")[1];
      var user_type_id1 = location.search.slice(1).split("&")[7]?.split("=")[1];
      var about1 = location.search.slice(1).split("&")[8]?.split("=")[1];
      var job_title1 = location.search.slice(1).split("&")[9]?.split("=")[1];
      var invited_by_first_name1 = location.search
        .slice(1)
        .split("&")[10]
        ?.split("=")[1];
      var invited_by_last_name1 = location.search
        .slice(1)
        .split("&")[11]
        ?.split("=")[1];

      var uid1 = location.pathname.split("/")[2];
      var tokn1 = location.pathname.split("/")[3];

      //@ts-ignore
      history.push({
        //@ts-ignore
        pathname: "/college-team-invite",
        //@ts-ignore
        Fname: f_name,
        //@ts-ignore
        Lname: l_name,
        //@ts-ignore
        Email: e_mail.replace("%40", "@"),
        //@ts-ignore
        Mob: p_hone,
        //@ts-ignore
        collegeID: college_id,
        //@ts-ignore
        collegeName: decodeURI(college_name),
        //@ts-ignore
        flag: new_user_flag1,
        //@ts-ignore
        About: decodeURI(about1),
        //@ts-ignore
        JobTitle: decodeURI(job_title1),
        //@ts-ignore
        userTypeID: user_type_id1,
        //@ts-ignore
        u_id: uid1,
        //@ts-ignore
        to_ken: tokn1,
        //@ts-ignore
        ifn: invited_by_first_name1,
        //@ts-ignore
        iln: invited_by_last_name1,
      });
    }

    if (userType === "Company") {
      if (url !== "company" && validateRoutes(url)) {
        history.push("/login");
      }
    } else if (userType === "College") {
      if (url !== "college" && validateRoutes(url)) {
        history.push("/login");
      }
    } else if (userType === "Student") {
      if(url === 'candidate'){
        setCandidateSignup(true);
      }
      else if (url !== "student" && validateRoutes(url)) {
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    checkValidRoute(location.pathname.split("/")[1]);
  }, [location.pathname]);

  const [darkState, setDarkState] = useState(false);
  const palletType = darkState ? "dark" : "light";
  const textPrimary = darkState ? "#ffffff" : "#000000";
  const bgColor = darkState ? "#222222" : "#ffffff";
  const bgColorPaper = darkState ? "#333333" : "#ffffff";
  const mainBg = darkState ? "#222222" : "#ffffff";
  const inputLabel = darkState ? "#ffffff" : "rgb(120,120,120)";
  const tableText = darkState ? "#ffffff" : "#424242";
  // const Shadows = darkState ? ["none"] : ["0px 0px 4px rgba(0, 0, 0, 0.25)"];
  // @ts-ignore
  const domain = useSelector(
    // @ts-ignore
    (state) => state.SubDomainDetails.subDomainDetails
  );
  // @ts-ignore
  const ShadowsArr: Shadows = [
    "none",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0 0 8px 0 rgba(0,0,0,0.25)",
    "0 10px 25px 0 rgba(172,185,196,.50)",
    "0 0 3px 0 #ced4da",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
  ];

  const Theme = createMuiTheme({
    palette: {
      type: palletType,
      common: {
        black: "#000000",
        white: "#ffffff",
      },
      // @ts-ignore
      domain: {
        main: domain?.domain ? domain?.color_code : "#007bff",
        light: domain?.domain ? domain?.light_color_code : "#5aaaff",
        hover: domain?.domain ? domain?.light_color_code : "#0069d9",
      },
      primary: {
        main: "#3282C4",
        light: "#CDEDF6",
        dark: "#def1f7",
      },
      secondary: {
        light: "#C1D9ED",
        main: "#E55934",
      },
      text: {
        primary: textPrimary,
        secondary: "#828282",
      },
      error: {
        main: "#D64045",
      },
      success: {
        light: "#DEFFEC",
        main: "#219653",
      },
      info: {
        light: "#9b51e0",
        main: "#f2c94c",
        dark: "#f2994a",
      },
      grey: {
        50: "#F2F2F2",
        100: "#777",
      },
      background: {
        paper: bgColorPaper,
        default: bgColor,
      },
    },
    typography: {
      fontFamily: "Roboto",
      h3: {
        fontSize: "20px",
        color: textPrimary,
      },
      h4: {
        fontSize: "24px",
        fontWeight: "bold",
        color: textPrimary,
      },
      h5: {
        fontSize: "22px",
        color: textPrimary,
      },
      h6: {
        fontSize: "18px",
        fontWeight: "bold",
        color: textPrimary,
      },
      body1: {
        fontSize: "16px",
        color: textPrimary,
      },
      body2: {
        fontSize: "16px",
        color: textPrimary,
      },
      subtitle1: {
        fontSize: "14px",
        color: textPrimary,
      },
      caption: {
        fontSize: "18px",
        color: textPrimary,
      },
    },
    shadows: ShadowsArr,
    overrides: {
      MuiButton: {
        label: {
          textTransform: "capitalize",
        },
        contained: {
          // "&$disabled": {
          //   color: "#FFF",
          //   backgroundColor: "#EE7D66",
          // },
          color: "#ffffff",
          fontWeight: "bold",
          backgroundColor: "#3282c4",
          "&:hover": {
            backgroundColor: "#3282c4",
          },
          "&$disabled": {
            color: "#FFF",
            backgroundColor: fade("#3282c4", 0.3),
          },
        },
        text: {
          color: "#3282c4",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:focus": {
            outline: "none",
          },
        },
        outlined: {
          color: "#3282c4",
          border: "1px solid #3282c4",
          fontWeight: "bold",
          "&:focus": {
            outline: "none",
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: textPrimary,
          "&$focused": {
            color: textPrimary,
          },
        },
        focused: {
          color: "#000000",
        },
      },
      MuiFab: {
        root: {
          backgroundColor: "#3282c4",
          color: "#FFF",
        },
      },

      MuiOutlinedInput: {
        root: {
          height: "40px",
          borderRadius: 30,

          // border: "1px solid #ced4da",
          // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
        },
        notchedOutline: {
          // borderColor: "transparent",
        },
        multiline: {
          height: "auto",
        },
      },
      MuiInputLabel: {
        outlined: {
          transform: "translate(14px, 11px) scale(1)",
          color: inputLabel,
          padding: "4px 4px",
        },
      },
      MuiTableCell: {
        head: {
          color: tableText,
          fontWeight: "bold",
        },
        body: {
          color: tableText,
        },
      },
    },
  });

  // const appHistory = createBrowserHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  if (query.get("utm_source")) {
    // console.log(query.get("utm_source"))
    document.cookie =
      "utm_source=" + query.get("utm_source") + "; path=/; domain=getwork.org";
  }
  if (query.get("utm_medium")) {
    document.cookie =
      "utm_medium=" + query.get("utm_medium") + "; path=/; domain=getwork.org";
  }
  if (query.get("utm_campaign")) {
    document.cookie =
      "utm_campaign=" +
      query.get("utm_campaign") +
      "; path=/; domain=getwork.org";
  }
  if (query.get("utm_term")) {
    document.cookie =
      "utm_term=" + query.get("utm_term") + "; path=/; domain=getwork.org";
  }
  if (query.get("utm_content")) {
    document.cookie =
      "utm_content=" +
      query.get("utm_content") +
      "; path=/; domain=getwork.org";
  }
  const [doaminLoading, setDomainLoading] = useState(false);
  //@ts-ignore
  const domain_new = useSelector(
    // @ts-ignore
    (state) => state.SubDomainDetails.subDomainDetails
  );

  const gwToken = localStorage.getItem('gw_token');

  useEffect(() => {
    //@ts-ignore
    if (!domain_new?.domain) dispatch(getSubDomainDetails(setDomainLoading));
  }, []);

  return MAINTAINENCE_FLAG ? (
    <Maintainence />
  ) : (
    // @ts-ignore
    <AlertProvider template={AlertTemplate} {...options}>
      <ThemeProvider theme={Theme}>
        <div
          className="App"
          style={{
            width: "100%",
            minHeight: "100vh",
            backgroundColor:
              !darkState &&
              ((localStorage.getItem("user_type_id") === "1" ||
                userType === "Student") && !candidateSignup)
                ? "#f2f2f2"
                : location.pathname.includes("/public/student/profile")
                ? "#f8f9fa"
                : mainBg,
          }}
        >
          {/* @ts-ignore */}
          {/* <BrowserRouter history={appHistory}> */}
          <Switch>
            <Route exact path="/public/not-found" component={NotFound} />

            <Route exact path="/" component={StudentLogin}>
              {doaminLoading ? (
                <CircularProgress
                  size={60}
                  style={{ margin: "auto", marginTop: 100, display: "flex" }}
                />
              ) : (
                <StudentLogin />
              )}
            </Route>
            <Route exact path="/login/employer" component={NewSignUp} />
            <Route exact path="/signup/employer" component={NewSignUp} />
            <Route exact path="/login/:token?">
              {doaminLoading ? (
                <CircularProgress
                  size={60}
                  style={{ margin: "auto", marginTop: 100, display: "flex" }}
                />
              ) : (
                <StudentLogin />
              )}
            </Route>

            <Route exact path="/signup/:token?">
              {doaminLoading ? (
                <CircularProgress
                  size={60}
                  style={{ margin: "auto", marginTop: 100, display: "flex" }}
                />
              ) : (
                <Register />
              )}
            </Route>

            <Route exact path="/sso_auth/">
              {doaminLoading ? (
                <CircularProgress
                  size={60}
                  style={{ margin: "auto", marginTop: 100, display: "flex" }}
                />
              ) : (
                <SsoAuth />
              )}
            </Route>
            
            <Route
              exact
              path="/apply-for-the-job/:token?"
              component={StudentLoginApplyJob}
            ></Route>
            <Route
              exact
              path="/public/thank-you-for-applying/:token?"
              component={ThankYouForApplying}
            ></Route>
            <Route
              exact
              path="/public/referral/thank-you-for-applying/:token?"
              component={ThankYouForApplying}
            ></Route>

            {/* <Route exact path="/signup/:token?" component={Signup}></Route> */}

            <Route path="/confirm-email" component={EmailConfirmation} />
            <Route exact path="/company-team-invite" component={TeamForm} />
            {/* <Route exact path="/company/admin-approve" component={AdminApprove} /> */}
            <Route
              exact
              path="/company/admin-approve"
              component={CompanyUserVerification}
            />

            <Route path="/user-approved/:token" component={UserApproved} />
            <Route
              exact
              path="/college-team-invite"
              component={TeamFormCollege}
            />

            {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
            <Route path="/forgot-password" component={ForgotPassword} />

            {/* <Route path="/verify-email/:token" component={VerifyEmail} /> */}
            <Route path="/verify-email/:token" component={VerifyEmail} />

            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route
              exact
              path="/terms-and-services"
              component={TermsAndService}
            />
            <Route
              exact
              path="/privacy-and-cookie-policy"
              component={PrivacyPolicy}
            />
            <Route
              exact
              path="/cancellation-and-refund"
              component={CancellationPolicy}
            />
            <Route exact path="/company/register" component={CompanySignup} />
            <Route exact path="/student/register" component={StudentSignup} />
            <Route exact path="/getwork/redirect" component={GetWorkRedirect} />
            <Route path="/activation-link/:token" component={ActivationPage} />
            <Route
              path="/college/:dashboard"
              component={() => (
                <NavigationLayout
                  setDarkState={setDarkState as any}
                  darkState={darkState}
                />
              )}
            />
            <Route
              path="/student/:dashboard"
              component={() => (
                <NavigationLayout
                  setDarkState={setDarkState as any}
                  darkState={darkState}
                />
              )}
            />
            <Route
              path="/company/:dashboard"
              component={() => (
                <NavigationLayout
                  setDarkState={setDarkState as any}
                  darkState={darkState}
                />
              )}
            />
            <Route
              path="/public/:slug"
              component={() => (
                <NavigationLayout
                  setDarkState={setDarkState as any}
                  darkState={darkState}
                />
              )}
            />
            <AlertProvider template={NewAlertTemplate} {...options}>
              <PrimaryTheme>
              <Route
                  exact
                  path="/candidate/basic-details/:token"
                >
                  <LoginFlow />
                  </Route>
                <Route
                  exact
                  path="/candidate/basic-details"
                >
                  {gwToken ? <LoginFlow /> : <Redirect to="/candidate/signup" />}
                </Route>
                <Route exact path="/candidate/signup">
                  {doaminLoading ? (
                    <CircularProgress
                      size={60}
                      style={{
                        margin: "auto",
                        marginTop: 100,
                        display: "flex",
                      }}
                    />
                  ) : (
                    gwToken ? <Redirect to="/candidate/basic-details" /> : <CandidateSignUp />
                  )}
                </Route>
              </PrimaryTheme>
            </AlertProvider>
          </Switch>
          
        </div>
      </ThemeProvider>
    </AlertProvider>
  );
};

export default withRouter(App);

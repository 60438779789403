import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardHeader } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 40,
    width: "90%",
  },
  cardRoot: {
    marginBottom: "2%",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius:"30px",
  },
  cardRootListing: {
    boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
    borderRadius: "30px",
    width: "100%",
    transform: "translateX(-20%)"
  },
  cardRootNew: {
    width: "70%",
    boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
    borderRadius: 30,
    marginBottom: "2%",
    marginLeft: 154,
    borderRadius:"30px",
  },
  cardRootApplicants: {
    width: "100%",
    boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
    borderRadius: 10,
    borderRadius:"30px",
    marginBottom: "2%",
  },
}));

function Media(props) {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        avatar={
          <Skeleton
            variant="circle"
            animation="wave"
            height={40}
            width={40}
            style={{ marginBottom: 5 }}
          />
        }
        title={
          <Skeleton
            animation="wave"
            height={15}
            width="60%"
            style={{ marginBottom: 5 }}
          />
        }
        subheader={
          <Skeleton
            animation="wave"
            style={{ marginLeft: "5%" }}
            height={10}
            width="80%"
          />
        }
      />
      {
        <Box display="flex" justifyContent="center" marginBottom="4%">
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        </Box>
      }
    </Card>
  );
}

function MediaListing(props) {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.cardRootListing }}>
      <CardHeader
        title={
          <Skeleton
            animation="wave"
            height={15}
            width="60%"
            style={{ marginBottom: 5 }}
          />
        }
        subheader={
          <Skeleton
            animation="wave"
            style={{ marginLeft: "5%" }}
            height={10}
            width="80%"
          />
        }
        avatar={
          <Skeleton
            variant="circle"
            animation="wave"
            height={40}
            width={40}
            style={{ marginBottom: 5 }}
          />
        }
      />
      {
        <Box display="flex" justifyContent="center" marginTop="3%">
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        </Box>
      }
      {
        <Box display="flex" justifyContent="center" margin="2% 0%">
          <Skeleton animation="wave" height={10} width="90%" />
        </Box>
      }
    </Card>
  );
}

function MediaNew(props) {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.cardRootNew }}>
      <CardHeader
        avatar={
          <Skeleton
            variant="circle"
            animation="wave"
            height={40}
            width={40}
            style={{ marginBottom: 5 }}
          />
        }
        title={
          <Skeleton
            animation="wave"
            height={15}
            width="60%"
            style={{ marginBottom: 5 }}
          />
        }
        subheader={
          <Skeleton
            animation="wave"
            style={{ marginLeft: "5%" }}
            height={10}
            width="80%"
          />
        }
      />
      {
        <Box display="flex" justifyContent="center" marginBottom="4%">
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        </Box>
      }
    </Card>
  );
}

function MediaApplicants(props) {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.cardRootApplicants }}>
      <CardHeader
        avatar={
          <Skeleton
            variant="circle"
            animation="wave"
            height={40}
            width={40}
            style={{ marginBottom: 5 }}
          />
        }
        title={
          <Skeleton
            animation="wave"
            height={15}
            width="60%"
            style={{ marginBottom: 5 }}
          />
        }
        subheader={
          <Skeleton
            animation="wave"
            style={{ marginLeft: "5%", borderRadius: 30 }}
            height={10}
            width="80%"
          />
        }
      />
      {
        <Box display="flex" justifyContent="center" marginBottom="4%">
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.media}
            style={{ borderRadius: 30 }}
          />
        </Box>
      }
    </Card>
  );
}

export default function JobCardSkeleton() {
  return (
    <div>
      <Media />
      <Media />
    </div>
  );
}

export function JobCardSkeletonListing() {
  return (
    <>
      <div style={{marginBottom:"2%"}}>
        <MediaListing />
      </div>
      <div style={{marginBottom:"2%"}}>
        <MediaListing />
      </div>
    </>
  );
}

export function JobCardSkeletonNew() {
  return (
    <div>
      <MediaNew />
      <MediaNew />
    </div>
  );
}

export function JobCardSkeletonApplicants() {
  return (
    <div>
      <MediaApplicants />
      <MediaApplicants />
      <MediaApplicants />
      <MediaApplicants />
      <MediaApplicants />
    </div>
  );
}

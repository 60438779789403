import React, { useState } from "react";
import Slide from "@material-ui/core/Slide";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  Grid,
  ButtonBase,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import fetchApi from "../../apiCall/fetchApi";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";
import { CircularProgress } from "@mui/material";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "20px",
    border: ".5px solid #707070",
    boxShadow: "none",
    padding: "5px",
    paddingBottom: "20px",
    overflowY: "clip",
    width: "600px",
    // height: "400px",
  },
  btn: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    height: "40px",
    fontWeight: "500",
  },
  btngrid: {
    paddingRight: "25px",
    display: "flex",
    paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "20px",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: "none",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCommentDialog(props) {
  const classes = useStyles();
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [comment, setComment] = useState(null);
  const [commentError, setCommentError] = useState(null);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { fromStudentDetail = false } = props;
  const handleSubmit = () => {
    //date to be created by backend later
    var today = new Date();
    var date =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();
    var currentDate = moment(date, "DD/MM/YYYY");
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/comments/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        job_applicant_id: props?.selectedRow?.selectedIds[0],
        comment_dict: {
          user_id: localStorage.getItem("user_id"),
          status_id: props?.activeStatusObject?.status_id,
          status_name: props?.activeStatusObject?.heading,
          comment: comment,
          create_time: currentDate,
          user_name:
            JSON.parse(localStorage.getItem("user")).first_name +
            " " +
            JSON.parse(localStorage.getItem("user")).last_name,
        },
      }),
      setLoading: setLoading,
      callBackFn: callBackFn,
    });
  };

  const callBackFn = () => {
    setComment("");
    setCommentError("");
    if (fromStudentDetail) {
      props.getComments();
    }
    props.handleClose();
  };
  return (
    <div>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        open={props.open}
        TransitionComponent={Transition}
        maxWidth={maxWidth}
        keepMounted
        onClose={props.handleClose}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Add Comments
        </DialogTitle>
        <DialogContent style={{ paddingTop: "0px" }}>
          <Typography variant="h6" style={{ fontWeight: "400" }}>
            Applicant Name:{" "}
            {props?.selectedRow?.details?.length &&
              props?.selectedRow?.details[0]?.name}
          </Typography>
          <div style={{ marginTop: "20px" }}>
            <TextField
              style={{ width: "100%" }}
              label="Add Comment"
              multiline
              rows={6}
              variant="outlined"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setCommentError("");
              }}
              error={commentError ? true : false}
              helperText={commentError}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={4}
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          >
            <Grid item sm={6} style={{ display: "grid" }}>
              <Button
                className={classes.btn}
                variant="outlined"
                color="primary"
                onClick={props.handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item sm={6} style={{ display: "grid" }}>
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (!loading) {
                    if (comment) {
                      handleSubmit();
                    } else {
                      setCommentError("Please Enter comment");
                    }
                  }
                }}
              >
                {loading ? <CircularProgress size={18} /> : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Navbar from "../../components/public/Navbar/Navbar";
import MuiCustomTheme from "../components/Theme/theme";
import EnterOtp from "../../components/student/Dialog/EnterOtp";
import TeamInviteLeftSideSection from "../components/TeamInvite/TeamInviteLeftSection";
import TeamInviteForm from "../components/TeamInvite/TeamInviteForm";
const useStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    //paddingLeft: "5px",
    //paddingRight: "5px",
  },
});
export default function TeamInvite() {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <Container
        maxWidth="lg"
        component="main"
        className={classes.header}
        disableGutters
      >
        <MuiCustomTheme>
          <div
            style={{ marginTop: "80px", marginBottom: "20px", width: "100%" }}
          >
            <Grid container>
              <Grid item md={6}>
                <TeamInviteLeftSideSection />
              </Grid>
              <Grid item md={6}>
                <TeamInviteForm />
              </Grid>
            </Grid>
          </div>
        </MuiCustomTheme>
      </Container>
    </>
  );
}

import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  paper: {
    // minHeight: "600px",
    borderRadius: "20px",
    // textAlign: "center",
    padding: 12,
    width: "100%",
    marginBottom: 10,
  },
}));

function CertificateCards(props) {
  const { data, index, editBtn, handleDelete, handleDeleteId } = props;

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="body1" style={{ textAlign: "left", fontSize: 15 }}>
              {data?.name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <EditIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                editBtn(data?.id, index);
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <DeleteIcon
              style={{ color: "#007bff", fontSize: 15, cursor: "pointer" }}
              onClick={() => {
                if (data?.id) handleDeleteId(data?.id);
                else handleDelete(index);
              }}
            />
          </Grid>
        </Grid>

        <Typography variant="body2" style={{ color: "#6c757d", textAlign: "left", fontSize: 13 }}>
          {data?.institute}
        </Typography>
        <Typography variant="body2" style={{ color: "#6c757d", textAlign: "left", fontSize: 12 }}>
          {moment(data?.start_date).format("MMM YYYY")}
          {"-"}
          {moment(data?.end_date).format("MMM YYYY")}
        </Typography>

        <Typography variant="body2" style={{ color: "#6c757d", textAlign: "left", fontSize: 12 }}>
          {!data?.expiration ? (
            <>
              {" "}
              Valid Upto:
              {moment(data?.valid_date).format("MMM YYYY")}
            </>
          ) : (
            <>Valid Upto: Doesn't Expire</>
          )}
        </Typography>
      </Paper>
    </>
  );
}

export default CertificateCards;

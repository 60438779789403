import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  UPDATE_CTC_EXPLORE,
  UPDATE_INDUSTRY,
  UPDATE_JOB_SEGMENT,
  UPDATE_LOCATION_EXPLORE,
  UPDATE_LOCATION_EXPLORE_COLLEGE,
  UPDATE_SIZE
} from "./action";
import Carousel from "./Carousel";
import CarouselCollege from "./CarouselCollege";
import ImageGroup from "./ImageGroup";
import ImageGroupBlogs from "./ImageGroupBlogs";


const useStyles = makeStyles({
  OuterTopBox: {
    width: "93.8%",

    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "30px",
    marginTop: "20px",
    marginLeft: "3.1%",
    marginBottom: "20px",
  },
  OuterBox: {
    // width: "97%",
    // boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    // backgroundColor: "#fff",
    // padding: "20px",
    // borderRadius: "4px",
    // marginLeft: "1.5%",
    // marginBottom: "20px",
    width: "93.8%",

    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "30px",
    // marginTop: "92px",
    marginLeft: "3.1%",
    marginBottom: "20px",
  },
  subText: {
    fontSize: "13px",
    color: "#424242",
  },
  postButton: {
    background: "#3282C4",
    color: "#fff",
    alignItems: "left",
    borderRadius: "30px",
    width: "100%",
    height: "40px",
    "&:hover": {
      background: "#3282C4",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
});

export default function ExploreFeature(props) {
  const {
    jobRole,
    jobRoles,
    setJobRole,
    setJobType,
    jobType,
    jobTypes,
    city,
    setCity,
    cityList,
    data,
    blog,
    stateMemory,
    handleFilterChange,
  } = props;
  const citySelected = useSelector((state) => state.ExploreFilter.location);
  const [dataAttained, setDataAttained] = React.useState();
  const history = useHistory();
  const collegeID = localStorage.getItem("college_id");
  const stateMemoryCollege = useSelector((state) => state.ExploreFilterCollege);

  const alert = useAlert();
  useEffect(() => {
    if (data) setDataAttained(data);
  }, [data]);
  const classes = useStyles();
  const jobroles = Object.keys(jobRoles).map((jobrole) => {
    return { name: jobrole, value: jobrole };
  });
  const jobtypes = Object.keys(jobTypes).map((job_type) => {
    return { name: job_type, value: job_type };
  });
  const cities = Object.keys(cityList).map((City) => {
    return { name: City, value: City };
  });
  const Hiringfor = () => (
    <Autocomplete
      value={jobRole}
      onChange={(event, newValue) => {
        setJobRole(newValue);
      }}
      id="controllable-states-demo"
      options={jobroles.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          style={{ borderRadius: "30px" }}
          {...params}
          label={
            !collegeID ? "What are you Hiring For" : "Select Specific Industry"
          }
          variant="outlined"
        />
      )}
    />
  );
  const max_ctc = [
    { name: "Less than 1 LPA", value: 100000 },
    { name: "Less than 6 LPA", value: 600000 },
    { name: "Less than 10 LPA", value: 1000000 },
    { name: "Less than 30 LPA", value: 3000000 },
    { name: "Less than 60 LPA", value: 6000000 },
  ];

  const companySizes = [
    { name: "1-10", value: "1-10" },
    { name: "11-50", value: "11-50" },
    { name: "51-100", value: "51-100" },
    { name: "101-1000", value: "101-1000" },
    { name: "1000+", value: "1000+" },
  ];
  const JobType = () => (
    <Autocomplete
      value={jobType}
      onChange={(event, newValue) => {
        setJobType(newValue);
      }}
      id="controllable-states-demo"
      options={jobtypes.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Job Type" variant="outlined" />
      )}
    />
  );

  const Location = () => (
    <Autocomplete
      value={city}
      onChange={(event, newValue) => {
        setCity(newValue);
      }}
      id="controllable-states-demo"
      options={cities.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Location" variant="outlined" />
      )}
    />
  );

  useEffect(() => {
    if (city) {
      if (!stateMemory.location.includes(city)) {
        const currentLocation = {
          city: city,
          city_id: cityList[city],
        };
        // let job_location = [...citySelected];
        let job_location = [];
        job_location.push(currentLocation);
        if (!collegeID)
          handleFilterChange(UPDATE_LOCATION_EXPLORE, job_location);
        else handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, job_location);
      }
    }
  }, [city]);

  useEffect(() => {
    if (jobRole) {
      let selectedCourses = [];
      if (!collegeID) {
        if (
          selectedCourses
            .map((selected_course) => selected_course.job_segment_name)
            .includes(jobRole)
        )
          return;

        selectedCourses.push(jobRoles[jobRole]);
        handleFilterChange(UPDATE_JOB_SEGMENT, selectedCourses);
      } else {
        if (
          selectedCourses
            .map((selected_course) => selected_course.industry_name)
            .includes(jobRole)
        )
          return;

        selectedCourses.push(jobRoles[jobRole]);
        handleFilterChange(UPDATE_INDUSTRY, selectedCourses);
      }
    }
  }, [jobRole]);
  const inputLabel = React.useRef(null);

  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <>
      <div style={collegeID && { marginTop: "100px" }}>
        <Box className={classes.OuterTopBox}>
          <Typography style={{ fontSize: "20px" }}>
            {!collegeID ? (
              <b>Find Colleges for Internships, Jobs and much more</b>
            ) : (
              <b>Find Companies for Placements and more</b>
            )}
          </Typography>
          {!collegeID ? (
            <span className={classes.subText}>
              From 1st year Interns to Top Tier College Grads
            </span>
          ) : (
            <span className={classes.subText}>
              From Startups to Top established Companies
            </span>
          )}
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Hiringfor />
            </Grid>
            <Grid item xs={3}>
              {/* <JobType /> */}
              <FormControl variant="outlined" style={{ width: "100%" }}>
                {!collegeID ? (
                  <>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      ref={inputLabel}
                    >
                      Max CTC
                    </InputLabel>
                    <Select
                      value={stateMemory.ctc}
                      onChange={(e) =>
                        handleFilterChange(
                          UPDATE_CTC_EXPLORE,
                          parseInt(e.target.value)
                        )
                      }
                      label="CTC"
                      input={
                        <OutlinedInput
                          notched
                          labelWidth={labelWidth}
                          name="age"
                          id="outlined-age-always-notched"
                        />
                      }
                    >
                      {max_ctc.map((data) => {
                        return (
                          <MenuItem value={data.value}>{data.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </>
                ) : (
                  <>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      ref={inputLabel}
                    >
                      Company Size
                    </InputLabel>
                    <Select
                      value={stateMemoryCollege.size}
                      onChange={(e) =>
                        handleFilterChange(UPDATE_SIZE, e.target.value)
                      }
                      label="Size"
                      input={
                        <OutlinedInput
                          notched
                          labelWidth={labelWidth}
                          name="age"
                          id="outlined-age-always-notched"
                        />
                      }
                    >
                      {companySizes.map((data) => {
                        return (
                          <MenuItem value={data.value}>{data.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Location />
            </Grid>
            {!collegeID ? (
              <Grid item xs={2}>
                {stateMemory.location.length > 0 ||
                stateMemory.job_segment.length > 0 ||
                parseInt(stateMemory.ctc) !== 0 ? (
                  // <Link to="/company/invite">
                  <Button
                    onClick={() =>
                      history.push({
                        pathname: "/company/invite",
                        flag: 1424,
                      })
                    }
                    className={classes.postButton}
                  >
                    Search
                  </Button>
                ) : (
                  // </Link>
                  <Button
                    className={classes.postButton}
                    onClick={() =>
                      alert.error("Please Select one Item to Search")
                    }
                  >
                    Search
                  </Button>
                )}
              </Grid>
            ) : (
              <Grid item xs={2}>
                {stateMemoryCollege.location.length > 0 ||
                stateMemoryCollege.industry.length > 0 ||
                stateMemoryCollege.size !== null ? (
                  // <Link to="/company/invite">
                  <Button
                    onClick={() =>
                      history.push({
                        pathname: "/college/invite",
                        flag: 1424,
                      })
                    }
                    className={classes.postButton}
                  >
                    Search
                  </Button>
                ) : (
                  // </Link>
                  <Button
                    className={classes.postButton}
                    onClick={() =>
                      alert.error("Please Select one Item to Search")
                    }
                  >
                    Search
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
        {data.job_segment_industry && (
          <Box className={classes.OuterBox}>
            <Typography style={{ fontSize: "20px" }}>
              {!collegeID ? (
                <b>Browse by Job Segment Type</b>
              ) : (
                <b>Browse by Industry Type</b>
              )}
            </Typography>

            <Carousel data={data} />
          </Box>
        )}

        <Box className={classes.OuterBox}>
          <ImageGroup data={data} />
        </Box>
        <Box className={classes.OuterBox}>
          <CarouselCollege data={data} />
        </Box>
        <Box className={classes.OuterBox}>
          <Typography style={{ fontSize: "20px" }}>
            <b>Get Inspiration for your next Hire</b>
          </Typography>{" "}
          <br />
          <ImageGroupBlogs blog={blog} />
        </Box>
        {/* <Box className={classes.OuterBox}>
        <Typography style={{ fontSize: "20px" }}>
          <b>Connect with other Recruiters </b>
        </Typography>{" "}
        <br />
        <BlogsGroup />
      </Box> */}
      </div>
    </>
  );
}

import Axios from "axios";
import { BackendBaseApi } from "../../../../constants/constants";

export const GET_COMPANY_USER_DETAILS = "GET_COMPANY_USER_DETAILS";

export const getCompanyUserDetails = (history, alert) => {
  let token = localStorage.getItem("gw_token");
  const userType = localStorage.getItem("user_type_id");
  return async (dispatch) => {
    const res = await Axios.get(`${BackendBaseApi.PRAVESH}api/shared/user/?user_type=${userType}`, {
      headers: {
        Authorization: "Token " + token,
      },
      withCredentials: true
    }).catch((err) => {
      localStorage.clear();
      alert.error("Your session has been Expired. Please login again");
      history.push("/login");
    });

    if (res.data.success) {
      const { email, first_name, last_name, id, profile_picture } = res.data.data;

      localStorage.setItem("user_id", res.data.data.id);
      localStorage.setItem("comp_profile_completed", res.data.data.company_user_details.comp_profile_complete);
      localStorage.setItem("company_domain", res?.data?.data?.company_user_details?.company_domain);

      localStorage.setItem(
        "user",
        JSON.stringify({
          email,
          first_name,
          last_name,
          id,
          profile_picture,
        })
      );
      localStorage.setItem("company", JSON.stringify(res.data.data.company_user_details));
    } else {
      alert.error(res.data.error);
    }
    dispatch({ type: GET_COMPANY_USER_DETAILS, payload: res.data.data });
  };
};

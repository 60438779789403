import {
  AppBar,
  Box,
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import calender from "../../../assets/images/blue-calendar.png";
import maxpackage from "../../../assets/images/maxpackage.png";
import placement from "../../../assets/images/placement.png";
import fee from "../../../assets/images/fee.png";
import badge from "../../../assets/images/badge.png";
import affiliation from "../../../assets/images/affiliation.png";
import star from "../../../assets/images/star.png";
import tick from "../../../assets/images/tick.png";

import React, { Fragment, useEffect, useState } from "react";
import kFormatter, { LFormatter } from "../../../utils/ZeroToK";
const useStyles = makeStyles((theme) =>
  createStyles({
    WhiteBox: {
      // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      // borderRadius: "4px",
      // backgroundColor: "#fff",
      margin: "20px 40px 40px 40px",

      // padding: "40px",
      display: "flex",
      flexDirection: "row",
    },
    BorderBox: {
      backgroundColor: "#fff",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",

      // border: "1px solid #41a5ff",
      padding: "20px",
      borderRadius: "8px",
      width: "100%",
      margin: "0 12px 0 12px",
      "&:hover": {
        transform: "scale(1.02)",
        transition: "transform 0.2s",
      },
    },
    imageBox: {
      display: "flex",
      justifyContent: "center",
    },
    MainText: {
      fontSize: "20px",
    },
    SubText: {
      fontSize: "18px",
      color: "grey",
    },
    "@media only screen and (max-width: 768px)": {
      WhiteBox: {
        flexDirection: "column",
        marginRight: "auto",
        marginLeft: "-20px",
      },
      BorderBox: {
        marginBottom: "10px",
      },
    },
  })
);

export default function CardBox(props) {
  const classes = useStyles();
  const { data } = props;
  const [cardData, setCardData] = useState({});
  const [fees, setFees] = useState([]);
  useEffect(() => {
    if (data) {
      setCardData(data?.college_info);
    }
  }, [data]);
  return (
    <>
      <Box className={classes.WhiteBox}>
        <Box className={classes.BorderBox}>
          <Box className={classes.imageBox}>
            <img src={badge} alt="" height="50px" />
          </Box>
          <br />
          {cardData?.university_type ? (
            <>
              <Typography
                align="center"
                variant="body1"
                className={classes.SubText}
              >
                University Type
              </Typography>
              <br />
              <Typography
                align="center"
                variant="body1"
                className={classes.MainText}
              >
                <b>
                  {cardData?.university_type ? cardData.university_type : "NA"}
                </b>
              </Typography>
            </>
          ) : (
            <>
              <Typography
                align="center"
                variant="body1"
                className={classes.SubText}
              >
                Type of College
              </Typography>
              <br />
              <Typography
                align="center"
                variant="body1"
                className={classes.MainText}
              >
                <b>
                  {cardData?.type_of_college
                    ? cardData.type_of_college + " College"
                    : "NA"}
                </b>
              </Typography>
            </>
          )}
        </Box>

        <Box className={classes.BorderBox}>
          <Box className={classes.imageBox}>
            <img src={calender} alt="" height="40px" />
          </Box>
          <br />
          <Typography
            align="center"
            variant="body1"
            className={classes.SubText}
          >
            Established in
          </Typography>
          <br />
          <Typography
            align="center"
            variant="body1"
            className={classes.MainText}
          >
            <b>{cardData?.year_founded ? cardData.year_founded : "NA"}</b>
          </Typography>
        </Box>
        {parseInt(data?.placement?.highest_salary) ? (
          <Box className={classes.BorderBox}>
            <Box className={classes.imageBox}>
              <img src={maxpackage} alt="" height="40px" />
            </Box>
            <br />
            <Typography
              align="center"
              variant="body1"
              className={classes.SubText}
            >
              Max. Package
            </Typography>
            <br />
            <Typography
              align="center"
              variant="body1"
              className={classes.MainText}
            >
              <b>
                {parseInt(data?.placement?.highest_salary)
                  ? parseInt(data?.placement?.highest_salary) > 99999
                    ? LFormatter(parseInt(data?.placement?.highest_salary))
                    : kFormatter(parseInt(data?.placement?.highest_salary))
                  : "NA"}
              </b>
            </Typography>
          </Box>
        ) : (
          <Box className={classes.BorderBox}>
            <Box className={classes.imageBox}>
              <img src={tick} alt="" height="40px" />
            </Box>
            <br />
            <Typography
              align="center"
              variant="body1"
              className={classes.SubText}
            >
              Approved By
            </Typography>
            <br />
            <Typography
              align="center"
              variant="body1"
              className={classes.MainText}
            >
              <b>
                {data?.college_info?.approved_by.length > 0
                  ? data?.college_info?.approved_by.map((item) =>
                      data?.college_info?.approved_by.indexOf(item) !==
                      data?.college_info?.approved_by.length - 1
                        ? item + ", "
                        : item
                    )
                  : "NA"}{" "}
              </b>
            </Typography>
          </Box>
        )}

        <Box className={classes.BorderBox}>
          <Box className={classes.imageBox}>
            <img src={star} alt="" height="40px" />
          </Box>
          <br />
          <Typography
            align="center"
            variant="body1"
            className={classes.SubText}
          >
            Rating
          </Typography>
          <br />
          <Typography
            align="center"
            variant="body1"
            className={classes.MainText}
          >
            <b>{cardData?.reviews?.rating}/10</b>
          </Typography>
        </Box>
        <Box className={classes.BorderBox}>
          <Box className={classes.imageBox}>
            <img src={affiliation} alt="" height="40px" />
          </Box>
          <br />
          <Typography
            align="center"
            variant="body1"
            className={classes.SubText}
          >
            Affiliated to
          </Typography>
          <br />
          <Typography
            align="center"
            variant="body1"
            className={classes.MainText}
          >
            <b>
              {cardData?.affiliated_to ? cardData.affiliated_to?.name : "NA"}
            </b>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

import { Grid } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { BackendBaseApi } from "../../../constants/constants";
import Loader from "../../common/UI/Loader";
import Layout from "..//Layout/Layout";
import BlogCard from "./BlogCard";

export default function Blog() {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   GetDataBlog(BackendBaseApi.CMS, "blogs", setBlogData, setLoading);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const GetDataBlog = async (baseUrl, endPoint, updateState, setLoading) => {
  //   fetch(baseUrl + endPoint)
  //     .then(response => response.json())
  //     .then(result => {
  //       console.log("blogData",result);
  //       var arr=[];
  //       result &&
  //         result.filter(item => item.category.Title === "Student" && arr.push(item))
  //       updateState(arr);
  //       setLoading(false);
  //     })
  //     .catch(error => console.log('error', error));
  // };
  useEffect(()=>{
    const NewBlogData = async(updateState, setLoading) => {
      fetch(`${BackendBaseApi.CMS}blogs/and?category.id=${1}&_sort=published_at:DESC`)
      .then(response => response.json())
      .then(result => {
        var arr=[];
        result &&
          result.map(item => arr.push(item))
        updateState(arr);
        setLoading(false);
      })
      .catch(error => console.log('error', error));
    }
    NewBlogData(setBlogData, setLoading);
  },[]);

  return (
    <Layout>
      {!loading ? (
        <div>
          <Grid container>
            <Grid xs={12} style={{ marginTop: "0px" }}>
              <BlogCard data={blogData} />
            </Grid>
          </Grid>
        </div>
      ) : (
        <Loader />
      )}
    </Layout>
  );
}

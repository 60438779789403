import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { emailRegex, BackendBaseApi } from "../../../../../constants/constants";
import { useAlert } from "react-alert";
import { setDefaultBreakpoints } from "react-socks";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      boxShadow: "none",
      marginTop: "-50px",
    },
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    height: "40px",
    boxShadow: "none",
    borderRadius: "30px",
    display: "flex",
    fontWeight: "400",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    //fontSize: "14px",
    color: "#c5c4ba",
    transform: "translate(15px, 14px) scale(1)",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  errorPassword: {
    color: "#D64045",
    fontSize: 13,
  },
}));

export default function Register({ setStep, email, setOtpEditStep, setEmail, data }) {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [appliedReferral, setAppliedReferral] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = React.useState({
    name: "",
    email: email,
    phone: "",
    password: "",
    showPassword: false,
    referralCode: "",
  });

  const [errorValues, setErrorValues] = React.useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const [isApplied, setIsApplied] = React.useState(false);

  const validateForm = () => {
    let isError = false;
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let passwordError = "";

    if (!values?.name) {
      nameError = "Please enter full name";
      isError = true;
    }
    if (!values?.email) {
      emailError = "Please enter valid email address";
      isError = true;
    }
    if (!values?.phone || values?.phone.length !== 10) {
      phoneError = "Please enter valid phone number";
      isError = true;
    }
    if (!values?.password || values?.password.length < 6) {
      passwordError = "Password length should be atleast 6 character";
      isError = true;
    }
    setErrorValues({
      name: nameError,
      email: emailError,
      phone: phoneError,
      password: passwordError,
    });

    return isError ? false : true;
  };

  useEffect(() => {
   if(data && Object.keys(data).length > 0){
     setValues({...values, name: `${data?.first_name} ${data.last_name ? data.last_name : ""}`, phone: data?.phone, email: data?.email})
      setEmail(data?.email);
    }
  }, [data])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signup = () => {
    if (validateForm()) {
      if(!continueCheck){
        alert.error("Please check GetWork's T&C!");
        return;
      }
      let body = {
        full_name: values?.name,
        user_email: values?.email,
        phone: values?.phone,
        password: values?.password,
      };
      if (appliedReferral) {
        body["referral_code"] = appliedReferral;
      }
      setIsLoading(true);
      axios
        .post(`${BackendBaseApi.PRAVESH}api/company_saas/signup/`, body)
        .then((res) => {
          if (res?.data?.success) {
            alert.success(res?.data?.data?.message);
            setStep(3);
            setOtpEditStep(1);
          } else {
            alert.error(res?.data?.error);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          alert.error("Something went wrong. Please try again!!");
        });
    }
  };

  const [referralMsg, setReferralMsg] = useState("");
  const [referralSuccess, setReferralSuccess] = useState(false);
  const [referralLoading, setReferralLoading] = useState(false);

  const [continueCheck, setContinue] = useState(true);

  const applyReferral = () => {
    const body = {
      referral_code: values?.referralCode,
    };
    setReferralLoading(true);
    axios
      .post(`${BackendBaseApi.PRAVESH}api/company_saas/apply_referral/`, body)
      .then((res) => {
        if (res?.data?.success) {
          setIsApplied(true);
          setReferralMsg(res?.data?.data?.message);
          setReferralSuccess(true);
          setAppliedReferral(values?.referralCode);
        } else {
          setIsApplied(false);
          setReferralMsg(res?.data?.error);
          setReferralSuccess(false);
          setAppliedReferral(null);
        }
        setReferralLoading(false);
      })
      .catch((err) => {
        setIsApplied(false);
        setReferralMsg("Something went wrong. Please try again!");
        setReferralSuccess(false);
        setAppliedReferral(null);
        setReferralLoading(false);
        alert.error("Something went wrong. Please try again!");
      });
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="h4" style={{ fontWeight: "500" }}>
            Register with us
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <TextField
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label="Full Name"
            variant="outlined"
            value={values?.name}
            required
            onChange={handleChange("name")}
            error={errorValues?.name ? true : false}
            helperText={errorValues?.name ? errorValues?.name : ""}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <TextField
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label="Email ID"
            variant="outlined"
            value={values?.email}
            disabled
            required
            onChange={(e) => {
              setValues({...values, email: e.target.value});
              setEmail(e.target.value);
              setErrorValues({...errorValues, email: ""});
              }
            }
            error={errorValues?.email ? true : false}
            helperText={errorValues?.email ? errorValues?.email : ""}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <TextField
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label="Phone Number"
            variant="outlined"
            type="number"
            value={values?.phone}
            required
            onChange={handleChange("phone")}
            error={errorValues?.phone ? true : false}
            helperText={errorValues?.phone ? errorValues?.phone : ""}
          />
        </Grid>

        {/* <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <TextField
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label="Designation"
            variant="outlined"
          />
        </Grid> */}

        <Grid item xs={12} md={12}>
          <FormControl
            className={clsx(classes.margin, classes.textField, classes.form)}
            variant="outlined"
            //   error={passwordErrorMessage !== "" ? true : false}
          >
            <InputLabel required className={classes.floatingLabelFocusStyle}>
              Password
            </InputLabel>
            <OutlinedInput
              style={{
                borderRadius: "30px",
              }}
              id="outlined-adornment-password"
              required
              type={values.showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
              value={values?.password}
              onChange={handleChange("password")}
            />
            <FormHelperText
              style={{
                fontFamily: " Nunito",

                fontSize: "12px",
              }}
              id="standard-weight-helper-text"
              className={errorValues?.password ? classes.errorPassword : ""}
            >
              {errorValues?.password ? errorValues?.password : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <TextField
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label="Enter Referral Code"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Button
                  color="primary"
                  style={{ fontWeight: "500" }}
                  disabled={values?.referralCode && !isApplied ? false : true}
                  onClick={applyReferral}
                >
                  <Typography
                    variant="body2"
                    style={{ color: `${isApplied ? "#34A853" : "#007BFF"}` }}
                  >
                    {referralLoading ? <CircularProgress style={{ color: "#007BFF" }} size={18} /> :  isApplied ? "Applied" : "Apply"}
                  </Typography>
                </Button>
              ),
            }}
            value={values?.referralCode}
            onChange={(e) => {
              setValues({
                ...values,
                referralCode: e.target.value.toUpperCase(),
              });
              setIsApplied(false);
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {referralMsg ? (
              referralSuccess ? (
                <CheckCircleRoundedIcon style={{ color: "#34A853" }} />
              ) : (
                <HighlightOffIcon style={{ color: "#F1511B" }} />
              )
            ) : null}

            <Typography
              variant="body2"
              style={{ color: `${referralSuccess ? "#34A853" : "#F1511B"}` }}
            >
              {referralMsg ? referralMsg : ""}
            </Typography>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={continueCheck}
                onChange={(e) => {setContinue(!continueCheck)}}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <Typography style={{ marginTop: "14px", fontSize: "14px" }}>
                By clicking on continue, you agree to GetWork’s 
                <a href="https://getwork.org/terms-and-conditions" target="_blank">Terms &
                Conditions </a> and <a href="https://getwork.org/privacy-and-cookie-policy" target="_blank">Privacy Policy </a>
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          {" "}
          <Button
            color="primary"
            className={classes.btn}
            variant="contained"
            onClick={signup}
          >
            {isLoading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Continue"
            )}
          </Button>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
        <div className={classes.create}>
                      <Typography
                        variant="body2"
                        style={{ fontFamily: " Nunito" }}
                      >
                        Wrong Email?
                      </Typography>

                      <Button
                        size="small"
                        style={{ color: "#007bff" }}
                        onClick={() => {
                          setStep(1);
                        }}
                      >
                        <Typography variant="body2" className={classes.text}>
                          Try with different email.
                        </Typography>
                      </Button>
              </div>
              </Grid>
      </Grid>
    </>
  );
}

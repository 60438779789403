import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { NEXT_APP_ENDPOINT } from "../../../../../constants/constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },

  linktext: {
    color: "white",
    paddingBottom: "10px",
    "&:hover": {
      textDecoration: "underline",
      color: "white",
    },
  },
  text: {
    marginBottom: "7px",
    fontSize: 14,
    fontFamily: "Nunito",
  },
  title: {
    color: "#fff",
    fontSize: 20,
    fontFamily: "Nunito",
  },
}));

export default function AboutGetWork() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.title} gutterBottom>
        About GetWork
      </Typography>
      <br />
      <a href={`${NEXT_APP_ENDPOINT}/post-job`} className={classes.linktext}>
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Post your Job Vacancies
        </Typography>{" "}
      </a>

      <a href={`${NEXT_APP_ENDPOINT}/about-us`} className={classes.linktext}>
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          About Us
        </Typography>{" "}
      </a>

      <a href={`${NEXT_APP_ENDPOINT}/blogs`} className={classes.linktext}>
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Blogs
        </Typography>{" "}
      </a>

      <a href={`${NEXT_APP_ENDPOINT}/career`} className={classes.linktext}>
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Career
        </Typography>{" "}
      </a>

      <a href={`${NEXT_APP_ENDPOINT}/faqs`} className={classes.linktext}>
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          FAQ's
        </Typography>{" "}
      </a>

      <a
        href={`${NEXT_APP_ENDPOINT}/security-policy`}
        className={classes.linktext}
      >
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Security Policy
        </Typography>{" "}
      </a>

      <a
        href={`${NEXT_APP_ENDPOINT}/terms-and-conditions`}
        className={classes.linktext}
      >
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Terms & Conditions
        </Typography>{" "}
      </a>

      {/* <a
        href={`${NEXT_APP_ENDPOINT}/stautory-compliances`}
        className={classes.linktext}
      >
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Statutory Compliances
        </Typography>{" "}
      </a> */}

      <a
        href={`${NEXT_APP_ENDPOINT}/cancellation-and-refund`}
        className={classes.linktext}
      >
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Cancellation & Refund
        </Typography>{" "}
      </a>

      <a
        href={`${NEXT_APP_ENDPOINT}/privacy-and-cookie-policy`}
        className={classes.linktext}
      >
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Privacy & Cookie Policy
        </Typography>{" "}
      </a>

      <a href={`${NEXT_APP_ENDPOINT}/contact-us`} className={classes.linktext}>
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Contact Us
        </Typography>{" "}
      </a>

      <a
        href={
          `${NEXT_APP_ENDPOINT}/sitemap` /* `${NEXT_APP_ENDPOINT}/sitemap.xml` */
        }
        className={classes.linktext}
      >
        <Typography
          variant="body2"
          color="inherit"
          className={classes.text}
          id="footerBtn"
        >
          Sitemap
        </Typography>{" "}
      </a>
    </>
  );
}

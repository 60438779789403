/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import op from "../../../assets/images/oop.png";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/httpRequest";
import NewJobCard from "../../../components/college/JobCard/NewJobCard";
import TrackPane from "../../../components/college/TrackPane/TrackPane";
import { JobListProps, AllJobProfilesProps, JobTypeProps, AllCompanyProps } from "../NewJob/@types";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsCont: {
      marginTop: "70px",
      overflowY: "scroll",
      scrollbarWidth: "none",
    },
  })
);

export default function CloseJob() {
  const classes = useStyles();
  const [jobData, setJobData] = useState<JobListProps[]>([]);
  const alert = useAlert();
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);

  const [loading, setLoading] = useState(false);

  const [allJobProfiles, setAllJobProfiles] = useState<AllJobProfilesProps[]>([]);
  const [jobProfile, setJobProfile] = useState("All");

  const [jobType, setJobType] = useState<JobTypeProps[]>([]);
  const [selectedJobType, setSelectedJobType] = useState("All");

  const [allCompanies, setAllCompanies] = useState<AllCompanyProps[]>([]);
  const [selectedCompany, setSelectedCompany] = useState("All");

  const [queryParamsObj, setQueryParamsObj] = useState({});
  const [jobProfileObject, setJobProfileObject] = useState({});

  const [placementType, setPlacementType] = useState("Placement Type");
  const [searching, setSearching] = useState("");
  const [buttonClickSearch, setButtonClickSearch] = useState("");
  const firstCall = useRef(0);
  let locationSearch = useLocation().search;
  let params = (new URLSearchParams(locationSearch)).get('query');

  // const [search, setSearch] = useState("");
  const [filteredJobData, setFilteredJobData] = useState<JobListProps[]>([]);

  const generateQueryParamsObj = (arr: JobListProps[]) => {
    const newArr = arr.map((item) => ({
      id: item.id,
      is_approved: item.is_approved,
      is_rejected: !item.is_approved,
      company_id: item.job.company.company_id,
      college_id: parseInt(localStorage.getItem("college_id")!),
      user_id: parseInt(localStorage.getItem("user_id")!),
    }));
    setQueryParamsObj(newArr);
  };

  // useEffect(() => {
  //   GetData(
  //     BackendBaseApi.NIYUKTI,
  //     `job/college/invite/?college_id=MQ==&job_status=Q0xPU0U=`,
  //     { headers: localStorage.getItem("gw_token") },

  //     setJobData
  //   );
  // }, []);
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    let e = event.nativeEvent;
    if (
      //@ts-ignore
      e.target.scrollTop + 10 >=
      //@ts-ignore
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        newData(newURL, newEndPoint);
      }
    }
  };

  useEffect(() => {
    if (placementType == "All" || placementType == "Placement Type") {
      setFilteredJobData(jobData);
    } else {
      if (placementType == "On Campus") {
        setPlacementType("ON CAMPUS");
      } else if (placementType == "Off Campus") {
        setPlacementType("OFF CAMPUS");
      }
      let temp1 = jobData.filter((applicant) => {
        return applicant.job.hiring_type == placementType;
      });
      setFilteredJobData(temp1);
    }
  }, [placementType]);

  // useEffect(() => {
  //   if (jobType == "All" || jobType == "Job Type") {
  //     setFilteredJobData(jobData);
  //   } else {
  //     if (jobType == "Internships") {
  //       setJobType("Part Time");
  //     }
  //     let temp1 = jobData.filter((applicant) => {
  //       return applicant.job.employment_type_name == jobType;
  //     });
  //     setFilteredJobData(temp1);
  //   }
  // }, [jobType]);

  const getJobTypes = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch((err) => {
      throw err;
    });
    let obj = { id: "All", job_type: "All" };
    setJobType([obj, ...res.data.data]);
  };

  const getCompanies = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + `job/college/get_companies/?college_id=${btoa(localStorage.getItem("college_id")!)}`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    }).catch((err) => {
      throw err;
    });
    let obj = { company_id: "All", company_name: "All" };
    setAllCompanies([obj, ...res.data.data]);
  };

  useEffect(() => {
    if(searching!=='' && firstCall.current === 0){
      firstCall.current = 1;
      return;
    }
    if (searching !== "") getAllJobs();
  }, [buttonClickSearch]);

  useEffect(() => {
    //getJobProfiles();
    getJobTypes();
    getCompanies();
  }, []);

  const getAllJobs = () => {
    const checkJobProfile = () => {
      if (jobProfile === "All") {
        return "";
      } else {
        return `&job_id=${btoa(jobProfile)}`;
      }
    };

    const checkJobType = () => {
      if (selectedJobType === "All") {
        return "";
      } else {
        return `&job_type_id=${btoa(selectedJobType)}`;
      }
    };

    const checkCompany = () => {
      if (selectedCompany === "All") {
        return "";
      } else {
        return `&company_id=${btoa(selectedCompany)}`;
      }
    };

    const checkSearch = () => {
      if (buttonClickSearch !== ""){
        firstCall.current = 1;
      return `&search=${buttonClickSearch}`;
      }
      else if(firstCall.current === 0 && params) {
        setSearching(params);
        setButtonClickSearch(params);
        return `&search=${params}`;
      }
      else {
        firstCall.current = 1;
        return ``
      };
    };

    setLoading(true);

    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/invite/?college_id=${btoa(localStorage.getItem("college_id")!)}${checkJobType()}${checkJobProfile()}${checkCompany()}&job_status=Q0xPU0U=${checkSearch()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.next === null) {
            setEnd(true);
          } else {
            setEnd(false);
            setNewURL(res.data.data.next.slice(0, 20));
            setNewEndPoint(res.data.data.next.slice(20));
          }

          setJobData(res.data.data.results);
          generateQueryParamsObj(res.data.data.results);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Some Error Occured");
        throw err;
      });
  };

  useEffect(() => {
    getAllJobs();
  }, [selectedJobType, selectedCompany, jobProfile]);

  const newData = (baseURL: string, endpoint: string) => {
    setLoading(true);
    Axios.get(baseURL + endpoint, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.data.next === null) {
          setEnd(true);
        } else {
          setNewURL(res.data.data.next.slice(0, 20));
          setNewEndPoint(res.data.data.next.slice(20));
        }

        let data = [...jobData, ...res.data.data.results];
        setJobData(data);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  return (
    <Box style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <TrackPane
          profiles={allJobProfiles}
          jobProfile={jobProfile}
          jobTypes={jobType}
          selectedJobType={selectedJobType}
          setSelectedJobType={setSelectedJobType}
          setJobProfile={setJobProfile}
          allCompanies={allCompanies}
          setSelectedCompany={setSelectedCompany}
          setJobProfileObject={setJobProfileObject}
          JobProfileObject={jobProfileObject}
          searching={searching}
          setSearching={setSearching}
          buttonClickSearch={buttonClickSearch}
          setButtonClickSearch={setButtonClickSearch}
          setJobData={setJobData}
          setEnd={setEnd}
          setNewURL={setNewURL}
          setNewEndPoint={setNewEndPoint}
          path="CLOSE"
        />
      </div>
      <div className={classes.cardsCont} onScroll={handleScroll} style={{ height: filteredJobData.length > 9 ? "100vh" : "auto" }}>
        {buttonClickSearch !== "" && (
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                marginLeft: 50,
                marginTop: 10,
              }}
            >
              You searched for "{buttonClickSearch}"...
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                // setSearching("");
                setButtonClickSearch("");
                setJobData([]);
                setEnd(false);
                setNewEndPoint("");
                setNewURL("");
              }}
              style={{ borderRadius: 30 }}
            >
              Clear your Search
            </Button>
          </Box>
        )}

        {jobData.length > 0 ? (
          <NewJobCard data={jobData} fullView={false} setJobData={setJobData} jobIsClosed={true} />
        ) : loading ? (
          <Box padding="2% 2%">
            <JobCardSkeleton />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <img src={op} alt="" width="50%" height="auto" />

            <Typography variant="h4">No Jobs available</Typography>
          </Box>
        )}
        {loading && (
          <Box padding="2% 2%">
            <JobCardSkeleton />
          </Box>
        )}
      </div>
    </Box>
  );
}

import { Tooltip } from "@material-ui/core";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import idea from "../../../assets/images/idea.png";
import { BackendBaseApi } from "../../../constants/constants";
import AddDetails from "./AddDetails";

const StudentProfileProjects = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const addProject = "ADD_PROJECT";
  const editProject = "EDIT_PROJECT";
  const [type, setType] = useState("");
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [newProject, setNewProject] = useState({
    id: null,
    title: "",
    links: null,
    description: "",
    start_date: null,
    end_date: null,
    skills: [],
  });

  function validURL(str) {
    var pattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return !!pattern.test(str);
  }

  const getAllProjects = () => {
    setLoading(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/education/user_project", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        setLoading(false);

        if (!res.data.success) {
          alert.error(res.data.error);
        } else {
          if (res.data.data.length > 0) {
            //popualte state with projects from backend
            let temp = [];
            res.data.data.forEach((project) => {
              temp.push(project);
            });
            setProjectDetails(temp);
          }
          //alert.success('fetched project details succeffully')
        }
      })
      .catch((err) => {
        alert.error("Error fetching project details");
      });
  };

  useEffect(() => {
    getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveProject = () => {
    let data = newProject;

    Axios.post(BackendBaseApi.PRAVESH + "api/education/user_project", data, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          let temp = [...projectDetails];
          setNewProject({ ...newProject, id: res.data.data.project_id });
          temp.push(newProject);
          // setProjectDetails(temp);
          alert.success(res.data.data.message);
        } else res.error(res.data.error);
      })
      .catch((err) => {
        alert.error(
          "There was some error adding a project. Please try again later"
        );
      });
  };

  useEffect(() => {
    if (newProject.id) {
      setProjectDetails([...projectDetails, newProject]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProject]);
  const AddProject = () => {
    let isValid = true;
    if (selectedProject?.links && !validURL(selectedProject?.links)) {
      alert.error("Please add Project Link with http:// or https://");
      return;
    }
    Object.values(newProject).forEach((val, ind) => {
      if (!val || (val && val.length == 0)) {
        isValid = false;
        return;
      }
    });
    if (isValid) saveProject();
    else alert.error("Please fill all required details");
  };

  const ChangeProject = () => {
    let temp = [...projectDetails];
    if (selectedProject?.links && !validURL(selectedProject?.links)) {
      alert.error(
        "Please check Project Link. It should start with http:// or https://"
      );
      return;
    }
    temp.forEach((project, ind) => {
      if (project.id == selectedProject.id) {
        //send a patch request to update details

        let res = updateProject(selectedProject);
        res.then((response) => {
          temp[ind] = selectedProject;
          setProjectDetails(temp);
        });
      }
    });
  };

  const DeleteProject = (id) => {
    Axios.delete(BackendBaseApi.PRAVESH + `api/education/user_project/${id}/`, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          let temp = projectDetails.filter((project) => {
            return project.id != id;
          });
          setProjectDetails(temp);
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error(
          "There was some error deleting this project. Try again later"
        );
      });
  };

  const updateProject = async (project) => {
    let result = Axios.patch(
      BackendBaseApi.PRAVESH + `api/education/user_project/${project.id}/`,
      project,
      {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) alert.success(res.data.data.message);
        else alert.error(res.data.error);
      })
      .catch((err) => {
        console.log(err);
      });
    return result;
  };

  const EditProject = (project) => {
    setSelectedProject(project);
    openProjectForm("EDIT_PROJECT");
  };

  const openProjectForm = (project_type) => {
    setType(project_type);
    setShowProjectForm(true);
  };

  return (
    <>
      {showProjectForm && (
        <AddDetails
          appendToParentState={
            type == "ADD_PROJECT" ? AddProject : ChangeProject
          }
          formState={type == "ADD_PROJECT" ? newProject : selectedProject}
          setFormState={
            type == "ADD_PROJECT" ? setNewProject : setSelectedProject
          }
          detailType={type}
          showForm={showProjectForm}
          setShowForm={setShowProjectForm}
        />
      )}

      <div className="row my-3 justify-content-center">
        <Tooltip title="Add Project" placement="left">
          <button
            onClick={() => {
              openProjectForm("ADD_PROJECT");
            }}
            className="add ripple"
          >
            <span>+</span>
          </button>
        </Tooltip>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : projectDetails.length > 0 ? (
        projectDetails.map((project, ind) => {
          return (
            <div className="card job-card-main" key={project.id}>
              <div
                className="card-body"
                style={{ boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)" }}
              >
                <div className="row">
                  <div className="col-md-8 col-6 text-left">
                    <p
                      className="fs-12"
                      style={{ color: "#000000", fontWeight: "bold" }}
                    >
                      Project {ind + 1}{" "}
                    </p>
                  </div>
                  <div className="col-md-4 col-6 text-right">
                    <Tooltip title="Edit Project" placement="right">
                      <i
                        onClick={() => {
                          EditProject(project);
                        }}
                        className="fas fa-edit edit-icon mx-2"
                      ></i>
                    </Tooltip>
                    <Tooltip title="Delete Project" placement="right">
                      <i
                        onClick={() => {
                          DeleteProject(project.id);
                        }}
                        className="fas fa-trash edit-icon mx-2"
                      ></i>
                    </Tooltip>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-md-1 col-12 text-center">
                    <img
                      src={idea}
                      alt=""
                      style={{ height: "50px", width: "50px" }}
                    />
                  </div>
                  <div className="col-7 text-left">
                    <p className="fs-14">
                      <b className="fs-16"> {project.title}</b> <br />{" "}
                      <a
                        href={
                          validURL(project.links)
                            ? project.links
                            : "//" + project.links
                        }
                        target="blank"
                      >
                        <span className="fw-500 link-text">
                          {project.links}
                        </span>
                      </a>
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-12 text-md-right text-left">
                    <span className="fs-12 text-muted">
                      <i class="far fa-calendar"></i>{" "}
                      {moment(project.end_date).diff(
                        moment(project.start_date),
                        "days"
                      )}{" "}
                      days
                    </span>
                    <br />
                    <span className="fs-13 text-muted">
                      {" "}
                      {moment(project.start_date).format("MMM DD")} -{" "}
                      {moment(project.end_date).format("MMM DD")}{" "}
                    </span>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-md-8 col-sm-12 text-left">
                    <p className="fs-12">{project.description}</p>
                  </div>
                  <div className="col-md-4 col-sm-12 text-left pl-md-5 pl-2">
                    {project.skills &&
                      project.skills.map((projSkill) => {
                        return (
                          <span
                            key={projSkill.skill_id}
                            className="badge badge-light bg-blue-light p-1 mx-2 my-1"
                          >
                            {projSkill.skill_name}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div>No projects yet, but you can add them right away! :)</div>
      )}

      {/* <div className="row mt-4 pt-2 justify-content-center">
            <button className="btn btn-main btn-sm shadow-main update-btn">Update Details</button>
        </div> */}
    </>
  );
};

export default StudentProfileProjects;

import React from "react";
import { Route, Switch, useSelector } from "react-router-dom";
import NotFound from "../bundles/common/components/UI/NotFound";
import { DashboardNew } from "../bundles/company/components/Home/Dashboard/DashboardNew";
//import Invite from "../common/Explore/Invite";
import Blogs from "../common/Explore/Blogs.js";
//import Assignment from "../bundles/company/components/Assignments/Assignment";
import Assignment from "../components/company/Assignments/Assignment";
// import Track from "../bundles/company/components/Home/Track/Track";
//import Explore from "../common/Explore/Explore.js";
import Explore from "../components/company/common/Explore/Explore";
//import CollegeConnection from "../bundles/company/components/Home/Connections/College";
import CollegeConnection from "../components/company/Connections/College";
import CompanyProfile from "../components/company/Home/Profile/Profile";
import CompanyProfileUpdate from "../components/company/Home/Profile/Update";
import CompanySettings from "../components/company/Home/ProfileMenu/CompanySettings";
import UserSettings from "../components/company/Home/ProfileMenu/UserSettings";
//import { Interview } from "../bundles/company/components/Home/Interview/InterviewNew";
import { Interview } from "../components/company/Interview/InterviewNew";
import Invite from "../components/company/invite/Invite";
import Layout from "../NewAppLayout/components/Layout/Layout";
import MuiCustomTheme from "../NewAppLayout/components/Theme/theme";
import TeamInvite from "../NewAppLayout/pages/TeamInvite";
import ApplicantDetails from "../NewAppLayout/pages/DetailIndex";
import ManageJobApplicants from "../NewAppLayout/pages/index";
import JobTrack from "../NewAppLayout/pages/JobTrack";
import JobTrackIndex from "../NewAppLayout/pages/JobTrackIndex";
import SubscriptionIndex from "../NewAppLayout/pages/SubscriptionIndex";
import TempMySubscription from "../NewAppLayout/pages/TempMySubscription";
import Applicants1 from "../pages/company/Applicants";
import CompleteCompanyProfile from "../pages/company/CompleteCompanyProfile/CompleteCompanyProfile";
// import PostJob from "../bundles/company/components/Home/Jobs/PostJob/PostJob";
// import CompanyProfile from "../bundles/company/components/Home/Profile/Profile";
// import CompanyProfileUpdate from "../bundles/company/components/Home/Profile/Update";
// import CompanySettings from "../bundles/company/components/Home/ProfileMenu/CompanySettings";
// import UserSettings from "../bundles/company/components/Home/ProfileMenu/UserSettings";
import PostJob from "../pages/company/PostJobNew/index";
// import AllJobs from "../components/company/jobs/AllJobs";
import Track2 from "../pages/company/Track";
import CompanyNewRecruiter from "../NewAppLayout/components/Dialog/CompanyNewRecruiter";
import { parseWithOptions } from "date-fns/fp";


// const NewRoute = ({children, path}) => {
//   return(
//   <Route exact path={parseWithOptions}>
//     <>
//     <CompanyNewViewRedirectDialog />
//   {children}
//   </>
// </Route>
//   )
// }

const CompanyRoutes = ({}) => {
  
  return (
    <div style={{ width: "100%" }}>
      <MuiCustomTheme>
      <CompanyNewRecruiter fromOverview={true} />
      </MuiCustomTheme>
      <Switch>
        {/* <Route exact path="/company/all-jobs">
          <AllJobs />
        </Route> */}
        {/* <Route path="*">
          <CompanyNewViewRedirectDialog />
        </Route> */}
        <Route exact path="/company/complete-profile">
          <CompleteCompanyProfile />
        </Route>
        <MuiCustomTheme>
          <Layout>
            <Route exact path="/company/all-jobs">
              <JobTrack />
            </Route>
            <Route exact path="/company/blogs/:token">
              <Blogs />
            </Route>
            <Route exact path="/company/invite">
              <Invite />
            </Route>
            <Route exact path="/company/explore">
              <Explore />
            </Route>
            {/* <Route exact path="/company/all-jobs/:jobType">
          <AllJobs />
        </Route> */}
            <Route exact path="/company/dashboard">
              <DashboardNew />
            </Route>
            <Route exact path="/company/assignment">
              <Assignment />
            </Route>
            {/* <Route exact path="/company/job-applicants">
          <Applicants />
        </Route> */}
            <Route exact path="/company/job-applicants/:token?">
              <Applicants1 />
            </Route>
            <Route exact path="/company/post-job">
              <PostJob />
            </Route>
            <Route exact path="/company/post-job/:job_id">
              <PostJob />
            </Route>
            <Route exact path="/company/interview">
              <Interview />
            </Route>
            <Route exact path="/company/track/:token?">
              <Track2 />
            </Route>

            <Route exact path="/company/college-connection">
              <CollegeConnection />
            </Route>

            <Route
              exact
              path="/company/company-settings/:token?"
              component={CompanySettings}
            />
            <Route
              exact
              path="/company/settings/payment-status/:status"
              component={CompanySettings}
            />
            <Route
              exact
              path="/company/user-settings/:token?"
              component={UserSettings}
            />
            <Route exact path="/company/profile">
              <CompanyProfile />
            </Route>
            <Route exact path="/company/profile_update">
              <CompanyProfileUpdate />
            </Route>

            <Route exact path="/company/manage-job/:token?">
              <ManageJobApplicants />
            </Route>
            <Route exact path="/company/students/:token?">
              <ApplicantDetails />
            </Route>
            <Route exact path="/company/job-track">
              <JobTrackIndex />
            </Route>
            <Route exact path="/company/plan">
              <SubscriptionIndex />
            </Route>
            <Route exact path="/company/subscription">
              <TempMySubscription />
            </Route>
            {/*    <Route exact path="company/company-team-invite-new">
              <TeamInvite />
            </Route> */}

            {/* </MuiCustomTheme> */}
          </Layout>
        </MuiCustomTheme>
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default CompanyRoutes;

import {
  Box,
  Button,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MuiThemeProvider,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import OffCampus from "./OffCampus";
import OnCampus from "./OnCampus";
import { useDispatch, useSelector } from "react-redux";
import { getRounds } from "../../company/action";
import CssTextField from "./FormComponents/CssTextField";
import StyledRadio from "./FormComponents/CustomizedRadio";
import DateSelector from "./FormComponents/DateComponent";
import theme from "./FormComponents/MuiOutlined";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import generateCsv, {
  generateCsvDegreeName,
} from "../../../common/generateCsv/generateCsv";
import BootstrapTooltip, {
  BootstrapTooltipNew,
} from "../../../common/Tooltip/Tooltip";
import HelpFunction from "./FormComponents/help";
import { useParams } from "react-router-dom";
import CommonChip from "../../CommonChip/CommonChip";
import CommonButton from "../CommonButton/CommonButton";
const HiringProcess = (props) => {
  let { job_id } = useParams();

  const {
    state,
    setState,
    hiringRef,
    offCampusLoader,
    setOffCampusLoader,
    jobData,
    setApplyButtonClicked,
    applyButtonClicked,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [openOnCampus, setOpenOnCampus] = React.useState(false);
  const [qualificationData, setQualificationData] = useState([]);
  const [preferencesText, setPreferencesText] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenOnCampus = () => {
    setOpenOnCampus(true);
  };

  const handleCloseOnCampus = () => {
    setOpenOnCampus(false);
  };

  useEffect(() => {
    if (job_id && jobData && jobData !== undefined) {
      setStartDate(jobData.apply_start_date);
      setEndDate(jobData.apply_end_date);
    }
  }, [jobData]);

  useEffect(() => {
    if (startDate) {
      setState({
        ...state,
        Colleges: {
          ...state.Colleges,
          apply_start_date: startDate,
          apply_end_date: endDate ? endDate : null,
        },
      });
    }
  }, [startDate, state.Preferences.eligibility_criteria.courses]);

  useEffect(() => {
    if (endDate) {
      setState({
        ...state,
        Colleges: {
          ...state.Colleges,
          apply_start_date: startDate ? startDate : null,
          apply_end_date: endDate,
        },
      });
    }
  }, [endDate, state.Preferences.eligibility_criteria.courses]);

  useEffect(() => {
    const temp = {};

    var arr = [...state.Preferences.eligibility_criteria.courses];
    arr.forEach((item) => {
      if (temp[item.degree_name] === undefined) {
        temp[item.degree_name] = [{ ...item }];
      } else {
        temp[item.degree_name].push({ ...item });
      }
    });
    setQualificationData(temp);
  }, [
    state.Preferences.eligibility_criteria.courses.length,
    state.Preferences.eligibility_criteria.degrees.length,
  ]);

  useEffect(() => {
    if (state.Basics.posted_on_job_lake) {
      setState({
        ...state,
        Colleges: {
          ...state.Colleges,
          college: [],
        },
      });
    }
  }, [state.Basics.posted_on_job_lake]);

  useEffect(() => {
    if (state.Colleges.hiring_type === "ON CAMPUS")
      setPreferencesText("Colleges");
    else setPreferencesText("Courses");
  }, [state.Colleges.hiring_type]);

  // useEffect(() => {
  //   if (state.Preferences.eligibility_criteria.courses.length > 0)
  //     setState({
  //       ...state,
  //       Colleges: {
  //         ...state.Colleges,
  //         college: [],
  //       },
  //     });
  //   if (state.Colleges.college.length > 0)
  //     setState({
  //       ...state,
  //       Preferences: {
  //         ...state.Preferences,
  //         eligibility_criteria: {
  //           ...state.Preferences.eligibility_criteria,
  //           degrees: [],
  //           courses: [],
  //         },
  //       },
  //     });
  // }, [state.Colleges.hiring_type]);
  return (
    <>
      {" "}
      <div style={{ marginTop: "50px" }} ref={hiringRef}>
        <h5 className={classes.heading}>Hiring Process</h5>
        <Divider />

        <Grid container style={{ marginTop: "30px" }}>
          <Grid item xs={3} className={classes.section}>
            <p className={classes.formLabel} style={{ paddingRight: "20px" }}>
              Hiring Type
              <span className={classes.RedStar}>*</span>
              <HelpFunction title={"How do you want to hire?"} />
            </p>
          </Grid>
          <Grid item xs={9} className={classes.section}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={state.Colleges.hiring_type}
                onChange={(e) => {
                  setState({
                    ...state,
                    Colleges: {
                      ...state.Colleges,
                      hiring_type: e.target.value,
                    },
                    Basics: {
                      ...state.Basics,
                      is_ipo_job: e.target.value === "ON CAMPUS" ? false : true,
                    },
                  });
                }}
              >
                <FormControlLabel
                  value="ON CAMPUS"
                  control={<StyledRadio />}
                  label={
                    <span className={classes.labelSize}>
                      {"On Campus(Choose Specific Colleges)"}
                    </span>
                  }
                />
                <FormControlLabel
                  value="OFF CAMPUS"
                  control={<StyledRadio />}
                  label={
                    <span className={classes.labelSize}>{"Open To All"}</span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {state.Colleges.hiring_type == "ON CAMPUS" && (
            <>
              <Grid item xs={3} className={classes.section}>
                <p
                  className={classes.formLabel}
                  style={{ paddingRight: "20px" }}
                >
                  Job Lake?
                  <span className={classes.RedStar}>*</span>
                  <HelpFunction
                    title={
                      "Do you want to post your job in all available colleges?"
                    }
                  />
                </p>
              </Grid>
              <Grid item xs={9} className={classes.section}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={
                      state.Basics.posted_on_job_lake === true
                        ? "true"
                        : "false"
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        Basics: {
                          ...state.Basics,
                          posted_on_job_lake:
                            e.target.value === "true" ? true : false,
                        },
                      });
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<StyledRadio />}
                      label={<span className={classes.labelSize}>{"Yes"}</span>}
                    />
                    <FormControlLabel
                      value="false"
                      control={<StyledRadio />}
                      label={<span className={classes.labelSize}>{"No"}</span>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item sm={3} className={classes.section}>
            <p className={classes.formLabel}>
              Global Dates
              <span className={classes.RedStar}>*</span>
            </p>
          </Grid>
          <Grid
            item
            sm={3}
            className={classes.section}
            style={{ marginRight: "2%" }}
          >
            <DateSelector
              date={startDate}
              setDate={setStartDate}
              dateType={"Apply Start Date"}
            />
          </Grid>
          <Grid item sm={3} className={classes.section}>
            <DateSelector
              date={endDate}
              setDate={setEndDate}
              dateType={"Apply End Date"}
            />
          </Grid>
        </Grid>
        <br />
        {state.Colleges.hiring_type !== "" && (
          <>
            <p
              align="center"
              className={classes.formLabel}
              style={
                state.Colleges.hiring_type == "" ||
                (state.Colleges.hiring_type !== "OFF CAMPUS" &&
                  state.Basics.posted_on_job_lake === true)
                  ? { color: "grey" }
                  : { color: "black" }
              }
            >
              Click on the below button to choose {" " + preferencesText}.&nbsp;
              {state.Basics.posted_on_job_lake === true && (
                <>
                  You can't select {" " + preferencesText} when you want to post
                  job in job lake.
                </>
              )}
            </p>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                float: "center",
                marginBottom: "20px",
              }}
            >
              <CommonButton
                variant="contained"
                onClick={() => {
                  state.Colleges.hiring_type == "OFF CAMPUS"
                    ? handleClickOpen()
                    : handleOpenOnCampus();
                }}
                disabled={
                  state.Colleges.hiring_type == "" ||
                  (state.Colleges.hiring_type !== "OFF CAMPUS" &&
                    state.Basics.posted_on_job_lake === true)
                    ? true
                    : false
                }
              >
                Select {" " + preferencesText}
              </CommonButton>
            </Box>
          </>
        )}

        {state.college_id_list.checkAll && (
          <Box className={classes.selectedPreferences}>
            <Grid container>
              <Grid item xs={3}>
                <p className={classes.formLabel}>
                  Selected Colleges
                  <Button
                    autofocus
                    onClick={() => {
                      state.Colleges.hiring_type == "OFF CAMPUS"
                        ? handleClickOpen()
                        : handleOpenOnCampus();
                    }}
                    disabled={
                      state.Colleges.hiring_type == "" ||
                      (state.Colleges.hiring_type !== "OFF CAMPUS" &&
                        state.Basics.posted_on_job_lake === true)
                        ? true
                        : false
                    }
                  >
                    Edit
                  </Button>
                </p>
                <BootstrapTooltip
                  //title={} // placement="top-start"
                  className=""
                >
                  <CommonChip
                    label={
                      <span style={{ fontSize: 12 }}>
                        {`(${state.college_id_list?.list?.length}) Colleges Selected`}
                      </span>
                    }
                    style={{ marginRight: "1%", marginBottom: "1%" }}
                  />
                </BootstrapTooltip>
              </Grid>
            </Grid>
          </Box>
        )}
        {/*      {state.Colleges.hiring_type === "ON CAMPUS" ? (
              state.Colleges.college.map((clg) => (
                <>
                  <BootstrapTooltip
                    title={generateCsvDegreeName(clg.preferences)} // placement="top-start"
                    className=""
                  >
                    <CommonChip
                      label={clg.college_name}
                      style={{ marginRight: "1%", marginBottom: "1%" }}
                    />
                  </BootstrapTooltip>
                </>
              ))
            ) : (
              <>
                {Object.keys(qualificationData).map((item) => (
                  <>
                    <BootstrapTooltipNew
                      title={generateCsv(qualificationData[item], "name")}
                      className=""
                    >
                      <CommonChip
                        label={item}
                        style={{ marginRight: "1%", marginBottom: "1%" }}
                      />
                    </BootstrapTooltipNew>
                  </>
                ))}
              </>
            )} */}

        {(state.Colleges.college.length > 0 ||
          state.Preferences.eligibility_criteria.courses.length > 0) && (
          <Box className={classes.selectedPreferences}>
            <Grid container>
              <Grid item xs={3}>
                <p className={classes.formLabel}>
                  Selected {" " + preferencesText}
                  <Button
                    autofocus
                    onClick={() => {
                      state.Colleges.hiring_type == "OFF CAMPUS"
                        ? handleClickOpen()
                        : handleOpenOnCampus();
                    }}
                    disabled={
                      state.Colleges.hiring_type == "" ||
                      (state.Colleges.hiring_type !== "OFF CAMPUS" &&
                        state.Basics.posted_on_job_lake === true)
                        ? true
                        : false
                    }
                  >
                    Edit
                  </Button>
                </p>
              </Grid>
            </Grid>

            {state.Colleges.hiring_type === "ON CAMPUS" ? (
              state.Colleges.college.map((clg) => (
                <>
                  <BootstrapTooltip
                    title={generateCsvDegreeName(clg.preferences)} // placement="top-start"
                    className=""
                  >
                    <CommonChip
                      label={clg.college_name}
                      style={{ marginRight: "1%", marginBottom: "1%" }}
                    />
                  </BootstrapTooltip>
                </>
              ))
            ) : (
              <>
                {Object.keys(qualificationData).map((item) => (
                  <>
                    <BootstrapTooltipNew
                      title={generateCsv(qualificationData[item], "name")}
                      className=""
                    >
                      <CommonChip
                        label={item}
                        style={{ marginRight: "1%", marginBottom: "1%" }}
                      />
                    </BootstrapTooltipNew>
                  </>
                ))}
              </>
            )}
          </Box>
        )}

        <OffCampus
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          state={state}
          setState={setState}
          offCampusLoader={offCampusLoader}
          setOffCampusLoader={setOffCampusLoader}
        />
        <OnCampus
          open={openOnCampus}
          handleClickOpen={handleOpenOnCampus}
          handleClose={handleCloseOnCampus}
          state={state}
          setState={setState}
          setApplyButtonClicked={setApplyButtonClicked}
          applyButtonClicked={applyButtonClicked}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formLabel: {
      fontSize: "13px",
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    RedStar: {
      color: "red",
      fontWeight: "bold",
      marginLeft: "2px",
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "bolder",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    section: {
      marginBottom: "20px",
    },
    subTitle: {
      fontSize: "11px",
      color: "grey",
      width: "70%",
      margin: "6px 0 0 10px",
    },
    preferenceButton: {
      borderRadius: 30,
      "&:hover": {
        border: "2px solid #3282C4",
        background: "white",
        color: "#3282C4",
      },
    },
    InputWidth: {
      width: "70%",
    },
    labelSize: {
      fontSize: "12px",
    },
    selectedPreferences: {
      padding: "20px",
      borderRadius: 30,
      WebkitBackdropFilter: "blur(30px)",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "1px solid rgba(152, 152, 152, 0.27)",
      marginBottom: 30,
    },
    "@media only screen and (max-width: 900px)": {
      InputWidth: {
        width: "100%",
      },
      formLabel: {
        fontSize: "11px",
        fontWeight: "bold",
        paddingTop: "10px",
      },
      labelSize: {
        fontSize: "11px",
      },
    },

    MuiOutlinedInput: {
      root: {
        height: "auto",

        // border: "1px solid #ced4da",
        // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
      },
    },
  })
);
export default HiringProcess;

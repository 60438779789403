const styles = {
  container: {
    display: "flex",
    // border: "2px solid #000",
    flexDirection: "column",
    backgroundColor: "#fff",
    alignItems: "center",
  },
  leftPaper: {
    backgroundColor: "#fff",
    borderRadius: "30px",
    flex: 1,
    //display: "flex",
    display: "grid",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "15px",
    // height: "260px",
    // width: "220px",
    //marginLeft: "40px",
    // border: "2px solid #000",
    background: "#ffffff",
    //paddingTop: "100px",
    width: "10%",
    // paddingTop: "1%",
    position: "fixed",
  },

  leftMenuBox: {
    // width: "170px",
    height: "38px",
    marginTop: "15px",
    marginRight: "10px",
    marginLeft: "10px",
    borderRadius: 30,
    backgroundColor: "#fff",
    color: "#007BFF",
    boxShadow: "none",
    border: "1px solid #007BFF",
  },
  leftMenuBoxSelected: {
    //width: "170px",
    boxShadow: "none",
    height: "38px",
    marginTop: "15px",
    marginRight: "10px",
    marginLeft: "10px",
    borderRadius: "30px",
    color: "#fff",
    backgroundColor: "#007BFF",
    fontWeight: "bold",
  },

  paperStyle: {
    //marginLeft: "280px",
    //backgroundColor: "#fff",
    // width: "73%",
    borderRadius: "30px",
    marginTop: "15px",
    paddingTop: "30px",
    paddingLeft: "20px",
    paddingBottom: "15px",
    marginBottom: "35px",
    //boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
  },
  creditStyle: {
    // marginLeft: "280px",
    backgroundColor: "#fff",
    // width: "73%",
    marginTop: "15px",
    borderRadius: "25px",
    // paddingTop: "30px",
    // paddingLeft: "20px",
    // paddingBottom: "15px",
    marginBottom: "35px",
    //boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
  },
  fieldDiv: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    alignItems: "flex-start",
    // border: "2px solid #000",
  },
  inputBox: {
    marginLeft: "35px",
    width: "220px",
    paddingLeft: "5px",
    borderRadius: "30px",
    height: "48px",
    fontSize: 12,
    fontWeight: "bold",
    // borderRadius: "5px",
  },
  inputBox1: {
    marginLeft: "35px",
    width: "650px",
    height: "35px",
    paddingLeft: "5px",
    borderRadius: "25px",
    fontSize: 12,
  },
  inputBox2: {
    marginLeft: "35px",
    width: "650px",
    height: "150px",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderRadius: "25px",
    fontSize: 12,
  },
  inputBoxAbout: {
    marginLeft: "35px",
    width: "650px",
    height: "70px",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderRadius: "25px",
    fontSize: 12,
  },
  inputBox3: {
    marginLeft: "35px",
    width: "300px",
    height: "35px",
    paddingLeft: "5px",
    borderRadius: "30px",
    fontSize: 12,
  },
  heading: {
    width: "200px",
    // border: "2px solid #000",
    // height: "30px",
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
    fontSize: 13,
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  radioStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: "20px",
  },
  notRequired: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "120px",
  },
  dropDown: {
    marginLeft: "35px",
    width: "180px",
  },
  dropDown1: {
    marginLeft: "35px",
    width: "220px",
    borderRadius: "30px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "30px",
    marginTop: "50px",
    marginBottom: "50px",
  },
  button: {
    backgroundColor: "#007BFF",
    color: "#fff",
    height: "40px",
    boxShadow: "none",
    paddingRight: "20px",
    paddingLeft: "20px",
    borderRadius: "30px",
    display: "flex",
  },
  button2: {
    backgroundColor: "#DF8184",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginLeft: "15px",
    fontWeight: "bold",
  },
  button3: {
    backgroundColor: "#6FCF97",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginLeft: "15px",
    fontWeight: "bold",
  },
  tableContainer: {
    // border: "1px solid #000",
    width: "98%",
    marginLeft: "0px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "50px",
    paddingBottom: "30px",
    borderRadius: 30,
  },
  tab: {
    // border: "1px solid #000",
    width: "100%",
    marginLeft: "0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
  },
  tab1: {
    // border: "1px solid #000",
    width: "100%",
    marginLeft: "0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
  },
  tableHeader: {
    height: "40px",
    width: "48%",
    borderRight: "1px solid #000",
    alignItems: "center",
    paddingLeft: "20px",
    display: "flex",
    backgroundColor: "#E0E0E0",
    fontWeight: "600",
    fontSize: 12,
  },
  tableHeader3: {
    height: "40px",
    width: "48%",
    borderRight: "1px solid #000",
    alignItems: "center",
    paddingLeft: "20px",
    display: "flex",
    backgroundColor: "#F2F2F2",
    fontWeight: "600",
    fontSize: 12,
  },
  tableHeader1: {
    height: "40px",
    width: "48%",
    // border: "1px solid #000",
    alignItems: "center",
    paddingLeft: "50px",
    display: "flex",
    backgroundColor: "#2F80ED",
    fontWeight: "600",
    color: "#fff",
    fontSize: 12,
  },
  tableHeader2: {
    height: "40px",
    width: "48%",
    // border: "1px solid #000",
    alignItems: "center",
    paddingLeft: "50px",
    display: "flex",
    backgroundColor: "#4594FF",
    fontWeight: "600",
    color: "#fff",
    fontSize: 12,
  },
  buttonT1: {
    backgroundColor: "#424242",
    color: "#fff",
    borderRadius: "30px",
    fontSize: 12,
  },
  buttonT2: {
    backgroundColor: "#424242",
    color: "#fff",
    borderRadius: "30px",
    fontSize: 12,
  },
  images: {
    marginLeft: "190px",
    marginTop: "35px",
    marginBottom: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarBox: {
    marginLeft: "240px",
    marginTop: "35px",
    marginBottom: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyLogo: {
    marginLeft: "240px",
    marginTop: "35px",
    marginBottom: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "200px",
    height: "200px",
    borderRadius: "100px",
    backgroundColor: "gray",
    color: "white",
    fontWeight: "bold",
  },
  emptyBanner: {
    marginLeft: "240px",
    marginTop: "35px",
    marginBottom: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "600px",
    height: "200px",
    backgroundColor: "gray",
    color: "white",
    fontWeight: "bold",
  },
  details: {
    fontSize: "18px",
    fontFamily: "Roboto",
  },
};

export default styles;

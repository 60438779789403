import {
  Button,
  Grid, TextField,
  Typography, CircularProgress
} from "@material-ui/core";
import {
  makeStyles
} from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import {BackendBaseApi} from "../../../../../constants/constants";
import { useAlert } from "react-alert";
import { SettingsSystemDaydreamOutlined } from "@material-ui/icons";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      boxShadow: "none",
      marginTop: "-50px",
    },
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    height: "40px",
    boxShadow: "none",
    borderRadius: "30px",
    display: "flex",
    fontWeight: "400",
  },

  floatingLabelFocusStyle: {
    //color: "#7e7e7e",
    //fontSize: "14px",
    color: "#c5c4ba",
    transform: "translate(15px, 14px) scale(1)",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function CompanyInfo({setStep, setEmail, email, checkUser}) {
  
  const [value, setValue] = useState(null);
  const classes = useStyles();
  const [companyName, setCompanyName] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [designation, setDesignation] = useState("");
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const alert = useAlert();
  

  const [cityData, setCityData] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    getCompanyList();
    getCityState();
  }, []);

  const getCompanyList = () => {
    axios.get(`${BackendBaseApi.PRAVESH}api/company_saas/company_list/`).then(res => {
      if(res?.data?.success){
        setCompanyData(res?.data?.data);
      }
    }).catch(err => {
      alert.error("Error in company fetch!");
    })
  }

  const getCityState = () => {
    axios.get(`${BackendBaseApi.PRAVESH}api/location/city_state/`).then(res => {
      if(res?.data?.success){
        setCityData(res?.data?.data);
      }
    }).catch(err => {
      alert.error("Something went wrong. Please try again!!")
    })
  }

  const disableButton = () => {
    if((companyId || (companyName && companySize && companyWebsite && city && state)) && designation){
      return false;
    }  
    return true;
  }

  const startHiring = () => {
    const body = {
      company_id: companyId,
      company_name: companyName,
      company_size: companySize,
      company_website: companyWebsite,
      city: city,
      state: state,
      designation: designation,
      email: email
    }
    setLoading(true);
    axios.post(`${BackendBaseApi.PRAVESH}api/company_saas/choose/company/`, 
    body,
    {withCredentials: true}
    ).then(res => {
      if(res?.data?.success){
        alert.success(res?.data?.data?.message);
        if(res?.data?.data?.code === 2) {
            setEmail(res?.data?.data?.data?.email);
            setStep(6);
        }else{
        checkUser(res?.data?.data?.token, setLoading);
        }
      }else{
        alert.error(res?.data?.error);
        setLoading(false);
      }
    }).catch(err => {
      setLoading(false);
      alert.error("Something went wrong. Please try again!");
    })
  }

  const getSelectedSize = () => {
    let data = {};
    if(companySize){
    data =  sizeData.find(size => size?.value === companySize);
    }
    return data;
  }

  const getCityData = () => {
    let data = {};
    if(city && state){
    data = cityData.find(city1 => city1?.city_id === city && city1?.state_id === state);
    }
    return data;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="h4" style={{ fontWeight: "500" }}>
            Provide your Company Info
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  company_name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setCompanyName(newValue.inputValue);
                setCompanyId(null);
                setCity(null);
                setState(null);
                setCompanySize("");
                setCompanyWebsite("");
                setValue({
                  company_name: newValue.inputValue,
                });
              } else if(newValue){
                setCompanyId(newValue?.id);
                setCity(newValue?.company_city);
                setState(newValue?.company_state);
                setCompanySize(newValue?.size);
                setCompanyWebsite(newValue?.website);
                setCompanyName(newValue?.company_name);
                setValue(newValue);
              } else {
                setCompanyName("");
                setCompanyId(null);
                setCity(null);
                setState(null);
                setCompanySize("");
                setCompanyWebsite("");
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  company_name: `Create "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            renderOption={(option) => (
              <Typography style={{ fontSize: "14px" }}>
                {option.company_name}
              </Typography>
            )}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={companyData}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.company_name) {
                return option.company_name;
              }
            }}
            //freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company Name"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Autocomplete
            id="select-demo"
            disabled={companyId ? true :false}
            renderOption={(option) => (
              <Typography style={{ fontSize: "14px" }}>
                {option.title}
              </Typography>
            )}
            value={getSelectedSize()}
            options={sizeData}
            getOptionLabel={(option) => option.title}
            style={{
              background: "#fff",
              borderRadius: 30,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Company Size"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                required
              />
            )}
            onChange={(e, value) => {
              setCompanySize(value?.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Autocomplete
            id="select-demo"
            disabled={companyId ? true :false}
            renderOption={(option) => (
              <Typography style={{ fontSize: "14px" }}>
                {`${option?.city}, ${option?.state}`}
              </Typography>
            )}
            options={cityData}
            value={getCityData()}
            getOptionLabel={(option) => option?.city && option?.state ? `${option?.city}, ${option?.state}` :""}
            style={{
              background: "#fff",
              borderRadius: 30,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Company Location"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                required
              />
            )}
            onChange={(event, newValue) => {
              setCity(newValue?.city_id);
              setState(newValue?.state_id);
            }}      
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <TextField
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label="Company Website"
            variant="outlined"
            required
            value={companyWebsite}
            disabled={companyId ? true :false}
            onChange={(e) => {setCompanyWebsite(e.target.value)}}
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <TextField
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label="Your Designation"
            variant="outlined"
            required
            value={designation}
            onChange={(e) => {setDesignation(e.target.value)}}
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          {" "}
          <Button color="primary" disabled={disableButton()} className={classes.btn} variant="contained" onClick={() => {
            startHiring();
          }}>
            {loading ? <CircularProgress style={{ color: "#fff" }} size={18} /> : "Start Hiring" }
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const sizeData = [
  { title: "1-10", value: "1-10" },
  { title: "11-50", value: "11-50" },
  { title: "51-100", value: "51-100" },
  { title: "101-1000", value: "101-1000" },
  { title: "1000+", value: "1000+" }
  ]
  
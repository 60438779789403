import {
  Box,
  Button,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../constants/constants";
import Axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import JobTypeList from "../../../../student/components/Main/JobTypeList";
import JobTypeList from "../../../../bundles/student/components/Main/JobTypeList";
import { JobSegmentList } from "../../jobs/PostJob/reducer";
import CommonButton from "../../../common/CommonButton/CommonButton";
import CustomizedCheckboxes from "../../../../components/common/JobPostNew/FormComponents/CustomizedCheckboxes";

const useStyles = makeStyles((theme) =>
  createStyles({
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "20px 10px 0 20px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
      minHeight: "700px",
      maxHeight: "700px",
      padding: "7px",
      position: "fixed",
      overflowY: "scroll",
      width: "17%",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    root1: {
      boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
      border: "solid 0.5px #007bff",
      backgroundColor: "#e8f3ff",
      color: "#007bff",
      fontWeight: "normal",
      marginRight: "8px",
      marginBottom: "8px",
    },
    para: {
      fontSize: 13,
    },
    checked: {},
    listItem: {
      display: "flex",
      flexDirection: "column",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
      fontSize: "18px",
      paddingTop: 17,
    },
    deleteIcon: {
      color: "#007bff",
      "&:hover": {
        color: "#0069d9",
      },
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      padding: "10px 20px 10px 20px",
      fontWeight: "bold",
      // marginLeft: "42%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  })
);

export default function CollegeList(props) {
  const {
    data,
    setPreferencesData,
    setSubmitButtonOpen,
    submitButtonClicked,
    fromModal,
    handleClose,
    setOpenActivateModal,
  } = props;
  const classes = useStyles();
  const alert = useAlert();
  const [segmentTypes, setSegmentTypes] = useState({});
  const [currentSegment, setCurrentSegment] = useState("");
  const [jobSegmentList, setJobSegmentList] = useState(data?.job_segment || []);
  const [jobSegmentEdit, setJobSegmentEdit] = useState(false);
  const [locationEdit, setLocationEdit] = useState(false);
  const [city, setCity] = useState();
  const [cityList, setCityList] = useState({});
  const [selectedCities, setSelectedCities] = useState(data?.location || []);
  const [jobTypes, setJobTypes] = useState({});
  const [selectedJobType, setSelectedJobType] = useState(data?.job_type || []);
  const [jobTypeEdit, setJobTypeEdit] = useState(false);
  const [workExpEdit, setWorkExpEdit] = useState(false);
  const [workExperiences, setWorkExperiences] = useState({});
  const [workExp, setWorkExp] = useState(data?.work_experience || []);

  const work_experiences = [
    {
      name:
        localStorage.getItem("user_type") === "College" ? "Fresher" : "Fresher",
      value: 1,
    },
    {
      name:
        localStorage.getItem("user_type") === "College"
          ? "1 Year Passouts"
          : "1 year",
      value: 2,
    },
    {
      name:
        localStorage.getItem("user_type") === "College"
          ? "2 years Passouts"
          : "2 years",
      value: 3,
    },
    {
      name:
        localStorage.getItem("user_type") === "College"
          ? "More than 3 or more years passouts"
          : "3 and more years",
      value: 4,
    },
  ];

  useEffect(() => {
    getJobSegment();
    getAllLocations();
    getJobTypes();
    let temp = {};

    work_experiences.forEach((j) => {
      temp[j.name] = { ...j, isChecked: checkIsTrueWE(j) };
    });
    setWorkExperiences({ ...temp });
  }, []);

  const checkIsTrueWE = (obj) => {
    var boole = false;
    workExp.map((u, iin) => {
      if (u.id === obj.value) {
        boole = true;
      }
    });
    return boole;
  };
  const getJobSegment = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/segment/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((job_type) => {
            temp[job_type.job_segment] = {
              ...job_type,
              isChecked: checkIsTrueJS(job_type),
            };
          });
          setSegmentTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Error Occured 3");
      });
  };
  const getAllLocations = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/location/city/")
      .then((res) => {
        let temp = {};

        for (let i = 0; i < res.data.length; i++) {
          temp[res.data[i].city] = res.data[i].city_id;
        }
        setCityList({ ...temp });
      })
      .catch((err) => {
        alert.error("Some Error Occured 2");
      });
  };

  const getJobTypes = () => {
    Axios.get(BackendBaseApi.NIYUKTI + "job/type/")
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((job_type) => {
            temp[job_type.job_type] = {
              ...job_type,
              isChecked: checkIsTrue(job_type),
            };
          });

          setJobTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        alert.error("Some Error Occured 1");
      });
  };

  const checkIsTrue = (obj) => {
    var boole = false;
    selectedJobType.map((u, iin) => {
      if (u.id === obj.id) {
        boole = true;
      }
    });
    return boole;
  };

  const checkIsTrueJS = (obj) => {
    var boole = false;
    jobSegmentList.map((u, iin) => {
      if (u.id === obj.id) {
        boole = true;
      }
    });
    return boole;
  };

  const segments = Object.keys(segmentTypes).map((d) => {
    return { name: d, value: d };
  });
  const cities = Object.keys(cityList).map((City) => {
    return { name: City, value: City };
  });

  const JobSegment = () => (
    <Autocomplete
      value={currentSegment}
      onChange={(event, newValue) => {
        setCurrentSegment(newValue);
      }}
      id="controllable-states-demo"
      options={segments.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Job Segments" variant="outlined" />
      )}
    />
  );
  const CityList = () => (
    <Autocomplete
      value={city}
      onChange={(event, newValue) => {
        setCity(newValue);
      }}
      id="controllable-states-demo"
      options={cities.map((option) => option.name)}
      renderOption={(option) => {
        return <span style={{ fontSize: 13 }}>{`${option}`}</span>;
      }}
      style={{ width: "90%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<span style={{ fontSize: 13 }}>Choose Cities</span>}
          variant="outlined"
        />
      )}
    />
  );

  useEffect(() => {
    if (currentSegment) {
      let list = [...jobSegmentList];
      const listName = list.map((d) => d.name);
      if (listName.includes(currentSegment)) return;
      list.push({ id: segmentTypes[currentSegment], name: currentSegment });
      setJobSegmentList(list);
    }
  }, [currentSegment]);

  useEffect(() => {
    if (city) {
      let list = [...selectedCities];
      const listName = list.map((d) => d.name);
      if (listName.includes(city)) return;
      list.push({ city_id: cityList[city], city: city });
      setSelectedCities(list);
    }
  }, [city]);

  const handleDelete = (key, arr, setArr) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);
    }
  };
  const handleDeleteJobType = (key, arr, setArr, name) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);

      jobTypes[name].isChecked = false;
    }
  };

  const handleDeleteJS = (key, arr, setArr, name) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);

      segmentTypes[name].isChecked = false;
    }
  };
  const handleDeleteWE = (key, arr, setArr) => {
    var name;
    if (key.id === 1) name = "Fresher";
    if (key.id === 2) name = "1 year";
    if (key.id === 3) name = "2 years";
    if (key.id === 4) name = "3 and more years";

    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);
      workExperiences[name].isChecked = false;
    }
  };
  let token = localStorage.getItem("gw_token");

  useEffect(() => {
    if (jobSegmentList.length > 0) {
      setSubmitButtonOpen(true);
      if (submitButtonClicked) {
        handlePreferences();
      }
    }
  }, [
    selectedCities.length,
    jobSegmentList.length,
    selectedJobType.length,
    workExp.length,
    submitButtonClicked,
  ]);

  const handlePreferences = () => {
    const preferencesObject = {
      location: selectedCities,
      job_segment: jobSegmentList,
      job_type: selectedJobType,
      work_experience: workExp,
    };
    const body = {
      preferences: preferencesObject,
    };
    Axios.patch(BackendBaseApi.PRAVESH + "api/company/settings/", body, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setPreferencesData(preferencesObject);
          alert.success("Preferences Updated Successfully");
          if (fromModal) {
            localStorage.setItem("comp_pref_filled", true);
            handleClose();
            setOpenActivateModal(true);
          }
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };

  const handlePreferencesCollege = () => {
    const preferencesObject = {
      location: selectedCities,
      job_type: selectedJobType,
      work_experience: workExp,
    };
    const body = {
      preferences: preferencesObject,
    };
    Axios.patch(BackendBaseApi.PRAVESH + "api/college/settings/", body, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setPreferencesData(preferencesObject);
          alert.success("Updated Successfully");
        } else {
          alert.error(res.data.error);
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
      });
  };

  return (
    <>
      {(localStorage.getItem("user_type") === "Company" ||
        !localStorage.getItem("user_type")) && (
        <>
          <div>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h4" className={classes.heading}>
                  Job Segment{" "}
                  <span
                    style={{
                      fontSize: 15,
                      fontWeight: "normal",
                      color: "grey",
                    }}
                  >
                    {fromModal && " (Mandatory Field)"}
                  </span>
                </Typography>
              </Grid>
              {jobSegmentList.length > 0 && (
                <Grid
                  item
                  xs={2}
                  // style={{ display: "flex", justifyContent: "center" }}
                >
                  <CommonButton
                    onClick={() => setJobSegmentEdit(true)}
                    variant="outlined"
                    startIcon={<CreateIcon />}
                    //style={{ marginLeft: 50 }}
                  >
                    Edit
                  </CommonButton>
                </Grid>
              )}
            </Grid>
            <br />
            <Divider />
            <br />

            {jobSegmentList.length > 0
              ? jobSegmentList.map((item) =>
                  jobSegmentEdit ? (
                    <Chip
                      label={<span style={{ fontSize: 12 }}>{item.name}</span>}
                      onDelete={() => {
                        handleDeleteJS(
                          item,
                          jobSegmentList,
                          setJobSegmentList,
                          item.name
                        );
                      }}
                      size="small"
                      classes={{ deleteIcon: classes.deleteIcon }}
                      className={classes.root1}
                    />
                  ) : (
                    <Chip
                      label={<span style={{ fontSize: 12 }}>{item.name}</span>}
                      className={classes.root1}
                      size="small"
                    />
                  )
                )
              : !jobSegmentEdit && (
                  <>
                    <p
                      align="center"
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      No Job Segment selected yet. Please click on the below
                      button to add new job segments.
                    </p>

                    <Box
                      style={{
                        display: "flex",
                        float: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <Button
                       
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          borderRadius: 30,
                        }}
                      > */}
                      <CommonButton
                        type="submit"
                        onClick={() => {
                          setJobSegmentEdit(true);
                        }}
                      >
                        Add Job Segments{" "}
                      </CommonButton>
                    </Box>
                  </>
                )}
            {/* <br /> */}
            {/* <br /> */}
            {jobSegmentEdit && (
              <>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography className={classes.para}>
                      Select Job Segment
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {/* <JobSegment /> */}
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      {Object.keys(segmentTypes).map((item) => (
                        <FormControlLabel
                          control={
                            <CustomizedCheckboxes
                              checked={segmentTypes[item].isChecked}
                              onChange={(e) => {
                                if (segmentTypes[item].isChecked === false) {
                                  var arr = [...jobSegmentList];

                                  arr.push({
                                    id: segmentTypes[item].id,
                                    name: segmentTypes[item].job_segment,
                                  });
                                  setJobSegmentList(arr);
                                } else {
                                  var arr = [...jobSegmentList];
                                  var indexx;
                                  arr.map((u, iin) => {
                                    if (u.id === segmentTypes[item].id) {
                                      indexx = iin;
                                    }
                                  });
                                  arr.splice(indexx, 1);
                                  setJobSegmentList(arr);
                                }

                                segmentTypes[item].isChecked = e.target.checked;
                              }}
                              name="gilad"
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label={
                            <span style={{ fontSize: 12 }}>
                              {segmentTypes[item].job_segment}
                            </span>
                          }
                        />
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    {!fromModal && (
                      <CommonButton
                        type="submit"
                        onClick={() => {
                          setJobSegmentEdit(false);
                          handlePreferences();
                        }}
                        style={{
                          marginLeft: "40px",
                        }}
                      >
                        Update Job Segment
                      </CommonButton>
                    )}
                  </Grid>
                </Grid>

                {/* <br /> */}
                {/* <br /> */}
              </>
            )}
          </div>
          {/* <br /> */}
          {/* <br /> */}
        </>
      )}
      <>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.heading}>
              Location
            </Typography>
          </Grid>
          {selectedCities.length > 0 && (
            <Grid item xs={2}>
              <CommonButton
                onClick={() => setLocationEdit(true)}
                variant="outlined"
                startIcon={<CreateIcon />}
              >
                Edit
              </CommonButton>
            </Grid>
          )}
        </Grid>
        <br />
        <Divider />
        <br />

        {selectedCities.length > 0
          ? selectedCities.map((item) =>
              locationEdit ? (
                <>
                  <Chip
                    size="small"
                    label={<span style={{ fontSize: 12 }}>{item.city}</span>}
                    onDelete={() => {
                      handleDelete(item, selectedCities, setSelectedCities);
                    }}
                    classes={{
                      deleteIcon: classes.deleteIcon,
                      chip: classes.chip,
                    }}
                    className={classes.root1}
                  />
                </>
              ) : (
                <>
                  <Chip
                    size="small"
                    label={<span style={{ fontSize: 12 }}>{item.city}</span>}
                    className={classes.root1}
                  />
                </>
              )
            )
          : !locationEdit && (
              <>
                <p
                  align="center"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  No Locations selected yet. Please click on the below button to
                  add new locations
                </p>

                <Box
                  style={{
                    display: "flex",
                    float: "center",
                    justifyContent: "center",
                  }}
                >
                  <CommonButton
                    type="submit"
                    onClick={() => {
                      setLocationEdit(true);
                    }}
                  >
                    Add Locations{" "}
                  </CommonButton>
                </Box>
              </>
            )}
        {/* <br /> */}
        {/* <br /> */}
        {locationEdit && (
          <>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="body1" style={{ paddingTop: 11 }}>
                  Select Location
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <CityList />
              </Grid>
              <Grid item xs={4}>
                {!fromModal && (
                  <CommonButton
                    type="submit"
                    onClick={() => {
                      setLocationEdit(false);
                      if (localStorage.getItem("user_type") === "Company")
                        handlePreferences();
                      else handlePreferencesCollege();
                    }}
                    style={{
                      marginLeft: "40px",
                    }}
                  >
                    Update Location
                  </CommonButton>
                )}
              </Grid>
            </Grid>

            {/* <br /> */}
            {/* <br /> */}
          </>
        )}
      </>

      {/* <br /> */}
      {/* <br /> */}
      <>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.heading}>
              Job Type
            </Typography>
          </Grid>
          {selectedJobType.length > 0 && (
            <Grid item xs={2}>
              <CommonButton
                onClick={() => setJobTypeEdit(true)}
                variant="outlined"
                startIcon={<CreateIcon />}
              >
                Edit
              </CommonButton>
            </Grid>
          )}
        </Grid>
        <br />
        <Divider />
        <br />

        {selectedJobType.length > 0
          ? selectedJobType.map((item) =>
              jobTypeEdit ? (
                <>
                  <Chip
                    size="small"
                    label={<span style={{ fontSize: 12 }}>{item.name}</span>}
                    onDelete={() => {
                      handleDeleteJobType(
                        item,
                        selectedJobType,
                        setSelectedJobType,
                        item.name
                      );
                    }}
                    classes={{
                      deleteIcon: classes.deleteIcon,
                      chip: classes.chip,
                    }}
                    className={classes.root1}
                  />
                </>
              ) : (
                <>
                  <Chip
                    size="small"
                    label={<span style={{ fontSize: 12 }}>{item.name}</span>}
                    className={classes.root1}
                  />
                </>
              )
            )
          : !jobTypeEdit && (
              <>
                <p
                  align="center"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  No Job Types selected yet. Please click on the below button to
                  add new Job Types.
                </p>

                <Box
                  style={{
                    display: "flex",
                    float: "center",
                    justifyContent: "center",
                  }}
                >
                  <CommonButton
                    type="submit"
                    onClick={() => {
                      setJobTypeEdit(true);
                    }}
                  >
                    Add Job Type{" "}
                  </CommonButton>
                </Box>
              </>
            )}
        {/* <br /> */}
        {/* <br /> */}
        {jobTypeEdit && (
          <>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="body1" className={classes.para}>
                  Select Job Types
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {Object.keys(jobTypes).map((item) => (
                    <FormControlLabel
                      control={
                        <CustomizedCheckboxes
                          checked={jobTypes[item].isChecked}
                          onChange={(e) => {
                            if (jobTypes[item].isChecked === false) {
                              var arr = [...selectedJobType];

                              arr.push({
                                id: jobTypes[item].id,
                                name: jobTypes[item].job_type,
                              });
                              setSelectedJobType(arr);
                            } else {
                              var arr = [...selectedJobType];
                              var indexx;
                              arr.map((u, iin) => {
                                if (u.id === jobTypes[item].id) {
                                  indexx = iin;
                                }
                              });
                              arr.splice(indexx, 1);
                              setSelectedJobType(arr);
                            }

                            jobTypes[item].isChecked = e.target.checked;
                          }}
                          name="gilad"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: 12 }}>
                          {jobTypes[item].job_type}
                        </span>
                      }
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Box
              style={{
                display: "flex",
                //   float: "right",
                justifyContent: "right",
              }}
            >
              {!fromModal && (
                <CommonButton
                  type="submit"
                  onClick={() => {
                    setJobTypeEdit(false);
                    if (localStorage.getItem("user_type") === "Company")
                      handlePreferences();
                    else handlePreferencesCollege();
                  }}
                  style={{
                    marginRight: "40px",
                  }}
                >
                  Update Job Type
                </CommonButton>
              )}
            </Box>
            {/* <br /> */}
            {/* <br /> */}
          </>
        )}
      </>

      <br />
      {/* <br /> */}
      <>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.heading}>
              {localStorage.getItem("user_type") === "College"
                ? "Placement Batch"
                : "Work Experience"}
            </Typography>
          </Grid>
          {workExp.length > 0 && (
            <Grid item xs={2}>
              <CommonButton
                onClick={() => setWorkExpEdit(true)}
                variant="outlined"
                startIcon={<CreateIcon />}
                //  style={{ marginLeft: 50 }}
              >
                {localStorage.getItem("user_type") === "College"
                  ? "Edit"
                  : "Edit"}
              </CommonButton>
            </Grid>
          )}
        </Grid>
        <br />
        <Divider />
        <br />

        {workExp.length > 0
          ? workExp.map((item) =>
              workExpEdit ? (
                <>
                  <Chip
                    size="small"
                    label={
                      <span style={{ fontSize: 12 }}>
                        {item.id === 1
                          ? "Fresher"
                          : localStorage.getItem("user_type") === "College"
                          ? item.id - 1 + " year passout"
                          : item.id - 1 + " year"}
                      </span>
                    }
                    onDelete={() => {
                      handleDeleteWE(item, workExp, setWorkExp);
                    }}
                    classes={{
                      deleteIcon: classes.deleteIcon,
                      chip: classes.chip,
                    }}
                    className={classes.root1}
                  />
                </>
              ) : (
                <>
                  <Chip
                    size="small"
                    label={
                      <span style={{ fontSize: 12 }}>
                        {item.id === 1
                          ? "Fresher"
                          : localStorage.getItem("user_type") === "College"
                          ? item.id - 1 + " year passout"
                          : item.id - 1 + " year"}
                      </span>
                    }
                    className={classes.root1}
                  />
                </>
              )
            )
          : !workExpEdit && (
              <>
                <p
                  align="center"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  No{" "}
                  {localStorage.getItem("user_type") === "College"
                    ? "Placement Batch"
                    : "Work Experience"}{" "}
                  selected yet. Please click on the below button to add new Work
                  Experiences.
                </p>

                <Box
                  style={{
                    display: "flex",
                    float: "center",
                    justifyContent: "center",
                  }}
                >
                  <CommonButton
                    type="submit"
                    onClick={() => {
                      setWorkExpEdit(true);
                    }}
                  >
                    {localStorage.getItem("user_type") === "College"
                      ? "Add Placement Batches"
                      : "Add Work Experience"}
                  </CommonButton>
                </Box>
              </>
            )}
        {/* <br /> */}
        {/* <br /> */}
        {workExpEdit && (
          <>
            <Grid container>
              <Grid item xs={4}>
                <Typography className={classes.para}>
                  {localStorage.getItem("user_type") === "College"
                    ? "Select Placement Batches"
                    : "Select Work Experience"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {Object.keys(workExperiences).map((item) => (
                    <FormControlLabel
                      control={
                        <CustomizedCheckboxes
                          checked={workExperiences[item].isChecked}
                          onChange={(e) => {
                            if (workExperiences[item].isChecked === false) {
                              var arr = [...workExp];
                              arr.push({
                                id: workExperiences[item].value,
                                name: workExperiences[item].name,
                              });
                              setWorkExp(arr);
                            } else {
                              var arr = [...workExp];
                              var indexx;
                              arr.map((u, iin) => {
                                if (u.id === workExperiences[item].value) {
                                  indexx = iin;
                                }
                              });
                              arr.splice(indexx, 1);
                              setWorkExp(arr);
                            }

                            workExperiences[item].isChecked = e.target.checked;
                          }}
                          name="gilad"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: 12 }}>
                          {workExperiences[item].name}
                        </span>
                      }
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
            {!fromModal && (
              <Box
                style={{
                  display: "flex",
                  //  float: "right",
                  justifyContent: "right",
                }}
              >
                <CommonButton
                  type="submit"
                  onClick={() => {
                    setWorkExpEdit(false);
                    if (localStorage.getItem("user_type") === "College")
                      handlePreferencesCollege();
                    else handlePreferences();
                  }}
                  style={{
                    marginRight: "40px",
                  }}
                >
                  {localStorage.getItem("user_type") === "College"
                    ? "Update Placement Batches"
                    : "Update Work Experience"}
                </CommonButton>
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
}

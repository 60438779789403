import {
  Box,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";
import eg from "../../assets/images/location1.jpg";
import map from "../../assets/images/red_map.png";
import BlogCardSkeleton from "../../assets/skeletons/BlogCardSkeleton.js";
import Loader from "../../bundles/common/components/UI/Loader.js";
import Layout from "../../components/college/Layout/Layout_c";
import { BackendBaseApi } from "../../constants/constants.js";
import Filter from "./Filter";
import {
  FiCard,
  FiCardActionArea,
  FiCardActions,
  FiCardContent,
  FiCardMedia
} from "./FullImageCard";
import MobileFilter from "./MobileFilter";

const useStyles = makeStyles({
  CollegeBox: {
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    display: "block",
    borderRadius: "6px 6px 0 0",
    position: "relative",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
      cursor: "pointer",
    },
  },
  BlackTranslucentBox: {
    position: "absolute",
    top: "65%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left",
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0.65)",
    width: "100%",
    padding: "10px 15px 10px 15px",
    height: "100px",
    borderRadius: "6px 6px 0 0",
  },
  imageStyling: {
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px 6px 0 0",
    height: 240,
    width: "100%",
  },
  WhiteBottomBox: {
    position: "absolute",
    display: "flex",
    width: "100%",
    justifyContent: "left",
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "0px 3px 6px #272d3b33",
    borderRadius: "0 0 6px 6px",
    // padding: "20px 5px 0 5px",
  },
  gridTop1: {
    marginTop: "6%",
  },
  gridTop2: {
    marginTop: "2%",
  },
  logo: {
    height: "30px",
    width: "30px",
  },
  card: {
    width: 345,
  },

  /**
   * Applied to Orginal Card demo
   * Same vale used in Material-ui Card Demos
   */
  media: {
    height: 140,
  },

  /**
   * Demo stlying to inclrease text visibility
   * May verry on implementation
   */
  fiCardContent: {
    color: "#ffffff",
    backgroundColor: "rgba(0,0,0,0.65)",
    top: "18%",
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)",
  },
  newBox: {
    width: "95%",
    display: "flex",
    justifyContent: "center",
    marginLeft: "60px",

    height: "900px",
    overflowX: "none",
  },
  "@media only screen and (max-width: 768px)": {
    gridTop1: {
      marginTop: "10%",
    },
    gridTop2: {
      marginTop: "10%",
    },
    logo: {
      height: "30px",
      width: "30px",
    },
    newpara: {
      fontSize: "14px",
    },
    BlackTranslucentBox: {
      top: "60%",
    },
    newBox: {
      margin: "auto",
    },
  },
});

export default function CollegeList() {
  const classes = useStyles();

  const alert = useAlert();
  const [collegeData, setCollegeData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [isApiCall, setIsApiCall] = useState(false);
  const [end, setEnd] = useState(false);
  const [nextUrl, setNextUrl] = useState();
  const [collegeCount, setCollegeCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [firstApiCall, setFirstApiCall] = useState(false);

  useEffect(() => {
    getData();
  }, [searchString]);

  const checkString = () => {
    if (searchString) return `?${searchString}`;
    else return "";
  };
  const getData = () => {
    if (!nextUrl || end) if (!searchString && !firstApiCall) setLoading(true);
    const urlCollegeRank = nextUrl
      ? nextUrl
      : `${
          BackendBaseApi.PRAVESH
        }api/colleges/all_college_data/${checkString()}`;
    Axios.get(urlCollegeRank)
      .then((res) => {
        setIsApiCall(true);
        setFirstApiCall(true);

        if (res.data.success) {
          setCollegeCount(res.data.data.count);
          let data = [...collegeData, ...res.data.data.results];
          setCollegeData(data); //   setCollegeList({ ...collegeList, ...temp });
          setLoading(false);
        } else {
          alert.error(res.data.error);
          setLoading(false);
        }

        if (res.data.data.next) {
          setNextUrl(res.data.data.next);
        } else {
          setEnd(true);
        }
      })
      .catch((err) => {
        alert.error("Error Occured");
        setLoading(false);
        setFirstApiCall(true);
      });
    setIsApiCall(false);
  };
  // useEffect(() => {
  //   getData();
  // }, []);

  const handleScroll = (event) => {
    let e = event.nativeEvent;

    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && isApiCall) {
        getData();
      }
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Layout>
      {loading === true ? (
        <Loader />
      ) : (
        <div style={{ height: "900px" }}>
          <br />
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <h2>Find the Right College </h2>
            <BreakpointProvider>
              <Breakpoint medium up>
                <h4 style={{ color: "grey" }}>
                  Choose from {collegeCount} colleges across India with
                  GetWork......
                </h4>
              </Breakpoint>
              <Breakpoint medium down>
                <p style={{ color: "grey" }}>
                  Choose from {collegeCount} colleges across India with
                  GetWork......
                </p>
              </Breakpoint>
            </BreakpointProvider>
          </div>
          <br />
          <Grid container>
            <Grid item md={2}>
              <BreakpointProvider>
                <Breakpoint medium up>
                  <Filter
                    searchString={searchString}
                    setSearchString={setSearchString}
                    setCollegeData={setCollegeData}
                    setNextUrl={setNextUrl}
                    setEnd={setEnd}
                    end={end}
                    nextUrl={nextUrl}
                  />
                </Breakpoint>
                <Breakpoint medium down>
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    startIcon={<FilterListIcon />}
                    style={{
                      marginLeft: "20px",
                      marginBottom: "20px",
                      marginTop: "-30px",
                    }}
                  >
                    Filters
                  </Button> */}
                  <MobileFilter
                    open={open}
                    setOpen={setOpen}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    setCollegeData={setCollegeData}
                    setNextUrl={setNextUrl}
                    setEnd={setEnd}
                    end={end}
                    nextUrl={nextUrl}
                  />
                </Breakpoint>
              </BreakpointProvider>
            </Grid>

            <Grid item md={10}>
              <div className={classes.newBox}>
                <div style={{}} className="scrollY1" onScroll={handleScroll}>
                  <Grid container>
                    {collegeData.map((item, index) => (
                      // <Grid
                      //   item
                      //   md={3}
                      //   className={
                      //     index > 3 ? classes.gridTop1 : classes.gridTop2
                      //   }
                      // >
                      // <Link
                      //   to={
                      //     "/public/college/new-profile/" +
                      //     item.college_name.replace(/ /g, "-") +
                      //     "$" +
                      //     item.college_id
                      //   }
                      //   target="_blank"
                      // >
                      //     <Box className={classes.CollegeBox}>
                      //       <img
                      //         src={item.banner}
                      //         alt=""
                      //         className={classes.imageStyling}
                      //       />

                      //       <Box className={classes.BlackTranslucentBox}>
                      //         <Grid container spacing={3}>
                      //           <Grid item md={3}>
                      //             <img
                      //               src={item.logo}
                      //               alt=""
                      //               className={classes.logo}
                      //             />
                      //           </Grid>
                      //           <Grid item md={9}>
                      //             <p
                      //               className={classes.newpara}
                      //               style={{
                      //                 color: "#fff",
                      //                 fontWeight: "bolder",
                      //               }}
                      //             >
                      //               {item.college_name}
                      //               <br />
                      // <img
                      //   src={map}
                      //   alt=""
                      //   height="18px"
                      // /> &nbsp;{" "}
                      // <span
                      //   style={{
                      //     color: "#fff",
                      //     fontWeight: "normal",
                      //   }}
                      // >
                      //   {item.city}, {item.state}
                      // </span>
                      //             </p>
                      //           </Grid>
                      //         </Grid>
                      //       </Box>

                      //       <Box className={classes.WhiteBottomBox}>
                      // <Box
                      //   component="fieldset"
                      //   mb={3}
                      //   borderColor="transparent"
                      //   style={{
                      //     display: "flex",
                      //     flexDirection: "row",
                      //     justifyContent: "left",
                      //     marginTop: "5px",
                      //   }}
                      // >
                      //   <Rating
                      //     name="read-only"
                      //     // value={parseFloat(item.avg_rating) / 2}
                      //     defaultValue={parseFloat(item.avg_rating) / 2}
                      //     precision={0.25}
                      //     readOnly
                      //   />
                      //   &nbsp;{" "}
                      //   <span style={{ color: "grey" }}>
                      //     {parseFloat(item.avg_rating) / 2}
                      //   </span>
                      // </Box>
                      //       </Box>
                      //     </Box>
                      //   </Link>
                      // </Grid>
                      <Grid item md={3}>
                        <Link
                          to={
                            "/public/college/new-profile/" +
                            item.college_name.replace(/ /g, "-") +
                            "$" +
                            item.college_id
                          }
                          target="_blank"
                        >
                          <Box my={4}>
                            <FiCard className={classes.card}>
                              <FiCardActionArea style={{ height: "200px" }}>
                                <FiCardMedia
                                  media="picture"
                                  alt={item.college_name}
                                  image={
                                    item.banner === null ? eg : item.banner
                                  }
                                  title={item.college_name}
                                />
                                <FiCardContent
                                  className={classes.fiCardContent}
                                >
                                  <Grid container spacing={4}>
                                    <Grid item xs={2}>
                                      <img
                                        src={item.logo}
                                        alt=""
                                        className={classes.logo}
                                      />
                                    </Grid>
                                    <Grid item xs={10}>
                                      <Typography
                                        gutterBottom
                                        // variant="h5"
                                        // component="h5"
                                        style={{
                                          color: "white",
                                          fontSize: "15px",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        {item.college_name}
                                      </Typography>
                                      <img src={map} alt="" height="18px" />{" "}
                                      &nbsp;{" "}
                                      <span
                                        style={{
                                          color: "#fff",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {item.city}, {item.state}
                                      </span>
                                    </Grid>
                                  </Grid>

                                  {/* <Typography
                                variant="body2"
                                className={classes.fiCardContentTextSecondary}
                                component="p"
                              >
                                Lizards are a widespread group of squamate
                                reptiles, with over 6,000 species, ranging
                                across all continents except Antarctica
                              </Typography> */}
                                </FiCardContent>
                              </FiCardActionArea>
                              <FiCardActions>
                                <Box
                                  component="fieldset"
                                  // mb={3}
                                  borderColor="transparent"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "left",
                                    // marginTop: "5px",
                                  }}
                                >
                                  <Rating
                                    name="read-only"
                                    // value={parseFloat(item.avg_rating) / 2}
                                    defaultValue={
                                      parseFloat(item.avg_rating) / 2
                                    }
                                    precision={0.25}
                                    readOnly
                                  />
                                  &nbsp;{" "}
                                  <span style={{ color: "grey" }}>
                                    {parseFloat(item.avg_rating) / 2}
                                  </span>
                                </Box>
                              </FiCardActions>
                            </FiCard>
                          </Box>
                        </Link>
                      </Grid>
                    ))}
                    {!isApiCall && (
                      <>
                        <Grid item md={3} style={{ marginTop: "4%" }}>
                          <BlogCardSkeleton />
                        </Grid>
                        <Grid item md={3} style={{ marginTop: "4%" }}>
                          <BlogCardSkeleton />
                        </Grid>
                        <Grid item md={3} style={{ marginTop: "4%" }}>
                          <BlogCardSkeleton />
                        </Grid>
                        <Grid item md={3} style={{ marginTop: "4%" }}>
                          <BlogCardSkeleton />
                        </Grid>
                        <Grid item md={3} style={{ marginTop: "4%" }}>
                          <BlogCardSkeleton />
                        </Grid>
                        <Grid item md={3} style={{ marginTop: "4%" }}>
                          <BlogCardSkeleton />
                        </Grid>
                        <Grid item md={3} style={{ marginTop: "4%" }}>
                          <BlogCardSkeleton />
                        </Grid>
                        <Grid item md={3} style={{ marginTop: "4%" }}>
                          <BlogCardSkeleton />
                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                      </>
                    )}
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Layout>
  );
}

import React from "react";
import { Paper, makeStyles, Typography, Button, Divider } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
  },
  job: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "3px",
  },
}));

function Education({ data }) {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">Education</Typography>
        <br />
        {data?.student_user_details?.education?.map((item) => (
          <>
            {item.education > 2 ? (
              <>
                <div className={classes.job}>
                  <Typography variant="body1">{item?.college_name}</Typography>
                </div>
                <Typography variant="subtitle2">
                  {item?.start_date && item?.start_date !== null && (
                    <>
                      {moment(item?.start_date).format("MMM, YYYY")}{" "}
                      {item.end_date && item.end_date !== null && (
                        <>
                          {" - "}
                          {moment(item?.end_date).format("MMM, YYYY")}{" "}
                        </>
                      )}
                    </>
                  )}
                </Typography>
                <Typography variant="subtitle2" style={{ color: "#6c757d" }}>
                  {item.degree && (
                    <>
                      {item?.degree} {" in " + item?.specialization}
                    </>
                  )}
                </Typography>
              </>
            ) : (
              <>
                <div className={classes.job}>
                  <Typography variant="body1">{item?.highest_qualification ? item?.highest_qualification + " Education" : ""}</Typography>
                </div>
                <Typography variant="subtitle2">
                  {item?.start_date && item?.start_date !== null && (
                    <>
                      {moment(item?.start_date).format("MMM, YYYY")}{" "}
                      {item.end_date && item.end_date !== null && (
                        <>
                          {" - "}
                          {moment(item?.end_date).format("MMM, YYYY")}{" "}
                        </>
                      )}
                    </>
                  )}
                </Typography>
                <Typography variant="subtitle2" style={{ color: "#6c757d" }}>
                  {item.board && <>{item?.board_name}</>}
                </Typography>
                <Typography variant="subtitle2" style={{ color: "#6c757d" }}>
                  {item?.college_name && <>{item.college_name}</>}
                </Typography>
              </>
            )}
            {item.percentage ? (
              <Typography style={{ color: "#6c757d" }} variant="subtitle2">
                {"Percentage: " + item.percentage}
              </Typography>
            ) : (
              <Typography style={{ color: "#6c757d" }} variant="subtitle2">
                CGPA: {item.cgpa ? item.cgpa : "NA"}
              </Typography>
            )}

            {/* <Button style={{ color: "#007bff" }} startIcon={<GetAppIcon />}>
          Marksheet.pdf
        </Button> */}
            {/* <Divider style={{ marginBottom: 10, marginTop: 10 }} /> */}
            <br />
          </>
        ))}
      </Paper>
    </>
  );
}

export default Education;

import { AppBar, Button, CircularProgress, Grid, Hidden, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useHistory, useLocation } from "react-router-dom";
import check from "../../../../assets/checked-new.png";
import empty from "../../../../assets/empty.png";
import { BackendBaseApi } from "../../../../constants/constants";
import CommonButton from "../../../common/CommonButton/CommonButton";
import FillResume from "../FillResume";
import CircularProgressWithLabel from "./circularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "30px",
  },
  grid: {
    textAlign: "center",
  },
  heading: {
    fontSize: "40px",
  },
  btn: {
    borderRadius: "30px",
    backgroundColor: theme.palette.domain.main,
    color: "#ebf4f8",
    boxShadow: "none",
    width: "350px",
    marginBottom: "30px",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
    "&:disabled": {
      backgroundColor: theme.palette.domain.light,
    },
  },
  form: {
    display: "flex",
    alignItems: "center",
    marginBottom: 110,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    height: "100px",
    backgroundColor: "#fff",
  },
  apply: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function ProfileGrid(props) {
  const {
    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    currentCity,
    setCurrentCity,
    currentHomeCity,
    setCurrentHomeCity,
    currentGender,
    setCurrentGender,
    allCities,
    buttonClicked,
    setButtonClicked,
    openAddEducation,
    setOpenAddEducation,
    allColleges,
    allBoards,
    educationDetails,
    setEducationDetails,
    educationDetailsErrors,
    setEducationDetailsErrors,
    educationDetailsList,
    setEducationDetailsList,
    activeStep,
    setActiveStep,
    workExpDetails,
    setWorkExpDetails,
    workExpDetailsErrors,
    setWorkExpDetailsErrors,
    openAddWorkExp,
    setOpenAddWorkExp,
    workExpDetailsList,
    setWorkExpDetailsList,
    allCompanies,
    setAllCompanies,
    allJobType,
    allSkills,
    skillDetails,
    setSkillDetails,
    skillDetailsErrors,
    setSkillDetailsErrors,
    skillDetailsList,
    setSkillDetailsList,
    linkList,
    setLinkList,
    allSocialLinks,
    projectDetails,
    setProjectDetails,
    projectDetailsErrors,
    setProjectDetailsErrors,
    projectList,
    setProjectList,
    educationLoader,
    expLoader,
    skillsLoader,
    projectLoader,
    linksLoader,
    previewImage,
    setPreviewImage,
    setProfilePicChanged,
    profilePicChanged,
    openAddAchievements,
    setOpenAddAchievements,
    openAddHobbies,
    setOpenAddHobbies,
    openAddLanguages,
    setOpenAddLanguages,
    openAddProjects,
    setOpenAddProjects,
    extraData,
    setExtraData,
    getUserDetails,
    fetchPrecentage,
    varyPercentage,
    prefFilled,
    educationDetailsFilled,
    skillsFilled,
    basicDetailsFilled,
    setBasicDetailsFilled,
    mainRef,
    executeScroll,
    instituteList,
    openAddCertificate,
    setOpenAddCertificate,
  } = props;
  const classes = useStyles();
  const alert = useAlert();
  const fileInputRef = useRef();
  const [saveLoaderResume, setSaveLoaderResume] = useState(false);
  const ResumeParsing = (resume) => {
    const fd = new FormData();

    fd.append("resume", resume);
    setSaveLoaderResume(true);
    Axios.post(BackendBaseApi.PRAVESH + "api/education/v2/resume_upload/", fd, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res.data.success) {
          setSaveLoaderResume(false);
          alert.success("Resume Parsed Successfully!!!");
          localStorage.setItem("is_student_resume_uploaded", true);

          setActiveStep(0);
          getUserDetails();
        } else {
          alert.error("Unable to parse resume!");
          setSaveLoaderResume(false);
        }
      })
      .catch((err) => {
        setSaveLoaderResume(false);

        console.log(err);
      });
  };

  const fileInputClicked = (e) => {
    fileInputRef.current.click();
  };
  const location = useLocation();

  const [educationDetailsCheck, setEducationDetailsCheck] = useState(false);
  const [skillDetailsCheck, setSkillDetailsCheck] = useState(false);

  useEffect(() => {
    if (educationDetailsList.length) {
      const found = educationDetailsList.some((el) => el.is_current === true);
      if (found) setEducationDetailsCheck(true);
      else setEducationDetailsCheck(false);
    }
  }, [educationDetailsList.length]);

  useEffect(() => {
    if (skillDetailsList.length) {
      setSkillDetailsCheck(true);
    } else setSkillDetailsCheck(false);
  }, [skillDetailsList.length]);
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Hidden smDown>
          <Grid item xs={12} sm={6}>
            <div>
              <Hidden smDown>
                <Typography className={classes.heading}>Complete your Profile</Typography>
              </Hidden>
              <Hidden smUp>
                <Typography variant="h5">Complete your Profile</Typography>
              </Hidden>

              <br />
              {location.search && location.search.includes("source") ? (
                <>
                  <div style={{ width: "350px" }}>
                    <Typography variant="body2" color="initial">
                      Fill all the below mentioned details for the Job Application to apply for the job
                    </Typography>
                  </div>
                  <br />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      <img src={basicDetailsFilled ? check : empty} height="16px" width="16px" />
                      <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                        Basic Details
                      </Typography>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      <img src={educationDetailsFilled ? check : empty} height="16px" width="16px" />
                      <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                        Latest Education Details
                      </Typography>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={skillsFilled ? check : empty} height="16px" width="16px" />
                      <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                        Skills
                      </Typography>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ width: "350px" }}>
                    <Typography variant="body2" color="initial">
                      Fill all the details required for applying on any Job Application and to have better chance of getting short listed
                    </Typography>
                  </div>
                  <br />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      <img src={basicDetailsFilled ? check : empty} height="16px" width="16px" />
                      <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                        Basic Details
                      </Typography>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      <img src={educationDetailsFilled ? check : empty} height="16px" width="16px" />
                      <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                        Latest Education Details
                      </Typography>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={skillsFilled ? check : empty} height="16px" width="16px" />
                      <Typography variant="subtitle2" style={{ marginLeft: "10px", fontFamily: " Nunito" }}>
                        Skills
                      </Typography>
                    </div>
                  </div>
                </>
              )}
              <br />
              {!userDetails.resume && (
                <div onClick={(e) => fileInputClicked(e)}>
                  <input
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    id="file"
                    onChange={(e) => {
                      if (!e.target.files[0]) return;
                      const ext = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".") + 1);
                      if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
                        alert.error("Only PDF & DOC Format Allowed!");
                        return;
                      }
                      if (e.target.files[0].size < 2097152) {
                        ResumeParsing(e.target.files[0]);

                        // setUserDetails({ ...userDetails, resume: e.target.files[0] });
                      } else alert.error("File Size Limit: 2MB");
                    }}
                  />

                  {/* <Button variant="contained" className={classes.btn}>
                    {saveLoaderResume ? <CircularProgress style={{ color: "#fff" }} size={35} /> : "Upload Resume to Autofill your details"}
                  </Button> */}
                </div>
              )}

              <br />
              <CircularProgressWithLabel varyPercentage={varyPercentage} />

              {location.search && location.search.includes("source") ? (
                <>
                  <Button
                    variant="contained"
                    className={classes.btn}
                    disabled={prefFilled ? false : true}
                    onClick={() => {
                      history.push(`${location.search.replace("?source=", "")}`);
                    }}
                  >
                    Apply Now
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    className={classes.btn}
                    // disabled={prefFilled ? false : true}
                    onClick={() => {
                      history.push(`/student/dashboard`);
                    }}
                  >
                    Move to Dashboard
                  </Button>
                </>
              )}
              <br />
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} className={classes.form}>
          <FillResume
            basicDetailsFilled = {basicDetailsFilled}
            setBasicDetailsFilled = {setBasicDetailsFilled}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            currentCity={currentCity}
            setCurrentCity={setCurrentCity}
            currentHomeCity={currentHomeCity}
            setCurrentHomeCity={setCurrentHomeCity}
            currentGender={currentGender}
            setCurrentGender={setCurrentGender}
            allCities={allCities}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
            openAddEducation={openAddEducation}
            setOpenAddEducation={setOpenAddEducation}
            allBoards={allBoards}
            educationDetails={educationDetails}
            setEducationDetails={setEducationDetails}
            allColleges={allColleges}
            educationDetailsErrors={educationDetailsErrors}
            setEducationDetailsErrors={setEducationDetailsErrors}
            setEducationDetailsList={setEducationDetailsList}
            educationDetailsList={educationDetailsList}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            workExpDetails={workExpDetails}
            setWorkExpDetails={setWorkExpDetails}
            workExpDetailsErrors={workExpDetailsErrors}
            setWorkExpDetailsErrors={setWorkExpDetailsErrors}
            openAddWorkExp={openAddWorkExp}
            setOpenAddWorkExp={setOpenAddWorkExp}
            workExpDetailsList={workExpDetailsList}
            setWorkExpDetailsList={setWorkExpDetailsList}
            allCompanies={allCompanies}
            setAllCompanies={setAllCompanies}
            allJobType={allJobType}
            allSkills={allSkills}
            skillDetails={skillDetails}
            setSkillDetails={setSkillDetails}
            skillDetailsErrors={skillDetailsErrors}
            setSkillDetailsErrors={setSkillDetailsErrors}
            skillDetailsList={skillDetailsList}
            setSkillDetailsList={setSkillDetailsList}
            linkList={linkList}
            setLinkList={setLinkList}
            allSocialLinks={allSocialLinks}
            projectDetails={projectDetails}
            setProjectDetails={setProjectDetails}
            projectDetailsErrors={projectDetailsErrors}
            setProjectDetailsErrors={setProjectDetailsErrors}
            projectList={projectList}
            setProjectList={setProjectList}
            educationLoader={educationLoader}
            expLoader={expLoader}
            skillsLoader={skillsLoader}
            projectLoader={projectLoader}
            linksLoader={linksLoader}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            setProfilePicChanged={setProfilePicChanged}
            profilePicChanged={profilePicChanged}
            openAddAchievements={openAddAchievements}
            setOpenAddAchievements={setOpenAddAchievements}
            openAddHobbies={openAddHobbies}
            setOpenAddHobbies={setOpenAddHobbies}
            openAddLanguages={openAddLanguages}
            setOpenAddLanguages={setOpenAddLanguages}
            openAddProjects={openAddProjects}
            setOpenAddProjects={setOpenAddProjects}
            extraData={extraData}
            setExtraData={setExtraData}
            fetchPrecentage={fetchPrecentage}
            getUserDetails={getUserDetails}
            mainRef={mainRef}
            executeScroll={executeScroll}
            instituteList={instituteList}
            openAddCertificate={openAddCertificate}
            setOpenAddCertificate={setOpenAddCertificate}
          />
          {/* <BasicInfo />   Demo component  */}
        </Grid>
      </Grid>
      {location.search && location.search.includes("source") ? (
        <Hidden smUp>
          <React.Fragment>
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar className={classes.apply}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                    justifyContent: "center",
                    height: 47,
                    marginTop: 10,
                  }}
                >
                  {" "}
                  <Typography style={{ fontSize: "12px", marginTop: "10px" }}>Profile Completion</Typography>
                  <CircularProgressWithLabel varyPercentage={varyPercentage} mobileView={true} />
                </div>
                <Link
                  to={{
                    pathname: `${location.search.replace("?source=", "")}`,
                  }}
                >
                  <CommonButton variant="contained" className={classes.btn} disabled={prefFilled ? false : true}>
                    Apply Now
                  </CommonButton>
                </Link>
              </Toolbar>
            </AppBar>
          </React.Fragment>
        </Hidden>
      ) : (
        <Hidden smUp>
          <React.Fragment>
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar className={classes.apply}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                    justifyContent: "center",
                    height: 47,
                    marginTop: 10,
                  }}
                >
                  {" "}
                  <Typography style={{ fontSize: "12px", marginTop: "10px" }}>Profile Completion</Typography>
                  <CircularProgressWithLabel varyPercentage={varyPercentage} mobileView={true} />
                </div>
                <Link
                  to={{
                    pathname: `/student/dashboard`,
                  }}
                >
                  <CommonButton variant="contained" className={classes.btn}>
                    Move to Dashboard
                  </CommonButton>
                </Link>
              </Toolbar>
            </AppBar>
          </React.Fragment>
        </Hidden>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { Grid, Paper, Typography, IconButton, Chip, Divider, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../../assets/company/png/de.png";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import ShareIcon from "@material-ui/icons/Share";
import BasicDetails from "../BasicDetails";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Data from "../BasicDetails/Data";
import moment from "moment";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import kFormatter, { LFormatter, MFormatter } from "../../../../utils/ZeroToK";
import { monthDiff } from "../../../../utils/AddMonths";
import JobSharerButton from "../../../common/JobSharer/JobSharerButton";
import { jobSharerDetail } from "../../../../utils/utility";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  image: {
    border: "1px solid #e2e6ea",
    borderRadius: "12px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "80px",
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  subheading: {
    fontSize: "15px",
    color: "#6c757d",
    marginLeft: "15px",
  },
  info: {
    fontSize: "12px",
    color: "#6c757d",
  },
  icon: {
    color: "#6c757d",
    fontSize: "16px",
    display: "flex",
  },
  iconshare: {
    color: "#6c757d",
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  chip: {
    color: "#007bff",
    backgroundColor: "#e8f3ff",
    borderColor: "#007bff",
  },
  chipsection: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  mobile: {
    textAlign: "center",
    display: "contents",
  },
}));

function Header({ data, fromCollegeView }) {
  const classes = useStyles();
  const [shareIcon, setShareIcon] = useState(false);
  const [companyLogoError, setCompanyLogoError] = useState(false);

  function valueLabelFormat(value) {
    var new_value;
    if (value > 99999) {
      if (value > 999999) new_value = MFormatter(value);
      else new_value = LFormatter(value);
    } else new_value = kFormatter(value);
    return new_value;
  }
  const mainData = [
    // {
    //   icon: <HourglassFullIcon />,
    //   heading: "Apply By",
    //   details: moment(data?.apply_end_date).format("DD MMM 'YY"),
    //   show: data?.apply_end_date ? true : false,
    // },
    {
      icon: <WorkOutlineIcon />,
      heading: "Job Type",
      details: data?.job_type_name,
      show: data?.job_type ? true : false,
    },
    // {
    //   icon: <PlayCircleOutlineIcon />,
    //   heading: "Start Date",
    //   details: moment(data?.job_duration_start).format("DD MMM 'YY"),
    //   show: data?.job_duration_start ? true : false,
    // },
    {
      icon: <DateRangeIcon />,
      heading: "Duration",
      details: monthDiff(new Date(data?.job_duration_start), new Date(data?.job_duration_end)) + " months",
      show: data?.job_duration_start && data?.job_duration_end ? true : false,
    },
    {
      icon: <CreditCardIcon />,
      heading: "Stipend",
      details:
        data?.stipend && data?.internship_salary_type === "PAID"
          ? valueLabelFormat(data?.stipend) + " per month"
          : data?.internship_salary_type === "NOT DISCLOSED"
          ? "Not Disclosed"
          : data?.internship_salary_type === "UNPAID" && "Unpaid",
      show: data?.job_type === 1 ? true : false,
    },
    {
      icon: <CardMembershipIcon />,
      heading: "PPO",
      details: "Yes",
      show: data?.ppo ? true : false,
    },
    {
      icon: <CreditCardIcon />,
      heading: <>Salary After PPO</>,
      details:
        data?.salary_type === "PAID"
          ? data?.ctc_min !== 0 && data?.ctc_max !== 0 && valueLabelFormat(data?.ctc_min) + " - " + valueLabelFormat(data?.ctc_max) + " " + data?.salary_payment_type?.toLowerCase()
          : data?.salary_type === "NOT DISCLOSED"
          ? "Not Disclosed"
          : data?.salary_type === "UNPAID" && "Unpaid",
      show: data?.job_type === 1 && data?.ppo === true ? true : false,
    },
    {
      icon: <CreditCardIcon />,
      heading: "Compensation",
      details:
        data?.salary_type === "PAID"
          ? data?.ctc_min !== 0 && data?.ctc_max !== 0 && valueLabelFormat(data?.ctc_min) + " - " + valueLabelFormat(data?.ctc_max) + " " + data?.salary_payment_type?.toLowerCase()
          : data?.salary_type === "NOT DISCLOSED"
          ? "Not Disclosed"
          : data?.salary_type === "UNPAID" && "Unpaid",
      show: data?.job_type !== 1 ? true : false,
    },

    {
      icon: <AssignmentTurnedInIcon />,
      heading: "Hiring Type",
      details: data?.hiring_type === "ON CAMPUS" ? "On Campus" : "Off Campus",
      show: true,
    },
  ];

  let companyLogo = data?.company?.company_website && !companyLogoError ? `//logo.clearbit.com/${data?.company?.company_website}` : logo

  return (
    <>
      <div>
        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid item md={2} lg={2} sm={2}>
              <Paper className={classes.image}>
                <img
                  src={companyLogo}
                  onError={(e) => {
                    e.target.onerror = () => setCompanyLogoError(true)
                    e.target.src = logo
                  }}
                  height="50px"
                  width="50px"
                />
              </Paper>
            </Grid>
            <Grid item md={!shareIcon ? 8 : 6} lg={!shareIcon ? 8 : 6} sm={!shareIcon ? 8 : 6}>
              <Hidden smDown>
                <Typography variant="h5">
                  <b>{data?.job_title}</b>
                </Typography>
              </Hidden>
              <Typography variant="body2" style={{ marginTop: "10px", color: "#6c757d" }}>
                {data?.company ? (
                  <a style={{ marginTop: "10px", color: "#6c757d" }} href={`/public/company/profile/${data?.company?.company_id}`} target="blank">
                    {" "}
                    <b> {data?.company ? data?.company?.company_name : data?.company_details}</b>
                  </a>
                ) : (
                  <b> {data?.company ? data?.company?.company_name : data?.company_details}</b>
                )}
              </Typography>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid
              container
              spacing={3}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div className={classes.mobile}>
                <Grid item xs={6}>
                  <Paper className={classes.image}>
                    <img
                      src={companyLogo}
                      onError={(e) => {
                        e.target.onerror = () => setCompanyLogoError(true)
                        e.target.src = logo
                      }}
                      height="50px"
                      width="50px"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ marginTop: "10px" }}>
                    {data?.company ? data?.company?.company_name : data?.company_details}
                  </Typography>
                </Grid>
              </div>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item md={!shareIcon ? 2 : 3} lg={!shareIcon ? 2 : 3} sm={!shareIcon ? 2 : 3}>
              {!fromCollegeView && (
                <>
                  {!shareIcon ? (
                    <div className={classes.iconshare}>
                      {/* <IconButton aria-label="delete">
                  <BookmarkBorderIcon />
                </IconButton> */}
                      <IconButton
                        aria-label="save"
                        onClick={() => {
                          setShareIcon(!shareIcon);
                        }}
                        style={{
                          transition: "all 0.3s ease-in-out 0s",
                        }}
                      >
                        <ShareIcon style={{ color: "#000" }} />{" "}
                      </IconButton>
                    </div>
                  ) : (
                    <JobSharerButton detail={jobSharerDetail(data)} page="ALL" />
                  )}
                </>
              )}
            </Grid>
          </Hidden>

          {mainData.map(
            (val, ind) =>
              val.show && (
                <>
                  <Hidden smUp>
                    <Grid item xs={6} md={2} sm={2} lg={2} style={{ padding: "0px 12px" }}>
                      <BasicDetails icon={val.icon} heading={val.heading} details={val.details} />
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <Grid item xs={6} md={2} sm={2} lg={2}>
                      <BasicDetails icon={val.icon} heading={val.heading} details={val.details} />
                    </Grid>
                  </Hidden>
                </>
              )
          )}
          {(data) && (
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <div>
                {" "}
                <div className={classes.icon}>
                  <LocationOnIcon />
                  <Typography className={classes.subheading}>
                    <b>Location</b>
                  </Typography>
                </div>
                <div className={classes.chipsection} style={{ marginLeft: 30 }}>
                  {/* {data?.getwork_fulfilled ? ( */}
                  <>
                    {data?.work_from_home === true ? (
                      <Chip label={"Work From Home"} variant="outlined" size="small" className={classes.chip} />
                    ) : data?.job_location?.length==0 ? (
                      <Chip label={"PAN India"} variant="outlined" size="small" className={classes.chip} />
                    ) : (
                      data?.job_location?.map((item, index) => <Chip label={item.city} variant="outlined" size="small" className={classes.chip} />)
                    )}
                  </>
                  {/* ) 
                  : (
                    <Chip label={data?.job_location} variant="outlined" size="small" className={classes.chip} />
                  )} */}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        <br />
        <Divider />
      </div>
    </>
  );
}

export default Header;

import React from "react";
import { useDispatch } from "react-redux";
import pSBC from "shade-blend-color";

import {
  CircularProgress,
  createMuiTheme,
  fade,
  ThemeProvider,
} from "@material-ui/core";

export default function MuiCustomTheme({ children }) {
  const Theme2 = createMuiTheme({
    palette: {
      type: "light",
      common: {
        black: "#000000",
        white: "#ffffff",
      },
      primary: {
        main: "#007BFF",
        hover: "#0069D9",
        disable: "#58AAFF",
        contrastText: "#fff",
        secondary: "#e0a800",
      },
      secondary: {
        main: "#6C757D",
        hover: "#5A6268",
        disable: "#B0B6BA",
      },
      success: {
        main: "#28A745",
        hover: "#218838",
        disable: "#74C686",
      },
      text: {
        primary: "#424242",
        secondary: "#828282",
      },
      error: {
        main: "#DC3545",
        hover: "#C82333",
        disable: "#E97B86",
      },
      warning: {
        main: "#FFC107",
        hover: "#E0A800",
        disable: "#FFD75E",
      },

      grey: {
        50: "#F2F2F2",
      },
    },
    typography: {
      fontFamily: "Nunito",
      h1: {
        fontSize: "3em",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h2: {
        fontSize: "32px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h3: {
        fontSize: "24px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h4: {
        fontSize: "20px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h5: {
        fontSize: "18px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      h6: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body1: {
        color: "#000000",
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body2: {
        color: "#000000",
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      body3: {
        fontSize: "12px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle1: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      subtitle2: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "600",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      caption: {
        fontSize: "12px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      overline: {
        fontSize: "10px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      button: {
        fontSize: "16px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
      buttonmob: {
        fontSize: "14px",
        letterSpacing: "0px",
        wordSpacing: "0px",
        fontWeight: "400",
        textDecoration: "none",
        fontStyle: "normal",
        fontVariant: "normal",
        textTransform: "none",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          position: "relative",
          textTransform: "none",
          transition: "all .15s ease",
          letterSpacing: ".025em",
          fontSize: ".875rem",
          padding: ".625rem 1.25rem",
          willChange: "transform",
          border: "1px solid transparent",
          lineHeight: "1.5",
          borderRadius: ".375rem",
          userSelect: "none",
          display: "inline-block",
          boxShadow: "none",
          fontWeight: "600",
          textAlign: "center",
          verticalAlign: "middle",
        },
        contained: {
          "&$disabled": {
            color: "#FFF",
            backgroundColor: pSBC(0.7, "#007BFF"),
          },
        },
        text: {
          color: "#EE7D66",
        },
      },
      MuiOutlinedInput: {
        // MuiInputLabel: {
        //   root: {
        //     transform: "translate(15px, 14px) scale(1)",
        //   },
        // },
        root: {
          backgroundColor: "#fff",
          height: 40,
          borderRadius: 30,
          // fontSize: 12,

          // border: "1px solid #ced4da",
          // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
        },
        notchedOutline: {
          // borderColor: "transparent",
        },
        multiline: {
          height: "auto",
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: "14px",
        },
      },
      MuiFormControlLabel: {
        fontSize: 14,
        label: {
          fontSize: 14,
        },
      },

      MuiFab: {
        root: {
          backgroundColor: "#EE7D66",
          color: "#FFF",
        },
      },
      MuiTab: {
        wrapper: {
          color: "#000",
        },
        textColorInherit: {
          color: "#FFF",
        },
      },
      MuiAutocomplete: {
        paper: {
          borderRadius: 10,
        },
        input: {
          padding: "3.5px 4px !important",
          fontSize: "14px",
        },
      },
      MuiMenu: {
        paper: {
          borderRadius: 10,
        },
      },
      // MuiFormLabel: {
      //   root: {
      //     color: "rgba(0, 0, 0, 0.23)",
      //   },
      // },
      MuiSelect: {
        root: {
          fontSize: "14px",
          background: "transparent",
        },
        select: {
          backgroundColor: "transparent",

          "&:focus": {
            backgroundColor: "transparent",
            borderRadius: 30,
          },
        },
      },
    },
    publicLeftSide: {},
  });

  return <ThemeProvider theme={Theme2}>{children}</ThemeProvider>;
}

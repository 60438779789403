import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  alpha,
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";

import {makeStyles,
  createStyles} from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Axios from "axios";
import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../constants/constants";
import user from "../../../Assets/Images/png/aisha.png";
import { CustomTextField } from "../../Common/Input/InputField";
import CurrencyComponent from "../../../../components/common/JobPostNew/FormComponents/CurrencyComponent";

const useStyles = makeStyles((theme) =>
  createStyles({
    labelSize: {
      fontSize: 13,
    },
    "@media only screen and (max-width: 1100px)": {
      labelSize: {
        fontSize: 11,
      }
    },
  })
);

export default function RegisterBasicInfo({
  setStep,
  step,
  finalValues,
  setFinalValues,
  finalResume,
  setFinalResume,
  finalExpclicked,
  setFinalExpClicked,
  getContinueButtonId,
  uploadedResume
}) {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const gwToken = localStorage.getItem("gw_token");

  const uploadpaper = {
    cursor: "pointer",
    boxShadow: "none",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  };
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const [resume, setResume] = React.useState(finalResume);

  const handleFile = (e) => {
    setResume(e.target.files[0]);
  };

  const [values, setValues] = useState(finalValues);

  const [errorValues, setErrorValues] = useState({
    yearExp: null,
    currentCtc: null,
    monthExp: null,
  });

  const [expclicked, setExpClicked] = useState(finalExpclicked);

  const updateWorkExperience = () => {
    if (!validateWorkExperience()) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    if (expclicked === 2) {
      let totalExp = values?.yearExp * 12;
      if (values?.monthExp) totalExp = totalExp + values?.monthExp;
      formData.append("total_exp", totalExp);
      values?.currentCtc && formData.append("current_ctc", values?.currentCtc);
    } else {
      formData.append("total_exp", 0);
    }
    resume && formData.append("resume", resume);
    Axios.post(
      `${BackendBaseApi.PRAVESH}api/student/signup/work-experience/`,
      formData,
      {
        headers: {
          Authorization: "Token " + gwToken,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        setLoading(false);
        if (expclicked === 2) {
          setFinalValues(values);
        }
        setFinalResume(resume);
        setFinalExpClicked(expclicked);
        setStep(step + 1);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const validateWorkExperience = () => {
    if (expclicked === 0) {
      alert.error("Please select your work experience!!");
      return false;
    }
    if (expclicked === 2 && !values?.yearExp && values?.yearExp !== 0) {
      alert.error("Please enter your total work experience!!");
      return false;
    }
    return true;
  };

  return (
    <>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar alt="user-img" src={user} sx={{ width: 66, height: 66 }} />
        <Typography
          variant="h4"
          style={{ fontWeight: "500", lineHeight: 1.4, marginTop: 5 }}
        >
          One step closer, what is your current experience?
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Button
            onClick={() =>
              expclicked === 1 ? setExpClicked(0) : setExpClicked(1)
            }
            // endIcon={<ArrowForwardRoundedIcon />}
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "30px",
              display: "flex",
              fontWeight: "400",
              fontSize: "15px",
              backgroundColor: `${
                expclicked !== 1
                  ? "#fff"
                  : alpha(theme.palette.primary.main, 0.1)
              }`,
              color: `${
                expclicked !== 1 ? "#6C757D" : theme.palette.primary.main
              }`,
              borderWidth: "2px",
              borderColor: `${
                expclicked !== 1 ? "#6C757D" : theme.palette.primary.main
              }`,
            }}
            variant="outlined"
          >
            {expclicked !== 1 ? (
                ""
              ) : (
                <CheckCircleRoundedIcon
                  color="primary"
                  style={{
                    fontSize: "20px",
                    position: "absolute",
                    left: "15px",
                  }}
                />
              )}
            I don’t have work experience
          </Button>
        </Grid>{" "}
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Button
            onClick={() =>
              expclicked === 2 ? setExpClicked(0) : setExpClicked(2)
            }
            // endIcon={<ArrowForwardRoundedIcon />}
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "30px",
              display: "flex",
              fontWeight: "400",
              fontSize: "15px",
              backgroundColor: `${
                expclicked !== 2
                  ? "#fff"
                  : alpha(theme.palette.primary.main, 0.1)
              }`,
              color: `${
                expclicked !== 2 ? "#6C757D" : theme.palette.primary.main
              }`,
              borderWidth: "2px",
              borderColor: `${
                expclicked !== 2 ? "#6C757D" : theme.palette.primary.main
              }`,
            }}
            variant="outlined"
          >
            {expclicked !== 2 ? (
                ""
              ) : (
                <CheckCircleRoundedIcon
                  color="primary"
                  style={{
                    fontSize: "20px",
                    position: "absolute",
                    left: "15px",
                  }}
                />
              )}
            I have work experience
          </Button>
        </Grid>
        {expclicked !== 2 ? (
          ""
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12} sx={{ pb: 0 }}>
              <Typography
                variant="body2"
                color="initial"
                style={{ color: "#6C757D", marginBottom: "7px" }}
              >
                Tell us your work experience (i.e. 1 years 2 months)*
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} sx={{ display: "grid", pt: 0 }}>
              <CustomTextField
                size="small"
                label="Experience (Years)"
                type="number"
                required
                onWheel={() => document.activeElement.blur()}
                error={errorValues?.yearExp ? true : false}
                helperText={errorValues?.yearExp ? errorValues?.yearExp : ""}
                value={values?.yearExp}
                onChange={(e) => {
                  setValues({
                    ...values,
                    yearExp: e?.target?.value ? parseFloat(e.target.value) : null,
                  });
                  setErrorValues({ ...errorValues, yearExp: "" });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} sx={{ display: "grid", pt: 0 }}>
              <CustomTextField
                size="small"
                label="Experience (Months)"
                type="number"
                required
                onWheel={() => document.activeElement.blur()}
                error={errorValues?.monthExp ? true : false}
                helperText={errorValues?.monthExp ? errorValues?.monthExp : ""}
                value={values?.monthExp}
                onChange={(e) => {
                  setValues({
                    ...values,
                    monthExp: e.target.value
                      ? parseFloat(e.target.value)
                      : null,
                  });
                  setErrorValues({ ...errorValues, monthExp: "" });
                }}
              />
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12} sm={12} md={12} sx={{ display: "grid", pt: 0 }}>
              <Typography
                variant="body2"
                color="initial"
                style={{ color: "#6C757D", marginBottom: 7 }}
              >
                Your current annual salary (in Rs.)
              </Typography>
              {/* <CustomTextField
                size="small"
                label="Current Salary"
                type="number"
                onWheel={() => document.activeElement.blur()}
                error={errorValues?.currentCtc ? true : false}
                helperText={
                  errorValues?.currentCtc ? errorValues?.currentCtc : ""
                }
                value={values?.currentCtc}
                onChange={(e) => {
                  setValues({
                    ...values,
                    currentCtc: parseFloat(e.target.value),
                  });
                  setErrorValues({ ...errorValues, currentCtc: "" });
                }}
              /> */}
              <CurrencyComponent
                    state={values?.currentCtc}
                    setState={(value) => {
                      
                      setValues({
                        ...values,
                        currentCtc: value ? parseFloat(value) : null,
                      });
                      setErrorValues({ ...errorValues, currentCtc: "" });
                    }}
                    labelName={"Salary"}
                    width={"100%"}
                    responsive={classes.labelSize}
                    error={false}
                    required={true}
                  />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <Box
            style={uploadpaper}
            onClick={() => {
              // `current` points to the mounted file input element
              ref.current.click();
            }}
          >
            <div
              style={{
                border: "1px solid",
                borderColor: "#D7E6F0",
                borderRadius: "6px",
                padding: "10px",
              }}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.958 26.166V11.249L14.5 15.666L13.042 14.166L20 7.20703L26.958 14.166L25.5 15.666L21.042 11.249V26.166H18.958ZM10.125 32.499C9.403 32.499 8.785 32.242 8.271 31.728C7.757 31.214 7.5 30.596 7.5 29.874V24.957H9.583V29.874C9.583 30.0127 9.63867 30.1377 9.75 30.249C9.86133 30.3604 9.98633 30.416 10.125 30.416H29.875C30.0137 30.416 30.1387 30.3604 30.25 30.249C30.3613 30.1377 30.417 30.0127 30.417 29.874V24.957H32.5V29.874C32.5 30.596 32.243 31.214 31.729 31.728C31.215 32.242 30.597 32.499 29.875 32.499H10.125Z"
                  fill="#007BFF"
                />
              </svg>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <Typography
                variant="h6"
                color="primary"
                caption="The year when you have completed your highest qualification."
              >
                Upload Resume
              </Typography>
              <input ref={ref} type="file" onChange={handleFile} hidden />

              <Typography variant="body2" color="secondary">
                {" "}
                {!resume && !uploadedResume ? (
                  <>Accepted file formats: .pdf &.doc</>
                ) : (
                  <Typography
                    variant="body2"
                    nowrap
                    style={{ maxWidth: "250px" }}
                    onClick={(e) => {
                      if(uploadedResume && !resume){
                      e.stopPropagation();
                      window.open(uploadedResume);
                      }
                    }}
                    
                  >
                    {resume?.name ? resume?.name : "View Uploaded Resume"}
                  </Typography>
                )}
              </Typography>
            </div>
          </Box>
          {errorValues?.resume && (
            <Typography
              style={{ color: "#DC3545", fontSize: "13px", marginTop: "5px" }}
            >
              {errorValues?.resume}
            </Typography>
          )}
        </Grid>
      </Grid>
      <div>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            position: "relative",
            marginTop: "10px",
            [theme.breakpoints.only("sm")]: {
              top: "auto",
              bottom: 0,
              boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
              position: "fixed",
            },
            [theme.breakpoints.only("xs")]: {
              top: "auto",
              bottom: 0,
              boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
              position: "fixed",
            },
          }}
        >
          <Box
            sx={{
              pl: 0,
              pr: 0,
              [theme.breakpoints.only("sm")]: { pl: 1, pr: 1 },
              [theme.breakpoints.only("xs")]: { pl: 1, pr: 1 },
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
                <Button
                  color="primary"
                  sx={{
                    height: "40px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "30px",
                    display: "flex",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
                <Button
                  color="primary"
                  endIcon={<ArrowForwardRoundedIcon />}
                  sx={{
                    height: "40px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "30px",
                    display: "flex",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  variant="contained"
                  onClick={() => {
                    updateWorkExperience();
                  }}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    <span id={getContinueButtonId()}> Continue</span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AppBar>
      </div>
    </>
  );
}

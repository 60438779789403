import { makeStyles, Tooltip } from "@material-ui/core";
import React, { useState, useEffect, Fragment } from "react";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    borderRadius: 10,
  },
}));

const useStylesBootstrapYellow = makeStyles((theme) => ({
  arrow: {
    color: "#fff7e0",
  },
  tooltip: {
    backgroundColor: "#fff7e0",
    color: "rgba(0, 0, 0, 0.95)",
    boxShadow: theme.shadows[1],
    fontSize: 14.5,
    borderRadius: 10,
    width: "50%",
  },
}));

const useStylesBootstrapHeight = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    borderRadius: 10,

    maxHeight: "600px",
    maxWidth: "700px",
    overflowY: "scroll",
  },
}));

export default function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export function BootstrapTooltipNew(props) {
  const classes = useStylesBootstrapHeight();

  return <Tooltip arrow classes={classes} {...props} />;
}

export function BootstrapTooltipYellow(props) {
  const classes = useStylesBootstrapYellow();

  return <Tooltip arrow classes={classes} {...props} />;
}

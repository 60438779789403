import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import { BackendBaseApi } from "../../../../constants/constants";
import StudentRoutes from "../../../../routes/StudentRoutes";
import Loader from "../../../common/components/UI/Loader";
import "../UI/UI.css";

const StudentHomeComponent = () => {
  const [loading, setLoading] = useState(true);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [educationDetails, setEducationDetails] = useState({});
  let user_type_id = localStorage.getItem("user_type_id");

  const getStudentDetails = (token) => {
    if (location.pathname.split("/")[2] !== "public") {
      axios
        .get(
          BackendBaseApi.PRAVESH + "api/shared/user/?user_type=1",
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((res) => {
          //fire an action here to save details in student context store

          localStorage.setItem("user_details", JSON.stringify(res.data.data));
          localStorage.setItem("email_verified", res.data.data.email_verified);
          setUserDetails(res.data.data);
        })
        .catch((err) => {
          localStorage.clear();
          alert.error("Your session has been Expired. Please login again");
          history.push("/login");
        });
    }
  };
  useEffect(() => {
    let token = null;
    token = localStorage.getItem("gw_token");

    //if(Object.keys(userDetails).length==0)
    getStudentDetails(token);
    // if(location.pathname=='/student')
    // history.push('/student/dashboard')
  }, []);

  useEffect(() => {
    if (
      Object.keys(userDetails).length > 0 ||
      location.pathname.split("/")[2] === "public"
    ) {
      setLoading(false);
    }
  }, [userDetails]);
  return loading ? (
    <>
      <Loader />
    </>
  ) : (
    <>
      <div
        className=""
        style={{
          // backgroundColor: "#f2f2f2"
        }}
      >
        {/* <div className=""> */}
          {/* <div className=""><Sidebar userType={"STUDENT"}/></div> */}
          {/* <div className=""> */}
            {/* <StudentNavbar name={userDetails.first_name+' '+userDetails.last_name} dp={userDetails.profile_picture}/> */}
            {/* { userDetails && (<StudentRoutes studentData={userDetails}/>)} */}
            <StudentRoutes studentData={userDetails} />
          {/* </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default StudentHomeComponent;

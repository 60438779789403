import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  Drawer,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Hidden,
} from "@material-ui/core";

import ArrowRight from "../../../assets/images/sidepanel/arrow-right.png";
import HomeIcon from "../../../assets/images/sidepanel/home.png";
import HomeIcon2 from "../../../assets/images/sidepanel/home2.png";
import JobsIcon from "../../../assets/images/sidepanel/jobs.png";
import JobsIcon2 from "../../../assets/images/sidepanel/jobs2.png";
import Invite from "../../../assets/images/sidepanel/invite.png";
import ApplicantStatus2 from "../../../assets/images/sidepanel/applicant-status2.png";
import Connections from "../../../assets/images/sidepanel/connections.png";
import Connections2 from "../../../assets/images/sidepanel/connections2.png";
import assign from "../../../assets/images/sidepanel/assign.png";
import SmallProileCard from "../SmallProfileCard/Index";
import mentor from "../../../assets/mentor/mentor.png";
import Badge from "@material-ui/core/Badge";
import UploadIcon from "../../../assets/images/sidepanel/upload.png";
import UploadResume from '../../../components/student/UploadResume/UploadResume';

import { NavLink, useLocation } from "react-router-dom";

import {
  BackendBaseApi, MENTORKART_ENDPOINT
} from "../../../constants/constants";
import Axios from "axios";


//import TopPanel from "./TopPanel";

const CompanySidebar = (props) => {

  const checkforSSO = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/sso/mentorkart/token", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res?.data?.success){
          window.open(MENTORKART_ENDPOINT + "/auth?SSO_Mtoken=" + res.data.data.token + "&utm_source=Getwork_Portal%20&utm_medium=web&utm_campaign=SMP&utm_content=download", '_blank');
        }
      })
      .catch((err) => {
      });
  };

  const {handleDrawerToggle, mobileOpen } = props;
  const classes = useStyles();
  const classes2 = useStyles1();

  const location = useLocation();

  const [expanded, setExpanded] = useState("");
  const [openResumeModal, setOpenResumeModal] = useState(false);

  const container = undefined;

  const switchIcon = (menu, defaultIcon, flag) => {
    if (flag && menu === "Home") {
      return HomeIcon2;
    } else if (flag && menu === "Jobs") {
      return JobsIcon2;
    } else if (flag && menu === "Applicants") {
      return ApplicantStatus2;
    } else if (flag && menu === "Assignment") {
      return Connections2;
    } else if (flag && menu === "Connections") {
      return Connections2;
    } else return defaultIcon;
  };

  const checkAccordionExpansion = () => {
    if (
      location.pathname === "/company/job-applicants" ||
      location.pathname === "/company/track" ||
      location.pathname === "/company/interview"
    ) {
      setExpanded("Applicants");
    } else if (
      location.pathname === "/company/college-connection" ||
      location.pathname === "/company/student-connection"
    ) {
      setExpanded("Connections");
    } else setExpanded("");
  };

  useEffect(() => {
    checkAccordionExpansion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClose = () => { setOpenResumeModal(false); };

  const sideMenus = [
    {
      menu: "Home",
      link: "/student/dashboard",
      icon: HomeIcon,
      subMenu: [],
      subLinks: [],
    },
    {
      menu: "Jobs",
      link: "",
      icon: JobsIcon,
      // subMenu: ["Open Jobs", "Applied", "Saved", "Hidden", "Closed"],
      subMenu: ["Open Jobs", "My Jobs"],
      subLinks: [
        "/student/open",
        "/student/my-jobs",
        // "/student/applied",
        // "/student/saved",
        // "/student/hidden",
        // "/student/closed",
      ],
    },
    {
      menu: "Interview",
      link: "",
      icon: Invite,
      subMenu: ["Invited", "Scheduled"],
      subLinks: ["/student/invited", "/student/scheduled"],
    },
    {
      menu: "Assignment",
      link: "",
      icon: assign,
      subMenu: ["All Assignments", "Submitted"],
      subLinks: ["/student/open-assignment", "/student/submitted-assignment"],
    },
    {
      menu: "Mentorship",
      link: "/student/mentorship",
      icon: mentor,
      subMenu: [],
      subLinks: [],
      isNew: true,
    },

    {
      menu: "Blogs",
      link: "/student/blog",
      icon: assign,
      subMenu: [],
      subLinks: [],
    },

    // {
    //   menu: "Connections",
    //   icon: Connections,
    //   link: "/student/connection",
    //   subMenu: [],
    //   subLinks: [],
    // },

    // {
    //   menu: "Upload Resume",
    //   icon: UploadIcon,
    //   link: "",
    //   subMenu: [],
    //   subLinks: [],
    // },
  ];

  const handleExpansionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box>
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
            container={container}
            variant="temporary"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <div className={classes.toolbar} />
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="space-around">
              <SmallProileCard />
            </Box>

            {sideMenus.map((item, index) =>
              item.subMenu.length > 0 ? (
                <Accordion
                  className={classes.accordion}
                  key={`${item.menu}-${index}`}
                  classes={{ root: classes.accordionRoot }}
                >
                  <AccordionSummary
                    style={{ backgroundColor: "#f2f2f2" }}
                    classes={{
                      expandIcon: classes.expandIcon,
                      expanded: classes.expanded,
                    }}
                    expandIcon={<img src={ArrowRight} alt="arrow-right" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box display="flex" alignItems="center" width="100%">
                      <img
                        src={switchIcon(
                          item.menu,
                          item.icon,
                          item.link === location.pathname ||
                            item.subLinks.includes(location.pathname)
                        )}
                        className={classes.expandIcon}
                        alt="expand-icon"
                      />
                      {item.isNew === true ? (
                        <Badge
                          color="secondary"
                          badgeContent={"New"}
                          classes={{
                            badge: classes2.badge,
                            colorSecondary: classes2.colorSecondary,
                          }}
                        >
                          <Typography className={classes.sidePanelMenuText} >
                            {item.menu}
                          </Typography>
                         
                        </Badge>
                      ) : (
                        <Typography className={classes.sidePanelMenuText}>
                          {item.menu}
                        </Typography>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: "#f2f2f2" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="end"
                      paddingLeft="33%"
                    >
                      {item.subMenu.map((item2, index2) => (
                        <NavLink
                          to={`${item.subLinks[index2]}`}
                          key={index2}
                          activeClassName={classes.activeLink}
                        >
                          <Typography className={classes.submenuText}>
                            {item2}
                          </Typography>
                        </NavLink>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ) : (
                item.isNew === true ? (
                  <div   onClick={() => {
                    checkforSSO();
                  }}>
                  <Box className={classes.normalLinkBox} key={index}>
                    <img
                      src={switchIcon(
                        item.menu,
                        item.icon,
                        item.link === location.pathname ||
                          item.subLinks.includes(location.pathname)
                      )}
                      className={classes.expandIcon}
                      alt="expand-on"
                    />
                    
                      <Badge
                        color="secondary"
                        badgeContent={"New"}
                        classes={{
                          badge: classes2.badge,
                          colorSecondary: classes2.colorSecondary,
                        }}
                      >
                        <Typography className={classes.sidePanelMenuText}>
                           {item.menu}
                        </Typography>
                      </Badge>
                      </Box>
                </div>
                    ) : (
                      <NavLink to={item.link} activeClassName={classes.activeLink}>
                  <Box className={classes.normalLinkBox} key={index}>
                    <img
                      src={switchIcon(
                        item.menu,
                        item.icon,
                        item.link === location.pathname ||
                          item.subLinks.includes(location.pathname)
                      )}
                      className={classes.expandIcon}
                      alt="expand-on"
                    />
                      <Typography className={classes.sidePanelMenuText}>
                        {item.menu}
                      </Typography>
                      </Box>
                </NavLink>
                    )
                  
              )
            )}
              <Box className={classes.normalLinkBox} onClick={e=>{setOpenResumeModal(true)}}>
                <img
                  src={UploadIcon}
                  className={classes.expandIcon}
                  alt="expand-icon"
                />
                <Typography className={classes.sidePanelMenuText}>
                  Upload Resume
                </Typography>   
                { openResumeModal && 
                  <UploadResume 
                    openResumeModal
                  />
                }           
              </Box>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <div className={classes.toolbar} />
            {/* <Divider /> */}

            <Box display="flex" justifyContent="space-around">
              <SmallProileCard />
            </Box>

            { sideMenus.map((item, index) =>
              item.subMenu.length > 0 ? (
                <Accordion
                  className={classes.accordion}
                  key={`${item.menu}-${index}`}
                  classes={{ root: classes.accordionRoot }}
                  expanded={expanded === item.menu}
                  onChange={handleExpansionChange(item.menu)}
                >
                  <AccordionSummary
                    style={{ backgroundColor: "#f2f2f2" }}
                    classes={{
                      expandIcon: classes.expandIcon,
                      expanded: classes.expanded,
                    }}
                    expandIcon={<img src={ArrowRight} alt="arrow-right" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box display="flex" alignItems="center" width="100%">
                      <img
                        src={switchIcon(
                          item.menu,
                          item.icon,
                          item.link === location.pathname ||
                            item.subLinks.includes(location.pathname)
                        )}
                        className={classes.expandIcon}
                        alt="expand-icon"
                      />
                      {item.isNew === true ? (
                        <Badge
                          color="secondary"
                          badgeContent={"New"}
                          classes={{
                            badge: classes2.badge,
                            colorSecondary: classes2.colorSecondary,
                          }}
                        >
                          <Typography className={classes.sidePanelMenuText}>
                            {item.menu}
                          </Typography>
                        </Badge>
                      ) : (
                        <Typography className={classes.sidePanelMenuText}>
                          {item.menu}
                        </Typography>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: "#f2f2f2" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="end"
                      paddingLeft="33%"
                    >
                      {item.subMenu.map((item2, index2) => (
                        <NavLink
                          to={`${item.subLinks[index2]}`}
                          key={index2}
                          activeClassName={classes.activeLink}
                        >
                          <Typography className={classes.submenuText}>
                            {item2}
                          </Typography>
                        </NavLink>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ) : 
                item.isNew === true ? (
                <div   onClick={() => {
                  checkforSSO();
                }}>
                  <Box className={classes.normalLinkBox} key={index}>
                    <img
                      src={switchIcon(
                        item.menu,
                        item.icon,
                        item.link === location.pathname ||
                          item.subLinks.includes(location.pathname)
                      )}
                      className={classes.expandIcon}
                      alt="expand-icon"
                    />
                    
                      <Badge
                        color="secondary"
                        badgeContent={"New"}
                        classes={{
                          badge: classes2.badge,
                          colorSecondary: classes2.colorSecondary,
                        }}
                      >
                        <Typography className={classes.sidePanelMenuText} >
                          {item.menu}
                        </Typography>
                      </Badge>
                      </Box>
                      </div>
                    ) : (
                      <NavLink to={item.link} activeClassName={classes.activeLink}>
                  <Box className={classes.normalLinkBox} key={index}>
                    <img
                      src={switchIcon(
                        item.menu,
                        item.icon,
                        item.link === location.pathname ||
                          item.subLinks.includes(location.pathname)
                      )}
                      className={classes.expandIcon}
                      alt="expand-icon"
                    />
                      <Typography className={classes.sidePanelMenuText}>
                        {item.menu}
                      </Typography>
                    
                  </Box>
                </NavLink>
                    
              )
              )
            }
            <Box className={classes.normalLinkBox} onClick={e=>{setOpenResumeModal(true)}} >
                <img
                  src={UploadIcon}
                  className={classes.expandIcon}
                  alt="expand-icon"
                />
                <Typography className={classes.sidePanelMenuText}>
                  Upload Resume
                </Typography>
            </Box>
          </Drawer>
        </Hidden>
      </Box>
      {
        openResumeModal===true
        ?
        <UploadResume 
          openResumeModal={openResumeModal}
          handleClose={handleClose}
        />
        :null
      }
    </>
  );
};

const useStyles1 = makeStyles({
  badge: {
    position: "relative",
    right: 15,
  },
  colorSecondary: {
    background: "linear-gradient(90deg, #f58915 0%, #fd1d1d 51%, #1d3557 100%)",
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      //width: `calc(100% - 16%)`,
      //marginLeft: "16%",
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },

    drawer: {
      //width: "16%",
    },
    drawerPaper: {
      backgroundColor: "#f2f2f2",
      border: "none",
      position: "static",
    },
    logo: {
      width: "137px",
      height: "auto",
    },
    colorSelectorBox: {
      background: "#000000",
      borderRadius: 4,
      width: "47px",
      height: "47px",
    },

    listIcon: {
      minWidth: "36px",
    },
    listIcon2: {
      minWidth: "16px",
    },
    accordion: {
      boxShadow: "none",
      "&$expanded": {
        margin: "0px",
      },
    },
    expandIcon: {
      "&$expanded": {
        transform: "rotate(90deg)",
      },
      width: "auto",
      height: "18px",
    },
    expanded: {},
    sidePanelMenuText: {
      marginLeft: "2%",
      fontFamily: "ProximaNova",
      fontSize: "16px",
    },
    submenuText: {
      fontFamily: "ProximaNova",
      fontSize: "16px",
      marginBottom: "7%",
    },
    normalLinkBox: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "11px 16px",
      //borderTop: "1px solid #E5E5E5",
      cursor: "pointer",
      backgroundColor: "#f2f2f2",
    },
    accordionRoot: {
      "&:before": {
        height: "0px",
      },
      "&$expanded": {
        margin: "0px",
      },
    },
    activeLink: {
      fontFamily: "ProximaNova",
      color: "#3282C4",
      backgroundColor: "#f2f2f2",
    },
    homeButton: {
      fontSize: "24px",
      fontWeight: "bold",
      fontFamily: "ProximaNova",
      textTransform: "capitalize",
      color: "#000000",
    },
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary
    },
    hr: {
      margin:'0px 4px',
    }
  })
);

export default CompanySidebar;

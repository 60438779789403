import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Container, Button, Hidden, IconButton, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SideDrawer from "./SideDrawer";
import SearchIcon from "@material-ui/icons/Search";
import LogInBtn from "./LogInBtn";
import RegisterBtn from "./RegisterBtn";
import DropDown from "./DropDown";
import axios from "axios";
import AvatarMenu from "./AvatarMenu";
import Logo from "../../../assets/images/sidepanel/logo.png";

import { Link, useLocation } from "react-router-dom";
import { BackendBaseApi, NEXT_APP_ENDPOINT } from "../../../constants/constants";
import { checkLinkingHomePage } from "../../../utils/utility";
import RegisterBtnDomain from "./RegisterBtnDomain";
import { useSelector } from "react-redux";
import LogInBtnDomain from "./LoginBtnDomain";
const useStyles = makeStyles({
  appbar: {
    borderBottom: "1px solid #e2e6ea",
    backgroundColor: "white",
  },
  navbar: {
    backgroundColor: "white",
    BorderBottom: " 4px solid black",
  },
  navtab: {
    float: "right",
    paddingRight: "0px",
    padding: "6px",
  },
  navicon: {
    marginRight: "5px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    //paddingLeft: "5px",
    //paddingRight: "5px",
  },
  menuButton: {
    padding: "0px",
    // justifyContent: "flex-start",
    display: "flex",
    justifyContent: "center",
  },
  sectionDesktop: {
    display: "none",

    display: "flex",
  },
  btn: {
    "&:hover": {
      color: "#007bff",
    },
    padding: "10px",
  },
  btn_active: {
    color: "#007bff",
    padding: "10px",
    fontWeight: "20px",
  },
  tab: {
    borderRadius: "30px",
  },
  tab_active: {
    borderRadius: "30px",
    // backgroundColor: "#e8f3ff",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
});

const navLinks = [
  { title: "Students", path: `${NEXT_APP_ENDPOINT}/students` },
  { title: "Jobs", path: `${NEXT_APP_ENDPOINT}/full-time-jobs` },
  { title: "WFH Jobs", path: `${NEXT_APP_ENDPOINT}/work-from-home-jobs` },
  { title: "Internships", path: `${NEXT_APP_ENDPOINT}/internships` },

  { title: "WFH Internships", path: `${NEXT_APP_ENDPOINT}/work-from-home-internships` },
  { title: "Employers", path: `${NEXT_APP_ENDPOINT}/employer` },

  { title: "Colleges", path: `${NEXT_APP_ENDPOINT}/colleges-campus-placements` },
  { title: "Blogs", path: `${NEXT_APP_ENDPOINT}/blogs` },
  { title: "Student Registration", 
  // path: `/signup/student` 
  path: "/candidate/signup"
},
  { title: "Employer Registration", path: `/signup/employer` },
  // { title: "Log In", path: `/login` },

  { title: "About us", path: `${NEXT_APP_ENDPOINT}/about-us` },
  { title: "Contact us", path: `${NEXT_APP_ENDPOINT}/contact-us` },
];

const navLinksLoggedIn = [
  { title: "Dashboard", path: checkLinkingHomePage(localStorage.getItem("user_type")) },

  { title: "Students", path: `${NEXT_APP_ENDPOINT}/students` },
  { title: "Jobs", path: `${NEXT_APP_ENDPOINT}/full-time-jobs` },
  { title: "WFH Jobs", path: `${NEXT_APP_ENDPOINT}/work-from-home-jobs` },
  { title: "Internships", path: `${NEXT_APP_ENDPOINT}/internships` },

  { title: "WFH Internships", path: `${NEXT_APP_ENDPOINT}/work-from-home-internships` },
  { title: "Employers", path: `${NEXT_APP_ENDPOINT}/employer` },

  { title: "Colleges", path: `${NEXT_APP_ENDPOINT}/colleges-campus-placements` },
  { title: "Blogs", path: `${NEXT_APP_ENDPOINT}/blogs` },

  { title: "About us", path: `${NEXT_APP_ENDPOINT}/about-us` },
  { title: "Contact us", path: `${NEXT_APP_ENDPOINT}/contact-us` },
];

function DomainNavbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState("");
  const token = localStorage.getItem("gw_token");
  const location = useLocation();
  const classes = useStyles();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (token) {
      axios
        .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
          headers: {
            Authorization: "Token " + token,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200 && res.data.success && res.data.profile_complete) {
            if (res.data.user_type.length > 0) {
              setUserType(res.data.user_type[0].main_user);
              setIsLoggedIn(true);
            }
            if (res.data.company_details !== null) localStorage.setItem("company", JSON.stringify(res.data.company_details));
            localStorage.setItem(
              "user",
              JSON.stringify({
                email: res.data.email,
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                id: res.data.id,
                profile_picture: res.data?.profile_picture,
              })
            );
            let user_details = localStorage.getItem("user_details") ? localStorage.getItem("user_details") : "{}";
            user_details = JSON.parse(user_details);
            user_details["email"] = res.data.email;
            user_details["first_name"] = res.data.first_name;
            user_details["last_name"] = res.data.last_name;
            user_details["id"] = res.data.id;
            user_details["profile_picture"] = res.data.profile_picture;
            localStorage.setItem("user_details", JSON.stringify(user_details));
          }
        })
        .catch((err) => {
          if (err.response.status === 401) localStorage.clear();
          console.log(err);
        });
    }
  }, []);

  return (
    <AppBar position="fixed" elevation={0} className={classes.appbar}>
      <Toolbar className={classes.navbar}>
        <Container maxWidth="lg" component="main" className={classes.header} disableGutters>
          <Hidden smDown>
            {/* <a href={`${NEXT_APP_ENDPOINT}`}> */}
            <Button edge="start" className={classes.menuButton} color="inherit" aria-label="menu" style={{ flexGox: 1 }}>
              <img src={domain?.logo} alt="Getwork" width="70px" height="60px" />
            </Button>
            {/* </a> */}

            {localStorage.getItem("user_type") ? (
              <AvatarMenu />
            ) : (
              <div className={classes.sectionDesktop}>
                {/* <IconButton>
                  <SearchIcon color="inherit" />
                </IconButton> */}
                <MenuItem style={{ color: "inherit", backgroundColor: "inherit" }}>
                  <RegisterBtnDomain />
                </MenuItem>
                <MenuItem
                  style={{
                    paddingRight: "3px",
                    color: "inherit",
                    backgroundColor: "inherit",
                  }}
                >
                  <LogInBtnDomain />
                </MenuItem>

                {/* </div> */}
              </div>
            )}
          </Hidden>
          <Hidden mdUp>
            <SideDrawer navLinks={localStorage.getItem("gw_token") ? navLinksLoggedIn : navLinks} />
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default DomainNavbar;

import React from 'react';
import Axios from 'axios';
import './RatingExperience.css';
import { BackendBaseApi } from "../../../constants/constants";
import PropTypes from 'prop-types';
import { Button, Box, withStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const StyledRating = withStyles({
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

function RatingExperience({ alert, jobID }) {

    const [value, setValue] = React.useState(null);
    const [isFeedback, setIsFeedback] = React.useState(false);

    const submitFeedback = (e) => {
        e.preventDefault();
        if(isFeedback) {
          alert.show('Your Feedback has already been recorded!');
          return;
        }
        if(!localStorage.getItem('user_id')) { alert.error('You must be logged in to submit feedback!'); return; }
        if(!value) { alert.error('Select a feedback!'); return; }
        Axios
        .post(BackendBaseApi.NIYUKTI + "job/student/rating/", 
            {
                job_id: jobID,
                user_id: localStorage.getItem('user_id'),
                rating_experience: { rating_value: value }
            }
        )
        .then((res) => {
            if (!res.data.success) {
                alert.error(res.data.error || 'Error Occured');
            }
            else{
                alert.success(res.data.data.message);
                setIsFeedback(true);
            }
        })
        .catch((err) => {
            alert.error("Error occured!");
        });
    }

    return (
        jobID &&
        <div id="rating_experience_box">
            <b>Rate Your Job Application Experience</b>
            <Box component="fieldset" my={1} borderColor="transparent">
                <Rating
                    name="customized-icons"
                    value={value}
                    onChange={(event, newValue) => { setValue(newValue) }}
                    getLabelText={(value) => customIcons[value].label}
                    IconContainerComponent={IconContainer}
                />
            </Box>
            <Button 
                variant="contained"
                color={ value ? "primary" : '' }
                disabled={ value ? false : true }
                onClick={e=> { value && submitFeedback(e) } }
                style={{borderRadius:'2rem'}}
            >
            Submit Feedback
            </Button>
        </div>
    )
}

export default RatingExperience

import React from "react";
import { Paper, makeStyles, Typography, Chip } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
  },
  job: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  skill: {
    color: "#007bff",
    backgroundColor: "#e8f3ff",
    borderColor: "#007bff",
  },
  chip: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

function Hobbies({ data }) {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">Hobbies</Typography>
        <br />
        <div className={classes.chip}>
          {data?.student_user_details?.hobbies?.map((item) => (
            <>
              <Chip label={item} variant="outlined" className={classes.skill} />
            </>
          ))}
        </div>
        <br />
      </Paper>
    </>
  );
}

export default Hobbies;

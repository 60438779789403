import { Button, Typography, Tooltip, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import View from "../Table/View";
import moment from "moment";
import React from "react";

export const columnsOpenJobs = [
  {
    field: "job_title",
    headerName: "Job Title",
    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/public/job/${
                params?.row?.hiring_type === "OFF CAMPUS"
                  ? "offcampus"
                  : "oncampus"
              }/${params?.row?.id}`}
              style={{ textDecoration: "none", display: "contents" }}
              target="_blank"
            >
              {" "}
              <Tooltip title={params.row.job_title} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.job_title}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
    ),
  },

  {
    field: "job_type_name",
    // type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          cursor: "pointer",
        }}
      >
        <Tooltip title={params.row.job_type_name} placement="right">
          <Typography noWrap style={{ fontSize: "14px", cursor: "pointer" }}>
            {params.row.job_type_name}
          </Typography>
        </Tooltip>
      </span>
    ),
  },
  {
    field: "hiring_type",

    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Hiring Type{" "}
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.hiring_type}
        </span>
      </>
    ),
  },
  {
    field: "ctc_display",
    // type: "text",
    sortable: false,
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.ctc_display}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    sortable: false,
    width: 160,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>
        Stipend (Monthly)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "11px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },
  {
    field: "applicant_count",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>
        Applicants
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        {" "}
        <Link
          to={`/company/manage-job/?job_id=${btoa(params?.row?.id)}`}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" style={{ fontSize: "14px" }}>
            {params.row.applicant_count}
          </Typography>
        </Link>
      </span>
    ),
  },
  {
    field: "apply_end_date ",
    // type: "text",
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "7px" }}>
        Apply by Date{" "}
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.apply_end_date?.slice(0, 10)}
      </span>
    ),
  },
  {
    field: "posted_on",
    headerName: "Created",
    type: "date",
    width: 155,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "8px" }}>
        Posted On Date{" "}
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.posted_on}
      </span>
    ),
  },
  {
    field: "button",
    headerName: "",
    type: "button",
    sortable: false,
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>Actions</strong>
    ),
    renderCell: (cellValues) => {
      return (
        <>
          <div style={{ display: "flex", margin: "auto" }}>
            <View id={cellValues?.row?.id} />
          </div>
        </>
      );
    },
  },
];

export const columnsClosedJobs = [
  {
    field: "job_title",
    headerName: "Job Title",
    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/public/job/${
                params?.row?.hiring_type === "OFF CAMPUS"
                  ? "offcampus"
                  : "oncampus"
              }/${params?.row?.id}`}
              style={{ textDecoration: "none", display: "contents" }}
              target="_blank"
            >
              {" "}
              <Tooltip title={params.row.job_title} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.job_title}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
    ),
  },

  {
    field: "job_type_name",
    // type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_type_name}
      </span>
    ),
  },
  {
    field: "hiring_type",

    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Hiring Type{" "}
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.hiring_type}
        </span>
      </>
    ),
  },
  {
    field: "ctc_display",
    // type: "text",
    sortable: false,
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.ctc_display}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    sortable: false,
    width: 190,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>
        Stipend (Monthly)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },
  {
    field: "hired_count",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Hired</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        {" "}
        <Link
          to={`/track/?job_id=${params?.row?.id}`}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" style={{ fontSize: "14px" }}>
            {params.row.hired_count}
          </Typography>
        </Link>
      </span>
    ),
  },
  {
    field: "apply_end_date ",
    // type: "text",
    width: 165,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Apply by Date{" "}
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.apply_end_date?.slice(0, 10)}
      </span>
    ),
  },
  {
    field: "posted_on",
    headerName: "Created",
    type: "date",
    width: 165,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Posted On Date{" "}
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.posted_on}
      </span>
    ),
  },
  {
    field: "button",
    headerName: "",
    sortable: false,
    type: "button",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>Actions</strong>
    ),
    renderCell: (cellValues) => {
      return <View id={cellValues?.row?.id} />;
    },
  },
];

export const columnsDraftedJobs = [
  {
    field: "job_title",
    headerName: "Job Title",
    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/public/job/${
                params?.row?.hiring_type === "OFF CAMPUS"
                  ? "offcampus"
                  : "oncampus"
              }/${params?.row?.id}`}
              style={{ textDecoration: "none", display: "contents" }}
              target="_blank"
            >
              {" "}
              <Tooltip title={params.row.job_title} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.job_title}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
    ),
  },

  {
    field: "job_type_name",
    // type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_type_name}
      </span>
    ),
  },
  {
    field: "hiring_type",

    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Hiring Type{" "}
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.hiring_type}
        </span>
      </>
    ),
  },
  {
    field: "ctc_display",
    // type: "text",
    sortable: false,
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.ctc_display}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    sortable: false,
    width: 190,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>
        Stipend (Monthly)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },

  {
    field: "apply_end_date ",
    // type: "text",
    width: 155,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Apply by Date{" "}
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.apply_end_date?.slice(0, 10)}
      </span>
    ),
  },
  {
    field: "posted_on",
    headerName: "Created",
    type: "date",
    width: 165,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Created On Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.posted_on}
      </span>
    ),
  },
];

export const columnsScheduledJobs = [
  {
    field: "job_title",
    headerName: "Job Title",
    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/public/job/${
                params?.row?.hiring_type === "OFF CAMPUS"
                  ? "offcampus"
                  : "oncampus"
              }/${params?.row?.id}`}
              style={{ textDecoration: "none", display: "contents" }}
              target="_blank"
            >
              {" "}
              <Tooltip title={params.row.job_title} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.job_title}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
    ),
  },

  {
    field: "job_type_name",
    // type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_type_name}
      </span>
    ),
  },
  {
    field: "hiring_type",

    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Hiring Type{" "}
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.hiring_type}
        </span>
      </>
    ),
  },
  {
    field: "ctc_display",
    // type: "text",
    sortable: false,
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.ctc_display}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    sortable: false,
    width: 190,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", margin: "auto" }}>
        Stipend (Monthly)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },
  /*   {
    field: "applicant_count",
    // type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>
        Applicants
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        {" "}
        <Typography color="primary" style={{ fontSize: "14px" }}>
          {params.row.applicant_count}
        </Typography>
      </span>
    ),
  }, */
  {
    field: "live_in ",
    // type: "text",
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "34px" }}>Live In </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.live_in}
      </span>
    ),
  },
  {
    field: "apply_end_date ",
    // type: "text",
    width: 155,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Apply by Date{" "}
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.apply_end_date?.slice(0, 10)}
      </span>
    ),
  },
  {
    field: "posted_on",
    headerName: "Created",
    type: "date",
    width: 155,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Posted On Date{" "}
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.posted_on}
      </span>
    ),
  },
];

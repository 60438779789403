import React, { useEffect, useState } from "react";
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  ListItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InterviewScheduling from "../../Dialog/InterviewScheduling";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoveStep from "../../Dialog/MoveStep";
import Rejected from "../../Dialog/Rejected";
import AssignMember from "../Dropdown/AssignMember";
import { CSVLink } from "react-csv";
import AddCommentDialog from "../../Dialog/AddCommentDialog";
import { BackendBaseApi } from "../../../../constants/constants";
import fetchApi from "../../../apiCall/fetchApi";
import { useAlert } from "react-alert";
import { getProfileUnlockInfo } from "../../../../GlobalComponents/CreditBoard/action";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  btn: {
    boxShadow: "none",
    borderRadius: "30px",
    marginRight: "25px",
    height: "40px",
    display: "flex",
    fontWeight: "500",
  },
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //   backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        ///  color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function TableAction({
  selectedRow,
  setSelectedRow,
  moveNextArray,
  totalApplicantList,
  callBackMainApplicantApi,
  activeStatusObject,
  selectedJob,
  setApplicantData,
  applicantData,
  mainApplicantData,
  isPublic,
  sharedPublicTrackData,
  errorCallback,
  getNavBarData,
  setTotalApplicantList,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const [openMove, setOpenMove] = React.useState(false);
  const [openAddComment, setOpenAddComment] = React.useState(false);
  const [unlockedData, setUnlockedData] = useState(null);
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpenMove = () => {
    setOpenMove(true);
  };

  const handleCloseMove = () => {
    setOpenMove(false);
  };

  const handleClickOpenAddComment = () => {
    setOpenAddComment(true);
  };

  const handleCloseAddComment = () => {
    setOpenAddComment(false);
  };

  const [openReject, setOpenReject] = React.useState(false);

  const handleClickOpenReject = () => {
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const handleUnlock = () => {
    if (
      selectedRow?.details?.length === 1 &&
      selectedRow?.details?.some((item) => !item?.is_locked)
    ) {
      alert.error("Profile already unlocked");
      return;
    }
    let temp_arr = [];
    selectedRow.details.forEach((item) => {
      if (item?.is_locked) {
        temp_arr.push(item?.id);
      }
    });
    setUnlockedData(null);
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/unlock_applicant/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        job_applicant_ids: temp_arr,
        company_id: JSON.parse(localStorage.getItem("company")).company,
      }),
      setResponse: setUnlockedData,
      setLoading: setLoadingUnlock,
      callBackFn: helper,
      errorCallback: errorCallback,
    });
  };

  const helper = () => {
    dispatch(getProfileUnlockInfo(() => {}));
    callBackMainApplicantApi();
    setSelectedRow({
      details: [],
      selectedIds: [],
    });
  };

  useEffect(() => {
    if (unlockedData && unlockedData?.data?.data?.length > 0) {
      var arr = [...applicantData.data.results.applicant_data];

      unlockedData.data.data.forEach((item) => {
        arr.forEach((obj) => {
          if (item?.applicant_id === obj?.applicant_id) {
            obj.user_details.first_name = item?.user_detail?.first_name;
            obj.user_details.last_name = item?.user_detail?.last_name;
            obj.user_details.phone = item?.user_detail?.phone;
            obj.user_details.email = item?.user_detail?.email;
            obj.assignment = item?.user_detail?.assignment;
            obj.user_details.resume = item?.user_detail?.resume;
            obj.user_details.profile_url = item?.user_detail?.profile_url;
            obj.is_locked = false;
          }
        });
      });
      setApplicantData({
        ...applicantData,
        data: {
          ...applicantData.data,
          results: { ...applicantData?.data?.results, applicant_data: arr },
        },
      });
    }
  }, [unlockedData]);

  return (
    <>
      {selectedRow?.selectedIds?.length > 0 && (
        <div style={{ marginBottom: "20px", display: "flex" }}>
          {!isPublic && (
            <Button
              color="primary"
              variant="contained"
              style={{ minWidth: "130px" }}
              className={classes.btn}
              disabled={!selectedRow?.selectedIds?.length}
              onClick={() => {
                if (!loadingUnlock) handleUnlock();
              }}
            >
              {loadingUnlock ? (
                <CircularProgress size={15} style={{ color: "#fff" }} />
              ) : (
                "Unlock"
              )}
            </Button>
          )}
          {(!isPublic ||
            sharedPublicTrackData?.action_performed?.authority?.track) && (
            <>
              {activeStatusObject?.status_id !== 14 &&
                activeStatusObject?.status_id !== 18 && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      onClick={handleClickOpenMove}
                      disabled={
                        !selectedRow?.selectedIds?.length ||
                        selectedRow?.details?.some((item) => item.is_locked)
                      }
                    >
                      Move to Next Round
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      onClick={handleClickOpenReject}
                      disabled={
                        !selectedRow?.selectedIds?.length ||
                        selectedRow?.details?.some((item) => item.is_locked)
                      }
                    >
                      Reject
                    </Button>
                  </>
                )}
            </>
          )}

          {!isPublic && activeStatusObject?.status_id === 7 && (
            <InterviewScheduling
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              selectedJob={selectedJob}
              activeStatusObject={activeStatusObject}
            />
          )}
          {!isPublic &&
            activeStatusObject?.status_id !== 14 &&
            activeStatusObject?.status_id !== 18 && (
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                style={
                  activeStatusObject?.status_id === 7
                    ? { marginLeft: "25px" }
                    : {}
                }
                disabled={selectedRow?.selectedIds?.length !== 1}
                onClick={() => handleClickOpenAddComment()}
              >
                Add Comment
              </Button>
            )}
          {selectedRow?.selectedIds?.length > 0 && (
            <>
              {!isPublic && (
                <div>
                  <AssignMember
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    activeStatusObject={activeStatusObject}
                  />
                </div>
              )}
              <MoveStep
                open={openMove}
                handleClickOpen={handleClickOpenMove}
                handleClose={handleCloseMove}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                moveNextArray={moveNextArray}
                callBackMainApplicantApi={callBackMainApplicantApi}
                isPublic={isPublic}
                getNavBarData={getNavBarData}
                totalApplicantList={totalApplicantList}
                setTotalApplicantList={setTotalApplicantList}
              />

              <Rejected
                open={openReject}
                handleClickOpen={handleClickOpenReject}
                handleClose={handleCloseReject}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                callBackMainApplicantApi={callBackMainApplicantApi}
                isPublic={isPublic}
                getNavBarData={getNavBarData}
                totalApplicantList={totalApplicantList}
                setTotalApplicantList={setTotalApplicantList}
              />
            </>
          )}

          <AddCommentDialog
            open={openAddComment}
            handleClickOpen={handleClickOpenAddComment}
            handleClose={handleCloseAddComment}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            activeStatusObject={activeStatusObject}
          />
        </div>
      )}
    </>
  );
}

export default TableAction;

import React, {useState, useEffect} from 'react';
import {
    makeStyles,
    Box,
    Grid,
    Paper,
    Typography
  } from "@material-ui/core";
import wallet_icon from "../../../assets/gif/welcome_pop.gif";
import new_wallet from "../../../assets/gif/new_wallet.gif";
import Loader from "../../../bundles/common/components/UI/Loader";
import logo from "../../../assets/img/getwork-logo.png";
import ActivateCreditButton from './ActivateCreditButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Axios from "axios";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../constants/constants";
import { useDispatch } from "react-redux";
import { getCreditInfo } from "../../../GlobalComponents/CreditBoard/action";

  const useStyles = makeStyles({
    paper2: {
      textAlign: "center",
    //  height: "270px",
   
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      backgroundColor: "#ffffff",
      borderRadius: "30px",
  },
  Button: {
    borderRadius: "30px",
    marginBottom: "12px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#32B2F9",
  },
  });


function ActivateCreditModal({isFromSideBar = false, openModal=true, setOpenModal = (() => {}) }) {
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(isFromSideBar);
    const [callApi, setCallApi] = useState(isFromSideBar);
    const [creditActivated, setCreditActivated] = useState(false);
    const [disableBackdropClick, setDisableBackdropClick] = useState(false);
    const dispatch = useDispatch();
    const alert = useAlert();

    const handleClose = () => {
      setOpenModal(false);
      };

    useEffect(() => {
      if(callApi && callApi !== 'undefined'){
        setDisableBackdropClick(true);
        Axios.patch(
          BackendBaseApi.PRAVESH + `api/common/user_credit_data/${localStorage.getItem("user_type_id")}?op=activate`,
          {credit_status: "ACTIVE"},
          {
            headers: {
              Authorization: "Token " + localStorage.getItem("gw_token"),
            },
          }
        )
          .then((res) => {
            if(res.data.success){
            setCreditActivated(true);
            dispatch(getCreditInfo( () => {}));
            }
            else {
              alert.error(res.error);
              handleClose();
            }
            setShowLoader(false);
            setDisableBackdropClick(false);
          })
          .catch((err) => {
            handleClose();
            setShowLoader(false);
            setDisableBackdropClick(false);
            alert.error("There was error in activating your credits");
          }); 
      }
    }, [callApi])

return (
<Dialog
  open={openModal}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  disableBackdropClick={disableBackdropClick ? "true" : undefined}
  PaperProps={{
    style: {
      borderRadius: 30,
    },
  }}
>
<Box
          padding="3%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ overflowX: "hidden" }}
        >
          <div className={classes.root}></div>
         <Grid container spacing={3}>
        {showLoader && !creditActivated ? 
        <Loader /> :
        <>
        <Grid item xs={6}>
      
    
          <img
             src={creditActivated ? new_wallet : wallet_icon}
             alt="Notification"
            height="280px"/>
        </Grid>

        <Grid item xs={6}>
        <Paper className={classes.paper2}>
        <DialogContent>
          <DialogContentText>
          <img
             src={logo}
             alt="Notification"
            height="40px"/>

            {creditActivated ?
            
            <>
             <Typography variant="h4" gutterBottom>
            Thank You!
              </Typography>
              <p>
                You have successfully activated your GetWork Credit's.
              </p>
            </>
            
            : 
            <>
            <Typography variant="h4" gutterBottom>
            Welcome To GetWork  
              </Typography>

              <p>
              Your GetWork Credits are inactive now. Please activate it first to start using various GetWork services.
              </p>
              
                <div onClick = {() => {setShowLoader(true); setCallApi(true);}} >
                <ActivateCreditButton />  
                </div>
                </>
              }
           
                      {/* <Thankyou /> */}

                    
          
            
          </DialogContentText>
        </DialogContent>
      
        </Paper>
        </Grid>
        </>}
      </Grid>
      
       </Box>
</Dialog>
)
}

export default ActivateCreditModal;
import {
  Button, createStyles, makeStyles
} from "@material-ui/core";
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentLoading from "../../assets/payment-loading.gif";
import { getProfileUnlockInfo } from "./action";
import {useHistory}  from "react-router-dom";

const ProfileUnlock = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
//   const creditInfo = useSelector((state) => state?.CreditInfo?.creditInfo);
  const profileUnlockData = useSelector((state) => state?.CreditInfo?.profileUnlockInfo);
  const [loading, setLoading] = useState(false);
  const companyUserDetails = useSelector((state) => state?.CompanyUserDetails?.companyUserDetails);
  const history = useHistory();

  useEffect(() => {
    if (
      companyUserDetails && (!profileUnlockData || Object.keys(profileUnlockData).length === 0)
    ) {
    //   dispatch(getCreditInfo(setLoading));
      dispatch(getProfileUnlockInfo(setLoading));
    }
  }, [companyUserDetails]);

  return (
    <Button
      startIcon={<OfflineBoltOutlinedIcon />}
      variant="text"
      color="primary"
    //   endIcon={
    //     <img
    //       src={Refresh}
    //       alt="loading"
    //       width="25px"
    //       height="auto"
    //       onClick={() => {
    //         // dispatch(getCreditInfo(setLoading))
    //         dispatch(getProfileUnlockInfo(setLoading))
    //       }
    //       }
    //     />
    //   }
      style={{
        height: "40px",
        borderRadius: "30px",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "flex",
        boxShadow: "none",
        backgroundColor: "#fff",
        border: "1px solid #C4C4C4",
        marginRight: "30px",

      }}
      onClick={() => {
          history.push("/company/subscription");
      }}
    >
      {loading ? (
        <img src={PaymentLoading} alt="loading" width="20px" height="auto" style={{ marginLeft: "5px" }} />
      ) : (
        <span>Unlocks Available: {profileUnlockData ? profileUnlockData?.available_credits : "..."}</span>
      )}
    </Button>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    amount: {
      marginLeft: 7,
    },
    iconButton: {
      "&:focus": {
        outline: "none",
      },
    },
    mainBox: {
      display: "flex",
      alignItems: "center",
      padding: "5px 12px",

      borderRadius: "5px",
      justifyContent: "space-between",

      borderRadius: "10px",

      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
    },
  })
);

export default ProfileUnlock;

import React from "react";
import "./JobApplication.css";
import { Box } from "@material-ui/core";
import SignInSection from "../SignIn/SignInSection";
import RegisterFormJobApply from "../SignIn/RegisterFormJobApply";

function JobApplication({ jobID, setNavValue, setApplySuccessful, isUserApplied, setIsUserApplied }) {
  const [initEmail, setInitEmail] = React.useState("");
  const [initFirstName, setInitFirstName] = React.useState("");
  const [initLastName, setInitLastName] = React.useState("");
  const [initPhone, setInitPhone] = React.useState("");
  const [toggleExpand, setToggleExpand] = React.useState(false);

  const signinRef = React.useRef(null);

  const executeScroll = () => {
    signinRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div id="application_box" ref={signinRef}>
      <SignInSection
        jobID={jobID}
        initEmail={initEmail}
        setInitEmail={setInitEmail}
        initFirstName={initFirstName}
        setInitFirstName={setInitFirstName}
        initLastName={initLastName}
        setInitLastName={setInitLastName}
        initPhone={initPhone}
        setInitPhone={setInitPhone}
        setNavValue={setNavValue}
        setApplySuccessful={setApplySuccessful}
        isUserApplied={isUserApplied}
        setIsUserApplied={setIsUserApplied}
        toggleExpand={toggleExpand}
        setToggleExpand={setToggleExpand}
      />
      <div id="register_below_text">
        <span>New to GetWork? Register below</span>
      </div>
      <RegisterFormJobApply
        jobID={jobID}
        initEmail={initEmail}
        setInitEmail={setInitEmail}
        initFirstName={initFirstName}
        setInitFirstName={setInitFirstName}
        initLastName={initLastName}
        setInitLastName={setInitLastName}
        initPhone={initPhone}
        setInitPhone={setInitPhone}
        setNavValue={setNavValue}
        setApplySuccessful={setApplySuccessful}
        isUserApplied={isUserApplied}
        setIsUserApplied={setIsUserApplied}
        executeScroll={executeScroll}
        setToggleExpand={setToggleExpand}
      />
    </div>
  );
}

export default JobApplication;

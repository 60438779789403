import React, { useEffect, useState } from "react";
import { Box, createStyles, Grid, makeStyles } from "@material-ui/core";
import Filter from "../../components/company/Filter/Filter";
import Filter2 from "../../components/company/Filter/Filter2";
import { BackendBaseApi } from "../../constants/constants";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import ApplicantsCards from "../../components/company/ApplicantsCards/ApplicantsCards";
import No_data from "../../assets/No_data.gif";
import warning from "../../assets/warning.gif";
import ApplicantsProfile from "../../components/company/ApplicantsProfile.js/ApplicantsProfile";
import { useAlert } from "react-alert";
import sandclock from "../../assets/sandclock.gif";
import { JobCardSkeletonNew } from "../../assets/skeletons/JobCardSkeleton";
export default function Track() {
  const alert = useAlert();
  const [allCompanies, setAllCompanies] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [onlyClosed, setOnlyClosed] = useState(false);
  const [campusType, setCampusType] = useState(false);
  const [jobProfileObject, setJobProfileObject] = useState({});
  const [allJobProfiles, setAllJobProfiles] = useState([]);
  const [jobProfileName, setJobProfileName] = useState();
  const [trackData, setTrackData] = React.useState([]);
  const [filterList, setFilterList] = React.useState({});
  const [filterObject, setFilterObject] = React.useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [loader, setLoader] = useState(false);
  const [filterArray, setFilterArray] = useState([]);
  const [filterData, setFilterData] = useState(false);
  const [fillDataComplete, setFillDataComplete] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [selectedCard, setSelectedCard] = useState(0);
  const [end, setEnd] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const location = useLocation();
  const [isApiCall, setIsApiCall] = useState(false);
  const [oneApiCall, setOneApiCall] = useState(false);
  const [errorIcon, setErrorIcon] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [moveButtonClicked, setMoveButtonClicked] = useState(false);
  const [statusChanging, setStatusChanging] = useState(false);
  const [rejectButtonClicked, setRejectButtonClicked] = useState(false);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const classes = useStyles();
  const checkString = () => {
    if (searchString) return `&${searchString}`;
    else return "";
  };

  const appendParam = () => {
    if (selectedJobType === "All" || selectedJobType === "") {
      return "";
    } else return `&job_type_id=${btoa(selectedJobType)}`;
  };

  const checkOnlyClosed = () => {
    if (!onlyClosed) {
      return `&job_status=${btoa("OPEN")}`;
    } else {
      return `&job_status=${btoa("CLOSE")}`;
    }
  };

  const checkCampusType = () => {
    if (!campusType) {
      return `&hiring_type=${btoa("OFF CAMPUS")}`;
    } else {
      return `&hiring_type=${btoa("ON CAMPUS")}`;
    }
  };

  const checkCollege = () => {
    if (selectedCompany === "All" || selectedCompany === "") {
      return "";
    } else {
      return `&job_college_id=${btoa(selectedCompany)}`;
    }
  };
  const checkJobProfile = () => {
    if (jobProfile === "" || jobProfile === "All") {
      return "";
    } else {
      return `&job_id=${btoa(jobProfile)}`;
    }
  };

  const appendParam1 = () => {
    if (selectedJobType === "All" || selectedJobType === "") {
      return "";
    } else return `&job_type_id:${btoa(selectedJobType)}`;
  };

  const checkOnlyClosed1 = () => {
    if (!onlyClosed) {
      return `&job_status:${btoa("OPEN")}`;
    } else {
      return `&job_status:${btoa("CLOSE")}`;
    }
  };

  const checkCampusType1 = () => {
    if (!campusType) {
      return `&hiring_type:${btoa("OFF CAMPUS")}`;
    } else {
      return `&hiring_type:${btoa("ON CAMPUS")}`;
    }
  };

  const checkCollege1 = () => {
    if (selectedCompany === "All" || selectedCompany === "") {
      return "";
    } else {
      return `&job_college_id:${btoa(selectedCompany)}`;
    }
  };
  const checkJobProfile1 = () => {
    if (jobProfile === "" || jobProfile === "All") {
      return "";
    } else {
      return `&job_id:${btoa(jobProfile)}`;
    }
  };

  useEffect(() => {
    var params_new = getSearchParameters();
    params_new &&
      Object.keys(params_new).length > 0 &&
      Object.keys(params_new).map((item) => {
        // setDiffStates(item, params_new[item]);
        if (item === "job_college_id") {
          setSelectedCompany(parseInt(window.atob(params_new[item])));
        }
        if (item === "job_type_id") {
          setSelectedJobType(parseInt(window.atob(params_new[item])));
        }
        if (item === "job_id") {
          setJobProfile(parseInt(window.atob(params_new[item])));
          setJobProfileName(parseInt(window.atob(params_new[item])));
        }
        if (item === "hiring_type") {
          if (window.atob(params_new[item]) === "ON CAMPUS")
            setCampusType(true);
          else setCampusType(false);
        }
        if (item === "job_status") {
          if (window.atob(params_new[item]) === "OPEN") setOnlyClosed(false);
          else setOnlyClosed(true);
        }
      });
    setFillDataComplete(true);
    // getTrackData(1);
  }, []);

  useEffect(() => {
    if (fillDataComplete) {
      getTrackData(1, 2);
      getFilters();
      getJobProfiles();
    }
  }, [fillDataComplete]);

  function getSearchParameters() {
    var prmstr = location.search.substr(1);
    return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
  }

  function transformToAssocArray(prmstr) {
    var params = {};
    var prmarr = prmstr.split("&");
    for (var i = 0; i < prmarr.length; i++) {
      var tmparr = prmarr[i].split(":");
      params[tmparr[0]] = tmparr[1];
    }
    return params;
  }

  const getJobProfiles = async () => {
    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/job/list/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}&status_id=MQ==${appendParam()}${checkOnlyClosed()}${checkCampusType()}${checkCollege()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });

    let profiles = [];
    // if (res.data.data.length > 0) {
    //   let obj = { job_id: "All", job_title: "All" };
    //   setAllJobProfiles([obj, ...res.data.data]);
    // } else {
    setAllJobProfiles(res.data.data);
    // }
  };
  const getJobTypes = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch(
      (err) => {
        throw err;
      }
    );
    let obj = { id: "All", job_type: "All" };
    setJobType([obj, ...res.data.data]);
  };

  const getCompanies = async () => {
    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/job-college/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }&job_id=${jobProfileName}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    let obj = { name: "All", id: "All" };
    setAllCompanies([obj, ...res.data.data]);
  };

  useEffect(() => {
    // getJobProfiles();
    getJobTypes();
  }, []);
  useEffect(() => {
    if (jobProfileName) getCompanies();
  }, [jobProfileName]);

  const getTrackData = (flag, value) => {
    if (flag === 1) setLoader(true);

    setPaginationLoader(true);

    const urlNew = nextUrl
      ? decodeURI(nextUrl)
      : BackendBaseApi.NIYUKTI +
        `job/company/track/applicants/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}&status_id=MQ==${checkJobProfile()}${appendParam()}${checkOnlyClosed()}${checkCampusType()}${checkCollege()}${
          value === 9 ? "" : checkString()
        }`;
    Axios.get(urlNew, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success && !res.data?.data?.message) {
          setIsApiCall(true);
          if (flag === 1) setLoader(true);

          setPaginationLoader(true);

          setErrorIcon(No_data);
          setErrorMessage("No Applicant to display");
          setOneApiCall(true);

          setJobProfileName(parseInt(res.data.data.results.job_id));
          allJobProfiles.length > 0 &&
            allJobProfiles.map((item) => {
              if (item.job_id === parseInt(res.data.data.results.job_id))
                setJobProfileObject(item);
            });

          if (res.data.data.next) {
            setNextUrl(res.data.data.next);
          } else {
            setEnd(true);
          }
          let data = [...trackData, ...res.data.data.results.applicant_data];
          setTrackData(data);
          setCurrentData(res.data.data.results.applicant_data[0]);
          setSelectedCard(
            data.indexOf(res.data.data.results.applicant_data[0])
          );
        } else if (res.data.success && res.data?.data?.message) {
          setOneApiCall(true);
          setErrorIcon(No_data);
          setErrorMessage("No recent job with this filter");
        } else {
          setOneApiCall(true);
          setErrorIcon(warning);
          setErrorMessage("Some Error Occurred");
        }
        setLoader(false);
        setPaginationLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setOneApiCall(true);

        setLoader(false);
        setPaginationLoader(false);
        setErrorIcon(warning);
        setErrorMessage("Some Error Occurred");
      });
    setIsApiCall(false);
  };

  const getFilters = () => {
    setFilterLoader(true);
    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/track/filters/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}&status_id=MQ==${checkJobProfile()}${appendParam()}${checkOnlyClosed()}${checkCampusType()}${checkCollege()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          Object.keys(res.data.data).forEach((item) => {
            res.data.data[item].selected_data = [];
          });
          setFilterList(res.data.data);
          var temp = {};

          Object.keys(res.data.data).forEach((head) => {
            temp[res.data.data[head].param_name] = [];
          });

          Object.keys(res.data.data).map((item) => {
            if (res.data.data[item].data.length > 0) setFilterData(true);
          });

          setFilterObject(temp);
        }
        setFilterLoader(false);
      })
      .catch((err) => {
        console.log("Some Error Occured");
        setFilterLoader(false);
      });
  };

  const moveToNextStep = () => {
    var selectedApplicantIds = [];
    selectedApplicantIds.push(currentData.applicant_id);
    var data = [];
    data = [
      {
        id: currentData.applicant_id,
        round: currentData.round,
        status: moveButtonClicked ? 7 : 14,
        user_id: currentData.user_id,
        job_id: currentData.job_id,
        company_id: parseInt(
          JSON.parse(localStorage.getItem("company")).company
        ),
      },
    ];

    Axios.post(
      BackendBaseApi.NIYUKTI + "job/company/status_update/",
      {
        student_data: data,
        applicant_feedback: {
          user_id: parseInt(localStorage.getItem("user_id")),
          status_id: moveButtonClicked ? 7 : 14,
          status_name: moveButtonClicked ? currentData.round_name : "Rejected",
          feedback_id: null,
          feedback_name: null,
        },
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setStatusChanging(true);
          var myArray = [];
          let temp = trackData.filter((data) =>
            selectedApplicantIds.includes(data.applicant_id)
          );
          var index = trackData.indexOf(temp[0]);
          myArray = trackData.filter((item) => !temp.includes(item));
          if (moveButtonClicked)
            alert.success("Applicant(s) shortlisted successfully ");
          else alert.success("Applicant(s) rejected successfully ");

          if (index === trackData.length - 1) {
            setCurrentData(myArray[0]);
            setSelectedCard(0);
          } else {
            setCurrentData(myArray[index]);
            setSelectedCard(index);
          }

          setTrackData(myArray);

          setMoveButtonClicked(false);

          setRejectButtonClicked(false);
        } else alert.error("Applicant was not moved. Some error occurred");
        setStatusChanging(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.history.pushState(
      {},
      null,
      `/company/job-applicants/?${checkJobProfile1()}${appendParam1()}${checkOnlyClosed1()}${checkCampusType1()}${checkCollege1()}`
    );
  }, [
    jobProfile,
    jobProfileName,
    selectedCompany,
    selectedJobType,
    onlyClosed,
    campusType,
  ]);

  useEffect(() => {
    if (oneApiCall) {
      getTrackData(1, 9);
      setFilterData(false);

      getFilters();
    }
  }, [selectedCompany, selectedJobType, jobProfile, onlyClosed, campusType]);

  useEffect(() => {
    if (oneApiCall) getJobProfiles();
  }, [selectedCompany, selectedJobType, onlyClosed, campusType]);

  useEffect(() => {
    if (oneApiCall) getTrackData(1, 2);
  }, [searchString]);

  useEffect(() => {
    if (moveButtonClicked) {
      moveToNextStep();
    }
  }, [moveButtonClicked]);

  useEffect(() => {
    if (rejectButtonClicked) {
      moveToNextStep();
    }
  }, [rejectButtonClicked]);

  return (
    <>
      <div style={{ width: "93.8%", marginLeft: "40px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ position: "fixed", zIndex: "1", height: "75vh" }}>
              <Filter
                jobTypes={jobType}
                profiles={allJobProfiles}
                allCompanies={allCompanies}
                onlyClosed={onlyClosed}
                setOnlyClosed={setOnlyClosed}
                campusType={campusType}
                setCampusType={setCampusType}
                setSelectedCompany={setSelectedCompany}
                setSelectedJobType={setSelectedJobType}
                setJobProfile={setJobProfile}
                setJobProfileName={setJobProfileName}
                jobProfileName={jobProfileName}
                jobProfile={jobProfile}
                selectedJobType={selectedJobType}
                selectedCompany={selectedCompany}
                setTrackData={setTrackData}
                setJobProfileObject={setJobProfileObject}
                applicants={true}
                setEnd={setEnd}
                setNextUrl={setNextUrl}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <ApplicantsCards
              trackData={trackData}
              setCurrentApplicant={setCurrentData}
              setSelectedCard={setSelectedCard}
              end={end}
              setEnd={setEnd}
              nextUrl={nextUrl}
              setNextUrl={setNextUrl}
              isApiCall={isApiCall}
              getTrackData={getTrackData}
              selectedCard={selectedCard}
              loader={loader}
              errorMessage={errorMessage}
              paginationLoader={paginationLoader}
            />
          </Grid>
          <Grid item xs={6} className={classes.applicantsBox}>
            {loader ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 100,
                }}
              >
                <img src={sandclock} alt="" height="120px" />
              </Box>
            ) : trackData?.length > 0 ? (
              <ApplicantsProfile
                currentData={currentData}
                applicantsPanel={true}
                setMoveButtonClicked={setMoveButtonClicked}
                setRejectButtonClicked={setRejectButtonClicked}
                changeStatus={statusChanging}
              />
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{ marginTop: 150 }}
                >
                  <img src={errorIcon} alt="" height="250px" />
                </Box>
                <div>
                  <p align="center" style={{ fontSize: 14, color: "#6a6a6a" }}>
                    {errorMessage}
                  </p>
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            <Filter2
              data={filterList}
              setData={setFilterList}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              loader={filterLoader}
              setLoader={setFilterLoader}
              filterArray={filterArray}
              setFilterArray={setFilterArray}
              setSearchString={setSearchString}
              filterData={filterData}
              trackLoader={loader}
              applicants={true}
              setEnd={setEnd}
              setNextUrl={setNextUrl}
              setTrackData={setTrackData}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    applicantsBox: {},

    "@media only screen and (max-width: 1850px)": {
      applicantsBox: {
        marginTop: 34,
      },
    },
    "@media only screen and (max-width: 1700px)": {
      applicantsBox: {
        marginTop: 20,
      },
    },
    "@media only screen and (max-width: 1600px)": {
      applicantsBox: {
        marginTop: 0,
      },
    },
  })
);

import { Box, createStyles, Grid, makeStyles, useTheme } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loader_gif from "../../../assets/images/loader_data.gif";
import {JobCardSkeletonListing} from "../../../assets/skeletons/JobCardSkeleton";
import Loader from "../../../bundles/common/components/UI/Loader";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import JobSharerDialog from "../../../components/common/JobSharer/JobSharerDialog";
import { BackendBaseApi } from "../../../constants/constants";
import { updateCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import FilterComponent from "./FilterComponent";
import RecommendedJobCard from "./RecommendedJobCard";

const RecommendedJob = () => {
  const classes = useStyles();
  const alert = useAlert();
  const [jobData, setJobData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [isApiCall, setIsApiCall] = useState(false);
  const [end, setEnd] = useState(false);
  const [nextUrl, setNextUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [firstApiCall, setFirstApiCall] = useState(false);
  const [presetFilters, setPresetFilters] = useState();
  const [checkBoxClicked, setCheckBoxClicked] = useState(false);
  const [serviceBond, setServiceBond] = useState(false);
  const [totalJobCount, setTotalJobCount] = useState(false);
  const [courseArray, setCourseArray] = useState([]);
  const [degreeCoursesComing, setDegreeCoursesComing] = useState(false);
  const [allData, setAllData] = useState([]);
  const [loaderDegree, setLoaderDegree] = useState();
  let token = localStorage.getItem("gw_token");
  const [search, setSearch] = useState("");
  const [buttonClickSearch, setButtonClickSearch] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [mainItem, setMainItem] = useState({});
  const [openJobSharer, setOpenJobSharer] = useState(false);
  const [acceptSuccessful, setAcceptSuccessful] = useState(false);
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const theme = useTheme();
  const dispatch = useDispatch();

  const firstCall = useRef(0);
  let locationSearch = useLocation().search;
  let params = (new URLSearchParams(locationSearch)).get('query');

  const handleClickOpen = (e, item) => {
    e.stopPropagation();
    setOpen(true);
    setMainItem(item);
  };
  const handleClose = () => {
    // setDataDegree([]);
    setOpen(false);
    // setMainItem({});
    if (acceptSuccessful) {
      setOpenJobSharer(true);
    } else {
      // setMainItem({});
    }
  };

  const handleJobSharerClose = (status) => {
    setOpenJobSharer(status);
    setMainItem({});
    setAcceptSuccessful(false);
  };

  const checkString = () => {
    if (searchString) return `&${searchString}`;
    else return "";
  };
  const checkServiceBond = () => {
    if (serviceBond) return `&service_bond=${1}`;
    else return `&service_bond=${0}`;
  };

  // const checkSearch = () => {
  //   if (buttonClickSearch) return `&search=${buttonClickSearch}`;
  //   else return ``;
  // };

  const checkSearch = () => {
    
    if (buttonClickSearch !== "" && buttonClickSearch !== undefined){ 
      firstCall.current = 1;
      return `&search=${buttonClickSearch}`;}
    else if(firstCall.current === 0 && params) {
      setSearch(params);
      console.log("jnfdkld", params);
      setButtonClickSearch(params);
      return `&search=${params}`;
    }
    else {
      firstCall.current = 1; 
      return ``;
    }
  };

  useEffect(() => {
    if(buttonClickSearch !== undefined && firstCall.current === 0){
      firstCall.current = 1;
      return;
    }
    if (buttonClickSearch !== undefined) {
      setSearch("");
      getData();
    }
  }, [buttonClickSearch]);

  const getData = () => {
    if (!nextUrl || end) if (!searchString && !firstApiCall) setLoading(true);
    const url = nextUrl
      ? nextUrl
      : // firstApiCall
        // ?
        `${BackendBaseApi.NIYUKTI}job/college_jobs/job_lake/?college_id=${localStorage.getItem(
          "college_id"
        )}&filtered_response=${true}${checkServiceBond()}${checkString()}${checkSearch()}`;
    Axios.get(url, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        setIsApiCall(true);
        setFirstApiCall(true);
        let data = [...jobData, ...res.data.data.results];
        setJobData(data);
        setLoading(false);
        setTotalJobCount(res.data.data.count);

        if (!firstApiCall) {
          if (res.data.data.results.length > 0) setPresetFilters(res.data.data.results[0].preset_filter);
          else {
            setPresetFilters({});
          }
        }

        if (res.data.data.next) {
          setNextUrl(res.data.data.next);
        } else {
          setEnd(true);
        }
      })
      .catch((err) => {
        alert.error("Error occured!");
        setLoading(false);
        setFirstApiCall(true);
      });
    setIsApiCall(false);
    setButtonClicked(true);
  };

  useEffect(() => {
    if (checkBoxClicked) getData();
  }, [searchString, serviceBond]);

  useEffect(() => {
    getData();
    getDegreeAndCourses();
  }, []);

  const getDegreeAndCourses = () => {
    // if (collegeList[clg_name].education_details.length === 0) {
    setLoaderDegree(true);
    Axios.get(BackendBaseApi.PRAVESH + "api/company/new_college_rank/education/?college_id=" + localStorage.getItem("college_id"), {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data?.success && res?.data.data) {
          res.data.data.forEach((item) => {
            item.degree_selected = false;
            item.specialization.forEach((spec) => {
              spec.course_selected = false;
            });
            item.select_all = false;
          });
          setAllData(res.data.data);
        } else {
          alert.error("Error Occured");
        }
        setLoaderDegree(false);
      })
      .catch((err) => {
        setLoaderDegree(false);
        alert.error("Error");
      });
    // }
  };

  const handleSubmit = (item) => {
    const data = {
      college_id: parseInt(localStorage.getItem("college_id")),
      job: parseInt(item.job.job_id),
      apply_start_date: item.job.apply_start_date,
      apply_end_date: item.job.apply_end_date,
      preferences: courseArray,
      is_approved: true,
      user_type_id: localStorage.getItem("user_type_id"),
      user_id: localStorage.getItem("user_id"),
      service_type: "RECOMMENDED_JOB_ACCEPTANCE",
      amount: creditInfo?.user_service?.find((item) => item.service_type === "RECOMMENDED_JOB_ACCEPTANCE")?.credits,
      transaction_type: "DEBIT",
    };
    Axios.post(BackendBaseApi.NIYUKTI + "job/college_jobs/accept/", data, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          alert.success("Accepted Successfully. You may view this job in 'Live Jobs' section now.");
          var arr = [...jobData];
          var indexx;
          arr.map((inn, new_index) => {
            if (inn.job.job_id === item.job.job_id) indexx = new_index;
          });
          arr.splice(indexx, 1);
          setJobData(arr);
          setTotalJobCount(arr.length);
          dispatch(updateCreditInfo(res.data.data.transaction_data));
          setAcceptSuccessful(true);
          handleClose();
          setOpenJobSharer(true);
        } else alert.error("Error");
      })
      .catch((err) => {
        alert.error("Error");
      });
  };

  return (
    <Box className={classes.OuterBox}>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <Grid container>
            <Grid item xs={9} sm={9}>
              <Box marginLeft="5%" marginRight="2%" marginTop="5%">
                <RecommendedJobCard
                  data={jobData}
                  setData={setJobData}
                  count={totalJobCount}
                  dataDegree={courseArray}
                  setDataDegree={setCourseArray}
                  loading={loaderDegree}
                  setLoading={setLoaderDegree}
                  degreeCoursesComing={degreeCoursesComing}
                  setDegreeCoursesComing={setDegreeCoursesComing}
                  allData={allData}
                  setAllData={setAllData}
                  handleSubmit={handleSubmit}
                  setEnd={setEnd}
                  setNextUrl={setNextUrl}
                  search={search}
                  setSearch={setSearch}
                  buttonClickSearch={buttonClickSearch}
                  setButtonClickSearch={setButtonClickSearch}
                  setButtonClicked={setButtonClicked}
                  getData={getData}
                  end={end}
                  isApiCall={isApiCall}
                  open={open}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  mainItem={mainItem}
                  setMainItem={setMainItem}
                />

                <br />
                {!isApiCall ? (
                  <>
                    <Box padding="2% 0% 0% 3%">
                      <JobCardSkeletonListing />
                    </Box>
                    <br />
                  </>
                ) : (
                  jobData.length === 0 && (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img src={loader_gif} alt="" height="300px" width="500px" />
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            textAlign: "center",
                            color: theme.palette.type === "dark" ? "#ffff" : "black",
                          }}
                        >
                          No Jobs Available. Please Change the selected Filters to see Jobs accordingly{" "}
                        </p>
                      </Box>
                    </>
                  )
                )}
                {!end && isApiCall && (
                  <Box display="flex" justifyContent="center" margin="auto" marginTop="-20px" marginBottom="30px">
                    <CommonButton variant="outlined" onClick={() => getData()}>
                      Load More Opportunities
                    </CommonButton>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={3} sm={3}>
              <Box className={classes.filterBox}>
                <FilterComponent
                  searchString={searchString}
                  setSearchString={setSearchString}
                  setEnd={setEnd}
                  setJobData={setJobData}
                  setNextUrl={setNextUrl}
                  nextUrl={nextUrl}
                  end={end}
                  presetFilters={presetFilters}
                  firstApiCall={firstApiCall}
                  checkBoxClicked={checkBoxClicked}
                  setCheckBoxClicked={setCheckBoxClicked}
                  serviceBond={serviceBond}
                  setServiceBond={setServiceBond}
                />
              </Box>
            </Grid>
          </Grid>
          {Object.keys(mainItem).length > 0 && openJobSharer && (
            <JobSharerDialog
              openModal={openJobSharer}
              setOpenModal={handleJobSharerClose}
              detail={{
                job_id: mainItem?.job?.job_id,
                company_name: mainItem.job.company ? mainItem.job.company.company_name : mainItem.job.company_details,
                job_title: mainItem?.job?.job_title,
                job_type: mainItem?.job?.job_type_name,
                apply_end_date: mainItem?.job?.apply_end_date,
                job_role: mainItem?.job?.job_role_name,
              }}
              page="RECOMMENDED"
            />
          )}
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    filterBox: {
      border: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "solid 2px #F1F1F1",

      position: "fixed",
      height: "87%",
      backgroundColor: theme.palette.background.default,
      //width: "260px",
      overflowY: "scroll",
      scrollbarWidth: "none",
      paddingBottom: "70px",
      // right: "20px",
      top: "92px",
      borderRadius: 30,
      WebkitBackdropFilter: "blur(30px)",
      backdropFilter: "blur(30px)",
      boxShadow: theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[25],
    },
    OuterBox: {
      backgroundColor: theme.palette.background.default,
    },
    "@media only screen and (max-width: 1600px)": {
      filterBox: {
        marginRight: "2%",
        minWidth: "272px",
      },
    },
  })
);

export default RecommendedJob;

import React, { useState } from "react";
import { IconButton, Button, AppBar, Toolbar, Menu, MenuItem, Avatar, Container, Box, Drawer, Badge, Typography, Grid } from "@material-ui/core";
// import Logo from "../../../assets/images/gw_sidebar_icon.png";
import RingNotification from "../../../assets/icon/ri_notification-2-line.png";
import ProfileIcon from "../../../assets/icon/gg_profile.png";
import UserRequest from "../../../assets/icon/user request.png";
import MessageIcon from "../../../assets/icon/bx_bx-chat.png";
import HomeIcon from "../../../assets/svg/Home.svg";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import "./navbar.css";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import TocIcon from "@material-ui/icons/Toc";
import CallIcon from "@material-ui/icons/Call";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { BreakpointProvider, Breakpoint } from "react-socks";
import { EndPointPrefix, BackendBaseApi, MENTORKART_ENDPOINT } from "../../../constants/constants";
import axios from "axios";
import { PhoneOutlined, HighlightOff } from "@material-ui/icons";
import { useDispatch, useSelector} from "react-redux";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import UploadIcon from "../../../assets/images/sidepanel/upload.png";
import Axios from "axios";
import Logo from "../../../assets/png/logo-long.png";
import { AndroidView, IOSView } from "react-device-detect";
import google from "../../../assets/new/google.svg";
import apple from "../../../assets/new/apple.svg";
import {subDomainLogoSelectorFn, instituteIdSelectorFn} from '../../../selector';

const useStyles = makeStyles((theme) => ({
  badge: {
    position: "relative",
    right: 15,
    top: 10,
  },
  blackBox: {
    backgroundColor: "black",
    padding: 10,
    color: "#fff",
    marginTop: -5,
  },
  colorSecondary: {
    background: "linear-gradient(90deg, #f58915 0%, #fd1d1d 51%, #1d3557 100%)",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  img: {
    width: 110,
  },
  logo: {
    width: '120px',
    height: '25px',
  },
  profileButton: {
    "&:focus": {
      outline: "none",
    },
  },
  drawerPaper: {
    width: "48%",
  },
  "@media screen and (max-width: 768px)": {
    logo: {
      display: "none",
    },
  },
}));

export default function StudentNavbar({hideDownload=false}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const data = JSON.parse(localStorage.getItem("user_details"));
  const institudeId = useSelector(instituteIdSelectorFn);
  const subDomainLogo = useSelector(subDomainLogoSelectorFn);

  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState(open);
  };

  const profilePic = data.profile_picture;
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const checkforSSO = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/sso/mentorkart/token", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res?.data?.success) {
          window.open(
            MENTORKART_ENDPOINT + "/auth?SSO_Mtoken=" + res.data.data.token + "&utm_source=Getwork_Portal%20&utm_medium=web&utm_campaign=SMP&utm_content=download",
            "_blank"
          );
        }
      })
      .catch((err) => {});
  };

  const dispatch = useDispatch();
  const logout = () => {
    setLoading(true);
    axios
      .post(BackendBaseApi.PRAVESH + "api/logout/", null, {
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
        withCredentials: true
      })
      .then((res) => {
        setLoading(false);

        if (res.data.success) {
          localStorage.clear();
          // dispatch({ type: "USER_LOGOUT" });
          history.push("/login");
        }
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/login");
      });
  };
  function openNav() {
    document.getElementById("mySidebar").style.width = "50%";
    // document.getElementById("main").style.marginLeft = "250px";
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    // document.getElementById("main").style.marginLeft= "0";
  }
  const isMenuOpen = Boolean(anchorEl);
  const classes = useStyles();
  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        {" "}
        <Link to={`/public/student/profile/${data.id}`} target="_blank" style={{ display: "flex", alignItems: "center" }}>
          {" "}
          <AccountCircleIcon className="mg-right-15" /> View Profile
        </Link>
      </MenuItem>
      <MenuItem onClick={() => history.push("/student/edit-profile")} style={{ display: "flex", alignItems: "center" }}>
        <EditIcon className="mg-right-15" />
        Edit Profile
      </MenuItem>
      <MenuItem onClick={logout} style={{ display: "flex", alignItems: "center" }}>
        <ExitToAppIcon className="mg-right-15" />
        Logout
      </MenuItem>
    </Menu>
  );
  return (
    <BreakpointProvider>
      <Breakpoint large up>
        <div className="navbar">
          <AppBar
            position="fixed"
            color="transparent"
            style={{
              boxShadow: "none",
              background: "#ffffff",
              minHeight: 40,
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              height: "64px",
            }}
          >
            <Toolbar style={{ padding: "0px" }}>
              <Container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Link to="/student/dashboard" style={{display:"flex",alignItems:"center" }} >
                  <img src={institudeId && subDomainLogo ? subDomainLogo: Logo} className={classes.logo}  alt="" />
                </Link>
                <div>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    {JSON.parse(localStorage.getItem("user_details")).first_name + " " + JSON.parse(localStorage.getItem("user_details")).last_name}
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                      style={{ float: "right" }}
                      className={classes.profileButton}
                    >
                      <Avatar className="mg-right-15" src={JSON.parse(localStorage.getItem("user_details")).profile_picture} />
                    </IconButton>
                  </Box>
                </div>
              </Container>
            </Toolbar>
          </AppBar>

          {renderMenu}
        </div>
      </Breakpoint>
      <Breakpoint medium down>
        <div className="navbar">
          <AppBar
            position="fixed"
            color="transparent"
            style={{
              boxShadow: "none",
              background: "#ffffff",
              minHeight: 40,
            }}
          >
            <Toolbar style={{ padding: "0 10px" }}>
              <button className="openbtn" onClick={toggleDrawer(true)}>
                ☰
              </button>
              <Link to="/student/dashboard">
                <img src={institudeId && subDomainLogo ? subDomainLogo: Logo} alt="" className={classes.img} />
              </Link>

              <div>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  style={{ float: "right" }}
                >
                  <Avatar
                    src={JSON.parse(localStorage.getItem("user_details")).profile_picture}
                    style={{
                      height: "25px !important",
                      width: "25px !important",
                    }}
                  />
                </IconButton>
              </div>
            </Toolbar>
            {!institudeId && !hideDownload &&
            <Box className={classes.blackBox}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Typography variant="body2" style={{ fontFamily: " Nunito", color: "#fff", fontSize: 14 }}>
                    <b>Download the Get Work App to get Exciting Job Alerts!</b>
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                  <AndroidView>
                    <a href="https://play.google.com/store/apps/details?id=com.getwork_app" target="_blank">
                      <Button
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: 2,
                          padding: "3px 11px 3.5px 11px",
                        }}
                        startIcon={<img src={google} alt="" />}
                      >
                        Download
                      </Button>
                    </a>
                  </AndroidView>
                  <IOSView>
                    <a href="https://apps.apple.com/in/app/getwork/id1579830388" target="_blank">
                      <Button
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: 2,
                          padding: "3px 11px 3.5px 11px",
                        }}
                        startIcon={<img src={apple} alt="" />}
                      >
                        Download
                      </Button>
                    </a>
                  </IOSView>
                </Grid>
              </Grid>
            </Box>
            }
          </AppBar>
          {/*<br />*/}
          {/*<br />*/}
          {renderMenu}
        </div>
        <Drawer open={state} onClose={toggleDrawer(false)} classes={{ paper: classes.drawerPaper }}>
          <div>
            <Box padding="5%" display="flex" justifyContent="flex-end">
              <HighlightOff onClick={toggleDrawer(false)} />
            </Box>
            <Box display="flex" justifyContent="left" paddingLeft="12%" marginTop="6%">
              <img src={HomeIcon} className="ml-1 pointer" style={{ marginRight: "7%", height: "17px", width: "auto" }} />
              <Link activeClassName="link-active" to="/student/dashboard" className="flex align-item-center" style={{ fontSize: "16px" }}>
                Home
              </Link>
            </Box>

            <Box display="flex" justifyContent="left" paddingLeft="12%" marginTop="6%" alignItems="center">
              <WorkOutlineIcon style={{ marginRight: "7%", height: "17px", width: "auto" }} />
              <p className="flex align-item-center" style={{ fontSize: "16px", marginBottom: "0px" }}>
                Jobs
              </p>
            </Box>

            <div className="flex flex-col" style={{ marginLeft: "32%" }}>
              <Link to="/student/open" className="mt-1" style={{ fontSize: "16px" }}>
                Open Jobs
              </Link>
              <Link to="/student/my-jobs" className="mt-1" style={{ fontSize: "16px" }}>
                My Jobs
              </Link>
              {/* <Link
                to="/student/applied"
                className="mt-1"
                style={{ fontSize: "16px" }}
              >
                Applied
              </Link>
              <Link
                to="/student/saved"
                className="mt-1"
                style={{ fontSize: "16px" }}
              >
                Saved
              </Link>
              <Link
                to="/student/closed"
                className="mt-1"
                style={{ fontSize: "16px" }}
              >
                Closed
              </Link>
              <Link
                to="/student/hidden"
                className="mt-1"
                style={{ fontSize: "16px" }}
              >
                Hidden
              </Link> */}
            </div>

            <Box display="flex" justifyContent="left" paddingLeft="12%" marginTop="6%" alignItems="center">
              <PhoneOutlined style={{ marginRight: "7%", height: "17px", width: "auto" }} />
              <p className="flex align-item-center" style={{ fontSize: "16px", marginBottom: "0px" }}>
                Interview
              </p>
            </Box>

            <div className="flex flex-col" style={{ marginLeft: "32%" }}>
              <Link to="/student/invited" className="mt-1" style={{ fontSize: "16px" }}>
                Invited
              </Link>
              <Link to="/student/scheduled" className="mt-1" style={{ fontSize: "16px" }}>
                Scheduled
              </Link>
            </div>

            <Box display="flex" justifyContent="left" paddingLeft="12%" marginTop="6%" alignItems="center">
              <AssignmentIcon style={{ marginRight: "7%", height: "17px", width: "auto" }} />
              <p className="flex align-item-center" style={{ fontSize: "16px", marginBottom: "0px" }}>
                Assignment
              </p>
            </Box>

            <div className="flex flex-col" style={{ marginLeft: "32%" }}>
              <Link to="/student/open-assignment" className="mt-1">
                Open
              </Link>
              <Link to="/student/submitted-assignment" className="mt-1" style={{ fontSize: "16px" }}>
                Submitted
              </Link>
            </div>

            <Box display="flex" justifyContent="left" paddingLeft="12%" marginTop="6%">
              <SupervisorAccountIcon style={{ marginRight: "7%", height: "17px", width: "auto" }} />
              <div
                onClick={() => {
                  checkforSSO();
                }}
              >
                <Badge
                  color="secondary"
                  badgeContent={"New"}
                  classes={{
                    badge: classes.badge,
                    colorSecondary: classes.colorSecondary,
                  }}
                >
                  Mentorship
                </Badge>
              </div>
            </Box>

            <Box display="flex" justifyContent="left" paddingLeft="12%" marginTop="6%">
              <TocIcon style={{ marginRight: "7%", height: "17px", width: "auto" }} />
              <Link to="/student/blog" className="flex align-item-center" style={{ fontSize: "16px" }}>
                Blogs
              </Link>
            </Box>

            {/* <Box display="flex" justifyContent="left" paddingLeft="12%" marginTop="6%">
              <PeopleAltIcon style={{ marginRight: "7%", height: "17px", width: "auto" }} />
              <Link to="/student/connection" className="flex align-item-center" style={{ fontSize: "16px" }}>
                Connections
              </Link>
            </Box> */}

            <Box display="flex" justifyContent="left" paddingLeft="12%" marginTop="6%">
              <img src={UploadIcon} className={classes.expandIcon} alt="expand-icon" style={{ marginRight: "7%", height: "17px", width: "auto" }} />
              <Link to="/student/edit-profile" className="flex align-item-center" style={{ fontSize: "16px" }}>
                Upload Resume
              </Link>
            </Box>
          </div>
        </Drawer>
      </Breakpoint>
    </BreakpointProvider>
  );
}

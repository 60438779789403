import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: "#007bff",
  },
}));

export default function Action({ actionState, setActionState }) {
  const classes = useStyles();
  // const [state, setState] = React.useState({
  //   track: true,
  //   feedback: false,
  // });

  const handleChange = (event) => {
    setActionState({ ...actionState, [event.target.name]: event.target.checked });
  };

  const { track, download } = actionState;
  const error = [track, download].filter((v) => v).length !== 2;

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup className={classes.formControl} row>
          <FormControlLabel control={<Checkbox style={{ color: "#007bff" }} checked={track} onChange={handleChange} name="track" />} label="Track" />
          <FormControlLabel control={<Checkbox style={{ color: "#007bff" }} checked={download} onChange={handleChange} name="download" />} label="Download" />
          {/* <FormControlLabel
            control={<Checkbox  style={{color:"#007bff"}} checked={feedback} onChange={handleChange} name="feedback" />}
            label="Feedback"
          /> */}
        </FormGroup>
      </FormControl>
    </>
  );
}

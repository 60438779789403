import React from "react";
import { Paper, makeStyles, Typography, Chip } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "12px",
  },
  job: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  skill: {
    color: "#007bff",
    backgroundColor: "#e8f3ff",
    borderColor: "#007bff",
  },
  chip: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

function Languages({ data }) {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6">Languages</Typography>
        <br />
        {data?.student_user_details?.languages?.map((item) => (
          <>
            <div className={classes.job}>
              <Typography variant="body1">{item.name}</Typography>
              {/* <Typography variant="body2">
                {item?.start_date && item?.start_date !== null && (
                  <>
                    {moment(item?.start_date).format("MMM, YYYY")}{" "}
                    {item.end_date && item.end_date !== null && (
                      <>
                        {" - "}
                        {moment(item?.end_date).format("MMM, YYYY")}{" "}
                      </>
                    )}
                  </>
                )}
              </Typography> */}
            </div>
            <Typography variant="subtitle2" style={{ color: "#212121" }}>
              Proficiency: {item?.proficiency}
            </Typography>

            {/* <Typography variant="body2" style={{ color: "#007bff" }}>
              {item?.job_type_name}
            </Typography> */}
            {item.level.length > 0 && (
              <div className={classes.chip}>
                {item.level.map((item2) => (
                  <Chip label={item2} variant="outlined" className={classes.skill} />
                ))}{" "}
              </div>
            )}
            <br />
          </>
        ))}
      </Paper>
    </>
  );
}

export default Languages;

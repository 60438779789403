import React from "react";
import { Paper, Typography, Button, Grid } from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";

export default function BillingContact(props) {
  return (
    <>
      <Paper
        style={{
          padding: "20px",
          borderRadius: "20px",
          //   boxShadow: "0px, 0px rgba(0, 0, 0, 0.09)",
          boxShadow: "none",
          border: ".5px solid #6C757D",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" component="h3">
            {props.heading}
          </Typography>
        </div>
        {data.map((item) => (
          <Grid container spacing={2}>
            <Grid item>{item.icon}</Grid>
            <Grid item md={3}>
              <div style={{ display: "flex" }}>
                <Typography variant="body2">{item.title}</Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <Typography variant="body2">{item.res}</Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
    </>
  );
}

const data = [
  {
    icon: <AccountCircleOutlinedIcon />,
    title: "Acount Holder",
    res: "Rohan Joshi",
  },
  {
    icon: <MailOutlineOutlinedIcon />,
    title: "Email",
    res: "rohan.joshi34@gmail.com",
  },
  {
    icon: <PhoneOutlinedIcon />,
    title: "Phone",
    res: "+91 8525904321",
  },
];

import { Female, Male, Transgender } from "@mui/icons-material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText, IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { BackendBaseApi, emailRegex } from "../../../../constants/constants";
import { CustomTextField } from "../../Common/Input/InputField";

export default function RegisterApplyJob({userData, isNewUser, checkUser}) {
  // const classes = useStyles();
  const theme = useTheme();
  const selectgender = {
    borderColor: theme.palette.primary.main,
    backgroundColor: "#e8f3ff",
    color: theme.palette.primary.main,
    borderRadius: "30px",
    textTransform: "capitalize",
    marginRight: "20px",
  };
  const disablegender = {
    borderColor: theme.palette.grey[500],
    backgroundColor: "#fff",
    color: theme.palette.grey[500],
    borderRadius: "30px",
    textTransform: "capitalize",
    marginRight: "20px",
  };
  const alert = useAlert();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = React.useState({
    name: userData ? `${userData?.first_name ? userData?.first_name : ""} ${userData?.last_name ? userData.last_name : ""}` : "",
    email: userData?.email ? userData?.email : "",
    phone: userData?.phone ? userData?.phone : "",
    password: "",
    city: userData?.current_location ? userData?.current_location : "",
    showPassword: false,
  });

  const [errorValues, setErrorValues] = React.useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    password: "",
  });

  const [showPassErr, setShowPassErr] = useState(false);

  const [checkPwdLength, setCheckPwdLength] = useState(false);
  const [checkCapital, setCheckCapital] = useState(false);
  const [checkDigit, setCheckDigit] = useState(false);
  const [checkNoSpace, setCheckNoSpace] = useState(false);
  const [gender, setGender] = React.useState(null);
  const [selectedGender, setSelectedGender] = React.useState({
    male: false,
    female: false,
    others: false,
  });

  const [searchedCity, setSearchedCity] = useState("");
  const [cityLoading, setCityLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState();

  const [changed, setChanged] = useState(0);
  const [isError, setIsError] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
    if (prop === "password") {
      setCheckPwdLength(false);
      setCheckDigit(false);
      setCheckNoSpace(false);
      setCheckCapital(false);
      //setShowPassErr(false);
      setChanged((prev) => prev + 1);
    }
  };

  useEffect(() => {
    getCityState();
  }, [searchedCity]);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [cityData, setCityData] = useState([]);

  const getCityState = () => {
    setCityLoading(true);
    const citySearch = searchedCity ? `?city=${searchedCity}` : "";
    Axios.get(`${BackendBaseApi.PRAVESH}api/location/search/city/${citySearch}`)
      .then((res) => {
        if (res?.data?.success) {
          setCityData(res?.data?.data);
        }
        setCityLoading(false);
      })
      .catch((err) => {
        setCityLoading(false);
        alert.error("Something went wrong. Please try again!!");
      });
  };



  const handleSignup = () => {
    // let f_name = values?.name?.substring(0, values?.name?.indexOf(" "));
    // let l_name = values?.name?.substring(values?.name?.indexOf(" ") + 1);
    // if (f_name === "") {
    //   f_name = values?.name;
    //   l_name = "";
    // }

    const validated = validateData();

    if (validated) {
      if(userData){
        updateBasicDetails();
      }else{
        createNewAccount();
      }
    }
  };

  const createNewAccount = () => {
    let f_name = values?.name?.substring(0, values?.name?.indexOf(" "));
    let l_name = values?.name?.substring(values?.name?.indexOf(" ") + 1);
    if (f_name === "") {
      f_name = values?.name;
      l_name = "";
    }
    setIsLoading(true);
      Axios.post(`${BackendBaseApi.PRAVESH}api/getwork/create/`, {
        email: values?.email,
        first_name: f_name,
        last_name: l_name,
        password: values?.password,
        phone: values?.phone,
        gender: gender,
        curr_city_id: values?.city,
        user_type: "Student",
        user_platform: isMobile ? "MWEB" : "DWEB",
      },
      { withCredentials: true }
      )
        .then((res) => {
          if (res?.data?.success) {
            let data = res?.data?.data;
            localStorage.setItem("gw_token", data?.token);
            localStorage.setItem("signupName", values?.name);
            localStorage.setItem("user_type", "Student");
            history.push("/candidate/basic-details");
          } else {
            alert.error(
              res?.data?.error ? res?.data?.error : "Please try again!!"
            );
          }
          setIsLoading(false);
        })
        .catch((err) => {
          alert.error(
            err?.response?.data?.error
              ? err?.response?.data?.error
              : "Please try again"
          );
          setIsLoading(false);
        });
    }


  const updateBasicDetails = () => {
    let f_name = values?.name?.substring(0, values?.name?.indexOf(" "));
    let l_name = values?.name?.substring(values?.name?.indexOf(" ") + 1);
    if (f_name === "") {
      f_name = values?.name;
      l_name = "";
    }
    setIsLoading(true);
      Axios.post(`${BackendBaseApi.PRAVESH}api/getwork/update-basic-details/`, {
        email: values?.email,
        first_name: f_name,
        last_name: l_name,
        phone: values?.phone,
        gender: gender,
        curr_city_id: values?.city
      },{
        headers: {
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
        withCredentials: true
      }
      
      )
        .then((res) => {
          if (res?.data?.success) {
            let data = res?.data?.data;
            // localStorage.setItem("gw_token", data?.token);
            localStorage.setItem("signupName", values?.name);
            localStorage.setItem("user_type", "Student");
            if(isNewUser){  
              history.push("/candidate/basic-details");
            }else{
              checkUser(localStorage.getItem("gw_token"));
            }
          } else {
            alert.error(
              res?.data?.error ? res?.data?.error : "Please try again!!"
            );
          }
          setIsLoading(false);
        })
        .catch((err) => {
          alert.error(
            err?.response?.data?.error
              ? err?.response?.data?.error
              : "Please try again"
          );
          setIsLoading(false);
        });
    }

  const validateData = () => {
    let flag = true;
    let emailError = "";
    let passwordError = "";
    let mobileError = "";
    let nameError = "";
    let cityError = "";

    if (values?.email === "") {
      emailError = "Email cannot be left blank";
      flag = false;
    } else if (!emailRegex.test(values?.email)) {
      emailError = "Please enter a valid email address";
      flag = false;
    }
    if (!userData &&  values?.password === "") {
      passwordError = "Password field cannot be left blank";
      flag = false;
    } else if (!userData && values?.password?.length < 6) {
      passwordError = "Please enter a valid Password 6 digits long";
      flag = false;
    }
    if (values?.phone?.toString().length !== 10) {
      mobileError = "Please enter a valid mobile number";
      flag = false;
    }
    if (values?.name?.length === 0) {
      nameError = "Please enter your name";
      flag = false;
    }
    if (!values?.city) {
      cityError = "Please select your current city";
      flag = false;
    }
    setErrorValues({
      ...errorValues,
      name: nameError,
      password: passwordError,
      email: emailError,
      phone: mobileError,
      city: cityError,
    });
    setIsError(!flag);
    return flag;
  };

  const debounce = (func) => {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChangeSearch = (e) => {
    setCityData([]);
    setSearchedCity(e.target.value);
    
  };

  const optimisedHandleChange = React.useCallback(debounce(handleChangeSearch), []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} sm={12} md={12} style={{ display: "flex" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "600",
              lineHeight: 1.8,
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {userData ? 
            <>
            Fill details to find your <span style={{ color: "#007BFF" }}> Dream Job </span>
            </>
            : 
            <>
            Create your account to find your <span style={{ color: "#007BFF" }}> Dream Job </span>
            </>
            }
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12} style={{ display: "grid" }}>
          <CustomTextField
            size="small"
            label="Full Name"
            variant="outlined"
            value={values?.name}
            required
            onChange={handleChange("name")}
            error={errorValues?.name ? true : false}
            helperText={errorValues?.name ? errorValues?.name : ""}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} style={{ display: "grid" }}>
          <CustomTextField
            size="small"
            label="Phone Number"
            variant="outlined"
            type="number"
            value={values?.phone}
            required
            onChange={handleChange("phone")}
            error={errorValues?.phone ? true : false}
            helperText={errorValues?.phone ? errorValues?.phone : ""}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} style={{ display: "grid" }}>
          <Autocomplete
            id="select-city"
            options={cityData}
            value={selectedCity}
            loading={cityLoading}
            // selectOnFocus
            // clearOnBlur={false}
            // handleHomeEndKeys
            getOptionLabel={(option) =>
              option?.city && option?.state
                ? `${option?.city}, ${option?.state}`
                : ""
            }
            renderOption={(props, option) => {
              let { key, ...propsNew } = props;
              return (
                <Typography
                  style={{
                    fontSize: "14px",
                    padding: "5px 20px",
                    cursor: "pointer",
                  }}
                  {...propsNew}
                >
                  {option?.city}, {option?.state}
                </Typography>
              );
            }}
            style={{
              background: "#fff",
              borderRadius: 30,
            }}
            
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="outlined"
                label="Current Location"
                size="small"
                required
                // value={searchedCity}
                onChange={(ev) => {
                  setCityLoading(true);
                  // dont fire API if the user delete or not entered anything
                    optimisedHandleChange(ev);
                }}
                error={errorValues?.city ? true : false}
                helperText={errorValues?.city ? errorValues?.city : ""}
              />
              )}
            onChange={(event, newValue) => {
              setSelectedCity(newValue);
              setValues({ ...values, city: newValue?.city_id });
              setErrorValues({ ...errorValues, city: "" });
              // setSearchedCity("");
            }}
          />
        </Grid>

        <Grid xs={12} sm={12} md={12} style={{ display: "grid" }}>
          <CustomTextField
            size="small"
            label="Email ID"
            variant="outlined"
            value={values?.email}
            disabled={userData?.email ? true : false}
            onChange={handleChange("email")}
            error={errorValues?.email ? true : false}
            helperText={errorValues?.email ? errorValues?.email : ""}
            required
          />
        </Grid>
        {!userData && 

        <Grid xs={12} sm={12} md={12}>
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              width: "100%",
            }}
            required
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                fontSize: "14px",
                color: "#7e7e7e",
                paddingTop: "1.2px",
              }}
            >
              Password
            </InputLabel>
            <OutlinedInput
              style={{
                borderRadius: "30px",
                height: "40px",
              }}
              help
              error={errorValues?.password ? true : false}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              //value={values?.password}
              value={values?.password || ""}
              autoComplete="new-password"
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText
                style={{
                  fontFamily: " Nunito",
                  fontSize: "12px",
                  color: "#DC3545"
                }}
                
              >
               {errorValues?.password ? errorValues?.password : ""} 
              </FormHelperText>
          </FormControl>
        </Grid>
        }

        <Grid xs={12} sm={12} md={12}>
          <Typography variant="body2">Gender</Typography>
          <div style={{ marginTop: "5px" }}>
            <Button
              startIcon={<Male />}
              variant="outlined"
              style={selectedGender.male ? selectgender : disablegender}
              size="small"
              onClick={() => {
                const temp = {
                  male: true,
                  female: false,
                  others: false,
                };
                setSelectedGender({ ...selectedGender, ...temp });
                setGender("Male");
                setErrorValues({ ...errorValues, gender: "" });
              }}
            >
              Male
            </Button>
            <Button
              startIcon={<Female />}
              variant="outlined"
              style={selectedGender.female ? selectgender : disablegender}
              size="small"
              onClick={() => {
                const temp = {
                  male: false,
                  female: true,
                  others: false,
                };
                setSelectedGender({ ...selectedGender, ...temp });
                setGender("Female");
                setErrorValues({ ...errorValues, gender: "" });
              }}
            >
              Female
            </Button>
            <Button
              startIcon={<Transgender />}
              variant="outlined"
              style={selectedGender.others ? selectgender : disablegender}
              size="small"
              onClick={() => {
                const temp = {
                  male: false,
                  female: false,
                  others: true,
                };
                setSelectedGender({ ...selectedGender, ...temp });
                setGender("Others");
                setErrorValues({ ...errorValues, gender: "" });
              }}
            >
              Others
            </Button>
          </div>
          {errorValues?.gender && (
            <Typography
              style={{ color: "#DC3545", fontSize: "13px", marginTop: "5px" }}
            >
              {errorValues?.gender}
            </Typography>
          )}
        </Grid>
        {!userData && 
        <div style={{ display: "grid", paddingLeft: "12px", paddingTop: "10px" }}>
        <Typography
            variant="body3"
            color="initial"
            style={{ color: "#6C757D" }}
          >
            By signing up here, I agree to the{" "}
            <a href="/terms-and-conditions" target="_blank">
              {" "}
              <span style={{ color: "#007BFF" }}> Term of Service. </span>{" "}
            </a>
          </Typography> 
        </div>
}
        <Grid xs={12} sm={12} md={12} style={{ display: "grid", paddingTop: "5px" }}>
          {" "}
          <Button
            endIcon={<ArrowForwardRoundedIcon />}
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "30px",
              display: "flex",
              fontSize: "15px",
              fontWeight: "400",
            }}
            color="primary"
            variant="contained"
            onClick={handleSignup}
          >
            {isLoading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              <span id="candidate_signup_main">Continue</span>
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import ForgotPassword from "../bundles/common/components/Auth/ForgotPassword";
import Login from "../bundles/common/components/Auth/Login";
import ResetPassword from "../bundles/common/components/Auth/ResetPassword";
import Signup from "../bundles/common/components/Auth/Signup";
import VerifyEmail from "../bundles/common/components/Auth/VerifyEmail";
import StudentHome from "../bundles/common/components/Dashboard/StudentHome";
import PrivacyPolicy from "../bundles/common/components/TermsAndService/PrivacyPolicy";
import TermsAndService from "../bundles/common/components/TermsAndService/TermsAndService";
import ActivationPage from "../bundles/common/components/UI/ActivationPage";
import Maintainence from "../bundles/common/components/UI/Maintainence";
import NotFound from "../bundles/common/components/UI/NotFound";
import UserApproved from "../bundles/common/components/UI/UserApproved";
import CompanyProfileView from "../bundles/company/components/Home/Profile/ComView";
import CompanyProfileViewAdmin from "../bundles/company/components/Home/Profile/ComViewAdmin";
import CompanySignup from "../bundles/company/components/Signup/Signup";
import AdminApprove from "../bundles/company/components/UI/AdminApprove";
import EmailConfirmation from "../bundles/company/components/UI/EmailConfirmation";
import TeamForm from "../bundles/company/components/UI/TeammateForm";
import TeamFormCollege from "../bundles/company/components/UI/TeammateForm1";
import StudentSignup from "../bundles/student/components/Auth/Signup";
import StudentHomeComponent from "../bundles/student/components/Home/StudentHome";
//import RecommendedJob from "../CollegeSection/page/RecommendedJob/RecommendedJob";
import CollegeList from "../common/AllCollege";
import Blogs from "../common/Explore/Blogs.js";
//import UploadStudentData from "../CollegeSection/page/UploadStudentData/UploadStudentData";
//import Explore from "../commonNew/Explore/Explore.js";
import Explore from "../common/Explore/Explore.js";
import ReportsPanel from "../components/college/ReportsPanel/ReportsPanel";
import CompanyHomeComponent from "../components/company/Home/CompanyHome";
import MuiCustomTheme from "../NewAppLayout/components/Theme/theme";
import ApplicantDetails from "../NewAppLayout/pages/DetailIndex";
import EmployeeDetails from "../NewAppLayout/pages/EmployeeDetails";
import ManageJobApplicants from "../NewAppLayout/pages/index";
import LinkNotFound from "../NewAppLayout/pages/LinkNotFound";
import PublicTrack from "../NewAppLayout/pages/PublicTrack";
import TeamInvite from "../NewAppLayout/pages/TeamInvite";
import CloseJob from "../pages/college/ClosedJob/CloseJob";
import CompanyConnection from "../pages/college/CompanyConnection/CompanyConnection";
import ConnectionStudents from "../pages/college/Connections/Students";
import DashBoard from "../pages/college/Dashboard/Dashboard";
import DraftJob from "../pages/college/DraftJob/DraftJob";
import Interview from "../pages/college/Interview/Interview";
import Invite from "../pages/college/InviteJob/Invite";
import NewJob from "../pages/college/NewJob/NewJob";
import CollegePublicPage from "../pages/college/NewProfile/Profile";
import OpenJob from "../pages/college/OpenJob/OpenJob";
import CreateJob from "../pages/college/PostJobNew/index";
import Dummy from "../pages/college/Profile/dummy";
import CollegeProfile from "../pages/college/Profile/Profile";
import UpdateProfile from "../pages/college/Profile/Update";
import ViewProfile from "../pages/college/Profile/View";
import ViewProfilePublic from "../pages/college/Profile/ViewPublic";
import CollegeSettings from "../pages/college/ProfileMenu/CollegeSettings";
import UserSettings from "../pages/college/ProfileMenu/UserSettings";
import RecommendedJob from "../pages/college/RecommendedJob/RecommendedJob";
//import CollegeSettings from "../CollegeSection/page/ProfileMenu/CollegeSettings";
//import UserSettings from "../CollegeSection/page/ProfileMenu/UserSettings";
// import TrackCollege from "../CollegeSection/page/Track/Track";
import TrackCollege from "../pages/college/Track";
import UploadStudentData from "../pages/college/UploadStudentData/UploadStudentData";
import CancellationPolicy from "../pages/common/CancellationPolicy/index";
import ViewJobPage from "../pages/common/viewJob/ViewJob";
import NewCollege from "../pages/public/AllCollege";
import JobDetails from "../pages/public/JobDetails";
import PublicOpenJob from "../pages/public/Openjob";
import Profile from "../pages/public/Profile";
import Resume from "../pages/public/Resume/index";
import StudentLogin from "../pages/public/StudentLogin";

const IndexRoutes = () => {  
  return (
    <>
      {/* <Layout2> */}
      {/* <BrowserRouter history={appHistory}> */}
      {/* <PublicLayout visible={true}> */}
      <Switch>
        <Route exact path="/college/explore" component={Explore} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route path="/confirm-email" component={EmailConfirmation} />
        <Route path="/company-team-invite" component={TeamForm} />

        <Route path="/college-team-invite" component={TeamFormCollege} />
        <Route path="/public/team-invite-new" component={TeamInvite} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/verify-email/:token" component={VerifyEmail} />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/company/admin-approve" component={AdminApprove} />
        <Route exact path="/student/register" component={StudentSignup} />
        <Route path="/activation-link/:token" component={ActivationPage} />
        <Route exact path="/company/register" component={CompanySignup} />
        <Route path="/user-approved/:token" component={UserApproved} />
        <Route exact path="/student-home" component={withRouter(StudentHome)} />
        {/* <Route path="/company-home" component={withRouter(CompanyHome)} /> */}
        <Route path="/student" component={withRouter(StudentHomeComponent)} />
        {/* <Route path="/company" component={withRouter(CompanyHomeComponent)} /> */}
        {/* <Route path="/public/job/:job_title$:token" component={ViewJob} />
        <Route path="/public/job/:token" component={ViewJob} /> */}
        <Route
          path="/public/job/oncampus/:job_id/:action"
          component={JobDetails}
        />
        <Route path="/public/job/oncampus/:job_id" component={JobDetails} />
        <Route
          path="/public/job/offcampus/:job_id/:action"
          component={JobDetails}
        />
        <Route path="/public/job/offcampus/:job_id" component={JobDetails} />
        <Route path="/public/job/:job_id/:action" component={JobDetails} />
        <Route path="/public/job/:job_id" component={JobDetails} />
        <Route path="/company" component={withRouter(CompanyHomeComponent)} />
        {/* <Route path="/public/job/:job_id/:action" component={ViewJobPage} />
        <Route path="/public/job/:job_id" component={ViewJobPage} /> */}
        <Route path="/public/all_india_colleges" component={CollegeList} />
        <Route path="/public/india-colleges" component={NewCollege} />
        <Route path="/maintainence" component={Maintainence} />
        <Route exact path="/" component={CheckUser} />

        {/* college route */}
        <Route exact path="/college/open-job" component={OpenJob} />
        <Route exact path="/college/drafted-job" component={DraftJob} />

        <Route exact path="/college/close-job" component={CloseJob} />
        <Route exact path="/college/new-job" component={NewJob} />
        <Route
          exact
          path="/college/recommended-job"
          component={RecommendedJob}
        />
        <Route exact path="/college/invite" component={Invite} />
        <Route
          exact
          path="/college/company-connection"
          component={CompanyConnection}
        />
        <Route exact path="/college/track/:token?" component={TrackCollege} />
        <Route exact path="/college/interviews" component={Interview} />

        <Route
          exact
          path="/college/college-settings/:token?"
          component={CollegeSettings}
        />
        <Route
          exact
          path="/college/settings/payment-status/:status"
          component={CollegeSettings}
        />
        <Route exact path="/college/user-settings" component={UserSettings} />
        <Route exact path="/college/dashboard" component={DashBoard} />
        <Route exact path="/college/profile" component={CollegeProfile} />

        <Route exact path="/college/Profile_update" component={UpdateProfile} />

        <Route
          exact
          path="/public/company/profile/:token"
          component={CompanyProfileView}
        />
        <Route
          exact
          path="/public/company/profile"
          component={CompanyProfileViewAdmin}
        />
        <Route path="/public/student/profile/:id" component={Resume} />

        <Route
          exact
          path="/public/college/new-profile/:college_name$:college_id"
          component={CollegePublicPage}
        />

        <Route
          exact
          path="/college/upload_student_data"
          component={UploadStudentData}
        />
        <Route exact path="/public/college/profile" component={ViewProfile} />
        <Route
          exact
          path="/public/college/profile/:token"
          component={ViewProfilePublic}
        />
        <Route exact path="/college/blogs/:token" component={Blogs}></Route>

        <Route exact path="/college/dummy" component={Dummy} />
        <Route
          exact
          path="/college/students_connections"
          component={ConnectionStudents}
        />
        <Route exact path="/college/post-job/:job_id?" component={CreateJob} />
        <Route exact path="/college/reports" component={ReportsPanel} />
        <Route
          exact
          path="/college/reports/:reportId"
          component={ReportsPanel}
        />
        <Route exact path="/terms-and-services" component={TermsAndService} />
        <Route
          exact
          path="/privacy-and-cookie-policy"
          component={PrivacyPolicy}
        />

        <Route
          exact
          path="/cancellation-and-refund"
          component={CancellationPolicy}
        />
        <Route
          exact
          path="/public/open-jobs/:page?"
          component={PublicOpenJob}
        />
        {/* <Route exact path="/public/track/:token?" component={Track} /> */}
        <Route exact path="/public/new-college" component={NewCollege} />
        {/* <Route exact path="/public/log-in" component={LoginNew} />
        <Route exact path="/public/studentlog-in" component={LoginStudent} /> */}
        {/* <Route exact path="/public/register" component={Register} /> */}
        <Route
          exact
          path="/public/job-details/:job_id"
          component={ViewJobPage}
        />
        <Route exact path="/public/registration" component={Profile} />
        <Route exact path="/public/login" component={StudentLogin} />
        <Route path="/public/track/:token?" component={PublicTrack} />
        {/* <Route path="/public/new-login" component={NewSignUp} /> */}

        <Route
          path="/public/students/track"
          render={(props) => <ApplicantDetails {...props} isPublic={true} />}
        />
        <Route path="/public/demo-testing" component={LinkNotFound} />
        <MuiCustomTheme>
          <Route path="/public/manage-job" component={ManageJobApplicants} />
          <Route path="/public/details" component={EmployeeDetails} />
        </MuiCustomTheme>
        {/* <Route exact path="/public/completeprofile" component={CompleteProfile} /> */}
        {/* <Route exact path="/public/resume/:id" component={Resume} /> */}

        <Route path="*" component={NotFound} />
      </Switch>
      {/* </PublicLayout> */}
      {/* </BrowserRouter> */}
    </>
  );
};

export default IndexRoutes;

const CheckUser = () => {
  const history = useHistory();

  const data = localStorage.getItem("user_type");
  if (data === "Company") {
    history.push("/company/dashboard");
  } else if (data === "Student") {
    history.push("/student/dashboard");
  } else if (data === "College") {
    history.push("/college/dashboard");
  } else {
    history.push("/login");
  }

  return null;
};

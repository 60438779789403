import React, { useEffect, useState } from "react";
import CssTextField from "./CssTextField";

const NumberTextField = ({ state, setState, labelName, width, responsive }) => (
  <CssTextField
    id="outlined-name111"
    type="number"
    InputProps={{ inputProps: { min: 0 }, style: { fontSize: 13 } }}
    InputLabelProps={{ shrink: true }}
    label={<span className={responsive}>{labelName}</span>}
    value={state}
    onChange={(e) => {
      setState(e.target.value);
    }}
    variant="outlined"
    style={{ width: width }}
  />
);

export const NumberTextFieldPercentage = ({
  state,
  setState,
  labelName,
  width,
  responsive,
}) => (
  <CssTextField
    id="outlined-name1"
    type="number"
    InputProps={{ inputProps: { min: 0 }, style: { fontSize: 13 } }}
    InputLabelProps={{ shrink: true }}
    label={<span className={responsive}>{labelName}</span>}
    value={state}
    onChange={(e) => {
      setState(e.target.value);
    }}
    variant="outlined"
    style={{ width: width }}
  />
);

export default NumberTextField;

import { Box, Chip, Divider, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "react-calendar/dist/Calendar.css";
import { BackendBaseApi } from "../../../constants/constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
      borderRadius: "30px",
      //   height: "200px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      marginTop: "20px",
      marginBottom: "10px",
      width: "500px",
      borderRadius: "30px",

      // height: "200px",
    },
    deleteButton: {
      background: "#3282C4",
      color: "#fff",
      borderRadius: "30px",

      // width: "60%",

      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    deleteButtondisabled: {
      background: "#828282",
      color: "#fff",
      borderRadius: "30px",

      // width: "60%",
    },
    cancelButton: {
      color: "black",
      backgroundColor: "white",
      border: "2px solid black",
      borderRadius: "30px",
      "&:hover": {
        backgroundColor: "#F2F2F2",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      fontSize: 13,
      marginRight: "1%",
      marginBottom: "1%",
    },
    reactCalender: {
      border: "0px",
      boxShadow: "0px 9px 20px 6px rgba(0, 0, 0, 0.25)",
      justifyContent: "center",
    },
    InnerBox: {
      width: "100%",
      backgroundColor: "#F2F2F2",
      borderRadius: "30px",
      padding: "10px 0 10px 10px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
  })
);

export default function ViewDetails(props) {
  const {
    handleClose,
    handleOpen,
    open,

    subUserList,
    setSubUserList,
    subUserAssignment,
    setSubUserAssignment,
    usersList,
    setUserList,
  } = props;
  const alert = useAlert();
  const classes = useStyles();

  const [currentData, setCurrentData] = useState([]);
  const [userSelected, setUserSelected] = useState();
  const [userSelectedName, setUserSelectedName] = useState("");

  useEffect(() => {
    if (open) setUserSelectedName("");
  }, [open]);

  useEffect(() => {
    if (subUserList?.student_data !== undefined) setCurrentData(subUserList?.student_data);
  }, [subUserList]);

  const getAllUsers = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/company/settings/team/", {
      headers: { Authorization: `Token ${localStorage.getItem("gw_token")}` },
    })
      .then((res) => {
        let temp = {};

        for (let i = 0; i < res.data.data.length; i++) {
          temp[res.data.data[i].first_name + " " + res.data.data[i].last_name] = res.data.data[i].user_id;
        }
        setUserList({ ...temp });
      })
      .catch((err) => {
        alert.error("Some Error Occured");
      });
  };

  useEffect(() => {
    if (usersList === undefined && localStorage.getItem("user_type") && localStorage.getItem("user_type") !== "College") getAllUsers();
  }, []);

  const assign = () => {
    subUserList.student_data.forEach((item) => {
      item.user_id = userSelected;
    });

    Axios.post(
      BackendBaseApi.NIYUKTI + "job/company/assign_user/",

      subUserList,

      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("User Assigned Successfully");
          handleClose();
          setUserSelected();
          setSubUserList({});
          setSubUserAssignment(res.data.data.verbose_data);
        } else {
          alert.error("Subuser was not assigned. Some Error occured");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const user_list =
    usersList &&
    Object.keys(usersList).map((item) => {
      return { name: item, value: item };
    });

  const UserDropDown = () => (
    <Autocomplete
      value={userSelectedName}
      onChange={(event, newValue) => {
        setUserSelected(usersList[newValue]);
        setUserSelectedName(newValue);
      }}
      id="controllable-states-demo"
      options={user_list.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
    />
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={11}></Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <>
              <Typography align="left" variant="body1">
                <b>Assign A User</b>
              </Typography>
              <Typography align="left" variant="body2" style={{ color: "#424242" }}>
                Assign a user with these selected applicants{" "}
              </Typography>
              <br />
              {currentData?.map((item, index) => (
                <Chip key={index} className={classes.root1} label={item.applicant_first_name + " " + item.applicant_last_name} color="primary" variant="outlined" />
              ))}
              <br /> <br />
              <Divider />
              <br />
              <UserDropDown />
            </>
            <br />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                float: "center",
              }}
            >
              {userSelected ? (
                <Button className={classes.deleteButton} onClick={() => assign()}>
                  Assign{" "}
                </Button>
              ) : (
                <Button className={classes.deleteButtondisabled} disabled>
                  Assign{" "}
                </Button>
              )}
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

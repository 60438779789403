import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Skeleton from "react-loading-skeleton";
import ReactHtmlParser from "react-html-parser";

import { useMediaQuery } from "react-responsive";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import nobanner from "../../../../../assets/images/noimg.png";
import fakelogo from "../../../../../assets/images/user/user-default.png";
import Layout from "../../../../../components/college/Layout/Layout_c";
import { BackendBaseApi } from "../../../../../constants/constants";
import Avatarimage from "./Avatarimage.jsx";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 200,
  },
}));

export default function CompanyProfileView(props) {
  const [url, setUrl] = useState(
    BackendBaseApi.PRAVESH + "api/company/edit_profile/"
  );
  const [isError, setIsError] = useState(false);
  const [isAbout, setIsAbout] = useState();
  const [isDescription, setIsDescription] = useState();
  const [isName, setIsName] = useState();
  const [industry, setIsIndustry] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();

  const [logo, setIsLogo] = useState();
  const [banner, setIsBanner] = useState();
  const [web, setIsweb] = useState();
  const [year, setIsyear] = useState();
  const [spcl, setIsSpecialities] = useState();
  const [size, setIsSize] = useState();
  const [fb, setFb] = useState();
  const [linked, setLinkedIn] = useState();
  const [twitter, setTwitter] = useState();
  const [org, setOrg] = useState();
  const [socialLinks, setSocialLinks] = useState([]);
  const alert = useAlert();
  const checkClearBitDomain = (name) => {
    if (name === "LinkedIn") {
      return ".cn";
    } else return ".com";
  };

  useEffect(() => {
    let token = localStorage.getItem("gw_token");

    const fetchData = () => {
      axios
        .get(url, {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            setIsName(res.data.data.company_name);
            setIsAbout(res.data.data.about);
            setIsDescription(res.data.data.company_description);
            setIsIndustry(res.data.data.industry_name);
            setIsLogo(res.data.data.logo);
            setIsBanner(res.data.data.banner);
            setIsSize(res.data.data.size);
            setIsweb(res.data.data.website);
            setIsyear(res.data.data.year_founded);
            setIsSpecialities(res.data.data.specialities);
            setOrg(res.data.data.organization_type);
            setCity(res.data.data.city_name);
            setState(res.data.data.state_name);

            if (res.data.data.social_links != null) {
              setSocialLinks(res.data.data.social_links);
              setFb(res.data.data.social_links[1].value);
              setLinkedIn(res.data.data.social_links[0].value);
              setTwitter(res.data.data.social_links[2].value);
            }
          }
        })
        .catch((error) => {
          alert.error("Error Occured.");
        });
    };

    fetchData();
  }, []);

  const IMAGES = [
    {
      src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
      thumbnail:
        "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
      thumbnailWidth: 1000,
      thumbnailHeight: 174,
      // isSelected: true,
      caption: "After Rain (Jeshu John - designerspics.com)",
    },
    {
      src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
      thumbnail:
        "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
      tags: [
        { value: "Ocean", title: "Ocean" },
        { value: "People", title: "People" },
      ],
      caption: "Boats (Jeshu John - designerspics.com)",
    },

    {
      src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
      thumbnail:
        "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
    },

    {
      src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
      thumbnail:
        "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
      thumbnailWidth: 400,
      thumbnailHeight: 212,
    },
  ];

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
  };

  function myTileViewportStyleFn() {
    if (this.props.item.isSelected) return {};
  }

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div className="collegeprofile">
      <Layout>
        <br />
        <br />
        {/* <CompanyNavbar /> */}
        <Container className="mt--7" fluid>
          <Row>
            <Col xl="3">
              <Card
                className=""
                style={{
                  position: "fixed",
                  padding: "0 50px 0 50px",
                  width: "22%",
                  borderRadius: 30,
                  backdropFilter: "blur(30px)",
                  boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                  border: "solid 1px #f1f1f1",
                  overflowY: "scroll",
                  maxHeight: "600px",
                }}
              >
                <CardBody>
                  <div>
                    {logo ? (
                      <img
                        src={logo}
                        alt="logo"
                        height="150px"
                        width="160px"
                        style={{
                          border: "6px solid rgb(182, 205, 226)",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          margin: "auto",
                        }}
                      />
                    ) : (
                      <img
                        src={fakelogo}
                        alt="logo"
                        height="150px"
                        width="160px"
                        style={{
                          border: "6px solid rgb(182, 205, 226)",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          margin: "auto",
                        }}
                      />
                    )}
                    <br />
                  </div>
                  <h3 style={{ textAlign: "center" }}>
                    {isName} {city && <>- {city},</>}
                    {state && <> {state},</>} <br />
                    India
                  </h3>
                  <p style={{ textAlign: "center" }}> {isAbout} </p>
                  <br />
                  <hr />
                  <br />
                  {socialLinks.length > 0 ? (
                    <>
                      {socialLinks !== null &&
                        socialLinks.map((item, ind) => {
                          return (
                            <Fragment key={ind}>
                              <a href={item.value} target="blank">
                                <img
                                  src={
                                    item.name
                                      ? "//logo.clearbit.com/" +
                                        item.name +
                                        checkClearBitDomain(item.name)
                                      : fakelogo
                                  }
                                  style={{
                                    height: "30px",
                                    width: "auto",
                                    paddingTop: "5px",
                                  }}
                                  alt="img"
                                />{" "}
                                &nbsp; {item.name}
                              </a>
                              <br />
                            </Fragment>
                          );
                        })}
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xl="9">
              <Card
                className="shadow"
                style={{
                  padding: "40px",
                  borderRadius: 30,
                  backdropFilter: "blur(30px)",
                  boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                  border: "solid 1px #f1f1f1",
                }}
              >
                <CardBody>
                  <div>
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      className={className}
                      size="lg"
                      style={{ marginTop: "100px" }}
                    >
                      <ModalHeader toggle={toggle}>
                        Update Cover Photo
                      </ModalHeader>
                      <ModalBody>
                        <Avatarimage />
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={toggle}>
                          Update Cover Photo
                        </Button>{" "}
                        <Button color="secondary" onClick={toggle}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                  {banner ? (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundImage: `url(${banner})`,
                        boxShadow: "2px 2px 10px #9E9E9E",
                        zIndex: "-10",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: 30,
                      }}
                    >
                      {" "}
                      <br />
                      <br />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        backgroundImage: `url(${nobanner})`,
                        boxShadow: "2px 2px 10px #9E9E9E",
                        zIndex: "-10",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: 30,
                      }}
                    >
                      {" "}
                      <br />
                      <br />
                    </div>
                  )}

                  <br />
                  <br />

                  <Row>
                    <Col xl="12">
                      <h3>{isName}</h3>
                      <p style={{ color: "grey" }}>
                        {industry} {city && <>• {city},</>}
                        {state && <> {state}</>} • {size} Employees
                      </p>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  {isDescription && (
                    <>
                      <h6>Company Description</h6>
                      <p>{isDescription}</p>
                      <br />
                    </>
                  )}
                  {web && (
                    <>
                      <Row>
                        <Col sm="2">
                          <h6>Website</h6>
                        </Col>
                        <Col sm="10">
                          <a href={web} target="blank">
                            <h6 style={{ color: "#0000EE" }}>
                              <b>{web}</b>
                            </h6>
                          </a>
                        </Col>
                      </Row>
                      <br />
                    </>
                  )}
                  {industry && (
                    <>
                      <Row>
                        <Col sm="2">
                          <h6>Industry</h6>
                        </Col>
                        <Col sm="10">
                          <p>{industry}</p>
                        </Col>
                      </Row>
                      <br />
                    </>
                  )}
                  {size && (
                    <>
                      <Row>
                        <Col sm="2">
                          <h6>Employees</h6>
                        </Col>
                        <Col sm="10">
                          <p>{size}</p>
                        </Col>
                      </Row>
                      <br />
                    </>
                  )}
                  {org && (
                    <>
                      <Row>
                        <Col sm="2">
                          <h6>Type</h6>
                        </Col>
                        <Col sm="10">
                          <p>
                            {org === "SemiPrivate" ? "Semi Government" : org}
                          </p>
                        </Col>
                      </Row>
                      <br />
                    </>
                  )}
                  {year && (
                    <>
                      <Row>
                        <Col sm="2">
                          <h6>Founded</h6>
                        </Col>
                        <Col sm="10">
                          <p>{year}</p>
                        </Col>
                      </Row>
                      <br />
                    </>
                  )}
                  {spcl && (
                    <>
                      <Row>
                        <Col sm="2">
                          <h6>Specialities</h6>
                        </Col>
                        <Col sm="10">
                          <p>{ReactHtmlParser(spcl)}</p>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </div>
  );
}

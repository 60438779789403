import React, {useEffect, useState, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../../components/public/Navbar/Navbar';
import Footer from '../../components/public/Footer/Footer';
import Box from "@material-ui/core/Box";
import Grid from '@material-ui/core/Grid';
import Filter from '../../components/public/Filter/Filter';
import Hidden from "@material-ui/core/Hidden";
import Axios from "axios";
import { BackendBaseApi } from "../../constants/constants";
import { useAlert } from "react-alert";
import PublicCollegeMain from '../../components/public/PublicCollege/PublicCollegeMain';


const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex'
    
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  mainJob: {
    minHeight: '100vh'
  }
}));


export default function OpenJob() {
  const classes = useStyles();
  const [filterData, setFilterData] = useState({});
  const [collegeData, setCollegeData] = useState([]);
  const [collegeDataFetched, setCollegeDataFetched] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [collegeCount, setCollegeCount] = useState(0);
  const [isFilterDataPresent, setIsFilterDataPresent] = useState(false);
  const firstApiResponse = useRef(false);

const [appliedFilters, setAppliedFilters] = useState({
  stream_id: "",
  state_id: "",
  city_id: ""
  
});
  const alert = useAlert();
  
  const getFilterData = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/colleges/college_filters/").
    then((res) => {
      if(res.data && res.data.success){
          setFilterData(res.data.data);
          setIsFilterDataPresent(true);
      }
      else{
        alert.error("Some error in fetching filters");
        setIsFilterDataPresent(true);
      }
    }).catch(err => {
      alert.error("Some error in fetching filters");
      setIsFilterDataPresent(true);
    })
  }

  const getCollgeData = () => {
    let urlQueryParam = currentPage ? `page=${currentPage}` : "";
    
    Object.keys(appliedFilters).map((key) => {
        if (key === "stream_id" && appliedFilters.stream_id)
        urlQueryParam += ("&" + key + "=" + appliedFilters.stream_id)
        else if (key === "state_id" && appliedFilters.state_id)
        urlQueryParam += ("&" + key + "=" + appliedFilters.state_id)
        else if (key === "city_id" && appliedFilters.city_id)
        urlQueryParam += ("&" + key + "=" + appliedFilters.city_id)
    })
    setCollegeDataFetched(false);
    setCollegeData([]);
    Axios.get(BackendBaseApi.PRAVESH + "api/colleges/all_college_data/?" + urlQueryParam).
    then((res) => {
      if(res.data && res.data.success){
        setCollegeData(res.data.data);
        setCollegeDataFetched(true);
        setCollegeCount(res.data.data.count);
      }
      else{
        alert.error("Some error in fetching jobs");
        setCollegeDataFetched(true);
      }
    }).catch(err => {
      alert.error("Some error in fetching jobs");
      setCollegeDataFetched(true);
    })
    
  }

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    if(currentPage || appliedFilters)
    getCollgeData();
  }, [currentPage, appliedFilters]);
 
  return (
   
    <div  className={classes.root}>    
     <Navbar/>
     <div className={classes.mainBox}>  
     <Grid container style={{flexWrap: "nowrap"}}>
       <Hidden only={['sm','xs']}>    
       {/* Public Job Same Filter  */}
          <Filter filterData={filterData} setFilters={setAppliedFilters} dataFetched={collegeDataFetched} isFilterDataPresent = {isFilterDataPresent} appliedFilters={appliedFilters} />      
        </Hidden>
        <Grid item xs={12} sm={12} md={9} className={classes.mainJob}>
        <PublicCollegeMain 
        collegeData={collegeData} 
        collegeDataFetched={collegeDataFetched}
        setPageChange={setCurrentPage}
        collegeCount={collegeCount}
        setFilters={setAppliedFilters}
        isFilterDataPresent = {isFilterDataPresent}
        appliedFilters={appliedFilters}
        filterData={filterData}
        /> 
        <Box>
          <Footer/>
        </Box>
        </Grid>
     </Grid>   
     </div>
    </div>
  );
}
import Axios, { AxiosPromise } from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { ThunkAction } from "redux-thunk";
import { TReduxStore } from "../../..";

export const GET_LEADS = "GET_LEADS";
export const CTC_BY_COMPANY = "CTC_BY_COMPANY";
export const DETAILED_CTC = "DETAILED_CTC";
export const GET_SKILLS = "GET_SKILLS";
export const GET_PLACEMENT_BY_DEGREE = "GET_PLACEMENT_BY_DEGREE";
export const GET_PLACEMENT_BY_SPECIALIZATION =
  "GET_PLACEMENT_BY_SPECIALIZATION";
export const SET_REF = "SET_REF";

export interface TAction {
  type: string;
  data?: any;
  additionalDispatchData?: any;
  payload?: any;
}

export const getLeads = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      `${BackendBaseApi.PRAVESH}api/college/reports/leeds_by_industry/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    dispatch({ type: GET_LEADS, payload: res.data.data });
    return res;
  };
};

export const getCtcByCompany = (
  setLoading: any
): ThunkAction<AxiosPromise, TReduxStore, {}, TAction> => {
  return async (dispatch) => {
    setLoading(true);
    const res = await Axios.get(
      `${BackendBaseApi.PRAVESH}api/college/reports/ctc_by_company/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      setLoading(false);
      throw err;
    });
    dispatch({ type: CTC_BY_COMPANY, payload: res.data.data });
    setLoading(false);
    return res;
  };
};

export const getDetailedCtc = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      `${BackendBaseApi.PRAVESH}api/college/reports/ctc_detailed_hired/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    dispatch({ type: DETAILED_CTC, payload: res.data.data });
    return res;
  };
};

export const getSkills = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      `${BackendBaseApi.PRAVESH}api/college/reports/skills/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    dispatch({ type: GET_SKILLS, payload: res.data.data });
    return res;
  };
};

export const getPlacementByDegree = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      `${BackendBaseApi.PRAVESH}api/college/reports/placement_by_degree/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    dispatch({ type: GET_PLACEMENT_BY_DEGREE, payload: res.data.data });
    return res;
  };
};

export const getPlacementBySpecialization = (): ThunkAction<
  AxiosPromise,
  TReduxStore,
  {},
  TAction
> => {
  return async (dispatch) => {
    const res = await Axios.get(
      `${BackendBaseApi.PRAVESH}api/college/reports/placement_by_specialization/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    dispatch({
      type: GET_PLACEMENT_BY_SPECIALIZATION,
      payload: res.data.data,
    });
    return res;
  };
};

export const setRef = (
  ref: React.RefObject<HTMLInputElement>
): ThunkAction<any, TReduxStore, {}, TAction> => {
  return (dispatch) => {
    dispatch({ type: SET_REF, payload: ref });
    return ref;
  };
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 225,
    marginBottom: theme.spacing(1),
    maxWidth: "90%",
    borderRadius: 5,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "90%",
    marginBottom: "12%",
  },
}));

function Media(props) {
  const classes = useStyles();

  return (
    <div>
      <Skeleton animation="wave" variant="rect" className={classes.media} />
      <div className={classes.title}>
        <Skeleton animation="wave" variant="circle" width={25} height={25} />
        <Skeleton
          animation="wave"
          style={{ marginLeft: 5 }}
          height={22}
          width={190}
        />
      </div>
    </div>
  );
}

export default function BlogCardSkeleton() {
  return (
    <div>
      <Media />
    </div>
  );
}

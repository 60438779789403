import {
  Typography,
  Link,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CurrentPlanDetails from "../components/Common/CurrentPlanDetails/CurrentPlanDetails";
import BillingOverview from "../components/Common/CurrentPlanDetails/BillingOverview";
import BillingContact from "../components/Common/CurrentPlanDetails/BillingContact";
import CommonTable from "../components/Common/Table/CommonTable";
import StepperDialog from "../components/Dialog/StepperDialog";
import Plan from "../components/Common/AllPlan/Plan";
import DoneDialog from "../components/Dialog/DoneDialog";
import UploadDialog from "../components/Dialog/UploadDialog";

function Subscriptions() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div>
          <Typography variant="h2">My Subscriptions</Typography>
          <Typography
            variant="body2"
            style={{ color: "#6C757D", fontWeight: "400" }}
          >
            Track all applicants job status
          </Typography>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Plan />
        </Grid>
        <Grid item md={6}>
          <CurrentPlanDetails />
          <br />
          <BillingContact heading=" Key Account Manager" />
        </Grid>
        <Grid item md={6}>
          <BillingOverview />
          <br />
          <BillingContact heading="Billing Contact" />
        </Grid>
        <Grid item md={12}>
          {/* <CommonTable columns={columns} /> */}
          <StepperDialog />
          <DoneDialog />
          <UploadDialog />
        </Grid>
      </Grid>
    </>
  );
}

export default Subscriptions;

const columns = [
  {
    field: "id",
    width: 100,
    displayName: "Invoice ID",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>
        Invoice ID
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        {params.row.id}
      </span>
    ),
  },
  {
    field: "plan",
    width: 120,
    displayName: "Plan Name",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "17px" }}>
        Plan Name
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        {params.row.plan}
      </span>
    ),
  },
  {
    field: "amount",
    width: 120,
    displayName: "Amount",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "7px" }}>Amount</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        {params.row.amount}
      </span>
    ),
  },
  {
    field: "paid_on",
    width: 130,
    displayName: "Paid On",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "2px" }}>Paid On</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        {params.row.paid_on}
      </span>
    ),
  },
  {
    field: "payment_method",
    // type: "text",
    sortable: false,
    displayName: "Payment Method",
    hide: false,
    width: 180,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>
        Payment Method
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "12px",
          }}
        >
          {params.row.payment_method}
        </span>
      </>
    ),
  },
  {
    field: "paid_by",
    width: 150,
    hide: false,
    displayName: "Paid by",
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "4px" }}>Paid by</strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "12px",
          }}
        >
          {params.row.paid_by}
        </span>
      </>
    ),
  },

  {
    field: "action",
    width: 150,
    displayName: "Action",
    hide: false,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Action</strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "12px",
        }}
      >
        <Button
          color="primary"
          variant="text"
          //onClick={() => handleClickOpen(params?.row?.id, params?.row?.name)}
        >
          Download
        </Button>
      </div>
    ),
  },
];

import React from "react";
import Navbar from "../../components/public/Navbar/Navbar";
import MuiCustomTheme from "../components/Theme/theme";
import PublicTrackEmployee from "./PublicTrackEmployee";
import TrackSharerExpiredPopUp from "../../NewAppLayout/components/Dialog/TrackSharerExpiredPopUp";

function PublicTrack() {
  return (
    <>
      <Navbar />
      <MuiCustomTheme>
        <div style={{ margin: 20, marginTop: "80px" }}>
          <TrackSharerExpiredPopUp />
          {/* <PublicTrackEmployee /> */}
        </div>
      </MuiCustomTheme>
    </>
  );
}

export default PublicTrack;

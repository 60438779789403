import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import eg from "../../../../assets/images/banner-bg.jpg";
import eg2 from "../../../../assets/images/company-7.jpg";
import { Box, Button, Grid, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles({
  ImageBox: {
    position: "relative",
    width: "100%",
    height: "229px",
    display: "block",
    borderRadius: "30px",

    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s",
    },
  },
  subText: {
    fontSize: "13px",
    color: "#fff",
    marginTop: "-10px",
  },

  InnerText: {
    display: "block",
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "229px",
    background:
      "linear-gradient(180deg, rgba(255,255,255,0.4) 0%, rgba(0,0,0,0.85) 100%)",
    color: "white",
    borderRadius: "30px",
    paddingTop: "30%",
    paddingLeft: "30px",
  },
  InnerText1: {
    display: "block",
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "229px",
    background:
      "linear-gradient(180deg, rgba(255,255,255,0.4) 0%, rgba(0,0,0,0.85) 100%)",
    color: "white",
    borderRadius: "30px",
    paddingTop: "20%",
    paddingLeft: "30px",
  },
});

export default function ImageGroup(props) {
  const { blog } = props;
  const [blogData, setBlogData] = React.useState([]);
  const collegeID = localStorage.getItem("college_id");

  useEffect(() => {
    if (blog !== undefined) {
      setBlogData(blog.results);
    }
  }, [blog]);
  const classes = useStyles();
  return (
    <div>
      {/* <Box>
        <img src={eg2} alt="" height="239px" width="676px" />
      </Box> */}
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <div>
            {blog.results && (
              <a
                className={classes.ImageBox}
                href={
                  !collegeID
                    ? "/company/blogs/" + blog.results[5].id
                    : "/college/blogs/" + blog.results[5].id
                }
                style={{
                  backgroundImage: `url(${
                    blog.results ? blog.results[5].icon : eg2
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
                }}
                target="blank"
              >
                <span className={classes.InnerText1}>
                  <h4 style={{ marginBottom: "-1px" }}>
                    {blog.results ? blog.results[5].title : ""}
                  </h4>
                  <span className={classes.subText}>
                    by&nbsp;:&nbsp;
                    {blog.results
                      ? blog.results[5].author?.first_name +
                        " " +
                        blog.results[5].author?.last_name
                      : ""}
                  </span>
                </span>
              </a>
            )}
          </div>
        </Grid>
        <Grid item xs={5}>
          <div>
            {blog.results && (
              <a
                className={classes.ImageBox}
                href={
                  !collegeID
                    ? "/company/blogs/" + blog.results[1].id
                    : "/college/blogs/" + blog.results[1].id
                }
                style={{
                  backgroundImage: `url(${
                    blog.results ? blog.results[1].icon : eg2
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
                }}
                target="blank"
              >
                <span className={classes.InnerText}>
                  <h4 style={{ marginBottom: "-1px" }}>
                    {blog.results ? blog.results[1].title : ""}
                  </h4>
                  <span className={classes.subText}>
                    by&nbsp;:&nbsp;
                    {blog.results
                      ? blog.results[1].author?.first_name +
                        " " +
                        blog.results[1].author?.last_name
                      : ""}
                  </span>
                </span>
              </a>
            )}
          </div>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div>
            {blog.results && (
              <a
                className={classes.ImageBox}
                href={
                  !collegeID
                    ? "/company/blogs/" + blog.results[2].id
                    : "/college/blogs/" + blog.results[2].id
                }
                style={{
                  backgroundImage: `url(${
                    blog.results ? blog.results[2].icon : eg2
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
                }}
                target="blank"
              >
                <span className={classes.InnerText}>
                  <h4 style={{ marginBottom: "-1px" }}>
                    {blog.results ? blog.results[2].title : ""}
                  </h4>
                  <span className={classes.subText}>
                    by&nbsp;:&nbsp;
                    {blog.results
                      ? blog.results[2].author?.first_name +
                        " " +
                        blog.results[2].author?.last_name
                      : ""}
                  </span>
                </span>
              </a>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            {blog.results && (
              <a
                className={classes.ImageBox}
                href={
                  !collegeID
                    ? "/company/blogs/" + blog.results[3].id
                    : "/college/blogs/" + blog.results[3].id
                }
                style={{
                  backgroundImage: `url(${
                    blog.results ? blog.results[3].icon : eg2
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
                }}
                target="blank"
              >
                <span className={classes.InnerText}>
                  <h4 style={{ marginBottom: "-1px" }}>
                    {blog.results ? blog.results[3].title : ""}
                  </h4>
                  <span className={classes.subText}>
                    by&nbsp;:&nbsp;
                    {blog.results
                      ? blog.results[3].author?.first_name +
                        " " +
                        blog.results[3].author?.last_name
                      : ""}
                  </span>
                </span>
              </a>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            {blog.results && (
              <a
                className={classes.ImageBox}
                href={
                  !collegeID
                    ? "/company/blogs/" + blog.results[4].id
                    : "/college/blogs/" + blog.results[4].id
                }
                style={{
                  backgroundImage: `url(${
                    blog.results ? blog.results[4].icon : eg2
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
                }}
                target="blank"
              >
                <span className={classes.InnerText}>
                  <h4 style={{ marginBottom: "-1px" }}>
                    {blog.results ? blog.results[4].title : ""}
                  </h4>
                  <span className={classes.subText}>
                    by&nbsp;:&nbsp;
                    {blog.results
                      ? blog.results[4].author?.first_name +
                        " " +
                        blog.results[4].author?.last_name
                      : ""}
                  </span>
                </span>
              </a>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import React from "react";
import AuthHeader from "../../../common/components/UI/AuthHeader";
import SideImage from "../../../common/components/UI/SideImage";

const ActivationPage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div className="col-md-8 col-lg-8 y-scroll full-page-view">
            <div className="row">
              <AuthHeader />
            </div>
            <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-6 mx-auto">
                    {/* <div className="row justify-content-center mx-auto my-2">
                        <i class="fas fa-envelope-square fa-5x"></i>
                        </div> */}
                    <div className="row justify-content-center mx-auto my-2">
                      <p className="fs-18 fw-500">
                        Redirecting you to the desired page
                      </p>
                      <p className="fs-14">
                        Please wait while we complete your signup process Show
                        some loader here
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivationPage;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Form from "../Card/Form";
import { useParams } from "react-router-dom";
import { ButtonBase, Grid, Button } from "@material-ui/core";
import { AndroidView, IOSView } from "react-device-detect";
import google from "../../../../assets/new/google.svg";
import apple from "../../../../assets/new/apple.svg";
import {useHistory} from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "16%",
    // width: 500,
  },
  blackBox: {
    backgroundColor: "black",
    padding: 10,
    color: "#fff",
    marginTop: -5,
  },
  tabs: {
    color: "#007bff",
    backgroundColor: "#fff",
    active_tab: {
      color: "red",
    },
  },
}));

export default function MobileTab(props) {
  const {
    type,
    email,
    setEmail,
    password,
    setPassword,
    loginUser,
    emailErrorMessage,
    setEmailErrorMessage,
    passwordErrorMessage,
    setPasswordErrorMessage,
    responseGoogle,
    responseFacebook,
    loading,
    setButtonClickedLogin,
    buttonClickedLogin,
    checkUser,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const params = useParams();

  useEffect(() => {
    if (params && params?.token) {
      if (params?.token === "student") setValue(0);
      if (params?.token === "college") setValue(1);
      if (params?.token === "employer") return setValue(2);
    } else if (!params.token && params.token === undefined) setValue(0);
    else setValue(0);
  }, [params]);

  return (
    <div className={classes.root}>
      {value === 0 && (
        <Box className={classes.blackBox}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Typography variant="body2" style={{ fontFamily: " Nunito", color: "#fff", fontSize: 14 }}>
                <b>Download the Get Work App to get Exciting Job Alerts!</b>
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
              <AndroidView>
                <a href="https://play.google.com/store/apps/details?id=com.getwork_app" target="_blank">
                  <Button
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 2,
                      padding: "3px 11px 3.5px 11px",
                    }}
                    startIcon={<img src={google} alt="" />}
                  >
                    Download
                  </Button>
                </a>
              </AndroidView>
              <IOSView>
                <a href="https://apps.apple.com/in/app/getwork/id1579830388" target="_blank">
                  <Button
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 2,
                      padding: "3px 11px 3.5px 11px",
                    }}
                    startIcon={<img src={apple} alt="" />}
                  >
                    Download
                  </Button>
                </a>
              </IOSView>
            </Grid>
          </Grid>
        </Box>
      )}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          //  textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label="Students"
            {...a11yProps(0)}
            onClick={() => {
              window.history.pushState({}, null, `/login/student`);
            }}
          />
          <Tab
            label="College"
            {...a11yProps(1)}
            onClick={() => {
              window.history.pushState({}, null, `/login/college`);
            }}
          />
          <Tab
            label="Employer"
            {...a11yProps(2)}
            onClick={() => {
              // window.history.pushState({}, null, `/signup/employer`);
              history.push("/signup/employer");
            }}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Form
            type={"Student"}
            email={email}
            setEmail={setEmail}
            setPassword={setPassword}
            password={password}
            loginUser={loginUser}
            emailErrorMessage={emailErrorMessage}
            passwordErrorMessage={passwordErrorMessage}
            setEmailErrorMessage={setEmailErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            responseGoogle={responseGoogle}
            responseFacebook={responseFacebook}
            loading={loading}
            buttonClicked={buttonClickedLogin}
            setButtonClicked={setButtonClickedLogin}
            checkUser={checkUser}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Form
            type={"College"}
            email={email}
            setEmail={setEmail}
            setPassword={setPassword}
            password={password}
            loginUser={loginUser}
            emailErrorMessage={emailErrorMessage}
            passwordErrorMessage={passwordErrorMessage}
            setEmailErrorMessage={setEmailErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            responseGoogle={responseGoogle}
            responseFacebook={responseFacebook}
            loading={loading}
            buttonClicked={buttonClickedLogin}
            setButtonClicked={setButtonClickedLogin}
            checkUser={checkUser}
          />{" "}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Form
            type={"Company"}
            email={email}
            setEmail={setEmail}
            setPassword={setPassword}
            password={password}
            loginUser={loginUser}
            emailErrorMessage={emailErrorMessage}
            passwordErrorMessage={passwordErrorMessage}
            setEmailErrorMessage={setEmailErrorMessage}
            setPasswordErrorMessage={setPasswordErrorMessage}
            responseGoogle={responseGoogle}
            responseFacebook={responseFacebook}
            loading={loading}
            buttonClicked={buttonClickedLogin}
            setButtonClicked={setButtonClickedLogin}
            checkUser={checkUser}
          />{" "}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

import { Chip, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
// import { EJDJobDeleteAPI, JobDeleteAPI } from "../../../api/JobPost/action";
// import CircularProgressBar from "../../CircularProgressBar";
// import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import fetchApi from "../../../apiCall/fetchApi";
import { BackendBaseApi } from "../../../../constants/constants";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "20px",
  },

  pro: {
    width: "100%",
  },
  button: {
    borderRadius: "30px",
    marginRight: "10px",
    boxShadow: "none",
    padding: "10px",
    height: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    display: "flex",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "12px",
  },
}));

export default function DeleteJobDialog({ selectedRow, setSelectedRow, callBackMainApis }) {
  const classes = useStyles();

  const [deleteReason, setDeleteReason] = useState(null);
  const [deleteReasonError, setDeleteReasonError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (chipToDelete) => () => {
    if (selectedRow.selectedIds?.length > 1) {
      var temp_item_arr = [...selectedRow?.details];
      var temp_id_arr = [...selectedRow?.selectedIds];
      temp_id_arr = temp_id_arr.filter((id) => id !== chipToDelete?.id);
      temp_item_arr = temp_item_arr.filter((obj) => obj?.id !== chipToDelete?.id);
      setSelectedRow({
        ...selectedRow,
        details: temp_item_arr,
        selectedIds: temp_id_arr,
      });
    }
  };
  const [showMore, setShowMore] = React.useState(false);
  const alert = useAlert();
  const handleSubmit = () => {
    if (selectedRow?.details?.every((item) => item.is_editable)) {
      var data = {
        company_id: JSON.parse(localStorage.getItem("company")).company,
        job_ids: selectedRow?.selectedIds,
        status_choices: "DELETE",
      };

      fetchApi({
        url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/job_status_change/`,
        method: "post",
        alert: alert,
        body: JSON.stringify(data),
        setLoading: setLoading,
        callBackFn: callBackMainApis,
      });
    } else alert.error("Applicants have already applied to this job. Can't delete it");
  };

  return (
    <div>
      <Button
        color="primary"
        size="small"
        className={classes.button}
        variant="contained"
        onClick={handleClickOpen}
      >
        Delete
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <div
            style={{
              padding: "10px",
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Typography variant="h6">Are you sure, want to Delete these Jobs?</Typography>
          </div>

          <div component="ul" className={classes.root}>
            {!showMore
              ? selectedRow?.details.slice(0, 5).map((data, index) => {
                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={selectedRow?.details?.length > 1 ? handleDelete(data) : null}
                        deleteIcon={
                          selectedRow?.details?.length > 1 ? <CloseIcon color="primary" /> : null
                        }
                        label={data.job_title}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : selectedRow?.details.map((data, index) => {
                  let icon;

                  return (
                    <li key={data.id}>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={selectedRow?.details?.length > 1 ? handleDelete(data) : null}
                        deleteIcon={
                          selectedRow?.details?.length > 1 ? <CloseIcon color="primary" /> : null
                        }
                        label={data.job_title}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
          </div>

          {selectedRow?.details?.length > 5 && (
            <Button
              className={classes.button}
              onClick={() => {
                setShowMore(!showMore);
              }}
              style={{
                padding: "5px",
                marginLeft: "7px",
                display: "flex",
                justifyContent: "flex-start",
              }}
              endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              color="primary"
            >
              {showMore ? "Hide" : "View All"}
            </Button>
          )}
          {/* <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            multiline
            rows="2"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label={"Why you want to delete these jobs?"}
            value={deleteReason}
            onChange={(e) => {
              setDeleteReason(e.target.value);
              setDeleteReasonError("");
            }}
            error={deleteReasonError ? true : false}
            helperText={<span>{deleteReasonError}</span>}
          /> */}
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              className={classes.button}
              onClick={handleClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: "12px" }}
              className={classes.button}
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? <CircularProgress size={16} style={{ color: "#fff" }} /> : "Delete"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

import { Reducer } from "react";
import {
  GET_LOCATIONS,
  UPDATE_LOCATION_EXPLORE,
  UPDATE_CTC_EXPLORE,
  UPDATE_JOB_SEGMENT,
  GET_JOB_SEGMENT,
  GET_CITY_ARRAY,
  FILTER_COUNT,
  UPDATE_LOCATION_EXPLORE_COLLEGE,
  UPDATE_SIZE,
  UPDATE_INDUSTRY,
  FILTER_COUNT_COLLEGE,
  GET_INDUSTRY,
  TAction,
} from "./action";

export interface InitialStateProps {
  location: LocationProps[];
  industry: IndustryProps[];
  size: any;
  num: number;
}

export interface SegmentProps {
  job_segment_name: string;
  job_segment_id: number;
}

export interface InitialStateExploreProps {
  location: LocationProps[];
  job_segment: SegmentProps[];
  ctc: number;
  num: number;
}

const initialState = {
  location: [],
  job_segment: [],
  ctc: 0,
  num: 0,
};

const initialStateCollege: InitialStateProps = {
  location: [],
  industry: [],
  size: null,
  num: 0,
};

export interface CityListProps {
  [key: string]: any;
}

export interface JobSegmentProps {
  [key: string]: any;
}

export interface IndustryListProps {
  [key: string]: any;
}

export interface LocationProps {
  city: string;
  city_id: number;
}

export interface IndustryProps {
  industry_name: string;
  industry_id: number;
}

export interface CityArrayProps {
  cityArray: LocationProps[];
}

const ExploreFilter: Reducer<InitialStateExploreProps, TAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_LOCATION_EXPLORE:
      return { ...state, location: action.payload };
    case UPDATE_CTC_EXPLORE:
      return { ...state, ctc: action.payload };
    case UPDATE_JOB_SEGMENT:
      return { ...state, job_segment: action.payload };
    case FILTER_COUNT:
      return { ...state, num: action.payload };
    default:
      return state;
  }
};

export const ExploreFilterCollege: Reducer<InitialStateProps, TAction> = (
  state = initialStateCollege,
  action
) => {
  switch (action.type) {
    case UPDATE_LOCATION_EXPLORE_COLLEGE:
      return { ...state, location: action.payload };
    case UPDATE_SIZE:
      return { ...state, size: action.payload };
    case UPDATE_INDUSTRY:
      return { ...state, industry: action.payload };
    case FILTER_COUNT_COLLEGE:
      return { ...state, num: action.payload };
    default:
      return state;
  }
};
export const CityList: Reducer<CityListProps, TAction> = (
  state = { cityList: {} },
  action
) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return { ...state, cityList: action.payload };
    default:
      return state;
  }
};

export const JobSegmentList: Reducer<JobSegmentProps, TAction> = (
  state = { JobSegmentList: {} },
  action
) => {
  switch (action.type) {
    case GET_JOB_SEGMENT:
      return { ...state, JobSegmentList: action.payload };
    default:
      return state;
  }
};
export const IndustryList: Reducer<IndustryListProps, TAction> = (
  state = { IndustryList: {} },
  action
) => {
  switch (action.type) {
    case GET_INDUSTRY:
      return { ...state, IndustryList: action.payload };
    default:
      return state;
  }
};

export const CityArray: Reducer<CityArrayProps, TAction> = (
  state = { cityArray: [] },
  action
) => {
  switch (action.type) {
    case GET_CITY_ARRAY:
      return { ...state, cityArray: action.payload };
    default:
      return state;
  }
};

export default ExploreFilter;

import React from "react";

import { useQuill } from "react-quilljs";
// or const { useQuill } = require('react-quilljs');

import "quill/dist/quill.snow.css"; // Add css for snow theme
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

export default (props) => {
  const { state, setState, desc } = props;
  const placeholder = "Write your description.....";

  const { quill, quillRef } = useQuill({ placeholder });

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta) => {
        const text = quill.getText();
        setState(quill.root.innerHTML);
      });
    }
  }, [quill]);

  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(desc ? desc : "");
    }
  }, [quill, desc]);

  return (
    <>
      <div style={{ width: "100%", height: 150, marginBottom: 80, borderRadius: 20 }}>
        <div ref={quillRef} style={{ borderRadius: "0px 0px 30px 30px" }} />
      </div>
    </>
  );
};

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import BusinessPlan from "./BusinessPlan";
import EnterprisePlan from "./EnterprisePlan";
import MostPopularPlan from "./MostPopularPlan";
import ProfessionalPaln from "./ProfessionalPlan";

function AllPlanGrid() {
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ height: "100vh", display: "flex", alignItems: "center" }}
      >
        <Grid item md={12} style={{ textAlign: "center" }}>
          <Typography variant="h1">Pricing</Typography>
          <Typography variant="h6" style={{ color: "#6C757D" }}>
            GetWork Application Tracking System & Profile Unlocks
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          style={{ padding: "20px" }}
          data-aos="zoom-in-up"
        >
          <ProfessionalPaln />
        </Grid>

        <Grid item xs={12} sm={6} md={3} style={{ padding: "20px" }}>
          <MostPopularPlan />
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{ padding: "20px" }}>
          <BusinessPlan />
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{ padding: "20px" }}>
          <EnterprisePlan />
        </Grid>
      </Grid>
    </>
  );
}

export default AllPlanGrid;

import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Divider, Hidden, IconButton, CircularProgress } from "@material-ui/core";
import loginimg from "../../../../assets/svg/login/Resume-rafiki.svg";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { useAlert } from "react-alert";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as LoginImg } from "../../../../assets/svg/login/Resume-rafiki.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "30px",
  },
  grid: {
    textAlign: "center",
  },
  heading: {
    fontSize: "40px",
  },
  btn: {
    borderRadius: "30px",
    backgroundColor: theme.palette.domain.main,
    color: "#ebf4f8",
    boxShadow: "none",
    width: "350px",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  divider: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 15,
    marginTop: 15,
  },
  upload: {
    borderRadius: "30px",
    // backgroundColor: "#007bff",
    color: theme.palette.domain.main,
    borderColor: theme.palette.domain.main,
    boxShadow: "none",
    width: "350px",
  },
  upload1: {
    borderRadius: "30px",
    // backgroundColor: theme.palette.domain.main,
    color: theme.palette.domain.main,
    borderColor: theme.palette.domain.main,
    boxShadow: "none",
  },
}));

export default function Header({ setResume, resume, loading }) {
  const classes = useStyles();
  const fileInputRef = useRef();
  const alert = useAlert();
  const fileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    if (file.length === 1) {
      const ext = file[0].name.substring(file[0].name.lastIndexOf(".") + 1);
      // if (ext !== 'pdf' && ext !== 'doc' && ext !== 'docx') {
      //     alert.error('Only PDF & DOC Format Allowed!');
      // }
      // else {
      if (file[0].size < 2097152) setResume(file[0]);
      else alert.error("File Size Limit: 2MB");
      // }
    } else {
      alert.error("Drag only one File");
    }
  };

  const fileInputClicked = (e) => {
    fileInputRef.current.click();
  };
  const [tempResume, setTempResume] = useState(null);
  const location = useLocation();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <div>
            <Hidden smDown>
              <Typography className={classes.heading}>Complete your Profile</Typography>
            </Hidden>
            <Hidden smUp>
              <Typography variant="h5">Complete your Profile</Typography>
            </Hidden>

            <br />
            {/* <img src={loginimg} height="400px" width="400px" alt="profile" /> */}
            <LoginImg height="400px" width="400px" fill={domain?.domain ? domain?.color_code : "#007bff"} />
            <br />
            <Typography variant="body2" color="initial">
              Fill all the details required for the Job Application to have better chance of getting short listed
            </Typography>
            <br />
            <Link
              to={
                location.search && location.search.includes("source")
                  ? {
                      pathname: "/student/complete-profile",
                      search: `${location.search}`,
                    }
                  : {
                      pathname: "/student/complete-profile",
                    }
              }
            >
              <Button variant="contained" className={classes.btn}>
                Complete Profile
              </Button>
            </Link>
            <br />
            <div className={classes.divider}>
              <Divider style={{ width: "25%" }} />
              <Typography variant="body2" color="initial" style={{ fontFamily: " Nunito" }}>
                OR
              </Typography>
              <Divider style={{ width: "25%" }} />
            </div>
            <Hidden smDown>
              {tempResume ? (
                <div style={{ display: "flex", flexDirection: "row", margin: "auto", justifyContent: "space-evenly", width: "39%" }}>
                  <div style={{ border: "1px solid #007bff", borderRadius: 30, padding: "0px 60px" }}>
                    <span style={{ color: "#007bff" }}>{tempResume.name}</span>
                    <IconButton onClick={() => setTempResume(null)}>
                      <DeleteIcon style={{ color: "red", fontSize: 18 }} />
                    </IconButton>
                  </div>
                  <Button variant="outlined" className={classes.upload1} startIcon={<PublishOutlinedIcon />} onClick={() => setResume(tempResume)}>
                    {loading ? <CircularProgress style={{ color: "#007bff" }} size={17} /> : "Upload Now"}
                  </Button>
                </div>
              ) : (
                <>
                  <Button variant="outlined" className={classes.upload} startIcon={<PublishOutlinedIcon />} onClick={(e) => fileInputClicked(e)}>
                    Upload Resume to Apply
                  </Button>
                  <input
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    id="file"
                    onChange={(e) => {
                      if (!e.target.files[0]) return;
                      const ext = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".") + 1);
                      if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
                        alert.error("Only PDF & DOC Format Allowed!");
                        return;
                      }
                      if (e.target.files[0].size < 2097152) setTempResume(e.target.files[0]);
                      else alert.error("File Size Limit: 2MB");
                      }}
                      accept=".docx,.pdf"
                  />
                </>
              )}
            </Hidden>

            <Hidden smUp>
              {tempResume ? (
                <>
                  <div style={{ border: "1px solid #007bff", borderRadius: 30, padding: "5px 60px", marginBottom: 15 }}>
                    <span style={{ color: "#007bff" }}>{tempResume.name}</span>
                  </div>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "30px",
                      // backgroundColor: "#007bff",
                      color: "red",
                      borderColor: "red",
                      boxShadow: "none",
                    }}
                    startIcon={<DeleteIcon />}
                    onClick={() => setTempResume(null)}
                  >
                    Delete
                  </Button>
                  &nbsp; &nbsp;
                  <Button variant="outlined" className={classes.upload1} startIcon={<PublishOutlinedIcon />} onClick={() => setResume(tempResume)}>
                    {loading ? <CircularProgress style={{ color: "#007bff" }} size={17} /> : "Upload Now"}
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="outlined" className={classes.upload} startIcon={<PublishOutlinedIcon />} onClick={(e) => fileInputClicked(e)}>
                    Upload Resume to Apply
                  </Button>
                  <input
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    id="file"
                    onChange={(e) => {
                      if (!e.target.files[0]) return;
                      const ext = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".") + 1);
                      if (ext !== "pdf" && ext !== "doc" && ext !== "docx") {
                        alert.error("Only PDF & DOC Format Allowed!");
                        return;
                      }
                      if (e.target.files[0].size < 2097152) setTempResume(e.target.files[0]);
                      else alert.error("File Size Limit: 2MB");
                    }}
                    accept=".docx,.pdf"
                  />
                </>
              )}
            </Hidden>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

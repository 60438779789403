import React, { useState } from 'react';

const ClipBoard = ({password}) => {
const [copySuccess, setCopySuccess] = useState('');

// your function to copy here

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
      setTimeout(()=>{
        setCopySuccess('')
      },2000)
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

return (
 <div>
    <i onClick={() => copyToClipBoard(password)} className="far fa-copy edit-icon fs-20"></i>
  {/* // after copying see the message here */}
  <span className="text-muted fs-12">{copySuccess}</span>
 </div>
)
}

export default ClipBoard;
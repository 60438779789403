import {
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { TReduxStore } from "../../..";
import logout from "../../../assets/common/arrow.svg";
import user from "../../../assets/common/business-man.svg";
import college_profile from "../../../assets/common/college_profile.svg";
import publicview from "../../../assets/common/college_public_profile.svg";
import Explore_icon from "../../../assets/common/explore_icon.png";
import Home_icon from "../../../assets/common/home_icon.png";
import companysetting from "../../../assets/common/settings.svg";
import PaymentLoading from "../../../assets/payment-loading.gif";
import { BackendBaseApi } from "../../../constants/constants";
import { getUserDetails } from "../../../pages/college/Dashboard/action";
import copy from "../../../assets/copy.png";
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";

interface NavBarProps {
  setDarkState: any;
  darkState: boolean;
}

const CollegeNavbar = (Props: NavBarProps) => {
  const { setDarkState, darkState } = Props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const profilePic = localStorage.getItem("profile_pic");
  const alert = useAlert();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector<TReduxStore, any>((state) => {
    //@ts-ignore
    return state.UserDetails?.allDetails;
  });
  //@ts-ignore
  let { job_id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    // if (Object.keys(userDetails).length === 0) {
    dispatch(getUserDetails(history as any, alert as any, localStorage.getItem("user_type_id"), setLoading as any));
    // }
  }, []);

  useEffect(() => {
    location.pathname === "/college/track"
      ? setPageTitle("Track")
      : location.pathname === "/college/track/"
        ? setPageTitle("Track")
        : location.pathname === "/college/reports"
          ? setPageTitle("Reports")
          : location.pathname === "/college/open-job"
            ? setPageTitle("Open Jobs")
            : location.pathname === "/company/post-job"
              ? setPageTitle("Create Job")
              : location.pathname === "/college/invite"
                ? setPageTitle("Invite Companies")
                : location.pathname === "/college/new-job"
                  ? setPageTitle("Inbound Jobs")
                  : location.pathname === "/college/drafted-job"
                    ? setPageTitle("Drafted Jobs")
                    : location.pathname === "/college/close-job"
                      ? setPageTitle("Closed Jobs")
                      : location.pathname === "/college/company-connection"
                        ? setPageTitle("Companies")
                        : location.pathname === "/student/scheduled"
                          ? setPageTitle("Students")
                          : location.pathname === "/college/students_connections"
                            ? setPageTitle("Student Data")
                            : location.pathname === "/college/upload_student_data"
                              ? setPageTitle("Upload Student Data")
                              : location.pathname === "/college/post-job"
                                ? setPageTitle("Post Job")
                                : location.pathname === `/college/post-job/${job_id}`
                                  ? setPageTitle("Post Job")
                                  : location.pathname === "/college/profile"
                                    ? setPageTitle("Profile")
                                    : location.pathname === "/college/profile_update"
                                      ? setPageTitle("Update Profile")
                                      : location.pathname === "/public/college/profile"
                                        ? setPageTitle("View Profile")
                                        : location.pathname === "/college/recommended-job"
                                          ? setPageTitle("GetWork Listing")
                                          : location.pathname === "/college/college-settings/"
                                            ? setPageTitle("College Settings")
                                            : location.pathname === "/college/user-settings/"
                                              ? setPageTitle("User Settings")
                                              : location.pathname === "/college/interviews"
                                                ? setPageTitle("Interviews")
                                                : setPageTitle("");
  }, [location.pathname]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget as any);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Logout = () => {
    Axios.post(BackendBaseApi.PRAVESH + "api/logout/", null, {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
      withCredentials: true
    })
      .then((res) => {
        if (res.data.success) {
          localStorage.clear();
          history.push("/login/college");
          alert.success("You have been Logged out successfuly");
        }
        // dispatch({ type: "USER_LOGOUT" });
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/login/college");
        alert.success("You have been Logged out successfuly");
      });
  };

  return (
    <Fragment>
      <AppBar position="fixed" className={location.pathname.split("/")[1] === "public" ? classes.appBar_public : classes.appBar}>
        <Toolbar className={classes.toolBar}>
          {location.pathname === "/college/explore" || (location.pathname !== "/college/dashboard" && <Typography variant="h4">{pageTitle}</Typography>)}
          <Paper
            className={classes.navbar}
            style={{
              //  marginTop: "20px",
              boxShadow: "none",

              // borderBottom: "4px solid #3282C4",
            }}
          >
            <StyledTabs
              // className={classes.root1}
              // @ts-ignore
              value={location.pathname === "/college/explore" ? 1 : 0}
              // onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            //  style={{ borderBottom: "4px solid #3282C4" }}
            >
              {(location.pathname === "/college/explore" || location.pathname === "/college/dashboard") && (
                <>
                  <Link to="/college/dashboard">
                    <StyledTab
                      // @ts-ignore
                      label={
                        <>
                          <img src={Home_icon} alt="home_icon" height="28px" />
                          {"   "}&nbsp; Home
                        </>
                      }
                    />
                  </Link>
                  <Link to="/college/explore">
                    <StyledTab
                      // @ts-ignore
                      label={
                        <>
                          <img
                            src={Explore_icon}
                            // style={{ fontSize: "inherit" }}
                            alt=""
                            height="28px"
                          />
                          {"   "}
                          &nbsp; Explore Now
                        </>
                      }
                    />
                  </Link>

                  {/* </StyledTabs> */}
                </>
              )}
              // @ts-ignore
              {localStorage.getItem("college_id") && (
                <CopyToClipboard text={localStorage.getItem("college_id")} onCopy={() => alert.info("College Passcode Copied!")}>
                  <StyledTab
                    // @ts-ignore
                    label={
                      <>
                        <img
                          src={copy}
                          // style={{ fontSize: "inherit" }}
                          alt=""
                          height="28px"
                        />
                        {"   "}
                        &nbsp; College Passcode: {localStorage.getItem("college_id") ? localStorage.getItem("college_id") : "NA"}
                      </>
                    }
                  />
                </CopyToClipboard>
              )}
            </StyledTabs>
          </Paper>
          <Box display="flex" alignItems="center">
            {/* {location.pathname.split("/")[1] !== "public" ? (
              <Tooltip title={<b>toggle light/dark theme</b>} placement="bottom" arrow>
                {theme.palette.type === "dark" ? (
                  <IconButton onClick={() => setDarkState(!darkState)} className={classes.themeButton}>
                    <BrightnessHighIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setDarkState(!darkState)} className={classes.themeButton}>
                    <Brightness4Icon />
                  </IconButton>
                )}
              </Tooltip>
            ) : null} */}
            {localStorage.getItem("gw_token") ? (
              <>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ marginRight: "10px" }}>
                    {/* @ts-ignore */}
                    {userDetails?.first_name && userDetails?.last_name ? (
                      userDetails?.first_name + " " + userDetails?.last_name
                    ) : (
                      <img src={PaymentLoading} width="40px" height="auto" style={{ marginLeft: "5px" }} />
                    )}
                  </Typography>
                  {loading ? (
                    <CircularProgress size={25} />
                  ) : (
                    <Avatar aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.avatar} src={profilePic as string} />
                  )}

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    style={{ marginTop: "43px", marginLeft: "40px" }}
                  >
                    {/* <Link to="/college/profile">
                    <MenuItem onClick={handleClose}>
                      <img src={publicview} alt="" height="18px" style={{ margin: "0px 15px 0px 0px" }} />
                      <Typography variant="body1">College Profile</Typography>
                    </MenuItem>
                  </Link> */}
                    <Link target="_blank" to={`/public/college/profile/${localStorage.getItem("college_id")}`}>
                      <MenuItem onClick={handleClose}>
                        <img src={college_profile} alt="" height="18px" style={{ margin: "0px 15px 0px 0px" }} />

                        <Typography variant="body1">College Public Profile</Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/college/user-settings">
                      <MenuItem onClick={handleClose}>
                        <img src={user} alt="" height="18px" style={{ margin: "0px 15px 0px 0px" }} />
                        <Typography variant="body1">User Settings</Typography>
                      </MenuItem>
                    </Link>

                    <Link to="/college/college-settings">
                      <MenuItem onClick={handleClose}>
                        <img src={companysetting} alt="" height="18px" style={{ margin: "0px 15px 0px 0px" }} />
                        <Typography variant="body1">College Settings</Typography>
                      </MenuItem>
                    </Link>
                    <Divider />
                    <MenuItem className={classes.logoutText} onClick={() => Logout()}>
                      <img src={logout} alt="" height="18px" style={{ margin: "0px 12px 0px 4px" }} />
                      <Typography variant="body1">Sign Out</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            ) : (
              <Link to="/login">
                <Button variant="contained" className={classes.postButton}>
                  LogIn
                </Button>
              </Link>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

const StyledTabs = withStyles({
  indicator: {
    //display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    //borderBottom: "4px solid #3282C4",

    "& > span": {
      width: "100%",
      backgroundColor: "none",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme: Theme) => ({
  root: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
    "&:hover": {
      //  borderBottom: "4px solid #3282C4",
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)",
      borderRadius: "30px",
    },

    // "&:focus": {
    //   opacity: 1,
    // },
  },
  wrapper: {
    display: "block",
    color: theme.palette.type === "dark" ? "#ffffff" : "#000000",
    flexDirection: "column",
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      marginLeft: "220px",
      width: `calc(100% - 250px)`,
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      height: "64px",
      //backgroundColor: theme.palette.background.paper,
      // width: "82%",
      marginRight: "15px",
      borderRadius: "30px",
      backdropFilter: "blur(30px)",
      // boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
    },
    appBar_public: {
      marginLeft: "220px",
      width: "100%",
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      height: "64px",
      backgroundColor: theme.palette.background.paper,
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      //backgroundColor: theme.palette.background.paper,
    },

    logoutText: {
      fontSize: "18px",
      fontFamily: "ProximaNova",
      color: theme.palette.error.main,
    },
    avatar: {
      cursor: "pointer",
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      alignItems: "left",
      width: "60%",
    },
    themeButton: {
      marginRight: "10px",
      // "&:focus": {
      //   outline: "none",
      // },
    },
    navbar: {
      textTransform: "none",
      borderBottom: "none",
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(15),
      marginTop: "-2px",
      marginRight: theme.spacing(1),
      color: "black",
      // "&:focus": {
      //   opacity: 1,
      // },
    },
  })
);

export default CollegeNavbar;

import { Box, Button, ButtonBase, Chip, createStyles, Divider, Grid, IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import mail from "../../../assets/mail.png";
import phone from "../../../assets/phone.png";
import Iron from "../../../assets/common/ironman.png";
import download1 from "../../../assets/download1.png";
import bullet from "../../../assets/bullets.png";
import web from "../../../assets/web.png";
import moment from "moment";
import BootstrapTooltip from "../../../common/Tooltip/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";
import Skeleton from "@material-ui/lab/Skeleton";
import facebook from "../../../assets/common/facebook.png";
import github from "../../../assets/common/github.png";
import linkedin from "../../../assets/common/linkedin.png";
import cancel from "../../../assets/cancel.png";
import shortlist from "../../../assets/shortlist.png";
import CommonChip from "../../CommonChip/CommonChip";

export default function ApplicantsProfile(props) {
  const { currentData, applicantsPanel, changeStatus, setMoveButtonClicked, setRejectButtonClicked } = props;
  const classes = useStyles();
  const alert = useAlert();
  const checkClearBitDomain = (name) => {
    if (name === "LinkedIn") {
      return ".cn";
    } else return ".com";
  };

  var link_icon;
  var link;

  const checkSocialLinkImage = (name) => {
    switch (name) {
      case "LinkedIn":
        link_icon = linkedin;
        return link_icon;

      case "Github":
        link_icon = github;
        return link_icon;

      case "Facebook":
        link_icon = facebook;
        return link_icon;

      default:
        break;
    }
  };

  const checkSocialLink = (name, value) => {
    switch (name) {
      case "LinkedIn":
        link = value;
        return link;

      case "Github":
        link = value;
        return link;

      case "Facebook":
        link = value;
        return link;

      default:
        break;
    }
  };

  return (
    <>
      <Box className={applicantsPanel ? classes.profileBoxApplicants : classes.profileBox}>
        <Box display="flex" flexDirection="row-reverse">
          {currentData?.user_details?.phone && (
            <BootstrapTooltip title={currentData?.user_details.phone}>
              <CopyToClipboard text={currentData?.user_details && currentData?.user_details.phone}>
                <img
                  src={phone}
                  alt=""
                  height="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    alert.success("Phone Number Copied!");
                  }}
                />
              </CopyToClipboard>
            </BootstrapTooltip>
          )}
          {currentData?.user_details?.email && (
            <BootstrapTooltip title={currentData?.user_details.email}>
              <CopyToClipboard text={currentData?.user_details && currentData?.user_details.email}>
                <img
                  src={mail}
                  alt=""
                  height="25px"
                  style={{ marginRight: 10, cursor: "pointer" }}
                  onClick={() => {
                    alert.success("Email ID Copied!");
                  }}
                />
              </CopyToClipboard>
            </BootstrapTooltip>
          )}
        </Box>
        <Grid container>
          <Grid item xs={applicantsPanel ? 4 : 5}>
            <ButtonBase className={classes.image} disabled>
              <img className={classes.img} alt="complex" src={currentData?.user_details?.profile_picture ? currentData?.user_details.profile_picture : Iron} />{" "}
            </ButtonBase>
          </Grid>
          <Grid
            item
            xs={applicantsPanel ? 8 : 7}
            style={{
              marginTop: 10,
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p className={classes.title}> {currentData?.user_details.first_name + " " + currentData?.user_details.last_name}</p>
            <p className={classes.subTitle}>{currentData?.user_details.current_city ? currentData?.user_details.current_city : ""}</p>
            {currentData?.user_details.education.find((item) => item.is_current === true) && (
              <p className={classes.subTitle}>
                {currentData?.user_details.education.find((item) => item.is_current === true).temp_college_name !== null &&
                currentData?.user_details.education.find((item) => item.is_current === true).college === 142
                  ? currentData?.user_details.education.find((item) => item.is_current === true).temp_college_name
                  : currentData?.user_details.education.find((item) => item.is_current === true).college_name &&
                    currentData?.user_details.education.find((item) => item.is_current === true).college_name}{" "}
              </p>
            )}
            <Box
              display="flex"
              flexDirection="row"
              style={{
                marginTop: "-10px",
                marginLeft: "-12px",
              }}
            >
              {currentData?.user_details?.resume && (
                <BootstrapTooltip title="Download Resume">
                  <IconButton
                    className={classes.margin}
                    aria-label="delete"
                    onClick={() => {
                      window.open(currentData?.user_details?.resume);
                    }}
                  >
                    <img height="30px" src={download1} />
                  </IconButton>
                </BootstrapTooltip>
              )}

              {currentData?.user_details ? (
                <>
                  {currentData?.user_details.profile_url !== null &&
                    currentData?.user_details.profile_url.map((item, ind) => {
                      return (
                        <BootstrapTooltip title={item.name}>
                          <IconButton
                            className={classes.margin}
                            aria-label="delete"
                            onClick={() => {
                              window.open(checkSocialLink(item.name, item.value));
                            }}
                          >
                            <img height="30px" src={checkSocialLinkImage(item.name)} />
                          </IconButton>
                        </BootstrapTooltip>
                      );
                    })}
                </>
              ) : (
                <Skeleton />
              )}
            </Box>
            {applicantsPanel && (
              <Box marginTop="5%">
                {changeStatus ? (
                  <>
                    <span className="fs-12 text-info fw-500">Changing Status...</span>
                  </>
                ) : currentData?.user_details ? (
                  currentData?.status == 1 ? (
                    <>
                      <Button
                        onClick={() => setMoveButtonClicked(true)}
                        className={classes.nextButton}
                        style={{ marginRight: 10 }}
                        startIcon={<img src={shortlist} alt="" height="20px" />}
                      >
                        <b style={{ fontSize: 12 }}>Shortlist</b>
                      </Button>
                      <Button onClick={() => setRejectButtonClicked(true)} className={classes.rejectButton} startIcon={<img src={cancel} alt="" height="20px" />}>
                        <b style={{ fontSize: 12 }}>Reject</b>
                      </Button>
                    </>
                  ) : (
                    <>
                      <p className="fs-14 fw-500">
                        Status: <span className="fs-13 gray-2">{currentData?.status_name}</span>
                      </p>
                    </>
                  )
                ) : (
                  <Skeleton />
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box className={applicantsPanel ? classes.educationBoxApplicants : classes.educationBox}>
        {currentData?.user_details.about && (
          <>
            <p className={classes.newTitle}>About</p>
            <p className={classes.subTitle}>
              This Company Logo Image API allows you to quickly look up a company via its domain name. Simply type in the company’s URL, and we’ll return the company’s logo.
            </p>
          </>
        )}
        {currentData?.user_details.skill && currentData?.user_details.skill.length > 0 && (
          <>
            <p className={classes.newTitle} style={{ marginTop: 17 }}>
              Skills
            </p>
            {currentData?.user_details.skill.map((item) => (
              <CommonChip
                label={<span style={{ fontSize: 12 }}>{item.skill_name}</span>}
                // className={classes.root1}
                style={{ marginRight: "1%", marginBottom: "1%" }}
              />
            ))}
          </>
        )}
        <p className={classes.newTitle} style={{ marginTop: 17 }}>
          Education
        </p>
        {currentData?.user_details.education.map((item) => (
          <>
            {item.degree && (
              <Grid container>
                <Grid item xs={1}>
                  <img src={bullet} alt="" className={classes.listIcon} />
                </Grid>
                <Grid item xs={11}>
                  <p className={classes.educationTitle}>
                    {item.degree}

                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "normal",
                        color: "grey",
                      }}
                    >
                      {item?.start_date && item?.start_date !== null && (
                        <>
                          {" ("}
                          {moment(item?.start_date).format("DD MMM, YYYY")}{" "}
                          {item.end_date && item.end_date !== null && (
                            <>
                              {" to "}
                              {moment(item?.end_date).format("DD MMM, YYYY")}{" "}
                            </>
                          )}
                          {") "}
                        </>
                      )}
                    </span>
                  </p>
                  {localStorage.getItem("user_type") === "College" && (
                    <>
                      { item.specialization && <p className={classes.subTitle}>{item.specialization ? item.specialization : "Specialization NA"}</p> }
                      <p className={classes.subTitle}>{(item.college_name ? item.college_name : "College NA") + ", " + item.college_location}</p>
                      {(item.percentage && item.percentage !== null && item.percentage !== 0) ? (
                        <p className={classes.subTitle}>
                          Percentage:&nbsp;
                          {item.percentage + " %"}
                        </p>
                      ) : null}
                      {(item.cgpa && item.cgpa !== null && item.cgpa !== 0) ? (
                        <p className={classes.subTitle}>
                          CGPA:&nbsp;
                          {item.cgpa}
                        </p>
                          ) : null
                      }
                    </>
                  )}
                  {localStorage.getItem("user_type") === "Company" && (
                    <>
                      {item.specialization && <p className={classes.subTitle}>{item.specialization ? item.specialization : "Specialization NA"}</p>}
                      <p className={classes.subTitle}>{(item.college_name ? item.college_name : "College NA") + ", " + item.college_location}</p>
                      {(item.percentage && item.percentage !== null && item.percentage !== 0) ? (
                        <p className={classes.subTitle}>
                          Percentage:&nbsp;
                          {item.percentage + " %"}
                        </p>
                      ) : null}
                      {(item.cgpa && item.cgpa !== null && item.cgpa !== 0) ? (
                        <p className={classes.subTitle}>
                          Percentage:&nbsp;
                          {item.cgpa * item.percentage_factor} %
                        </p>
                      ) : null}
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        ))}
        {currentData?.user_details.work_experience && currentData?.user_details.work_experience.length > 0 && (
          <>
            <p className={classes.newTitle} style={{ marginTop: 17 }}>
              Experience
            </p>
            {currentData?.user_details.work_experience.map((item, index) => (
              <Grid container>
                <Grid item xs={1}>
                  <img src={bullet} alt="" className={classes.listIcon} />
                </Grid>
                <Grid item xs={11}>
                  <p className={classes.educationTitle}>
                    {" "}
                    {item.company_name}
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "normal",
                        color: "grey",
                      }}
                    >
                      {item?.start_date && item?.start_date !== null && (
                        <>
                          {" ("}
                          {moment(item.start_date).format("DD MMM, YYYY")}{" "}
                          {item.end_date && item.end_date !== null && (
                            <>
                              {" to "}
                              {moment(item.end_date).format("DD MMM, YYYY")}{" "}
                            </>
                          )}
                          {item.work_month !== 0 ? " - " + item.work_month + " months" : " - Fresher"}
                          {") "}
                        </>
                      )}
                    </span>
                  </p>
                  <p className={classes.subTitle}>
                    <a href={item.company_website} target="blank" style={{ color: "#034e9f" }}>
                      {item.company_website}
                    </a>
                  </p>
                  <p className={classes.subTitle}>{item.job_designation}</p>
                  <p className={classes.subTitle} style={{ marginTop: 5, textAlign: "justify" }}>
                    {item.job_description}
                  </p>
                </Grid>
              </Grid>
            ))}
          </>
        )}

        {(currentData?.user_details.cur_ctc || currentData?.user_details.expected_ctc || currentData?.user_details.notice_period) && (
          <>
            <p className={classes.newTitle} style={{ marginTop: 17 }}>
              Salary Expectation
            </p>
            <Grid container>
              <ul>
                {currentData?.user_details.cur_ctc ? (
                  <li className={classes.educationTitle}>
                    Current CTC:
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "normal",
                        color: "grey",
                      }}
                    >
                      &nbsp;{currentData?.user_details.cur_ctc} Lacs
                    </span>
                  </li>
                ) : null}
                {currentData?.user_details.expected_ctc ? (
                  <li className={classes.educationTitle}>
                    Expected CTC:
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "normal",
                        color: "grey",
                      }}
                    >
                      &nbsp;{currentData?.user_details.expected_ctc} Lacs
                    </span>
                  </li>
                ) : null}
                {currentData?.user_details.notice_period ? (
                  <li className={classes.educationTitle}>
                    Notice Period:
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "normal",
                        color: "grey",
                      }}
                    >
                      &nbsp;{currentData?.user_details.notice_period} days
                    </span>
                  </li>
                ) : null}
              </ul>
            </Grid>
          </>
        )}

        {currentData?.user_details.project && currentData?.user_details.project.length > 0 && (
          <>
            <p className={classes.newTitle} style={{ marginTop: 17 }}>
              Projects
            </p>
            {currentData?.user_details.project.map((item, index) => (
              <Grid container>
                <Grid item xs={1}>
                  <img src={bullet} alt="" className={classes.listIcon} />
                </Grid>
                <Grid item xs={11}>
                  <p className={classes.educationTitle}>
                    {" "}
                    {item.title}
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "normal",
                        color: "grey",
                      }}
                    >
                      {item?.start_date && item?.start_date !== null && (
                        <>
                          {" ("}
                          {moment(item.start_date).format("DD MMM, YYYY")}{" "}
                          {item.end_date && item.end_date !== null && (
                            <>
                              {" to "}
                              {moment(item.end_date).format("DD MMM, YYYY")}{" "}
                            </>
                          )}
                          {item.work_month && " - " + item.work_month + " months"}
                          {") "}
                        </>
                      )}
                    </span>
                  </p>
                  <p className={classes.subTitle}>
                    <a href={item.links} target="blank" style={{ color: "#034e9f" }}>
                      {item.links}
                    </a>
                  </p>
                  <p className={classes.subTitle} style={{ marginTop: 5, textAlign: "justify" }}>
                    {item.description}
                  </p>
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    profileBox: {
      padding: 20,
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: "30px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      marginBottom: 20,
      marginTop: 10,
    },
    educationBox: {
      padding: 20,
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: "30px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      marginBottom: 20,
      marginTop: 10,
    },
    profileBoxApplicants: {
      padding: 20,
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: "25px",
      // backdropFilter: "blur(30px)",
      // boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      marginBottom: 20,
      marginTop: 56,
    },
    educationBoxApplicants: {
      padding: 20,
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: "25px",
      // backdropFilter: "blur(30px)",
      // boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      marginBottom: 20,
      marginTop: 10,
    },
    img: {
      margin: "auto",
      display: "block",
      height: "155px",
      width: "155px",

      borderRadius: "50%",
    },
    title: {
      fontSize: 19,
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    newTitle: {
      fontSize: 16,
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    educationTitle: {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    subTitle: {
      fontSize: 12,
      marginTop: -15,
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      fontSize: 12,
    },
    listIcon: {
      marginBottom: 2,
      marginRight: 8,
      height: "20px",
    },

    nextButton: {
      backgroundColor: "#76e5ac",
      border: "1px solid #145f38",
      height: "36px",
      borderRadius: 30,
      color: "#145f38",
      width: "33%",
      height: 30,
      "&:hover": {
        backgroundColor: "#5dd698",
      },
    },
    rejectButton: {
      backgroundColor: "rgb(249 188 202)",
      border: "1px solid rgb(188 12 30 / 1)",
      height: "36px",
      borderRadius: 30,
      width: "33%",
      height: 30,
      color: "rgb(188 12 30 / 1)",
      "&:hover": {
        backgroundColor: "#ff86a2",
      },
    },
  })
);

import { makeStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, CardBody, Table } from "reactstrap";
import notify from "../../../../../assets/common/no_notification.gif";
import cup from "../../../../../assets/common/no_task.gif";
import arrow from "../../../../../assets/images/arrow1.png";
import WarningBox from "../../../../../components/common/WarningBox/WarningBox";
import PurchasePlanDialog from "../../../../../NewAppLayout/components/Dialog/PurchasePlanDialog";
import NewSignUpDialog from "../../../../../NewAppLayout/components/Dialog/NewSignUpDialog";
import CompanyNewViewPopUp from "../../../../../NewAppLayout/components/Dialog/CompanyNewRecruiter";
import TempPlan from "../../../../../NewAppLayout/components/TempMySubscriptionComponents/TempPlan";
import "./Dashboard_new.css";

const useStyles = makeStyles({
  tableWhite: {
    background: "white",
    border: "hidden",
    "&:hover": {
      background: "#e1e1e1",
    },
  },
  tableBlue: {
    background: "#EEFBFF",
    border: "hidden",
    "&:hover": {
      background: "#e1e1e1",
    },
  },
  tableWhiteNoHover: {
    background: "white",
    border: "hidden",
    "&:hover": {
      background: "#fff",
    },
  },
  tableBlueNoHover: {
    background: "#EEFBFF",
    border: "hidden",
    "&:hover": {
      background: "#EEFBFF",
    },
  },
  paper2: {
    textAlign: "center",
    //  height: "270px",

    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#ffffff",
    borderRadius: "30px",
  },
  Button: {
    borderRadius: "30px",
    marginBottom: "12px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #f1f1f1",
    backgroundColor: "#32B2F9",
  },
  warningBox: {
    borderRadius: "30px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    borderBottom: "solid 4px #fad196",
    backgroundColor: "#fff5ea",
    marginBottom: 30,
    padding: "10px 10px 0px 10px",
  },
  warningHover: {
    "&:hover": {
      cursor: "pointer",
      color: "rgb(50, 130, 196)",
    },
  },
  anchorStyle: {
    color: "#007BFF",
  },
});

export const DashboardNew = () => {
  const history = useHistory();
  let user_type_id = localStorage.getItem("user_type_id");

  var today = new Date(),
    date =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();
  const [newjobs, setNewJobs] = useState([]);
  const [recruitment, setRecruit] = useState([]);
  const [hired, setHired] = useState();
  const [totaljobs, setTotalJobs] = useState();
  const [openjobs, setOpenJobs] = useState();
  const [conCollege, setConnectedCollege] = useState([]);
  const [penCollege, setPendingCollege] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [banner, setBanner] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [check, setCheck] = useState(false);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const classes = useStyles();

  const companyUserDetails = useSelector(
    (state) => state?.CompanyUserDetails?.companyUserDetails
  );

  const availableCredits = useSelector(
    (state) => state?.CreditInfo?.profileUnlockInfo?.available_credits
  );

  const profileUnlockInfo = useSelector(
    (state) => state?.CreditInfo?.profileUnlockInfo
  );

  useEffect(() => {
    if (companyUserDetails) {
      let arr = [];

      if (
        companyUserDetails.company_user_details.recruitment_progress &&
        companyUserDetails.company_user_details.recruitment_progress.length > 0
      )
        companyUserDetails.company_user_details.recruitment_progress.forEach(
          (data) => {
            arr = [...arr, ...data.applicants];

            setApplicants(arr);
          }
        );

      setNewJobs(companyUserDetails?.company_user_details?.new_jobs);
      setRecruit(
        companyUserDetails?.company_user_details?.recruitment_progress
      );
      setHired(companyUserDetails?.company_user_details?.total_hired);
      setTotalJobs(
        companyUserDetails?.company_user_details?.get_jobs_count?.total_jobs
      );
      setOpenJobs(
        companyUserDetails?.company_user_details?.get_jobs_count?.total_open_jobs
      );
      setConnectedCollege(
        companyUserDetails?.company_user_details?.connected_college
      );
      setPendingCollege(
        companyUserDetails?.company_user_details?.pending_college
      );
      setNotifications(
        companyUserDetails?.company_user_details?.notifications.invites
      );
      setBanner(
        companyUserDetails?.company_user_details?.banner_image?.length > 0 &&
          companyUserDetails?.company_user_details?.banner_image[0].banner_path
      );
      setTasks(companyUserDetails?.company_user_details?.tasks.interview);
      if (
        creditInfo.credit_status == "INACTIVE" &&
        localStorage.getItem("comp_pref_filled") === "true"
      ) {
        setOpenActivateModal(true);
      }
    }
  }, [companyUserDetails]);

  useEffect(() => {
    let item = recruitment[3];
    var temp = recruitment.filter((item1) => {
      return item1?.job_id === item?.job_id;
    });
    for (let i = 0; i < temp.length; i++) {
      setApplicants(temp[i].applicants);
    }
  }, [recruitment]);

  const handleDropdown = (e) => {
    var i;
    var arr = [];

    if (e.target.value == "All") {
      for (i = 0; i < recruitment.length; i++) {
        arr = [...arr, ...recruitment[i].applicants];

        setApplicants(arr);
      }
    } else {
      var temp = recruitment?.filter((item) => {
        console.log(typeof item?.job_id, typeof e.target.value);
        return item?.job_id.toString() === e.target.value;
      });

      for (i = 0; i < temp.length; i++) {
        setApplicants(temp[i]?.applicants);
      }
    }
  };

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
  };

  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className="container"
        style={{
          maxWidth: "1240px",
          paddingLeft: "3%",
          paddingRight: "3%",
          marginTop: "20px",
        }}
      >
        <WarningBox dashboard={true} />
        {/* <br />

    
      <br /> */}
        {/*  {sessionStorage.getItem("show") === null && availableCredits < 100 && (
          <TempPlan planExpired={availableCredits === 0 ? true : false} />
        )} */}

        {sessionStorage.getItem("show") === null &&
        (profileUnlockInfo?.code === 1 || profileUnlockInfo?.code === 2) &&
        (availableCredits < 10 || profileUnlockInfo?.days_remaining < 10) ? (
          <TempPlan fromOverview={true} />
        ) : (
          sessionStorage.getItem("plan") === null &&
          profileUnlockInfo?.code === 3 && (
            <PurchasePlanDialog fromOverview={true} />
          )
        )}
        {/* {
          !totaljobs ? 
          <NewSignUpDialog fromOverview={true} /> : null
          // <CompanyNewViewPopUp fromOverview={true} /> : null
        // } */}

        <div className="row1 row">
          <div className="col-lg-7">
            {/* --------------------------card1------------------------------------------- */}

            <Card
              className="card1"
              style={{
                backgroundImage: `url(${banner})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: 30,
                backdropFilter: "blur(30px)",
                boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                border: "solid 1px #f1f1f1",
              }}
            >
              <CardBody>
                {/* {" "}
                <div>
                <span className="card1text1" style={{ color: "white" }}>
                  Hire Experts & GET
                </span>
                <br />
                <span className="card1text2" style={{ color: "white" }}>
                  your Job done
                </span>
                </div> */}
              </CardBody>
            </Card>
          </div>

          <Desktop>
            <div className="col-lg-2">
              <Card
                className="card2"
                style={{
                  borderRadius: 30,
                  backdropFilter: "blur(30px)",
                  boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                  border: "solid 1px #f1f1f1",
                }}
              >
                <CardBody
                  className="mt-2"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {" "}
                  <span className="card2text1 totalH imgmoddd">
                    Total Hired{" "}
                  </span>{" "}
                  <span className="card2text1 imgmoddd">{hired}</span>
                </CardBody>
              </Card>
            </div>

            {/* --------------------------card3------------------------------------------- */}
            <div className="col-lg-3">
              <Card
                className="card3"
                style={{
                  borderRadius: 30,
                  backdropFilter: "blur(30px)",
                  boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                  border: "solid 1px #f1f1f1",
                }}
              >
                <CardBody className="mt-2 ">
                  <span className="card3text1 totalJob imgmoddd">
                    Total Jobs: {totaljobs}
                  </span>
                  <span className="card3text1 imgmoddd">
                    Total Opened Jobs: {openjobs}
                  </span>
                </CardBody>
              </Card>
            </div>
          </Desktop>
          <Mobile>
            <div
              className="col-xs-2"
              style={{ marginLeft: "15px", marginRight: "22px" }}
            >
              <Card
                className="card2"
                style={{
                  borderRadius: 30,
                  backdropFilter: "blur(30px)",
                  boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                  border: "solid 1px #f1f1f1",
                }}
              >
                <CardBody className="mt-2">
                  {" "}
                  <span className="card2text1 imgmoddd ">
                    Total Hired{" "}
                  </span>{" "}
                  <br />
                  <span className="card2text1 imgmoddd">
                    Candidates: {hired}
                  </span>
                </CardBody>
              </Card>
            </div>

            {/* --------------------------card3------------------------------------------- */}
            <div className="col-xs-3">
              <Card
                className="card3"
                style={{
                  borderRadius: 30,
                  backdropFilter: "blur(30px)",
                  boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                  border: "solid 1px #f1f1f1",
                }}
              >
                <CardBody className="mt-2 ">
                  <span className="card3text1 imgmoddd">
                    Total Jobs: {totaljobs}
                  </span>
                  <br />
                  <span className="card3text1 imgmoddd">
                    Total Opened Jobs: {openjobs}
                  </span>
                </CardBody>
              </Card>
            </div>
          </Mobile>
          {/* --------------------------card2------------------------------------------- */}
        </div>

        <div className="row row2">
          {/* --------table1------------------ */}
          <div className="col-lg-7">
            <Card
              className="table1card"
              style={{
                minHeight: "300px",
                borderRadius: 30,
                backdropFilter: "blur(30px)",
                boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                border: "solid 1px #f1f1f1",
              }}
            >
              <span className="table1CardHead mx-3 my-3 font-weight-bold">
                New Jobs
              </span>

              <Table hover size="sm">
                <thead>
                  <tr className="tablehead">
                    <th className="ml-2"></th>
                    <th
                      className="ml-2"
                      style={{
                        fontSize: "14px",
                        color: "white",
                        padding: "12px 0 12px 0",
                        paddingLeft: "5px",
                        width: "27%",
                      }}
                    >
                      <b>Job Title</b>
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        color: "white",
                        padding: "12px 0 12px 0",
                      }}
                    >
                      <b>Posting Date</b>
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        color: "white",
                        padding: "12px 0 12px 0",
                      }}
                    >
                      <b>Closing Date</b>
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        color: "white",
                        padding: "12px 0 12px 0",
                        width: "18%",
                      }}
                    >
                      <b>No. of Candidates</b>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <>
                    {newjobs ? (
                      newjobs.slice(0, 4).map((d, index) => {
                        // const urll="http://appreacttest.getwork.org/job/"+d.job_id;
                        const urll = `/public/job/${
                          d.hiring_type === "ON CAMPUS"
                            ? "oncampus"
                            : "offcampus"
                        }/${d.job_id}`;

                        return (
                          <Fragment key={index}>
                            {index % 2 == 0 ? (
                              <tr className={classes.tableBlue}>
                                <td></td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "#000",
                                    paddingTop: "2%",
                                  }}
                                >
                                  <a
                                    href={urll}
                                    target="_blank"
                                    className={classes.anchorStyle}
                                  >
                                    {d.job_title}
                                  </a>
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "#000",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {d.apply_start_date}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "#000",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {d.apply_end_date}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "#000",
                                    paddingTop: "2%",
                                    textAlign: "center",
                                  }}
                                >
                                  {d.candidates}
                                </td>
                                <td>
                                  <Link
                                    to={`/company/manage-job/?job_id=${btoa(
                                      d.job_id
                                    )}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      /*    onClick={() => {
                                      history.push(
                                        `company/manage-job/?job_id=${btoa(
                                          d.job_id
                                        )}`
                                      );
                                    }} */
                                      //className="viewJob"
                                      color="primary"
                                      type="submit"
                                      //size="small"
                                      style={{
                                        backgroundColor: "#007BFF",
                                        borderRadius: 30,
                                        height: "30px",
                                        fontSize: "14px",
                                        display: "flex",
                                        margin: "5px",
                                        width: "88%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      Track
                                    </Button>
                                  </Link>
                                </td>
                              </tr>
                            ) : (
                              <tr className={classes.tableWhite}>
                                <td></td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                  }}
                                >
                                  <a
                                    href={urll}
                                    target="_blank"
                                    className={classes.anchorStyle}
                                  >
                                    {d.job_title}
                                  </a>
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {d.apply_start_date}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {d.apply_end_date}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                    textAlign: "center",
                                  }}
                                >
                                  {d.candidates}
                                </td>
                                <td>
                                  <Link
                                    to={`/company/manage-job/?job_id=${btoa(
                                      d.job_id
                                    )}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      /* onClick={() =>
                                      history.push(
                                        `company/manage-job/?job_id=${btoa(
                                          d.job_id
                                        )}`
                                      )
                                    } */
                                      color="primary"
                                      type="submit"
                                      // size="small"
                                      //className="viewJob"
                                      style={{
                                        backgroundColor: "#007BFF",
                                        borderRadius: 30,
                                        height: "30px",
                                        fontSize: "14px",
                                        display: "flex",
                                        margin: "5px",
                                        width: "88%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      Track
                                    </Button>
                                  </Link>
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      })
                    ) : (
                      <>
                        <tr className={classes.tableBlueNoHover}>
                          <td className={classes.tableBlueNoHover}></td>
                          <td
                            className={classes.tableBlueNoHover}
                            style={{
                              paddingBottom: "40px",
                            }}
                          ></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                        </tr>
                        <tr className={classes.tableWhiteNoHover}>
                          <td
                            className={classes.tableWhiteNoHover}
                            colspan="6"
                            align="center"
                            style={{ color: "black" }}
                          >
                            No new Jobs for now
                            <br />
                            <span
                              style={{
                                fontSize: "11px",
                                color: "grey",
                              }}
                            >
                              Start Creating new jobs
                            </span>
                          </td>
                        </tr>
                        <tr className={classes.tableBlueNoHover}>
                          <td className={classes.tableBlueNoHover}></td>
                          <td
                            className={classes.tableBlueNoHover}
                            style={{
                              paddingBottom: "40px",
                            }}
                          ></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                        </tr>
                        <tr className={classes.tableWhiteNoHover}>
                          <td
                            className={classes.tableWhiteNoHover}
                            colspan="6"
                            align="center"
                          >
                            <Link to="/company/post-job">
                              <Button
                                color="primary"
                                type="submit"
                                style={{
                                  backgroundColor: "#3282c4",
                                  borderRadius: 30,
                                }}
                              >
                                Create Job +
                              </Button>
                            </Link>
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                  {newjobs.length >= 3 && (
                    <tr className={classes.tableWhiteNoHover}>
                      <td
                        className={classes.tableWhiteNoHover}
                        colspan="6"
                        align="right"
                        style={{
                          color: "#3282C4",
                          paddingTop: "15px",
                        }}
                      >
                        <Link
                          to="/company/all-jobs"
                          style={{ color: "#3282C4" }}
                        >
                          <b className="viewA" style={{ color: "#007BFF" }}>
                            View All
                          </b>{" "}
                          &nbsp;
                          <img src={arrow} />
                          &nbsp; &nbsp;
                        </Link>
                      </td>
                    </tr>
                  )}
                </tbody>

                {/* </Table> */}
              </Table>
            </Card>
          </div>

          <div className="col-lg-5">
            <Card
              className="OuterCard"
              style={{
                minHeight: "300px",
                borderRadius: 30,
                backdropFilter: "blur(30px)",
                boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                border: "solid 1px #f1f1f1",
              }}
            >
              <span
                className="TaskDay mx-2 my-2 font-weight-bold"
                style={{ padding: "10px", color: "black", fontSize: "18px" }}
              >
                Today's Task {date}
              </span>
              <>
                {" "}
                {tasks.length ? (
                  tasks.slice(0, 2).map((t, index) => {
                    return (
                      <Fragment key={index}>
                        {index % 2 == 0 ? (
                          <>
                            <Card
                              className="innerCard1"
                              style={{
                                padding: "10px",
                              }}
                            >
                              Interview Scheduled for {t.job_title} on{" "}
                              {t.start_date}{" "}
                            </Card>
                          </>
                        ) : (
                          <Card
                            className="innerCard2"
                            style={{ padding: "10px" }}
                          >
                            Interview Scheduled for {t.job_title} on{" "}
                            {t.start_date}{" "}
                          </Card>
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <>
                    <img
                      src={cup}
                      align="center"
                      style={{
                        height: "115px",
                        width: "115px",
                        margin: "auto",
                      }}
                    />
                    <br />
                    <p
                      style={{
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      There are no tasks for today
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Check back later for new tasks
                      </span>
                    </p>
                  </>
                )}
              </>
            </Card>
          </div>

          {/* --------table2------------------ */}

          <div className="col-md-7">
            <Card
              className="table2card"
              style={{
                minHeight: "300px",
                borderRadius: 30,
                backdropFilter: "blur(30px)",
                boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                border: "solid 1px #f1f1f1",
              }}
            >
              <div className="table2CardHead">
                <span className="table2CardHead1 font-weight-bold ml-3 my-3">
                  Recruitment Progress
                </span>

                <select
                  onChange={handleDropdown}
                  className="selectorB form-control-sm"
                  style={{
                    borderColor: "grey",
                    // marginLeft: "28%",
                    // marginTop: "2%",
                    borderRadius: 30,
                  }}
                >
                  <option>---Select A Profile---</option>
                  <option
                    selected
                    value={recruitment[0]?.job_id}
                    key={recruitment[0]?.job_id}
                  >
                    {recruitment?.length > 0 && recruitment[0]?.job_title}
                  </option>
                  <>
                    {""}
                    {recruitment.length !== 0 &&
                      recruitment.map((e) => {
                        return (
                          <option
                            className="form-control"
                            key={e.job_id}
                            value={e.job_id}
                          >
                            {e.job_title}
                          </option>
                        );
                      })}
                  </>
                </select>
              </div>

              <Table className="table2" hover size="sm">
                <thead>
                  <tr className="tablehead">
                    <th></th>
                    {/*  <th></th> */}
                    <th
                      style={{
                        fontSize: "14px",
                        color: "white",
                        padding: "12px 0px",
                        paddingLeft: "0.6%",
                      }}
                    >
                      Applicant Name
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        color: "white",
                        padding: "12px 0px",
                        paddingLeft: "0.6%",
                      }}
                    >
                      College or Email
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        color: "white",
                        padding: "12px 0px",
                        paddingLeft: "0.6%",
                      }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {" "}
                    {applicants.length ? (
                      applicants.slice(0, 3).map((t, index) => {
                        return (
                          <Fragment key={index}>
                            {index % 2 == 0 ? (
                              <tr className={classes.tableBlue}>
                                <td></td>
                                {/*   <td>
                                  <Col xs={6} md={4}>
                                    <Image
                                      src={t.profile_picture}
                                      roundedCircle
                                      height="38px"
                                    />
                                  </Col>
                                </td> */}
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {t.first_name} {t.last_name}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {t.email}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {t.status}
                                </td>
                              </tr>
                            ) : (
                              <tr className={classes.tableWhite}>
                                <td></td>
                                {/* <td>
                                  <Col xs={6} md={4}>
                                    <Image
                                      src={t.profile_picture}
                                      roundedCircle
                                      height="38px"
                                    />
                                  </Col>
                                </td> */}
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {t.first_name} {t.last_name}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                  }}
                                >
                                  {t.email}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    color: "black",
                                    paddingTop: "2%",
                                    borderColor: "grey",
                                    marginLeft: "29%",
                                    marginTop: "2%",
                                  }}
                                >
                                  {t.status}
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      })
                    ) : (
                      <>
                        <tr className={classes.tableBlueNoHover}>
                          <td className={classes.tableBlueNoHover}></td>
                          <td
                            className={classes.tableBlueNoHover}
                            style={{
                              paddingBottom: "40px",
                            }}
                          ></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                        </tr>
                        <tr className={classes.tableWhiteNoHover}>
                          <td
                            className={classes.tableWhiteNoHover}
                            colspan="5"
                            align="center"
                            style={{ color: "black" }}
                          >
                            No recruitment in progress for now
                            <br />
                            <span
                              style={{
                                fontSize: "11px",
                                color: "grey",
                              }}
                            >
                              Start creating new jobs to view recruitment
                              progress
                            </span>
                          </td>
                        </tr>
                        <tr className={classes.tableBlueNoHover}>
                          <td className={classes.tableBlueNoHover}></td>
                          <td
                            className={classes.tableBlueNoHover}
                            style={{
                              paddingBottom: "40px",
                            }}
                          ></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                          <td className={classes.tableBlueNoHover}></td>
                        </tr>
                        <tr className={classes.tableWhiteNoHover}>
                          <td
                            className={classes.tableWhiteNoHover}
                            colspan="6"
                            align="center"
                          >
                            <Link to="/company/post-job">
                              <Button
                                color="primary"
                                type="submit"
                                style={{
                                  backgroundColor: "#3282c4",
                                  borderRadius: 30,
                                }}
                              >
                                Create Job +
                              </Button>
                            </Link>
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                  {/* 
                  {applicants.length >= 3 && (
                    <tr className={classes.tableWhiteNoHover}>
                      <td
                        className={classes.tableWhiteNoHover}
                        colspan="6"
                        align="right"
                        style={{
                          color: "#3282C4",
                          paddingTop: "15px",
                        }}
                      >
                        <Link to="/company/track" style={{ color: "#3282C4" }}>
                          <b className="viewA">View All</b> &nbsp;
                          <img src={arrow} />
                          &nbsp; &nbsp;
                        </Link>
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </Table>
            </Card>
          </div>

          <div className="col-md-5">
            <Card
              className="card7"
              style={{
                padding: "10px",
                minHeight: "300px",
                borderRadius: 30,
                backdropFilter: "blur(30px)",
                boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                border: "solid 1px #f1f1f1",
              }}
            >
              <span className="card5Head mx-2 my-2 font-weight-bold">
                Recent Notifications
              </span>

              <>
                {" "}
                {notifications.length ? (
                  notifications.slice(0, 3).map((h, i) => {
                    return (
                      <Fragment key={i}>
                        <ul>
                          <li
                            style={{
                              color: "black",
                            }}
                          >
                            <b>
                              {h.first_name} {h.last_name}{" "}
                            </b>{" "}
                            &nbsp; from <b>{h.college} </b>has invited you
                          </li>
                        </ul>
                      </Fragment>
                    );
                  })
                ) : (
                  <>
                    <img
                      src={notify}
                      align="center"
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "auto",
                      }}
                    />
                    <br />
                    <p align="center" style={{ color: "black" }}>
                      No notifications
                    </p>
                    <p align="center">
                      <span style={{ fontSize: "12px" }}>
                        You have no active Chats
                      </span>
                    </p>
                  </>
                )}
              </>
            </Card>
          </div>

          {/* ----------------table3 &4 ------------------ */}

          {/*<div className="row col-8">*/}
          {/*  <div className="col-md-6">*/}
          {/*    <Card*/}
          {/*      className="card8"*/}
          {/*      style={{*/}
          {/*        minHeight: "200px",*/}
          {/*        borderRadius: 30,*/}
          {/*        backdropFilter: "blur(30px)",*/}
          {/*        boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",*/}
          {/*        border: "solid 1px #f1f1f1",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <div className="card8Head">*/}
          {/*        <span className="card8HeadText font-weight-bold ml-3 my-3">*/}
          {/*          Connected Colleges*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*      <Table hover size="sm">*/}
          {/*        <thead style={{ border: "hidden" }}></thead>*/}
          {/*        <tbody>*/}
          {/*          <>*/}
          {/*            {" "}*/}
          {/*            {conCollege.length ? (*/}
          {/*              conCollege.slice(0, 3).map((g, index) => {*/}
          {/*                return (*/}
          {/*                  <Fragment key={index}>*/}
          {/*                    {index % 2 == 0 ? (*/}
          {/*                      <tr className={classes.tableBlue}>*/}
          {/*                        <td></td>*/}
          {/*                        <td*/}
          {/*                          style={{*/}
          {/*                            border: "hidden",*/}
          {/*                          }}*/}
          {/*                        >*/}
          {/*                          <Col xs={6} md={4}>*/}
          {/*                            <Image*/}
          {/*                              src={g.logo}*/}
          {/*                              roundedCircle*/}
          {/*                              height="40px"*/}
          {/*                              className="imgmoddd"*/}
          {/*                            />*/}
          {/*                          </Col>*/}
          {/*                        </td>*/}
          {/*                        <td*/}
          {/*                          style={{*/}
          {/*                            border: "hidden",*/}
          {/*                          }}*/}
          {/*                          className="collegeN"*/}
          {/*                        >*/}
          {/*                          {g.college_name}*/}
          {/*                        </td>*/}
          {/*                      </tr>*/}
          {/*                    ) : (*/}
          {/*                      <tr className={classes.tableWhite}>*/}
          {/*                        <td></td>*/}
          {/*                        <td*/}
          {/*                          style={{*/}
          {/*                            border: "hidden",*/}
          {/*                          }}*/}
          {/*                        >*/}
          {/*                          <Col xs={6} md={4}>*/}
          {/*                            <Image*/}
          {/*                              src={g.logo}*/}
          {/*                              roundedCircle*/}
          {/*                              height="40px"*/}
          {/*                              className="imgmoddd"*/}
          {/*                            />*/}
          {/*                          </Col>*/}
          {/*                        </td>*/}
          {/*                        <td*/}
          {/*                          className="collegeN"*/}
          {/*                          style={{*/}
          {/*                            border: "hidden",*/}
          {/*                          }}*/}
          {/*                        >*/}
          {/*                          {g.college_name}*/}
          {/*                        </td>*/}
          {/*                      </tr>*/}
          {/*                    )}*/}
          {/*                  </Fragment>*/}
          {/*                );*/}
          {/*              })*/}
          {/*            ) : (*/}
          {/*              <>*/}
          {/*                <tr className={classes.tableBlueNoHover}>*/}
          {/*                  <td className={classes.tableBlueNoHover}></td>*/}
          {/*                  <td*/}
          {/*                    className={classes.tableBlueNoHover}*/}
          {/*                    style={{*/}
          {/*                      paddingBottom: "40px",*/}
          {/*                    }}*/}
          {/*                  ></td>*/}
          {/*                  <td className={classes.tableBlueNoHover}></td>*/}
          {/*                </tr>*/}
          {/*                <tr className={classes.tableWhiteNoHover}>*/}
          {/*                  <td*/}
          {/*                    className={classes.tableWhiteNoHover}*/}
          {/*                    colspan="3"*/}
          {/*                    align="center"*/}
          {/*                    style={{*/}
          {/*                      color: "black",*/}
          {/*                    }}*/}
          {/*                  >*/}
          {/*                    No colleges connected for now*/}
          {/*                    /!* <br/>*/}
          {/*                                      <span style={{fontSize:'11px', color:'grey'}}>Start Creating new jobs to view recruitment progress</span> *!/*/}
          {/*                  </td>*/}
          {/*                </tr>*/}
          {/*                <tr className={classes.tableBlueNoHover}>*/}
          {/*                  <td className={classes.tableBlueNoHover}></td>*/}
          {/*                  <td*/}
          {/*                    className={classes.tableBlueNoHover}*/}
          {/*                    style={{*/}
          {/*                      paddingBottom: "40px",*/}
          {/*                    }}*/}
          {/*                  ></td>*/}
          {/*                  <td className={classes.tableBlueHoHover}></td>*/}
          {/*                </tr>*/}
          {/*              </>*/}
          {/*            )}*/}
          {/*          </>*/}
          {/*          {conCollege.length >= 3 && (*/}
          {/*            <tr className={classes.tableWhiteNoHover}>*/}
          {/*              <td*/}
          {/*                className={classes.tableWhiteNoHover}*/}
          {/*                colspan="6"*/}
          {/*                align="right"*/}
          {/*                style={{*/}
          {/*                  color: "#3282C4",*/}
          {/*                  paddingTop: "15px",*/}
          {/*                }}*/}
          {/*              >*/}
          {/*                <Link*/}
          {/*                  to="/company/college-connection"*/}
          {/*                  style={{ color: "#3282C4" }}*/}
          {/*                  onClick={() =>*/}
          {/*                    history.push({*/}
          {/*                      pathname: "/company/college-connection",*/}
          {/*                      flag: "1002",*/}
          {/*                    })*/}
          {/*                  }*/}
          {/*                >*/}
          {/*                  <b className="viewA"> View All</b> &nbsp;*/}
          {/*                  <img src={arrow} />*/}
          {/*                  &nbsp; &nbsp;*/}
          {/*                </Link>*/}
          {/*              </td>*/}
          {/*            </tr>*/}
          {/*          )}*/}
          {/*        </tbody>*/}
          {/*      </Table>*/}
          {/*    </Card>*/}
          {/*  </div>*/}

          {/*  <div className="col-md-5">*/}
          {/*    <Card*/}
          {/*      className="card9"*/}
          {/*      style={{*/}
          {/*        minHeight: "200px",*/}
          {/*        borderRadius: 30,*/}
          {/*        backdropFilter: "blur(30px)",*/}
          {/*        boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",*/}
          {/*        border: "solid 1px #f1f1f1",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <div className="card8Head">*/}
          {/*        <span className="card8HeadText font-weight-bold mx-3 my-3">*/}
          {/*          Pending Colleges*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*      <Table hover size="sm">*/}
          {/*        <thead></thead>*/}
          {/*        <tbody>*/}
          {/*          <>*/}
          {/*            {" "}*/}
          {/*            {penCollege.length ? (*/}
          {/*              penCollege.slice(0, 3).map((g, index) => {*/}
          {/*                return (*/}
          {/*                  <Fragment>*/}
          {/*                    {index % 2 == 0 ? (*/}
          {/*                      <tr className={classes.tableBlue}>*/}
          {/*                        <td></td>*/}
          {/*                        <td*/}
          {/*                          style={{*/}
          {/*                            border: "hidden",*/}
          {/*                          }}*/}
          {/*                        >*/}
          {/*                          <Col xs={6} md={4}>*/}
          {/*                            <Image*/}
          {/*                              src={g.logo}*/}
          {/*                              roundedCircle*/}
          {/*                              height="40px"*/}
          {/*                              className="imgmoddd"*/}
          {/*                            />*/}
          {/*                          </Col>*/}
          {/*                        </td>*/}
          {/*                        <td*/}
          {/*                          style={{*/}
          {/*                            border: "hidden",*/}
          {/*                          }}*/}
          {/*                          className="imgmoddd"*/}
          {/*                        >*/}
          {/*                          {g.college_name}*/}
          {/*                        </td>*/}
          {/*                      </tr>*/}
          {/*                    ) : (*/}
          {/*                      <tr className={classes.tableWhite}>*/}
          {/*                        <td></td>*/}
          {/*                        <td*/}
          {/*                          style={{*/}
          {/*                            border: "hidden",*/}
          {/*                          }}*/}
          {/*                        >*/}
          {/*                          <Col xs={6} md={4}>*/}
          {/*                            <Image*/}
          {/*                              src={g.logo}*/}
          {/*                              roundedCircle*/}
          {/*                              height="40px"*/}
          {/*                              className="imgmoddd"*/}
          {/*                            />*/}
          {/*                          </Col>*/}
          {/*                        </td>*/}
          {/*                        <td*/}
          {/*                          style={{*/}
          {/*                            border: "hidden",*/}
          {/*                          }}*/}
          {/*                          className="imgmoddd"*/}
          {/*                        >*/}
          {/*                          {g.college_name}*/}
          {/*                        </td>*/}
          {/*                      </tr>*/}
          {/*                    )}*/}
          {/*                  </Fragment>*/}
          {/*                );*/}
          {/*              })*/}
          {/*            ) : (*/}
          {/*              <>*/}
          {/*                <tr className={classes.tableBlueNoHover}>*/}
          {/*                  <td className={classes.tableBlueNoHover}></td>*/}
          {/*                  <td*/}
          {/*                    className={classes.tableBlueNoHover}*/}
          {/*                    style={{*/}
          {/*                      paddingBottom: "40px",*/}
          {/*                    }}*/}
          {/*                  ></td>*/}
          {/*                  <td className={classes.tableBlueNoHover}></td>*/}
          {/*                </tr>*/}
          {/*                <tr className={classes.tableWhiteNoHover}>*/}
          {/*                  <td*/}
          {/*                    className={classes.tableWhiteNoHover}*/}
          {/*                    colspan="3"*/}
          {/*                    align="center"*/}
          {/*                    style={{*/}
          {/*                      color: "black",*/}
          {/*                    }}*/}
          {/*                  >*/}
          {/*                    No colleges pending for now*/}
          {/*                    /!* <br/>*/}
          {/*                                      <span style={{fontSize:'11px', color:'grey'}}>Start Creating new jobs to view recruitment progress</span> *!/*/}
          {/*                  </td>*/}
          {/*                </tr>*/}
          {/*                <tr className={classes.tableBlueNoHover}>*/}
          {/*                  <td className={classes.tableBlueNoHover}></td>*/}
          {/*                  <td*/}
          {/*                    className={classes.tableBlueNoHover}*/}
          {/*                    style={{*/}
          {/*                      paddingBottom: "40px",*/}
          {/*                    }}*/}
          {/*                  ></td>*/}
          {/*                  <td className={classes.tableBlueNoHover}></td>*/}
          {/*                </tr>*/}
          {/*              </>*/}
          {/*            )}*/}
          {/*          </>*/}
          {/*          {penCollege.length >= 3 && (*/}
          {/*            <tr className={classes.tableWhiteNoHover}>*/}
          {/*              <td*/}
          {/*                className={classes.tableWhiteNoHover}*/}
          {/*                colspan="6"*/}
          {/*                align="right"*/}
          {/*                style={{*/}
          {/*                  color: "#3282C4",*/}
          {/*                  paddingTop: "15px",*/}
          {/*                }}*/}
          {/*              >*/}
          {/*                <Link*/}
          {/*                  to="/company/college-connection"*/}
          {/*                  style={{ color: "#3282C4" }}*/}
          {/*                >*/}
          {/*                  <b className="viewA">View All</b> &nbsp;*/}
          {/*                  <img src={arrow} />*/}
          {/*                  &nbsp; &nbsp;*/}
          {/*                </Link>*/}
          {/*              </td>*/}
          {/*            </tr>*/}
          {/*          )}*/}
          {/*        </tbody>*/}
          {/*      </Table>*/}
          {/*    </Card>*/}
          {/*    <div>*/}
          {/*      {openActivateModal && (*/}
          {/*        <ActivateCreditModal*/}
          {/*          openModal={openActivateModal}*/}
          {/*          setOpenModal={setOpenActivateModal}*/}
          {/*        />*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

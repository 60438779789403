import { Hidden } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Footer from "../../../components/public/FooterNew/Footer";
import FooterDomain from "../../../components/public/FooterNew/Footer/FooterDomain";
import MobileTab from "../../../components/public/LogIn/Header/MobileTab";
import MobileTabRegister from "../../../components/public/LogIn/Header/MobileTabRegister";
import StudentHeader from "../../../components/public/LogIn/Header/StudentHeader";
import DomainNavbar from "../../../components/public/Navbar/DomainNavbar";
import Navbar from "../../../components/public/Navbar/Navbar";
import { BackendBaseApi, emailRegex, FACEBOOK_APP_ID, FACEBOOK_APP_SECRET, NEXT_APP_ENDPOINT } from "../../../constants/constants";
import { getCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import { getCookie } from "../../../utils/common/Cookies";
import { digits_count } from "../../../utils/utility";

function Register() {
  const history = useHistory();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({
    Flag: false,
    UserID: null,
    FirstName: "",
    LastName: "",
    PhoneNo: null,
    Email: "",
    Password: "",
    Token: null,
    isemailVerified: false,
  });

  const [userDetailsCollege, setUserDetailsCollege] = useState({
    name: "",
    college_name: "",
    college_location: "",
    email: "",
    phone: null,
  });

  const [errorMessagesCollege, setErrorMessagesCollege] = useState({
    name: "",
    college_name: "",
    college_location: "",
    email: "",
    phone: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [fbAccessToken, setfbAccessToken] = useState("");
  const [googleAccessToken, setgoogleAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const firstRender_1 = useRef(true);
  const firstRender_2 = useRef(true);
  const firstRender_3 = useRef(true);
  const alert = useAlert();
  const params = useParams();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  const responseFacebook = (response) => {
    setfbAccessToken(response.accessToken);
  };

  useEffect(() => {
    console.log(getCookie("utm_source"));
  }, []);

  const checkUserType = () => {
    if (params && params?.token) {
      if (params?.token === "student") return "Student";
      if (params?.token === "employer" || params?.token === "company") return "Company";
      if (params?.token === "college") return "College";
    } else if (!params.token && params.token === undefined) return "Student";
    else return "Student";
  };

  //access short life token from google popup response
  const responseGoogle = (response) => {
    setgoogleAccessToken(response.accessToken);
  };

  const checkTab = () => {
    switch (value) {
      case 0:
        return "Student";
      case 1:
        return "College";
      case 2:
        return "Company";
    }
  };

  const handleSubmit = () => {
    if (params?.token === "company" || params?.token === "employer") createUserProfile();
    else createUserProfileStudent();
  };

  const createUserProfile = () => {
    var flag = 0;

    if (digits_count(userDetails.PhoneNo) !== 10) flag = 1;
    if (emailRegex.test(userDetails.Email) === false) {
      flag = 2;
    }

    if (flag === 0) {
      setLoading(true);
      axios
        .post(
          BackendBaseApi.PRAVESH + "api/profile/create/",
          {
            email: userDetails.Email,
            first_name: userDetails.FirstName,
            last_name: userDetails.LastName,
            password: userDetails.Password,
            phone: userDetails.PhoneNo,
            user_type: "Company",
          },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.success) alert.success(res.data.data.message);
          else {
            alert.error(res.data.error);
            return;
          }

          let data = res.data.data;

          setUserDetails({
            ...userDetails,
            UserID: data.id,
            Token: data.token,
          });
          // getDetails(data.token);
          history.push({
            pathname: "/confirm-email",
            email: res.data.data.email,
            user_id: res.data.data.id,
          });
        })
        .catch((err) => {
          localStorage.clear();
          setLoading(false);
          alert.error("Invalid Session!! Please try again");
          throw err;
        });
    } else {
      if (flag === 1) alert.error("Enter a valid  10 digit Phone No.");
      if (flag === 2) alert.error("Enter valid Email Address");
    }
  };

  const createUserProfileStudent = () => {
    var flag = 0;

    // if (digits_count(userDetails.PhoneNo) !== 10) flag = 1;
    // if (emailRegex.test(userDetails.Email) === false) {
    //   flag = 2;
    // }

    if (flag === 0) {
      setLoading(true);
      axios
        .post(
          BackendBaseApi.PRAVESH + "api/profile/create/",
          {
            email: userDetails.Email,
            first_name: userDetails.FirstName,
            last_name: userDetails.LastName,
            password: userDetails.Password,
            phone: userDetails.PhoneNo,
            user_type: "Student",
          },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);

          if (res.data.success) alert.success(res.data.data.message);
          else {
            alert.error(res.data.error);
            setErrorMessages({
              ...errorMessages,
              email: "Email Address already exists",
            });
            return;
          }

          let data = res.data.data;

          setUserDetails({
            ...userDetails,
            UserID: data.id,
            Token: data.token,
          });
          getDetails(data.token);

          // setStep(step + 1);
        })
        .catch((err) => {
          localStorage.clear();
          setLoading(false);
          alert.error("Invalid Session!! Please try again");
          throw err;
        });
    } else {
      if (flag === 1) alert.error("Enter a valid  10 digit Phone No.");
      if (flag === 2) alert.error("Enter valid Email Address");
    }
  };

  const getDetails = (token) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.user_type[0].type === 1) {
          localStorage.setItem("gw_token", token);
          localStorage.setItem("user_type_id", res.data.user_type[0].type);
          localStorage.setItem("user_type", "Student");
          localStorage.setItem("user_id", res.data.id);
          localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
          localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);
          localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);

          //student without verified email, go to home

          if (res.data.is_pref_filled) {
            // if (location.search.includes("?source=") && !location.search.includes("utm_source") && !getCookie("utm_source")) {
            //   history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
            // } else if (location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
            //   history.push({ pathname: `/student/referral/update-profile-and-apply`, search: location.search ? location.search : "" });
            // } else if (!location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
            //   history.push({ pathname: `/student/referral/update-profile-and-apply` });
            // } else
            history.push("/student/dashboard");
          } else {
            // if (location.search.includes("?source=") && !location.search.includes("utm_source") && !getCookie("utm_source")) {
            //   history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
            // } else if (location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
            //   history.push({ pathname: `/student/referral/update-profile-and-apply`, search: location.search ? location.search : "" });
            // } else if (!location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
            //   history.push({ pathname: `/student/referral/update-profile-and-apply` });
            // } else {
            //   history.push({
            //     pathname: "/student/complete-profile",
            //     flag: 12367,
            //   });
            // }
            history.push({
              pathname: "/student/complete-profile",
              flag: 12367,
            });
          }
        }
      });
  };
  const checkUser = (token) => {
    axios
      .get(BackendBaseApi.PRAVESH + "api/profile/details/", {
        headers: {
          Authorization: "Token " + token,
        },
        withCredentials: true,
      })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          if (!res.data.email_verified) {
            //move to signup options component
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              email: res.data.email,
              token,
              isemailVerified: false,
            });
          } else {
            //move to signup options component
            // setStep(step + 2);
            setLoading(false);
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              token,
              email: res.data.email,
              isemailVerified: true,
            });
          }
        } else {
          localStorage.setItem("user_type", res.data.user_type[0].main_user);
          localStorage.setItem("user_type_id", res.data.user_type[0].type);
          localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

          if (res.data.email_verified) {
            localStorage.setItem("gw_token", token);
            localStorage.setItem("user_type_id", res.data.user_type[0].type);

            localStorage.setItem("user_id", res.data.user_type[0].user_id);
            localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

            //check if its student or company
            if (res.data.user_type[0].user_id == 1) {
              //student with verified email, go to home

              history.push("/student/dashboard");
            } else {
              if (res.data.is_pref_filled) {
                if (!res.data.company_details.admin_approved) {
                  localStorage.setItem(
                    "user",
                    JSON.stringify({
                      email: res.data.email,
                      first_name: res.data.first_name,
                      last_name: res.data.last_name,
                      id: res.data.id,
                      profile_picture: null,
                    })
                  );

                  history.push({
                    pathname: "/company/admin-approve",
                    flag: 19087,

                    company: res.data.company_details.company_name,
                    admin_email: res.data.company_details.admin_email,
                    admin_name: res.data.company_details.admin_name,
                    is_admin: res.data.is_company_admin,
                  });
                } else {
                  if (res?.data?.company_details !== null) localStorage.setItem("company", JSON.stringify(res.data.company_details));

                  dispatch(getCreditInfo(setLoader));
                  if (location.flag === 28071998) history.push("/company/post-job");
                  else {
                    if (res.data.user_type[0].type === 5) {
                      localStorage.setItem("comp_pref_filled", res.data.comp_pref_filled);
                      history.push({ pathname: "/company/dashboard", comp_pref_filled: res.data.comp_pref_filled });
                    } else {
                      history.push({ pathname: "/company/dashboard" });
                    }
                  }
                }
              } else {
                localStorage.setItem("mobile", res.data.mobile);
                history.push("/company/complete-profile");
              }
            }

            // else {
            //   //company user with verified email, check if admin approved
            //   if (!res.data.company_details.admin_approved) {
            //     history.push({
            //       pathname: "/company/admin-approve",
            //       company: res.data.company_details.company_name,
            //       admin_email: res.data.company_details.admin_email,
            //       admin_name: res.data.company_details.admin_name,
            //       is_admin: res.data.is_company_admin,
            //     });
            //   } else history.push("/company/dashboard");
            // }
          } else {
            //check if its student or company
            if (res.data.user_type[0].type == 1) {
              localStorage.setItem("gw_token", token);
              localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
              localStorage.setItem("user_id", res.data.id);
              localStorage.setItem("is_student_resume_uploaded", res.data.is_student_resume_uploaded);
              localStorage.setItem("profile_complete", res.data.user_type[0].profile_complete);

              //student without verified email, go to home

              if (res.data.is_pref_filled) {
                // if (location.search.includes("?source=") && !location.search.includes("utm_source") && !getCookie("utm_source")) {
                //   history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                // } else if (location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
                //   history.push({ pathname: `/student/referral/update-profile-and-apply`, search: location.search ? location.search : "" });
                // } else if (!location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
                //   history.push({ pathname: `/student/referral/update-profile-and-apply` });
                // } else
                history.push("/student/dashboard");
              } else {
                // if (location.search.includes("?source=") && !location.search.includes("utm_source") && !getCookie("utm_source")) {
                //   history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                // } else if (location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
                //   history.push({ pathname: `/student/referral/update-profile-and-apply`, search: location.search ? location.search : "" });
                // } else if (!location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
                //   history.push({ pathname: `/student/referral/update-profile-and-apply` });
                // } else {
                //   history.push({
                //     pathname: "/student/complete-profile",
                //     flag: 12367,
                //   });
                // }
                history.push({
                  pathname: "/student/complete-profile",
                  flag: 12367,
                });
              }
            } else {
              //company user without verified email
              history.push({
                pathname: "/confirm-email",
                email: res.data.email,
                user_id: res.data.user_type[0].user_id,
              });
            }
          }
        }

        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        throw err;
      });
  };

  useEffect(() => {
    let token = "";
    if (firstRender_1.current) {
      firstRender_1.current = false;
      return;
    }
    if (fbAccessToken)
      axios
        .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
          },
          withCredentials: true,
          provider: "facebook",
          access_token: fbAccessToken,
          user_type: params.token,
        },
        )
        .then((res) => {
          token = res.data.data.token;
          if (res.status === 200) {
            localStorage.setItem("gw_token", token);
            alert.success(res.data.data.message);
            setLoading(true);
            checkUser(token);
          } else {
            alert.error("No Valid response");
          }
        })
        .catch((err) => {
          alert.error("Error");
          throw err;
        });
  }, [fbAccessToken]);

  useEffect(() => {
    let token = "";
    if (firstRender_2.current) {
      firstRender_2.current = false;
      return;
    }
    if (googleAccessToken)
      axios
        .post(BackendBaseApi.PRAVESH + "api/oauth/login/", {
          headers: {
            "Content-Type": "application/json",
            Connection: "keep-alive",
          },
          withCredentials: true,
          provider: "google-oauth2",
          access_token: googleAccessToken,
          user_type: params.token,
        },
        )
        .then((res) => {
          if (res.data.success) {
            token = res.data.data.token;

            localStorage.setItem("gw_token", token);
            alert.success(res.data.data.message);
            setLoading(true);
            checkUser(token);
          } else {
            alert.error("Error");
          }
        })
        .catch((err) => {
          alert.error("Error");
          throw err;
        });
  }, [googleAccessToken]);

  const handleSubmitCollege = () => {
    setLoading(true);
    axios
      .post(
        `${BackendBaseApi.PRAVESH}api/common/college_form/`,

        userDetailsCollege
      )
      .then((res) => {
        if (res.data.success) {
          alert.success("Congrats!! You have successfully scheduled a demo");
          window.open(`${NEXT_APP_ENDPOINT}/thank-you-request-demo-college`, "_self");

          setLoading(false);
        } else {
          alert.error("Failed to send response");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [params?.token]);
  return (
    <>
      {domain?.domain ? <DomainNavbar /> : <Navbar />}

      <Hidden smDown>
        {" "}
        <StudentHeader
          type={checkUserType()}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
          handleSubmit={handleSubmit}
          responseGoogle={responseGoogle}
          responseFacebook={responseFacebook}
          registerForm={true}
          loading={loading}
          setLoading={setLoading}
          userDetailsCollege={userDetailsCollege}
          setUserDetailsCollege={setUserDetailsCollege}
          errorMessagesCollege={errorMessagesCollege}
          setErrorMessagesCollege={setErrorMessagesCollege}
          handleSubmitCollege={handleSubmitCollege}
        />
      </Hidden>
      <Hidden smUp>
        {" "}
        <MobileTabRegister
          type={checkUserType()}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
          handleSubmit={handleSubmit}
          responseGoogle={responseGoogle}
          responseFacebook={responseFacebook}
          registerForm={true}
          loading={loading}
          setLoading={setLoading}
          userDetailsCollege={userDetailsCollege}
          setUserDetailsCollege={setUserDetailsCollege}
          errorMessagesCollege={errorMessagesCollege}
          setErrorMessagesCollege={setErrorMessagesCollege}
          handleSubmitCollege={handleSubmitCollege}
        />
      </Hidden>
      {!domain?.domain ? <Footer /> : <FooterDomain />}
    </>
  );
}

export default Register;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import './Index.css';
import Layout from "../Layout/Layout";
import {
  Button,
  Box,
  Switch,
  FormControlLabel,
  Divider,
  makeStyles,
  createStyles,
  BottomNavigation,
  BottomNavigationAction
} from "@material-ui/core";
import { httpRequest } from "../../../utils/common/httpRequest";
import filterJob, { sortThings } from "../../../utils/common/filterJob";
import { Link } from "react-router-dom";
import NewJobCard from "../JobsCard/NewJobCard";
import NoJobs from "../../../assets/images/no-jobs.png";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import Axios from "axios";
import SelectSearch from "react-select-search";
import { BackendBaseApi } from "../../../constants/constants";
import { useParams } from "react-router-dom";

import apply_img from '../../../assets/student/jobs/applied.png';
import hidden_img from '../../../assets/student/jobs/hidden.png';
import saved_img from '../../../assets/student/jobs/saved.png';
import closed_img from '../../../assets/student/jobs/closed.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    checked: {
      color: "#3282c4 !important",
    },
    track: {
      backgroundColor: "#3282c4 !important",
    },
    thumb: {
      border: "1px solid #3282c4",
    },
  })
);

export default function Index() {
  const classes = useStyles();

  const [jobCount, setJobCount] = useState(0);
  const [jobData, setJobData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [sortValue, setSortValue] = useState("");

  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const data = JSON.parse(localStorage.getItem("user_details"));
  const [loading, setLoading] = useState(false);

  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("All");
  const [allJobTypes, setAllJobTypes] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("All");
  const [hiringType, setHiringType] = useState("ON CAMPUS");

  const [navValue, setNavValue] = useState('');
  const {action} = useParams();

  useEffect(() => {
    if(action===undefined) { setNavValue('Applied'); return; }
    if(action==='applied') { setNavValue('Applied'); return; }
    if(action==='saved')   { setNavValue('Saved');   return; }
    if(action==='hidden')  { setNavValue('Hidden');  return; }
    if(action==='closed')  { setNavValue('Closed');  return; }
    setNavValue('Applied');
  }, [action]);

  const handleNavChange = (event, newValue) => { setNavValue(newValue); };

  const filterDetails = data.student_user_details.education.filter(
    (item) => item.is_current === true
  );

  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setLoading(true);
    let res = await httpRequest(baseUrl, endPoint, body);

    if (res.data.next === null) {
      setEnd(true);
    } else {
      setEnd(false);
      setNewURL(res.data.next.slice(0, 20));
      setNewEndPoint(res.data.next.slice(20));
    }
    updateState(jobData.concat([...res.data.results]));
    setAllData(allData.concat([...res.data.results]));
    setLoading(false);
  };

  useEffect(() => {
    if (filterValue.length) {
      let data = filterJob(allData, "applicant_status_name", filterValue);
      setJobData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData, filterValue]);

  useEffect(() => {
    if (sortValue === "company.company_name") {
      let data = allData.sort((a, b) => sortThings(a, b, sortValue));
      setJobData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData, sortValue]);

  const handleScroll = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        const token = localStorage.getItem("gw_token");
        GetData(newURL, newEndPoint, { headers: token }, setJobData);
      }
    }
  };

  const getFilters = async () => {
    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/student/job_filter/?user_id=${window.btoa(data.id)}&status=MQ==`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
          },
        }
    );
    if (!res.data.data.message) {
      setAllCompanies([
        { company_id: "All", company_name: "All" },
        ...res.data.data[0]["Companies"],
      ]);
      setAllJobTypes([
        { job_type_id: "All", job_type_name: "All" },
        ...res.data.data[0]["job_types"],
      ]);
    } else {
      setAllCompanies([{ company_id: "All", company_name: "All" }]);
      setAllJobTypes([{ job_type_id: "All", job_type_name: "All" }]);
    }
  };

  const getAppliedJobs = async () => {
    setLoading(true);

    const checkJobType = () => {
      if (selectedJobType === "All") return "";
      return `&job_type_id=${btoa(selectedJobType)}`;
    };

    const checkCompany = () => {
      if (selectedCompany === "All") return "";
      return `&company_id=${btoa(selectedCompany)}`;
    };

    let status_code = 'MQ=='  // Applied
    if(navValue === 'Applied') { status_code = 'MQ==' }
    if(navValue === 'Saved')   { status_code = 'c2F2ZWQ=' }
    if(navValue === 'Hidden')  { status_code = 'aGlkZGVu' }
    if(navValue === 'Closed')  { status_code = 'Y2xvc2U=' }

    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/student/job_details/?status=${status_code}${checkJobType()}${checkCompany()}&hiring_type=${window.btoa(
          hiringType
        )}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("gw_token")}`,
          },
        }
    ).catch((err) => {
      throw err;
    });

    if (res.data.data.next === null) {
      setEnd(true);
    } else {
      setEnd(false);
      setNewURL(res.data.data.next.slice(0, 20));
      setNewEndPoint(res.data.data.next.slice(20));
    }

    //setJobData(jobData.concat([...res.data.data.results]));
    setJobCount(res.data.data.count);
    setJobData(res.data.data.results);
    //setAllData(allData.concat([...res.data.data.results]));
    setAllData(res.data.data.results);
    setLoading(false);
  };

  const refreshStates = () => {
    setJobCount(0);
    setJobData([]);
    setAllData([]);
  }

  useEffect(() => {
    if (!navValue) return;
    refreshStates();
    getFilters();
    getAppliedJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, selectedJobType, hiringType, navValue]);


  const handleToggle = (e) => {
    if (e.target.checked === true) {
      setHiringType("ON CAMPUS");
    } else setHiringType("OFF CAMPUS");
  };

  return (
    <>
        <Layout>
            <div id="yours_job_div">
              <div id="jobs-tabs-styles">
                  <BottomNavigation value={navValue} showLabels onChange={handleNavChange} id="mui_job_status_nav">
                        <BottomNavigationAction 
                            label={ <> 
                              <span>Applied</span> 
                              { jobCount>0 && navValue==='Applied' && 
                                <span style={{marginLeft:'4px'}}>{' '}({jobCount})</span> 
                              }
                            </> }
                            value="Applied" 
                            icon={<img src={apply_img} height={35} width={35} />} 
                        />
                        <BottomNavigationAction 
                            label={ <> 
                              <span>Saved</span> 
                              { jobCount>0 && navValue==='Saved' && 
                                <span style={{marginLeft:'4px'}}>{' '}({jobCount})</span> 
                              }
                            </> }
                            value="Saved"   
                            icon={<img src={saved_img} height={35} width={35} />} 
                        />
                        <BottomNavigationAction 
                            label={ <> 
                              <span>Hidden</span> 
                              { jobCount>0 && navValue==='Hidden' && 
                                <span style={{marginLeft:'4px'}}>{' '}({jobCount})</span> 
                              }
                            </> }
                            value="Hidden"  
                            icon={<img src={hidden_img} height={35} width={35} />} 
                        />
                        <BottomNavigationAction 
                            label={ <> 
                              <span>Closed</span> 
                              { jobCount>0 && navValue==='Closed' && 
                                <span style={{marginLeft:'4px'}}>{' '}({jobCount})</span> 
                              }
                            </> }
                            value="Closed"  
                            icon={<img src={closed_img} height={35} width={35} />} 
                        />
                  </BottomNavigation>
              </div> 
              <div style={{display:'flex', flexDirection:'column'}}>
                {/* <div id="status_heading">
                    <span>{jobCount>0 && jobCount} {navValue} Jobs </span>
                    <span id="filter_by_heading"> Filter by:{" "} </span>
                </div> */}
                <div id="filter_by_div">
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', flexWrap:'nowrap'}}>
                        <span id="filter_by_field"> Company:{" "} </span>
                        <SelectSearch
                            options={
                                allCompanies &&
                                allCompanies.length > 0 &&
                                allCompanies.map((item) => ({
                                    name: item.company_name,
                                    value: item.company_id,
                                }))
                            }
                            value={selectedCompany}
                            onChange={(val) => setSelectedCompany(val)}
                            placeholder="Company"
                            search
                        />
                    </div>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', flexWrap:'nowrap'}}>
                        <span id="filter_by_field"> Job Type:{" "} </span>
                        <SelectSearch
                            options={
                                allJobTypes &&
                                allJobTypes.length > 0 &&
                                allJobTypes.map((item) => ({
                                    name: item.job_type_name,
                                    value: item.job_type_id,
                                }))
                            }
                            value={selectedJobType}
                            onChange={(val) => setSelectedJobType(val)}
                            placeholder="Job Type"
                            search
                        />
                    </div>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', flexWrap:'nowrap'}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hiringType === "ON CAMPUS" ? true : false}
                                    onChange={handleToggle}
                                    classes={{
                                    checked: classes.checked,
                                    track: classes.track,
                                    thumb: classes.thumb,
                                    }}
                                />
                            }
                            label="On Campus"
                            color="primary"
                        />
                    </div>
                </div>
                <Divider style={{ margin: "1% 0%", backgroundColor: "#424242" }} />
              </div>
              <div
                id="myid"
                className="scrollY1"
                style={{ marginTop: "10px" }}
                onScroll={handleScroll}
              >
                <>
                    <NewJobCard
                        data={jobData}
                        all={navValue!=='Saved'}
                        fullView={true}
                        applied={true}
                        hideStatus={navValue==='Hidden'}
                        saveJob={navValue==='Saved'}
                    />
                    {
                      loading
                      &&
                      <Box style={{marginTop:'1rem'}}>
                        <JobCardSkeleton />
                      </Box>
                    }
                </>
                {
                  !loading && jobCount===0 && jobData.length===0
                  &&
                  <Box id="no_data_box">
                          <img id="no_job_img" src={NoJobs} alt="no-job" width="300px" height="auto" />
                          <p id="no_job_text"> No {navValue} Jobs here </p>
                          <Link to="/student/dashboard">
                            <Button
                              style={{
                                width: 238,
                                height: 44,
                                backgroundColor: "#3282C4",
                                textTransform: "capitalize",
                                color: "#ffffff",
                                "&:hover": {
                                  backgroundColor: "#3282C4",
                                },
                              }}
                            >
                              Go Back To Home
                            </Button>
                          </Link>
                  </Box>
                }
              </div>
            </div>
        </Layout>
    </>
  );
}
import React, { useState, useEffect, Fragment } from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import { Grid, Card, CardContent, Divider, Button, Box, makeStyles, createStyles, Typography, Avatar, Tooltip, Chip, Hidden, CircularProgress } from "@material-ui/core";
import "./ViewJob.css";
import CloseIcon from "@material-ui/icons/Close";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import Loading from "../customHooks/Loading";
import RoomIcon from "@material-ui/icons/Room";
import kFormatter, { LFormatter } from "../../../utils/common/ZeroToK";
import { FiberManualRecord, AccountBalanceWallet, CreditCardOutlined, ClassOutlined, QueryBuilderOutlined, BeenhereOutlined, LockOutlined } from "@material-ui/icons";
import { BackendBaseApi } from "../../../constants/constants";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Theme, withStyles} from "@material-ui/core/styles";
import { BootstrapTooltipNew } from "../../common/Tooltip/Tooltip";
import companyLogo from "../../../assets/company/png/de.png"
import LinkIcon from "@material-ui/icons/Link";
import ReactHtmlParser from "react-html-parser";
import { generateCsvCommas, generateCsvNew, generateCsv } from "../../../components/company/common/generateCsv/generateCsv";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import { useHistory } from "react-router-dom";
import { APP_ENDPOINT } from "../../../constants/constants";
import BootstrapTooltip from "../../common/Tooltip/Tooltip";
import { useSelector } from "react-redux";
import Header from "../../../components/public/JobDetails/Header";
import pSBC from "shade-blend-color";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#3e3d3d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const JobViewStyles = {
  Page: {
    height: "auto",
    background: "#E5E5E5",
  },
  Logo: {
    height: "100px",
    width: "100px",
  },
};

const useStyles = makeStyles((theme,domain) =>
  createStyles({
    mainCont: {
      marginTop: "0px",
      height: "82vh !important",
      boxShadow: "0 10px 25px 0 rgba(172,185,196,.28)",
    },
    table: {
      minWidth: 500,
    },
    chip: {
     // color: "#007bff",
      //backgroundColor: "#e8f3ff",
      backgroundColor: pSBC(0.8, domain?.domain ? domain?.color_code : "#007bff",),
      //borderColor: "#007bff",
    },
    chipsection: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    image: {
      border: "1px solid #e2e6ea",
      borderRadius: "12px",
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      height: "80px",
    },
    // icon: {
    //   display: "flex",
    //   flexDirection: "row",
    //   justifyContent: "flex-end",
    // },
    subheading: {
      fontSize: "15px",
      color: "#6c757d",
      marginLeft: "15px",
    },
    info: {
      fontSize: "12px",
      color: "#6c757d",
    },
    iconH: {
      color: "#6c757d",
      fontSize: "16px",
      display: "flex",
    },
    iconshare: {
      color: "#6c757d",
      fontSize: "16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    mobile: {
      textAlign: "center",
      display: "contents",
    },
  })
);

const ViewJob2 = ({
  data,
  handleClosey,
  open,
  apply,
  hide,
  closeIcon,
  index,
  appliedList,
  setAppliedList,
  invite,
  reject,
  setInviteList,
  inviteList,
  rejectList,
  setRejectList,
  fromApplied,
}) => {
  const classes = useStyles();
  const [jobData, setJobData] = useState();
  const [isApply, setIsApply] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [qualificationData, setQualificationData] = useState({});
  const [showMore, setShowMore] = useState(false);
  // const [specializationKey, setSpecializationKey] = useState("name");
  const history = useHistory();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  const [isReject, setIsReject] = useState(false);

  const generateCsv = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += (item.specialization_name ? item.specialization_name : item.name) + " | ")
        : (str += item.specialization_name ? item.specialization_name : item.name)
    );
    return str;
  };

    if (data) {
        var object = {};
        var detail = data;
        if (detail.hiring_type === "OFF CAMPUS") {
            detail.eligibility_criteria.degrees.map((item) => {
                var arr = [];
                detail.eligibility_criteria.courses.map((obj) => {
                    if (obj.degree_id === item.id) arr.push(obj);
                });

                if (item.all_degree_selected && item.all_spec_selected) object["Open to All"] = {};
                else {
                    if (item.all_spec_selected) object[item.name] = [{ name: "All Courses" }];
                    else object[item.name] = arr;
                }
            });
        } else if (detail.hiring_type === "ON CAMPUS" && (localStorage.getItem("user_type") === "College" || localStorage.getItem("user_type") === "Student")) {
            if (detail.college.length > 0) {
                var list = detail.college[0].preferences;
                var checkSpecSelect = false;
                var checkSelectAllArray = detail.college.map((item) => item.preferences.every((e) => e.degree_selected || e.select_all));

                var checkSelectAll = checkSelectAllArray.every((e) => e === true);
                if (checkSelectAll) object["Open to All"] = {};
                else {
                    detail.college.map((item) =>
                        item.preferences.map((item2) => {
                            object[item2.degree_name] = checkValue(item2);
                        })
                    );
                }
            }
        }
        detail.eligibility_criteria["qualifications"] = object;
        data.eligibility_criteria["qualifications"] = object;
    }

  const checkValue = (item) => {
    var boole = item.specialization.every((e) => e.selected || e.course_selected);
    var obj;
    if (boole) {
      obj = [{ name: "All Courses" }];
    } else {
      obj = item.specialization;
    }
    return obj;
  };

  const checkIfAllSelected = (item) => {
    var boole = item.preferences.every((e) => e.select_all || e.degree_selected);
    item.preferences.forEach((item2) => {
      item2["name"] = item2.degree_name;
    });
    var obj;
    if (boole) {
      obj = [{ name: "Open to All Degrees" }];
    } else {
      obj = item.preferences;
    }
    return obj;
  };

  // const generateCsv = (arr) => {
  //   let str = "";
  //   arr.forEach((item, index) =>
  //     arr.indexOf(item) !== arr.length - 1
  //       ? (str += (item.specialization_name ? item.specialization_name : item.name) + " | ")
  //       : (str += item.specialization_name ? item.specialization_name : item.name)
  //   );
  //   return str;
  // };

  return (
    <>
      {data !== null ? (
        <Grid
          container
          justify="center"
          style={{ height: "100%" }}
          // onClick={
          //   open
          //     ? (e) => {
          //         e.stopPropagation();
          //       }
          //     : (e) => {
          //         e.stopPropagation();
          //         handleClosey(false);
          //       }
          // }
        >
          <Grid xs={12} style={{ marginTop: "0" }}>
            <Card style={{ height: "100vh", overflowY: "scroll", scrollbarWidth: "none" }}>
              <CardContent style={{ paddingLeft: "2%" }}>
                {!closeIcon && (
                  <span
                    className="float-right pointer"
                    onClick={(e) => {
                      // e.stopPropagation();
                      handleClosey(false);
                    }}
                  >
                    <CloseIcon />
                  </span>
                )}

                <Grid container>
                  <Grid xs={12} style={{ marginTop: "0px" }}>
                    <Grid container>
                        <Header data={data} fromCollegeView={true} />
                      <br />
                      <Grid container>
                            <Grid
                            xs={12}
                            className="flex"
                            justify="flex-end"
                            style={{
                                borderBottom: "1px solid #DEDEDE",
                                paddingBottom: 10,
                                marginTop: "16px",
                            }}
                            >
                            {hide && (
                                <>
                                {" "}
                                <HiddenBtn isHide={isHide} setIsHide={setIsHide} jobId={data.id} reject={false} />{" "}
                                </>
                            )}
                            {apply && (
                                <>
                                {" "}
                                <ApplyButton
                                    isHide={isHide}
                                    setIsHide={setIsHide}
                                    jobId={data.id}
                                    reject={false}
                                    setIsApply={setIsApply}
                                    appliedList={appliedList}
                                    appliedListFun={setAppliedList}
                                    index={index}
                                    collegeId={JSON.parse(localStorage.getItem("user_details")).student_user_details.education.find((item) => item.is_current === true)?.college}
                                />{" "}
                                </>
                            )}
                            </Grid>
                      </Grid>

                      <br />
                        <div style={{ marginTop: "10px" }}>
                          {data?.job_description && (
                            <>
                              <Typography variant="h6">Job Description</Typography>
                              <Typography className={classes.info}>{ReactHtmlParser(data?.job_description.toString("html"))}</Typography>
                            </>
                          )}

                          {data?.eligibility_criteria?.skills?.length > 0 && (
                            <>
                              <Typography variant="h6">Skills</Typography>
                              <div className={classes.chipsection}>
                                {data?.eligibility_criteria?.skills?.map((item, index) => (
                                  <Chip variant="outlined" size="small"  label={item?.skill_name} className={classes.chip} style={{ backgroundColor: pSBC(0.8, domain?.domain ? domain?.color_code : "#007bff"), color: domain?.domain ? domain?.color_code : "#007bff", borderColor: domain?.domain ? domain?.color_code : "#007bff",   }} />
                                ))}{" "}
                              </div>
                            </>
                          )}
                          <br />
                          <Typography variant="h6">Who can apply</Typography>
                          <Typography className={classes.info} style={{ marginTop: -15 }}>
                                {data?.eligibility_criteria?.experiecne?.length > 0 && (
                                <>
                                    <b>Work Experience : </b>
                                    {data?.eligibility_criteria?.experiecne[0]}
                                </>
                                )}
                                {data?.eligibility_criteria?.graduation_years?.length > 0 && (
                                <>
                                    <br /> <b>Eligible Graduation Years : </b> {generateCsvCommas(data?.eligibility_criteria?.graduation_years)}
                                </>
                                )}
                                {data?.eligibility_criteria?.percentage && (
                                <>
                                    <br /> <b>Min. Percentage : </b> {data?.eligibility_criteria?.percentage}
                                </>
                                )}
                                <br />
                           </Typography>

                          {data.eligibility_criteria?.qualifications && (
                            <>
                              <br />
                              <Typography variant="h6">Eligible Degrees</Typography>
                              <div className={classes.chipsection}>
                                <>
                                  {Object.keys(data?.eligibility_criteria?.qualifications)?.map((item, index) => (
                                    <>
                                      <>{item === "Open to All" && <Chip variant="outlined" size="small" label={"Open to All Courses"} className={classes.chip} style={{ backgroundColor: pSBC(0.8, domain?.domain ? domain?.color_code : "#007bff"), color: domain?.domain ? domain?.color_code : "#007bff", borderColor: domain?.domain ? domain?.color_code : "#007bff",   }} />}</>
                                    </>
                                  ))}
                                </>
                                <>
                                  <>
                                    {!showMore &&
                                      Object.keys(data?.eligibility_criteria?.qualifications)
                                        ?.slice(0, 1)
                                        .map((item, index) => (
                                          <>
                                            {item !== "Open to All" &&
                                              !showMore &&
                                              data?.eligibility_criteria?.qualifications[item]
                                                .slice(0, 5)
                                                .map((item2) => (
                                                  <>
                                                    {
                                                      <Chip
                                                        variant="outlined"
                                                        size="small"
                                                        label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                                        className={classes.chip}
                                                        style={{ backgroundColor: pSBC(0.8, domain?.domain ? domain?.color_code : "#007bff"), color: domain?.domain ? domain?.color_code : "#007bff", borderColor: domain?.domain ? domain?.color_code : "#007bff",   }}
                                                      />
                                                    }
                                                  </>
                                                ))}
                                            {!showMore && data?.eligibility_criteria?.qualifications[Object.keys(data?.eligibility_criteria?.qualifications)[0]].length > 5 && (
                                              <span onClick={() => setShowMore(true)} style={{ color: domain?.domain ? domain?.color_code : "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 3 }}>
                                                Show More...
                                              </span>
                                            )}
                                          </>
                                        )
                                      )
                                    }
                                  </>
                                  <>
                                    {showMore &&
                                      Object.keys(data?.eligibility_criteria?.qualifications)?.map((item, index) => (
                                        <>
                                          {item !== "Open to All" &&
                                            showMore &&
                                            data?.eligibility_criteria?.qualifications[item].map((item2) => (
                                              <>
                                                <Hidden smUp>
                                                  <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                                    className={classes.chip}
                                                    // style={{ maxWidth: "100%" }}
                                                    style={{ backgroundColor: pSBC(0.8, domain?.domain ? domain?.color_code : "#007bff"), color: domain?.domain ? domain?.color_code : "#007bff", borderColor: domain?.domain ? domain?.color_code : "#007bff",   }}
                                                  />
                                                </Hidden>
                                                <Hidden smDown>
                                                  <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    label={item2.name ? item + " / " + item2.name : item + " / " + item2.specialization_name}
                                                    className={classes.chip}
                                                    style={{ backgroundColor: pSBC(0.8, domain?.domain ? domain?.color_code : "#007bff"), color: domain?.domain ? domain?.color_code : "#007bff", borderColor: domain?.domain ? domain?.color_code : "#007bff",   }}
                                                  />
                                                </Hidden>
                                              </>
                                            ))}
                                        </>
                                      ))}
                                    {showMore && (
                                      <span onClick={() => setShowMore(false)} style={{ color: domain?.domain ? domain?.color_code : "#007bff", fontSize: 12, cursor: "pointer", paddingTop: 3 }}>
                                        Show less...
                                      </span>
                                    )}
                                  </>
                                </>
                              </div>
                            </>
                          )}
                          {data?.documents?.length > 0 && (
                            <>
                              <br />
                              <Typography variant="h6">Documents Required</Typography>
                              {data?.documents?.map((item, index) => (
                                <Typography className={classes.info}>
                                  {index + 1 + ". " + item}
                                  <br />
                                </Typography>
                              ))}
                            </>
                          )}
                          <br />
                          <Typography variant="h6">Job Round</Typography>
                          {data?.job_rounds?.map((item, index) => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Typography className={classes.info} style={{ marginRight: "10px" }}>
                                <b>Round {" " + (index + 1)}</b>
                              </Typography>{" "}
                              :
                              <Typography className={classes.info} style={{ marginLeft: "10px" }}>
                                {item?.round_name}
                              </Typography>
                            </div>
                          ))}
                          <br />

                          {data?.company && (
                            <>
                              <Typography variant="h6"  >About {data?.company ? data?.company?.company_name : data?.company_details}</Typography>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <LinkIcon style={{  color: domain?.domain ? domain?.color_code : "#007bff", }} />
                                <Typography variant="h6" style={{  color: domain?.domain ? domain?.color_code : "#007bff", }}>
                                  <a href={data?.company?.company_website} target="blank" variant="h6" style={{  color: domain?.domain ? domain?.color_code : "#007bff", }}>
                                    {" "}
                                    {data?.company?.company_website}
                                  </a>
                                </Typography>
                              </div>
                              {data?.company?.company_description && <Typography className={classes.info}>{ReactHtmlParser(data?.company?.company_description.toString("html"))}</Typography>}
                              <br />
                            </>
                          )}
                          <br />
                          {data?.vacancy && <Typography variant="h6">Number of Openings: {data?.vacancy}</Typography>}
                        </div>
                      

                      <Grid container>
                        <Grid xs={12} className="flex" justify="flex-end">
                          {hide && (
                            <>
                              {" "}
                              <HiddenBtn isHide={isHide} setIsHide={setIsHide} jobId={data.id} reject={false} />{" "}
                            </>
                          )}
                          {apply && (
                            <>
                              {" "}
                              <ApplyButton
                                isApply={isApply}
                                setIsApply={setIsApply}
                                jobId={data.id}
                                reject={false}
                                index={index}
                                appliedList={appliedList}
                                appliedListFun={setAppliedList}
                                collegeId={JSON.parse(localStorage.getItem("user_details")).student_user_details.education.find((item) => item.is_current === true)?.college}
                              />
                            </>
                          )}

                          {invite ? (
                            data.is_accepted === false ? (
                              <>
                                {data.interview ? (
                                  <RejectAccept
                                    interviewId={data.interview.interview_id}
                                    jobId={data.id}
                                    reject={reject}
                                    inviteList={inviteList}
                                    setInviteList={setInviteList}
                                    index={index}
                                    rejectList={rejectList}
                                    setRejectList={setRejectList}
                                  />
                                ) : (
                                  <>
                                    <div className="fs-16 text-black-50">invited for interview, details will be posted soon</div>
                                  </>
                                )}{" "}
                              </>
                            ) : null
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default ViewJob2;

const ApplyButton = ({ jobId, reject, isApply, setIsApply, collegeId, index, appliedList, appliedListFun }) => {
  const alert = useAlert();

  const loading = Loading();
  const [done, setDone] = useState(false);

  const data = JSON.parse(localStorage.getItem("user_details"));

  const handelActive = (jobId) => {
    // setIsApply(true);
    // appliedList[index] = true;
    // appliedListFun(appliedList);

    loading.changeLoading(true);
    fetch(BackendBaseApi.NIYUKTI + "job/student/apply/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&round=1&status=1&feedback=1&college_id=${collegeId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert.success(data.data.message);
          loading.changeLoading(false);
          setDone(true);
          setIsApply(true);
          changeIndex(index);
        } else {
          alert.error(data.error);
        }
      });
  };

  const changeIndex = (index) => {
    let list = [...appliedList];
    list[index] = true;
    appliedListFun(list);
  };
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);
  return (
    <Button
      variant="contained"
      style={{  backgroundColor: domain?.domain ? domain?.color_code : "#007bff", float: "right", borderRadius:"30px" }}
      className="text-white fw-700"
      //disabled={appliedList[index]}
      onClick={() => handelActive(jobId)}
    >
      {reject ? (isApply ? "Accepted" : "Accept") : appliedList && appliedList[index] ? "Applied" : "Apply"}
      {/* {
            done ? 'Applied' : 'Apply'
        } */}
    </Button>
  );
};

const HiddenBtn = ({ jobId, hideStatus, isHide, setIsHide }) => {
  const [done, setDone] = useState(hideStatus);
  const handelActive = (jobId) => {
    const data = JSON.parse(localStorage.getItem("user_details"));

    if (hideStatus) {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=unhide`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          setIsHide(false);
        });
    } else {
      fetch(BackendBaseApi.NIYUKTI + "job/student/status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `job_id=${jobId}&user_id=${data.id}&status=hidden`,
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data.message);
          setIsHide(true);
        });
    }
  };

  return (
    <>
      {isHide ? (
        <>
          {" "}
          <Button className="mg-right-15" style={{ marginRight: 15 }} disabled={true} variant="outlined">
            Hidden
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            style={{ marginRight: 15 }}
            className="pointer text-blue mg-right-15"
            onClick={(event) => {
              event.stopPropagation();
              handelActive(jobId);
            }}
          >
            <span className="fs-12 text-blue">hide</span>
          </Button>
        </>
      )}
    </>
  );
};

const RejectAccept = ({ jobId, reject, interviewId, inviteList, setInviteList, index, rejectList, setRejectList }) => {
  const alert = useAlert();
  const [isReject, setIsReject] = useState(false);
  return (
    <>
      {!isReject && <RejectButton jobId={jobId} reject={reject} interviewId={interviewId} rejectList={rejectList} setRejectList={setRejectList} index={index} />}
      <AcceptButton
        setIsReject={setIsReject}
        interviewId={interviewId}
        reject={reject}
        jobId={jobId}
        inviteList={inviteList}
        setInviteList={setInviteList}
        index={index}
        rejectList={rejectList}
        setRejectList={setRejectList}
      />
    </>
  );
};

const RejectButton = ({ jobId, isReject, interviewId, index, rejectList, setRejectList }) => {
  const alert = useAlert();
  const data = JSON.parse(localStorage.getItem("user_details"));

  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    rejectList[index] = true;
    setRejectList(rejectList);
    fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&status=false&interview_id=${interviewId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert.success("Invite Rejected");

        setDone(true);
      });
  };
  return (
    !isReject && (
      <Button
        variant="outlined"
        color="primary"
        className="fw-700 mr-1"
        disabled={done}
        onClick={(event) => {
          event.stopPropagation();
          handelActive(jobId);
        }}
      >
        {rejectList[index] ? "Rejected" : "Reject"}
      </Button>
    )
  );
};

const AcceptButton = ({ jobId, reject, setIsReject, interviewId, inviteList, setInviteList, index, rejectList, setRejectList }) => {
  const alert = useAlert();
  const data = JSON.parse(localStorage.getItem("user_details"));

  const [done, setDone] = useState(false);
  const handelActive = (jobId) => {
    inviteList[index] = true;
    setInviteList(inviteList);

    fetch(BackendBaseApi.NIYUKTI + "job/student/interview/status/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `job_id=${jobId}&user_id=${data.id}&status=true&interview_id=${interviewId}`,
    })
      .then((res) => res.json())
      .then((data) => {
        alert.success("Invite Accepted");

        setDone(true);
        setIsReject(true);
      });
  };
  return (
    <Button
      variant="contained"
      style={{ backgroundColor: "#3282C4" }}
      className="text-white fw-700"
      disabled={inviteList[index]}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
    >
      {reject ? (inviteList[index] ? "Accepted" : "Accept") : done ? "Applied" : "Apply"}
    </Button>
  );
};
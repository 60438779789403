import { Reducer } from "react";
import { TAction, GET_USER_DETAILS, GET_All_DETAILS } from "./action";
import { UserDetailsProps } from "./Dashboard";

interface IStateProps {
  userDetails: UserDetailsProps;
  allDetails: any;
}

export const UserDetails: Reducer<IStateProps, TAction> = (
  state = { userDetails: {}, allDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case GET_All_DETAILS:
      return { ...state, allDetails: action.payload };
    default:
      return state;
  }
};

import Axios, { AxiosPromise } from "axios";

import { BackendBaseApi } from "../../constants/constants";
import { ThunkAction } from "redux-thunk";
import { TReduxStore } from "../..";
import { CreditInfoProps } from "./reducer";

export const GET_CREDIT_INFO = "GET_CREDIT_INFO";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const UPDATE_CREDIT_INFO = "UPDATE_CREDIT_INFO";
export const GET_PROFILE_UNLOCK_INFO = "GET_PROFILE_UNLOCK_INFO";

export interface TAction {
  type: string;
  data?: any;
  additionalDispatchData?: any;
  payload?: any;
}

export const getCreditInfo = (
  setLoading: any
): ThunkAction<AxiosPromise, TReduxStore, {}, TAction> => {
  const userTypeId = localStorage.getItem("user_type_id");
  const userId = localStorage.getItem("user_id");
  setLoading(true);
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH +
        `api/common/user_credit_data/?user_type_id=${userTypeId}&user_id=${userId}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        setLoading(false);
      }
      setLoading(false);
      throw error;
    });

    dispatch({
      type: GET_CREDIT_INFO,
      payload: res.data.data,
    });
    setLoading(false);
    return res;
  };
};

export const getProfileUnlockInfo = (
  setLoading: any
): ThunkAction<AxiosPromise, TReduxStore, {}, TAction> => {
  const userTypeId = localStorage.getItem("user_type_id");
  const userId = localStorage.getItem("user_id");
  setLoading(true);
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/profile_unlock_info/?user_type_id=${userTypeId}&user_id=${userId}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        setLoading(false);
      }
      setLoading(false);
      throw error;
    });

    dispatch({
      type: GET_PROFILE_UNLOCK_INFO,
      payload: res.data.data,
    });
    setLoading(false);
    return res;
  };
};

export const getTransactionHistory = (
  page: any,
  setCreditLoader: any
): ThunkAction<AxiosPromise, TReduxStore, {}, TAction> => {
  const userTypeId = localStorage.getItem("user_type_id");
  const userId = localStorage.getItem("user_id");
  setCreditLoader(true);
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH +
        `api/common/credit_history/?user_type_id=${userTypeId}&user_id=${userId}&page=${page}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        setCreditLoader(false);
      }
      throw error;
    });

    dispatch({
      type: GET_TRANSACTION_HISTORY,
      payload: res.data.data,
    });
    setCreditLoader(false);
    return res;
  };
};

export const updateCreditInfo = (
  data: CreditInfoProps
): ThunkAction<any, TReduxStore, {}, TAction> => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CREDIT_INFO, payload: data });
  };
};

import {
  createStyles,
  FormControlLabel,
  makeStyles,
  Switch,
} from "@material-ui/core";
import React, { useState } from "react";
import SelectSearch from "react-select-search";
import "./Panes.css";
import Filter from "../../../assets/common/Filter2.png";

const TrackPane = ({
  profiles,
  jobProfile,
  setJobProfile,
  setShow,
  search,
  setSearch,
  jobTypes,
  companies,
  setJobType,
  setCompany,
  selectedJobType,
  setSelectedJobType,
  allCompanies,
  setSelectedCompany,
  onlyClosed,
  setOnlyClosed,
  campusType,
  setCampusType,
}) => {
  const [selected, setSelected] = useState("All Jobs");
  const handleSelect = (e) => {
    setSelected(e.target.value);
    setShow(e.target.value);
  };
  const [status, setStatus] = useState("Applied");

  const classes = useStyles();

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <nav
        // className="job-pane fs-14 navbar fixed-top small-nav navbar-expand navbar-light topbar shadow-main"
        style={{
          borderRadius: "14px",
          padding: "2px",
          position: "sticky",
          width: "100%",
          height: "auto",
          backdropFilter: "blur(30px)",
          boxShadow: "rgb(0 0 0 / 10%) 0px 25px 30px 0px",
          border: "1px solid rgb(241 241 241)",
          backgroundColor: "rgb(255 255 255)",
          marginBottom: "30px",
          top: "64px",
          zIndex: "100",
        }}
      >
        <div
          ///  className="row fs-18 mx-5"
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            paddingRight: "20px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {/* Separate this as a component */}
          {/* <p className="my-auto ml-5 pl-3">Filter By : </p> */}
          <img src={Filter} fontSize="inherit" alt="" height="35px" />

          <div className="dropdown ml-4 " style={{ borderRadius: "30px" }}>
            <SelectSearch
              borderRadius={16}
              options={
                jobTypes &&
                jobTypes.length > 0 &&
                jobTypes.map((item) => ({
                  name: item.job_type,
                  value: item.id,
                }))
              }
              onChange={(val) => setSelectedJobType(val)}
              placeholder="Job type"
              search
            />
          </div>

          <div className="dropdown ml-4">
            <SelectSearch
              options={
                profiles &&
                profiles.length > 0 &&
                profiles.map((item) => ({
                  name: item.job_title,
                  value: item.job_id,
                }))
              }
              onChange={(val) => {
                setJobProfile(val);
              }}
              placeholder="Profile"
              style={{ borderRadius: "30px" }}
            />
          </div>

          {/* <FormControlLabel
            value="end"
            control={
              <Switch
                size="small"
                color="primary"
                checked={onlyClosed}
                onChange={(e) => setOnlyClosed(e.target.checked)}
              />
            }
            label="Closed jobs"
            labelPlacement="end"
          /> */}
          <FormControlLabel
            value="end"
            control={
              <Switch
                size="small"
                color="primary"
                checked={campusType}
                onChange={(e) => setCampusType(e.target.checked)}
                style={{ borderRadius: "30px" }}
              />
            }
            label="On Campus jobs"
            labelPlacement="end"
          />
        </div>
        {/* Separate this as a component */}
        {/* <div className="gw-input-container fs-18 ml-auto">
              <i class="fas fa-search"></i>
              <input
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                type="text"
                className="ml-5 gw-input input-secondary input-small mr-3"
                value={search}
                placeholder="Search by name..."
              ></input>
            </div> */}
      </nav>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      width: `calc(100% - 215px)`,
      marginLeft: "220px",
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
    },
    headerTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000000",
      fontFamily: "OpenSans",
    },
    headerTitle2: {
      fontSize: "18px",
      color: "#000000",
      fontFamily: "Proxima",
    },
    dropdown: {
      width: "220px",
      height: "36px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    searchBox: {
      width: "219px",
      height: "36px",
      background: "#F2F2F2",
      boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "30px",
      padding: "0% 2%",
    },
    selectedTabText: {
      color: "#000",
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Proxima",
    },
    normalTabText: {
      color: "#000",
      fontSize: "18px",

      fontFamily: "Proxima",
    },
    selectedTab: {
      height: "40px",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      backgroundColor: "#E5E5E5",
      position: "absolute",
      top: "43%",
      borderRadius: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
    },
    normalTab: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
      height: "40px",
    },
    mainContentBox: {
      width: `calc(100% - 220px)`,
      marginLeft: "220px",
      background: "#E5E5E5",
      padding: "1% 2%",
      boxSizing: "border-box",
      display: "flex",
    },
    filterPanel: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      width: "240px",
    },
  })
);
export default TrackPane;

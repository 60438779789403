import React, { Children } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./layout.css";
import { Breakpoint, BreakpointProvider } from "react-socks";
import Navbar from "../Navbar/StudentNavbar";
import {
  Container,
  Grid,
  Box,
  makeStyles,
  createStyles,
  Hidden,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

export default function Layout({ children, hideDownload=false }) {
  const classes = useStyles();
  return (
    <Box width="100%" bgcolor="#f2f2f2">
      <Navbar hideDownload={hideDownload}/>
      <Container style={{ backgroundColor: "#f2f2f2" }}>
        <Grid container id="grid_container">
          <Hidden smDown>
            <Grid item sm={2}>
              <Box className={classes.scrollBox}>
                <Sidebar />
              </Box>
            </Grid>
          </Hidden>
          {/* <Hidden smDown> */}
            <Grid item sm={10} xs={12} style={{ width: "100%" }}>
              <Box className={classes.scrollBox} paddingLeft="2%">
                {children}
              </Box>
            </Grid>
          {/* </Hidden> */}
          {/* <Hidden smUp>
            <Grid item sm={10} xs={12} style={{ width: "100%" }}>
              <Box>{children}</Box>
            </Grid>
          </Hidden> */}
        </Grid>
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    scrollBox: {
      height: "91vh",
      overflowY: "scroll",
      "& webkit-scrollbar": {
        width: "0px",
        background: "transparent",
      },
    },
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0px",
        display: "none",
      },
      "*::-webkit-scrollbar-track": {
        // "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        //outline: "1px solid slategrey",
      },
    },
    "@media screen and (max-width: 768px)": {
      scrollBox: {
        height: "auto",
        overflowY: "scroll",
        "& webkit-scrollbar": {
          width: "0px",
          background: "transparent",
        },
      },
    },
  })
);

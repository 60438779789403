import {
  Box,
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import { FilterListOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SelectSearch from "react-select-search";
import { TReduxStore } from "../../..";
import NoJobs from "../../../assets/images/no-jobs.png";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import { changeState, FILTER_COUNT_COLLEGE, getAllLocations, getIndustries, UPDATE_INDUSTRY, UPDATE_LOCATION_EXPLORE_COLLEGE, UPDATE_SIZE } from "../../../common/Explore/action";
import { CityArrayProps, CityListProps, IndustryListProps, IndustryProps, InitialStateProps, LocationProps } from "../../../common/Explore/reducer";
import CompanyCard from "../../../components/college/companyCard/CompanyCard";
import CommonButton from "../../../components/common/CommonButton/CommonButton";
import CommonChip from "../../../components/CommonChip/CommonChip";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/httpRequest";

export interface CompanyDetailsProps {
  company_id: number;
  first_name: string;
  hiring_now: number;
  hiring_total: number;
  index: number;
  last_name: string;
  main_user_type: string;
  sub_user_type: string;
  user_id: number;
  email?: string | null;
  phone?: string | null;
}

export interface JobDataProps {
  city: string;
  company_description: string;
  company_name: string;
  coworking: string | null;
  created_time: string;
  formatted_time: string;
  id: number;
  industry: string;
  is_onboarded: boolean;
  logo: string | null;
  size: string;
  website: string;
  year_founded: string | null;
  company_user: CompanyDetailsProps;
  show_company_detail: boolean;
}

export interface IndustryIdProps {
  id: number;
  industry_name: string;
}

export interface CityIdProps {
  city_id: number;
  city: string;
}

export default function Invite() {
  const [jobData, setJobData] = useState<JobDataProps[]>([]);
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [companySize, setCompanySize] = useState<string[]>([]);
  const [organizationType, setOrganizationType] = useState<string[]>([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [cityIds, setCityIds] = useState<CityIdProps[]>([]);
  const [industry, setIndustry] = useState([]);
  const [city, setCity] = useState([]);
  const [startYear, setStartYear] = useState<number[]>([]);
  const [filterCount, setFilterCount] = useState(0);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [totalCompanyCount, setTotalCompanyCount] = useState(0);
  const [inviteModal, setInviteModal] = useState(false);
  const [industry_n, setIndustryN] = useState("");
  const [city_n, setCity_n] = useState("");
  const [loadingFilter, setLoadingFilter] = useState(false);
  const location = useLocation();
  const [searching, setSearching] = useState("");
  const [buttonClickSearch, setButtonClickSearch] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleFilterChange = (dispatcher: string, data: any) => {
    dispatch(changeState(dispatcher, data));
  };

  useEffect(() => {
    dispatch(getIndustries());
    dispatch(getAllLocations());
  }, []);
  const getCities = async () => {
    const res = await Axios.get(`${BackendBaseApi.PRAVESH}api/location/city/`).catch((err) => {
      throw err;
    });

    setCityIds(res.data);
  };

  useEffect(() => {
    getCities();
    getIndustries();
    //@ts-ignore
    if (location.flag === 1424) {
      setFilterDialog(true);
    }
  }, []);

  const companySizes = [
    { name: "1-10", value: "1-10" },
    { name: "11-50", value: "11-50" },
    { name: "51-100", value: "51-100" },
    { name: "101-1000", value: "101-1000" },
    { name: "1000+", value: "1000+" },
  ];

  useEffect(() => {
    GetData(BackendBaseApi.PRAVESH, "api/college/invite/?state=invite", { headers: localStorage.getItem("gw_token") }, setJobData);
    setLoader(true);
  }, []);
  const GetData = async (baseUrl: string, endPoint: string, body: any, updateState: any) => {
    if (!isApiCalled) {
      setIsApiCalled(true);
      setLoading(true);
      let res = await httpRequest(baseUrl, endPoint, body);

      if (res.data.next === null) {
        setEnd(true);
      } else {
        setNewURL(res.data.next.slice(0, 31));
        setNewEndPoint(res.data.next.slice(31));
      }
      updateState(jobData.concat([...res.data.results]));
      setTotalCompanyCount(res.data.count);
      setIsApiCalled(false);
    }
    setLoading(false);
    setLoader(false);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    //@ts-ignore
    let e = event.nativeEvent;

    if (
      //@ts-ignore
      e.target.scrollTop + 10 >=
      //@ts-ignore
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && newURL !== "" && !isApiCalled) {
        GetData(newURL, newEndPoint, { headers: localStorage.getItem("gw_token") }, setJobData);
      }
    }
  };
  const classes = useStyles();

  const handleFilterDialog = () => {
    setFilterDialog(false);
  };

  const generateCsv = (arr: any) => {
    let str = "";
    arr.forEach((item: LocationProps, index: number) => (arr.indexOf(item) !== arr.length - 1 ? (str += item.city_id + ",") : (str += item.city_id)));
    return str;
  };

  const generateCsvJobSegment = (arr: any) => {
    let str = "";
    arr.forEach((item: IndustryProps, index: number) => (arr.indexOf(item) !== arr.length - 1 ? (str += item.industry_id + ",") : (str += item.industry_id)));
    return str;
  };

  const checkLocation = () => {
    if (stateMemory.location.length > 0) {
      return `&city=${generateCsv(stateMemory.location)}`;
    } else return "";
  };

  const checkJobSegment = () => {
    if (stateMemory.industry.length > 0) {
      return `&industry=${generateCsvJobSegment(stateMemory.industry)}`;
    } else return "";
  };
  const checkCTC = () => {
    if (stateMemory.size !== null) {
      return `&size=${stateMemory.size}`;
    } else return "";
  };
  const checkSearch = () => {
    if (buttonClickSearch !== "") return `&search=${buttonClickSearch}`;
    else return ``;
  };

  useEffect(() => {
    if (searching !== "") getFilteredData();
  }, [buttonClickSearch]);

  const getFilteredData = async () => {
    setLoadingFilter(true);
    setLoading(true);
    setLoader(true);
    const res = await Axios({
      method: "GET",
      url: `${BackendBaseApi.PRAVESH}api/college/invite/?state=invite${checkCTC()}${organizationType.length > 0 ? "&organization_type=" + organizationType : ""}${checkLocation()}${
        startYear.length > 0 ? "&start_year=" + startYear : ""
      }${checkJobSegment()}${checkSearch()}`,
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    }).catch((err) => {
      throw err;
    });

    if (res.data.data.next === null) {
      setEnd(true);
    } else {
      setNewURL(res.data.data.next.slice(0, 31));
      setNewEndPoint(res.data.data.next.slice(31));
    }
    setJobData(res.data.data.results);
    setTotalCompanyCount(res.data.data.count);
    setFilterApplied(false);
    setLoading(false);
    setLoader(false);
    setLoadingFilter(false);
  };
  const stateMemory = useSelector<TReduxStore, InitialStateProps>((state) => state.ExploreFilterCollege);
  useEffect(() => {
    if (filterApplied) {
      getFilteredData();
    }
  }, [filterApplied]);

  const handleDelete = (key: string | number, arr: string[] | number[], setArr: any) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);

    if (index > -1) {
      sampleArr.splice(index, 1);
      setArr(sampleArr);
    }
  };

  const handleDeleteNew = (key: LocationProps | IndustryProps, arr: any, setArr: string) => {
    let sampleArr = arr;

    const index = sampleArr.indexOf(key);
    if (index > -1) {
      sampleArr.splice(index, 1);

      handleFilterChange(setArr, sampleArr);
    }
  };

  const generateChipText = (num: number) => {
    if (num === 2) {
      return "2+ Years";
    } else if (num === 5) {
      return "5+ Years";
    } else if (num === 10) {
      return "10+ Years";
    }
  };

  const handleClearAll = () => {
    setNewURL("");
    setCompanySize([]);
    setOrganizationType([]);
    setCity([]);
    setIndustry([]);
    setStartYear([]);
    handleFilterChange(UPDATE_SIZE, null);
    handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, []);
    handleFilterChange(UPDATE_INDUSTRY, []);
    handleFilterChange(FILTER_COUNT_COLLEGE, 0);
    setFilterApplied(true);
    setFilterDialog(false);
    setLoader(true);
    setEnd(false);
  };

  useEffect(() => {
    let num = 0;
    if (stateMemory.size !== null) {
      num += 1;
      handleFilterChange(FILTER_COUNT_COLLEGE, num);
    }
    if (organizationType.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT_COLLEGE, num);
    }
    if (stateMemory.location.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT_COLLEGE, num);
    }
    if (stateMemory.industry.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT_COLLEGE, num);
    }
    if (startYear.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT_COLLEGE, num);
    }

    setFilterCount(num);
  }, [stateMemory.size, organizationType, stateMemory.location.length, , stateMemory.industry.length, startYear]);

  const handleSubmit = async () => {
    if (!companyName || !hrName || !hrEmail) {
      alert("Please Enter Valid Details");
      return;
    }
    fetch(BackendBaseApi.PRAVESH + "api/college/invite/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      body: JSON.stringify({
        company_id: null,
        company_name: companyName,
        hr_name: hrName,
        hr_email: hrEmail,
        note: null,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.data.message);
        setHrEmail("");
        setHrName("");
        setCompanyName("");
      })
      .catch((err) => {
        alert("some Error Occurred");
      });
  };
  const [companyName, setCompanyName] = useState("");
  const [hrName, setHrName] = useState("");
  const [hrEmail, setHrEmail] = useState("");

  const citySelected = useSelector<TReduxStore, any>(
    //@ts-ignore
    (state) => state.ExploreFilterCollege.location
  );
  const industryselected = useSelector<TReduxStore, IndustryProps>(
    //@ts-ignore
    (state) => state.ExploreFilterCollege.industry
  );

  const cityList = useSelector<TReduxStore, CityArrayProps>(
    //@ts-ignore
    (state) => state.CityArray.cityArray
  );
  const cityListObject = useSelector<TReduxStore, CityListProps>((state) => state.CityList.cityList);
  const industries = useSelector<TReduxStore, IndustryListProps>(
    //@ts-ignore
    (state) => state.IndustryList.IndustryList
  );

  const cities = Object.keys(cityListObject).map((City) => {
    return { name: City, value: City };
  });

  useEffect(() => {
    if (city_n) {
      if (!stateMemory.location.map((item) => item.city).includes(city_n)) {
        const currentLocation = {
          city: city_n,
          city_id: cityListObject[city_n],
        };
        let job_location = [...citySelected];
        job_location.push(currentLocation);

        handleFilterChange(UPDATE_LOCATION_EXPLORE_COLLEGE, job_location);
      }
    }
  }, [city_n]);

  useEffect(() => {
    if (industry_n) {
      let selectedCourses = stateMemory.industry;
      if (selectedCourses.map((selected_course) => selected_course.industry_name).includes(industry_n)) return;

      selectedCourses.push(industries[industry_n]);

      handleFilterChange(UPDATE_INDUSTRY, selectedCourses);
    }
  }, [industry_n]);

  const Location = () => (
    <Autocomplete
      value={city_n}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setCity_n(newValue);
        }
      }}
      id="controllable-states-demo"
      options={cities.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
    />
  );

  const industrie_s = Object.keys(industries).map((jobrole) => {
    return { name: jobrole, value: jobrole };
  });

  const Hiringfor = () => (
    <Autocomplete
      value={industry_n}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setIndustryN(newValue);
        }
      }}
      id="controllable-states-demo"
      options={industrie_s.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Industry Type" variant="outlined" />}
    />
  );

  return (
    <>
      <Box marginRight="4%" marginLeft="4%">
        <div style={{ display: "flex", flexFlow: "column nowrap", justifyContent: "center", flexDirection: "row" }}>
          <div className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" style={{ marginRight: "10px" }}>
                  If you can’t find the company you are looking for, <b>click on</b>
                </Typography>
                <CommonButton variant="outlined" onClick={() => setInviteModal(true)}>
                  Invite Other Company
                </CommonButton>
              </Box>
              <>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", alignContent: "center", alignItems: "center" }}>
                  <TextField
                    id="outlined-name-2"
                    label="Search"
                    type="text"
                    value={searching}
                    onChange={(e) => {
                      setSearching(e.target.value);
                    }}
                    variant="outlined"
                    style={{
                      width: "100%",
                      padding: "3px",
                    }}
                  />
                  <IconButton
                    color="primary"
                    aria-label="search"
                    // style={{ marginTop: "20" }}
                    onClick={() => {
                      if (buttonClickSearch !== searching) {
                        setButtonClickSearch(searching);
                        setJobData([]);
                        setEnd(false);
                        setNewEndPoint("");
                        setNewURL("");
                      }
                    }}
                    disabled={searching === "" ? true : false}
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </>
              <CommonButton variant="outlined" startIcon={<FilterListOutlined />} onClick={() => setFilterDialog(true)}>
                Filters
                {` (${filterCount})`}
              </CommonButton>
            </Toolbar>
          </div>
        </div>
        <div className={classes.mainCont} onScroll={handleScroll}>
          <Grid container>
            <Grid item xs={12}>
              {loader === false ? (
                jobData.length ? (
                  <Typography variant="h3" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <b>Recommendations for you ({totalCompanyCount} Companies)</b>
                  </Typography>
                ) : (
                  <Typography variant="h3">
                    <b>"No Company Found! Please Change Filters"</b>
                  </Typography>
                )
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={5}>
              {buttonClickSearch !== "" && (
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  {/* <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "bold",
                    marginLeft: 50,
                    marginTop: 10,
                  }}
                > */}
                  <Typography
                    variant="h3"
                    style={{
                      marginLeft: 50,
                      marginTop: 10,
                    }}
                  >
                    <b>You searched for "{buttonClickSearch}"...</b>
                  </Typography>
                  {/* </p> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      // setSearching("");
                      setButtonClickSearch("");
                      setJobData([]);
                      setEnd(false);
                      setNewEndPoint("");
                      setNewURL("");
                    }}
                    // style={{ borderRadius: 30 }}
                  >
                    Clear your Search
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
          <br />
          {jobData.length > 0 && !loadingFilter ? (
            <CompanyCard data={jobData} invitation={true} />
          ) : loading ? (
            <Box padding="2% 2%">
              <JobCardSkeleton />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="5%" paddingBottom="5%">
              <img src={NoJobs} width="341px" height="auto" />
              <p
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                }}
              >
                No Recommended Companies for you
              </p>
            </Box>
          )}
          {loading && (
            <Box padding="2% 2%">
              <JobCardSkeleton />
            </Box>
          )}
        </div>
        <Dialog
          open={filterDialog}
          onClose={handleFilterDialog}
          className={classes.dialog}
          maxWidth="lg"
          PaperProps={{
            style: {
              borderRadius: 30,
            },
          }}
        >
          <DialogContent>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleFilterDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box padding="2%">
              {stateMemory.size !== null && (
                <CommonChip
                  label={stateMemory.size}
                  onDelete={() => handleFilterChange(UPDATE_SIZE, null)}
                  style={{
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                />
              )}
              {organizationType.map((item, index) => (
                <CommonChip
                  label={item}
                  onDelete={() => handleDelete(item, organizationType, setOrganizationType)}
                  style={{
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                />
              ))}
              {stateMemory.industry.length > 0 &&
                stateMemory.industry.map((item: IndustryProps, index: number) => (
                  <CommonChip
                    label={item.industry_name}
                    onDelete={() => handleDeleteNew(item, stateMemory.industry, UPDATE_INDUSTRY)}
                    style={{
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                ))}
              {citySelected.length > 0 &&
                citySelected.map((item: LocationProps, index: number) => (
                  <CommonChip
                    label={item.city}
                    onDelete={() => handleDeleteNew(item, citySelected, UPDATE_LOCATION_EXPLORE_COLLEGE)}
                    style={{
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                  />
                ))}
              {startYear.map((item, index) => (
                <CommonChip
                  label={generateChipText(item)}
                  onDelete={() => handleDelete(item, startYear, setStartYear)}
                  style={{
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                />
              ))}
            </Box>
            <Divider />
            <Box>
              <p
                style={{
                  fontSize: 26,
                  fontWeight: "bold",
                  fontFamily: "ProximaNova",
                }}
              >
                Company details
              </p>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Box width="30%">
                      <p style={{ marginRight: "15px" }}>Company size</p>
                    </Box>
                    <Box width="60%">
                      {/* <SelectSearch
                      multiple={true}
                      printOptions="on-focus"
                      options={[
                        {
                          name: "1-10",
                          value: "1-10",
                        },
                        {
                          name: "11-50",
                          value: "11-50",
                        },
                        {
                          name: "51-100",
                          value: "51-100",
                        },
                        {
                          name: "101-1000",
                          value: "101-1000",
                        },
                        {
                          name: "1000+",
                          value: "1000+",
                        },
                      ]}
                      value={companySize}
                      //@ts-ignore
                      onChange={(val) => setCompanySize(val)}
                      search
                    /> */}
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-outlined-label">Company Size</InputLabel>
                        <Select value={stateMemory.size} onChange={(e) => handleFilterChange(UPDATE_SIZE, e.target.value)} label="Size">
                          {companySizes.map((data) => {
                            return <MenuItem value={data.value}>{data.name}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Box width="30%">
                      <p style={{ marginRight: "15px" }}>Organization type</p>
                    </Box>
                    <Box
                      width="60%"
                      // @ts-ignore

                      id={theme.palette.type === "dark" && "theme-select-search"}
                    >
                      <SelectSearch
                        multiple={true}
                        printOptions="on-focus"
                        style={{ width: "60%" }}
                        options={[
                          {
                            name: "Private",
                            value: "Private",
                          },
                          {
                            name: "SemiPrivate.",
                            value: "Semi Private",
                          },
                          {
                            name: "Government",
                            value: "Government",
                          },
                        ]}
                        value={organizationType}
                        //@ts-ignore
                        onChange={setOrganizationType}
                        placeholder="Select"
                        search
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Box width="30%">
                      <p style={{ marginRight: "15px" }}>Location</p>
                    </Box>
                    <Box width="60%">
                      {/* <SelectSearch
                      multiple={true}
                      printOptions="on-focus"
                      autoComplete="on"
                      //@ts-ignore
                      options={cityIds.map((item) => ({
                        name: item.city,
                        value: item.city_id,
                      }))}
                      value={city}
                      //@ts-ignore
                      onChange={(val) => setCity(val)}
                      placeholder="Select"
                      search
                    /> */}
                      <Location />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Box width="30%">
                      <p style={{ marginRight: "15px" }}>Industry</p>
                    </Box>
                    <Box width="60%">
                      {/* <SelectSearch
                      multiple={true}
                      printOptions="on-focus"
                      autoComplete="on"
                      style={{ width: "60%" }}
                      //@ts-ignore
                      options={industryIds.map((item) => ({
                        name: item.industry_name,
                        value: item.id,
                      }))}
                      value={industry}
                      //@ts-ignore
                      onChange={(val) => setIndustry(val)}
                      placeholder="Select"
                      search
                    /> */}
                      <Hiringfor />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Box width="30%">
                      <p style={{ marginRight: "15px" }}>No. of years founded from now</p>
                    </Box>
                    <Box
                      width="60%" // @ts-ignore
                      id={theme.palette.type === "dark" && "theme-select-search"}
                    >
                      <SelectSearch
                        multiple={true}
                        printOptions="on-focus"
                        style={{ width: "60%" }}
                        options={[
                          {
                            name: "2+ years",
                            //@ts-ignore
                            value: 2,
                          },
                          {
                            name: "5+ years",
                            //@ts-ignore
                            value: 5,
                          },
                          {
                            name: "10+ years",
                            //@ts-ignore
                            value: 10,
                          },
                        ]}
                        //@ts-ignore
                        value={startYear}
                        //@ts-ignore
                        onChange={(val) => setStartYear(val)}
                        placeholder="Select"
                        search
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <CommonButton onClick={handleClearAll}>Clear All</CommonButton>
            <CommonButton
              onClick={() => {
                setNewURL("");
                handleFilterDialog();
                setFilterApplied(true);
              }}
            >
              Apply Filters
            </CommonButton>
          </DialogActions>
        </Dialog>
        <Dialog open={inviteModal} onClose={() => setInviteModal(false)}>
          <DialogTitle>Invite Other Company</DialogTitle>
          <DialogContent>
            <Typography variant="body1">Connect with companies easily which are not on GetWork</Typography>
            <div className="row">
              <div className="col-md-5 d-flex justify-content-end">
                <p className="fw-700 fs-16">Company Name</p>
              </div>
              <div className="col-md-6">
                <input type="text" onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 d-flex justify-content-end">
                <p className="fw-700 fs-16">HR Name</p>
              </div>
              <div className="col-md-6">
                <input type="text" onChange={(e) => setHrName(e.target.value)} value={hrName} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 d-flex justify-content-end">
                <p className="fw-700 fs-16">HR Email id</p>
              </div>
              <div className="col-md-6">
                <input type="email" onChange={(e) => setHrEmail(e.target.value)} value={hrEmail} />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <CommonButton onClick={() => setInviteModal(false)}>Close</CommonButton>
            <CommonButton onClick={handleSubmit}>Done</CommonButton>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

const OtherCompanyModal = () => {
  const handleSubmit = async () => {
    if (!companyName || !hrName || !hrEmail) {
      alert("Please Enter Valid Details");
      return;
    }
    fetch(BackendBaseApi.PRAVESH + "api/college/invite/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      body: JSON.stringify({
        company_id: null,
        company_name: companyName,
        hr_name: hrName,
        hr_email: hrEmail,
        note: null,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.data.message);
        setHrEmail("");
        setHrName("");
        setCompanyName("");
      })
      .catch((err) => {
        alert("some Error Occurred");
      });
  };
  const [companyName, setCompanyName] = useState("");
  const [hrName, setHrName] = useState("");
  const [hrEmail, setHrEmail] = useState("");
  return (
    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" style={{ marginTop: "10%" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Invite other company
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">Connect with companies easily which are not on GetWork</div>
            </div>
            <div className="row">
              <div className="col-md-5 d-flex justify-content-end">
                <p className="fw-700 fs-16">Compnay Name</p>
              </div>
              <div className="col-md-6">
                <input type="text" onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 d-flex justify-content-end">
                <p className="fw-700 fs-16">HR Name</p>
              </div>
              <div className="col-md-6">
                <input type="text" onChange={(e) => setHrName(e.target.value)} value={hrName} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 d-flex justify-content-end">
                <p className="fw-700 fs-16">HR Email id</p>
              </div>
              <div className="col-md-6">
                <input type="email" onChange={(e) => setHrEmail(e.target.value)} value={hrEmail} />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appbar: {
      width: `100%`,
      marginTop: "44px",
      padding: "1% 0%",
      display: "flex",
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.up(1000)]: {
        width: "100%",
      },
    },
    // appbar2: {
    //   width: `100%`,
    //   padding: "1% 0%",
    //   display: "flex",
    //   background: "#ffffff",
    //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    //   [theme.breakpoints.up(1000)]: {
    //     width: "100%",
    //   },
    // },
    appBar: {
      width: "78%",
      position: "fixed",
      background: "#ffffff",
      boxShadow: "0 0 3px 0 #ced4da",
      height: "64px",
      backgroundColor: theme.palette.background.paper,
      //marginTop: "66px",
      borderRadius: "30px",
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "30px",
    },
    deleteIcon: {
      color: "#ffffff",
    },
    dialog: {
      // width: "600px",
    },
    mainCont: {
      marginTop: "100px",
      height: "100vh",
      overflowY: "scroll",
    },
  })
);

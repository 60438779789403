import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  Button,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  InputLabel,
  Typography,
  OutlinedInput,
  Paper,
  Grid,
  FormHelperText,
  CircularProgress,
  Hidden,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { display } from "@material-ui/system";
import Logo from "../../../../../assets/images/sidepanel/logo.png";
import Facebook from "../../../../../assets/facebook.png";
import Google from "../../../../../assets/google-icon.png";
import {
  emailRegex,
  FACEBOOK_APP_ID,
  NEXT_APP_ENDPOINT,
  GOOGLE_CLIENT_ID
} from "../../../../../constants/constants";
import { Link, useHistory, useLocation } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useSelector } from "react-redux";
import Otp from "../../../../student/Dialog/Otp";
import EnterOtp from "../../../../student/Dialog/EnterOtp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    fontFamily: " Nunito",
    color: theme.palette.domain.main,
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    // width:"60%",
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.domain.hover,
    },
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
    border: `1px solid ${theme.palette.domain.main}`,
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
  grid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  createNew: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
  floatingLabelFocusStyle: {
    color: "#6c757d",
    padding: "6px",
    fontSize: "14px",
    paddingLeft: "10px",
    fontFamily: "Nunito",
    fontWeight: "600",
  },

  input: {
    height: 48,
    fontFamily: "Nunito",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);
export default function Form(props) {
  const {
    type,
    email,
    setEmail,
    password,
    setPassword,
    loginUser,
    emailErrorMessage,
    setEmailErrorMessage,
    passwordErrorMessage,
    setPasswordErrorMessage,
    responseGoogle,
    responseFacebook,
    loading,
    buttonClicked,
    setButtonClicked,
    fromApplyPage,
    setValue,
    checkUser,
  } = props;
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const history = useHistory();
  const location = useLocation();
  const domain = useSelector(
    (state) => state.SubDomainDetails.subDomainDetails
  );
  const [openOtp, setOpenOtp] = React.useState(false);
  const [otpDetails, setOtpDetails] = useState({
    email: null,
    otp: null,
  });
  const [otpDetailsError, setOtpDetailsError] = useState({
    email: null,
    otp: null,
    user_type: false,
  });
  const [codeError, setCodeError] = useState(null);

  const [code, setCode] = useState("");
  const handleClickOtp = () => {
    setOpenOtp(false);
    setOtpDetails({ ...otpDetails, email: "", otp: "" });
    setCodeError(null);
    setCode("");
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [validate, setValidate] = useState(false);

  const validateData = () => {
    setValidate(false);
    if (email === "") {
      setEmailErrorMessage("Email cannot be left blank");
      setValidate(true);
    }
    if (!emailRegex.test(email)) {
      setEmailErrorMessage("Please enter a valid email address");
      setValidate(true);
    }
    if (password === "") {
      setPasswordErrorMessage("Password field cannot be left blank");
      setValidate(true);
    }
    if (password.length < 6) {
      setPasswordErrorMessage("Please enter a valid Password 6 digits long");
      setValidate(true);
    }
    setButtonClicked(true);
  };

  useEffect(() => {
    if (!validate && buttonClicked && email !== "" && password !== "") {
      loginUser(type);
    }
  }, [buttonClicked]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.grid}>
            {/* <img src={Logo} height="70px" width="80px" />
            <br /> */}
            <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
              {fromApplyPage ? (
                <b>Login to Apply for the Job</b>
              ) : (
                <b>
                  {type === "Student" && (
                    <>
                      Login to Get Personalized Job
                      <br /> Recommendations
                    </>
                  )}
                  {type === "Company" && (
                    <>
                      Login to hire high-quality candidates
                      <br /> with GetWork
                    </>
                  )}
                  {type === "College" && (
                    <>
                      Login to Bring Your Campus
                      <br /> Placement Online
                    </>
                  )}
                </b>
              )}
            </Typography>
            <br />
            <CssTextField
              className={classes.margin}
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label="Email"
              variant="outlined"
              id="custom-css-outlined-input"
              error={emailErrorMessage !== "" ? true : false}
              helperText={
                <span
                  className={
                    emailErrorMessage !== ""
                      ? classes.errorPassword
                      : classes.noErrorPassword
                  }
                >
                  {emailErrorMessage}
                </span>
              }
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErrorMessage("");
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormControl
              color="#007bff"
              className={clsx(classes.margin, classes.textField, classes.form)}
              variant="outlined"
              error={passwordErrorMessage !== "" ? true : false}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                style={{ borderBottomColor: "#007bff" }}
                className={classes.floatingLabelFocusStyle}
              >
                Password
              </InputLabel>

              <OutlinedInput
                style={{
                  borderRadius: "30px",
                  // padding: "22px",
                  borderColor: "#007bff",
                  height: 48,
                }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordErrorMessage("");
                  setButtonClicked(false);
                }}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText
                style={{
                  fontFamily: " Nunito",

                  fontSize: "12px",
                }}
                id="standard-weight-helper-text"
                className={
                  passwordErrorMessage !== ""
                    ? classes.errorPassword
                    : classes.noErrorPassword
                }
              >
                {passwordErrorMessage !== "" && passwordErrorMessage}
              </FormHelperText>
            </FormControl>
            <Grid container style={{ justifyContent: "flex-end" }}>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Button size="small" style={{ color: "#007bff" }}>
                  <Typography
                    color="primary"
                    className={classes.pass}
                    style={{
                      fontFamily: " Nunito",
                      color: domain?.domain ? domain?.color_code : "#007bff",
                      fontSize: "12px",
                      fontWeight: "900",
                    }}
                  >
                    <Link
                      to="/forgot-password"
                      style={{
                        fontFamily: " Nunito",
                        color: domain?.domain ? domain?.color_code : "#007bff",
                        fontSize: "12px",
                        fontWeight: "900",
                      }}
                    >
                      Forgot Password
                    </Link>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {/* <CssTextField
              className={classes.margin}
              label="College Name"
              variant="outlined"
              id="custom-css-outlined-input"
            /> */}
          </Grid>
          <Grid item md={12} xs={12}>
            <div
              className={classes.btndiv}
              style={{
                marginBottom: 15,
              }}
            >
              <Button
                className={classes.btn}
                variant="contained"
                onClick={() => {
                  validateData();
                }}
                style={{ height: 45 }}
              >
                <Typography
                  variant="body2"
                  style={{ color: "#fff", fontFamily: " Nunito" }}
                >
                  {" "}
                  {/* Sign In{" "} */}
                  {loading ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    <b>LOGIN</b>
                  )}
                </Typography>
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 15,
              }}
            >
              <Divider style={{ width: "45%" }} />
              <Typography
                variant="body2"
                color="initial"
                style={{ fontFamily: " Nunito" }}
              >
                OR
              </Typography>
              <Divider style={{ width: "45%" }} />
            </div>

            <Grid item xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginBottom: 15,
                }}
              >
                <Otp
                  handleClose={handleClose}
                  open={open}
                  type={type}
                  openOtp={openOtp}
                  setOpenOtp={setOpenOtp}
                  otpDetails={otpDetails}
                  setOtpDetails={setOtpDetails}
                  otpDetailsError={otpDetailsError}
                  setOtpDetailsError={setOtpDetailsError}
                />
                <EnterOtp
                  handleCloseotp={handleClickOtp}
                  otpopen={openOtp}
                  otpDetails={otpDetails}
                  type={type}
                  checkUser={checkUser}
                  code={code}
                  setCode={setCode}
                  codeError={codeError}
                  setCodeError={setCodeError}
                  handleClickOpen={handleClickOpen}
                />

                <Button
                  className={classes.btnsocial}
                  variant="outlined"
                  color="primary"
                  style={{ height: 45 }}
                  onClick={handleClickOpen}

                  // startIcon={<FaceBook />}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: domain?.domain ? domain?.color_code : "#007bff",
                        marginLeft: "12px",
                        fontFamily: " Nunito",
                      }}
                    >
                      <b> Login with OTP</b>
                    </Typography>
                  </div>
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginBottom: 15,
                }}
              >
                <GoogleLogin
                  // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
                  clientId={GOOGLE_CLIENT_ID}
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      className={classes.btnsocial}
                      variant="outlined"
                      color="primary"
                      style={{ height: 45 }}

                      // startIcon={<FaceBook />}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={Google} height="28px" width="28px" />
                        <Typography
                          variant="body1"
                          style={{
                            color: domain?.domain
                              ? domain?.color_code
                              : "#007bff",
                            marginLeft: "12px",
                            fontFamily: " Nunito",
                          }}
                        >
                          <b> Login with Google</b>
                        </Typography>
                      </div>
                    </Button>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                />
              </div>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              {type === "Student" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginBottom: 15,
                    }}
                  >
                    <FacebookLogin
                      appId={FACEBOOK_APP_ID}
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          className={classes.btnsocial}
                          variant="outlined"
                          color="primary"
                          style={{ height: 45 }}

                          // startIcon={<FaceBook />}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={Facebook} height="28px" width="28px" />
                            <Typography
                              variant="body1"
                              style={{
                                color: domain?.domain ? domain?.color_code : "#007bff",
                                marginLeft: "12px",
                                fontFamily: " Nunito",
                              }}
                            >
                              <b>Login with Facebook</b>
                            </Typography>
                          </div>
                        </Button>
                      )}
                      disableMobileRedirect={true}
                    />
                  </div>
                </>
              )}
            </Grid> */}
            <Grid item xs={12} md={12}>
              {!domain?.domain && (
                <>
                  {!fromApplyPage && (
                    <div className={classes.create}>
                      <Typography
                        variant="body2"
                        style={{ fontFamily: " Nunito" }}
                      >
                        Don't have an account?
                      </Typography>

                      <Button
                        size="small"
                        style={{ color: "#007bff" }}
                        onClick={() => {
                          if (type === "Student")
                            history.push({
                              // pathname: "/signup/student",
                              pathname :"/candidate/signup",
                              search: location.search ? location.search : "",
                            });
                          if (type === "Company")
                            history.push({
                              pathname: "/signup/employer",
                              search: location.search ? location.search : "",
                            });
                          if (type === "College")
                            history.push({
                              pathname: "/signup/college",
                              search: location.search ? location.search : "",
                            });
                        }}
                      >
                        <Typography variant="body2" className={classes.text}>
                          {type !== "College"
                            ? "Create Account"
                            : "Schedule a Demo Now"}
                        </Typography>
                      </Button>
                    </div>
                  )}
                </>
              )}
              {domain?.domain && type !== "College" && (
                <>
                  {!fromApplyPage && (
                    <div className={classes.create}>
                      <Typography
                        variant="body2"
                        style={{ fontFamily: " Nunito" }}
                      >
                        Don't have an account?
                      </Typography>

                      <Button
                        size="small"
                        style={{ color: "#007bff" }}
                        onClick={() => {
                          if (type === "Student")
                            history.push({
                              // pathname: "/signup/student",
                              pathname :"/candidate/signup",
                              search: location.search ? location.search : "",
                            });
                          if (type === "Company")
                            history.push({
                              pathname: "/signup/employer",
                              search: location.search ? location.search : "",
                            });
                          if (type === "College")
                            history.push({
                              pathname: "/signup/college",
                              search: location.search ? location.search : "",
                            });
                        }}
                      >
                        <Typography variant="body2" className={classes.text}>
                          {type !== "College"
                            ? "Create Account"
                            : "Schedule a Demo Now"}
                        </Typography>
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {fromApplyPage && (
            <Hidden smUp>
              <div
                className={classes.createNew}
                style={{ marginTop: 30, margin: "auto" }}
              >
                <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
                  Don't have an account?
                </Typography>

                <Button
                  size="small"
                  style={{ color: "#007bff" }}
                  onClick={() => {
                    setValue(1);
                  }}
                >
                  <Typography variant="body2" className={classes.text}>
                    Create Your Account and Apply
                  </Typography>
                </Button>
              </div>
            </Hidden>
          )}
        </Grid>
      </Paper>
    </div>
  );
}

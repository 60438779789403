import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthHeader from "../../../common/components/UI/AuthHeader";
import SideImage from "../../../common/components/UI/SideImage";
import StudentEducationalDetails from "./EducationalDetails";
import StudentIdentification from "./Identification";

const StudentSignup = () => {
  const history = useHistory();
  const location = useLocation();
  const [signupStep, setSignupStep] = useState(1);

  const [showField, setShowField] = useState({
    College: false,
    CollegeState: false,
    CollegeCity: false,
    Qualification: false,
    Course: false,
    Branch: false,
  });
  const [studentDetails, setStudentDetails] = useState({
    Type: 1,
    isActive: false,
    StudentID: null,
    CollegeName: "",
    CollegeID: null,
    CollegeStateID: "",
    CollegeCityID: "",
    QualificationID: null,
    CurrentCGPA: "",
    Course: "",
    CourseID: null,
    Branch: "",
    BranchID: null,
    Year: null,
    Gender: "",
    Ethincity: "",
    VeteranStatus: "",
    Case: null,
    RefferalCode: "",
    Token: null,
  });

  useEffect(() => {
    if (!location.userID) history.push("/login");

    if (!studentDetails.StudentID && location.userID && location.token) {
      setStudentDetails({
        ...studentDetails,
        StudentID: location.userID,
        Token: location.token,
      });
    }
  }, []);

  if (signupStep == 2)
    return (
      <>
        <StudentIdentification
          step={signupStep}
          setStep={setSignupStep}
          studentDetails={studentDetails}
          setStudentDetails={setStudentDetails}
        />
      </>
    );
  else
    return (
      <>
        <div className="container-fluid">
          <div className="row no-gutter full-page-view">
            <SideImage />
            <div
              className="col-md-8 col-lg-8 y-scroll full-page-view"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="row">
                <AuthHeader />
              </div>
              <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
                <div className="container" style={{ marginTop: "3%" }}>
                  <div className="row">
                    <div className="col-md-9 col-lg-6 mx-auto">
                      {/* {signupStep ==1 && (<StudentPersonalDetails step={signupStep} setStep={setSignupStep}
                          studentDetails={studentDetails} setStudentDetails={setStudentDetails}/>)} */}
                      {signupStep == 1 && (
                        <StudentEducationalDetails
                          step={signupStep}
                          setStep={setSignupStep}
                          showField={showField}
                          setShowField={setShowField}
                          studentDetails={studentDetails}
                          setStudentDetails={setStudentDetails}
                        />
                      )}
                      {/* {signupStep ==3 && (<StudentIdentification step={signupStep} setStep={setSignupStep}/>)}             */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default StudentSignup;

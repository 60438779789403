import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import React from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../../constants/constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "scroll",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      marginTop: "20px",
      marginBottom: "10px",
      width: "500px",
      borderRadius: "10px"
    },
    deleteButton: {
      background: "#007BFF",
      color: "#fff",
      borderRadius: "30px",
      height: "40px",
      // width: "60%",
      paddingLeft: "20px",
      paddingRight: "20px",

      "&:hover": {
        background: "#007BFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    cancelButton: {
      height: "40px",
      color: "black",
      backgroundColor: "white",
      border: "2px solid black",
      borderRadius: "30px",
      paddingLeft: "20px",
      paddingRight: "20px",
      "&:hover": {
        backgroundColor: "#F2F2F2",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
  })
);

export default function ViewDetails(props) {
  const {
    handleClose,
    handleOpen,
    open,
    setOpen,
    interviewID,
    index,
    tempData,
    setTempData,
    profile,
  } = props;
  const classes = useStyles();
  const alert = useAlert();

  const deleteInterview = () => {
    var interview = interviewID.interview_id;

    Axios.delete(
      BackendBaseApi.NIYUKTI +
        `job/company/manage_interview/?interview_id=${window.btoa(interview)}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        var list = { ...interviewID };
        alert.success("deleted");

        setTempData(list);
        handleClose();
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={11}></Grid>
              <Grid item xs={1}>
                <IconButton aria-label="delete" onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Typography align="center" variant="body1">
              <b>
                Are you sure you want to delete the Scheduled interview for the
                Job Profile - {profile} ?
              </b>
            </Typography>
            <br />
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.cancelButton}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button
                className={classes.deleteButton}
                onClick={() => deleteInterview()}
              >
                Delete Interview{" "}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

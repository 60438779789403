import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box, IconButton,
  styled, Toolbar,
  Typography
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import * as React from "react";
import logo from "../../../Assets/Images/png/getwork-logo.png";
import {useHistory} from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === "light" ? "#007BFF" : "#308fe8",
  },
}));

export default function LoginNavbar({ step, setStep, signupComplete, getSkipButtonId }) {
  const history = useHistory();
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          color="inherit"
          sx={{ borderBottom: "1px solid #E3E6EA" }}
        >
          <Toolbar>
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> */}
            <img src={"https://s3.ap-south-1.amazonaws.com/getwork-store/prod/common/Black_Logo"} alt="gtwork-logo" style={{cursor: "pointer"}} height="25px" onClick={() => {
              history.push("/student/complete-profile")
            }}/>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>
            <Box sx={{ display: { xs: "flex" } }}>
              {/* {step >= 2 && (
              <IconButton size="large" aria-label="show more" color="inherit">
                <SearchIcon />
              </IconButton>
            ): <>"
            <h1>demo</h1>
            }

            </> */}
              {step ? (
                step >= 2 && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ color: "#6C757D", cursor: "pointer" }}
                      onClick={() => {
                        if(step < 7){
                        setStep(step+1)
                      }else{
                      signupComplete()
                      // history.push("/student/dashboard")
                      }
                      }}
                    >
                      <span id={getSkipButtonId()}>
                      {step === 7 ? "Finish" : "Skip this step"}
                      </span>
                    </Typography>
                  </>
                )
              ) : (
                <IconButton size="large" aria-label="show more" color="inherit">
                  <SearchIcon />
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      {step >= 2 && (
        <div>
          <BorderLinearProgress variant="determinate" value={(100 * (step - 1))/6} />
        </div>
      )}
    </>
  );
}

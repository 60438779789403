import { Box, Button, Checkbox, createStyles, Divider, FormControlLabel, makeStyles, Popover, Switch, Typography } from "@material-ui/core";
import { FilterListRounded } from "@material-ui/icons";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import loader_gif from "../../../assets/images/filter_load.gif";
import { BackendBaseApi } from "../../../constants/constants";
import CustomizedCheckboxes from "../../../components/common/JobPostNew/FormComponents/CustomizedCheckboxes";

const FilterComponent = (props) => {
  const {
    searchString,
    setSearchString,
    setEnd,
    setNextUrl,
    setJobData,
    nextUrl,
    end,
    presetFilters,
    firstApiCall,
    checkBoxClicked,
    setCheckBoxClicked,
    serviceBond,
    setServiceBond,
  } = props;
  const [newPresetFilters, setNewPresetFilters] = useState({});
  const [firstApiCallFilters, setFirstApiCallFilters] = useState(false);
  useEffect(() => {
    if (presetFilters !== undefined && !firstApiCallFilters) {
      setNewPresetFilters(presetFilters);

      getFilters();
    }
  }, [presetFilters]);
  const classes = useStyles();
  const alert = useAlert();
  const [viewMore, setViewMore] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [filterObject, setFilterObject] = useState({});
  const [filterArray, setFilterArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalFilterCount, setTotalFilterCount] = useState();
  const [appliedFilterCount, setAppliedFilterCount] = useState();

  let token = localStorage.getItem("gw_token");

  const getFilters = () => {
    if (!nextUrl || end) setLoader(true);

    Axios.get(`${BackendBaseApi.NIYUKTI}job/filter/job_lake/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        setData(res.data.data);
        setFirstApiCallFilters(true);
        var newArr = {};
        var temp = {};
        var arr = [];
        setTotalFilterCount(Object.keys(res.data.data).length);

        Object.keys(res.data.data).forEach((head) => {
          arr = res.data.data[head].data.map((v) => ({
            ...v,
            isChecked: Object.keys(presetFilters).length > 0 ? checkIfTrue(v, head, res.data.data[head].param_name) : false,
          }));

          newArr[head] = {
            heading: res.data.data[head].heading,
            param_name: res.data.data[head].param_name,
            data: arr,
          };

          temp[res.data.data[head].param_name] = [];
        });

        setRawData(newArr);
        setFilterObject(temp);

        setLoader(false);
      })
      .catch((err) => {
        alert.error("Error occured!");
        setLoader(false);
      });
  };

  const checkIfTrue = (obj, head, param) => {
    var boole = false;
    if (head === "job_segment" || head === "job_type" || head === "location" || head === "education_level" || head === "work_experience") {
      presetFilters[head].map((u, iin) => {
        if (u.id === obj.id) {
          boole = true;
        }
      });
    }

    return boole;
  };

  useEffect(() => {
    if (Object.keys(filterObject).length > 0) {
      Object.keys(rawData).map((heading, index) =>
        rawData[heading].data.map((item, index2) => {
          if (item.isChecked === true) {
            var new_arr = [...filterArray];
            var arr = filterObject[rawData[heading].param_name];
            var temp = filterObject;
            arr.push({ id: item.id, name: item.name });
            new_arr.push(item.id);
            setFilterArray(new_arr);
            temp[rawData[heading].param_name] = arr;
            setFilterObject(temp);
          }
        })
      );
    }
  }, [filterObject]);

  const handleViewMore = (e, index) => {
    setAnchorEl(e.currentTarget);
    setSelectedSection(index);
    setViewMore(true);
  };

  const generateCsv = (arr, head) => {
    let str = "";
    if (head === "education_level") {
      arr.forEach((item, index) => (arr.indexOf(item) !== arr.length - 1 ? (str += item.name + ",") : (str += item.name)));
    } else {
      arr.forEach((item, index) => (arr.indexOf(item) !== arr.length - 1 ? (str += item.id + ",") : (str += item.id)));
    }

    return str;
  };

  useEffect(() => {
    let str = "";
    var count = 0;

    Object.keys(filterObject).forEach((head, index) =>
      Object.keys(filterObject).indexOf(head) !== Object.keys(filterObject).length - 1
        ? (str += filterObject[head].length > 0 ? head + "=" + generateCsv(filterObject[head], head) + "&" : "")
        : (str += filterObject[head].length > 0 ? head + "=" + generateCsv(filterObject[head], head) : "")
    );

    Object.keys(filterObject).forEach((head, index) => {
      if (filterObject[head].length > 0) count = count + 1;
    });
    setAppliedFilterCount(count);
    setSearchString(str);
  }, [filterArray.length]);
  return (
    <Box>
      {loader === true ? (
        <Box className={classes.WhiteBox} style={{ marginTop: "50px" }}>
          <img src={loader_gif} alt="" height="170px" />
        </Box>
      ) : (
        <>
          <Box className={classes.section} display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" style={{ color: "#3282c4" }}>
              Filter
            </Typography>
            <Typography variant="body1" style={{ color: "#3282c4" }}>
              Applied({appliedFilterCount}/{totalFilterCount})
            </Typography>
          </Box>
          <Divider className={classes.divider} />

          {Object.keys(rawData).map((heading, index) => (
            <>
              {heading !== "salary" && (
                <>
                  <Box className={classes.section}>
                    <Typography style={{ fontSize: 18, marginLeft: 10, marginBottom: 10, fontWeight: "bold" }}> {rawData[heading].heading}</Typography>
                    <Box paddingLeft="16px" display="flex" flexDirection="column">
                      <>
                        {heading === "service_bond" && (
                          <>
                            {" "}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={serviceBond}
                                  onChange={(e) => {
                                    setServiceBond(e.target.checked);
                                    setCheckBoxClicked(true);
                                    setJobData([]);
                                    setNextUrl(null);
                                    setEnd(false);
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label={<span style={{ fontSize: 13 }}>{serviceBond ? "Yes" : "No"}</span>}
                            />
                          </>
                        )}
                      </>
                      {rawData[heading].data.map(
                        (item, index2) =>
                          index2 < 5 && (
                            <>
                              {heading !== "service_bond" && (
                                <FormControlLabel
                                  variant="inherit"
                                  control={
                                    <CustomizedCheckboxes
                                      checked={item.isChecked}
                                      onChange={(e) => {
                                        setCheckBoxClicked(true);
                                        if (item.isChecked === false) {
                                          var new_arr = [...filterArray];
                                          var arr = filterObject[rawData[heading].param_name];
                                          var temp = filterObject;
                                          arr.push({
                                            id: item.id,
                                            name: item.name,
                                          });
                                          new_arr.push(item.id);
                                          setFilterArray(new_arr);
                                          temp[rawData[heading].param_name] = arr;
                                          setFilterObject(temp);
                                        } else {
                                          var new_arr = [...filterArray];
                                          var arr = filterObject[rawData[heading].param_name];

                                          var temp = filterObject;

                                          var indexx;
                                          arr.map((u, iin) => {
                                            if (u.id === item.id) {
                                              indexx = iin;
                                            }
                                          });

                                          arr.splice(indexx, 1);
                                          new_arr.push(item.id);
                                          setFilterArray(new_arr);

                                          temp[rawData[heading].param_name] = arr;
                                          setFilterObject(temp);
                                        }

                                        setJobData([]);
                                        setNextUrl(null);
                                        setEnd(false);
                                        item.isChecked = e.target.checked;
                                      }}
                                      color="primary"
                                      name="gilad"
                                    />
                                  }
                                  label={<span style={{ fontSize: 13 }}>{item.count ? item.name + " (" + item.count + ")" : item.name}</span>}
                                />
                              )}
                            </>
                          )
                      )}
                    </Box>

                    {rawData[heading].data.length > 4 && (
                      <Button variant="text" onClick={(e) => handleViewMore(e, index)}>{`+${rawData[heading].data.length + 1 - 5} More`}</Button>
                    )}
                    {heading !== "service_bond" && (
                      <Button
                        variant="text"
                        onClick={(e) => {
                          setCheckBoxClicked(true);

                          var new_arr = [...filterArray];
                          var arr = filterObject[rawData[heading].param_name];

                          var temp = filterObject;

                          arr = [];

                          temp[rawData[heading].param_name] = arr;
                          setFilterObject(temp);

                          rawData[heading].data.map((item) => {
                            if (item.isChecked === true) {
                              setJobData([]);
                              setNextUrl(null);
                              setEnd(false);
                            }
                            new_arr.push(item.id);
                            setFilterArray(new_arr);
                            item.isChecked = false;
                          });
                        }}
                      >
                        Clear
                      </Button>
                    )}

                    {selectedSection === index && (
                      <Popover
                        open={viewMore}
                        onClose={() => setViewMore(false)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "right",
                        }}
                      >
                        <Box padding="10px" display="flex" flexDirection="column">
                          {rawData[heading].data.map((item, index2) => (
                            <FormControlLabel
                              classes={{ label: classes.formLabel }}
                              className={classes.checkBoxLabel}
                              control={
                                <CustomizedCheckboxes
                                  checked={item.isChecked}
                                  onChange={(e) => {
                                    setCheckBoxClicked(true);

                                    if (item.isChecked === false) {
                                      var new_arr = [...filterArray];
                                      var arr = filterObject[rawData[heading].param_name];
                                      var temp = filterObject;
                                      arr.push({
                                        id: item.id,
                                        name: item.name,
                                      });
                                      new_arr.push(item.id);
                                      setFilterArray(new_arr);
                                      temp[rawData[heading].param_name] = arr;
                                      setFilterObject(temp);
                                    } else {
                                      var new_arr = [...filterArray];
                                      var arr = filterObject[rawData[heading].param_name];

                                      var temp = filterObject;

                                      var indexx;
                                      arr.map((u, iin) => {
                                        if (u.id === item.id) {
                                          indexx = iin;
                                        }
                                      });

                                      arr.splice(indexx, 1);
                                      new_arr.push(item.id);
                                      setFilterArray(new_arr);

                                      temp[rawData[heading].param_name] = arr;
                                      setFilterObject(temp);
                                    }

                                    setJobData([]);
                                    setNextUrl(null);
                                    setEnd(false);
                                    item.isChecked = e.target.checked;
                                  }}
                                />
                              }
                              label={<span style={{ fontSize: 13 }}>{item.count ? item.name + " (" + item.count + ")" : item.name}</span>}
                            />
                          ))}
                        </Box>
                      </Popover>
                    )}
                  </Box>
                </>
              )}
              <Divider />
            </>
          ))}
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      //   margin: "10px 0px",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
    section: {
      padding: "12px",
    },
    checkBoxLabel: {
      margin: "3px 0px",
    },
    formLabel: {
      marginLeft: "6px",
    },
  })
);

export default FilterComponent;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    maxHeight: "200px",
    overflowY: "auto",
  },
  cursorPointer: {
    cursor: "pointer",
    paddingTop: "0px",
  },
  textFont: {
    fontSize: "14px",
  },
}));

export default function Job_List({ data, param, setFilters, appliedFilters }) {
  const classes = useStyles();
  const [openDict, setOpenDict] = useState([]);

  const handleToggle = (index) => {
    // setOpen(!open);
    let newList = [...openDict];
    newList[index] = !newList[index];
    let add = true;
    if (!newList[index]) add = false;
    setOpenDict(newList);
    if (data) {
      let text = "";
      data.map((value, index) => {
        if (newList[index]) {
          text = text ? text + "," + value.id : value.id.toString();
        }
      });
      setFilters({ ...appliedFilters, [param]: text });
    }
  };

  useEffect(() => {
    let initialState = [];
    let paramAppliedFilter = appliedFilters[param] ? appliedFilters[param].split(",") : [] ;
    if (data) {
      data.map((value, index) => {
        if (paramAppliedFilter.includes(value.id.toString())) initialState.push(true);
        else initialState.push(false);
      });
      setOpenDict(initialState);
    }
  }, [data]);

  return (
    <List className={classes.root}>
      {data &&
        data.map((value, index) => {
          const labelId = `checkbox-list-label-${value.name}`;
          return (
            <ListItem
              className={classes.cursorPointer}
              key={"checkbox-list-label-" + value.name + value.id}
              onClick={() => {
                handleToggle(index);
              }}
            >
              <Checkbox edge="start" checked={openDict[index] || false} inputProps={{ "aria-labelledby": labelId }} />
              <ListItemText id={labelId} primary={`${value.name} ${value?.count ? "(" + value.count + ")" : ""}`} classes={{ primary: classes.textFont }} />
            </ListItem>
          );
        })}
    </List>
  );
}

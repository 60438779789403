import { combineReducers } from "redux";
import Reports from "../components/college/ReportsPanel/reducer";
import { CreditInfo } from "../GlobalComponents/CreditBoard/reducer";
import CollegeFilterState, { DegreesList, AllDegreesList, CoursesList, CityList, FilterCount, JobSegmentList } from "../bundles/company/components/Home/Jobs/PostJob/reducer";
import { UserDetails } from "../pages/college/Dashboard/reducer";
import CompanyUserDetails from "../bundles/company/components/Home/reducer";
import { JobSegmentListNew, JobRoleList, JobTypeList, SkillList, EmploymentTypeList, CityArrayNew, RoundList, CompanyList } from "../components/company/reducer";
import ExploreFilter, { ExploreFilterCollege, CityArray, IndustryList } from "../common/Explore/reducer";

import SubDomainDetails from "../reducer";

const appReducer = combineReducers({
  Reports,
  ExploreFilter,
  CityList,
  JobSegmentList,
  CityArray,
  CollegeFilterState,
  DegreesList,
  CoursesList,
  AllDegreesList,
  UserDetails,
  FilterCount,
  CreditInfo,
  ExploreFilterCollege,
  IndustryList,
  CompanyUserDetails,
  JobSegmentListNew,
  JobRoleList,
  JobTypeList,
  SkillList,
  EmploymentTypeList,
  CityArrayNew,
  RoundList,
  CompanyList,
  SubDomainDetails,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

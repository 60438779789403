import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { NEXT_APP_ENDPOINT } from "../../../../../constants/constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },

  linktext: {
    color: "white",
    paddingBottom: "10px",
    "&:hover": {
      textDecoration: "underline",
      color: "white",
    },
  },
  text: {
    marginBottom: "7px",
    fontSize: 14,
    fontFamily: "Nunito",
  },
  title: {
    color: "#fff",
    fontSize: 20,
    fontFamily: "Nunito",
  },
}));

export default function InternshipsByLocations() {
  const classes = useStyles();
  const data = [
    {
      heading: "Internships in Delhi / NCR",
      link: `${NEXT_APP_ENDPOINT}/internships-in-delhi`,
    },
    {
      heading: "Internships in Bangalore",
      link: `${NEXT_APP_ENDPOINT}/internships-in-bangalore`,
    },
    {
      heading: "Internships in Mumbai",
      link: `${NEXT_APP_ENDPOINT}/internships-in-mumbai`,
    },
    {
      heading: "Internships in Pune",
      link: `${NEXT_APP_ENDPOINT}/internships-in-pune`,
    },
    {
      heading: "Internships in Hyderabad",
      link: `${NEXT_APP_ENDPOINT}/internships-in-hyderabad`,
    },
    {
      heading: "Internships in Jaipur",
      link: `${NEXT_APP_ENDPOINT}/internships-in-jaipur`,
    },
    {
      heading: "Internships in Bhopal",
      link: `${NEXT_APP_ENDPOINT}/internships-in-bhopal`,
    },
    {
      heading: "Internships in Ahmedabad",
      link: `${NEXT_APP_ENDPOINT}/internships-in-ahmedabad`,
    },

    {
      heading: "Internships in Chandigarh",
      link: `${NEXT_APP_ENDPOINT}/internships-in-chandigarh`,
    },
    {
      heading: "Internships in Surat",
      link: `${NEXT_APP_ENDPOINT}/internships-in-surat`,
    },
    {
      heading: "Internships in Nagpur",
      link: `${NEXT_APP_ENDPOINT}/internships-in-nagpur`,
    },
    {
      heading: "Internships in Lucknow",
      link: `${NEXT_APP_ENDPOINT}/internships-in-lucknow`,
    },
  ];

  return (
    <>
      <Typography variant="h4" className={classes.title} gutterBottom>
        Internships by Locations
      </Typography>
      <br />
      {data.map((item) => (
        <a
          href={item.link}
          className={classes.linktext}
          style={{ fontSize: 14 }}
        >
          <Typography
            variant="body2"
            color="inherit"
            className={classes.text}
            id="footerBtn"
          >
            {item.heading}{" "}
          </Typography>{" "}
        </a>
      ))}
    </>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LanguageIcon from "@material-ui/icons/Language";
import github from "../../../../assets/png/github.png";
import facebook from "../../../../../assets/common/facebook.png";
import beh from "../../../../assets/png/behance.png";
import linkedin from "../../../../assets/png/linkedin.png";
import instagram from "../../../../assets/png/instagram.png";
import dri from "../../../../assets/png/dribbble.png";
import { Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  icon: {
    color: "#007bff",
  },

  sociallink: {
    marginRight: "10px",
    cursor: "pointer",
  },
}));

export default function Contact({ data }) {
  const classes = useStyles();
  const alert = useAlert();
  const basicData = (data) => {
    return [
      {
        heading: "Gender",
        info: data?.gender,
        show: data?.gender ? true : false,
      },
      {
        heading: "Current Location",
        info: data?.currentCity,
        show: data?.currentCity ? true : false,
      },
      {
        heading: "Current CTC",
        info: data?.currentCtc ? `${data?.currentCtc}` : "NA",
        show: data?.currentCtc ? true : false,
      },
      {
        heading: "Expected CTC",
        info: data?.expectedCtc ? `${data?.expectedCtc}` : "NA",
        show: data?.expectedCtc ? true : false,
      },
      {
        heading: "Notice Period",
        info: data?.noticePeriod ? `${data?.noticePeriod}` : "NA",
        show: data?.noticePeriod ? true : false,
      },
    ];
  };

  const contactData = (data) => {
    return [
      {
        icon: <PhoneIcon color="primary" />,
        info: data?.phone,
        link: `tel:+91${data?.phone}`,
      },
      {
        icon: <MailOutlineIcon color="primary" />,
        info: data?.email,
        link: `mailto: ${data?.email}`,
      },
      // {
      //   icon: <LanguageIcon color="primary" />,
      //   info: "www.roshankapoor.in",
      //   link: "https://www.roshankapoor.in",
      // },
    ];
  };

  const checkSocialLinkImage = (name) => {
    let link_icon = "";
    switch (name) {
      case "Linkedin":
        link_icon = linkedin;
        return link_icon;

      case "Github":
        link_icon = github;
        return link_icon;

      case "Facebook":
        link_icon = facebook;
        return link_icon;

      case "Instagram":
        link_icon = instagram;
        return link_icon;
      default:
        break;
    }
  };

  const checkSocialLink = (name, value) => {
    let link = "";
    switch (name) {
      case "Linkedin":
        link = value;
        return link;

      case "Github":
        link = value;
        return link;

      case "Facebook":
        link = value;
        return link;
      case "Instagram":
        link = value;
        return link;

      default:
        break;
    }
  };
  return (
    <>
      <div>
        <div className={classes.overview}>
          <Typography variant="h6">Contact and Portfolio</Typography>
          <div style={{ display: "flex", marginTop: "10px" }}>
            {contactData(data).map((item) => (
              <div style={{ marginRight: "10px", cursor: "pointer" }}>
                {/* <a href={item.link} target="_blank" style={{ textDecoration: "none" }}> */}
                <CopyToClipboard
                  text={item.info}
                  onCopy={() => alert.success("Copied to clipboard!")}
                >
                  <Tooltip title={item.info} placement="bottom">
                    {item.icon}
                  </Tooltip>
                </CopyToClipboard>
                {/* </a> */}
              </div>
            ))}

            {/* {SocialData.map((item) => (
              <div style={{ marginRight: "10px", cursor: "pointer" }}>
                <a href={item.link} target="_blank" style={{ textDecoration: "none" }}>
                  <Tooltip title={item.info} placement="bottom">
                    <img
                      src={item.icon}
                      height="22px"
                      alt="social-link"
                      // className={classes.sociallink}
                    />
                  </Tooltip>
                </a>
              </div>
            ))} */}

            {data?.profile_url && data?.profile_url?.length ? (
              <>
                <div style={{ marginRight: "10px", cursor: "pointer" }}>
                  {data?.profile_url !== null &&
                    data?.profile_url.map((item, ind) => (
                        <Tooltip title={item.name} placement="bottom"> 
                          <img
                            src={checkSocialLinkImage(item.name)}
                            height="22px"
                            alt="social-link"
                            onClick={() => window.open(checkSocialLink(item.name, item.value))}
                            style={{ marginLeft: 10 }}
                          />
                        </Tooltip>
                    ))}
                </div>
              </>
            ) : null}
          </div>

          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Typography
              variant="h6"
              style={{
                marginBottom: "10px",
              }}
            >
              Basic Info
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {basicData(data).map(
                (val, ind) =>
                  val.show && (
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "400",
                        marginTop: "10px",
                        color: "#6C757D",
                      }}
                    >
                      {val.heading} : {val.info}
                    </Typography>
                  )
              )}
            </div>
          </div>
          {data?.about && <div>

            <Typography variant="h6">About Us</Typography>
            <Typography variant="body2">{data?.about}</Typography>
          </div>}

        </div>
      </div>
    </>
  );
}

const SocialData = [
  {
    icon: github,
    info: "gitlab.com",
    link: "https://www.linkedin.com/feed/",
  },
  {
    icon: linkedin,
    info: "www.linkedin.com",
    link: "https://www.linkedin.com/feed/",
  },
  {
    icon: beh,
    info: "www.behance.net",
    link: "https://www.behance.net/",
  },
  {
    icon: dri,
    info: "dribbble.com",
    link: "https://dribbble.com",
  },
];

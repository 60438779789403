import {
  Box,
  Button,
  CircularProgress, Dialog,
  DialogActions, DialogContent,
  useTheme
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Warning_dark from "../../../assets/transfer-money-dark.gif";
import Warning from "../../../assets/transfer-money.gif";
import CommonButton from "../CommonButton/CommonButton";

const CreditDialog = ({
  open,
  setOpen,
  poster,
  posterArg,
  creditsRequired,
  serviceType,
  progress,
}) => {
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const theme = useTheme();
  const history = useHistory();
  return (
    <Dialog
      //open
      PaperProps={{
        style: {
          borderRadius: 30,
        },
      }}
      open={open}
      disableBackdropClick
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <p>
            {creditInfo &&
              creditInfo.user_service &&
              creditInfo?.user_service?.find(
                (item) => item.service_type === serviceType
              )?.service_type}
          </p>
          <p>
            {creditInfo &&
              creditInfo.user_service &&
              creditInfo?.user_service?.find(
                (item) => item.service_type === serviceType
              )?.service_description}
          </p>
          <img
            src={theme.palette.type === "dark" ? Warning_dark : Warning}
            width="60%"
            height="auto"
          />
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            You will be charged {creditsRequired} Credits for this action
          </p>
          {creditsRequired > creditInfo.available_credits && (
            <>
              <p style={{ fontSize: "18px" }}>
                seems like You don't have enough credits to perform this action.
              </p>
              <CommonButton
                variant="contained"
                color="primary"
                onClick={() =>
                  localStorage.getItem("user_type") === "College"
                    ? history.push("/college/college-settings/?credits")
                    : history.push("/company/company-settings/?credits")
                }
                
              >
                Recharge now !
              </CommonButton>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            backgroundColor: "#D64045",
            color: "#ffffff",
            borderRadius: 30,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        {creditsRequired <= creditInfo.available_credits && (
          <CommonButton
            disabled={progress}
            variant="contained"
            style={{
              width: "140px",
              height: "36px",
              borderRadius: 30,
            }}
            onClick={() => poster(posterArg)}
          >
            {progress ? (
              <CircularProgress style={{ color: "#fff" }} size={25} />
            ) : (
              "Proceed"
            )}
          </CommonButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreditDialog;

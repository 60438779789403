import React, { useState, useEffect } from "react";
import "./FormComponents.css";
import moment from "moment";
import { Tooltip, TextField, InputLabelProps } from "@material-ui/core";
import { Chip, Avatar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { uniqueArray } from "../../../../utils/common/filterJob";
import dateDifference from "../../../../utils/common/dateDifference";
import CommonButton from "../../CommonButton/CommonButton";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

function Projects({ projects, setProjects, allSkills, alert, projectBoxOpen, positions }) {
  const [projectTitle, setProjectTitle] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [projectStart, setProjectStart] = useState("");
  const [projectEnd, setProjectEnd] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [count, setCount] = useState(0);
  const [viewBtn, setViewBtn] = useState(false);

  const classes = useStyles();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    setCount(projects.length);
  }, [projects]);

  useEffect(() => {
    if (projectBoxOpen.success) {
      editBtn(projectBoxOpen.id);
    }
  }, [projectBoxOpen]);

  const clearStates = () => {
    setProjectTitle("");
    setProjectLink("");
    setProjectDesc("");
    setProjectStart("");
    setProjectEnd("");
    setSelectedSkills([]);
  };

  const addProject = (e) => {
    e.preventDefault();
    if (!projectTitle) {
      alert.error("Project Title required !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!projectDesc) {
      alert.error("Project Description required !", { position: positions.TOP_RIGHT });
      return;
    }
    let arr = [...projects];
    let temp = {
      id: count,
      title: projectTitle,
      links: projectLink,
      description: projectDesc,
      start_date: projectStart || null,
      end_date: projectEnd || null,
      skills: selectedSkills,
      is_valid: true,
    };
    arr.push(temp);
    setProjects(uniqueArray(arr));
    clearStates();
    setCount(count + 1);
    window.document.getElementById("project-enter").className = "collapse";
  };

  const handleDelete = (boxId) => {
    var arr = projects.filter((proj) => proj.id !== boxId);
    setProjects(arr);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (!projectTitle) {
      alert.error("Project Title required !", { position: positions.TOP_RIGHT });
      return;
    }
    if (!projectDesc) {
      alert.error("Project Description required !", { position: positions.TOP_RIGHT });
      return;
    }
    let arr = [...projects];
    let temp = {
      id: count,
      title: projectTitle,
      links: projectLink,
      description: projectDesc,
      start_date: projectStart || null,
      end_date: projectEnd || null,
      skills: selectedSkills,
      is_valid: true,
    };
    let a = arr.map((obj) => (obj.id === editId ? temp : obj));
    setProjects(uniqueArray(a));
    cancelEdit();
  };

  const editBtn = (id) => {
    let arr = projects.filter((edu) => edu.id === id);
    let temp = arr[0];
    setIsEditOpen(true);
    setEditId(id);
    setProjectTitle(temp.title || "");
    setProjectLink(temp.links || "");
    setProjectDesc(temp.description || "");
    setProjectStart(temp.start_date || "");
    setProjectEnd(temp.end_date || "");
    setSelectedSkills(temp.skills || []);
    window.document.getElementById("project-enter").className = "collapse show";
    window.document.getElementById("project_section").scrollIntoView(true);
  };

  const cancelEdit = () => {
    window.document.getElementById("project-enter").className = "collapse";
    setIsEditOpen(false);
    setEditId(null);
    clearStates();
  };

  return (
    <div id="projects-form">
      <div className="collapse" id="project-enter">
        <div>
          <div id="add_new_div">
            <TextField type="text" required variant="outlined" label="Project Title" value={projectTitle} onChange={(e) => setProjectTitle(e.target.value)} />
            <TextField type="text" variant="outlined" label="Project Link" value={projectLink} onChange={(e) => setProjectLink(e.target.value)} />
          </div>
          <div id="add_new_full_length_div">
            <TextField
              type="text"
              required
              variant="outlined"
              label="Project Description"
              multiline
              rows={3}
              value={projectDesc}
              onChange={(e) => setProjectDesc(e.target.value)}
            />
          </div>
          <div id="add_new_div">
            <TextField
              type="date"
              variant="outlined"
              label="Project Start Date"
              InputLabelProps={{ shrink: true }}
              value={projectStart}
              onChange={(e) => setProjectStart(e.target.value)}
            />
            <TextField
              type="date"
              variant="outlined"
              label="Project End Date"
              InputLabelProps={{ shrink: true }}
              value={projectEnd}
              onChange={(e) => setProjectEnd(e.target.value)}
            />
          </div>
          <div id="add_new_autocomplete_div">
            <Autocomplete
              multiple
              filterSelectedOptions
              options={allSkills}
              getOptionLabel={(option) => option.skill_name}
              className="type_search_autocomplete_one"
              onChange={(e, i) => {
                i ? setSelectedSkills(i) : setSelectedSkills(null);
              }}
              value={selectedSkills}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Skills implemented" />}
            />
          </div>
        </div>
        {isEditOpen ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CommonButton variant="contained" style={{ display: "block", margin: "1rem 8px", outline: "none", backgroundColor: "#e0e0e0" }} onClick={() => cancelEdit()}>
              Cancel
            </CommonButton>
            <CommonButton variant="contained" style={{ display: "block", margin: "1rem 8px", outline: "none" }} onClick={(e) => handleEdit(e)}>
              Update
            </CommonButton>
          </div>
        ) : (
          <CommonButton variant="contained" style={{ display: "block", margin: "1rem auto", outline: "none" }} onClick={(e) => addProject(e)}>
            Add Project
          </CommonButton>
        )}
      </div>
      <div>
        {projects.length > 0 ? (
          <div id="box_value" style={{ marginLeft: "1%" }}>
            {projects.map((proj, key) => {
              return (
                <div id="card_outer_div" style={!proj.is_valid ? { border: "1px solid #dc3545", backgroundColor: "#fff6ff" } : {}}>
                  {/* Heading & Delete */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginBottom: "0.6rem" }}>
                    <div>
                      <span>
                        <b>Project {key + 1}</b>
                      </span>
                    </div>
                    <div style={{ marginRight: "0.5rem", cursor: "pointer" }}>
                      <Tooltip title="Edit Project" placement="right">
                        <span
                          style={{ marginRight: "1rem" }}
                          onClick={(e) => {
                            editBtn(proj.id);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete Project" placement="right">
                        <span
                          onClick={(e) => {
                            handleDelete(proj.id);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  {/* Project Title with Link(if present) */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <div>
                      <b
                        style={proj.links ? { color: "#3282c4", cursor: "pointer" } : {}}
                        onClick={(e) => {
                          proj.links && window.open(proj.links);
                        }}
                      >
                        {" "}
                        {proj.title}{" "}
                      </b>
                    </div>
                  </div>
                  {/* Project Start & End Date, Duration if both dates present */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    {proj.start_date && (
                      <div>
                        <span>
                          {moment(proj.start_date).format("DD MMM'YY")}
                          {proj.end_date ? ` - ${moment(proj.end_date).format("DD MMM'YY")}` : " - Present"}
                        </span>
                      </div>
                    )}
                    {proj.start_date && proj.end_date && moment(proj.start_date).isValid() && moment(proj.end_date).isValid() && (
                      <div>
                        <span>
                          <i class="fas fa-calendar-week" style={{ color: "#9a9595" }}></i> {dateDifference(proj.start_date, proj.end_date)}
                          {/* {moment(proj.end_date).diff(moment(proj.start_date), "days")}
                                                    {' '}days */}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* Project Description */}
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <div>
                      <span> {proj.description} </span>
                    </div>
                  </div>
                  {/* Project Skills */}
                  {uniqueArray(proj.skills).length > 0 && (
                    <span id="box_value">
                      {uniqueArray(proj.skills).length <= 5 ? (
                        uniqueArray(proj.skills).map((skill, key) => {
                          return (
                            <Chip
                              variant="outlined"
                              size="medium"
                              label={<b style={{ fontSize: "14px" }}>{skill.skill_name}</b>}
                              color="primary"
                              style={{ margin: "4px 8px 8px 0px", maxHeight: "25px" }}
                            />
                          );
                        })
                      ) : (
                        <>
                          {uniqueArray(proj.skills).map(
                            (skill, skill_key) =>
                              (skill_key < 5 || viewBtn) && (
                                <Chip
                                  variant="outlined"
                                  size="medium"
                                  label={<b style={{ fontSize: "14px" }}>{skill.skill_name}</b>}
                                  color="primary"
                                  style={{ margin: "4px 8px 8px 0px", maxHeight: "25px" }}
                                />
                              )
                          )}
                          <Button onClick={() => setViewBtn(!viewBtn)} style={{ marginLeft: "8px" }}>
                            {viewBtn ? "...View Less" : "...View More"}
                          </Button>
                        </>
                      )}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div id="no_content">
            <span>Add your Personal Projects !!</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Projects;

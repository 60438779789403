import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useLocation, useParams } from "react-router-dom";
import { BackendBaseApi } from "../../../../constants/constants";
import AuthHeader from "../UI/AuthHeader";
import Loader from "../UI/Loader";
import SideImage from "../UI/SideImage";

const ResetPassword = () => {
  //axios.defaults.withCredentials=true;
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = useParams();
  const alert = useAlert();
  const showErrorText = useRef();
  const disableButton = useRef();

  //UI state variables
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  //state variables
  const [step, setStep] = useState(1);
  const [Password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [uid, setUid] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const temp = location.pathname.split("/");
    setUid(temp[2]);
    setToken(temp[3]);
    if (step == 1) {
      showErrorText.current.style.display = "none";
      disableButton.current.classList.add("disabled");
    }
  }, []);

  useEffect(() => {
    if (step == 1) validate();
  }, [Password, confirmPassword]);

  const validate = () => {
    //setConfirmPassword(e.target.value)
    if (Password !== confirmPassword) {
      showErrorText.current.style.display = "block";
      disableButton.current.classList.add("disabled");
    } else {
      showErrorText.current.style.display = "none";
      disableButton.current.classList.remove("disabled");
    }
  };

  const handleSubmit = (e) => {
    if (Password !== confirmPassword) return;
    e.preventDefault();
    setLoading(true);
    axios
      .post(BackendBaseApi.PRAVESH + "api/email/reset", {
        uid: uid,
        token: token,
        password: Password,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          alert.success(res.data.data.message);
          setStep(step + 1);
        } else {
          alert.error(res.data.error);
          if (res.data.error === "Invalid token") setStep(3);
        }
      })
      .catch((err) => {
        setLoading(false);

        localStorage.clear();
        alert.error("Invalid Session!! Please try again");
      });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="container-fluid">
        <div className="row no-gutter full-page-view">
          <SideImage />
          <div
            className="col-md-8 col-lg-8"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="row">
              <AuthHeader />
            </div>
            <div className="login d-flex align-items-center py-5 mx-md-0 mx-3 px-md-0 px-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-5 mx-auto">
                    {step === 1 && (
                      <>
                        <h1 className="mb-4 login-heading text-left">
                          Hi{" "}
                          {location.pathname.split("/")[4] +
                            " " +
                            location.pathname.split("/")[5]}
                          , set your new password
                        </h1>

                        <form onSubmit={handleSubmit} className="my-md-0 my-2">
                          <div className="form-group text-left">
                            <label htmlFor="Password" className="fw-500">
                              {" "}
                              New Password
                            </label>
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              Password must be atleast 8 characters long and
                              contain at least one uppercase, one lowercase and
                              one digit
                            </small>
                            <input
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              type="password"
                              className="form-control mt-2 mb-1 shadow_1-lightest"
                              id="Password"
                              value={Password}
                              aria-describedby="emailHelp"
                              placeholder=""
                              required
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              autoFocus
                            />
                          </div>
                          <div className="form-group text-left">
                            <label htmlFor="ConfirmPassword" className="fw-500">
                              {" "}
                              Confirm Password
                            </label>
                            <input
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                              type="password"
                              className="form-control mt-2 mb-1 shadow_1-lightest"
                              id="ConfirmPassword"
                              value={confirmPassword}
                              aria-describedby="emailHelp"
                              placeholder=""
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              required
                            />
                            <small ref={showErrorText} className="err-text">
                              Password's do not match
                            </small>
                          </div>

                          <div className="row my-1 pt-md-3 pt-3">
                            <div className="col-12 text-left">
                              <button
                                style={{
                                  width: "auto",
                                }}
                                ref={disableButton}
                                className=" shadow_1 btn btn-lg btn-main btn-login text-uppercase font-weight-bold mb-2"
                                type="submit"
                              >
                                Change Password <i className="fas fa-pen"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <div className="row justify-content-center mx-auto my-2">
                          <i class="far fa-check-circle fa-5x"></i>
                        </div>
                        <div className="row justify-content-center mx-auto my-2">
                          <p className="fs-18 fw-500">
                            Bingo! You've successfully changed your password.
                          </p>
                          <p className="fs-14">
                            You can login with your new credentials{" "}
                            <Link to="/login">here</Link>
                          </p>
                        </div>
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <div className="row justify-content-center mx-auto my-2">
                          <i class="far fa-check-circle fa-5x"></i>
                        </div>
                        <div className="row justify-content-center mx-auto my-2">
                          <p className="fs-18 fw-500">Ohh!!!!!</p>
                          <p className="fs-14">
                            This link is expired. Please generate new reset
                            password link{" "}
                            <Link to="/forgot-password">here</Link>
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";
import MoveStep from "../../../Dialog/MoveStep";
import Rejected from "../../../Dialog/Rejected";
import InterviewScheduling from "../../../Dialog/InterviewScheduling";
import AssignMember from "../../../Common/Dropdown/AssignMember";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    borderRadius: "12px",
  },
  icon: {
    color: "#007bff",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    boxShadow: "none",
    borderRadius: "30px",
    height: "40px",
    fontWeight: "500",
    display: "flex",
  },
}));

export default function PerformActionNew({
  details,
  getActionsData,
  moveNextArray,
  isPublic
}) {
  const classes = useStyles();
  const [openMove, setOpenMove] = React.useState(false);

  const handleClickOpenMove = () => {
    setOpenMove(true);
  };

  const handleCloseMove = () => {
    setOpenMove(false);
  };

  const [openReject, setOpenReject] = React.useState(false);

  const handleClickOpenReject = () => {
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  return (
    <>
      <div>
        <div className={classes.overview}>
          <div className={classes.div}>
            <Typography variant="h5">Perform Actions</Typography>
          </div>
          <br />
          <Grid container spacing={2}>
            <Grid item sm={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={handleClickOpenMove}
                disabled={
                  details?.status_id === 14 || details?.status_id === 18 || !moveNextArray || moveNextArray?.length === 0
                    ? true
                    : false
                }
              >
                Change Status
              </Button>
            </Grid>
            {!isPublic && 
            <Grid item sm={6} style={{ display: "grid" }}>
              <InterviewScheduling
                selectedRow={{
                  details: [
                    {
                      id: details?.applicantId,
                      name: details?.name,
                      user_id: details?.user_id,
                    },
                  ],
                  selectedIds: [details?.applicantId],
                }}
                selectedJob={{ job_id: details?.job_id }}
                activeStatusObject={{
                  round_id: details?.round_id,
                  status_id: details?.status_id,
                  tooltip: details?.round_name,
                }}
                fromStudentDetail={true}
              />
            </Grid>
} 
            <Grid item sm={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={handleClickOpenReject}
                disabled={details?.status_id === 14
                  || !moveNextArray || moveNextArray?.length === 0
                  ? true : false}
              >
                Reject
              </Button>
            </Grid>

            {!isPublic && 
            <Grid item sm={6} style={{ display: "grid" }}>
              <AssignMember
                selectedRow={{
                  details: [{ id: details?.applicantId, name: details?.name }],
                  selectedIds: [details?.applicantId],
                }}
                selectedJob={{ job_id: details?.job_id }}
                activeStatusObject={{
                  round_id: details?.round_id,
                  status_id: details?.status_id,
                }}
                fromStudentDetail={true}
              />{" "}
            </Grid>
         
              }
               </Grid>
        </div>
        <MoveStep
          open={openMove}
          handleClickOpen={handleClickOpenMove}
          handleClose={handleCloseMove}
          selectedRow={{
            details: [
              {
                id: details?.applicantId,
                name: details?.name,
                user_id: details?.user_id,
                job_id: details?.job_id,
              },
            ],
            selectedIds: [details?.applicantId],
          }}
          setSelectedRow={null}
          moveNextArray={moveNextArray}
          callBackMainApplicantApi={getActionsData}
          isPublic={isPublic}
        />
        <Rejected
          open={openReject}
          handleClickOpen={handleClickOpenReject}
          handleClose={handleCloseReject}
          selectedRow={{
            details: [
              {
                id: details?.applicantId,
                name: details?.name,
                user_id: details?.user_id,
                job_id: details?.job_id,
              },
            ],
            selectedIds: [details?.applicantId],
          }}
          setSelectedRow={null}
          moveNextArray={[]}
          callBackMainApplicantApi={getActionsData}
          isPublic={isPublic}
        />
      </div>
    </>
  );
}

import { ClickAwayListener } from "@material-ui/core";
import BootstrapTooltip, {
  BootstrapTooltipYellow,
} from "../../../../common/Tooltip/Tooltip";
import help from "../../../../assets/help.png";
import React, { useEffect, useState } from "react";

const HelpFunction = (props) => {
  const { title } = props;
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    // <ClickAwayListener onClickAway={handleTooltipClose}>
    <span>
      <BootstrapTooltipYellow
        title={title}
        PopperProps={{
          disablePortal: true,
        }}
        placement="right-start"
        // onClose={handleTooltipClose}
        // open={open}
        // disableFocusListener
        // disableHoverListener
        // disableTouchListener
      >
        <img
          onClick={handleTooltipOpen}
          src={help}
          alt=""
          height="22px"
          style={{
            paddingBottom: 5,
            paddingLeft: 7,
            cursor: "pointer",
          }}
        />
      </BootstrapTooltipYellow>
    </span>
    // </ClickAwayListener>
  );
};

export default HelpFunction;

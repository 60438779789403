import React from "react";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
const Data = [
  {
    icon: <PlayCircleOutlineIcon />,
    heading: "Start Date",
    details: "Immediately",
  },
  {
    icon: <DateRangeIcon />,
    heading: "Duration",
    details: "6 Months",
  },
  {
    icon: <CreditCardIcon />,
    heading: "Stipend",
    details: "₹8,000 - ₹10,000",
  },
  {
    icon: <HourglassFullIcon />,
    heading: "Apply By",
    details: "24th June 21",
  },
  {
    icon: <WorkOutlineIcon />,
    heading: "Job Type",
    details: "Internship",
  },
];

export default Data;

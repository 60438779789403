import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, Grid, IconButton, makeStyles, useTheme } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import background from "../../../assets/background.jpg";
import ApplicantsProfile from "../ApplicantsProfile.js/ApplicantsProfile";
import Feedback from "../Feedback/Feedback";

export default function APplicantsDetailDialog(props) {
  const {
    open,
    handleClickOpen,
    handleClose,
    currentData,
    setCurrentData,
    trackData,
    currentIndex,
    setCurrentIndex,
    moveNextArray,
    setMoveButtonClicked,
    setSelectedApplicants,
    setFeedbackSentState,
    feedbackTyped,
    setFeedbackTyped,
    currentState,
    show,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const handleNext = () => {
    setCurrentData(trackData[currentIndex + 1]);
    setCurrentIndex(currentIndex + 1);
  };
  const handlePrev = () => {
    setCurrentData(trackData[currentIndex - 1]);
    setCurrentIndex(currentIndex - 1);
  };
  var len = trackData?.length - 1;
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,

            background: theme.palette.type === "dark" ? "#33333" : "linear-gradient(15deg, #d0d5dd, #d5d9e1, #d9dee5, #dee2e9, #e2e7ed, #e7ebf2, #ebf0f6, #f0f4fa)",

            // height: 800,
          },
        }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
      >
        {currentIndex !== 0 && (
          <IconButton aria-label="delete" className={classes.prevButton} onClick={() => handlePrev()}>
            <ChevronLeftIcon />
          </IconButton>
        )}
        {currentIndex < len && (
          <IconButton aria-label="delete" className={classes.nextButton} onClick={() => handleNext()}>
            <ChevronRightIcon />
          </IconButton>
        )}
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {" "}
              <ApplicantsProfile currentData={currentData} applicantsPanel={false} />
            </Grid>
            <Grid item xs={6}>
              <Feedback
                currentData={currentData}
                moveNextArray={moveNextArray}
                setMoveButtonClicked={setMoveButtonClicked}
                setSelectedApplicants={setSelectedApplicants}
                setFeedbackSentState={setFeedbackSentState}
                handleMainModalClose={handleClose}
                feedbackTyped={feedbackTyped}
                setFeedbackTyped={setFeedbackTyped}
                currentState={currentState}
                show={show}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
const useStyles = makeStyles((theme) =>
  createStyles({
    prevButton: {
      backgroundColor: "#fff",
      position: "fixed",
      left: "20%",
      top: "47%",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "1px solid #d8d8d8",
      "&:hover": {
        backgroundColor: "#d8d8d8",
      },
    },
    nextButton: {
      backgroundColor: "#fff",
      position: "fixed",
      right: "20%",
      top: "47%",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "1px solid #d8d8d8",
      "&:hover": {
        backgroundColor: "#d8d8d8",
      },
    },
    "@media only screen and (max-width: 1750px)": {
      prevButton: {
        left: "15%",
      },
      nextButton: {
        right: "15%",
      },
    },
    "@media only screen and (max-width: 1600px)": {
      prevButton: {
        left: "10%",
      },
      nextButton: {
        right: "10%",
      },
    },
    "@media only screen and (max-width: 1450px)": {
      prevButton: {
        left: "7%",
      },
    },
    "@media only screen and (max-width: 1300px)": {
      prevButton: {
        left: "4%",
      },
      nextButton: {
        right: "4%",
      },
    },
    "@media only screen and (max-width: 1150px)": {
      prevButton: {
        left: "0%",
      },
      nextButton: {
        right: "0%",
      },
    },
  })
);

import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CssTextField from "../../../components/common/JobPostNew/FormComponents/CssTextField";
import { makeStyles } from "@material-ui/styles";
import { Divider, Grid } from "@material-ui/core";
import { generateCsvWithoutSpacing } from "../../../common/generateCsv/generateCsv";
import CommonChip from "../../../components/CommonChip/CommonChip";
import { Badge } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    border: theme.palette.type === "dark" ? "solid 1px #504e4e" : "solid 1px #f1f1f1",
    backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
    width: 310,
  },
  filter_s: {
    marginBottom: "30px",
  },
  comboOptions: {
    fontSize: "13px",
    padding: "6.5px 11px",
  },

  autoComplete: {
    width: "100%",
    marginBottom: 10,
    borderRadius: theme.palette.type === "dark" && 30,
  },
  root1: {
    fontWeight: "bolder",
    border: "2px solid #082c5a",
    color: "#082c5a",
    backgroundColor: "#bde3ff",
    fontSize: 13,
  },
  deleteIcon: {
    color: "rgba(0, 0, 0, 0.26)",
  },
  "@media only screen and (max-width: 1500px)": {
    paper: {
      width: 280,
    },
  },
  "@media only screen and (max-width: 1400px)": {
    paper: {
      width: 230,
    },
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: 190,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function FilterDialog({
  open,
  handleClickOpen,
  handleClose,
  data,
  setData,
  filterObject,
  setFilterObject,

  filterArray,
  setFilterArray,
  setSearchString,
  filterData,
  setApplyButtonClicked,
  setJobData,
  setSingleJobData,
  setNewURL,
  setNewEndPoint,
  setEnd,
  setClearAllClicked,
  clearAllClicked,
}) {
  const classes = useStyles();

  const handleDeleteJS = (key, arr, param_name) => {
    let sampleArr = [...arr];
    const index = sampleArr.indexOf(key);
    var temp = { ...filterObject };
    if (index > -1) {
      sampleArr.splice(index, 1);
      temp[param_name] = sampleArr;
      setFilterObject(temp);
      // setApplyButtonClicked(true);
    }
  };

  const handleClearAll = () => {
    var temp = { ...filterObject };
    var dict = { ...filterData };
    Object.keys(temp).map((item) => {
      temp[item] = [];
    });

    setFilterObject(temp);
    var arr = [...filterArray];
    arr.push(1);
    setFilterArray(arr);
    setJobData([]);

    setClearAllClicked(true);
    handleClose();
  };

  useEffect(() => {
    let str = "";
    var count = 0;

    Object.keys(filterObject).forEach((head, index) =>
      Object.keys(filterObject).indexOf(head) !== Object.keys(filterObject).length - 1
        ? (str += filterObject[head].length > 0 ? head + "=" + generateCsvWithoutSpacing(filterObject[head], "id") + "&" : "")
        : (str += filterObject[head].length > 0 ? head + "=" + generateCsvWithoutSpacing(filterObject[head], "id") : "")
    );

    // Object.keys(filterObject).forEach((head, index) => {
    //   if (filterObject[head].length > 0) count = count + 1;
    // });
    // setAppliedFilterCount(count);
    // if (applicants) {
    //   setEnd(false);
    //   setNextUrl(null);
    //   setTrackData([]);
    // }
    setSearchString(str);
    if (clearAllClicked) setApplyButtonClicked(true);
  }, [filterArray.length]);

  const checkFilterNumber = () => {
    var count = 0;
    Object.keys(filterObject).map((item) => {
      if (filterObject[item].length > 0) count = count + 1;
    });

    return count;
  };
  return (
    <div>
      <Badge color="primary" badgeContent={checkFilterNumber()} style={{ marginTop: 10 }}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen} startIcon={<FilterListIcon />}>
          Filters
        </Button>
      </Badge>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Apply Filters
          <br /> <br />
          {filterObject &&
            Object.keys(filterObject).map((item) =>
              filterObject[item].map((value) => (
                <CommonChip
                  label={item === "wfh" ? `Work from home : ${value.name}` : value.name}
                  onDelete={() => handleDeleteJS(value, filterObject[item], item)}
                  deleteIcon={<CloseIcon fontSize="small" />}
                  style={{
                    marginRight: "8px",
                    //   marginBottom: "8px",
                  }}
                />
              ))
            )}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {Object.keys(data).map(
              (item) =>
                data[item].param_name !== "sort_by" &&
                data[item].param_name !== "wfh" && (
                  // <div className={classes.filter_s}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom style={{ fontSize: "12px" }}>
                      {data[item].heading}
                    </Typography>
                    <Autocomplete
                      // value={currentJobSegment}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          var new_arr = [...filterArray];
                          var arr = filterObject[data[item].param_name];
                          var temp = filterObject;
                          var n_arr = [];
                          if (!data[item].is_single_select) {
                            arr.push(newValue);
                            new_arr.push(newValue.id);
                            setFilterArray(new_arr);
                            temp[data[item].param_name] = arr;
                            setFilterObject(temp);
                          } else {
                            n_arr.push(newValue);
                            new_arr.push(newValue.id);
                            setFilterArray(new_arr);
                            temp[data[item].param_name] = n_arr;
                            setFilterObject(temp);
                          }
                        }
                      }}
                      id="role"
                      options={data[item].data}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => {
                        return <span style={{ fontSize: 12 }}>{`${option.name}`}</span>;
                      }}
                      // style={{ width: "100%", marginBottom: 10 }}
                      className={classes.autoComplete}
                      renderInput={(params) => {
                        params.inputProps.className = classes.comboOptions;
                        return <CssTextField {...params} label={<span style={{ fontSize: 12 }}>{data[item].heading}</span>} variant="outlined" />;
                      }}
                    />
                  </Grid>
                )
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleClearAll();
            }}
            color="primary"
          >
            Clear All
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setJobData([]);
              // setSingleJobData(null);
              // setNewEndPoint("");
              // setEnd(false);
              // setNewURL("");
              setApplyButtonClicked(true);
              handleClose();
            }}
            color="primary"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

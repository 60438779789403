import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Divider, Chip, Paper, Typography } from "@material-ui/core";
import pSBC from "shade-blend-color";

const useStyles = makeStyles((theme) => ({
  overview: {
    padding: "15px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: "0px",
    marginRight: "10px",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: pSBC(0.9, theme.palette.primary.main),
  },
}));

export default function Languages({ data }) {
  const classes = useStyles();
  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Read" },
    { key: 1, label: "Write" },
    { key: 2, label: "Speak" },
  ]);

  return (
    <>
      <div>
        <div className={classes.overview}>
          <div className={classes.div}>
            <Typography variant="h6">Languages</Typography>
          </div>
          {data?.map((item) => (
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                {item?.name}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "#6C757D",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                Proficiency: {item?.proficiency}
              </Typography>
              <div component="ul" className={classes.root}>
                {item?.level?.map((data) => {
                  return (
                    <li key={data}>
                      <Chip
                        size="small"
                        label={
                          <Typography
                            color="inherit"
                            style={{ fontSize: "12px", fontWeight: "BOLD" }}
                          >
                            {data}
                          </Typography>
                        }
                        variant="outlined"
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

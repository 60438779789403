import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Pagination from "@material-ui/lab/Pagination";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import pSBC from "shade-blend-color";
import generateCsv from "../../../../components/company/common/generateCsv/generateCsv";
import { BackendBaseApi } from "../../../../constants/constants";
import { getProfileUnlockInfo } from "../../../../GlobalComponents/CreditBoard/action";
import fetchApi from "../../../apiCall/fetchApi";
import { ReactComponent as Error } from "../../../assets/svg/Error.svg";
import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";
import JobApplicants from "../../Cards/JobApplicants/JobApplicants";
import AssignmentTable from "../../Common/Table/AssingmentTable/AssignmentTable";
import CommentDialog from "../../Dialog/CommentDialog";
import PurchasePlanDialog from "../../Dialog/PurchasePlanDialog";
import ScoreDialog from "../../Dialog/ScoreDialog";
import TempPlan from "../../TempMySubscriptionComponents/TempPlan";
import ActionBar from "../ActionBar/ActionBar";
import TableAction from "../ActionBar/TableAction";
import CommonTable from "../Table/CommonTable";

let cgreen = "#0B8A00";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tabpanel-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //    backgroundColor: theme.palette.background.paper,
  },
  rate: {
    backgroundColor: pSBC(0.8, cgreen),
    color: cgreen,
    borderColor: cgreen,
    border: "1px solid",
  },
  linkColor: {
    //  color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

const NewTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    color: theme.palette.primary.main,
    fontSize: "14px",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  scrollButtons: {
    "&.Mui-disabled": {
      opacity: 0.6,
    },
  },
}))(Tabs);

const NewTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "14px",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "#000",
      fontWeight: "bold",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function EmployerTab({
  navbarData,
  setActiveStatusObject,
  activeStatusObject,
  applicantData,
  filterData,
  loading,
  filterLoading,
  selectedRow,
  setSelectedRow,
  value,
  setValue,
  setSelectedFilters,
  selectedFilters,
  setClickFilterApplyButton,
  searchByName,
  setSearchByName,
  applicantDataError,
  filterDataError,
  pageData,
  setPageData,
  paginationResponse,
  setPaginationResponse,
  callBackMainApplicantApi,
  selectedJob,
  applicantCountData,
  setApplicantData,
  isPublic,
  sharedPublicTrackData,
  totalApplicantList,
  setTotalApplicantList,
  setSort,
  sort,
  order,
  setOrder,
  getNavBarData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cardViewApplicantList, setCardViewApplicantList] = useState([]);
  const [moveNextArray, setMoveNextArray] = useState([]);
  const alert = useAlert();
  const [showTempPlanPopUp, setShowTempPlanPopUp] = useState(false);
  const [errorForPopUp, setErrorForPopUp] = useState(null);
  useEffect(() => {
    if (applicantData?.data) {
      let applicants_detail = [];
      applicantData.data.results.applicant_data.forEach((item) => {
        let temp_skill_arr = [];
        if (item?.user_details?.skill && item.user_details.skill.length > 0) {
          item.user_details.skill.forEach((skill_item) => {
            if (
              skill_item.skill_name !== null &&
              skill_item.skill_name !== ""
            ) {
              temp_skill_arr.push(skill_item);
            }
          });
        }
        var current_education = "NA";
        if (
          item?.user_details.education.find((item) => item.is_current === true)
        ) {
          if (
            item.user_details.education.find((item) => item.is_current === true)
              .degree
          ) {
            current_education = `${
              item.user_details.education.find(
                (item) => item.is_current === true
              ).degree
            } 
            ${
              item.user_details.education.find(
                (item) => item.is_current === true
              ).specialization
                ? "in " +
                  item.user_details.education.find(
                    (item) => item.is_current === true
                  ).specialization
                : ""
            }`;
          }
        }

        var currentCollege = "NA";
        if (
          item?.user_details.education.find((item) => item.is_current === true)
        ) {
          if (
            item.user_details.education.find((item) => item.is_current === true)
              .college_name
          ) {
            currentCollege = `${
              item.user_details.education.find(
                (item) => item.is_current === true
              ).college_name
            } 
          `;
          }
        }

        applicants_detail.push({
          newFlag: item?.new_flag,
          collegeName: currentCollege,
          assignment_list: item?.assignment?.length > 0 ? item?.assignment : [],
          id: item.applicant_id,
          name: `${item?.user_details?.first_name} ${item?.user_details?.last_name}`,
          current_salary: item?.user_details?.cur_ctc
            ? `${item?.user_details?.cur_ctc}`
            : "NA",
          expected_salary: item?.user_details?.expected_ctc
            ? `${item?.user_details?.expected_ctc}`
            : "NA",
          location: item?.user_details?.current_city
            ? item?.user_details?.current_city
            : "NA",
          experience: item?.user_details?.work_ex
            ? item?.user_details?.work_ex
            : "NA",
          notice_period: item?.notice_period ? `${item?.notice_period}` : "NA",
          skills: temp_skill_arr?.length
            ? generateCsv(temp_skill_arr, "skill_name")
            : "NA",
          score: item?.relevance_score,
          education: current_education,
          preferred_location: item?.preferred_locations?.length
            ? generateCsv(item?.preferred_locations, "city")
            : "NA",
          industry: "NA",
          email: item?.user_details?.email,
          phone: item?.user_details?.phone,
          user_id: item?.user_id,
          job_id: item?.job_id,
          create_date: item?.create_date,
          score_data: item?.student_job_matching_data,
          key_skill_score:
            item?.student_job_matching_data &&
            item?.student_job_matching_data?.key_skills
              ? `${Number(item?.student_job_matching_data?.key_skills) / 10}/10`
              : "NA",
          communication_score:
            item?.student_job_matching_data &&
            item?.student_job_matching_data?.communication_skills
              ? `${
                  Number(
                    item?.student_job_matching_data?.communication_skills
                  ) / 10
                }/10`
              : "NA",
          is_locked: item?.is_locked,
          resume: item?.user_details?.resume,

          current_title:
            item?.user_details?.work_experience?.length > 0
              ? item?.user_details?.work_experience[0]?.job_designation
              : "NA",
        });
      });
      setTotalApplicantList(applicants_detail);
    }
    // ...(localStorage.getItem("user_type") === "Company" && {
    //   filtered_by: filterObject.applicant_college_id.length === 0 ? null : filterObject.applicant_college_id.map((t) => t.id),
    // }),
  }, [applicantData, sharedPublicTrackData]);

  useEffect(() => {
    if (applicantData?.data) {
      setCardViewApplicantList(applicantData?.data?.results?.applicant_data);
    }
  }, [applicantData]);

  useEffect(() => {
    if (navbarData) {
      var arr = Object.keys(navbarData);
      var new_arr = [];
      var index;
      Object.keys(navbarData).map((item) => {
        if (navbarData[item].param === activeStatusObject?.param) {
          if (navbarData[item].status_id !== 19)
            index = Object.keys(navbarData).indexOf(item);
          else index = -1;
        }
      });
      if (index !== -1) arr.splice(0, index + 1);
      else arr.splice(1, 1);

      arr.map((item) => {
        if (item !== "rejected" && item !== "getwork_screened") {
          new_arr.push({
            status_id: navbarData[item].status_id,
            name: navbarData[item].tooltip,
            round_id: navbarData[item].round_id
              ? navbarData[item].round_id
              : `gw-${Math.floor(Math.random() * 100)}`,
          });
        }
      });
      setMoveNextArray(new_arr);
    }
  }, [navbarData, activeStatusObject]);

  const handleChange = (event, newValue) => {
    var selectedData = null;
    Object.keys(navbarData).forEach((item, index) => {
      if (index === newValue) selectedData = navbarData[item];
    });
    if (selectedData.heading !== activeStatusObject.heading) {
      setValue(newValue);
      setTotalApplicantList([]);
      setActiveStatusObject(selectedData);
    }
  };

  const [open, setOpen] = React.useState(false);

  const [maxWidth, setMaxWidth] = React.useState("xs");
  const [currentApplicant, setCurrentApplicant] = useState(null);

  const handleClickOpen = (applicant_id, name) => {
    setCurrentApplicant({ applicantId: applicant_id, applicantName: name });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [unlockedCandidateData, setUnlockedCandidateData] = useState(null);

  useEffect(() => {
    if (
      unlockedCandidateData &&
      unlockedCandidateData?.data?.data?.length > 0
    ) {
      var arr = [...applicantData.data.results.applicant_data];

      unlockedCandidateData.data.data.forEach((item) => {
        arr.forEach((obj) => {
          if (item?.applicant_id === obj?.applicant_id) {
            obj.user_details.first_name = item?.user_detail?.first_name;
            obj.user_details.last_name = item?.user_detail?.last_name;
            obj.user_details.phone = item?.user_detail?.phone;
            obj.user_details.email = item?.user_detail?.email;
            obj.assignment = item?.user_detail?.assignment;
            obj.user_details.resume = item?.user_detail?.resume;
            obj.user_details.profile_url = item?.user_detail?.profile_url;
            obj.is_locked = false;
          }
        });
      });
      setApplicantData({
        ...applicantData,
        data: {
          ...applicantData.data,
          results: { ...applicantData?.data?.results, applicant_data: arr },
        },
      });
    }
  }, [unlockedCandidateData]);

  const helper = () => {
    dispatch(getProfileUnlockInfo(() => {}));
    callBackMainApplicantApi(true);
    setSelectedRow({
      details: [],
      selectedIds: [],
    });
  };

  const errorCallback = () => {
    setShowTempPlanPopUp(true);
  };
  const handleUnlockCandidate = (id) => {
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/unlock_applicant/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        job_applicant_ids: [id],
        company_id: JSON.parse(localStorage.getItem("company")).company,
      }),
      setResponse: setUnlockedCandidateData,
      //setLoading: setLoadingUnlock,
      callBackFn: helper,
      errorCallback: errorCallback,
    });
  };

  const columnss = [
    {
      field: "name",
      width: 170,
      resizable: true,
      displayName: "Candidate Name",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}> Candidate Name</strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              {!params?.row?.is_locked ? (
                <a
                  href={`${
                    isPublic ? "/public/students/track/" : "/company/students/"
                  }?user_id=${params?.row?.user_id}&applicant_id=${
                    params?.row?.id
                  }&job_id=${params?.row?.job_id}`}
                  style={{ textDecoration: "none", display: "contents" }}
                  target="_blank"
                >
                  <Tooltip title={params.row.name} placement="right">
                    <Typography
                      color="primary"
                      noWrap
                      style={{ fontSize: "14px" }}
                    >
                      {params.row.name}
                    </Typography>
                  </Tooltip>
                </a>
              ) : (
                <div
                  style={{ display: "flex" }}
                  onClick={() => {
                    if (!isPublic) handleUnlockCandidate(params?.row?.id);
                  }}
                >
                  {" "}
                  <LockOpenIcon
                    color="primary"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  />
                  <Tooltip title={params.row.name} placement="right">
                    <Typography
                      color="primary"
                      noWrap
                      style={{ fontSize: "14px" }}
                    >
                      {params.row.name}
                    </Typography>
                  </Tooltip>
                </div>
              )}
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "email",
      width: 150,
      displayName: "Email",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>Email</strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            // textAlign: "center",
            display: "flex",
            // margin: "auto",
            fontSize: "14px",
            cursor: "pointer",
          }}
        >
          <CopyToClipboard
            text={params.row.email}
            onCopy={() => alert.success("Email Copied!")}
          >
            <Tooltip title={params?.row?.email} placement="right">
              <Typography
                style={{
                  fontSize: "14px",
                }}
              >
                {params?.row?.email}
              </Typography>
            </Tooltip>
          </CopyToClipboard>
        </span>
      ),
    },
    {
      field: "phone",
      width: 150,
      displayName: "Phone",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "40px" }}>Phone</strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            cursor: "pointer",
          }}
        >
          <CopyToClipboard
            text={params.row.phone}
            onCopy={() => alert.success("Phone Number Copied!")}
          >
            <Tooltip title={params.row.phone} placement="right">
              <Typography
                style={{
                  fontSize: "14px",
                }}
              >
                {params.row.phone ? params.row.phone : "NA"}
              </Typography>
            </Tooltip>
          </CopyToClipboard>
        </span>
      ),
    },
    {
      field: "current_salary",
      width: 150,
      resizable: true,
      displayName: "Current Salary",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
          Current Salary
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "center",
              display: "flex",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            <Grid item xs zeroMinWidth>
              {/*  <Tooltip title={params.row.asalary} placement="right"> */}
              <Typography noWrap style={{ fontSize: "14px" }}>
                {params.row.current_salary}
              </Typography>
              {/*    </Tooltip> */}
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "expected_salary",
      width: 150,
      displayName: "Expected Salary",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "2px" }}>
          Expected Salary{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.expected_salary}
        </span>
      ),
    },
    {
      field: "location",
      width: 155,
      displayName: "Current Location",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>Current Location</strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            //textAlign: "center",
            display: "flex",
            //margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.location}
        </span>
      ),
    },
    {
      field: "experience",
      width: 130,
      displayName: "Experience",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "6px" }}>
          Experience
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.experience}
        </span>
      ),
    },
    {
      field: "current_Designation",
      width: 180,
      displayName: "Current Designation",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>Current Designation</strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            // textAlign: "center",
            display: "flex",
            //margin: "auto",
            cursor: "pointer",
          }}
        >
          <Tooltip title={params.row.current_title} placement="right">
            <Typography
              style={{
                fontSize: "14px",
              }}
            >
              {params.row.current_title}
            </Typography>
          </Tooltip>
        </span>
      ),
    },
    {
      field: "notice_period",
      width: 130,
      displayName: "Notice Period",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>Notice Period </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            //textAlign: "center",
            display: "flex",
            //margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.notice_period}
        </span>
      ),
    },
    {
      field: "skills",
      // type: "text",
      sortable: false,
      displayName: "Key Skills",
      hide: false,
      sortable: false,
      width: 180,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>Key Skills</strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              // textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link style={{ textDecoration: "none", display: "contents" }}>
                {" "}
                <Tooltip title={params.row.skills} placement="right">
                  <Typography noWrap style={{ fontSize: "14px" }}>
                    {params.row.skills}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "score",
      width: 170,
      hide: false,
      sortable: false,
      displayName: "Relevance Score",
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "13px" }}>
          Relevance Score
        </strong>
      ),
      renderCell: (params) => (
        <div style={{ margin: "auto", textAlign: "center" }}>
          {/* <Chip
            size="small"
            onMouseOver={handleClickOpen}
            onMouse
            label={
              <Typography
                color="inherit"
                style={{ fontWeight: "400", fontSize: "14px" }}
              >
                {params.row.score}
              </Typography>
            }
            className={classes.rate}
          /> */}
          <ScoreDialog
            score={params.row.score}
            scoreData={params?.row?.score_data}
          />
        </div>
      ),
    },
    {
      field: "key_skill_score",
      width: 150,
      hide: false,
      sortable: false,
      displayName: "Key Skill Score",
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
          Key Skill Score
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.key_skill_score}
        </span>
      ),
    },
    {
      field: "communication_score",
      width: 200,
      hide: false,
      sortable: false,
      displayName: "Comm. Skill Score",
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "20px" }}>
          Comm. Skill Score
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params.row.communication_score}
        </span>
      ),
    },
    {
      field: "College Name",
      width: 170,
      hide: false,
      sortable: false,
      displayName: "College Name",
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>College Name</strong>
      ),
      renderCell: (params) => (
        // <span
        //   style={{
        //     // textAlign: "center",
        //     display: "flex",
        //     // margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   {params.row.education}
        // </span>
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link style={{ textDecoration: "none", display: "contents" }}>
                {" "}
                <Tooltip title={params.row.collegeName} placement="right">
                  <Typography noWrap style={{ fontSize: "14px" }}>
                    {params.row.collegeName}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "education",
      width: 170,
      hide: false,
      sortable: false,
      displayName: "Highest Education",
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>Highest Education</strong>
      ),
      renderCell: (params) => (
        // <span
        //   style={{
        //     // textAlign: "center",
        //     display: "flex",
        //     // margin: "auto",
        //     fontSize: "14px",
        //   }}
        // >
        //   {params.row.education}
        // </span>
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link style={{ textDecoration: "none", display: "contents" }}>
                {" "}
                <Tooltip title={params.row.education} placement="right">
                  <Typography noWrap style={{ fontSize: "14px" }}>
                    {params.row.education}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "preferred_location",
      width: 170,
      displayName: "Preferred Location",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268" }}>Preferred Location</strong>
      ),
      renderCell: (params) => (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              //    margin: "auto",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Link style={{ textDecoration: "none", display: "contents" }}>
                {" "}
                <Tooltip
                  title={params.row.preferred_location}
                  placement="right"
                >
                  <Typography noWrap style={{ fontSize: "14px" }}>
                    {params.row.preferred_location}
                  </Typography>
                </Tooltip>
              </Link>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      field: "applied_on",
      width: 130,
      displayName: "Applied On",
      hide: false,
      sortable: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
          Applied On{" "}
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.create_date}
        </span>
      ),
    },
    // {
    //   field: "industry",
    //   width: 110,
    //   displayName: "Industry",
    //   hide: false,
    //   renderHeader: (params) => (
    //     <strong style={{ color: "#5a6268", marginLeft: "15px" }}>Industry</strong>
    //   ),
    //   renderCell: (params) => (
    //     <span
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         margin: "auto",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {params.row.industry}
    //     </span>
    //   ),
    // },

    {
      field: "resume",
      width: 120,
      sortable: false,
      displayName: "Resume",
      hide: false,

      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", margin: "auto" }}>Resume</strong>
      ),
      renderCell: (params) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
            fontSize: "14px",
          }}
        >
          {params?.row?.is_locked ? (
            <Button
              color="primary"
              variant="text"
              onClick={() =>
                alert.error("Please unlock the profile to view resume!!")
              }
            >
              View
            </Button>
          ) : params?.row?.resume ? (
            <a
              href={params?.row?.resume}
              download
              target="blank"
              disabled={params?.row?.resume ? false : true}
              className={classes.linkColor}
            >
              <Button color="primary" variant="text">
                View
              </Button>
            </a>
          ) : (
            <Button color="secondary" variant="text">
              NA
            </Button>
          )}

          {/*  {params?.row?.resume ? (
            params?.row?.is_locked ? (
              <Button
                color="primary"
                variant="text"
                onClick={() => alert.error("Please unlock the profile first!!")}
              >
                View
              </Button>
            ) : (
              <a
                href={params?.row?.resume}
                download
                target="blank"
                disabled={params?.row?.resume ? false : true}
                className={classes.linkColor}
              >
                <Button color="primary" variant="text">
                  View
                </Button>
              </a>
            )
          ) : (
            <Button color="secondary" variant="text">
              NA
            </Button>
          )} */}
        </div>
      ),
    },
    {
      field: "assignment_list",
      width: 140,
      sortable: false,
      displayName: "Assignment",
      hide: false,
      renderHeader: (params) => (
        <strong
          style={{
            color: "#5a6268",
            margin: "auto",
          }}
        >
          Assignment
        </strong>
      ),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
          }}
        >
          {params?.row?.is_locked ? (
            <Button
              color="primary"
              variant="text"
              onClick={() =>
                alert.error("Please unlock the profile to view assignment!!")
              }
            >
              View
            </Button>
          ) : params?.row?.assignment_list?.length > 0 ? (
            <AssignmentTable data={params?.row} />
          ) : (
            "NA"
          )}
        </span>
      ),
    },

    {
      field: "Comments",
      width: 150,
      sortable: false,
      displayName: "Comments",
      hide: false,
      renderHeader: (params) => (
        <strong style={{ color: "#5a6268", margin: "auto" }}>Comments</strong>
      ),
      renderCell: (params) => (
        <div
          style={{
            //textAlign: "center",
            display: "flex",
            // margin: "auto",
            fontSize: "14px",
          }}
        >
          <Button
            color="primary"
            variant="text"
            onClick={() => handleClickOpen(params?.row?.id, params?.row?.name)}
          >
            View Comments
          </Button>
        </div>
      ),
    },
  ];
  const [columns, setColumns] = useState(columnss);

  const [shareColumns, setShareColumns] = useState(
    localStorage.getItem("track_column_fields") !== null
      ? JSON.parse(localStorage.getItem("track_column_fields"))
      : [
          {
            field: "name",
            displayName: "Candidate Name",
            hide: false,
          },
          {
            field: "email",
            displayName: "Email",
            hide: false,
          },
          {
            field: "phone",
            displayName: "Phone",
            hide: false,
          },
          {
            field: "current_salary",
            displayName: "Current Salary",
            hide: false,
          },
          {
            field: "expected_salary",
            displayName: "Expected Salary",
            hide: false,
          },
          {
            field: "location",
            displayName: "Current Location",
            hide: false,
          },
          {
            field: "experience",
            displayName: "Experience",
            hide: false,
          },
          {
            field: "current_Designation",
            displayName: "Current Designation",
            hide: false,
          },
          {
            field: "notice_period",
            displayName: "Notice Period",
            hide: false,
          },
          {
            field: "skills",
            displayName: "Key Skills",
            hide: false,
          },
          {
            field: "score",
            displayName: "Relevance Score",
            hide: false,
          },
          {
            field: "key_skill_score",
            displayName: "Key Skill Score",
            hide: false,
          },
          {
            field: "communication_score",
            displayName: "Comm. Skill Score",
            hide: false,
          },
          {
            field: "College Name",
            displayName: "College Name",
            hide: false,
          },

          {
            field: "education",
            displayName: "Highest Education",
            hide: false,
          },
          {
            field: "preferred_location",
            displayName: "Preferred Location",
            hide: false,
          },
          {
            field: "applied_on",
            displayName: "Applied On",
            hide: false,
          },
          {
            field: "resume",
            displayName: "Resume",
            hide: false,
          },
          {
            field: "assignment_list",
            displayName: "Assignment",
            hide: false,
          },

          {
            field: "Comments",
            displayName: "Comments",
            hide: false,
          },
        ]
  );

  useEffect(() => {
    if (shareColumns) {
      let temp = [...columns];

      shareColumns.map((item, index) => {
        temp[index].hide = item?.hide;
      });

      setColumns(temp);
      localStorage.setItem("track_column_fields", JSON.stringify(shareColumns));
    }
  }, [shareColumns]);

  useEffect(() => {
    if (
      isPublic &&
      sharedPublicTrackData?.action_performed?.table_columns?.length > 0
    ) {
      var arr1 = [...columns];
      var arr2 = [...sharedPublicTrackData?.action_performed?.table_columns];
      var filteredArray = filterByReference(arr1, arr2);
      setColumns(filteredArray);
    }
  }, [sharedPublicTrackData]);

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return arr2.find((element) => {
        return element === el.field;
      });
    });
    return res;
  };

  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => setShowResults(true);

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });

  const handleChangetoggle = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  /* const [switchToggled, setSwitchToggled] = useState(false); */ // localStorage.getItem('toggle') === "true"
  const [switchToggled, setSwitchToggled] = useState(
    localStorage.getItem("switchToggled") === "true"
  );
  const ToggleSwitch = () => {
    switchToggled ? setSwitchToggled(false) : setSwitchToggled(true);
  };
  const handleChangePage = (event, value) => {
    // console.log("inside", value);
    /*  console.log("current Page", pageData.currentPage);
    console.log("value Page", value - 1); */
    if (pageData.currentPage === value - 1) return;
    setPageData({ ...pageData, currentPage: value - 1 });
  };

  useEffect(() => {
    localStorage.setItem("switchToggled", switchToggled);
  }, [switchToggled]);

  /*  useEffect(() => {
    localStorage.setItem("columns", JSON.stringify(columns));
  }, [columns]); */

  return (
    <>
      <div className={classes.root}>
        {showTempPlanPopUp && (
          <PurchasePlanDialog
            setShowTempPlanPopUp={setShowTempPlanPopUp}
            tempPlanPopUp={true}
          />
        )}
        <AppBar
          position="static"
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <NewTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            // variant="fullWidth"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="ant example"
          >
            {navbarData &&
              Object.keys(navbarData).map((item, idx) => (
                /*  <Tooltip title={navbarData[item]?.tooltip} placement="right"> */
                <NewTab
                  key={item}
                  label={
                    <Tooltip
                      title={navbarData[item]?.tooltip}
                      placement="right"
                    >
                      <span>
                        {`${navbarData[item]?.heading} (${navbarData[item]?.count})`}
                      </span>
                    </Tooltip>
                  }
                  {...a11yProps(idx)}
                />
                /*  </Tooltip> */
              ))}
          </NewTabs>
        </AppBar>
        <div
          style={{
            marginTop: "20px",
            marginLeft: "30px",
            marginBottom: "20px",
          }}
        >
          {applicantCountData &&
            Object.keys(applicantCountData)?.map((item) => (
              <Button
                variant="contained"
                color="inherit"
                style={{
                  backgroundColor: "#D7E6F0",
                  borderRadius: "30px",
                  border: "none",
                  boxShadow: "none",
                  marginRight: "10px",
                  fontWeight: "500",
                  cursor: "default",
                }}
              >
                {applicantCountData[item].heading}{" "}
                {`(${applicantCountData[item].count})`}
              </Button>
            ))}
        </div>
        <ActionBar
          ToggleSwitch={ToggleSwitch}
          switchToggled={switchToggled}
          filterData={filterData}
          filterLoading={filterLoading}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          setClickFilterApplyButton={setClickFilterApplyButton}
          tableColumns={shareColumns}
          setTableColumns={setShareColumns}
          searchByName={searchByName}
          setSearchByName={setSearchByName}
          filterDataError={filterDataError}
          setSort={setSort}
          sort={sort}
          order={order}
          setOrder={setOrder}
          isPublic={isPublic}
        />
        {navbarData &&
          Object.keys(navbarData).map((item, idx) => (
            <TabPanel value={value} index={idx} key={item}>
              <br />
              {switchToggled ? (
                <>
                  <TableAction
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    moveNextArray={moveNextArray}
                    totalApplicantList={totalApplicantList}
                    callBackMainApplicantApi={callBackMainApplicantApi}
                    activeStatusObject={activeStatusObject}
                    selectedJob={selectedJob}
                    setApplicantData={setApplicantData}
                    applicantData={applicantData}
                    isPublic={isPublic}
                    sharedPublicTrackData={sharedPublicTrackData}
                    errorCallback={errorCallback}
                    getNavBarData={getNavBarData}
                    setTotalApplicantList={setTotalApplicantList}
                  />
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 80,
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress size={50} />
                    </div>
                  ) : (
                    <>
                      {applicantDataError ? (
                        <div
                          style={{
                            display: "flex",
                            marginTop: 80,
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Error
                              height="180px"
                              width="180px"
                              alt="404 Error"
                              fill={"#007BFF"}
                            />
                            <p align="center">{applicantDataError}</p>
                          </div>
                        </div>
                      ) : cardViewApplicantList?.length === 0 ? (
                        <div
                          style={{
                            display: "flex",
                            marginTop: 80,
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <NoData
                              height="180px"
                              width="180px"
                              alt="404 Error"
                              fill={"#007BFF"}
                            />
                            <p align="center">{"No Applicants"}</p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {cardViewApplicantList?.length > 0 &&
                            cardViewApplicantList?.map((item) => (
                              <JobApplicants
                                cardViewApplicantList={cardViewApplicantList}
                                item={item}
                                loading={loading}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                moveNextArray={moveNextArray}
                                callBackMainApplicantApi={
                                  callBackMainApplicantApi
                                }
                                selectedJob={selectedJob}
                                activeStatusObject={activeStatusObject}
                                isPublic={isPublic}
                                sharedPublicTrackData={sharedPublicTrackData}
                                errorCallback={errorCallback}
                                handleUnlockCandidate={handleUnlockCandidate}
                              />
                            ))}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Pagination
                              count={Math.ceil(
                                paginationResponse?.totalDataCount / 10
                              )}
                              color="primary"
                              page={pageData?.currentPage + 1}
                              onChange={handleChangePage}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <TableAction
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    moveNextArray={moveNextArray}
                    totalApplicantList={totalApplicantList}
                    callBackMainApplicantApi={callBackMainApplicantApi}
                    activeStatusObject={activeStatusObject}
                    selectedJob={selectedJob}
                    setApplicantData={setApplicantData}
                    applicantData={applicantData}
                    isPublic={isPublic}
                    sharedPublicTrackData={sharedPublicTrackData}
                    errorCallback={errorCallback}
                    getNavBarData={getNavBarData}
                    setTotalApplicantList={setTotalApplicantList}
                  />
                  <CommonTable
                    columns={columns}
                    rowData={totalApplicantList}
                    loading={loading}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    applicantDataError={applicantDataError}
                    pageData={pageData}
                    setPageData={setPageData}
                    totalDataCount={paginationResponse?.totalDataCount}
                    fromTrack={true}
                    fromManageJobs={false}
                  />{" "}
                </>
              )}
            </TabPanel>
          ))}
        <CommentDialog
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          activeStatusObject={activeStatusObject}
          currentApplicant={currentApplicant}
          isPublic={isPublic}
        />
      </div>
    </>
  );
}

/* eslint-disable */
import {
  Chip,
  CircularProgress,
  createStyles,
  Divider,
  makeStyles,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { useAlert } from "react-alert";
import upload from "../../../assets/images/assignmentUpload.png";
import new_i from "../../../assets/common/back.gif";
import plus from "../../../assets/images/fig_plus.png";
import map from "../../../assets/images/map.png";
import {
  generateCsvNew,
  generateCsvWithName,
} from "../../../common/generateCsv/generateCsv";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/httpRequest";
import TrackPane from "./TrackPane";

export default function Assignment() {
  const classes = useStyles();
  const [buttonClick, setButtonClick] = useState(false);
  const [job_Type_campus, setJob_Type_campus] = useState();
  const [jobTypes, setJobTypes] = useState({});
  const [jobType, setJobType] = useState();
  const [jobTypeName, setJobTypeName] = useState("");
  const [oldFileDetails, setOldFileDetails] = useState();

  const [assignmentTitle, setAssignmentTitle] = useState();
  const [assignmentDesc, setAssignmentDesc] = useState();
  const [submissionDate, setSubmissionDate] = useState();
  const [fileDetails, setFileDetails] = useState();
  const [data, setData] = useState([]);
  const [closedData, setClosedData] = useState([]);
  const [fileProgress, setFileProgress] = useState(0);
  const [end, setEnd] = useState(false);
  const [closedEnd, setClosedEnd] = useState(false);

  const [newClosedURL, setNewClosedURL] = useState("");
  const [newClosedEndPoint, setClosedNewEndPoint] = useState("");
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");

  const [allJobProfiles, setAllJobProfiles] = useState([]);
  const [jobProfile, setJobProfile] = useState("All");

  const [jobType1, setJobType1] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("All");

  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("All");
  const [campusType, setCampusType] = useState(false);
  const [onlyClosed, setOnlyClosed] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [mail, setMail] = useState([]);
  const [companyID, setCompanyID] = useState(
    localStorage.getItem("company")
      ? btoa(JSON.parse(localStorage.getItem("company")).company)
      : null
  );
  const userID = JSON.parse(localStorage.getItem("user")).id;
  const companyName = JSON.parse(localStorage.getItem("company")).company_name;

  const alert = useAlert();
  var flag = 0;

  var today = new Date();

  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  // const getClosedAssignments = () => {
  //   Axios.get(
  //     +"company/assignment/?company_id=" +
  //       companyID +
  //       "&assignment_status=Q0xPU0U=",
  //     {
  //       headers: {
  //         Authorization: `Token ${localStorage.getItem("gw_token")}`,
  //       },
  //     }
  //   )
  //     .then((res) => {
  //       if (res.data.success) {
  //         if (res.data.data.length) {
  //           const temp = res.data.data;

  //           var result = temp.map((v) => ({
  //             ...v,
  //             iconButtonClick: "close",
  //           }));
  //           setClosedData(result);
  //         }
  //       } else {
  //         alert.error(res.data.error);
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const handleScroll = (event) => {
    let e = event.nativeEvent;

    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end === false && !isApiCalled) {
        const token = localStorage.getItem("gw_token");
        GetData(newURL, newEndPoint, { headers: token }, setData);
      }
    }
  };

  const handleScroll1 = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (closedEnd !== true && !isApiCalled) {
        const token = localStorage.getItem("gw_token");
        GetData(newClosedURL, newClosedEndPoint, { headers: token }, setData);
      }
    }
  };

  const getJobProfiles = async () => {
    const appendParam = () => {
      if (selectedJobType === "All") {
        return "";
      } else return `&job_type_id=${btoa(selectedJobType)}`;
    };
    // const appendParam2 = () => {
    //   if (selectedCompany === "All") {
    //     return "";
    //   } else return `&company_id=${btoa(selectedCompany)}`;
    // };

    const checkOnlyClosed = () => {
      if (!onlyClosed) {
        return "";
      } else {
        return `&job_status=${btoa("CLOSE")}`;
      }
    };

    const checkCampusType = () => {
      if (!campusType) {
        return `&hiring_type=${btoa("OFF CAMPUS")}`;
      } else {
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      }
    };

    // const checkJobStatus = () => {
    //   return `&status=${btoa(activeTab)}`;
    // };

    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/job/list/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${appendParam()}${checkOnlyClosed()}${checkCampusType()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });

    let obj = { job_id: "All", job_title: "All" };

    setAllJobProfiles([obj, ...res.data.data]);
  };

  const getJobTypes1 = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch(
      (err) => {
        throw err;
      }
    );
    let obj = { id: "All", job_type: "All" };
    setJobType1([obj, ...res.data.data]);
  };

  const getCompanies = async () => {
    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/college/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    let obj = { name: "All", id: "All" };
    setAllCompanies([obj, ...res.data.data]);
  };

  useEffect(() => {
    //getJobProfiles();
    getJobTypes1();
    getCompanies();
  }, []);

  useEffect(() => {
    getJobProfiles();
  }, [selectedJobType, selectedCompany, campusType]);

  useEffect(() => {
    const token = localStorage.getItem("gw_token");
    const checkJobProfile = () => {
      flag = 1;
      if (jobProfile === "All") {
        return "";
      } else {
        return `&job_id=${btoa(jobProfile)}`;
      }
    };

    const checkJobType = () => {
      flag = 1;
      if (selectedJobType === "All") {
        return "";
      } else {
        return `&job_type_id=${btoa(selectedJobType)}`;
      }
    };

    const checkCollege = () => {
      if (selectedCompany === "All") {
        return "";
      } else {
        return `&college_id=${btoa(selectedCompany)}`;
      }
    };

    const checkOnlyClosed = () => {
      if (!onlyClosed) {
        return "";
      } else {
        return `&job_status=${btoa("CLOSE")}`;
      }
    };

    // const checkJobStatus = () => {
    //   return `&status=${btoa(activeTab)}`;
    // };

    const checkCampusType = () => {
      flag = 1;
      setEnd(false);
      setClosedEnd(false);

      if (!campusType) {
        return `&hiring_type=${btoa("OFF CAMPUS")}`;
      } else {
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      }
    };
    const assignmentStatus = () => {
      return `&assignment_status=${btoa("OPEN")}`;
    };
    const assignmentStatus1 = () => {
      return `&assignment_status=${btoa("CLOSE")}`;
    };
    GetData(
      BackendBaseApi.NIYUKTI,

      `job/company/assignment/?company_id=${companyID}${assignmentStatus()}${checkJobType()}${checkJobProfile()}${checkCampusType()}`,
      { headers: token },
      setData
    );

    GetClosedData(
      BackendBaseApi.NIYUKTI,

      `job/company/assignment/?company_id=${companyID}${assignmentStatus1()}${checkJobType()}${checkJobProfile()}${checkCampusType()}`,
      { headers: token },
      setClosedData
    );
  }, [selectedJobType, jobProfile, campusType]);

  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setIsApiCalled(true);
    let res = await httpRequest(baseUrl, endPoint, body);

    var result;

    if (res.data.next === null) {
      setEnd(true);

      if (res.data.results.length) {
        const temp = res.data.results;

        var result = temp.map((v) => ({
          ...v,
          iconButtonClick: "close",
        }));
        // setData(result);
        if (flag === 1) updateState(result);
        else updateState(data.concat([...result]));
      } else {
        updateState([]);
      }
    } else {
      setNewURL(res.data.next.slice(0, 20));
      setNewEndPoint(res.data.next.slice(20));
      if (res.data.results.length) {
        const temp = res.data.results;

        result = temp.map((v) => ({
          ...v,
          iconButtonClick: "close",
        }));
        if (flag === 1) updateState(result);
        else updateState(data.concat([...result]));
      } else {
        updateState([]);
      }

      // setFiltersData(res.data.filter);
      // setLoading(false);
    }
    setIsApiCalled(false);

    // updateState(data.concat([...result]));
  };

  const GetClosedData = async (baseUrl, endPoint, body, updateState) => {
    let res = await httpRequest(baseUrl, endPoint, body);

    var result;

    if (res.data.next === null) {
      setClosedEnd(true);
      if (res.data.results.length) {
        const temp = res.data.results;

        var result = temp.map((v) => ({
          ...v,
          iconButtonClick: "close",
        }));
        // setData(result);
        if (flag === 1) updateState(result);
        else updateState(closedData.concat([...result]));
      } else {
        updateState([]);
      }
    } else {
      setNewClosedURL(res.data.next.slice(0, 20));
      setClosedNewEndPoint(res.data.next.slice(20));
      if (res.data.results.length) {
        const temp = res.data.results;

        result = temp.map((v) => ({
          ...v,
          iconButtonClick: "close",
        }));
        updateState(closedData.concat([...result]));
      } else {
        updateState([]);
      }

      // setFiltersData(res.data.filter);
      // setLoading(false);
    }

    // updateState(data.concat([...result]));
  };

  // useEffect(() => {
  //   getOpenAssignments();
  // }, [data.length]);

  // useEffect(() => {
  //   getClosedAssignments();
  // }, [closedData.length]);

  const getJobTypes = (e) => {
    //   var hiringType = job_Type_campus=="oncampus"?'T04gQ0FNUFVT':'T0ZGIENBTVBVUw=='
    var hiringType;

    if (e == "oncampus") {
      hiringType = "T04gQ0FNUFVT";
    }
    if (e == "offcampus") {
      hiringType = "T0ZGIENBTVBVUw==";
    }
    const url =
      BackendBaseApi.NIYUKTI +
      "job/company/job/list/?company_id=" +
      companyID +
      "&hiring_type=" +
      hiringType;

    Axios.get(url, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          let temp = {};
          res.data.data.forEach((j) => {
            temp[j.job_id] = j;
          });
          setJobTypes({ ...temp });
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // function getKeyByValue(object, value) {
  //   for (var prop in object) {
  //     if (object.hasOwnProperty(prop)) {
  //       if (object[prop] === value) return prop;
  //     }
  //   }
  // }

  const statusObj = [
    {
      name: "Applied Candidates",
      id: 1,
    },
    { name: "Reviewed Candidates", id: 8 },
    { name: "Shortlisted Candidates", id: 2 },
    { name: "Interviewed Candidates", id: 7 },
  ];

  const jobtypes = Object.values(jobTypes).map((job_type) => {
    return { name: job_type.job_title, value: job_type.job_id };
  });

  const checkJobProfile = () => {
    if (jobType) return `&job_id=${btoa(jobType)}`;
    else return ``;
  };

  const checkCampusType = () => {
    if (job_Type_campus) {
      if (job_Type_campus === "oncampus")
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      else return `&hiring_type=${btoa("OFF CAMPUS")}`;
    } else return ``;
  };

  const [roundsData, setRoundsData] = useState([]);

  const getRoundsDynamic = () => {
    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/track/applicants/count?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${checkJobProfile()}${checkCampusType()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        var arr = [];
        if (res.data.success) {
          Object.keys(res.data.data).map((item) => {
            if (
              res.data.data[item].status_id !== 13 &&
              res.data.data[item].status_id !== 18 &&
              res.data.data[item].status_id !== 14
            )
              arr.push({
                status_id: res.data.data[item].status_id,
                round_id: res.data.data[item].round_id,
                round_name: res.data.data[item].round_id
                  ? res.data.data[item].tooltip
                  : null,
                status_name: res.data.data[item].round_id
                  ? "Interviewed"
                  : res.data.data[item].tooltip,
                name: res.data.data[item].tooltip,
              });
          });
          setRoundsData(arr);
        }
      })
      .catch((err) => {
        console.log("Some Error Occured");
      });
  };

  useEffect(() => {
    if (jobType) {
      getRoundsDynamic();
    }
  }, [jobType]);

  const JobTypes = () => (
    <Autocomplete
      onChange={(event, newValue) => {
        setJobType(newValue.value);
        setJobTypeName(newValue);
      }}
      disabled={job_Type_campus ? false : true}
      id="controllable-states-demo"
      options={jobtypes}
      getOptionLabel={(option) => option.name}
      value={jobTypeName}
      style={{ width: "30%" }}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
  );

  const Status = () => (
    <Autocomplete
      multiple
      filterSelectedOptions
      id="tags-outlined"
      options={roundsData}
      disabled={job_Type_campus && jobType ? false : true}
      getOptionLabel={(option) => option.name}
      // defaultValue={[statusObj[0]]}
      style={{ width: "70%", height: "100%" }}
      onChange={(e, i) => {
        var arr = uniqueArray(i);
        setMail(arr);
      }}
      value={mail}
      renderInput={(params) => (
        <TextField
          {...params}
          // variant="outlined"
          label="Send Assignment to"
          // placeholder="Favorites"
        />
      )}
    />
  );

  const uniqueArray = (arr) => {
    return [...new Set(arr.map(JSON.stringify))].map(JSON.parse);
  };

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
      name: newName,
    });
  }
  const getFileDetails = (e) => {
    if (jobType) {
      var extension = e.target.files[0].name.split(".").pop();
      const newName = `${jobTypeName.name
        .split(" ")
        .join("_")}_${localStorage.getItem("user_id")}_${jobType}_${Math.floor(
        100000 + Math.random() * 900000
      )}.${extension}`;
      var new_file = renameFile(e.target.files[0], newName);

      if (e.target.files[0].size <= 10485760) {
        setFileDetails(new_file);
        setOldFileDetails(e.target.files[0]);
      } else alert.error("File Size should be less than 10 MB");
    } else {
      alert.error("First Select Job Type");
    }
  };

  const clearFields = () => {
    setJob_Type_campus();
    setJobType();
    setAssignmentDesc();
    setAssignmentTitle();
    setSubmissionDate();
    setFileDetails();
  };

  const sendAssignment = () => {
    if (
      jobType &&
      assignmentTitle &&
      submissionDate &&
      fileDetails &&
      mail.length > 0
    ) {
      const fd = new FormData();
      const configu = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setFileProgress(percentCompleted);
        },
      };
      var arr = [];
      mail.map((item) => {
        arr.push(item.status_id);
      });

      fd.append("job_id", jobType);
      fd.append("job_title", jobTypeName.name);

      fd.append("company_name", companyName);
      fd.append("user_id", userID);
      fd.append("title", assignmentTitle);
      fd.append("description", assignmentDesc);
      fd.append("end_date", submissionDate);
      fd.append("assignment_status", "OPEN");
      fd.append("url", null);
      fd.append("file", fileDetails);
      fd.append("status_id", generateCsvNew(arr));
      fd.append("status_id_list", JSON.stringify(mail));
      fd.append("scheduled_at", JSON.stringify(mail));

      const obj = {
        job_id: jobType,
        user_id: userID,
        title: assignmentTitle,
        description: assignmentDesc,
        end_date: submissionDate,
        assignment_status: "OPEN",
        url: null,
        file: fileDetails,
      };

      Axios.post(BackendBaseApi.NIYUKTI + "job/company/assignment/", fd, {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,

          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setFileProgress(percentCompleted);
        },
      }).then((res) => {
        if (res.data.success === true) {
          let list = [...data];

          obj.iconButtonClick = "close";
          obj.job_location = res.data.data.verbose.job_location;
          obj.job_title = res.data.data.verbose.job_title;
          obj.file = res.data.data.verbose.file;
          obj.hiring_type = res.data.data.verbose.hiring_type;
          obj.assignment_sent_status =
            res.data.data.verbose.assignment_sent_status;

          list.unshift(obj);

          setData(list);

          setFileProgress(0);
          alert.success("Assignment Added Successfully");
          setButtonClick(false);
          clearFields();
          setMail();

          // window.location.reload(false);
        } else {
          setButtonClick(false);
          setFileProgress(0);
          alert.error(res.data.error);
          setMail();
        }
      });
      // .catch((err) => {
      //   alert.error("Assignment addition unsuccessful");
      // });
    } else if (jobType && assignmentTitle && submissionDate)
      alert.error("Upload File Correctly");
    else {
      alert.error("Fill all the required Fields");
    }
  };
  return (
    <>
      <div style={{ marginTop: "-20px" }}>
        <TrackPane
          profiles={allJobProfiles}
          jobProfile={jobProfile}
          jobTypes={jobType1}
          selectedJobType={selectedJobType}
          setSelectedJobType={setSelectedJobType}
          setJobProfile={setJobProfile}
          allCompanies={allCompanies}
          setSelectedCompany={setSelectedCompany}
          campusType={campusType}
          setCampusType={setCampusType}
        />
        <Container maxWidth="lg">
          {/* <Box className={classes.BlueBox}>
          <Grid container spacing={3}>
            <Grid item xs={1}>
              <img src={assignment1} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.BlueBodyText}>
                Create Assignments for any particular Job, Applicants will
                recieve the Asignments created by you.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <br /> */}

          {buttonClick == false ? (
            <>
              {" "}
              <Box
                style={{
                  display: "flex",
                  float: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  className={classes.postButton}
                  onClick={() => {
                    setButtonClick(true);
                  }}
                  style={{
                    marginBottom: "20px",
                    borderRadius: "30px",
                    marginTop: "1%",
                    backgroundColor: "#007BFF",
                    height: "40px",
                    boxShadow: "none",
                    display: "flex",
                  }}
                >
                  Create Assignment &nbsp; &nbsp; <img src={plus} />
                </Button>
              </Box>
              {/* <Typography className={classes.BlueBodyText}>
              ** Create Assignments for any particular Job, Applicants will
              recieve the Asignments created by you.
            </Typography> */}
              <div class="containerA1">
                <span
                  data-title="** Create Assignments for Open Jobs, Applicants will recieve the
                Asignments created by you."
                  class="text"
                >
                  ** Create Assignments for Open Jobs, Applicants will recieve
                  the Asignments created by you.
                </span>
              </div>
            </>
          ) : (
            <Box className={classes.assignmentBox}>
              {fileProgress === 0 ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Typography className={classes.textTop} variant="body1">
                        <b>Job Type</b>&nbsp;
                        <span className={classes.redstar}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="gender1"
                        value={job_Type_campus}
                        onChange={(e) => {
                          setJob_Type_campus(e.target.value);

                          getJobTypes(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="oncampus"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label="On Campus"
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                        &nbsp; &nbsp;
                        <FormControlLabel
                          value="offcampus"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label="Off Campus"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          setButtonClick(false);
                          clearFields();
                        }}
                        aria-label="delete"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Typography className={classes.textTop} variant="body1">
                        <b>Select Job</b>&nbsp;
                        <span className={classes.redstar}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid4}>
                      <JobTypes />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Typography className={classes.textTop} variant="body1">
                        <b>Send Assignment to</b>&nbsp;
                        <span className={classes.redstar}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid4}>
                      <Status />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Typography className={classes.textTop} variant="body1">
                        <b>Assignment Title</b>&nbsp;
                        <span className={classes.redstar}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid4}>
                      <TextField
                        id="date"
                        label="Assignment Title"
                        type="text"
                        onChange={(e) => {
                          setAssignmentTitle(e.target.value);
                        }}
                        value={assignmentTitle}
                        variant="outlined"
                        style={{ width: "50%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Typography className={classes.textTop} variant="body1">
                        <b>Assignment Description</b>&nbsp;
                        {/* <span className={classes.redstar}>*</span> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid4}>
                      <TextareaAutosize
                        rowsMin={3}
                        rowsMax={5}
                        style={{
                          width: "50%",
                          height: "100%",
                          borderRadius: "5px",
                          borderColor: "#d2cdcd",
                        }}
                        aria-label="maximum height"
                        placeholder="Maximum 100 words.."
                        onChange={(e) => {
                          setAssignmentDesc(e.target.value);
                        }}
                        value={assignmentDesc}
                      />
                    </Grid>
                  </Grid>
                  <br />

                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Typography className={classes.textTop} variant="body1">
                        <b>Last Date of Submission</b>&nbsp;
                        <span className={classes.redstar}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid4}>
                      <TextField
                        id="date"
                        label="Submission Date"
                        type="date"
                        onChange={(e) => {
                          setSubmissionDate(e.target.value);
                        }}
                        value={submissionDate}
                        variant="outlined"
                        style={{ width: "30%" }}
                        InputProps={{ inputProps: { min: String(today) } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <Typography className={classes.textTop} variant="body1">
                        <b>Upload Assignment File</b>&nbsp;
                        <span className={classes.redstar}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid4}>
                      <input
                        // accept="image/*"
                        onChange={(e) => {
                          getFileDetails(e);
                        }}
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          // className={classes.UploadButton}
                          className={classes.postButton}
                          variant="contained"
                          component="span"
                          style={{
                            marginBottom: "20px",
                            borderRadius: "30px",
                            marginTop: "1%",
                            backgroundColor: "#007BFF",
                            height: "40px",
                            boxShadow: "none",
                            display: "flex",
                          }}
                        >
                          Upload File
                        </Button>
                        &nbsp;
                        {oldFileDetails ? (
                          oldFileDetails.name
                        ) : (
                          <>No File choosen</>
                        )}{" "}
                      </label>
                      <br />
                      <br />
                      <Typography variant="body2">
                        Note: The file size should not ne more then 10 MB
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  <Button
                    type="submit"
                    className={classes.postButton}
                    onClick={() => {
                      sendAssignment();
                    }}
                    style={{
                      marginBottom: "20px",
                      borderRadius: "30px",
                      marginTop: "1%",
                      backgroundColor: "#007BFF",
                      height: "40px",
                      boxShadow: "none",
                      display: "flex",
                    }}
                  >
                    Send Assignment &nbsp; &nbsp; <img src={plus} />
                  </Button>
                </>
              ) : (
                <>
                  <CircularProgress
                    variant="determinate"
                    value={fileProgress}
                    style={{ marginLeft: "50%" }}
                  />
                  <Box
                    top={-340}
                    left={10}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      style={{
                        color: "black",
                      }}
                    >
                      <b>{`${fileProgress}%`}</b>
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          )}
          <br />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div
                // className="scrollY"
                // style={{
                //   height: "90vh",
                // }}
                onScroll={handleScroll}
              >
                <Box className={classes.assignmentBox1}>
                  <Typography style={{ color: "#219653" }} variant="h6">
                    <b>Open Assignments</b>
                  </Typography>
                  <br />

                  <Divider />
                  <br />
                  <>
                    {data.length > 0 ? (
                      data.map((d, index) => {
                        return (
                          <Fragment key={index}>
                            <Card className={classes.cardRoot}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={7}>
                                    <Typography variant="h6">
                                      <b>{d.job_title}</b>
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={5}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Chip
                                      label={d.hiring_type + " HIRING"}
                                      variant="outlined"
                                      className={classes.root}
                                      color="primary"
                                    />
                                    {d.iconButtonClick == "close" ? (
                                      <>
                                        <IconButton
                                          onClick={() => {
                                            let ar = data;

                                            ar[index].iconButtonClick = "open";
                                            setData([...ar]);
                                          }}
                                          aria-label="delete"
                                        >
                                          <ExpandMoreIcon />{" "}
                                        </IconButton>
                                        {/* <Button
                                    variant="contained"
                                    className={classes.iconButton}
                                    startIcon={<ExpandMoreIcon />}
                                    onClick={() => {
                                      let ar = data;

                                      ar[index].iconButtonClick = "open";
                                      setData([...ar]);
                                    }}
                                  ></Button> */}
                                      </>
                                    ) : (
                                      <>
                                        {/* <Button
                                    variant="contained"
                                    className={classes.iconButton}
                                    startIcon={<ExpandLessIcon />}
                                    onClick={() => {
                                      let ar = data;

                                      ar[index].iconButtonClick = "close";
                                      setData([...ar]);
                                    }}
                                  ></Button> */}
                                        <IconButton
                                          onClick={() => {
                                            let ar = data;

                                            ar[index].iconButtonClick = "close";
                                            setData([...ar]);
                                          }}
                                          aria-label="delete"
                                        >
                                          <ExpandLessIcon />{" "}
                                        </IconButton>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Typography variant="body1">
                                      <b> Assignment Title: </b> {d.title}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <br />
                                {d.job_location && (
                                  <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                      <Box className={classes.locationBox}>
                                        <Typography variant="body1">
                                          <img
                                            src={map}
                                            height="18px"
                                            alt="map"
                                          />
                                          &nbsp;
                                          {d.job_location.length > 0 ? (
                                            d.job_location.map((item, i) => (
                                              <Fragment key={i}>
                                                {i !=
                                                d.job_location.length - 1 ? (
                                                  <>{item.city}, </>
                                                ) : (
                                                  <>{item.city}</>
                                                )}
                                              </Fragment>
                                            ))
                                          ) : (
                                            <>Work From Home</>
                                          )}{" "}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}

                                {d.iconButtonClick == "open" ? (
                                  <>
                                    <br />
                                    <Box className={classes.greyBox}>
                                      <Typography
                                        variant="body2"
                                        style={{ paddingBottom: "10px" }}
                                      >
                                        <b>ASSIGNMENT DETAILS</b>
                                      </Typography>

                                      <Typography variant="body2">
                                        <b>Title:</b>
                                        <span style={{ color: "#255a9b" }}>
                                          &nbsp; {d.title}
                                        </span>{" "}
                                      </Typography>
                                      <Typography variant="body2">
                                        <Grid container spacing={0}>
                                          <Grid item xs={4}>
                                            {" "}
                                            <b>Assignment Description:</b>
                                          </Grid>
                                          <Grid item xs={8}>
                                            <Typography
                                              align="left"
                                              style={{
                                                color: "#255a9b",
                                                margin: "auto",
                                                padding: "auto",
                                                // display: "inline-block",
                                              }}
                                            >
                                              {d.description}
                                            </Typography>{" "}
                                          </Grid>
                                        </Grid>
                                      </Typography>
                                      <Typography variant="body2">
                                        <b>Submission Date:</b>{" "}
                                        <span style={{ color: "#255a9b" }}>
                                          &nbsp; {d.end_date}
                                        </span>{" "}
                                      </Typography>
                                      {d.assignment_sent_status !== null && (
                                        <Typography variant="body2">
                                          <b>Assignment sent to:</b>
                                          <span style={{ color: "#255a9b" }}>
                                            &nbsp;{" "}
                                            {generateCsvWithName(
                                              d.assignment_sent_status,
                                              "name"
                                            )}
                                            {/* {d.assignment_sent_status.map(
                                            (item) => item.name
                                          )} */}
                                          </span>{" "}
                                        </Typography>
                                      )}
                                      <Typography variant="body2">
                                        <b>Attachment:</b>{" "}
                                        <span style={{ color: "#255a9b" }}>
                                          &nbsp;{" "}
                                          {d.file
                                            ? d.file.split("/")[6].split("?")[0]
                                            : "null"}
                                        </span>
                                        <a
                                          href={d.file ? d.file : null}
                                          download="GFG"
                                          target="blank"
                                        >
                                          &nbsp; <img src={upload} />
                                        </a>
                                      </Typography>
                                    </Box>
                                  </>
                                ) : null}
                                {/* <Link to="/company/track">
                                <Typography
                                  variant="body2"
                                  align="right"
                                  style={{
                                    color: "#3282C4",
                                    marginTop: "12px",
                                  }}
                                >
                                  View Submitted Assignments&nbsp;{" "}
                                  <img src={arrow} alt="arrow" />
                                </Typography>
                              </Link> */}
                              </CardContent>
                            </Card>
                            <br />
                          </Fragment>
                        );
                      })
                    ) : (
                      <>
                        <img
                          src={new_i}
                          align="center"
                          style={{
                            margin: "auto",
                            height: "250px",
                            paddingLeft: "40%",
                          }}
                        />
                        <Typography align="center" variant="h6">
                          <b>No Assignments created yet</b>
                        </Typography>
                      </>
                    )}
                  </>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                // className="scrollY"
                // style={{
                //   height: "90vh",
                // }}
                onScroll={handleScroll1}
              >
                <Box className={classes.assignmentBox1}>
                  <Typography style={{ color: "#D64045" }} variant="h6">
                    <b>Closed Assignments</b>
                  </Typography>
                  <br />

                  <Divider />
                  <br />
                  <>
                    {closedData.length ? (
                      closedData.map((d, index) => {
                        return (
                          <Fragment key={index}>
                            <Card className={classes.cardRoot}>
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={7}>
                                    <Typography variant="h6">
                                      <b>{d.job_title}</b>
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={5}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Chip
                                      label={d.hiring_type + " HIRING"}
                                      variant="outlined"
                                      className={classes.root}
                                      color="primary"
                                    />
                                    {d.iconButtonClick == "close" ? (
                                      <IconButton
                                        onClick={() => {
                                          let ar = closedData;

                                          ar[index].iconButtonClick = "open";
                                          setClosedData([...ar]);
                                        }}
                                      >
                                        <ExpandMoreIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => {
                                          let ar = closedData;

                                          ar[index].iconButtonClick = "close";
                                          setClosedData([...ar]);
                                        }}
                                      >
                                        <ExpandLessIcon />
                                      </IconButton>
                                    )}
                                  </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Typography variant="body1">
                                      <b> Assignment Title: </b> {d.title}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <br />
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Box className={classes.locationBox}>
                                      <Typography variant="body1">
                                        <img
                                          src={map}
                                          height="18px"
                                          alt="map"
                                        />
                                        &nbsp;
                                        {d.job_location.length > 0 ? (
                                          d.job_location.map((item, i) => (
                                            <Fragment key={i}>
                                              {i !=
                                              d.job_location.length - 1 ? (
                                                <>{item.city}, </>
                                              ) : (
                                                <>{item.city}</>
                                              )}
                                            </Fragment>
                                          ))
                                        ) : (
                                          <>Work From Home</>
                                        )}{" "}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>

                                {d.iconButtonClick == "open" ? (
                                  <>
                                    <br />
                                    <Box className={classes.greyBox}>
                                      <Typography
                                        variant="body2"
                                        style={{ paddingBottom: "10px" }}
                                      >
                                        <b>ASSIGNMENT DETAILS</b>
                                      </Typography>

                                      <Typography variant="body2">
                                        <b>Title:</b>
                                        <span style={{ color: "#255a9b" }}>
                                          &nbsp; {d.title}
                                        </span>{" "}
                                      </Typography>
                                      <Typography variant="body2">
                                        <Grid container spacing={0}>
                                          <Grid item xs={4}>
                                            {" "}
                                            <b>Assignment Description:</b>
                                          </Grid>
                                          <Grid item xs={8}>
                                            <Typography
                                              align="left"
                                              style={{
                                                color: "#255a9b",
                                                margin: "auto",
                                                padding: "auto",
                                                // display: "inline-block",
                                              }}
                                            >
                                              {d.description}
                                            </Typography>{" "}
                                          </Grid>
                                        </Grid>
                                      </Typography>
                                      <Typography variant="body2">
                                        <b>Submission Date:</b>{" "}
                                        <span style={{ color: "#255a9b" }}>
                                          &nbsp; {d.end_date}
                                        </span>{" "}
                                      </Typography>
                                      {d.assignment_sent_status !== null && (
                                        <Typography variant="body2">
                                          <b>Assignment sent to:</b>
                                          <span style={{ color: "#255a9b" }}>
                                            &nbsp;{" "}
                                            {generateCsvWithName(
                                              d.assignment_sent_status,
                                              "name"
                                            )}
                                            {/* {d.assignment_sent_status.map(
                                            (item) => item.name
                                          )} */}
                                          </span>{" "}
                                        </Typography>
                                      )}
                                      <Typography variant="body2">
                                        <b>Attachment:</b>{" "}
                                        <span style={{ color: "#255a9b" }}>
                                          &nbsp;{" "}
                                          {d.file
                                            ? d.file.split("/")[6].split("?")[0]
                                            : "null"}
                                        </span>
                                        <a
                                          href={d.file ? d.file : null}
                                          download="GFG"
                                          target="blank"
                                        >
                                          &nbsp; <img src={upload} />
                                        </a>
                                      </Typography>
                                    </Box>
                                  </>
                                ) : null}
                                {/* <Link to="/company/track">
                                <Typography
                                  variant="body2"
                                  align="right"
                                  style={{
                                    color: "#3282C4",
                                    marginTop: "12px",
                                  }}
                                >
                                  View Submitted Assignments&nbsp;{" "}
                                  <img src={arrow} alt="arrow" />
                                </Typography>
                              </Link> */}
                              </CardContent>
                            </Card>
                            <br />
                          </Fragment>
                        );
                      })
                    ) : (
                      <>
                        <img
                          src={new_i}
                          align="center"
                          style={{
                            margin: "auto",
                            height: "250px",
                            paddingLeft: "40%",
                          }}
                        />
                        <Typography align="center" variant="h6">
                          <b>No Assignments closed yet</b>
                        </Typography>
                      </>
                    )}
                  </>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    cardRoot: {
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      // position:'relative',
    },
    root: {
      fontWeight: "bolder",
      border: "1px solid #007BFF",
      color: "#007BFF",
      fontSize: "12px",
    },
    postButton: {
      background: "#007BFF",
      color: "#fff",
      padding: "10px 20px 10px 20px",
      fontWeight: "bold",
      borderRadius: "30px",
      // marginLeft: "42%",

      "&:hover": {
        borderRadius: "30px",
        background: "grey",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    UploadButton: {
      background: "#BDBDBD",
      color: "black",
      border: "1px solid black",
      padding: "8px 35px 8px 35px",
      borderRadius: "30px",

      "&:hover": {
        background: "grey",
        borderRadius: "30px",
        backdropFilter: "blur(30px)",
        boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
        border: "solid 1px #f1f1f1",
      },
    },
    iconButton: {
      borderRadius: "35px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      backgroundColor: "#ffffff",
      paddingLeft: "25px",
    },
    assignmentBox: {
      width: "100%",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      backgroundColor: "#ffffff",
      padding: "20px",
      marginTop: "2%",
      borderRadius: "30px",
    },
    assignmentBox1: {
      width: "100%",
      height: "98%",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      backgroundColor: "#ffffff",
      padding: "20px",
      borderRadius: "20px",
      overflowY: "scroll",
    },
    assignmentBox1: {
      width: "100%",
      height: "500px",

      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      backgroundColor: "#ffffff",
      padding: "20px",
      borderRadius: "20px",
      overflowY: "auto",
    },
    greyBox: {
      width: "100%",
      // boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
      backgroundColor: "#f0f8ff",
      padding: "20px",
      borderRadius: "20px",
      backdropFilter: "blur(30px)",
      boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #f1f1f1",
      backgroundColor: "#ffffff",
    },
    locationBox: {
      width: "100%",
      padding: "5px",
      fontWeight: "bolder",
      borderRadius: "40px",
      backgroundColor: "#e3f0ff",
      border: "2px solid #fff",
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
    },
    textTop: {
      paddingTop: "7%",
    },
    redstar: {
      color: "red",
    },
    BlueBox: {
      backgroundColor: "#F0FCFF",
      borderTop: "3px solid #3282C4",
      color: "black",
      padding: "12px",
      borderRadius: "20px",
    },
    BlueBodyText: {
      fontSize: "14px",
      textAlign: "center",
    },
    radio: {
      "&$checked": {
        color: "#3282C4",
      },
      "&:hover": {
        backgroundColor: "rgba(50, 130, 196, 0.04)",
      },
    },
    checked: {},
  })
);

import {
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import delete_icon from "../../../assets/common/delete.svg";

import { useDispatch, useSelector } from "react-redux";
import { getRounds } from "../../company/action";
import CssTextField from "./FormComponents/CssTextField";
import CustomizedCheckboxes from "./FormComponents/CustomizedCheckboxes";

import theme from "./FormComponents/MuiOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams } from "react-router-dom";
import HelpFunction from "./FormComponents/help";

const SelectionProcedure = (props) => {
  let { job_id } = useParams();

  const { state, setState, selectionRef, jobData } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const roundList = useSelector((state) => state.RoundList.RoundList);
  const [currentRound, setCurrentRound] = useState();
  const [otherRounds, setOtherRounds] = useState();
  const [typingRound, setTypingRound] = useState();
  const [addClicked, setAddClikced] = useState(false);
  const documents = ["Resume", "Cover Letter", "Transcript", "ID Proof (e.g. Aadhar Card, PAN Card, etc.)"];

  useEffect(() => {
    if (typingRound !== undefined && addClicked) {
      const selectRound = {
        round_id: Math.floor(100000 + Math.random() * 900000),
        r_name: typingRound,
        round_no: 1,
        is_interviewed: true,
      };

      setState({
        ...state,
        Basics: {
          ...state.Basics,
          rounds: [...state.Basics.rounds, selectRound],
        },
      });
    }
  }, [addClicked]);

  useEffect(() => {
    if (job_id && jobData && jobData !== undefined) {
      var arr = [];
      jobData &&
        jobData.job_rounds &&
        jobData.job_rounds.length > 0 &&
        jobData.job_rounds.map((item) => {
          arr.push({ name: item.r_name, id: item.round_id });
        });
      // setCurrentRound(arr);
    }
  }, [jobData]);

  useEffect(() => {
    if (roundList.length === 0) dispatch(getRounds());
  }, []);

  useEffect(() => {
    if (currentRound) {
      if (!state.Basics.rounds.map((item) => item.round_id).includes(currentRound.id)) {
        const selectRound = {
          round_id: currentRound.id,
          r_name: currentRound.name,
          round_no: 1,
          is_interviewed: true,
        };

        setState({
          ...state,
          Basics: {
            ...state.Basics,
            rounds: [...state.Basics.rounds, selectRound],
          },
        });
      }
    }
  }, [currentRound]);

  const removeLocation = (location) => {
    if (state.Basics.rounds.length > 0 && state.Basics.rounds.includes(location)) {
      let temp = state.Basics.rounds.filter((loc) => loc.round_id !== location.round_id);
      setState({
        ...state,
        Basics: {
          ...state.Basics,
          rounds: temp,
        },
      });
    }
  };

  const UpdateDocuments = (event) => {
    const document = event.target.name;
    if (state.Details.documents.includes(document)) {
      let temp = state.Details.documents.filter((doc) => doc !== document);
      setState({
        ...state,
        Details: {
          ...state.Details,
          documents: temp,
        },
      });
    } else {
      setState({
        ...state,
        Details: {
          ...state.Details,
          documents: [...state.Details.documents, document],
        },
      });
    }
  };

  const Round_s = () => (
    <Autocomplete
      value={currentRound}
      onChange={(event, newValue) => {
        if (newValue) setCurrentRound(newValue);
        else setCurrentRound();
      }}
      id="round"
      options={roundList}
      className={classes.InputWidth}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => {
        return <span style={{ fontSize: 12 }}>{`${option.name}`}</span>;
      }}
      renderInput={(params) => {
        params.inputProps.className = classes.comboOptions;
        return <CssTextField {...params} label={<span className={classes.labelSize}>Choose Rounds</span>} variant="outlined" />;
      }}
    />
  );

  return (
    <>
      {" "}
      <div style={{ marginTop: "50px" }} ref={selectionRef}>
        <h5 className={classes.heading}>Selection Procedure</h5>
        <Divider />

        <Grid container style={{ marginTop: "30px" }}>
          <Grid item xs={3} className={classes.section}>
            <p className={classes.formLabel}>
              Rounds<span className={classes.RedStar}>*</span>
            </p>
          </Grid>
          <Grid item xs={9} className={classes.section}>
            {!otherRounds ? (
              <Round_s />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={8}>
                    <CssTextField
                      id="outlined-name"
                      label={<span className={classes.labelSize}>Round</span>}
                      variant="outlined"
                      // className={classes.InputWidth}
                      style={{ width: "90%" }}
                      value={typingRound}
                      InputProps={{
                        style: { fontSize: 13 },
                      }}
                      onChange={(e) => {
                        setAddClikced(false);
                        setTypingRound(e.target.value);
                      }}
                    />{" "}
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.preferenceButton}
                      onClick={() => {
                        setAddClikced(true);
                      }}
                      disabled={typingRound ? false : true}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            <FormControlLabel
              value={otherRounds}
              style={{ marginLeft: 0 }}
              control={
                <CustomizedCheckboxes
                  checked={otherRounds}
                  onChange={(e) => {
                    setOtherRounds(e.target.checked);
                  }}
                  name={"allow_remote"}
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                  color="primary"
                />
              }
              label={
                <span className={classes.labelSize}>
                  Others <HelpFunction title={"If your round is not mentioned in the list. Click here to type your own"} />
                </span>
              }
            />
          </Grid>
          {state.Basics.rounds.length > 0 && (
            <TableContainer
              style={{
                // maxHeight: "400px",
                WebkitBackdropFilter: "blur(30px)",
                backdropFilter: "blur(30px)",
                boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                border: "1px solid rgba(152, 152, 152, 0.27)",
                marginBottom: "25px",
                borderRadius: 30,
              }}
            >
              <Table stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="center">S. No.</TableCell> */}
                    <StyledTableCell style={{ fontSize: 13 }}>Round No.</StyledTableCell>
                    <StyledTableCell style={{ fontSize: 13 }}>Round Type</StyledTableCell>
                    {localStorage.getItem("user_type") === "Company" && <StyledTableCell style={{ fontSize: 13 }}>Interview Conduction</StyledTableCell>}
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.Basics.rounds.map((item, index) => (
                    <>
                      <TableRow>
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <StyledTableCell>Round {index + 1}</StyledTableCell>
                        <StyledTableCell>{item.r_name}</StyledTableCell>
                        {localStorage.getItem("user_type") === "Company" && (
                          <StyledTableCell>
                            {" "}
                            <FormControlLabel
                              control={
                                <CustomizedCheckboxes
                                  checked={item.is_interviewed}
                                  onChange={(e) => {
                                    var arr = [...state.Basics.rounds];
                                    arr[index].is_interviewed = e.target.checked;
                                    setState({
                                      ...state,
                                      Basics: {
                                        ...state.Basics,
                                        rounds: arr,
                                      },
                                    });
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label={<span className={classes.labelSize}>Interview</span>}
                            />
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          <IconButton
                            className={classes.margin}
                            aria-label="delete"
                            onClick={() => {
                              removeLocation(item);
                            }}
                          >
                            <img height="40px" src={delete_icon} />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Grid item xs={3} className={classes.section}>
            <p className={classes.formLabel}>
              Documents Required
              <span className={classes.RedStar}>*</span>
            </p>
          </Grid>
          <Grid item xs={9} className={classes.section}>
            <FormGroup>
              {documents.map((item) => (
                <FormControlLabel
                  value={item}
                  control={
                    <CustomizedCheckboxes
                      checked={state.Details.documents.includes(item)}
                      onChange={UpdateDocuments}
                      name={item}
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                      color="primary"
                    />
                  }
                  label={<span className={classes.labelSize}>{item}</span>}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formLabel: {
      fontSize: "13px",
      fontWeight: "bold",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    comboOptions: {
      fontSize: "13px",
      padding: "6.5px 11px",
    },
    RedStar: {
      color: "red",
      fontWeight: "bold",
      marginLeft: "2px",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "bolder",
      color: theme.palette.type === "dark" ? "#fff" : "black",
    },
    section: {
      marginBottom: "20px",
    },
    roundTable: {
      fontSize: "13px",
    },
    InputWidth: {
      width: "70%",
    },
    subTitle: {
      fontSize: "11px",
      color: "grey",
      width: "70%",
      margin: "6px 0 0 10px",
    },
    labelSize: {
      fontSize: "12px",
    },
    preferenceButton: {
      borderRadius: 30,
      "&:hover": {
        border: "2px solid #3282C4",
        background: "white",
        color: "#3282C4",
      },
    },
    "@media only screen and (max-width: 900px)": {
      InputWidth: {
        width: "100%",
      },
      formLabel: {
        fontSize: "11px",
        fontWeight: "bold",
        paddingTop: "10px",
      },
      roundTable: {
        fontSize: "11px",
      },
    },
    "@media only screen and (max-width: 1100px)": {
      labelSize: {
        fontSize: "11px",
      },
    },

    MuiOutlinedInput: {
      root: {
        height: "auto",

        // border: "1px solid #ced4da",
        // boxShadow: "0px 0px 3px 0px rgb(206 212 218)",
      },
    },
  })
);

const StyledTableCell = withStyles(() => ({
  // head: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  body: {
    fontSize: 12,
    padding: "7px 16px 5px 16px",
  },
  "@media only screen and (max-width: 900px)": {
    body: {
      fontSize: 12,
    },
  },
}))(TableCell);

export default SelectionProcedure;

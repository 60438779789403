import { Paper, Typography, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CompanySlider from "./CompanySlider";

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 8px 4px #0000001A",
    borderRadius: "12px",
    padding: "17px",
    /*  padding: "30px", */

    // display: "flex",
    // alignItems: "center",
  },
  smallgrid: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-40px",
    },
  },
  heading: {
    color: "#828282",
    textAlign: "center",
    // width: "300px",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      textAlign: "center",
    },
  },
  textgrid: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

function CompanyIdeal() {
  const classes = useStyles();
  return (
    <>
      <Paper
        style={{
          backgroundColor: "#fff",
          position: "relative",
          marginBottom: "-12px",
          marginLeft: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
          boxShadow: "none",
          width: "fit-content",
        }}
      >
        <Typography className={classes.heading} component="h2">
          Trusted by the world's leading companies
        </Typography>
      </Paper>
      <Paper className={classes.paper}>
        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          {" "}
          <CompanySlider />{" "}
        </div>
      </Paper>
    </>
  );
}

export default CompanyIdeal;

import {
  createStyles, Grid,
  makeStyles
} from "@material-ui/core";
import Axios from "axios";
import React from "react";
import { Breakpoint, BreakpointProvider } from "react-socks";
import {
  BackendBaseApi, MENTORKART_ENDPOINT
} from "../../../constants/constants";
import "./topDescription.css";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay , Navigation, Pagination } from "swiper";

import "swiper/swiper.scss";
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css'



const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      width: "100%",
      height: "257px",
      borderRadius: "10px",
      "&:hover":{
        cursor: "pointer"
      }
    },
    image_mob: {
      width: "100%",
      height: "127px",
      borderRadius: "10px",
      "&:hover":{
        cursor: "pointer"
      }
    },
  })
);

export default function TopDescriptionSection({ banner_image, ctaUrl, banner_list }) {
  const classes = useStyles();
  SwiperCore.use([Autoplay, Pagination, Navigation]);

  const checkforSSO = () => {
    Axios.get(BackendBaseApi.PRAVESH + "api/sso/mentorkart/token", {
      headers: {
        Authorization: "Token " + localStorage.getItem("gw_token"),
      },
    })
      .then((res) => {
        if (res?.data?.success){
          window.open(MENTORKART_ENDPOINT + "/auth?SSO_Mtoken=" + res.data.data.token + "&utm_source=Getwork_Portal%20&utm_medium=web&utm_campaign=SMP&utm_content=download");
        }
      })
      .catch((err) => {
      });
  };


  return (
    <BreakpointProvider>
      <Breakpoint large up>
        <div className="top-des" style={{ paddingTop: "0px", paddingBottom: '0px' }}>
          <Grid container justify="center">
            {banner_list.length > 0 && (<div className="swiper-wrapper" style={{paddingTop: "0px"}}>
              <Swiper
                  moduled={[Navigation, Pagination]}
                  spaceBetween={20}
                  slidesPerView={1}
                  pagination={{clickable:true}}
                  scrollbar={{ draggable: true }}
                  loop={true}
                  autoplay={{ delay: 3000 }}
                  style={{paddingTop: '0px', marginTop:'0px'}}
              >
                {banner_list.map((item)=>{
                  return (
                    <SwiperSlide>
                    <div className="swiper-slide" data-aos="fade-right">
                      <div
                          className="card-body" style={{paddingTop:'0px'}}
                      >
                        <div className="top-des__introduction" onClick={ () => window.open(item?.banner_url, '_newtab')}>
                          <img className={classes.image} src={item?.banner_path} />
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </SwiperSlide>)
                })}

                {/*<SwiperSlide>*/}
                {/*  <div className="swiper-slide" data-aos="fade-right">*/}
                {/*    <div*/}
                {/*        className="card-body"*/}
                {/*    >*/}
                {/*      <div className="top-des__introduction" onClick={ () => window.open(ctaUrl, '_newtab')}>*/}
                {/*        <img className={classes.image} src={banner_image} />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    /!* </div> *!/*/}
                {/*  </div>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*  <div className="swiper-slide" data-aos="fade-right">*/}
                {/*    <div*/}
                {/*        className="card-body"*/}
                {/*    >*/}
                {/*      <div className="top-des__introduction" onClick={ () => window.open(ctaUrl, '_newtab')}>*/}
                {/*        <img className={classes.image} src={banner_image} />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    /!* </div> *!/*/}
                {/*  </div>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*  <div className="swiper-slide" data-aos="fade-right">*/}
                {/*    <div*/}
                {/*        className="card-body"*/}
                {/*    >*/}
                {/*      <div className="top-des__introduction" onClick={ () => window.open(ctaUrl, '_newtab')}>*/}
                {/*        <img className={classes.image} src={banner_image} />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    /!* </div> *!/*/}
                {/*  </div>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*  <div className="swiper-slide" data-aos="fade-right">*/}
                {/*    <div*/}
                {/*        className="card-body"*/}
                {/*    >*/}
                {/*      <div className="top-des__introduction" onClick={ () => window.open(ctaUrl, '_newtab')}>*/}
                {/*        <img className={classes.image} src={banner_image} />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    /!* </div> *!/*/}
                {/*  </div>*/}
                {/*</SwiperSlide>*/}
                ...
              </Swiper>
            </div>)}


              {/*<Swiper*/}
              {/*    spaceBetween={20}*/}
              {/*    slidesPerView={3}*/}
              {/*    navigation*/}
              {/*    pagination={{ clickable: true }}*/}
              {/*    scrollbar={{ draggable: true }}*/}
              {/*    autoplay={{ delay: 10 }}*/}
              {/*>*/}
              {/*    <SwiperSlide className='testimonial'>*/}
              {/*        <div className="top-des__introduction" onClick={ () => window.open(ctaUrl, '_newtab')}>*/}
              {/*            <img className={classes.image} src={banner_image} />*/}
              {/*        </div>*/}
              {/*    </SwiperSlide>*/}
              {/*    <SwiperSlide className='testimonial'>*/}
              {/*        <div className="top-des__introduction" onClick={ () => window.open(ctaUrl, '_newtab')}>*/}
              {/*            <img className={classes.image} src={banner_image} />*/}
              {/*        </div>*/}
              {/*    </SwiperSlide>*/}
              {/*    <SwiperSlide className='testimonial'>*/}
              {/*        <div className="top-des__introduction" onClick={ () => window.open(ctaUrl, '_newtab')}>*/}
              {/*            <img className={classes.image} src={banner_image} />*/}
              {/*        </div>*/}
              {/*    </SwiperSlide>*/}
              {/*</Swiper>*/}

            {/*<Grid xs={12} style={{ marginTop: "0px" }}>*/}

              {/*<Carousel>*/}
              {/*  <Carousel.item>*/}

                {/*</Carousel.item>*/}
              {/*</Carousel>*/}


            {/*</Grid>*/}
          </Grid>
        </div>
      </Breakpoint>
      <Breakpoint medium down>
        <div className="top-des">
          <Grid container>
            <Grid xs={12} style={{ marginTop: "0px" }}>

              <div className="swiper-wrapper">
                <Swiper
                    moduled={[Navigation, Pagination]}
                    // spaceBetween={10}
                    slidesPerView={1}
                    pagination={{clickable:true}}
                    scrollbar={{ draggable: true }}
                    loop={true}
                    autoplay={{ delay: 3000 }}
                >
                  {banner_list.map((item)=>{
                    return (
                        <SwiperSlide className='special'>
                          <div className="swiper-slide" data-aos="fade-right">
                            <div className="card-body" style={{width: '100%'}}>
                              <div className="top-des__introduction" onClick={ () => window.open(item?.banner_url, '_newtab')}>
                                <img className={classes.image_mob}  src={item?.banner_path} />
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>


              {/*<div className="top-des__introduction" onClick={ () => window.open(ctaUrl, '_newtab')}>*/}
              {/*  <img className={classes.image_mob} src={banner_image} />*/}
              {/*</div>*/}
            </Grid>
          </Grid>
        </div>
      </Breakpoint>
    </BreakpointProvider>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  overview: {
    marginBottom: "15px",
  },

  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function SaveEducationNew({ data }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.overview}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "4px",
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontWeight: "600",
            }}
          >
            {data?.highest_qualification}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
              color: "#6C757D",
            }}
          >
            {data?.timePeriod}
          </Typography>
        </div>
        <Typography
          variant="body2"
          style={{
            fontWeight: "600",
            marginBottom: "4px",
            color: "#6C757D",
          }}
        >
          {data?.college_name}
        </Typography>

        <Typography
          variant="body2"
          style={{
            marginBottom: "4px",
            fontWeight: "400",
            color: "#6C757D",
          }}
        >
          {data?.degreeSpec}{" "}
          {data?.grade && `${data?.degreeSpec && `, `} ${data?.grade}`}
        </Typography>
      </div>
    </>
  );
}

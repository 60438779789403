import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DegreeCourses from "../../../common/DegreeSpecializationCollege/Index";
import ConfirmationDialog from "../../../GlobalComponents/ConfirmationDialog/ConfirmationDialog";
import { useSelector, useDispatch } from "react-redux";
import CommonButton from "../../../components/common/CommonButton/CommonButton";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { open, handleClose, handleClickOpen, data, setData, loading, setLoading, degreeCoursesComing, setDegreeCoursesComing, allData, setAllData, handleSubmit, item } = props;
  const dispatch = useDispatch();
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const [specializationNew, setSpecializationNew] = useState({});
  const creditInfo = useSelector((state) => state.CreditInfo.creditInfo);
  const [newItem, setNewItem] = useState();
  useEffect(() => {
    if (item !== undefined) {
      setNewItem(item);
    }
  }, [item]);
  useEffect(() => {
    let temp = {};
    let temp2 = {};
    let arr = [...allData];
    arr.forEach((item) => {
      temp2[item.degree_id] = item.specialization;
    });
    setSpecializationNew(temp2);
  }, [allData]);

  return (
    <div>
      <Dialog
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="100%"
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Select Degrees and Courses
        </DialogTitle>
        <DialogContent dividers>
          <DegreeCourses
            data={data}
            setData={setData}
            width={"90%"}
            loading={loading}
            setLoading={setLoading}
            degreeCoursesComing={degreeCoursesComing}
            setDegreeCoursesComing={setDegreeCoursesComing}
            allData={allData}
            setAllData={setAllData}
            specializationNew={specializationNew}
          />
        </DialogContent>
        <DialogActions>
          <CommonButton
            onClick={() => {
              setConfirmationDialog(true);
            }}
            disabled={data.length > 0 ? false : true}
          >
            Apply
          </CommonButton>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={confirmationDialog}
        setOpen={setConfirmationDialog}
        poster={handleSubmit}
        posterArg={newItem}
        creditsRequired={creditInfo?.user_service?.find((item) => item.service_type === "RECOMMENDED_JOB_ACCEPTANCE")?.credits}
        serviceType={creditInfo?.user_service?.find((item) => item.service_type === "RECOMMENDED_JOB_ACCEPTANCE")?.service_type}
      />
    </div>
  );
}

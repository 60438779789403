import React, { Fragment } from 'react';
import './JobOverview.css';
// import { Button } from '@material-ui/core';
import CommonButton from '../CommonButton/CommonButton';
import WorkIcon from '@material-ui/icons/Work';
import success from '../../../assets/success.gif';
import RatingExperience from './RatingExperience';

const divStyle = {
    display:'flex', 
    justifyContent:'center',
    alignItems:'center',
    flexWrap:'wrap',
    padding: '8px',
}
const spanStyle = {
    display:'flex', 
    justifyContent:'center',
    textAlign:'center',
    flexWrap:'wrap',
    width:'100%',
    fontSize:'16px',
}

function JobSuccessPage({ alert, jobData }) {

    return (
        <div id="success_box" style={divStyle}>
            <br/><br/>
            <span style={spanStyle}>
                Thank You !!
            </span>
            <br/><br/>
            <span style={spanStyle}>
                Your Application for <b>&nbsp;{jobData.job_title}&nbsp;</b> at 
                <b>&nbsp;{jobData.company_name}&nbsp;</b> has been submitted successfully.
            </span>     
            <br/><br/>
            <span style={spanStyle}>
                <img 
                    src={success}
                    height='110px'
                    width='120px'
                    alt="gif"
                />
            </span>
            <br/><br/>
            <RatingExperience 
                alert={alert}
                jobID={jobData.job_id}
            />
            <br/><br/>
            <span style={spanStyle}>
                Check out similar awesome opportunities.
            </span>
            <br/><br/>
            <CommonButton 
                variant="contained"
                color="primary"
                href="/public/open-jobs"
                style={{borderRadius:'2rem'}}
                startIcon={<WorkIcon />}
            >
            Similar Jobs
            </CommonButton>
            <br/>
        </div>
    )
}

export default JobSuccessPage
import { ThunkAction } from "redux-thunk";
import Axios, { AxiosPromise } from "axios";
import { TReduxStore } from "../../..";
import { BackendBaseApi } from "../../../constants/constants";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_All_DETAILS = "GET_All_DETAILS";

export interface TAction {
  type: string;
  data?: any;
  additionalDispatchData?: any;
  payload?: any;
}

export const getUserDetails = (
  history: any,
  alert: any,
  user_type_id: any,
  setLoading?: any
): ThunkAction<AxiosPromise, TReduxStore, {}, TAction> => {
  if (setLoading) {
    setLoading(true);
  }
  return async (dispatch) => {
    const res = await Axios.get(
      BackendBaseApi.PRAVESH + "api/shared/user/?user_type=" + user_type_id,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        alert.error("Your Session has been Expired. Please Login Again");
        history.push("/login");
      }
      if (setLoading) {
        setLoading(false);
      }
      throw error;
    });

    localStorage.setItem(
      "logo",
      res.data.data?.college_user_details?.college_logo
    );
    localStorage.setItem("profile_pic", res.data.data.profile_picture);
    localStorage.setItem(
      "user_details",
      JSON.stringify(res.data.data.college_user_details)
    );
    localStorage.setItem("all_details", JSON.stringify(res.data.data));
    localStorage.setItem(
      "college_id",
      res.data.data.college_user_details?.college_id
    );
    localStorage.setItem("user_id", res.data.data.id);

    dispatch({
      type: GET_USER_DETAILS,
      payload: res.data.data.college_user_details,
    });
    dispatch({
      type: GET_All_DETAILS,
      payload: res.data.data,
    });
    if (setLoading) {
      setLoading(false);
    }
    return res;
  };
};

//@ts-nocheck
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { BackendBaseApi } from "../../../constants/constants";
import kFormater from "../../../utils/ZeroToK";
import ViewJob from "../viewjob/Viewjob";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import {
  NewJobCardProps,
  ApplyButtonProps,
  AcceptButtonProps,
  RejectButtonProps,
  SaveIconProps,
  RejectAcceptButton,
  JobListProps,
} from "../../../pages/college/NewJob/@types";
import ConfirmationDialog from "../../../GlobalComponents/ConfirmationDialog/ConfirmationDialog";
import { useSelector, useDispatch } from "react-redux";
import { updateCreditInfo } from "../../../GlobalComponents/CreditBoard/action";
import { TReduxStore } from "../../..";
import { CreditInfoProps } from "../../../GlobalComponents/CreditBoard/reducer";
import JobSharerDialog from "../../../components/common/JobSharer/JobSharerDialog";
import JobSharerButton from "../../../components/common/JobSharer/JobSharerButton";
import { jobSharerDetail, valueLabelFormat } from "../../../utils/utility";
import CommonChip from "../../CommonChip/CommonChip";
import CommonButton from "../../common/CommonButton/CommonButton";
import EditIcon from "@material-ui/icons/Edit";
import CommonIconButton from "../../common/CommonButton/CommonIconButton";
import BootstrapTooltip from "../../../common/Tooltip/Tooltip";
import { Link, useHistory } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import edit_icon from "../../../assets/common/edit_jobs.svg";
import clone_icon from "../../../assets/common/clone.png";
import logo from "../../../assets/company/png/de.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root1: {
      fontWeight: "bolder",
      border: "2px solid #26a95b",
      color: "#26a95b",
    },
    root: {
      fontWeight: "bolder",
      border:
        theme.palette.type === "dark"
          ? "2px solid #CDEDF6"
          : "2px solid #3282C4",
      color: theme.palette.type === "dark" ? "#CDEDF6" : "#3282C4",
    },
    card: {
      boxShadow:
        theme.palette.type === "dark" ? theme.shadows[0] : theme.shadows[2],
      padding: "1% 2%",
      backgroundColor: theme.palette.background.paper,
      marginBottom: "2%",
      borderRadius: "30px",
      cursor: "pointer",
      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "none",
    },
    innerCardBox: {
      display: "flex",
      padding: "6px",
      justifyContent: "space-between",
      border:
        theme.palette.type === "dark"
          ? "1px solid rgba(255, 255, 255, 0.12)"
          : "1px solid #d3d3d3",
      borderRadius: "25px",
    },
  })
);

const ApplyButton = (Props: ApplyButtonProps) => {
  const { jobId, reject } = Props;
  const alert = useAlert();
  const [done, setDone] = useState(false);

  const handelActive = async (jobId: number) => {
    const data =
      localStorage.getItem("user_details") !== undefined
        ? JSON.parse(localStorage.getItem("user_details")!)
        : {};

    const res = await Axios({
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url: `${BackendBaseApi.NIYUKTI}/job/student/apply/job_id=${jobId}&user_id=${data.id}&round=1&status=1&feedback=1`,
      // body: `job_id=${jobId}&user_id=${data.id}&round=1&status=1&feedback=1`,
    });
    alert.success(res.data.data.message);

    setDone(true);
  };
  return (
    <Button
      variant="contained"
      style={{ backgroundColor: "#3282C4", borderRadius: "30px" }}
      className="text-white fw-700"
      disabled={done}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
    >
      {reject ? (done ? "Accepted" : "Accept") : done ? "Applied" : "Apply"}
    </Button>
  );
};

const AcceptButton = ({
  id,
  reject,
  setIsReject,
  companyId,
  jobId,
  queryParamsObj,
  GetData,
  setLoading,
  setJobData,
  confirmationDialog,
  setConfirmationDialog,
  setJobSharer,
}: AcceptButtonProps) => {
  const alert = useAlert();
  const data = queryParamsObj.find((item: any) => item.id === id);
  const creditInfo = useSelector<TReduxStore, CreditInfoProps>(
    (state) =>
      //@ts-ignore
      state.CreditInfo.creditInfo
  );
  const dispatch = useDispatch();

  const [done, setDone] = useState(false);

  const handelActive = async (jobId: number) => {
    setLoading(true);
    const res = await Axios({
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      url: BackendBaseApi.NIYUKTI + "job/college/invite/",
      data: {
        college_id: data.college_id,
        company_id: data.company_id,
        id: data.id,
        is_approved: true,
        is_rejected: false,
        user_type_id: localStorage.getItem("user_type_id"),
        user_id: localStorage.getItem("user_id"),
        service_type: "JOB_ACCEPTANCE",
        amount: creditInfo?.user_service?.find(
          (item) => item.service_type === "JOB_ACCEPTANCE"
        )?.credits,
        transaction_type: "DEBIT",
      },
    }).catch((err) => {
      alert.error("Error occured");
      setLoading(false);
      throw err;
    });

    if (res.data.success) {
      alert.success(res.data.data.message);
      dispatch(updateCreditInfo(res.data.data.transaction_data));
      setJobSharer(true);
    }

    const res2 = await Axios({
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      url: `${BackendBaseApi.NIYUKTI}job/college/invite/?college_id=${btoa(
        JSON.parse(localStorage.getItem("user_details")!).college_id
      )}&job_status=TkVX`,
    }).catch((err) => {
      setLoading(false);
      throw err;
    });
    setJobData(res2.data.data.results);
    setLoading(false);
    // GetData(
    //   BackendBaseApi.NIYUKTI,
    //   `job/college/invite/?college_id=${btoa(data.college_id)}&job_status=TkVX`,
    //   {
    //     headers: { Authorization: `Token ${localStorage.getItem("gw_token")}` },
    //   }
    // );
    setConfirmationDialog(false);

    setDone(true);
    setIsReject(true);
  };
  return (
    <>
      <CommonButton
        variant="contained"
        disabled={done}
        onClick={(event) => {
          event.stopPropagation();
          //handelActive(jobId);
          setConfirmationDialog(true);
        }}
      >
        {reject ? (done ? "Accepted" : "Accept") : done ? "Applied" : "Apply"}
      </CommonButton>
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          setOpen={setConfirmationDialog}
          poster={handelActive}
          posterArg={jobId}
          creditsRequired={
            creditInfo?.user_service?.find(
              (item) => item.service_type === "JOB_ACCEPTANCE"
            )?.credits
          }
          serviceType={
            creditInfo?.user_service?.find(
              (item) => item.service_type === "JOB_ACCEPTANCE"
            )?.service_type
          }
        />
      )}
    </>
  );
};

const RejectButton = ({
  id,
  jobId,
  isReject,
  companyId,
  queryParamsObj,
  GetData,
  setLoading,
}: RejectButtonProps) => {
  const alert = useAlert();
  const data = queryParamsObj.find((item: any) => item.id === id);

  const [done, setDone] = useState(false);

  const handelActive = async (jobId: number) => {
    const res = await Axios({
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
      url: BackendBaseApi.NIYUKTI + "job/college/invite/",
      data: {
        college_id: data.college_id,
        company_id: data.company_id,
        id: data.id,
        is_approved: false,
        is_rejected: true,
        user_id: data.user_id,
      },
    }).catch((err) => {
      alert.error("Error occured");
      setLoading(false);
      throw err;
    });
    alert.success(res.data.data.message);
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/college/invite/?college_id=${btoa(
        JSON.parse(localStorage.getItem("user_details")!).college_id
      )}&job_status=TkVX`
    );

    setDone(true);
  };
  return !isReject ? (
    <CommonButton
      variant="outlined"
      disabled={done}
      onClick={(event) => {
        event.stopPropagation();
        handelActive(jobId);
      }}
      style={{ marginRight: 10 }}
    >
      {done ? "Rejected" : "Reject"}
    </CommonButton>
  ) : null;
};

const SaveIcon = ({ jobId, updateData, isSave }: SaveIconProps) => {
  const data =
    localStorage.getItem("user_details") !== undefined
      ? JSON.parse(localStorage.getItem("user_details")!)
      : {};
  const [done, setDone] = useState(isSave);
  const handelActive = async (jobId: number) => {
    if (done) {
      const res = await Axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${BackendBaseApi.NIYUKTI}job/student/status/job_id=${jobId}&user_id=${data.id}&status=unsave`,
        //body: `job_id=${jobId}&user_id=${data.id}&status=unsave`,
      }).catch((err) => {
        throw err;
      });
      alert(res.data.data.message);
      setDone(!done);
    } else {
      const res2 = await Axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${BackendBaseApi.NIYUKTI}job/student/status/job_id=${jobId}&user_id=${data.id}&status=saved`,
        //body: `job_id=${jobId}&user_id=${data.id}&status=saved`,
      }).catch((err) => {
        throw err;
      });
      alert(res2.data.data.message);
      setDone(!done);
    }
  };
  return (
    <>
      {done ? (
        <>
          {" "}
          <div className="flex align-item-center">
            {" "}
            <BookmarkIcon
              className="pointer text-blue mg-right-5"
              onClick={(event) => {
                event.stopPropagation();
                handelActive(jobId);
              }}
            />
            <span className="fs-12 text-blue mr-2">Saved</span>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="flex align-item-center">
            {" "}
            <BookmarkBorderIcon
              className="pointer text-blue mg-right-5"
              onClick={(event) => {
                event.stopPropagation();
                handelActive(jobId);
              }}
            />
            <span className="fs-12 text-blue mr-2">Save</span>
          </div>
        </>
      )}
    </>
  );
};
const HiddenIcon = ({
  jobId,
  hideStatus,
}: {
  jobId: number;
  hideStatus: boolean;
}) => {
  const [done, setDone] = useState(hideStatus);
  const handelActive = async (jobId: number) => {
    const data =
      localStorage.getItem("user_details") !== undefined
        ? JSON.parse(localStorage.getItem("user_details")!)
        : {};

    if (hideStatus) {
      const res = await Axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${BackendBaseApi.NIYUKTI}job/student/status/job_id=${jobId}&user_id=${data.id}&status=unhide`,
        //body: `job_id=${jobId}&user_id=${data.id}&status=unhide`,
      }).catch((err) => {
        throw err;
      });
      alert(res.data.data.message);
      setDone(false);
    } else {
      const res2 = await Axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${BackendBaseApi.NIYUKTI}job/student/status/job_id=${jobId}&user_id=${data.id}&status=hidden`,
        //body: `job_id=${jobId}&user_id=${data.id}&status=hidden`,
      }).catch((err) => {
        throw err;
      });
      alert(res2.data.data.message);
      setDone(true);
    }
  };

  return (
    <>
      {done ? (
        <>
          <div className="flex align-item-center">
            <VisibilityOffIcon
              className="pointer text-blue mg-right-5"
              onClick={(event) => {
                event.stopPropagation();
                handelActive(jobId);
              }}
            />
            <span className="fs-12 text-blue mr-2">hidden</span>
          </div>{" "}
        </>
      ) : (
        <>
          <div className="flex align-item-center">
            <VisibilityIcon
              className="pointer text-blue mg-right-5"
              onClick={(event) => {
                event.stopPropagation();
                handelActive(jobId);
              }}
            />
            <span className="fs-12 text-blue mr-2">hide</span>
          </div>
        </>
      )}
    </>
  );
};

const RejectAccept = ({
  jobId,
  reject,
  interviewId,
  companyId,
  id,
  queryParamsObj,
  GetData,
  setLoading,
  setJobData,
  confirmationDialog,
  setConfirmationDialog,
  setOpenJobSharer,
  setSingleJobData,
  jobData,
}: RejectAcceptButton) => {
  const [isReject, setIsReject] = useState(false);

  useEffect(() => {
    setSingleJobData(jobData);
  }, []);
  return (
    <>
      {!isReject && (
        <RejectButton
          companyId={companyId}
          id={id}
          interviewId={interviewId}
          reject={reject}
          jobId={jobId}
          queryParamsObj={queryParamsObj}
          GetData={GetData}
          setLoading={setLoading}
          isReject={isReject}
        />
      )}
      <AcceptButton
        setIsReject={setIsReject}
        companyId={companyId}
        id={id}
        interviewId={interviewId}
        reject={reject}
        jobId={jobId}
        queryParamsObj={queryParamsObj}
        GetData={GetData}
        setLoading={setLoading}
        setJobData={setJobData}
        //@ts-ignore
        confirmationDialog={confirmationDialog}
        setConfirmationDialog={setConfirmationDialog}
        setJobSharer={setOpenJobSharer}
      />
    </>
  );
};

const NewJobCard = ({
  data,
  applied,
  interview,
  reject,
  fullView,
  all,
  hideStatus,
  saveJob,
  queryParamsObj,
  GetData,
  setLoading,
  setJobData,
  confirmationDialog,
  setConfirmationDialog,
  isEditable,
  draftedJob,
  jobIsClosed,
}: NewJobCardProps) => {
  const [open, handleClose] = useState(false);
  const [singleJobData, setSingleJobData] = useState<any>([]);
  const [openJobSharer, setOpenJobSharer] = useState<boolean>(false);
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const alert = useAlert();
  const openJob = (item: JobListProps) => {
    setSingleJobData(item);
    handleClose(!open);
  };
  const callback = (value: any) => {
    handleClose(value);
  };

  return (
    <div>
      {data.length > 0 ? (
        <Box padding="2% 2%">
          <Grid container>
            {data.map((item, index) => (
              <Grid item xs={12} sm={12}>
                <Box
                  className={classes.card}
                  // style={{ borderLeft: "5px solid #3282c4" }}
                  onClick={() => {
                    openJob(item);
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Grid container>
                        <Grid item xs={1} sm={1}>
                          <Avatar
                            //@ts-ignore
                            src={
                              item?.job?.company?.company_website
                                ? `//logo.clearbit.com/${item?.job?.company?.company_website}`
                                : logo
                            }
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: 30,
                            }}
                            alt="company-picture"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={
                            isEditable && item.job.is_posted_by_college ? 4 : 5
                          }
                          sm={
                            isEditable && item.job.is_posted_by_college ? 4 : 5
                          }
                        >
                          <Typography variant="h4" style={{ fontSize: 18 }}>
                            {item.job.job_title}
                          </Typography>

                          <Typography variant="body1">
                            {item.job.company
                              ? item.job.company.company_name
                              : item.job.company_details}
                          </Typography>

                          <Typography
                            variant="subtitle1"
                            style={{ color: "grey" }}
                          >
                            {item.job.company
                              ? item.job.company.company_location
                              : "Company Location NA"}
                            &nbsp;&middot;&nbsp;
                            {item.job.company ? (
                              item.job.company.company_size + " " + "employess"
                            ) : (
                              <>Company Size NA</>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          {item.job.up_coming_state.code === 1 &&
                            item.job.job_status !== "CLOSE" && (
                              <CommonChip label={"UPCOMING JOB"} />
                            )}
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          {item.job.is_posted_by_college === true && (
                            <CommonChip label={"POSTED BY YOU"} />
                          )}
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <CommonChip
                            label={item.job.hiring_type + " HIRING"}
                          />

                          {applied && (
                            <div>
                              {item.job.applicant_status_name}
                              <ChevronRightIcon />
                            </div>
                          )}

                          {/* </Box> */}
                        </Grid>
                        {isEditable && item.job.is_posted_by_college && (
                          <Grid item xs={1} sm={1}>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <BootstrapTooltip title="Clone this Job">
                                <img
                                  src={clone_icon}
                                  height="20px"
                                  alt=""
                                  style={{ color: "#007bff", fontSize: 18 }}
                                  onClick={() => {
                                    //@ts-ignore
                                    history.push({
                                      pathname:
                                        "/college/post-job/" + item.job.job_id,
                                      flag: 1979,
                                    });
                                  }}
                                />
                              </BootstrapTooltip>
                              <BootstrapTooltip title="Edit this Job">
                                <img
                                  src={edit_icon}
                                  height="22px"
                                  alt=""
                                  //@ts-ignore
                                  style={
                                    item?.job?.is_editable
                                      ? { color: "#007bff", fontSize: 18 }
                                      : { color: "grey", fontSize: 18 }
                                  }
                                  onClick={(e) => {
                                    if (
                                      //@ts-ignore
                                      item?.job?.is_editable
                                    ) {
                                      //@ts-ignore
                                      history.push({
                                        pathname:
                                          "/college/post-job/" +
                                          item.job.job_id,
                                        flag: 1976,
                                      });
                                    } else {
                                      e.stopPropagation();

                                      alert.error(
                                        "Applicants have already applied for this job"
                                      );
                                    }
                                  }}
                                />
                              </BootstrapTooltip>
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.innerCardBox}>
                        <Box display="flex" alignItems="center">
                          <>
                            <Typography variant="subtitle1">
                              {item.job.job_type_name}

                              {item.job.job_type === 1
                                ? item.job.stipend
                                  ? " (₹ " +
                                    valueLabelFormat(item.job.stipend) +
                                    " per month)"
                                  : " (Unpaid)"
                                : null}
                            </Typography>
                            {item.job.job_type !== 1 ? (
                              <>
                                {item.job.ctc_min !== 0 &&
                                item.job.ctc_max !== 0 &&
                                item.job.salary_type === "PAID" ? (
                                  <>
                                    &nbsp;&middot;&nbsp;
                                    <Typography
                                      variant="subtitle1"
                                      style={{ color: "#ff4500" }}
                                    >
                                      {" "}
                                      {kFormater(item.job.ctc_min)} -{" "}
                                      {kFormater(item.job.ctc_max)}{" "}
                                      {item.job.salary_payment_type.toLowerCase()}
                                    </Typography>
                                  </>
                                ) : (item.job.ctc_min !== 0 &&
                                    item.job.ctc_max !== 0) ||
                                  item.job.salary_type === "UNPAID" ? (
                                  <>
                                    &nbsp;&middot;&nbsp;
                                    <Typography
                                      variant="subtitle1"
                                      style={{ color: "#ff4500" }}
                                    >
                                      Unpaid
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    &nbsp;&middot;&nbsp;
                                    <Typography
                                      variant="subtitle1"
                                      style={{ color: "#ff4500" }}
                                    >
                                      Not Disclosed
                                    </Typography>
                                  </>
                                )}
                              </>
                            ) : (
                              item.job.ppo && (
                                <>
                                  {item.job.ctc_min !== 0 &&
                                  item.job.ctc_max !== 0 &&
                                  item.job.salary_type === "PAID" ? (
                                    <>
                                      &nbsp;&middot;&nbsp;
                                      <Typography
                                        variant="subtitle1"
                                        style={{ color: "#ff4500" }}
                                      >
                                        {" "}
                                        {kFormater(item.job.ctc_min)} -{" "}
                                        {kFormater(item.job.ctc_max)}{" "}
                                        {item.job.salary_payment_type.toLowerCase()}{" "}
                                        (PPO)
                                      </Typography>
                                    </>
                                  ) : (item.job.ctc_min !== 0 &&
                                      item.job.ctc_max !== 0) ||
                                    item.job.salary_type === "UNPAID" ? (
                                    <>
                                      &nbsp;&middot;&nbsp;
                                      <Typography
                                        variant="subtitle1"
                                        style={{ color: "#ff4500" }}
                                      >
                                        Unpaid
                                      </Typography>
                                    </>
                                  ) : (
                                    <>
                                      &nbsp;&middot;&nbsp;
                                      <Typography
                                        variant="subtitle1"
                                        style={{ color: "#ff4500" }}
                                      >
                                        Not Disclosed
                                      </Typography>
                                    </>
                                  )}
                                </>
                              )
                            )}
                            {item.job.equity_min !== 0 &&
                            item.job.equity_max !== 0 ? (
                              <>
                                &nbsp;&middot;&nbsp;
                                <Typography variant="subtitle1">
                                  {item.job.equity_min}% - {item.job.equity_max}
                                  %
                                </Typography>
                              </>
                            ) : (
                              <>
                                &nbsp;&middot;&nbsp;
                                <Typography variant="subtitle1">
                                  No Equity
                                </Typography>
                              </>
                            )}
                          </>
                        </Box>
                        <Box display="flex" alignItems="center">
                          {!reject && !draftedJob && (
                            <JobSharerButton
                              detail={jobSharerDetail(item.job)}
                              page="JOBS"
                            />
                          )}
                          {(!reject || jobIsClosed) && !draftedJob && (
                            <CommonButton
                              onClick={(e) => {
                                e.stopPropagation();

                                history.push(
                                  `/college/track/?&job_id:${btoa(
                                    String(item.job.job_id)
                                  )}&job_status:${
                                    jobIsClosed ? btoa("CLOSE") : btoa("OPEN")
                                  }`
                                );
                              }}
                              style={{ marginLeft: 20 }}
                            >
                              Track Applicants
                            </CommonButton>
                          )}
                          {interview && (
                            <>
                              <ApplyButton
                                jobId={item.job.job_id}
                                interviewId={item.job.id}
                                reject={reject!}
                              />
                            </>
                          )}
                          {/* <JobSharerButton detail={{
                          job_id: item.job.job_id,
                          company_name: item.job.company.company_name,
                          job_title: item.job.job_title,
                          job_type: item.job.job_type_name
                      }} page="RECOMMENDED" /> */}
                          {reject && (
                            <>
                              {item.job.up_coming_state.code !== -1 ? (
                                <RejectAccept
                                  id={item.id}
                                  companyId={item.job?.company?.company_id}
                                  jobId={item.job.job_id}
                                  reject={reject}
                                  queryParamsObj={queryParamsObj}
                                  GetData={GetData!}
                                  setLoading={setLoading}
                                  interviewId={item.job.id}
                                  confirmationDialog={confirmationDialog}
                                  setConfirmationDialog={setConfirmationDialog}
                                  setJobData={setJobData}
                                  setOpenJobSharer={setOpenJobSharer}
                                  setSingleJobData={setSingleJobData}
                                  jobData={item}
                                />
                              ) : (
                                <Chip
                                  label={"Deadline Passed"}
                                  style={{
                                    backgroundColor:
                                      theme.palette.type === "dark"
                                        ? theme.palette.background.default
                                        : "#fff",
                                    fontWeight: "bold",
                                    borderRadius: "30px",
                                    color: "red",
                                    border: "1px solid red",
                                  }}
                                />
                              )}
                            </>
                          )}

                          {draftedJob && (
                            <Link to={"/college/post-job/" + item.job.job_id}>
                              <CommonButton>Edit and Post Job</CommonButton>
                            </Link>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          {item?.interview?.length ? (
                            <div className="container__body__info">
                              <span className="fs-12">
                                {item.job.interview[0]?.location?.city}
                              </span>
                              &nbsp;&middot;&nbsp;
                              <span className="orange fs-12">
                                {" "}
                                {item.job.interview[0]?.start_date} -{" "}
                                {item.job.interview[0]?.end_date}{" "}
                              </span>
                            </div>
                          ) : null}
                        </Box>
                        <Box>
                          {all && (
                            <div className="flex flex-row justify-content-end">
                              {
                                <SaveIcon
                                  jobId={item.job.job_id}
                                  isSave={item.job.is_saved}
                                />
                              }
                              {
                                <HiddenIcon
                                  jobId={item.job.job_id}
                                  hideStatus={hideStatus}
                                />
                              }
                            </div>
                          )}

                          {saveJob && (
                            <div className="flex flex-row justify-content-end">
                              {
                                <SaveIcon
                                  jobId={item.job.job_id}
                                  isSave={item.job.is_saved}
                                />
                              }
                            </div>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}

      {open && (
        <Dialog
          open={open}
          onClose={() => handleClose(!open)}
          maxWidth="md"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          PaperProps={{
            style: {
              borderRadius: 30,
            },
          }}
        >
          <Grid container justify="flex-end">
            <Grid xs={12} sm={12} style={{ marginTop: "0" }}>
              <ViewJob
                data={singleJobData}
                apply={interview}
                reject={reject}
                open={fullView}
                handleClosey={callback}
                setLoading={setLoading}
                setJobData={setJobData}
                confirmationDialog={confirmationDialog}
                setConfirmationDialog={setConfirmationDialog}
              />
            </Grid>
          </Grid>
        </Dialog>
      )}
      {openJobSharer && (
        <JobSharerDialog
          openModal={openJobSharer}
          setOpenModal={setOpenJobSharer}
          detail={jobSharerDetail(singleJobData.job)}
          page="AVAILABLE"
        />
      )}
    </div>
  );
};

export default NewJobCard;

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
// import { allCollegeSelectedSelectorFn } from "../../../api/University/APIs/selector";
import { IconSelector } from "../../utility/iconSelector";
// import { collegeIdFn } from "../../../api/SignIn/selector";
// import { groupViewSelectorFn } from "../../../api/Common/selector";

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: "16px",
    marginRight: "16px",
  },
  sidebarlink: {
    display: "flex",
    color: theme.palette.primary.main,
    padding: "8px 0px 8px 16px",
    textDecoration: "none",
    listStyle: "none",

    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: pSBC(0.7, theme.palette.primary.main),
      textDecoration: "none",
      color: "#fff",
    },
  },
  blueSideBar: {
    backgroundColor: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
    display: "flex",
    padding: "8px 0px 8px 16px",
    listStyle: "none",
    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "4px",
    "&:hover": {
      color: "#fff",
    },
  },
  subNavSelected: {
    backgroundColor: pSBC(0.7, theme.palette.primary.main),
    textDecoration: "none",
    color: "#fff",

    display: "flex",
    padding: "8px 0px 8px 16px",
    listStyle: "none",

    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "4px",
  },
  dropdownlink: {
    display: "flex",
    color: "#060b26",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    color: theme.palette.secondary.main,
    alignItems: "center",
    padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: pSBC(0.7, theme.palette.primary.main),
      textDecoration: "none",
      color: "#fff",
    },
  },
  blueLink: {
    display: "flex",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    alignItems: "center",
    padding: "0px 16px 0px 55px",
    justifyContent: "flex-start",
    borderRadius: "4px",

    backgroundColor: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
  },
}));

// const SidebarLink = makeStyles((Link) => ({
//   display: "flex",
//   color: "#060b26",
//   padding: "8px 0px 8px 16px",
//   textDecoration: "none",
//   listStyle: "none",
//   height: "50px",
//   alignItems: "center",
//   padding: "0 16px",
//   borderRadius: "4px",
//   "&:hover": {
//     backgroundColor: "#1a83ff",
//     textDecoration: "none",
//     color: "#fff",
//   },
// }));

const SubMenu = ({ items, key }) => {
  const [subnav, setSubnav] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const [item, setItem] = useState({});
  const dispatch = useDispatch();
  const [navbarClicked, setNavbarClicked] = useState(false);
  // const allCollegeSelected = useSelector(allCollegeSelectedSelectorFn);
  // const collegeId = useSelector(collegeIdFn);
  // const groupView = useSelector(groupViewSelectorFn);
  const showSubnav = () => {
    setSubnav(!subnav);
  };

  useEffect(() => {
    if (navbarClicked) {
      // dispatch(resetSelectedCollegeToken());
      setNavbarClicked(false);
    }
  }, [navbarClicked]);

  useEffect(() => {
    if (
      items.subNav &&
      items.subNav.length &&
      items.subNav.some((i) => i.path === location.pathname)
    )
      setSubnav(true);
    else setSubnav(false);
  }, [items]);

  useEffect(() => {
    if (location.pathname === items.path)
      setItem({
        ...items,
        selected: true,
      });
    else
      setItem({
        ...items,

        selected: false,
      });

    if (items.subNav && items.subNav.length) {
      items.subNav.forEach((i) => {
        if (i.path === location.pathname) i["selected"] = true;
        else i["selected"] = false;
      });
    }
  }, [items, location.pathname]);

  return (
    <>
      <div
        style={{ marginBottom: "20px" }}
        onMouseEnter={() => {
          if (item.subNav) showSubnav();
        }}
        onMouseLeave={() => {
          if (item.subNav) showSubnav();
        }}
      >
        {" "}
        <Link
          className={
            item.selected
              ? classes.blueSideBar
              : item.subNav &&
                item.subNav.length &&
                item.subNav.some((i) => i.path === location.pathname)
              ? classes.subNavSelected
              : classes.sidebarlink
          }
          style={{ width: "220px" }}
          to={item?.college_path ? item.college_path : item.path}
          onClick={() => {
            setNavbarClicked(true);
            if (item.subNav) showSubnav();
          }}
        >
          <div style={{ display: "contents" }}>
            {/* {item.icon} */}
            {IconSelector(item.type)}
            <Typography
              variant="body2"
              color="inherit"
              className={classes.text}
            >
              {item.title}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            {item.subNav && subnav
              ? IconSelector("dropdown_open")
              : item.subNav
              ? IconSelector("dropdown_closed")
              : null}
          </div>
        </Link>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              item?.show_college && (
                <Link
                  className={
                    item.selected ? classes.blueLink : classes.dropdownlink
                  }
                  to={item?.college_path ? item.college_path : item.path}
                  key={index}
                >
                  {item.icon}
                  <Typography variant="body2" color="inherit">
                    {item?.college_title ? item.college_title : item.title}
                  </Typography>
                </Link>
              )
            );
          })}
      </div>
    </>
  );
};

export default SubMenu;

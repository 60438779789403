const styles = {
    leftPaper: {
		backgroundColor: "#fff",
		flex: 1,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		height: "100%",
		width: "260px",
		// border: "2px solid #000",
		background: "#ffffff",
		paddingTop: "100px",
		position: "fixed",
	},
	leftMenuBox: {
		width: "200px",
		height: "38px",
		marginTop: "15px",
		borderRadius: 5,
		backgroundColor: "#fff",
	},
	leftMenuBoxSelected: {
		width: "200px",
		height: "38px",
		marginTop: "15px",
		borderRadius: 5,
		backgroundColor: "#CDEDF6",
		fontWeight: "bold",
	},
	paperStyle1: {
		marginLeft: "100px",
		backgroundColor: "#fff",
		width: "65vw",
		marginTop: "35px",
		paddingTop: "30px",
		paddingLeft: "20px",
		paddingBottom: "15px",
		marginBottom: "35px",
	},
	fieldDiv: {
		display: "flex",
		flexDirection: "row",
		marginTop: "20px",
		alignItems: "flex-start",
		// border: "2px solid #000",
	},
	inputBox: {
		marginLeft: "35px",
		width: "220px",
		paddingLeft: "5px",
		height: "35px",
		// borderRadius: "5px",
	},
	inputBox1: {
		marginLeft: "35px",
		width: "650px",
		height: "35px",
		paddingLeft: "5px",
	},
	inputBox2: {
		marginLeft: "35px",
		width: "650px",
		height: "150px",
		paddingLeft: "5px",
		paddingRight: "5px",
	},
	inputBox3: {
		marginLeft: "35px",
		width: "300px",
		height: "35px",
		paddingLeft: "5px",
	},
	heading: {
		width: "200px",
		// border: "2px solid #000",
		height: "30px",
		paddingLeft: "5px",
		display: "flex",
		flexDirection: "row",
	},
	radioStyle: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	notRequired: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "120px",
	},
	dropDown: {
		marginLeft: "35px",
		width: "180px",
	},
	dropDown1: {
		marginLeft: "35px",
		width: "220px",
	},
	buttonContainer1: {
		display: "flex",
		justifyContent: "left",
		paddingRight: "30px",
		marginTop: "50px",
		marginBottom: "50px",
	},
	button: {
		backgroundColor: "#3282C4",
		color: "#fff",
		borderRadius:"30px",
		paddingRight: "20px",
		paddingLeft: "20px",
	},
	button2: {
		backgroundColor: "#DF8184",
		paddingRight: "20px",
		borderRadius:"30px",
		paddingLeft: "20px",
		marginLeft: "15px",
		fontWeight: "bold",
	},
	button3: {
		backgroundColor: "#6FCF97",
		paddingRight: "20px",
		paddingLeft: "20px",
		marginLeft: "15px",
		borderRadius:"30px",
		fontWeight: "bold",
	},
	tableContainer: {
		// border: "1px solid #000",
		width: "98%",
		marginLeft: "0px",
		display: "flex",
		justifyContent: "space-around",
		alignItems: "center",
		flexDirection: "column",
		marginTop: "50px",
		paddingBottom: "30px",
	},
	tab: {
		// border: "1px solid #000",
		width: "100%",
		marginLeft: "0px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	tab1: {
		// border: "1px solid #000",
		width: "100%",
		marginLeft: "0px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	tableHeader0: {
		height: "40px",
		width: "48%",
		borderRight: "1px solid #000",
		alignItems: "center",
		paddingLeft: "100px",
		display: "flex",
		backgroundColor: "#E0E0E0",
		fontWeight: "600",
	},
	tableHeaderCollegeProfile: {
		height: "3.0rem",
		width: "48%",
		borderRight: "1px solid #000",
		alignItems: "center",
		paddingLeft: "25px",
		display: "flex",
		backgroundColor: "#E0E0E0",
		fontWeight: "600",
	},
	tableHeaderCollegeProfileEven: {
		height: "3.0rem",
		width: "48%",
		borderRight: "1px solid #000",
		alignItems: "center",
		paddingLeft: "25px",
		display: "flex",
		backgroundColor: "#F2F2F2",
		fontWeight: "600",
	},

	tableHeader32: {
		height: "40px",
		width: "48%",
		borderRight: "1px solid #000",
		alignItems: "center",
		paddingLeft: "100px",
		display: "flex",
		backgroundColor: "#F2F2F2",
		fontWeight: "600",
	},
	tableHeader1: {
		height: "40px",
		width: "48%",
		// border: "1px solid #000",
		alignItems: "center",
		paddingLeft: "50px",
		display: "flex",
		backgroundColor: "#2F80ED",
		fontWeight: "600",
		color: "#fff",
	},
	tableHeader22: {
		height: "40px",
		width: "48%",
		// border: "1px solid #000",
		alignItems: "center",
		paddingLeft: "100px",
		display: "flex",
		backgroundColor: "#4594FF",
		fontWeight: "600",
		color: "#fff",
	},
	buttonT1: {
		backgroundColor: "#424242",
		color: "#fff",
	},
	buttonT2: {
		backgroundColor: "#424242",
		color: "#fff",
	},
	
	images12: {
		// marginLeft: "190px",
		// marginTop: "35px",
        // marginBottom: "35px",
        cursor:'pointer',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
    },
    
  
	avatarBox12: {
		// marginLeft: "240px",
		marginTop: "35px",
		// marginBottom: "35px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
    },
    
    
	avatarBox98: {
		marginLeft: "20px",
		marginTop: "35px",
		// marginBottom: "35px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},


	emptyLogo12: {
		marginLeft: "240px",
		marginTop: "35px",
		marginBottom: "35px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "200px",
		height: "200px",
		borderRadius: "100px",
		backgroundColor: "gray",
		color: "white",
		fontWeight: "bold",
	},
	emptyBanner12: {
		// marginLeft: "240px",
		// marginTop: "35px",
		// marginBottom: "35px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "1200",
		height: "200px",
		backgroundColor: "gray",
		color: "white",
		fontWeight: "bold",
	},
};

export default styles;

/* eslint-disable */
import {
  Box,
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Container,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Badge from "@material-ui/core/Badge";
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/httpRequest";
// import CompanyCard from "../../bundles/company/connection/Home/Connections/CollegeCard";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import NoJobs from "../../../assets/images/no-jobs.png";
import Loader from "../../../bundles/common/components/UI/Loader";
import College from "../cards/college/College";
import { useSelector, useDispatch } from "react-redux";
import filter from "../../../assets/common/filter.png";
import {
  changeState,
  getAllLocations,
  getJobSegment,
  UPDATE_CTC_EXPLORE,
  UPDATE_JOB_SEGMENT,
  UPDATE_LOCATION_EXPLORE,
  FILTER_COUNT,
} from "../action";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useLocation } from "react-router-dom";

export default function Invite() {
  const [jobData, setJobData] = useState([]);
  const games = ["1", "2", "3", "4", "5"];
  const [game, setGame] = useState("");
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [companySize, setCompanySize] = useState([]);
  const [organizationType, setOrganizationType] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [industryIds, setIndustryIds] = useState([{ name: "", value: "" }]);
  const [cityIds, setCityIds] = useState([{ name: "", value: "" }]);
  const [industry, setIndustry] = useState([]);
  const [city, setCity] = useState([]);
  const [city_n, setCity_n] = useState();
  const location = useLocation();
  const [cardFromCollege, setCardFromCollege] = useState(true);

  const [endYear, setEndYear] = useState(moment().year());
  const [startYear, setStartYear] = useState([]);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [totalCompanyCount, setTotalCompanyCount] = useState(0);
  const citySelected = useSelector((state) => state.ExploreFilter.location);
  const JobSegmentSelected = useSelector(
    (state) => state.ExploreFilter.job_segment
  );
  const stateMemory = useSelector((state) => state.ExploreFilter);

  const cityListObject = useSelector((state) => state.CityList.cityList);
  const jobSegments = useSelector(
    (state) => state.JobSegmentList.JobSegmentList
  );

  const [jobSegment, setJobSegment] = useState("");
  const dispatch = useDispatch();

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeState(dispatcher, data));
  };

  const max_ctc = [
    { name: "Less than 1 LPA", value: 100000 },
    { name: "Less than 6 LPA", value: 600000 },
    { name: "Less than 10 LPA", value: 1000000 },
    { name: "Less than 30 LPA", value: 3000000 },
    { name: "Less than 60 LPA", value: 6000000 },
  ];

  const getIndustries = async () => {
    const res = await Axios.get(
      `${BackendBaseApi.PRAVESH}api/company/industry/`
    ).catch((err) => {
      throw err;
    });

    setIndustryIds(res.data.data);
  };

  useEffect(() => {
    dispatch(getAllLocations());
    dispatch(getJobSegment());
    // getCities1();
    getIndustries();
    if (location.flag === 1424) {
      setFilterDialog(true);
    }
  }, []);

  useEffect(() => {
    GetData(
      BackendBaseApi.PRAVESH,
      "api/college/search/?college_name=&search=all",
      { headers: localStorage.getItem("gw_token") },
      setJobData
    );
    setLoader(true);
  }, []);

  const GetData = async (baseUrl, endPoint, body, updateState) => {
    if (!isApiCalled) {
      setIsApiCalled(true);
      setLoading(true);
      let res = await httpRequest(baseUrl, endPoint, body);

      if (res.data.next === null) {
        setEnd(true);
      } else {
        setNewURL(res.data.next.slice(0, 31));
        setNewEndPoint(res.data.next.slice(31));
      }
      updateState(jobData.concat([...res.data.results]));
      setTotalCompanyCount(res.data.count);
      setIsApiCalled(false);
    }
    setLoading(false);
    setLoader(false);
  };

  const handleScroll = (event) => {
    let e = event.nativeEvent;

    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && !isApiCalled) {
        GetData(
          newURL,
          newEndPoint,
          { headers: localStorage.getItem("gw_token") },
          setJobData
        );
      }
    }
  };
  const classes = useStyles();

  const handleFilterDialog = () => {
    setFilterDialog(false);
  };

  const generateCsv = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item.city_id + ",")
        : (str += item.city_id)
    );
    return str;
  };

  const generateCsvJobSegment = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item.job_segment_id + ",")
        : (str += item.job_segment_id)
    );
    return str;
  };

  const checkLocation = () => {
    if (stateMemory.location.length > 0) {
      return `&location=${generateCsv(stateMemory.location)}`;
    } else return "";
  };

  const checkJobSegment = () => {
    if (stateMemory.job_segment.length > 0) {
      return `&job_segment=${generateCsvJobSegment(stateMemory.job_segment)}`;
    } else return "";
  };
  const checkCTC = () => {
    if (parseInt(stateMemory.ctc) !== 0) {
      return `&max_ctc=${stateMemory.ctc}`;
    } else return "";
  };

  const getFilteredData = async () => {
    setLoading(true);
    setLoader(true);

    const res = await Axios({
      method: "GET",
      url: `${
        BackendBaseApi.PRAVESH
      }api/college/search/?college_name=&search=all${checkLocation()}${checkCTC()}${checkJobSegment()}`,
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    }).catch((err) => {
      throw err;
    });

    if (res.data.data.next === null) {
      setEnd(true);
    } else {
      setNewURL(res.data.data.next.slice(0, 31));
      setNewEndPoint(res.data.data.next.slice(31));
    }
    setJobData(res.data.data.results);
    setTotalCompanyCount(res.data.data.count);
    setFilterApplied(false);
    setLoading(false);
    setLoader(false);
  };

  useEffect(() => {
    if (filterApplied) {
      getFilteredData();
    }
  }, [filterApplied]);

  const handleDelete = (key, arr, setArr) => {
    let sampleArr = arr;

    const index = sampleArr.indexOf(key);
    if (index > -1) {
      sampleArr.splice(index, 1);

      handleFilterChange(setArr, sampleArr);
    }
  };

  const generateChipText = (num) => {
    if (num == 2) {
      return "2+ Years";
    } else if (num == 5) {
      return "5+ Years";
    } else if (num == 10) {
      return "10+ Years";
    }
  };

  const handleClearAll = () => {
    setCompanySize([]);
    setOrganizationType([]);
    setCity([]);
    setIndustry([]);
    setStartYear([]);
    handleFilterChange(UPDATE_CTC_EXPLORE, 0);
    handleFilterChange(UPDATE_LOCATION_EXPLORE, []);
    handleFilterChange(UPDATE_JOB_SEGMENT, []);
    handleFilterChange(FILTER_COUNT, 0);
    setFilterApplied(true);

    setLoader(true);
    setEnd(false);
  };

  useEffect(() => {
    let num = 0;

    if (stateMemory.location.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }

    if (parseInt(stateMemory.ctc) !== 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }
    if (stateMemory.job_segment.length > 0) {
      num += 1;
      handleFilterChange(FILTER_COUNT, num);
    }
    if (
      stateMemory.job_segment.length === 0 &&
      parseInt(stateMemory.ctc) === 0 &&
      stateMemory.location.length === 0
    ) {
      handleFilterChange(FILTER_COUNT, 0);
    }
  }, [
    stateMemory.location.length,
    stateMemory.ctc,
    stateMemory.job_segment.length,
  ]);

  const cities = Object.keys(cityListObject).map((City) => {
    return { name: City, value: City };
  });

  useEffect(() => {
    if (city_n) {
      if (!stateMemory.location.includes(city)) {
        const currentLocation = {
          city: city_n,
          city_id: cityListObject[city_n],
        };
        let job_location = [...citySelected];
        job_location.push(currentLocation);

        handleFilterChange(UPDATE_LOCATION_EXPLORE, job_location);
      }
    }
  }, [city_n]);

  useEffect(() => {
    if (jobSegment) {
      let selectedCourses = stateMemory.job_segment;
      if (
        selectedCourses
          .map((selected_course) => selected_course.job_segment_name)
          .includes(jobSegment)
      )
        return;

      selectedCourses.push(jobSegments[jobSegment]);

      handleFilterChange(UPDATE_JOB_SEGMENT, selectedCourses);
    }
  }, [jobSegment]);

  const Location = () => (
    <Autocomplete
      value={city_n}
      onChange={(event, newValue) => {
        setCity_n(newValue);
      }}
      id="controllable-states-demo"
      options={cities.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Location" variant="outlined" />
      )}
    />
  );

  const jobsegments = Object.keys(jobSegments).map((jobrole) => {
    return { name: jobrole, value: jobrole };
  });

  const Hiringfor = () => (
    <Autocomplete
      value={jobSegment}
      onChange={(event, newValue) => {
        setJobSegment(newValue);
      }}
      id="controllable-states-demo"
      options={jobsegments.map((option) => option.name)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label="Job Segment" variant="outlined" />
      )}
    />
  );

  // const inputLabel = React.useRef(null);

  // const [labelWidth, setLabelWidth] = React.useState(0);
  // React.useEffect(() => {
  //   if (filterDialog === true) setLabelWidth(inputLabel.current.offsetWidth);
  // }, [filterDialog]);

  return (
    <div className="company-invite" style={{ marginTop: "20px" }}>
      <div
        class="containerA"
        style={{
          marginLeft: "1%",
        }}
      >
        <span data-title="Recommendations for you" class="text">
          Recommendations for you (
          {jobData.length
            ? totalCompanyCount + " Colleges"
            : "No College Found! Please Change Filters"}
          )
        </span>
      </div>
      <nav
        class="job-pane fs-14 navbar fixed-top small-nav navbar-expand navbar-light topbar shadow-main ml-auto"
        style={{
          width: "calc(1%)",
          marginRight: "40px",
          marginTop: "",

          borderRadius: "40px",

          backdropFilter: "blur(30px)",
          boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
          border: "solid 1px #f1f1f1",
          backgroundColor: "#ffffff",

          marginTop: "35px",
          padding: "30px",
        }}
      >
        <div className="row track-tabs" style={{ width: "98vw" }}>
          {/* <div style={{ paddingLeft: "12%" }}>
              <div
                style={{
                  marginRight: "12px",
                  marginTop: "2%",
                  fontSize: "16px",
                }}
              >
                If you can’t find the company you are looking for,
                <b> click on</b>
              </div>
            </div>
            <div
              className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-center"
              style={{ paddingLeft: "0", marginBottom: "20px" }}
            >
              <div>
                <button
                  className="btn btn-outline-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Invite other company
                </button>
              </div>
            </div> */}
        </div>

        <div
          className="row fs-18 mx-12"
          style={{
            cursor: "pointer",
            alignItems: "center",
          }}
          onClick={() => setFilterDialog(true)}
        >
          <h3 style={{}}>
            <Badge color="secondary" badgeContent={`${stateMemory.num}`}>
              <img src={filter} fontSize="inherit" alt="" height="30px" />

              <span
                style={{
                  color: "#3282c4",
                  fontSize: "16px",
                  marginLeft: "4px",
                }}
              ></span>
            </Badge>
          </h3>
          <div className="dropdown mb-5">
            {/*  <div
                className="dd dropdown-toggle-custom"
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span
                  className="fs-15 d-inline-block text-truncate"
                  style={{ maxWidth: "150px" }}
                >
                  {game}
                </span>
              </div>  
             */}
            <div
              className="dropdown-menu"
              style={{ height: "50vh", overflowY: "scroll" }}
              aria-labelledby="dropdownMenuButton"
            >
              {games &&
                games.length > 0 &&
                games.map((g) => {
                  return (
                    <button
                      key={g}
                      onClick={(e) => {
                        setGame(e.target.value);
                      }}
                      className="dropdown-item cp"
                    >
                      <span
                        className="d-inline-block text-truncate"
                        style={{
                          maxWidth: "150px",
                        }}
                      ></span>
                      {g}
                    </button>
                  );
                })}
            </div>
          </div>
        </div>

        {/* <div className="gw-input-container">
                <i class="fas fa-search"></i>
                <input
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  type="text"
                  className="ml-5 gw-input input-secondary1 input-small mr-3"
                  value={search}
                  placeholder="Search"
                ></input>
              </div> */}
      </nav>
      {/* <nav
                        className={classes.appbar2}
                        onClick={() => setFilterDialog(true)}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            cursor: "pointer",
                            borderTop: "1px solid grey",
                        }}
                    >
                    </nav> */}
      {loader === false ? (
        <div className="row comapnyconcinvite">
          {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> */}
          <Container maxWidth="xl">
            <div
              id="myid"
              onScroll={handleScroll}
              style={{ height: "90vh", overflowY: "scroll", padding: "10px" }}
              // className={classes.scrollY21}
            >
              {jobData.length ? (
                <College
                  data={jobData}
                  invitation={true}
                  cardFromCollege={cardFromCollege}
                />
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginTop="5%"
                  paddingBottom="5%"
                >
                  <img src={NoJobs} width="341px" height="auto" />
                  <p
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                    }}
                  >
                    No Recommended Colleges for you
                  </p>
                </Box>
              )}
              {loading && (
                // <Box padding="3% 3%">
                <Box>
                  <JobCardSkeleton />
                </Box>
              )}
            </div>
          </Container>
          {/* </div> */}
        </div>
      ) : (
        <Loader />
      )}
      <Dialog
        open={filterDialog}
        onClose={handleFilterDialog}
        className={classes.dialog}
        maxWidth="lg"
        PaperProps={{
          style: {
            borderRadius: 30,
          },
        }}
      >
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={() => {
                handleFilterDialog();
                handleFilterChange(UPDATE_CTC_EXPLORE, 0);
                handleFilterChange(UPDATE_LOCATION_EXPLORE, []);
                handleFilterChange(UPDATE_JOB_SEGMENT, []);
                handleFilterChange(FILTER_COUNT, 0);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box padding="2%">
            {companySize.map((item, index) => (
              <Chip
                label={item}
                onDelete={() => handleDelete(item, companySize, setCompanySize)}
                // classes={{ deleteIcon: classes.deleteIcon }}
                className={classes.root11}
              />
            ))}
            {organizationType.map((item, index) => (
              <Chip
                label={item}
                onDelete={() =>
                  handleDelete(item, organizationType, setOrganizationType)
                }
                className={classes.root11}
              />
            ))}
            {JobSegmentSelected.length > 0 &&
              JobSegmentSelected.map((item, index) => (
                <Chip
                  label={item.job_segment_name}
                  onDelete={() =>
                    handleDelete(item, JobSegmentSelected, UPDATE_JOB_SEGMENT)
                  }
                  className={classes.root11}
                />
              ))}

            {stateMemory.ctc > 0 && (
              <Chip
                label={stateMemory.ctc}
                onDelete={() => handleFilterChange(UPDATE_CTC_EXPLORE, 0)}
                className={classes.root11}
              />
            )}
            {citySelected.length > 0 &&
              citySelected.map((item, index) => {
                // console.log(citySelected);
                // console.log(cityList);
                // console.log(cityIds);
                // console.log(city);
                // console.log(
                //   cityIds.length > 0 &&
                //     cityIds.find((city) => city.city_id === item.city_id).city
                // );

                return (
                  <Chip
                    label={item.city}
                    onDelete={() =>
                      handleDelete(item, citySelected, UPDATE_LOCATION_EXPLORE)
                    }
                    className={classes.root11}
                  />
                );
              })}
            {startYear.map((item, index) => (
              <Chip
                label={generateChipText(item)}
                onDelete={() => handleDelete(item, startYear, setStartYear)}
                className={classes.root11}
              />
            ))}
          </Box>
          <Divider />
          <br />
          <Box>
            <p
              style={{
                fontSize: 26,
                fontWeight: "bold",
                fontFamily: "ProximaNova",
              }}
            >
              Filters
            </p>
            <Grid container spacing={4}>
              {/* <Grid item xs={12} sm={6}>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Box width="30%">
                      <p style={{ marginRight: "15px" }}>Company size</p>
                    </Box>
                    <Box width="60%">
                      <SelectSearch
                        multiple={true}
                        printOptions="on-focus"
                        style={{ width: "60%" }}
                        options={[
                          {
                            name: "1-10",
                            value: "1-10",
                          },
                          {
                            name: "11-50",
                            value: "11-50",
                          },
                          {
                            name: "51-100",
                            value: "51-100",
                          },
                          {
                            name: "101-1000",
                            value: "101-1000",
                          },
                          {
                            name: "1000+",
                            value: "1000+",
                          },
                        ]}
                        value={companySize}
                        onChange={setCompanySize}
                        search
                      />
                    </Box>
                  </Box>
                </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Box width="30%">
                      <p style={{ marginRight: "15px" }}>Organization type</p>
                    </Box>
                    <Box width="60%">
                      <SelectSearch
                        multiple={true}
                        printOptions="on-focus"
                        style={{ width: "60%" }}
                        options={[
                          {
                            name: "Private",
                            value: "Private",
                          },
                          {
                            name: "SemiPrivate.",
                            value: "Semi Private",
                          },
                          {
                            name: "Government",
                            value: "Government",
                          },
                        ]}
                        value={organizationType}
                        onChange={setOrganizationType}
                        placeholder="Select"
                        search
                      />
                    </Box>
                  </Box>
                </Grid> */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" justifyContent="end">
                  <Box width="30%">
                    <p style={{ marginRight: "15px" }}>Location</p>
                  </Box>
                  <Box width="70%">
                    {/* <SelectSearch
                        multiple={true}
                        printOptions="on-focus"
                        autoComplete="on"
                        style={{ width: "60%" }}
                        options={cityIds.map((item) => ({
                          name: item.city,
                          value: item.city_id,
                        }))}
                        value={city}
                        onChange={(val) => setCity(val)}
                        placeholder="Select"
                        search
                      /> */}
                    <Location />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" justifyContent="end">
                  <Box width="30%">
                    <p style={{ marginRight: "15px" }}>Job Segment</p>
                  </Box>
                  <Box width="70%">
                    {/* <SelectSearch
                        multiple={true}
                        printOptions="on-focus"
                        autoComplete="on"
                        style={{ width: "60%" }}
                        options={industryIds.map((item) => ({
                          name: item.industry_name,
                          value: item.id,
                        }))}
                        value={industry}
                        onChange={(val) => setIndustry(val)}
                        placeholder="Select"
                        search
                      /> */}
                    <Hiringfor />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" justifyContent="end">
                  <Box width="30%">
                    <p style={{ marginRight: "15px" }}>Max CTC</p>
                  </Box>
                  <Box width="70%">
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        // ref={inputLabel}
                      >
                        Max CTC
                      </InputLabel>
                      <Select
                        value={stateMemory.ctc}
                        onChange={(e) =>
                          handleFilterChange(
                            UPDATE_CTC_EXPLORE,
                            parseInt(e.target.value)
                          )
                        }
                        label="CTC"
                        // input={
                        //   <OutlinedInput
                        //     notched
                        //     labelWidth={labelWidth}
                        //     name="age"
                        //     id="outlined-age-always-notched"
                        //   />
                        // }
                      >
                        {max_ctc.map((data) => {
                          return (
                            <MenuItem value={data.value}>{data.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        {location.flag === 1424 && (
          <>
            <br />
            <p style={{ paddingLeft: "20px" }}>
              Please Click on Apply Filters after Selecting your choices.{" "}
            </p>
            <br />
          </>
        )}
        <DialogActions>
          <Button onClick={handleClearAll} color="primary">
            Clear All
          </Button>
          <Button
            onClick={() => {
              handleFilterDialog(), setFilterApplied(true);
            }}
            color="primary"
            autoFocus
          >
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appbar: {
      width: `100%`,
      marginTop: "44px",
      padding: "1% 0%",
      display: "flex",
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.up(1000)]: {
        width: "100%",
      },
    },

    root11: {
      fontWeight: "bolder",
      border: "2px solid #082c5a",
      color: "#082c5a",
      backgroundColor: "#bde3ff",
      fontSize: 13,
      marginRight: "2%",
      marginBottom: "1%",
    },

    appbar2: {
      width: `100%`,
      padding: "1% 0%",
      display: "flex",
      background: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.up(1000)]: {
        width: "100%",
      },
    },
    deleteIcon: {
      color: "#ffffff",
    },
    dialog: {
      // width: "600px",
    },
    scrollY21: {
      marginTop: "-10px",
    },
  })
);

import {
  AppBar,
  Box,
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import bg from "../../../assets/images/college.jpg";
import phone from "../../../assets/images/phone.png";
import bullet from "../../../assets/images/bullet.png";

import web from "../../../assets/images/web.png";
import ReactHtmlParser from "react-html-parser";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import React, { Fragment, useEffect, useState } from "react";
import kFormatter, { LFormatter } from "../../../utils/ZeroToK";
const useStyles = makeStyles((theme) =>
  createStyles({
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "0 40px 40px 40px",
      padding: "40px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
      // maxHeight: "1000px",
      // overflowY: "scroll",
    },
    WhiteBox2: {
      backgroundColor: "#fff",
      margin: "0 40px 0 0",
      padding: "40px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
    },
    WhiteBox3: {
      backgroundColor: "#fff",
      margin: "0 40px 40px 0",
      padding: "40px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
    },
    imageAndText: {
      float: "left",
      padding: "0 20px 15px 0",
    },
    SplitBox: {
      display: "flex",
      flexDirection: "column",
    },
    para: {
      color: "#4c4949",
      fontSize: "18px",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
    },
    "@media only screen and (max-width: 768px)": {
      WhiteBox: {
        marginLeft: "-7px",
        // maxHeight: "1000px",
        // overflowY: "scroll",
        width: "100%",
      },
      WhiteBox2: {
        // marginLeft: "-20px",
        width: "100%",
      },
      WhiteBox3: {
        // marginLeft: "-20px",
        width: "100%",
      },
    },
  })
);

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#3e3d3d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
    "@media only screen and (max-width: 768px)": {
      body: {
        fontSize: 12,
      },
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export default function OverviewBox(props) {
  const { data } = props;
  const [overviewData, setOverviewData] = useState({});
  useEffect(() => {
    if (data) setOverviewData(data);
  }, [data]);
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item md={9}>
        <Box className={classes.WhiteBox}>
          <h2 className={classes.heading}>Overview</h2>
          <br />
          <h3 className={classes.heading}>
            {overviewData?.college_name} Highlights
          </h3>
          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "30%" }}
                  >
                    Established | Institute Type
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {overviewData?.college_info?.year_founded}{" "}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "30%" }}
                  >
                    Approved by
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {overviewData?.college_info?.approved_by.length > 0
                      ? overviewData?.college_info?.approved_by.map((item) =>
                          overviewData?.college_info?.approved_by.indexOf(
                            item
                          ) !==
                          overviewData?.college_info?.approved_by.length - 1
                            ? item + ", "
                            : item
                        )
                      : "NA"}{" "}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "30%" }}
                  >
                    Courses Offered
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {overviewData?.courses?.courses?.degrees.length} courses
                    across {overviewData?.courses?.courses?.streams.length}{" "}
                    streams
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "30%" }}
                  >
                    Top Recruiters
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {overviewData?.placement?.placement
                      ? overviewData?.placement?.placement
                          .slice(0, 5)
                          .map((item) =>
                            overviewData?.placement?.placement.indexOf(item) !==
                            4
                              ? item.company_name + ", "
                              : item.company_name
                          )
                      : "NA"}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "30%" }}
                  >
                    Website
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    <a
                      href={overviewData?.college_info?.website}
                      target="blank"
                    >
                      {overviewData?.college_info?.website}
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />
          <h3 className={classes.heading}>
            {overviewData?.college_name} Rankings
          </h3>
          {/* <br /> */}
          <p className={classes.para}>
            The university has been awarded and acknowledged through many ranks.
            The recent ranking has been indicated below.
          </p>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "30%" }}
                  >
                    <b>Ranking Agency</b>
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    <b>Ranking Position</b>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "30%" }}
                  >
                    {overviewData?.college_info?.ranking[0].agency +
                      " " +
                      overviewData?.college_info?.ranking[0].year}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    Ranked&nbsp;{" "}
                    {overviewData?.college_info?.ranking[0].rank +
                      " among the Best " +
                      overviewData?.college_info?.ranking[0].stream +
                      " Universities in India."}{" "}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <br />
          {overviewData?.courses?.compare_courses.length > 0 && (
            <>
              <h3 className={classes.heading}>
                {overviewData?.college_name} Courses
              </h3>
              {/* <br /> */}
              <p className={classes.para}>
                The university offers various programs falling under the field
                of{" "}
                {overviewData?.courses?.courses.streams?.map((item) =>
                  overviewData?.courses?.courses.streams.indexOf(item) !==
                  overviewData?.courses?.courses.streams.length - 1
                    ? item + ", "
                    : item
                )}
                . The detailed summary of the same has been given below.
              </p>

              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: "30%" }}
                      >
                        <b>Courses</b>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <b>Details</b>
                      </StyledTableCell>
                    </StyledTableRow>
                    {overviewData?.courses?.compare_courses.map((item) => (
                      <StyledTableRow>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ width: "30%" }}
                        >
                          {item.course}
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                          <b>Eligibility:</b>&nbsp;{" "}
                          {item.eligibility ? item.eligibility : "NA"}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {parseInt(overviewData?.placement?.highest_salary) > 0 && (
            <>
              <br />
              <br />
              <h3 className={classes.heading}>
                {overviewData?.college_name} Placements
              </h3>
              {/* <br /> */}
              <p className={classes.para}>
                The university has a dedicated placement cell which trains its
                students to enhance their personal, inter-personal and
                professional skills. The university insists the students to take
                up internships and several trainings while in their period of
                study. The range of the salary is indicated below.
              </p>

              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: "30%" }}
                      >
                        <b>Average Package</b>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <b>Highest Package</b>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: "30%" }}
                      >
                        INR&nbsp;{" "}
                        {parseInt(overviewData?.placement?.average_salary) >
                        99999
                          ? LFormatter(
                              parseInt(overviewData?.placement?.average_salary)
                            )
                          : kFormatter(
                              parseInt(overviewData?.placement?.average_salary)
                            )}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        INR&nbsp;{" "}
                        {parseInt(overviewData?.placement?.highest_salary) >
                        99999
                          ? LFormatter(
                              parseInt(overviewData?.placement?.highest_salary)
                            )
                          : kFormatter(
                              parseInt(overviewData?.placement?.highest_salary)
                            )}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {/* <p className={classes.para}>
            <img
              src={bg}
              alt=""
              height="350px"
              width="700px"
              className={classes.imageAndText}
            />
            {ReactHtmlParser(overviewData?.cutoff?.description)}
          </p> */}
        </Box>
      </Grid>
      <Grid item md={3}>
        <Box className={classes.SplitBox}>
          <Box className={classes.WhiteBox3}>
            <p className={classes.para}>
              {" "}
              <img src={web} alt="" height="20px" />
              &nbsp; &nbsp; &nbsp;{" "}
              <a href={overviewData?.college_info?.website} target="blank">
                {overviewData?.college_info?.website}
              </a>
            </p>
            <p className={classes.para}>
              {" "}
              <img src={phone} alt="" height="20px" />
              &nbsp; &nbsp; &nbsp;{" "}
              {overviewData?.college_info?.phone_no[0].value}
            </p>
          </Box>
          <Box className={classes.WhiteBox2}>
            <h3 className={classes.heading}>Courses</h3>
            <br />
            {overviewData?.courses?.courses?.courses.map((item) => (
              <p className={classes.para}>{item.display_name}</p>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

import React from "react";
import { useHistory } from "react-router-dom";
import "./Cards.css";
import { Box, Hidden } from "@material-ui/core";

const SignupOptions = ({ userid, email, isemailVerified, token }) => {
  let history = useHistory();

  const navigateSignup = (userType) => {
    if (userType === 1)
      history.push({
        pathname: "/student/register",
        userID: userid,
        email,
        token,
      });
    else if (userType === 5) {
      if (isemailVerified) {
        history.push({
          pathname: "/company/register",
          userID: userid,
          email,
          token,
        });
      } else {
        history.push({
          pathname: "/confirm-email",
          email,
        });
        localStorage.removeItem("gw_token");
      }
    }
  };

  return (
    <Box width="100%" display="flex" alignItems="center" flexDirection="column">
      <h1 className="mb-4 login-heading text-left" style={{ color: "#000" }}>
        Signup as a
      </h1>

      <Hidden smDown>
        <div className="row text-left">
          <div className="large-list">
            <ul style={{ marginLeft: "0px" }}>
              <li
                className="my-4 shadow_1"
                onClick={() => {
                  navigateSignup(1);
                }}
              >
                <span className="fw-700 fs-34 py-3 px-3">Student</span>
              </li>
              <li
                className="my-4 shadow_1"
                onClick={() => {
                  navigateSignup(5);
                }}
              >
                <span className="fw-700 fs-34 py-3 px-3">Company</span>
              </li>
            </ul>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <Box padding="0% 10%" width="100%">
          <Box
            borderLeft="5px solid #3282c4"
            width="100%"
            color="#1d3354"
            padding="3% 0%"
            className="my-4 shadow_1"
            onClick={() => {
              navigateSignup(1);
            }}
          >
            <span className="fw-700 fs-34 py-3 px-3">Student</span>
          </Box>
          <Box
            borderLeft="5px solid #3282c4"
            width="100%"
            color="#1d3354"
            padding="3% 0%"
            className="my-4 shadow_1"
            onClick={() => {
              navigateSignup(5);
            }}
          >
            <span className="fw-700 fs-34 py-3 px-3">Company</span>
          </Box>
        </Box>
        {/* <div className="row text-left">
          <div className="large-list">
            <ul style={{ marginLeft: "0px" }}>
              <li
                className="my-4 shadow_1"
                onClick={() => {
                  navigateSignup(5);
                }}
              >
                <span className="fw-700 fs-34 py-3 px-3">Student</span>
              </li>
              <li
                className="my-4 shadow_1"
                onClick={() => {
                  navigateSignup(5);
                }}
              >
                <span className="fw-700 fs-34 py-3 px-3">Company</span>
              </li>
            </ul>
          </div>
        </div> */}
      </Hidden>
    </Box>
  );
};

export default SignupOptions;

import {
    Grid, Link,
    makeStyles, Typography
} from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { Logger } from "logging-library";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
/* import ScrollToView from "../components/Cards/ScrollToView/ScrollToView"; */
/* import resumeimg from "../assets/png/resume.png";  */ //../../../assets/png/resume.png
import FileViewer from "react-file-viewer";
import { useHistory, useLocation } from "react-router-dom";
import Icon401 from "../../assets/svg/401-Error-Unauthorized-rafiki.svg";
import CommonButton from "../../components/common/CommonButton/CommonButton";
import { APP_ENDPOINT, BackendBaseApi } from "../../constants/constants";
import fetchApi from "../apiCall/fetchApi";
/* import SkillsRatingNew from "../components/Cards/SkillsRating/SkillsRatingNew"; */
import SkillsRatingNew from "../components/Cards/SkillsRating/SkillRatingNew";
import AchievementsNew from "../components/Cards/Students/Achievements/AchievementsNew";
import CerificationNew from "../components/Cards/Students/Cerification/CerificationNew";
import ContactNew from "../components/Cards/Students/Contact/ContactNew";
import EducationNew from "../components/Cards/Students/Education/EducationNew";
import ExpereinceNew from "../components/Cards/Students/Expereince/ExperienceNew";
import HobbiesNew from "../components/Cards/Students/Hobbies/HobbiesNew";
import LanguagesNew from "../components/Cards/Students/Languages/LanguagesNew";
import OverViewNew from "../components/Cards/Students/Overview/OverViewNew";
import PerformActionNew from "../components/Cards/Students/PerformAction/PerformActionNew";
import ProjectNew from "../components/Cards/Students/Project/ProjectNew";
import PublicationsNew from "../components/Cards/Students/Publications/PublicationNew";
import SkillNew from "../components/Cards/Students/Skill/SkillNew";
import CommentSectionNew from "../components/Cards/Students/CommentSection/CommentSectionNew";
import downloadIcon from "../assets/svg/cloud_download.svg";
import { CustomErrorComponent } from "custom-error";
import { useAlert } from "react-alert";

const DownloadIcon = (
  <img src={downloadIcon} height="25px" alt="download-resume" />
);
  
  //import DocViewer, { DocViewerRenderers } from "@neek_hill/react-file-viewer";
  
  const useStyles = makeStyles((theme) => ({
    overviewNew: {
      marginTop: "20px",
      padding: "15px",
      borderRadius: "10px",
      // height: "50px",
      backgroundColor: "#fff",
      color: "#007BFF",
      position: "sticky",
      top: "60px",
      display: "flex",
      // marginBottom: "30px",
      justifyContent: "space-between",
      zIndex: 200,
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
  
    btn: {
      background: "none",
      color: "inherit",
      border: "none",
      //padding: "15px",
      font: "inherit",
      cursor: "pointer",
      outline: "inherit",
      //borderRight: "1px solid #DDDDDD",
      fontWeight: "bold",
      text: "center",
      height: "20px",
      // background: "inherit", */
    },
    overview: {
      marginTop: "20px",
      padding: "15px",
      borderRadius: "10px",
      // height: "50px",
      backgroundColor: "#fff",
      color: "#007BFF",
  
      display: "flex",
      // marginBottom: "30px",
      justifyContent: "space-between",
  
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
  
    btnlast: {
      background: "none",
      color: "inherit",
      border: "none",
      // padding: "15px",
      font: "inherit",
      cursor: "pointer",
      outline: "inherit",
      fontWeight: "bold",
      height: "20px",
      //borderRight: "1px solid #DDDDDD",
      /*  height: "65px",
        background: "inherit", */
    },
  }));
  export default function StudentDetailTrackShare({isPublic}) {
    const classes = useStyles();
  
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userDataError, setUserDataError] = useState(null);
    const location = useLocation();
    const history = useHistory();
    const [paramData, setParamData] = useState(null);
    const [actionsData, setActionsData] = useState(null);
    const [loadingActions, setLoadingActions] = useState(false);
    const [actionsDataError, setActionsDataError] = useState(null);
    const [commentsData, setCommentsData] = useState(null);
    const [loadingComments, setLoadingComments] = useState(false);
    const [commentsError, setCommentsError] = useState(false);
    const [preferencesData, setPreferencesData] = useState(null);
    const [preferencesDataError, setPreferencesDataError] = useState(null);
    const [loadingPreferences, setLoadingPreferences] = useState(false);
    const alert = useAlert();
    const [structuredData, setStructuredData] = useState({
      overviewData: null,
      contactDetails: null,
      skills: [],
      jobRoles: null,
      location: null,
      workExperiece: [],
      education: [],
      projects: [],
      publications: [],
      achievements: [],
      languages: [],
      hobbies: [],
    });
    const [hidden, setHidden] = useState(false);
    const [open, setOpen] = useState(false);
    const skillsRef = useRef(null);
    const workExRef = useRef(null);
    const eduRef = useRef(null);
    const projectRef = useRef(null);
    const resumeRef = useRef(null);
    const otherDetailsRef = useRef(null);
  
    useEffect(() => {
      if (location.search && Object.keys(getSearchParameters()).length > 0) {
        if (Object.keys(getSearchParameters()).includes("user_id"))
          setParamData(getSearchParameters());
        else history.push("/signup/employer");
      } else history.push("/signup/employer");
    }, [location.search]);
  
    useEffect(() => {
      if (paramData?.user_id) {
        setLoading(true);
          axios
          .get(
            BackendBaseApi.NIYUKTI + `api/track_sharer/public_profile/?user_id=${paramData?.user_id}&job_id=${paramData?.job_id}`,
            { withCredentials: true }
          )
          .then((res) => {
              if(res?.data?.success){
                setUserData(res?.data);
                getActionsData();
                getComments();
              }else{
                  setUserDataError(true);
              }
              setLoading(false);
          }).catch(err => {
            if(err?.response?.status === 401){
              setHidden(true);
            }
            else{
              setUserDataError(true);
            }
              setLoading(false);
          })
        }
    }, [paramData?.user_id]);
  
    const getActionsData = () => {
      setLoadingActions(true);
      axios
          .get(
            BackendBaseApi.NIYUKTI + `api/track_sharer/company/job_round_check/?applicant_id=${paramData?.applicant_id}&job_id=${paramData?.job_id}`,
            { withCredentials: true }
          ).then(res => {
              if(res?.data?.success){
                setActionsData(res?.data);
              }else{
                alert.error(res?.data?.error);
              }
            setLoadingActions(false);
          }).catch(err => {
            setLoadingActions(false);
            setActionsDataError(err?.response?.data?.error);
          })
    };
  
    // const getUserPreferences = () => {
    //   fetchApi({
    //     url: `${BackendBaseApi.PRAVESH}api/company/student_user_preferences/?user_id=${paramData?.user_id}`,
    //     setResponse: setPreferencesData,
    //     setLoading: setLoadingPreferences,
    //     setError: setPreferencesDataError,
    //   });
    // };
  
    const getComments = () => {
      setLoadingComments(true);
      axios
          .get(
            BackendBaseApi.NIYUKTI + `api/track_sharer/company/comments/?job_applicant_id=${paramData?.applicant_id}`,
            { withCredentials: true }
          ).then(res => {
              if(res?.data?.success){
                setCommentsData(res?.data);
              }else{
                alert.error(res?.data?.error);
              }
              setLoadingComments(false);
          }).catch(err => {
            setLoadingComments(false);
            setCommentsError(err?.response?.data?.error);
          })

      // fetchApi({
      //   url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/comments/?job_applicant_id=${paramData?.applicant_id}`,
      //   setResponse: setCommentsData,
      //   setLoading: setLoadingComments,
      //   setError: setCommentsError,
      // });
    };
  
    useEffect(() => {
      if (userData?.data) {
        let data = userData?.data;
        if (data?.hiddenData) {
          setHidden(true);
        }
        setStructuredData({
          ...structuredData,
          overviewData: {
            name: data?.first_name + " " + data?.last_name,
            isActive: !data?.student_user_details?.is_blocked,
            resume: data?.student_user_details?.resume,
            profileImage: data?.profile_picture,
          },
          contactDetails: {
            gender: data?.student_user_details?.gender,
            dob: data?.dob,
            currentCity: data?.current_city_name,
            currentCtc: data?.student_user_details?.current_ctc,
            expectedCtc: data?.student_user_details?.expected_ctc,
            noticePeriod: data?.student_user_details?.notice_period,
            workExp: data?.student_user_details?.total_work_exp
              ? data?.student_user_details?.total_work_exp
              : null,
            about: data?.student_user_details?.about,
            phone: data?.phone,
            email: data?.email,
            profile_url: data?.profile_url,
          },
          skills: data?.student_user_details?.skill?.map(
            (item) => item.skill_name && item.skill_name
          ),
          workExperiece: data?.student_user_details?.work_ex?.map((item) => {
            let obj = {
              jobDesignation: item?.job_designation,
              company: item?.company_name,
              jobType: item?.job_type_name,
              jobDescription: item?.job_description,
              timePeriod: item?.start_date
                ? `${moment(item?.start_date).format("MMM, YYYY")}${
                    item?.end_date
                      ? ` - ${moment(item?.end_date).format("MMM, YYYY")}`
                      : " - Present"
                  }`
                : null,
            };
            return obj;
          }),
          certification_data: data?.student_user_details?.certification_data,
          education: data?.student_user_details?.education?.map((item) => {
            let cur_start_date = item?.start_date
              ? moment(item?.start_date).format("YYYY")
              : "NA";
            let cur_end_date = item?.end_date
              ? moment(item?.end_date).format("YYYY")
              : "PRESENT";
            return {
              college: item?.board
                ? item.highest_qualification
                : item?.temp_college_name && item?.college === 142
                ? item?.temp_college_name
                : item?.college_name,
              degreeSpec: item?.board
                ? item?.board_name
                : item?.degree
                ? `${item?.degree}${
                    item?.specialization ? ` in ${item?.specialization}` : ""
                  }`
                : item?.specialization,
              grade: item?.percentage
                ? item?.is_grade_system
                  ? `CGPA: ${item?.percentage}`
                  : `Percentage: ${item?.percentage}`
                : null,
              timePeriod: cur_start_date + " - " + cur_end_date,
              college_name: item?.college_name,
              highest_qualification: item?.highest_qualification,
            };
          }),
          projects: data?.student_user_details?.project?.filter(
            (item) => item.type === "Project"
          ),
          publications: data?.student_user_details?.project?.filter(
            (item) => item.type === "Publications"
          ),
          achievements: data?.student_user_details?.achievements,
          languages: data?.student_user_details?.languages,
          hobbies: data?.student_user_details?.hobbies,
        });
      }
    }, [userData]);
  
    useEffect(() => {
      if (preferencesData?.data?.preference) {
        var temp_data = preferencesData?.data?.preference;
        setStructuredData({
          ...structuredData,
          job_roles: temp_data?.job_roles,
          preferred_location: temp_data?.job_location,
          industry_type: temp_data?.industry_type,
          job_type: temp_data?.job_type ? temp_data?.job_type : "NA",
        });
      }
    }, [preferencesData]);
  
    function getSearchParameters() {
      var prmstr = location.search.substr(1);
      return prmstr != null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
    }
  
    function transformToAssocArray(prmstr) {
      var params = {};
      var prmarr = prmstr.split("&");
      for (var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
      }
      return params;
    }
  
    const scrollToComponent = (type) => {
      if (type === "skills") {
        skillsRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (type === "workEx") {
        workExRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (type === "edu") {
        eduRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (type === "project") {
        projectRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (type === "resume") {
        resumeRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (type === "other") {
        otherDetailsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
  
    const onError = (e) => {
      Logger.logError(e, "error in file-viewer");
    };
  
    const checkResumeTypes = () => {
      
      let fileType = ""; 
      if(structuredData?.overviewData?.resume?.includes(".pdf"))
        fileType =  "pdf";
      else if (structuredData?.overviewData?.resume?.includes(".docx"))
        fileType =  "docx";
      else if (structuredData?.overviewData?.resume?.includes(".png"))
        fileType =  "png";
      else if (structuredData?.overviewData?.resume?.includes(".jpeg"))
        fileType =  "jpeg";
      return fileType;
    };
    const docs = [{ uri: structuredData?.overviewData?.resume }];
    return (
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={24} />
          </div>
        ) : userDataError ? (
          <>
            <div
              style={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                color="primary"
                style={{ fontWeight: "400" }}
              >
                {userDataError}
              </Typography>
            </div>
          </>
        ) : hidden ? (
          <>
            <div
              style={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={Icon401} style={{ height: "400px", width: "400px" }} />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Grid container spacing={3}>
                <Grid item sm={8}>
                  {structuredData?.overviewData && (
                    <OverViewNew
                      data={structuredData?.overviewData}
                      relevanceScore={actionsData?.data?.relevance_score}
                      scoreData={actionsData?.data?.student_job_matching_data}
                      createdData={actionsData?.data?.create_date}
                      actionsData={actionsData?.data}
                      contactDetails={structuredData?.contactDetails}
                      workExperience={structuredData?.workExperiece}
                      education={structuredData?.education}
                      salaryData={structuredData?.contactDetails}
                    />
                  )}
                  <div style={{ paddingTop: "20px" }}>
                    <ContactNew data={structuredData?.contactDetails} />
                  </div>
                  {actionsData?.data?.student_job_matching_data && (
                    <div style={{ paddingTop: "20px" }}>
                      <SkillsRatingNew
                        relevanceScore={actionsData?.data?.relevance_score}
                        data={actionsData?.data?.student_job_matching_data}
                      />
                    </div>
                  )}
                  {/* <br /> */}
  
                  <div className={classes.overviewNew}>
                    <button
                      className={classes.btn}
                      variant="text"
                      style={{
                        cursor: "cursor",
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      View
                    </button>
                    {structuredData?.skills?.length > 0 && (
                      <button
                        className={classes.btn}
                        variant="text"
                        onClick={() => {
                          scrollToComponent("skills");
                        }}
                      >
                        Skills
                      </button>
                    )}
                    {structuredData?.workExperiece?.length > 0 && (
                      <button
                        className={classes.btn}
                        variant="text"
                        onClick={() => {
                          scrollToComponent("workEx");
                        }}
                      >
                        Work Experience
                      </button>
                    )}
                    {structuredData?.education?.length > 0 && (
                      <button
                        className={classes.btn}
                        variant="text"
                        onClick={() => {
                          scrollToComponent("edu");
                        }}
                      >
                        Education
                      </button>
                    )}
                    {structuredData?.projects?.length > 0 && (
                      <button
                        className={classes.btn}
                        variant="text"
                        onClick={() => {
                          scrollToComponent("project");
                        }}
                      >
                        Projects
                      </button>
                    )}
                    <button
                      className={classes.btn}
                      onClick={() => {
                        scrollToComponent("other");
                      }}
                    >
                      Other Details
                    </button>
                    <button
                      className={classes.btnlast}
                      variant="text"
                      onClick={() => {
                        scrollToComponent("resume");
                      }}
                    >
                      Resume
                    </button>
                  </div>
                  <div
                    style={{
                      height: "80vh",
                      overflow: "auto",
                      padding: "1px",
                    }}
                  >
                    {/*  <AboutNew data={structuredData?.contactDetails} />
                  <br /> */}
  
                    {structuredData?.skills?.length > 0 && (
                      <div ref={skillsRef} style={{ paddingTop: "20px" }}>
                        <SkillNew data={structuredData?.skills} />
                      </div>
                    )}
  
                    {/* </Grid>
                <Grid item sm={4}> */}
                    {structuredData?.workExperiece?.length > 0 && (
                      <div ref={workExRef} style={{ paddingTop: "20px" }}>
                        <ExpereinceNew data={structuredData?.workExperiece} />
                      </div>
                    )}
                    {structuredData?.education?.length > 0 && (
                      <div ref={eduRef} style={{ paddingTop: "20px" }}>
                        <EducationNew data={structuredData?.education} />
                      </div>
                    )}
                    {structuredData?.projects?.length > 0 && (
                      <div ref={projectRef} style={{ paddingTop: "20px" }}>
                        <ProjectNew data={structuredData?.projects} />
                      </div>
                    )}
                    {structuredData?.publications?.length > 0 && (
                      <>
                        <div style={{ paddingTop: "20px" }}>
                          <PublicationsNew data={structuredData?.publications} />
                        </div>
                      </>
                    )}
                    {structuredData?.certification_data?.length > 0 && (
                      <>
                        <div style={{ paddingTop: "20px" }}>
                          <CerificationNew
                            data={structuredData?.certification_data}
                          />
                        </div>
                      </>
                    )}
  
                    <div ref={otherDetailsRef}>
                      {structuredData?.achievements?.length > 0 && (
                        <>
                          <div style={{ paddingTop: "20px" }}>
                            <AchievementsNew
                              data={structuredData?.achievements}
                            />
                          </div>
                        </>
                      )}
                      {structuredData?.languages?.length > 0 && (
                        <>
                          <div style={{ paddingTop: "20px" }}>
                            <LanguagesNew data={structuredData?.languages} />
                          </div>
                        </>
                      )}
                      {structuredData?.hobbies?.length > 0 && (
                        <>
                          <div
                            style={{ paddingTop: "20px", paddingBottom: "20px" }}
                          >
                            <HobbiesNew data={structuredData?.hobbies} />
                          </div>
                        </>
                      )}
                    </div>
                    <br />
                    {structuredData?.overviewData?.resume && (
                      <div ref={resumeRef} style={{ paddingTop: "20px" }}>
                        {/*   <iframe
                          style={{ width: "100%", height: 800 }}
                          src={structuredData?.overviewData?.resume}
                        /> */}
                        {/* <div className={classes.overview}>
                          <div>Resume</div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.open(structuredData?.overviewData?.resume);
                            }}
                          >
                            Download
                          </div>
                        </div>
                        <br /> */}
                        <div
                        style={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "15px",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Typography variant="h5" style={{ color: "black" }}>
                            Resume
                          </Typography>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.open(structuredData?.overviewData?.resume);
                            }}
                          >
                            {DownloadIcon}
                          </div>
                        </div>
                        <FileViewer
                          fileType={checkResumeTypes()}
                          filePath={structuredData?.overviewData?.resume}
                          // filePath={"http://praveshtest1.getwork.org/api/admin/bulk/preview/resume/6c2954ae81f84dd6876911d400d4c4db.docx"}
                          errorComponent={CustomErrorComponent}
                          onError={onError}
                        />
                        </div>
                        
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item sm={4}>
                <div className={classes.overview}>
                  <div>
                  <div style={{ display: "flex" }} >
                  <Typography variant="h6" style={{ color: "#939292", fontWeight: "400",fontSize:"14px" }}>
                        Job Profile :&nbsp;
                      </Typography>
                    <Typography
                      variant="h6"
                      style={{  marginBottom: "10px", fontWeight: "400", fontSize:"14px" }}
                    >
                      {actionsData?.data?.job_title}
                    </Typography>
                    </div>
                  <div style={{ display: "flex" }}>
                      <Typography style={{ color: "#939292", fontWeight: "400",fontSize:"14px" }}>
                        Current Status :&nbsp;
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "400",fontSize:"14px" }}
                      >{` ${
                        [1, 19, 20, 17, 18, 14, 13].includes(actionsData?.data?.current_job_round?.status_id)
                          ? actionsData?.data?.current_job_round?.status_name
                          : actionsData?.data?.current_job_round?.round_name
                      }`}</Typography>
                  </div>
                  </div>
                </div>
                <br />

                  {paramData?.applicant_id && paramData?.job_id && (
                    <>
                      <PerformActionNew
                        details={{
                          name: structuredData?.overviewData?.name,
                          applicantId: paramData?.applicant_id,
                          user_id: paramData?.user_id,
                          job_id: paramData?.job_id,
                          round_id:
                            actionsData?.data?.current_job_round?.round_id,
                          status_id:
                            actionsData?.data?.current_job_round?.status_id,
                          round_name:
                            actionsData?.data?.current_job_round?.round_name,
                        }}
                        getActionsData={getActionsData}
                        moveNextArray={actionsData?.data?.job_round_list?.map(
                          (item) => {
                            return {
                              status_id: item.status_id,
                              name: item.tooltip,
                              round_id: item.round_id
                                ? item.round_id
                                : `gw-${Math.floor(Math.random() * 100)}`,
                            };
                          }
                        )}
                        isPublic={isPublic}
                      />
  
                      <br />
                      <CommentSectionNew
                        commentsData={
                          commentsData?.data?.comments
                            ? commentsData?.data?.comments
                            : []
                        }
                        loadingComments={loadingComments}
                        commentsError={commentsError}
                        currentApplicant={{
                          applicantId: paramData?.applicant_id,
                          applicantName: structuredData?.overviewData?.name,
                          status_id:
                            actionsData?.data?.current_job_round?.status_id,
                          status_name:
                            actionsData?.data?.current_job_round?.status_name,
                        }}
                        getComments={getComments}
                        isPublic={isPublic}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </>
    );
  }
  
import { Box, Button, makeStyles } from "@material-ui/core";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import React, { useEffect, useState } from "react";
import facebook from "../../../../assets/common/facebook.png";
import line from "../../../../assets/images/lineOr.png";

import { BackendBaseApi, emailRegex, FACEBOOK_APP_ID, FACEBOOK_APP_SECRET, GOOGLE_CLIENT_ID } from "../../../../constants/constants";
const SignupCondition = (props) => {
  const { responseFacebook, responseGoogle, setStep, type } = props;
  const classes = useStyles();

  return (
    <div
      // className="row mt-md-0 mt-4"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backdropFilter: "blur(30px)",
        boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
        border: "solid 1px #f1f1f1",
        borderRadius: 30,
        padding: 20,
      }}
    >
      {" "}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p className={classes.heading}>Sign up</p>
        <span className={classes.subTitle}>as {type === "company" ? " Company" : " Student"}</span>
      </div>
      <Box display="flex" justifyContent="space-between">
        {type === "student" ? (
          <>
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  className={classes.googleButton}
                  variant="contained"
                  startIcon={<img src={facebook} alt="" height="25px" />}
                  style={{ marginRight: 10 }}
                >
                  SIGN UP WITH FACEBOOK
                </Button>
              )}
              disableMobileRedirect={true}
            />
            <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
              // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  className={classes.googleButton}
                  variant="contained"
                  startIcon={<img src="https://img.icons8.com/fluent/48/000000/google-logo.png" alt="" height="25px" />}
                >
                  SIGN UP WITH <br />
                  GOOGLE
                </Button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
            />
          </>
        ) : (
          <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
            // clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                className={classes.googleButton}
                variant="contained"
                startIcon={<img src="https://img.icons8.com/fluent/48/000000/google-logo.png" alt="" height="25px" />}
              >
                SIGN UP WITH GOOGLE
              </Button>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          />
        )}
      </Box>
      <img src={line} alt="" width="70%" style={{ marginBottom: 20, marginTop: 20, height: 15 }} />
      <p style={{ textAlign: "center", fontSize: 15, marginBottom: 50 }}>
        <b>
          {" "}
          Sign up with{" "}
          <span style={{ color: "#2196f3", cursor: "pointer" }} onClick={() => setStep(1)}>
            Email
          </span>
        </b>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "70%",
          margin: "auto",
        }}
      >
        <p
          style={{
            fontSize: 15,
            textAlign: "center",
          }}
        >
          By signing up you indicate that you have read and agree to the{" "}
          <span style={{ fontWeight: "bold" }}>
            <a href="/terms-and-services">Terms and Conditions</a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignupCondition;
const useStyles = makeStyles((theme) => ({
  googleButton: {
    background: "#fff",
    color: "black",
    borderRadius: 30,
    fontWeight: "normal",
    fontSize: 13,
    width: "100%",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },
  heading: {
    textAlign: "center",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: "0rem",
    color: "#4f4f4f",
  },
  subTitle: {
    color: "grey",
    textAlign: "center",
    fontSize: 14,
    marginBottom: 25,
  },
  subTitleNew: {
    color: "grey",
    textAlign: "center",
    fontSize: 14,
    marginBottom: 20,
  },
}));

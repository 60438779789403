import React from "react";
import { Paper, Typography, Button, Grid } from "@material-ui/core";
import b1 from "../../../assets/svg/plan/b1.svg";
import b2 from "../../../assets/svg/plan/b2.svg";
import b3 from "../../../assets/svg/plan/b3.svg";
import b4 from "../../../assets/svg/plan/b4.svg";
import { ContactlessTwoTone } from "@material-ui/icons";

export default function BillingOverview() {
  return (
    <>
      <Paper
        style={{
          padding: "20px",
          borderRadius: "20px",
          //   boxShadow: "0px, 0px rgba(0, 0, 0, 0.09)",
          boxShadow: "none",
          border: ".5px solid #6C757D",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <Typography variant="h6" component="h3">
            Billing Overview
          </Typography>
        </div>
        {data.map((item) => (
          <Grid container spacing={2}>
            <Grid item>
              <img src={item.icon} height="15px" />
            </Grid>
            <Grid item md={3}>
              <div style={{ display: "flex" }}>
                <Typography variant="body2">{item.title}</Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <Typography variant="body2">{item.res}</Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>
    </>
  );
}

const data = [
  {
    icon: b1,
    title: "Amount",
    res: "₹20,000",
  },
  {
    icon: b2,
    title: "Next Billing Date",
    res: "24/07/2022",
  },
  {
    icon: b3,
    title: "Billing Frequency",
    res: "Monthly",
  },
  {
    icon: b4,
    title: "Payment Method",
    res: "Debit Card",
  },
];

import React, { useEffect, useState } from "react";
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  ListItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import fetchApi from "../../../apiCall/fetchApi";
import { BackendBaseApi } from "../../../../constants/constants";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  btn: {
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "30px",
    height: "40px",
    color: "#6C757D",
    display: "flex",
    border: "none",
    backgroundColor: "#fff",
    width: "100%",
    fontWeight: "500",
  },
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //   backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        ///  color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function AssignMember(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const alert = useAlert();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchApi({
      url: `${BackendBaseApi.PRAVESH}api/company/settings/team/`,
      setResponse: setUserData,
      setLoading: setLoading,
      setError: setDataError,
    });
  }, []);

  const handleSubmit = (currentItem) => {
    fetchApi({
      url: `${BackendBaseApi.NIYUKTI}api/manage_jobs/company/assign_user/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        student_data: props?.selectedRow?.details?.map((item) => {
          return {
            applicant_first_name: item.name.substring(
              0,
              item.name.indexOf(" ")
            ),
            applicant_last_name: item.name.substring(
              item.name.indexOf(" ") + 1
            ),
            user_id: currentItem?.user_id,
            applicant_id: item?.id,
            status: props?.activeStatusObject?.status_id,
            is_active: 1,
          };
        }),
      }),
      setLoading: setLoadingSubmit,
    });
    handleClose();
  };

  return (
    <>
      <div>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="outlined"
          endIcon={<ExpandMoreIcon />}
          className={classes.btn}
          onClick={handleClick}
        >
          {loadingSubmit ? (
            <div style={{ width: 100 }}>
              {" "}
              <CircularProgress size={16} />{" "}
            </div>
          ) : props.fromStudentDetail ? (
            "Assign Team"
          ) : (
            "Assign Team Member"
          )}
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {loading ? (
            <StyledMenuItem style={{ width: 100 }}>
              {" "}
              <CircularProgress size={18} />
            </StyledMenuItem>
          ) : (
            userData?.data?.length > 0 &&
            userData?.data?.map((item) => (
              <StyledMenuItem onClick={() => handleSubmit(item)}>
                <Typography variant="body2" color="inherit" noWrap>
                  {item?.first_name + " " + item?.last_name}
                </Typography>
              </StyledMenuItem>
            ))
          )}
        </StyledMenu>
      </div>
    </>
  );
}

import React, { Fragment } from "react";
import "./JobOverview.css";
import moment from "moment";
import { printNumber } from "../../../utils/common/printNumber.js";
import ReactHtmlParser from "react-html-parser";
import { Box, Chip, Avatar, Button, Paper, createStyles } from "@material-ui/core";
import { BootstrapTooltipNew } from "../../../pages/common/Tooltip/Tooltip";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

import calendar from "../../../assets/job/calendar.svg";
import stopwatch from "../../../assets/job/stopwatch.svg";
import skills from "../../../assets/job/skills.svg";
import location from "../../../assets/job/location.svg";
import college from "../../../assets/job/college.svg";
import company from "../../../assets/job/company.png";
import money from "../../../assets/job/money.svg";
import job_b from "../../../assets/job/job_b.svg";
import permanentJob from "../../../assets/job/permanent-job.png";
import serviceBond from "../../../assets/job/service-bond.png";
import documents from "../../../assets/job/documents.png";
import vacancy from "../../../assets/job/vacancy.png";
import hiringType from "../../../assets/job/hiringType.png";
import equity from "../../../assets/job/equity.png";
import ppo from "../../../assets/job/ppo.png";
import jobRounds from "../../../assets/job/jobRounds.png";
import description from "../../../assets/job/description.png";
import preferences from "../../../assets/job/preferences.png";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: "#3e3d3d",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function JobOverviewContent({ jobData }) {
  const classes = useStyles();

  const generateTooltipContent = (arr) => {
    let str = "";
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1 ? (str += item["name"] || item["specialization_name"] + " | ") : (str += item["name"] || item["specialization_name"])
    );
    return str;
  };

  const generateTooltipContentClg = (arr) => {
    let str = "";
    arr.forEach((item, index) => (arr.indexOf(item) !== arr.length - 1 ? (str += item["specialization_name"] + " | ") : (str += item["specialization_name"])));
    return str;
  };

  return (
    <div id="overview_box">
      {jobData.job_status === "CLOSE" ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "4px", textAlign: "center" }}>
          <span style={{ color: "red", marginTop: "4px" }}>&#9888; Job Already Closed !</span>
        </div>
      ) : (
        <>
          <>
            {jobData?.up_coming_state?.code === -1 && (
              <div style={{ display: "flex", justifyContent: "center", marginTop: "4px", textAlign: "center" }}>
                <span style={{ color: "red", marginTop: "4px" }}>&#9888; Job Deadline Passed !</span>
              </div>
            )}
          </>
          <>
            {jobData?.up_coming_state?.code === 1 && (
              <div style={{ display: "flex", justifyContent: "center", marginTop: "4px", textAlign: "center" }}>
                <span style={{ color: "green", marginTop: "4px" }}>&#9888; Upcoming Job !</span>
              </div>
            )}
          </>
        </>
      )}
      <Box display="flex" flexWrap="wrap" mx={1}>
        {/* Job Location */}
        <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
          <div id="box_div">
            <img id="box_img_style" src={location} alt="emp_type" />
            <div id="box_img_span">
              <span id="box_heading">Job Location </span>
              <span id="box_value_shift">{jobData.job_location.length > 0 ? jobData.job_location.map((e) => e.city).join(", ") : "PAN India"}</span>
            </div>
          </div>
        </Box>
        {/* Employment Type */}
        {jobData.employment_type_name && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={permanentJob} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Employment Type </span>
                <span id="box_value_shift">{jobData.employment_type_name}</span>
              </div>
            </div>
          </Box>
        )}
        {/* Hiring Type */}
        {jobData.hiring_type && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={hiringType} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Hiring Type </span>
                <span id="box_value_shift">{jobData.hiring_type}</span>
              </div>
            </div>
          </Box>
        )}
        {/* Apply Before Duration */}
        {jobData.apply_end_date && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={stopwatch} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Apply By </span>
                <span id="box_value_shift">{jobData.apply_end_date && moment(jobData.apply_end_date).format("DD MMM'YY")}</span>
              </div>
            </div>
          </Box>
        )}
        {/* Job Type */}
        {jobData.job_type_name && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={job_b} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Job Type </span>
                <span id="box_value_shift">{jobData.job_type_name}</span>
              </div>
            </div>
          </Box>
        )}
        {/* Internship Duration */}
        {jobData.job_type_name === "Internship" && (jobData.job_duration_start || jobData.job_duration_end) && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={calendar} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading"> Duration </span>
                <span id="box_value_shift">
                  {jobData.job_duration_start && moment(jobData.job_duration_start).format("DD MMM'YY")}
                  {jobData.job_duration_start && jobData.job_duration_end && "  to  "}
                  {jobData.job_duration_end && moment(jobData.job_duration_end).format("DD MMM'YY")}
                </span>
              </div>
            </div>
          </Box>
        )}
        {/* Job Start Date */}
        {jobData.job_type_name !== "Internship" && jobData.job_duration_start && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={calendar} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Job Start Date</span>
                <span id="box_value_shift">{jobData.job_duration_start && moment(jobData.job_duration_start).format("DD MMM'YY")}</span>
              </div>
            </div>
          </Box>
        )}
        {/* Stipend */}
        {jobData.job_type_name === "Internship" && jobData.stipend && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={money} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Stipend </span>
                <span id="box_value_shift">
                  {jobData.stipend && printNumber(parseInt(jobData.stipend))}
                  {` Per Month`}
                </span>
              </div>
            </div>
          </Box>
        )}
        {/* Compensation Offered */}
        {(jobData.ctc_min || jobData.ctc_max) && ((jobData.job_type_name === "Internship" && jobData.ppo) || jobData.job_type_name !== "Internship") && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={money} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Compensation {jobData.job_type_name === "Internship" && `after PPO`}</span>
                <span id="box_value_shift">
                  {jobData.ctc_min && printNumber(parseInt(jobData.ctc_min))}
                  {jobData.ctc_min && jobData.ctc_max && " - "}
                  {jobData.ctc_max && printNumber(parseInt(jobData.ctc_max))}
                  {jobData.salary_payment_type && ` ${jobData.salary_payment_type}`}
                </span>
              </div>
            </div>
          </Box>
        )}
        {/* Equity Offered */}
        {(jobData.equity_min || jobData.equity_max) && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={equity} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Equity </span>
                <span id="box_value_shift">
                  {jobData.equity_min && printNumber(parseInt(jobData.equity_min))}
                  {jobData.equity_min && jobData.equity_max && " - "}
                  {jobData.equity_max && printNumber(parseInt(jobData.equity_max))}
                  {jobData.salary_payment_type && ` ${jobData.salary_payment_type}`}
                </span>
              </div>
            </div>
          </Box>
        )}
        {/* PPO Offered */}
        {jobData.job_type_name === "Internship" && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={ppo} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">PPO Offered </span>
                <span id="box_value_shift">{jobData.ppo ? "Yes" : "No"}</span>
              </div>
            </div>
          </Box>
        )}
        {/* Vacancies */}
        {jobData.vacancy && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={vacancy} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Vacancies </span>
                <span id="box_value_shift"> {jobData.vacancy} </span>
              </div>
            </div>
          </Box>
        )}
        {/* Service Bond */}
        {jobData.service_bond && (
          <Box id="overview_detail_box" p={1} m={0.5} px={0} mx={0}>
            <div id="box_div">
              <img id="box_img_style" src={serviceBond} alt="emp_type" />
              <div id="box_img_span">
                <span id="box_heading">Service Bond </span>
                <span id="box_value_shift">
                  {jobData.service_bond}
                  {` Months`}
                </span>
              </div>
            </div>
          </Box>
        )}
      </Box>
      {/* Job Description */}
      {jobData.job_description && (
        <Box display="flex" flexWrap="wrap" mx={1}>
          <div id="box_img_span_box" style={{ width: "100%" }}>
            <img id="box_img_style" src={description} alt="emp_type" />
            <span id="box_heading_second">Job Description </span>
          </div>
          <span style={{ marginLeft: "5%" }}>
            <span>{ReactHtmlParser(jobData.job_description.toString("html"))}</span>
          </span>
        </Box>
      )}
      {/* On Campus Colleges Description */}
      {jobData.colleges.length > 0 && jobData.hiring_type === "ON CAMPUS" && (
        <Box display="flex" flexWrap="wrap" m={1}>
          <div id="box_img_span_box" style={{ width: "100%" }}>
            <img id="box_img_style" src={college} alt="emp_type" />
            <span id="box_heading_second">On Campus - College Details </span>
          </div>
          <div className="col-md-12" id="box_value" style={{ paddingRight: "8px", marginTop: "2%" }}>
            <TableContainer id="college_table" component={Paper} style={{ maxHeight: "400px", overflow: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ position: "sticky", top: "0", zIndex: 10 }}>
                  <TableRow>
                    <StyledTableCell> College </StyledTableCell>
                    <StyledTableCell align="left"> Degrees </StyledTableCell>
                    <StyledTableCell align="left"> Status </StyledTableCell>
                    <StyledTableCell align="left"> Application Date </StyledTableCell>
                    {/* <StyledTableCell align="left"> Apply End Date </StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobData.colleges.map((colgObj, ind) => (
                    <StyledTableRow key={ind}>
                      <StyledTableCell component="th" scope="row">
                        {colgObj.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {colgObj?.preferences?.map((prefObj, ind1) => (
                          <Fragment key={ind1}>
                            {colgObj?.preferences?.indexOf(prefObj) !== colgObj?.preferences?.length - 1 ? (
                              <>
                                <BootstrapTooltipNew title={generateTooltipContentClg(prefObj.specialization)} placement="top-start">
                                  <Button id="degree_button_clg"> {prefObj?.degree_name} </Button>
                                </BootstrapTooltipNew>
                                {" | "}
                              </>
                            ) : (
                              <>
                                <BootstrapTooltipNew title={generateTooltipContentClg(prefObj.specialization)} placement="top-start">
                                  <Button id="degree_button_clg"> {prefObj.degree_name} </Button>
                                </BootstrapTooltipNew>{" "}
                              </>
                            )}
                          </Fragment>
                        ))}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        {colgObj.is_rejected && !colgObj.is_approved && <>Rejected</>}
                        {!colgObj.is_rejected && colgObj.is_approved && <>Accepted</>}
                        {!colgObj.is_rejected && !colgObj.is_approved && <>Pending</>}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row" style={{ whiteSpace: "nowrap" }}>
                        {colgObj.apply_start_date && moment(colgObj.apply_start_date).format("DD MMM'YY")}
                        {colgObj.apply_start_date && colgObj.apply_end_date && " - "}
                        {colgObj.apply_end_date && moment(colgObj.apply_end_date).format("DD MMM'YY")}
                      </StyledTableCell>

                      {/* <StyledTableCell component="th" scope="row" style={{ width: "150px" }}>
                                                {printDate(colgObj.apply_end_date)}
                                            </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      )}
      {/* Job skills */}
      {jobData.skills.length > 0 && (
        <Box display="flex" flexWrap="wrap" m={1}>
          <div id="box_img_span_box" style={{ width: "100%" }}>
            <img id="box_img_style" src={skills} alt="emp_type" />
            <span id="box_heading_second">Skills Required </span>
          </div>
          <span id="box_value" style={{ marginLeft: "3%" }}>
            {jobData.skills.map((skill, key) => {
              return (
                <Chip
                  variant="outlined"
                  size="medium"
                  key={key}
                  avatar={
                    <Avatar variant="circle" className={classes.small}>
                      {skill.skill_name ? skill.skill_name.substring(0, 1) : "S"}
                    </Avatar>
                  }
                  label={<b style={{ fontSize: "16px" }}>{skill.skill_name}</b>}
                  color="primary"
                  style={{ margin: "0 8px 8px" }}
                />
              );
            })}
          </span>
        </Box>
      )}
      {/* Preferences */}
      {
        <Box display="flex" flexWrap="wrap" m={1}>
          <div id="box_img_span_box" style={{ width: "100%" }}>
            <img
              id="box_img_style"
              src={preferences}
              alt="emp_type"
              // style={{backgroundColor:'#cccccc', borderRadius:'50%'}}
            />
            <span id="box_heading_second">Preferences </span>
          </div>
          <span id="box_value" style={{ marginLeft: "10px" }}>
            <ul>
              {((jobData?.experience && jobData?.experience.length > 0) || jobData.eligibility_criteria?.experience.length > 0) && (
                <li>
                  <b>Work Experience: </b>
                  {jobData.experience ? jobData.experience.join(", ") : jobData.eligibility_criteria.experience.join(", ")}
                </li>
              )}
              {((jobData?.graduation_years && jobData?.graduation_years.length > 0) || jobData.eligibility_criteria?.graduation_years.length > 0) && (
                <li>
                  <b>Eligible Graduation Years: </b>
                  {jobData.graduation_years ? jobData.graduation_years.join(", ") : jobData.eligibility_criteria.graduation_years.join(", ")}
                </li>
              )}
              {jobData.percentage && (
                <li>
                  <b> Min. Percentage: </b> {jobData.percentage} %{" "}
                </li>
              )}
              {Object.keys(jobData.qualifications).length > 0 && (
                <li>
                  <b> Qualifications Required: </b>
                  <span className="mr-2">
                    {Object.keys(jobData.qualifications).map((item) => (
                      <>
                        {Object.keys(jobData.qualifications).indexOf(item) !== Object.keys(jobData.qualifications).length - 1 ? (
                          <>
                            <BootstrapTooltipNew title={generateTooltipContent(jobData.qualifications[item])} placement="top-start">
                              <Button id="degree_button"> {item} </Button>
                            </BootstrapTooltipNew>
                            {" | "}
                          </>
                        ) : (
                          <>
                            <BootstrapTooltipNew title={generateTooltipContent(jobData.qualifications[item])} placement="top-start">
                              <Button id="degree_button"> {item} </Button>
                            </BootstrapTooltipNew>
                          </>
                        )}
                      </>
                    ))}
                  </span>
                </li>
              )}
            </ul>
          </span>
        </Box>
      }
      {/* Documents Required */}
      {jobData.documents.length > 0 && (
        <Box display="flex" flexWrap="wrap" m={1}>
          <div id="box_img_span_box" style={{ width: "100%" }}>
            <img id="box_img_style" src={documents} alt="emp_type" />
            <span id="box_heading_second">Documents Required </span> <br />
          </div>
          <span id="box_value" style={{ marginLeft: "10px" }}>
            <ul>
              {jobData.documents.map((doc, key) => {
                return <li key={key}>{doc}</li>;
              })}
            </ul>
          </span>
        </Box>
      )}
      {/* Job Rounds */}
      {jobData.job_rounds.length > 0 && (
        <Box display="flex" flexWrap="wrap" m={1}>
          <div id="box_img_span_box" style={{ width: "100%" }}>
            <img id="box_img_style" src={jobRounds} alt="emp_type" />
            <span id="box_heading_second">Job Rounds </span> <br />
          </div>
          <span id="box_value" style={{ marginLeft: "10px" }}>
            <ul>
              {jobData.job_rounds.map((round, key) => {
                return (
                  <li key={key}>
                    {" "}
                    <b>Round {key + 1}: </b>
                    {round.round_name}
                  </li>
                );
              })}
            </ul>
          </span>
        </Box>
      )}
      {/* About Company */}
      {(jobData.company_description || jobData.industry_name) && (
        <Box display="flex" flexWrap="wrap" mx={1}>
          <div id="box_img_span_box" style={{ width: "100%" }}>
            <img id="box_img_style" src={company} alt="emp_type" />
            <span id="box_heading_second">About {jobData.company_name} </span> <br />
          </div>
          <span id="box_value">
            {jobData.company_description && (
              <div id="box_value_description">
                <p> {jobData.company_description} </p>
              </div>
            )}
            <ul>
              {jobData.industry_name && (
                <li>
                  <b>Industry: </b> {jobData.industry_name}
                </li>
              )}
              {jobData.company_size && (
                <li>
                  <b>Company Size: </b> {jobData.company_size}
                </li>
              )}
              {jobData.company_location && (
                <li>
                  <b>Office: </b> {jobData.company_location}
                </li>
              )}
              {jobData.company_website && (
                <li>
                  <b>Check out us @ </b>
                  <span style={{ cursor: "pointer", color: "rgb(6, 69, 173)" }} onClick={(e) => window.open(jobData.company_website)}>
                    {jobData.company_website}
                  </span>
                </li>
              )}
            </ul>
          </span>
        </Box>
      )}
    </div>
  );
}

export default JobOverviewContent;

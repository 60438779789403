import {
  CircularProgress, Grid, TextField, Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import Countdown from "react-countdown";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import {BackendBaseApi} from  "../../../../../constants/constants";
import { SettingsSystemDaydreamOutlined } from "@material-ui/icons";


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {/* <Typography variant="h6">{children}</Typography> */}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
    },
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    display: "flex",
    borderRadius: "30px",
    boxShadow: "none",
    height: "40px",
    fontWeight: "400",
  },
  card: {
    //paddingBottom: "10px",
  },
  floatingLabelFocusStyle: {
    color: "#b0b6ba",
    fontSize: "14px",
    fontFamily: "Nunito",
  },
  btngrid: {
    display: "flex",
    //paddingLeft: "25px",
    marginTop: "50px",
    justifyContent: "flex-start",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#fff",
  },
  title: {
    flex: 1,
    textAlign: "center",
    marginRight: "50px",
    fontFamily: "Nunito",
  },
  //   upload: {
  //     "@media only screen and (max-width: 2000px)": {
  //       marginTop: "100px",
  //     },
  //     "@media only screen and (max-width: 1700px)": {
  //       marginTop: "70px",
  //     },
  //     "@media only screen and (max-width: 1300px)": {
  //       marginTop: "100px",
  //     },
  //   },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#007bff",
      fontFamily: "Nunito",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "solid 1.5px #e2e6ea",
        borderRadius: "30px",
        padding: "15px",
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderRadius: "30px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
        borderRadius: "30px",
      },
    },
  },
})(TextField);

export default function EnterOtp({
  setStep,
  email,
  setEmail,
  checkUser,
  otpEditStep
}) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [timerComplete, setTimerComplete] = React.useState(false);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const handleChange = (code) => {
    setCode(code);
    setCodeError(null);
  };

  const resendOtp = () => {
    axios.post(`${BackendBaseApi.PRAVESH}api/resend_otp/`, { email: email, user_type: "company" })
      .then((res) => {
        if (res?.data?.success) {
          alert.success(res?.data?.data?.message);
          setTimerComplete(false);
        } else {
          if (res?.data?.data?.code === 2) {
            alert.error(res?.data?.data?.code_text);
          } 
          else alert.error(res?.data?.error);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const validateDataOtp = () => {
    if (!code) {
      setCodeError("Please Enter Your Verification Code");
      return;
    }
    if (code && code?.length < 4) {
      setCodeError("Please Enter Your Verification Code Correctly");
      return;
    }

    setLoadingSubmit(true);
    
    axios.post(`${BackendBaseApi.PRAVESH}api/company_saas/login/validate/`,
     { email: email, user_type: "company", otp: code },
     { withCredentials: true }
     )
      .then((res) => {
        if (res?.data?.success) {
          // checkUser(res.data.data.token);
          // handleCloseotp();
          alert.success(res?.data?.data?.message);
          if(res?.data?.data?.code === 1){
            setStep(5);
          }else if(res?.data?.data?.code === 3){
            setStep(6);
            setEmail(res?.data?.data?.email);
          }else if (res?.data?.data?.code === 2){
            checkUser(res?.data?.data?.token, setLoadingSubmit);
          }else if(res?.data?.data?.code === 4){
            setStep(7);
            // setEmail(res?.data?.data?.email);
          }

        } else {
          alert.error(res?.data?.error);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  return (
    <div>
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          fontWeight: "400",
          marginBottom: "20px",
        }}
      >
        {otpEditStep === 4 ?  "Verify with OTP" : "Login with OTP"}
      </Typography>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            fontWeight: "500",
            padding: 12,
          }}
        >
          <span style={{ color: "#707070" }}>Sent to</span>{" "}
          <span style={{ color: "#007bff" }}> {email}</span>
        </Typography>
        <EditIcon
          style={{
            color: "#007bff",
            fontSize: "18px",
            cursor: "pointer",
            marginLeft: "-7px",
          }}
          onClick={() => {setStep(otpEditStep)}}
        />
      </div>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "400",
                  padding: 6,
                }}
              >
                Enter OTP
              </Typography>
              <OtpInput
                value={code}
                onChange={(e) => handleChange(e)}
                numInputs={4}
                separator={<span style={{ width: "20px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: ".5px solid #a5a5a5",
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "20px",
                  fontFamily: "Nunito",
                  // color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: 12,
                }}
              >
                <span style={{ color: "#ed1111" }}> </span>{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  padding: 12,
                }}
              >
                <span
                  style={
                    timerComplete
                      ? { color: "#007bff", cursor: "pointer" }
                      : { color: "#707070" }
                  }
                  onClick={() => {
                    if (timerComplete) resendOtp();
                  }}
                >
                  Resend OTP
                </span>
                {!timerComplete && (
                  <span style={{ color: "#707070" }}>{""}&nbsp; in </span>
                )}
                <span style={{ color: "#007bff" }}>
                  {!timerComplete && (
                    <Countdown
                      date={Date.now() + 30000}
                      onComplete={() => setTimerComplete(true)}
                      renderer={(props) => (
                        <div>
                          <b>{props.seconds + " sec"}</b>
                        </div>
                      )}
                    />
                  )}
                </span>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} style={{ display: "grid" }}>
            {" "}
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                validateDataOtp();
              }}
              color="primary"
            >
              {loadingSubmit ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                " Verify"
              )}
            </Button>
            <Typography
              variant="body2"
              style={{
                color: "#A3A3A3",
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                fontWeight: "600",
                padding: 12,
              }}
            >
              Check spam folders if email is not in the inbox.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

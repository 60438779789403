import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import warning from "../../../assets/warning_static.png";

const WarningBox = ({ dashboard }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      {localStorage.getItem("comp_profile_completed") === "false" && (
        <Box className={dashboard ? classes.warningBoxDashboard : classes.warningBox}>
          <p style={{ fontSize: 14 }}>
            <img src={warning} alt="" height="18px" />
            &nbsp; Please fill your Company Details to <b>verify</b> your company.{" "}
            <span className={classes.warningHover} onClick={() => history.push({ pathname: "/company/company-settings/?details", company_not_verified: true })}>
              Click here to fill the complete company details.
            </span>
          </p>
        </Box>
      )}
    </>
  );
};

export default WarningBox;

const useStyles = makeStyles({
  warningBox: {
    borderRadius: "30px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    borderBottom: "solid 4px #fad196",
    backgroundColor: "#fff5ea",
    marginBottom: 30,
    padding: "10px 10px 0px 10px",
    width: "80%",
    marginTop: 25,
    diaplay: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  warningBoxDashboard: {
    borderRadius: "30px",
    backdropFilter: "blur(30px)",
    boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
    borderBottom: "solid 4px #fad196",
    backgroundColor: "#fff5ea",
    marginBottom: 30,
    padding: "10px 10px 0px 10px",
  },
  warningHover: {
    "&:hover": {
      cursor: "pointer",
      color: "rgb(50, 130, 196)",
    },
  },
});

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Grid, Container } from "@material-ui/core";
import ProfessionalPaln from "../Common/AllPlan/ProfessionalPlan";
import MostPopularPlan from "../Common/AllPlan/MostPopularPlan";
import BusinessPlan from "../Common/AllPlan/BusinessPlan";
import EnterprisePlan from "../Common/AllPlan/EnterprisePlan";
import vactor from "../../assets/png/vector_tick.png";

import CustomStepper from "../Common/Stepper/CustomStepper";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StepperDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container maxWidth="lg">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Grid
            container
            spacing={2}
            style={{ height: "100vh", display: "flex", alignItems: "center" }}
          >
            <Grid item md={12} style={{ textAlign: "center" }}></Grid>
            <Grid item xs={12} sm={6} md={6} style={{ padding: "20px" }}>
              <div>
                <Typography variant="h4">You are almost there!</Typography>
                <br />
                <Typography variant="body2">
                  Thank you for choosing GetWork.
                  <br />
                  Your plan will be activated once you complete the payment.
                </Typography>
                <CustomStepper />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={{ padding: "20px" }}>
              <img src={vactor} width="100%" />
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
}

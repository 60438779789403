import {
  Typography,
  Link,
  Breadcrumbs,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import JobManageTabs from "../components/Common/Tabs/JobManageTabs";
import fetchApi from "../apiCall/fetchApi";
import { BackendBaseApi } from "../../constants/constants";
import { CSVLink } from "react-csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import { ReactComponent as NewError } from "../assets/svg/NewError.svg"; //../../../assets/svg/NewError.svg
function JobTrack() {
  const [navbarData, setNavbarData] = useState(null);
  const [navbarDataError, setNavbarDataError] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorJobData, setErrorJobData] = useState(null);
  const [activeStatusObject, setActiveStatusObject] = useState(null);
  const [paginationResponse, setPaginationResponse] = useState(null);
  const [oneApiCallMade, setOneApiCallMade] = useState(false);
  const [value, setValue] = React.useState(0);
  const [totalJobList, setTotalJobList] = useState(null);
  const [pageData, setPageData] = useState({ currentPage: 0, pageSize: 10 });
  const [selectedRow, setSelectedRow] = useState({
    details: [],
    selectedIds: [],
  });
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const [applyClickedCTC, setApplyClickedCTC] = useState(false);
  const [applyClickedStipend, setApplyClickedStipend] = useState(false);
  const [jobDataDownload, setJobDataDownload] = useState(null);
  const [jobDataDownloadError, setJobDataDownloadError] = useState(null);
  const [jobDataDownloadLoading, setJobDataDownloadLoading] = useState(false);
  const [createExportData, setCreateExportData] = useState([]);
  const [downloadCSV, setDownloadCSV] = useState([]);

  const [filters, setFilters] = useState({
    job_title: null,
    job_type: null,
    hiring_type: null,
    created: null,
    ctc_min: null,
    ctc_max: null,
    stipend_min: null,
    stipend_max: null,
  });

  const checkPageData = () => {
    return `&page=${pageData?.currentPage + 1}&item_per_page=${
      pageData?.pageSize
    }`;
  };

  const checkSearch = () => {
    if (filters?.job_title) return `&search=${filters?.job_title}`;
    else return ``;
  };

  const checkJobType = () => {
    if (filters?.job_type) return `&job_type_id=${filters?.job_type?.id}`;
    else return ``;
  };

  const checkHiringType = () => {
    if (filters?.hiring_type)
      return `&hiring_type=${
        filters?.hiring_type === "On Campus" ? "ON CAMPUS" : "OFF CAMPUS"
      }`;
    else return ``;
  };

  const checkCTC = () => {
    if (filters.ctc_min && filters?.ctc_max)
      return `&ctc_min=${Number(filters.ctc_min) * 100000}&ctc_max=${
        Number(filters.ctc_max) * 100000
      }`;
    else if (filters.ctc_min)
      return `&ctc_min=${Number(filters.ctc_min) * 100000}`;
    else if (filters.ctc_max)
      return `&ctc_max=${Number(filters.ctc_max) * 100000}`;
    else return ``;
  };

  const checkStipend = () => {
    if (filters.stipend_min && filters?.stipend_max)
      return `&stipend_min=${Number(filters.stipend_min)}&stipend_max=${Number(
        filters.stipend_max
      )}`;
    else if (filters.stipend_min)
      return `&stipend_min=${Number(filters.stipend_min)}`;
    else if (filters.stipend_max)
      return `&stipend_max=${Number(filters.stipend_max)}`;
    else return ``;
  };

  const checkCreated = () => {
    if (filters.created)
      return `&posted_date=${new Date(filters.created)
        .toISOString()
        .substring(0, 10)}`;
    else return ``;
  };

  useEffect(() => {
    setJobData(null);
    fetchApi({
      url: `${
        BackendBaseApi.NIYUKTI
      }api/manage_jobs/company/job_main/navbar/?company_id=${
        JSON.parse(localStorage.getItem("company")).company
      }`,
      setResponse: setNavbarData,
      setError: setNavbarDataError,
    });
  }, []);

  useEffect(() => {
    if (
      navbarData &&
      Object.keys(navbarData?.data).length > 0 &&
      !oneApiCallMade
    ) {
      Object.keys(navbarData?.data).forEach((item) => {
        if (navbarData?.data[item].heading === "Open Jobs") {
          setActiveStatusObject(navbarData?.data[item]);
        }
      });
    }
  }, [navbarData]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    if (activeStatusObject) {
      setJobData(null);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }${activeStatusObject?.param}`,
        setResponse: setJobData,
        setLoading: setLoading,
        setError: setErrorJobData,
        setPagination: setPaginationResponse,
        setOneApiCallMade: setOneApiCallMade,
        source: source,
      });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }${activeStatusObject?.param}&export=1`,
        setResponse: setJobDataDownload,
        setLoading: setJobDataDownloadLoading,
        setError: setJobDataDownloadError,
        source: source,
      });
      clearFilters();
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [activeStatusObject]);

  const clearFilters = () => {
    setFilters({
      ...filters,
      job_title: "",
      job_type: null,
      hiring_type: null,
      created: null,
      ctc_min: null,
      ctc_max: null,
      stipend_min: null,
      stipend_max: null,
    });
  };

  useEffect(() => {
    if (oneApiCallMade) {
      setJobData(null);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }${
          activeStatusObject?.param
        }${checkPageData()}${checkSearch()}${checkJobType()}${checkHiringType()}${checkCreated()}${checkCTC()}${checkStipend()}`,
        setResponse: setJobData,
        setLoading: setLoading,
        setError: setErrorJobData,
        setPagination: setPaginationResponse,
      });
    }
  }, [pageData]);

  useEffect(() => {
    if (clickSearchButton) {
      setJobData(null);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }${
          activeStatusObject?.param
        }${checkPageData()}${checkSearch()}${checkJobType()}${checkHiringType()}${checkCreated()}${checkCTC()}${checkStipend()}`,
        setResponse: setJobData,
        setLoading: setLoading,
        setError: setErrorJobData,
        setPagination: setPaginationResponse,
      });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }&export=1${
          activeStatusObject?.param
        }${checkSearch()}${checkJobType()}${checkHiringType()}${checkCreated()}${checkCTC()}${checkStipend()}`,
        setResponse: setJobDataDownload,
        setLoading: setJobDataDownloadLoading,
        setError: setJobDataDownloadError,
      });
      setClickSearchButton(false);
    }
  }, [clickSearchButton]);

  useEffect(() => {
    if (oneApiCallMade) {
      setJobData(null);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }${
          activeStatusObject?.param
        }${checkPageData()}${checkSearch()}${checkJobType()}${checkHiringType()}${checkCreated()}${checkCTC()}${checkStipend()}`,
        setResponse: setJobData,
        setLoading: setLoading,
        setError: setErrorJobData,
        setPagination: setPaginationResponse,
      });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }&export=1${
          activeStatusObject?.param
        }${checkSearch()}${checkJobType()}${checkHiringType()}${checkCreated()}${checkCTC()}${checkStipend()}`,
        setResponse: setJobDataDownload,
        setLoading: setJobDataDownloadLoading,
        setError: setJobDataDownloadError,
      });

      setClickSearchButton(false);
    }
  }, [filters?.job_type, filters?.hiring_type, filters?.created]);

  useEffect(() => {
    if (applyClickedCTC) {
      setJobData(null);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }${
          activeStatusObject?.param
        }${checkPageData()}${checkSearch()}${checkJobType()}${checkHiringType()}${checkCTC()}${checkStipend()}${checkCreated()}`,
        setResponse: setJobData,
        setLoading: setLoading,
        setError: setErrorJobData,
        setPagination: setPaginationResponse,
      });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }&export=1${
          activeStatusObject?.param
        }${checkSearch()}${checkJobType()}${checkHiringType()}${checkCreated()}${checkCTC()}${checkStipend()}`,
        setResponse: setJobDataDownload,
        setLoading: setJobDataDownloadLoading,
        setError: setJobDataDownloadError,
      });
      setApplyClickedCTC(false);
    }
  }, [applyClickedCTC]);

  useEffect(() => {
    if (applyClickedStipend) {
      setJobData(null);
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }${
          activeStatusObject?.param
        }${checkPageData()}${checkSearch()}${checkJobType()}${checkHiringType()}${checkCTC()}${checkStipend()}${checkCreated()}`,
        setResponse: setJobData,
        setLoading: setLoading,
        setError: setErrorJobData,
        setPagination: setPaginationResponse,
      });
      fetchApi({
        url: `${
          BackendBaseApi.NIYUKTI
        }api/manage_jobs/company/job_main/?company_id=${
          JSON.parse(localStorage.getItem("company")).company
        }&export=1${
          activeStatusObject?.param
        }${checkSearch()}${checkJobType()}${checkHiringType()}${checkCreated()}${checkCTC()}${checkStipend()}`,
        setResponse: setJobDataDownload,
        setLoading: setJobDataDownloadLoading,
        setError: setJobDataDownloadError,
      });
      setApplyClickedStipend(false);
    }
  }, [applyClickedStipend]);

  const callBackMainApis = () => {
    setJobData(null);
    fetchApi({
      url: `${
        BackendBaseApi.NIYUKTI
      }api/manage_jobs/company/job_main/navbar/?company_id=${
        JSON.parse(localStorage.getItem("company")).company
      }`,
      setResponse: setNavbarData,
      setError: setNavbarDataError,
    });
    fetchApi({
      url: `${
        BackendBaseApi.NIYUKTI
      }api/manage_jobs/company/job_main/?company_id=${
        JSON.parse(localStorage.getItem("company")).company
      }${activeStatusObject?.param}${checkPageData()}`,
      setResponse: setJobData,
      setLoading: setLoading,
      setError: setErrorJobData,
      setPagination: setPaginationResponse,
      setOneApiCallMade: setOneApiCallMade,
    });
    fetchApi({
      url: `${
        BackendBaseApi.NIYUKTI
      }api/manage_jobs/company/job_main/?company_id=${
        JSON.parse(localStorage.getItem("company")).company
      }&export=1${activeStatusObject?.param}`,
      setResponse: setJobDataDownload,
      setLoading: setJobDataDownloadLoading,
      setError: setJobDataDownloadError,
    });
    clearFilters();
  };

  useEffect(() => {
    if (jobDataDownload?.data?.length > 0 && activeStatusObject?.param) {
      var tempDownloadArray = [];
      var downloadArray = [];
      if (selectedRow?.details?.length > 0)
        tempDownloadArray = [...selectedRow?.details];
      else tempDownloadArray = [...jobDataDownload?.data];
      downloadArray = tempDownloadArray?.map((item) => {
        return {
          id: item?.id,
          job_title: item?.job_title,
          job_type: item?.job_type_name,
          hiring_type: item?.hiring_type,
          ctc_range: item?.ctc_display,
          stipend: item?.stipend,
          vacancies: item?.vacancies,
          apply_by_date: item?.apply_end_date,
          posted_date: item?.posted_on,
          ...((activeStatusObject?.heading === "Open Jobs" ||
            activeStatusObject?.heading === "Scheduled Jobs") && {
            applicants: item?.applicant_count,
          }),
          ...(activeStatusObject?.heading === "Closed Jobs" && {
            hired: item?.hired_count,
          }),
          ...(activeStatusObject?.heading === "Scheduled Jobs" && {
            live_in: item?.live_in,
          }),
        };
      });
      setDownloadCSV(downloadArray);
    }
  }, [jobDataDownload, selectedRow?.details?.length, activeStatusObject]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5px",
          marginBottom: "10px",
        }}
      >
        <div>
          <Typography variant="h2">Manage Jobs</Typography>
          {/* <Typography variant="body2" style={{ color: "#6C757D", fontWeight: "400" }}>
            Manage All Jobs
          </Typography> */}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CSVLink
            data={downloadCSV?.length > 0 ? downloadCSV : []}
            style={{
              cursor: "pointer",
              textDecoration: "none",
            }}
            filename={"Jobs.csv"}
          >
            <Button
              startIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
              color="primary"
              style={{
                height: "40px",
                borderRadius: "30px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                boxShadow: "none",
                marginLeft: "20px",
              }}
            >
              Export
            </Button>
          </CSVLink>
        </div>
      </div>
      {!navbarDataError && navbarData?.data ? (
        <JobManageTabs
          navbarData={navbarData?.data}
          value={value}
          setValue={setValue}
          activeStatusObject={activeStatusObject}
          setActiveStatusObject={setActiveStatusObject}
          jobData={jobData}
          totalJobList={totalJobList}
          setTotalJobList={setTotalJobList}
          loading={loading}
          errorJobData={errorJobData}
          pageData={pageData}
          setPageData={setPageData}
          paginationResponse={paginationResponse}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          filters={filters}
          setFilters={setFilters}
          clickSearchButton={clickSearchButton}
          setClickSearchButton={setClickSearchButton}
          setApplyClickedCTC={setApplyClickedCTC}
          setApplyClickedStipend={setApplyClickedStipend}
          callBackMainApis={callBackMainApis}
        />
      ) : navbarDataError ? (
        <NewError
          height="180px"
          width="180px"
          alt="404 Error"
          fill={"#007BFF"}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <CircularProgress />
          </div>
        </div>
      )}
    </>
  );
}

export default JobTrack;

import React, { useEffect, useState } from "react";

const ScrollableCheckboxList = ({ state, setState, listData, from="" }) => {
    const [selectedChecks, setSelectedChecks] = useState([]);
    const scrollableListStyle = {
        border: "1px solid #ced4da",
        maxHeight: "100px",
        overflowY: "scroll",
    };
    const found = (arr, item) => {
        let ind = 0;
        let isPresent = false;

        arr.forEach((a) => {
            if (Object.values(a)[0] === item) {
                isPresent = true;
                return;
            }
            ind++;
        });
        if (isPresent) {
            return ind;
        } else return -1;
    };

    const handleChange = (e) => {
        const val = e.target.value;
        const ind = found(state, val);

        let selectedArr = [...selectedChecks];

        if (e.target.checked) {
            listData.forEach(
                (item) => item.name === e.target.value && selectedArr.push(item)
            );
            setSelectedChecks(selectedArr);
        } else {
            setSelectedChecks(
                selectedArr.filter((item) => item.name !== e.target.value)
            );
        }
    };
    let list = [];

    useEffect(() => {
        setState([...selectedChecks]);
    }, [selectedChecks]);
    
    if (listData && listData.length > 0)
        listData.forEach((data) => {
            list.push(
                <div className="checkbox text-left" key={from + data.id}>
                    <label>
                        <input
                            onChange={handleChange}
                            key={from + data.id}
                            type="checkbox"
                            id={from + data.id}
                            defaultValue={data.name}
                        />
                        <span className="fs-14 ml-2 list-item">
                            {data.name}
                        </span>
                    </label>
                </div>
            );
        });

    return (
        <>
            <form
                class="form-horizontal checkbox-list pl-2 shadow_1-lightest my-2"
                style={scrollableListStyle}
            >
                {list}
            </form>
        </>
    );
};

export default ScrollableCheckboxList;

import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "#e3e3e3",
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));
export function CircularProgressWithLabel(props) {
  const classes = useStylesFacebook();
  const domain = useSelector((state) => state.SubDomainDetails.subDomainDetails);

  return (
    <Box position="relative" display="inline-flex" justifyContent="center" marginLeft="60px" marginBottom="60px">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={props.mobileView ? 40 : 250}
        // thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        {...props}
        size={props.mobileView ? 40 : 250}
        style={domain?.domain ? { color: domain?.color_code } : { color: "#007bff" }}
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        thickness={4}
      />
      <Box top={props.mobileView ? 20 : 0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary" style={props.mobileView ? { textAlign: "center", fontSize: 11 } : { textAlign: "center" }}>
          {!props.mobileView && (
            <>
              {" "}
              <Typography variant="body1" style={{ fontSize: 20 }}>
                Profile Completion
              </Typography>
              <br />
            </>
          )}
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic({ varyPercentage, mobileView }) {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={varyPercentage} mobileView={mobileView} />;
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: "1px solid #007BFF",
    // marginTop: "10px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: "#007BFF",
    marginRight: "10px",
    fontFamily: "Nunito",
  },
  btn: {
    boxShadow: "none",
    height: "34px",
    fontWeight: "400",
    backgroundColor: "#007BFF",
    borderRadius: "30px",
    display: "flex",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontFamily: "Nunito",
    "&:hover": {
      backgroundColor: "#007BFF",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
}));

export default function JobNavbar() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <AppBar position="relative" style={{ backgroundColor: "#e3f0ff" }}>
        <Toolbar style={{ justifyContent: "center", minHeight: "50px" }}>
          <Typography variant="body2" className={classes.title}>
            Are You Looking For Jobs?
          </Typography>
          <Button
            variant="contained"
            className={classes.btn}
            color="inherit"
            onClick={() => {
              history.push("/login/student");
            }}
          >
            Continue as Student
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Grid, Button, Paper } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Form from "../Card/Form/index";
import LogImage from "../../../../assets/svg/login/Resume-rafiki.svg";
import check from "../../../../assets/checked-new.png";
import LoginGrid from "../Card/LoginGrid/index";
import CommonLeftComponent from "./CommonLeftComponent";
import RegisterMethods from "../Card/Form/RegisterMethods";
import RegisterForm from "../Card/Form/RegisterForm";
import ForgotPasswordForm from "../Card/Form/ForgotPasswordForm";
import ResetPasswordForm from "../Card/Form/ResetPasswordForm";
import EmailVerify from "../Card/Verifications/emailVerify";
import EmailVerified from "../Card/Verifications/emailVerified";
import AdminApproval from "../Card/Verifications/adminApproval";
import RegisterFormCollege from "../Card/Form/RegisterFormCollege";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffff",

    fontFamily: " Nunito",
    marginTop: "5.5%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    marginTop: "50px",
    marginBottom: "20px",
  },
  img: {
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "flex-end",
  },
  title: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
  },
}));

export default function StudentHeader(props) {
  const {
    type,
    email,
    setEmail,
    password,
    setPassword,
    loginUser,
    emailErrorMessage,
    setEmailErrorMessage,
    passwordErrorMessage,
    setPasswordErrorMessage,
    responseGoogle,
    responseFacebook,
    loading,
    registerForm,
    userDetails,
    setUserDetails,
    errorMessages,
    setErrorMessages,
    handleSubmit,
    showGrid,
    forgotPasswordForm,
    resetEmail,
    setResetEmail,
    passwordResetStep,
    setPasswordResetStep,
    resPassword,
    setResPassword,
    confirmPassword,
    setConfirmPassword,
    resetSteps,
    setResetSteps,
    resetPasswordForm,
    verifyEmail,
    timerComplete,
    setTimerComplete,
    verifiedEmail,
    userVerify,
    company,
    adminName,
    adminEmail,
    isAdmin,
    buttonClickedLogin,
    setButtonClickedLogin,
    userDetailsCollege,
    setUserDetailsCollege,
    errorMessagesCollege,
    setErrorMessagesCollege,
    handleSubmitCollege,
    buttonClickedPassword,
    setButtonClickedPassword,
    checkUser,
  } = props;
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);

  React.useEffect(() => {
    setCurrentStep(0);
  }, [type]);

  function getStepContent() {
    if (registerForm) {
      if (type !== "College") {
        if (currentStep === 0)
          return <RegisterMethods currentStep={currentStep} setCurrentStep={setCurrentStep} type={type} responseGoogle={responseGoogle} responseFacebook={responseFacebook} />;
        else
          return (
            <RegisterForm
              type={type}
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
              handleSubmit={handleSubmit}
              loading={loading}
            />
          );
      } else
        return (
          <RegisterFormCollege
            type={type}
            handleSubmit={handleSubmitCollege}
            loading={loading}
            userDetails={userDetailsCollege}
            setUserDetails={setUserDetailsCollege}
            errorMessages={errorMessagesCollege}
            setErrorMessages={setErrorMessagesCollege}
          />
        );
    }

    if (forgotPasswordForm) {
      return (
        <ForgotPasswordForm
          loading={loading}
          resetEmail={resetEmail}
          setResetEmail={setResetEmail}
          handleSubmit={handleSubmit}
          passwordResetStep={passwordResetStep}
          setPasswordResetStep={setPasswordResetStep}
          buttonClicked={buttonClickedPassword}
          setButtonClicked={setButtonClickedPassword}
        />
      );
    }
    if (resetPasswordForm) {
      return (
        <ResetPasswordForm
          loading={loading}
          handleSubmit={handleSubmit}
          resPassword={resPassword}
          setResPassword={setResPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          resetSteps={resetSteps}
          setResetSteps={setResetSteps}
        />
      );
    }
    if (verifyEmail) {
      return <EmailVerify timerComplete={timerComplete} setTimerComplete={setTimerComplete} handleSubmit={handleSubmit} />;
    }
    if (verifiedEmail) {
      return <EmailVerified />;
    }
    if (userVerify) {
      return <AdminApproval company={company} adminName={adminName} adminEmail={adminEmail} isAdmin={isAdmin} />;
    } else {
      return (
        <Form
          type={type}
          email={email}
          setEmail={setEmail}
          setPassword={setPassword}
          password={password}
          loginUser={loginUser}
          emailErrorMessage={emailErrorMessage}
          passwordErrorMessage={passwordErrorMessage}
          setEmailErrorMessage={setEmailErrorMessage}
          setPasswordErrorMessage={setPasswordErrorMessage}
          responseGoogle={responseGoogle}
          responseFacebook={responseFacebook}
          loading={loading}
          buttonClicked={buttonClickedLogin}
          setButtonClicked={setButtonClickedLogin}
          checkUser={checkUser}
        />
      );
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg" className={classes.header}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className={classes.title}>
              <CommonLeftComponent type={type} registerForm={registerForm} showGrid={showGrid} />
            </Grid>

            <Grid item xs={12} sm={6} className={classes.img}>
              {getStepContent()}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

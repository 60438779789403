import React, { useEffect, useState } from "react";
import { Avatar, Box, Grid, makeStyles } from "@material-ui/core";
import Iron from "../../../assets/common/ironman.png";
import ConvertMonthsToYear from "../../../utils/CovertMonthsToYear";
import back from "../../../assets/common/empty.gif";

import {
  JobCardSkeletonApplicants,
  JobCardSkeletonNew,
} from "../../../assets/skeletons/JobCardSkeleton";

export default function ApplicantsCards(props) {
  const {
    trackData,
    setSelectedCard,
    setCurrentApplicant,
    end,
    setEnd,
    nextUrl,
    setNextUrl,
    isApiCall,
    getTrackData,
    selectedCard,
    loader,
    errorMessage,
    paginationLoader,
  } = props;

  const handleScroll = (event) => {
    let e = event.nativeEvent;

    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && isApiCall) {
        getTrackData(2, 3);
      }
    }
  };
  return (
    <div
      className="card gw-card "
      style={{
        height: "80.6%",
        top: "17.5%",
        overflowX: "hidden",
        overflowY: "scroll",
        width: "18.7%",
        fontFamily: "Roboto",
        borderRadius: "30px",
        backdropFilter: "blur(30px)",
        boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
        border: "solid 1px #f1f1f1",
        backgroundColor: "#ffffff",
        position: "fixed",
      }}
      onScroll={handleScroll}
    >
      <>
        {trackData?.length > 0
          ? trackData?.map((job_applicant, ind) => {
              return (
                <div
                  key={ind}
                  className={"card applicant-card pl-1 my-0"}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedCard === ind ? "#f4ffff" : "#fff",
                  }}
                  onClick={() => {
                    setSelectedCard(ind);
                    setCurrentApplicant(
                      trackData.find(
                        (item) =>
                          item.applicant_id === job_applicant.applicant_id
                      )
                    );
                  }}
                >
                  <div className="card-body" style={{ padding: "6px" }}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 5,
                        }}
                      >
                        <Avatar
                          src={
                            job_applicant?.user_details?.profile_picture !==
                            null
                              ? job_applicant.user_details.profile_picture
                              : Iron
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <p className="fs-11 mb-0">
                            <b>
                              {job_applicant.user_details.first_name +
                                " " +
                                job_applicant.user_details.last_name}
                            </b>
                          </p>
                          <p className="fs-11 fw-500 mb-0">
                            {job_applicant.job_title}
                          </p>
                        </Box>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        backgroundColor:
                          selectedCard === ind ? "#d5f7ff" : "#f3f3f3",
                        borderRadius: 20,
                        padding: 6,
                        marginBottom: 5,
                      }}
                    >
                      <div className="row text-left mx-2 my-1">
                        <Grid container>
                          <Grid item sm={5}>
                            <p
                              className="card-text fs-11 gray-2"
                              style={{ fontWeight: "bold" }}
                            >
                              Degree :
                            </p>
                          </Grid>
                          <Grid item sm={7}>
                            {job_applicant?.user_details.education.find(
                              (item) => item.is_current === true
                            ) && (
                              <p className="card-text fs-11 gray-2">
                                {
                                  job_applicant.user_details.education.find(
                                    (item) => item.is_current === true
                                  ).degree
                                }
                              </p>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                      {job_applicant?.user_details.education.find(
                        (item) => item.is_current === true
                      ) && (
                        <>
                          {job_applicant.user_details.education.find(
                            (item) => item.is_current === true
                          ).specialization !== null && (
                            <div className="row text-left mx-2 my-1">
                              <Grid container>
                                <Grid item sm={5}>
                                  <p
                                    className="card-text fs-11 gray-2"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Specialization :
                                  </p>
                                </Grid>
                                <Grid item sm={7}>
                                  <p className="card-text fs-11 gray-2">
                                    {
                                      job_applicant.user_details.education.find(
                                        (item) => item.is_current === true
                                      ).specialization
                                    }
                                  </p>
                                </Grid>
                              </Grid>
                            </div>
                          )}
                        </>
                      )}

                      {job_applicant.user_details.work_ex !== null && (
                        <div className="row text-left mx-2 my-1">
                          <Grid container>
                            <Grid item sm={5}>
                              <p
                                className="card-text fs-11 gray-2"
                                style={{ fontWeight: "bold" }}
                              >
                                Experience :
                              </p>
                            </Grid>
                            <Grid item sm={7}>
                              <p className="card-text fs-11 gray-2">
                                {parseInt(
                                  job_applicant.user_details.work_ex
                                ) !== 0
                                  ? " " +
                                    ConvertMonthsToYear(
                                      job_applicant.user_details.work_ex
                                    )
                                  : " Fresher"}{" "}
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                      {job_applicant?.user_details.education.find(
                        (item) => item.is_current === true
                      ) && (
                        <>
                          {job_applicant.user_details.education.find(
                            (item) => item.is_current === true
                          ).percentage !== null && (
                            <div className="row text-left mx-2 my-1">
                              <Grid container>
                                <Grid item sm={5}>
                                  <p
                                    className="card-text fs-11 gray-2"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Percentage :
                                  </p>
                                </Grid>
                                <Grid item sm={7}>
                                  <p className="card-text fs-11 gray-2">
                                    {
                                      job_applicant.user_details.education.find(
                                        (item) => item.is_current === true
                                      ).percentage || job_applicant.user_details.education.find(
                                        (item) => item.is_current === true
                                      ).cgpa * job_applicant.user_details.education.find(
                                        (item) => item.is_current === true
                                      ).percentage_factor
                                    }
                                    %
                                  </p>
                                </Grid>
                              </Grid>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : !paginationLoader && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  alignContent="center"
                  flexDirection="column"
                  style={{ marginTop: 220 }}
                >
                  <img src={back} alt="" height="50px" />

                  <p
                    style={{
                      color: "grey",
                      fontSize: 12,
                      textAlign: "center",
                      padding: 10,
                    }}
                  >
                    {errorMessage}
                  </p>
                </Box>
              </>
            )}
      </>
      {paginationLoader && <JobCardSkeletonApplicants />}
    </div>
  );
}

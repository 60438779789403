import React, { useState } from "react";
import {
  Box,
  Container,
  Dialog,
  useMediaQuery,
  useTheme,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Grid2 from "@mui/material/Unstable_Grid2";
import LoginForm from "../Section/LoginRightSection/LoginForm";
import loginImage from "../../Assets/Images/png/login-form.png";
import EnterOtp from "../Section/LoginRightSection/EnterOtp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginDialog({
  open,
  handleClickOpen,
  handleClose,
  setStep,
}) {
  const [loginstep, setLoginStep] = useState(1);
  function getLoginComponent() {
    switch (loginstep) {
      case 1:
        return <LoginForm setLoginStep={setLoginStep} />;
      case 2:
        // return <FirstCheck setLoginStep={setLoginStep} />;
        return <EnterOtp setLoginStep={setLoginStep} />;

      default:
        return <LoginForm setLoginStep={setLoginStep} />;
    }
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [maxWidth, setMaxWidth] = React.useState("sm");

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            [theme.breakpoints.only("sm")]: {
              borderRadius: 0,
            },
            [theme.breakpoints.only("xs")]: {
              borderRadius: 0,
            },
          },
        }}
      >
        <AppBar
          color="inherit"
          sx={{
            borderBottom: "1px solid #E3E6EA",
            position: "relative",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            {loginstep
              ? loginstep >= 2 && (
                  <>
                    <IconButton
                      onClick={() => {
                        setLoginStep(1);
                      }}
                      edge="start"
                      color="inherit"
                      aria-label="close"
                    >
                      <ArrowBackRoundedIcon />
                    </IconButton>
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h6"
                      component="div"
                    >
                      Login via OTP
                    </Typography>
                  </>
                )
              : ""}
            {loginstep === 1 && (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Login via Email
              </Typography>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {loginstep === 1 && (
              <IconButton color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            pl: 1,
            pr: 1,
            mt: 3,
            pb: 2,
            [theme.breakpoints.only("sm")]: {
              pb: 0,
            },
            [theme.breakpoints.only("xs")]: {
              pb: 0,
            },
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 4,
              }}
            >
              <img src={loginImage} />
            </Box>
            {getLoginComponent()}
          </Container>
        </Box>
      </Dialog>
    </div>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import ShareIcon from "@material-ui/icons/Share";
import JobMobile from "../JobMobile";
import Header from "../Header";
import Description from "../Description";
import { Container, Paper, Button, Dialog, Toolbar, AppBar, IconButton, Typography, Slide, CircularProgress } from "@material-ui/core";
import CommonButton from "../../../common/CommonButton/CommonButton";
import { checkLinkingHomePage } from "../../../../utils/utility";
import { useHistory } from "react-router-dom";
import Oncampus from "../../../../pages/public/JobDetails/oncampus";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#fff",
  },
  title: {
    //  marginLeft: theme.spacing(2),
    textAlign: "center",
    flex: 1,
  },
  jobGrid: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(8),
  },
  job: {
    height: "100%",
    borderRadius: "20px",
    padding: "15px",
    paddingBottom: "40px",
    display: "flex",
    boxShadow: "none",
    flexDirection: "column",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MobileView({ data, isUserApplied, setApplyClicked, notAuthorized, loader }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${data?.job_title} | ${data?.company?.company_name}`,
          text: `Check out ${data?.job_title} on ${data?.company?.company_name}`,
          url: data?.job_url,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  };
  const history = useHistory();

  return (
    <div>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => (localStorage.getItem("user_type") ? history.push(checkLinkingHomePage(localStorage.getItem("user_type"))) : history.push("/login"))}
            >
              <ArrowBackIcon style={{ color: "#000" }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {data?.job_title}
            </Typography>

            {/* <BookmarkBorderIcon style={{ color: "#000" }} /> */}
            {/* <ShareIcon style={{ color: "#000" }} /> */}
            <IconButton
              aria-label="save"
              onClick={() => {
                // setShareOpen(!shareOpen);
                handleOnClick();
              }}
              style={{
                transition: "all 0.3s ease-in-out 0s",
              }}
            >
              <ShareIcon style={{ color: "#000" }} />{" "}
            </IconButton>
          </Toolbar>
        </AppBar>

        {loader ? (
          <Container
            className={classes.jobGrid}
            maxWidth="md"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 170,
            }}
          >
            <CircularProgress size={50} style={{ color: "#007bff", alignItems: "center", justifyContent: "center", display: "flex" }} />
          </Container>
        ) : !notAuthorized ? (
          <Container className={classes.jobGrid} maxWidth="md">
            {data?.hidden_data ? (
              <>
                <Oncampus />
              </>
            ) : (
              <Paper className={classes.job}>
                <Header data={data} />
                <Description data={data} />
              </Paper>
            )}
          </Container>
        ) : localStorage?.getItem("gw_token") ? (
          <Container className={classes.jobGrid} maxWidth="md">
            <Paper className={classes.job}>
              <Typography variant="body1" style={{ textAlign: "center" }}>
                {" "}
                You are not authorized to view this job
              </Typography>
              <br />

              <CommonButton
                style={{ width: "20%", margin: "auto" }}
                onClick={() => {
                  var link = checkLinkingHomePage(localStorage.getItem("user_type"));
                  history.push(link);
                }}
              >
                Home
              </CommonButton>
            </Paper>
          </Container>
        ) : (
          <Oncampus />
        )}
        {!notAuthorized && !data?.hidden_data && <JobMobile data={data} isUserApplied={isUserApplied} setApplyClicked={setApplyClicked} />}
      </Dialog>
    </div>
  );
}

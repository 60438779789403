/* eslint-disable */
import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Connected from "../../../assets/common/link.svg";
import Pending from "../../../assets/common/pending.svg";
import Rejected from "../../../assets/common/rejected.svg";
import search from "../../../assets/images/closedjob.png";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import CompanyCard from "../../../components/college/companyCard/CompanyCard";
import { BackendBaseApi } from "../../../constants/constants";
import { httpRequest } from "../../../utils/httpRequest";
import { JobDataProps } from "../InviteJob/Invite";

export default function Invite() {
  const [jobData, setJobData] = useState<JobDataProps[]>([]);
  const step = useRef(1);
  const [apiCall, setApiCall] = useState("connected");
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);
  const token = localStorage.getItem("gw_token");
  const [jobDataPending, setJobDataPending] = useState<JobDataProps[]>([]);
  const [jobDataConnected, setJobDataConnected] = useState<JobDataProps[]>([]);
  const [jobDataRejected, setJobDataRejected] = useState<JobDataProps[]>([]);
  const [jobDataPendingCheck, setJobDataPendingCheck] = useState(false);
  const [jobDataConnectedCheck, setJobDataConnectedCheck] = useState(false);
  const [jobDataRejectedCheck, setJobDataRejectedCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);

  useEffect(() => {
    if (!jobDataPendingCheck && apiCall === "pending") {
      GetData(BackendBaseApi.PRAVESH, "api/college/invite/?state=" + apiCall, { headers: token }, setJobData);
    }
    if (!jobDataConnectedCheck && apiCall === "connected") {
      GetData(BackendBaseApi.PRAVESH, "api/college/invite/?state=" + apiCall, { headers: token }, setJobData);
    }
    if (!jobDataRejectedCheck && apiCall === "rejected") {
      GetData(BackendBaseApi.PRAVESH, "api/college/invite/?state=" + apiCall, { headers: token }, setJobData);
    }

    setEnd(false);
  }, [apiCall]);

  const GetData = async (baseUrl: string, endPoint: string, body: any, updateState: any) => {
    if (!isApiCalled) {
      setIsApiCalled(true);
      setLoading(true);
      let res = await httpRequest(baseUrl, endPoint, body);

      if (res.data.next === null) {
        setEnd(true);
      } else {
        setNewURL(res.data.next.slice(0, 31));
        setNewEndPoint(res.data.next.slice(31));
      }
      updateState(jobData.concat([...res.data.results]));
      if (apiCall === "pending") {
        setJobDataPending(jobData.concat([...res.data.results]));
        setJobDataPendingCheck(true);
      } else if (apiCall === "connected") {
        setJobDataConnected(jobData.concat([...res.data.results]));
        setJobDataConnectedCheck(true);
      } else {
        setJobDataRejected(jobData.concat([...res.data.results]));
        setJobDataRejectedCheck(true);
      }
      setIsApiCalled(false);
    }
    setLoading(false);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    let e = event.nativeEvent;
    if (
      //@ts-ignore
      e.target.scrollTop + 10 >=
      //@ts-ignore
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true && !isApiCalled) {
        GetData(newURL, newEndPoint, { headers: token }, setJobData);
      }
    }
  };

  const classes = useStyles();

  return (
    <Box paddingLeft="4%" paddingRight="4%">
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <div className={classes.appBar}>
          <div className={classes.toolBar}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <Box
                className={apiCall === "pending" ? classes.selectedTab : classes.normalTab}
                onClick={() => {
                  step.current = 2;
                  setApiCall("pending");
                  setJobData([]);
                }}
              >
                <div className={classes.tab}>
                  <img src={Pending} height="20px" />
                  <Typography className={apiCall === "pending" ? classes.selectedTabText : classes.normalTabText}>Pending</Typography>
                </div>
              </Box>

              <Box
                style={{ left: "33%" }}
                className={apiCall === "connected" ? classes.selectedTab : classes.normalTab}
                onClick={() => {
                  step.current = 1;
                  setApiCall("connected");
                  setJobData([]);
                }}
              >
                <div className={classes.tab}>
                  <img src={Connected} height="20px" />
                  <Typography className={apiCall === "connected" ? classes.selectedTabText : classes.normalTabText}>Connected</Typography>
                </div>
              </Box>
              <Box
                style={{ left: "25%" }}
                className={apiCall === "rejected" ? classes.selectedTab : classes.normalTab}
                onClick={() => {
                  step.current = 4;
                  setApiCall("rejected");
                  setJobData([]);
                }}
              >
                <div className={classes.tab}>
                  <img src={Rejected} height="20px" />
                  <Typography className={apiCall === "rejected" ? classes.selectedTabText : classes.normalTabText}>Rejected</Typography>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.mainCont} onScroll={handleScroll}>
        {jobDataPending.length > 0 && apiCall === "pending" ? (
          <CompanyCard data={jobDataPending} apiCall={apiCall} />
        ) : loading ? (
          <Box padding="3% 0%" paddingBottom={0}>
            <JobCardSkeleton />
          </Box>
        ) : (
          apiCall === "pending" && (
            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
              <img
                src={search}
                style={{
                  margin: "auto",
                  height: "250px",
                }}
              />

              <Typography variant="h4">No Results Found</Typography>
              <Typography variant="body1">Invite Companies to create new connections</Typography>

              <Link to="/college/invite">
                <Button variant="contained" className={classes.postButton}>
                  Invite Company
                </Button>
              </Link>
            </Box>
          )
        )}

        {jobDataConnected.length > 0 && apiCall === "connected" ? (
          <CompanyCard data={jobDataConnected} apiCall={apiCall} />
        ) : loading ? (
          <Box padding="3% 0%" paddingBottom={0}>
            <JobCardSkeleton />
          </Box>
        ) : (
          apiCall === "connected" && (
            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
              <img
                src={search}
                style={{
                  margin: "auto",
                  height: "250px",
                }}
              />

              <Typography variant="h4">No Results Found</Typography>
              <Typography variant="body1">Invite Companies to create new connections</Typography>

              <Link to="/college/invite">
                <Button variant="contained" className={classes.postButton}>
                  Invite Company
                </Button>
              </Link>
            </Box>
          )
        )}

        {jobDataRejected.length > 0 && apiCall === "rejected" ? (
          <CompanyCard data={jobDataRejected} apiCall={apiCall} />
        ) : loading ? (
          <Box padding="3% 0%" paddingBottom={0}>
            <JobCardSkeleton />
          </Box>
        ) : (
          apiCall === "rejected" && (
            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
              <img
                src={search}
                style={{
                  margin: "auto",
                  height: "250px",
                }}
              />

              <Typography variant="h4">No Results Found</Typography>
              <Typography variant="body1">Invite Companies to create new connections</Typography>
              <Link to="/college/invite">
                <Button variant="contained" className={classes.postButton}>
                  Invite Company
                </Button>
              </Link>
            </Box>
          )
        )}
        {loading && (
          <Box padding="3% 0%">
            <JobCardSkeleton />
          </Box>
        )}
      </div>
    </Box>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    appbar: {
      width: `100%`,
      marginTop: "44px",
      padding: "1% 0%",
      display: "flex",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.up(1000)]: {
        width: "100%",
      },
    },
    postButton: {
      background: "#3282C4",
      color: "#fff",
      borderRadius: "30px",
      "&:hover": {
        background: "#3282C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
    },
    tab: {
      display: "flex",
      flexDirection: "column",
    },
    appBar: {
      padding: "9px",
      width: "78%",
      position: "fixed",
      borderRadius: "30px",
      backdropFilter: "blur(30px)",
      boxShadow: "rgb(0 0 0 / 10%) 0px 25px 30px 0px",
      border: "1px solid rgb(241 241 241)",
      backgroundColor: theme.palette.type === "dark" ? "#333333" : "#ffffff",
    },
    toolBar: {
      display: "block",
      justifyContent: "space-between",
      position: "relative",
      borderRadius: "30px",
      backgroundColor: theme.palette.background.paper,
    },
    selectedTabText: {
      color: theme.palette.type === "dark" ? "#ffffff" : "#000",
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "ProximaNova",
    },
    normalTabText: {
      color: theme.palette.type === "dark" ? "#ffffff" : "#000",
      fontSize: "18px",

      fontFamily: "ProximaNova",
    },
    selectedTab: {
      //display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "ProximaNova",
      padding: "1px 12px",
      height: "40px",
      //boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      backgroundColor: theme.palette.background.default,
      // position: "absolute",
      //top: "38%",
      borderRadius: "4px 4px 0px 0px",
      cursor: "pointer",
      borderLeft: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none",
      borderTop: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none",
      borderRight: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "none",
    },
    normalTab: {
      //display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 12px",
      // height: "40px",
      // position: "absolute",
      //top: "38%",
      cursor: "pointer",
    },
    mainCont: {
      marginTop: "100px",
      paddingLeft: "4%",
      paddingRight: "4%",
      overflowY: "scroll",
    },
  })
);

import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MainForm from "../../../components/common/JobPostNew/mainform";
import StepBar from "../../../components/common/JobPostNew/stepbar";
import RichTextEditor from "react-rte";
import Loader from "../../../bundles/common/components/UI/Loader";
import { useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import { useAlert } from "react-alert";

const MainLayout = () => {
  let { job_id } = useParams();
  const location = useLocation();
  const [jobMainData, setJobMainData] = useState({});

  const stateMemory = useSelector((state) => state.ExploreFilter);
  const basicsRef = useRef(null);
  const detailsRef = useRef(null);
  const qualificationsRef = useRef(null);
  const selectionRef = useRef(null);
  const hiringRef = useRef(null);
  const [wholeLoading, setWholeLoading] = useState(false);
  const [basicsCheck, setBasicsCheck] = useState(false);
  const [detailsCheck, setDetailsCheck] = useState(false);
  const [preferencesCheck, setPreferencesCheck] = useState(false);
  const [preferencesYellowCheck, setPreferencesYellowCheck] = useState(false);
  const alert = useAlert();
  const [selectionCheck, setSelectionCheck] = useState(false);
  const [hiringCheck, setHiringCheck] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const executeScroll = (i) => {
    var value;
    if (i === 0) value = basicsRef;
    if (i === 1) value = detailsRef;
    if (i === 2) value = qualificationsRef;
    if (i === 3) value = selectionRef;
    if (i === 4) value = hiringRef;

    value.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  // const [jobData, setJobData] = useState([]);

  const [formData, setFormData] = useState({
    Basics: {
      user_id: JSON.parse(localStorage.getItem("user")).id,
      company_id: JSON.parse(localStorage.getItem("company")).company,
      job_title: "",
      job_type: null,
      ppo: false,
      employment_type: null,
      job_duration_start: null,
      job_duration_end: null,
      rounds: [],
      posted_on_job_lake: false,
      is_ipo_job: false,
      job_segment: null,
      stipend: 0,
      internship_salary_type: "UNPAID",
    },
    Details: {
      job_description: "",
      raw_job_description: RichTextEditor.createEmptyValue(),
      job_role: null,
      vacancy: null,
      salary_type: parseInt(stateMemory.ctc) !== 0 ? "PAID" : "UNPAID",
      salary_payment_type: "",
      ctc_min: 0,
      ctc_max: parseInt(stateMemory.ctc) !== 0 ? parseInt(stateMemory.ctc) : 0,
      is_equity: false,
      equity_min: 0,
      equity_max: 0,
      documents: [],
      job_location: stateMemory.location.length > 0 ? stateMemory.location : [],
      allow_remote: false,
      is_service_bond: false,
      service_bond: 0,
      work_from_home: false,
    },

    Preferences: {
      eligibility_criteria: {
        graduation_years: [],
        education_levels: [],
        college_preferences: [],
        degrees: [],
        courses: [],
        courses_rank: [],
        degrees_rank: [],
        skills: [],
        experience: [],
        percentage: null,
      },
      backlog: false,
    },
    Colleges: {
      hiring_type: "",
      college: [],
      apply_start_date: null,
      apply_end_date: null,
    },
    extra: {
      degrees_ids: "",
    },
    extra1: {
      job_type_name: "",
      emp_type_name: "",
    },
    Extra1: {
      apply_start_date: null,
      apply_end_date: null,
    },
    OffCampus: {
      allData: null,
      specialization: [],
      degree: [],
      flag: null,
    },
    degree_list: {
      list: null,
    },
    Preview: {
      data: null,
    },
    Rounds: {
      rounds: [],
    },
    ppo: {
      ppo: false,
    },
    Extra: {
      job_role_name: "",
    },
    loader: {
      loader: false,
    },
    college_id_list: {
      checkAll: false,
      list: [],
    },
  });

  const getJobData = () => {
    setEditLoader(true);

    let jobUrl =
      BackendBaseApi.NIYUKTI + `job/?job_id=${btoa(job_id.toString())}`;

    Axios.get(jobUrl, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          var jobData = res.data.data;
          setJobMainData(res.data.data);
          var arr = [];
          var college_arr = [];
          jobData &&
            jobData.job_rounds &&
            jobData.job_rounds.length > 0 &&
            jobData.job_rounds.map((item) => {
              arr.push({
                round_id: item.round,
                r_name: item.round_name,
                round_no: item.round_no,
                is_interviewed: item.is_interviewed
                  ? item.is_interviewed
                  : item.isInterview,
              });
            });

          jobData &&
            jobData.college &&
            jobData.college.length > 0 &&
            jobData.college.map((item) => {
              college_arr.push({
                apply_end_date: item.apply_end_date,
                apply_start_date: item.apply_start_date,
                college_id: item.id,
                preferences: item.preferences,
                is_approved: item.is_approved,
                is_rejected: item.is_rejected,
                college_location: item.location,
                college_name: item.name,
              });
            });
          setFormData({
            ...formData,
            Basics: {
              ...formData.Basics,
              job_title: jobData.job_title,
              job_type: jobData.job_type,
              ppo: jobData.ppo,
              employment_type: jobData.employment_type,
              job_duration_start: jobData.job_duration_start,
              job_duration_end: jobData.job_duration_end,
              rounds: arr,
              posted_on_job_lake: jobData.posted_on_job_lake,
              job_segment: jobData?.job_segment ? jobData.job_segment : null,
              stipend: jobData?.stipend ? jobData.stipend : 0,
              is_ipo_job: jobData?.is_ipo_job ? jobData.is_ipo_job : false,
              internship_salary_type: jobData?.internship_salary_type
                ? jobData?.internship_salary_type
                : "UNPAID",
            },
            Details: {
              ...formData.Details,
              job_description: jobData.job_description,
              raw_job_description: RichTextEditor.createValueFromString(
                jobData.job_description,
                "html"
              ),
              job_role: jobData.job_role,
              vacancy: parseInt(jobData.vacancy),
              salary_type: jobData.salary_type,
              salary_payment_type: jobData.salary_payment_type,
              ctc_min: jobData.ctc_min,
              ctc_max: jobData.ctc_max,
              is_equity: jobData.is_equity,
              equity_min: jobData.equity_min,
              equity_max: jobData.equity_max,
              documents: jobData.documents,
              job_location: jobData.job_location,
              allow_remote: jobData?.job_location?.length == 0 ? true : false,
              is_service_bond: jobData.service_bond === 0 ? false : true,
              service_bond: jobData.service_bond,
              work_from_home: jobData.work_from_home,
            },
            Preferences: {
              ...formData.Preferences,
              eligibility_criteria: {
                graduation_years: jobData.eligibility_criteria.graduation_years,
                education_levels: jobData.eligibility_criteria.education_levels,
                college_preferences:
                  jobData.eligibility_criteria.college_preferences,
                degrees: jobData.eligibility_criteria.degrees,
                courses: jobData.eligibility_criteria.courses,
                courses_rank: jobData.eligibility_criteria.courses_rank,
                degrees_rank: jobData.eligibility_criteria.degrees_rank,
                skills: jobData.eligibility_criteria.skills,
                experience: jobData.eligibility_criteria.experience,
                percentage: jobData.eligibility_criteria.percentage,
              },
              backlog: false,
            },
            Colleges: {
              ...formData.Colleges,
              hiring_type: jobData.hiring_type,
              college: college_arr.length > 0 ? college_arr : [],
              apply_start_date: jobData.apply_start_date,
              apply_end_date: jobData.apply_end_date,
            },
            extra1: {
              ...formData.extra1,
              job_type_name: jobData.job_type_name,
              emp_type_name: jobData.employment_type_name,
            },
            OffCampus: {
              ...formData.OffCampus,

              specialization: jobData.eligibility_criteria.courses,
              degree: jobData.eligibility_criteria.degrees,
              flag: null,
            },
          });
          setEditLoader(false);
        } else {
          alert.error(res.data.error);
          setEditLoader(false);
        }
      })
      .catch((err) => {
        alert.error("Error");
        console.log("Error");
        setEditLoader(false);
      });
  };

  useEffect(() => {
    if (job_id) getJobData();
  }, []);

  useEffect(() => {
    setBasicsCheck(false);
    setDetailsCheck(false);
    setPreferencesCheck(false);
    setSelectionCheck(false);
    setHiringCheck(false);

    if (parseInt(formData.Basics.job_type) === 1) {
      if (
        formData.Basics.job_title !== "" &&
        formData.Basics.job_segment !== null &&
        formData.Details.job_role !== null &&
        formData.Preferences.eligibility_criteria.skills.length > 0 &&
        formData.Basics.employment_type != null &&
        formData.Basics.job_type !== null &&
        formData.Basics.job_duration_start !== null &&
        formData.Basics.job_duration_end !== null
      ) {
        // if (formData.Basics.ppo === false)
        setBasicsCheck(true);
        // if (formData.Basics.ppo === true) {
        //   if (
        //     formData.Basics.stipened &&
        //     parseInt(formData.Basics.stipened) >= 0
        //   )
        //     setBasicsCheck(true);
        //   else setBasicsCheck(false);
        // }
      }
    } else {
      if (
        formData.Basics.job_title !== "" &&
        formData.Basics.job_segment !== null &&
        formData.Details.job_role !== null &&
        formData.Preferences.eligibility_criteria.skills.length > 0 &&
        formData.Basics.employment_type != null &&
        formData.Basics.job_type !== null &&
        formData.Basics.job_duration_start !== null
      ) {
        setBasicsCheck(true);
      }
    }

    if (
      formData.Details.salary_type === "UNPAID" ||
      formData.Details.salary_type === "NOT DISCLOSED"
    ) {
      if (
        formData.Details.job_description !== "" &&
        (formData.Details.job_location.length > 0 ||
          formData.Details.allow_remote === true ||
          formData.Details.work_from_home === true) &&
        formData.Details.vacancy !== null &&
        formData.Preferences.eligibility_criteria.experience.length > 0 &&
        (formData.Details.is_service_bond === false ||
          formData.Details.service_bond !== 0)
      )
        setDetailsCheck(true);
    } else {
      if (
        formData.Details.job_description !== "" &&
        (formData.Details.job_location.length > 0 ||
          formData.Details.allow_remote === true ||
          formData.Details.work_from_home === true) &&
        formData.Details.vacancy !== null &&
        formData.Preferences.eligibility_criteria.experience.length > 0 &&
        parseInt(formData.Details.ctc_min) !== 0 &&
        parseInt(formData.Details.ctc_max) !== 0 &&
        formData.Details.salary_payment_type !== "" &&
        (formData.Details.is_service_bond === false ||
          formData.Details.service_bond !== 0)
      )
        setDetailsCheck(true);
    }

    if (
      formData.Preferences.eligibility_criteria.graduation_years.length > 0 &&
      formData.Preferences.eligibility_criteria.percentage
    )
      setPreferencesCheck(true);

    if (
      formData.Basics.rounds.length > 0 &&
      formData.Details.documents.length > 0
    ) {
      setSelectionCheck(true);
    }

    if (formData.Colleges.hiring_type === "ON CAMPUS") {
      if (formData.Basics.posted_on_job_lake === true) {
        if (
          formData.Colleges.apply_end_date !== null &&
          formData.Colleges.apply_start_date !== null
        )
          setHiringCheck(true);
      } else {
        if (
          formData.Colleges.apply_end_date !== null &&
          formData.Colleges.apply_start_date !== null &&
          (formData.Colleges.college.length > 0 ||
            formData.college_id_list.list.length > 0)
        )
          setHiringCheck(true);
      }
    } else if (formData.Colleges.hiring_type === "OFF CAMPUS") {
      if (
        formData.Preferences.eligibility_criteria.degrees.length > 0 &&
        formData.Preferences.eligibility_criteria.courses.length > 0 &&
        formData.Colleges.apply_end_date !== null
        // formData.Colleges.apply_start_date !== null
      )
        setHiringCheck(true);
    }
  }, [formData]);

  useEffect(() => {
    if (
      hiringCheck &&
      selectionCheck &&
      detailsCheck &&
      basicsCheck &&
      !preferencesCheck
    )
      setPreferencesYellowCheck(true);
    else setPreferencesYellowCheck(false);
  }, [
    hiringCheck,
    selectionCheck,
    detailsCheck,
    basicsCheck,
    preferencesCheck,
  ]);

  return (
    <>
      {!editLoader ? (
        <Grid container style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <Grid item xs={3}>
            <StepBar
              executeScroll={executeScroll}
              detailsRef={detailsRef}
              basicsRef={basicsRef}
              qualificationsRef={qualificationsRef}
              basicsCheck={basicsCheck}
              detailsCheck={detailsCheck}
              preferencesCheck={preferencesCheck}
              selectionCheck={selectionCheck}
              hiringCheck={hiringCheck}
              preferencesYellowCheck={preferencesYellowCheck}
            />
          </Grid>
          <Grid item xs={9}>
            <MainForm
              state={formData}
              setState={setFormData}
              detailsRef={detailsRef}
              basicsRef={basicsRef}
              qualificationsRef={qualificationsRef}
              selectionRef={selectionRef}
              hiringRef={hiringRef}
              loading={wholeLoading}
              setLoading={setWholeLoading}
              basicsCheck={basicsCheck}
              detailsCheck={detailsCheck}
              preferencesCheck={preferencesCheck}
              selectionCheck={selectionCheck}
              hiringCheck={hiringCheck}
              jobData={jobMainData}
              locationUrl={location}
            />
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default MainLayout;

import { Box, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, makeStyles, OutlinedInput, TextField } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import CommonButton from "../../../../components/common/CommonButton/CommonButton";
import { emailRegex, passwordRegex } from "../../../../constants/constants";

const SignupForm = (props) => {
  const {
    type,

    errorMessages,
    setErrorMessages,

    handleSubmit,
    userDetails,
    setUserDetails,
  } = props;
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: "",

    showPassword: false,
  });
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [validate, setValidate] = useState(false);

  const validateData = () => {
    setValidate(false);
    if (userDetails.FirstName === "") {
      setErrorMessages({
        ...errorMessages,
        firstName: "First Name cannot be left blank",
      });
      setValidate(true);
    }
    if (userDetails.PhoneNo === null) {
      setErrorMessages({
        ...errorMessages,
        phone: "Phone Number cannot be left blank",
      });
      setValidate(true);
    }
    if (userDetails.PhoneNo && userDetails.PhoneNo.length !== 10) {
      setErrorMessages({
        ...errorMessages,
        phone: "Enter a valid phone number",
      });
      setValidate(true);
    }
    if (userDetails.Email === "") {
      setErrorMessages({
        ...errorMessages,
        email: "Email cannot be left blank",
      });
      setValidate(true);
    }
    if (!emailRegex.test(userDetails.Email)) {
      setErrorMessages({
        ...errorMessages,
        email: "Please enter a valid email address",
      });
      setValidate(true);
    }
    if (userDetails.Password === "") {
      setErrorMessages({
        ...errorMessages,
        password: "Password field cannot be left blank",
      });
      setValidate(true);
    }
    if (!passwordRegex.test(userDetails.Password)) {
      setErrorMessages({
        ...errorMessages,
        password: "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit",
      });
      setValidate(true);
    }
    setButtonClicked(true);
  };

  useEffect(() => {
    if (!validate && buttonClicked && userDetails.Email !== "" && userDetails.Password !== "") {
      handleSubmit();
    }
  }, [buttonClicked]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p className={classes.heading}>Create an Account</p>
        <span className={classes.subTitle}>{type === "company" ? "Company" : "Student"}</span>
      </div>
      <div style={{ width: "70%", margin: "auto" }}>
        <Grid container spacing={3} style={{ marginBottom: 15 }}>
          <Grid item md={6}>
            <TextField
              error={errorMessages.firstName !== "" ? true : false}
              id="standard-error-helper-text"
              variant="outlined"
              label={
                <span>
                  First Name&nbsp;<span className={classes.RedStar}>*</span>
                </span>
              }
              helperText={<span style={errorMessages.firstName !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.firstName}</span>}
              value={userDetails.FirstName}
              onChange={(e) => {
                setUserDetails({ ...userDetails, FirstName: e.target.value });
                setErrorMessages({ ...errorMessages, firstName: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={
                errorMessages.firstName !== ""
                  ? { style: { backgroundColor: "#fff" } }
                  : {
                      style: { color: "grey", backgroundColor: "#fff" },
                    }
              }
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errorMessages.lastName !== "" ? true : false}
              id="standard-error-helper-text"
              variant="outlined"
              label={"Last Name"}
              helperText={<span style={errorMessages.lastName !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.lastName}</span>}
              value={userDetails.LastName}
              onChange={(e) => {
                setUserDetails({ ...userDetails, LastName: e.target.value });
                setErrorMessages({ ...errorMessages, lastName: "" });
                setButtonClicked(false);
              }}
              onKeyPress={(data) => {
                if (data.charCode === 13) {
                  validateData();
                }
              }}
              InputProps={{
                style: { fontSize: 13 },
              }}
              InputLabelProps={
                errorMessages.lastName !== ""
                  ? { style: { backgroundColor: "#fff" } }
                  : {
                      style: { color: "grey", backgroundColor: "#fff" },
                    }
              }
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>

        <TextField
          error={errorMessages.phone !== "" ? true : false}
          key="Email"
          id="standard-error-helper-text"
          variant="outlined"
          type="number"
          label={
            <span>
              Phone No.&nbsp;<span className={classes.RedStar}>*</span>
            </span>
          }
          helperText={<span style={errorMessages.phone !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.phone}</span>}
          value={userDetails.PhoneNo}
          onChange={(e) => {
            setUserDetails({ ...userDetails, PhoneNo: e.target.value });
            setErrorMessages({ ...errorMessages, phone: "" });
            setButtonClicked(false);
          }}
          onKeyPress={(data) => {
            if (data.charCode === 13) {
              validateData();
            }
          }}
          InputProps={{
            style: { fontSize: 13 },
          }}
          InputLabelProps={
            errorMessages.phone !== ""
              ? { style: { backgroundColor: "#fff" } }
              : {
                  style: { color: "grey", backgroundColor: "#fff" },
                }
          }
          style={{ width: "100%", marginBottom: 30 }}
        />
        <TextField
          error={errorMessages.email !== "" ? true : false}
          key="Email"
          id="standard-error-helper-text"
          variant="outlined"
          label={
            <span>
              Email&nbsp;<span className={classes.RedStar}>*</span>
            </span>
          }
          helperText={<span style={errorMessages.email !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>{errorMessages.email}</span>}
          value={userDetails.Email}
          onChange={(e) => {
            setUserDetails({ ...userDetails, Email: e.target.value });
            setErrorMessages({ ...errorMessages, email: "" });
            setButtonClicked(false);
          }}
          onKeyPress={(data) => {
            if (data.charCode === 13) {
              validateData();
            }
          }}
          InputProps={{
            style: { fontSize: 13 },
          }}
          InputLabelProps={
            errorMessages.email !== ""
              ? { style: { backgroundColor: "#fff" } }
              : {
                  style: { color: "grey", backgroundColor: "#fff" },
                }
          }
          style={{ width: "100%" }}
        />

        <FormControl
          className={clsx(classes.margin, classes.textField)}
          style={{ width: "100%", marginTop: 30 }}
          error={errorMessages.password !== "" ? true : false}
          variant="outlined"
        >
          <InputLabel htmlFor="standard-adornment-password" style={errorMessages.password !== "" ? { color: "#D64045" } : { color: "grey" }}>
            Password<span className={classes.RedStar}>*</span>
          </InputLabel>
          <OutlinedInput
            id="standard-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={userDetails.Password}
            labelWidth={80}
            onChange={(e) => {
              setUserDetails({ ...userDetails, Password: e.target.value });
              setErrorMessages({ ...errorMessages, password: "" });
              setButtonClicked(false);
            }}
            onKeyPress={(data) => {
              if (data.charCode === 13) {
                validateData();
              }
            }}
            style={{ fontSize: 13 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />

          <FormHelperText id="standard-weight-helper-text" style={errorMessages.password !== "" ? { color: "#D64045", fontSize: 13 } : { color: "grey", fontSize: 13 }}>
            {errorMessages.password !== "" ? errorMessages.password : "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit"}
          </FormHelperText>
        </FormControl>

        <Box display="flex" justifyContent="center" margin="auto" marginTop="35px" marginBottom="20px">
          <CommonButton
            onClick={() => {
              validateData();
            }}
            // className={classes.submitButton}
            variant="contained"
          >
            Submit
          </CommonButton>
        </Box>
      </div>
    </>
  );
};

export default SignupForm;

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: "0rem",
    color: "#4f4f4f",
  },
  subTitle: {
    color: "grey",
    textAlign: "center",
    fontSize: 14,
    marginBottom: 25,
  },
  subTitleNew: {
    color: "grey",
    textAlign: "center",
    fontSize: 14,
    marginBottom: 20,
  },
  submitButton: {
    background: "linear-gradient(40deg, #45cafc, #303f9f)",
    color: "#fff",
    borderRadius: 30,
    fontWeight: "normal",
    fontSize: 14,
    width: "100%",
  },
  RedStar: {
    color: "red",
    // fontWeight: "bold",
    marginLeft: "2px",
  },
  googleButton: {
    background: "#fff",
    color: "black",
    borderRadius: 30,
    fontWeight: "normal",
    fontSize: 13,
    width: "100%",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },
}));

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar-edit";
import verifiedIcon from "../../../assets/images/instagram-verified-badge-png.png";
import axios from "axios";
import {
  EndPointPrefix,
  FileUrl,
  BackendBaseApi,
} from "../../../constants/constants";
import Cities from "./UI/Cities";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useAlert } from "react-alert";
import { Tooltip, Modal, Grid, TextField } from "@material-ui/core";
import PublicPorfile from "../PublicProfile/Index";
import Navbar from "../Navbar/StudentNavbar";
import { useLocation } from "react-router-dom";
import ForgotPassword from "../../../bundles/common/components/Auth/ForgotPassword";

class MyEditor extends React.Component {
  constructor(props) {
    super(props);

    const src = JSON.parse(localStorage.getItem("user_details"))
      .profile_picture;
    this.state = {
      preview: src,
      src,
      showResetPassword: false,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
  }

  onClose() {
    this.setState({ preview: null });
  }

  onCrop(preview) {
    this.setState({ preview }, () => {});

    this.props.setPic(this.state.preview, () => {
      alert(this.state.preview);
    });
  }

  onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 10071680) {
      alert("File is too big!");
      elem.target.value = "";
    }
  }

  render() {
    return (
      <div>
        <Avatar
          width={100}
          label="Choose File"
          labelStyle={{ fontSize: "12", cursor: "pointer" }}
          height={100}
          onCrop={this.onCrop}
          onClose={this.onClose}
          onBeforeFileLoad={this.onBeforeFileLoad}
          src={this.state.src}
          style={{ cursor: "pointer" }}
        />
        {this.state.preview && (
          <div>
            <span className="fs-12">Image Preview</span>
            <div className="row justify-content-center">
              <img src={this.state.preview} alt="Preview" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const StudentProfilePersonal = (props) => {
  const alert = useAlert();
  let location = useLocation();
  const isEmailVerified = localStorage.getItem("email_verified").toString();

  const [studentDetails, setStudentDetails] = useState({});
  const [currentCityID, setCurrentCityID] = useState();
  const [hometownID, setHometownID] = useState();
  const [profilePic, setProfilePic] = useState();
  const [resume, setResume] = useState();
  const [editProfilePic, setEditProfilePic] = useState(
    profilePic ? false : true
  );
  const [userResume, setUserResume] = useState("");
  const [editResume, setEditResume] = useState(userResume ? false : true);
  const [open, handleClose] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const details = JSON.parse(localStorage.getItem("user_details"));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [nameCheck, setNameCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [currentEmail, setCurrentEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  useEffect(() => {
    let {
      id,
      first_name,
      last_name,
      email,
      phone,
      dob,
      hometown_city,
      current_city,
      current_city_name,
      hometown_city_name,
      profile_picture,
    } = details;

    profile_picture ? setEditProfilePic(false) : setEditProfilePic(true);
    details?.student_user_details?.resume
      ? setUserResume(details?.student_user_details?.resume)
      : setUserResume("");
    details?.student_user_details?.resume
      ? setEditResume(false)
      : setEditResume(true);
    setStudentDetails({
      ...studentDetails,
      id,
      first_name,
      last_name,
      email,
      phone,
      dob,
      current_city,
      hometown_city,
      current_city_name,
      hometown_city_name,
    });
    setProfilePic(profile_picture);
    setCurrentCityID(current_city);
    setHometownID(hometown_city);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [hometownID, currentCityID]);
  useEffect(() => {
    setFirstName(studentDetails.first_name);
    setLastName(studentDetails.last_name);
    setCurrentEmail(studentDetails.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentDetails]);
  const handleChange = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.id]: e.target.value });
  };

  const sendDetails = () => {
    //first validate fields here
    setButtonDisabled(true);
    if (
      !resume &&
      profilePic &&
      studentDetails.phone &&
      studentDetails.dob &&
      currentCityID &&
      hometownID
    )
      return;

    if (resume) saveResume();
    let formData = new FormData();

    if (profilePic && editProfilePic)
      formData.append("profile_picture", profilePic);
    if (studentDetails.phone) formData.append("phone", studentDetails.phone);
    if (studentDetails.dob) formData.append("dob", studentDetails.dob);
    if (currentCityID) formData.append("current_city", currentCityID);
    if (hometownID) formData.append("hometown_city", hometownID);
    if (currentEmail) formData.append("email", currentEmail);
    if (firstName) formData.append("first_name", firstName);
    if (lastName) formData.append("last_name", lastName);

    axios
      .patch(BackendBaseApi.PRAVESH + `api/shared/user/`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Token " + localStorage.getItem("gw_token"),
        },
      })
      .then((res) => {
        if (res.data.success) alert.success("Details updated successfully!");
        else alert.error(res.data.error);
        setButtonDisabled(false);
      })
      .catch((err) => {
        throw err;
        alert.error(
          "There was some error updating your details. Please try again later"
        );
        setButtonDisabled(false);
      });
  };

  const uploadImage = (e) => {
    setProfilePic(e.target.files[0]);
  };

  const verifyEmail = () => {
    axios
      .post(
        BackendBaseApi.PRAVESH + "api/email/generate/email",
        {},
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("gw_token"),
          },
        }
      )
      .then((res) => {
        alert.success("Email Sent. Please Check Your Email!");
      })
      .catch((err) => {
        throw err;
      });
  };

  const TestFileType = (fileName, fileTypes) => {
    if (!fileName) return;

    let dots = fileName.split(".");
    //get the part AFTER the LAST period.
    let fileType = dots[dots.length - 1];

    if (fileTypes.includes(fileType)) {
      alert.success("Resume file format is ok");
      return true;
    } else {
      alert.error(
        "Please only upload files with .pdf format" +
          fileTypes.join(" .") +
          "\n\nPlease select a new pdf file and try again."
      );
      setResume("");
      return false;
    }
  };

  const saveResume = () => {
    let resumeData = new FormData();
    resumeData.append("resume", resume);
    axios
      .patch(
        BackendBaseApi.PRAVESH + `api/education/student_details`,
        resumeData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "Token " + localStorage.getItem("gw_token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          alert.success(res.data.data.message);
          setUserResume(res.data.data.data.resume);
          setEditResume(false);
        } else alert.error(res.data.error);
      })
      .catch((err) => {
        throw err;
        alert.error(
          "There was some error updating your details. Please try again later"
        );
      });
  };

  const handleResume = (e) => {
    let file = e.target.files[0];

    setResume(file);
    let isValidFile = TestFileType(file.name, ["pdf"]);
    if (!isValidFile) return;
  };
  const callback = (value) => {
    handleClose(value);
    // do something with value in parent component, like save to state
  };

  return (
    <>
      {/* <Navbar/> */}

      <div
        class="card"
        style={{ marginLeft: "0.8rem", scrollbarWidth: "none" }}
      >
        <div class="card-body px-2 profile-section-fixed d-lg-block">
          <div className="row justify-content-center my-2 position-relative">
            {editProfilePic ? (
              <MyEditor pic={profilePic} setPic={setProfilePic} />
            ) : (
              <>
                <img
                  className="profile-pic"
                  src={profilePic}
                  alt="profile_picture"
                />
                <Tooltip title="Edit Profile Picture" placement="right-start">
                  <i
                    onClick={() => {
                      setEditProfilePic(true);
                    }}
                    className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                    id="email"
                    style={{
                      marginLeft: "0px",
                      fontSize: "14px",
                    }}
                  />
                </Tooltip>
              </>
            )}

            <div
              className="position-absolute"
              style={{ right: 0, cursor: "pointer" }}
            >
              <Tooltip title="View Public profile">
                <Link
                  to={`/public/student/profile/${details.id}`}
                  target="_blank"
                >
                  <VisibilityIcon className="public-profile" />
                </Link>
              </Tooltip>
            </div>

            {/* <input type="file" onChange={uploadImage}/> */}
          </div>
          <div className="row my-2 mx-3">
            <div className="text-left w-100">
              <label
                htmlFor="name"
                className="fs-10 fw-500 mb-1 text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                Name
              </label>
              {nameCheck ? (
                <div className="row no-gutters">
                  <div className="col-11">
                    <TextField
                      id="outlined-name"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      variant="outlined"
                      style={{ width: "100%", marginBottom: "20px" }}
                    />
                  </div>

                  <div className="col-11">
                    <TextField
                      id="outlined-name"
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              ) : (
                <div className="row no-gutters">
                  <div className="col-11">
                    <span className="fs-12">
                      {studentDetails.first_name} {studentDetails.last_name}
                    </span>
                  </div>
                  {/* <Tooltip title="You can not change your Name...to change please contact admin">
                  <i
                    className="fas fa-info-circle mt-1 pt-1 text-muted "
                    style={{ fontSize: "12px" }}
                  />
                </Tooltip> */}
                  <div className="col-1">
                    <i
                      onClick={() => {
                        setNameCheck(true);
                      }}
                      className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row my-3 mx-3">
            <div className="text-left w-100">
              <label
                htmlFor="email"
                className="fs-10 fw-500 mb-1 text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                Email
              </label>

              <div className="row no-gutters">
                <div className="col-11">
                  <span className="fs-12 link-text">
                    {studentDetails.email}
                  </span>
                </div>
                <Tooltip title="You can not change your Email address...to change please contact admin">
                  <i
                    className="fas fa-info-circle mt-1 pt-1 text-muted "
                    style={{ fontSize: "12px" }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="row my-3 mx-3">
            <div className="text-left w-100">
              <label
                htmlFor="phone"
                className="fs-10 fw-500 mb-1 text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                Phone Number
              </label>
              <div className="row no-gutters">
                <div className="col">
                  <input
                    onChange={handleChange}
                    type="number"
                    class="form-control shadow_1-lightest profile-field"
                    id="phone"
                    value={studentDetails.phone ? studentDetails.phone : ""}
                    aria-describedby="emailHelp"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-3 mx-3">
            <div className="text-left w-100">
              <label
                htmlFor="dob"
                className="fs-10 fw-500 mb-1 text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                Date of Birth
              </label>
              <div className="row no-gutters">
                <div className="col">
                  <input
                    onChange={handleChange}
                    type="date"
                    class="form-control shadow_1-lightest profile-field"
                    id="dob"
                    value={studentDetails.dob ? studentDetails.dob : ""}
                    aria-describedby="emailHelp"
                    placeholder="18/01/1999"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-3 mx-3">
            <div className="text-left w-100">
              <label
                htmlFor="currentCity"
                className="fs-10 fw-500 mb-1 text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                Current City
              </label>
              <div className="row no-gutters">
                {studentDetails.current_city_name ? (
                  <>
                    <div className="col">
                      <span className="fs-12 link-text">
                        {studentDetails.current_city_name}
                      </span>
                    </div>
                    <div className="col-auto">
                      <i
                        onClick={() => {
                          setStudentDetails({
                            ...studentDetails,
                            current_city_name: "",
                          });
                        }}
                        className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                        id="email"
                        style={{
                          marginLeft: "-25px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="col">
                    <Cities
                      cityName={currentCityID}
                      Id={currentCityID}
                      setId={setCurrentCityID}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row my-3 mx-3">
            <div className=" text-left w-100">
              <label
                htmlFor="hometown"
                className="fs-10 fw-500 mb-1 text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                Hometown
              </label>
              <div className="row no-gutters">
                {studentDetails.hometown_city_name ? (
                  <>
                    <div className="col">
                      <span className="fs-12 link-text">
                        {studentDetails.hometown_city_name}
                      </span>
                    </div>
                    <div className="col-auto">
                      <i
                        onClick={() => {
                          setStudentDetails({
                            ...studentDetails,
                            hometown_city_name: "",
                          });
                        }}
                        className="fas fa-edit mt-1 pt-1 text-muted edit-icon"
                        id="email"
                        style={{
                          marginLeft: "-25px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="col">
                    <Cities
                      cityName={hometownID}
                      Id={hometownID}
                      setId={setHometownID}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row my-1 mx-3">
            <div className="form-group text-left w-100 position-relative">
              <label
                htmlFor="resetPassword"
                className="fs-10 fw-500 mb-1 text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                Reset Password
              </label>
              {location.pathname !== "/student/update-password" ? (
                <p className="fs-12">
                  <Link
                    to="/student/update-password"
                    className="fw-500 link-text"
                    style={{ color: "#3282C4" }}
                  >
                    Click here
                  </Link>{" "}
                  to reset your password
                </p>
              ) : (
                <p className="fs-12">
                  <Link
                    to="/student/profile"
                    className="fw-500 link-text"
                    style={{ color: "#3282C4" }}
                  >
                    Click here
                  </Link>{" "}
                  to return to Profile
                </p>
              )}

              {/* <p className="fs-12"><span onClick = {()=>props.setForgotClicked(true)} className="fw-500 link-text">Click here</span> to reset your password</p>  */}
              {isEmailVerified == "true" ? (
                <>
                  <label
                    htmlFor="verifyEmail"
                    className="fs-10 fw-500 mb-1 text-uppercase"
                    style={{ fontWeight: "bold" }}
                  >
                    Email Verified
                  </label>
                  <img className="verified-icon" src={verifiedIcon} alt="" />
                </>
              ) : (
                <>
                  <label
                    htmlFor="verifyEmail"
                    className="fs-10 fw-500 mb-1 text-uppercase"
                    style={{ fontWeight: "bold" }}
                  >
                    Verify Email
                  </label>
                  <p className="fs-12 mb-0">
                    <a
                      onClick={verifyEmail}
                      className="fw-500 link-text"
                      style={{ color: "#3282C4" }}
                    >
                      Click here
                    </a>{" "}
                    to verify your email
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="row my-1 mx-3 fs-14">
            <div className="form-group text-left w-100">
              {userResume && !editResume && (
                <>
                  <label
                    htmlFor="resume"
                    className="fs-10 fw-500 mb-1 text-uppercase"
                    style={{ fontWeight: "bold" }}
                  >
                    View Resume
                  </label>
                  <p className="fs-12 mb-0">
                    <a
                      href={userResume}
                      target="_blank"
                      className="fw-500 link-text"
                      style={{ color: "#3282C4" }}
                    >
                      Click here
                    </a>{" "}
                    to view your resume
                    <i
                      onClick={() => {
                        setEditResume(true);
                      }}
                      className="fas fa-file-upload mr-2 edit-icon text-muted fs-14 float-right"
                    />
                  </p>
                </>
              )}
              {editResume && (
                <>
                  <label
                    htmlFor="resume"
                    className="fs-10 fw-500 mb-1 text-uppercase"
                  >
                    Upload Resume
                  </label>
                  <input onChange={handleResume} id="resume" type="file" />
                </>
              )}
            </div>
          </div>
          <Modal
            open={open}
            onClose={() => handleClose(!open)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Grid container justify="center">
              <Grid xs={10}>
                <PublicPorfile handleClosey={callback} />
              </Grid>
            </Grid>
          </Modal>
          <div className="row justify-content-center mt-4 pt-3">
            <button
              className="btn btn-main btn-sm shadow-main"
              onClick={sendDetails}
              disabled={buttonDisabled}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentProfilePersonal;

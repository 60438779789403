import { Box, Typography, Theme, makeStyles, createStyles, Button } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import JobCardSkeleton from "../../../assets/skeletons/JobCardSkeleton";
import { BackendBaseApi } from "../../../constants/constants";
import NewJobCard from "../../../components/college/JobCard/NewJobCard";
import TrackPane from "../../../components/college/TrackPane/TrackPane";
import oj from "../../../assets/images/openjob.png";
import { JobListProps, AllJobProfilesProps, JobTypeProps, AllCompanyProps, AllJobTypes } from "./@types";
import CommonButton from "../../../components/common/CommonButton/CommonButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsCont: {
      marginTop: "70px",
      borderRadius: "30px",
      overflowY: "scroll",
      scrollbarWidth: "none",
    },
  })
);

export default function NewJob() {
  const alert = useAlert();
  const [jobData, setJobData] = useState<JobListProps[]>([]);
  const classes = useStyles();
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [end, setEnd] = useState(false);

  const [allJobProfiles, setAllJobProfiles] = useState<AllJobProfilesProps[]>([]);
  const [searching, setSearching] = useState("");
  const [buttonClickSearch, setButtonClickSearch] = useState("");

  const [jobProfile, setJobProfile] = useState("All");

  const [jobType, setJobType] = useState<JobTypeProps[]>([]);
  const [selectedJobType, setSelectedJobType] = useState("All");

  const [allCompanies, setAllCompanies] = useState<AllCompanyProps[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | number>("All");

  const [allJobTypes, setAllJobTypes] = useState<AllJobTypes[]>([]);
  const [filteredJobData, setFilteredJobData] = useState<JobListProps[]>([]);
  const [queryParamsObj, setQueryParamsObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [jobProfileObject, setJobProfileObject] = useState({});
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const firstCall = useRef(0);
  let locationSearch = useLocation().search;
  let params = (new URLSearchParams(locationSearch)).get('query');


  const generateQueryParamsObj = (arr: JobListProps[]) => {
    const newArr = arr.map((item) => ({
      id: item.id,
      is_approved: item.is_approved,
      is_rejected: !item.is_approved,
      company_id: item?.job?.company?.company_id,
      college_id: parseInt(localStorage.getItem("college_id")!),
      user_id: parseInt(localStorage.getItem("user_id")!),
    }));
    setQueryParamsObj(newArr);
  };

  const GetData = async (baseUrl: string, endPoint: string, body: any) => {
    let token = localStorage.getItem("gw_token");

    setLoading(true);
    const res = await Axios({
      method: "GET",
      url: baseUrl + endPoint,
      headers: {
        Authorization: "Token " + token,
      },
    }).catch((err) => {
      setLoading(false);
      throw err;
    });
    if (res.data.data.next == null) {
      setEnd(true);
    } else {
      setNewURL(res.data.data.next.slice(0, 20));
      setNewEndPoint(res.data.data.next.slice(20));
    }
    if (res.data.success) {
      setJobData(jobData.concat([...res.data.data.results]));

      generateQueryParamsObj(res.data.data.results);

      let profiles = [];
      res.data.data.results.forEach((data: JobListProps) => {
        profiles.push(data.job.job_title);
      });

      let types = [];
      res.data.data.results.forEach((data: JobListProps) => {
        types.push({ id: data.job.job_type, name: data.job.job_type_name });
      });

      let company = [];
      res.data.data.results.forEach((data: JobListProps) => {
        company.push({
          id: data.job.company.company_id,
          name: data.job.company.company_name,
        });
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setFilteredJobData(jobData);
  }, [jobData]);

  const removeDuplicates = () => {
    let arr = [];
    let obj: any = {};

    for (let i in allJobTypes) {
      let objId = allJobTypes[i]["id"];
      obj[objId] = allJobTypes[i];
    }
    if (Object.keys(obj).length > 0) {
      for (let i in obj) {
        arr.push(obj[i]);
      }
    }

    return [...arr];
  };

  useEffect(() => {
    if (allJobTypes && allJobTypes.length) {
      setAllJobTypes(removeDuplicates());
    }
  }, [allJobTypes.length]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    let e = event.nativeEvent;
    if (
      //@ts-ignore
      e.target.scrollTop + 10 >=
      //@ts-ignore
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        newData(newURL, newEndPoint);
      }
    }
  };
  // useEffect(() => {
  //   let temp1 = jobData.filter((applicant) => {
  //     return applicant.job.job_title.toLowerCase().includes(search);
  //   });
  //   setFilteredJobData(temp1);
  // }, [search]);

  // const getJobProfiles = async () => {
  //   const appendParam = () => {
  //     if (selectedJobType === "All") {
  //       return "";
  //     } else return `&job_type_id=${btoa(selectedJobType)}`;
  //   };
  //   const appendParam2 = () => {
  //     if (selectedCompany === "All") {
  //       return "";
  //     } else return `&company_id=${btoa(JSON.stringify(selectedCompany))}`;
  //   };

  //   const res = await Axios.get(
  //     BackendBaseApi.NIYUKTI +
  //       `job/college/job/list/?college_id=${btoa(
  //         localStorage.getItem("college_id")!
  //       )}${appendParam()}${appendParam2()}`,
  //     {
  //       headers: {
  //         Authorization: `Token ${localStorage.getItem("gw_token")}`,
  //       },
  //     }
  //   ).catch((err) => {
  //     throw err;
  //   });

  //   let profiles = [...res.data.data];
  //   let newProfiles = profiles.map((item, index) => ({
  //     job_id: item.job_id,
  //     job_title: item.job_title,
  //     is_college_posted: index % 2 === 0 ? true : false,
  //   }));
  //   let obj = { job_id: "All", job_title: "All", is_college_posted: true };

  //   setAllJobProfiles([obj, ...newProfiles]);
  // };

  const getJobTypes = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch((err) => {
      throw err;
    });
    let obj = { id: "All", job_type: "All" };
    setJobType([obj, ...res.data.data]);
  };

  const getCompanies = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + `job/college/get_companies/?college_id=${btoa(localStorage.getItem("college_id")!)}`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    }).catch((err) => {
      throw err;
    });
    let obj = { company_id: "All", company_name: "All" };
    setAllCompanies([obj, ...res.data.data]);
  };

  useEffect(() => {
    //getJobProfiles();
    getJobTypes();
    getCompanies();
  }, []);

  useEffect(() => {
    if(searching!=='' && firstCall.current === 0){
      firstCall.current = 1;
      return;
    }
    if (searching !== "") getAllJobs();
  }, [buttonClickSearch]);

  const getAllJobs = () => {
    const checkJobProfile = () => {
      if (jobProfile === "All") {
        return "";
      } else {
        return `&job_id=${btoa(jobProfile)}`;
      }
    };

    const checkJobType = () => {
      if (selectedJobType === "All") {
        return "";
      } else {
        return `&job_type_id=${btoa(selectedJobType)}`;
      }
    };

    const checkCompany = () => {
      if (selectedCompany === "All") {
        return "";
      } else {
        return `&company_id=${btoa(JSON.stringify(selectedCompany))}`;
      }
    };

    const checkSearch = () => {
      if (buttonClickSearch !== "") {
        firstCall.current = 1;
        return `&search=${buttonClickSearch}`;
    }
      else if(firstCall.current === 0 && params) {
        setSearching(params);
        setButtonClickSearch(params);
        return `&search=${params}`;
      }
      else {
        firstCall.current = 1;
        return ``;
      }
    };

    setLoading(true);

    Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/college/invite/?college_id=${btoa(localStorage.getItem("college_id")!)}${checkJobType()}${checkJobProfile()}${checkCompany()}&job_status=TkVX${checkSearch()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.next === null) {
            setEnd(true);
          } else {
            setEnd(false);
            setNewURL(res.data.data.next.slice(0, 20));
            setNewEndPoint(res.data.data.next.slice(20));
          }
          setJobData(res.data.data.results);
          generateQueryParamsObj(res.data.data.results);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Some Error Occured");
        throw err;
      });
  };

  useEffect(() => {
    getAllJobs();
  }, [selectedJobType, selectedCompany, jobProfile]);

  const newData = (baseURL: string, endpoint: string) => {
    setLoading(true);
    Axios.get(baseURL + endpoint, {
      headers: {
        Authorization: `Token ${localStorage.getItem("gw_token")}`,
      },
    })
      .then((res) => {
        if (res.data.data.next === null) {
          setEnd(true);
        } else {
          setNewURL(res.data.data.next.slice(0, 20));
          setNewEndPoint(res.data.data.next.slice(20));
        }

        let data = [...jobData, ...res.data.data.results];
        setJobData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };

  return (
    <Box style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <TrackPane
          profiles={allJobProfiles}
          jobProfile={jobProfile}
          jobTypes={jobType}
          selectedJobType={selectedJobType}
          setSelectedJobType={setSelectedJobType}
          setJobProfile={setJobProfile}
          allCompanies={allCompanies}
          setSelectedCompany={setSelectedCompany}
          setJobProfileObject={setJobProfileObject}
          searching={searching}
          setSearching={setSearching}
          buttonClickSearch={buttonClickSearch}
          setButtonClickSearch={setButtonClickSearch}
          setJobData={setJobData}
          setEnd={setEnd}
          setNewURL={setNewURL}
          setNewEndPoint={setNewEndPoint}
          path="AVAILABLE"
        />
      </div>
      <div className={classes.cardsCont} onScroll={handleScroll} style={{ height: jobData.length > 9 ? "100vh" : "auto" }}>
        {buttonClickSearch !== "" && (
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                fontSize: "17px",
                fontWeight: "bold",
                marginLeft: 50,
                marginTop: 10,
              }}
            >
              You searched for "{buttonClickSearch}"...
            </p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                // setSearching("");
                setButtonClickSearch("");
                setJobData([]);
                setEnd(false);
                setNewEndPoint("");
                setNewURL("");
              }}
              style={{ borderRadius: 30 }}
            >
              Clear your Search
            </Button>
          </Box>
        )}
        {jobData.length > 0 && (
          <NewJobCard
            loading={loading}
            data={filteredJobData}
            fullView={false}
            reject={true}
            all={false}
            queryParamsObj={queryParamsObj}
            GetData={GetData}
            setLoading={setLoading}
            setJobData={setJobData}
            //@ts-ignore
            confirmationDialog={confirmationDialog}
            setConfirmationDialog={setConfirmationDialog}
          />
        )}
        {!loading && jobData.length === 0 && (
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <img src={oj} alt="" width="50%" height="auto" />

            <Typography variant="h4">No Jobs available</Typography>
          </Box>
        )}

        {end !== true && !loading ? (
          <Box display="flex" justifyContent="center" margin="auto" marginTop="-20px" marginBottom="30px">
            <CommonButton variant="outlined" onClick={() => newData(newURL, newEndPoint)}>
              Load More Opportunities
            </CommonButton>
          </Box>
        ) : (
          !end && (
            <Box padding="2% 2%">
              <JobCardSkeleton />
            </Box>
          )
        )}
      </div>
    </Box>
  );
}

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import user from "../../../Assets/Images/png/aisha.png";
import { CustomTextField } from "../../Common/Input/InputField";
import { BackendBaseApi } from "../../../../constants/constants";
import Axios from "axios";
import moment from "moment";

export default function CurrentEducation({
  setStep,
  step,
  finalSelectedCollege,
  setFinalSelectedCollege,
  finalSelectedDegree,
  setFinalSelectedDegree,
  finalSelectedSpecialization,
  setFinalSelectedSpecilization,
  finalStartDate,
  finalEndDate,
  setFinalStartDate,
  setFinalEndDate,
  isCurrent,
  setIsCurrent,
  getContinueButtonId,
  getSkipButtonId
}) {
  // const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const filter = createFilterOptions();

  const [allCollegeData, setAllCollegeData] = useState([]);

  const [allDegreeData, setAllDegreeData] = useState([]);

  const [allSpecializationData, setAllSpecializationData] = useState([]);

  const [selectedCollege, setSelectedCollege] = useState(finalSelectedCollege);

  const [selectedDegree, setSelectedDegree] = useState(finalSelectedDegree);

  const [selectedSpecialization, setSelectedSpecilization] = useState(
    finalSelectedSpecialization
  );

  const [startDate, setStartDate] = useState(finalStartDate);
  const [endDate, setEndDate] = useState(finalEndDate);
  const [current, setCurrent] = useState(isCurrent);
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);

  const gwToken = localStorage.getItem("gw_token");

  const getAllCollegeList = () => {
    Axios.get(`${BackendBaseApi.PRAVESH}/api/education/college/`)
      .then((res) => {
        if (res?.data) {
          setAllCollegeData(res?.data);
        }
      })
      .catch((err) => {
        alert.error("Something went wrong. Please try again!!");
      });
  };

  const getCollegeId = () => {
    return selectedCollege?.is_onboarded ? selectedCollege?.id : 142;
  };

  const getDegreeList = () => {
    const queryParam = selectedCollege?.id
      ? `?college_id=${getCollegeId()}`
      : "";
    Axios.get(
      `${BackendBaseApi.PRAVESH}/api/education/get_degree${queryParam}`
    )
      .then((res) => {
        if (res?.data?.success) {
          setAllDegreeData(res?.data?.data);
        }
      })
      .catch((err) => {
        alert.error("Something went wrong. Please try again!!");
      });
  };

  const getSpecializationList = () => {
    const queryParam = selectedDegree?.id
      ? `?college_id=${getCollegeId()}&degree_id=${selectedDegree?.id}`
      : "";
    Axios.get(
      `${BackendBaseApi.PRAVESH}/api/education/get_specialization${queryParam}`
    )
      .then((res) => {
        if (res?.data?.success) {
          setAllSpecializationData(res?.data?.data);
        }
      })
      .catch((err) => {
        alert.error("Something went wrong. Please try again!!");
      });
  };

  useEffect(() => {
    getAllCollegeList();
  }, []);

  useEffect(() => {
    if (selectedCollege?.id) {
      getDegreeList();
    }
  }, [selectedCollege]);

  useEffect(() => {
    if (selectedDegree?.id) {
      getSpecializationList();
    }
  }, [selectedDegree]);

  const updateCurrentEducation = () => {
    if (!validateCurrentEducation()) {
      return;
    }
    setLoading(true);
    Axios.post(
      `${BackendBaseApi.PRAVESH}api/education/current_education/`,
      {
        college: selectedCollege?.id,
        college_name: selectedCollege?.name,
        start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        education: selectedDegree?.education_type,
        degree: selectedDegree?.id,
        specialization: selectedSpecialization?.specialization_id,
      },
      {
        headers: {
          Authorization: "Token " + gwToken,
        },
        withCredentials: true,
      }
    )
      .then((res) => {
        if (res?.data?.success) {
          setFinalEndDate(endDate);
          setFinalStartDate(startDate);
          setFinalSelectedCollege(selectedCollege);
          setFinalSelectedDegree(selectedDegree);
          setFinalSelectedSpecilization(selectedSpecialization);
          setIsCurrent(current);
          setStep(step + 1);
        } else {
          alert.error("Something went wrong. Try again!!");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Something went wrong. Try again!!");
      });
  };

  const validateCurrentEducation = () => {
    if (!selectedCollege || !selectedCollege?.id) {
      alert.error("Please select a college");
      return false;
    } else if (!selectedDegree || !selectedDegree?.id) {
      alert.error("Please select Degree");
      return false;
    } else if (
      !selectedSpecialization ||
      !selectedSpecialization?.specialization_id
    ) {
      alert.error("Please select Specialization");
      return false;
    } else if (!startDate) {
      alert.error("Please enter start date");
      return false;
    }
    else if(!endDate){
      alert.error(current ? "Please enter tentative end date" : "Please enter end date");
      return false;
    }
    else if(moment(startDate) > moment(endDate)){
      alert.error("Start date cannot be greater than end date");
      return false;
    }
    return true;
  };

  return (
    <>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar alt="user-img" src={user} sx={{ width: 66, height: 66 }} />
        <Typography
          variant="h4"
          style={{ fontWeight: "500", lineHeight: 1.4, marginTop: 5 }}
        >
          Finally, what is your recent educational background?
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
          <Autocomplete
            options={allCollegeData}
            value={
              selectedCollege && selectedCollege?.id
                ? selectedCollege?.name
                : ""
            }
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setSelectedCollege({
                  id: 142,
                  name: newValue,
                });
              } else if (newValue && newValue?.inputValue) {
                setSelectedCollege({
                  id: 142,
                  name: newValue.inputValue,
                });
              } else if (newValue) {
                setSelectedCollege(newValue);
              } else {
                setSelectedCollege({});
              }
              setAllDegreeData([]);
              setAllSpecializationData([]);
              setSelectedDegree({});
              setSelectedSpecilization({});
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option?.name
              );

              if (params.inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue: params.inputValue,
                  name: `Create "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            renderOption={(props, option) => {
              let { key, ...propsNew } = props;
              return (
                <Typography
                  style={{
                    fontSize: "14px",
                    padding: "5px 20px",
                    cursor: "pointer",
                  }}
                  /*  onClick={onClick} */
                  {...propsNew}
                  /* {...props} */
                >
                  {option?.name}
                </Typography>
              );
            }}
            style={{
              background: "#fff",
              borderRadius: 30,
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.name) {
                return option.name;
              }
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="outlined"
                required
                label="College Name"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
          <Autocomplete
            options={allDegreeData}
            disabled={allDegreeData.length > 0 ? false : true}
            value={selectedDegree?.id ? selectedDegree : null}
            renderOption={(props, option) => {
              let { key, ...propsNew } = props;
              return (
                <Typography
                  style={{
                    fontSize: "14px",
                    padding: "5px 20px",
                    cursor: "pointer",
                  }}
                  {...propsNew}
                >
                  {option?.name}
                </Typography>
              );
            }}
            getOptionLabel={(option) => (option?.name ? option?.name : "")}
            onChange={(event, value) => {
              if (value) {
                setSelectedDegree(value);
              } else {
                setSelectedDegree({});
              }
            }}
            style={{
              background: "#fff",
              borderRadius: 30,
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="outlined"
                required
                label="Degree"
                onKeyPress={(e) => {
                  e.preventDefault();
                }}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
          <Autocomplete
            options={allSpecializationData}
            disabled={allSpecializationData.length > 0 ? false : true}
            value={
              selectedSpecialization?.specialization_id
                ? selectedSpecialization
                : null
            }
            getOptionLabel={(option) =>
              option?.specialization_name ? option?.specialization_name : ""
            }
            renderOption={(props, option) => {
              let { key, ...propsNew } = props;
              return (
                <Typography
                  style={{
                    fontSize: "14px",
                    padding: "5px 20px",
                    cursor: "pointer",
                  }}
                  {...propsNew}
                >
                  {option?.specialization_name}
                </Typography>
              );
            }}
            onChange={(event, value) => {
              if (value) {
                setSelectedSpecilization(value);
              } else {
                setSelectedSpecilization({});
              }
            }}
            style={{
              background: "#fff",
              borderRadius: 30,
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="outlined"
                required
                label="Specialization"
                size="small"
                onKeyPress={(e) => {
                  e.preventDefault();
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              views={["year", "month"]}
              label="Start Date"
              // disablePast
              disableFuture
              value={startDate ? startDate : null}
              open={startOpen}
              onClose={() => {
                setStartOpen(false);
              }}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => (
                <CustomTextField size="small" {...params}
                onClick={() => {setStartOpen(true)}}
                inputProps={{...params.inputProps, readOnly: true, disabled: true}}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ pt: 0, pb: 0 }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={current}
                onChange={() => {
                  setCurrent(!current);
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                color="initial"
                style={{ color: "#6C757D" }}
              >
                I’m currently studying
              </Typography>
            }
          />
        </Grid>
      </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ display: "grid" }}>
          
          <LocalizationProvider dateAdapter={AdapterMoment}>
          
            <DesktopDatePicker
              views={["year", "month"]}
              label={current ? "Tentative End Date" : "End Date"}
              // disablePast
              minDate={startDate ? startDate : null}
              value={endDate ? endDate : null}
              open={endOpen}
              onClose={() => {
                setEndOpen(false);
              }}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => (
                <CustomTextField size="small" {...params}
                onClick={() => {setEndOpen(true)}}
                inputProps={{...params.inputProps, readOnly: true, disabled: true}}
                />
              )}
            />
          </LocalizationProvider>
          
        </Grid>
        
      <div>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            position: "relative",
            marginTop: "10px",
            [theme.breakpoints.only("sm")]: {
              top: "auto",
              bottom: 0,
              boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
              position: "fixed",
            },
            [theme.breakpoints.only("xs")]: {
              top: "auto",
              bottom: 0,
              boxShadow: "rgb(0 0 0 / 10%) 0px -2px 8px",
              position: "fixed",
            },
          }}
        >
          <Box
            sx={{
              pl: 0,
              pr: 0,
              [theme.breakpoints.only("sm")]: { pl: 1, pr: 1 },
              [theme.breakpoints.only("xs")]: { pl: 1, pr: 1 },
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
                <Button
                  color="primary"
                  sx={{
                    height: "40px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "30px",
                    display: "flex",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid xs={6} sm={6} md={6} style={{ display: "grid" }}>
                <Button
                  color="primary"
                  endIcon={<ArrowForwardRoundedIcon />}
                  sx={{
                    height: "40px",
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "30px",
                    display: "flex",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                  variant="contained"
                  onClick={() => {
                    updateCurrentEducation();
                  }}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    <span id={getContinueButtonId()}> Continue</span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AppBar>
      </div>
    </>
  );
}

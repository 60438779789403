import {
  GET_CREDIT_INFO,
  GET_TRANSACTION_HISTORY,
  UPDATE_CREDIT_INFO,
  TAction,
  GET_PROFILE_UNLOCK_INFO
} from "./action";
import { Reducer } from "react";

export interface UserServiceProps {
  credits: number;
  service_description: string;
  service_type: string;
}

export interface CreditInfoProps {
  available_credits: number;
  burned_credits: number;
  expired_credits: number;
  user_service: UserServiceProps[];
  credit_status: string;
  credit_details:boolean;
}

interface IStateProps {
  creditInfo: CreditInfoProps;
}

export const CreditInfo: Reducer<IStateProps, TAction> = (
  //@ts-ignore
  state = { creditInfo: {}, transactionHistory: {}, profileUnlockInfo: {} },
  action
) => {
  switch (action.type) {
    case GET_CREDIT_INFO:
      return { ...state, creditInfo: action.payload };
    case GET_PROFILE_UNLOCK_INFO:
      return { ...state, profileUnlockInfo: action.payload };
    case GET_TRANSACTION_HISTORY:
      return { ...state, transactionHistory: action.payload };
    case UPDATE_CREDIT_INFO:
      return { ...state, creditInfo: action.payload };
    default:
      return state;
  }
};

import { Box } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import NoJobs from "../../../assets/images/no-jobs.png";

import { BackendBaseApi } from "../../../constants/constants";

import { httpRequest } from "../../../utils/httpRequest";
//import InterviewCard from "../../InterviewCard/InterviewCard";
import InterviewCard from "../../company/cards/InterviewCard/InterviewCard";

//import TrackPane from "../../InterviewCard/TrackPane";
import TrackPane from "../../company/cards/InterviewCard/TrackPane";

import Loader from "../../../bundles/common/components/UI/Loader";

import { ReactComponent as NoData } from "../../../assets/svg/New entries-rafiki.svg";

export const Interview = () => {
  const [jobData, setJobData] = useState([]);
  const data = JSON.parse(localStorage.getItem("company"));
  const [end, setEnd] = useState(false);
  const [newURL, setNewURL] = useState("");
  const [newEndPoint, setNewEndPoint] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const [onlyClosed, setOnlyClosed] = useState(false);
  const [campusType, setCampusType] = useState(false);
  const [allJobProfiles, setAllJobProfiles] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("gw_token");
    const checkJobProfile = () => {
      if (jobProfile === "All" || jobProfile === "") {
        return "";
      } else {
        return `&job_id=${btoa(jobProfile)}`;
      }
    };

    const checkJobType = () => {
      if (selectedJobType === "All" || selectedJobType === "") {
        return "";
      } else {
        return `&job_type_id=${btoa(selectedJobType)}`;
      }
    };

    const checkCollege = () => {
      if (selectedCompany === "All" || selectedCompany === "") {
        return "";
      } else {
        return `&college_id=${btoa(selectedCompany)}`;
      }
    };

    const checkOnlyClosed = () => {
      if (!onlyClosed) {
        return "";
      } else {
        return `&job_status=${btoa("CLOSE")}`;
      }
    };

    // const checkJobStatus = () => {
    //   return `&status=${btoa(activeTab)}`;
    // };

    const checkCampusType = () => {
      if (!campusType) {
        return `&hiring_type=${btoa("OFF CAMPUS")}`;
      } else {
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      }
    };

    // setLoading(true);
    GetData(
      BackendBaseApi.NIYUKTI,
      `job/company/manage_interview/?company_id=${window.btoa(
        data.company
      )}${checkJobType()}${checkJobProfile()}${checkCollege()}${checkOnlyClosed()}${checkCampusType()}`,
      { headers: token },
      setJobData
    );
  }, [selectedJobType, jobProfile, selectedCompany, onlyClosed, campusType]);
  const GetData = async (baseUrl, endPoint, body, updateState) => {
    setIsLoading(true);
    let res = await httpRequest(baseUrl, endPoint, body);
    if (res.data.next === null) {
      setEnd(true);
      updateState(res.data.results);
    } else {
      setNewURL(res.data.next.slice(0, 20));
      setNewEndPoint(res.data.next.slice(20));
      updateState(jobData.concat([...res.data.results]));
    }
    setIsLoading(false);
    setJobData(res.data.results);
  };

  const getJobProfiles = async () => {
    const appendParam = () => {
      if (selectedJobType === "All" || selectedJobType === "") {
        return "";
      } else return `&job_type_id=${btoa(selectedJobType)}`;
    };

    const checkOnlyClosed = () => {
      if (!onlyClosed) {
        return "";
      } else {
        return `&job_status=${btoa("CLOSE")}`;
      }
    };

    const checkCampusType = () => {
      if (!campusType) {
        return `&hiring_type=${btoa("OFF CAMPUS")}`;
      } else {
        return `&hiring_type=${btoa("ON CAMPUS")}`;
      }
    };

    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/job/list/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}${appendParam()}${checkOnlyClosed()}${checkCampusType()}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });

    let profiles = [];
    let obj = { job_id: "All", job_title: "All" };

    setAllJobProfiles([obj, ...res.data.data]);
  };

  const getJobTypes = async () => {
    const res = await Axios.get(BackendBaseApi.NIYUKTI + "job/type/").catch(
      (err) => {
        throw err;
      }
    );
    let obj = { id: "All", job_type: "All" };
    setJobType([obj, ...res.data.data]);
  };

  const getCompanies = async () => {
    const res = await Axios.get(
      BackendBaseApi.NIYUKTI +
        `job/company/college/?company_id=${btoa(
          JSON.parse(localStorage.getItem("company")).company
        )}&interview_state=true`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("gw_token")}`,
        },
      }
    ).catch((err) => {
      throw err;
    });
    let obj = { name: "All", id: "All" };
    setAllCompanies([obj, ...res.data.data]);
  };

  useEffect(() => {
    //getJobProfiles();
    getJobTypes();
    //getCompanies();
  }, []);

  useEffect(() => {
    getJobProfiles();
  }, [selectedJobType, selectedCompany, onlyClosed, campusType]);

  const handleScroll = (event) => {
    let e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if (end !== true) {
        const token = localStorage.getItem("gw_token");
        GetData(newURL, newEndPoint, { headers: token }, setJobData);
      }
    }
  };

  useEffect(() => {
    setJobProfile("");
    // setSelectedCompany("All");
    getCompanies();
    setSelectedCompany("");
  }, [campusType]);

  return (
    <div
    // className="scrollY"
    // onScroll={handleScroll}
    // style={{height: "unset", marginTop: "50px"}}
    >
      {/* <div style={{ margin: "30px 20px 20px 20px" }}> */}
      <div style={{ marginTop: "-20px" }}>
        <TrackPane
          profiles={allJobProfiles}
          jobProfile={jobProfile}
          jobTypes={jobType}
          selectedJobType={selectedJobType}
          setSelectedJobType={setSelectedJobType}
          setJobProfile={setJobProfile}
          allCompanies={allCompanies}
          setSelectedCompany={setSelectedCompany}
          onlyClosed={onlyClosed}
          setOnlyClosed={setOnlyClosed}
          campusType={campusType}
          setCampusType={setCampusType}
        />

        {isLoading ? (
          <Loader />
        ) : jobData.length > 0 ? (
          jobData.map((item, index) => (
            <Fragment>
              <InterviewCard
                data={item}
                index={index}
                onlyClosed={onlyClosed}
                jobData={jobData}
                setJobData={setJobData}
              />
              <br />
            </Fragment>
          ))
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="5%"
            paddingBottom="5%"
          >
            <NoData
              height="400px"
              width="400px"
              alt="404 Error"
              fill={"#007BFF"}
            />
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              No Interview Scheduled
            </p>
          </Box>
        )}
      </div>
    </div>
  );
};

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PublishIcon from "@material-ui/icons/Publish";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  BackendBaseApi,
  emailRegex,
  passwordRegex,
} from "../../../constants/constants"; // "../../../../../constants/constants"
import theme from "../Theme/theme";
import { stringEllipsify } from "../../../utils/utility"; //../../../../../utils/utility
import EnterOtp from "../../../components/student/Dialog/EnterOtp";
import { getCreditInfo } from "../../../GlobalComponents/CreditBoard/action"; // ../../../GlobalComponents/CreditBoard/action

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
  },
  text: {
    fontFamily: " Nunito",
    //color: theme.palette.domain.main,
    fontWeight: 900,
  },
  paper: {
    padding: 25,
    margin: "auto",
    //maxWidth: 500,
    /* width: "100%", */
    borderRadius: "25px",
    border: "solid 1.5px #e2e6ea",
    boxShadow: "0 0 3px 0 rgba(0 0 0 0.16)",
    //   boxShadow: "none",
    display: "flex",
    width: "73%",
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  margin: {
    // margin: theme.spacing(1),
    width: "100%",
    fontFamily: " Nunito",
  },
  btn: {
    padding: "14px",
    paddingLeft: "32%",
    paddingRight: "32%",
    //backgroundColor: theme.palette.domain.main,
    borderRadius: "30px",
    boxShadow: "none",
    "&:hover": {
      //backgroundColor: theme.palette.domain.hover,
    },
  },
  btnsocial: {
    padding: "12px",
    borderRadius: "30px",
    boxShadow: "none",
  },
  grid: {
    display: "grid",
    /*  alignItems: "center",
      textAlign: "center",
      flexDirection: "column", */
  },
  ctcGrid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "row",
  },
  pass: {
    fontFamily: " Nunito",
    color: "#007bff",
    fontSize: "12px",
    fontWeight: "900",
  },
  btndiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0 2px 4px 0 rgba(44 39 56 0.08)",
  },
  create: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  createNew: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorPassword: {
    fontFamily: " Nunito",

    fontSize: "12px",
  },
  "@media only screen and (max-width: 1100px)": {
    paper: {
      width: "100%",
      borderRadius: 0,
      border: "0px",
      boxShadow: "0px",
    },
  },
  floatingLabelFocusStyle: {
    //color: "#7E7E7E",
    //fontSize: "14px",
    color: "#C5C4BA",
    transform: "translate(15px, 14px) scale(1)",
  },

  input: {
    height: 48,
    fontFamily: "Nunito",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const BlueCheckbox = withStyles({
  root: {
    color: "#6c757d",
    "&$checked": {
      color: "#007bff",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

// const CssTextField = withStyles({
//   root: {
//     "& label.Mui-focused": {
//       color: "#007bff",
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: "#007bff",
//       borderRadius: "30px",
//     },
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         border: "solid 1.5px #e2e6ea",
//         borderRadius: "30px",
//         padding: "15px",
//       },
//       "&:hover fieldset": {
//         borderColor: "black",
//         borderRadius: "30px",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "#007bff",
//         borderRadius: "30px",
//       },
//     },
//   },
// })(TextField);
export default function TeamInviteForm(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const checkUserType = (id) => {
    if (id === "1") {
      return "student";
    } else if (
      id === "2" ||
      id === "3" ||
      id === "4" ||
      id === "5" ||
      id === "11"
    ) {
      return "company";
    } else if (id === "7" || id === "8" || id === "12" || id === "13") {
      return "college";
    }
  };

  const [openOtp, setOpenOtp] = React.useState(false);
  const [otpDetails, setOtpDetails] = useState({
    email: location.Email,
    otp: null,
  });
  const [otpDetailsError, setOtpDetailsError] = useState({
    email: null,
    otp: null,
    user_type: false,
  });
  const [codeError, setCodeError] = useState(null);
  const [type, setType] = useState(
    location.userTypeID && checkUserType(location.userTypeID)
  );
  const [code, setCode] = useState("");
  const handleClickOtp = () => {
    setOpenOtp(false);
    setOtpDetails({ ...otpDetails, email: "", otp: "" });
    setCodeError(null);
    setCode("");
    history.push("/login");
  };

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  /*  const domain = useSelector(
      (state) => state.SubDomainDetails.subDomainDetails
    ); */

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [buttonClicked, setButtonClicked] = useState(false);
  const [validate, setValidate] = useState(false);
  const [teamInviteLoading, setTeamInviteLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: location?.Email,
    password: "",
    jobDesignation: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    fullName: "",
    phone: "",
    email: "",
    password: "",
    jobDesignation: "",
  });

  const validateData = () => {
    var fullNameError = "";
    var phoneError = "";
    var emailError = "";
    var passwordError = "";
    var jobDesignationError = "";

    setValidate(false);
    if (formData.fullName === "") {
      fullNameError = "Name cannot be left blank";
      setValidate(true);
    }
    if (formData.phone === "") {
      phoneError = "Phone Number cannot be left blank";
      setValidate(true);
    }
    if (formData.phone && formData.phone.length !== 10) {
      phoneError = "Enter a valid phone number";
      setValidate(true);
    }
    if (formData.email === "") {
      emailError = "Email cannot be left blank";
      setValidate(true);
    }
    if (!emailRegex.test(formData.email)) {
      emailError = "Please enter a valid email address";
      setValidate(true);
    }
    if (formData.jobDesignation === "") {
      jobDesignationError = "Job Designation cannot be left blank";
      setValidate(true);
    }
    if (formData.password === "") {
      passwordError = "Password field cannot be left blank";
      setValidate(true);
    }
    if (!passwordRegex.test(formData.password)) {
      passwordError =
        "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit";
      setValidate(true);
    }

    setErrorMessages({
      ...errorMessages,
      fullNameError: fullNameError,
      phone: phoneError,
      email: emailError,
      password: passwordError,
      jobDesignation: jobDesignationError,
    });
    setButtonClicked(true);
  };

  useEffect(() => {
    if (
      !validate &&
      buttonClicked &&
      formData.email !== "" &&
      formData.password !== ""
    ) {
      /* handleSubmit(); */
      teamAdd();
    }
  }, [buttonClicked]);

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangecheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  /*   useEffect(() => {
      if (emailRegex.test(userDetails?.Email)) {
        Axios.post(BackendBaseApi.PRAVESH + "api/check_user/", {
          email: userDetails?.Email,
        })
          .then((res) => {
            if (res.data.data.is_student_user) {
              setErrorMessages({
                ...errorMessages,
                email: `Email '${userDetails?.Email}' already exists!!`,
              });
              return;
            }
          })
          .catch((err) => {
            alert.error("Error occured!");
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails?.Email]); */

  // useEffect(() => {
  //   if (!location.ifn) {
  //     history.push("/login");
  //   }
  // }, []);

  const generateOTP = () => {
    Axios.post(BackendBaseApi.PRAVESH + "api/generate_otp/", {
      email: formData?.email,
      user_type: type.toLowerCase(),
    })
      .then((res) => {
        if (res.data.success) {
          setOpenOtp(true);
        } else {
          if (res.data.data.code === 2 || res.data.data.code === 1) {
            // alert.error(res.data.data.code_text);
            setOtpDetailsError({
              ...otpDetailsError,
              email: res.data.data.code_text,
              user_type: true,
            });
          } else alert.error("Some Error Occurred");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const teamAdd = () => {
    var data = {};
    const [firstName, ...lastName] = formData?.fullName
      .split(" ")
      .filter(Boolean);
    if (location?.About) {
      data = {
        uid: location.u_id,
        token: location.to_ken,
        email: location.Email,
        first_name: firstName ? firstName : null,
        last_name: lastName?.length > 0 ? lastName.join(" ") : "",
        phone: formData?.phone,
        password: formData?.password,
        new_user_flag: location.flag,
        user_type_id: location.userTypeID,
        company_id: location.companyID,
        company_name: location.companyName,
        job_title: formData?.jobDesignation,
        about: location.About,
        alma_mater_id: null,
        alma_mater_name: null,
        graduation_year: null,
      };
    } else {
      data = {
        uid: location.u_id,
        token: location.to_ken,
        email: location.Email,
        first_name: firstName,
        last_name: lastName?.length > 0 ? lastName.join(" ") : "",
        phone: formData?.phone,
        password: formData?.password,
        new_user_flag: location.flag,
        user_type_id: location.userTypeID,
        company_id: location.companyID,
        company_name: location.companyName,
        job_title: formData?.jobDesignation,
        about: location.About ? location.About : null,
        alma_mater_id: null,
        alma_mater_name: null,
        graduation_year: null,
      };
    }

    /*   if (
        location.Email &&
        firname &&
        lastname &&
        password &&
        mobile1 &&
        (collegeList[collegeType] || cname) &&
        year &&
        title
      ) { */
    setTeamInviteLoading(true);
    Axios.post(
      BackendBaseApi.PRAVESH + "api/company/settings/team/add/",
      data
      // , {
      //     headers: {
      //         Authorization: "Token " + token,
      //     },
      // }
    )
      .then((res) => {
        if (res.data.success) {
          alert.success("Signed Up Successfully. Please Login to Continue");
          generateOTP();
        } else alert.error(res.data.error);
        setTeamInviteLoading(false);
      })
      .catch((err) => {
        alert.error("Error");
        setTeamInviteLoading(false);
      });

    /*   } else {
        alert.error("Fill All the Required Fields");
      } */
  };

  const [loading, setLoading] = useState(false);

  const [loader, setLoader] = useState(false);
  const checkUser = (token) => {
    Axios.get(BackendBaseApi.PRAVESH + "api/profile/details/", {
      headers: {
        Authorization: "Token " + token,
      },
      withCredentials: true,
    })
      .then((res) => {
        //CASE 1: user type does not exist => profile is also not completed
        if (res.data.user_type.length === 0) {
          if (!res.data.email_verified) {
            //move to signup options component
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              email: res.data.email,
              token,
              isemailVerified: false,
            });
          } else {
            //move to signup options component
            // setStep(step + 2);
            setLoading(false);
            history.push({
              pathname: "/signup",
              step: 2,
              userid: res.data.id,
              token,
              email: res.data.email,
              isemailVerified: true,
            });
          }
        } else {
          localStorage.setItem("user_type", res.data.user_type[0].main_user);
          localStorage.setItem("user_type_id", res.data.user_type[0].type);
          localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

          if (res.data.email_verified) {
            localStorage.setItem("gw_token", token);
            localStorage.setItem("user_type_id", res.data.user_type[0].type);

            localStorage.setItem("user_id", res.data.user_type[0].user_id);
            localStorage.setItem("is_pref_filled", res.data.is_pref_filled);

            //check if its student or company
            if (res.data.user_type[0].user_id == 1) {
              //student with verified email, go to home

              history.push("/student/dashboard");
            } else {
              if (res.data.is_pref_filled) {
                if (!res.data.company_details.admin_approved) {
                  localStorage.setItem(
                    "user",
                    JSON.stringify({
                      email: res.data.email,
                      first_name: res.data.first_name,
                      last_name: res.data.last_name,
                      id: res.data.id,
                      profile_picture: null,
                    })
                  );

                  history.push({
                    pathname: "/company/admin-approve",
                    flag: 19087,

                    company: res.data.company_details.company_name,
                    admin_email: res.data.company_details.admin_email,
                    admin_name: res.data.company_details.admin_name,
                    is_admin: res.data.is_company_admin,
                  });
                } else {
                  if (res?.data?.company_details !== null)
                    localStorage.setItem(
                      "company",
                      JSON.stringify(res.data.company_details)
                    );

                  dispatch(getCreditInfo(setLoader));
                  if (location.flag === 28071998)
                    history.push("/company/post-job");
                  else {
                    if (res.data.user_type[0].type === 5) {
                      localStorage.setItem(
                        "comp_pref_filled",
                        res.data.comp_pref_filled
                      );
                      history.push({
                        pathname: "/company/dashboard",
                        comp_pref_filled: res.data.comp_pref_filled,
                      });
                    } else {
                      history.push({ pathname: "/company/dashboard" });
                    }
                  }
                }
              } else {
                localStorage.setItem("mobile", res.data.mobile);
                history.push("/company/complete-profile");
              }
            }

            // else {
            //   //company user with verified email, check if admin approved
            //   if (!res.data.company_details.admin_approved) {
            //     history.push({
            //       pathname: "/company/admin-approve",
            //       company: res.data.company_details.company_name,
            //       admin_email: res.data.company_details.admin_email,
            //       admin_name: res.data.company_details.admin_name,
            //       is_admin: res.data.is_company_admin,
            //     });
            //   } else history.push("/company/dashboard");
            // }
          } else {
            //check if its student or company
            if (res.data.user_type[0].type == 1) {
              localStorage.setItem("gw_token", token);
              localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
              localStorage.setItem("user_id", res.data.id);
              localStorage.setItem(
                "is_student_resume_uploaded",
                res.data.is_student_resume_uploaded
              );
              localStorage.setItem(
                "profile_complete",
                res.data.user_type[0].profile_complete
              );

              //student without verified email, go to home

              if (res.data.is_pref_filled) {
                // if (location.search.includes("?source=") && !location.search.includes("utm_source") && !getCookie("utm_source")) {
                //   history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                // } else if (location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
                //   history.push({ pathname: `/student/referral/update-profile-and-apply`, search: location.search ? location.search : "" });
                // } else if (!location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
                //   history.push({ pathname: `/student/referral/update-profile-and-apply` });
                // } else
                history.push("/student/dashboard");
              } else {
                // if (location.search.includes("?source=") && !location.search.includes("utm_source") && !getCookie("utm_source")) {
                //   history.push({ pathname: `/student/update-profile-and-apply`, search: location.search ? location.search : "" });
                // } else if (location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
                //   history.push({ pathname: `/student/referral/update-profile-and-apply`, search: location.search ? location.search : "" });
                // } else if (!location.search.includes("?source=") && (location.search.includes("utm") || getCookie("utm_source"))) {
                //   history.push({ pathname: `/student/referral/update-profile-and-apply` });
                // } else {
                //   history.push({
                //     pathname: "/student/complete-profile",
                //     flag: 12367,
                //   });
                // }
                history.push({
                  pathname: "/student/complete-profile",
                  flag: 12367,
                });
              }
            } else {
              //company user without verified email
              history.push({
                pathname: "/confirm-email",
                email: res.data.email,
                user_id: res.data.user_type[0].user_id,
              });
            }
          }
        }

        //CASE 2:
      })
      .catch((err) => {
        setLoading(false);
        alert.error("Oop! There is some error, try again later.");
        localStorage.clear();
        throw err;
      });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.grid}>
            {/* <img src={Logo} height="70px" width="80px" />
                <br /> */}
            <Typography
              variant="h4"
              style={{ /* fontFamily: " Nunito", */ fontWeight: 600 }}
            >
              <>
                {/*  Register to Hire high-quality candidates <br />
                  with GetWork */}
                {location.ifn}&nbsp; {location.iln} Has Invited You To Join Team{" "}
                {location.companyName}
              </>
            </Typography>
          </Grid>
          <br />
          <Grid item xs={12} md={12} className={classes.grid}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label="Full Name"
              variant="outlined"
              type="text"
              error={errorMessages?.fullName !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages?.fullName !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages?.fullName}
                </span>
              }
              value={formData?.fullName}
              onChange={(e) => {
                setFormData({ ...formData, fullName: e.target.value });
                setErrorMessages({ ...errorMessages, fullName: "" });
                setButtonClicked(false);
              }}
            />
          </Grid>
          <br />
          <Grid item xs={12} md={12} className={classes.grid}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label="Email"
              variant="outlined"
              type="email"
              error={errorMessages?.email !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages?.email !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages?.email}
                </span>
              }
              value={formData?.email}
              /*   onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }} */
            />
          </Grid>
          <br />
          <Grid item xs={12} md={12} className={classes.grid}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label="Phone Number"
              variant="outlined"
              type="number"
              error={errorMessages?.phone !== "" ? true : false}
              id="custom-css-outlined-input"
              //error={errorMessages.firstName !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages?.phone !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages?.phone}
                </span>
              }
              value={formData?.phone}
              onChange={(e) => {
                setFormData({ ...formData, phone: e.target.value });
                setErrorMessages({ ...errorMessages, phone: "" });
                setButtonClicked(false);
              }}
              /*  onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }} */
            />
          </Grid>
          <br />
          <Grid item xs={12} md={12} className={classes.grid}>
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              label="Job Designation"
              variant="outlined"
              type="text"
              error={errorMessages?.jobDesignation !== "" ? true : false}
              helperText={
                <span
                  style={
                    errorMessages?.jobDesignation !== ""
                      ? { color: "#D64045", fontSize: 13 }
                      : { color: "grey", fontSize: 13 }
                  }
                >
                  {errorMessages?.jobDesignation}
                </span>
              }
              value={formData?.jobDesignation}
              onChange={(e) => {
                setFormData({ ...formData, jobDesignation: e.target.value });
                setErrorMessages({ ...errorMessages, jobDesignation: "" });
                setButtonClicked(false);
              }}
              /*   onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }} */
            />
          </Grid>
          <br />

          <Grid item xs={12} md={12}>
            <FormControl
              color="#007bff"
              className={clsx(classes.margin, classes.textField, classes.form)}
              variant="outlined"
              error={errorMessages?.password !== "" ? true : false}
            >
              <InputLabel
                className={classes.floatingLabelFocusStyle}
                style={{ borderBottomColor: "#007bff" }}
              >
                Password*
              </InputLabel>

              <OutlinedInput
                style={{
                  borderRadius: "30px",
                  // padding: "22px",
                  borderColor: "#007bff",
                  height: "40px",
                }}
                id="outlined-adornment-password"
                type={values?.showPassword ? "text" : "password"}
                value={formData?.password}
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                // labelWidth={80}
                onChange={(e) => {
                  setFormData({ ...formData, password: e.target.value });
                  setErrorMessages({ ...errorMessages, password: "" });
                  setButtonClicked(false);
                }}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values?.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />

              <FormHelperText
                style={{
                  fontFamily: " Nunito",

                  fontSize: "12px",
                }}
                id="standard-weight-helper-text"
                className={
                  errorMessages?.password !== ""
                    ? classes?.errorPassword
                    : classes?.noErrorPassword
                }
              >
                {errorMessages?.password !== ""
                  ? errorMessages?.password
                  : "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit"}
              </FormHelperText>
            </FormControl>

            {/* <CssTextField
                  className={classes.margin}
                  label="College Name"
                  variant="outlined"
                  id="custom-css-outlined-input"
                /> */}
          </Grid>

          <Grid item md={12} xs={12} style={{ paddingTop: "0px" }}>
            <FormControlLabel
              control={
                <BlueCheckbox
                  size="small"
                  checked={state.checkedA}
                  onChange={handleChangecheck}
                  name="checkedA"
                />
              }
              label={
                <Typography
                  style={{
                    fontSize: "12px",
                    fontFamily: "Nunito",
                    marginTop: "10px",
                    color: "#000",
                  }}
                >
                  I want to receive GetWork newsletter to receive Product
                  updates and useful tips.
                </Typography>
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <div className={classes.btndiv}>
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={() => {
                  validateData();
                }}
                style={{ height: 40, display: "flex", boxShadow: "none" }}
              >
                <Typography
                  variant="body2"
                  style={{ color: "#fff", fontFamily: " Nunito" }}
                >
                  {" "}
                  {/* Sign In{" "} */}
                  {teamInviteLoading ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    <b>Continue</b>
                  )}
                </Typography>
              </Button>
            </div>
          </Grid>

          {/* <Grid item xs={12} md={12} className={classes.grid}>
              <CssTextField
                className={classes.margin}
                label="Full Name *"
                variant="outlined"
                id="custom-css-outlined-input"
                type="text"
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={errorMessages?.fullName !== "" ? true : false}
                helperText={
                  <span
                    style={
                      errorMessages?.fullName !== ""
                        ? { color: "#D64045", fontSize: 13 }
                        : { color: "grey", fontSize: 13 }
                    }
                  >
                    {errorMessages?.fullName}
                  </span>
                }
                value={formData?.fullName}
                onChange={(e) => {
                  setFormData({ ...formData, fullName: e.target.value });
                  setErrorMessages({ ...errorMessages, fullName: "" });
                  setButtonClicked(false);
                }}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.grid}>
              <CssTextField
                className={classes.margin}
                label="Email *"
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                id="custom-css-outlined-input"
                type="email"
                error={errorMessages?.email !== "" ? true : false}
                helperText={
                  <span
                    style={
                      errorMessages?.email !== ""
                        ? { color: "#D64045", fontSize: 13 }
                        : { color: "grey", fontSize: 13 }
                    }
                  >
                    {errorMessages?.email}
                  </span>
                }
                value={formData?.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                  setErrorMessages({ ...errorMessages, email: "" });
                  setButtonClicked(false);
                }}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }}
              />
            </Grid> */}

          {/* <Grid item xs={12} md={12} className={classes.grid}>
              <CssTextField
                className={classes.margin}
                label="Phone No *"
                variant="outlined"
                type="number"
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={errorMessages?.phone !== "" ? true : false}
                id="custom-css-outlined-input"
                //error={errorMessages.firstName !== "" ? true : false}
                helperText={
                  <span
                    style={
                      errorMessages?.phone !== ""
                        ? { color: "#D64045", fontSize: 13 }
                        : { color: "grey", fontSize: 13 }
                    }
                  >
                    {errorMessages?.phone}
                  </span>
                }
                value={formData?.phone}
                onChange={(e) => {
                  setFormData({ ...formData, phone: e.target.value });
                  setErrorMessages({ ...errorMessages, phone: "" });
                  setButtonClicked(false);
                }}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }}
              />
            </Grid>
            <br />
            <Grid item xs={12} md={12} className={classes.grid}>
              <CssTextField
                className={classes.margin}
                label="Job Designation *"
                variant="outlined"
                id="custom-css-outlined-input"
                type="text"
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                error={errorMessages?.jobDesignation !== "" ? true : false}
                helperText={
                  <span
                    style={
                      errorMessages?.jobDesignation !== ""
                        ? { color: "#D64045", fontSize: 13 }
                        : { color: "grey", fontSize: 13 }
                    }
                  >
                    {errorMessages?.jobDesignation}
                  </span>
                }
                value={formData?.jobDesignation}
                onChange={(e) => {
                  setFormData({ ...formData, jobDesignation: e.target.value });
                  setErrorMessages({ ...errorMessages, jobDesignation: "" });
                  setButtonClicked(false);
                }}
                onKeyPress={(data) => {
                  if (data.charCode === 13) {
                    validateData();
                  }
                }}
              />
            </Grid>
  
            <Grid item xs={12} md={12}>
              <FormControl
                color="#007bff"
                className={clsx(classes.margin, classes.textField, classes.form)}
                variant="outlined"
                error={errorMessages?.password !== "" ? true : false}
              >
                <InputLabel
                  className={classes.floatingLabelFocusStyle}
                  style={{ borderBottomColor: "#007bff" }}
                >
                  Password*
                </InputLabel>
  
                <OutlinedInput
                  style={{
                    borderRadius: "30px",
                    // padding: "22px",
                    borderColor: "#007bff",
                    height: "48px",
                  }}
                  id="outlined-adornment-password"
                  type={values?.showPassword ? "text" : "password"}
                  value={formData?.password}
                  inputProps={{
                    style: {
                      padding: "16px",
                    },
                  }}
                  // labelWidth={80}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    setErrorMessages({ ...errorMessages, password: "" });
                    setButtonClicked(false);
                  }}
                  onKeyPress={(data) => {
                    if (data.charCode === 13) {
                      validateData();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values?.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
  
                <FormHelperText
                  style={{
                    fontFamily: " Nunito",
  
                    fontSize: "12px",
                  }}
                  id="standard-weight-helper-text"
                  className={
                    errorMessages?.password !== ""
                      ? classes?.errorPassword
                      : classes?.noErrorPassword
                  }
                >
                  {errorMessages?.password !== ""
                    ? errorMessages?.password
                    : "Password must be atleast 8 characters long and contain at least one uppercase, one lowercase and one digit"}
                </FormHelperText>
              </FormControl>
  
              {/* <CssTextField
                  className={classes.margin}
                  label="College Name"
                  variant="outlined"
                  id="custom-css-outlined-input"
                /> */}
          {/* </Grid>
  
            <Grid item md={12} xs={12} style={{ paddingTop: "0px" }}>
              <FormControlLabel
                control={
                  <BlueCheckbox
                    size="small"
                    checked={state.checkedA}
                    onChange={handleChangecheck}
                    name="checkedA"
                  />
                }
                label={
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontFamily: "Nunito",
                      marginTop: "10px",
                      color: "#000",
                    }}
                  >
                    I want to receive GetWork newsletter to receive Product
                    updates and useful tips.
                  </Typography>
                }
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <div className={classes.btndiv}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  onClick={() => {
                    validateData();
                  }}
                  style={{ height: 45 }}
                >
                  <Typography
                    variant="body2"
                    style={{ color: "#fff", fontFamily: " Nunito" }}
                  >
                    {" "}
                    {/* Sign In{" "} */}
          {/* {loading ? (
                      <CircularProgress style={{ color: "#fff" }} size={18} />
                    ) : (
                      <b>Register</b>
                    )}
                  </Typography>
                </Button>
              </div>
            </Grid>   */}
        </Grid>
      </Paper>

      <EnterOtp
        handleCloseotp={handleClickOtp}
        otpopen={openOtp}
        otpDetails={otpDetails}
        type={type}
        checkUser={checkUser}
        code={code}
        setCode={setCode}
        codeError={codeError}
        setCodeError={setCodeError}
        //handleClickOpen={handleClickOpen}
        fromTeamInvite={true}
      />
    </div>
  );
}

import { Box, createStyles, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    WhiteBox: {
      backgroundColor: "#fff",
      margin: "20px 40px 40px 40px",
      padding: "40px",
      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
    },
    WhiteBox2: {
      backgroundColor: "#fff",
      margin: "0 40px 0 0",
      padding: "40px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    WhiteBox3: {
      backgroundColor: "#fff",
      margin: "40px 40px 40px 0",
      padding: "40px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
    },
    imageAndText: {
      float: "left",
      padding: "0 20px 15px 0",
    },
    SplitBox: {
      display: "flex",
      flexDirection: "column",
    },
    para: {
      color: "#4c4949",
      fontSize: "18px",
      textAlign: "center",
    },
    heading: {
      fontWeight: "bolder",
      color: "#444343",
    },
    borderBox: {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      // border: "1px solid grey",
      padding: "10px",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        transform: "scale(1.02)",
        transition: "transform 0.2s",
      },
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
    },
    "@media only screen and (max-width: 768px)": {
      WhiteBox: {
        width: "100%",
        marginLeft: "-15px",
        marginRight: "0px",
        backgroundColor: "inherit",
        marginTop: "20px",
        marginBottom: "40px",
        padding: "0px",
        boxShadow: "none",
        borderRadius: "4px",
      },
    },
  })
);

export default function OverviewBox(props) {
  const { data } = props;
  const classes = useStyles();
  const [placementData, setPlacementData] = useState({});
  useEffect(() => {
    if (data) {
      setPlacementData(data?.placement);
    }
  }, [data]);
  return (
    <Box className={classes.WhiteBox}>
      <h2 className={classes.heading}>
        Top Packages offered by these Companies
      </h2>
      <br />
      <Grid container spacing={3}>
        {placementData.placement?.map((item) => (
          <Grid item md={2}>
            <img
              src={item.company_image_path}
              alt=""
              height="60px"
              width="120px"
              style={{ marginBottom: "30px" }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
